import { COLOR } from '../../../containers/App/AppStyles';

const styles = {
  iconsContainer: {
    display: 'flex',
    padding: '2px',
    borderRadius: '3px',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
    marginLeft: '8px',
  },
  button: {
    backgroundColor: COLOR.grey[8],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '28px',
    width: '28px',
  },
};

export default styles;
