// Core
import React, { memo, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MonthPicker from 'react-month-picker';
// UI
import Button from '@mui/material/Button';
// Style
import 'react-month-picker/css/month-picker.css';
import './DataRangeModalStyle.css';

// Icons
import Modal from '../../containers/Modals/Modal/Modal';

// hooks
import { useStyles } from '../../hooks/useStyles';

// Parts
import DateRange from './DateRange';
import ModalFooter from '../../containers/Modals/Modal/ModalFooter/ModalFooter';

// styles
import DataRangeModalStyles from './DataRangeModalStyles';

const monthPickerDefValue = {
  from: { year: new Date().getFullYear(), month: new Date().getMonth() - 5 },
  to: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
};
const monthPickerYears = { min: 2021 };
const formatDataToString = currentValue => ({ // this is need for Back-end
  year: `${currentValue.year}`,
  month: currentValue.month < 10 ? `0${currentValue.month}` : `${currentValue.month}`,
});
const formatDataToNumber = currentValue => ({ // this is need for react-month-picker
  year: Number(currentValue.year),
  month: Number(currentValue.month),
});


function DataRangeModal(props) {
  const {
    children, open, title, handleDataRangeToggle, type,
    onDateRangeChange, startDate, endDate, range, filters, columnName,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(DataRangeModalStyles);
  const monthPickerRef = useRef();
  const monthPickerLang = {
    months: [t('Янв'), t('Фев'), t('Мар'), t('Апр'), t('Май'), t('Июн'), t('Июл'), t('Авг'), t('Сен'), t('Окт'), t('Ноя'), t('Дек')],
    from: t('от'),
    to: t('до'),
  };

  const onChange = (rangePeriod) => {
    onDateRangeChange(rangePeriod);
    if (range === false) {
      handleDataRangeToggle();
    }
  };

  useEffect(() => {
    if (open && type === 'month') {
      setTimeout(() => {
        monthPickerRef.current.show();
      }, 100);
    }
  }, [open, type]);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      handleDataRangeToggle();
    }
  };

  const onSubmitMonth = () => {
    const rawValue = monthPickerRef.current?.state?.rawValue;
    const currentValue = {
      from: formatDataToString(rawValue.from),
      to: formatDataToString(rawValue.to),
    };
    onDateRangeChange(currentValue);
    onDateRangeChange(currentValue);
    handleDataRangeToggle();
  };
  const modalFooterComponent = () => (
    type === 'month' && (
      <ModalFooter>
        <Button color="primary" variant="contained" onClick={onSubmitMonth}>{t('Применить')}</Button>
      </ModalFooter>
    )
  );

  return (
    <Modal
      title={title}
      modalOpen={open}
      handleModalToggle={handleDataRangeToggle}
      modalMaxWidth="unset"
      fullWidth={false}
      hideButtons
      modalFooterComponent={modalFooterComponent}
      onKeyUp={onEnter}
      noPadding
    >
      {type === 'month'
        ? (
          <div className={classes.modalPaper}>
            <MonthPicker
              ref={monthPickerRef}
              years={monthPickerYears}
              value={filters?.value ? {
                from: formatDataToNumber(filters?.value?.from),
                to: formatDataToNumber(filters?.value?.to),
              } : monthPickerDefValue}
              lang={monthPickerLang}
              theme="light"
            />
          </div>
        )
        : (
          <DateRange
            range={range}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            columnName={columnName}
          />
        )
      }
      {children}
    </Modal>
  );
}

DataRangeModal.propTypes = {
  filters: PropTypes.any,
  handleDataRangeToggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  range: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  onDateRangeChange: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  type: PropTypes.oneOf(['day', 'month']),
  columnName: PropTypes.string,
};

DataRangeModal.defaultProps = {
  type: 'day',
};

export default memo(DataRangeModal);
