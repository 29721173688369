// Core
import React from 'react';

function FileJpegIcon(props) {
  return (
    <svg width="29" height="38" viewBox="0 0 29 38" fill="none" fontSize="inherit" {...props}>
      <g clipPath="url(#clip0_1905_329)">
        <path d="M21.025 0L29 9.12V36.86C29 37.4908 28.4887 38 27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V1.14C0 0.5092 0.511316 0 1.14474 0H21.025Z" fill="#EBE8E1" />
        <path d="M21.025 0V8.74L29 9.12L21.025 0Z" fill="#CCCABE" />
        <path d="M27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V26.22H29V36.86C29 37.4908 28.4887 38 27.8553 38Z" fill="#4D9E57" />
        <path d="M6.64844 29.2734H7.80078V32.8984C7.80078 33.3724 7.75911 33.737 7.67578 33.9922C7.5638 34.3255 7.36068 34.5938 7.06641 34.7969C6.77214 34.9974 6.38411 35.0977 5.90234 35.0977C5.33724 35.0977 4.90234 34.9401 4.59766 34.625C4.29297 34.3073 4.13932 33.8424 4.13672 33.2305L5.22656 33.1055C5.23958 33.4336 5.28776 33.6654 5.37109 33.8008C5.49609 34.0065 5.6862 34.1094 5.94141 34.1094C6.19922 34.1094 6.38151 34.0365 6.48828 33.8906C6.59505 33.7422 6.64844 33.4362 6.64844 32.9727V29.2734Z" fill="white" />
        <path d="M9.03516 35V29.2734H10.8906C11.5938 29.2734 12.0521 29.3021 12.2656 29.3594C12.5938 29.4453 12.8685 29.6328 13.0898 29.9219C13.3112 30.2083 13.4219 30.5794 13.4219 31.0352C13.4219 31.3867 13.3581 31.6823 13.2305 31.9219C13.1029 32.1615 12.9401 32.3503 12.7422 32.4883C12.5469 32.6237 12.3477 32.7135 12.1445 32.7578C11.8685 32.8125 11.4688 32.8398 10.9453 32.8398H10.1914V35H9.03516ZM10.1914 30.2422V31.8672H10.8242C11.2799 31.8672 11.5846 31.8372 11.7383 31.7773C11.8919 31.7174 12.0117 31.6237 12.0977 31.4961C12.1862 31.3685 12.2305 31.2201 12.2305 31.0508C12.2305 30.8424 12.1693 30.6706 12.0469 30.5352C11.9245 30.3997 11.7695 30.3151 11.582 30.2812C11.444 30.2552 11.1667 30.2422 10.75 30.2422H10.1914Z" fill="white" />
        <path d="M14.3711 35V29.2734H18.6172V30.2422H15.5273V31.5117H18.4023V32.4766H15.5273V34.0352H18.7266V35H14.3711Z" fill="white" />
        <path d="M22.3711 32.8945V31.9297H24.8633V34.2109C24.6211 34.4453 24.2695 34.6523 23.8086 34.832C23.3503 35.0091 22.8854 35.0977 22.4141 35.0977C21.8151 35.0977 21.293 34.9727 20.8477 34.7227C20.4023 34.4701 20.0677 34.1107 19.8438 33.6445C19.6198 33.1758 19.5078 32.6667 19.5078 32.1172C19.5078 31.5208 19.6328 30.9909 19.8828 30.5273C20.1328 30.0638 20.4987 29.7083 20.9805 29.4609C21.3477 29.2708 21.8047 29.1758 22.3516 29.1758C23.0625 29.1758 23.6172 29.3255 24.0156 29.625C24.4167 29.9219 24.6745 30.3333 24.7891 30.8594L23.6406 31.0742C23.5599 30.793 23.4076 30.5716 23.1836 30.4102C22.9622 30.2461 22.6849 30.1641 22.3516 30.1641C21.8464 30.1641 21.444 30.3242 21.1445 30.6445C20.8477 30.9648 20.6992 31.4401 20.6992 32.0703C20.6992 32.75 20.8503 33.2604 21.1523 33.6016C21.4544 33.9401 21.8503 34.1094 22.3398 34.1094C22.582 34.1094 22.8242 34.0625 23.0664 33.9688C23.3112 33.8724 23.5208 33.7565 23.6953 33.6211V32.8945H22.3711Z" fill="white" />
        <g clipPath="url(#clip1_1905_329)">
          <path d="M13.002 14.5C13.002 14.8978 12.844 15.2794 12.5627 15.5607C12.2814 15.842 11.8998 16 11.502 16C11.1042 16 10.7227 15.842 10.4414 15.5607C10.16 15.2794 10.002 14.8978 10.002 14.5C10.002 14.1022 10.16 13.7206 10.4414 13.4393C10.7227 13.158 11.1042 13 11.502 13C11.8998 13 12.2814 13.158 12.5627 13.4393C12.844 13.7206 13.002 14.1022 13.002 14.5Z" fill="#303030" fillOpacity="0.72" />
          <path d="M8.5 11C8.10218 11 7.72064 11.158 7.43934 11.4393C7.15804 11.7206 7 12.1022 7 12.5V21.5C7 21.8978 7.15804 22.2794 7.43934 22.5607C7.72064 22.842 8.10218 23 8.5 23H21.5C21.8978 23 22.2794 22.842 22.5607 22.5607C22.842 22.2794 23 21.8978 23 21.5V12.5C23 12.1022 22.842 11.7206 22.5607 11.4393C22.2794 11.158 21.8978 11 21.5 11H8.5ZM21.5 12C21.6326 12 21.7598 12.0527 21.8536 12.1464C21.9473 12.2402 22 12.3674 22 12.5V18.5L18.225 16.553C18.1312 16.506 18.025 16.4897 17.9215 16.5064C17.8179 16.5231 17.7223 16.5719 17.648 16.646L13.938 20.356L11.278 18.584C11.182 18.5201 11.0668 18.4913 10.9519 18.5026C10.8371 18.5139 10.7297 18.5646 10.648 18.646L8.002 21V21.54L8 21.5V12.5C8 12.3674 8.05268 12.2402 8.14645 12.1464C8.24021 12.0527 8.36739 12 8.5 12H21.5Z" fill="#303030" fillOpacity="0.72" />
        </g>
      </g>
      <path d="M6.64844 29.2734H7.80078V32.8984C7.80078 33.3724 7.75911 33.737 7.67578 33.9922C7.5638 34.3255 7.36068 34.5938 7.06641 34.7969C6.77214 34.9974 6.38411 35.0977 5.90234 35.0977C5.33724 35.0977 4.90234 34.9401 4.59766 34.625C4.29297 34.3073 4.13932 33.8424 4.13672 33.2305L5.22656 33.1055C5.23958 33.4336 5.28776 33.6654 5.37109 33.8008C5.49609 34.0065 5.6862 34.1094 5.94141 34.1094C6.19922 34.1094 6.38151 34.0365 6.48828 33.8906C6.59505 33.7422 6.64844 33.4362 6.64844 32.9727V29.2734Z" fill="white" />
      <path d="M9.03516 35V29.2734H10.8906C11.5938 29.2734 12.0521 29.3021 12.2656 29.3594C12.5938 29.4453 12.8685 29.6328 13.0898 29.9219C13.3112 30.2083 13.4219 30.5794 13.4219 31.0352C13.4219 31.3867 13.3581 31.6823 13.2305 31.9219C13.1029 32.1615 12.9401 32.3503 12.7422 32.4883C12.5469 32.6237 12.3477 32.7135 12.1445 32.7578C11.8685 32.8125 11.4688 32.8398 10.9453 32.8398H10.1914V35H9.03516ZM10.1914 30.2422V31.8672H10.8242C11.2799 31.8672 11.5846 31.8372 11.7383 31.7773C11.8919 31.7174 12.0117 31.6237 12.0977 31.4961C12.1862 31.3685 12.2305 31.2201 12.2305 31.0508C12.2305 30.8424 12.1693 30.6706 12.0469 30.5352C11.9245 30.3997 11.7695 30.3151 11.582 30.2812C11.444 30.2552 11.1667 30.2422 10.75 30.2422H10.1914Z" fill="white" />
      <path d="M14.3711 35V29.2734H18.6172V30.2422H15.5273V31.5117H18.4023V32.4766H15.5273V34.0352H18.7266V35H14.3711Z" fill="white" />
      <path d="M22.3711 32.8945V31.9297H24.8633V34.2109C24.6211 34.4453 24.2695 34.6523 23.8086 34.832C23.3503 35.0091 22.8854 35.0977 22.4141 35.0977C21.8151 35.0977 21.293 34.9727 20.8477 34.7227C20.4023 34.4701 20.0677 34.1107 19.8438 33.6445C19.6198 33.1758 19.5078 32.6667 19.5078 32.1172C19.5078 31.5208 19.6328 30.9909 19.8828 30.5273C20.1328 30.0638 20.4987 29.7083 20.9805 29.4609C21.3477 29.2708 21.8047 29.1758 22.3516 29.1758C23.0625 29.1758 23.6172 29.3255 24.0156 29.625C24.4167 29.9219 24.6745 30.3333 24.7891 30.8594L23.6406 31.0742C23.5599 30.793 23.4076 30.5716 23.1836 30.4102C22.9622 30.2461 22.6849 30.1641 22.3516 30.1641C21.8464 30.1641 21.444 30.3242 21.1445 30.6445C20.8477 30.9648 20.6992 31.4401 20.6992 32.0703C20.6992 32.75 20.8503 33.2604 21.1523 33.6016C21.4544 33.9401 21.8503 34.1094 22.3398 34.1094C22.582 34.1094 22.8242 34.0625 23.0664 33.9688C23.3112 33.8724 23.5208 33.7565 23.6953 33.6211V32.8945H22.3711Z" fill="white" />
      <defs>
        <clipPath id="clip0_1905_329">
          <rect width="29" height="38" fill="white" />
        </clipPath>
        <clipPath id="clip1_1905_329">
          <rect width="16" height="16" fill="white" transform="translate(7 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FileJpegIcon;
