import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import { COLOR } from '../../../containers/App/AppStyles';

const successColor = green[500];

const styles = theme => ({
  MuiButtonBaseRoot: {
    padding: 5,
  },
  onlyShowTextArea: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: 50,
  },
  select: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    '& .MuiInputBase-root': {
      minHeight: '32px',
      height: '32px',
      marginLeft: '0 !important',
      '& .MuiChip-root': {
        height: '26px',
        margin: '1.5px 2px',
      },
      '& .select-tags__single-value,': {
        padding: 0,
        marginLeft: '11px',
      },
      '& .select-tags__value-container': {
        padding: 0,
      },
      '& .MuiInputBase-input': {
        height: '32px !important',
        '& .select-tags__value-container': {
          padding: '2px 0',
        },
      },
    },
  },
  fs12: { fontSize: 12 },
  selectSuccess: {
    '@global': {
      button: {
        color: successColor,
        border: `2px solid ${successColor}`,
        '&:hover': {
          border: '2px solid',
        },
      },
      div: {
        color: successColor,
      },
      fieldset: {
        borderColor: `${successColor} !important`,
      },
      textarea: {
        border: `1px solid ${successColor}`,
        color: successColor,
      },
    },
  },
  chip: {
    height: 20,
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5,
    width: 'fit-content',
    backgroundColor: '#5285CC',
    display: 'flex',
  },
  chipSelf: {
    height: 20,
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5,
    width: 'fit-content',
    backgroundColor: '#E00027',
    display: 'flex',
  },
  chipLabel: {
    paddingLeft: 0,
    paddingRight: 0,
    color: '#fff',
    fontSize: '12px',
  },
  chipLabelSelf: {
    paddingLeft: 0,
    paddingRight: 0,
    color: '#fff',
    fontSize: '12px',
  },
  selectError: {
    '@global': {
      div: {
        color: red[500],
      },
      button: {
        border: `1px solid ${red[500]} !important`,
      },
      fieldset: {
        border: `1px solid ${red[500]} !important`,
      },
      textarea: {
        border: `1px solid ${red[500]} !important`,
      },
    },
  },
  selectWidthConfirmWrap: {
    marginRight: '24px',
    padding: '2px',
    backgroundColor: '#fff',
    gap: '2px',
    '& .MuiButtonGroup-grouped': {
      minWidth: '24px',
    },
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      borderRight: 'none !important',
    },
    '& .MuiButtonGroup-grouped:not(:first-of-type)': {
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
    },
  },
  selectWidthConfirm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    width: '100%',
  },
  selectWidthConfirmClose: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    borderRadius: '3px',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(48, 48, 48, 0.08)',
    },
    '& svg': {
      fill: '#303030b8',
    },
  },
  selectWidthConfirmCheck: {
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(48, 48, 48, 0.08)',
    },
    '& svg': {
      fill: '#5285CC',
    },
  },
  selectWidthConfirmDisable: {
    '& svg': {
      fill: COLOR.inactive,
    },
  },
  selectWidthConfirmRemove: {
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(48, 48, 48, 0.08)',
    },
    '& svg': {
      fill: '#E00027',
    },
  },
  selectWidthConfirmShow: {
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(48, 48, 48, 0.08)',
    },
    '& svg': {
      fill: '#5285CC',
      padding: '0px 3px',
    },
  },
  selectWidthConfirmShowLog: {
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(48, 48, 48, 0.08)',
    },
    '& svg': {
      fill: '#303030b8',
      padding: '0px 3px',
    },
  },
});

export default styles;
