// Core
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
// Parts
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import LoaderComponent from '../../../../../_Form/Selects/FormSelect/components/LoaderComponent';

const styles = () => ({
  photo: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgWrapper: {
    width: '100%',
    cursor: 'pointer',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPreview: {
    minWidth: 34,
    minHeight: 36,
    objectFit: 'cover',
  },
  photoHidden: {
    display: 'none',
    visibility: 'hidden',
  },
  counterPhoto: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  photoInTooltip: {
    padding: 0,
    lineHeight: 0,
    boxShadow: '0px 0px 18px 0px rgba(0,0,0,0.5)',
  },
  tooltipImg: { maxHeight: '200px', maxWidth: '400px' },
});

const useStyles = makeStyles(styles);

const PhotoItem = (Props) => {
  const {
    image, index, length, offTooltip, alloImage, onError,
  } = Props;
  const classes = useStyles();
  const [imageLink, setImageLink] = useState(image);
  const [loading, setLoading] = useState(true);
  const photoClassNames = cx('gallery-item', classes.photo, {
    [classes.photoHidden]: index !== 0,
  });

  const handleError = () => {
    if (alloImage) {
      setImageLink(alloImage);
    }
    if (onError) {
      onError();
    }
    if (alloImage === imageLink || !alloImage) {
      setLoading(false);
    }
  };

  const handleLoad = () => {
    setLoading(false);
  };

  const dataSources = JSON.stringify([{ srcset: image }, { srcset: alloImage }]);

  return (
    <a
      data-src={imageLink}
      className={photoClassNames}
      data-sources={dataSources}
    >
      <Tooltip
        placement="right"
        open={offTooltip === true ? !offTooltip : undefined}
        classes={{
          tooltip: classes.photoInTooltip,
        }}
        title={<img alt="" className={classes.tooltipImg} height={200} src={imageLink} />}
      >
        <span className={classes.imgWrapper}>
          {loading && (<LoaderComponent />)}
          <img
            alt=""
            height={36}
            width={34}
            src={imageLink}
            className={classes.photoPreview}
            onError={handleError}
            onLoad={handleLoad}
            style={{ display: !loading ? 'block' : 'none' }}
          />
        </span>
      </Tooltip>
      <b className={classes.counterPhoto}>{length} Фото </b>
    </a>
  );
};

export default PhotoItem;
