// Core
import Chip from '@mui/material/Chip';
import React, { useState, useEffect, useCallback } from 'react';
import {
  reduxForm, Field, Form, FieldArray,
} from 'redux-form/immutable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router';
import isNil from 'lodash/isNil';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { List, Map } from 'immutable';
// Icons
import SaveIcons from '@mui/icons-material/Save';
import CallMissedIcon from '@mui/icons-material/CallMissed';
// Parts
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteButton from '../../Settings/Users/components/DeleteButton';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import UserPartnerRolesField from '../components/UserPartnerRolesField/UserPartnerRolesField';
import Loading from '../../../components/Loading/Loading';
import FormSelect from '../../../components/_Form/Selects/FormSelect/FormSelect';
import Checkbox from '../../../components/_Form/Checkbox/Checkbox';
import PartnerSelect from '../../../components/_Form/Selects/PartnerSelect';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import SectionHolder from '../../../containers/SectionHolder/SectionHolder';
// Engine
import { userActionAsync } from '../../../../engine/core/user/saga/asyncAction';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import { selectors } from '../../../../engine/config/selectors';
import i18n, { locale } from '../../../../engine/init/i18n';
import { accessList } from '../../../../engine/config/access';
import { userRoles } from '../../../../engine/config/userRoles';
import { pageLinks } from '../../../../engine/config/routes';
import { userActions } from '../../../../engine/core/user/action';
// Helpers
import UserByHashIdStyle from './UserByHashIdStyle';
import { useStyles } from '../../../hooks/useStyles';
import { useUserRole } from '../../../hooks/useUserRole';
import { validators, phoneMask } from '../../../../_helpers/validationsForm';
import AdditionalEmailGroupsFields from '../components/AdditionalEmailGroupField/AdditionalEmailGroupsFields';

export const formName = 'settingsUserAddForm';

function ProfileUser(props) {
  const classes = useStyles(UserByHashIdStyle);
  const { hashId } = useParams();

  const {
    getUserByHashIdAsync, clearUserByHashId, getUsersRolesAsync,
    getPartnerListAsync, handleSubmit, usersRoles, isLoading, userAddPending,
    putUsersHashIdPending, userPartnerRolesPending, isUserByHashIdData, hasAccessEdit,
    rolesList, hasAccessPartnerContextList, partnerList, postUsersAsync, putUserByHashIdAsync,
  } = props;

  const {
    canEdit, canDelete, id, canEditRoles, isCurrentUser, super: isSuper, role: userRole,
  } = isUserByHashIdData;
  const { t } = useTranslation();
  const editMode = (!!hashId && typeof hashId === 'string');
  const disabledAllInputs = canEdit === false && editMode;
  const isPending = userPartnerRolesPending || isLoading || userAddPending || putUsersHashIdPending;
  const partnerRoleValue = 1;
  const isAdminRole = useUserRole(userRoles.admin);
  const [roleValue, setRoleValue] = useState();
  const isRequiredTel = rolesList.includes(userRoles.admin);

  useEffect(() => {
    if (userRole?.value) {
      setRoleValue(userRole.value);
    }
  }, [userRole]);

  useEffect(() => {
    if (hashId) {
      getUserByHashIdAsync(hashId);
    }
    getUsersRolesAsync();
  }, [getUserByHashIdAsync, getUsersRolesAsync, hashId]);

  useEffect(() => {
    if (hasAccessPartnerContextList && roleValue === partnerRoleValue) {
      getPartnerListAsync();
    }
  }, [getPartnerListAsync, hasAccessPartnerContextList, roleValue]);

  useEffect(() => () => {
    clearUserByHashId();
  }, [clearUserByHashId]);

  const onFormSubmit = (formData, redirect) => {
    if (formData && formData.toJS) {
      const userData = formData.toJS();
      const groupsData = userData[`${formName}Groups`];
      const additionalEmails = userData[`${formName}AdditionalEmails`];
      const fixedAddEmails = isNil(additionalEmails) ? [] : additionalEmails.map(item => item.value);

      const userInfo = {
        fullName: userData[`${formName}FullName`]?.trim(),
        email: userData[`${formName}Email`],
        password: userData[`${formName}Password`],
        phone: userData[`${formName}Phone`],
        role: userData[`${formName}Role`],
        enabled: userData[`${formName}Enabled`],
        locale: userData[`${formName}Locale`],
        partner: userData[`${formName}Partner`]?.value,
        additionalEmails: fixedAddEmails,
        groups: isNil(groupsData) ? [] : groupsData,
      };
      if (roleValue === partnerRoleValue || userData[`${formName}Role`] === partnerRoleValue) {
        userInfo.partnerRoles = userData[`${formName}partnerRoles`];
      }
      if (userData[`${formName}Phone`] && userData[`${formName}Phone`].length <= 3) {
        userInfo.phone = '';
      }
      if (hashId && (typeof hashId === 'string')) {
        putUserByHashIdAsync({ hashId, formData: userInfo, redirect });
      } else {
        postUsersAsync({ userInfo, redirect });
      }
    }
  };

  const RenderLangRadio = useCallback((fieldRenderProps) => {
    const { input, disabled, ...rest } = fieldRenderProps;
    return (
      <RadioGroup aria-label="position" row {...input} {...rest}>
        <FormControlLabel
          value={locale.ua}
          disabled={disabled}
          control={<Radio color="primary" />}
          label="Українська"
          labelPlacement="end"
        />
        <FormControlLabel
          value={locale.ru}
          disabled={disabled}
          control={<Radio color="primary" />}
          label="Русский"
          labelPlacement="end"
        />
      </RadioGroup>
    );
  }, []);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container xs={12}>
        <PageHeader
          pageHeadingClassName={classes.pageHeaderWrap}
          pageHeadingComponentClassName={classes.pageHeaderComponentWrap}
          wrapperClassName={classes.headerWrapper}
          title={
            editMode
              ? t('Редактировать пользователя')
              : t('Добавить пользователя')
          }
          titleComponent={(
            <div className={classes.chipWrapper}>
              {isCurrentUser && !isLoading && (
                <Chip
                  color="secondary"
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                  label={t('Это вы')}
                />
              )}
              {isSuper && (
                <Chip
                  color="primary"
                  classes={{
                    root: classes.chipRootMarginLeft,
                    label: classes.chipLabel,
                  }}
                  label={t('Руководитель')}
                />
              )}
            </div>
          )}
        >
          <ButtonGoBack defaultPage={pageLinks.settings.users.all} />
          {canDelete && (
            <DeleteButton
              userIds={[id]}
              redirectTo={pageLinks.settings.users.all}
              pending={isPending}
            />
          )}
          {!disabledAllInputs && hasAccessEdit && (
            <>
              <ButtonWithIconAndCircularProgress
                text={t('Сохранить и вернуться')}
                isLoading={isPending}
                disabled={isPending}
                onClick={handleSubmit(value => onFormSubmit(value, true))}
                component="button"
                type="button"
              >
                <CallMissedIcon />
              </ButtonWithIconAndCircularProgress>
              {editMode && (
                <ButtonWithIconAndCircularProgress
                  text={t('Сохранить')}
                  isLoading={isPending}
                  disabled={isPending}
                  onClick={handleSubmit(value => onFormSubmit(value, false))}
                  component="button"
                  type="button"
                >
                  <SaveIcons />
                </ButtonWithIconAndCircularProgress>
              )}
            </>
          )}
        </PageHeader>
        <SectionHolder>
          <Grid container justifyContent="space-between" className={classes.tabsContent} spacing={3}>
            {isLoading ? (
              <Grid container justifyContent="center" alignContent="center">
                <Loading isLoading={isLoading} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name={`${formName}FullName`}
                    component={TextField}
                    fullWidth
                    validation
                    required
                    label={t('Имя')}
                    disabled={disabledAllInputs}
                    validate={[validators.required]}
                  />
                  <Field
                    name={`${formName}Email`}
                    component={TextField}
                    fullWidth
                    required
                    validation
                    label={t('Email')}
                    disabled={disabledAllInputs}
                    className={classes.textField}
                    margin="normal"
                    validate={[validators.required, validators.email]}
                  />
                  <FieldArray disabled={disabledAllInputs} name={`${formName}AdditionalEmails`} component={AdditionalEmailGroupsFields} />
                  <Field
                    name={`${formName}Phone`}
                    component={TextField}
                    fullWidth
                    validation
                    label={t('Телефон')}
                    disabled={disabledAllInputs}
                    className={classes.textField}
                    margin="normal"
                    type="tel"
                    required={!isRequiredTel}
                    {...phoneMask}
                    validate={
                      isRequiredTel
                        ? []
                        : [validators.required]
                    }
                  />
                  {(isAdminRole && !isCurrentUser) && (
                    <FormControl component="fieldset" className={classes.status}>
                      <Field
                        component={Checkbox}
                        name={`${formName}Enabled`}
                        label={t('Статус пользователя')}
                        checkboxLabelFalse={t('Отключен')}
                        checkboxLabelTrue={t('Включен')}
                        disabled={disabledAllInputs}
                        margin="none"
                      />
                    </FormControl>
                  )}
                  {!isCurrentUser && (
                    <FormControl component="fieldset" className={classes.lang}>
                      <FormLabel component="legend" className={classes.langLabel}>{t('Язык')}</FormLabel>
                      <Field
                        component={RenderLangRadio}
                        name={`${formName}Locale`}
                        disabled={disabledAllInputs}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name={`${formName}Role`}
                    component={FormSelect}
                    handleChange={setRoleValue}
                    label={t('Роль')}
                    hidden={rolesList.includes(userRoles.partnerActive)}
                    options={usersRoles}
                    selectFirst={usersRoles.length === 1}
                    disabled={usersRoles.length === 1 || disabledAllInputs || canEditRoles === false}
                    formSelectWidth="100%"
                    validation
                    required
                    validate={[validators.required]}
                  />
                  {roleValue === partnerRoleValue && (
                    <>
                      <Field
                        fullWidth
                        margin={rolesList.includes(userRoles.partnerActive) ? 'none' : 'normal'}
                        selectFirst={usersRoles.length === 1 && partnerList.size === 1}
                        disabled={!hasAccessPartnerContextList || (usersRoles.length === 1 && partnerList.size === 1) || disabledAllInputs}
                        component={PartnerSelect}
                        name={`${formName}Partner`}
                        data={partnerList}
                      />
                      <UserPartnerRolesField
                        formName={formName}
                        disabled={disabledAllInputs || canEditRoles === false}
                        name={`${formName}partnerRoles`}
                        namePartnerField={`${formName}Partner`}
                        fullWidth
                        required
                        validate={[validators.itemsRequiredValidator]}
                      />
                    </>
                  )}
                  <Field
                    name={`${formName}Password`}
                    component={TextField}
                    fullWidth
                    required
                    validation
                    label={t('Пароль')}
                    disabled={disabledAllInputs}
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    validate={
                      editMode
                        ? [validators.editPasswordValidator]
                        : [validators.required, validators.password]
                    }
                  />
                  <Field
                    name={`${formName}CPassword`}
                    component={TextField}
                    fullWidth
                    validation
                    label={t('Повторить пароль')}
                    disabled={disabledAllInputs}
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    validate={[validators.confirmField(`${formName}Password`)]}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </SectionHolder>
      </Grid>
    </Form>
  );
}

ProfileUser.defaultProps = {
  hasAccessPartnerContextList: false,
  rolesList: [],
  partnerList: List(),
};

ProfileUser.propTypes = {
  handleSubmit: PropTypes.func,
  clearUserByHashId: PropTypes.func,
  hasAccessPartnerContextList: PropTypes.bool,
  userAddPending: PropTypes.bool,
  putUsersHashIdPending: PropTypes.bool,
  hasAccessEdit: PropTypes.bool,
  postUsersAsync: PropTypes.func.isRequired,
  getUsersRolesAsync: PropTypes.func.isRequired,
  usersRoles: PropTypes.array.isRequired,
  getUserByHashIdAsync: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userPartnerRolesPending: PropTypes.bool,
  isUserByHashIdData: PropTypes.object.isRequired,
  putUserByHashIdAsync: PropTypes.func.isRequired,
  role: PropTypes.number,
  getPartnerListAsync: PropTypes.func.isRequired,
  partnerList: PropTypes.instanceOf(List),
  userPartnerRoles: PropTypes.instanceOf(Map),
  rolesList: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {
    postUsersAsync: params => dispatch(userActionAsync.postUsersAsync(params)),
    getUsersRolesAsync: () => dispatch(userActionAsync.getUsersRolesAsync()),
    getUserByHashIdAsync: hashId => dispatch(userActionAsync.getUserByHashIdAsync(hashId)),
    putUserByHashIdAsync: params => dispatch(userActionAsync.putUserByHashIdAsync(params)),
    getPartnerListAsync: () => dispatch(partnerAsyncAction.getPartnerContextListAsync()),
    clearUserByHashId: () => dispatch(userActions.mergeInUserByHashId({ pending: false, user: {} })),
  };
}
function mapStateToProps(state) {
  return {
    hasAccessPartnerContextList: selectors.user.accessList(state).includes(accessList.partnerContextList),
    usersRoles: selectors.user.userRoles(state),
    userAddPending: selectors.user.userAddPending(state),
    putUsersHashIdPending: selectors.user.putUsersHashIdPending(state),
    rolesList: selectors.user.rolesList(state),
    isLoading: selectors.user.isUserByHashIdLoading(state),
    isUserByHashIdData: selectors.user.isUserByHashIdData(state),
    hasAccessEdit: selectors.user.accessList(state).includes(accessList.userEdit),
    userPartnerRolesPending: selectors.user.userPartnerRolesPending(state),
    partnerList: selectors.partner.listContext(state),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    initialValues: {
      [`${formName}Locale`]: locale.ua,
    },
    validate: (values, data) => {
      const isAdmin = data?.rolesList?.includes(userRoles.admin);
      const valuePhone = values.get(`${formName}Phone`);
      const adminNotValidPhone = valuePhone?.length < 12 && valuePhone?.length > 3;
      const partnerNotValidPhone = !isAdmin && valuePhone?.length < 12;
      return adminNotValidPhone || partnerNotValidPhone
        ? { [`${formName}Phone`]: i18n.t('Не валидный номер телефона') }
        : {};
    },
  }),
)(ProfileUser);
