// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const mediaAction = Object.freeze({
  setMediaCropperModalShow(data) {
    return getActionCreator(types.SET_MEDIA_CROPPER_MODAL_SHOW, data);
  },
  setMediaUploadModalPending(data) {
    return getActionCreator(types.SET_MEDIA_UPLOAD_MODAL_PENDING, data);
  },
  setMediaUploadFilesPending(data) {
    return getActionCreator(types.SET_MEDIA_UPLOAD_FILES_PENDING, data);
  },
  setInMediaRequestFiles(formName, name, link, status, id, hashId) {
    return getActionCreator(types.SET_IN_MEDIA_REQUEST_FILES, {
      formName, name, link, status, id, hashId,
    });
  },
});
