// Core
import React from 'react';
// Parts
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
import { FocusComponent } from '../../../../Content/ContentProductEdit/components/RenderWeightFormField';
// Helpers
import { EndComponent } from '../styles';
import { onChangeWithValidateWeight } from '../../../../Content/ContentProductEdit/utils/validateWeight';

function RenderWeightFormField(props) {
  const {
    margin, input, meta,
    disabled, label, wrapperClassName,
  } = props;
  const currentError = Boolean(meta.touched) && meta.error && !meta.active
    ? meta.error
    : undefined;

  const onChange = (e) => {
    onChangeWithValidateWeight(e, input.onChange);
  };
  const inputProps = {
    ...input,
    onChange,
  };

  const handleBlur = () => {
    if (input.value.endsWith('.')) {
      input.onChange(input.value.substring(0, input.value.length - 1));
    }
  };

  return (
    <TextField
      meta={meta}
      fullWidth
      helperTextAfter={currentError}
      isError={currentError}
      disabled={disabled}
      required={meta.error}
      input={inputProps}
      label={label}
      margin={margin}
      focusComponent={<FocusComponent />}
      wrapperClassName={wrapperClassName}
      endAdornment={<EndComponent component="span">{' кг'}</EndComponent>}
      customOnBlur={handleBlur}
      inputmode="decimal"
    />
  );
}

export default RenderWeightFormField;
