// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { reset } from 'redux-form';
import {
  Form, Field, reduxForm, formValueSelector,
} from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import SaveIcons from '@mui/icons-material/Save';
// Parts
import Modal from '../../../../../containers/Modals/Modal/Modal';
import FilesUploader from '../../../../../components/_Form/FilesUploader/FilesUploader';
import ImportResultXlsxBlock from './ImportResult/ImportResultXlsxBlock';
import FormHelperTextComponent from '../../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';
// Engine
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';
import { validators } from '../../../../../../_helpers/validationsForm';
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';
import { asyncActions } from '../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/content/actions';
// Helpers
import ModalStyle from '../../../../../containers/Modals/Modal/ModalStyle';
import { usePrevious } from '../../../../../hooks/usePrevious';
import { useStyles } from '../../../../../hooks/useStyles';

export const form = Object.freeze({
  name: 'ProductsPropertiesImportModal',
  fields: Object.freeze({
    productsFile: 'productsFile',
  }),
});
const selector = formValueSelector(form.name);

function ProductsPropertiesImportModal(props) {
  const {
    handleModalToggle, handleSubmit, pending,
    modalOpen, pristine, valid, submitting,
  } = props;
  const classes = useStyles(ModalStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessProductImport = useAccessList(accessList.productImport);
  const importModal = useSelector(selectors.content.importPropertiesModal);
  const productsImportPending = useSelector(selectors.content.productsImportPending);
  const feedproductsFile = useSelector(state => selector(state, form.fields.productsFile));
  const prevModal = usePrevious(modalOpen);

  useEffect(() => {
    if (hasAccessProductImport) {
      if (!modalOpen) {
        dispatch(actions.setProductsImportModalInitialState());
      }
      if (prevModal === true && modalOpen === false) {
        dispatch(reset(form.name));
      }
    }
  }, [hasAccessProductImport, modalOpen, dispatch, prevModal]);

  const onFormSubmit = (values) => {
    const { productsFile } = values.toJS();
    dispatch(asyncActions.postProductsPropertiesImportAsync({ productsFile }));
  };

  if (!hasAccessProductImport) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      addText={t('Сохранить')}
      iconSendButton={<SaveIcons />}
      disabledSend={pristine || submitting || productsImportPending || !valid || !feedproductsFile || !feedproductsFile.length}
      title={t('Импорт свойств товаров')}
      loadingSend={productsImportPending}
      handleModalAdd={handleSubmit}
      modalMaxWidth="xs"
      fullScreen={false}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={renderFilesUploader}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.productsFile}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
        <ImportResultXlsxBlock classes={classes} importModal={importModal} />
      </Form>
    </Modal>
  );
}

function renderFilesUploader(fieldRenderProps) {
  const {
    meta: { touched, error }, disabled, ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

ProductsPropertiesImportModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleModalToggle: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
};

ProductsPropertiesImportModal.defaultProps = {
  pristine: false,
  submitting: false,
  pending: false,
  handleSubmit: () => {},
};

export default reduxForm({
  form: form.name,
})(ProductsPropertiesImportModal);
