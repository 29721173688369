// Core
import React, { useCallback, useEffect, useState } from 'react';
import Timer from 'react-timer-wrapper';
import { isNaN } from 'lodash';
import {
  ms, m, h,
} from 'time-convert';
import * as PropTypes from 'prop-types';
import moment from 'moment';

// UI
import Box from '@mui/material/Box';
import cx from 'classnames';
import TooltipIcon from '../../containers/TooltipIcon';
import InfoFilledIcon from '../../icons/InfoFilled';
import ShutdownIcon from '../../icons/Shutdown';

import { useStyles } from '../../hooks/useStyles';
import TimerCountCardStyles from './styles';


function TimerCountCard(props) {
  const {
    start, end,
    onEnd, endText, endDesc,
    desc, onDescClickAfterEndTimer, isInModal,
  } = props;
  const [startDuration, setStartDuration] = useState(moment(end).valueOf() - moment(start).valueOf());
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const timeToMilliSeconds = duration - time;
  const classes = useStyles(TimerCountCardStyles);

  const onTimerUpdate = useCallback(({ time: currentTime, duration: currentDuration }) => {
    setTime(currentTime);
    setDuration(currentDuration);
  }, []);

  const onFinish = useCallback(() => {
    if (onEnd) {
      onEnd();
    }
  }, [onEnd]);

  useEffect(() => {
    setStartDuration(moment(end).valueOf() - moment(start).valueOf());
  }, [end, start]);

  const msToHm = milliseconds => (
    ms.to(h, m)(milliseconds).map(n => n < 10 ? `0${n}` : n.toString()).join(':')
  );
  const currentTimer = msToHm(timeToMilliSeconds).split(':');
  const hh = currentTimer[0];
  const mm = currentTimer[1];

  return (
    <Timer active className={classes.timer} component="span" duration={startDuration || 0} key={startDuration} onTimeUpdate={onTimerUpdate} onFinish={onFinish}>
      <ShutdownIcon className={classes.shutdownIcon} />
      <Box className={classes.timerCountRow}>
        <span className={cx(classes.timerValue, { [classes.timerValueInModal]: isInModal })}>
          {timeToMilliSeconds > 1000
            ? <>{hh}<strong className="blink_me">:</strong>{mm}</>
            : endText
        }
        </span>
        {desc && (
          <TooltipIcon text={timeToMilliSeconds > 1000 ? desc : endDesc} onTooltipClick={(timeToMilliSeconds < 0 || isNaN(timeToMilliSeconds)) ? onDescClickAfterEndTimer : undefined}>
            <div><InfoFilledIcon className={classes.infoIcon} /></div>
          </TooltipIcon>
        )}
      </Box>
    </Timer>
  );
}

TimerCountCard.defaultProps = {
  endText: '00:00',
};
TimerCountCard.propTypes = {
  desc: PropTypes.string,
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  endText: PropTypes.string,
  endDesc: PropTypes.string,
  onEnd: PropTypes.func,
  onDescClickAfterEndTimer: PropTypes.func,
  isInModal: PropTypes.bool,
};

export default TimerCountCard;
