import FilesUploader from '../../../../../../../components/_Form/FilesUploader/FilesUploader';
import FormHelperTextComponent
  from '../../../../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';

function RenderFilesUploader(fieldRenderProps) {
  const {
    meta: { touched, error }, disabled, ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

export default RenderFilesUploader;
