import { getIn, List } from 'immutable';
import { stateKeys as billingPartnersStatusKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const billingPartnersStatus = Object.freeze({
  currentPage: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.currentPage], 0),
  filters: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.filters], List()),
  isLoading: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.pending], false),
  list: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.items], List()),
  pageSize: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.reload], false),
  totalCount: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.totalCount], 0),
  sorting: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.sorting], List()),
  settingStatus: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.settingStatus], false),
  settingPending: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.settingPending], false),
  conditions: state => getIn(state, [billingPartnersStatusKeys.controller, billingPartnersStatusKeys.partnersStatusList, billingPartnersStatusKeys.conditions], List()),
});
