// Core
import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
// Parts
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
// Helpers
import { useStyles } from '../hooks/useStyles';

const styles = () => ({
  hasError: {
    color: 'rgb(244, 67, 54)',
  },
});

function Label(props) {
  const { label } = props;
  const {
    className, hasError, required,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(styles);

  if (typeof label !== 'string' || label === '') {
    return null;
  }

  const classNames = cx({
    [className]: className,
    [classes.hasError]: hasError,
  });

  return (
    <>
      <span className={classNames}>{label}</span>
      {required && (
        <Tooltip title={t('Обязательное поле')}>
          <Icon color="primary" className={classes.buttonRequired}>*</Icon>
        </Tooltip>
      )}
    </>
  );
}

Label.propTypes = {
  className: string,
  hasError: bool,
  label: string.isRequired,
  required: bool,
};

Label.defaultProps = {
  className: '',
  hasError: false,
  required: false,
};
export default Label;
