import { COLOR } from '../../../../containers/App/AppStyles';

const FormTextAreaStyle = theme => ({
  flexOne: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row !important',
    },
    '@global': {
      'label + .MuiInput-formControl': {
        marginTop: 0,
      },
    },
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  wrapperRequired: {
    '@global': {
      input: {
        paddingRight: 25,
      },
    },
  },
  wrapperRow: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: 10,
  },
  wrapperButton: {
    display: 'flex',
    alignSelf: 'center',
    borderRadius: 3,
    padding: 2,
    boxShadow: COLOR.shadow.block.main,
    marginLeft: 8,
    background: '#ffffff',
  },
  wrapperButtonRequired: {
    paddingRight: 20,
  },
  button: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 3,
    background: COLOR.grey['8'],
    marginLeft: 4,
    '& svg': {
      width: 21,
      height: 21,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      background: COLOR.grey['12'],
    },
  },
  compareButton: {
    color: COLOR.controls.dark,
  },
  compareButtonActive: {
    background: COLOR.controls.dark,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.controls.dark,
      color: '#ffffff',
    },
  },
  checkButton: {
    color: COLOR.success,
  },
  checkButtonActive: {
    background: COLOR.success,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.success,
      color: '#ffffff',
    },
  },
  cancelButton: {
    color: COLOR.error,
  },
  cancelButtonActive: {
    background: COLOR.error,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.error,
      color: '#ffffff',
    },
  },
  buttonRequired: {
    position: 'absolute',
    right: 0,
    top: 18,
    zIndex: 1,
  },
  hasError: {
    color: COLOR.error,
  },
  tooltipHint: {
    marginLeft: '8px',
  },
});

export default FormTextAreaStyle;
