// Core
import { Map } from 'immutable';

// Instruments
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  statisticsList: {
    counters: {
      notificationsAll: 0,
      notificationsNotRead: 0,
      ordersOverdue: 0,
    },
    workingHours: {
      isWorkingHour: false,
      weekdaysFrom: {
        hours: 0,
        minutes: 0,
      },
      weekdaysTo: {
        hours: 0,
        minutes: 0,
      },
      weekendsFrom: {
        hours: 0,
        minutes: 0,
      },
      weekendsTo: {
        hours: 0,
        minutes: 0,
      },
    },
  },
});

export const statisticsKeys = {
  controller: 'statistics',
  statisticsList: 'statisticsList',
  counters: 'counters',
  ordersNew: 'ordersNew',
  supportMessagesNotRead: 'supportMessagesNotRead',
  updatedAt: 'updatedAt',
  brandsLastUpdate: 'brandsLastUpdate',
  ordersOverdue: 'ordersOverdue',
  categoriesLastUpdate: 'categoriesLastUpdate',
  notificationsAll: 'notificationsAll',
  appReactHash: 'appReactHash',
  rolesLastUpdate: 'rolesLastUpdate',
  attributeSetLastUpdate: 'attributeSetLastUpdate',
  productsStatusesLastUpdate: 'productsStatusesLastUpdate',
  userRolesLastUpdate: 'userRolesLastUpdate',
  partnerPaymentTypesLastUpdate: 'partnerPaymentTypesLastUpdate',
  mailTemplateEventsLastUpdate: 'mailTemplateEventsLastUpdate',
  mailTemplateGroupsLastUpdate: 'mailTemplateGroupsLastUpdate',
  faqServiceDescLastUpdate: 'faqServiceDescLastUpdate',
  partnersLastUpdate: 'partnersLastUpdate',
  feedStatusesLastUpdate: 'feedStatusesLastUpdate',
  statusesLastUpdate: 'statusesLastUpdate',
  partnerBalanceLastUpdate: 'partnerBalanceLastUpdate',
  lastUpdates: 'lastUpdates',
  notificationsLastUpdate: 'notificationsLastUpdate',
  notificationsNotRead: 'notificationsNotRead',
  workingHours: 'workingHours',
  isWorkingHour: 'isWorkingHour',
  weekdaysFrom: 'weekdaysFrom',
  weekdaysTo: 'weekdaysTo',
  weekendsFrom: 'weekendsFrom',
  weekendsTo: 'weekendsTo',
  hours: 'hours',
  minutes: 'minutes',
};

export const statisticsReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_STATISTICS_LISTS: {
      const statisticsList = action.payload;
      return state.setIn([statisticsKeys.statisticsList], statisticsList);
    }

    case types.SET_STATISTICS_COUNTER_NOTIFICATION_NOT_READ: {
      const notificationsNotRead = action.payload;
      return state.setIn([statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.notificationsNotRead], notificationsNotRead);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
