// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';
import isUndefined from 'lodash/isUndefined';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
// Helpers
import { getDate } from '../../../../../../_helpers/getDate';

export function* callGetFeedItemByHashIdWorker({ payload: hashId }) {
  // cause to re-render whole page and lost filters -  http://jira.nautilus.allo.ua/browse/AMP-963
  // another possible solution - save filters in localStorage
  // yield put(actions.mergeFeedsItemByHashId({ pending: true }));

  const response = yield apply(api, api.feeds.getFeedsHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { hashId: hashIdItem, ...rest } = response.data;
    const dateAndTimeFields = ['createdAt', 'feedDate', 'doneAt', 'issueAt'];
    dateAndTimeFields.forEach((item) => {
      if (!isUndefined(rest[item])) {
        rest[item] = getDate(rest[item]);
      }
    });
    yield put(actions.mergeFeedsItemByHashId({ item: Map(rest) }));
  }

  yield put(actions.mergeFeedsItemByHashId({ pending: false }));
}
