import { getIn, List, Map } from 'immutable';
import { stateKeys as messagesKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const messages = Object.freeze({
  currentPage: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.currentPage], 0),
  filters: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.filters], List()),
  isLoading: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.pending], false),
  list: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.items], List()),
  pageSize: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.reload], false),
  totalCount: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.totalCount], 0),
  sorting: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.sorting], List()),
  settingStatus: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.settingStatus], false),
  settingPending: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesList, messagesKeys.settingPending], false),
  userGroup: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesUserTypes, messagesKeys.items], List()),
  userGroupPending: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesUserTypes, messagesKeys.pending], false),
  messageDeleteRequestState: state => getIn(state, [messagesKeys.controller, messagesKeys.messageRequestState, messagesKeys.delete], false),
  messageSendRequestState: state => getIn(state, [messagesKeys.controller, messagesKeys.messageRequestState, messagesKeys.send], false),
  messageSaveRequestState: state => getIn(state, [messagesKeys.controller, messagesKeys.messageRequestState, messagesKeys.save], false),
  messageByHashId: state => getIn(state, [messagesKeys.controller, messagesKeys.messageByHashId, messagesKeys.message], Map()),
  isMessageByHashIdLoading: state => getIn(state, [messagesKeys.controller, messagesKeys.messageByHashId, messagesKeys.pending], false),
  messageSentAt: state => getIn(state, [messagesKeys.controller, messagesKeys.messageByHashId, messagesKeys.message, messagesKeys.sentAt], false),
  messageUserTypesByTypeItems: state => getIn(state, [messagesKeys.controller, messagesKeys.messageUserTypesByType, messagesKeys.items], Map()),
  messageUserTypesByTypePending: state => getIn(state, [messagesKeys.controller, messagesKeys.messageUserTypesByType, messagesKeys.pending], false),
  messagesStatuses: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesStatuses, messagesKeys.items], List()),
  messagesStatusesPending: state => getIn(state, [messagesKeys.controller, messagesKeys.messagesStatuses, messagesKeys.pending], false),
  recipientsImportPending: state => getIn(state, [messagesKeys.controller, messagesKeys.recipientsImport, messagesKeys.pending], false),
  recipientsImportItems: state => getIn(state, [messagesKeys.controller, messagesKeys.recipientsImport, messagesKeys.items], Map()),
  isOpenRecipientsImportModal: state => getIn(state, [messagesKeys.controller, messagesKeys.recipientsImport, messagesKeys.isOpenModal], false),
  isOpenPartnersNotFoundModal: state => getIn(state, [messagesKeys.controller, messagesKeys.partnersNotFoundModal, messagesKeys.isOpen], false),
  typePartnersNotFoundModal: state => getIn(state, [messagesKeys.controller, messagesKeys.partnersNotFoundModal, messagesKeys.type], ''),
});
