// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function UploadOutlinedIcon(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M14.6254 14.3836L12.8887 14.3836L12.8887 20.0625C12.8887 20.1656 12.8043 20.25 12.7012 20.25L11.2949 20.25C11.1918 20.25 11.1074 20.1656 11.1074 20.0625L11.1074 14.3836L9.37538 14.3836C9.21835 14.3836 9.13163 14.2031 9.22772 14.0813L11.8527 10.7602C11.8703 10.7377 11.8927 10.7196 11.9183 10.7072C11.9438 10.6947 11.9719 10.6882 12.0004 10.6882C12.0288 10.6882 12.0569 10.6947 12.0825 10.7072C12.1081 10.7196 12.1305 10.7377 12.148 10.7602L14.773 14.0813C14.8691 14.2031 14.7824 14.3836 14.6254 14.3836Z" fill="#5285CC" />
      <path d="M4.98281 8.59453C6.05625 5.76328 8.79141 3.75 11.9953 3.75C15.1992 3.75 17.9344 5.76094 19.0078 8.59219C21.0164 9.11953 22.5 10.95 22.5 13.125C22.5 15.7148 20.4023 17.8125 17.8148 17.8125H16.875C16.7719 17.8125 16.6875 17.7281 16.6875 17.625V16.2188C16.6875 16.1156 16.7719 16.0312 16.875 16.0312H17.8148C18.6047 16.0312 19.3477 15.7172 19.9008 15.1477C20.4516 14.5805 20.7445 13.8164 20.7188 13.0242C20.6977 12.4055 20.4867 11.8242 20.1047 11.3344C19.7133 10.8352 19.1648 10.4719 18.5555 10.3102L17.6672 10.0781L17.3414 9.22031C17.1398 8.68594 16.8586 8.18672 16.5047 7.73438C16.1553 7.28603 15.7414 6.89191 15.2766 6.56484C14.3133 5.8875 13.1789 5.52891 11.9953 5.52891C10.8117 5.52891 9.67734 5.8875 8.71406 6.56484C8.24766 6.89297 7.83516 7.28672 7.48594 7.73438C7.13203 8.18672 6.85078 8.68828 6.64922 9.22031L6.32578 10.0758L5.43984 10.3102C4.16953 10.6523 3.28125 11.8078 3.28125 13.125C3.28125 13.9008 3.58359 14.632 4.13203 15.1805C4.40099 15.451 4.72095 15.6655 5.07337 15.8115C5.42579 15.9576 5.80368 16.0323 6.18516 16.0312H7.125C7.22812 16.0312 7.3125 16.1156 7.3125 16.2188V17.625C7.3125 17.7281 7.22812 17.8125 7.125 17.8125H6.18516C3.59766 17.8125 1.5 15.7148 1.5 13.125C1.5 10.9523 2.9789 9.12422 4.98281 8.59453Z" fill="#5285CC" />
    </SvgIcon>
  );
}

export default UploadOutlinedIcon;
