// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
// Parts
import ConfirmButtons from '../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import Select from '../../../../components/_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/feeds/attributeSet/saga/asyncActions';

function ContentManagerProvider(props) {
  const {
    feature, hashId, value, withConfirm, valuesPlain, required,
  } = props;
  const dispatch = useDispatch();
  const defaultValue = { value: null, label: null, hashId: null };
  const initValue = value || defaultValue;
  const [selectedValue, setSelectedValue] = useState(initValue);
  const options = useSelector(selectors.partner.listUsersContext);

  const onChangeValueConfirm = (payload) => {
    dispatch(asyncActions.putContentManagerAsync(
      {
        hashId,
        assignedTo: {
          manager: payload.value,
          priority: feature.priority.value,
          label: payload.label,
          key: 'manager',
        },
      },
    ));
  };

  const onReject = () => {
    setSelectedValue(value || defaultValue);
  };

  const selectInput = {
    value: selectedValue,
    onChange: setSelectedValue,
  };

  return (
    <ConfirmButtons
      key={get(value, 'value', 'user-confirm-buttons-key')}
      error={feature.error}
      disabled={feature.pending}
      required={required}
      notEmpty={selectedValue.value !== null}
      onConfirm={onChangeValueConfirm}
      onReject={onReject}
      success={feature.success}
      value={selectedValue}
      valuesPlain={valuesPlain}
      withConfirm={withConfirm}
      withId
      initValue={initValue}
    >
      <Select
        input={selectInput}
        hasSearch
        closeMenuOnSelect
        isLoading={feature.pending}
        disabled={feature.pending}
        options={options}
        variant="outlined"
      />
    </ConfirmButtons>
  );
}

ContentManagerProvider.propTypes = {
  feature: PropTypes.shape({
    error: PropTypes.bool,
    pending: PropTypes.bool,
    success: PropTypes.bool,
    priority: PropTypes.any,
  }),
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withConfirm: PropTypes.bool,
  required: PropTypes.bool,
  hashId: PropTypes.string,
};

ContentManagerProvider.defaultProps = {
  withConfirm: true,
  valuesPlain: '',
};

export default ContentManagerProvider;
