// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset, submit } from 'redux-form';
// Redux
import { Field, Form, reduxForm } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { priceCreatorActions } from '../../../../../../../engine/core/priceCreator/action';
import { accessList } from '../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import FilesUploaderWithValidation from '../../../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import { validators } from '../../../../../../../_helpers/validationsForm';
import { mimeTypeExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { priceListAsyncAction } from '../../../../../../../engine/core/priceCreator/saga/asyncAction';

export const formName = 'updatePriceForm';
export const formFields = Object.freeze({
  fields: Object.freeze({
    stopPriceListFile: 'stopPriceListFile',
  }),
});

function UpdatePricesButton(props) {
  const { t } = useTranslation();
  const {
    handleSubmit, pending, sx, fullWidth,
  } = props;
  const dispatch = useDispatch();
  const isOpen = useSelector(selectors.priceList.updatePriceModalIsOpen);
  const hasAccess = useAccessList(accessList.partnerUploadProductPrices);
  const priceListUploadIsUploading = useSelector(selectors.priceList.priceListUploadIsUploading);
  const partnerId = useSelector(selectors.user.userPartnerId);

  if (!hasAccess) {
    return null;
  }

  const setUpdatePriceModalIsOpen = () => {
    dispatch(reset(formName));
    dispatch(priceCreatorActions.setUpdatePriceModalIsOpen(!isOpen));
  };

  const handleModalAdd = () => {
    dispatch(submit(formName));
  };

  const onFormSubmit = (formValues) => {
    const file = formValues.get(formFields.fields.stopPriceListFile);
    dispatch(priceListAsyncAction.postUploadPriceListAsync({ file, partnerId }));
  };

  return (
    <>
      <ButtonWithIcon
        sx={sx}
        text={t('Обновить цены')}
        fullWidth={fullWidth}
        onClick={setUpdatePriceModalIsOpen}
      >
        <RefreshIcon />
      </ButtonWithIcon>
      <Modal
        title={t('Обновить цены')}
        sendText={t('Отправить')}
        modalOpen={isOpen}
        handleModalAdd={handleModalAdd}
        handleModalToggle={setUpdatePriceModalIsOpen}
        handleModalCancel={setUpdatePriceModalIsOpen}
        disabledSend={priceListUploadIsUploading}
        modalMaxWidth="md"
      >
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Field
            allowFileTypeValidation
            acceptedFileTypes={[mimeTypeExtensions.xlsx]}
            textBefore2={t('Файл в формате XLSX')}
            component={FilesUploaderWithValidation}
            labelFileTypeNotAllowed={t('Тип файла не валидный')}
            name={formFields.fields.stopPriceListFile}
            disabled={pending}
            validate={[validators.itemsRequiredValidator]}
          />
        </Form>
      </Modal>
    </>
  );
}

UpdatePricesButton.displayName = 'partnerUploadProductPrices';

UpdatePricesButton.propTypes = {
  handleSubmit: PropTypes.func,
  pending: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
};

UpdatePricesButton.defaultProps = {
  pending: false,
  handleSubmit: () => {},
};

export default reduxForm({
  form: formName,
})(memo(UpdatePricesButton));
