// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetListWithMidWorker({ payload }) {
  const { context = 'select_with_mid', statusId } = payload;
  yield put(actions.mergePartnersListWithMid({ statusId, data: { pending: true } }));
  const params = {
    context,
    partnerStatus: [{ value: statusId }],
  };
  const response = yield apply(api, api.partner.getPartnersList, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.mergePartnersListWithMid({ data }));
  }

  yield put(actions.mergePartnersListWithMid({ data: { pending: false } }));
}
