// Core
import { apply, put } from 'redux-saga/effects';

// actions
import { actions as partnerActions } from '../../actions';
import { partnerAsyncAction } from '../../../_partners/saga/asyncAction';

// config
import { api, requestStatus } from '../../../../config/api';

export function* callPutPartnersOrderProcessingScheduleWorker({ payload: { hashId, formData } }) {
  yield put(partnerActions.mergePartnerOrderProcessing({ pending: true }));

  const response = yield apply(api, api.partner.putPartnersOrderProcessingSchedule, [{ hashId, formData }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { status } = response.data;

    switch (status) {
      case requestStatus.error: {
        break;
      }
      case requestStatus.success: {
        yield put(partnerActions.mergePartnerOrderProcessing({ isModalOpen: false }));
        yield put(partnerAsyncAction.getPartnerInfo({ hashId, disableLoader: true, disableInitForm: true }));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(partnerActions.mergePartnerOrderProcessing({ pending: false }));
}
