// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List } from 'immutable';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import declineWord from 'decline-word';
// Parts
import Chip from '@mui/material/Chip';
import Modal from '../../../../containers/Modals/Modal/Modal';
// Helpers
import { useMDView, useMobileView } from '../../../../hooks/useDesktopView';
import ModalStyle from '../../../../containers/Modals/Modal/ModalStyle';
import { selectors } from '../../../../../engine/config/selectors';
import { useStyles } from '../../../../hooks/useStyles';

function ContentProductModal(props) {
  const {
    modalOpen, handleModalToggle, successCounter,
    notSuccessCounter, notSuccessIds,
  } = props;
  const classes = useStyles(ModalStyle);
  const rows = useSelector(selectors.contentProduct.list);
  const { t } = useTranslation();
  const rowsToJs = rows.toJS();
  const isMDView = useMDView();
  const isMobile = useMobileView();


  return (
    <Modal
      title={t('Результат массового редактирования')}
      fullScreen={false}
      fullWidth={isMobile || !isMDView}
      modalOpen={modalOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      hideButtons
    >
      <p className={classes.labelTitle}><b>{t('Результат редактирования')}:</b></p>
      <ul className={classes.statusList}>
        <li>{t('Успешно изменено')}: <span className={classes.importSuccess}>{successCounter} {declineWord(successCounter, '', t('товар'), t('товара'), t('товаров'))}</span></li>
        <li>{t('Не успешно изменено')}: <span className={classes.importError}>{notSuccessCounter} {declineWord(notSuccessCounter, '', t('товар'), t('товара'), t('товаров'))}</span>
          <div className={classes.skuContainer}>
            {(notSuccessIds || []).map((id) => {
              const item = rowsToJs.find(product => product.id === id);
              let value = '';
              let prefix = '';

              if (item) {
                value = get(item, ['sku', 'value']);
                prefix = get(item, ['sku', 'prefix']);
              }

              const computedPrefix = prefix ? `-${prefix}` : prefix;

              const sku = (
                <><b>SKU:</b>&nbsp;{`${value}${computedPrefix}`}</>
              );

              return (
                <Chip
                  key={sku}
                  className={classes.sku}
                  variant="outlined"
                  label={sku}
                />
              );
            })}
          </div>
        </li>
      </ul>
    </Modal>
  );
}

ContentProductModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func,
  successCounter: PropTypes.number.isRequired,
  notSuccessCounter: PropTypes.number.isRequired,
  notSuccessIds: PropTypes.instanceOf(List).isRequired,
};

export default ContentProductModal;
