// Core
import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import i18n from '../../../../../engine/init/i18n';

function MultipleSelectFilter(props) {
  const {
    disabled, filters, onSelectChange,
    columnName, placeholder, isLoading, values, getAction, searchErrorLabel,
  } = props;

  const dispatch = useDispatch();
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);

  useEffect(() => {
    // timeout for make sure our request will be sent after self backend changes
    const handleChangeLang = () => setTimeout(() => dispatch(getAction), 0);
    i18n.on('languageChanged', handleChangeLang);
    return () => i18n.off('languageChanged', handleChangeLang);
  }, [i18n]);


  useEffect(() => {
    if (!currentFiltersNameList.includes(columnName)) {
      dispatch(getAction);
    }
  }, [currentFiltersNameList, columnName]);


  return (
    <TableHeadFilterSelectMultiple
      values={values}
      loading={isLoading}
      disabled={disabled}
      filters={filters}
      onSelectChange={onSelectChange}
      placeholder={placeholder}
      searchErrorLabel={searchErrorLabel}
    />
  );
}

MultipleSelectFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  columnName: PropTypes.string,
  values: PropTypes.object,
  isLoading: PropTypes.bool,
  getAction: PropTypes.object,
  searchErrorLabel: PropTypes.string,
};

export default MultipleSelectFilter;
