// Core
import {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Data
import { formFields } from '../../../../../../_helpers/data/pages/handbook/categories/form';
// Engine
import { asyncActions } from '../../../../../../engine/core/categories/saga/asyncActions';
import { selectors } from '../../../../../../engine/config/selectors';

export function useForm(hashId) {
  const categoryItemSubmitting = useSelector(selectors.categories.categoryItemSubmitting);
  const [submitting, setSubmitting] = useState(categoryItemSubmitting);
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback((formData) => {
    const defaultValue = undefined;
    const radix = 10;
    const fillingPercent = parseInt(formData.get(formFields.fillingPercent), radix);
    const partnerOfferPercent = parseInt(formData.get(formFields.partnerOfferPercent), radix);

    const dataToServer = {
      fillingPercent: Number.isNaN(fillingPercent) ? defaultValue : fillingPercent,
      partnerOfferPercent: Number.isNaN(partnerOfferPercent) ? defaultValue : partnerOfferPercent,
    };

    setSubmitting(true);
    dispatch(asyncActions.putCategoryItemByHashId({ hashId, data: dataToServer }));
  }, [dispatch, hashId]);

  useEffect(() => {
    setSubmitting(categoryItemSubmitting);
  }, [categoryItemSubmitting]);

  return useMemo(() => ({
    onSubmitHandler,
    submitting,
  }), [onSubmitHandler, submitting]);
}
