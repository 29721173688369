// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../action';

export function* callGetUsersPartnerRolesWorker({ payload }) {
  yield put(userActions.mergeGetPartnerRoles({
    pending: true,
  }));
  const response = yield apply(api, api.user.getPartnerRoles, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const items = response.data;
    yield put(userActions.mergeGetPartnerRoles({ items }));
  }
  yield put(userActions.mergeGetPartnerRoles({
    pending: false,
  }));
}
