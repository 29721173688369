// Core
import { apply, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { change, initialize } from 'redux-form/immutable';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import toArray from 'lodash/toArray';
import { Map } from 'immutable';
// Instruments
import { partnerActions } from '../../action';
import { formName } from '../../../../../ui/page/Partner/form';
import { formName as orderProcessingScheduleForm } from '../../../../../ui/page/Partner/components/PartnerEditSite/components/OrderProcessingModal/form';
import { formName as deliveryDelayPeriodForm } from '../../../../../ui/page/Partner/components/PartnerEditSite/components/DeliveryDelayPeriodModal/form';
// Engine
import { api } from '../../../../config/api';
import { dateStringShortFormat } from '../../../../config/globalConfig';
import { selectors } from '../../../../config/selectors';
// Helpers
import { getDateWithCurrentLocal } from '../../../../../_helpers/getDate';
import { formOfOwnershipStatus } from '../../../../../ui/page/Partner/components/PartnerEditFinance';

export function* callGetPartnerInfoWorker({
  payload: {
    hashId, resetShowPopUps = true, disableLoader, disableInitForm,
  },
}) {
  if (!disableLoader) {
    yield put(partnerActions.setPartnerInfo({ pending: true }));
  }
  const currentPartnerHashId = yield select(selectors.user.userPartnerHashId);
  if (resetShowPopUps) {
    yield put(partnerActions.mergePartnerSave({ showPopUps: Map() }));
  }

  const response = yield apply(api, api.partner.getDataByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const userData = response.data;
    const infoFieldValues = get(userData, 'infoFieldValues', {});
    const infoFieldPaymentValues = get(userData, 'infoFieldPaymentValues', {});
    const categories = get(userData, 'categories', []);
    const partnerPaymentTypes = get(userData, 'partnerPaymentTypes', []);
    const formOfOwnership = toArray(formOfOwnershipStatus).map(item => item.label);
    const currentFormOfOwnership = get(infoFieldValues, 'formOfOwnership.current', '');
    const isTaxAdditionalCostNumber = get(infoFieldValues, 'isTaxAdditionalCostNumber.current', '');
    const formOfOwnershipSelect = formOfOwnership.includes(currentFormOfOwnership) || currentFormOfOwnership === '' ? currentFormOfOwnership : formOfOwnershipStatus.anotherForm.label;
    const beenAlloSupplierValue = get(infoFieldValues, 'beenAlloSupplier.current', '');
    const beenAlloSupplier = beenAlloSupplierValue && beenAlloSupplierValue.includes(', ') ? beenAlloSupplierValue.split(', ')[0] : beenAlloSupplierValue;
    const beenAlloSupplierName = beenAlloSupplierValue && beenAlloSupplierValue.includes(', ') ? beenAlloSupplierValue.split(', ')[1] : '';
    const noticeDateAt = get(userData, 'noticeDateAt');
    const partnerStatus = get(userData, 'partnerStatus');
    const orderProcessingSchedule = get(infoFieldValues, 'orderProcessingSchedule');
    const deliveryDelayPeriod = get(infoFieldValues, 'deliveryDelayPeriod');

    const [mainWarehouse, ...otherWarehouses] = infoFieldValues?.warehouses ?? [];
    const warehouses = otherWarehouses?.map(item => ({ label: item, value: item }));


    const formData = {
      categories,
      formOfOwnershipSelect,
      partnerStatus,
      beenAlloSupplier,
      beenAlloSupplierName,
      orderProcessingSchedule,
      canProcess: get(userData, 'canProcess'),
      accessBilling: get(userData, 'accessBilling'),
      accessBlockContent: get(userData, 'accessBlockContent'),
      accessFeed: get(userData, 'accessFeed'),
      accessRatingRatio: get(userData, 'accessRatingRatio'),
      isTop: get(userData, 'isTop'),
      address: get(infoFieldValues, 'address.current', ''),
      addressIndex: get(infoFieldValues, 'addressIndex.current', ''),
      brands: get(userData, 'brands', []),
      categoriesPercent: categories,
      formOfOwnership: get(infoFieldValues, 'formOfOwnership.current', ''),
      hasServiceCenter: get(infoFieldValues, 'hasServiceCenter.current', ''),
      hasContactCenter: get(infoFieldValues, 'hasContactCenter.current', ''),
      activityType: get(infoFieldValues, 'activityType.current', ''),
      planToSell: get(infoFieldValues, 'planToSell.current', ''),
      hasOnlineStore: get(infoFieldValues, 'hasOnlineStore.current', ''),
      readyToYmlFormat: get(infoFieldValues, 'readyToYmlFormat.current', ''),
      ymlPriceLink: get(infoFieldValues, 'ymlPriceLink.current', ''),
      yourselfInformation: get(infoFieldValues, 'yourselfInformation.current', ''),
      city: get(infoFieldValues, 'city.current', ''),
      companyName: get(infoFieldValues, 'companyName.current', ''),
      contactEmail: get(infoFieldValues, 'contactEmail.current', ''),
      contactNameAndPosition: get(infoFieldValues, 'contactNameAndPosition.current', ''),
      contactPhone: get(infoFieldValues, 'contactPhone.current', ''),
      deliveryTime: get(infoFieldValues, 'deliveryTime.current', ''),
      deliveryTimeFrom: get(infoFieldValues, 'deliveryTimeFrom.current', ''),
      deliveryTimeTo: get(infoFieldValues, 'deliveryTimeTo.current', ''),
      detailedInformation: get(infoFieldValues, 'detailedInformation.current', ''),
      directorName: get(infoFieldValues, 'directorName.current', ''),
      directorNameAdditional: get(infoFieldValues, 'directorNameAdditional.current', ''),
      directorPhone: get(infoFieldValues, 'directorPhone.current', ''),
      enterpriseClassifierCode: get(infoFieldValues, 'enterpriseClassifierCode.current', ''),
      enterpriseName: get(infoFieldValues, 'enterpriseName.current', ''),
      enterpriseType: get(infoFieldValues, 'enterpriseType.current', ''),
      erpCompanyName: get(infoFieldValues, 'erpCompanyName.current', ''),
      financialManagerName: get(infoFieldValues, 'financialManagerName.current', ''),
      financialManagerPhone: get(infoFieldValues, 'financialManagerPhone.current', ''),
      groups: get(userData, 'groups'),
      partnerInfo: get(infoFieldValues, 'partnerInfo.current', ''),
      warrantyAndReturn: get(infoFieldValues, 'warrantyAndReturn.current', ''),
      workSchedule: get(infoFieldValues, 'workSchedule.current', ''),
      registrationAt: getDateWithCurrentLocal(userData.registrationAt),
      site: get(infoFieldValues, 'site.current', ''),
      smallLogo: get(infoFieldValues, 'smallLogo.currentId'),
      taxAdditionalCostNumber: get(infoFieldValues, 'taxAdditionalCostNumber.current', ''),
      enterpriseGroupCode: get(infoFieldValues, 'enterpriseGroupCode.current', ''),
      automaticVoucherGeneration: get(infoFieldValues, 'automaticVoucherGeneration.current', ''),
      taxIdentification: get(infoFieldValues, 'taxIdentification.current', ''),
      taxationType: get(infoFieldValues, 'taxationType.current', ''),
      iban: get(infoFieldValues, 'iban.current', ''),
      bankName: get(infoFieldValues, 'bankName.current', ''),
      interBranchTurnover: get(infoFieldValues, 'interBranchTurnover.current', ''),
      bankClassifierCode: get(infoFieldValues, 'bankClassifierCode.current', ''),
      idMagentoPartner: get(userData, 'idMagentoPartner'),
      erpPartnerCode: get(userData, 'erpPartnerCode'),
      erpStatus: get(userData, 'erpStatus'),
      erpError: get(userData, 'erpError'),
      rating: get(userData, 'rating'),
      erpDogovorName: get(userData, 'erpDogovorName'),
      sedDocumentStatus: get(userData, 'sedDocumentStatus'),
      erpContractNumber: get(userData, 'erpContractNumber'),
      documentAt: getDateWithCurrentLocal(userData.documentAt, dateStringShortFormat),
      partnerManager: get(userData, 'partnerManager', ''),
      partnerCancelStatus: get(userData, 'partnerCancelStatus', ''),
      cancelComment: get(userData, 'cancelComment', ''),
      noticeDateAt: noticeDateAt ? new Date(noticeDateAt) : undefined,
      contactName1: get(infoFieldValues, 'contactName1.current'),
      contactName2: get(infoFieldValues, 'contactName2.current'),
      contactPhone1: get(infoFieldValues, 'contactPhone1.current'),
      contactPhone2: get(infoFieldValues, 'contactPhone2.current'),
      warehouses,
      mainWarehouse: mainWarehouse && mainWarehouse?.length > 0 ? { label: mainWarehouse, value: mainWarehouse } : '',
      isErpContractTerminatedAt: get(userData, 'isErpContractTerminatedAt', ''),
      isErpContractTerminated: get(infoFieldValues, 'isErpContractTerminated.current'),
      erpContractTerminatedBy: get(infoFieldValues, 'erpContractTerminatedBy.current'),
    };

    if (infoFieldPaymentValues) {
      Object.keys(infoFieldPaymentValues).forEach((item) => {
        formData[item] = infoFieldPaymentValues[item];
      });
    }

    if (isTaxAdditionalCostNumber === true) {
      formData.isTaxAdditionalCostNumber = 'Так';
    } else if (isTaxAdditionalCostNumber === false) {
      formData.isTaxAdditionalCostNumber = 'Ні';
    }

    const fields = Object.keys(formData);
    forEach(partnerPaymentTypes, (value, key) => {
      fields.push(`paymentTypes_${key}`);
      formData[`paymentTypes_${key}`] = value?.active;
    });

    const formOrderProcessingData = {
      day0: orderProcessingSchedule?.days?.[0],
      day1: orderProcessingSchedule?.days?.[1],
      day2: orderProcessingSchedule?.days?.[2],
      day3: orderProcessingSchedule?.days?.[3],
      day4: orderProcessingSchedule?.days?.[4],
      day5: orderProcessingSchedule?.days?.[5],
      day6: orderProcessingSchedule?.days?.[6],
    };

    const delayedPeriodEnd = deliveryDelayPeriod?.delayedPeriod?.end;
    const delayedPeriodStart = deliveryDelayPeriod?.delayedPeriod?.start;
    const delayedDaysDelay = deliveryDelayPeriod?.delayedPeriod?.daysDelay;
    const formDeliveryDelayPeriod = {
      end: delayedPeriodEnd ? moment(delayedPeriodEnd) : undefined,
      start: delayedPeriodStart ? moment(delayedPeriodStart) : undefined,
      daysDelay: delayedDaysDelay ? `${deliveryDelayPeriod?.delayedPeriod?.daysDelay}` : undefined,
    };
    if (!disableInitForm) {
      yield put(initialize(formName, formData, fields));
    } else {
      yield put(change(formName, 'orderProcessingSchedule', orderProcessingSchedule));
    }
    yield put(initialize(orderProcessingScheduleForm, formOrderProcessingData, Object.keys(formOrderProcessingData)));
    yield put(initialize(deliveryDelayPeriodForm, formDeliveryDelayPeriod, Object.keys(formDeliveryDelayPeriod)));
    yield put(partnerActions.setPartnerContractLink(get(infoFieldValues.contract, 'current')));
    yield put(partnerActions.setPartnerContractPage(get(infoFieldValues.contract, 'currentPages')));

    delete userData.infoFieldValues.contract;

    if (currentPartnerHashId !== hashId) {
      const shouldApproveValuesKey = 'shouldApproveValues';
      const shouldApproveValuesDefaultValue = false;
      forEach(infoFieldValues, (value) => {
        if (value.old !== undefined) {
          if (get(userData, shouldApproveValuesKey, shouldApproveValuesDefaultValue) === shouldApproveValuesDefaultValue) {
            userData.shouldApproveValues = true;
          }
          value.approvedValue = null; // eslint-disable-line
        }
      });

      const partnerOfferPercentKey = 'partnerOfferPercent';
      const partnerOfferPercentApprovedKey = 'partnerOfferPercentApproved';
      forEach(categories, (category) => {
        const partnerOfferPercent = get(category, partnerOfferPercentKey);
        const partnerOfferPercentApproved = get(category, partnerOfferPercentApprovedKey);
        if (partnerOfferPercent !== partnerOfferPercentApproved) {
          if (get(userData, shouldApproveValuesKey, shouldApproveValuesDefaultValue) === shouldApproveValuesDefaultValue) {
            userData.shouldApproveValues = true;
          }
          category.approvedValue = null; // eslint-disable-line
        }
      });
    }
    yield put(partnerActions.setPartnerInfo({ pending: false, data: userData }));
  }
}
