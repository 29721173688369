// Core
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';

const selectWidthConfirmStyles = theme => ({
  selectWidthConfirm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  select: {
    width: '100%',
  },
  selectSuccess: {
    '@global': {
      div: {
        color: green[500],
      },
      fieldset: {
        border: `2px solid ${green[500]}`,
      },
    },
  },
  selectError: {
    '@global': {
      div: {
        color: red[500],
      },
      fieldset: {
        border: `1px solid ${red[500]} !important`,
      },
    },
  },
  selectWidthConfirmWrapp: {
    padding: '2px',
    backgroundColor: '#fff',
  },
  selectWidthConfirmClose: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: 'rgba(48, 48, 48, 0.08)',
    color: '#000',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  selectWidthConfirmCheck: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  selectMarginBoth: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export default selectWidthConfirmStyles;
