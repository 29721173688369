// Core
import { apply, put, select } from 'redux-saga/effects';
// lodash
import uniqBy from 'lodash/uniqBy';
import forEach from 'lodash/forEach';
// Engine
import { initialize, change } from 'redux-form';
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';
import { formName } from '../../../../../ui/page/PartnerAccess/components/BankInstallmentsTable/utils';

export function* callGetListWorker({ payload }) {
  const totalCount = yield select(selectors.bankInstallments.totalCount);
  const formValues = yield select(state => selectors.form.getFormValues(state, formName));
  const filters = yield select(selectors.bankInstallments.filters);
  yield put(actions.setList({ pending: true }));
  const { params, treeDataRowIds } = payload;
  const response = yield apply(api, api.partners.getBankInstallmentsList, [params, treeDataRowIds]);

  let data = {
    items: [],
    totalCount,
    pending: false,
  };


  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
    const fieldsValues = {};
    data.items.forEach((item) => {
      fieldsValues[`${item?.categoryId}_paymentCount`] = item.hasHistory ? { value: item?.paymentCount, label: Number(item?.paymentCount) } : undefined;
    });

    if (Object.keys(fieldsValues).length) {
      yield put(initialize(formName, fieldsValues, Object.keys(fieldsValues)));
    }
  }

  if (response && response.length > 0) {
    const dataFromState = yield select(selectors.bankInstallments.list);
    let newDataItems = dataFromState.toJS();
    forEach(response, (responseData) => {
      newDataItems = [...responseData.data.items, ...newDataItems];
    });
    data.items = uniqBy(newDataItems, 'categoryId');
    // TODO: simplify logic if it is possible
    const dataFromFormValues = Object.entries(formValues.toJS()).map(([key, value]) => ({ categoryId: Number(key.split('_')[0]), paymentCount: value }));
    // eslint-disable-next-line no-restricted-syntax
    for (const responseData of response) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of responseData.data.items) {
        const parentItem = dataFromFormValues.find(it => it.categoryId === item.parentId);
        const isChangedByUser = parentItem?.paymentCount && data.items.find(it => it.categoryId === parentItem.categoryId)?.paymentCount !== parentItem?.paymentCount?.value;
        if (isChangedByUser) {
          yield put(change(formName, `${item?.categoryId}_paymentCount`, { value: parentItem.paymentCount.value, label: parentItem.paymentCount.label }));
        } else if (item.hasHistory) {
          yield put(change(formName, `${item?.categoryId}_paymentCount`, { value: item.paymentCount, label: item.paymentCount }));
        } else {
          yield put(change(formName, `${item?.categoryId}_paymentCount`, undefined));
        }
      }
    }
  }

  if (filters.toJS().length) {
    data.items = uniqBy(data.items, 'categoryId');
  }

  yield put(actions.setList(data));
}
