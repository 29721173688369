import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PartnerSelect from '../../../components/_Form/Selects/PartnerSelect';
import Modal from '../../../containers/Modals/Modal/Modal';
import ModalFooter from '../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import { selectors } from '../../../../engine/config/selectors';
import Select from '../../../components/_Form/Selects/Select/Select';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';

export default function ChoosePartnerAndServiceModal({ onModalClose, open }) {
  const { t } = useTranslation();

  const [partner, setPartner] = useState({});
  const [selectedService, setSelectedService] = useState({ label: 'ALLO Express', value: 'ALLO_EXPRESS' });

  const changeFormSelect = (arrayValues) => {
    setPartner(arrayValues);
  };

  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              variant="outlined"
              color="primary"
              sx={{ p: BUTTON_SIZE.outlinedMiddle, marginRight: '15px' }}
              onClick={() => {
                onModalClose(false, false);
              }}
            >{t('Отменить')}
            </Button>
            <Button
              disabled={!partner}
              variant="contained"
              color="primary"
              onClick={() => {
                onModalClose(partner.hashId, 'ALLO_EXPRESS');
              }}
            >
              {t('Продолжить')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalFooter>
  );


  return (
    <>
      <Modal
        title={t('Створення заявки')}
        modalOpen={open}
        handleModalToggle={() => {
          onModalClose(false, false);
        }}
        hideButtons
        keepMounted
        fullWidth
        noPadding
        modalMaxWidth="sm"
        modalFooterComponent={modalFooterComponent}
      >
        <Grid marginBottom={'15px'} container justifyContent="flex-end" flexDirection="column">
          <Grid sx={{ p: '10px 20px 0' }} item sm={12}>
            <PartnerSelect
              input={{ value: partner, onChange: changeFormSelect }}
              fullWidth
              partnerListSelector={selectors.partner.partnersListContextForServiceRequests}
              partnerPendingSelector={selectors.partner.partnersListContextForServiceRequestsPending}
            />
          </Grid>
          <Grid sx={{ p: '10px 20px 0' }} item>
            <Select
              variant="outlined"
              disabled
              label=""
              handleChange={setSelectedService}
              value={selectedService}
            >
              <MenuItem value={'ALLO_EXPRESS'}>ALLO Express</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
