// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_SETTING_PENDING, settingPending);
  },
  mergeList(data) {
    return getActionCreator(types.MERGE_CONTENT_TEMPLATES_LIST, data);
  },
  setIsContentTemplateImportModalOpen(isOpen) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_IMPORT_MODAL_OPEN, isOpen);
  },
  setProductImportPending(payload) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_IMPORT_PENDING, payload);
  },
  setContentTemplateImportModalInitialState() {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_IMPORT_MODAL_INITIAL_STATE);
  },
  setProductsImportTemplatesDownloadState(downloadState) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_STATE, downloadState);
  },
  setIsContentUpdateFromMagentoPending(pending) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_PENDING, pending);
  },
  setIsContentUpdateFromMagentoCategory(categoryId) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_CATEGORY, categoryId);
  },
  setIsContentUpdateValuesFromMagentoPending(pending) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_PENDING, pending);
  },
  setIsContentUpdateValuesFromMagentoFeature(featureId) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_FEATURE, featureId);
  },
});
