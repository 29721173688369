// Core
import {
  apply, put, select, delay,
} from 'redux-saga/effects';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { actions as actionsFeedsAll } from '../../../../feeds/all/actions';
import { productFeedCategoriesAttributesConst } from '../../../const';
import { productFeedCategoriesAttributes } from '../../selectors';
import { asyncActions } from '../asyncActions';
// import { asyncActions as feedsAsyncActions } from '../../../../feeds/all/saga/asyncActions';

export function* callGetListWorker({ payload: params }) {
  const { mode, hashId, params: listParams } = params;
  if (mode !== productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_BUTTON) {
    yield put(actions.setList({ pending: true }));
  }
  const response = yield apply(api, api.productFeedCategoriesAttributes.getList, [params]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    if (
      mode === productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_BUTTON
      || mode === productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_CHANGE_CATEGORY
    ) {
      yield put(actionsFeedsAll.setFeedsItemByHashIdCanParse(response.data.canParse));
      const inProcessItems = response.data.items;
      const currentList = yield select(productFeedCategoriesAttributes.list);
      const currentTotalCount = yield select(productFeedCategoriesAttributes.totalCount);
      data.items = currentList.map(item => inProcessItems.find(bItem => bItem.id === item.id) || item);
      data.totalCount = currentTotalCount;
      yield put(actions.setList(data));

      if (response.data.totalCount > 0) {
        yield delay(5000);
        yield put(asyncActions.getListAsync(listParams, hashId, mode));
      } else {
        if (mode === productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_BUTTON) {
          delete listParams.categoryStatus;
          yield put(asyncActions.getListAsync(listParams, hashId));
        }
        yield put(actions.setAutoConnectPending(false));
        const { data: { stepsData } } = yield apply(api, api.feeds.getFeedsHashId, [hashId]);
        yield put(actionsFeedsAll.setFeedsItemByHashIdStepData(stepsData));
      }
    } else {
      data = Object.assign(data, response.data);
      yield put(actions.setList(data));
    }
  }
}
