// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Components
import cx from 'classnames';
import Typography from '@mui/material/Typography';
import Error from './Error';

import titleImg404 from '../../../assets/img/404_status_img.jpg';
import { useStyles } from '../../hooks/useStyles';
import ErrorStyles from './ErrorStyles';

function Error404({ componentView }) {
  const { t } = useTranslation();
  const classes = useStyles(ErrorStyles);

  const titleView = (
    <Typography className={cx(classes.title, classes.title404)}>
      <span>4</span>
      <img src={titleImg404} alt="0" className={classes.titleImg404} />
      <span>4</span>
    </Typography>
  );

  return (
    <Error
      titleView={titleView}
      componentView={componentView}
      status={404}
      caption={t('Может быть, вы заблудились и не нашли то, что искали. Не беспокойтесь, мы поможем вам найти нужную информацию. Пожалуйста, проверьте адрес страницы или перейдите на главную страницу - там вы сможете найти больше полезной информации.')}
    />
  );
}


Error404.propTypes = {
  componentView: PropTypes.bool,
};

export default Error404;
