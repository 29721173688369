// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Modal from '../../../containers/Modals/Modal/Modal';

function ConfirmModal(props) {
  const {
    isModalOpen, setIsModalOpen, submit,
  } = props;
  const { t } = useTranslation();

  const handleModalCancel = () => setIsModalOpen(false);


  return (
    <Modal
      modalOpen={isModalOpen}
      handleModalToggle={handleModalCancel}
      handleModalCancel={handleModalCancel}
      handleModalSend={submit()}
      title={t('Внимание!')}
      modalMaxWidth="sm"
      sendText={t('Сохранить')}
      cancelText={t('Отменить')}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>{t('После сохранения номера IBAN - его дальнейшее редактирование будет невозможно.')} </Box>
    </Modal>

  );
}


export default memo(ConfirmModal);
