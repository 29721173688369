// Core
import { put, select } from 'redux-saga/effects';
import find from 'lodash/find';
// Engine
import { isNil } from 'lodash';
import { pageLinks } from '../../../../config/routes';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetProductsInitialStateWorker({ payload: url }) {
  const categoryId = url.substring(url.lastIndexOf('/') + 1);
  const productsPageAll = pageLinks.content.products.all(categoryId);
  const productsPageRedo = pageLinks.content.products.redo(categoryId);
  const productsPageModerate = pageLinks.content.products.moderate(categoryId);

  const filterName = 'productStatus';
  const redoValue = 6;
  const moderateValue = 2;

  const productStatusId = yield select(selectors.content.isProductCategoryCountModalId);
  const products = yield localData.getItem(url);

  const initialPageState = getInitialPageState(products || {});

  function getCurrentFilter() {
    let currentFilterIndex = initialPageState.filters.findIndex(({ columnName }) => columnName === filterName);
    if (currentFilterIndex === -1) {
      initialPageState.filters.push({
        columnName: filterName,
        value: null,
      });
      currentFilterIndex = initialPageState.filters.length - 1;
    }
    return initialPageState.filters[currentFilterIndex];
  }

  switch (url) {
    case productsPageAll: {
      if (!isNil(productStatusId)) {
        const currentFilter = getCurrentFilter();
        currentFilter.value = productStatusId;
        yield put(actions.setIsProductCategoryCountModal({ productStatusId: null }));
      }
      break;
    }

    case productsPageRedo: {
      const currentFilter = getCurrentFilter();
      const redoFilter = find(currentFilter, { value: redoValue });
      if (!redoFilter) {
        currentFilter.value = redoValue;
      }
      break;
    }
    case productsPageModerate: {
      const currentFilter = getCurrentFilter();
      const moderateFilter = find(currentFilter, { value: moderateValue });
      if (!moderateFilter) {
        currentFilter.value = moderateValue;
      }
      break;
    }
    default: {
      break;
    }
  }

  initialPageState.reload = true;

  yield put(actions.setInitialProductsState(initialPageState));
}
