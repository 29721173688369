// Core
import { Map } from 'immutable';

// Instruments
import { types } from './types';
import { types as typesUI } from '../ui/types';


export const initialState = Map({
  priceListDownload: {
    pending: false,
  },
  updatePrice: Map({
    isOpen: false,
  }),
  priceListUpload: Map({
    pending: false,
    isModalOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  priceListUpload: 'priceListUpload',
  controller: 'priceList',
  priceListDownload: 'priceListDownload',
  pending: 'pending',
  updatePrice: 'updatePrice',
  isOpen: 'isOpen',
});

export const priceListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.MERGE_PRICE_CREATOR_UPLOAD_STOP: {
      return state.mergeIn([stateKeys.priceListUpload], payload);
    }
    case types.SET_PRICE_CREATOR_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.priceListDownload, stateKeys.pending], payload);
    }
    case types.SET_PRICE_CREATOR_UPDATE_PRICE_MODAL_IS_OPEN: {
      return state.setIn([stateKeys.updatePrice, stateKeys.isOpen], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
