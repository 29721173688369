export const types = Object.freeze({
  SET_CONFIGURATIONS_LIST: 'SET_CONFIGURATIONS_LIST',
  MERGE_CONFIGURATIONS_TYPES: 'MERGE_CONFIGURATIONS_TYPES',
  SET_CONFIGURATIONS_CURRENT_PAGE: 'SET_CONFIGURATIONS_CURRENT_PAGE',
  SET_CONFIGURATIONS_FILTERS: 'SET_CONFIGURATIONS_FILTERS',
  SET_CONFIGURATIONS_PAGE_SIZE: 'SET_CONFIGURATIONS_PAGE_SIZE',
  SET_CONFIGURATIONS_SORTING: 'SET_CONFIGURATIONS_SORTING',
  SET_CONFIGURATIONS_INITIAL_STATE: 'SET_CONFIGURATIONS_INITIAL_STATE',
  SET_CONFIGURATIONS_SETTING_STATUS: 'SET_CONFIGURATIONS_SETTING_STATUS',
  SET_CONFIGURATIONS_SETTING_PENDING: 'SET_CONFIGURATIONS_SETTING_PENDING',
  MERGE_CONFIGURATIONS_ITEM_BY_HASH_ID: 'MERGE_CONFIGURATIONS_ITEM_BY_HASH_ID',
});
