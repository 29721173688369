// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, treeDataRowIds) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_FEATURE_MANAGEMENT_LIST_ASYNC, {
      params, treeDataRowIds,
    });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_CATEGORIES_FEATURE_MANAGEMENT_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_FEATURE_MANAGEMENT_INITIAL_STATE_ASYNC, path);
  },
  postCommissionUploadAsync(file) {
    return getActionCreator(asyncTypes.POST_CATEGORIES_FEATURE_MANAGEMENT_COMMISSION_UPLOAD_ASYNC, file);
  },
  postCategoriesFeatureImport(data) {
    return getActionCreator(asyncTypes.POST_CATEGORIES_FEATURE_MANAGEMENT_IMPORT_ASYNC, data);
  },
  getCategoriesFeatureExport() {
    return getActionCreator(asyncTypes.GET_CATEGORIES_FEATURE_MANAGEMENT_EXPORT_ASYNC);
  },
});
