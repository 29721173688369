import { getIn, List } from 'immutable';
import { stateKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const partnersServiceRequests = Object.freeze({
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.pending], false),
  isDownloadPending: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.isDownloadPending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.partnersServiceRequestsList, stateKeys.settingPending], false),
});
