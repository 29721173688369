// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
import { pageLinks } from '../../../../../../engine/config/routes';
import { selectors } from '../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
// Data
import { requestDelay } from '../../../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../_helpers/convertDataToUrlParams';
import { partnerStatuses } from '../../../../../../engine/config/partnerStatuses';

export function usePartnersList(hashId, refresh) {
  const currentPage = useSelector(selectors.partners.currentPage);
  const limit = useSelector(selectors.partners.pageSize);
  const filters = useSelector(selectors.partners.filters);
  const sorting = useSelector(selectors.partners.sorting);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const reload = useSelector(selectors.partners.reload);
  const hasAccessList = useAccessList(accessList.partnerList);
  const offset = limit * currentPage;

  const paramsByFilter = useMemo(() => {
    const params = {};

    switch (path) {
      case pageLinks.partner.new: {
        params.partnerStatus = { value: partnerStatuses.questionnaireFill };
        break;
      }
      case pageLinks.partner.waiting: {
        params.partnerStatus = { value: partnerStatuses.contractSigning };
        break;
      }
      case pageLinks.partner.rejected: {
        params.partnerStatus = [
          { value: partnerStatuses.cancelStatus },
          { value: partnerStatuses.frozenSigning },
        ].filter(Boolean);
        break;
      }
      default: {
        break;
      }
    }

    return filters.toJS().reduce((acc, { columnName, value }) => {
      if (!Object.prototype.hasOwnProperty.call(params, columnName)) {
        acc[columnName] = value;
      }
      return acc;
    }, params);
  }, [filters, path]);

  const getListAsync = useCallback(() => {
    if (hasAccessList) {
      const params = {
        ...paramsByFilter,
        orderBy: convertSortingObjectToUrlParams(sorting.toJS()),
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [
    hasAccessList, paramsByFilter,
    offset, sorting, limit, dispatch,
  ]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    getInitialState.current = true;
  }, [routeKey]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      dispatch(asyncActions.getInitialStateAsync(path));
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync, reload, refresh, timeout, routeKey]);
}
