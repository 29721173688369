// Core
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, reduxForm } from 'redux-form/immutable';
// Parts
import Grid from '@mui/material/Grid';
import SaveIcons from '@mui/icons-material/Save';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import TabsNav from '../../../../containers/Tabs/TabsNav';
import TabsNavMenu from '../../../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsContainer from '../../../../containers/Tabs/TabsContainer';
import CategoryEditGeneral from './components/CategoryEditGeneral';
import CategoryEditStatusesHistory from './components/CategoryEditStatusesHistory';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { userRoles } from '../../../../../engine/config/userRoles';
// Helpers
import { formName } from '../../../../../_helpers/data/pages/handbook/categories/form';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { useCategoryItem } from '../_hooks/useCategoryItem';
import { useForm } from './_hooks/useForm';
import { useStyles } from '../../../../hooks/useStyles';
import CategoryEditStyles from './CategoryEditStyles';

function CategoryEdit(props) {
  const { handleSubmit, mode } = props;
  const { t } = useTranslation();
  const { hashId } = useParams();
  const [selectedTab, setSelectedTab] = useState('general');
  const { getCategoryItemByHashIdAsync, isLoading, setCategoryItemDefaultState } = useCategoryItem(hashId);
  const classes = useStyles(CategoryEditStyles);
  const { onSubmitHandler, submitting } = useForm(hashId);
  const hasAccessStatusesHistory = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.contentManager,
  ]);

  useEffect(() => {
    getCategoryItemByHashIdAsync();
    return () => {
      setCategoryItemDefaultState();
    };
  }, [getCategoryItemByHashIdAsync, setCategoryItemDefaultState]);

  const displayTab = useCallback(currentTab => (
    (selectedTab === currentTab) ? 'flex' : 'none'
  ), [selectedTab]);

  const handleChangeTab = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);
  const categoryPageTitle = mode === 'edit' ? t('Редактирование категории') : t('Просмотр категории');

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container justifyContent="center">
        <PageHeader title={categoryPageTitle}>
          <ButtonGoBack defaultPage={pageLinks.categories.all} />
          {(mode === 'edit') && (
            <ButtonWithIcon
              text={t('Сохранить')}
              component="button"
              disabled={submitting}
              onClick={handleSubmit(onSubmitHandler)}
            >
              <SaveIcons />
            </ButtonWithIcon>
          )}
        </PageHeader>
        <TabsContainer>
          <TabsNav>
            <TabsNavMenu
              value={selectedTab}
              onChange={handleChangeTab}
            >
              <TabItemMenu value="general" label={t('Общая информация')} />
              {hasAccessStatusesHistory && (<TabItemMenu value="statusesHistory" label={t('История изменений')} />)}
            </TabsNavMenu>
          </TabsNav>
          <CategoryEditGeneral
            display={displayTab('general')}
            index={'general'}
            value={selectedTab}
            isLoading={isLoading}
            submitting={submitting}
            mode={mode}
            classes={classes}
          />
          {hasAccessStatusesHistory && (
            <CategoryEditStatusesHistory
              display={displayTab('statusesHistory')}
              index="statusesHistory"
              value={selectedTab}
              classes={classes}
            />
          )}
        </TabsContainer>
      </Grid>
    </Form>
  );
}

CategoryEdit.propTypes = {
  handleSubmit: PropTypes.func,
  mode: PropTypes.oneOf(['edit', 'mode', 'show']),
};

CategoryEdit.defaultProps = {
  handleSubmit: () => {},
};

export default reduxForm({ form: formName })(CategoryEdit);
