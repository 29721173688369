// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_MESSAGES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_MESSAGES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_MESSAGES_INITIAL_STATE_ASYNC, path);
  },
  getMessagesUserTypesAsync() {
    return getActionCreator(asyncTypes.GET_MESSAGES_USER_TYPES_ASYNC);
  },
  postMessageAsync(data) {
    return getActionCreator(asyncTypes.POST_MESSAGE_ASYNC, data);
  },
  getMessageByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_MESSAGE_BY_HASH_ID_ASYNC, hashId);
  },
  getMessagesStatusesAsync() {
    return getActionCreator(asyncTypes.GET_MESSAGES_STATUSES_ASYNC);
  },
  deleteMessageByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.DELETE_MESSAGE_HASH_ID_ASYNC, hashId);
  },
  getMessageUserTypesByTypeAsync(type) {
    return getActionCreator(asyncTypes.GET_MESSAGE_USER_TYPES_BY_TYPE_ASYNC, type);
  },
  postRecipientsImportAsync(data) {
    return getActionCreator(asyncTypes.POST_RECIPIENTS_IMPORT_ASYNC, data);
  },
});
