// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetTransactionPaymentInfoWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetPaymentTransactionsStatusesListWorker,
  callPutTransactionPaymentWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_TRANSACTIONS_PAYMENT_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_TRANSACTIONS_PAYMENT_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetPaymentTransactionsStatusesList() {
  yield takeEvery(asyncTypes.GET_TRANSACTIONS_PAYMENT_STATUSES_ASYNC, callGetPaymentTransactionsStatusesListWorker);
}
function* watchGetTransactionPaymentInfo() {
  yield takeEvery(asyncTypes.GET_TRANSACTION_PAYMENT_INFO_ASYNC, callGetTransactionPaymentInfoWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_TRANSACTIONS_PAYMENT_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutTransactionPayment() {
  yield takeEvery(asyncTypes.PUT_TRANSACTION_PAYMENT_ASYNC, callPutTransactionPaymentWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_TRANSACTIONS_PAYMENT_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_TRANSACTIONS_PAYMENT_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_TRANSACTIONS_PAYMENT_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_TRANSACTIONS_PAYMENT_SORTING, callSetToLocalDataWorker);
}

export function* watchersTransactionsPayment() {
  yield all([
    watchGetList(),
    watchGetTransactionPaymentInfo(),
    watchGetInitialState(),
    watchPutTransactionPayment(),
    watchGetPaymentTransactionsStatusesList(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
  ]);
}
