// Core
import { apply, put, call } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

// Engine
import { api } from '../../../../config/api';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { mediaAction } from '../../action';

export function* callPostProductMediasWorker({ payload }) {
  const {
    files, context, cb,
  } = payload;
  yield put(mediaAction.setMediaUploadFilesPending(true));
  const response = yield apply(api, api.medias.batchMediasUpload, [{ files, context }]);

  if (response && response.status >= 200 && response.status < 300) {
    if (response.data) {
      const { status, statusTitle } = response.data;
      switch (status) {
        case 'success': {
          const { message, mediaFiles } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          if (mediaFiles) {
            const newArr = mediaFiles.map(item => ({
              key: uuidv4(),
              primary: '',
              secondary: item.link,
              src: item.link,
            }));
            yield call(cb, newArr);
          }
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(mediaAction.setMediaUploadFilesPending(false));
}
