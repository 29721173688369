// Core
import { apply, delay, put } from 'redux-saga/effects';
// Engine
import { push } from 'redux-first-history';
import { api } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
// Partial
import { actions } from '../../actions';
import { pageLinks } from '../../../../../config/routes';

export function* callDeleteFeedByHashIdWorker({ payload: hashId }) {
  yield put(actions.setFeedsDelete({ showModal: false }));
  yield put(actions.setFeedsDeletePending(true));
  const response = yield apply(api, api.feeds.deleteFeedsHashId, [hashId.hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;
    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(pageLinks.productsAndPrices.prices));
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setFeedsDeletePending(false));
}
