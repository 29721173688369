// Core
import { fromJS, List, Map } from 'immutable';
// Types
import { types } from './types';

export const initialState = fromJS({
  attributeSetsListContext: Map({
    items: List(),
    pending: false,
    totalCount: 0,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'attributeSets',
  attributeSetsListContext: 'attributeSetsListContext',
  items: 'items',
  pending: 'pending',
});

export const attributeSetsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ATTRIBUTE_SETS_LIST_CONTEXT: {
      return state.mergeIn([stateKeys.attributeSetsListContext], Map(payload));
    }
    default: {
      return state;
    }
  }
};
