export const types = Object.freeze({
  SET_BILLING_STATUS: 'SET_BILLING_STATUS',
  SET_CREDIT_LIMIT_MODAL_STATUS: 'SET_CREDIT_LIMIT_MODAL_STATUS',
  SET_CREDIT_LIMIT_MODAL_OFF_STATUS: 'SET_CREDIT_LIMIT_MODAL_OFF_STATUS',
  SET_BILLING_TRANSACTIONS_DOWNLOAD_PARAMETERS: 'SET_BILLING_TRANSACTIONS_DOWNLOAD_PARAMETERS',
  SET_BILLING_STATUSES_LIST: 'SET_BILLING_STATUSES_LIST',
  SET_BILLING_PAYMENT_TYPES: 'SET_BILLING_PAYMENT_TYPES',
  MERGE_IN_BILLING_INVOICE_TYPES: 'MERGE_IN_BILLING_INVOICE_TYPES',
  SET_BILLING_REPORT_FILTERS: 'SET_BILLING_REPORT_FILTERS',
  SET_BILLING_ORDERS_DASHBOARD_DATA: 'SET_BILLING_ORDERS_DASHBOARD_DATA',
  SET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA: 'SET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA',
  SET_BILLING_NEW_ORDERS_DASHBOARD_DATA: 'SET_BILLING_NEW_ORDERS_DASHBOARD_DATA',
  SET_BILLING_NEW_ORDERS_DASHBOARD_DATA_LOADING: 'SET_BILLING_NEW_ORDERS_DASHBOARD_DATA_LOADING',
  SET_BILLING_DASHBOARD_LAST_TRANSACTIONS: 'SET_BILLING_DASHBOARD_LAST_TRANSACTIONS',
  SET_IS_BILLING_DASHBOARD_LAST_TRANSACTIONS_LOADING: 'SET_IS_BILLING_DASHBOARD_LAST_TRANSACTIONS_LOADING',
  SET_BILLING_SELECT_FILTER_PARTNER: 'SET_BILLING_SELECT_FILTER_PARTNER',
  SET_BILLING_SUCCESS_DELIVERY_DASHBOARD_DATA: 'SET_BILLING_SUCCESS_DELIVERY_DASHBOARD_DATA',
  SET_RATING_STATUS: 'SET_RATING_STATUS',
  SET_BILLING_PARTNERS_DOWNLOAD_PENDING: 'SET_BILLING_PARTNERS_DOWNLOAD_PENDING',
  SET_BILLING_INVOICE_MODAL: 'SET_BILLING_INVOICE_MODAL',
  MERGE_BILLING_REPORT_DOWNLOAD: 'MERGE_BILLING_REPORT_DOWNLOAD',
});
