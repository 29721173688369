// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { asyncActions } from '../../../partners/managers/saga/asyncActions';
import { setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { partnersManagers } from '../../../partners/managers/selectors';

export function* callPostBusinessesListWorker({ payload }) {
  const pageSize = yield select(partnersManagers.pageSize);
  const sorting = yield select(partnersManagers.sorting);
  const filters = yield select(partnersManagers.filters);
  const response = yield apply(api, api.partners.setBusiness, [payload]);

  if (response.status >= 200 && response.status < 400) {
    const { message, statusTitle } = response.data;
    yield put(setSuccessMessage(message, statusTitle));
    const params = {
      sorting,
      limit: pageSize,
    };

    filters.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);

    yield put(asyncActions.getListAsync(params));
  }
}
