// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
// Parts
import ChipFormatter from './ChipFormatter';
// Helpers
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
// Engine
import { actions } from '../../../../../engine/core/feeds/products/actions';
import { actions as contentProductActions } from '../../../../../engine/core/contentProduct/actions';
import { feedsProductsFiltersWithoutCategory } from '../../../../../engine/core/feeds/products/selectors';
import { pageLinks } from '../../../../../engine/config/routes';

function CategoryCell(props) {
  const { column, value } = props;
  const dispatch = useDispatch();
  const feedsProductsFilterNoCategory = useSelector(feedsProductsFiltersWithoutCategory);
  switch (column.type) {
    case tableDataTypes.feedsProductsStatisticsCategoryLink.type: {
      const onClick = () => {
        dispatch(contentProductActions.setSendQuery(true));
        dispatch(push(pageLinks.content.products.all(value.value)));
        dispatch(actions.mergeProductsStatistic({ modalOpened: false }));
      };
      return (
        <ChipFormatter onClick={onClick} clickable value={value} />
      );
    }
    case tableDataTypes.categoryFilterFeedProducts.type: {
      const onClick = () => {
        const checkedFiltersArray = [...feedsProductsFilterNoCategory.toJS()]
          .filter(item => item.columnName !== column.name);

        dispatch(actions.setFilters([...checkedFiltersArray, {
          columnName: column.name,
          value: [value],
        }]));
      };
      return (
        <ChipFormatter
          onClick={onClick}
          clickable
          value={value}
        />
      );
    }
    default: {
      return <ChipFormatter value={value} />;
    }
  }
}

export default memo(CategoryCell);
