// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ArrowUpSorting(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 8 14" {...props}>
      <path d="M4.40487 0.167863C4.3518 0.114652 4.28875 0.0724362 4.21933 0.0436316C4.14991 0.014827 4.0755 0 4.00034 0C3.92518 0 3.85077 0.014827 3.78135 0.0436316C3.71194 0.0724362 3.64888 0.114652 3.59581 0.167863L0.167563 3.59611C0.0602743 3.7034 0 3.84891 0 4.00064C0 4.15237 0.0602743 4.29788 0.167563 4.40517C0.274852 4.51246 0.420367 4.57274 0.572096 4.57274C0.723825 4.57274 0.86934 4.51246 0.976629 4.40517L3.42897 1.95169V13.1426C3.42897 13.2942 3.48916 13.4395 3.59632 13.5466C3.70347 13.6538 3.8488 13.714 4.00034 13.714C4.15188 13.714 4.29721 13.6538 4.40436 13.5466C4.51152 13.4395 4.57171 13.2942 4.57171 13.1426V1.95169L7.02405 4.40517C7.13134 4.51246 7.27686 4.57274 7.42859 4.57274C7.58031 4.57274 7.72583 4.51246 7.83312 4.40517C7.94041 4.29788 8.00068 4.15237 8.00068 4.00064C8.00068 3.84891 7.94041 3.7034 7.83312 3.59611L4.40487 0.167863Z" fill="#303030" fillOpacity="0.72" />
    </SvgIcon>
  );
}

export default ArrowUpSorting;
