// Core
import { apply, put, select } from 'redux-saga/effects';
// lodash
import uniqBy from 'lodash/uniqBy';
import forEach from 'lodash/forEach';
import concat from 'lodash/concat';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';

export function* callGetListWorker({ payload }) {
  const totalCount = yield select(selectors.categoriesFeatureManagement.totalCount);
  yield put(actions.setList({ pending: true }));
  const { params, treeDataRowIds } = payload;
  const response = yield apply(api, api.categoriesFeatureManagement.getList, [params, treeDataRowIds]);

  let data = {
    items: [],
    totalCount,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  if (response && response.length > 0) {
    const dataFromState = yield select(selectors.categoriesFeatureManagement.list);
    forEach(response, (responseData) => {
      data.items = uniqBy(concat(responseData.data.items, dataFromState.toJS()), 'id');
    });
  }
  yield put(actions.setList(data));
}
