// Core
import { fromJS } from 'immutable';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

const initialState = fromJS({
  submitting: false,
});

export const stateKeys = Object.freeze({
  controller: 'password',
  submitting: 'submitting',
});

export const passwordReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_RESET_PASSWORD_SUBMITTING: {
      const { payload: submitting } = action;
      return state.set(stateKeys.submitting, submitting);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
