import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as synonymsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const synonyms = Object.freeze({
  currentPage: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.currentPage], 0),
  filters: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.filters], List()),
  isLoading: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.pending], false),
  list: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.items], List()),
  pageSize: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.reload], false),
  totalCount: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.totalCount], 0),
  sorting: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.sorting], List()),
  settingStatus: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.settingStatus], false),
  settingPending: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.settingPending], false),
  selection: state => getIn(state, [synonymsKeys.controller, synonymsKeys.synonymsList, synonymsKeys.selection], List()),
});
