// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { actions as partnerActions } from '../../../../engine/core/partners/actions';
import { accessList } from '../../../../engine/config/access';
import { useAccessList } from '../../../hooks/useAccessList';

function PartnerConfirmFormRejectButton(props) {
  const { isDisplay, disabled } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const partnerConfirmForm = useAccessList(accessList.partnerConfirmForm);

  const approvePartner = () => {
    dispatch(partnerActions.setReasonRejectionModal({
      open: true,
    }));
  };


  if (!partnerConfirmForm || !isDisplay) {
    return null;
  }

  return (
    <ButtonWithIcon
      text={t('Отклонить партнера')}
      disabled={disabled}
      onClick={approvePartner}
    >
      <CancelIcon />
    </ButtonWithIcon>
  );
}

PartnerConfirmFormRejectButton.propTypes = {
  disabled: PropTypes.bool,
  isDisplay: PropTypes.bool,
};

export default PartnerConfirmFormRejectButton;
