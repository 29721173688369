export const formName = 'PartnerAccessEdit';
export const formFields = Object.freeze({
  trackingStatus: 'trackingStatus',
  active: 'active',
  documentsLink: 'documentsLink',
  paymentDocumentLink: 'paymentDocumentLink',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  partnerPaymentType: 'partnerPaymentType',
  contractSigned: 'contractSigned',
  licenseeStatement: 'licenseeStatement',
  iban: 'iban',
});
