// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  partnersList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    selection: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  partnersTopExport: Map({
    pending: false,
  }),
  partnersTopImport: Map({
    pending: false,
    isOpen: false,
  }),
  partnersListByStatus: Map({
    /*
      // for example Active
      12: Map({
        pending: false,
        items: List(),
        totalCount: 0,
      }),
    */
  }),
  partnersListWithMid: Map({
    pending: false,
    items: List(),
  }),
  partnersDownload: Map({
    pending: false,
  }),
  partnersPaymentTypes: Map({
    pending: false,
    items: List(),
  }),
  reasonRejectionModal: Map({
    open: false,
  }),
  partnersProcess: Map({
    pending: false,
  }),
  partnerHashIdConfirm: Map({
    pending: false,
  }),
  partnerOrderProcessing: Map({
    pending: false,
    isModalOpen: false,
  }),
  partnerDeliveryDelayPeriod: Map({
    pending: false,
    isModalOpen: false,
  }),
  partnersDeliveryDelayDelete: Map({
    pending: false,
    showModal: false,
    hashId: null,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'partners',
  partnersDownload: 'partnersDownload',
  partnersList: 'partnersList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  selection: 'selection',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  partnersPaymentTypes: 'partnersPaymentTypes',
  reasonRejectionModal: 'reasonRejectionModal',
  partnersProcess: 'partnersProcess',
  cancelStatuses: 'cancelStatuses',
  disableStatuses: 'disableStatuses',
  partnerHashIdConfirm: 'partnerHashIdConfirm',
  partnersListByStatus: 'partnersListByStatus',
  partnersListWithMid: 'partnersListWithMid',
  partnersTopImport: 'partnersTopImport',
  partnersTopExport: 'partnersTopExport',
  isOpen: 'isOpen',
  partnerOrderProcessing: 'partnerOrderProcessing',
  partnerDeliveryDelayPeriod: 'partnerDeliveryDelayPeriod',
  isModalOpen: 'isModalOpen',
  partnersDeliveryDelayDelete: 'partnersDeliveryDelayDelete',
  hashId: 'hashId',
  showModal: 'showModal',
});

export const partnersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNERS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.partnersList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.partnersList, stateKeys.pending], pending)
        .setIn([stateKeys.partnersList, stateKeys.items], List(items))
        .setIn([stateKeys.partnersList, stateKeys.totalCount], totalCount);
    }
    case types.MERGE_PARTNERS_DOWNLOAD: {
      return state.mergeIn([stateKeys.partnersDownload], payload);
    }
    case types.MERGE_PARTNERS_LIST: {
      return state.mergeIn([stateKeys.partnersList], payload);
    }
    case types.SET_PARTNERS_SORTING: {
      return state.setIn([stateKeys.partnersList, stateKeys.sorting], List(payload));
    }
    case types.SET_PARTNERS_SELECTION: {
      return state.setIn([stateKeys.partnersList, stateKeys.selection], List(payload));
    }
    case types.SET_PARTNERS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.partnersList, stateKeys.filters], List(filters));
    }
    case types.SET_PARTNERS_PAGE_SIZE: {
      return state.setIn([stateKeys.partnersList, stateKeys.pageSize], payload);
    }
    case types.SET_PARTNERS_CURRENT_PAGE: {
      return state.setIn([stateKeys.partnersList, stateKeys.currentPage], payload);
    }
    case types.SET_PARTNERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.partnersList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.partnersList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.partnersList, stateKeys.filters], List(filters))
        .setIn([stateKeys.partnersList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.partnersList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.partnersList, stateKeys.reload], reload)
        .setIn([stateKeys.partnersList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PARTNERS_SETTING_STATUS: {
      return state.setIn([stateKeys.partnersList, stateKeys.settingStatus], payload);
    }
    case types.SET_PARTNERS_SETTING_PENDING: {
      return state.setIn([stateKeys.partnersList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.MERGE_PARTNERS_PAYMENT_TYPES: {
      return state.mergeIn([stateKeys.partnersPaymentTypes], payload);
    }
    case types.MERGE_PARTNERS_PROCESS: {
      return state.mergeIn([stateKeys.partnersProcess], Map(payload));
    }
    case types.MERGE_REASON_REJECTION: {
      return state.mergeIn([stateKeys.reasonRejectionModal], Map(payload));
    }
    case types.MERGE_PARTNERS_TOP_IMPORT: {
      return state.mergeIn([stateKeys.partnersTopImport], payload);
    }
    case types.MERGE_PARTNER_ORDER_PROCESSING: {
      return state.mergeIn([stateKeys.partnerOrderProcessing], payload);
    }
    case types.MERGE_PARTNER_DELIVERY_DELAY_PERIOD: {
      return state.mergeIn([stateKeys.partnerDeliveryDelayPeriod], payload);
    }
    case types.MERGE_PARTNERS_TOP_EXPORT: {
      return state.mergeIn([stateKeys.partnersTopExport], payload);
    }
    case types.MERGE_PARTNERS_HASH_ID_CONFIRM: {
      return state.mergeIn([stateKeys.partnerHashIdConfirm], payload);
    }
    case types.MERGE_PARTNERS_LIST_BY_STATUS: {
      const { statusId, data } = payload;
      return state.mergeIn([stateKeys.partnersListByStatus, statusId], data);
    }
    case types.SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_MODAL_SHOW: {
      return state.setIn([stateKeys.partnersDeliveryDelayDelete], Map(payload));
    }
    case types.MERGE_PARTNERS_LIST_WITH_MID: {
      const { data } = payload;
      return state.mergeIn([stateKeys.partnersListWithMid], data);
    }
    default: {
      return state;
    }
  }
};
