// Core
import { apply, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { pageLinks } from '../../../../config/routes';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPostMessageWorker({ payload: { method, ...params } }) {
  let response;
  const methodSend = params.send ? 'send' : 'save';

  yield put(actions.mergeMessageRequestState({
    [methodSend]: true,
  }));

  switch (method) {
    case 'post': {
      response = yield apply(api, api.messages.postMessages, [params]);
      break;
    }
    case 'put': {
      response = yield apply(api, api.messages.putMessages, [params]);
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status, statusTitle } = data;

    yield put(actions.mergeMessageRequestState({
      [methodSend]: false,
    }));

    switch (status) {
      case requestStatus.success: {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(push(pageLinks.messages.all));
        break;
      }
      case requestStatus.error: {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
}
