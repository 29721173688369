export const types = Object.freeze({
  SET_MESSAGES_LIST: 'SET_MESSAGES_LIST',
  SET_MESSAGES_CURRENT_PAGE: 'SET_MESSAGES_CURRENT_PAGE',
  SET_MESSAGES_FILTERS: 'SET_MESSAGES_FILTERS',
  SET_MESSAGES_PAGE_SIZE: 'SET_MESSAGES_PAGE_SIZE',
  SET_MESSAGES_SORTING: 'SET_MESSAGES_SORTING',
  SET_MESSAGES_RELOAD: 'SET_MESSAGES_RELOAD',
  SET_MESSAGE_BY_HASH_ID: 'SET_MESSAGE_BY_HASH_ID',
  MERGE_MESSAGE_REQUEST_STATE: 'MERGE_MESSAGE_REQUEST_STATE',
  MERGE_MESSAGES_USER_TYPES: 'MERGE_MESSAGES_USER_TYPES',
  SET_MESSAGES_INITIAL_STATE: 'SET_MESSAGES_INITIAL_STATE',
  SET_MESSAGES_SETTING_STATUS: 'SET_MESSAGES_SETTING_STATUS',
  SET_MESSAGES_SETTING_PENDING: 'SET_MESSAGES_SETTING_PENDING',
  MERGE_MESSAGES_USER_TYPES_BY_TYPE: 'MERGE_MESSAGES_USER_TYPES_BY_TYPE',
  MERGE_MESSAGES_USER_TYPES_BY_TYPE_ITEMS: 'MERGE_MESSAGES_USER_TYPES_BY_TYPE_ITEMS',
  MERGE_MESSAGES_STATUSES: 'MERGE_MESSAGES_STATUSES',
  MERGE_RECIPIENTS_IMPORT: 'MERGE_RECIPIENTS_IMPORT',
  MERGE_PARTNERS_NOT_FOUND_MODAL: 'MERGE_PARTNERS_NOT_FOUND_MODAL',
  MERGE_RECIPIENTS_IMPORT_ITEMS: 'MERGE_RECIPIENTS_IMPORT_ITEMS',
});
