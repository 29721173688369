// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { asyncTypes } from './asyncTypes';

export const userActionAsync = Object.freeze({
  signUpAsync(userData) {
    return getActionCreator(asyncTypes.SIGN_UP_ASYNC, userData);
  },
  getMailSettingsAsync() {
    return getActionCreator(asyncTypes.GET_MAIL_SETTINGS_ASYNC);
  },
  getUsersPartnerRolesAsync(data) {
    return getActionCreator(asyncTypes.GET_USERS_PARTNER_ROLES_ASYNC, data);
  },
  putMailSettingsAsync(data) {
    return getActionCreator(asyncTypes.PUT_MAIL_SETTINGS_ASYNC, data);
  },
  signInAsync(signInData) {
    return getActionCreator(asyncTypes.SIGN_IN_ASYNC, signInData);
  },
  phoneVerifyCode(code) {
    return getActionCreator(asyncTypes.POST_PHONE_VERIFY_CODE, code);
  },
  getRepeatSendPhoneCode() {
    return getActionCreator(asyncTypes.GET_REPEAT_SEND_PHONE_CODE);
  },
  confirmCodeAsync(confirmCode) {
    return getActionCreator(asyncTypes.CONFIRM_CODE_ASYNC, confirmCode);
  },
  logOutAsync(serverLogOut) {
    return getActionCreator(asyncTypes.LOG_OUT_ASYNC, serverLogOut);
  },
  setUserInfoAsync(userData) {
    return getActionCreator(asyncTypes.SET_USER_INFO_ASYNC, userData);
  },
  getUserInfoAsync(params = {}) {
    return getActionCreator(asyncTypes.GET_USER_INFO_ASYNC, params);
  },
  putUserInfoAsync(userInfo) {
    return getActionCreator(asyncTypes.PUT_USER_INFO_ASYNC, userInfo);
  },
  getUsersListAsync(params = {}) {
    return getActionCreator(asyncTypes.GET_USERS_LIST_ASYNC, params);
  },
  postUsersAsync(data) {
    return getActionCreator(asyncTypes.POST_USERS_ASYNC, data);
  },
  getUsersRolesAsync() {
    return getActionCreator(asyncTypes.GET_USERS_ROLES_ASYNC);
  },
  getUserByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_USER_BY_HASH_ID_ASYNC, hashId);
  },
  putUserByHashIdAsync(params) {
    return getActionCreator(asyncTypes.PUT_USER_BY_HASH_ID_ASYNC, params);
  },
  getUsersSelfAsync(params) {
    return getActionCreator(asyncTypes.GET_USERS_SELF_ASYNC, params);
  },
  authCleanUp() {
    return getActionCreator(asyncTypes.AUTH_CLEAN_UP);
  },
  postUserInfoAsync(data) {
    return getActionCreator(asyncTypes.POST_USER_INFO_ASYNC, data);
  },
  setUserNotificationsAsync() {
    return getActionCreator(asyncTypes.SET_USER_NOTIFICATIONS_ASYNC);
  },
});
