// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { useSelector } from 'react-redux';
// Components
import { useLocation } from 'react-router-dom';
import Loading from '../../../../../../components/Loading/Loading';
// Helpers
import WorkspaceStyles from './WorkspaceStyle';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';

function Workspace(props) {
  const { classes, children, opened } = props;

  const newDesignPagesPath = [
    '/shipping_integration/edit',
  ];

  const isLoading = useSelector(selectors.ui.isLoading);
  const appReactHash = useSelector(selectors.statistics.appReactHash);
  const location = useLocation();
  const currentPath = location.pathname;
  const isPathNewDesign = newDesignPagesPath.some(path => currentPath.startsWith(path));

  const mainClassNames = cx(classes.content, classes['content-left'], {
    [classes['no-padding']]: isPathNewDesign,
    [classes.contentShift]: opened,
    [classes['contentShift-left']]: opened,
  });

  return (
    <main className={mainClassNames} id="main-wrapper">
      {isLoading || appReactHash === null ? (
        <Loading className={classes.fullHeight} isLoading />
      ) : (
        children
      )}
    </main>
  );
}

Workspace.prototypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool,
};
export default withStyles(WorkspaceStyles)(Workspace);
