// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { reset } from 'redux-form';
import {
  Form, Field, reduxForm, formValueSelector,
} from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SaveIcons from '@mui/icons-material/Save';
// Parts
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import ImportResultXlsxBlock from '../ImportResult/ImportResultXlsxBlock';
import NotificationText from '../../../../../../components/Notifications/NotificationText';
import ModalStyle from '../../../../../../containers/Modals/Modal/ModalStyle';
import RenderFilesUploader from './components/RenderFilesUploader';
// Engine
import { accessList } from '../../../../../../../engine/config/access';
import { mimeTypeExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
// Helpers
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { validators } from '../../../../../../../_helpers/validationsForm';
import { usePrevious } from '../../../../../../hooks/usePrevious';
import { COLOR } from '../../../../../../containers/App/AppStyles';
import { useStyles } from '../../../../../../hooks/useStyles';

export const form = Object.freeze({
  name: 'ProductsPricesImportModal',
  fields: Object.freeze({
    productsFile: 'productsFile',
  }),
});
const selector = formValueSelector(form.name);

function ProductsPricesImportModal(props) {
  const {
    handleModalToggle, handleSubmit, pending,
    modalOpen, pristine, valid, submitting,
  } = props;
  const classes = useStyles(ModalStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessProductImport = useAccessList(accessList.productImport);
  const importModal = useSelector(selectors.content.importPricesModal);
  const productsImportPending = useSelector(selectors.content.productsImportPending);
  const feedproductsFile = useSelector(state => selector(state, form.fields.productsFile));
  const prevModal = usePrevious(modalOpen);

  useEffect(() => {
    if (hasAccessProductImport) {
      if (!modalOpen) {
        dispatch(actions.setProductsImportModalInitialState());
      }
      if (prevModal === true && modalOpen === false) {
        dispatch(reset(form.name));
      }
    }
  }, [hasAccessProductImport, modalOpen, dispatch, prevModal]); //eslint-disable-line

  const onFormSubmit = (values) => {
    const { productsFile } = values.toJS();

    dispatch(asyncActions.postProductsPricesImportAsync({ productsFile }));
  };

  if (!hasAccessProductImport) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      addText={t('Сохранить')}
      iconSendButton={<SaveIcons />}
      disabledSend={pristine || submitting || productsImportPending || !valid || !feedproductsFile || !feedproductsFile.length}
      title={t('Импорт цен и наличия')}
      loadingSend={productsImportPending}
      handleModalAdd={handleSubmit}
      modalMaxWidth="xs"
      fullScreen={false}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={RenderFilesUploader}
          loadFileFromSaga
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.productsFile}
          disabled={pending}
          className="import_modal"
          validate={[validators.itemsRequiredValidator]}
        />
        <NotificationText
          icon={<FeedbackOutlinedIcon sx={{ color: COLOR.error }} />}
          title={t('Внимание!')}
          titleColor={COLOR.error}
          border={`1px solid ${COLOR.error}`}
          backgroundColor="rgba(224, 0, 39, 0.1)"
        >
          {t("Если среди изменяемых товаров, есть товары в автоматическом режиме управления ценой/наличием - при изменении 'Стоимости'/'Акционной стоимости'/'Наличия', они будут переведены в ручной режим управления ценой/наличием.")}
        </NotificationText>
        <ImportResultXlsxBlock classes={classes} importModal={importModal} />
      </Form>
    </Modal>
  );
}

ProductsPricesImportModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleModalToggle: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
};
ProductsPricesImportModal.defaultProps = {
  pristine: false,
  submitting: false,
  pending: false,
  handleSubmit: () => {},
};

export default reduxForm({
  form: form.name,
})(ProductsPricesImportModal);
