// Core
import * as PropTypes from 'prop-types';
// Parts
import RadioButtons from '../../../RadioButtons/RadioButtons';

const RenderRadioField = (formFieldRenderProps) => {
  const {
    input, margin = 'normal', getApprovedValue, getCurrentValue, getOldValue,
    approveInfoField, isShowMode, setShowInput, showInput, boolToTextOnCompare, ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  return (
    <RadioButtons
      disabled={isShowMode}
      {...rest}
      setShowInput={setShowInput}
      showInput={showInput}
      fullWidth
      approvedValue={getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue(inputName)}
      valueOld={getOldValue(inputName, boolToTextOnCompare)}
      validation
    />
  );
};

RenderRadioField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  margin: PropTypes.string,
  getApprovedValue: PropTypes.func,
  getCurrentValue: PropTypes.func,
  getOldValue: PropTypes.func,
  approveInfoField: PropTypes.func,
  isShowMode: PropTypes.bool,
  setShowInput: PropTypes.func,
  showInput: PropTypes.bool,
  boolToTextOnCompare: PropTypes.bool,
};

export default RenderRadioField;
