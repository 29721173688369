// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
// UI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Loading from '../../../components/Loading/Loading';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import Modal from '../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../engine/config/selectors';
// helpers
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import { api } from '../../../../engine/config/api';
import { setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { COLOR } from '../../../containers/App/AppStyles';
import { emailRegExp, validators } from '../../../../_helpers/validationsForm';


const LicenseModal = ({
  isOpen, closeModal, hashId, partnerHashId,
}) => {
  const { t } = useTranslation();
  const partnerData = useSelector(selectors.partner.userData);
  const partnerDataLoading = useSelector(selectors.partner.partnerIsLoading);
  const companyType = partnerData?.infoFieldValues?.enterpriseType?.current;
  const partnerEmail = partnerData?.infoFieldValues?.contactEmail?.current;
  const dispatch = useDispatch();
  const [value, setValue] = useState('ФОП');
  const [email, setEmail] = useState(partnerEmail);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [pending, setPending] = useState(false);

  const handleChangeCompanyType = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (partnerHashId !== undefined) {
      dispatch(partnerAsyncAction.getPartnerInfo({ hashId: partnerHashId }));
    }
  }, [partnerHashId]);

  useEffect(() => {
    if (companyType !== undefined) {
      setValue(companyType);
    }
    if (partnerEmail !== undefined) {
      setEmail(partnerEmail);
    }
  }, [companyType, partnerEmail]);

  const validateEmail = emailAddress => emailRegExp.test(emailAddress);

  useEffect(() => {
    if (email) {
      if (email?.endsWith('.ru')) {
        setError(true);
        setHelperText(t('Не поддерживаем ru домен'));
      } else if (email.length > 100) {
        setError(true);
        setHelperText('Лимит 100 символов');
      } else if (validateEmail(email)) {
        setError(false);
        setHelperText('');
      } else {
        setError(true);
        setHelperText(t('Не валидный email адрес'));
      }
    } else {
      setError(false);
      setHelperText('');
    }
  }, [email]);

  const handleChange = (input, event) => {
    setEmail(event.target.value);
  };

  const handleSend = async () => {
    setPending(true);
    const payload = {
      action: 'send_licensee_email',
      payload: {
        enterpriseType: value,
        email,
      },
    };
    const response = await api.partners.postLicense({ hashId, payload });
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(response.data.message, response.data.statusTitle));
      closeModal();
    }
    setPending(false);
  };

  return (
    <Modal
      title={t('Отправка заявления лицензиата')}
      modalOpen={isOpen}
      handleModalCancel={closeModal}
      handleModalToggle={closeModal}
      handleModalSend={handleSend}
      disabledSend={error || partnerDataLoading || pending}
      modalMaxWidth="sm"
    >
      {partnerDataLoading ? <Loading isLoading />
        : (
          <FormControl sx={{ width: '100%', padding: '12px 16px' }}>
            <Box
              sx={{
                border: `1px solid ${COLOR.grey[38]}`,
                borderRadius: '4px',
                padding: '0px 16px 12px',
                width: '100%',
              }}
              component="fieldset"
            >
              <FormLabel
                component="legend"
                id="demo-controlled-radio-buttons-group"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px',
                  fontSize: '12px',
                  color: COLOR.grey[38],
                  '&.Mui-focused': {
                    color: COLOR.grey[38],
                  },
                }}
              >{t('Тип предприятия')}&nbsp;<Typography sx={{ color: 'red' }}> *</Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChangeCompanyType}
              >
                <FormControlLabel value="ФОП" control={<Radio />} label={t('ФОП')} sx={{ maxWidth: '20%' }} />
                <FormControlLabel value="ТОВ" control={<Radio />} label={t('ТОВ')} sx={{ maxWidth: '20%' }} />
              </RadioGroup>
            </Box>
            <Box sx={{ marginTop: '20px' }}>
              <TextField
                meta={{ error: helperText, touched: error }}
                label="Email"
                value={email}
                customOnChange={handleChange}
                defaultValue={partnerEmail}
                fullWidth
                validation
                validate={[validators.email, validators.maxLength100]}
              />
            </Box>
          </FormControl>
        )}
    </Modal>
  );
};

LicenseModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  hashId: PropTypes.string,
  partnerHashId: PropTypes.string,
};

export default LicenseModal;
