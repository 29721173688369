// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/partners/managers/actions';
import { asyncActions } from '../../../../../../engine/core/partners/managers/saga/asyncActions';

// Hooks
import { usePartnersManagersList } from '../_hooks/usePartnersManagersList';

// parts
import DxTable from '../../../../../components/_Table/DxTable/DxTable';

// Redux
function PartnersManagersTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.partnersManagers.isLoading);
  // Rows
  const rows = useSelector(selectors.partnersManagers.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.partnersManagers.currentPage);
  const pageSize = useSelector(selectors.partnersManagers.pageSize);
  const totalCount = useSelector(selectors.partnersManagers.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.partnersManagers.sorting);
  // Settings
  const settingStatus = useSelector(selectors.partnersManagers.settingStatus);
  const settingPending = useSelector(selectors.partnersManagers.settingPending);
  // Filtering
  const filters = useSelector(selectors.partnersManagers.filters);

  return (
    <DxTable
      name="partnersManagers"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePartnersManagersList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.partnersManagers.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(PartnersManagersTable);
