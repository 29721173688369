// Core
import { apply, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { form } from '../../../../../ui/page/Products/Content/ContentProducts/components/ProductsPricesImportModal/ProductsPricesImportModal';

export function* callPostProductsPricesImportWorker({ payload }) {
  yield put(actions.setImportPricesModalIsOpen({ pending: true }));
  yield put(actions.setProductImportPending(true));

  const response = yield apply(api, api.feedsProducts.postProductsPricesImport, [payload]);
  const modalState = {
    pending: false,
    statusTitle: '',
    errors: [],
  };

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, statusTitle } = responseData;
    const importModal = yield select(selectors.content.importModal);
    const isCurrentModalOpen = importModal.get('isOpen') && importModal.get('pending');

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        if (isCurrentModalOpen) {
          modalState.statusTitle = statusTitle;
          modalState.errors = errors;
          modalState.status = status;
          break;
        }

        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message, minorErrors, successCounter } = responseData;
        yield put(reset(form.name));
        if (isCurrentModalOpen) {
          modalState.statusTitle = message;
          modalState.errors = minorErrors;
          modalState.status = requestStatus.error;
          modalState.successCounter = successCounter;
          modalState.hideSubmitButton = true;
          break;
        }

        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setImportPricesModalIsOpen(modalState));
  yield put(actions.setProductImportPending(false));
}
