// Engine
import { useDownMDView } from './useDesktopView';

export function useEditCellWidth(accessArray = [], minCount = 0, conWidthUpMD = 34, conWidthDownMD = 34) {
  const isDownMDView = useDownMDView();
  const accessCount = accessArray.filter(Boolean).length;
  const buttonCount = accessCount + minCount;

  return buttonCount * (isDownMDView ? conWidthUpMD : conWidthDownMD) + 8;
}
