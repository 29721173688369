import { COLOR } from '../../../../containers/App/AppStyles';

const BalancePartnerStyles = () => ({
  balanceMenuButton: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR.darkStyle.fontColorMain,
    padding: '3px 8px 2px 9px',
    flexShrink: 0,
    marginRight: '10px',
    minWidth: '89px',
    borderRadius: '5px',
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  helpMenuButtonActive: {
    background: COLOR.darkStyle.white['8'],
  },
  balanceMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '8px',
  },
  balanceIcon: {
    width: '24px',
    height: '24px',
    marginBottom: '3px',
    color: COLOR.darkStyle.fontColorMain,
  },
  balanceDropIcon: {
    width: '16px',
    height: '16px',
  },
  balanceDropIconActive: {
    transform: 'rotate(180deg)',
  },
  balanceTitle: {
    fontSize: '14px',
    lineHeight: 1,
    color: '#ffffff',
  },
  balancesMenu: {
    zIndex: 301,
    '& .MuiMenu-paper': {
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      background: COLOR.darkStyle.main,
      zIndex: 1300,
    },
    '& .MuiList-root': {
      padding: '5px 10px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${COLOR.darkStyle.white['24']}`,
    color: COLOR.darkStyle.white['72'],
    fontSize: '14px',
    lineHeight: '14px',
    minHeight: '40px',
    padding: '6px 10px 6px 0',
    cursor: 'default',
    '&:hover, &:focus, &:active': {
      background: 'transparent',
    },
    '&:first-child': {
      borderTop: 'none',
    },
  },
  creditItem: {
    display: 'block',
    paddingTop: '15px',
    paddingBottom: '13px',
  },
  menuItemValue: {
    color: '#ffffff',
  },
  negativeValue: {
    color: COLOR.brand.main,
  },
  menuItemValueNegative: {
    color: COLOR.brand.dark,
  },
  creditTitle: {
    fontSize: '12px',
    lineHeight: '16px',
    color: COLOR.darkStyle.white['72'],
    marginTop: '20px',
    marginBottom: '9px',
    display: 'block',
  },
  creditDate: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR.darkStyle.white['72'],
    fontSize: '12px',
    lineHeight: 1,
    '& svg': {
      marginRight: '7px',
      marginLeft: '2px',
      width: '15px',
      height: '15px',
      color: COLOR.darkStyle.white['72'],
    },
  },
});

export default BalancePartnerStyles;
