// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// UI
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// Parts
import IsLoading from '../Loading/Loading';
import SupportButton from './components/SupportButton';
import RenderMenu from './components/SidebarMenu';
// Engine
import { selectors } from '../../../engine/config/selectors';
// hooks
import { useStyles } from '../../hooks/useStyles';
// Style
import SidebarStyles from './SidebarStyle';

function Sidebar(props) {
  const {
    opened, toggleDrawer,
  } = props;
  const classes = useStyles(SidebarStyles);
  const isLoading = useSelector(selectors.ui.isLoading);

  return (
    <>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: opened ? classes.drawerPaper : classes.miniDrawerPaper,
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          {isLoading
            ? <IsLoading className={classes.fullHeight} isLoading />
            : <RenderMenu opened={opened} />}
          <SupportButton opened={opened} />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={opened}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          disableBackdropTransition={false}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          {isLoading
            ? <IsLoading className={classes.fullHeight} isLoading />
            : <RenderMenu opened={opened} />}
          <SupportButton opened={opened} />
        </SwipeableDrawer>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  opened: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default memo(Sidebar);
