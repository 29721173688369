// Core
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import values from 'lodash/values';
import {
  Form, initialize, isPristine, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
// UI
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
import HeaderComponent from './components/HeaderComponent';
import ParametersTableCell from './components/ParametersTableCell';
import HelpModalInitialLoadPriceListOptions from './components/HelpModalInitialLoadPriceListOptions';
import HelpModalEditPriceListOptions from './components/HelpModalEditPriceListOptions';
import TooltipCantFinish from '../TooltipCantFinish';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/products/productFeedParameters/actions';
import { asyncActions } from '../../../../../../../engine/core/feeds/all/saga/asyncActions';
import { userRoles } from '../../../../../../../engine/config/userRoles';
import { asyncActions as asyncActionsProductsFeeds } from '../../../../../../../engine/core/feeds/products/saga/asyncActions';
// Helpers
import { locale } from '../../../../../../../engine/init/i18n';
import { dispatchCompletionListItemData } from '../../_helpers/dispatchCompletionListItemData';
import { useProductFeedParametersList } from './_hooks/useProductFeedParametersList';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';

import CompleteImportModal from '../CompleteImportModal';
import { IMPORT_STEPS } from '../../constants';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const formName = 'productFeed_step1';
export const submitTypeFrom = {
  saveAndComplete: 'saveAndComplete',
  saveAndContinue: 'saveAndContinue',
  save: 'save',
};
export const completionPriceListModalConfig = {
  steps: {
    step_1: 'step_1',
    step_2: 'step_2',
    step_3: 'step_3',
  },
  type: {
    edit: 'edit',
    import: 'import',
  },
};

const FilterComponent = () => null;
function ProductFeedParametersTable(props) {
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const isItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerPaymentWaiting,
  ]);
  const { feedStatusData, canEdit, canParse } = isItemByHashIdItem.toJS();
  // const pristine = useSelector(isPristine(formName));
  const pristine = useSelector(state => isPristine(formName)(state));
  const canSendHooks = isItemByHashIdLoading === false && feedStatusData === undefined;
  useProductFeedParametersList(canSendHooks);

  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const { hashId } = useParams();

  const {
    handleSubmit, disable, handleNextStep, invalid, setStepDisabled,
  } = props;
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.productFeedParameters.isLoading);
  // Rows and columns
  const rows = useSelector(selectors.productFeedParameters.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const grid = useMemo(() => {
    const lang = locale[language];
    return gridSettings.productFeedParameters.gridSettings.map(({ width, ...item }) => ({
      ...item,
      width: '25%', // TODO set from back-end
      title: item.lang?.[lang],
    }));
  }, [gridSettings, language]);
  const columns = values(grid);
  // const currentPage = useSelector(selectors.productFeedParameters.currentPage);
  // const totalCount = useSelector(selectors.productFeedParameters.totalCount);
  // Sorting
  // const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.productFeedParameters.sorting);
  // Settings
  const settingStatus = useSelector(selectors.productFeedParameters.settingStatus);
  const settingPending = useSelector(selectors.productFeedParameters.settingPending);
  // Filtering
  const filters = useSelector(selectors.productFeedParameters.filters);

  const completionImportList = useSelector(selectors.feeds.completionImportList);
  const completionImportListNormalized = completionImportList?.toJS();

  const stepKey = completionPriceListModalConfig.steps.step_1;
  const isEditMode = completionImportListNormalized[hashId]?.isSaved;
  const modalTypeKey = isEditMode
    ? completionPriceListModalConfig.type.edit
    : completionPriceListModalConfig.type.import;

  useEffect(() => {
    // import mode
    if (isPartnerRoles && !completionImportListNormalized[hashId] && !isEditMode) {
      dispatchCompletionListItemData({
        dispatch,
        hashId,
        completionImportList: completionImportListNormalized,
        modalTypeKey,
        stepKey,
        value: true,
        isOpened: false,
        isSaved: false,
      });
    }
  }, []);

  useEffect(() => {
    // edit mode
    if (
      isPartnerRoles
      && isEditMode
      && completionImportListNormalized[hashId]
      && !completionImportListNormalized?.[hashId]?.[modalTypeKey]?.[stepKey]?.isOpened
    ) {
      dispatchCompletionListItemData({
        dispatch,
        hashId,
        completionImportList: completionImportListNormalized,
        modalTypeKey,
        stepKey,
        value: true,
        isOpened: false,
      });
    }
  }, []);


  const exitCallback = () => {
    dispatch(asyncActionsProductsFeeds.postParseByHashIdAsync({ hashId }));
    dispatch(asyncActions.putCompletionImportListItemAsync({ hashId, data: { isSaved: true } }));
  };

  const onSubmit = (event, type) => {
    event.preventDefault();
    const onSuccessHandler = {};

    if (type !== submitTypeFrom.save) {
      onSuccessHandler.onSuccess = type === submitTypeFrom.saveAndComplete ? exitCallback : handleNextStep;
    }

    handleSubmit((data) => {
      dispatch(asyncActions.putFeedItemByHashId({
        hashId,
        step: 1,
        parameters: data?.toJS(),
        ...onSuccessHandler,
      }));
    })(formValues);

    dispatch(initialize(formName, formValues, Object.keys(formValues)));
  };

  const saveAndCompleteHandler = (value) => {
    onSubmit(value, submitTypeFrom.saveAndComplete);
  };
  const saveAndContinueHandler = (value) => {
    onSubmit(value, submitTypeFrom.saveAndContinue);
  };
  const saveHandler = (value) => {
    onSubmit(value, submitTypeFrom.save);
  };

  const showConfirmModal = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => {
    if (invalid) {
      setStepDisabled(prev => ({ ...prev, [IMPORT_STEPS.CATEGORIES]: true, [IMPORT_STEPS.CATEGORIES_ATTRIBUTES]: true }));
    } else {
      setStepDisabled(prev => ({ ...prev, [IMPORT_STEPS.CATEGORIES]: false, [IMPORT_STEPS.CATEGORIES_ATTRIBUTES]: true }));
    }
  }, [invalid]);

  return (
    <StyledForm>
      <Box sx={{ flexGrow: 1 }}>
        <DxTable
          type="static"
          showAllItems
          rowFilterComponent={FilterComponent}
          customCellComponent={ParametersTableCell}
          name="productFeedParameters"
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          // Paging
          // totalCount={totalCount}
          // currentPage={currentPage}
          // Sorting
          // onSortingChange={onSortingChange}
          sorting={sorting}
          // Filters
          filters={filters}
          filtersAction={actions.setFilters}
          // Settings
          // gridSettings={gridSettings.productFeedParameters.gridSettings}
          settingStatus={settingStatus}
          settingPending={settingPending}
          customHeaderCellComponent={HeaderComponent}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'end',
      }}
      >
        <div>
          {!pristine && isEditMode
            ? (
              <>
                <TooltipCantFinish text={t('Происходит процесс автоматического сопоставления характеристик и их значений. Кнопка “Сохранить и завершить импорт” будет доступна после его завершения.')}>
                  <Tooltip title={invalid ? t('Заполните обязательные поля, чтобы продолжить') : ''}>
                    <span>
                      <LoadingButton
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={disable || pristine || !canEdit || !canParse || invalid}
                        loading={disable}
                        onClick={showConfirmModal}
                      >
                        {t('Сохранить и завершить импорт')}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </TooltipCantFinish>
                <TooltipCantFinish text={t('Происходит процесс автоматического сопоставления характеристик и их значений. Кнопка “Сохранить и продолжить импорт” будет доступна после его завершения.')}>
                  <Tooltip title={invalid ? t('Заполните обязательные поля, чтобы продолжить') : ''}>
                    <span>
                      <LoadingButton
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={disable || pristine || !canEdit || !canParse || invalid}
                        loading={disable}
                        onClick={saveAndContinueHandler}
                      >
                        {t('Сохранить и продолжить импорт')}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </TooltipCantFinish>
              </>
            ) : null
          }
          {!isEditMode
            ? (
              <Tooltip title={invalid ? t('Заполните обязательные поля, чтобы сохранить сопоставление') : ''}>
                <span>
                  <LoadingButton
                    variant="contained"
                    sx={{ mt: 1, mr: 1 }}
                    disabled={disable || pristine || !canEdit || invalid}
                    loading={disable}
                    onClick={saveHandler}
                  >
                    {t('Сохранить')}
                  </LoadingButton>
                </span>
              </Tooltip>
            )
            : null
          }
          {!isEditMode || (isEditMode && pristine)
            ? (
              <Tooltip title={invalid ? t('Заполните обязательные поля, чтобы продолжить') : ''}>
                <span>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleNextStep}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={disable || invalid}
                    loading={disable}
                  >
                    {t('Продолжить')}
                  </LoadingButton>
                </span>
              </Tooltip>
            )
            : null
          }
        </div>
      </Box>
      { isPartnerRoles && isEditMode ? (<HelpModalEditPriceListOptions />) : null}
      { isPartnerRoles && !isEditMode ? (<HelpModalInitialLoadPriceListOptions />) : null}
      { isShowConfirmModal ? (
        <CompleteImportModal
          showModal
          setShowConfirmModal={setShowConfirmModal}
          onConfirm={saveAndCompleteHandler}
          disable={disable}
        />
      ) : null}
    </StyledForm>
  );
}

export default reduxForm({
  form: formName,
})(ProductFeedParametersTable);
