// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import get from 'lodash/get';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
// Data
import { formName } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { getDateWithCurrentLocal } from '../../../../../_helpers/getDate';
import { dateStringShortFormat } from '../../../../config/globalConfig';

export function* callGetProductsHashIdWorker({ payload: hashId }) {
  yield put(actions.setIsProductByHashIdLoading(true));
  yield put(actions.setProductByHashId({}));

  const response = yield apply(api, api.products.getProductsHashId, [{ hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;

    const {
      editable, hashId: unusedHashId, ...rest
    } = data;

    yield put(asyncActions.getProductsSettingsAsync({ categoryId: rest?.category?.value }));
    rest.productMedias = rest.productMedias.map(({
      id, externalLink, responseCode, checkedForMgAt, checkedAt, alloLink,
    }) => ({
      id,
      primary: '',
      secondary: externalLink,
      src: externalLink,
      alloLink,
      key: id,
      responseCode,
      checkedForMgAt: getDateWithCurrentLocal(checkedForMgAt, dateStringShortFormat),
      checkedAt: getDateWithCurrentLocal(checkedAt, dateStringShortFormat),
    }));

    rest.sku = get(rest.sku, 'value', rest.sku);
    rest.manager = get(rest.manager, 'value', rest.manager || 0);

    yield put(initialize(formName, rest, Object.keys(rest)));
    yield put(actions.setProductByHashId(data));
  }

  yield put(actions.setIsProductByHashIdLoading(false));
}
