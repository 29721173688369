const InputFileStyles = () => ({
  inputFile: {
    display: 'none',
  },
  btnAttach: {
    pointerEvents: 'none',
    padding: 0,
    width: '100%',
    height: '100%',
  },
  inputLabel: {
    height: '100%',
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default InputFileStyles;
