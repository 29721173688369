// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

// Types
import { asyncTypes } from './asyncTypes';

export const priceListAsyncAction = Object.freeze({
  downloadPriceListAsync(Id) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_PRICE_LIST_DOWNLOAD_ASYNC, Id);
  },
  postUploadPriceListAsync(data) {
    return getActionCreator(asyncTypes.POST_PRICE_CREATOR_PRICE_LIST_UPLOAD_ASYNC, data);
  },
});
