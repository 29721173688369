import React, {
  useEffect, memo, useState,
} from 'react';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import without from 'lodash/without';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import cx from 'classnames';
// Parts
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
// Engine
import { userActions } from '../../../../../engine/core/user/action';
import { selectors } from '../../../../../engine/config/selectors';
import { userActionAsync } from '../../../../../engine/core/user/saga/asyncAction';
import Loading from '../../../../components/Loading/Loading';
// Hooks
import { useStyles } from '../../../../hooks/useStyles';
// Style
import UserPartnerRolesFieldStyles from './UserPartnerRolesFieldStyles';
import { COLOR } from '../../../../containers/App/AppStyles';


function UserPartnerRolesField(props) {
  const classes = useStyles(UserPartnerRolesFieldStyles);
  const { t } = useTranslation();
  const {
    formName, name, namePartnerField, disabled, validate, fullWidth,
    margin, required,
  } = props;

  const dispatch = useDispatch();

  const items = (useSelector(selectors.user.userPartnerRolesItems)).toJS();
  const pending = useSelector(selectors.user.userPartnerRolesPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const syncErrors = useSelector(state => selectors.form.getSyncErrors(state, formName));
  const submitFailed = useSelector(state => selectors.form.submitFailed(state, formName));
  const normalizeFormValue = formValues.toJS();
  const checkedAllItem = items.find(item => item.checkedAll);
  const [state, setState] = useState(normalizeFormValue[name] || []);
  const [checkedAll, setCheckedAll] = useState(undefined);
  const hasError = !!(submitFailed && syncErrors[name]);

  const partnerIdFromArray = normalizeFormValue[namePartnerField]?.[0]?.value;
  const partnerIdFromObject = normalizeFormValue[namePartnerField]?.value;
  const partnerId = partnerIdFromArray || partnerIdFromObject;
  // Classes
  const fieldSetClassNames = cx(classes.fieldSet, { [classes.fieldSetError]: submitFailed && syncErrors[name] });
  const legendClassNames = cx(classes.legend, { [classes.legendError]: submitFailed && syncErrors[name] });

  useEffect(() => {
    if (partnerId !== undefined) {
      dispatch(userActionAsync.getUsersPartnerRolesAsync({ partnerId }));
    }
  }, [dispatch, partnerId]);

  useEffect(() => {
    if (
      checkedAll === undefined
      && normalizeFormValue?.[name]?.length
      && normalizeFormValue[name].includes(checkedAllItem?.value)
    ) {
      setCheckedAll(true);
    }
  }, [checkedAll, checkedAllItem, name, normalizeFormValue]);

  useEffect(() => () => {
    dispatch(userActions.mergeGetPartnerRoles({ items: List() }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(change(formName, name, state));
  }, [dispatch, formName, name, state]);

  useEffect(() => {
    if (checkedAll && (state.length !== items.length)) {
      const allItems = items.map(listItem => listItem.value);
      setState(allItems);
    }
  }, [checkedAll, items, state.length]);

  if (partnerId === undefined) {
    return null;
  }

  const Items = items.length === 0 ? <span className={classes.disabled}>{t('Нет доступных ролей пользователя')}</span> : (
    <>
      <Field
        component={TextField}
        name={name}
        isHidden
        validate={validate}
      />
      {items.map((item) => {
        const onChange = () => {
          if (item.checkedAll && !checkedAll) {
            const allItems = items.map(listItem => listItem.value);
            setCheckedAll(true);
            setState(allItems);
            return false;
          }
          if (item.checkedAll && checkedAll) {
            setCheckedAll(false);
            setState([]);
            return false;
          }
          if (state.includes(item.value)) {
            setCheckedAll(false);
            setState(without(state, item.value));
          } else {
            setCheckedAll(false);
            setState([...state, item.value]);
          }
        };
        return (
          <div>
            <FormControlLabel
              className={classes.label}
              onChange={onChange}
              key={item.value}
              disabled={(checkedAll && item.value !== checkedAllItem?.value) || disabled}
              checked={state.includes(item.value)}
              control={<Checkbox disabled={disabled} name={`partnerRoles_${item.value}`} />}
              label={t(item.label)}
            />
          </div>
        );
      })}
    </>
  );

  return (
    <FormControl error={hasError} fullWidth={fullWidth} margin={margin}>
      <fieldset className={fieldSetClassNames}>
        <legend className={legendClassNames}>
          {t('Роль партнера')}
          {required && <Box sx={{ color: COLOR.error }} component="span">*</Box>}
        </legend>
        { pending
          ? <Loading isLoading />
          : Items
        }
      </fieldset>
      { hasError && <FormHelperText>{ syncErrors[name] }</FormHelperText> }
    </FormControl>
  );
}

UserPartnerRolesField.propsType = {
  partnerId: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  namePartnerField: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.array,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
};

export default memo(UserPartnerRolesField);
