import { getIn, List, Map } from 'immutable';

// config
import { pageSizes } from '../../config/globalConfig';
import { stateKeys as ordersKeys } from './reducer';

export const orders = Object.freeze({
  currentPage: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.currentPage], 0),
  filters: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.filters], List()),
  isLoading: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.pending], false),
  settingStatus: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.settingStatus], false),
  settingPending: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.settingPending], false),
  orderEditPending: state => getIn(state, [ordersKeys.controller, ordersKeys.orderEdit, ordersKeys.pending], false),
  list: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.items], List()),
  orderItemByHashId: state => getIn(state, [ordersKeys.controller, ordersKeys.orderItemByHashId], Map()),
  pageSize: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.pageSize], pageSizes[0]),
  totalCount: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.totalCount], 0),
  sorting: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.sorting], List()),
  statuses: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatusesList], {}),
  ordersStatusesListPending: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatusesListPending], false),
  paymentTypes: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersPaymentTypes], Map({ pending: false, item: {} })),
  cancelStatuses: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatusesList, ordersKeys.cancelStatuses], []),
  orderStatuses: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatusesList, ordersKeys.orderStatuses], []),
  statusesFlow: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatusesList, ordersKeys.statusesFlow], {}),
  ordersUploadIsUploading: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersUpload, ordersKeys.pending], false),
  ordersUploadError: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersUpload, ordersKeys.error], []),
  ordersUploadSuccess: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersUpload, ordersKeys.success], ''),
  ordersStatistics: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersStatistics], Map()),
  csvData: state => getIn(state, [ordersKeys.controller, ordersKeys.csvData], Map()),
  expandedRowIds: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.expandedRowIds], List()),
  isLoadingOrdersDownloadCsvButton: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersDownloadCsv, ordersKeys.pending], false),
  isDownloadFullOrdersLoading: state => getIn(state, [ordersKeys.controller, ordersKeys.downloadFullOrders, ordersKeys.pending], false),
  reload: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersList, ordersKeys.reload], false),
  isModalOpenOrdersUpload: state => getIn(state, [ordersKeys.controller, ordersKeys.ordersUpload, ordersKeys.modalOpen], false),
  orderUnbindShippingTrackingPending: state => getIn(state, [ordersKeys.controller, ordersKeys.orderUnbindShippingTracking, ordersKeys.pending], false),
  errorModal: state => getIn(state, [ordersKeys.controller, ordersKeys.errorModal], Map()),
});
