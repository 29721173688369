import { Trans } from 'react-i18next';

export const importLinkXML = ({ hasAdminAccess }) => ([
  {
    title: <Trans>Выберите формат файла</Trans>,
    target: '#xml_radio_btn',
    content: <Trans>Выберите XML-формат файла для загрузки.</Trans>,
    disableBeacon: true,
    placement: 'bottom-start',
    offset: 6,
  },
  {
    title: <Trans>Выберите язык контента прайс-листа</Trans>,
    target: '#choose_lang_card',
    content: <Trans>Выберите язык прайс-листа. Можно выбрать как один, так и оба языка.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
  ...(hasAdminAccess ? [{
    title: <Trans>Выберите партнера</Trans>,
    target: '#import_mid_partner',
    content: <Trans>Выберите партнера и перейдите к следующему шагу.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  }] : []),
  {
    title: <Trans>Ознакомьтесь с требованиями к прайс-листу</Trans>,
    target: '#requirements_for_file',
    content: <Trans>Загрузите шаблон и проверьте, верно ли составлен ваш прайс-лист.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  },
  {
    title: <Trans>Добавьте файл или ссылку</Trans>,
    target: '#import_link_radio',
    content: <Trans>Выберите вариант загрузки «Ссылка».</Trans>,
    disableBeacon: true,
    placement: 'top-start',
    offset: 6,
  },
  {
    title: <Trans>Адрес ссылки</Trans>,
    target: '#import_file_link',
    content: <Trans>Укажите ссылку на XML-файл с прайс-листом.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Импортировать»</Trans>,
    target: '#import_button_send',
    content: <Trans>Нажмите кнопку «Импортировать».</Trans>,
    disableBeacon: true,
    placement: 'top-start',
    offset: 6,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  },
]);
export const importProductManual = ({ isDownXLView }) => ([
  {
    title: <Trans>Выберите раздел «Товары»</Trans>,
    target: '#linkToProduct',
    content: <Trans>Перейдите в раздел «Товары», нажав на соответствующий пункт меню.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  },
  ...(isDownXLView ? [{
    title: <Trans>Кнопка «Добавить товар»</Trans>,
    target: '#product_add_button',
    content: <Trans>В разделе «Товары» нажмите на кнопку «Добавить товар».</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  }] : [{
    title: <Trans>Кнопка «Добавить товар»</Trans>,
    target: '#product_add_button',
    content: <Trans>В разделе «Товары» нажмите на кнопку «Добавить товар».</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  }]),
  // {
  //   title: <Trans>Кнопка «Добавить товар»</Trans>,
  //   target: '#product_add_button',
  //   content: <Trans>В разделе «Товары» нажмите на кнопку «Добавить товар».</Trans>,
  //   disableBeacon: true,
  //   placement: 'bottom',
  //   offset: 6,
  // },
]);
export const importXLSX = ({ hasAdminAccess }) => ([
  {
    title: <Trans>Выберите формат файла</Trans>,
    target: '#xlsx_radio_btn',
    content: <Trans>Выберите XLSX-формат файла для загрузки.</Trans>,
    disableBeacon: true,
    placement: 'bottom-start',
    offset: 6,
    styles: {
      spotlight: {
        opacity: 0,
      },
      overlay: {
        opacity: 1,
        height: '100vh',
      },
    },
  },
  {
    title: <Trans>Выберите категорию.</Trans>,
    target: '#choose_category_btn',
    content: <Trans>Выберите необходимую категорию и загрузите на свое устройство соответствующий шаблон. Если ваш ассортимент товаров охватывает различные категории, можно загрузить соответствующие им шаблоны.</Trans>,
    disableBeacon: true,
    placement: 'bottom-start',
    offset: 6,
    styles: {
      spotlight: {
        opacity: 0,
        marginTop: '1px',
      },
      overlay: {
        opacity: 1,
        height: '100vh',
      },
    },
  },
  ...(hasAdminAccess ? [{
    title: <Trans>Выберите партнера</Trans>,
    target: '#partner_select_import_xlsx',
    content: <Trans>Выберите партнера и перейдите к следующему шагу.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  }] : []),
  {
    title: <Trans>Выберите категорию товаров</Trans>,
    target: '#choose_category_select',
    content: <Trans>Выберите нужную категорию товаров, проверьте, что она соответствует категории шаблона, который вы использовали.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
  {
    title: <Trans>Добавьте заполненный шаблон</Trans>,
    target: '#file_pond_target',
    content: <Trans>Нажмите на ссылку «Выберите файл» и выберите на своем устройстве сохраненный файл.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Импортировать»</Trans>,
    target: '#import_button_send_xlsx',
    content: <Trans>Нажмите кнопку «Импортировать».</Trans>,
    disableBeacon: true,
    placement: 'top-start',
    offset: 6,
  },
]);


export const importFileXML = ({ hasAdminAccess }) => ([
  {
    title: <Trans>Выберите формат файла</Trans>,
    target: '#xml_radio_btn',
    content: <Trans>Выберите XML-формат файла для загрузки.</Trans>,
    disableBeacon: true,
    placement: 'bottom-start',
    offset: 6,
  },
  {
    title: <Trans>Выберите язык контента прайс-листа</Trans>,
    target: '#choose_lang_card',
    content: <Trans>Выберите язык прайс-листа. Можно выбрать как один, так и оба языка.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
  ...(hasAdminAccess ? [{
    title: <Trans>Выберите партнера</Trans>,
    target: '#import_mid_partner',
    content: <Trans>Выберите партнера и перейдите к следующему шагу.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  }] : []),
  {
    title: <Trans>Ознакомьтесь с требованиями к прайс-листу</Trans>,
    target: '#requirements_for_file',
    content: <Trans>Загрузите шаблон и проверьте, верно ли составлен ваш прайс-лист.</Trans>,
    disableBeacon: true,
    placement: 'right-start',
    offset: 6,
  },
  {
    title: <Trans>Добавьте файл или ссылку</Trans>,
    target: '#import_file_radio',
    content: <Trans>Выберите вариант загрузки «Файл».</Trans>,
    disableBeacon: true,
    placement: 'top-start',
    offset: 6,
  },
  {
    title: <Trans>Загрузка файла</Trans>,
    target: '.file_pond_xml',
    content: <Trans>Загрузите XML-файл с прайс-листом.</Trans>,
    disableBeacon: true,
    placement: 'right',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Импортировать»</Trans>,
    target: '#import_button_send',
    content: <Trans>Нажмите кнопку «Импортировать».</Trans>,
    disableBeacon: true,
    placement: 'top-start',
    offset: 6,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  },
]);
