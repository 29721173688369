// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ChatRightIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_7070_341097)">
        <path d="M7.99978 1.11108C4.07978 1.11108 0.888672 3.9022 0.888672 7.33331C0.888672 10.7644 4.07978 13.5555 7.99978 13.5555C8.73318 13.5547 9.46298 13.453 10.1687 13.2533L12.6264 14.8178C12.6935 14.8608 12.771 14.885 12.8507 14.8878C12.9303 14.8907 13.0093 14.872 13.0793 14.8339C13.1493 14.7957 13.2077 14.7394 13.2484 14.6709C13.2892 14.6024 13.3108 14.5241 13.3109 14.4444V11.4533C13.867 10.9198 14.3108 10.2805 14.6161 9.57294C14.9214 8.86538 15.0821 8.1039 15.0887 7.33331C15.1109 3.9022 11.9198 1.11108 7.99978 1.11108ZM12.5731 10.9378C12.5284 10.9792 12.4927 11.0294 12.4683 11.0852C12.4438 11.141 12.4311 11.2013 12.4309 11.2622V13.6355L10.4842 12.3955C10.4299 12.3612 10.3688 12.3391 10.3051 12.3306C10.2415 12.3222 10.1767 12.3276 10.1153 12.3466C9.43033 12.5594 8.71707 12.6673 7.99978 12.6666C4.56867 12.6666 1.77756 10.2755 1.77756 7.33331C1.77756 4.39108 4.56867 1.99997 7.99978 1.99997C11.4309 1.99997 14.222 4.39108 14.222 7.33331C14.2119 8.01556 14.0601 8.68826 13.7762 9.30875C13.4924 9.92924 13.0827 10.484 12.5731 10.9378Z" fill="inherit" />
        <path d="M11.111 6.88892H4.88878C4.77091 6.88892 4.65786 6.93574 4.57451 7.01909C4.49116 7.10244 4.44434 7.21549 4.44434 7.33336C4.44434 7.45123 4.49116 7.56428 4.57451 7.64763C4.65786 7.73098 4.77091 7.7778 4.88878 7.7778H11.111C11.2289 7.7778 11.3419 7.73098 11.4253 7.64763C11.5086 7.56428 11.5554 7.45123 11.5554 7.33336C11.5554 7.21549 11.5086 7.10244 11.4253 7.01909C11.3419 6.93574 11.2289 6.88892 11.111 6.88892Z" fill="inherit" />
        <path d="M9.66645 9.11108H6.33312C6.21524 9.11108 6.1022 9.15791 6.01885 9.24126C5.9355 9.32461 5.88867 9.43765 5.88867 9.55553C5.88867 9.6734 5.9355 9.78645 6.01885 9.8698C6.1022 9.95315 6.21524 9.99997 6.33312 9.99997H9.66645C9.78432 9.99997 9.89737 9.95315 9.98072 9.8698C10.0641 9.78645 10.1109 9.6734 10.1109 9.55553C10.1109 9.43765 10.0641 9.32461 9.98072 9.24126C9.89737 9.15791 9.78432 9.11108 9.66645 9.11108Z" fill="inherit" />
        <path d="M5.01329 5.55558H10.9866C11.1045 5.55558 11.2175 5.50875 11.3009 5.4254C11.3842 5.34205 11.4311 5.22901 11.4311 5.11113C11.4311 4.99326 11.3842 4.88021 11.3009 4.79686C11.2175 4.71351 11.1045 4.66669 10.9866 4.66669H5.01329C4.89542 4.66669 4.78237 4.71351 4.69902 4.79686C4.61567 4.88021 4.56885 4.99326 4.56885 5.11113C4.56885 5.22901 4.61567 5.34205 4.69902 5.4254C4.78237 5.50875 4.89542 5.55558 5.01329 5.55558Z" fill="inherit" />
      </g>
      <defs>
        <clipPath id="clip0_7070_341097">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ChatRightIcon;
