// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';

// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutStatusCategoryWorker({ payload: { hashId, key, value } }) {
  yield put(actions.updatePriceCreatorCategoriesListItem({
    hashId,
    key,
    pending: true,
  }));
  const response = yield apply(api, api.priceCreatorCategories.putStatusCategory, [{
    hashId,
    [key]: value,
  }]);

  if (response && response.status >= 200 && response.status < 400) {
    const {
      status, statusTitle, message, errors,
    } = response.data;

    switch (status) {
      case requestStatus.error: {
        yield put(setErrorMessage(errors || message, statusTitle));
        yield put(actions.updatePriceCreatorCategoriesListItem({
          hashId,
          key,
          value,
          error: true,
          success: false,
          pending: false,
        }));
        break;
      }
      case requestStatus.success: {
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.updatePriceCreatorCategoriesListItem({
          hashId,
          key,
          value,
          error: false,
          success: true,
          pending: false,
        }));
        break;
      }
      default: {
        break;
      }
    }
  }
}
