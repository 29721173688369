// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isImmutable } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';

// Engine
import { asyncActions } from '../../../../../../engine/core/bankInstallments/saga/asyncActions';
import { actions } from '../../../../../../engine/core/bankInstallments/actions';
import { selectors } from '../../../../../../engine/config/selectors';
import { requestDelay } from '../../../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../_helpers/convertDataToUrlParams';

export function useBankInstallmentsDataList(hashId, refresh) {
  const currentPage = useSelector(selectors.bankInstallments.currentPage);
  const limit = useSelector(selectors.bankInstallments.pageSize);
  const filters = useSelector(selectors.bankInstallments.filters);
  const sorting = useSelector(selectors.bankInstallments.sorting);
  const reload = useSelector(selectors.bankInstallments.reload);
  const treeDataRowIds = useSelector(selectors.bankInstallments.treeDataRowIds);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const offset = limit * currentPage;
  const filtersStringify = JSON.stringify(filters.toJS());
  const sortingStringify = JSON.stringify(sorting.toJS());
  const getTreeDataRowIds = useMemo(() => isImmutable(treeDataRowIds)
    ? treeDataRowIds.toJS()
    : treeDataRowIds,
  [treeDataRowIds]);
  const isEmptyTreeDataRowIds = isEmpty(getTreeDataRowIds);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const defaultFilters = useMemo(() => ({
    loadRootCategories: isEmpty(getTreeDataRowIds),
    ...(isEmpty(filters.toJS()) ? {
      parentId: 0,
    } : {}),
    orderBy: isEmpty(orderBy) ? 'name ASC' : orderBy,
  }), [orderBy, getTreeDataRowIds, filters]);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const getListAsync = useCallback(() => {
    const params = {
      hashId,
      ...paramsByFilter,
      ...defaultFilters,
      ...(isEmpty(getTreeDataRowIds) ? {
        limit,
        offset,
      } : {}),
    };
    dispatch(asyncActions.getListAsync(params, getTreeDataRowIds));
  }, [
    dispatch,
    offset, limit, paramsByFilter,
    defaultFilters, getTreeDataRowIds,
  ]);


  const getInitialStateAsync = useCallback(() => {
    if (isEmptyTreeDataRowIds) {
      dispatch(asyncActions.getInitialStateAsync(path));
    }
  }, [dispatch, path, isEmptyTreeDataRowIds]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    dispatch(actions.setReload(true));
  }, [dispatch, treeDataRowIds]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync,
    getInitialStateAsync,
    reload, refresh, timeout, routeKey]);
}
