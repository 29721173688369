// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/orderHistory/actions';
// Hooks
import { asyncActions } from '../../../../../engine/core/orderHistory/saga/asyncActions';
import { useOrderHistoryList } from './useOrderHistoryList';

function OrderHistoryTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.orderHistory.isLoading);
  // Rows
  const rows = useSelector(selectors.orderHistory.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.orderHistory.currentPage);
  const pageSize = useSelector(selectors.orderHistory.pageSize);
  const totalCount = useSelector(selectors.orderHistory.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.orderHistory.sorting);
  // Settings
  const settingStatus = useSelector(selectors.orderHistory.settingStatus);
  const settingPending = useSelector(selectors.orderHistory.settingPending);
  // Filtering
  const filters = useSelector(selectors.orderHistory.filters);

  const disableFiltering = [
    {
      columnName: 'statusFrom',
      filteringEnabled: false,
    },
    {
      columnName: 'statusTo',
      filteringEnabled: false,
    },
  ];

  return (
    <DxTable
      name="orderStatusHistory"
      type="static"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useOrderHistoryList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      filteringExtensions={disableFiltering}
      // Settings
      gridSettings={gridSettings.orderStatusHistory.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default OrderHistoryTable;
