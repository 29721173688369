// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { api, requestStatus } from '../../../../../config/api';
import { selectors } from '../../../../../config/selectors';
import { actions as feedActions } from '../../../../feeds/all/actions';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { asyncActions } from '../asyncActions';
import { asyncActions as feedCategoriseAttributesAsyncActions } from '../../../productFeedCategoriesAttributes/saga/asyncActions';
import { productFeedCategoriesAttributes } from '../../../productFeedCategoriesAttributes/selectors';
import { productFeedCategoriesAttributesConst } from '../../../const';

export function* callPutItemByHashId({ payload }) {
  yield put(actions.setList({ pending: true }));
  const { feedHashId, hasAccessProductFeedAutoConnect, ...categoryPayload } = payload;
  yield put(actions.setHashIdPending(true));
  const response = yield apply(api, api.productFeedCategories.putItemByHashId, [categoryPayload]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, productFeedCategoryStatuses } = responseData;

    if (productFeedCategoryStatuses) {
      yield put(feedActions.mergeFeedsItemByHashIdStepDataSecond({ productFeedCategoryStatuses }));
    }

    switch (status) {
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        if (hasAccessProductFeedAutoConnect) {
          const pageSize = yield select(productFeedCategoriesAttributes.pageSize);
          const sorting = (yield select(productFeedCategoriesAttributes.sorting)).toJS();
          const filters = (yield select(productFeedCategoriesAttributes.filters)).toJS();
          filters.push({
            columnName: 'categoryStatus',
            value: [{
              value: productFeedCategoriesAttributesConst.AUTO_CONNECT_STATUS,
            }],
          });

          const params = {
            sorting,
            limit: pageSize,
          };

          filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, params);

          yield put(feedCategoriseAttributesAsyncActions.getListAsync(
            params,
            feedHashId,
            productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_CHANGE_CATEGORY,
          ));
        }
        break;
      }
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default:
        break;
    }

    const pageSize = yield select(selectors.productFeedCategories.pageSize);
    const currentPage = yield select(selectors.productFeedCategories.currentPage);
    const limit = yield select(selectors.productFeedCategories.pageSize);
    const sorting = (yield select(selectors.productFeedCategories.sorting)).toJS();
    const filters = (yield select(selectors.productFeedCategories.filters)).toJS();
    const offset = limit * currentPage;

    const params = {
      sorting,
      limit: pageSize,
      offset,
    };

    filters.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);

    const isRepeatedRequest = true;
    yield put(asyncActions.getListAsync(params, feedHashId, isRepeatedRequest));
  }
  yield put(actions.setHashIdPending(false));
}
