// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import { stateKeys } from '../../reducers';

export function* callGetPartnersContextWorker({ payload }) {
  const partnerReducerName = payload?.reducerName;
  const listContextLoading = yield select(selectors.partner.listContextLoading);
  const partnersListContextBillingPaymentForPartnerContextLoading = yield select(selectors.partner.partnersListContextBillingPaymentForPartnerContextLoading);
  if (listContextLoading || partnersListContextBillingPaymentForPartnerContextLoading) {
    return null;
  }
  if (partnerReducerName === stateKeys.partnersListContextBillingPaymentForPartner) {
    yield put(partnerActions.setPartnerContextBillingPaymentForPartnerPending(true));
  } else {
    yield put(partnerActions.setPartnerContextPending(true));
  }

  const { partners, partnersLastUpdate } = localData.keysObj;
  const partnersLastUpdateFromLocalData = yield apply(localData, localData.getItem, [partnersLastUpdate]);
  const partnersLastUpdateFromStatistics = yield select(selectors.statistics.partnersLastUpdate);

  let response;
  let data = {
    items: [],
  };
  const params = {
    context: 'select',
    partnerStatus: [],
  };

  if (partnerReducerName === stateKeys.partnersListContextBillingPaymentForPartner) {
    params.partnerStatus = [
      { value: partnerStatuses.active },
      { value: partnerStatuses.awaitingPayment },
      { value: partnerStatuses.blocked },
    ];
  }

  switch (true) {
    case !partnersLastUpdateFromLocalData:
    case partnersLastUpdateFromLocalData !== partnersLastUpdateFromStatistics: {
      response = yield apply(api, api.partner.getPartnersList, [params]);
      yield apply(localData, localData.setItem, [partnersLastUpdate, partnersLastUpdateFromStatistics]);
      break;
    }
    case partnersLastUpdateFromLocalData === partnersLastUpdateFromStatistics: {
      const dataFromLocal = yield apply(localData, localData.getItem, [partners + partnerReducerName]);
      if (!dataFromLocal) {
        response = yield apply(api, api.partner.getPartnersList, [params]);
      }
      data = { items: dataFromLocal?.items };
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [partners + partnerReducerName, data]);
  }
  if (partnerReducerName === stateKeys.partnersListContextBillingPaymentForPartner) {
    yield put(partnerActions.setPartnerContextBillingPaymentForPartnerList(data?.items));
    yield put(partnerActions.setPartnerContextBillingPaymentForPartnerPending(false));
  } else {
    yield put(partnerActions.setPartnerContextList(data?.items));
    yield put(partnerActions.setPartnerContextPending(false));
  }
}
