const NotificationStyle = () => ({
  iconInfoColor: {
    fill: '#568da7',
  },
  wrapper: {
    cursor: 'auto',
    width: '100%',
    wordBreak: 'break-word',
    fontSize: 14,
  },
  linkTruncate: {
    fontSize: 14,
  },
  wrapperIcons: {
    marginRight: '3px',
  },
  title: {
    margin: 0,
    marginBottom: 5,
    '& svg': {
      verticalAlign: 'top',
    },
  },
  message: {
    margin: 0,
    color: 'inherit',
    wordBreak: 'break-word',
  },
  containerLink: {
    cursor: 'pointer',
  },
  btnClose: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
});

export default NotificationStyle;
