// Core
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../components/_Table/DxTable/DxTable';
import HeaderComponent from '../../../components/_Table/TableHeaderWithTooltip/HeaderWithTooltip';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/priceCreator/priceCreatorCategories/actions';
// Parts
import FormatterComponent from './FormatterComponent';
// Hooks
import { usePriceCreatorCategoriesList } from '../PriceCreatorCategories/_hooks/usePriceCreatorCategoriesList';
import { asyncActions } from '../../../../engine/core/priceCreator/priceCreatorCategories/saga/asyncActions';
import { tableDataTypes } from '../../../../_helpers/data/tableDataTypes';

function CustomHeaderCellComponent(props) {
  const { column } = props;
  const isHiddenTooltip = useMemo(() => {
    switch (column.name) {
      case 'xmlFileId':
      case 'xmlFile':
        return true;
      default:
        return false;
    }
  }, [column]);

  return <HeaderComponent isHiddenTooltip={isHiddenTooltip} {...props} />;
}

function PriceCreatorCategoriesTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.priceCreatorCategories.isLoading);
  // Rows
  const rows = useSelector(selectors.priceCreatorCategories.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.priceCreatorCategories.currentPage);
  const pageSize = useSelector(selectors.priceCreatorCategories.pageSize);
  const totalCount = useSelector(selectors.priceCreatorCategories.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.priceCreatorCategories.sorting);
  // Settings
  const settingStatus = useSelector(selectors.priceCreatorCategories.settingStatus);
  const settingPending = useSelector(selectors.priceCreatorCategories.settingPending);
  // Filtering
  const filters = useSelector(selectors.priceCreatorCategories.filters);

  return (
    <DxTable
      name="xmlCategories"
      showShortText
      isLoading={isLoading}
      rows={rows}
      dataTypeProviderProps={{
        formatterComponent: FormatterComponent,
        for: [tableDataTypes.enabled.name],
      }}
      updateHooks={usePriceCreatorCategoriesList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.xmlCategories.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
      // Header
      customHeaderCellComponent={settingStatus ? undefined : CustomHeaderCellComponent}
    />
  );
}

export default memo(PriceCreatorCategoriesTable);
