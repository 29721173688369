// Core
import { useTranslation } from 'react-i18next';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
// Parts
import ComponentWithTooltip from '../../../../../../../containers/ComponentWithTooltip';
// Engine
import { locale } from '../../../../../../../../engine/init/i18n';
// Helpers
import { useStyles } from '../../../../../../../hooks/useStyles';
import Styles from './styles';

const HeaderCellComponent = (cellProps) => {
  const { i18n } = useTranslation();
  const { children: cellChildren, column: cellColumn } = cellProps;
  const currentLocale = locale[i18n.language];
  const descCellColumn = cellColumn?.desc?.[currentLocale] && <b>{cellColumn?.desc?.[currentLocale]}</b>;
  const classes = useStyles(Styles);

  return (
    <TableHeaderRow.Cell
      {...cellProps}
      column={cellColumn}
      draggingEnabled={false}
      resizingEnabled={false}
    >
      <ComponentWithTooltip description={descCellColumn}>
        <div className={classes.descriptionLabel}>{cellChildren}</div>
      </ComponentWithTooltip>
    </TableHeaderRow.Cell>
  );
};

export default HeaderCellComponent;
