// Core
import {
  memo, useMemo, useState, useRef, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
// Parts
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NumberFormatPrice from '../../../NumberFormat/NumberFormatPrice';
// Helpers
import { Description, StyledTextField, Wrapper } from './styles';
import { COLOR } from '../../../../containers/App/AppStyles';

const TextField = (props) => {
  const {
    autoComplete, required, fullWidth,
    margin, validation, autoFocus,
    focusComponent, meta, label,
    disabled, type, input,
    endAdornment, isHidden, isPrice,
    isRed, color, onPaste,
    textColor, multiline, rows,
    inputComponent, descriptionComponent,
    startAdornment, readOnly, placeholder, isRecommended,
    isClearable, setIsClearable,
    timeout, wrapperClassName, onKeyUp, isError, defaultValue, customOnChange,
    helperTextAfter, helperTextBefore, customOnBlur, inputmode, dataTestId, inputLabelProps, maxLength,
  } = props;
  const { touched, error } = meta;
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const hasError = validation && touched && typeof error !== 'undefined';
  const helperText = hasError ? error : undefined;
  const inputProps = {
    autoComplete: autoComplete || 'new-password',
    required: false,
    inputmode,
    maxLength,
    'data-testId': dataTestId,
  };
  const isPassword = type === 'password';
  const typeFieldPassword = isPassword && showPassword ? 'text' : type;
  const toggleHandlerPassword = () => setShowPassword(!showPassword);
  const onFocus = (focusParams) => {
    if (focusComponent) {
      setFocused(true);
    }
    if (input.onFocus) {
      input.onFocus(focusParams);
    }
  };
  const onBlur = (blurParams) => {
    if (customOnBlur) {
      customOnBlur();
    }
    // without timeout, it is not possible to click on the link inside focusComponent
    setTimeout(() => {
      if (focusComponent) {
        setFocused(false);
      }
      if (input.onBlur) {
        input.onBlur(blurParams);
      }
    }, timeout);
  };

  const handleChange = (event) => {
    if (customOnChange) {
      customOnChange(input, event);
    } else {
      input.onChange(event);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  const InputProps = useMemo(() => {
    switch (true) {
      case isPassword:
        return {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={toggleHandlerPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          ),
        };
      case isPrice:
        return {
          endAdornment: <InputAdornment sx={{ '& p': { fontSize: '14px', lineHeight: 1 } }} position="end">&#x20b4;</InputAdornment>,
          inputComponent: NumberFormatPrice,
          inputProps: { allowNegative: false },
          style: {
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
              },
            },
          },
        };
      case isClearable:
        return {
          readOnly,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="button"
                onClick={() => {
                  input.onChange('');
                  setIsClearable(false);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment,
          inputComponent,
        };
      default:
        return {
          readOnly,
          endAdornment,
          startAdornment,
          inputComponent,
        };
    }
  }, [
    isPassword, isPrice, showPassword,
    endAdornment, startAdornment, inputComponent,
    readOnly, isClearable,
  ]);

  const sx = useMemo(() => ({
    ...(textColor && {
      '& input': {
        color: textColor,
        textFillColor: `${textColor} !important`,
      },
    }),
    ...(isRed && {
      '& input': {
        color: COLOR.error,
        textFillColor: `${COLOR.error} !important`,
        backgroundColor: COLOR.bgError,
      },
    }),
  }), [textColor, isRed]);

  return (
    <Wrapper color={color} sx={isHidden ? { display: 'none' } : undefined} className={wrapperClassName}>
      {helperTextBefore && (
        <FormHelperText sx={{
          marginTop: margin === 'normal' ? '16px' : undefined,
          marginBottom: margin === 'normal' ? '-16px' : undefined,
          color: hasError || isError ? COLOR.error : 'initial',
        }}
        >
          {helperTextBefore}
          {required && <Box sx={{ color: COLOR.error }} component="span">*</Box>}
        </FormHelperText>
      )}
      <StyledTextField
        {...input}
        inputRef={inputRef}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onPaste={onPaste}
        InputProps={InputProps}
        // eslint-disable-next-line
        inputProps={inputProps}
        error={hasError || isError}
        fullWidth={fullWidth}
        required={required || isRecommended}
        isRecommended={isRecommended}
        margin={margin}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        type={isPassword ? typeFieldPassword : type}
        variant="outlined"
        color={color}
        helperText={helperText || helperTextAfter}
        sx={sx}
        multiline={multiline}
        rows={rows}
        onKeyUp={onKeyUp}
        defaultValue={defaultValue}
        InputLabelProps={inputLabelProps}
      />
      {/* <Typography sx={{
        color: 'red', height: '12px', lineHeight: '12px', fontSize: '12px', fontWeight: 400,
      }}
      > { helperText }
      </Typography> */}
      {focused && <Description> {focusComponent} </Description>}
      {descriptionComponent && <Description>{descriptionComponent}</Description>}
    </Wrapper>
  );
};

TextField.propTypes = {
  onPaste: PropTypes.func,
  customOnChange: PropTypes.func,
  customOnBlur: PropTypes.func,
  descriptionComponent: PropTypes.node,
  autoComplete: PropTypes.string,
  helperTextBefore: PropTypes.string,
  helperText: PropTypes.string,
  helperTextAfter: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  validation: PropTypes.bool,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  inputComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  endAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  startAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  color: PropTypes.oneOf(['secondary', 'dark']),
  type: PropTypes.oneOf(['password', 'text', 'datetime-local']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isHidden: PropTypes.bool,
  isPrice: PropTypes.bool,
  isClearable: PropTypes.bool,
  setIsClearable: PropTypes.func,
  isRed: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  focusComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  isRecommended: PropTypes.bool,
  isError: PropTypes.bool,
  input: PropTypes.object,
  prefix: PropTypes.string,
  textColor: PropTypes.string,
  placeholder: PropTypes.string,
  timeout: PropTypes.number,
  wrapperClassName: PropTypes.string,
  onKeyUp: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputmode: PropTypes.string,
  dataTestId: PropTypes.string,
  inputLabelProps: PropTypes.object,
  maxLength: PropTypes.number,
};

TextField.defaultProps = {
  focusComponent: false,
  disabled: false,
  required: false,
  isPrice: false,
  isClearable: false,
  autoFocus: false,
  isError: false,
  type: 'text',
  color: 'secondary',
  meta: {},
  input: {},
  timeout: 0,
  inputmode: 'text',
};

export default memo(TextField);
