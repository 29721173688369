// Core
import { memo } from 'react';
import { useSelector } from 'react-redux';

import TableFilterRowWithSelectedCounter from '../../../../../../components/_Table/DxTable/components/TableFilterRowWithSelectedCounter/TableFilterRowWithSelectedCounter';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';

function TableFilterRowComponent(props) {
  const selection = useSelector(selectors.logisticsDataByCategory.selection);
  const hasProductEdit = useAccessList(accessList.productEdit);
  const selectedCounter = hasProductEdit ? selection.size : undefined;

  return (
    <TableFilterRowWithSelectedCounter selectedCounter={selectedCounter} {...props} />
  );
}

export default memo(TableFilterRowComponent);
