// Core
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Field, Form, reduxForm, isPristine,
  isSubmitting,
} from 'redux-form/immutable';
// Parts
import CallMissedIcon from '@mui/icons-material/CallMissed';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import PageHeader from '../../../components/PageHeader/PageHeader';
import FormInput from '../../../components/_Form/TextField/FormTextField/FormTextField';
import FormSelect from '../../../components/_Form/Selects/FormSelect/FormSelect';
import Select from '../../../components/_Form/Selects/Select/Select';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import Loading from '../../../components/Loading/Loading';
import SectionHolder from '../../../containers/SectionHolder/SectionHolder';
// Engine
import { asyncAction } from '../../../../engine/core/mail/saga/asyncAction';
import { selectors } from '../../../../engine/config/selectors';
import FormTextArea from '../../../components/_Form/TextArea/FormTextArea';
import { usePrevious } from '../../../hooks/usePrevious';

export const formName = 'emailTemplatesEdit';
function EmailTemplatesEdit(props) {
  const { handleSubmit } = props;
  const mailTemplateByHashIdItem = useSelector(selectors.mail.mailTemplateByHashIdItem).toJS();
  const mailTemplatesEvents = useSelector(selectors.mail.mailTemplatesEvents);
  const pending = useSelector(selectors.mail.mailTemplateByHashIdPending);
  const putPending = useSelector(selectors.mail.putMailTemplateByHashIdPending);
  const submitting = useSelector(isSubmitting(formName));
  const pristine = useSelector(isPristine(formName));
  const { t, i18n } = useTranslation();
  const prevLanguage = usePrevious(i18n.language);
  const { hashId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if ((i18n.language !== prevLanguage) && (prevLanguage !== undefined && i18n.language !== undefined)) {
      dispatch(asyncAction.getMailTemplateByHashId(hashId));
      dispatch(asyncAction.getMailTemplatesEvents());
    }
  }, [dispatch, hashId, i18n.language, prevLanguage]);

  useEffect(() => {
    dispatch(asyncAction.getMailTemplateByHashId(hashId));
    dispatch(asyncAction.getMailTemplatesEvents());
  }, [dispatch, hashId]);

  const onFormSubmit = (value, redirect) => {
    const data = value.toJS ? value.toJS() : props;
    if (data) {
      dispatch(asyncAction.putMailTemplateByHashId({
        hashId,
        redirect,
        data: {
          body: data.body,
          bodyUa: data.bodyUa,
          event: data.event,
          subject: data.subject,
          subjectUa: data.subjectUa,
        },
      }));
    }
  };

  const receivers = [
    { value: 1, label: t('Админы') },
    { value: 2, label: t('Менеджеры') },
    { value: 3, label: t('Партнеры') },
    { value: 4, label: t('Менеджеры контента') },
    { value: 5, label: t('Менеджеры по продажам') },
  ];

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={mailTemplateByHashIdItem?.eventName}>
          <ButtonWithIconAndCircularProgress
            text={t('Сохранить и вернуться')}
            isLoading={putPending || pending}
            disabled={submitting || pristine || pending || putPending}
            onClick={handleSubmit(value => onFormSubmit(value, true))}
            component="button"
            type="button"
          >
            <CallMissedIcon />
          </ButtonWithIconAndCircularProgress>
          <ButtonWithIconAndCircularProgress
            text={t('Сохранить')}
            isLoading={putPending || pending}
            disabled={submitting || pristine || pending || putPending}
            onClick={handleSubmit(value => onFormSubmit(value, false))}
            component="button"
            type="button"
          >
            <SaveIcon />
          </ButtonWithIconAndCircularProgress>
        </PageHeader>
        <SectionHolder>
          <Grid container xs={12}>
            <Grid item xs={1} />
            {pending
              ? (
                <Grid
                  item
                  xs={10}
                  style={{ height: 320 }}
                >
                  <Loading isLoading />
                </Grid>
              )
              : (
                <Grid item xs={10}>
                  <Field
                    component={FormInput}
                    name="subject"
                    validation
                    id="subject"
                    label={t('Тема письма ру.')}
                    type="text"
                    fullWidth
                  />
                  <Field
                    component={FormInput}
                    name="subjectUa"
                    validation
                    margin="normal"
                    id="subject"
                    label={t('Тема письма укр.')}
                    type="text"
                    fullWidth
                  />
                  <Field
                    disabled
                    component={FormSelect}
                    name="event"
                    label={t('Событие')}
                    labelWidth={80}
                    options={mailTemplatesEvents}
                    formSelectWidth="100%"
                    margin="normal"
                  />
                  <Field
                    disabled
                    component={Select}
                    variant="outlined"
                    name="group"
                    isMulti
                    label={t('Группа')}
                    margin="normal"
                  />
                  <Field
                    disabled
                    component={Select}
                    variant="outlined"
                    isMulti
                    name="roles"
                    options={receivers}
                    label={t('Получатели')}
                    margin="normal"
                  />
                  <Field
                    component={FormTextArea}
                    validation
                    margin="normal"
                    name="body"
                    id="body"
                    label={t('Содержание письма ру.')}
                    type="text"
                    multiline
                    fullWidth
                    rows={8}
                    variant="outlined"
                  />
                  <Field
                    component={FormTextArea}
                    validation
                    margin="normal"
                    name="bodyUa"
                    id="bodyUa"
                    label={t('Содержание письма укр.')}
                    type="text"
                    multiline
                    fullWidth
                    rows={8}
                    variant="outlined"
                  />
                </Grid>
              )
          }
            <Grid item xs={1} />
          </Grid>
        </SectionHolder>
      </Grid>
    </Form>
  );
}

EmailTemplatesEdit.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(EmailTemplatesEdit);
