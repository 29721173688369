// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Box from '@mui/material/Box';
// Parts
import FormTextArea from '../../../../../components/_Form/TextArea/FormTextArea';
import TooltipHint from '../../../../../components/Tooltip/TooltipHint';
import { useStyles } from '../../../../../hooks/useStyles';

const styles = () => ({
  contentHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltipHint: {
    marginTop: '10px',
    marginLeft: '8px',
  },
});
function FormTextAreaOnlyUkr(props) {
  const { t } = useTranslation();
  const classes = useStyles(styles);

  return (
    <Box
      component="div"
      className={classes.contentHolder}
    >
      <FormTextArea
        {...props}
      />
      <TooltipHint
        title={t('Поле должно быть заполнено на укр. языке')}
        placement="top-end"
        classNameTooltip={classes.tooltipHint}
      />
    </Box>
  );
}

export default FormTextAreaOnlyUkr;
