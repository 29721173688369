// Core
import {
  apply, put, delay, select,
} from 'redux-saga/effects';
import { replace } from 'redux-first-history';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { actions as contentProductActions } from '../../../contentProduct/actions';
import { pageLinks } from '../../../../config/routes';
import { selectors } from '../../../../config/selectors';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callContentProductCreateWorker({ payload }) {
  const productCreatePending = yield select(selectors.content.productCreatePending);
  if (productCreatePending) {
    return null;
  }
  const { data, redirectUrl } = payload;
  yield put(actions.setProductCreate({ pending: true }));
  const response = yield apply(api, api.products.postProduct, [data]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;
    switch (status) {
      case requestStatus.success: {
        const { message, hashId } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(1000);
        yield put(contentProductActions.setReload(true));
        if (typeof redirectUrl === 'string') {
          yield put(replace(redirectUrl));
        } else {
          yield put(replace(pageLinks.content.products.edit(hashId)));
        }
        break;
      }
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setProductCreate({ pending: false }));
}
