import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as React from 'react';
import { InputLabel, Select } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useEffect } from 'react';
import moment from 'moment';
import { locale } from '../../../../../engine/init/i18n';
import FormTextFieldInput from '../input/FormTextFieldInput';
import {
  requisitesPhoneMask, validators,
} from '../../../../../_helpers/validationsForm';
import MaskedInputWithRef from '../input/MaskedInputWithRef';
import CalendarRounded from '../../../../icons/CalendarRounded';
import { useStyles } from '../../../../hooks/useStyles';
import { FormSelectStyle } from '../../../../components/_Form/Selects/FormSelect/FormSelectStyle';
import { Wrapper } from '../../../../components/_Form/DataRange/DataRangeDrop/styles';

const tomorrow = moment().add(1, 'days');
const MAX_TRUSTEES_COUNT = 3;
const ONE = 1;
export default function TrusteesForm({ formData, setTrusteesFormData, editMode }) {
  const trusteeTemplate = {
    fullName: '',
    passportType: 'BOOK',
    passportNumber: '',
    phoneNumber: '',
    ipn: '',
    errors: {},
  };
  const { i18n: { language } } = useTranslation();
  const classes = useStyles(FormSelectStyle);

  const handleAddTrustee = () => {
    if (formData.trustees.length >= MAX_TRUSTEES_COUNT) {
      return;
    }
    setTrusteesFormData(prevState => ({
      ...prevState,
      trustees: [...prevState.trustees, trusteeTemplate],
    }));
  };

  const handleRemoveTrustee = (index) => {
    if (formData.trustees.length > ONE) {
      setTrusteesFormData(prevState => ({
        ...prevState,
        trustees: prevState.trustees.filter((_, i) => i !== index),
      }));
    }
  };

  const handlePowerOfAttorneyDateChange = (date) => {
    setTrusteesFormData(prevState => ({
      ...prevState,
      powerOfAttorneyDate: date,
    }));
  };

  const handleIsTrusteesCreatedChange = () => {
    setTrusteesFormData((prevState) => {
      let valid = false;
      if (!prevState.doNotCreateTrustees) {
        valid = true;
      }
      return {
        ...prevState,
        doNotCreateTrustees: !prevState.doNotCreateTrustees,
        isFormValid: valid,
      };
    });
  };

  useEffect(() => {
    if (formData && formData.trustees && formData.trustees.length === 0) {
      handleAddTrustee();
    }
  }, []);

  useEffect(() => {
    const errors = [];
    if (!formData.doNotCreateTrustees && formData.trustees.length > 0) {
      formData.trustees.forEach((trustee, index) => {
        errors[index] = {};
        Object.entries(trustee).forEach(([key, value]) => {
          if (key === 'passportNumber') {
            errors[index][key] = validators.validateTrusteeForm(key, value, trustee.passportType);
          } else {
            errors[index][key] = validators.validateTrusteeForm(key, value);
          }
        });
      });
      const isFormValid = errors.every(field => Object.values(field).every(value => value.trim() === ''));

      setTrusteesFormData(prevState => ({
        ...prevState,
        isFormValid: isFormValid && formData.powerOfAttorneyDate,
      }));
    } else {
      setTrusteesFormData(prevState => ({
        ...prevState,
        isFormValid: true,
      }));
    }
  }, [formData.trustees, formData.powerOfAttorneyDate, formData.doNotCreateTrustees]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    let errorKey = name;
    let error = '';

    if (name === 'ipn' && (!/^\d*$/.test(value) || value.length > 10)) {
      return;
    }

    if (name === 'fullName' && (!/^[А-ЯҐЄІЇа-яґєії ]*$/.test(value) || value.length > 100)) {
      return;
    }

    if ((name === 'passportNumber') && formData.trustees[index]) {
      const passportType = formData.trustees[index].passportType;

      if (passportType === 'ID_CARD' && (!/^\d*$/.test(value) || value.length > 9)) {
        return;
      }

      if (passportType === 'BOOK' && (!/^[А-ЩЬЮЯҐЄІЇ]{0,2}\d{0,8}$/.test(value) || value.length > 8)) {
        return;
      }

      error = validators.validateTrusteeForm(name, value, passportType);
    } else if (name === 'passportType') {
      const passportNumber = formData.trustees[index].passportNumber;
      error = validators.validateTrusteeForm('passportNumber', passportNumber, value);
      errorKey = 'passportNumber';
    } else {
      error = validators.validateTrusteeForm(name, value);
    }

    setTrusteesFormData(prevState => ({
      ...prevState,
      trustees: prevState.trustees.map((trustee, i) => {
        if (i === index) {
          return {
            ...trustee,
            [name]: value,
            errors: { ...trustee.errors, [errorKey]: error },
          };
        }
        return trustee;
      }),
    }));
  };

  return (
    <>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>
          Дані довірених осіб
        </Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid marginTop={'5px'} item xs={12} sm={12} lg={12} xl={12}>
            <Box>
              <FormControlLabel
                value="end"
                control={(
                  <Checkbox
                    checked={formData.doNotCreateTrustees}
                    name="doNotCreateTrustees"
                    onChange={handleIsTrusteesCreatedChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={<Typography fontSize={'14px'} color={'#303030'}>Не створювати довіреність</Typography>}
                labelPlacement="end"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Box style={{ marginBottom: '10px', borderLeft: '2px solid #DB8020' }}>
              <Box style={{ paddingLeft: '10px' }}>
                <Typography fontSize={'12px'} color={'rgba(48, 48, 48, 0.87)'}>
                  При підключенні доставки АЛЛО Express ви можете не вказувати довірених осіб. В цьому випадку тільки директор вашого магазину матиме право відправляти нові та отримувати повернені замовлення.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {!formData.doNotCreateTrustees && (
          <>
            <Divider />
            <Grid container spacing={2}>
              <Grid marginTop={'25px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                <Wrapper>
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale[language]}>
                    <DesktopDatePicker
                      slots={{
                        openPickerIcon: CalendarRounded,
                      }}
                      slotProps={{
                        sx: { fieldset: { legend: { maxWidth: '100%' } } },
                        textField: {
                          helperText: 'Цей термін діє для всіх довірених осіб',
                          readOnly: true,
                          variant: 'outlined',
                          color: 'secondary',
                          fullWidth: true,
                          fontSize: '14px',
                          error: false,
                          label: 'Термін дії довіреності',
                          shrink: true,
                        },
                      }}
                      onChange={handlePowerOfAttorneyDateChange}
                      disablePast
                      value={formData.powerOfAttorneyDate}
                      defaultValue={tomorrow}
                      minDate={tomorrow}
                      maxDate={new Date('26.06.2070')}
                      name={'POAdate'}
                      format="DD.MM.YYYY"
                    />
                  </LocalizationProvider>
                </Wrapper>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Box style={{ borderLeft: '2px solid #DB8020' }}>
                  <Box style={{ paddingLeft: '10px' }}>
                    <Typography fontSize={'12px'} color={'rgba(48, 48, 48, 0.87)'}>
                      Максимальна кількість довірених осіб: 3
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
              {formData.trustees.map((trustee, index) => (
                <Grid container spacing={2} marginTop={'20px'} key={index}>
                  <Grid marginTop={'15px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                    <Box>
                      <FormTextFieldInput
                        input={'text'}
                        name="fullName"
                        focused
                        touched
                        disabled={editMode && trustee.id}
                        variant="outlined"
                        value={trustee.fullName}
                        error={!!trustee.errors.fullName}
                        helperText={trustee.errors.fullName}
                        onChange={e => handleChange(index, e)}
                        label={'ПІБ довіреної особи'}
                        fullWidth
                        type={'text'}
                        placeholder={'Шевченко Тарас Григорович'}
                        required
                        validation
                        validate={validators.required}
                      />
                    </Box>
                  </Grid>
                  <Grid marginTop={'15px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                    <Box className={classes.formSelectWrapper}>
                      <FormControl
                        style={{
                          fontSize: '1rem',
                        }}
                        className={classes.wrapperFullWidth}
                      >
                        <InputLabel sx={{ fontSize: '1rem' }}>Тип паспорту</InputLabel>
                        <Select
                          disabled={editMode && trustee.id}
                          size="small"
                          name="passportType"
                          onChange={e => handleChange(index, e)}
                          fullWidth
                          label={'Тип паспорту'}
                          placeholder=""
                          value={trustee.passportType}
                          variant="outlined"
                        >
                          <MenuItem style={{ fontSize: '14px' }} value={'ID_CARD'}>ID-картка</MenuItem>
                          <MenuItem style={{ fontSize: '14px' }} value={'BOOK'}>Паспорт-книжечка</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '25px',
                    }}
                    item
                    sm={1}
                    lg={1}
                    xl={1}
                  >
                    {
                      ((formData.trustees.length > 1 && index !== 0)) && (
                        <Box
                          width={'32px'}
                          height={'32px'}
                          style={{
                            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Button
                            style={{
                              padding: 0,
                              width: '100%',
                              height: '100%',
                              opacity: '0px',
                              background: 'rgba(48, 48, 48, 0.08)',
                              border: 'solid 4px white',
                            }}
                            onClick={() => {
                              handleRemoveTrustee(index);
                            }}
                          >
                            <DeleteOutlinedIcon color={'red'} />
                          </Button>
                        </Box>
                      )
                    }
                  </Grid>
                  <Grid marginBottom={'10px'} marginTop={'15px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                    <FormTextFieldInput
                      variant="outlined"
                      name={'phoneNumber'}
                      focused
                      fullWidth
                      label="Телефон"
                      error={!!trustee.errors.phoneNumber}
                      helperText={trustee.errors.phoneNumber}
                      required
                      validation
                      validate={validators.required}
                      InputProps={{
                        inputComponent: MaskedInputWithRef,
                        inputProps: {
                          mask: requisitesPhoneMask,
                        },
                        value: trustee.phoneNumber,
                        onChange: e => handleChange(index, e),
                      }}
                    />
                  </Grid>
                  <Grid marginTop={'15px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                    <div className={''}>
                      <FormTextFieldInput
                        input={'text'}
                        name="passportNumber"
                        focused
                        touched
                        disabled={editMode && trustee.id}
                        variant="outlined"
                        error={!!trustee.errors.passportNumber}
                        helperText={trustee.errors.passportNumber}
                        value={trustee.passportNumber}
                        onChange={e => handleChange(index, e)}
                        label={'Номер паспорту'}
                        fullWidth
                        type={'text'}
                        placeholder={''}
                        required
                        validation
                        validate={validators.required}
                      />
                    </div>
                  </Grid>
                  <Grid marginTop={'15px'} item xs={12} sm={5.5} lg={5.5} xl={5.5}>
                    <div className={''}>
                      <FormTextFieldInput
                        input={'text'}
                        name="ipn"
                        focused
                        touched
                        disabled={editMode && trustee.id}
                        variant="outlined"
                        value={trustee.ipn}
                        error={!!trustee.errors.ipn}
                        helperText={trustee.errors.ipn}
                        onChange={e => handleChange(index, e)}
                        label={'ІПН довіреної особи'}
                        fullWidth
                        type={'text'}
                        placeholder={''}
                        required
                        validation
                        validate={validators.required}
                      />
                    </div>
                  </Grid>
                </Grid>
              ))}
            { formData.trustees.length < 3 && (
            <Button
              style={{ marginRight: '10px', marginTop: '20px' }}
              startIcon={<AddCircleOutlineIcon />}
              color={'secondary'}
              onClick={handleAddTrustee}
              variant="outlined"
            >Додати ще довірених осіб
            </Button>
            ) }
          </>
        )}
      </Box>
    </>
  );
}
