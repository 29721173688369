// Core
import red from '@mui/material/colors/red';
import indigo from '@mui/material/colors/indigo';
import green from '@mui/material/colors/green';
import { COLOR } from '../../../containers/App/AppStyles';

const TextFieldStyles = theme => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: '14px',
    textAlign: 'left',
  },
  defaultLabelStyle: {
    fontSize: '14px',
    color: COLOR.black.initial,
  },
  wrapperWithApprove: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  controlUnderLine: {
    borderBottom: '1px solid #949494',
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: -15,
  },
  wrapperRequired: {
    '@global': {
      input: {
        paddingRight: 25,
      },
    },
  },
  buttonRequired: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    color: COLOR.brand.dark,
  },
  hasError: {
    borderTop: '2px solid #f44336',
    color: red[500],
    '& .MuiCheckbox-root, & .MuiCheckbox-root:hover': {
      color: '#E00027',
    },
    '& .MuiCheckbox-root:before': {
      content: 'none',
    },
  },
  button: {
    width: 42,
    height: 42,
    padding: 7,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  compareButton: {
    color: indigo[500],
  },
  compareButtonActive: {
    borderColor: indigo[500],
  },
  checkButton: {
    color: green[500],
  },
  checkButtonActive: {
    borderColor: green[500],
  },
  cancelButton: {
    color: red[500],
  },
  cancelButtonActive: {
    borderColor: red[500],
  },
  flexOne: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row !important',
    },
    '@global': {
      'label + .MuiInput-formControl': {
        marginTop: 0,
      },
    },
  },
  notFullWidth: {
    'align-self': 'flex-start',
  },
});

export default TextFieldStyles;
