// Core
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
// Engine
import { asyncActions } from '../../../../../engine/core/faq/saga/asyncActions';

export function useEditFaq() {
  const dispatch = useDispatch();
  const { hashId } = useParams();

  const getFaqItemByHashId = useCallback(() => {
    dispatch(asyncActions.getFaqItemByHashId(hashId));
  }, [dispatch, hashId]);

  useEffect(() => {
    getFaqItemByHashId();
  }, [getFaqItemByHashId]);
}
