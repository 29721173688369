// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';

// Engine
import { priceCreatorActions } from '../../action';

import { api } from '../../../../config/api';
import { setSuccessMessage, setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';
// Const
import { formName } from '../../../../../ui/page/Products/Content/ContentProducts/components/ContentProductsSidebar/UpdatePricesButton';

export function* callPostUploadPriceListWorker({ payload: { file, partnerId } }) {
  yield put(priceCreatorActions.mergePriceListUpload({ pending: true }));

  const response = yield apply(api, api.products.postPriceListUpload, [{ file, partnerId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const {
      errors, status, message, statusTitle,
    } = responseData;
    yield put(reset(formName));

    switch (status) {
      case 'error': {
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        yield put(setSuccessMessage(message, statusTitle));
        yield put(priceCreatorActions.mergePriceListUpload({ isModalOpen: false }));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(priceCreatorActions.mergePriceListUpload({ pending: false }));
}
