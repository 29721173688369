const AttachedFileListStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    boxShadow: 'none',
    width: '100%',
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  fileName: {
    color: theme.palette.primary.main,
  },
  fileSize: {
    color: '#000',
  },
  date: {
    color: '#0006',
  },
  filesWrapper: {
    position: 'absolute',
    bottom: 110,
    maxHeight: '40vh',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      bottom: 52,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      bottom: -4,
      height: 17,
      background: 'linear-gradient(to top, #fff, rgba(255,255,255,0))',
      [theme.breakpoints.up('md')]: {
        bottom: 0,
      },
    },
  },
});

export default AttachedFileListStyles;
