// Core
import React from 'react';

function FilePngIcon(props) {
  return (
    <svg width="29" height="38" {...props} viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1905_328)">
        <path d="M21.025 0L29 9.12V36.86C29 37.4908 28.4887 38 27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V1.14C0 0.5092 0.511316 0 1.14474 0H21.025Z" fill="#EBE8E1" />
        <path d="M21.025 0V8.74L29 9.12L21.025 0Z" fill="#CCCABE" />
        <path d="M27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V26.22H29V36.86C29 37.4908 28.4887 38 27.8553 38Z" fill="#4D9E57" />
        <path d="M6.58203 35V29.2734H8.4375C9.14062 29.2734 9.59896 29.3021 9.8125 29.3594C10.1406 29.4453 10.4154 29.6328 10.6367 29.9219C10.8581 30.2083 10.9688 30.5794 10.9688 31.0352C10.9688 31.3867 10.9049 31.6823 10.7773 31.9219C10.6497 32.1615 10.487 32.3503 10.2891 32.4883C10.0938 32.6237 9.89453 32.7135 9.69141 32.7578C9.41536 32.8125 9.01562 32.8398 8.49219 32.8398H7.73828V35H6.58203ZM7.73828 30.2422V31.8672H8.37109C8.82682 31.8672 9.13151 31.8372 9.28516 31.7773C9.4388 31.7174 9.55859 31.6237 9.64453 31.4961C9.73307 31.3685 9.77734 31.2201 9.77734 31.0508C9.77734 30.8424 9.71615 30.6706 9.59375 30.5352C9.47135 30.3997 9.31641 30.3151 9.12891 30.2812C8.99089 30.2552 8.71354 30.2422 8.29688 30.2422H7.73828Z" fill="white" />
        <path d="M11.9297 35V29.2734H13.0547L15.3984 33.0977V29.2734H16.4727V35H15.3125L13.0039 31.2656V35H11.9297Z" fill="white" />
        <path d="M20.3633 32.8945V31.9297H22.8555V34.2109C22.6133 34.4453 22.2617 34.6523 21.8008 34.832C21.3424 35.0091 20.8776 35.0977 20.4062 35.0977C19.8073 35.0977 19.2852 34.9727 18.8398 34.7227C18.3945 34.4701 18.0599 34.1107 17.8359 33.6445C17.612 33.1758 17.5 32.6667 17.5 32.1172C17.5 31.5208 17.625 30.9909 17.875 30.5273C18.125 30.0638 18.4909 29.7083 18.9727 29.4609C19.3398 29.2708 19.7969 29.1758 20.3438 29.1758C21.0547 29.1758 21.6094 29.3255 22.0078 29.625C22.4089 29.9219 22.6667 30.3333 22.7812 30.8594L21.6328 31.0742C21.5521 30.793 21.3997 30.5716 21.1758 30.4102C20.9544 30.2461 20.6771 30.1641 20.3438 30.1641C19.8385 30.1641 19.4362 30.3242 19.1367 30.6445C18.8398 30.9648 18.6914 31.4401 18.6914 32.0703C18.6914 32.75 18.8424 33.2604 19.1445 33.6016C19.4466 33.9401 19.8424 34.1094 20.332 34.1094C20.5742 34.1094 20.8164 34.0625 21.0586 33.9688C21.3034 33.8724 21.513 33.7565 21.6875 33.6211V32.8945H20.3633Z" fill="white" />
        <g clipPath="url(#clip1_1905_328)">
          <path d="M13.002 14.5C13.002 14.8978 12.844 15.2794 12.5627 15.5607C12.2814 15.842 11.8998 16 11.502 16C11.1042 16 10.7227 15.842 10.4414 15.5607C10.16 15.2794 10.002 14.8978 10.002 14.5C10.002 14.1022 10.16 13.7206 10.4414 13.4393C10.7227 13.158 11.1042 13 11.502 13C11.8998 13 12.2814 13.158 12.5627 13.4393C12.844 13.7206 13.002 14.1022 13.002 14.5Z" fill="#303030" fillOpacity="0.72" />
          <path d="M8.5 11C8.10218 11 7.72064 11.158 7.43934 11.4393C7.15804 11.7206 7 12.1022 7 12.5V21.5C7 21.8978 7.15804 22.2794 7.43934 22.5607C7.72064 22.842 8.10218 23 8.5 23H21.5C21.8978 23 22.2794 22.842 22.5607 22.5607C22.842 22.2794 23 21.8978 23 21.5V12.5C23 12.1022 22.842 11.7206 22.5607 11.4393C22.2794 11.158 21.8978 11 21.5 11H8.5ZM21.5 12C21.6326 12 21.7598 12.0527 21.8536 12.1464C21.9473 12.2402 22 12.3674 22 12.5V18.5L18.225 16.553C18.1312 16.506 18.025 16.4897 17.9215 16.5064C17.8179 16.5231 17.7223 16.5719 17.648 16.646L13.938 20.356L11.278 18.584C11.182 18.5201 11.0668 18.4913 10.9519 18.5026C10.8371 18.5139 10.7297 18.5646 10.648 18.646L8.002 21V21.54L8 21.5V12.5C8 12.3674 8.05268 12.2402 8.14645 12.1464C8.24021 12.0527 8.36739 12 8.5 12H21.5Z" fill="#303030" fillOpacity="0.72" />
        </g>
      </g>
      <path d="M6.58203 35V29.2734H8.4375C9.14062 29.2734 9.59896 29.3021 9.8125 29.3594C10.1406 29.4453 10.4154 29.6328 10.6367 29.9219C10.8581 30.2083 10.9688 30.5794 10.9688 31.0352C10.9688 31.3867 10.9049 31.6823 10.7773 31.9219C10.6497 32.1615 10.487 32.3503 10.2891 32.4883C10.0938 32.6237 9.89453 32.7135 9.69141 32.7578C9.41536 32.8125 9.01562 32.8398 8.49219 32.8398H7.73828V35H6.58203ZM7.73828 30.2422V31.8672H8.37109C8.82682 31.8672 9.13151 31.8372 9.28516 31.7773C9.4388 31.7174 9.55859 31.6237 9.64453 31.4961C9.73307 31.3685 9.77734 31.2201 9.77734 31.0508C9.77734 30.8424 9.71615 30.6706 9.59375 30.5352C9.47135 30.3997 9.31641 30.3151 9.12891 30.2812C8.99089 30.2552 8.71354 30.2422 8.29688 30.2422H7.73828Z" fill="white" />
      <path d="M11.9297 35V29.2734H13.0547L15.3984 33.0977V29.2734H16.4727V35H15.3125L13.0039 31.2656V35H11.9297Z" fill="white" />
      <path d="M20.3633 32.8945V31.9297H22.8555V34.2109C22.6133 34.4453 22.2617 34.6523 21.8008 34.832C21.3424 35.0091 20.8776 35.0977 20.4062 35.0977C19.8073 35.0977 19.2852 34.9727 18.8398 34.7227C18.3945 34.4701 18.0599 34.1107 17.8359 33.6445C17.612 33.1758 17.5 32.6667 17.5 32.1172C17.5 31.5208 17.625 30.9909 17.875 30.5273C18.125 30.0638 18.4909 29.7083 18.9727 29.4609C19.3398 29.2708 19.7969 29.1758 20.3438 29.1758C21.0547 29.1758 21.6094 29.3255 22.0078 29.625C22.4089 29.9219 22.6667 30.3333 22.7812 30.8594L21.6328 31.0742C21.5521 30.793 21.3997 30.5716 21.1758 30.4102C20.9544 30.2461 20.6771 30.1641 20.3438 30.1641C19.8385 30.1641 19.4362 30.3242 19.1367 30.6445C18.8398 30.9648 18.6914 31.4401 18.6914 32.0703C18.6914 32.75 18.8424 33.2604 19.1445 33.6016C19.4466 33.9401 19.8424 34.1094 20.332 34.1094C20.5742 34.1094 20.8164 34.0625 21.0586 33.9688C21.3034 33.8724 21.513 33.7565 21.6875 33.6211V32.8945H20.3633Z" fill="white" />
      <defs>
        <clipPath id="clip0_1905_328">
          <rect width="29" height="38" fill="white" />
        </clipPath>
        <clipPath id="clip1_1905_328">
          <rect width="16" height="16" fill="white" transform="translate(7 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FilePngIcon;
