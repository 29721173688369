// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_STOP_WORDS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_STOP_WORDS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_STOP_WORDS_FILTERS, filters);
  },
  setReload(reload) {
    return getActionCreator(types.SET_STOP_WORDS_RELOAD, reload);
  },
  setStopWordHashId(data) {
    return getActionCreator(types.SET_STOP_WORD_HASH_ID, data);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_STOP_WORDS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_STOP_WORDS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_STOP_WORDS_INITIAL_STATE, initialState);
  },
  mergeDeleteStopWords(data) {
    return getActionCreator(types.MERGE_DELETE_STOP_WORD, data);
  },
  mergePostStopWord(data) {
    return getActionCreator(types.MERGE_POST_STOP_WORD, data);
  },
  mergeGetStopWord(data) {
    return getActionCreator(types.MERGE_GET_STOP_WORD, data);
  },
  mergePutStopWord(data) {
    return getActionCreator(types.MERGE_PUT_STOP_WORD, data);
  },
  mergeUploadStopWord(data) {
    return getActionCreator(types.MERGE_UPLOAD_STOP_WORD, data);
  },
  mergeGetExportStopWords(data) {
    return getActionCreator(types.MERGE_GET_EXPORT_STOP_WORDS, data);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_STOP_WORDS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_STOP_WORDS_SETTING_PENDING, settingPending);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_STOP_WORDS_SELECTION, selection);
  },
});
