// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_PARTNERS_LIST, data);
  },
  mergePaymentTypes(data) {
    return getActionCreator(types.MERGE_PARTNERS_PAYMENT_TYPES, data);
  },
  mergeList(data) {
    return getActionCreator(types.MERGE_PARTNERS_LIST, data);
  },
  mergeDownload(data) {
    return getActionCreator(types.MERGE_PARTNERS_DOWNLOAD, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PARTNERS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PARTNERS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PARTNERS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PARTNERS_SORTING, sorting);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_PARTNERS_SELECTION, selection);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PARTNERS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PARTNERS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_PARTNERS_SETTING_PENDING, settingPending);
  },
  mergePartnersProcess(data) {
    return getActionCreator(types.MERGE_PARTNERS_PROCESS, data);
  },
  mergePartnersTopImport(data) {
    return getActionCreator(types.MERGE_PARTNERS_TOP_IMPORT, data);
  },
  mergePartnerOrderProcessing(data) {
    return getActionCreator(types.MERGE_PARTNER_ORDER_PROCESSING, data);
  },
  mergePartnerDeliveryDelayPeriod(data) {
    return getActionCreator(types.MERGE_PARTNER_DELIVERY_DELAY_PERIOD, data);
  },
  setPartnersDeliveryDelayPeriodDelete(data) {
    return getActionCreator(types.SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_MODAL_SHOW, data);
  },
  setPartnersDeliveryDelayPeriodDeletePending(pending) {
    return getActionCreator(types.SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_PENDING, pending);
  },
  mergePartnersTopExport(data) {
    return getActionCreator(types.MERGE_PARTNERS_TOP_EXPORT, data);
  },
  mergePartnerHashIdConfirm(data) {
    return getActionCreator(types.MERGE_PARTNERS_HASH_ID_CONFIRM, data);
  },
  setReasonRejectionModal(payload) {
    return getActionCreator(types.MERGE_REASON_REJECTION, payload);
  },
  mergePartnersListByStatus({ statusId, data }) {
    return getActionCreator(types.MERGE_PARTNERS_LIST_BY_STATUS, { statusId, data });
  },
  mergePartnersListWithMid({ data }) {
    return getActionCreator(types.MERGE_PARTNERS_LIST_WITH_MID, { data });
  },
});
