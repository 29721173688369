// Core
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form/immutable';
import cx from 'classnames';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import FormTextArea from '../../../../../components/_Form/TextArea/FormTextArea';
import HelperText from './HelperText';
import RenderCheckbox from './RenderCheckbox';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';

function RenderFormFieldTextArea(props) {
  const {
    label, massEdit, input,
    disabled, meta, isRecommended,
    required, refresh, onRefresh,
    featureDisabled, changeFieldValue, setTouchField,
    willUnmountCallBack, onBlur, onFocus,
  } = props;

  const { touched } = meta;
  const error = !!(touched && meta.error);
  const isDisabled = disabled || featureDisabled?.disabled;
  const { isValueChanged, value, showChangedMessage } = useIsValueWasChanged(input);
  const checkboxName = getCheckboxName(input.name);
  const [willBeClear, setWillBeClear] = useState(false);
  const classes = useStyles(ContentProductEditStyles);

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  useEffect(() => () => { // TODO https://github.com/redux-form/redux-form/issues/3558
    if (willUnmountCallBack) {
      willUnmountCallBack();
    }
  }, []);

  const classNames = cx(classes.formControl, {
    [classes.errorFormControl]: error,
    [classes.changedFormControl]: showChangedMessage,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;
  return (
    <FormControl
      error={error}
      className={classNames}
      margin="normal"
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              isValueChanged={isValueChanged}
              setWillBeClear={setWillBeClear}
              noMargin
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            onFocus={onFocus}
            onBlur={onBlur}
            formName={formName}
            disabled={isDisabled}
            {...input}
            name={input.name.toString()}
            alignButton="top"
          >
            <FieldWithFeatureDisabled
              alignButton="top"
              showTooltip={featureDisabled?.showTooltip}
            >
              <FormTextArea
                input={{ ...input, name: input.name.toString() }}
                fullWidth
                multiline
                disabled={isDisabled}
                isRecommended={isRecommended}
                required={required}
                label={label}
                rows={5}
              />
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
          <HelperText
            error={meta?.error}
            isError={error}
            isValueChanged={showChangedMessage}
            touched={touched}
            isValueClear={willBeClear}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderFormFieldTextArea;
