// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import TransactionsPaymentTable from './components/TransactionsPaymentTable/TransactionsPaymentTable';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Hooks
import { useTransactionsPaymentList } from './_hooks/useTransactionsPaymentList';
// Engine
import { asyncActions } from '../../../engine/core/transactionsPayment/saga/asyncActions';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/transactionsPayment/actions';
import { initialState, stateKeys } from '../../../engine/core/transactionsPayment/reducer';

function TransactionsPaymentPage() {
  const { t } = useTranslation();
  useTransactionsPaymentList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.transactionsPayment.settingStatus);
  const settingPending = useSelector(selectors.transactionsPayment.settingPending);
  const isLoading = useSelector(selectors.transactionsPayment.isLoading);
  const filters = useSelector(selectors.transactionsPayment.filters);
  const sorting = useSelector(selectors.transactionsPayment.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.transactionsPaymentList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.transactionsPaymentList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.transactionsPaymentList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeader title={t('Транзакции по способам оплаты')}>
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <ResetFiltersButton pending={isLoading} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <TransactionsPaymentTable />
    </>
  );
}

export default TransactionsPaymentPage;
