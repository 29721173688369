import { Z_INDEX } from '../../../containers/App/AppStyles';

const DxTableStyles = theme => ({
  positionRelative: {
    position: 'relative',
    zIndex: Z_INDEX.allTable,
    flexGrow: '1',
    minHeight: 325,
    '@global': {
      '.TableInvisibleRow-row th': {
        display: 'none',
      },
      '.MuiTableCell-root.TableDetailToggleCell-toggleCell': {
        padding: '0',
      },
      '.MuiTableCell-root + .TableFixedCell-dividerRight': {
        left: '58px !important',
      },
      '.MuiTableCell-root.TableFixedCell-dividerRight': {
        borderRight: '1px solid #d8d8d8',
      },
      td: {
        padding: '12px 4px',
        verticalAlign: 'middle',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          padding: '2px 4px',
        },
      },
    },
  },
});

export default DxTableStyles;
