import React, { memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import * as PropTypes from 'prop-types';
import HighlightedText from '../../../../../containers/HighlightedText';

const Label = (props) => {
  const {
    label, isChecked, classes, onChangeCheckbox,
    value, innerRef, currentOption, isIndeterminate, search,
    onlyChild, hasChildren, hasActiveChildren, isPortal, onlyNotPortal,
  } = props;

  const activeWithoutChild = onlyChild === true && hasChildren === true && hasActiveChildren === false;
  const showOnlyChild = onlyChild ? (!hasChildren || activeWithoutChild) : true;
  const showBox = onlyNotPortal ? showOnlyChild && isPortal === false : showOnlyChild;

  const onClick = (e) => {
    e.stopPropagation();
    if (onChangeCheckbox && showBox) {
      onChangeCheckbox({ value, currentOption, isChecked });
    }
  };

  return (
    <MenuItem
      component="div"
      ref={innerRef}
      onClick={showBox ? onClick : undefined}
      className={classes.treeMenuItem}
    >
      { showBox && (
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate && !isChecked}
          className={classes.treeCheckbox}
          color="primary"
        />
      )}
      <div>
        <HighlightedText
          label={label}
          search={search}
        />
      </div>
    </MenuItem>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onlyChild: PropTypes.bool,
  onlyNotPortal: PropTypes.bool,
  hasActiveChildren: PropTypes.bool,
  hasChildren: PropTypes.bool,
  isPortal: PropTypes.bool,
  classes: PropTypes.object,
  onChangeCheckbox: PropTypes.func,
  value: PropTypes.number,
  innerRef: PropTypes.func,
  currentOption: PropTypes.object,
  isIndeterminate: PropTypes.bool,
  search: PropTypes.string,
};

Label.defaultProps = {
  isIndeterminate: false,
  isChecked: false,
};

export default memo(Label);
