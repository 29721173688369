// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// Components
import Loading from '../../../Loading/Loading';

function DxLoader(props) {
  const { isLoading, hasList } = props;
  const pending = isLoading && hasList;
  return (
    <Loading
      isLoading={pending}
      request={pending}
    />
  );
}

DxLoader.propTypes = {
  isLoading: PropTypes.bool,
  hasList: PropTypes.bool,
};
DxLoader.defaultProps = {
  isLoading: false,
  hasList: false,
};

export default DxLoader;
