import { getIn, List } from 'immutable';
import { stateKeys as priceCreatorFilesKeys } from './reducer';
// config
import { pageSizes } from '../../../config/globalConfig';

export const priceCreatorFiles = Object.freeze({
  currentPage: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.currentPage], 0),
  filters: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.filters], List()),
  isLoading: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.pending], false),
  list: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.items], List()),
  pageSize: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.reload], false),
  totalCount: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.totalCount], 0),
  sorting: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.sorting], List()),
  settingStatus: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.settingStatus], false),
  settingPending: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.priceCreatorFilesList, priceCreatorFilesKeys.settingPending], false),
  isDownloadUpdateDataXmlLoading: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.dataXmlDownload, priceCreatorFilesKeys.pending], false),
  modalActive: state => getIn(state, [priceCreatorFilesKeys.controller, priceCreatorFilesKeys.dataXmlDownload, priceCreatorFilesKeys.modalActive], false),
});
