// Core
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectWidthConfirm from './SelectWidthConfirm/SelectWidthConfirm';
import { asyncActions as asyncActionsFeeds } from '../../../../../engine/core/feeds/all/saga/asyncActions';

const getUniqueKey = (value, defaultKey = '') => get(value, 'value', defaultKey);

function EnabledInIPriceCell(props) {
  const { value, column, row: { hashId, canEditElabledInIPrice, id } } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = [{ value: false, label: t('Нет') }, { value: true, label: t('Да') }];
  const [currentValue, setCurrentValue] = useState(value);
  const valuesPlain = currentValue ? t('Да') : t('Нет');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  if (value === null) {
    return null;
  }
  const handleConfirm = (params) => {
    dispatch(asyncActionsFeeds.putFeedItemByHashId({
      hashId,
      enabledInIPrice: params.enabledInIPrice === true ? 1 : 0,
      onSuccess: () => {
        setSuccess(true);
        setCurrentValue(params.enabledInIPrice);
      },
      onError: () => setError(true),
      setPending,
    }));
  };

  return canEditElabledInIPrice
    ? (
      <SelectWidthConfirm
        key={getUniqueKey(currentValue, column.name)}
        disabled={pending}
        featureName={column.name}
        success={success}
        error={error}
        onConfirm={handleConfirm}
        options={options}
        value={currentValue}
        valuesPlain={valuesPlain}
        label=""
        productId={id}
      />
    )
    : valuesPlain;
}

export default EnabledInIPriceCell;
