import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

const SupportInfo = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ fontSize: '14px', lineHeight: '20px' }}>
      {t('Если вам нужна помощь, обратитесь к вашему менеджеру или в поддержку')}:
      <br />
      <span role="img" aria-label="tel">☎️</span>&nbsp;
      <Link href={'tel:0800200900'}>0 800 200 900</Link>
      <br />
      <span role="img" aria-label="mail">📧</span>&nbsp;
      <Link href={'mailto:mpsupport@allo.ua'}>mpsupport@allo.ua</Link>
    </Box>
  );
};

export default SupportInfo;
