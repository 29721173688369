// Core
import { apply, delay, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { actions } from '../../actions';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { pageLinks } from '../../../../../config/routes';
import { setSuccessMessage, setErrorMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPostRefreshPriceListWorker(hashId) {
  yield put(actions.mergeFeedsRefresh({ pending: true }));
  const response = yield apply(api, api.feeds.postRefreshPriceList, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(pageLinks.productsAndPrices.prices));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeFeedsRefresh({ pending: false }));
}
