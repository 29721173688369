
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import OrderHistoryTable from '../components/OrderHistoryTable/OrderHistoryTable';
import { useOrderHistoryList } from '../components/OrderHistoryTable/useOrderHistoryList';
import { actions } from '../../../../engine/core/orderHistory/actions';
import { initialState, stateKeys } from '../../../../engine/core/orderHistory/reducer';
import ResetFilterButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import { selectors } from '../../../../engine/config/selectors';

function OrderShowHistoryTab({ display, tabIndex, tabValue }) {
  useOrderHistoryList();
  const dispatch = useDispatch();
  const filters = useSelector(selectors.orderHistory.filters);
  const sorting = useSelector(selectors.orderHistory.sorting);

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.orderHistoryList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.orderHistoryList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.orderHistoryList).get(stateKeys.currentPage)));
  };
  return (
    display === 'none' ? null : (
      <TabsPanel
        index={tabIndex}
        value={tabValue}
      >
        <Box sx={{
          display: 'flex', flex: 1, justifyContent: 'flex-end', margin: 2,
        }}
        >
          <ResetFilterButton onReset={resetFilters} disabled={!filters.size && !sorting.size} />
        </Box>
        <OrderHistoryTable />
      </TabsPanel>
    ));
}


export default OrderShowHistoryTab;
