/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import Dialog from '@mui/material/Dialog';
import {
  useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import declineWord from 'decline-word';
import TabsPanel from '../../../../containers/Tabs/TabsPanel';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import { api } from '../../../../../engine/config/api';
import { usePartnerRole } from '../../../../hooks/usePartnerRole';
import ConfirmRefundModal from './components/ConfirmRefundModal';
import RefundTable from './components/RefundTable';

const OrderRefundTab = ({
  tabValue, handleChangeTab, hashId, isEditable,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    handleChangeTab(_, 'general');
  };
  const [selection, setSelection] = useState([]);
  const [rows, setRows] = useState([]);
  const [refundModal, setRefundModal] = useState(false);
  const [itemsToRefund, setItemsToRefund] = useState(null);

  const onSelection = (selected) => {
    const refundIndex = selected.reduce((acc, sel) => {
      const selectedRow = rows.find(row => row.id === sel);
      if (!itemsToRefund?.[sel]) {
        return { ...acc, [sel]: selectedRow };
      }
      return acc;
    }, {});
    setSelection(selected);
    setItemsToRefund(prev => ({ ...prev, ...refundIndex }));
  };

  const isPartner = usePartnerRole();

  useEffect(() => {
    (async () => {
      if (hashId) {
        const response = await api.orders.getOrderRefundList({ hashId });
        if (response.status >= 200 && response.status <= 400) {
          setRows(response.data);
        }
      }
    })();
  }, [hashId]);


  const totalRefunded = rows.reduce((acc, row) => acc + Number(row.amountRefunded), 0);
  const productsRefunded = rows.reduce((acc, row) => acc + Number(row.quantityRefunded), 0);

  const handleRefund = async () => {
    setRefundModal(true);
  };

  const amountToRefund = useMemo(() => selection.length > 0 ? selection.reduce((acc, select) => {
    acc += Number(itemsToRefund[select]?.quantityToRefund) * Number(itemsToRefund[select]?.price);
    return acc;
  }, 0) : 0, [selection, itemsToRefund]);

  return (
    <TabsPanel
      index="refund"
      value="refund"
    >
      <Dialog
        fullScreen
        open={tabValue === 'refund'}
        sx={{ maxHeight: '100%', overflowY: 'hidden' }}
      >
        <DialogHeader handleClose={handleClose} headerLabel={t('Оформить возврат')} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '12px 20px' }}>
          <Button
            sx={{ p: '4px 22px', height: '32px', fontSize: '14px' }}
            variant="contained"
            color="primary"
            onClick={handleRefund}
            disabled={!isPartner || !selection.length || !isEditable}
          >
            {t('Вернуть деньги покупателю')}
          </Button>
        </Box>
        <RefundTable
          rows={rows}
          selection={selection}
          onSelection={onSelection}
          setSelection={setSelection}
          setItemsToRefund={setItemsToRefund}
          itemsToRefund={itemsToRefund}
          isEditable={isEditable}
          isPartner={isPartner}
        />
        <Box sx={{
          display: 'flex', justifyContent: 'flex-end', width: '100%', p: '20px', flexDirection: 'column',
        }}
        >
          {totalRefunded !== 0 && (
            <Box sx={{ alignSelf: 'flex-end' }}>
              {t('Возвращено средств за', { productsRefunded })}&nbsp;{declineWord(productsRefunded, '', t('товар'), t('товара'), t('товаров'))}: &nbsp;<strong>{totalRefunded.toLocaleString('fr-FR')}&nbsp;₴</strong>
            </Box>
          )}
          <Box sx={{ alignSelf: 'flex-end' }}>{t('Сумма поточного возврата')}:&nbsp;<strong>{amountToRefund.toLocaleString('fr-FR')}&nbsp;₴</strong></Box>
        </Box>
        {refundModal && (
        <ConfirmRefundModal
          refundModal={refundModal}
          setRefundModal={setRefundModal}
          selection={selection}
          itemsToRefund={itemsToRefund}
          handleClose={handleClose}
          hashId={hashId}
          amountToRefund={amountToRefund.toLocaleString('fr-FR')}
        />
        )}
      </Dialog>
    </TabsPanel>
  );
};


export default OrderRefundTab;
