
export const tableColumns = Object.freeze({
  id: Object.freeze({
    name: 'id',
    title: 'ID категории',
  }),
  idMagentoCategory: Object.freeze({
    name: 'idMagentoCategory',
    title: 'M-ID категории',
  }),
  idMagentoCategoryParent: Object.freeze({
    name: 'idMagentoCategoryParent',
    title: 'M-ID родительской категории',
  }),
  name: Object.freeze({
    name: 'name',
    title: 'Название категории',
  }),
  parentId: Object.freeze({
    name: 'parentId',
    title: 'ID родительской категории',
  }),
  parentName: Object.freeze({
    name: 'parentName',
    title: 'Название родительской категории',
  }),
  height: Object.freeze({
    name: 'height',
    title: 'Высота в упаковке, см',
  }),
  width: Object.freeze({
    name: 'width',
    title: 'Ширина в упаковке, см',
  }),
  length: Object.freeze({
    name: 'width',
    title: 'Длина в упаковке, см',
  }),
  weight: Object.freeze({
    name: 'width',
    title: 'Вес в упаковке, кг',
  }),
  categoryLogisticsLog: Object.freeze({
    name: 'categoryLogisticsLog',
    title: 'История изменений',
  }),
  ean: Object.freeze({
    name: 'ean',
    title: 'EAN',
  }),
});
