// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Icon
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Engine
import { pageLinks } from '../../../../../../engine/config/routes';
import { userRoles } from '../../../../../../engine/config/userRoles';
// Hooks
import { useUserSomeRole } from '../../../../../hooks/useUserSomeRole';
// Style
import { TitleWithIcon } from '../../../styles';
import { COLOR } from '../../../../../containers/App/AppStyles';
import { CustomCard, CustomCardBorderContent } from './styles';

function ImportPage() {
  const { t } = useTranslation();
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerSigningContract,
    userRoles.partnerPaymentWaiting,
  ]);

  return (
    <>
      <CustomCard sx={{ borderLeft: '5px solid #EC9727' }}>
        <CustomCardBorderContent>
          <TitleWithIcon gutterBottom variant="h6" component="div" sx={{ marginBottom: '10px', lineHeight: '1' }}>
            <InfoOutlinedIcon sx={{ color: COLOR.warning, marginRight: '10px' }} /> {t('Обратите внимание!')}
          </TitleWithIcon>
          <Typography component="p" sx={{ fontSize: '14px', color: COLOR.black.initial, lineHeight: '20px' }}>
            {t('Уважаемый партнер! На Маркетплейс Алло есть список')} <Link sx={{ fontWeight: 'bold' }} color="secondary" target="_blank" href={pageLinks.stopBrandsCategories.all}>{t('стоп брендов - категорий')}</Link>
            {!isPartnerRoles ? <>&nbsp;{t('и')} <Link sx={{ fontWeight: 'bold' }} color="secondary" target="_blank" href={pageLinks.stopWords.all}>{t('стоп-слов')}</Link></> : null }
            {t(', которые можно размещать на сайте Алло. Перед загрузкой ваших товаров ознакомьтесь с этим перечнем')}.
          </Typography>
        </CustomCardBorderContent>
      </CustomCard>
      <CustomCard>
        <CustomCardBorderContent>
          <Typography
            sx={{
              fontWeight: 'bold', color: COLOR.black.initial, fontSize: '16px', lineHeight: '1', marginBottom: '30px',
            }}
            gutterBottom
            variant="h6"
            component="div"
          >
            {t('Как импортировать товары?')}
          </Typography>
          <Typography component="p" sx={{ fontSize: '14px', lineHeight: '1' }}>
            <Link sx={{ fontWeight: 'bold', display: 'inline-flex', alignItems: 'center' }} color="secondary" underline="none" target="_blank" href={pageLinks.productsAndPrices.instructions}>{t('Детальнее')} <ArrowForwardIosIcon sx={{ fontSize: 16, padding: '2px' }} /></Link>
          </Typography>
        </CustomCardBorderContent>
      </CustomCard>
    </>
  );
}

export default memo(ImportPage);
