const ModalHeaderStyle = theme => ({
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 15,
    transform: 'translate(-50%)',
    padding: 0,
  },
  closeButtonNoTitle: {
    [theme.breakpoints.up('xs')]: {
      top: 7,
    },
  },
  closeButtonNoPadding: {
    right: 5,
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    padding: '14px 30px',
    borderBottom: '1px solid #E6E6E6',
  },
  modalTitleWrap: {
    width: 'calc(100% - 24px)',
  },
  modalTitleNoPadding: {
    borderBottom: 'none',
    padding: '22px 20px',
  },
  modalSubTitleText: {
    fontSize: '14px',
    paddingTop: '6px',
  },
  modalAttentionText: {
    color: theme.palette.primary.main,
  },
  modalTitleText: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#303030',
  },
});

export default ModalHeaderStyle;
