// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import BrandsTable from './components/BrandsTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../../components/_Table/GridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useBrandsList } from './_hooks/useBrandsList';
// Engine
// import { asyncActions } from '../../../../engine/core/brands/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/brands/actions';
import { initialState, stateKeys } from '../../../../engine/core/brands/reducer';

function BrandsPage() {
  const { t } = useTranslation();
  useBrandsList();
  const dispatch = useDispatch();
  // const settingStatus = useSelector(selectors.brands.settingStatus);
  // const settingPending = useSelector(selectors.brands.settingPending);
  const isLoading = useSelector(selectors.brands.isLoading);
  const filters = useSelector(selectors.brands.filters);
  const sorting = useSelector(selectors.brands.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.brandsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.brandsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.brandsList).get(stateKeys.currentPage)));
  }, [dispatch]);

  return (
    <>
      <PageHeader title={t('Бренды')}>
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={() => dispatch(asyncActions.putSettingsAsync())} */}
        {/* /> */}
        <ResetFiltersButton disabled={isDisabledResetButton} pending={isLoading} onReset={resetFilters} />
      </PageHeader>
      <BrandsTable />
    </>
  );
}

export default BrandsPage;
