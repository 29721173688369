// Core
import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { userActionAsync } from '../../../../../engine/core/user/saga/asyncAction';

function PartnerRolesFilter(props) {
  const {
    disabled, onSelectChange, filters, frontTranslate,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const groupsListContext = useSelector(selectors.user.userPartnerRolesItems);
  const userPartnerRolesPending = useSelector(selectors.user.userPartnerRolesPending);

  useEffect(() => {
    dispatch(userActionAsync.getUsersPartnerRolesAsync({ context: 'select' }));
  }, []);

  const normalizeValue = useMemo(() => {
    const value = frontTranslate === true
      ? filters.value.map(item => ({ ...item, label: t(item.label) }))
      : filters.value;
    return {
      ...filters,
      value,
    };
  }, [frontTranslate, t, filters, i18n.language]);

  const options = useMemo(() => {
    const normalizeGroupsListContext = groupsListContext.toJS();
    if (frontTranslate === true) {
      return normalizeGroupsListContext.map(item => ({ ...item, label: t(item.label) }));
    }
    return normalizeGroupsListContext;
  }, [frontTranslate, groupsListContext, i18n.language]);

  return (
    <TableHeadFilterSelectMultiple
      values={options}
      isLoading={userPartnerRolesPending}
      disabled={disabled}
      filters={normalizeValue}
      onSelectChange={onSelectChange}
    />
  );
}

PartnerRolesFilter.propTypes = {
  disabled: PropTypes.bool,
  onSelectChange: PropTypes.func.isRequired,
  filters: PropTypes.object,
  frontTranslate: PropTypes.bool,
};

PartnerRolesFilter.defaultProps = {
  disabled: false,
};

export default PartnerRolesFilter;
