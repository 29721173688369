// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_SERVICE_DESK_LIST_ASYNC, params);
  },
  getStatusesList(params) {
    return getActionCreator(asyncTypes.GET_SERVICE_DESK_STATUSES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_SERVICE_DESK_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_SERVICE_DESK_INITIAL_STATE_ASYNC, path);
  },
  putItemByHashId(params) {
    return getActionCreator(asyncTypes.PUT_SERVICE_DESK_ITEM_BY_HASH_ID_ASYNC, params);
  },
  setFilesInChatMessage(params) {
    return getActionCreator(asyncTypes.SET_SERVICE_DESK_FILES_IN_CHAT_MESSAGE_ASYNC, params);
  },
  deleteFileInChatMessage(params) {
    return getActionCreator(asyncTypes.DELETE_SERVICE_DESK_FILE_IN_CHAT_MESSAGE_ASYNC, params);
  },
  getMessages(params) {
    return getActionCreator(asyncTypes.GET_SERVICE_DESK_MESSAGES_ASYNC, params);
  },
  postCloseQuestionAsync(params) {
    return getActionCreator(asyncTypes.POST_SERVICE_DESK_CLOSE_QUESTION_ASYNC, params);
  },
  postContinueQuestionAsync(params) {
    return getActionCreator(asyncTypes.POST_SERVICE_DESK_CONTINUE_QUESTION_ASYNC, params);
  },
  getCurrentMessagesFromLocal(params) {
    return getActionCreator(asyncTypes.GET_SERVICE_DESK_CURRENT_MSG_FROM_LOCAL, params);
  },
  putMessages(params) {
    return getActionCreator(asyncTypes.PUT_SERVICE_DESK_MESSAGE, params);
  },
  putMessageToLocal(params) {
    return getActionCreator(asyncTypes.SET_SERVICE_DESK_MESSAGE_TO_LOCAL, params);
  },
});
