
import { COLOR } from '../../containers/App/AppStyles';


export const styles = {
  root: {
    padding: '16px',
    boxShadow: COLOR.shadow.block.main,
    borderRadius: '5px',
    backgroundColor: '#fff',
    marginBottom: '16px',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '246px',
    height: '30px',
  },
  button: {
    backgroundColor: COLOR.grey[8],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '28px',
    width: '28px',
  },
  viberContainer: {
    backgroundColor: COLOR.grey[8],
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px',
    marginRight: '12px',
  },
  infoIcon: {
    backgroundColor: COLOR.grey[8],
    height: 24,
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px',
  },
  iconsContainer: {
    display: 'flex',
    padding: '2px',
    borderRadius: '3px',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
    height: '32px',
    marginLeft: '8px',
    marginTop: '20px',
    alignSelf: 'start',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '392px',
  },
};
