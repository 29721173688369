import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectors } from '../../engine/config/selectors';
import { locale } from '../../engine/init/i18n';

export function useChat() {
  const userInfo = useSelector(selectors.user.userInfo);

  useEffect(() => {
    if (!userInfo.chatAuthKey) {
      return;
    }

    const lang = localStorage.getItem('language') === 'ru' ? locale.ru : locale.ua;
    const script = document.createElement('script');
    script.text = `(function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = "https://widget.sender.mobi/build/init.js";
      fjs.parentNode.insertBefore(js, fjs, 'sender-widget');
    })(document, 'script');


    window.senderCallback = function() {
      SenderWidget.init({
        companyId: "i47555950882",
        lang: "${lang}",
        cleanHistory: true,
        welcomeRobotId: 939470,
        auth: "${userInfo.chatAuthKey}"
      });
    }`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      window.location.reload(true);
    };
  }, [userInfo.chatAuthKey, userInfo]);
}
