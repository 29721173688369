// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { actions } from '../../actions';
import { api } from '../../../../../config/api';
import { setSuccessMessage, setErrorMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPostUploadStopWordWorker({ payload }) {
  yield put(actions.mergeUploadStopWord({ pending: true }));

  const response = yield apply(api, api.stopLists.postStopWordUpload, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const {
      errors, status, message, statusTitle,
    } = responseData;

    switch (status) {
      case 'error': {
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergeUploadStopWord({ isModalOpen: false }));
        yield put(actions.setReload(false));
        yield put(actions.setReload(true));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeUploadStopWord({ pending: false }));
}
