export const STRING_LENGTH_ALLOWED = 25;
export const getStringTruncateCenter = (str) => {
  if (str.length >= STRING_LENGTH_ALLOWED) {
    return `${str.slice(0, 10)} ... ${str.slice(-10)}`;
  }
  return str;
};
export const getStringTruncateEnd = (str, allowed) => {
  if (str.length >= allowed) {
    return {
      wasTruncated: true,
      str: `${str.slice(0, allowed)} ...`,
    };
  }
  return {
    wasTruncated: false,
    str,
  };
};
