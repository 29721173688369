// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import values from 'lodash/values';
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/billing/reports/actions';
// Data
import { tableColumns } from './ReportHistoryTableColumnsSettings';
import { useReportsList } from '../../_hooks/useReportsList';

// Redux
function ReportsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.reports.isLoading);
  // Rows
  const rows = useSelector(selectors.reports.list).toJS();
  const columns = values(tableColumns);

  // Paging
  const currentPage = useSelector(selectors.reports.currentPage);
  const pageSize = useSelector(selectors.reports.pageSize);
  const totalCount = useSelector(selectors.reports.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.reports.sorting);
  const sortingStateColumnExtensions = [
    {
      columnName: 'category',
      sortingEnabled: false,
    },
  ];
  // Filtering
  const filters = useSelector(selectors.reports.filters);
  const filteringColumnExtension = [
    { columnName: tableColumns.summaryBalance.name, filteringEnabled: false },
    { columnName: tableColumns.partner.name, filteringEnabled: false },
  ];

  return (
    <DxTable
      name="reports"
      fixedColumn
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      updateHooks={useReportsList}
      filteringExtensions={filteringColumnExtension}
    />
  );
}

export default ReportsTable;
