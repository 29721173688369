// Core
import { apply, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../config/api';
import { actions } from '../../action';

export function* callGetMailTemplateWorker({ payload }) {
  yield put(actions.setMailTemplateList({ pending: true }));

  const response = yield apply(api, api.mail.getMailTemplateList, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { items, totalCount } = response.data;

    yield put(actions.setMailTemplateList({
      items,
      totalCount,
      reload: false,
    }));
  }
  yield put(actions.setMailTemplateList({ pending: false }));
}
