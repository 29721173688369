import { COLOR } from '../../../../../../../containers/App/AppStyles';

const PagingStyles = theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '10px',
  },
  selectLabel: {
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    color: COLOR.grey.main,
    overflow: 'initial',
    textOverflow: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  offsetSelect: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    marginRight: 35,
  },
  mainWrapper: {
    padding: '15px',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      padding: '6px 12px',
    },
    '& .MuiInputBase-formControl': {
      minHeight: '32px',
      minWidth: '75px',
      '& .MuiInputBase-input': {
        padding: '0 !important',
      },
    },
    '& .PageSizeSelector-pageSizeSelector': {
      paddingRight: '40px',
      '& .PageSizeSelector-label': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        color: COLOR.grey.main,
        paddingRight: '8px',
      },
      '& .PageSizeSelector': {
        '&-inputRoot': {
          padding: '7px 12px',
          color: COLOR.grey.main,
          background: '#ffffff',
          borderRadius: '3px',
          minHeight: '30px',
          border: `1px solid ${COLOR.grey['12']}`,
          '& .MuiMenuItem-root': {
            '&:hover': {
              background: 'red',
            },
          },
          '&.Mui-focused': {
            borderColor: COLOR.controls.dark,
            '& .MuiSelect-icon': {
              color: COLOR.controls.dark,
            },
          },
        },
        '&-selectIcon': {
          top: '7px',
          color: COLOR.grey.main,
          height: '17px',
          width: '17px',
        },
      },
      '& .MuiSelect-select': {
        padding: '0 28px 0 0',
        minHeight: '16px',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOR.grey.main,
        minWidth: '16px',
        fontWeight: '400',
      },
    },
    '& .Pagination-pagination': {
      '& .Pagination-rowsLabel': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: '400',
        color: COLOR.grey.main,
        paddingRight: '40px',
      },
      '& .MuiButtonBase-root': {
        borderRadius: '3px',
        padding: '5px',
        margin: '0 2px',
        minWidth: '30px',
        background: COLOR.grey['8'],
        fontSize: '16px',
        lineHeight: '20px',
        color: COLOR.grey.main,
        '&:hover': {
          background: COLOR.grey['18'],
        },
        '&:focus': {
          background: COLOR.grey['18'],
        },
        '&.Mui-disabled': {
          color: COLOR.grey['54'],
          background: COLOR.grey['8'],
          boxShadow: 'none',
        },
        '&.Pagination-activeButton': {
          background: COLOR.controls.dark,
          color: '#ffffff',
        },
        '&.MuiIconButton-root': {
          padding: '0',
          height: '30px',
          '& .MuiSvgIcon-root': {
            width: '26px',
            height: '26px',
          },
        },
      },
    },
  },
  mainWrapperTwoRow: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  paddingDisable: {
    padding: '0 !important',
  },
  paddingDisableAndHiddeRowsLabel: {
    padding: '0 !important',
    '@global .Pagination-rowsLabel': {
      display: 'none',
    },
  },
  mr: {
    marginRight: 8,
  },
});

export default PagingStyles;
