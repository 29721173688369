// Core
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form/immutable';
// Engine
import { formName, formFields } from '../../../../../_helpers/data/pages/faqs/formFields';

const selector = formValueSelector(formName);

export function useFaqItemStatus() {
  const status = useSelector(state => selector(state, formFields.status)) || Map();
  return status.toJS();
}
