// Core
import { apply, delay, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { stopSubmit } from 'redux-form';

// Instruments
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../config/routes';
import { formName } from '../../../../../ui/page/Partner/form';
import { partnerActions } from '../../action';

export function* callCreateNewPartnerWorker({ payload: formData }) {
  yield put(partnerActions.mergePartnerCreateNew({ pending: true }));
  const response = yield apply(api, api.partner.createNewPartner, [formData]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(pageLinks.partner.all));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle, true));
        yield put(stopSubmit(formName, errors.reduce((acc, { message, property_path: propertyPath }) => {
          if (propertyPath === 'name') {
            acc.contactNameAndPosition = message;
          } else {
            acc[propertyPath] = message;
          }
          return acc;
        }, {})));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(partnerActions.mergePartnerCreateNew({ pending: false }));
}
