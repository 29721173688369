import { NavLink } from 'react-router-dom';
import React from 'react';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { pageLinks } from '../../../../../../../engine/config/routes';
import { IconButtonStyles } from '../../../../../../components/_Table/TableCell/Styles';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../../engine/config/access';

const EditComponent = (props) => {
  const { tableRow } = props;
  const hashId = tableRow.row.hashId;
  const hasCategoryEdit = useAccessList(accessList.categoryEdit);
  const hasCategoryShow = useAccessList(accessList.categoryShow);

  return (
    <>
      {hasCategoryEdit && (
        <NavLink to={pageLinks.categories.edit(hashId)}>
          <IconButtonStyles color="inherit" size="large">
            <ModeOutlinedIcon />
          </IconButtonStyles>
        </NavLink>
      )}
      {hasCategoryShow && (
        <NavLink to={pageLinks.categories.show(hashId)}>
          <IconButtonStyles color="inherit" size="large">
            <RemoveRedEyeOutlinedIcon />
          </IconButtonStyles>
        </NavLink>
      )}
    </>
  );
};

export default EditComponent;
