// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
import { isImmutable } from 'immutable';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import FormSelect from '../../../../../components/_Form/Selects/FormSelect/FormSelect';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import RenderCheckbox from './RenderCheckbox';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
import HelperText from './HelperText';
// Helpers
import { formFields } from '../../ContentProducts/components/ContentProductsSidebar/ProductMultipleEditingForm';
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';


function RenderFormFieldFormSelect(props) {
  const {
    disabled, input, label,
    massEdit, meta, margin,
    isRecommended, formSelectWidth, options,
    mode, noMargin, featureDisabled,
    setTouchField, changeFieldValue, refresh,
    onRefresh, canDelete, willUnmountCallBack,
    required, fieldWrapperStyle, dataTestId, searchDataTestId,
  } = props;
  const hasProductChangeQuantityAccess = useAccessList(accessList.productChangeQuantity);
  const touched = meta.touched;
  const error = !!(touched && meta.error);
  const isDisabled = input.name === formFields.quantity
    ? disabled || featureDisabled?.disabled || !hasProductChangeQuantityAccess
    : disabled || featureDisabled?.disabled;
  const { isValueChanged, value, showChangedMessage } = useIsValueWasChanged(input);
  const checkboxName = getCheckboxName(input.name);
  const [willBeClear, setWillBeClear] = useState(false);
  const normalizeOptions = isImmutable(options) ? options.toJS() : options;
  const classes = useStyles(ContentProductEditStyles);

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  useEffect(() => () => { // TODO https://github.com/redux-form/redux-form/issues/3558
    if (willUnmountCallBack) {
      willUnmountCallBack();
    }
  }, []);

  const classNames = cx(classes.formControl, {
    [classes.errorFormControl]: error,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });
  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;

  return (
    <FormControl error={error} onClick={onClick} className={classNames} margin={margin}>
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              noMargin={noMargin}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            formName={formName}
            {...input}
            name={input.name.toString()}
            disabled={isDisabled}
            canDelete={canDelete}
            fieldWrapperStyle={fieldWrapperStyle}
          >
            <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
              {input.name === 'idMagentoPartner'
                ? (
                  <Field
                    {...input}
                    meta={meta}
                    required
                    isPortal={false}
                    disabled={mode !== 'create'}
                    component={PartnerSelect}
                    name={input.name.toString()}
                    dataTestId={dataTestId}
                    searchDataTestId={searchDataTestId}
                  />
                )
                : (
                  <>
                    <FormSelect
                      {...input}
                      isPortal={false}
                      formSelectWidth={formSelectWidth}
                      options={normalizeOptions}
                      disabled={isDisabled}
                      label={label}
                      required={required}
                      isRecommended={isRecommended}
                      isChanged={showChangedMessage}
                      hasSearch
                      fullWidth
                      dataTestId={dataTestId}
                      searchDataTestId={searchDataTestId}
                    />
                    <HelperText
                      error={meta?.error}
                      isError={error}
                      isValueChanged={showChangedMessage}
                      isValueClear={willBeClear}
                      touched={touched}
                    />
                  </>
                )}
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

RenderFormFieldFormSelect.defaultProps = {
  variant: 'standard',
};

export default RenderFormFieldFormSelect;
