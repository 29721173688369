// Core
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// Helpers
import { COLOR } from '../../../../containers/App/AppStyles';

export const Wrapper = styled(Box)(
  (props) => {
    const { disabled, color } = props;
    if (color === 'dark') {
      return {
        '&': {
          color: COLOR.darkStyle.white['38'],
          '& .MuiInputAdornment-positionEnd p': {
            color: COLOR.darkStyle.white['72'],
          },
          '& .MuiInputLabel-root': {
            color: COLOR.darkStyle.white['72'],
            '&.Mui-focused:not(.Mui-error)': {
              color: COLOR.darkStyle.brand.secondary,
            },
          },
          '&:hover': {
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLOR.darkStyle.brand.secondary,
                color: COLOR.darkStyle.brand.secondary,
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${COLOR.darkStyle.white['54']}`,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLOR.darkStyle.white['24'],
          },
          '& .MuiInputBase-root': {
            color: COLOR.darkStyle.fontColorMain,
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px ${COLOR.darkStyle.main} inset`,
                WebkitTextFillColor: COLOR.darkStyle.fontColorMain,
                borderRadius: '0',
                color: '#fff !important',
              },
            },
            '& svg': {
              color: `${COLOR.darkStyle.white['54']}`,
            },
            '&:hover:not(.Mui-error)': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${COLOR.darkStyle.white['54']}`,
              },
            },
            '&.Mui-focused:not(.Mui-error)': {
              '& svg': {
                borderColor: COLOR.darkStyle.brand.secondary,
                color: COLOR.darkStyle.brand.secondary,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLOR.darkStyle.brand.secondary,
                color: COLOR.darkStyle.brand.secondary,
              },
            },
            '& .MuiInputAdornment-root': {
              color: COLOR.darkStyle.white['54'],
            },
          },
        },
      };
    }
    return {
      width: '100%',
      caretColor: COLOR.controls.dark,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR.grey['38'],
        borderWidth: '1px !important',
      },
      '& .MuiInputAdornment-positionEnd p': {
        color: '#999999',
      },
      '& .Mui-focused:not(.Mui-error)': {
        '& input': {
          color: COLOR.grey.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLOR.controls.dark,
        },
      },
      '&:hover .MuiFormLabel-root:not(.Mui-error, .Mui-focused, .Mui-disabled)': {
        color: disabled ? undefined : COLOR.grey.main,
      },
      '& .MuiOutlinedInput-root:not(.Mui-error, .Mui-focused, .Mui-disabled)': {
        '&:hover fieldset': {
          borderColor: COLOR.grey.main,
        },
      },
    };
  },
);

export const StyledTextField = styled(TextField)(
  (props) => {
    const { isRecommended, multiline } = props;
    return {
      '& input.MuiInputBase-input, & textArea.MuiInputBase-inputMultiline': {
        fontSize: '14px',
        lineHeight: '22px',
      },
      '& textArea.MuiInputBase-inputMultiline': {
        padding: '0',
      },
      ...(multiline && {
        '& .MuiInputBase-root.MuiInputBase-multiline': {
          paddingRight: '0',
          paddingBottom: '0',
        },
        '& textArea.MuiInputBase-inputMultiline': {
          paddingRight: '14px',
          paddingBottom: '8.5px',
          resize: 'vertical',
        },
      }),
      '& input.MuiInputBase-input': {
        padding: '10px 14px',
      },
      '& input.MuiInputBase-inputAdornedStart': {
        paddingLeft: 0,
      },
      '& .MuiFormLabel-root': {
        '&[data-shrink="false"]': {
          fontSize: '14px',
          zIndex: 'initial',
        },
        '.MuiFormLabel-asterisk': {
          color: isRecommended ? COLOR.controls.dark : COLOR.error,
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: '7px',
        minWidth: '24px',
        flexShrink: 0,
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: 3,
      },
      '& .MuiIconButton-edgeEnd': {
        width: 24,
        height: 24,
        marginRight: '10px',
      },
      '& .Mui-disabled': {
        backdropColor: 'orange',
        '& svg': {
          color: COLOR.grey['12'],
        },
        '& input': {
          color: COLOR.grey['38'],
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${COLOR.grey['12']} !important`,
        },
      },
    };
  },
);

export const Description = styled(Box)(
  () => ({
    marginLeft: '14px',
    marginTop: '-8px',
    fontSize: '12px',
    color: '#777',
  }),
);
