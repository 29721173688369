// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { billingAsyncAction } from '../../../../../engine/core/billing/saga/asyncAction';
import { useAccessList } from '../../../../hooks/useAccessList';

function DownloadBillingPartnersXlsFileButton() {
  const { t } = useTranslation();
  const hasAccessToDownloadBillingPartnersXls = useAccessList(accessList.billingStatusPartnersDownload);
  const isLoading = useSelector(selectors.billing.billingPartnersDownloadIsLoading);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(billingAsyncAction.getBillingPartnersDownloadAsync());

  return hasAccessToDownloadBillingPartnersXls && (
    <ButtonWithIconAndCircularProgress
      isLoading={isLoading}
      text={t('Скачать xls файл')}
      disabled={isLoading}
      onClick={handleClick}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DownloadBillingPartnersXlsFileButton;
