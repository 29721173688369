import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';
import { getDate } from '../../../../_helpers/getDate';

const ONE_DAY = 1;
const SECOND_DAY = 2;
const THIRD_DAY = 3;

const TIME_RANGE_FIRST_OPTION = 'з 10:00 до 15:00';
const TIME_RANGE_SECOND_OPTION = 'з 15:00 до 18:00';

export default function CurrierArriveModal({ open, onClose, accept }) {
  const { i18n } = useTranslation();

  const getDateWithOffset = (offset) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + offset);
    const stringDate = getDate(currentDate, 'dd DD.MM', i18n.language);
    // Moment JS for not EN locale does not make first letter uppercased (вт -> Вт)
    return stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
  };

  const tomorrow = getDateWithOffset(ONE_DAY);
  const theDayAfterTomorrow = getDateWithOffset(SECOND_DAY);
  const thirdDay = getDateWithOffset(THIRD_DAY);

  const [selectedDate, setSelectedDate] = useState({ dateObject: new Date(), dateString: tomorrow });
  const [selectedTimeRange, setSelectedTimeRange] = useState(TIME_RANGE_FIRST_OPTION);

  const handleChangeDate = (offset, dateString) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + offset);
    setSelectedDate({ dateObject: currentDate, dateString });
  };

  const handleChangeTimeRange = (timeRange) => {
    setSelectedTimeRange(timeRange);
  };

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll={'body'}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '380px',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Make sure the Box takes up the full viewport height
      }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box
            borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
            padding={2}
          >
            <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>Оберіть дату та час, щоб <br /> передати кур`єру посилку</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Typography fontSize={'14px'}>Оберіть дату</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  sx={{ width: '80px', marginRight: '4px' }}
                  variant={selectedDate.dateString === tomorrow ? 'contained' : 'outlined'}
                  onClick={() => handleChangeDate(ONE_DAY, tomorrow)}
                >Завтра
                </Button>
                <Button
                  sx={{ width: '105px', marginRight: '4px' }}
                  variant={selectedDate.dateString === theDayAfterTomorrow ? 'contained' : 'outlined'}
                  onClick={() => handleChangeDate(SECOND_DAY, theDayAfterTomorrow)}
                >Післязавтра
                </Button>
                <Button
                  sx={{ width: '105px' }}
                  variant={selectedDate.dateString === thirdDay ? 'contained' : 'outlined'}
                  onClick={() => handleChangeDate(THIRD_DAY, thirdDay)}
                >{thirdDay}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={'14px'}>Оберіть Час</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  variant={selectedTimeRange === TIME_RANGE_FIRST_OPTION ? 'contained' : 'outlined'}
                  onClick={() => handleChangeTimeRange(TIME_RANGE_FIRST_OPTION)}
                >з 10:00 до 15:00
                </Button>
                <Button
                  variant={selectedTimeRange === TIME_RANGE_SECOND_OPTION ? 'contained' : 'outlined'}
                  onClick={() => handleChangeTimeRange(TIME_RANGE_SECOND_OPTION)}
                >з 15:00 до 18:00
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{
          alignSelf: 'flex-end', // Align the last box at the bottom
          marginTop: 'auto', // Push the last box to the bottom
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button sx={{ marginRight: '8px' }} variant="outlined" onClick={onClose}>Скасувати</Button>
            <Button
              variant="contained"
              onClick={() => {
                accept(selectedDate.dateObject, selectedDate.dateString, selectedTimeRange);
                onClose();
              }}
            >Підтвердити
            </Button>
          </Box>
        </Box>
      </Box>
    </RightSlideModal>
  );
}
