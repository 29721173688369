// core
import { getIn, List, Map } from 'immutable';

// reducers
import { keys as billingKeys, initialState as billingInitialState } from './reducers';

export const billing = Object.freeze({
  statusItems: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.status, billingKeys.items], {}),
  statusInformers: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.status, billingKeys.informers], Map()),
  ratingStatus: state => getIn(state, [billingKeys.controller, billingKeys.ratingStatus, billingKeys.rating], ''),
  externalRatingStatus: state => getIn(state, [billingKeys.controller, billingKeys.ratingStatus, billingKeys.externalRating], ''),
  ratingStatusPending: state => getIn(state, [billingKeys.controller, billingKeys.ratingStatus, billingKeys.pending], false),
  billingPartnersDownloadIsLoading: state => getIn(state, [billingKeys.controller, billingKeys.billingPartnersDownload, billingKeys.pending], false),
  statusPending: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.status, billingKeys.pending], true),
  downloadPaymentOrderPending: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.downloadPaymentOrder, billingKeys.pending], false),
  downloadPaymentStatus: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.downloadPaymentOrder, billingKeys.status], null),
  billingSelectFilterPartners: state => getIn(state, [billingKeys.controller, billingKeys.billingSelectFilterPartners], null),
  billingInvoiceModalIsOpen: state => getIn(state, [billingKeys.controller, billingKeys.billingInvoiceModal, billingKeys.modalIsOpen], false),
  statusesListItems: state => getIn(state, [billingKeys.controller, billingKeys.billingStatusesList, billingKeys.items], []),
  statusesListItemsPending: state => getIn(state, [billingKeys.controller, billingKeys.billingStatusesList, billingKeys.pending], false),
  paymentTypes: state => getIn(state, [billingKeys.controller, billingKeys.billingPaymentTypes, billingKeys.items], []),
  paymentTypesPending: state => getIn(state, [billingKeys.controller, billingKeys.billingPaymentTypes, billingKeys.pending], []),
  ordersDashboard: state => getIn(state, [billingKeys.controller, billingKeys.billingOrderDashboard], Map()),
  onTimeOrdersDashboard: state => getIn(state, [billingKeys.controller, billingKeys.billingOnTimeOrderDashboard], Map()),
  successDeliveryDashboard: state => getIn(state, [billingKeys.controller, billingKeys.billingSuccessDeliveryDashboard], Map()),
  newOrdersDashboard: state => getIn(state, [billingKeys.controller, billingKeys.billingNewOrderDashboard, billingKeys.items], List()),
  newOrdersDashboardPending: state => getIn(state, [billingKeys.controller, billingKeys.billingNewOrderDashboard, billingKeys.pending], false),
  dashboardLastTransactions: state => getIn(state, [billingKeys.controller, billingKeys.lastTransactions, billingKeys.items], List()),
  dashboardLastTransactionsPending: state => getIn(state, [billingKeys.controller, billingKeys.lastTransactions, billingKeys.pending], false),
  invoiceTypes: state => getIn(state, [billingKeys.controller, billingKeys.invoiceTypes], billingInitialState.invoiceTypes),
  creditLimitModalStatus: state => getIn(state, [billingKeys.controller, billingKeys.billingStatus, billingKeys.status, billingKeys.items, billingKeys.creditLimitModalStatus], null),
  reportDownloadParameters: state => getIn(state, [billingKeys.controller, billingKeys.billingReportDownload, billingKeys.parameters], Map()),
  reportDownloadPending: state => getIn(state, [billingKeys.controller, billingKeys.billingReportDownload, billingKeys.pending], false),
});
