// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
// Icon
import IconButton from '@mui/material/IconButton';
import CompareIcon from '@mui/icons-material/Compare';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// UI
import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

// Styles
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxStyles from './CheckBoxFormStyles';

// Helpers
import { validators } from '../../../../_helpers/validationsForm';
import TooltipHint from '../../Tooltip/TooltipHint';

const CheckBoxForm = (props) => {
  const {
    required, classes, fullWidth, margin, validation, defaultLabelStyle,
    meta, label, disabled, color, input, helperText, borderBottom,
    approve, approvedValue, valueOld, tooltipText, handleChange,
    flexOne, onApproveValue, checkboxClasses,
  } = props;
  const { t } = useTranslation();

  const onChange = (event) => {
    input.onChange(event);
    if (handleChange) {
      handleChange(event);
    }
  };

  const { touched, error } = meta;
  const hasError = validation && touched && typeof error !== 'undefined';

  const [compare, setCompare] = useState(false);

  // ClassNames
  const activeCompare = classNames(classes.button, classes.compareButton, {
    [classes.compareButtonActive]: compare,
  });
  const activeApproveTrue = classNames(classes.button, classes.checkButton, {
    [classes.checkButtonActive]: approvedValue === 'current',
  });
  const activeApproveFalse = classNames(classes.button, classes.cancelButton, {
    [classes.cancelButtonActive]: approvedValue === 'old',
  });
  const wrapperClassNames = classNames(classes.wrapper, {
    [classes.hasError]: validation && touched && error,
    [classes.wrapperWithApprove]: valueOld !== undefined && approve,
    [classes.wrapperRequired]: required,
    [classes.flexOne]: flexOne,
    [classes.wrapperRequired]: required,
  });
  const labelClassNames = classNames(classes.label, {
    [classes.defaultLabelStyle]: defaultLabelStyle,
  });
  const controlClassNames = classNames({
    [classes.controlUnderLine]: borderBottom,
  });

  const handleOnApproveValue = value => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value });
    }
  };

  const checkOldValue = isNil(valueOld) ? '' : valueOld;
  const showApproveButton = valueOld !== undefined && approve;
  return (
    <div className={wrapperClassNames}>
      <FormControl error={hasError} fullWidth={fullWidth} margin={margin} className={controlClassNames}>
        {required && (
          <span className={classes.buttonRequired}> *</span>
        )}
        <FormControlLabel
          classes={{
            label: labelClassNames,
            root: classNames({
              [classes.notFullWidth]: !fullWidth,
            }),
          }}
          control={(
            <Checkbox
              className={checkboxClasses}
              disabled={compare ? true : !!disabled}
              color={color}
              checked={compare ? checkOldValue : !!input.value}
              {...input}
              onChange={onChange}
            />
              )}
          label={label}
        />
        {hasError && (
        <div className={wrapperClassNames}>
          <FormHelperText>{error || (input.value === false && required && validators.required())}</FormHelperText>
        </div>
        )}
        {(helperText && !hasError) && (
        <div className={wrapperClassNames}>
          <FormHelperText>{helperText}</FormHelperText>
        </div>
        )}
      </FormControl>
      {tooltipText || showApproveButton
        && (
        <div className={classes.wrapperButton}>
          {tooltipText && (
          <TooltipHint title={tooltipText} classNameButton={classes.button} />
          )}
          {showApproveButton && (
            <>
              <Tooltip title={t('Показать прошлые данные')} placement="top">
                <IconButton
                  className={activeCompare}
                  onClick={() => setCompare(!compare)}
                  aria-label={t('Показать прошлые данные')}
                  size="large"
                >
                  <CompareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Подтвердить')} placement="top">
                <IconButton
                  className={activeApproveTrue}
                  onClick={handleOnApproveValue('current')}
                  aria-label={t('Подтвердить')}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Отклонить')} placement="top">
                <IconButton
                  className={activeApproveFalse}
                  onClick={handleOnApproveValue('old')}
                  aria-label={t('Отклонить')}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        )
      }
    </div>
  );
};

CheckBoxForm.propTypes = {
  approve: PropTypes.bool,
  approvedValue: PropTypes.string,
  valueOld: PropTypes.string,
  tooltipText: PropTypes.string,
  flexOne: PropTypes.bool,
  onApproveValue: PropTypes.func,
  handleChange: PropTypes.func,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  defaultLabelStyle: PropTypes.bool,
  borderBottom: PropTypes.bool,
  margin: PropTypes.string,
  validation: PropTypes.bool,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
  input: PropTypes.object,
  checkboxClasses: PropTypes.string,
};

CheckBoxForm.defaultProps = {
  color: 'primary',
};

export default withStyles(CheckBoxStyles)(CheckBoxForm);
