// Core
import { put, select } from 'redux-saga/effects';
// Engine
import { isNil } from 'lodash';
import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';
import { pageLinks } from '../../../../../config/routes';
import { billingActions } from '../../../action';
import i18n from '../../../../../init/i18n';
import { selectors } from '../../../../../config/selectors';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(lists || {});
  const billingSelectFilterPartners = yield select(selectors.billing.billingSelectFilterPartners);
  const billingStatusFilterName = 'billingStatus';
  const summaryBalanceFilterName = 'summaryBalance';
  const summaryHoldBalanceFilterName = 'holdBalance';

  switch (url) {
    case pageLinks.billing.partners: {
      if (!isNil(billingSelectFilterPartners)) {
        switch (billingSelectFilterPartners) {
          case 'enabledPartnersQuantity':
            initialPageState.filters = [{
              columnName: billingStatusFilterName,
              value: { label: i18n.t('Активный'), value: 1 },
            }];
            break;
          case 'creditLimitPartnersQuantity':
            initialPageState.filters = [{
              columnName: billingStatusFilterName,
              value: { label: i18n.t('Кредитный лимит'), value: 2 },
            }];
            break;
          case 'disabledPartnersQuantity':
            initialPageState.filters = [{
              columnName: billingStatusFilterName,
              value: { label: i18n.t('Заблокирован'), value: 0 },
            }];
            break;
          case 'positiveBalancesAmount':
            initialPageState.filters = [{
              columnName: summaryBalanceFilterName,
              value: 0,
            }];
            initialPageState.conditions = [{
              columnName: 'conditionsBalance',
              value: 'greaterThan',
            }];
            break;

          case 'negativeBalancesAmount':
            initialPageState.filters = [{
              columnName: summaryBalanceFilterName,
              value: 0,
            }];
            initialPageState.conditions = [{
              columnName: 'conditionsSummaryBalance',
              value: 'lessThanOrEqual',
            }];
            break;
          case 'holdBalancesAmount':
            initialPageState.filters = [{
              columnName: summaryHoldBalanceFilterName,
              value: 0,
            }];
            initialPageState.conditions = [{
              columnName: 'conditionsHold',
              value: 'greaterThan',
            }];
            break;
          default: {
            break;
          }
        }
        yield put(billingActions.setBillingSelectFilterPartners(null));
        yield put(actions.setInitialState(initialPageState));
      } else {
        yield put(actions.setInitialState(initialPageState));
      }

      break;
    }

    default: {
      break;
    }
  }
}
