// Core
import React, { useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// UI
import CircularProgress from '@mui/material/CircularProgress';

// parts
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import ChatMessage from './ChatMessage/ChatMessage';
import defaultAvatar from '../../../../assets/img/default-avatar.png';
import alloAvatar from '../../../../assets/img/logo_short_red.svg';
import Loading from '../../../components/Loading/Loading';
import EnterMessage from './EnterMessage/EnterMessage';

// Hooks
import { useStyles } from '../../../hooks/useStyles';
import { asyncActions } from '../../../../engine/core/serviceDesk/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import useInfinityScroll from '../_hooks/useInfinityScroll';

// styles
import ChatComponentsStyles from './ChatComponentsStyles';

export const SD_CHAT_MESSAGES_PER_PAGE = 17;

const ChatComponents = ({ refresh }) => {
  const classes = useStyles(ChatComponentsStyles);
  const { hashId } = useParams();
  const dispatch = useDispatch();

  const listInStore = useSelector(selectors.serviceDesk.messages);
  const itemsFromServer = useSelector(selectors.serviceDesk.messagesFromServer);
  const infinityScrollState = useSelector(selectors.serviceDesk.infinityScrollState);
  const messagesFromServerAreLoading = useSelector(selectors.serviceDesk.messagesFromServerAreLoading);
  const isCanEdit = useSelector(selectors.serviceDesk.canEdit);
  const classesHeight = useCallback(defaultClass => cx(defaultClass, {
    [classes.chatListHeightIsEdit]: isCanEdit,
    [classes.chatListHeightIsVisible]: !isCanEdit,
  }), [classes, isCanEdit]);

  const {
    hasMore,
    listToRender,
    loading,
    offset,
    setOffset,
  } = useInfinityScroll({
    listInStore,
    itemsFromServer,
    limit: SD_CHAT_MESSAGES_PER_PAGE,
    infinityScrollState,
  });

  const infinityScrollStyles = {
    display: 'flex',
    flexDirection: 'column-reverse',
  };

  useEffect(() => {
    dispatch(asyncActions.getMessages({ hashId, offset: 0, limit: SD_CHAT_MESSAGES_PER_PAGE }));
  }, [dispatch, hashId]);

  const fetchData = () => {
    dispatch(asyncActions.getMessages({ hashId, offset, limit: SD_CHAT_MESSAGES_PER_PAGE }));
    setOffset(offset + SD_CHAT_MESSAGES_PER_PAGE);
  };

  return (
    <TabsContainer className={classes.tabsContainer} key={refresh}>
      {loading
        ? (
          <div className={classesHeight(classes.messageLoader)}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )
        : (
          <div
            id="scrollableDivMessages"
            className={classesHeight(classes.chatList)}
          >
            <InfiniteScroll
              dataLength={listToRender.length} // This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={<Loading className={classes.infinityLoader} isLoading={messagesFromServerAreLoading} color="secondary" />}
              style={infinityScrollStyles} // To put endMessage and loader to the top.
              inverse
              scrollableTarget="scrollableDivMessages"
            >
              <>
                {listToRender.map(msg => (
                  <ChatMessage
                    key={msg.id}
                    title={msg.name}
                    typeAllo={msg.allo_support}
                    text={msg.message}
                    date={msg.updatedAt.date}
                    avatar={msg.allo_support ? alloAvatar : defaultAvatar}
                    partner={msg.partner}
                    files={msg.files}
                  />
                ))}
              </>
            </InfiniteScroll>
          </div>
        )
      }
      {isCanEdit && <EnterMessage />}
    </TabsContainer>
  );
};

ChatComponents.propTypes = {
  refresh: PropTypes.number,
};

export default ChatComponents;
