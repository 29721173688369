// Core
import { put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';
import { pageLinks } from '../../../../config/routes';
import { partnerStatuses } from '../../../../config/partnerStatuses';

export function* callSetFiltersWorker(settings) {
  const columnsObjectLike = {};
  const columns = get(settings, 'partner.gridSettings', []);
  const url = yield select(selectors.router.pathname);

  columns.reduce((acc, item) => {
    const { name, visible } = item;
    acc[name] = visible;
    return acc;
  }, columnsObjectLike);

  const filters = yield select(selectors.partners.filters);
  const sorting = yield select(selectors.partners.sorting);

  const filteredFilters = filters.toJS().filter((item) => {
    const key = get(item, 'columnName');
    const isColumnVisible = columnsObjectLike[key];

    if (isColumnVisible) {
      return true;
    }

    let filterValue = [get(item, 'value')];

    if (Array.isArray(filterValue)) {
      filterValue = filterValue.map(filterValueItem => filterValueItem.value);
    } else {
      filterValue = [filterValue];
    }

    switch (url) {
      case pageLinks.partner.new: {
        return filterValue.includes(partnerStatuses.questionnaireFill);
      }
      case pageLinks.partner.waiting: {
        return filterValue.includes(partnerStatuses.contractSigning);
      }
      case pageLinks.partner.rejected: {
        return filterValue.includes(partnerStatuses.frozenSigning) || filterValue.includes(partnerStatuses.cancelStatus);
      }
      default: {
        return false;
      }
    }
  });

  const filteredSorting = sorting.toJS().filter((item) => {
    const key = get(item, 'columnName');
    return columnsObjectLike[key];
  });

  yield put(actions.setFilters(filteredFilters));
  yield put(actions.setSorting(filteredSorting));
}
