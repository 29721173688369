// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  categoriesFeatureList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    treeDataRowIds: List(),
    reload: false,
  }),
  categoriesFeatureImport: Map({
    pending: false,
    isOpen: false,
  }),
  categoriesFeatureExport: Map({
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'categoriesFeatureManagement',
  categoriesFeatureList: 'categoriesFeatureList',
  isOpen: 'isOpen',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  item: 'item',
  treeDataRowIds: 'treeDataRowIds',
  commissionsUpload: 'commissionsUpload',
  submitting: 'submitting',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  categoriesFeatureImport: 'categoriesFeatureImport',
  categoriesFeatureExport: 'categoriesFeatureExport',
});

export const categoriesFeatureManagementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.categoriesFeatureList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.categoriesFeatureList, stateKeys.pending], pending)
        .setIn([stateKeys.categoriesFeatureList, stateKeys.items], List(items))
        .setIn([stateKeys.categoriesFeatureList, stateKeys.totalCount], totalCount);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_SORTING: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.sorting], List(payload));
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.filters], List(filters));
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_PAGE_SIZE: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.pageSize], payload);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_RELOAD: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.reload], payload);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_TREE_DATA_ROW_IDS: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.treeDataRowIds], List(payload));
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_CURRENT_PAGE: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.currentPage], payload);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.categoriesFeatureList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.categoriesFeatureList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.categoriesFeatureList, stateKeys.filters], List(filters))
        .setIn([stateKeys.categoriesFeatureList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.categoriesFeatureList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.categoriesFeatureList, stateKeys.reload], reload)
        .setIn([stateKeys.categoriesFeatureList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_STATUS: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.settingStatus], payload);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_PENDING: {
      return state.setIn([stateKeys.categoriesFeatureList, stateKeys.settingPending], payload);
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_IS_OPEN: {
      return state.setIn([stateKeys.commissionsUpload, stateKeys.isOpen], fromJS(payload));
    }
    case types.SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_UPLOADING: {
      return state.setIn([stateKeys.commissionsUpload, stateKeys.pending], fromJS(payload));
    }
    case types.MERGE_CATEGORIES_FEATURE_IMPORT: {
      return state.mergeIn([stateKeys.categoriesFeatureImport], payload);
    }
    case types.MERGE_CATEGORIES_FEATURE_EXPORT: {
      return state.mergeIn([stateKeys.categoriesFeatureExport], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
