// Core
import React from 'react';
import { useStyles } from '../../../../hooks/useStyles';
// Images
import Logo from '../../../../../assets/img/logo.svg';
// Styles
import MainLogoImageStyles from './MainLogoImageStyles';

function MainLogoImage() {
  const classes = useStyles(MainLogoImageStyles);
  return (
    <img
      alt=""
      className={classes.logo}
      src={Logo}
    />
  );
}

export default MainLogoImage;
