import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// Engine
import { change } from 'redux-form/immutable';
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { uiActionAsync } from '../../../../../../../engine/core/ui/saga/asyncAction';
import { store } from '../../../../../../../engine/init/store';
import { selectors } from '../../../../../../../engine/config/selectors';
import { fileExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { formFields, formName } from '../../../../../../page/Products/PriceLists/Import/components/AddFormXml';

export const importXlsxCallback = ({ hasAdminAccess }) => (data) => {
  const {
    action, status, index, type,
  } = data;
  const state = store.getState();
  const categoryGuideItem = selectors.categories.categoriesListContext(state)?.toJS().filter(
    item => item.value !== null,
  )?.[1];

  const midPartnerData = selectors.partner.listContext(state)?.toJS().filter(
    item => item.value !== null,
  )?.[55];
  const getIndexStep = stepIndex => hasAdminAccess ? stepIndex + 1 : stepIndex;

  if (index === 0 && action === ACTIONS.START) {
    store.dispatch(uiActions.setImportPageType('XLSX'));
  }
  if (index === 0 && action === ACTIONS.PREV && type === EVENTS.STEP_BEFORE) {
    store.dispatch(uiActions.setImportPageType('XLSX'));
  }
  if (index === 1 && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActionAsync.setImportCategory(categoryGuideItem));
  }
  if (index === getIndexStep(2) && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xlsx));
  }
  if (hasAdminAccess && index === 2 && type === EVENTS.STEP_BEFORE) {
    store.dispatch(change(formName, formFields.partner, midPartnerData));
  }
  if (index === getIndexStep(3)) {
    store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xlsx));
  }
  if (index === getIndexStep(2)) {
    store.dispatch(uiActionAsync.setImportCategory(categoryGuideItem));
  }

  if (action === ACTIONS.PREV && type === EVENTS.TARGET_NOT_FOUND) {
    if (index === 0) {
      store.dispatch(uiActions.setImportPageType('XLSX'));
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setJoyrideStepIndex(0));
    store.dispatch(uiActions.setImportPageFilePondDefault(null));
    store.dispatch(uiActionAsync.setImportCategory([]));
    store.dispatch(uiActions.setImportPageType('XML'));
    if (hasAdminAccess) {
      store.dispatch(change(formName, formFields.partner, null));
    }
  }
};
