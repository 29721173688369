// Core
import { put, select } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
import { pageLinks } from '../../../../../config/routes';
import { selectors } from '../../../../../config/selectors';
import { history } from '../../../../../init/store';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';

let prevIsWithCategoryId;

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const feedProductsPageSendQuery = yield localData.getItem(localData.keysObj.feedProductsPageSendQuery);
  const query = (yield select(selectors.router.query)).toJS();
  const initialPageState = getInitialPageState(lists || {});
  const feedProductsLink = pageLinks.feed.products;
  const currentId = url.substring(url.lastIndexOf('/') + 1);
  const isWithCategoryId = url.indexOf(`${feedProductsLink}/${currentId}`) !== -1;
  if (isWithCategoryId) {
    initialPageState.filters.push({ columnName: 'feedCategory', value: currentId });
    yield put(actions.setSendQuery(true));
    prevIsWithCategoryId = isWithCategoryId;
  } else if (prevIsWithCategoryId === true && isWithCategoryId === false && feedProductsPageSendQuery === true) {
    prevIsWithCategoryId = undefined;
    yield put(actions.setSendQuery(true));
  }
  Object.entries(query).forEach(([key, value]) => {
    let currentFilterValue;
    switch (key) {
      case 'feedProductStatus': {
        currentFilterValue = [{ value: decodeURI(value), label: 'В очереди' }];
        break;
      }
      case 'feedProductElaborationStatus': {
        currentFilterValue = [{ value: decodeURI(value), label: 'Новый' }];
        break;
      }
      case 'categoryAttributeSet': {
        currentFilterValue = JSON.parse(decodeURI(value));
        break;
      }
      default: {
        currentFilterValue = decodeURI(value);
      }
    }
    initialPageState.filters.push({ columnName: key, value: currentFilterValue });
    history.push({
      pathname: window.location.pathname,
    });
  });
  if (Object.entries(query).length) {
    yield put(actions.setSendQuery(true));
  }
  yield put(actions.setInitialState(initialPageState));
}
