// Core
import { Map, List } from 'immutable';
import { apply, put } from 'redux-saga/effects';

// actions
import { actions } from '../../actions';

// config
import { api } from '../../../../../config/api';

export function* callGetPartnersPaymentMethodsWorker({ payload: partner }) {
  yield put(actions.mergePartnerPaymentTypeModal({
    methods: Map({
      pending: true,
      items: List([]),
    }),
  }));

  const response = yield apply(api, api.partners.getPartnersPaymentMethods, [partner]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.mergePartnerPaymentTypeModal({
      methods: Map({
        pending: false,
        items: List(data),
      }),
    }));
  } else {
    yield put(actions.mergePartnerPaymentTypeModal({
      methods: Map({
        pending: false,
        items: List([]),
      }),
    }));
  }
}
