// core
import { memo } from 'react';
import { Field } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import Box from '@mui/material/Box';
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
import ControlButtons from '../ControlButtons/ControlButtons';

function ExeptionWordGroupFields(props) {
  const { fields, index, member } = props;
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
      <Field
        name={`${member}.value`}
        component={TextField}
        fullWidth
        label={t('Введите слово исключение')}
      />
      <ControlButtons index={index} fields={fields} />
    </Box>
  );
}

ExeptionWordGroupFields.propTypes = {
  fields: PropTypes.object.isRequired,
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(ExeptionWordGroupFields);
