export const types = Object.freeze({
  APPROVE_CATEGORIES_PERCENT: 'OLD_APPROVE_CATEGORIES_PERCENT',
  APPROVE_INFO_FIELD_VALUES: 'OLD_APPROVE_INFO_FIELD_VALUES',
  APPROVE_INFO_FIELD: 'OLD_APPROVE_INFO_FIELD',
  SET_PARTNERS_LIST_SETTING_STATUS: 'OLD_SET_PARTNERS_LIST_SETTING_STATUS',
  SET_PARTNERS_LIST_SETTING_PENDING: 'OLD_SET_PARTNERS_LIST_SETTING_PENDING',
  SET_PARTNER_INFO: 'OLD_SET_PARTNER_INFO',
  SET_STATUSES_LIST: 'OLD_SET_STATUSES_LIST',
  SET_PARTNERS_LIST: 'OLD_SET_PARTNERS_LIST',
  SET_STATUSES_LIST_PENDING: 'SET_STATUSES_LIST_PENDING',
  SET_PARTNER_HASH_ID: 'OLD_SET_PARTNER_HASH_ID',
  SET_PARTNER_CONTRACT_PAGE: 'OLD_SET_PARTNER_CONTRACT_PAGE',
  SET_PARTNER_CONTRACT_LINK: 'OLD_SET_PARTNER_CONTRACT_LINK',
  SET_PARTNERS_CURRENT_PAGE: 'OLD_SET_PARTNERS_CURRENT_PAGE',
  SET_PARTNERS_DOWNLOAD_PENDING: 'OLD_SET_PARTNERS_DOWNLOAD_PENDING',
  SET_PARTNER_DOWNLOAD_PENDING: 'OLD_SET_PARTNER_DOWNLOAD_PENDING',
  SET_PARTNERS_PAGE_SIZE: 'OLD_SET_PARTNERS_PAGE_SIZE',
  MERGE_PARTNER_SAVE: 'OLD_MERGE_PARTNER_SAVE',
  MERGE_PARTNER_CREATE_NEW: 'OLD_MERGE_PARTNER_CREATE_NEW',
  CHANGE_PARTNERS_SORTING: 'OLD_CHANGE_PARTNERS_SORTING',
  CHANGE_PARTNERS_FILTERS: 'OLD_CHANGE_PARTNERS_FILTERS',
  RESET_PARTNERS_FILTERS: 'OLD_RESET_PARTNERS_FILTERS',
  SET_PARTNER_CONTEXT_LIST: 'OLD_SET_PARTNER_CONTEXT_LIST',
  SET_PARTNERS_INITIAL_STATE: 'OLD_SET_PARTNERS_INITIAL_STATE',
  SET_PARTNER_SELECTION: 'OLD_SET_PARTNER_SELECTION',
  SET_PARTNER_LIST_CONTEXT: 'OLD_SET_PARTNER_LIST_CONTEXT',
  SET_PARTNER_CONTEXT_PENDING: 'OLD_SET_PARTNER_CONTEXT_PENDING',
  SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING: 'OLD_SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING',
  SET_PARTNER_MANAGERS_LIST: 'OLD_SET_PARTNER_MANAGERS_LIST',
  SET_PARTNER_MANAGERS_SORTING: 'OLD_SET_PARTNER_MANAGERS_SORTING',
  SET_PARTNER_MANAGERS_FILTERS: 'OLD_SET_PARTNER_MANAGERS_FILTERS',
  SET_PARTNER_MANAGERS_CURRENT_PAGE: 'OLD_SET_PARTNER_MANAGERS_CURRENT_PAGE',
  SET_PARTNERS_MANAGERS_PAGE_SIZE: 'OLD_SET_PARTNERS_MANAGERS_PAGE_SIZE',
  SET_PARTNER_MANAGERS_PENDING: 'OLD_SET_PARTNER_MANAGERS_PENDING',
  MERGE_IN_PARTNER_MANAGERS: 'OLD_MERGE_IN_PARTNER_MANAGERS',
  MERGE_PARTNER_SEND_CONTRACT_TO_SED: 'OLD_MERGE_PARTNER_SEND_CONTRACT_TO_SED',
  CHANGE_USERS_CONTEXT_ACTIVE_EDIT: 'OLD_CHANGE_USERS_CONTEXT_ACTIVE_EDIT',
  SET_PARTNERS_MANAGER_INITIAL_STATE: 'OLD_SET_PARTNERS_MANAGER_INITIAL_STATE',
  UPDATE_PARTNERS_MANAGER_LIST: 'OLD_UPDATE_PARTNERS_MANAGER_LIST',
  MERGE_PARTNER_IS_TOP: 'OLD_MERGE_PARTNER_IS_TOP',
  SET_USERS_LIST_CONTEXT: 'SET_USERS_LIST_CONTEXT',
  SET_USERS_LIST_CONTEXT_AND_ROLE: 'SET_USERS_LIST_CONTEXT_AND_ROLE',
  SET_USERS_LIST_CONTEXT_PENDING: 'SET_USERS_LIST_CONTEXT_PENDING',
  SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_PENDING: 'SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_PENDING',
  SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_LIST: 'SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_LIST',
  SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS: 'SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS',
  SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS_PENDING: 'SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS_PENDING',
  SET_BUSINESSES_LIST: 'SET_BUSINESSES_LIST',
  SET_BUSINESSES_LIST_PENDING: 'SET_BUSINESSES_LIST_PENDING',
});
