import { styled } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';

export const ButtonBaseSwitcher = styled(ButtonBase)`
  & .MuiTouchRipple-root {
    opacity: 1;
    border-radius: 50px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    & .MuiTouchRipple-ripple {
      background: #c7d6fc;
    }
  }

  & .MuiTouchRipple-child {
    background: none;
  }
`;
