// Core
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import useMediaQuery from '@mui/material/useMediaQuery';
// parts
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
import ContentTemplatesRow from './components/ContentTemplatesRow/ContentTemplatesRow';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/contentTemplates/actions';
// Hooks
import { useContentTemplatesList } from './_hooks/useContentTemplatesList';
// import { tableColumns } from "../../../_helpers/data/pages/contentMenagement/contentTemplates/tableColumns";
// import { asyncActions } from '../../../../../engine/core/contentTemplates/saga/asyncActions';

const TemplateRowComponent = ({ row }) => <ContentTemplatesRow row={row} />;

function ContentTemplatesTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.contentTemplatesImport.isLoading);
  useContentTemplatesList();

  const viewLessTablet = useMediaQuery('(max-width: 600px)');
  const columns = useMemo(() => [
    {
      name: 'name',
      title: t('Название категории'),
      width: 250,
    },
    ...(isMobile && viewLessTablet
      ? [
        {
          width: 250,
        },
      ]
      : []),
  ], [t, isMobile, viewLessTablet]);

  // Rows
  const rows = useSelector(selectors.contentTemplatesImport.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.contentTemplatesImport.currentPage);
  const pageSize = useSelector(selectors.contentTemplatesImport.pageSize);
  const totalCount = useSelector(selectors.contentTemplatesImport.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.contentTemplatesImport.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.contentTemplatesImport.settingStatus);
  // const settingPending = useSelector(selectors.contentTemplatesImport.settingPending);
  // Filtering
  const filters = useSelector(selectors.contentTemplatesImport.filters);

  return (
    <DxTable
      name="contentTemplates"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useContentTemplatesList}
      columns={columns}
      rowComponent={TemplateRowComponent}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.contentTemplates.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(ContentTemplatesTable);
