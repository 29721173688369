// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import isUndefined from 'lodash/isUndefined';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Data
import { formName } from '../../../../../_helpers/data/pages/faqs/formFields';
// Helpers
import { getDate } from '../../../../../_helpers/getDate';

export function* callGetFaqItemByHashIdWorker({ payload: hashId }) {
  yield put(actions.setIsItemByHashIdLoading(true));

  const response = yield apply(api, api.faq.getFaqsHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { hashId: hashIdItem, ...rest } = response.data;
    const keys = Object.keys(rest);

    if (!isUndefined(rest.createdAt)) {
      rest.createdAt = getDate(rest.createdAt);
    }
    if (!isUndefined(rest.answeredAt)) {
      rest.answeredAt = getDate(rest.answeredAt);
    }

    yield put(initialize(formName, rest, keys));
  }

  yield put(actions.setIsItemByHashIdLoading(false));
}
