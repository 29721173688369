const OrderStatisticsStyle = theme => ({
  btn: {
    marginLeft: 10,
  },
  iconColor: {
    fill: '#fff',
  },
  spacingBottom: {
    flexGrow: 1,
    margin: '0px',
    marginBottom: theme.spacing(1),
  },
  orderModalContent: {
    display: 'flex',
    margin: '0',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
});

export default OrderStatisticsStyle;
