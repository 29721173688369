// Core
import { put, apply } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { api } from '../../../../../config/api';

export function* callGetCategoriesByHashIdWorker({ payload }) {
  const { hashId, setLoading, onSuccess } = payload;
  setLoading(true);
  const { status, data } = yield apply(api, api.productFeedCategories.getItemByHashId, [hashId]);
  if (status >= 200 && status < 400) {
    yield put(actions.mergeCategoriesItems({ [hashId]: data }));
    if (onSuccess) {
      onSuccess();
    }
  }
  setLoading(false);
}
