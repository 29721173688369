import { getIn, List } from 'immutable';
import { stateKeys as priceCreatorCategoriesKeys } from './reducer';
// config
import { pageSizes } from '../../../config/globalConfig';

export const priceCreatorCategories = Object.freeze({
  currentPage: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.currentPage], 0),
  filters: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.filters], List()),
  isLoading: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.pending], false),
  list: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.items], List()),
  pageSize: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.reload], false),
  totalCount: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.totalCount], 0),
  sorting: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.sorting], List()),
  settingStatus: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.settingStatus], false),
  settingPending: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.priceCreatorCategoriesList, priceCreatorCategoriesKeys.settingPending], false),
  isOpenPartnersModal: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.partnersModal, priceCreatorCategoriesKeys.open], false),
  updateCategoriesPending: state => getIn(state, [priceCreatorCategoriesKeys.controller, priceCreatorCategoriesKeys.updateCategories, priceCreatorCategoriesKeys.pending], false),
});
