// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, hashId) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_PARAMETERS_LIST_ASYNC, { params, hashId });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRODUCT_FEED_PARAMETERS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_PARAMETERS_INITIAL_STATE_ASYNC, path);
  },
});
