// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_SERVICE_DESK_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_SERVICE_DESK_CURRENT_PAGE, currentPage);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_SERVICE_DESK_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_SERVICE_DESK_SETTING_PENDING, settingPending);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_SERVICE_DESK_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_SERVICE_DESK_PAGE_SIZE, pageSize);
  },
  setStatusesList(statusesList) {
    return getActionCreator(types.SET_SERVICE_DESK_STATUSES_LIST, statusesList);
  },
  setStatusesListPending(pending) {
    return getActionCreator(types.SET_SERVICE_DESK_STATUSES_LIST_PENDING, pending);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_SERVICE_DESK_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_SERVICE_DESK_INITIAL_STATE, initialState);
  },
  setIsItemByHashIdLoading(isLoading) {
    return getActionCreator(types.SET_SERVICE_DESK_IS_ITEM_BY_HASH_ID_LOADING, isLoading);
  },
  setIsItemByHashIdPutPending(pending) {
    return getActionCreator(types.SET_SERVICE_DESK_IS_ITEM_BY_HASH_PUT_PENDING, pending);
  },
  setIsChatFilePending(pending) {
    return getActionCreator(types.SET_CHAT_FILE_IS_PENDING, pending);
  },
  setFilesInChatMessage(files) {
    return getActionCreator(types.SET_FILES_IN_CHAT_MESSAGE, files);
  },
  setMessagesFromServerAreLoading(isLoading) {
    return getActionCreator(types.SET_SERVICE_DESK_MESSAGES_FROM_SERVER_ARE_PENDING, isLoading);
  },
  setMessages(messages) {
    return getActionCreator(types.SET_CHAT_MESSAGES, messages);
  },
  setMessagesFromServer(messages) {
    return getActionCreator(types.SET_SERVICE_DESK_MESSAGES_FROM_SERVER, messages);
  },
  setCurrentMsg(messages) {
    return getActionCreator(types.SET_CURRENT_MESSAGES, messages);
  },
  setExternalLink(link) {
    return getActionCreator(types.SET_SERVICE_DESK_EXTERNAL_LINK, link);
  },
  setChatName(name) {
    return getActionCreator(types.SET_SERVICE_DESK_CHAT_NAME, name);
  },
  setInfinityScrollState(payload) {
    return getActionCreator(types.SET_SERVICE_DESK_INFINITY_SCROLL_STATE, payload);
  },
  setMessageIsSending(payload) {
    return getActionCreator(types.SET_SERVICE_DESK_MESSAGE_IS_SENDING, payload);
  },
  setChatKey(payload) {
    return getActionCreator(types.SET_SERVICE_DESK_KEY, payload);
  },
  setNewTicketIsSending(payload) {
    return getActionCreator(types.SET_SERVICE_DESK_NEW_TICKET_PENDING, payload);
  },
  setCloseQuestionPending(pending) {
    return getActionCreator(types.SET_SERVICE_DESK_CLOSE_QUESTION_PENDING, pending);
  },
  setContinueQuestionPending(pending) {
    return getActionCreator(types.SET_SERVICE_DESK_CONTINUE_QUESTION_PENDING, pending);
  },
  mergeChat(data) {
    return getActionCreator(types.MERGE_CHAT, data);
  },
});
