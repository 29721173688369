// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callGetFaqsWorker,
  callGetFaqsStatusesListWorker,
  callSetToLocalDataWorker,
  callGetFaqsInitialStateWorker,
  callGetFaqItemByHashIdWorker,
  callPutFaqsItemByHashId,
  callPutFaqSettingsWorker,
  callDeleteFaqByHashIdWorker,
} from './workers';

function* watchGetFaqs() {
  yield takeEvery(asyncTypes.GET_FAQ_LIST_ASYNC, callGetFaqsWorker);
}
function* watchGetFaqsStatusesList() {
  yield takeEvery(asyncTypes.GET_FAQ_STATUSES_LIST_ASYNC, callGetFaqsStatusesListWorker);
}
function* watchGetFaqsInitialState() {
  yield takeEvery(asyncTypes.GET_FAQ_INITIAL_STATE_ASYNC, callGetFaqsInitialStateWorker);
}
function* watchSetFaqsCurrentPage() {
  yield takeEvery(types.SET_FAQ_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPartnersPageSize() {
  yield takeEvery(types.SET_FAQ_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFaqsFilters() {
  yield takeEvery(types.SET_FAQ_FILTERS, callSetToLocalDataWorker);
}
function* watchGetFaqItemByHashId() {
  yield takeEvery(asyncTypes.GET_FAQ_ITEM_BY_HASH_ID_ASYNC, callGetFaqItemByHashIdWorker);
}
function* watchPutFaqItemByHashId() {
  yield takeEvery(asyncTypes.PUT_FAQ_ITEM_BY_HASH_ID_ASYNC, callPutFaqsItemByHashId);
}
function* watchSetFaqSorting() {
  yield takeEvery(types.SET_FAQ_SORTING, callSetToLocalDataWorker);
}
function* watchPutFaqSettings() {
  yield takeEvery(asyncTypes.PUT_FAQ_SETTINGS_ASYNC, callPutFaqSettingsWorker);
}
function* watchDeleteFaqByHashId() {
  yield takeEvery(asyncTypes.DELETE_FAQ_BY_HASH_ID_ASYNC, callDeleteFaqByHashIdWorker);
}

export function* watchersFAQ() {
  yield all([
    watchGetFaqs(),
    watchGetFaqsStatusesList(),
    watchGetFaqsInitialState(),
    watchSetFaqsCurrentPage(),
    watchSetPartnersPageSize(),
    watchSetFaqsFilters(),
    watchGetFaqItemByHashId(),
    watchPutFaqItemByHashId(),
    watchSetFaqSorting(),
    watchPutFaqSettings(),
    watchDeleteFaqByHashId(),
  ]);
}
