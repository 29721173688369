// Core
import React, { memo, useMemo, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
// Parts
// import PenOutlinedIcon from '../../../icons/PenOutlinedIcon';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import DxTable from '../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/serviceDesk/actions';
import { asyncActions } from '../../../../engine/core/serviceDesk/saga/asyncActions';
import { pageLinks } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../hooks/useAccessList';
import { useServiceDeskList } from '../_hooks/useServiceDeskList';

// UI
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';

// Redux
function mapStateToProps(state) {
  return {
    gridSettings: selectors.user.userSettings(state).faqServiceDesc.gridSettings,
    rows: selectors.serviceDesk.list(state),
  };
}

function ServiceDeskTable(props) {
  const { gridSettings, rows } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.serviceDesk.isLoading);
  const hasAccessEdit = useAccessList(accessList.faqServiceDescQuestionEdit);
  const hasAccessShow = useAccessList(accessList.faqServiceDescQuestionShow);
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  // Paging
  const currentPage = useSelector(selectors.serviceDesk.currentPage);
  const pageSize = useSelector(selectors.serviceDesk.pageSize);
  const totalCount = useSelector(selectors.serviceDesk.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = sorting => dispatch(actions.setSorting(sorting));
  const sortingStateColumnExtensions = useSelector(selectors.serviceDesk.sortingStateColumnExtensions);
  const sorting = useSelector(selectors.serviceDesk.sorting);
  // Settings
  const settingStatus = useSelector(selectors.serviceDesk.settingStatus);
  const settingPending = useSelector(selectors.serviceDesk.settingPending);
  // Filtering
  const filters = useSelector(selectors.serviceDesk.filters);

  const EditComponent = (args) => {
    const { row: { hashId, canEdit } } = args;

    const currentIcon = useMemo(() => (
      canEdit && hasAccessEdit
        ? <ModeOutlinedIcon sx={{ p: '2px' }} />
        : <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
    ), [canEdit]);

    const goToHandler = () => dispatch(push(pageLinks.serviceDesk.chat(hashId)));

    return ((canEdit && hasAccessEdit) || (hasAccessShow)) && (
      <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" onClick={goToHandler} size="large">
        {currentIcon}
      </IconButtonStyles>
    );
  };

  useEffect(() => {
    if (!postUsersHashIdPending) {
      dispatch(asyncActions.getStatusesList());
    }
  }, [dispatch, postUsersHashIdPending]);

  return (
    <DxTable
      isLoading={isLoading}
      rows={rows}
      name="faqServiceDesc"
      showShortText
      // Update
      updateHooks={useServiceDeskList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Edit
      editComponent={EditComponent}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions.toJS()}
      // Settings
      gridSettings={gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
      // Filtering
      filters={filters}
      filtersAction={actions.setFilters}
    />
  );
}

ServiceDeskTable.displayName = 'ServiceDeskTable';
ServiceDeskTable.propTypes = {
  gridSettings: PropTypes.array,
  rows: PropTypes.object,

};
ServiceDeskTable.defaultProps = {
};

export default connect(mapStateToProps)(memo(ServiceDeskTable));
