// Core
import React, { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { List } from 'immutable';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { api } from '../../../../../engine/config/api';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../../engine/config/routes';
import { asyncActions } from '../../../../../engine/core/synonyms/saga/asyncActions';
import { actions } from '../../../../../engine/core/synonyms/actions';

function DeleteModal(props) {
  const {
    isDisplay, id, isModalOpen, setIsModalOpen,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selection = useSelector(selectors.synonyms.selection);
  const pageSize = useSelector(selectors.synonyms.pageSize);
  const filters = useSelector(selectors.synonyms.filters);
  const sorting = useSelector(selectors.synonyms.sorting);
  const isAllowedUserDelete = useAccessList(accessList.synonymsDelete);
  const ids = selection.toJS();


  const stopWordsIds = useMemo(() => {
    if (id) {
      return [id];
    }

    return ids;
  }, [ids, id]);

  const stopDeleting = () => setIsModalOpen(false);

  const handleDelete = async () => {
    const response = await api.synonyms.delete({ ids: stopWordsIds });
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(t('Успешно удалено'), t('Успех')));
      setIsModalOpen(false);
      if (id) {
        navigate(pageLinks.synonyms.all);
      } else {
        const params = {
          sorting,
          limit: pageSize,
        };

        filters.reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);

        dispatch(asyncActions.getListAsync(params));
        dispatch(actions.setSelection(List()));
      }
    }
  };

  if (!isAllowedUserDelete || !isDisplay) {
    return null;
  }


  return (
    <Modal
      modalOpen={isModalOpen}
      handleModalToggle={stopDeleting}
      handleModalCancel={stopDeleting}
      handleModalSend={handleDelete}
      title={t('Удалить синонимы?')}
      modalMaxWidth="sm"
      sendText={t('Удалить')}
      cancelText={t('Отменить')}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>{t('Вы действительно хотите удалить синонимы? Это действие необратимо, данные будут потеряны.')} </Box>
    </Modal>

  );
}

DeleteModal.propTypes = {
  isDisplay: PropTypes.bool,
  id: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

DeleteModal.defaultProps = {
  isDisplay: true,
  id: undefined,
};

export default memo(DeleteModal);
