// Core
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
// UI
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
// Helpers
import StatCardStyles from './StatCardStyles';
import StatCardStatusStyles from './StatCardStatusStyles';
import { useStyles } from '../../../hooks/useStyles';

const useStylesStatus = makeStyles(StatCardStatusStyles);

const CardIcon = (props) => {
  const {
    isFillType, color, icon,
  } = props;
  const classes = useStyles(StatCardStyles);
  return (
    <Grid item className={cx({ [classes.iconFloat]: isFillType })}>
      <IconButton
        className={classes.icon}
        style={{ backgroundColor: color }}
        size="small"
        sx={{ p: '0px' }}
      >
        {icon}
      </IconButton>
    </Grid>
  );
};

const StatCard = (props) => {
  const {
    customClasses, status, type, title, icon, color, coloredValue, value,
  } = props;
  const classes = useStyles(StatCardStyles);
  const isFillType = type === 'fill';
  const { t } = useTranslation();
  const statusClasses = useStylesStatus();
  const classNameStatus = cx(
    statusClasses.status,
    {
      [statusClasses.statusActive]: status === 1,
      [statusClasses.statusPassive]: status === 0 || status === 2,
    },
  );

  return (
    <Card className={customClasses} style={isFillType ? { backgroundColor: color } : null}>
      <CardActionArea component="div">
        <CardContent component="div" className={cx(classes.content, statusClasses.statCard)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ gap: '8px' }}
          >
            {(icon && !isFillType) && (
              <CardIcon
                isFillType={isFillType}
                color={color}
                icon={icon}
              />
            )}
            <Grid item>
              <div>
                {value && (
                <Typography
                  variant="h6"
                  className={cx(classes.title, { [classes.lightText]: isFillType })}
                  style={{ color: coloredValue || '#303030' }}
                >{value}
                </Typography>
                )}
                {(typeof status === 'number') && (
                  <Typography
                    variant="subtitle1"
                    className={cx(statusClasses.subtitle1, { [classes.lightText]: isFillType })}
                  >
                    {t('Мой статус')}:
                    <span className={classNameStatus}>
                      {status === 0 && t('Заблокирован')}
                      {status === 1 && t('Активный')}
                      {status === 2 && t('Кредитный лимит')}
                    </span>
                  </Typography>
                )}
                {title && (
                <Typography
                  variant="caption"
                  className={cx(statusClasses.caption, { [classes.lightText]: type === 'fill' })}
                >{title}
                </Typography>
                )}
              </div>
            </Grid>
            {(icon && isFillType) && (
              <CardIcon
                isFillType={isFillType}
                color={color}
                icon={icon}
              />
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

StatCard.propTypes = {
  customClasses: PropTypes.string,
  status: PropTypes.number,
  type: PropTypes.oneOf([
    'fill',
  ]),
  title: PropTypes.string,
  value: PropTypes.node,
  icon: PropTypes.element,
  color: PropTypes.string,
  coloredValue: PropTypes.string,
};

export default StatCard;
