// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';

// Engines
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetMessagesUserTypesWorker() {
  yield put(actions.mergeMessagesUserTypes({
    pending: true,
  }));

  const response = yield apply(api, api.messages.getMessagesUserTypes);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeMessagesUserTypes({
      items: List(response.data),
    }));
  }

  yield put(actions.mergeMessagesUserTypes({
    pending: false,
  }));
}
