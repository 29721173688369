import Button from '@mui/material/Button';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ModalFooter from '../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';
import Modal from '../../../containers/Modals/Modal/Modal';

export function AlertModalDialog({ open, handleConfirmCancel, handleConfirmSuccess }) {
  const { t } = useTranslation();
  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              variant="outlined"
              color="primary"
              sx={{ p: BUTTON_SIZE.outlinedMiddle, marginRight: '15px' }}
              onClick={handleConfirmCancel}
            >{t('Отменить')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmSuccess}
            >
              {t('Закрити сторінку')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalFooter>
  );

  return (
    <>
      <Modal
        title={`${t('Бажаєте закрити сторінку без збереження')}?`}
        modalOpen={open}
        handleModalToggle={handleConfirmCancel}
        hideButtons
        fullWidth
        noPadding
        modalMaxWidth="sm"
        modalFooterComponent={modalFooterComponent}
      >
        <Grid margin={'15px'} container justifyContent="flex-end" flexDirection="column">
          <Typography fontSize={'14px'}>{t('Если вы покинете эту страницу, внесенные данные не сохранятся.')}</Typography>
        </Grid>
      </Modal>
    </>
  );
}
