// Core
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
// UI
import Select from '../../../../components/_Form/Selects/Select/Select';
// Actions
import { actions } from '../../../../../engine/core/feeds/attributeSet/actions';
// Config
import { selectors } from '../../../../../engine/config/selectors';
import { useStyles } from '../../../../hooks/useStyles';

const ProductWithoutManagerQuantitySelectStyle = () => ({
  wrapperSelect: {
    width: '230px',
  },
});

export const defaultFilterTypeValue = 69;
function ProductWithoutManagerQuantitySelect() {
  const { t } = useTranslation();
  const classes = useStyles(ProductWithoutManagerQuantitySelectStyle);
  const typesList = [
    { value: defaultFilterTypeValue, label: t('Все статусы') },
    { value: 1, label: t('Новый') },
    { value: 2, label: t('Модерируется') },
    { value: 8, label: t('Добавлен на сайт') },
    { value: 6, label: t('На доработку') },
    { value: 3, label: t('Обновленный') },
    { value: 5, label: t('Отклоненный') },
  ];
  const dispatch = useDispatch();
  const filters = useSelector(selectors.attributeSet.filters);
  const normalizeFilters = filters.toJS();
  const filterValue = useMemo(
    () => {
      const currentFilter = normalizeFilters.filter(item => item.columnName === 'filterType');
      return currentFilter?.[0]?.value;
    },
    [normalizeFilters],
  );

  useEffect(() => {
    if (filterValue === undefined) {
      dispatch(actions.setFilters(uniqBy([
        { columnName: 'filterType', value: defaultFilterTypeValue },
        ...normalizeFilters,
      ], 'columnName')));
    }
  }, [filterValue]);

  const handleChange = useCallback((value) => {
    dispatch(actions.setFilters(uniqBy([
      { columnName: 'filterType', value: value.value },
      ...normalizeFilters,
    ], 'columnName')));
  }, [dispatch, normalizeFilters]);

  if (!filterValue) {
    return null;
  }
  return (
    <Select
      wrapperClassName={classes.wrapperSelect}
      handleChange={handleChange}
      placeholder=""
      color="secondary"
      defaultValue={typesList[0]}
      label={t('Статус')}
      variant="outlined"
      options={typesList}
      size="normal"
    />
  );
}

export default ProductWithoutManagerQuantitySelect;
