// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function RememberCheckbox(props) {
  const { input, label } = props;

  useEffect(() => {
    if (input.value === '') {
      input.onChange(true);
    }
  }, [input]);

  return (
    <FormControlLabel
      control={<Checkbox {...input} checked={!!input.value} color="primary" />}
      label={label}
    />
  );
}

RememberCheckbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
};

export default RememberCheckbox;
