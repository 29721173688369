// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_MANAGERS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_MANAGERS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PARTNERS_MANAGERS_INITIAL_STATE_ASYNC, path);
  },
  putPartnerManagerAsync(params) {
    return getActionCreator(asyncTypes.PUT_PARTNER_MANAGER_ASYNC, params);
  },
  getPartnersManagersExportAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_MANAGERS_EXPORT, params);
  },
  postUploadPartnersManagersAsync(data) {
    return getActionCreator(asyncTypes.POST_PARTNERS_MANAGERS_UPLOAD_ASYNC, data);
  },
});
