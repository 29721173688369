// Core
import React from 'react';
// Parts
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArchiveIcon from '@mui/icons-material/Archive';
// UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as MaterialLink } from '@mui/material';
import Header from '../components/Header/Header';
import Logo from '../../assets/img/logo_light.svg';
import LogoMobile from '../../assets/img/logo_short.svg';
import ContentWrapper from '../containers/ContentWrapper/ContentWrapper';

function EnterContract() {
  const style = { display: 'inline-flex', flexDirection: 'row' };
  return (
    <>
      <Header
        logoAltText="АЛЛО - личный кабинет"
        logo={Logo}
        LogoMobile={LogoMobile}
        onlyLogo
        isHideLangMenu
      />
      <ContentWrapper>
        <Container maxWidth="lg">
          <Box
            component="div"
            boxShadow={3}
            style={{
              padding: '15px 20px',
              minHeight: '100%',
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom color="primary">
              Сторінка споживача про надання послуг
            </Typography>
            <Typography variant="body1" gutterBottom>
              Договір співробітництва з Компанією АЛЛО укладається шляхом приєднання Учасником, який має намір
              реалізовувати свій товар через інтернет-магазин АЛЛО, на умовах Договору. Учасником може бути як юридична
              особа (крім юридичних осіб державної форми власності), так і фізична особа-підприємець.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              Для приєднання до умов Договору співробітництва Учаснику необхідно:
            </Typography>
            <ul>
              <li>Ознайомитися з умовами Договору</li>
              <li>Зареєструватися на веб-сайті АЛЛО</li>
              <li>Через особистий кабінет оформити, роздрукувати та підписати Заяву про приєднання та інші сформовані документи</li>
              <li>Заяву про приєднання, інші документи та засвідчені належним чином копії документів про Учасника, перелік яких визначено у Договорі, надіслати на адресу Компанії АЛЛО</li>
              <li>Після прийняття (реєстрації) Компанією АЛЛО Заяви про приєднання та виконання Учасником відповідних умов Договору, Учаснику буде надано право розміщувати свої товари на Веб-сайті АЛЛО</li>
            </ul>
            <MaterialLink href="/docs/Договір_співробітництва_в_редакції_19.10.2021.pdf" rel="noreferrer" target="_blank">
              <div style={style}><PictureAsPdfIcon />&nbsp;Договір співробітництва в редакції 19.10.2021
              </div>
            </MaterialLink>
            <br />
            <MaterialLink href="/docs/Архив.7z" rel="noreferrer" target="_blank">
              <div style={style}><ArchiveIcon />&nbsp;Архив</div>
            </MaterialLink>
            <br />
          </Box>
        </Container>
      </ContentWrapper>
    </>
  );
}

export default EnterContract;
