// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
// UI
// Containers
import ConfirmButtons from '../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import Select from '../../../../components/_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/feeds/attributeSet/saga/asyncActions';

function FeedPriorityProvider(props) {
  const {
    feature, hashId, value, valuesPlain, required, color,
  } = props;
  const dispatch = useDispatch();
  const defaultValue = { value: null, label: null, hashId: null };
  const initValue = value || defaultValue;
  const [selectedValue, setSelectedValue] = useState(initValue);
  const options = useSelector(selectors.feeds.prioritiesList);

  const onChangeValueConfirm = (payload) => {
    dispatch(asyncActions.putContentManagerAsync(
      {
        hashId,
        assignedTo: {
          manager: null,
          priority: payload.value,
          label: payload.label,
          key: 'priority',
        },
      },
    ));
  };

  const onReject = () => {
    setSelectedValue(value || defaultValue);
  };

  const input = {
    value: selectedValue,
    onChange: setSelectedValue,
  };

  return (
    <ConfirmButtons
      key={get(value, 'value', 'user-confirm-buttons-key')}
      error={feature.error}
      disabled={feature.pending}
      required={required}
      notEmpty={selectedValue.value !== null}
      onConfirm={onChangeValueConfirm}
      onReject={onReject}
      success={feature.success}
      value={selectedValue}
      valuesPlain={valuesPlain}
      withConfirm
      withId
      color={color}
      initValue={initValue}
    >
      <Select
        input={input}
        isLoading={feature.pending}
        disabled={feature.pending}
        options={options}
        variant="outlined"
      />
    </ConfirmButtons>
  );
}

FeedPriorityProvider.propTypes = {
  feature: PropTypes.shape({
    error: PropTypes.bool,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }),
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  hashId: PropTypes.string,
  color: PropTypes.string,
};

FeedPriorityProvider.defaultProps = {
  valuesPlain: '',
  color: null,
};

export default FeedPriorityProvider;
