import Box from '@mui/material/Box';
import { COLOR } from '../../containers/App/AppStyles';

const Informer = ({ borderColor = COLOR.grey.main, children, sx }) => (
  <Box sx={{
    width: '100%',
    borderRadius: '5px',
    borderLeft: `solid ${borderColor} 4px`,
    boxShadow: '0px 0px 8px 0px #0000001F',
    p: '16px',
    display: 'flex',
    alignItems: 'center',
    ...sx,
  }}
  >
    {children}
  </Box>
);


export default Informer;
