// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';

// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
import { getFileFromBlob } from '../../../../../../_helpers/getFileFromBlob';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callGetBillingReconciliationActDownload({ payload }) {
  yield put(actions.mergeReconciliation(Map({
    downloadPending: true,
  })));

  const response = yield apply(api, api.billing.getReconciliationActDownload, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergeReconciliation(Map({
          modalIsOpen: false,
        })));
        break;
      }

      default: {
        getFileFromBlob('billing_reconciliation.xls', response);
        yield put(actions.mergeReconciliation(Map({
          modalIsOpen: false,
        })));
        break;
      }
    }
  }

  yield put(actions.mergeReconciliation(Map({
    downloadPending: false,
  })));
}
