// Icons
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
// Parts
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { COLOR } from '../../containers/App/AppStyles';

export const NoteTextInfo = styled(Box)`
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid ${COLOR.controls.dark};
  background-color: rgba(82, 133, 204, 0.08);
`;
export const NoteTextWarning = styled(Box)`
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
  border-left: 4px solid ${COLOR.warning}
`;
export const NoteTextContent = styled(Box)`
  margin: 0;
  padding: 0 0 0 16px;
`;

function NotificationNote(props) {
  const { children, type, mt } = props;
  if (type === 'info') {
    return (
      <NoteTextInfo component="div" mt={mt}>
        <SpeakerNotesOutlinedIcon sx={{ color: COLOR.controls.dark }} />
        <NoteTextContent component="p">
          {children}
        </NoteTextContent>
      </NoteTextInfo>
    );
  }

  if (type === 'warning') {
    return (
      <NoteTextWarning component="div" mt={mt}>
        <InfoOutlined sx={{ color: COLOR.warning }} />
        <NoteTextContent component="p">
          {children}
        </NoteTextContent>
      </NoteTextWarning>
    );
  }
}

export default NotificationNote;
