// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ExitIcon(props) {
  return (
    <SvgIcon className="exit-icon" fontSize="inherit" viewBox="0 0 14 16" {...props}>
      <path d="M11.6637 1H2.44739C2.20034 1 2 1.19714 2 1.44025V14.5597C2 14.8029 2.20034 15 2.44739 15H11.6637C11.9108 15 12.1111 14.8029 12.1111 14.5597V13.3243C12.1111 13.0812 11.9108 12.8841 11.6637 12.8841C11.4167 12.8841 11.2163 13.0812 11.2163 13.3243V14.1195H2.89479V1.8805H11.2163V2.66635C11.2163 2.90946 11.4167 3.1066 11.6637 3.1066C11.9108 3.1066 12.1111 2.90946 12.1111 2.66635V1.44025C12.1111 1.19714 11.9108 1 11.6637 1Z" strokeWidth="0.3" />
      <path d="M9.46558 7.84428L14.4434 7.84428M11.6434 5.6665L9.46558 7.84428L11.6434 10.0221" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default ExitIcon;
