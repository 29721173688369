// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
// import { types } from '../types';
// Workers
import {
  // callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPutItemByHashId,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutItemByHashId() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_ITEM_BY_HASH_ID_ASYNC, callPutItemByHashId);
}
// function* watchSetCurrentPage() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_CURRENT_PAGE, callSetToLocalDataWorker);
// }
// function* watchSetPageSize() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_PAGE_SIZE, callSetToLocalDataWorker);
// }
// function* watchSetFilters() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_FILTERS, callSetToLocalDataWorker);
// }
// function* watchSetSorting() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_SORTING, callSetToLocalDataWorker);
// }

export function* watchersProductFeedCategories() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    // watchSetCurrentPage(),
    // watchSetPageSize(),
    // watchSetFilters(),
    // watchSetSorting(),
    watchPutItemByHashId(),
  ]);
}
