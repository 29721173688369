// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const notificationsActionAsync = Object.freeze({
  getNotificationsAsync: args => ({
    type: asyncTypes.GET_NOTIFICATIONS_ASYNC,
    payload: args,
  }),
  putNotificationsAsync: ids => ({
    type: asyncTypes.PUT_NOTIFICATIONS_ASYNC,
    payload: ids,
  }),
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_NOTIFICATIONS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_NOTIFICATIONS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_NOTIFICATIONS_INITIAL_STATE_ASYNC, path);
  },
  getNotificationsByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_NOTIFICATIONS_BY_HASH_ID_ASYNC, hashId);
  },
  getNotificationsGroups(lang) {
    return getActionCreator(asyncTypes.GET_NOTIFICATIONS_GROUPS_ASYNC, lang);
  },
  getNotificationsTypes(lang) {
    return getActionCreator(asyncTypes.GET_NOTIFICATIONS_TYPES_ASYNC, lang);
  },
});
