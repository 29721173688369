// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
// Parts
import CategoryStatusCount from '../CategoryStatusCount/CategoryStatusCount';
import Switcher from '../../../../../../components/_Form/Switcher/Switcher.js';

// Engine
import { localData } from '../../../../../../../engine/config/localData';
import { actions } from '../../../../../../../engine/core/contentProduct/actions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
import { useAccessList } from '../../../../../../hooks/useAccessList';

// Style
import {
  RefreshButton,
  RefreshIconStyled,
  UpdateControlsBlock,
} from './styles';

const TitleComponent = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(undefined);
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const productGridIsLoading = useSelector(selectors.content.productGridLoading);
  const contentProductIsLoading = useSelector(selectors.contentProduct.isLoading);
  const sendQuery = useSelector(selectors.contentProduct.sendQuery);
  const hasProductsCategoriesStatistic = useAccessList(accessList.productsCategoriesStatistic);
  const isLoading = (productGridIsLoading || contentProductIsLoading) && sendQuery !== undefined;
  const handleClick = () => dispatch(actions.setSendQuery(true));

  useEffect(() => {
    localData.getItem(localData.keysObj.contentProductsPageSendQuery)
      .then((value) => {
        setChecked(value);
        dispatch(actions.setSendQuery(value || undefined));
      });
  }, [categoryId]);

  const handleChange = (event) => {
    setChecked(() => {
      const value = event.target.checked;
      localData.setItem(localData.keysObj.contentProductsPageSendQuery, value);
      dispatch(actions.setSendQuery(value));
      return value;
    });
  };

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', rowGap: '10px', flexWrap: 'wrap',
    }}
    >
      <UpdateControlsBlock>
        <Tooltip title={`${t('Автоматическое обновление данных в таблице')} ${checked ? t('включено') : t('выключено')}`}>
          <div>
            <Switcher
              disableRipple
              color="secondary"
              isDisabled={checked === undefined}
              onChange={handleChange}
              isChecked={Boolean(checked)}
              sx={{ marginRight: '8px' }}
            />
          </div>
        </Tooltip>
        <Tooltip
          title={t('Обновить данные таблицы')}
          aria-label={t('Обновить данные таблицы')}
          style={{ lineHeight: 1 }}
        >
          <div>
            <RefreshButton
              onClick={handleClick}
            >
              { isLoading
                ? <CircularProgress color="inherit" size={17} />
                : (
                  <RefreshIconStyled />
                )
            }
            </RefreshButton>
          </div>
        </Tooltip>
      </UpdateControlsBlock>
      {hasProductsCategoriesStatistic && (
        <CategoryStatusCount />
      )}
    </Box>
  );
};

export default TitleComponent;
