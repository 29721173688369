import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CategoriesFeatureTable from './components/CategoriesFeature/CategoriesFeatureTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ActionsButtonGroups from './components/ActionsButtonGroups';
import CategoryFeatureImportModal from './components/CategoryFeatureImportModal';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useCharacteristicsList } from './_hooks/useCharacteristicsList';
// Engine
import { actions } from '../../../../engine/core/categoriesFeatureManagement/actions';
import { asyncActions } from '../../../../engine/core/categoriesFeatureManagement/saga/asyncActions';
import { initialState, stateKeys } from '../../../../engine/core/categoriesFeatureManagement/reducer';
import { selectors } from '../../../../engine/config/selectors';


function CategoriesFeaturePage() {
  useCharacteristicsList();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.categoriesFeatureManagement.settingStatus);
  const settingPending = useSelector(selectors.categoriesFeatureManagement.settingPending);
  const filters = useSelector(selectors.categoriesFeatureManagement.filters);
  const sorting = useSelector(selectors.categoriesFeatureManagement.sorting);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.categoriesFeatureList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.categoriesFeatureList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.categoriesFeatureList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeader title={t('Управление характеристиками категорий')}>
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <ActionsButtonGroups />
        {/* TODO если добавляю disabled={isLoading} pending={isLoading} начинается бесконечная перезагрузка */}
        <ResetFiltersButton onReset={resetFilters} disabled={settingPending || !(filters.size || sorting.size)} />
      </PageHeader>
      <CategoriesFeatureTable />
      <CategoryFeatureImportModal />
    </>
  );
}

export default CategoriesFeaturePage;
