import { COLOR } from '../../../containers/App/AppStyles';

const FormTextAreaStyle = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  textAreaWrapper: {
    flexDirection: 'row',
    display: 'column',
    width: '100%',
  },
  smallMarginTop: {
    marginTop: 8,
  },
  wrapperButton: {
    display: 'flex',
    alignSelf: 'center',
    borderRadius: 3,
    padding: 2,
    boxShadow: COLOR.shadow.block.main,
    marginLeft: 8,
    background: '#ffffff',
  },
  textArea: {
    '@global': {
      div: {
        paddingTop: 9.5,
        paddingBottom: 9.5,
      },
    },
  },
  button: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 3,
    background: COLOR.grey['8'],
    marginLeft: 4,
    '& svg': {
      width: 21,
      height: 21,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      background: COLOR.grey['12'],
    },
  },
  compareButton: {
    color: COLOR.controls.dark,
  },
  compareButtonActive: {
    background: COLOR.controls.dark,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.controls.dark,
      color: '#ffffff',
    },
  },
  checkButton: {
    color: COLOR.success,
  },
  checkButtonActive: {
    background: COLOR.success,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.success,
      color: '#ffffff',
    },
  },
  cancelButton: {
    color: COLOR.error,
  },
  cancelButtonActive: {
    background: COLOR.error,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.error,
      color: '#ffffff',
    },
  },
  hasError: {
    color: COLOR.error,
  },
  label: {
    backgroundColor: '#fff',
  },
});

export default FormTextAreaStyle;
