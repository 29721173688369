// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  id: Object.freeze({
    name: 'id',
    title: <Trans>ID</Trans>,
  }),
  idMagentoBrand: Object.freeze({
    name: 'idMagentoBrand',
    title: <Trans>M-ID бренда</Trans>,
  }),
  name: Object.freeze({
    name: 'name',
    title: <Trans>Название бренда</Trans>,
  }),
});
