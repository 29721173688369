// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import AttributeSetTable from './components/AttributeSetTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ProductWithoutManagerQuantitySelect from './components/ProductWithoutManagerQuantitySelect.js';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useAttributeSetList } from './_hooks/useAttributeSetList';
import { useStyles } from '../../../hooks/useStyles';
// Engine
import { asyncActions } from '../../../../engine/core/feeds/attributeSet/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/feeds/attributeSet/actions';
import { initialState, stateKeys } from '../../../../engine/core/feeds/attributeSet/reducer';

const AttributeSetPageStyle = theme => ({
  pageHeaderWrap: {
    flexDirection: 'column',
  },
  pageHeaderContentWrap: {
    alignSelf: 'flex-start !important',
    paddingLeft: '0 !important',
    marginTop: '25px',
  },
  childWrapper: {
    '& .MuiButton-root': {
      marginBottom: '0',
    },
    '& > div': {
      marginBottom: '0',
    },
  },
  wrapper: {
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
});
function AttributeSetPage() {
  useAttributeSetList();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.attributeSet.isLoading);
  const filters = useSelector(selectors.attributeSet.filters);
  const sorting = useSelector(selectors.attributeSet.sorting);
  const dispatch = useDispatch();
  const classes = useStyles(AttributeSetPageStyle);
  const settingStatus = useSelector(selectors.attributeSet.settingStatus);
  const settingPending = useSelector(selectors.attributeSet.settingPending);
  const isDisabledResetButton = isLoading || settingPending || !((filters.size > 1) || sorting.size);
  const putSettings = () => dispatch(asyncActions.putSettingsAsync());
  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.list).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.list).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.list).get(stateKeys.currentPage)));
  };

  return (
    <>
      <PageHeader
        title={t('Управление атрибут-сетами')}
        titleComponent={<ProductWithoutManagerQuantitySelect />}
        childWrapperClassName={classes.childWrapper}
        wrapperClassName={classes.wrapper}
        pageHeadingClassName={classes.pageHeaderWrap}
        pageHeadingComponentClassName={classes.pageHeaderContentWrap}
      >
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <AttributeSetTable />
    </>
  );
}

export default AttributeSetPage;
