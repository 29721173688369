// Core
import {
  apply, fork, put, select,
} from 'redux-saga/effects';
import get from 'lodash/get';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { userActions } from '../../../../user/action';
import { selectors } from '../../../../../config/selectors';
import { callSetFiltersWorker } from './setFilters';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutSettingsWorker({ payload }) {
  if (!payload) { // If change user settings
    const settingStatus = yield select(selectors.attributeSet.settingStatus);

    if (!settingStatus) {
      yield put(actions.setSettingsStatus(true));
    } else {
      yield put(actions.setSettingsPending(true));
      const userSetting = yield select(selectors.user.userSettings);
      const gridSettings = get(userSetting, 'feedAttributeSetManagement.gridSettings', []);

      const response = yield apply(api, api.user.putUserSettings, [{ feedAttributeSetManagement: { gridSettings } }]);

      if (response && response.status >= 200 && response.status < 400) {
        const data = response.data;
        const { status, statusTitle } = data;

        switch (status) {
          case 'success': {
            const { message } = data;
            yield put(setSuccessMessage(message, statusTitle));
            // https://jira.forforce.com/browse/AMP-1100
            yield fork(callSetFiltersWorker, userSetting);
            yield put(actions.setSettingsStatus(false));

            break;
          }
          case 'error': {
            const { errors } = data;
            yield put(setErrorMessage(errors, statusTitle));
            break;
          }
          default: {
            break;
          }
        }
      }

      yield put(actions.setSettingsPending(false));
    }
  } else { // If have context (reset: _name_)
    yield put(actions.setSettingsPending(true));

    const response = yield apply(api, api.user.putUserSettings, [payload]);

    if (response && response.status >= 200 && response.status < 400) {
      const data = response.data;
      const { status, statusTitle } = data;

      switch (status) {
        case 'success': {
          const { message, settings } = data;
          yield put(userActions.mergeUserSettings(settings));
          yield put(setSuccessMessage(message, statusTitle));
          // https://jira.forforce.com/browse/AMP-1100
          yield fork(callSetFiltersWorker, settings);
          break;
        }
        case 'error': {
          const { errors } = data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }

    yield put(actions.setSettingsPending(false));
  }
}
