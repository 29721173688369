// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Data
import { formFields, formName } from '../../../../../ui/page/Products/LogisticsData/components/OffCanvas/form';

export function* callGetItemByHashIdWorker({ payload }) {
  const { mode, hashId } = payload;
  yield put(actions.setItemByHashIdPendingId(hashId));
  const response = yield apply(api, api.logisticsDataByCategory.getLogisticsItemByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setItemByHashIdData(response.data));
    yield put(actions.setItemByHashIdMode(mode));
    const formData = {
      [formFields.length]: response.data.length?.toString() || '',
      [formFields.width]: response.data.width?.toString() || '',
      [formFields.height]: response.data.height?.toString() || '',
      [formFields.weight]: response.data.weight?.toString() || '',
      [formFields.ean]: response.data.ean,
    };
    yield put(initialize(formName, formData, Object.keys(formData)));
  }

  yield put(actions.setItemByHashIdPendingId(null));
}
