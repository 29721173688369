// Core
import { useEffect, useMemo } from 'react';
import { Map, isImmutable } from 'immutable';
import { change } from 'redux-form';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Field, reduxForm, FieldArray, untouch,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
// Icons
import SendIcon from '@mui/icons-material/Send';
// Parts
import Grid from '@mui/material/Grid';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import FilesUploader from '../../../../components/_Form/FilesUploader/FilesUploader';
import FormTextArea from '../../../../components/_Form/TextArea/FormTextArea';
import MessageButton from './MessageButton';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import Loading from '../../../../components/Loading/Loading';
import MessageDeleteButton from './MessageDeleteButton';
import RecipientsFields from './RecipientsFields/RecipientsFields';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { mediaActionAsync } from '../../../../../engine/core/media/saga/asyncAction';
import { asyncActions } from '../../../../../engine/core/messages/saga/asyncActions';
import { actions } from '../../../../../engine/core/messages/actions';
import SectionHolder from '../../../../containers/SectionHolder/SectionHolder';

// hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { useMessageReadOrEditPage } from '../_hooks/useMessageReadOrEditPage';

// helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { messageFormName, messageFormFields } from './form';

function Message(props) {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { hashId } = useParams();
  const { isReadMessage, isEditMessage } = useMessageReadOrEditPage();
  const isSendSendRequest = useSelector(selectors.messages.messageSendRequestState);
  const isSendSaveRequest = useSelector(selectors.messages.messageSaveRequestState);
  const fileIsLoading = useSelector(selectors.serviceDesk.fileIsLoading);
  const messageDeleteRequestState = useSelector(selectors.messages.messageDeleteRequestState);
  const isMessageByHashIdLoading = useSelector(selectors.messages.isMessageByHashIdLoading);
  const hasAccessMessageSend = useAccessList(accessList.messageSend);
  const hasAccessMessageCreate = useAccessList(accessList.messageCreate);

  const getMessageUsers = (fields) => {
    const dataToServer = map(fields[messageFormFields.messageUsers], data => ({
      type: data.type,
      values: map(data.values, ({ value }) => ({
        value,
      })),
    }));

    return filter(dataToServer, data => data.type && !isEmpty(data.values));
  };

  const getTitle = useMemo(() => {
    if (isEditMessage) {
      return t('Редактирование сообщения');
    }

    if (isReadMessage) {
      return t('Просмотр сообщение');
    }

    if (!hashId) {
      return t('Новое сообщение');
    }

    return '';
  }, [hashId, isReadMessage, isEditMessage, t]);

  const onFormSubmit = (formData, send) => {
    if (isImmutable(formData)) {
      const fields = formData.toJS();
      const messageUsers = getMessageUsers(fields);
      const body = {
        title: fields[messageFormFields.title],
        text: fields[messageFormFields.text],
        ...(hashId ? { hashId } : {}),
        ...(!isEmpty(messageUsers) ? { messageUsers } : {}),
        ...(fields[messageFormFields.media] && fields[messageFormFields.media][0] ? {
          mediaId: fields[messageFormFields.media][0].id,
        } : {}),
      };

      dispatch(asyncActions.postMessageAsync({
        body, send, method: hashId ? 'put' : 'post',
      }));
    }
  };

  const postMediasHandler = (data) => {
    dispatch(mediaActionAsync.postMediasAsync(data));
  };

  useEffect(() => {
    if (hashId) {
      dispatch(asyncActions.getMessageByHashIdAsync(hashId));
    }
  }, [dispatch, hashId, i18n.language]);

  useEffect(() => {
    dispatch(change(messageFormName, messageFormFields.media, null));
    dispatch(untouch(messageFormName, messageFormFields.media));
    return () => {
      dispatch(actions.setMessageByHashId({ message: Map() }));
      dispatch(actions.mergeMessageUserTypesByType({ items: Map() }));
      dispatch(actions.mergeRecipientsImport({ items: Map() }));
    };
  }, [dispatch]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container xs={12}>
        <PageHeader title={getTitle}>
          {(isEditMessage || !hashId) && (
            <>
              <MessageButton
                text={t('Сохранить')}
                isLoading={isSendSaveRequest}
                disabled={messageDeleteRequestState || isSendSendRequest || fileIsLoading}
                hasAccess={hasAccessMessageCreate}
                onClick={handleSubmit(value => onFormSubmit(value, 0))}
              />
              <MessageButton
                text={t('Отправить')}
                isLoading={isSendSendRequest}
                disabled={messageDeleteRequestState || isSendSaveRequest || fileIsLoading}
                hasAccess={hasAccessMessageSend}
                iconButton={<SendIcon />}
                onClick={handleSubmit(value => onFormSubmit(value, 1))}
              />
              <MessageDeleteButton />
            </>
          )}
          <ButtonGoBack defaultPage={pageLinks.messages.all} />
        </PageHeader>
        <SectionHolder>
          {isMessageByHashIdLoading ? <Loading isLoading /> : (
            <>
              <FieldArray name="messageUsers" component={RecipientsFields} />
              <Field
                component={TextField}
                fullWidth
                validation
                required
                margin="normal"
                disabled={isReadMessage}
                name={messageFormFields.title}
                label={t('Заголовок сообщения')}
                validate={[validators.required]}
              />
              {Boolean(hashId) && (
                <Field
                  disabled
                  fullWidth
                  component={TextField}
                  name={messageFormFields.sentAt}
                  label={t('Дата отправления')}
                  margin="normal"
                />
              )}
              <Field
                fullWidth
                multiline
                validation
                rows={10}
                disabled={isReadMessage}
                component={FormTextArea}
                variant="outlined"
                margin="normal"
                name={messageFormFields.text}
                label={t('Текст сообщения')}
                validate={[validators.required]}
              />

              <Field
                fullWidth
                component={FilesUploader}
                name={messageFormFields.media}
                margin="normal"
                disabled={isReadMessage || fileIsLoading}
                formName={messageFormName}
                uploadMedia={postMediasHandler}
                context="message"
                textBefore={t('Прикрепленный файл (pdf, xls, xlsx, zip)')}
              />
            </>
          )}
        </SectionHolder>
      </Grid>
    </Form>
  );
}

Message.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: messageFormName })(Message);
