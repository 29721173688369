// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import ContentProductModal from './ContentProductModal';
// Engine
import { actions } from '../../../../../engine/core/content/actions';
import { selectors } from '../../../../../engine/config/selectors';

function ContentProductEditResultModal() {
  const dispatch = useDispatch();
  const productEditResultModal = useSelector(selectors.allProductsTable.productEditResultModal);
  const isOpen = productEditResultModal.get('isOpen');
  const successCounter = productEditResultModal.get('successCounter');
  const notSuccessCounter = productEditResultModal.get('notSuccessCounter');
  const notSuccessIds = productEditResultModal.get('notSuccessIds');

  if (!isOpen) {
    return null;
  }

  const toggleModal = () => {
    dispatch(actions.setIsProductEditResultModalOpen(!isOpen));
  };

  return (
    <ContentProductModal
      modalOpen={isOpen}
      handleModalToggle={toggleModal}
      successCounter={successCounter}
      notSuccessCounter={notSuccessCounter}
      notSuccessIds={notSuccessIds}
    />
  );
}

export default ContentProductEditResultModal;
