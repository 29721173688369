import { memo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function ColumnItem(props) {
  const {
    name, changeColumnVisible, visible, title,
  } = props;

  const onChange = (event) => {
    changeColumnVisible(name, event);
  };

  return (
    <FormControlLabel
      sx={{
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
        margin: '2px',
        paddingRight: '6px',
        fontSize: '14px',
      }}
      control={(
        <Checkbox
          color="secondary"
          onChange={onChange}
          sx={{
            padding: '3px',
            margin: '3px',
          }}
          value={name}
          checked={visible}
        />
      )}
      label={title}
    />
  );
}

export default memo(ColumnItem);
