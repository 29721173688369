// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  createdAt: Object.freeze({
    name: 'createdAt',
    title: <Trans>Дата создания</Trans>,
  }),
  answer: Object.freeze({
    name: 'answer',
    title: <Trans>Ответ</Trans>,
  }),
  id: Object.freeze({
    name: 'id',
    title: <Trans>ID вопроса</Trans>,
  }),
  idMagentoFaq: Object.freeze({
    name: 'idMagentoFaq',
    title: <Trans>M-ID вопроса</Trans>,
  }),
  idMagentoOrder: Object.freeze({
    name: 'idMagentoOrder',
    title: <Trans>M-ID Заказа</Trans>,
  }),
  question: Object.freeze({
    name: 'question',
    title: <Trans>Вопрос</Trans>,
  }),
  sku: Object.freeze({
    name: 'sku',
    title: <Trans>SKU</Trans>,
  }),
  status: Object.freeze({
    name: 'status',
    title: <Trans>Статус</Trans>,
  }),
  partner: Object.freeze({
    name: 'partner',
    title: <Trans>Партнер</Trans>,
  }),
  type: Object.freeze({
    name: 'type',
    title: <Trans>Тип вопроса</Trans>,
  }),
  answeredAt: Object.freeze({
    name: 'answeredAt',
    title: <Trans>Дата ответа</Trans>,
  }),
  responderName: Object.freeze({
    name: 'responderName',
    title: <Trans>Ответчик</Trans>,
  }),
});
