// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function InfoOutlinedIcon(props) {
  return (
    <SvgIcon className="info-outlined-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M12 3C7.0374 3 3 7.03746 3 12.0001C3 16.9627 7.0374 21 12 21C16.9626 21 21 16.9627 21 12.0001C21 7.03746 16.9626 3 12 3ZM12 19.3636C7.93964 19.3636 4.63636 16.0604 4.63636 12.0001C4.63636 7.93975 7.93964 4.63636 12 4.63636C16.0604 4.63636 19.3636 7.93975 19.3636 12.0001C19.3636 16.0604 16.0603 19.3636 12 19.3636Z" strokeWidth="0.2" />
      <path d="M12 7C11.4486 7 11 7.44889 11 8.00065C11 8.55191 11.4486 9.0004 12 9.0004C12.5514 9.0004 13 8.55191 13 8.00065C13 7.44889 12.5514 7 12 7Z" strokeWidth="0.3" />
      <path d="M12 11C11.4477 11 11 11.3358 11 11.75V16.25C11 16.6642 11.4477 17 12 17C12.5523 17 13 16.6642 13 16.25V11.75C13 11.3358 12.5523 11 12 11Z" strokeWidth="0.3" />
    </SvgIcon>
  );
}

export default InfoOutlinedIcon;
