// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/analytics/actions';
// Hooks
import { useAnalyticsList } from '../_hooks/useAnalyticsList';
import { asyncActions } from '../../../../engine/core/analytics/saga/asyncActions';


function AnalyticsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.analytics.isLoading);
  // Rows
  const rows = useSelector(selectors.analytics.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.analytics.currentPage);
  const pageSize = useSelector(selectors.analytics.pageSize);
  const totalCount = useSelector(selectors.analytics.totalCount);
  const selection = useSelector(selectors.analytics.selection);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.analytics.sorting);
  // Settings
  const settingStatus = useSelector(selectors.analytics.settingStatus);
  const settingPending = useSelector(selectors.analytics.settingPending);
  // Filtering
  const filters = useSelector(selectors.analytics.filters);

  const onSelection = selected => dispatch(actions.setSelection(selected));

  return (
    <DxTable
      name="analytics"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows.toJS()}
      updateHooks={useAnalyticsList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting.toJS()}
      // Filters
      filters={filters.toJS()}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.partner_analytics.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
      selection={selection}
      onSelection={onSelection}
    />
  );
}

export default memo(AnalyticsTable);
