// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
// Engine
import { localData } from '../../../../../config/localData';
import { selectors } from '../../../../../config/selectors';
import { types } from '../../types';
import { actions } from '../../actions';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const currentFilters = yield select(selectors.categoriesFeatureLogs.filters);
  const currentSorting = yield select(selectors.categoriesFeatureLogs.sorting);
  const data = {
    currentPage: yield select(selectors.categoriesFeatureLogs.currentPage),
    filters: currentFilters.toJS(),
    pageSize: yield select(selectors.categoriesFeatureLogs.pageSize),
    totalCount: yield select(selectors.categoriesFeatureLogs.totalCount),
    sorting: currentSorting.toJS(),
  };

  let currentData;

  switch (type) {
    case types.SET_CATEGORIES_FEATURE_LOGS_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_CATEGORIES_FEATURE_LOGS_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_CATEGORIES_FEATURE_LOGS_FILTERS: {
      const currentPage = data.currentPage;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.SET_CATEGORIES_FEATURE_LOGS_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.categoriesFeatureLogs.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
