// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import RenderCheckbox from './RenderCheckbox';
import HelperText from './HelperText';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';

function RenderFormFieldFormMultipleSelect(props) {
  const {
    disabled, input, options,
    label, massEdit, meta,
    isRecommended, required, featureDisabled,
    refresh, onRefresh, productByHashId,
    setTouchField, changeFieldValue,
  } = props;

  const touched = meta.touched;
  const error = !!(touched && meta.error);
  const isDisabled = disabled || featureDisabled?.disabled;
  const name = input.name.toString();
  const oldValue = (productByHashId.get(name) || []).map(item => get(item, 'value', item));
  const wasInitData = productByHashId.get(name) !== null && productByHashId.get(name) !== undefined;
  const normalizedValue = (input.value && input.value.toJS ? input.value.toJS() : (input.value || [])).map(item => get(item, 'value', item));
  const isValueChanged = !isEqual(normalizedValue, oldValue);
  const checkboxName = getCheckboxName(name);
  const classes = useStyles(ContentProductEditStyles);
  const [willBeClear, setWillBeClear] = useState(false);

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [checkboxName, isValueChanged]);

  const classNames = cx(classes.formControl, {
    [classes.errorFormControl]: error,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(input.value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;

  return (
    <FormControl error={error} onClick={onClick} className={classNames} fullWidth margin="normal">
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <div className={classes.relativeWrapper}>
            <FieldWithClearButton
              refresh={refresh}
              onRefresh={onRefresh}
              formName={formName}
              {...input}
              name={input.name.toString()}
              disabled={isDisabled}
            >
              <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
                <Select
                  isPortal={false}
                  label={label}
                  isMulti
                  isClearable
                  disabled={isDisabled}
                  input={input}
                  options={options}
                  isRecommended={isRecommended}
                  required={required}
                  isChanged={isValueChanged && wasInitData}
                  placeholder=""
                  fullWidth
                  closeMenuOnSelect={false}
                  defaultValue={normalizedValue}
                  color="secondary"
                  variant="outlined"
                  size="normal"
                />
              </FieldWithFeatureDisabled>
            </FieldWithClearButton>
            <HelperText
              error={meta?.error}
              isError={error}
              isValueChanged={isValueChanged && wasInitData}
              isValueClear={willBeClear}
              touched={touched}
            />
          </div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderFormFieldFormMultipleSelect;
