// Core
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import red from '@mui/material/colors/red';
import DxTable from '../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { notificationsActions } from '../../../../engine/core/notifications/action';
// Hooks
import { useNotificationsList } from '../_hooks/useNotificationsList';
import { notificationsActionAsync } from '../../../../engine/core/notifications/saga/asyncAction';
import { NotificationsTableEditCell } from './NotificationsTableEditCell';
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';
import { useEditCellWidth } from '../../../hooks/useEditCellWidth';
import TableFilterRowComponent from './TableFilterRowComponent';

function NotificationsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.notification.isLoading);
  // Access
  const hasAccessShow = useAccessList(accessList.notificationCenterMessage);
  const hasAccessCategoryList = useAccessList(accessList.notificationCenterAction);
  const editWidth = useEditCellWidth([hasAccessShow, hasAccessCategoryList], 0);
  // Rows
  const rows = useSelector(selectors.notification.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.notification.currentPage);
  const pageSize = useSelector(selectors.notification.pageSize);
  const totalCount = useSelector(selectors.notification.totalCount);
  const onPageSize = size => dispatch(notificationsActions.setPageSize(size));
  const onCurrentPage = page => dispatch(notificationsActions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(notificationsActions.setSorting(currentSorting));
  const sorting = useSelector(selectors.notification.sorting);
  // Settings
  const settingStatus = useSelector(selectors.notification.settingStatus);
  const settingPending = useSelector(selectors.notification.settingPending);
  // Filtering
  const filters = useSelector(selectors.notification.filters);
  const selections = useSelector(selectors.notification.selection);
  const onSelection = (selected) => {
    dispatch(notificationsActions.setSelection(selected));
  };
  // Parts
  const RowComponent = useCallback(rowProps => <VirtualTable.Row bgcolor={rowProps.row.read ? '#fff' : red[100]} {...rowProps} />, []);

  return (
    <DxTable
      name="notifications"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rowFilterComponent={TableFilterRowComponent}
      rows={rows}
      updateHooks={useNotificationsList}
      // Row
      rowComponent={RowComponent}
      // Editing
      editComponent={NotificationsTableEditCell}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={notificationsActions.setFilters}
      // Settings
      gridSettings={gridSettings.notifications.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={notificationsActionAsync.putSettingsAsync}
      // Selection
      selection={selections}
      selectionOffFlag="canRead"
      onSelection={onSelection}
    />
  );
}

export default NotificationsTable;
