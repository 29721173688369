// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetItemByHashIdWorker,
  callPutItemByHashIdWorker,
  callGetTypesWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_CONFIGURATIONS_LIST_ASYNC, callGetListWorker);
}
function* watchGetTypesList() {
  yield takeEvery(asyncTypes.GET_CONFIGURATIONS_TYPES_LIST_ASYNC, callGetTypesWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_CONFIGURATIONS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CONFIGURATIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetItemByHashId() {
  yield takeEvery(asyncTypes.GET_CONFIGURATIONS_ITEM_BY_HASH_ID_ASYNC, callGetItemByHashIdWorker);
}
function* watchPutPending() {
  yield takeEvery(asyncTypes.PUT_CONFIGURATIONS_ITEM_BY_HASH_ID_ASYNC, callPutItemByHashIdWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CONFIGURATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CONFIGURATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CONFIGURATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CONFIGURATIONS_SORTING, callSetToLocalDataWorker);
}

export function* watchersConfigurations() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchPutPending(),
    watchGetTypesList(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetItemByHashId(),
  ]);
}
