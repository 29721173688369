// Core
import { useSelector } from 'react-redux';
// Engine
import { useMemo } from 'react';
import { selectors } from '../../engine/config/selectors';

const useCheckUserStatus = (arrayOfStatuses) => {
  const userStatus = useSelector(selectors.user.partnerStatus);
  return useMemo(() => arrayOfStatuses.includes(userStatus), [userStatus, arrayOfStatuses]);
};

export default useCheckUserStatus;
