// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { push } from 'redux-first-history';
import { api } from '../../../../../config/api';
import { asyncActions } from '../asyncActions';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../../config/routes';
import { stopListBrandsCategories } from '../../selectors';

export function* callDeleteWorker({ payload: { stopBrandsCategoriesIds, modal, isEdit } }) {
  yield put(actions.mergeDelete({ pending: true }));
  const response = yield apply(api, api.stopLists.deleteBrandsCategories, [{ stopBrandsCategoriesIds }]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setSelection([]));

        if (isEdit) {
          yield put(push(pageLinks.stopBrandsCategories.all));
        } else {
          const pageSize = yield select(stopListBrandsCategories.pageSize);
          const sorting = (yield select(stopListBrandsCategories.sorting)).toJS();
          const filters = (yield select(stopListBrandsCategories.filters)).toJS();

          const params = {
            sorting,
            limit: pageSize,
          };

          filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, params);

          yield put(asyncActions.getListAsync(params));
        }

        if (modal) {
          yield put(actions.mergeEdit({ isModalOpen: false }));
        }
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeDelete({ pending: false }));
}
