// Core
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
// Parts
import FormHelperText from '@mui/material/FormHelperText';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/categories/saga/asyncActions';
import SelectCategoryTreeView from './components/SelectCategoryTreeView';

function SelectCategoryTreeViewForm(props) {
  const {
    classes, input, meta: { touched }, recommendedCategoriesCount, label,
    disabled, formName, handleChange, onlyChild, isMulti, variant, required,
    closeMenuOnScroll, isTranslationNeeded,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categoriesList = useSelector(selectors.categories.categoriesListContext);

  const translateCategories = useMemo(() => {
    const categoriesArray = categoriesList.toJS();
    return categoriesArray.map(category => ({
      ...category,
      label: `${category.idMagentoCategory} | ${category.nameUa ?? category.name}`,
    }));
  }, [categoriesList]);

  const options = isTranslationNeeded ? translateCategories : categoriesList;

  const inputValue = input.value && input.value.toJS ? input.value.toJS() : (input.value || []);
  const normalizeInputValue = Array.isArray(inputValue) ? inputValue : [inputValue];
  const inputSize = normalizeInputValue && normalizeInputValue.length;

  const labelClassName = cx({
    [classes?.labelNoValue]: !inputSize,
    [classes?.categoriesLabelOutlined]: variant === 'outlined',
  });

  const onChange = (event) => {
    input.onChange(event);
    if (handleChange) {
      handleChange(event);
    }
  };

  useEffect(() => {
    if (!categoriesList) {
      dispatch(asyncActions.getCategoriesListContextAsync({ loadRootCategories: true, parentValue: 0 }));
    }
  }, [categoriesList]);

  return (
    <>
      <SelectCategoryTreeView
        formName={formName}
        disabled={disabled}
        categoriesList={options}
        label={label}
        filters={{ value: normalizeInputValue }}
        onSelectChange={onChange}
        labelClassName={labelClassName}
        onlyChild={onlyChild}
        isMulti={isMulti}
        variant={variant}
        isTreeViewForm
        required={required}
        closeMenuOnScroll={closeMenuOnScroll}
        {...props}
      />
      {recommendedCategoriesCount && touched && (inputSize >= 0) && (inputSize < recommendedCategoriesCount) && (
        <FormHelperText className={classes.warningText}>
          {`${t('Рекомендуем выбрать до')} ${recommendedCategoriesCount} ${t('ваших основных категорий, после успешного старта обязательно расширим ассортимент')}`}
        </FormHelperText>
      )}
    </>
  );
}

SelectCategoryTreeViewForm.propTypes = {
  classes: PropTypes.object,
  formName: PropTypes.string,
  variant: PropTypes.string,
  onlyChild: PropTypes.bool,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
  }),
  recommendedCategoriesCount: PropTypes.number,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  isTranslationNeeded: PropTypes.bool,
};

export default memo(SelectCategoryTreeViewForm);
