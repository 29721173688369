// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  configurationsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  types: Map({
    pending: false,
    items: List(),
  }),
  itemById: Map({
    item: Map(),
    pending: true,
    putPending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'configurations',
  types: 'types',
  itemById: 'itemById',
  item: 'item',
  configurationsList: 'configurationsList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  putPending: 'putPending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
});

export const configurationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.MERGE_CONFIGURATIONS_ITEM_BY_HASH_ID: {
      return state.mergeIn([stateKeys.itemById], Map(payload));
    }
    case types.MERGE_CONFIGURATIONS_TYPES: {
      return state.mergeIn([stateKeys.types], Map(payload));
    }
    case types.SET_CONFIGURATIONS_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.configurationsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.configurationsList, stateKeys.pending], pending)
        .setIn([stateKeys.configurationsList, stateKeys.items], List(items))
        .setIn([stateKeys.configurationsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_CONFIGURATIONS_SORTING: {
      return state.setIn([stateKeys.configurationsList, stateKeys.sorting], List(payload));
    }
    case types.SET_CONFIGURATIONS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.configurationsList, stateKeys.filters], List(filters));
    }
    case types.SET_CONFIGURATIONS_PAGE_SIZE: {
      return state.setIn([stateKeys.configurationsList, stateKeys.pageSize], payload);
    }
    case types.SET_CONFIGURATIONS_CURRENT_PAGE: {
      return state.setIn([stateKeys.configurationsList, stateKeys.currentPage], payload);
    }
    case types.SET_CONFIGURATIONS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.configurationsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.configurationsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.configurationsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.configurationsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.configurationsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.configurationsList, stateKeys.reload], reload)
        .setIn([stateKeys.configurationsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_CONFIGURATIONS_SETTING_STATUS: {
      return state.setIn([stateKeys.configurationsList, stateKeys.settingStatus], payload);
    }
    case types.SET_CONFIGURATIONS_SETTING_PENDING: {
      return state.setIn([stateKeys.configurationsList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
