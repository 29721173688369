import React, { useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import FormSelect from '../../../../../../../components/_Form/Selects/FormSelect/FormSelect';

function FormFieldFormSelect({ input, defaultValue, ...rest }) {
  const hasError = useMemo(() => rest.meta.touched && typeof rest.meta.error !== 'undefined', [
    rest.meta.error,
    rest.meta.touched,
  ]);

  return (
    <FormControl sx={{ alignSelf: 'end' }} error={hasError}>
      <FormSelect
        {...rest}
        {...input}
        variant="standard"
        label={rest.label}
        focusOn={!input.value ? '12:00' : 'selected'}
      />
    </FormControl>
  );
}

export default FormFieldFormSelect;
