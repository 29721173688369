// Core
import { getIn, Map } from 'immutable';

// selectors
import { mail as mailSelectors } from '../core/mail/selectors';
import { billing as billingSelectors } from '../core/billing/selectors';
import { transactionHistory as transactionHistorySelectors } from '../core/billing/history/selectors';
import { billingPartnersStatus as billingPartnersStatusSelectors } from '../core/billing/partnersStatus/selectors';
import { billingRatingRatio as billingRatingRatioSelectors } from '../core/billingRatingRatio/selectors';
import { reports as reportsSelectors } from '../core/billing/reports/selectors';
import { notification as notificationSelectors } from '../core/notifications/selectors';
import { brands as brandsSelectors } from '../core/brands/selectors';
import { stopListBrandsCategories as stopListBrandsCategoriesSelectors } from '../core/stopList/BrandsCategories/selectors';
import { categories as categoriesSelectors } from '../core/categories/selectors';
import { categoriesFeatureManagement as categoriesFeatureManagementSelectors } from '../core/categoriesFeatureManagement/selectors';
import { contentProduct as contentProductSelectors } from '../core/contentProduct/selectors';
import { contentTemplatesImport as contentTemplatesImportSelectors } from '../core/contentTemplates/selectors';
import { magentoRequests as magentoRequestsSelectors } from '../core/magentoRequests/selectors';
import { configurations as configurationsSelectors } from '../core/configurations/selectors';
import { faqs as faqsSelectors } from '../core/faq/selectors';
import { serviceDesk as serviceDeskSelectors } from '../core/serviceDesk/selectors';
import { feeds as feedsSelectors } from '../core/feeds/all/selectors';
import { feedsMatchingCategories as feedsMatchingCategoriesSelectors } from '../core/feeds/matchingCategories/selectors';
import { attributeSet as attributeSetSelectors } from '../core/feeds/attributeSet/selectors';
import { attributeSets as attributeSetsSelectors } from '../core/attributeSets/selectors';
import { feedsProducts as feedsProductsSelectors } from '../core/feeds/products/selectors';
import { productFeedParameters as productFeedParametersSelectors } from '../core/products/productFeedParameters/selectors';
import { productFeedCategories as productFeedCategoriesSelectors } from '../core/products/productFeedCategories/selectors';
import {
  partners as partnersSelectors, partner as partnerSelectors,
  partnerStatusesFromFlow,
  allProductsImmutableSelectionSku,
  partnerEditMode, partnerPaymentTypesModal,
  paymentTypes, partnerStatus,
  productStatusesFlow,
} from '../core/partners/selectors';
import { partnersAccess } from '../core/partners/accesses/selectors';
import { partnersManagers as partnersManagersSelectors } from '../core/partners/managers/selectors';
import { emailTemplates as emailTemplatesSelectors } from '../core/emailTemplates/selectors';
import { messages as messagesSelectors } from '../core/messages/selectors';
import { media as mediaSelectors } from '../core/media/selectors';
import { user as userSelectors, userSettings } from '../core/user/selectors';
import { users as usersSelectors } from '../core/users/selectors';
import { statistics as statisticsSelectors } from '../core/statistics/selectors';
import { transactionsPayment as transactionsPaymentSelectors } from '../core/transactionsPayment/selectors';
import { orders as ordersSelectors } from '../core/orders/selectors';
import { systemMotivationResults as systemMotivationResultsSelectors } from '../core/systemMotivation/results/selectors';
import { content as contentSelectors, allProductsTable as allProductsTableSelectors, fullProductGrid as allProductsFullProductGrid } from '../core/content/selectors';
import { password as passwordSelectors } from '../core/password/selectors';
import { priceList as priceListSelectors } from '../core/priceCreator/selectors';
import { priceCreatorFiles as priceCreatorFilesSelectors } from '../core/priceCreator/priceCreatorFiles/selectors';
import { priceCreatorCategories as priceCreatorCategoriesSelectors } from '../core/priceCreator/priceCreatorCategories/selectors';
import { ui as uiSelectors } from '../core/ui/selectors';
import { commissions as commissionsSelectors } from '../core/commissions/selectors';
import { stopWords as stopWordsSelectors } from '../core/stopList/stopWords/selectors';
import { feedsProductsStatistics as feedsProductsStatisticsSelectors } from '../core/feeds/productsStatistics/selectors';
import { productFeedCategoriesAttributes as productFeedCategoriesAttributesSelectors } from '../core/products/productFeedCategoriesAttributes/selectors';
import { productFeedLogs as productFeedLogsSelectors } from '../core/products/productFeedLogs/selectors';
import { partnerStatusesHistory as partnerStatusesHistorySelectors } from '../core/partners/statusesHistory/selectors';
import { contentProductStatusLogs as contentProductStatusLogsSelectors } from '../core/contentProductStatusLogs/selectors';
import { categoryStatusesHistory as categoryStatusesHistorySelectors } from '../core/categories/statusesHistory/selectors';
import { categoriesFeaturesItem as categoriesFeaturesItemSelectors } from '../core/categoriesFeatureManagement/categoriesFeaturesItem/selectors';
import { categoriesFeatureLogs as categoriesFeatureLogsSelectors } from '../core/categoriesFeatureManagement/categoriesFeatureLogs/selectors';
import { synonyms as synonymsSelectors } from '../core/synonyms/selectors';
import { partnersServiceRequests as partnersServiceRequestsSelector } from '../core/partners/partnerServiceRequests/selector';
import { analytics } from '../core/analytics/selectors';
import { orderHistory } from '../core/orderHistory/selectors';
import { logisticsDataByCategory } from '../core/logisticsDataByCategory/selectors';
import { bankInstallments } from '../core/bankInstallments/selectors';
import { productGroups } from '../core/productGroups/selectors';


const mainKeys = Object.freeze({
  form: {
    controller: 'form',
    values: 'values',
    initial: 'initial',
    syncErrors: 'syncErrors',
    submitFailed: 'submitFailed',
    registeredFields: 'registeredFields',
  },
  location: {
    controller: 'location',
    pathname: 'pathname',
  },
  router: {
    controller: 'router',
    key: 'key',
    query: 'query',
    pathname: 'pathname',
    location: 'location',
    search: 'search',
    hash: 'hash',
  },
});

const form = Object.freeze({
  getFormValues: (state, formName) => getIn(state, [mainKeys.form.controller, formName, mainKeys.form.values], Map()),
  getFormInitialValues: (state, formName) => getIn(state, [mainKeys.form.controller, formName, mainKeys.form.initial], Map()),
  getSyncErrors: (state, formName) => getIn(state, [mainKeys.form.controller, formName, mainKeys.form.syncErrors], Map()),
  submitFailed: (state, formName) => getIn(state, [mainKeys.form.controller, formName, mainKeys.form.submitFailed], undefined),
  registeredFields: (state, formName) => getIn(state, [mainKeys.form.controller, formName, mainKeys.form.registeredFields], Map()),
});

const location = Object.freeze({
  location: state => getIn(state, [mainKeys.location.controller], []),
  prevLocation: (state) => {
    const locationState = getIn(state, [mainKeys.location.controller], []);
    if (locationState && locationState.length > 1) {
      const prevLocation = locationState[locationState.length - 2];
      return getIn(prevLocation, [mainKeys.location.controller, mainKeys.location.pathname], '');
    }
  },
});

const router = Object.freeze({
  key: state => getIn(state, [mainKeys.router.controller, mainKeys.router.location, mainKeys.router.key], ''),
  pathname: state => getIn(state, [mainKeys.router.controller, mainKeys.router.location, mainKeys.router.pathname], ''),
  query: state => getIn(state, [mainKeys.router.controller, mainKeys.router.location, mainKeys.router.query], {}),
  search: state => getIn(state, [mainKeys.router.controller, mainKeys.router.location, mainKeys.router.search], ''),
  hash: state => getIn(state, [mainKeys.router.controller, mainKeys.router.location, mainKeys.router.hash], ''),
});

export const userSettingsColumnsOrderItemSelector = userSettings;
export const partnerStatusSelector = partnerStatus;
export const partnerStatusesFromFlowSelector = partnerStatusesFromFlow;
export const partnersPaymentTypes = paymentTypes;
export const allProductsImmutableSelectionSkuSelector = allProductsImmutableSelectionSku;
export const productStatusesFlowSelector = productStatusesFlow;
export const partnerEditModeSelector = partnerEditMode;
export const partnerPaymentTypesPopUps = partnerPaymentTypesModal;
export const allProductsProductGrid = allProductsFullProductGrid;

export const partnersServiceRequests = {
  ...partnersServiceRequestsSelector,
};

export const partnerStatusesHistory = {
  ...partnerStatusesHistorySelectors,
};
export const allProductsTable = {
  ...allProductsTableSelectors,
};
export const mail = {
  ...mailSelectors,
};
export const brands = {
  ...brandsSelectors,
};
export const categories = {
  ...categoriesSelectors,
};
export const categoriesFeatureManagement = {
  ...categoriesFeatureManagementSelectors,
};
export const configurations = {
  ...configurationsSelectors,
};
export const content = {
  ...contentSelectors,
};
export const contentProduct = {
  ...contentProductSelectors,
};
export const contentTemplatesImport = {
  ...contentTemplatesImportSelectors,
};
export const faqs = {
  ...faqsSelectors,
};
export const serviceDesk = {
  ...serviceDeskSelectors,
};
export const feeds = {
  ...feedsSelectors,
};
export const systemMotivationResults = {
  ...systemMotivationResultsSelectors,
};
export const transactionsPayment = {
  ...transactionsPaymentSelectors,
};

export const feedsMatchingCategories = {
  ...feedsMatchingCategoriesSelectors,
};
export const feedsProducts = {
  ...feedsProductsSelectors,

};
export const attributeSet = {
  ...attributeSetSelectors,
};
export const attributeSets = {
  ...attributeSetsSelectors,
};
export const orders = {
  ...ordersSelectors,
};
export const priceList = {
  ...priceListSelectors,
};
export const priceCreatorFiles = {
  ...priceCreatorFilesSelectors,
};
export const priceCreatorCategories = {
  ...priceCreatorCategoriesSelectors,
};
export const partner = {
  ...partnerSelectors,
};
export const partnersManagers = {
  ...partnersManagersSelectors,
};
export const partners = {
  ...partnersSelectors,
};
export const emailTemplates = {
  ...emailTemplatesSelectors,

};
export const messages = {
  ...messagesSelectors,
};
export const media = {
  ...mediaSelectors,
};
export const user = {
  ...userSelectors,
};
export const users = {
  ...usersSelectors,
};
export const notification = {
  ...notificationSelectors,
};
export const statistics = {
  ...statisticsSelectors,
};
export const stopListBrandsCategories = {
  ...stopListBrandsCategoriesSelectors,
};
export const billing = {
  ...billingSelectors,
};
export const transactionHistory = {
  ...transactionHistorySelectors,
};
export const billingPartnersStatus = {
  ...billingPartnersStatusSelectors,
};
export const billingRatingRatio = {
  ...billingRatingRatioSelectors,
};
export const magentoRequests = {
  ...magentoRequestsSelectors,
};
export const password = {
  ...passwordSelectors,
};
export const ui = {
  ...uiSelectors,
};
export const reports = {
  ...reportsSelectors,
};
export const commissions = {
  ...commissionsSelectors,
};
export const stopWords = {
  ...stopWordsSelectors,
};
export const feedsProductsStatistics = {
  ...feedsProductsStatisticsSelectors,
};
export const productFeedParameters = {
  ...productFeedParametersSelectors,
};
export const productFeedCategories = {
  ...productFeedCategoriesSelectors,
};
export const productFeedCategoriesAttributes = {
  ...productFeedCategoriesAttributesSelectors,
};
export const productFeedLogs = {
  ...productFeedLogsSelectors,
};
export const contentProductStatusLogs = {
  ...contentProductStatusLogsSelectors,
};

export const categoryStatusesHistory = {
  ...categoryStatusesHistorySelectors,
};

export const categoriesFeaturesItem = {
  ...categoriesFeaturesItemSelectors,
};
export const categoriesFeatureLogs = {
  ...categoriesFeatureLogsSelectors,
};

export const synonyms = {
  ...synonymsSelectors,
};


export const selectors = Object.freeze({
  allProductsTable,
  analytics,
  attributeSet,
  attributeSets,
  billing,
  billingPartnersStatus,
  billingRatingRatio,
  allProductsProductGrid,
  allProductsImmutableSelectionSku,
  brands,
  categories,
  categoriesFeatureLogs,
  categoriesFeatureManagement,
  categoriesFeaturesItem,
  categoryStatusesHistory,
  commissions,
  configurations,
  content,
  contentProduct,
  contentProductStatusLogs,
  contentTemplatesImport,
  emailTemplates,
  faqs,
  feeds,
  partnerStatusesFromFlow,
  productStatusesFlow,
  feedsMatchingCategories,
  feedsProducts,
  feedsProductsStatistics,
  form,
  location,
  magentoRequests,
  mail,
  media,
  messages,
  notification,
  orders,
  orderHistory,
  partner,
  partnersPaymentTypes: paymentTypes,
  partnerPaymentTypesModal,
  partnerEditMode,
  partners,
  partnersAccess,
  partnersManagers,
  partnersServiceRequests,
  partnerStatusesHistory,
  password,
  priceCreatorCategories,
  priceCreatorFiles,
  priceList,
  productGroups,
  productFeedCategories,
  productFeedCategoriesAttributes,
  productFeedLogs,
  productFeedParameters,
  reports,
  router,
  serviceDesk,
  statistics,
  stopListBrandsCategories,
  stopWords,
  synonyms,
  systemMotivationResults,
  transactionHistory,
  transactionsPayment,
  ui,
  user,
  userSettings,
  users,
  logisticsDataByCategory,
  bankInstallments,
});
