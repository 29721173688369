// Core
import React, { memo } from 'react';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// Parts
import RadioButtons from '../../../../../../components/_Form/RadioButtons/RadioButtons/RadioButtons';
// Helpers
import { validators } from '../../../../../../../_helpers/validationsForm';
import { formFields } from './ProductMultipleEditingForm';
// Color
import { Fieldset, Legend } from './styles';
import { COLOR } from '../../../../../../containers/App/AppStyles';

function ProductQuantityField() {
  const { t } = useTranslation();
  const options = [
    { label: t('В наличии'), value: true },
    { label: t('Нет в наличии'), value: false },
  ];
  return (
    <Fieldset sx={{ border: `1px solid ${COLOR.darkStyle.white['38']}` }} component="fieldset" id="quantity_fieldset">
      <Legend sx={{ color: `${COLOR.darkStyle.white['54']}` }} component="legend">
        {t('Наличие товара')}
      </Legend>
      <Field
        fullWidth
        darkTheme
        name={formFields.quantity}
        component={RadioButtons}
        options={options}
        validate={[validators.required]}
        validation
        sxLabel={{ alignSelf: 'flex-start' }}
      />
    </Fieldset>
  );
}

export default memo(ProductQuantityField);
