// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_REPORTS_LIST, data);
  },
  mergeReconciliation(data) {
    return getActionCreator(types.MERGE_RECONCILIATION, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_REPORTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_REPORTS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_REPORTS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_REPORTS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_REPORTS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_REPORTS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_REPORTS_SETTING_PENDING, settingPending);
  },
  setReload(reload) {
    return getActionCreator(types.SET_REPORTS_RELOAD, reload);
  },
});
