import { getIn, List } from 'immutable';
import { stateKeys as categoriesFeatureKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const categoriesFeatureManagement = Object.freeze({
  currentPage: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.currentPage], 0),
  filters: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.filters], List()),
  isLoading: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.pending], false),
  list: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.items], List()),
  pageSize: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.reload], false),
  totalCount: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.totalCount], 0),
  sorting: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.sorting], List()),
  settingStatus: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.settingStatus], false),
  settingPending: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.settingPending], false),
  treeDataRowIds: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureList, categoriesFeatureKeys.treeDataRowIds], List()),
  importPending: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureImport, categoriesFeatureKeys.pending], false),
  importIsOpen: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureImport, categoriesFeatureKeys.isOpen], false),
  exportPending: state => getIn(state, [categoriesFeatureKeys.controller, categoriesFeatureKeys.categoriesFeatureExport, categoriesFeatureKeys.pending], false),
});
