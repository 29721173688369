// Core
import React, {
  memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// lodash
import isEmpty from 'lodash/isEmpty';

// UI
import Grid from '@mui/material/Grid';

// config
import { pageLinks } from '../../../../engine/config/routes';
import { selectors } from '../../../../engine/config/selectors';

// Actions
import { asyncActions } from '../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
import { asyncActions as brandsAsyncActions } from '../../../../engine/core/brands/saga/asyncActions';
import { asyncActions as partnersAsyncActions } from '../../../../engine/core/partners/saga/asyncActions';

// parts
import SectionHolder from '../../../containers/SectionHolder/SectionHolder';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import BrandCategoryForm from './components/BrandCategoryForm';
import Buttons from './components/Buttons';

function BrandCategoryPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hashId } = useParams();
  const brandsListContext = useSelector(selectors.brands.brandsListContext);

  useEffect(() => {
    dispatch(partnersAsyncActions.getListWithMidAsync({ statusId: 12 }));
    dispatch(asyncActions.getItemAsync({ hashId }));
  }, [dispatch, hashId]);

  useEffect(() => {
    if (isEmpty(brandsListContext.toJS())) {
      dispatch(brandsAsyncActions.getBrandsListsContextAsync());
    }
  }, [dispatch, brandsListContext]);

  return (
    <Grid container>
      <PageHeader title={t('Редактирование стоп бренда-категории')}>
        <Buttons />
        <ButtonGoBack defaultPage={pageLinks.stopBrandsCategories.all} />
      </PageHeader>
      <SectionHolder>
        <Grid item xs={12}>
          <BrandCategoryForm />
        </Grid>
      </SectionHolder>
    </Grid>
  );
}

export default memo(BrandCategoryPage);
