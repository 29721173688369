// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Select from '../../../_Form/Selects/Select/Select';

// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions as messagesAsyncActions } from '../../../../../engine/core/messages/saga/asyncActions';

function UserTypesFilter(props) {
  const { filters, disabled, onSelectChange } = props;
  const dispatch = useDispatch();
  const userGroup = useSelector(selectors.messages.userGroup);
  const input = {
    value: filters && filters.value,
    onChange: onSelectChange,
  };

  useEffect(() => {
    dispatch(messagesAsyncActions.getMessagesUserTypesAsync());
  }, []);

  return (
    <Select
      options={userGroup}
      isDisabled={disabled}
      label=""
      input={input}
    />
  );
}

UserTypesFilter.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  filters: PropTypes.object,
  disabled: PropTypes.bool,
};

export default UserTypesFilter;
