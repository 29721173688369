// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ArrowDownSelect() {
  return (
    <SvgIcon className="arrow-down-select" fontSize="inherit" viewBox="0 0 10 5">
      <path d="M0 0L5 5L10 0H0Z" />
    </SvgIcon>
  );
}
export default ArrowDownSelect;
