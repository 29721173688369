// Core
import React from 'react';
// UI

function FileJpgIcon(props) {
  return (
    <svg width="29" height="38" viewBox="0 0 29 38" fill="none" fontSize="inherit" {...props}>
      <g clipPath="url(#clip0_1905_333)">
        <path d="M21.025 0L29 9.12V36.86C29 37.4908 28.4887 38 27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V1.14C0 0.5092 0.511316 0 1.14474 0H21.025Z" fill="#EBE8E1" />
        <path d="M21.025 0V8.74L29 9.12L21.025 0Z" fill="#D9D7CA" />
        <path d="M27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V26.22H29V36.86C29 37.4908 28.4887 38 27.8553 38Z" fill="#4D9E57" />
        <path d="M9.51172 29.0977H10.6641V32.7227C10.6641 33.1966 10.6224 33.5612 10.5391 33.8164C10.4271 34.1497 10.224 34.418 9.92969 34.6211C9.63542 34.8216 9.2474 34.9219 8.76562 34.9219C8.20052 34.9219 7.76562 34.7643 7.46094 34.4492C7.15625 34.1315 7.0026 33.6667 7 33.0547L8.08984 32.9297C8.10286 33.2578 8.15104 33.4896 8.23438 33.625C8.35938 33.8307 8.54948 33.9336 8.80469 33.9336C9.0625 33.9336 9.24479 33.8607 9.35156 33.7148C9.45833 33.5664 9.51172 33.2604 9.51172 32.7969V29.0977Z" fill="white" />
        <path d="M11.8984 34.8242V29.0977H13.7539C14.457 29.0977 14.9154 29.1263 15.1289 29.1836C15.457 29.2695 15.7318 29.457 15.9531 29.7461C16.1745 30.0326 16.2852 30.4036 16.2852 30.8594C16.2852 31.2109 16.2214 31.5065 16.0938 31.7461C15.9661 31.9857 15.8034 32.1745 15.6055 32.3125C15.4102 32.4479 15.2109 32.5378 15.0078 32.582C14.7318 32.6367 14.332 32.6641 13.8086 32.6641H13.0547V34.8242H11.8984ZM13.0547 30.0664V31.6914H13.6875C14.1432 31.6914 14.4479 31.6615 14.6016 31.6016C14.7552 31.5417 14.875 31.4479 14.9609 31.3203C15.0495 31.1927 15.0938 31.0443 15.0938 30.875C15.0938 30.6667 15.0326 30.4948 14.9102 30.3594C14.7878 30.224 14.6328 30.1393 14.4453 30.1055C14.3073 30.0794 14.0299 30.0664 13.6133 30.0664H13.0547Z" fill="white" />
        <path d="M19.8984 32.7188V31.7539H22.3906V34.0352C22.1484 34.2695 21.7969 34.4766 21.3359 34.6562C20.8776 34.8333 20.4128 34.9219 19.9414 34.9219C19.3424 34.9219 18.8203 34.7969 18.375 34.5469C17.9297 34.2943 17.5951 33.9349 17.3711 33.4688C17.1471 33 17.0352 32.4909 17.0352 31.9414C17.0352 31.3451 17.1602 30.8151 17.4102 30.3516C17.6602 29.888 18.026 29.5326 18.5078 29.2852C18.875 29.0951 19.332 29 19.8789 29C20.5898 29 21.1445 29.1497 21.543 29.4492C21.944 29.7461 22.2018 30.1576 22.3164 30.6836L21.168 30.8984C21.0872 30.6172 20.9349 30.3958 20.7109 30.2344C20.4896 30.0703 20.2122 29.9883 19.8789 29.9883C19.3737 29.9883 18.9714 30.1484 18.6719 30.4688C18.375 30.7891 18.2266 31.2643 18.2266 31.8945C18.2266 32.5742 18.3776 33.0846 18.6797 33.4258C18.9818 33.7643 19.3776 33.9336 19.8672 33.9336C20.1094 33.9336 20.3516 33.8867 20.5938 33.793C20.8385 33.6966 21.0482 33.5807 21.2227 33.4453V32.7188H19.8984Z" fill="white" />
        <g clipPath="url(#clip1_1905_333)">
          <path d="M13.002 14.5C13.002 14.8978 12.844 15.2794 12.5627 15.5607C12.2814 15.842 11.8998 16 11.502 16C11.1042 16 10.7227 15.842 10.4414 15.5607C10.16 15.2794 10.002 14.8978 10.002 14.5C10.002 14.1022 10.16 13.7206 10.4414 13.4393C10.7227 13.158 11.1042 13 11.502 13C11.8998 13 12.2814 13.158 12.5627 13.4393C12.844 13.7206 13.002 14.1022 13.002 14.5Z" fill="#303030" fillOpacity="0.72" />
          <path d="M8.5 11C8.10218 11 7.72064 11.158 7.43934 11.4393C7.15804 11.7206 7 12.1022 7 12.5V21.5C7 21.8978 7.15804 22.2794 7.43934 22.5607C7.72064 22.842 8.10218 23 8.5 23H21.5C21.8978 23 22.2794 22.842 22.5607 22.5607C22.842 22.2794 23 21.8978 23 21.5V12.5C23 12.1022 22.842 11.7206 22.5607 11.4393C22.2794 11.158 21.8978 11 21.5 11H8.5ZM21.5 12C21.6326 12 21.7598 12.0527 21.8536 12.1464C21.9473 12.2402 22 12.3674 22 12.5V18.5L18.225 16.553C18.1312 16.506 18.025 16.4897 17.9215 16.5064C17.8179 16.5231 17.7223 16.5719 17.648 16.646L13.938 20.356L11.278 18.584C11.182 18.5201 11.0668 18.4913 10.9519 18.5026C10.8371 18.5139 10.7297 18.5646 10.648 18.646L8.002 21V21.54L8 21.5V12.5C8 12.3674 8.05268 12.2402 8.14645 12.1464C8.24021 12.0527 8.36739 12 8.5 12H21.5Z" fill="#303030" fillOpacity="0.72" />
        </g>
      </g>
      <path d="M9.51172 29.0977H10.6641V32.7227C10.6641 33.1966 10.6224 33.5612 10.5391 33.8164C10.4271 34.1497 10.224 34.418 9.92969 34.6211C9.63542 34.8216 9.2474 34.9219 8.76562 34.9219C8.20052 34.9219 7.76562 34.7643 7.46094 34.4492C7.15625 34.1315 7.0026 33.6667 7 33.0547L8.08984 32.9297C8.10286 33.2578 8.15104 33.4896 8.23438 33.625C8.35938 33.8307 8.54948 33.9336 8.80469 33.9336C9.0625 33.9336 9.24479 33.8607 9.35156 33.7148C9.45833 33.5664 9.51172 33.2604 9.51172 32.7969V29.0977Z" fill="white" />
      <path d="M11.8984 34.8242V29.0977H13.7539C14.457 29.0977 14.9154 29.1263 15.1289 29.1836C15.457 29.2695 15.7318 29.457 15.9531 29.7461C16.1745 30.0326 16.2852 30.4036 16.2852 30.8594C16.2852 31.2109 16.2214 31.5065 16.0938 31.7461C15.9661 31.9857 15.8034 32.1745 15.6055 32.3125C15.4102 32.4479 15.2109 32.5378 15.0078 32.582C14.7318 32.6367 14.332 32.6641 13.8086 32.6641H13.0547V34.8242H11.8984ZM13.0547 30.0664V31.6914H13.6875C14.1432 31.6914 14.4479 31.6615 14.6016 31.6016C14.7552 31.5417 14.875 31.4479 14.9609 31.3203C15.0495 31.1927 15.0938 31.0443 15.0938 30.875C15.0938 30.6667 15.0326 30.4948 14.9102 30.3594C14.7878 30.224 14.6328 30.1393 14.4453 30.1055C14.3073 30.0794 14.0299 30.0664 13.6133 30.0664H13.0547Z" fill="white" />
      <path d="M19.8984 32.7188V31.7539H22.3906V34.0352C22.1484 34.2695 21.7969 34.4766 21.3359 34.6562C20.8776 34.8333 20.4128 34.9219 19.9414 34.9219C19.3424 34.9219 18.8203 34.7969 18.375 34.5469C17.9297 34.2943 17.5951 33.9349 17.3711 33.4688C17.1471 33 17.0352 32.4909 17.0352 31.9414C17.0352 31.3451 17.1602 30.8151 17.4102 30.3516C17.6602 29.888 18.026 29.5326 18.5078 29.2852C18.875 29.0951 19.332 29 19.8789 29C20.5898 29 21.1445 29.1497 21.543 29.4492C21.944 29.7461 22.2018 30.1576 22.3164 30.6836L21.168 30.8984C21.0872 30.6172 20.9349 30.3958 20.7109 30.2344C20.4896 30.0703 20.2122 29.9883 19.8789 29.9883C19.3737 29.9883 18.9714 30.1484 18.6719 30.4688C18.375 30.7891 18.2266 31.2643 18.2266 31.8945C18.2266 32.5742 18.3776 33.0846 18.6797 33.4258C18.9818 33.7643 19.3776 33.9336 19.8672 33.9336C20.1094 33.9336 20.3516 33.8867 20.5938 33.793C20.8385 33.6966 21.0482 33.5807 21.2227 33.4453V32.7188H19.8984Z" fill="white" />
      <defs>
        <clipPath id="clip0_1905_333">
          <rect width="29" height="38" fill="white" />
        </clipPath>
        <clipPath id="clip1_1905_333">
          <rect width="16" height="16" fill="white" transform="translate(7 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FileJpgIcon;
