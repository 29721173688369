// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import RefreshIcon from '@mui/icons-material/Refresh';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { asyncActions } from '../../../../engine/core/priceCreator/priceCreatorFiles/saga/asyncActions';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../engine/config/userRoles';
import { actions } from '../../../../engine/core/priceCreator/priceCreatorFiles/actions';

function UpdateDataXmlButton() {
  const hasAccess = useAccessList(accessList.productPricesXmlGenerate);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdminAndManagerRole = useUserSomeRole([userRoles.admin, userRoles.manager]);
  const partnerID = useSelector(selectors.user.userPartnerId);

  if (!hasAccess) {
    return null;
  }

  const onTriggerPopupUpdateXML = () => {
    if (isAdminAndManagerRole) {
      dispatch(actions.setDataXmlDownloadModalActive(true));
    } else {
      dispatch(asyncActions.downloadDataXmlAsync(partnerID));
    }
  };

  return (
    <>
      <ButtonWithIconAndCircularProgress
        text={t('Обновить данные в XML')}
        onClick={onTriggerPopupUpdateXML}
      >
        <RefreshIcon />
      </ButtonWithIconAndCircularProgress>
    </>
  );
}

export default UpdateDataXmlButton;
