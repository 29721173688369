// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  list: {
    currentPage: 0,
    filters: [],
    items: [],
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    sortingStateColumnExtensions: [],
  },
  setting: Map({
    status: false,
    pending: false,
  }),
  statusesList: Map({
    items: List(),
    pending: false,
  }),
  itemByHashId: {
    pending: true,
    putPending: false,
  },
  chat: Map({
    currentMessage: '',
    infinityScrollState: List(),
    messageIsSending: false,
    messages: {
      data: List(),
    },
    messagesFromServer: {
      pending: false,
      data: List(),
    },
    chatFiles: List(),
    pending: false,
    chatName: '',
    externalLink: null,
    canEdit: false,
    canClose: false,
    canContinue: false,
    key: new Date().getTime(),
  }),
  closeQuestion: Map({
    pending: false,
  }),
  continueQuestion: Map({
    pending: false,
  }),
  tickets: {
    new: {
      pending: false,
    },
  },
});

export const stateKeys = Object.freeze({
  controller: 'serviceDesk',
  canContinue: 'canContinue',
  continueQuestion: 'continueQuestion',
  columnName: 'columnName',
  currentPage: 'currentPage',
  direction: 'direction',
  itemByHashId: 'itemByHashId',
  setting: 'setting',
  status: 'status',
  list: 'list',
  closeQuestion: 'closeQuestion',
  filters: 'filters',
  items: 'items',
  pageSize: 'pageSize',
  pending: 'pending',
  putPending: 'putPending',
  totalCount: 'totalCount',
  sorting: 'sorting',
  sortingStateColumnExtensions: 'sortingStateColumnExtensions',
  statusesList: 'statusesList',
  tableColumnExtensions: 'tableColumnExtensions',
  reload: 'reload',
  chat: 'chat',
  chatFiles: 'chatFiles',
  data: 'data',
  canEdit: 'canEdit',
  canClose: 'canClose',
  messages: 'messages',
  messagesFromServer: 'messagesFromServer',
  currentMessage: 'currentMessage',
  externalLink: 'externalLink',
  chatName: 'chatName',
  infinityScrollState: 'infinityScrollState',
  messageIsSending: 'messageIsSending',
  ticket: 'ticket',
  new: 'new',
  key: 'key',
});

export const serviceDeskReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SERVICE_DESK_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.list, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.list, stateKeys.pending], pending)
        .setIn([stateKeys.list, stateKeys.items], List(items))
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount);
    }

    case types.SET_SERVICE_DESK_SETTING_STATUS: {
      return state.setIn([stateKeys.setting, stateKeys.status], payload);
    }
    case types.SET_SERVICE_DESK_SETTING_PENDING: {
      return state.setIn([stateKeys.setting, stateKeys.pending], payload);
    }

    case types.SET_SERVICE_DESK_STATUSES_LIST: {
      const statusesList = List(payload);
      return state.setIn([stateKeys.statusesList, stateKeys.items], statusesList);
    }

    case types.SET_SERVICE_DESK_STATUSES_LIST_PENDING: {
      return state.setIn([stateKeys.statusesList, stateKeys.pending], payload);
    }

    case types.SET_SERVICE_DESK_SORTING: {
      return state.setIn([stateKeys.list, stateKeys.sorting], fromJS(payload));
    }

    case types.SET_SERVICE_DESK_PAGE_SIZE: {
      const pageSize = action.payload;
      return state.setIn([stateKeys.list, stateKeys.pageSize], pageSize);
    }
    case types.SET_SERVICE_DESK_CURRENT_PAGE: {
      const currentPage = action.payload;
      return state.setIn([stateKeys.list, stateKeys.currentPage], currentPage);
    }
    case types.SET_SERVICE_DESK_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.list, stateKeys.filters], fromJS(filters));
    }
    case types.SET_SERVICE_DESK_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length ? sorting : initialState.getIn([stateKeys.list, stateKeys.sorting]).toJS();
      return state
        .setIn([stateKeys.list, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.list, stateKeys.filters], List(filters))
        .setIn([stateKeys.list, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.list, stateKeys.reload], reload)
        .setIn([stateKeys.list, stateKeys.sorting], fromJS(currentSorting));
    }
    case types.SET_SERVICE_DESK_IS_ITEM_BY_HASH_ID_LOADING: {
      const isLoading = action.payload;
      return state.setIn([stateKeys.itemByHashId, stateKeys.pending], isLoading);
    }
    case types.SET_SERVICE_DESK_IS_ITEM_BY_HASH_PUT_PENDING: {
      return state.setIn([stateKeys.itemByHashId, stateKeys.putPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.MERGE_CHAT: {
      return state.mergeIn([stateKeys.chat], Map(payload));
    }
    case types.SET_CHAT_FILE_IS_PENDING: {
      return state.setIn([stateKeys.chat, stateKeys.pending], payload);
    }
    case types.SET_FILES_IN_CHAT_MESSAGE: {
      return state.setIn([stateKeys.chat, stateKeys.chatFiles], payload);
    }
    case types.SET_CHAT_MESSAGES: {
      return state.setIn([stateKeys.chat, stateKeys.messages, stateKeys.data], payload);
    }
    case types.SET_SERVICE_DESK_MESSAGES_FROM_SERVER: {
      return state.setIn([stateKeys.chat, stateKeys.messagesFromServer, stateKeys.data], payload);
    }
    case types.SET_SERVICE_DESK_MESSAGES_FROM_SERVER_ARE_PENDING: {
      return state.setIn([stateKeys.chat, stateKeys.messagesFromServer, stateKeys.pending], payload);
    }
    case types.SET_CURRENT_MESSAGES: {
      return state.setIn([stateKeys.chat, stateKeys.currentMessage], payload);
    }
    case types.SET_SERVICE_DESK_EXTERNAL_LINK: {
      return state.setIn([stateKeys.chat, stateKeys.externalLink], payload);
    }
    case types.SET_SERVICE_DESK_CHAT_NAME: {
      return state.setIn([stateKeys.chat, stateKeys.chatName], payload);
    }
    case types.SET_SERVICE_DESK_INFINITY_SCROLL_STATE: {
      return state.setIn([stateKeys.chat, stateKeys.infinityScrollState], payload);
    }
    case types.SET_SERVICE_DESK_MESSAGE_IS_SENDING: {
      return state.setIn([stateKeys.chat, stateKeys.messageIsSending], payload);
    }
    case types.SET_SERVICE_DESK_KEY: {
      return state.setIn([stateKeys.chat, stateKeys.key], payload);
    }
    case types.SET_SERVICE_DESK_CLOSE_QUESTION_PENDING: {
      return state.setIn([stateKeys.closeQuestion, stateKeys.pending], payload);
    }
    case types.SET_SERVICE_DESK_CONTINUE_QUESTION_PENDING: {
      return state.setIn([stateKeys.continueQuestion, stateKeys.pending], payload);
    }
    case types.SET_SERVICE_DESK_NEW_TICKET_PENDING: {
      return state.setIn([stateKeys.tickets, stateKeys.new, stateKeys.pending], payload);
    }
    default: {
      return state;
    }
  }
};
