// Core
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
// UI
import Grid from '@mui/material/Grid';
// Components
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
// Data
import { formFields } from '../../../../../../_helpers/data/pages/handbook/categories/form';

function CategoryForm(props) {
  const { disabled, mode } = props;
  const { t } = useTranslation();

  const isDisabled = useMemo(() => disabled || (mode === 'show'), [disabled, mode]);
  const max = 100;
  const min = 0;

  const minMaxValue = useCallback((value) => {
    const defaultValue = '';
    if (value) {
      const number = parseInt(value, 10);
      if (Number.isNaN(number) || (number < min)) {
        return defaultValue;
      }
      return number > max ? max.toString() : number.toString();
    }
    return defaultValue;
  }, [max]);

  const renderField = useCallback(renderFieldProps => (
    <TextField
      {...renderFieldProps}
      margin="normal"
      fullWidth
      validation
    />
  ), []);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Field
          component={renderField}
          disabled
          label={t('Название категории')}
          name={formFields.name}
        />
        <Field
          component={renderField}
          disabled
          label={t('M-ID категории')}
          name={formFields.idMagentoCategory}
        />
        <Field
          component={renderField}
          disabled={isDisabled}
          label={t('Процент заполненности категории')}
          name={formFields.fillingPercent}
          normalize={minMaxValue}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Field
          component={renderField}
          disabled
          label={t('Название родительской категории')}
          name={formFields.parentName}
        />
        <Field
          component={renderField}
          disabled
          label={t('M-ID родительской категории')}
          name={formFields.idMagentoCategoryParent}
        />
        {/* <Field */}
        {/*  component={renderField} */}
        {/*  disabled={isDisabled} */}
        {/*  label={t('Процент комиссии Партнера по-умолчанию')} */}
        {/*  name={formFields.partnerOfferPercent} */}
        {/*  normalize={minMaxValue} */}
        {/* /> */}
      </Grid>
    </>
  );
}

CategoryForm.propTypes = {
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf(['edit', 'show']),
};

CategoryForm.defaultProps = {
  disabled: false,
  mode: 'edit',
};

export default CategoryForm;
