export const asyncTypes = Object.freeze({
  // PRODUCT_FEED_FEATURE_VALUES
  GET_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID: 'GET_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID',
  PUT_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID: 'PUT_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID',
  // PRODUCT_FEED_FEATURE
  PUT_PRODUCT_FEED_CATEGORIES_BY_HASH_ID: 'PUT_PRODUCT_FEED_CATEGORIES_BY_HASH_ID',
  GET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID: 'GET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID',
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST_ASYNC: 'GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST_ASYNC',
  PUT_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTINGS_ASYNC: 'PUT_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTINGS_ASYNC',
  GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE_ASYNC: 'GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE_ASYNC',
  POST_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_ASYNC: 'POST_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_ASYNC',
});
