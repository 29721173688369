// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPostLogisticsDataByCategoryImportWorker,
  callGetLogisticsDataByCategoryExportWorker,
  callGetItemByHashIdWorker,
  callPutItemByHashIdWorker,
  callGetLogsWorker,
} from './workers';

function* watchGetItemByHashId() {
  yield takeEvery(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC, callGetItemByHashIdWorker);
}
function* watchGetList() {
  yield takeEvery(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_LOGISTICS_DATA_BY_CATEGORY_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_LOGISTICS_DATA_BY_CATEGORY_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_LOGISTICS_DATA_BY_CATEGORY_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_LOGISTICS_DATA_BY_CATEGORY_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_LOGISTICS_DATA_BY_CATEGORY_SORTING, callSetToLocalDataWorker);
}

function* watchPostLogisticsDataByCategoryImport() {
  yield takeEvery(asyncTypes.POST_LOGISTICS_DATA_BY_CATEGORY_IMPORT_ASYNC, callPostLogisticsDataByCategoryImportWorker);
}

function* watchPostLogisticsDataByCategoryExport() {
  yield takeEvery(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_EXPORT_ASYNC, callGetLogisticsDataByCategoryExportWorker);
}
function* watchPutItemByHashIdWorker() {
  yield takeEvery(asyncTypes.PUT_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC, callPutItemByHashIdWorker);
}
function* watchGetLogsWorker() {
  yield takeEvery(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_LOGS_ASYNC, callGetLogsWorker);
}

export function* watchersLogisticsDataByCategory() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetItemByHashId(),
    watchPostLogisticsDataByCategoryImport(),
    watchPostLogisticsDataByCategoryExport(),
    watchPutItemByHashIdWorker(),
    watchGetLogsWorker(),
  ]);
}
