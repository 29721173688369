// UI
import SvgIcon from '@mui/material/SvgIcon';

export default function SystemDataIcon(props) {
  return (
    <SvgIcon className="person-outlined-icon" fontSize="inherit" viewBox="0 0 18 18" {...props}>
      <g clipPath="url(#clip0_1194_41123)">
        <path d="M0 2.25C0 1.00736 1.00736 0 2.25 0H11.75C12.9926 0 14 1.00736 14 2.25V11H18V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V2.25ZM14 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V12.5H14V16.5ZM2.25 1.5C1.83579 1.5 1.5 1.83579 1.5 2.25V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H12.5V2.25C12.5 1.83579 12.1642 1.5 11.75 1.5H2.25ZM4.25 4C3.83579 4 3.5 4.33579 3.5 4.75C3.5 5.16421 3.83579 5.5 4.25 5.5H9.75C10.1642 5.5 10.5 5.16421 10.5 4.75C10.5 4.33579 10.1642 4 9.75 4H4.25ZM3.5 8.75C3.5 8.33579 3.83579 8 4.25 8H9.75C10.1642 8 10.5 8.33579 10.5 8.75C10.5 9.16421 10.1642 9.5 9.75 9.5H4.25C3.83579 9.5 3.5 9.16421 3.5 8.75ZM4.25 12C3.83579 12 3.5 12.3358 3.5 12.75C3.5 13.1642 3.83579 13.5 4.25 13.5H7.25C7.66421 13.5 8 13.1642 8 12.75C8 12.3358 7.66421 12 7.25 12H4.25Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_1194_41123">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
