// Core
import { apply, put, cancelled } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { change } from 'redux-form';
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { formName } from '../../../../../../ui/page/Products/PriceLists/Item/components/ProductFeedCategoriesTable/ProductFeedCategoriesTable';

export function* callGetListWorker({ payload }) {
  const abortController = new AbortController();

  try {
    const { isRepeatedRequest, ...restPayload } = payload;
    yield put(actions.setList({ pending: true }));
    const response = yield apply(
      api,
      api.productFeedCategories.getList,
      [{ ...restPayload, signal: abortController.signal }],
    );

    let data = {
      items: [],
      totalCount: 0,
      pending: false,
    };

    if (response && response.status >= 200 && response.status < 400) {
      const allItems = response.data.items.map((item) => {
        const { connections, ...itemProps } = item;
        if (item.connections?.length) {
          return [{ ...itemProps }, ...item.connections];
        }
        return item;
      });
      data = Object.assign(data, {
        ...response.data,
        items: allItems.flat(),
      });

      const fieldsValues = {};
      data.items.forEach((item) => {
        if (item?.category?.value) {
          fieldsValues[`${item.id}_category`] = [item?.category];
        }
        fieldsValues[`${item.id}_ignored`] = item?.ignored;
      });

      if (Object.keys(fieldsValues).length && !isRepeatedRequest) {
        yield put(initialize(formName, fieldsValues, Object.keys(fieldsValues)));
        yield put(actions.setList({ pending: false }));
      }
      if (isRepeatedRequest) {
        yield put(change(formName, fieldsValues, Object.keys(fieldsValues)));
      }
    }

    yield put(actions.setList(data));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}
