// core
import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Parts
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import AdditionalEmailGroupFields from './AdditionalEmailGroupFields';

function AdditionalEmailGroupsFields(props) {
  const { fields, disabled } = props;
  const { t } = useTranslation();
  const [firstGroup, setFirstGroup] = useState(false);
  const groupAdd = useCallback(() => {
    fields.push({});
  }, [fields]);

  useEffect(() => {
    if (firstGroup && !fields.length) {
      setFirstGroup(false);
      groupAdd();
    }
  }, [firstGroup, setFirstGroup, groupAdd, fields]);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {fields.map((member, index) => (
            <AdditionalEmailGroupFields
              key={index}
              fields={fields}
              member={member}
              index={index}
              disabled={disabled}
            />
          ))}
          {fields.length >= 3 ? null : (
            <ButtonWithIcon
              text={t('Добавить')}
              onClick={groupAdd}
              disabled={disabled}
            >
              <AddCircleIcon />
            </ButtonWithIcon>
          )}
        </Grid>
      </Grid>
    </>
  );
}

AdditionalEmailGroupsFields.propTypes = {
  fields: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default memo(AdditionalEmailGroupsFields);
