// Core
import React, { useCallback, useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Containers
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';

const styles = () => ({
  wrapper: {
    display: 'flex',
  },
  textarea: {
    display: 'block',
    borderRadius: '4px',
    paddingLeft: '14px',
    paddingRight: '14px',
    margin: '0px !important',
    width: '100%',
    flexGrow: 1,
  },
});

const useStyles = makeStyles(styles);

function TextFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, onConfirm, productId, isEditable, fullText,
    success, value, valuesPlain, required, type, showDeleteButton,
  } = props;
  const classes = useStyles();
  const initValue = value.toString() || '';
  const [textFieldValue, setTextFieldValue] = useState(initValue);
  const notEmpty = textFieldValue !== '';
  const confirmButtonValue = textFieldValue.trim();

  const onChangeHandler = useCallback((ev) => {
    setTextFieldValue(ev.target.value);
  }, []);

  const onConfirmHandler = useCallback((payload) => {
    onConfirm({ [featureName]: payload });
  }, [featureName, onConfirm]);

  return (
    <EditableContainer isEditable={isEditable} fullText={fullText} text={value} maxWidth={false}>
      <ConfirmButtons
        key={productId}
        error={error}
        disabled={disabled}
        required={required}
        notEmpty={notEmpty}
        onConfirm={onConfirmHandler}
        onReject={setTextFieldValue}
        success={success}
        value={confirmButtonValue}
        type={type}
        valuesPlain={valuesPlain}
        initValue={initValue}
        showDeleteButton={showDeleteButton}
      >
        <div className={classes.wrapper}>
          <textarea
            className={classes.textarea}
            onChange={onChangeHandler}
            value={textFieldValue}
          />
        </div>
      </ConfirmButtons>
    </EditableContainer>
  );
}

TextFieldWithConfirmButtons.displayName = 'TextFieldWithConfirmButtons';

TextFieldWithConfirmButtons.propTypes = {
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditable: PropTypes.bool,
  fullText: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  success: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextFieldWithConfirmButtons.defaultProps = {
  error: false,
  disabled: false,
  required: false,
  isEditable: false,
  showDeleteButton: true,
  success: false,
  value: '',
  valuesPlain: '',
};

export default memo(TextFieldWithConfirmButtons);
