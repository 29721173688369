const getRecursiveChildrens = (options, currentValue) => {
  const cache = new Map();

  const getChildrenItems = (optionsList, parentValue) => {
    const cacheKey = `${parentValue}`;
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }

    const childrenItems = optionsList.filter(item => item.parentValue === parentValue);
    const result = childrenItems.flatMap(child => [
      child,
      ...getChildrenItems(optionsList, child.value),
    ]);

    cache.set(cacheKey, result);
    return result;
  };

  return getChildrenItems(options, currentValue);
};

export default getRecursiveChildrens;
