/* eslint-disable no-param-reassign */ // -> OFF

// Core
import reduce from 'lodash/reduce';
import get from 'lodash/get';
// _helpers
import { getDate } from './getDate';
// Data
import { tableColumns as ordersTableColumns } from './data/pages/orders/tableColumns';
import { tableColumns } from './data/pages/faqs/tableColumns';

const fields = Object.freeze({
  accessFeed: 'filter[accessFeed]',
  accessBlockContent: 'filter[accessBlockContent]',
  accessBilling: 'filter[accessBilling]',
  accessRatingRatio: 'filter[accessRatingRatio]',
  translatedAt: 'filter[translatedAt]',
  isTop: 'filter[isTop]',
  categoryId: 'categoryId',
  attributeSetIdSelect: 'attributeSetId',
  format: 'format',
  overdue: 'filter[overdue]',
  id: 'filter[id]',
  companyName: 'filter[companyName]',
  manager: 'filter[manager]',
  sourceType: 'filter[sourceType]',
  filterRegistrationAtFrom: 'filter[registrationAt][from]',
  filterRegistrationAtTo: 'filter[registrationAt][to]',
  groups: 'filter[groups]',
  email: 'filter[email]',
  mobPhone: 'filter[mobPhone]',
  partnerStatus: 'filter[partnerStatus]',
  deliveryTime: 'filter[deliveryTime]',
  site: 'filter[site]',
  limit: 'limit',
  offset: 'offset',
  context: 'context',
  orderBy: 'orderBy',
  name: 'filter[name]',
  categories: 'filter[categories]',
  quantity: 'filter[quantity]',
  referrer: 'filter[referrer]',
  sku: 'filter[sku]',
  amount: 'filter[amount]',
  commission: 'filter[commission]',
  cancelStatus: 'filter[cancelStatus]',
  idMagentoPartner: 'filter[idMagentoPartner]',
  partner: 'filter[partner]',
  paymentType: 'filter[paymentType]',
  paymentStatus: 'filter[paymentStatus]',
  information: 'filter[information]',
  customerName: 'filter[customerName]',
  customerPhone: 'filter[customerPhone]',
  customerEmail: 'filter[customerEmail]',
  shippingType: 'filter[shippingType]',
  enabled: 'filter[enabled]',
  shippingTrackingNumber: 'filter[shippingTrackingNumber]',
  shippingPrice: 'filter[shippingPrice]',
  shippingCity: 'filter[shippingCity]',
  shippingCityUid: 'filter[shippingCityUid]',
  shippingStreet: 'filter[shippingStreet]',
  shippingHouse: 'filter[shippingHouse]',
  shippingApartment: 'filter[shippingApartment]',
  stockName: 'filter[stockName]',
  stockNumberMe: 'filter[stockNumberMe]',
  note: 'filter[note]',
  idMagentoBrand: 'filter[idMagentoBrand]',
  idMagentoCategory: 'filter[idMagentoCategory]',
  parentId: 'filter[parentId]',
  idMagentoCategoryParent: 'filter[idMagentoCategoryParent]',
  parentName: 'filter[parentName]',
  doneAtFrom: 'filter[doneAt][from]',
  doneAtTo: 'filter[doneAt][to]',
  status: 'filter[status]',
  datetimeFrom: 'filter[datetime][from]',
  datetimeTo: 'filter[datetime][to]',
  canceledAtFrom: 'filter[canceledAt][from]',
  canceledAtTo: 'filter[canceledAt][to]',
  activateAtFrom: 'filter[activateAt][from]',
  activateAtTo: 'filter[activateAt][to]',
  contractAtFrom: 'filter[contractAt][from]',
  contractAtTo: 'filter[contractAt][to]',
  waitingVerificationAtFrom: 'filter[waitingVerificationAt][from]',
  waitingVerificationAtTo: 'filter[waitingVerificationAt][to]',
  filingAtFrom: 'filter[filingAt][from]',
  filingAtTo: 'filter[filingAt][to]',
  notPassedAtFrom: 'filter[notPassedAt][from]',
  notPassedAtTo: 'filter[notPassedAt][to]',
  registrationAtFrom: 'filter[registrationAt][from]',
  registrationAtTo: 'filter[registrationAt][to]',
  contactPhone: 'filter[contactPhone]',
  contactEmail: 'filter[contactEmail]',
  idMagentoOrder: 'filter[idMagentoOrder]',
  orderStatus: 'filter[orderStatus]',
  orderedAtFrom: 'filter[orderedAt][from]',
  orderedAtTo: 'filter[orderedAt][to]',
  sentAtFrom: 'filter[sentAt][from]',
  sentAtTo: 'filter[sentAt][to]',
  shippedAtFrom: 'filter[shippedAt][from]',
  shippedAtTo: 'filter[shippedAt][to]',
  roleName: 'filter[roleName]',
  title: 'filter[title]',
  messageUsers: 'filter[messageUsers]',
  messageStatus: 'filter[messageStatus]',
  subject: 'filter[subject]',
  eventName: 'filter[event]',
  createdAtFrom: 'filter[createdAt][from]',
  statusChangedAtFrom: 'filter[statusChangedAt][from]',
  statusChangedAtTo: 'filter[statusChangedAt][to]',
  createdAtTo: 'filter[createdAt][to]',
  read: 'filter[read]',
  paymentTypeId: 'filter[paymentTypeId]',
  stockNumber: 'filter[stockNumber]',
  category: 'filter[category]',
  brand: 'filter[brand]',
  sorting: 'orderBy',
  value: 'filter[value]',
  editable: 'filter[editable]',
  type: 'filter[type]',
  locale: 'locale',
  label: 'label',
  productMedias: 'filter[productMedias]',
  shippingId: 'filter[shippingId]',
  regionName: 'filter[regionName]',
  partnerID: 'filter[partnerID]',
  userId: 'filter[userID]',
  [tableColumns.status.name]: `filter[${tableColumns.status.name}]`,
  [tableColumns.id.name]: `filter[${tableColumns.id.name}]`,
  [tableColumns.answer.name]: `filter[${tableColumns.answer.name}]`,
  [tableColumns.idMagentoFaq.name]: `filter[${tableColumns.idMagentoFaq.name}]`,
  [tableColumns.question.name]: `filter[${tableColumns.question.name}]`,
  [tableColumns.sku.name]: `filter[${tableColumns.sku.name}]`,
  [tableColumns.partner.name]: `filter[${tableColumns.partner.name}]`,
  billingStatus: 'filter[billingStatus]',
  magentoMethod: 'filter[magentoMethod]',
  requestMethod: 'filter[requestMethod]',
  magentoPath: 'filter[magentoPath]',
  conditionsBalance: 'conditions[balance]',
  conditionsHold: 'conditions[holdBalance]',
  conditionsSummaryBalance: 'conditions[summaryBalance]',
  dictionaryType: 'filter[dictionaryType]',
  synonyms: 'filter[synonyms]',
  attributeSetId: 'filter[attributeSetId]',
});

/**
 * Index datetime FROM convertDatetimeFieldToUrlParams() array
 * @constant {number}
 * */
const FROM = 0;

/**
 * Index datetime TO convertDatetimeFieldToUrlParams() array
 * @constant {number}
 * */
const TO = 1;

/**
 * Create URL params from array of values
 * e.g [{ value: 1 }, { value: 2 }] will convert to string '1,2'
 * @params {Array.<value: Number>} fieldValue
 * @return {string|undefined}
 * */
export function convertSelectFieldToUrlParams(fieldValue = []) {
  if (Array.isArray(fieldValue) && fieldValue.length > 0) {
    return fieldValue.reduce((acc, { value }) => (
      `${acc}${value},`
    ), '').slice(0, -1);
  }
  if (typeof fieldValue === 'object') {
    return fieldValue?.value;
  }
  if (typeof fieldValue === 'number' || typeof fieldValue === 'string') {
    return fieldValue.toString();
  }
  if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
    return fieldValue.toString();
  }
}

/**
 * Create URL params from array of values
 * e.g [{ columnName: name, direction: asc }] will convert to string 'name ASC'
 * @params {Array.<columnName: String>, direction: <('asc'|'desc')>} sorting
 * @return {string|undefined}
 * */
export function convertSortingObjectToUrlParams(sorting = []) {
  if (Array.isArray(sorting) && sorting.length > 0) {
    return sorting.reduce((acc, { columnName, direction }) => (
      `${acc}${columnName} ${direction === 'asc' ? 'ASC' : 'DESC'}`
    ), '');
  }
}

/**
 * Create Array of values [datetimeFrom, datetimeTo]
 * @param {{ startDate: String, endDate: String }} datetime
 * @param {String} format - date format to be converted to
 * @return {String[]}
 * */
export function convertDatetimeFieldToUrlParams(datetime, format = 'YYYY-MM-DD') {
  let startDate;
  let endDate;
  if (datetime) {
    startDate = getDate(datetime.startDate, format);
    endDate = getDate(datetime.endDate, format);
  }
  return [startDate, endDate];
}

export function convertJSObjectToUrlParams(args) {
  return reduce(args, (result, value, key) => {
    if (fields[key] !== undefined || fields[`${key}From`] !== undefined || fields[`${key}To`] !== undefined) {
      switch (key) {
        case 'brand':
        case 'cancelStatus':
        case 'category':
        case 'categories':
        case 'groups':
        case 'manager':
        case 'sourceType':
        case 'orderStatus':
        case 'status':
        case 'enabled':
        case 'paymentType':
        case 'type':
        case 'messageUsers':
        case ordersTableColumns.partner.name:
        case 'conditionsBalance':
        case 'conditionsHold':
        case 'idMagentoPartner':
        case 'excludePartners':
        case tableColumns.partner.name: {
          result[fields[key]] = convertSelectFieldToUrlParams(value);
          break;
        }
        case 'billingStatus': {
          const billingValue = get(value, 'value', value);
          result[fields[key]] = convertSelectFieldToUrlParams(billingValue);
          break;
        }
        case 'activateAt':
        case 'canceledAt':
        case 'contractAt':
        case 'createdAt':
        case 'statusChangedAt':
        case 'datetime':
        case 'doneAt':
        case 'filingAt':
        case 'notPassedAt':
        case 'orderedAt':
        case 'sentAt':
        case 'shippedAt':
        case 'registrationAt':
        case 'waitingVerificationAt': {
          result[fields[`${key}From`]] = convertDatetimeFieldToUrlParams(value)[FROM];
          result[fields[`${key}To`]] = convertDatetimeFieldToUrlParams(value)[TO];
          break;
        }
        case tableColumns.status.name:
        case 'partnerStatus': {
          if (value && typeof value.value === 'number') {
            result[fields[key]] = value.value;
          } else if (value && value.length > 0) {
            result[fields[key]] = convertSelectFieldToUrlParams(value);
          }
          break;
        }
        case 'orderBy':
        case 'sorting': {
          const isString = typeof value === 'string';
          result[fields[key]] = isString ? value : convertSortingObjectToUrlParams(value);
          break;
        }
        case 'eventName':
        case 'messageStatus': {
          if (value && typeof value.value === 'number') {
            result[fields[key]] = value.value;
          }
          break;
        }
        case 'synonyms': {
          const valueArray = value.split(',').map(item => item.trim());
          result[fields[key]] = valueArray;
          break;
        }
        case 'attributeSetId': {
          if (!value) {
            break;
          }
          result[fields[key]] = value.join(',');
          break;
        }
        case 'translatedAt': {
          if (value.empty) {
            result[fields[key]] = value.empty;
          } else {
            result[`filter[${key}][from]`] = convertDatetimeFieldToUrlParams(value)[FROM];
            result[`filter[${key}][to]`] = convertDatetimeFieldToUrlParams(value)[TO];
          }
          break;
        }
        default: {
          result[fields[key]] = value;
        }
      }
    } else if (Array.isArray(value)) {
      result[`filter[${key}]`] = convertSelectFieldToUrlParams(value);
    } else if (get(value, 'startDate') || get(value, 'endDate')) {
      result[`filter[${key}][from]`] = convertDatetimeFieldToUrlParams(value)[FROM];
      result[`filter[${key}][to]`] = convertDatetimeFieldToUrlParams(value)[TO];
    } else {
      result[`filter[${key}]`] = value;
    }
    return result;
  }, {});
}
