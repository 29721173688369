// Core
import { useCallback } from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';
// Engine
import { selectors } from '../../engine/config/selectors';

export function useRequiredPartnerStatusFields(partnerStatus) {
  const statusListSelector = useSelector(selectors.partner.statusesList);

  const isRequiredStatusFields = useCallback((fieldName) => {
    const found = find(statusListSelector.toJS(), { value: partnerStatus });

    return found && !isEmpty(found.requiredFields) && includes(found.requiredFields, fieldName);
  }, [statusListSelector, partnerStatus]);

  return { isRequiredStatusFields };
}
