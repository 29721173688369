import { COLOR } from '../../../../../containers/App/AppStyles';

const tableHeadFilterCellStyles = () => ({
  selectCounterCell: {
    zIndex: 300,
    position: 'sticky !important',
    backgroundClip: 'padding-box',
    backgroundColor: '#FBFBFB',
    left: 0,
    '&.MuiTableCell-head.CellLayout-cell': {
      padding: '2px 3px 3px',
      '& .CellLayout-container': {
        paddingBottom: 0,
      },
    },
    '&.MuiTableCell-head.TableSelectAllCell-cell': {
      paddingLeft: '0px',
    },
  },
  selectCounterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },

  selectCounterTitle: {
    fontSize: '10px',
    lineHeight: '10px',
    color: COLOR.grey['54'],
    marginBottom: '4px',
  },
  selectCounterQuantity: {
    fontSize: '14px',
    lineHeight: '14px',
    color: COLOR.grey.main,
  },
});

export default tableHeadFilterCellStyles;
