// Core
import { memo } from 'react';
import cx from 'classnames';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import xor from 'lodash/xor';
import { useStyles } from '../../../../../../hooks/useStyles';
import CategoriesTableStyle from '../CategoriesTableStyle';

const RowComponent = (props) => {
  const { selectedRow, setSelectedRow, ...rest } = props;
  const classes = useStyles(CategoriesTableStyle);
  const isSelectedRow = selectedRow.includes(rest.row.id);
  const handleSelectedRow = () => {
    const itemToToggle = rest.row.id;
    const newItems = xor(selectedRow, [itemToToggle]);
    setSelectedRow(newItems);
  };

  return (
    <VirtualTable.Row
      {...rest}
      onClick={handleSelectedRow}
      className={cx({ [classes.activeColor]: isSelectedRow })}
    />
  );
};

export default memo(RowComponent);
