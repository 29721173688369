// Core
import { Map } from 'immutable';
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

// Data
import { formName, formFields } from '../../../../../_helpers/data/pages/settings/configurations/form';

export function* callGetItemByHashIdWorker({ payload }) {
  yield put(actions.mergeItemByHashId({ pending: true }));

  const response = yield apply(api, api.configurations.getItemByHashId, [payload]);

  const data = {
    item: Map(),
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data.item = Map(response.data);

    yield put(initialize(formName, { [formFields.value]: response.data.value }, [formFields.value]));
  }

  yield put(actions.mergeItemByHashId(data));
}
