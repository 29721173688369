import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const baseUrl = process.env.REACT_APP_API;
const apiVersion = process.env.REACT_APP_API_VERSION;
const isSaveMissingTranslate = process.env.REACT_APP_SAVE_MISSING_TRANSLATE === 'true';
const isI18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';

export const locale = Object.freeze({
  ua: 'uk',
  ru: 'ru',
  uk: 'ua',
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('language'),
    preload: [locale.uk, locale.ru],
    debug: isI18nDebug,
    fallbackLng: locale.uk,
    saveMissing: isSaveMissingTranslate,
    nsSeparator: false,
    keySeparator: false,
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
      wait: false,
    },
    backend: {
      loadPath: `${__dirname}../../build/locales/{{lng}}/{{ns}}.json`,
      addPath: `${baseUrl}/api/${apiVersion}/translates`,
    },
  });

export default i18n;
