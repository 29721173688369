// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const userActions = Object.freeze({
  setUserInfo(userInfo) {
    return getActionCreator(types.SET_USER_INFO, userInfo);
  },
  setInUserPartnerHashId(hashId) {
    return getActionCreator(types.SET_USER_PARTNER_HASH_ID, hashId);
  },
  mergePutUserInfo(pendingStatus) {
    return getActionCreator(types.MERGE_IN_PUT_USER_INFO, pendingStatus);
  },
  mergePostUserInfo(data) {
    return getActionCreator(types.MERGE_POST_IN_USER_INFO, data);
  },
  mergeInTwoFactorAuthInfo(twoFactorAuthInfo) {
    return getActionCreator(types.MERGE_TWO_FACTOR_AUTH_INFO, twoFactorAuthInfo);
  },
  mergeInConfirmCode(pendingStatus) {
    return getActionCreator(types.MERGE_IN_CONFIRM_CODE, pendingStatus);
  },
  mergeUserAdd(data) {
    return getActionCreator(types.MERGE_USER_ADD, data);
  },
  mergeMailSettings(data) {
    return getActionCreator(types.MERGE_MAIL_SETTINGS, data);
  },
  mergePutUsersHashId(data) {
    return getActionCreator(types.MERGE_PUT_USERS_HASH_ID, data);
  },
  mergeGetPartnerRoles(data) {
    return getActionCreator(types.MERGE_GET_USERS_PARTNER_ROLES, data);
  },
  mergeInUserInfo(data) {
    return getActionCreator(types.MERGE_IN_USER_INFO, data);
  },
  mergeUserSettings(userSettings) {
    return getActionCreator(types.MERGE_USER_SETTINGS, userSettings);
  },
  setUsersRoles(usersRoles) {
    return getActionCreator(types.SET_USERS_ROLES, usersRoles);
  },
  mergeInUserByHashId(userByHashId) {
    return getActionCreator(types.MERGE_IN_USER_BY_HASH_ID, userByHashId);
  },
  setUserConfiguration(configuration) {
    return getActionCreator(types.SET_USER_CONFIGURATIONS, configuration);
  },
  setUserSubmitting(submitting) {
    return getActionCreator(types.SET_USER_SUBMITTING, submitting);
  },
  setUserNotifications(data) {
    return getActionCreator(types.MERGE_USER_NOTIFICATIONS, data);
  },
});
