// Core
import {
  memo, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
// Parts
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '../../../TextField/TextField/TextField';
// Icons
import SearchOutlinedIcon from '../../../../../icons/SearchOutlined';
// Helpers
import { COLOR } from '../../../../../containers/App/AppStyles';

const SearchField = (props) => {
  const {
    color, searchMeta,
    onClearSearch, value, onChange, dataTestId,
  } = props;
  const { t } = useTranslation();
  const [shrink, setShrink] = useState(false);

  const handleClearSearch = (e) => {
    setShrink(false);
    onClearSearch(e);
  };

  const combineInput = useMemo(() => ({
    value,
    onChange,
    onBlur: (e) => {
      e.stopPropagation();
      setShrink(false);
    },
    onMouseDown: (e) => {
      e.stopPropagation();
      setShrink(true);
    },
    onKeyDown: (e) => {
      if (e.key !== 'Enter') {
        e.stopPropagation();
      }
    },
    onFocus: () => {
      setShrink(true);
    },
  }), [value, onChange]);

  const endAdornment = value?.length ? (
    <InputAdornment position="end" sx={{ transform: 'scale(0.7)' }}>
      <IconButton
        onClick={handleClearSearch}
        component="span"
        size="small"
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <Box sx={{
      position: 'relative',
      borderBottom: `1px solid ${COLOR.grey['12']}`,
      padding: '14px 20px',
      '& input.MuiInputBase-input': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      '& label.MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(30px, 9px) scale(1)',
      },
    }}
    >
      {(!shrink && !value?.length) && (
        <SearchOutlinedIcon sx={{
          fill: '#0000009B', position: 'absolute', left: '28px', top: '25px',
        }}
        />
      )}
      <TextField
        fullWidth
        autoFocus={!isMobile}
        label={t('Поиск')}
        variant="outlined"
        color={color}
        validation
        meta={searchMeta}
        input={combineInput}
        endAdornment={endAdornment}
        dataTestId={dataTestId}
      />
    </Box>
  );
};

export default memo(SearchField);
