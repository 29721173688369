// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

// config
import { selectors } from '../../../../../../../../engine/config/selectors';

// form
import { messageFormName } from '../../../form';

export function useValidateMessageGroup() {
  const messageUserTypesByTypeItems = useSelector(selectors.messages.messageUserTypesByTypeItems);
  const userGroup = useSelector(selectors.messages.userGroup);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, messageFormName));

  return useMemo(() => {
    const groups = messageUserTypesByTypeItems.toJS();
    const fields = getFormValues.toJS();
    const types = userGroup.toJS();
    const isHasMessageUsers = !isEmpty(groups) && !isEmpty(fields) && !isEmpty(fields.messageUsers);
    const filterByType = isHasMessageUsers ? filter(fields.messageUsers, (data) => {
      const groupByType = groups[data.type];
      return groupByType && !isEmpty(groupByType);
    }) : [];
    const isDuplicate = uniqBy(filterByType, 'type').length !== filterByType.length;
    const isValidGroup = !isDuplicate === !isEmpty(fields) && !isEmpty(fields.messageUsers) && fields.messageUsers.length < types.length;

    return { isValidGroup };
  }, [messageUserTypesByTypeItems, getFormValues, userGroup]);
}
