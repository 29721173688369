import React, { useMemo } from 'react';
import cx from 'classnames';
// Parts
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../../../../../../Buttons/ButtonWithIcon/ButtonWithIcon';
import Select from '../../../../../../_Form/Selects/Select/Select';

// Styles
import { useStyles } from '../../../../../../../hooks/useStyles';
import CustomPaginPanelStyles from './CustomPaginPanelStyles';

const CustomPaginPanel = (props) => {
  const classes = useStyles(CustomPaginPanelStyles);
  const {
    onCurrentPageChange, currentPage, pageSize, pageSizes, onPageSizeChange, rowsLength,
  } = props;
  const { t } = useTranslation();
  const setFirst = () => onCurrentPageChange(0);
  const setDec = () => onCurrentPageChange(currentPage - 1);
  const setInc = () => onCurrentPageChange(currentPage + 1);

  const options = useMemo(() => pageSizes.map(value => ({ label: value.toString(), value })), [pageSizes]);

  const handleChange = value => onPageSizeChange(value.value);
  return (
    <div className={classes.paginContainer}>
      <div className={cx(classes.offsetSelect, classes.quantityItemsSelect)}>
        <InputLabel
          classes={{ root: classes.selectLabel }}
          htmlFor="customPaginOffsetSelect"
        >
          {t('Выводить по')}
        </InputLabel>
        <Select
          inputId="customPaginOffsetSelect"
          variant="outlined"
          filterValueBy="value"
          options={options}
          value={pageSize}
          handleChange={handleChange}
        />
      </div>
      <ButtonWithIcon
        variant="text"
        className={cx(classes.btn, classes.btnFirsPage)}
        onClick={setFirst}
        disabled={currentPage === 0}
      >
        <FirstPageIcon />
      </ButtonWithIcon>
      <ButtonWithIcon
        variant="text"
        className={cx(classes.btn, classes.btnPreviousPage)}
        onClick={setDec}
        disabled={currentPage === 0}
      >
        <ArrowBackIosIcon />
      </ButtonWithIcon>
      <ButtonWithIcon
        variant="text"
        className={classes.btn}
        onClick={setInc}
        disabled={rowsLength < pageSize}
      >
        <ArrowForwardIosIcon />
      </ButtonWithIcon>
    </div>
  );
};

export default CustomPaginPanel;
