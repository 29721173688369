// Core
import { Map, List } from 'immutable';
// Engine
import isNil from 'lodash/isNil';
import { pageSizes } from '../../config/globalConfig';
// Instruments
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  notificationsList: Map({
    items: List(),
    offset: 0,
    offsetNotRead: 0,
    pending: false,
    totalCount: 0,
    currentPage: 0,
    filters: List(),
    sorting: List(),
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    selection: List(),
  }),
  notificationsOpenTabNumber: 0,
  notificationsReadArray: List(),
  notificationByHashId: Map({
    pending: false,
    notification: Map(),
  }),
  notificationsGroups: Map({
    pending: true,
    item: List(),
  }),
  notificationsTypes: Map({
    pending: true,
    item: List(),
  }),
});

export const stateKeys = {
  controller: 'notifications',
  notificationsList: 'notificationsList',
  notificationsOpenTabNumber: 'notificationsOpenTabNumber',
  notificationsReadArray: 'notificationsReadArray',
  notificationsGroups: 'notificationsGroups',
  notificationsTypes: 'notificationsTypes',
  offset: 'offset',
  offsetNotRead: 'offsetNotRead',
  pending: 'pending',
  items: 'items',
  totalCount: 'totalCount',
  currentPage: 'currentPage',
  filters: 'filters',
  sorting: 'sorting',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  notificationByHashId: 'notificationByHashId',
  notification: 'notification',
  selection: 'selection',
  item: 'item',
};

export const notificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_NOTIFICATION_READ_TO_ARRAY: {
      const newLists = state.get(stateKeys.notificationsReadArray).toJS().concat([payload]);
      const newListsNoDuplicate = [...new Set(newLists)];

      return state.set(stateKeys.notificationsReadArray, List(newListsNoDuplicate));
    }
    case types.SET_NOTIFICATIONS_CENTER_TAB: {
      return state.setIn([stateKeys.notificationsOpenTabNumber], payload);
    }
    case types.CLEAR_NOTIFICATION_READ_TO_LISTS: {
      return state.set(stateKeys.notificationsReadArray, List());
    }
    case types.SET_NOTIFICATION_SELECTION: {
      return state.setIn([stateKeys.notificationsList, stateKeys.selection], List(payload));
    }
    case types.MERGE_NOTIFICATIONS_LISTS: {
      return state.mergeIn([stateKeys.notificationsList], payload);
    }
    case types.SET_NOTIFICATIONS_LISTS_PENDING: {
      return state.setIn([stateKeys.notificationsList, stateKeys.pending], payload);
    }
    case types.SET_NOTIFICATIONS_LISTS_OFFSET: {
      return state.setIn([stateKeys.notificationsList, stateKeys.offset], payload);
    }
    case types.MERGE_NOTIFICATION_GROUPS: {
      return state.mergeIn([stateKeys.notificationsGroups], Map(payload));
    }
    case types.MERGE_NOTIFICATION_TYPES: {
      return state.mergeIn([stateKeys.notificationsTypes], Map(payload));
    }
    case types.SET_NOTIFICATIONS_LISTS_OFFSET_NOT_READ: {
      return state.setIn([stateKeys.notificationsList, stateKeys.offsetNotRead], payload);
    }
    case types.SET_NOTIFICATIONS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.notificationsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.notificationsList, stateKeys.pending], pending)
        .setIn([stateKeys.notificationsList, stateKeys.items], List(items))
        .setIn([stateKeys.notificationsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_NOTIFICATIONS_SORTING: {
      return state.setIn([stateKeys.notificationsList, stateKeys.sorting], List(payload));
    }
    case types.SET_NOTIFICATIONS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.notificationsList, stateKeys.filters], List(filters));
    }
    case types.SET_NOTIFICATIONS_PAGE_SIZE: {
      return state.setIn([stateKeys.notificationsList, stateKeys.pageSize], payload);
    }
    case types.SET_NOTIFICATIONS_CURRENT_PAGE: {
      return state.setIn([stateKeys.notificationsList, stateKeys.currentPage], payload);
    }
    case types.SET_NOTIFICATIONS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.notificationsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.notificationsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.notificationsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.notificationsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.notificationsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.notificationsList, stateKeys.reload], reload)
        .setIn([stateKeys.notificationsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_NOTIFICATIONS_SETTING_STATUS: {
      return state.setIn([stateKeys.notificationsList, stateKeys.settingStatus], payload);
    }
    case types.SET_NOTIFICATIONS_SETTING_PENDING: {
      return state.setIn([stateKeys.notificationsList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_NOTIFICATION_BY_HASH_ID: {
      return state.set(stateKeys.notificationByHashId, Map(payload));
    }
    default: {
      return state;
    }
  }
};
