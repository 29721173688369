// Core
import React from 'react';

function FileXlsxIcon(props) {
  return (
    <svg width="29" height="38" viewBox="0 0 29 38" fill="none" fontSize="inherit" {...props}>
      <g clipPath="url(#clip0_1905_498)">
        <path d="M21.025 0L29 9.12V36.86C29 37.4908 28.4887 38 27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V1.14C0 0.5092 0.511316 0 1.14474 0H21.025Z" fill="#EBE8E1" />
        <path d="M21.025 0V8.74L29 9.12L21.025 0Z" fill="#D9D7CA" />
        <path d="M27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V26.22H29V36.86C29 37.4908 28.4887 38 27.8553 38Z" fill="#4D9E57" />
        <path d="M12 10C10.8954 10 10 10.8954 10 12V22C10 23.1046 10.8954 24 12 24H18C19.1046 24 20 23.1046 20 22V14.4142C20 14.0164 19.842 13.6349 19.5607 13.3536L16.6464 10.4393C16.3651 10.158 15.9836 10 15.5858 10H12ZM11 12C11 11.4477 11.4477 11 12 11H15V13.5C15 14.3284 15.6716 15 16.5 15H19V22C19 22.5523 18.5523 23 18 23H12C11.4477 23 11 22.5523 11 22V12ZM18.7929 14H16.5C16.2239 14 16 13.7761 16 13.5V11.2071L18.7929 14Z" fill="#303030" fillOpacity="0.72" />
      </g>
      <path d="M4 35L5.95703 32.0117L4.18359 29.2734H5.53516L6.68359 31.1133L7.80859 29.2734H9.14844L7.36719 32.0547L9.32422 35H7.92969L6.66016 33.0195L5.38672 35H4Z" fill="white" />
      <path d="M9.94922 35V29.3203H11.1055V34.0352H13.9805V35H9.94922Z" fill="white" />
      <path
        d="M14.5156 33.1367L15.6406 33.0273C15.7083 33.4049 15.8451 33.6823 16.0508 33.8594C16.2591 34.0365 16.5391 34.125 16.8906 34.125C17.263 34.125 17.543 34.0469 17.7305 33.8906C17.9206 33.7318 18.0156 33.5469 18.0156 33.3359C18.0156 33.2005 17.9753 33.0859 17.8945 32.9922C17.8164 32.8958 17.6784 32.8125 17.4805 32.7422C17.3451 32.6953 17.0365 32.612 16.5547 32.4922C15.9349 32.3385 15.5 32.1497 15.25 31.9258C14.8984 31.6107 14.7227 31.2266 14.7227 30.7734C14.7227 30.4818 14.8047 30.2096 14.9688 29.957C15.1354 29.7018 15.3737 29.5078 15.6836 29.375C15.9961 29.2422 16.3724 29.1758 16.8125 29.1758C17.5312 29.1758 18.0716 29.3333 18.4336 29.6484C18.7982 29.9635 18.9896 30.3841 19.0078 30.9102L17.8516 30.9609C17.8021 30.6667 17.6953 30.4557 17.5312 30.3281C17.3698 30.1979 17.1263 30.1328 16.8008 30.1328C16.4648 30.1328 16.2018 30.2018 16.0117 30.3398C15.8893 30.4284 15.8281 30.5469 15.8281 30.6953C15.8281 30.8307 15.8854 30.9466 16 31.043C16.1458 31.1654 16.5 31.293 17.0625 31.4258C17.625 31.5586 18.0404 31.6966 18.3086 31.8398C18.5794 31.9805 18.7904 32.1745 18.9414 32.4219C19.0951 32.6667 19.1719 32.9701 19.1719 33.332C19.1719 33.6602 19.0807 33.9674 18.8984 34.2539C18.7161 34.5404 18.4583 34.7539 18.125 34.8945C17.7917 35.0326 17.3763 35.1016 16.8789 35.1016C16.1549 35.1016 15.599 34.9349 15.2109 34.6016C14.8229 34.2656 14.5911 33.7773 14.5156 33.1367Z"
        fill="white"
      />
      <path d="M19.5625 35L21.5195 32.0117L19.7461 29.2734H21.0977L22.2461 31.1133L23.3711 29.2734H24.7109L22.9297 32.0547L24.8867 35H23.4922L22.2227 33.0195L20.9492 35H19.5625Z" fill="white" />
      <defs>
        <clipPath id="clip0_1905_498">
          <rect width="29" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FileXlsxIcon;
