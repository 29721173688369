// Core
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment-timezone';
// Engine
import { dateStringFormat } from '../engine/config/globalConfig';
import i18n, { locale } from '../engine/init/i18n';

export function getDate(date, format = 'DD.MM.YYYY HH:mm', dateLocale = 'uk') {
  return moment(date).locale(dateLocale).format(format);
}
export function getDateWithoutHours(date, format = 'DD.MM.YYYY', dateLocale = 'uk') {
  return moment(date).locale(dateLocale).format(format);
}

export const getDateWithCurrentLocal = (data = '', format = dateStringFormat) => {
  const currentLocale = locale[i18n.language];
  if (data !== '') {
    return getDate(data, format, currentLocale);
  }
  return data;
};
export function getDateTimezone(date) {
  return moment.tz(date, 'Europe/Kyiv').format();
}

export function getDateFnsObjectByLang(lang) {
  let currentLocale;
  switch (locale[lang]) {
    case locale.ru: {
      currentLocale = require('date-fns/locale/ru/index.js'); // eslint-disable-line
      break;
    }
    default: {
      currentLocale = require('date-fns/locale/uk/index.js'); // eslint-disable-line
    }
  }
  return currentLocale;
}
