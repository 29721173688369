// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// Icons
import CloseIcon from '@mui/icons-material/Close';
// Components
import ContainedPrimaryButton from '../../../Buttons/ButtonContainedPrimary';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { asyncActions } from '../../../../../engine/core/content/saga/asyncActions';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

function RemoveProductFeatureButton(props) {
  const { categoryId, featureId } = props;
  const hasProductSettingEditAccess = useAccessList(accessList.productSettingEdit);
  const dispatch = useDispatch();

  if (!hasProductSettingEditAccess) {
    return null;
  }

  const onClick = () => {
    dispatch(asyncActions.putProductsSettingsAsync({ categoryId, featureIdForDelete: featureId }));
  };

  return (
    <ContainedPrimaryButton
      onClick={onClick}
      size="small"
      text={<CloseIcon />}
    />
  );
}

RemoveProductFeatureButton.propTypes = {
  categoryId: PropTypes.number.isRequired,
  featureId: PropTypes.number.isRequired,
};

export default RemoveProductFeatureButton;
