// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Parts
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
// Hooks
import { useMessageReadOrEditPage } from '../../../../../_hooks/useMessageReadOrEditPage';

function ControlButtonsRemove(props) {
  const {
    index, fields,
  } = props;
  const { isReadMessage } = useMessageReadOrEditPage();
  const { t } = useTranslation();

  const handleRemove = () => {
    fields.remove(index);
  };

  return fields.length === 1 ? null : (
    <Tooltip
      title={t('Удалить')}
      arrow
      placement="top"
    >
      <Button
        onClick={handleRemove}
        disabled={isReadMessage}
      >
        <DeleteOutlineOutlinedIcon />
      </Button>
    </Tooltip>
  );
}

ControlButtonsRemove.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(ControlButtonsRemove);
