// Core
import React, { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useStyles } from '../hooks/useStyles';

const styles = () => ({
  tooltip: {
    cursor: 'pointer',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  logisticTooltip: {
    maxWidth: '200px',
    '&.MuiTooltip-tooltip': {
      padding: '12px',
    },
    '&.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltip': {
      margin: '4px 30px 0 0 !important',
    },
  },
  headerIconMargin: {
    marginLeft: 5,
  },
  popper: {
    zIndex: '1200',
  },
});

function TooltipIcon(props) {
  const classes = useStyles(styles);
  const {
    children, text, onTooltipClick, noMaxWidth, headerIconMargin, logisticTooltip,
  } = props;
  return text ? (
    <Tooltip
      enterTouchDelay={0}
      classes={{ tooltip: cx({ [classes.noMaxWidth]: noMaxWidth, [classes.logisticTooltip]: logisticTooltip }), popper: classes.popper }}
      className={cx({
        [classes.tooltip]: onTooltipClick,
        [classes.headerIconMargin]: headerIconMargin,
      })}
      onClick={onTooltipClick}
      title={text}
      arrow
    >
      {children}
    </Tooltip>
  ) : <>{children}</>;
}

TooltipIcon.propTypes = {
  noMaxWidth: PropTypes.bool,
  logisticTooltip: PropTypes.bool,
  headerIconMargin: PropTypes.bool,
  children: PropTypes.element,
  onTooltipClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.node]),
};

export default memo(TooltipIcon);
