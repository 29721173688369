import { getIn, List, Map } from 'immutable';
import { stateKeys as notificationsKeys } from './reducers';
import { pageSizes } from '../../config/globalConfig';

export const notification = Object.freeze({
  notificationsList: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList], Map()),
  notificationsPending: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.pending], false),
  notificationsListItems: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.items], List()),
  notificationsListOffset: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.offset], 0),
  notificationsListOffsetNotRead: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.offsetNotRead], 0),
  notificationsReadArray: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsReadArray], List()),
  notificationsOpenTabNumber: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsOpenTabNumber], 0),
  currentPage: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.currentPage], 0),
  filters: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.filters], List()),
  isLoading: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.pending], false),
  list: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.items], List()),
  pageSize: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.reload], false),
  totalCount: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.totalCount], 0),
  sorting: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.sorting], List()),
  settingStatus: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.settingStatus], false),
  settingPending: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.settingPending], false),
  notificationByHashId: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationByHashId, notificationsKeys.notification]),
  isNotificationByHashIdLoading: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationByHashId, notificationsKeys.pending], false),
  selection: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsList, notificationsKeys.selection], List()),
  notificationsGroups: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsGroups, notificationsKeys.item], List()),
  notificationsTypes: state => getIn(state, [notificationsKeys.controller, notificationsKeys.notificationsTypes, notificationsKeys.item], List()),
});
