// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form, Field, reduxForm, submit,
} from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Modal from '../../../../containers/Modals/Modal/Modal';
import FilesUploader from '../../../../components/_Form/FilesUploader/FilesUploader';
// Engine
import { validators } from '../../../../../_helpers/validationsForm';
import { mimeTypeExtensions } from '../../../../../engine/config/mimeTypeExtensions';
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/categoriesFeatureManagement/actions';
import { asyncActions } from '../../../../../engine/core/categoriesFeatureManagement/saga/asyncActions';
// Style
import FormHelperTextComponent from '../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../engine/config/userRoles';

export const form = Object.freeze({
  name: 'CategoryFeatureImportModal',
  fields: Object.freeze({
    file: 'file',
  }),
});

function CategoryFeatureImportModal(props) {
  const {
    handleSubmit, pending,
    pristine, valid, submitting,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdminRole = useUserSomeRole([userRoles.admin]);
  const categoriesFeaturesImportPending = useSelector(selectors.categoriesFeatureManagement.importPending);
  const importIsOpen = useSelector(selectors.categoriesFeatureManagement.importIsOpen);

  const handleModalClose = () => {
    dispatch(actions.mergeCategoriesFeatureImport({ isOpen: false }));
  };
  const onFormSubmit = (values) => {
    dispatch(asyncActions.postCategoriesFeatureImport(values.toJS()));
  };
  const handleModalSend = () => dispatch(submit(form.name));

  if (!isAdminRole) {
    return null;
  }

  return (
    <Modal
      title={t('Импорт характеристик')}
      modalOpen={importIsOpen.toJS ? importIsOpen.toJS() : importIsOpen}
      handleModalToggle={handleModalClose}
      modalMaxWidth="xs"
      fullWidth
      disabledSend={pristine || submitting || categoriesFeaturesImportPending || !valid}
      loadingSend={categoriesFeaturesImportPending}
      handleModalSend={handleModalSend}
      disabledClose={categoriesFeaturesImportPending}
      handleModalCancel={handleModalClose}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={renderFilesUploader}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.file}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

function renderFilesUploader(fieldRenderProps) {
  const {
    meta: { touched, error }, disabled, ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

CategoryFeatureImportModal.propTypes = {
  handleSubmit: PropTypes.func,
  pending: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
};

const reduxFormConfig = {
  form: form.name,
};

export default reduxForm(reduxFormConfig)(CategoryFeatureImportModal);
