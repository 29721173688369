// Core
import { put, select } from 'redux-saga/effects';
import findIndex from 'lodash/findIndex';

// Engine
import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';
import { selectors } from '../../../../../config/selectors';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(lists || {});
  const partner = yield select(selectors.reports.partnerId);
  const createdAt = yield select(selectors.reports.createdAt);

  const partnerIndex = findIndex(initialPageState.filters, { columnName: 'partner' });
  const createdAtIndex = findIndex(initialPageState.filters, { columnName: 'createdAt' });
  if (createdAtIndex < 0) {
    initialPageState.filters.push({ columnName: 'createdAt', value: createdAt });
  } else {
    initialPageState.filters[createdAtIndex].value = createdAt;
  }
  if (partnerIndex < 0) {
    initialPageState.filters.push({ columnName: 'partner', value: partner });
  } else {
    initialPageState.filters[partnerIndex].value = partner;
  }
  yield put(actions.setInitialState(initialPageState));
}
