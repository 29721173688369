// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { actions as contentProductActions } from '../../../contentProduct/actions';

// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callSendProductsToModerationWorker({ payload }) {
  yield put(actions.sendProductsToModeration(true));
  const response = yield apply(api, api.products.putProducts, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;
    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(contentProductActions.setSelection([]));
        yield put(contentProductActions.setSendQuery(true));
        break;
      }
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
    yield put(actions.sendProductsToModeration(false));
  }
}
