// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_CURRENT_PAGE, currentPage);
  },
  setReload(reload) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_RELOAD, reload);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_PRICE_CREATOR_FILES_SETTING_PENDING, settingPending);
  },
  setDataXmlDownloadPending(isLoading) {
    return getActionCreator(types.SET_PRICE_CREATOR_DATA_XML_DOWNLOAD_PENDING, isLoading);
  },
  setDataXmlDownloadModalActive(isActivated) {
    return getActionCreator(types.SET_DATA_XML_DOWNLOAD_MODAL_ACTIVE, isActivated);
  },
});
