export const types = Object.freeze({
  SET_TRANSACTION_HISTORY_LIST: 'SET_TRANSACTION_HISTORY_LIST',
  SET_TRANSACTION_HISTORY_CURRENT_PAGE: 'SET_TRANSACTION_HISTORY_CURRENT_PAGE',
  SET_TRANSACTION_HISTORY_FILTERS: 'SET_TRANSACTION_HISTORY_FILTERS',
  MERGE_TRANSACTION_HISTORY_LIST: 'MERGE_TRANSACTION_HISTORY_LIST',
  SET_TRANSACTION_HISTORY_PAGE_SIZE: 'SET_TRANSACTION_HISTORY_PAGE_SIZE',
  SET_TRANSACTION_HISTORY_SORTING: 'SET_TRANSACTION_HISTORY_SORTING',
  SET_TRANSACTION_HISTORY_INITIAL_STATE: 'SET_TRANSACTION_HISTORY_INITIAL_STATE',
  SET_TRANSACTION_HISTORY_SETTING_STATUS: 'SET_TRANSACTION_HISTORY_SETTING_STATUS',
  SET_TRANSACTION_HISTORY_SETTING_PENDING: 'SET_TRANSACTION_HISTORY_SETTING_PENDING',
});
