export const types = Object.freeze({
  DELETE_USERS: 'DELETE_USERS',
  SET_USERS_LIST: 'SET_USERS_LIST',
  SET_USERS_CURRENT_PAGE: 'SET_USERS_CURRENT_PAGE',
  SET_USERS_FILTERS: 'SET_USERS_FILTERS',
  SET_USERS_PAGE_SIZE: 'SET_USERS_PAGE_SIZE',
  SET_USERS_SORTING: 'SET_USERS_SORTING',
  SET_USERS_SELECTION: 'SET_USERS_SELECTION',
  SET_USERS_INITIAL_STATE: 'SET_USERS_INITIAL_STATE',
  SET_USERS_SETTING_STATUS: 'SET_USERS_SETTING_STATUS',
  SET_USERS_SETTING_PENDING: 'SET_USERS_SETTING_PENDING',
  MERGE_DOWNLOAD_USERS: 'MERGE_DOWNLOAD_USERS',
});
