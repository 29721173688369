// Core
import React from 'react';
import { Navigate } from 'react-router-dom';
// Components
import CharacteristicEdit from './CategoriesFeatureEdit/CharacteristicEdit';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';

function CategoriesFeatureEditPage() {
  const hasAccessToThePage = useAccessList(accessList.categoriesFeatureManagementEdit);

  if (hasAccessToThePage) {
    return <CharacteristicEdit mode="edit" />;
  }

  return <Navigate to={pageLinks.productsAndPrices.products} />;
}

export default CategoriesFeatureEditPage;
