// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';

export function* callGetUsersContextWorker({
  payload: {
    itemsWithAdmin,
    idRole = '',
    context = 'select',
    storeContextAndRole,
  },
}) {
  yield put(partnerActions.setUsersContext({ pending: true }));
  const response = yield apply(api, api.user.getUsersList, [{ context, idRole }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { items } = response.data;
    if (storeContextAndRole) {
      yield put(partnerActions.setUsersContextAndRole({ items, idRole, context }));
    } else {
      // TODO: в идеале обернуть data.items в immutable List, но есть места где используется без List
      yield put(partnerActions.setUsersContext({ [itemsWithAdmin ? 'itemsWithAdmin' : 'items']: items }));
    }
  }
  yield put(partnerActions.setUsersContext({ pending: false }));
}
