import red from '@mui/material/colors/red';

const UserNotificationsMessageStyle = theme => ({
  container: {
    width: '80%',
    maxWidth: 700,
  },
  heading: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      fontSize: 30,
      marginTop: theme.spacing(2),
    },
  },
  description: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& a': {
      color: red[500],
    },
  },
});

export default UserNotificationsMessageStyle;
