// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Icons
import SettingsIcon from '@mui/icons-material/Settings';
// Parts
import ButtonWithIconAndCircularProgress from '../../../../Buttons/ButtonWithIconAndCircularProgress';

function GridSettingsButton(props) {
  const {
    settingStatus, settingPending, putSetting, text, color, blockVariant, icon,
  } = props;
  const { t } = useTranslation();
  const handleOpen = (e) => {
    document.getElementById('main-wrapper').style.overflow = 'hidden';
    putSetting(e);
    setTimeout(() => {
      document.getElementById('main-wrapper').style.removeProperty('overflow');
    }, 2000);
  };

  return (
    <ButtonWithIconAndCircularProgress
      disabled={settingPending}
      isLoading={settingPending}
      text={text || t('Настройка сетки')}
      color={color}
      onClick={handleOpen}
      ButtonVariant={!blockVariant && settingStatus ? 'outlined' : 'contained'}
    >
      { icon || <SettingsIcon /> }
    </ButtonWithIconAndCircularProgress>
  );
}

GridSettingsButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  settingStatus: PropTypes.bool,
  settingPending: PropTypes.bool,
  blockVariant: PropTypes.bool,
  putSetting: PropTypes.func.isRequired,
};

GridSettingsButton.defaultProps = {
  settingStatus: false,
  settingPending: false,
};

export default GridSettingsButton;
