// Core
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form/immutable';
// Parts
import FormSelect from '../../../../../../components/_Form/Selects/FormSelect/FormSelect';
// Helpers
import { formFields } from './ProductMultipleEditingForm';
import { validators } from '../../../../../../../_helpers/validationsForm';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { COMPONENTS_TYPES } from '../../../../../../containers/App/AppStyles';

function ProductCategoryField() {
  const isLoading = useSelector(selectors.contentProduct.isCategoriesListLoading);
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList).filter(
    item => item.value !== null,
  );

  return (
    <Field
      name={formFields.category}
      component={FormSelect}
      validate={[validators.required]}
      validation
      hasSearch
      formSelectWidth="100%"
      options={categoriesList}
      isLoading={isLoading}
      color={COMPONENTS_TYPES.SELECT.dark}
    />
  );
}

export default memo(ProductCategoryField);
