// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { notificationsActions } from '../../action';
import { api } from '../../../../config/api';
import { statisticsActions } from '../../../statistics/action';


export function* callGetNotificationsByHashIdWorker({ payload }) {
  yield put(notificationsActions.setNotificationByHashId({ pending: true }));

  const response = yield apply(api, api.notifications.getNotificationsByHashId, [payload]);
  let data = {};

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    const newNotificationsNotReadCount = response.data.notificationsNotRead;
    yield put(statisticsActions.setStatisticsCounterNotificationNotRead(newNotificationsNotReadCount));
  }

  yield put(notificationsActions.setNotificationByHashId({ pending: false, notification: data }));
}
