// Core
import { Map, List, fromJS } from 'immutable';
import isNil from 'lodash/isNil';

// Instruments
import { types } from './types';
import { pageSizes } from '../../config/globalConfig';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  mailTemplateList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    pending: true,
    sorting: List(),
    reload: false,
  }),
  mailTemplatesEvents: [], // TODO set immutable
  mailTemplatesRoles: Map({
    pending: true,
    item: List(),
  }),
  mailTemplatesGroups: Map({
    pending: true,
    item: List(),
  }),
  mailTemplateByHashId: Map({
    pending: true,
    item: Map(),
  }),
  putMailTemplateByHashIdPending: false,
});

export const storeKeys = Object.freeze({
  controller: 'mail',
  mailTemplatesRoles: 'mailTemplatesRoles',
  mailTemplatesGroups: 'mailTemplatesGroups',
  mailTemplateList: 'mailTemplateList',
  mailTemplatesEvents: 'mailTemplatesEvents',
  mailTemplateByHashId: 'mailTemplateByHashId',
  putMailTemplateByHashIdPending: 'putMailTemplateByHashIdPending',
  currentPage: 'currentPage',
  filters: 'filters',
  pageSize: 'pageSize',
  sorting: 'sorting',
  reload: 'reload',
  pending: 'pending',
  item: 'item',
});

export const mailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MAIL_TEMPLATE_LIST: {
      return state.mergeIn([storeKeys.mailTemplateList], fromJS(payload));
    }
    case types.MERGE_MAIL_TEMPLATES_ROLES: {
      return state.mergeIn([storeKeys.mailTemplatesRoles], Map(payload));
    }
    case types.MERGE_MAIL_TEMPLATES_GROUPS: {
      return state.mergeIn([storeKeys.mailTemplatesGroups], Map(payload));
    }
    case types.SET_MAIL_TEMPLATES_EVENTS: {
      return state.set(storeKeys.mailTemplatesEvents, payload);
    }
    case types.MERGE_IN_MAIL_TEMPLATE_BY_HASH_ID: {
      return state.mergeIn([storeKeys.mailTemplateByHashId], Map(payload));
    }
    case types.SET_MAIL_TEMPLATES_CURRENT_PAGE: {
      return state.setIn([storeKeys.mailTemplateList, storeKeys.currentPage], payload);
    }
    case types.SET_MAIL_TEMPLATES_PAGE_SIZE: {
      return state.setIn([storeKeys.mailTemplateList, storeKeys.pageSize], payload);
    }
    case types.SET_MAIL_TEMPLATES_SORTING: {
      const { sorting } = payload;
      return state.setIn([storeKeys.mailTemplateList, storeKeys.sorting], List(sorting));
    }
    case types.SET_MAIL_TEMPLATES_FILTERS: {
      const filters = payload.filters.filter(item => !isNil(item.value));
      return state.setIn([storeKeys.mailTemplateList, storeKeys.filters], List(filters));
    }
    case types.RESET_MAIL_FILTERS: {
      return state.setIn([storeKeys.mailTemplateList, storeKeys.filters], List())
        .setIn([storeKeys.mailTemplateList, storeKeys.currentPage], 0);
    }
    case types.SET_PUT_MAIL_TEMPLATE_BY_HASH_ID_PENDING: {
      return state.set(storeKeys.putMailTemplateByHashIdPending, payload);
    }
    case types.SET_MAIL_TEMPLATES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, sorting = [],
      } = payload;

      const reload = true;

      const currentSorting = sorting.length
        ? List(sorting)
        : initialState.getIn([storeKeys.mailTemplateList, storeKeys.sorting]);

      return state
        .setIn([storeKeys.mailTemplateList, storeKeys.currentPage], currentPage)
        .setIn([storeKeys.mailTemplateList, storeKeys.filters], List(filters))
        .setIn([storeKeys.mailTemplateList, storeKeys.pageSize], pageSize)
        .setIn([storeKeys.mailTemplateList, storeKeys.reload], reload)
        .setIn([storeKeys.mailTemplateList, storeKeys.sorting], currentSorting);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
