// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
// UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import DesktopToMobileDialog from './components/DesktopToMobileDialog';
import HeaderDescriptionAndTooltip from './components/HeaderDescriptionAndTooltip/HeaderDescriptionAndTooltip';

// Style
import PageHeaderStyles from './PageHeaderStyles';

function PageHeader(props) {
  const {
    classes, children, title, titleDescription, titleDescriptionTooltip, subTitle, titleComponent,
    typographyClassName, pageHeadingClassName, text, pageHeadingComponentClassName, childWrapperClassName, wrapperClassName,
  } = props;

  const pageHeading = cx(classes.pageHeading, { [pageHeadingClassName]: pageHeadingClassName });
  const childWrapper = cx(classes.childWrapper, { [childWrapperClassName]: childWrapperClassName });
  const wrapper = cx(classes.wrapper, { [wrapperClassName]: wrapperClassName });
  const pageheaderWrapper = cx(classes.headerWrapper, { [pageHeadingClassName]: pageHeadingClassName });

  return (
    <div className={pageheaderWrapper}>
      <Grid container justifyContent="space-between" alignItems="center" className={wrapper}>
        <div>
          <div className={pageHeading}>
            <Typography variant="h3" className={typographyClassName}>
              <div className={classes.pageHeadingText}>{title}</div>
            </Typography>
            <HeaderDescriptionAndTooltip description={titleDescription} tooltip={titleDescriptionTooltip} />
            {titleComponent && (
              <div
                className={cx(classes.pageHeadingComponent, { [pageHeadingComponentClassName]: pageHeadingComponentClassName })}
              >{titleComponent}
              </div>
            )}
          </div>
          {subTitle && <Typography variant="h6"><span className={classes.pageHeadingSubText}>{subTitle}</span></Typography>}
        </div>
        {children && (
          <div className={childWrapper}>
            <DesktopToMobileDialog fullScreen={false} dialogContentMargin modalMaxWidth="md">
              {children}
            </DesktopToMobileDialog>
          </div>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          {text && <p className={classes.text}>{text}</p>}
        </Grid>
      </Grid>
    </div>
  );
}

PageHeader.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.node]),
  subTitle: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  titleComponent: PropTypes.node,
  titleDescription: PropTypes.string,
  titleDescriptionTooltip: PropTypes.string,
  typographyClassName: PropTypes.string,
  pageHeadingClassName: PropTypes.string,
  childWrapperClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  pageHeadingComponentClassName: PropTypes.string,
};

PageHeader.defaultProps = {
  classes: {},
  title: '',
  typographyClassName: null,
  pageHeadingClassName: null,
};

export default withStyles(PageHeaderStyles)(PageHeader);
