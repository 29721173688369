import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Informer from '../../../components/Informer/Informer';
import { COLOR } from '../../../containers/App/AppStyles';
import { pageLinks } from '../../../../engine/config/routes';
import { selectors } from '../../../../engine/config/selectors';
import { userRoles } from '../../../../engine/config/userRoles';
import { useUserRole } from '../../../hooks/useUserRole';


const ConfigurePaymentsInformer = () => {
  const configurations = useSelector(selectors.user.configurations);
  const bankPendingInformer = configurations.getIn(['PAYMENT_ACCOUNT_CONNECT_INFO', 'configPendingCode']);
  const isPrivatPendingSettings = bankPendingInformer === 'privatbank_bnpl';
  const paymentMethodHashId = configurations.getIn(['PAYMENT_ACCOUNT_CONNECT_INFO', 'hashId']);
  const { t } = useTranslation();
  const isActivePartner = useUserRole(userRoles.partnerActive);

  if (!bankPendingInformer || !isActivePartner) {
    return null;
  }

  return (
    <Informer sx={{ marginBottom: '16px', borderColor: COLOR.error }}>
      <InfoOutlinedIcon sx={{ color: COLOR.error, marginRight: '8px' }} />
      <span><strong>{t('Внимание!')}</strong>&nbsp;
        {t('finish payments settings', { serviceName: isPrivatPendingSettings ? t('Покупка частями Приватбанк') : t('Покупка частями Monobank') })}&nbsp;
        <Link to={`${pageLinks.partner.accessEdit(paymentMethodHashId)}?bankBnpl=1`} style={{ color: COLOR.blue.main, textDecoration: 'underline' }}>{t('configure')}</Link>
      </span>
    </Informer>
  );
};

export default ConfigurePaymentsInformer;
