// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callDeleteWorker,
  callPostWorker,
  callPutItemWorker,
  callGetItemWorker,
  callPostUploadWorker,
  callGetDownload,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC, callGetListWorker);
}
function* watchGetDownload() {
  yield takeEvery(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_DOWNLOAD_ASYNC, callGetDownload);
}
function* watchAddItem() {
  yield takeEvery(asyncTypes.POST_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC, callPostWorker);
}
function* watchPostUpload() {
  yield takeEvery(asyncTypes.POST_STOP_LIST_BRANDS_CATEGORIES_UPLOAD_ASYNC, callPostUploadWorker);
}
function* watchPutItem() {
  yield takeEvery(asyncTypes.PUT_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC, callPutItemWorker);
}
function* watchGetItem() {
  yield takeEvery(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_BY_HASH_ASYNC, callGetItemWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_STOP_LIST_BRANDS_CATEGORIES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchDelete() {
  yield takeEvery(asyncTypes.DELETE_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC, callDeleteWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_STOP_LIST_BRANDS_CATEGORIES_SORTING, callSetToLocalDataWorker);
}

export function* watchersStopListBrandsCategories() {
  yield all([
    watchAddItem(),
    watchGetItem(),
    watchDelete(),
    watchGetList(),
    watchPutItem(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPostUpload(),
    watchGetDownload(),
  ]);
}
