const OrderEditStyle = theme => ({
  tabsContainer: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  tabsContent: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  informerItem: {
    fontSize: 14,
    width: '100%',
    marginBottom: 6,
    padding: '0px 4px',
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'uppercase',
    alignItems: 'center',
  },
  informerItemTimer: {
    fontSize: 20,
    textTransform: 'none',
  },
  informerLink: {
    marginTop: -4,
    display: 'flex',
    marginLeft: 10,
  },
  informerIcon: {
    color: theme.palette.primary.main,
    marginRight: 8,
  },
  statusSelect: {
    marginTop: 29,
    marginBottom: 7,
  },
  // Global Style
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  hasError: {
    color: 'rgb(244, 67, 54)',
  },
});

export default OrderEditStyle;
