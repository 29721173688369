// Core
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Containers
import Modal from '../../../../../containers/Modals/Modal/Modal';
import ContentTemplatesTable from './ContentTemplates/ContentTemplatesTable';
// Hooks
import { useStyles } from '../../../../../hooks/useStyles';
// Style
import { TitleWithIcon } from '../../../styles';
import { CustomCard, CustomCardContent, CardNumber } from './styles';
import { COLOR, BUTTON_SIZE } from '../../../../../containers/App/AppStyles';

const ImportTemplateStyles = () => ({
  radio: {
    padding: '0',
    margin: '2px',
  },
  controlLabel: {
    marginLeft: '-2px',
    gap: '3px',
    marginRight: '32px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: COLOR.black.default,
    },
  },
});

// export const typeValues = {
//   XML: 'XML',
//   XLSX: 'XLSX',
// };

function ImportTemplate(props) {
  const { handleChangeType, type } = props;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles(ImportTemplateStyles);
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <CustomCard>
      <CustomCardContent>
        <TitleWithIcon gutterBottom variant="h6" component="div">
          <CardNumber component="span">1</CardNumber>
          {t('Выберите формат файла')}
        </TitleWithIcon>
        <FormControl>
          {/* <FormLabel> */}
          {/*  <Typography component="p" sx={{ color: '#303030', marginTop: '24px' }}> */}
          {/*    {t('Для начала импорта выберите формат файла')} */}
          {/*  </Typography> */}
          {/* </FormLabel> */}
          <RadioGroup
            row
            value={type}
            name="file_type"
            onChange={handleChangeType}
          >
            <FormControlLabel id="xml_radio_btn" className={classes.controlLabel} sx={{ marginRight: '27px' }} value="XML" control={<Radio className={classes.radio} color="secondary" />} label="XML" />
            <FormControlLabel id="xlsx_radio_btn" className={classes.controlLabel} value="XLSX" control={<Radio className={classes.radio} color="secondary" />} label="XLSX" />
          </RadioGroup>
        </FormControl>
        {type === 'XLSX' && (
          <>
            <Typography component="p" sx={{ color: COLOR.grey['72'], marginTop: '20px', fontSize: '12px' }}>
              {t('Выберите категорию товаров, которую вы планируете скачать — эта информация необходима для того, чтобы сформировать для вас шаблон с необходимыми характеристиками в соответствии с вашей категорией товаров. Сохраните его на своём устройстве, заполните все обязательные характеристики, указанные в шаблоне звездочкой*.')}
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              sx={{ marginTop: '30px', padding: BUTTON_SIZE.outlinedWithStartIconLarge, '& .MuiButton-startIcon svg': { fontSize: '24px' } }}
              onClick={handleModalOpen}
              startIcon={<AddCircleIcon />}
              id="choose_category_btn"
            >
              {t('Выберите категорию')}
            </Button>
          </>
        )}
      </CustomCardContent>
      <Modal
        modalOpen={showModal}
        handleModalToggle={handleModalClose}
        handleModalCancel={handleModalClose}
        modalMaxWidth="md"
        hideButtons
        title={t('Шаблоны импорта товаров')}
      >
        <ContentTemplatesTable />
      </Modal>
    </CustomCard>
  );
}

export default memo(ImportTemplate);
