import { COLOR } from '../../../../containers/App/AppStyles';

const styles = hasError => ({
  '& .MuiFormLabel-root': {
    '&.MuiInputLabel-root': {
      '&.Mui-focused': {
        color: COLOR.blue.main,
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: hasError ? COLOR.error : COLOR.grey[12],
    },
    '&.Mui-focused fieldset': {
      borderColor: hasError ? COLOR.error : COLOR.blue.main,
    },
  },
});


export default styles;
