// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// Engine
import { api } from '../../../../config/api';
import { actions as ordersActions } from '../../actions';

// Data
import { formName, formFields } from '../../../../../ui/page/Orders/OrderEdit/form';

// Helpers
import { getDate } from '../../../../../_helpers/getDate';
import { modifyDataItem } from '../../utils';

export function* callGetOrdersHashIdWorker({ payload: params }) {
  const { hashId, mode, loading = true } = params;

  if (loading) {
    yield put(ordersActions.setOrderItemByHashId({ pending: true, item: {} }));
  }

  const response = yield apply(api, api.orders.getOrdersHashId, [{ hashId }]);
  const data = { pending: false, item: {} };

  if (response && response.status >= 200 && response.status < 400) {
    const { data: responseData } = response;
    const modifiedData = modifyDataItem(responseData);
    data.item = modifiedData;

    if (mode === 'edit') {
      const {
        idMagentoOrder, orderedAt, idMagentoPartner, partner, shippingTrackingNumber, orderStatus, note, customerEmail,
        customerName, customerPhone, shippingType, shippingCity, shippingCityUid, shippingStreet, shippingHouse, cancelStatus,
        shippingApartment, stockName, shippingPrice, paymentStatus, shippedAt, doneAt, canceledAt, information, name, paymentType,
        isSelfDelivery,
      } = data.item;

      const formData = {
        [formFields.idMagentoOrder]: idMagentoOrder,
        [formFields.orderedAt]: formatDatetime(orderedAt),
        [formFields.idMagentoPartner]: idMagentoPartner,
        [formFields.name]: name,
        [formFields.partner]: partner,
        [formFields.shippingTrackingNumber]: shippingTrackingNumber,
        [formFields.orderStatus]: orderStatus.value,
        [formFields.cancelStatus]: cancelStatus,
        [formFields.note]: note,
        [formFields.customerName]: customerName,
        [formFields.customerPhone]: customerPhone,
        [formFields.customerEmail]: customerEmail,
        [formFields.shippingType]: shippingType,
        [formFields.shippingCity]: shippingCity,
        [formFields.shippingCityUid]: shippingCityUid,
        [formFields.shippingStreet]: shippingStreet,
        [formFields.shippingHouse]: shippingHouse,
        [formFields.shippingApartment]: shippingApartment,
        [formFields.stockName]: stockName,
        [formFields.shippingPrice]: shippingPrice,
        [formFields.paymentStatus]: paymentStatus,
        [formFields.shippedAt]: formatDatetime(shippedAt),
        [formFields.doneAt]: formatDatetime(doneAt),
        [formFields.canceledAt]: formatDatetime(canceledAt),
        [formFields.information]: information,
        [formFields.paymentType]: paymentType?.label,
        [formFields.isSelfDelivery]: isSelfDelivery,
      };

      const fields = [
        formFields.idMagentoOrder,
        formFields.orderedAt,
        formFields.idMagentoPartner,
        formFields.partner,
        formFields.shippingTrackingNumber,
        formFields.orderStatus,
        formFields.note,
        formFields.customerName,
        formFields.customerPhone,
        formFields.shippingType,
        formFields.shippingCity,
        formFields.shippingCityUid,
        formFields.shippingStreet,
        formFields.shippingHouse,
        formFields.shippingApartment,
        formFields.stockName,
        formFields.shippingPrice,
        formFields.paymentStatus,
        formFields.shippedAt,
        formFields.doneAt,
        formFields.canceledAt,
        formFields.information,
        formFields.paymentType,
        formFields.isSelfDelivery,
      ];

      yield put(initialize(formName, formData, fields));
    }
  }

  yield put(ordersActions.setOrderItemByHashId(data));
}

function formatDatetime(data) {
  return data ? getDate(data, 'DD.MM.YYYY HH:mm') : '';
}
