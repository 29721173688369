// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import { Map } from 'immutable';

// lodash
import valuesLodash from 'lodash/values';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// Engine
import { actions } from '../../actions';
import { api } from '../../../../config/api';
import { messageFormFields, messageFormName } from '../../../../../ui/page/Settings/Messages/components/form';
import { getDate } from '../../../../../_helpers/getDate';
import { dateStringFormat } from '../../../../config/globalConfig';
import i18n, { locale } from '../../../../init/i18n';

export function* callGetMessageByHashIdWorker({ payload }) {
  yield put(actions.setMessageByHashId({ pending: true }));

  const response = yield apply(api, api.messages.getMessageByHashId, [payload]);
  let data = {};
  let media = [];

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;

    if (data.media) {
      media = [{
        source: data.media.link,
        options: {
          type: 'local',
          file: {
            name: data.media.name,
            size: data.media.size,
            type: data.media.mimeType,
            id: data.media.id,
          },
        },
      }];
    }

    if (!isEmpty(data.messageUsers)) {
      for (let i = 0; i < data.messageUsers.length; i++) {
        const { value, type, values } = data.messageUsers[i];
        if (!isEmpty(values)) {
          const getType = value || type;
          yield put(actions.mergeMessageUserTypesByTypeItems({
            [getType]: values,
          }));
        }
      }
    }

    const filterValues = values => filter(values, value => value.selected);
    const formData = {
      [messageFormFields.text]: data.text,
      [messageFormFields.title]: data.title,
      [messageFormFields.media]: media || [],
      [messageFormFields.messageStatus]: data.messageStatus,
      [messageFormFields.messageUsers]: !isEmpty(data.messageUsers) ? map(data.messageUsers, ({
        values, value, type,
      }) => ({
        [messageFormFields.type]: value || type,
        values: filterValues(values),
      })) : [],
      ...(data.sentAt ? {
        [messageFormFields.sentAt]: getDate(data.sentAt, dateStringFormat, locale[i18n.language]),
      } : {}),
    };

    yield put(initialize(messageFormName, formData, valuesLodash(messageFormFields)));
  }

  yield put(actions.setMessageByHashId({ pending: false, message: Map(data) }));
}
