// Parts
import React, { forwardRef } from 'react';
import NumberFormatCustom from './NumberFormatCustom';

const MAX_NUMBER_LENGTH = 7;
const MAX_FORMATED_LENGTH = 6;
const SEPARATOR = ',';

const NumberFormatWeight = forwardRef(function NumberFormatWeight(props, ref) {
  return (
    <NumberFormatCustom
      {...props}
      getInputRef={ref}
      decimalSeparator=","
      thousandSeparator=""
      decimalScale={2}
      allowNegative={false}
      isAllowed={({ formattedValue }) => {
        const lastComa = formattedValue.length === MAX_NUMBER_LENGTH && formattedValue.endsWith(SEPARATOR);
        return !(formattedValue.startsWith(SEPARATOR) || formattedValue.length > MAX_FORMATED_LENGTH || lastComa);
      }}
    />
  );
});
export default NumberFormatWeight;
