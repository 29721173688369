// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import { Map } from 'immutable';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { formName, formFields } from '../../../../../../ui/page/StopList/BrandsCategories/components/EditModal';
// Helpers
import { getDate } from '../../../../../../_helpers/getDate';

function formatDatetime(data) {
  return data ? getDate(data, 'DD.MM.YYYY HH:mm') : '';
}

export function* callGetItemWorker({ payload }) {
  yield put(actions.mergeEdit({ pending: true }));

  const response = yield apply(api, api.stopLists.getBrandsCategoriesByHashId, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const {
      brand,
      category,
      excludePartners,
      createdAt,
      updatedAt,
    } = response.data;
    const fixedPartners = excludePartners.map(item => ({ value: item.value, label: item.label }));
    const formData = {
      [formFields.brand]: brand,
      [formFields.category]: category,
      [formFields.excludePartners]: fixedPartners,
      [formFields.createdAt]: formatDatetime(createdAt),
      [formFields.updatedAt]: formatDatetime(updatedAt),
    };

    yield put(actions.mergeEdit({ item: Map(response.data) }));
    yield put(initialize(formName, formData, Object.keys(formData)));
  }

  yield put(actions.mergeEdit({ pending: false }));
}
