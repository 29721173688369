// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetFaqsStatusesListWorker() {
  yield put(actions.setFaqStatusesListPending(true));

  const response = yield apply(api, api.faq.getFaqStatuses, []);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setFaqStatusesList(response.data));
  }

  yield put(actions.setFaqStatusesListPending(false));
}
