// Core
import React from 'react';
import { change, submit } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Icons
import SaveIcons from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
// Containers
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Data
import { formName } from '../../../../../_helpers/data/pages/faqs/formFields';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';

function FaqEditSubmitFormButtons(props) {
  const { onButtonClick, hideButton, disabled } = props;
  const dispatch = useDispatch();
  const isItemByHashIdLoading = useSelector(selectors.faqs.isItemByHashIdLoading);
  const { t } = useTranslation();
  const hasAccessFaqSendToModerate = useAccessList(accessList.faqSendToModerate);
  const hasAccessFaqSendToMagento = useAccessList(accessList.faqSendToMagento);
  const hasAccessFaqShow = useAccessList(accessList.faqQuestionShow);
  const hasAccessFaqEdit = useAccessList(accessList.faqQuestionEdit);

  function onClick(str) {
    return () => {
      onButtonClick(str);
      dispatch(change(formName, 'mode', str));
      setTimeout(() => {
        dispatch(submit(formName));
      }, 0);
    };
  }

  if (hideButton || isItemByHashIdLoading || (hasAccessFaqShow && !hasAccessFaqEdit)) {
    return null;
  }

  return (
    <>
      {hasAccessFaqSendToModerate && (
        <>
          <ButtonWithIcon text={t('Отправить на модерацию')} disabled={disabled} component="button" onClick={onClick('moderation')}>
            <SendIcon />
          </ButtonWithIcon>
          <ButtonWithIcon text={t('Сохранить')} disabled={disabled} component="button" onClick={onClick('save')}>
            <SaveIcons />
          </ButtonWithIcon>
        </>
      )}
      {hasAccessFaqSendToMagento && (
        <>
          <ButtonWithIcon text={t('Опубликовать')} disabled={disabled} component="button" onClick={onClick('magento')}>
            <SendIcon />
          </ButtonWithIcon>
          <ButtonWithIcon text={t('Отклонить')} disabled={disabled} component="button" onClick={onClick('reject')}>
            <SaveIcons />
          </ButtonWithIcon>
        </>
      )}
    </>
  );
}

FaqEditSubmitFormButtons.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

FaqEditSubmitFormButtons.defaultProps = {
  disabled: false,
};

export default FaqEditSubmitFormButtons;
