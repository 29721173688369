// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { reset } from 'redux-form/immutable';
import { api } from '../../../../../config/api';
import { setErrorMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { localData } from '../../../../../config/localData';
import { actions } from '../../../actions';
import { formName } from '../../../../../../ui/page/ServiceDesk/components/form';
// Helpers
export function* callPutMessageWorker({ payload }) {
  const { hashId, params } = payload;
  yield put(actions.setMessageIsSending(true));
  const response = yield apply(api, api.serviceDesk.postMessage, [{ hashId, params }]);
  if (response && response.status >= 200 && response.status < 400) {
    const {
      status, statusTitle,
    } = response.data;
    switch (status) {
      case 'success': {
        const multipleModeContext = localData.keysObj.SDAttachedFiles;

        // Сброс прикрепленных файлов
        yield put(actions.setFilesInChatMessage([]));

        // Сброс localForage
        const chatData = yield apply(localData, localData.getItem, [multipleModeContext]);
        if (chatData) {
          delete chatData[hashId];
        }
        yield apply(localData, localData.setItem, [multipleModeContext, chatData]);

        // Сброс поля для ввода
        yield put(reset(formName));

        // Повторное получение сообщений
        yield put(actions.setChatKey(new Date().getTime()));
        break;
      }
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setMessageIsSending(false));
}
