// Core
import {
  apply, put,
} from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPostUpdateWFPWorker({ payload }) {
  const hashId = payload;
  yield put(actions.mergePartnerAccessUpdateWFP({ pending: true }));

  const response = yield apply(api, api.partners.postPaymentAccessDocumentsRegenerateByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(asyncActions.getParentAccessByHashIdAsync(hashId));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergePartnerAccessUpdateWFP({ pending: false }));
}
