// Core
import {
  apply, put, select, delay,
} from 'redux-saga/effects';
import { reset } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { isImmutable } from 'immutable';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { form } from '../../../../../ui/page/Products/LogisticsData/components/LogisticsDataByCategoryImportModal';
import { convertSortingObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';
import { logisticsDataByCategory } from '../../selectors';

export function* callPostLogisticsDataByCategoryImportWorker({ payload }) {
  yield put(actions.mergeLogisticsDataByCategoryImport({ pending: true }));

  const response = yield apply(api, api.logisticsDataByCategory.postLogisticsDataByCategoryImport, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, statusTitle } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = responseData;

        const currentPage = yield select(logisticsDataByCategory.currentPage);
        const limit = yield select(logisticsDataByCategory.pageSize);
        const sorting = (yield select(logisticsDataByCategory.sorting)).toJS();
        const filters = (yield select(logisticsDataByCategory.filters)).toJS();
        const treeDataRowIds = yield select(logisticsDataByCategory.treeDataRowIds);

        const offset = limit * currentPage;
        const filtersStringify = JSON.stringify(filters);
        const sortingStringify = JSON.stringify(sorting);
        const orderBy = convertSortingObjectToUrlParams(JSON.parse(sortingStringify));
        const getTreeDataRowIds = isImmutable(treeDataRowIds) ? treeDataRowIds.toJS() : treeDataRowIds;

        const treeDataRowsWithRoot = !isEmpty(getTreeDataRowIds)
          ? [0, ...getTreeDataRowIds]
          : getTreeDataRowIds;

        const defaultFilters = {
          ...(isEmpty(filters) ? {
            parentId: 0,
          } : {}),
          orderBy: isEmpty(orderBy) ? 'name ASC' : orderBy,
        };

        const paramsByFilter = () => {
          const params = {};
          return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, params);
        };

        const params = {
          ...paramsByFilter(),
          ...defaultFilters,
          forceRoot: !isEmpty(getTreeDataRowIds),
          limit,
          offset,
        };

        yield put(reset(form.name));
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergeLogisticsDataByCategoryImport({ isOpen: false }));
        yield delay(1000);

        yield put(asyncActions.getListAsync(params, treeDataRowsWithRoot));

        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeLogisticsDataByCategoryImport({ pending: false }));
}
