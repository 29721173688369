// Core
import { put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';

export function* callGetFeedsInitialStateWorker({ payload: url }) {
  const feeds = yield localData.getItem(url);
  const initialPageState = getInitialPageState(feeds || {});
  yield put(actions.setFeedsInitialState(initialPageState));
}
