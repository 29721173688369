import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export const CustomCard = styled(Card)`
  margin-bottom: 10px;
  overflow: initial;
`;
export const CustomCardContent = styled(CardContent)`
  padding: 20px;
`;
export const CustomCardBorderContent = styled(CardContent)`
  padding: 20px 20px 20px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const CardNumber = styled(Typography)`
  width: 24px;
  height: 24px;
  background-color: #E6E6E6;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin-right: 10px;
`;
