import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../components/Buttons/ButtonWithIconAndCircularProgress';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import AnalyticsTable from './components/AnalyticsTable';
import { useAnalyticsList } from './_hooks/useAnalyticsList';
import { actions } from '../../../engine/core/analytics/actions';
import { stateKeys, initialState } from '../../../engine/core/analytics/reducer';
import { selectors } from '../../../engine/config/selectors';
import { api, requestStatus } from '../../../engine/config/api';
import { getFileFromBlob } from '../../../_helpers/getFileFromBlob';
import { setErrorMessage, setSuccessMessage } from '../../../engine/core/ui/saga/asyncActionNotificationMessages';


const AnalyticsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useAnalyticsList();
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const filters = useSelector(selectors.analytics.filters).toJS();
  const analyticsList = useSelector(selectors.analytics.list).toJS();
  const selection = useSelector(selectors.analytics.selection).toJS();
  const sorting = useSelector(selectors.analytics.sorting).toJS();

  const disabledResetFiltersButton = filters.length === 0 && sorting.length === 0;

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnersAnalyticsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersAnalyticsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersAnalyticsList).get(stateKeys.currentPage)));
  };

  const handleExport = async () => {
    setExportIsLoading(true);
    const args = selection.length > 0 ? [{ columnName: 'ids', value: selection }] : filters;
    const response = await api.analytics.export(args);
    if (response && response.status >= 200 && response.status < 400) {
      const responseType = typeof response.data;
      let responseData;

      switch (responseType) {
        case 'object': {
          if (!response.data.status) {
            try {
              const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
              responseData = JSON.parse(decodedString);
            } catch (err) {
              responseData = {};
            }
            break;
          }
          responseData = response.data;
          break;
        }
        default: {
          responseData = {};
          break;
        }
      }

      const { status } = responseData;

      switch (status) {
        case requestStatus.error: {
          const { message } = responseData;
          dispatch(setErrorMessage(message, t('Ошибка')));
          break;
        }

        case requestStatus.success: {
          const { message } = responseData;
          dispatch(setSuccessMessage(message, t('Успех')));
          break;
        }

        default: {
          getFileFromBlob('analytics_list.xlsx', response);
          break;
        }
      }
    }
    setExportIsLoading(false);
  };

  return (
    <>
      <PageHeader title={t('Регистрация партнеров')}>

        <span>
          <ButtonWithIconAndCircularProgress
            text={t('Экспорт данных')}
            onClick={handleExport}
            isLoading={exportIsLoading}
            disabled={exportIsLoading || analyticsList.length === 0}
            sx={{ width: '100%' }}
          >
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        </span>
        <ResetFiltersButton onReset={resetFilters} disabled={disabledResetFiltersButton} />
      </PageHeader>
      <AnalyticsTable />
    </>
  );
};


export default AnalyticsPage;
