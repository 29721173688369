import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { List } from 'immutable';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/actions';

const CancelModal = ({ cancelModalOpen, closeCancelModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(actions.setFeatureRequiresLK(List()));
    closeCancelModal();
  };

  return (
    <Modal
      modalOpen={cancelModalOpen}
      handleModalToggle={closeCancelModal}
      handleModalCancel={closeCancelModal}
      title={t('Отмена изменений')}
      modalMaxWidth="sm"
      sendText={t('Отменить изменения')}
      cancelText={t('Вернуться')}
      buttonSendIcon={null}
      handleModalSend={handleCancel}
    >
      <Box>
        <Trans components={{ bold: <strong /> }}>Вы внесли несколько изменений в настройки обязательности характеристик. Если вы отмените эти изменения, они не будут сохранены.</Trans>
      </Box>
    </Modal>
  );
};

CancelModal.propTypes = {
  cancelModalOpen: PropTypes.bool,
  closeCancelModal: PropTypes.func,
};

export default CancelModal;
