import { COLOR } from '../App/AppStyles';

const TabsStyles = props => ({
  tabsMenu: {
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderRight: `1px solid ${COLOR.grey['8']}`,
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabsMenuSticky: {
    position: 'sticky',
    top: 0,
  },
  tabsNavMenu: {
    padding: '20px 10px',
    borderBottom: `1px solid ${COLOR.grey['8']}`,
  },
  tabsContent: {
    padding: props.padding ? 0 : 24,
  },
  tabsContentWrapper: {
    width: '100%',
  },
  tabsContainer: {
    backgroundColor: 'white',
    boxShadow: COLOR.shadow.block.main,
  },
});

export default TabsStyles;
