// Parts
import Box from '@mui/material/Box';
import OrderEditLink from '../../OrderEditLink';
import OrderShowLink from '../../OrderShowLink';
import OrderPaymentTooltip from '../../OrderPaymentTooltip';

const CellComponent = ({ tableRow }) => {
  const {
    hashId, editable, canUnbindShippingTracking, paymentType,
  } = tableRow.row;

  const isInstallmentsPayment = paymentType?.typeId === 'monobank_payment_bnpl' || paymentType?.typeId === 'privatbank_payment_bnpl';

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      flexDirection: 'column',
    }}
    >
      <OrderEditLink editable={editable} hashId={hashId} />
      <OrderShowLink hashId={hashId} canUnbindShippingTracking={canUnbindShippingTracking} isInstallmentsPayment={isInstallmentsPayment} />
      {tableRow.row.meta
        && (
          <OrderPaymentTooltip row={tableRow.row.meta} />
        )}
    </Box>
  );
};

export default CellComponent;
