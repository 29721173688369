// Core
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import * as PropTypes from 'prop-types';
// Icons
import CloudDownloadIcon from '@mui/icons-material/Refresh';
import Grid from '@mui/material/Grid';
import { isNil } from 'lodash';
import DateRangeFilter from '../../../../../components/_Table/DateRangeFilter';
import { useOrdersChartData } from './hooks/useOrdersChartData';
import ButtonWithIconAndCircularProgress from '../../../../../components/Buttons/ButtonWithIconAndCircularProgress';

function OrdersChart(props) {
  const { dataFieldClassName } = props;
  const {
    getDataAsync,
    isLoading,
    data,
    options,
    legend,
  } = useOrdersChartData();

  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    getDataAsync();
  }, [getDataAsync]);

  const onFilterChange = useCallback((value) => {
    setCurrentDate({ value });
    getDataAsync(value);
  }, [getDataAsync]);

  const handleUpdateOrderChart = () => {
    if (isNil(currentDate)) {
      getDataAsync();
    } else {
      getDataAsync(currentDate.value);
    }
  };

  return data.noData ? t('Нет заказов') : (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <DateRangeFilter
            variant="outlined"
            clearButton={false}
            filters={currentDate}
            onChange={onFilterChange}
            className={dataFieldClassName}
          />
        </Grid>
        <Grid item sx={{ mt: '3px' }}>
          <ButtonWithIconAndCircularProgress isLoading={isLoading} disabled={isLoading} text={t('Обновить')} onClick={handleUpdateOrderChart}>
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        </Grid>
      </Grid>
      <Bar legend={legend} options={options} data={data} />
    </>
  );
}

OrdersChart.propTypes = {
  dataFieldClassName: PropTypes.string,
};

export default OrdersChart;
