import Box from '@mui/material/Box';
import { IconButtonStyles } from '../../_Table/TableCell/Styles';
import { COLOR } from '../../../containers/App/AppStyles';
import styles from './styles';

const ButtonWithShadowContainer = ({
  icon, disabled, handleClick, buttonText, conteinerStyles,
}) => (
  <Box sx={{
    ...styles.iconsContainer,
    ...conteinerStyles,
  }}
  >
    <IconButtonStyles
      disabled={disabled}
      onClick={handleClick}
      text={buttonText}
      sx={{
        ...styles.button,
        '&.Mui-disabled': {
          backgroundColor: COLOR.grey[8],
        },
      }}
    >
      {icon}
    </IconButtonStyles>
  </Box>
);

export default ButtonWithShadowContainer;
