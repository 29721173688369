import React, { memo } from 'react';
import { Field, unregisterField } from 'redux-form/immutable';
import get from 'lodash/get';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import { tableFiltersTypes } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import RenderFormFieldFormSelect from './RenderFormFieldFormSelect';
import RenderFormFieldTextArea from './RenderFormFieldTextArea';
// Engine
import { userRoles } from '../../../../../../engine/config/userRoles';
// Helpers
import { validators } from '../../../../../../_helpers/validationsForm';
import { useUserRole } from '../../../../../hooks/useUserRole';
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';

function RenderProductStatusField(props) {
  const {
    input, formSelectWidth, margin, touchField,
    massEdit, options, disabled,
    checkIsFieldDisabled, setTouchField,
    changeFieldValue, getCurrentOptions, variant, onBlur, onFocus,
  } = props;
  const dispatch = useDispatch();
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);

  const { t } = useTranslation();
  const productCancelStatusArgs = {
    bulkEditable: massEdit,
    editable: true,
    name: 'cancelStatus',
    title: t('Статус отмены'),
  };

  const revisionNoteArgs = {
    bulkEditable: massEdit,
    editable: true,
    name: 'note',
    massProductEdit: massEdit,
    title: t('Примечание'),
  };

  const currentCheckIsFieldDisabled = checkIsFieldDisabled({
    bulkEditable: productCancelStatusArgs.bulkEditable,
    editable: productCancelStatusArgs.editable,
    disabled,
  });

  const inputValue = get(input.value && input.value.toJS ? input.value.toJS() : input.value, 'value', input.value);
  const currentOption = find(options && options.toJS ? options.toJS() : options, { value: inputValue }) || { additionalFields: [] };
  const additionalFields = currentOption.additionalFields || [];

  return (
    <>
      {(input.name === tableFiltersTypes.productStatus) && (
        <RenderFormFieldFormSelect
          {...props}
          variant={variant}
          setTouchField={setTouchField}
          changeFieldValue={changeFieldValue}
          canDelete={false}
        />
      )}
      {additionalFields.map(({ name, required }) => {
        switch (name) {
          case productCancelStatusArgs.name: {
            const willUnmountCallBack = () => {
              dispatch(unregisterField(formName, productCancelStatusArgs.name, true));
            };
            return (
              <Box key={productCancelStatusArgs.name}>
                <span id={productCancelStatusArgs.name} />
                <Field
                  component={RenderFormFieldFormSelect}
                  noMargin
                  willUnmountCallBack={willUnmountCallBack}
                  formSelectWidth={formSelectWidth}
                  disabled={currentCheckIsFieldDisabled || disabled || isPartnerActiveRole}
                  label={productCancelStatusArgs.title}
                  required={required}
                  margin={margin}
                  variant={variant}
                  name={productCancelStatusArgs.name}
                  options={getCurrentOptions(productCancelStatusArgs.name)}
                  touchField={touchField}
                  massEdit={massEdit}
                  validate={required ? [validators.required] : []}
                  setTouchField={setTouchField}
                  changeFieldValue={changeFieldValue}
                  canDelete={false}
                />
              </Box>
            );
          }
          default: {
            const willUnmountCallBack = () => {
              dispatch(unregisterField(formName, name.toString(), true));
            };
            return (
              <Field
                component={RenderFormFieldTextArea}
                disabled={currentCheckIsFieldDisabled || disabled || isPartnerActiveRole}
                name={name.toString()}
                id={name.toString()}
                key={name}
                label={revisionNoteArgs.title}
                type="text"
                margin="normal"
                fullWidth
                variant={variant}
                required={required}
                massEdit={revisionNoteArgs.massProductEdit}
                willUnmountCallBack={willUnmountCallBack}
                validate={required ? [validators.required] : []}
                setTouchField={setTouchField}
                changeFieldValue={changeFieldValue}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            );
          }
        }
      })}
    </>
  );
}

export default memo(RenderProductStatusField);
