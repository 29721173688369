import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// UI
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
// Parts
import SelectedProductsSkuList from './components/SelectedProductsSkuList';
import { COLOR } from '../../../../containers/App/AppStyles';
import { useStyles } from '../../../../hooks/useStyles';
import ContentProductEditStyles from './ContentProductEditStyles';

function ProductItemList(props) {
  const {
    allEdit, categoryName, massEdit,
  } = props;

  const classes = useStyles(ContentProductEditStyles);
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        {massEdit && (
          <>
            <Typography
              level="h3"
              sx={{
                color: `${COLOR.black.initial}`,
                fontSize: '18px',
                lineHeight: 1,
                fontWeight: 700,
                textTransform: 'none',
                margin: '0 0 15px',
              }}
            >
              {t('Изменяемые товары')}:
            </Typography>
            <SelectedProductsSkuList />
          </>
        )}
        {allEdit && (
          <>
            <span className={classes.allEdit}>
              <WarningIcon className={classes.iconWarning} />
              {`${t('Изменение всех товаров в категории')} ${categoryName}`}
            </span>
          </>
        )}
      </Grid>
    </Grid>
  );
}

ProductItemList.propTypes = {
  allEdit: PropTypes.bool.isRequired,
  massEdit: PropTypes.bool.isRequired,
  categoryName: PropTypes.string,
};


export default ProductItemList;
