import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// Parts
import Fab from '@mui/material/Fab';
// Engine
import { accessList } from '../../../../engine/config/access';
import { uiActions } from '../../../../engine/core/ui/action';
// Helpers
import { useAccessList } from '../../../hooks/useAccessList';
import { useStyles } from '../../../hooks/useStyles';
import SidebarStyles from '../SidebarStyle';

const SupportButton = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(SidebarStyles);
  const { opened } = props;
  const hasSupportMessageCreate = useAccessList(accessList.supportMessageCreate);
  const handleSupportPopupOpen = () => {
    dispatch(uiActions.mergeServiceSupportModal({ isOpen: true }));
  };
  // eslint-disable-next-line no-constant-condition
  if (!hasSupportMessageCreate || true) { // TODO AMP-5698
    return null;
  }
  return (
    (
      <Fab
        color="primary"
        aria-label="add"
        variant={opened && 'extended'}
        className={opened ? classes.fab : classes.fabSmall}
        onClick={handleSupportPopupOpen}
      >
        <QuestionAnswerIcon />
        {opened && <>&nbsp;{t('Задать вопрос')}</>}
      </Fab>
    )
  );
};

export default SupportButton;
