// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';
// lodash
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
// Engine
import { api } from '../../../../config/api';
import { notificationsActions } from '../../action';
import { uiActionAsync } from '../../../ui/saga/asyncAction';
import { selectors } from '../../../../config/selectors';
import { localData } from '../../../../config/localData';

export function* callGetNotificationsWorker({ payload }) {
  yield put(notificationsActions.setNotificationsPending(true));
  const {
    read, showType, offset,
    limit, createdAtTo,
  } = payload;
  const params = {
    offset,
    limit,
    read,
    createdAtTo,
  };

  switch (showType) {
    case 'toast': {
      params.createdAtFrom = yield localData.getItem(localData.keysObj.notificationsLastShow);
      break;
    }
    default: {
      break;
    }
  }

  const response = yield apply(api, api.notifications.getNotifications, [params]);

  if (response && response.status === 200) {
    const { items, totalCount } = response.data;
    switch (showType) {
      case 'toast': {
        yield apply(
          localData,
          localData.setItem,
          [localData.keysObj.notificationsLastShow, createdAtTo],
        );
        if (items.length) {
          for (let i = 0; i < items.length; i++) {
            const {
              title, text: message, id, link, type,
              group, externalLink, media,
            } = items[i];
            if (items[i].createdAt > params.createdAtFrom) {
              yield put(uiActionAsync.setNotificationMessage({
                id,
                link,
                externalLink,
                title,
                message,
                type,
                group,
                media,
                duration: 5000,
                position: 'top-right',
              }));
            }
          }
        }
        break;
      }
      default: {
        let notificationsListOffset;
        if (typeof read === 'undefined') {
          notificationsListOffset = yield select(selectors.notification.notificationsListOffset);
          yield put(notificationsActions.setNotificationsOffset(notificationsListOffset + items.length));
        } else {
          notificationsListOffset = yield select(selectors.notification.notificationsListOffsetNotRead);
          yield put(notificationsActions.setNotificationsOffsetNotRead(notificationsListOffset + items.length));
        }

        const notificationsListItems = yield select(selectors.notification.notificationsListItems);
        const updateListsFromResult = unionBy(items, notificationsListItems.toJS(), 'id');
        const notificationsReadArray = yield select(selectors.notification.notificationsReadArray);
        findIndex(updateListsFromResult, (item) => {
          if (includes(notificationsReadArray.toJS(), item.id)) {
            item.read = true; // eslint-disable-line
          }
        });

        yield put(notificationsActions.mergeNotifications({
          items: List(sortBy(updateListsFromResult, 'createdAt').reverse()),
          totalCount,
        }));
      }
    }
  }
  yield put(notificationsActions.setNotificationsPending(false));
}
