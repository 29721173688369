export const asyncTypes = Object.freeze({
  GET_ORDERS_LIST_ASYNC: 'GET_ORDERS_LIST_ASYNC',
  PUT_ORDERS_SETTINGS_ASYNC: 'PUT_ORDERS_SETTINGS_ASYNC',
  GET_ORDERS_INITIAL_STATE_ASYNC: 'GET_ORDERS_INITIAL_STATE_ASYNC',
  GET_ORDERS_HASH_ID_ASYNC: 'GET_ORDERS_HASH_ID_ASYNC',
  PUT_ORDERS_HASH_ID_ASYNC: 'PUT_ORDERS_HASH_ID_ASYNC',
  GET_ORDERS_STATUSES_LIST_ASYNC: 'GET_ORDERS_STATUSES_LIST_ASYNC',
  GET_ORDERS_PAYMENT_TYPES_ASYNC: 'GET_ORDERS_PAYMENT_TYPES_ASYNC',
  GET_ORDERS_DOWNLOAD_ASYNC: 'GET_ORDERS_DOWNLOAD_ASYNC',
  POST_ORDERS_UPLOAD_ASYNC: 'POST_ORDERS_UPLOAD_ASYNC',
  GET_ORDERS_STATISTICS_ASYNC: 'GET_ORDERS_STATISTICS_ASYNC',
  PUT_ORDER_UNBIND_SHIPPING_TRACKING: 'PUT_ORDER_UNBIND_SHIPPING_TRACKING',
});
