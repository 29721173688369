// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callGetDownloadWorker() {
  yield put(actions.mergeDownload({ pending: true }));

  const filters = yield select(selectors.partners.filters);
  const sorting = yield select(selectors.partners.sorting);

  const params = {
    format: 'xls',
  };

  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  sorting.toJS().reduce((acc, { columnName, direction }) => {
    acc.orderBy = `${columnName} ${direction === 'asc' ? 'ASC' : 'DESC'}`;
    return acc;
  }, params);

  const response = yield apply(api, api.partner.getPartnersDownload, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, message } = responseData;
        yield put(setErrorMessage(errors || message, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      default: {
        getFileFromBlob('partner.example.xls', response);
        break;
      }
    }
  }

  yield put(actions.mergeDownload({ pending: false }));
}
