import Box from '@mui/material/Box';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import Modal from '../../../../containers/Modals/Modal/Modal';
import PartnerInfoTable from './components/PartnerInfoTable';

const MonobankModal = ({ monoModalOpen, handleCloseMonoModal, partnerHashId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (partnerHashId !== undefined) {
      dispatch(partnerAsyncAction.getPartnerInfo({ hashId: partnerHashId }));
    }
  }, [partnerHashId]);

  const handleModalSend = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdZDlMzjiUuCe5UGQ1Psr1KhTaY2E8etNVuPzCSuHqFDVQXNg/viewform');
  };

  return (
    <Modal
      modalOpen={monoModalOpen}
      handleModalToggle={handleCloseMonoModal}
      handleModalCancel={handleCloseMonoModal}
      handleModalSend={handleModalSend}
      cancelText={t('Отменить')}
      sendText={t('Заполнить форму')}
      modalMaxWidth="sm"
      buttonSendIcon={null}
      title={t('Как подключить способ оплаты картой Monobank?')}
    >
      <Box>
        <Box component="span">
          {t('Для регистрации договора с “Monobank”, необходимо заполнить форму по')}
          <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdZDlMzjiUuCe5UGQ1Psr1KhTaY2E8etNVuPzCSuHqFDVQXNg/viewform" target="_blank">{t('ссылке')}.</Link>
        </Box>
        <PartnerInfoTable />
      </Box>
    </Modal>
  );
};


export default MonobankModal;
