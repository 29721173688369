// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form/immutable';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';

export function* callPostProductsImportWorker({ payload }) {
  const { formName, ...restPayload } = payload;
  yield put(actions.setIsContentTemplateImportModalOpen({ pending: true }));
  yield put(actions.setProductImportPending(true));

  const response = yield apply(api, api.contentTemplates.postProductsImport, [restPayload]);
  const modalState = {
    pending: false,
    statusTitle: '',
    errors: [],
  };
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        modalState.statusTitle = statusTitle;
        modalState.errors = errors;
        modalState.status = status;
        break;
      }
      case requestStatus.success: {
        const { message, minorErrors, successCounter } = responseData;
        modalState.statusTitle = message;
        modalState.errors = minorErrors;
        modalState.status = status;
        modalState.successCounter = successCounter;
        modalState.hideSubmitButton = true;
        if (formName) {
          yield put(reset(formName));
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setIsContentTemplateImportModalOpen(modalState));
  yield put(actions.setProductImportPending(false));
}
