// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { billingActions } from '../../action';
// Helpers
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callGetDashboardLastTransactionsWorker() {
  let response;

  yield put(billingActions.setIsBillingDashboardsLastTransactionsLoading(true));

  try {
    response = yield apply(api, api.dashboards.getDashboardsLastTransactions, []);
  } catch (err) {
    yield put(setErrorMessage(err.message, 'Error'));
  }

  if (response && response.status >= 200 && response.status < 400) {
    yield put(billingActions.setBillingDashboardsLastTransactions(response.data));
  }

  yield put(billingActions.setIsBillingDashboardsLastTransactionsLoading(false));
}
