// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function OrderStatisticCashIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9 8.75H6V17H9V8.75ZM18 11.75H15V17H18V11.75ZM13.5 17V5H10.5V17H13.5Z" fill="#6F2A7F" />
    </SvgIcon>
  );
}

export default OrderStatisticCashIcon;
