// Core
import { apply, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutUpdateValuesFromMagento({ payload: featureId }) {
  const currentFeatureId = get(featureId, 'featureId');
  yield put(actions.mergeContentUpdateValueFromMagento({
    pending: true,
    featureId: currentFeatureId,
  }));

  const response = yield apply(api, api.products.putUpdateValuesFromMagento, [featureId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.success: {
        const { message, values } = response.data;
        const selectProductGrid = yield select(selectors.content.productGrid);
        const currentGridSettings = selectProductGrid.toJS();
        yield put(setSuccessMessage(message, statusTitle));
        currentGridSettings.map((item) => {
          const newItem = item;
          if (newItem.name === currentFeatureId) {
            newItem.values = values;
          }
          return newItem;
        });
        yield put(actions.setProductGrid(currentGridSettings));
        break;
      }

      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      default: {
        break;
      }
    }
  }

  yield put(actions.mergeContentUpdateValueFromMagento({
    pending: false,
    featureId: null,
  }));
}
