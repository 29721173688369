// Colors
import green from '@mui/material/colors/green';

const StatCardStatusStyles = theme => ({
  statCard: {
    padding: 16,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '1',
    marginTop: '6px',
    color: 'rgba(48, 48, 48, 0.54)',
    fontWeight: 500,
  },
  subtitle1: {
    display: 'flex',
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    alignItems: 'center',
  },
  status: {
    fontSize: 14,
    borderRadius: '3px',
    color: theme.palette.primary.contrastText,
    marginLeft: 5,
    padding: '2px 4px',
  },
  statusActive: {
    backgroundColor: green[500],
  },
  statusPassive: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default StatCardStatusStyles;
