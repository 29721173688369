// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetCategoriesFeatureExportWorker() {
  yield put(actions.mergeCategoriesFeatureExport({ pending: true }));

  const filters = yield select(selectors.categoriesFeatureManagement.filters);
  const sorting = yield select(selectors.categoriesFeatureManagement.sorting);
  const params = {
    sorting: sorting.toJS(),
  };

  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  const response = yield apply(api, api.categoriesFeatureManagement.getCategoriesFeatureExport, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        getFileFromBlob('export_categories_feature.xlsx', response);
        break;
      }
    }
  }
  yield put(actions.mergeCategoriesFeatureExport({ pending: false }));
}
