import React, { createContext, useContext, useRef } from 'react';

const RefContext = createContext();

export function RefProvider({ children }) {
  const myRef = useRef(null);

  return (
    <RefContext.Provider value={myRef}>
      {children}
    </RefContext.Provider>
  );
}

export const useGuideTourRef = () => useContext(RefContext);
