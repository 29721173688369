export const asyncTypes = Object.freeze({
  GET_FEEDS_PRODUCTS_LIST_ASYNC: 'GET_FEEDS_PRODUCTS_LIST_ASYNC',
  POST_FEEDS_PARSE_BY_HASH_ID_ASYNC: 'POST_FEEDS_PARSE_BY_HASH_ID_ASYNC',
  PUT_FEEDS_PRODUCTS_SETTINGS_ASYNC: 'PUT_FEEDS_PRODUCTS_SETTINGS_ASYNC',
  GET_FEEDS_PRODUCTS_INITIAL_STATE_ASYNC: 'GET_FEEDS_PRODUCTS_INITIAL_STATE_ASYNC',
  GET_FEEDS_PRODUCTS_STATUSES_LIST_ASYNC: 'GET_FEEDS_PRODUCTS_STATUSES_LIST_ASYNC',
  PUT_PRODUCTS_FEED_MULTIPLE_EDITING_ASYNC: 'PUT_PRODUCTS_FEED_MULTIPLE_EDITING_ASYNC',
  PUT_FEEDS_PRODUCTS_ITEMS_BY_HASH_ID_ASYNC: 'PUT_FEEDS_PRODUCTS_ITEMS_BY_HASH_ID_ASYNC',
  GET_PRODUCTS_FEED_EXPORT_ASYNC: 'GET_PRODUCTS_FEED_EXPORT_ASYNC',
  POST_PRODUCTS_FEED_IMPORT_ASYNC: 'POST_PRODUCTS_FEED_IMPORT_ASYNC',
  GET_PRODUCTS_FEED_FULL_EXPORT_ASYNC: 'GET_PRODUCTS_FEED_FULL_EXPORT_ASYNC',
  GET_FEEDS_PRODUCTS_STATISTIC_ASYNC: 'GET_FEEDS_PRODUCTS_STATISTIC_ASYNC',
  GET_PRODUCTS_FEED_STATISTIC_ASYNC: 'GET_PRODUCTS_FEED_STATISTIC_ASYNC',
  GET_PRODUCTS_FEED_LIST_EXPORT_ASYNC: 'GET_PRODUCTS_FEED_LIST_EXPORT_ASYNC',
});
