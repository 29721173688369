// Core
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
// Parts
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { useTranslation } from 'react-i18next';
import ComponentWithTooltip from '../../../../../../../containers/ComponentWithTooltip';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
// Helpers
import { tableDataTypes } from '../../../../../../../../_helpers/data/tableDataTypes';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
import { locale } from '../../../../../../../../engine/init/i18n';

function HeaderComponent(props) {
  const {
    column, children, ...restProps
  } = props;
  const { i18n, t } = useTranslation();
  const currentLocale = locale[i18n.language];
  const descCellColumn = column?.desc?.[currentLocale] && <b>{column?.desc?.[currentLocale]}</b>;
  const feedIsItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const { stepsData } = feedIsItemByHashIdItem.toJS();
  const feedIsItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  let counter;
  let tooltipName;

  switch (column.name) {
    case tableDataTypes.productFeedCategory.name: {
      counter = stepsData?.[2].totalFeedCategories;
      tooltipName = t('Количество категорий в файле импорта');
      break;
    }
    case tableDataTypes.feedProductQuantity.name: {
      counter = (
        <>
          <NumberFormat
            value={stepsData?.[2]?.totalFeedProductsConnected}
            thousandSeparator=" "
            displayType="text"
          />
          {' / '}<NumberFormat
            value={stepsData?.[2]?.totalFeedProducts}
            thousandSeparator=" "
            displayType="text"
          />
        </>
      );
      tooltipName = t('Сопоставлено товаров / всего товаров');
      break;
    }
    default: {
      break;
    }
  }
  return (
    <TableHeaderRow.Cell className="tableCellWithCounter" column={column} {...restProps}>
      <ComponentWithTooltip description={descCellColumn}>
        <Box sx={{
          flexGrow: 1,
          width: 'calc(100% - 20px)',
          display: 'inline-flex',
          maxWidth: '100%',
          minWidth: 0,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
          {children}
        </Box>
      </ComponentWithTooltip>
      <Box sx={{ display: 'block', width: '100%', height: '14px' }}>
        {!feedIsItemByHashIdLoading && counter && (
          <Tooltip title={tooltipName} aria-label={tooltipName}>
            <Box sx={{ color: COLOR.inactive, cursor: 'pointer', width: 'fit-content' }}>({counter})</Box>
          </Tooltip>
        )}
      </Box>
    </TableHeaderRow.Cell>
  );
}
HeaderComponent.propTypes = {
  column: PropTypes.object,
  children: PropTypes.node,
};
export default memo(HeaderComponent);
