// Core
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { reduxForm, Field, Form } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import MainLogoImage from '../components/MainLogoImage/MainLogoImage';
import ButtonWrapper from '../components/ButtonWrapper';
import Header from '../../../components/Header/Header';
import Logo from '../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../assets/img/logo_short.svg';
// Engine
import { routersLink } from '../../../../engine/config/routes';
import { validators, phoneMask } from '../../../../_helpers/validationsForm';
import { asyncActions } from '../../../../engine/core/password/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
// Helpers
import AuthenticationStyle from '../AuthenticationStyle';
import { formFields, formName } from './form';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';

const useStyles = makeStyles(AuthenticationStyle);

function PasswordReset(props) {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const dispatch = useDispatch();
  const resetPasswordSubmitting = useSelector(selectors.password.submitting);
  const [resetByPhone, setResetByPhone] = useState(false);
  const { handleSubmit } = props;
  const onFormSubmit = async (formData) => {
    const data = {};
    if (resetByPhone) {
      data.phone = formData.get(formFields.phone);
    } else {
      data.email = formData.get(formFields.email);
    }

    if (process.env.REACT_APP_RECAPTCHA === 'true') {
      if (!executeRecaptcha) {
        console.warn('Execute recaptcha not yet available');
      } else {
        data.gRecaptchaResponse = await executeRecaptcha('PasswordReset');
        dispatch(asyncActions.passwordResetAsync(data));
      }
    } else {
      dispatch(asyncActions.passwordResetAsync(data));
    }
  };

  return (
    <div className={classes.session}>
      <Header
        logoAltText={t('АЛЛО - личный кабинет')}
        logo={Logo}
        LogoMobile={LogoMobile}
        onlyLogo
        signInButton
      />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="text-xs-center pb-xs">
                  <MainLogoImage />
                  <Typography variant="caption" display="block">
                    {t('Введите свою почту для восстановления пароля')}
                  </Typography>
                </div>
                <RadioGroup
                  defaultValue="email"
                  style={{ justifyContent: 'center' }}
                  row
                  aria-label="position"
                >
                  <FormControlLabel
                    value="email"
                    control={<Radio color="primary" onChange={() => setResetByPhone(false)} />}
                    label="Email"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="phone"
                    control={<Radio color="primary" onChange={() => setResetByPhone(true)} />}
                    label="Телефон"
                    labelPlacement="end"
                  />
                </RadioGroup>
                {resetByPhone
                  ? (
                    <Field
                      component={TextField}
                      className={classes.textField}
                      fullWidth
                      autoFocus
                      label={t('Номер телефона')}
                      margin="normal"
                      name={formFields.phone}
                      {...phoneMask}
                      validate={[validators.required, validators.phone]}
                      validation
                    />
                  )
                  : (
                    <Field
                      component={TextField}
                      className={classes.textField}
                      fullWidth
                      autoFocus
                      label={t('Адрес электронной почты')}
                      margin="normal"
                      name={formFields.email}
                      validate={[validators.required, validators.email]}
                      validation
                    />
                  )
                }
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={resetPasswordSubmitting}
                  sx={{ p: BUTTON_SIZE.middle }}
                >
                  {resetPasswordSubmitting
                    ? <CircularProgress color="inherit" size={14} />
                    : t('Восстановить пароль')
                  }&nbsp;
                </Button>
                <ButtonWrapper>
                  <Link to={routersLink.dashboard}>
                    <Button>{t('Войти')}</Button>
                  </Link>
                  <Link to={routersLink.users.signUp}>
                    <Button>{t('Регистрация')}</Button>
                  </Link>
                </ButtonWrapper>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

PasswordReset.propTypes = {
  handleSubmit: PropTypes.func,
};

const reduxFormConfig = {
  form: formName,
  initialValues: {
    [formFields.phone]: '380',
  },
};

export default reduxForm(reduxFormConfig)(PasswordReset);
