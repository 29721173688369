// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';
import { initialize } from 'redux-form/immutable';

// lodash
import map from 'lodash/map';
import forEach from 'lodash/forEach';

// actions
import { actions } from '../../actions';

// config
import { api } from '../../../../../config/api';

// helpers
import { formName } from '../../../../../../ui/page/PartnerAccess/components/PartnerAccessModal/form';

export function* callGetPartnerAccessCredentialsWorker({ payload: hashId }) {
  yield put(actions.setPartnerAccessCredentials({ pending: true }));

  const changeKeys = (formData) => {
    const result = {};
    forEach(formData, (value, key) => {
      result[`${formName}-${key}`] = value;
    });

    return result;
  };

  const response = yield apply(api, api.partners.getPaymentAccessCredentials, [hashId]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const getChangedKeys = changeKeys(data.credentials);
    const fields = map(getChangedKeys, (value, key) => key);

    yield put(initialize(formName, getChangedKeys, fields));

    yield put(actions.setPartnerAccessCredentials({
      data: Map(data),
    }));
  }

  yield put(actions.setPartnerAccessCredentials({ pending: false }));
}
