export const formName = 'logsMagentoRequestsItem';

export const formFields = Object.freeze({
  requestMethod: {
    name: 'requestMethod',
    label: 'Метод запроса',
  },
  magentoMethod: {
    name: 'magentoMethod',
    label: 'Мадженто метод запроса',
  },
  magentoPath: {
    name: 'magentoPath',
    label: 'Мадженто путь запроса',
  },
  responseCode: {
    name: 'responseCode',
    label: 'Код ответа',
  },
  requestBody: {
    name: 'requestBody',
    label: 'Тело запроса',
  },
  responseBody: {
    name: 'responseBody',
    label: 'Тело ответа',
  },
  createdAt: {
    name: 'createdAt',
    label: 'Дата создания лога',
  },
  runTime: {
    name: 'runTime',
    label: 'Время выполнения запроса',
  },
});
