// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  partner: Object.freeze({
    name: 'partner',
    title: <Trans>М-ID партнера</Trans>,
  }),
  orderCancelPercent: Object.freeze({
    name: 'orderCancelPercent',
    title: <Trans>% отмен партнера</Trans>,
  }),
  ratingRatio: Object.freeze({
    name: 'ratingRatio',
    title: <Trans>Коэффициент рейтинга</Trans>,
  }),
  ratingRatioDate: Object.freeze({
    name: 'ratingRatioDate',
    title: <Trans>Месяц начисления</Trans>,
  }),
  createdAt: Object.freeze({
    name: 'createdAt',
    title: <Trans>Дата создания коэффициента</Trans>,
  }),
});
