// core
import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// actions
import { asyncActions } from '../../../../../../engine/core/messages/saga/asyncActions';

// parts
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import RecipientsGroupFields from './components/RecipientsGroupFields';
import RecipientsImportModal from './components/RecipientsImportModal';
import PartnersNotFoundModal from './components/PartnersNotFoundModal';

// config
import { selectors } from '../../../../../../engine/config/selectors';

// hooks
import { useMessageReadOrEditPage } from '../../_hooks/useMessageReadOrEditPage';
import { useValidateMessageGroup } from './components/_hooks/useValidateMessageGroup';

function RecipientsFields(props) {
  const { fields } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isReadMessage } = useMessageReadOrEditPage();
  const { isValidGroup } = useValidateMessageGroup();
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const [firstGroup, setFirstGroup] = useState(true);
  const groupAdd = useCallback(() => {
    fields.push({});
  }, [fields]);

  useEffect(() => {
    if (firstGroup && !fields.length) {
      setFirstGroup(false);
      groupAdd();
    }
  }, [firstGroup, setFirstGroup, groupAdd, fields]);

  useEffect(() => {
    if (!postUsersHashIdPending) {
      dispatch(asyncActions.getMessagesUserTypesAsync());
    }
  }, [dispatch, postUsersHashIdPending]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: '16px' }}>
          <Typography variant="body1" component="div">
            {t('Получатели')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {fields.map((member, index) => (
            <RecipientsGroupFields
              key={index}
              fields={fields}
              member={member}
              index={index}
            />
          ))}
          <ButtonWithIcon text={t('Добавить')} onClick={groupAdd} disabled={isReadMessage || !isValidGroup}>
            <AddCircleIcon />
          </ButtonWithIcon>
        </Grid>
      </Grid>
      <RecipientsImportModal />
      <PartnersNotFoundModal />
    </>
  );
}

RecipientsFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default memo(RecipientsFields);
