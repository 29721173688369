// Core
import { apply, put } from 'redux-saga/effects';
import { replace } from 'redux-first-history';
import { List } from 'immutable';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
import { uiActions } from '../../../../ui/action';
import { pageLinks } from '../../../../../config/routes';

const PRICELIST_IMPORT_ERROR_STEP = 4;

export function* callPostFeedsImportWorker({ payload }) {
  yield put(actions.setImportPending(true));

  const response = yield apply(api, api.feeds.postImport, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, generalErrorMessage } = responseData;
        yield put(actions.setImportError({
          statusTitle,
          generalErrorMessage,
          errors: List(errors),
        }));
        yield put(uiActions.setPopup({ isOpen: true, data: { step: PRICELIST_IMPORT_ERROR_STEP, message: errors?.[0]?.message } }));
        break;
      }
      case requestStatus.success: {
        const { hashId, errors, generalErrorMessage } = responseData;
        if (hashId) {
          yield put(replace(pageLinks.productsAndPrices.import(hashId)));
        }
        if (errors) {
          yield put(actions.setImportError({
            statusTitle,
            generalErrorMessage,
            errors: List(errors),
          }));
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setImportPending(false));
}
