export const types = Object.freeze({
  SET_ANALYTICS_LIST: 'SET_ANALYTICS_LIST',
  SET_ANALYTICS_CURRENT_PAGE: 'SET_ANALYTICS_CURRENT_PAGE',
  SET_ANALYTICS_FILTERS: 'SET_ANALYTICS_FILTERS',
  SET_ANALYTICS_PAGE_SIZE: 'SET_ANALYTICS_PAGE_SIZE',
  SET_ANALYTICS_SORTING: 'SET_ANALYTICS_SORTING',
  SET_ANALYTICS_INITIAL_STATE: 'SET_ANALYTICS_INITIAL_STATE',
  SET_ANALYTICS_SETTING_STATUS: 'SET_ANALYTICS_SETTING_STATUS',
  SET_ANALYTICS_SETTING_PENDING: 'SET_ANALYTICS_SETTING_PENDING',
  SET_SELECTION: 'SET_SELECTION',
});
