// Core
import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Select from './Select/Select';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import { stateKeys } from '../../../../engine/core/_partners/reducers';

function PartnerSelect(props) {
  const {
    required, input, meta, id, wrapperClassName,
    margin, label, disabled,
    selectFirst, partnerListSelector, partnerPendingSelector, isSearchable, hasSearch = true, isCategorySelect = false,
    isMulti, isPortal, reducerName, filterValueBy, dataTestId, searchDataTestId, customOnBlur,
  } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(partnerPendingSelector || selectors.partner.listContextLoading);
  const partnerData = useSelector(partnerListSelector || selectors.partner.listContext);

  useEffect(() => {
    if (!partnerListSelector || reducerName !== 'default') {
      dispatch(partnerAsyncAction.getPartnerContextListAsync({ reducerName }));
    }
  }, [partnerListSelector]);

  return (
    <Select
      hasSearch={hasSearch}
      isPortal={isPortal}
      hasConfirmButton={isMulti}
      isMulti={isMulti}
      margin={margin}
      disabled={disabled}
      options={partnerData}
      isLoading={isLoading}
      variant="outlined"
      filterValueBy={filterValueBy}
      meta={meta}
      input={input}
      label={label}
      placeholder=""
      required={required}
      selectFirst={selectFirst}
      id={id}
      wrapperClassName={wrapperClassName}
      dataTestId={dataTestId}
      searchDataTestId={searchDataTestId}
      customOnBlur={customOnBlur}
      isSearchable={isSearchable}
      isCategorySelect={isCategorySelect}
    />
  );
}

PartnerSelect.propTypes = {
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  selectFirst: PropTypes.bool,
  label: PropTypes.string,
  partnerListSelector: PropTypes.func,
  partnerPendingSelector: PropTypes.func,
  required: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  margin: PropTypes.string,
  id: PropTypes.string,
  wrapperClassName: PropTypes.string,
  filterValueBy: PropTypes.string,
  reducerName: PropTypes.oneOf(['default', stateKeys.partnersListContextBillingPaymentForPartner]),
  isPortal: PropTypes.bool,
  dataTestId: PropTypes.string,
  searchDataTestId: PropTypes.string,
  customOnBlur: PropTypes.func,
  isSearchable: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isCategorySelect: PropTypes.bool,
};

PartnerSelect.defaultProps = {
  label: 'M-ID партнера',
  reducerName: 'default',
  disabled: false,
  isMulti: false,
  selectFirst: false,
};

export default PartnerSelect;
