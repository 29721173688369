// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Helpers
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetProductsFeatureValuesDownloadWorker({ payload: { categoryId } }) {
  yield put(actions.setProductsFeatureValuesListDownloadState({ [categoryId]: true }));

  const response = yield apply(api, api.products.getProductsFeatureValuesListDownload, [{ categoryId }]);

  if (response && response.status >= 200 && response.status < 400) {
    getFileFromBlob('products_feature_values_list.xls', response);
  }

  yield put(actions.setProductsFeatureValuesListDownloadState({ [categoryId]: false }));
}
