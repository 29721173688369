// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';

import { ButtonBaseSwitcher } from './SwitcherStyles';

const Switcher = (props) => {
  const {
    isChecked,
    isDisabled,
    centerRipple,
    sx,
    ...restProps
  } = props;
  return (
    <ButtonBaseSwitcher
      component="div"
      sx={sx}
    >
      <Switch
        disableRipple
        disabled={isDisabled}
        checked={isChecked}
        centerRipple={centerRipple}
        {...restProps}
      />
    </ButtonBaseSwitcher>
  );
};

Switcher.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  centerRipple: PropTypes.bool,
  sx: PropTypes.object,
};
Switcher.defaultProps = {
  isDisabled: false,
  centerRipple: true,
};

export default Switcher;
