// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { replace } from 'redux-first-history';
import { api, requestStatus } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../../config/routes';
import { actions } from '../../actions';
import { selectors } from '../../../../../config/selectors';
import { asyncActions } from '../asyncActions';

export function* callPutItemByHashId({ payload: { hashId, redirect = true, ...data } }) {
  yield put(actions.mergeItemByHashId({ putPending: true }));
  const response = yield apply(api, api.feedsProducts.putItemByHashId, [{ hashId, data }]);
  const prevLocation = yield select(selectors.location.prevLocation);
  const prevLocationFromStorage = localStorage.getItem('prevProductLink');

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        if (redirect) {
          if (prevLocationFromStorage || prevLocation) {
            yield put(replace(prevLocationFromStorage || prevLocation));
          } else {
            yield put(replace(pageLinks.feed.products));
          }
        } else {
          yield put(asyncActions.getItemByHashIdAsync(hashId));
        }
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  } else if (response && response.status === 403) {
    if (prevLocationFromStorage || prevLocation) {
      yield put(replace(prevLocationFromStorage || prevLocation));
    } else {
      yield put(replace(pageLinks.feed.products));
    }
  }
  yield put(actions.mergeItemByHashId({ putPending: false }));
}
