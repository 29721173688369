// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ExportImportIcon(props) {
  return (
    <SvgIcon className="person-outlined-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <path d="M7.86524 10.7166C7.88125 10.737 7.9017 10.7536 7.92505 10.7649C7.9484 10.7763 7.97403 10.7822 8 10.7822C8.02597 10.7822 8.0516 10.7763 8.07495 10.7649C8.0983 10.7536 8.11875 10.737 8.13476 10.7166L10.5305 7.68556C10.6182 7.57433 10.539 7.40963 10.3957 7.40963H8.8107V0.171123C8.8107 0.0770053 8.73369 0 8.63957 0H7.35615C7.26203 0 7.18503 0.0770053 7.18503 0.171123V7.40749H5.60428C5.46096 7.40749 5.38182 7.57219 5.46952 7.68342L7.86524 10.7166ZM15.8289 9.96791H14.5455C14.4513 9.96791 14.3743 10.0449 14.3743 10.139V13.4332H1.62567V10.139C1.62567 10.0449 1.54866 9.96791 1.45455 9.96791H0.171123C0.0770053 9.96791 0 10.0449 0 10.139V14.3743C0 14.7529 0.305882 15.0588 0.684492 15.0588H15.3155C15.6941 15.0588 16 14.7529 16 14.3743V10.139C16 10.0449 15.923 9.96791 15.8289 9.96791Z" />
    </SvgIcon>
  );
}

export default ExportImportIcon;
