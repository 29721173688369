// Core
import {
  apply, put,
} from 'redux-saga/effects';
// Engine
import { stopSubmit, initialize } from 'redux-form';
import { api } from '../../../../config/api';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { userActions } from '../../action';
import { formName } from '../../../../../ui/page/MailSettings/MailSettings';

export function* callPutMailSettingsWorker({ payload }) {
  yield put(userActions.mergeMailSettings({ putPending: true }));

  const response = yield apply(api, api.user.putMailSettings, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;

      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          const formData = {};
          const fields = [];
          payload.forEach(({ id, enableSend }) => {
            formData[id] = enableSend;
            fields.push(id);
          });

          yield put(initialize(formName, formData, fields));
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage('', statusTitle));
          yield put(stopSubmit(formName, errors));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(userActions.mergeMailSettings({ putPending: false }));
}
