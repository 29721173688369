import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../engine/core/feeds/all/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/feeds/all/saga/asyncActions';

function DeletePriceModal() {
  const deleteShowModal = useSelector(selectors.feeds.deleteShowModal);
  const hashId = useSelector(selectors.feeds.deleteShowModalHashId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleModalClose = () => {
    dispatch(actions.setFeedsDelete({ showModal: false, hashId: null }));
  };
  const handleModalSend = () => {
    dispatch(asyncActions.deleteFeedByHashId({ hashId }));
  };

  return (
    <Modal
      title={t('Удалить прайс-лист')}
      modalOpen={deleteShowModal}
      sendText={t('Удалить')}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      handleModalSend={handleModalSend}
      modalMaxWidth="sm"
      cancelText={t('Отменить')}
      iconSendButton={<></>}
    >
      <span style={{ fontWeight: 'bold' }}>{t('Внимание!')} </span>{t('После нажатия кнопки "Удалить", прайс-лист будет удален')}
    </Modal>
  );
}
export default DeletePriceModal;
