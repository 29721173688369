import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as analyticsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const analytics = Object.freeze({
  currentPage: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.currentPage], 0),
  filters: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.filters], List()),
  isLoading: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.pending], false),
  list: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.items], List()),
  pageSize: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.reload], false),
  totalCount: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.totalCount], 0),
  sorting: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.sorting], List()),
  settingStatus: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.settingStatus], false),
  settingPending: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.settingPending], false),
  selection: state => getIn(state, [analyticsKeys.controller, analyticsKeys.partnersAnalyticsList, analyticsKeys.selection], List()),
});
