import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { COLOR } from '../../../../containers/App/AppStyles';

export const StepperConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.success,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.success,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLOR.grey['38'],
  },
}));
export const StyledStepper = styled(Stepper)(() => (
  {
    margin: '0 auto',
    maxWidth: '435px',
    width: '100%',
    '& .MuiStepLabel-iconContainer.Mui-completed': {
      '& .MuiSvgIcon-root': {
        color: '#ffffff',
      },
    },
    '& .MuiSvgIcon-root.Mui-active': {
      color: 'transparent',
      border: `1px solid ${COLOR.success}`,
      borderRadius: '50%',
      fontWeight: 'bold',
      '& .MuiStepIcon-text': {
        fill: COLOR.success,
      },
    },
    '& .MuiStepLabel-iconContainer': {
      cursor: 'pointer',
      position: 'relative',
      zIndex: 2,
    },
    '& .MuiStepConnector-root': {
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
      zIndex: 1,
    },
    '& .MuiStepLabel-label': {
      textAlign: 'center',
      fontSize: '12px',
      color: COLOR.grey['38'],
      marginTop: '8px',
      lineHeight: '14px',
      '&.Mui-active, &.Mui-completed': {
        color: COLOR.success,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      cursor: 'pointer',
      display: 'contents',
    },
    '& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel': {
      marginTop: '3px',
      lineHeight: '14px',
    },
  }
));


export const ItemStyles = ({ stepsData }) => ({
  mainHolder: {
    minHeight: stepsData ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root:first-child': {
          '& .MuiTableCell-head': {
            '&:first-child': {
              borderTopLeftRadius: '5px',
            },
            '&:not(.TableStubCell-cell):nth-last-child(2)': {
              borderTopRightRadius: '5px',
            },
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .TableNoDataCell-cell': {
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        },
        '& .MuiTableRow-root:last-child': {
          '& .MuiTableCell-body': {

            '&:first-child:not(.TableNoDataCell-cell)': {
              borderBottomLeftRadius: '5px',
            },
            '&:nth-last-child(2):not(.TableStubCell-cell), &:last-child:not(.TableStubCell-cell)': {
              borderBottomRightRadius: '5px',
            },
          },
        },
      },
    },
  },
});
