// Core
import { fromJS, List, Map } from 'immutable';

// Instruments
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  billingInvoiceModal: {
    modalIsOpen: false,
  },
  billingStatus: {
    status: {
      items: {
        balance: 0,
        limitDateEnd: '',
        paymentNotification: undefined,
        creditLimitModalStatus: null,
        creditLimitModalOffStatus: null,
      },
      informers: Map(),
      pending: true,
    },
    downloadPaymentOrder: {
      pending: false,
      status: null,
    },
  },
  ratingStatus: {
    rating: '',
    pending: false,
  },
  invoiceTypes: Map({
    pending: false,
    statusTitle: null,
    errors: List(),
    items: List(),
    totalCount: 0,
  }),
  billingSelectFilterPartners: null,
  billingStatusesList: {
    items: List(),
    pending: false,
  },
  billingPaymentTypes: {
    items: [],
    pending: false,
  },
  billingOrderDashboard: Map({
    canceledQuantity: 0,
    doneQuantity: 0,
    inProcessQuantity: 0,
  }),
  billingOnTimeOrderDashboard: Map({
    timelyProcessedQuantity: 0,
    overdueQuantity: 0,
    inProcessingQuantity: 0,
  }),
  billingSuccessDeliveryDashboard: Map({
    timelyDeliveredQuantity: 0,
    overdueQuantity: 0,
    inProcessingQuantity: 0,
  }),
  billingNewOrderDashboard: Map({
    items: List(),
    pending: false,
  }),
  billingDashboardLastTransactions: Map({
    items: List(),
    pending: false,
  }),
  billingPartnersDownload: Map({
    pending: false,
  }),
  billingReportDownload: Map({
    pending: false,
    parameters: Map(),
  }),
});

export const keys = {
  controller: 'billing',
  billingStatus: 'billingStatus',
  status: 'status',
  rating: 'rating',
  informers: 'informers',
  externalRating: 'externalRating',
  billingReportDownload: 'billingReportDownload',
  creditLimitModalStatus: 'creditLimitModalStatus',
  creditLimitModalOffStatus: 'creditLimitModalOffStatus',
  billingOrderDashboard: 'billingOrderDashboard',
  billingOnTimeOrderDashboard: 'billingOnTimeOrderDashboard',
  billingNewOrderDashboard: 'billingNewOrderDashboard',
  downloadPaymentOrder: 'downloadPaymentOrder',
  billingStatusPartners: 'billingStatusPartners',
  billingSelectFilterPartners: 'billingSelectFilterPartners',
  billingStatusesList: 'billingStatusesList',
  billingPaymentTypes: 'billingPaymentTypes',
  billingInvoiceModal: 'billingInvoiceModal',
  modalIsOpen: 'modalIsOpen',
  parameters: 'parameters',
  invoiceTypes: 'invoiceTypes',
  filters: 'filters',
  sorting: 'sorting',
  billingReport: 'billingReport',
  pageSize: 'pageSize',
  currentPage: 'currentPage',
  totalCount: 'totalCount',
  reload: 'reload',
  statusesList: 'statusesList',
  lastTransactions: 'billingDashboardLastTransactions',
  pending: 'pending',
  items: 'items',
  statusTitle: 'statusTitle',
  errors: 'errors',
  conditions: 'conditions',
  ratingStatus: 'ratingStatus',
  billingSuccessDeliveryDashboard: 'billingSuccessDeliveryDashboard',
  billingPartnersDownload: 'billingPartnersDownload',
};

export const billingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_BILLING_STATUS: {
      return state.mergeIn([keys.billingStatus], payload);
    }
    case types.SET_RATING_STATUS: {
      return state.mergeIn([keys.ratingStatus], payload);
    }
    case types.SET_CREDIT_LIMIT_MODAL_STATUS: {
      return state.setIn([keys.billingStatus, keys.status, keys.items, keys.creditLimitModalStatus], payload);
    }
    case types.SET_CREDIT_LIMIT_MODAL_OFF_STATUS: {
      return state.setIn([keys.billingStatus, keys.status, keys.items, keys.creditLimitModalOffStatus], payload);
    }
    case types.MERGE_IN_BILLING_INVOICE_TYPES: {
      return state.mergeIn([keys.invoiceTypes], Map(payload));
    }
    case types.SET_BILLING_PARTNERS_DOWNLOAD_PENDING: {
      return state.setIn([keys.billingPartnersDownload, keys.pending], payload);
    }
    case types.SET_BILLING_STATUSES_LIST: {
      return state.mergeIn([keys.billingStatusesList], payload);
    }
    case types.SET_BILLING_INVOICE_MODAL: {
      return state.mergeIn([keys.billingInvoiceModal], payload);
    }
    case types.SET_BILLING_PAYMENT_TYPES: {
      return state.mergeIn([keys.billingPaymentTypes], payload);
    }
    case types.SET_BILLING_ORDERS_DASHBOARD_DATA: {
      return state.setIn([keys.billingOrderDashboard], fromJS(payload));
    }
    case types.SET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA: {
      return state.setIn([keys.billingOnTimeOrderDashboard], fromJS(payload));
    }
    case types.SET_BILLING_SUCCESS_DELIVERY_DASHBOARD_DATA: {
      return state.setIn([keys.billingSuccessDeliveryDashboard], fromJS(payload));
    }
    case types.SET_BILLING_NEW_ORDERS_DASHBOARD_DATA: {
      return state.setIn([keys.billingNewOrderDashboard, keys.items], fromJS(payload));
    }
    case types.SET_BILLING_NEW_ORDERS_DASHBOARD_DATA_LOADING: {
      return state.setIn([keys.billingNewOrderDashboard, keys.pending], fromJS(payload));
    }
    case types.SET_BILLING_DASHBOARD_LAST_TRANSACTIONS: {
      return state.setIn([keys.lastTransactions, keys.items], fromJS(payload));
    }
    case types.SET_IS_BILLING_DASHBOARD_LAST_TRANSACTIONS_LOADING: {
      return state.setIn([keys.lastTransactions, keys.pending], payload);
    }
    case types.SET_BILLING_SELECT_FILTER_PARTNER: {
      return state.setIn([keys.billingSelectFilterPartners], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.MERGE_BILLING_REPORT_DOWNLOAD: {
      return state.mergeIn([keys.billingReportDownload], Map(payload));
    }
    default: {
      return state;
    }
  }
};
