// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cx from 'classnames';

// UI
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Style
import ModalHeaderStyle from './ModalHeaderStyle';

function ModalHeader(props) {
  const {
    classes, title, handleModalToggle, subTitle, subTitleAttentionText, disabled, noMarginBottom,
  } = props;
  const closeButtonClass = cx(classes.closeButton, {
    [classes.closeButtonNoTitle]: !title,
    [classes.closeButtonNoPadding]: noMarginBottom,
  });
  return (
    <DialogTitle className={cx(classes.modalTitle, { [classes.modalTitleNoPadding]: noMarginBottom })} sx={{ marginBottom: '0' }} id="form-dialog-title">
      <div className={classes.modalTitleWrap}>
        {title && (
          <Typography variant="h3" className={classes.modalTitleText}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant="h5" className={classes.modalSubTitleText}>
            {subTitleAttentionText && <span className={classes.modalAttentionText}>{ subTitleAttentionText }</span>} {subTitle}
          </Typography>
        )}
      </div>
      <IconButton
        disabled={disabled}
        aria-label="Close"
        className={closeButtonClass}
        onClick={handleModalToggle}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

ModalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleAttentionText: PropTypes.string,
  disabled: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
};
ModalHeader.defaultProps = {
  disabled: false,
};
export default withStyles(ModalHeaderStyle)(ModalHeader);
