export const mimeTypeExtensions = Object.freeze({
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  png: 'image/png',
  xPng: 'image/x-png',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  webp: 'image/webp',
  pdf: 'application/pdf',
  xml: 'text/xml',
  rar: 'application/vnd.rar',
  zip: 'application/zip',
  xZip: 'application/x-zip-compressed',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '7z': 'application/x-7z-compressed',
});

export const fileExtensions = Object.freeze({
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  '7z': '7z',
  rar: 'rar',
  zip: 'zip',
  xZip: 'xZip',
  xlsx: 'xlsx',
  png: 'png',
  xPng: 'x-png',
  pdf: 'pdf',
  xml: 'xml',
  gif: 'gif',
  jpeg: 'jpeg',
  webp: 'webp',
});
