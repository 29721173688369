import React, { useCallback } from 'react';
import Chip from '@mui/material/Chip';
import cx from 'classnames';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const MultiValue = (props) => {
  const {
    selectProps, children, isFocused, removeProps, data, getValue,
  } = props;

  const onRemove = useCallback(() => {
    if (selectProps.sendOnRemove) {
      const currentValue = getValue();
      const newValue = currentValue.filter(item => item.value !== data.value);
      selectProps.onConfirm(newValue);
    } else {
      removeProps.onClick();
    }
  }, [data.value, getValue, removeProps, selectProps]);
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={cx(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={onRemove}
      deleteIcon={<CloseRoundedIcon {...removeProps} />}
    />
  );
};

export default MultiValue;
