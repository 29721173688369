import { getIn, List, Map } from 'immutable';
import { stateKeys as categoriesKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const categories = Object.freeze({
  currentPage: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.currentPage], 0),
  filters: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.filters], List()),
  isLoading: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.pending], false),
  list: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.items], List()),
  pageSize: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.reload], false),
  totalCount: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.totalCount], 0),
  sorting: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.sorting], List()),
  settingStatus: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.settingStatus], false),
  settingPending: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.settingPending], false),
  categoriesListContext: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesListContext, categoriesKeys.items], List()),
  categoriesListContextPending: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesListContext, categoriesKeys.pending], false),
  // TODO: данный селектор не используемый
  expandedRowIds: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.expandedRowIds], List()),
  categoryItem: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoryItem, categoriesKeys.item], Map()),
  categoryItemLoading: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoryItem, categoriesKeys.pending], false),
  categoryItemSubmitting: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoryItem, categoriesKeys.submitting], false),
  commissionsUploadIsOpen: state => getIn(state, [categoriesKeys.controller, categoriesKeys.commissionsUpload, categoriesKeys.isOpen], false),
  commissionsUploadUploading: state => getIn(state, [categoriesKeys.controller, categoriesKeys.commissionsUpload, categoriesKeys.pending], false),
  treeDataRowIds: state => getIn(state, [categoriesKeys.controller, categoriesKeys.categoriesList, categoriesKeys.treeDataRowIds], List()),
});
