// Core
import { useSelector } from 'react-redux';
// Engine
import { selectors } from '../../engine/config/selectors';
import { userRoles as allUserRoles } from '../../engine/config/userRoles';

export function usePartnerRole() {
  const partnerRolesArray = [
    allUserRoles.partner,
    allUserRoles.partnerActive,
    allUserRoles.partnerBilling,
    allUserRoles.partnerContentManager,
    allUserRoles.partnerManageProfile,
    allUserRoles.partnerOrderManager,
    allUserRoles.partnerPaymentWaiting,
    allUserRoles.partnerRating,
    allUserRoles.partnerSigningContract];
  const userRoles = useSelector(selectors.user.rolesList);
  return userRoles.some(currentItem => partnerRolesArray.indexOf(currentItem) !== -1);
}
