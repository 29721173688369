// Core
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Modal from '../../../../../../../containers/Modals/Modal/Modal';
import { completionPriceListModalConfig } from '../../ProductFeedParametersTable/ProductFeedParametersTable';
// Helpers
import { dispatchCompletionListItemData } from '../../../_helpers/dispatchCompletionListItemData';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';

function HelpModalInitialLoadPriceListCategories() {
  const { hashId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const completionImportList = useSelector(selectors.feeds.completionImportList)?.toJS();
  const modalTypeKey = completionPriceListModalConfig.type.import;
  const stepKey = completionPriceListModalConfig.steps.step_2;
  const isOpen = completionImportList?.[hashId]?.[modalTypeKey]?.[stepKey]?.value;

  const closeCallback = () => {
    dispatchCompletionListItemData({
      dispatch, hashId, completionImportList, modalTypeKey, stepKey,
    });
  };

  return (
    <Modal
      title={t('Подсказка')}
      modalOpen={isOpen}
      hideButtons
      cancelText={t('Понятно')}
      handleModalCancel={closeCallback}
      handleModalToggle={closeCallback}
      modalMaxWidth="sm"
    >
      <p>{t('Каждой категории из вашего прайс-листа - сопоставьте соответствующую категорию Алло. После сопоставления - нажмите кнопку "Продолжить".')}</p>
      <p>{t('Горячая линия')}: 0(800)200900</p>
    </Modal>
  );
}

export default HelpModalInitialLoadPriceListCategories;
