// Core
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/system';
// Parts
import { Form } from 'redux-form/immutable';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { COLOR } from '../../../../../containers/App/AppStyles';

export const classNames = {
  formField: {
    marginTop: '28px',
    marginBottom: '8px',
  },
};

export const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const StyledLoadingButton = styled(LoadingButton)`
  margin-top: auto;
  margin-left: auto;
  min-height: 36px
`;

export const Wrapper = styled(Box)`
  width: ${isMobile ? '320px' : '380px'};
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EndComponent = styled(Box)`
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px
`;


export const TooltipButton = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: ${COLOR.controlsIcons.main},
`;
