
const WorkspaceStyles = theme => ({
  content: {
    padding: theme.spacing(1),
    // paddingLeft: '16px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    backgroundColor: theme.palette.background.default,
    minWidth: 0,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'no-padding': {
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  'content-left': {
    overflowY: 'auto',
    height: 'calc(100vh - 56px)',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 'calc(-1 * (100vw - 100%))',
    },
  },
  'content-right': {
    marginRight: -270,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  fullHeight: {
    height: '75%',
  },
});

export default WorkspaceStyles;
