// Core
import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Grid from '@mui/material/Grid';
import FormTextAreaOnlyUkr from './components/FormTextAreaOnlyUkr';
// import SelectField from './components/SelectField';
import Loading from '../../../../components/Loading/Loading';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import FieldCropper from '../../../../components/_Form/FieldCropper/FieldCropper';
import OrderProcessing from './components/OrderProcessing';
import DeliveryDelayPeriod from './components/DeliveryDelayPeriod';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { partnerActions } from '../../../../../engine/core/_partners/action';
import { selectors } from '../../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
// Helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { useCurrentMode } from '../../hooks/useCurrentMode';
import { usePartnerData } from '../../hooks/usePartnerData';
import { useAccessList } from '../../../../hooks/useAccessList';
import { formName } from '../../form';
// Icons
import EyeIcon from '../../../../icons/Eye';
import TabsPanel from '../../../../containers/Tabs/TabsPanel';

function PartnerEditSite(props) {
  const {
    classes, display, isLoading, mode, userConfigurations, postUsersHashIdPending, index, value,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessPartnerConfirmForm = useAccessList(accessList.partnerConfirmForm);
  const { isEditMode, isShowMode } = useCurrentMode(mode);
  const { getApprovedValue, getCurrentValue, getOldValue } = usePartnerData();
  const linkInformationDisplayExample = userConfigurations.get('INFORMATION_DISPLAY_EXAMPLE');

  const handleUploadLogo = useCallback((config) => {
    dispatch(partnerAsyncAction.uploadPartnerLogo(config));
  }, [dispatch]);

  if ((isLoading || postUsersHashIdPending) && (isEditMode || isShowMode)) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  const {
    approve,
    approveInfoField,
    hashId,
    ignoreValidateFields,
  } = props;

  const detailedInformationApprovedValue = getApprovedValue('detailedInformation');
  const warrantyAndReturnApprovedValue = getApprovedValue('warrantyAndReturn');
  const workScheduleApprovedValue = getApprovedValue('workSchedule');
  const smallLogoApprovedValue = getApprovedValue('smallLogo');
  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {linkInformationDisplayExample && (
          <ButtonWithIcon fullWidth text={t('Пример отображения информации')} target="_blank" href={linkInformationDisplayExample} component="button">
            <EyeIcon />
          </ButtonWithIcon>
          )}
          {/* delete because of http://jira.nautilus.allo.ua/browse/AMP-625 */}
          {/* <Field
            options={[
              { label: t('1-3 дня'), value: '1-3 дня' },
              { label: t('3-5 дней'), value: '3-5 днів' },
              { label: t('5-7 дней'), value: '5-7 днів' },
              { label: t('7-14 дней'), value: '7-14 днів' },
            ]}
            margin="none"
            variant="standard"
            formSelectWidth="100%"
            component={SelectField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="deliveryTime"
            label={t('Срок доставки')}
            validate={ignoreValidateFields ? [validators.maxLength256] : [validators.required, validators.maxLength256]}
            required={!ignoreValidateFields}
          /> */}

          <Field
            approve={approve}
            component={FormTextAreaOnlyUkr}
            disabled={isShowMode}
            name="partnerInfo"
            id="partnerInfo"
            label={`${t('Доставка и оплата')}:`}
            fullWidth
            multiline
            rows={5}
            margin="normal"
            variant="outlined"
            value={getCurrentValue('partnerInfo')}
            valueOld={getOldValue('partnerInfo')}
            approvedValue={getApprovedValue('partnerInfo')}
            onApproveValue={approveInfoField}
            validation
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            approve={approve}
            component={FormTextAreaOnlyUkr}
            disabled={isShowMode}
            name="warrantyAndReturn"
            id="warrantyAndReturn"
            label={`${t('Гарантия и возврат')}:`}
            fullWidth
            multiline
            rows={5}
            margin="normal"
            variant="outlined"
            value={getCurrentValue('warrantyAndReturn')}
            valueOld={getOldValue('warrantyAndReturn')}
            approvedValue={warrantyAndReturnApprovedValue}
            onApproveValue={approveInfoField}
            validation
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            approve={approve}
            component={FormTextAreaOnlyUkr}
            disabled={isShowMode}
            name="workSchedule"
            id="workSchedule"
            label={`${t('График работы')}:`}
            fullWidth
            multiline
            rows={5}
            margin="normal"
            variant="outlined"
            value={getCurrentValue('workSchedule')}
            valueOld={getOldValue('workSchedule')}
            approvedValue={workScheduleApprovedValue}
            onApproveValue={approveInfoField}
            validation
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FieldCropper
            allowFileTypeValidation
            acceptedFileTypes={['image/png', 'image/x-png', 'image/jpeg', 'image/gif']}
            approve={hasAccessPartnerConfirmForm && getOldValue('smallLogo') !== undefined}
            approvedValue={smallLogoApprovedValue}
            currentImageSrc={getCurrentValue('smallLogo')}
            disabled={isShowMode}
            oldImageSrc={getOldValue('smallLogo')}
            onApproveValue={approveInfoField}
            compareImage={approve}
            name="smallLogo"
            hashId={hashId}
            formName={formName}
            textBefore={t('Логотип на сайте АЛЛО')}
            desc={t('Если у вас нет возможности загрузить логотип сразу, вы можете это сделать после')}
            className={classes.uploadLogo}
            sendForm={handleUploadLogo}
            aspectRatioWidth={22}
            aspectRatioHeight={5}
            labelFileTypeNotAllowed={t('Тип файла не валидный')}
          />
          <Field
            approve={approve}
            component={FormTextAreaOnlyUkr}
            disabled={isShowMode}
            name="detailedInformation"
            id="detailedInformation"
            label={`${t('Детальная информация о Продавце')}:`}
            fullWidth
            multiline
            rows={12}
            margin="normal"
            variant="outlined"
            value={getCurrentValue('detailedInformation')}
            valueOld={getOldValue('detailedInformation')}
            approvedValue={detailedInformationApprovedValue}
            onApproveValue={approveInfoField}
            validation
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
        </Grid>
      </Grid>
      {mode !== 'create' && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OrderProcessing hashId={hashId} mode={mode} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <DeliveryDelayPeriod mode={mode} hashId={hashId} />
          </Grid>
        </Grid>
      )}
    </TabsPanel>
  );
}

PartnerEditSite.propTypes = {
  approve: PropTypes.bool,
  postUsersHashIdPending: PropTypes.bool,
  hashId: PropTypes.string,
  approveInfoField: PropTypes.func,
  classes: PropTypes.object,
  display: PropTypes.oneOf(['flex', 'none']),
  isLoading: PropTypes.bool,
  ignoreValidateFields: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'show']),
  userConfigurations: PropTypes.object,
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditSite.defaultProps = {
  mode: 'edit',
  ignoreValidateFields: false,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.partner.partnerIsLoading(state),
    pathname: selectors.router.pathname(state),
    hasAccessPartnerConfirmForm: selectors.user.accessList(state).includes(accessList.partnerConfirmForm),
    userConfigurations: selectors.user.configurations(state),
    postUsersHashIdPending: selectors.user.postUsersHashIdPending(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approveInfoField: approve => dispatch(partnerActions.approveInfoField(approve)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEditSite);
