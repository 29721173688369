// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getFeedsListAsync(params) {
    return getActionCreator(asyncTypes.GET_FEEDS_LIST_ASYNC, params);
  },
  putFeedsSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_FEEDS_SETTINGS_ASYNC, param);
  },
  getFeedsStatusesListAsync(param) {
    return getActionCreator(asyncTypes.GET_FEEDS_STATUSES_LIST_ASYNC, param);
  },
  getFeedsInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_FEEDS_INITIAL_STATE_ASYNC, path);
  },
  getFeedItemByHashIdAsync(path) {
    return getActionCreator(asyncTypes.GET_FEED_BY_HASH_ID_ASYNC, path);
  },
  putFeedItemByHashId(param) {
    return getActionCreator(asyncTypes.PUT_FEED_ITEMS_BY_HASH_ID_ASYNC, param);
  },
  getFeedsPrioritiesAsync() {
    return getActionCreator(asyncTypes.GET_FEEDS_PRIORITIES_LIST_ASYNC);
  },
  getFeedsTypesAsync() {
    return getActionCreator(asyncTypes.GET_FEEDS_TYPES_LIST_ASYNC);
  },
  putFeedsImportAsync(params) {
    return getActionCreator(asyncTypes.GET_FEEDS_IMPORT_ASYNC, params);
  },
  getFeedsExportAsync(params) {
    return getActionCreator(asyncTypes.GET_FEEDS_EXPORT_ASYNC, params);
  },
  postRefreshAsync(data) {
    return getActionCreator(asyncTypes.POST_FEEDS_REFRESH_ASYNC, data);
  },
  deleteFeedByHashId(hashId) {
    return getActionCreator(asyncTypes.DELETE_FEEDS_BY_HASH_ID_ASYNC, hashId);
  },
  postRefreshPriceListAsync(hashId) {
    return getActionCreator(asyncTypes.POST_FEEDS_REFRESH_PRICE_LIST_ASYNC, hashId);
  },
  getCompletionImportListAsync(data) {
    return getActionCreator(asyncTypes.GET_FEED_COMPLETION_IMPORT_LIST_ASYNC, data);
  },
  putCompletionImportListAsync(data) {
    return getActionCreator(asyncTypes.PUT_FEED_COMPLETION_IMPORT_LIST_ASYNC, data);
  },
  putCompletionImportListItemAsync(data) {
    return getActionCreator(asyncTypes.PUT_FEED_COMPLETION_IMPORT_LIST_ITEM_ASYNC, data);
  },
});
