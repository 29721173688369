// Core
import React, { memo, useMemo } from 'react';
import { Field } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
// Parts
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';
import StatusIcon from '../../StatusIcon';
import CheckBoxForm from '../../../../../../../components/_Form/Checkbox/CheckBoxForm';
// Helpers
import { formName } from '../ProductFeedCategoriesTable';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
// Hooks
import { useAccessList } from '../../../../../../../hooks/useAccessList';
// Engine
import { productFeedCategoriesAttributesConst } from '../../../../../../../../engine/core/products/const';
import { accessList } from '../../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../../../engine/core/products/productFeedCategories/saga/asyncActions';
import { asyncActions as feedActions } from '../../../../../../../../engine/core/feeds/all/saga/asyncActions';
import EditableContainer
  from '../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import CategorySelect from './CategorySelect';
// import {
//   BlockIconWrapper,
// } from '../../../../../Content/ContentProducts/components/ContentProductTable/ContentProductTableCell/Components/BlockIconWrapper';

function TableCell(props) {
  const {
    column,
    row,
    value,
    ...restProps
  } = props;
  const { hashId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValue = getFormValues.toJS();
  const inAutoUpdateProcess = row?.categoryStatus?.value === productFeedCategoriesAttributesConst.AUTO_CONNECT_STATUS;
  const currentProductFeedCategory = normalizeFormValue[`${row.id}_category`];
  const currentIgnored = normalizeFormValue[`${row.id}_ignored`];
  const canEdit = useSelector(selectors.feeds.isItemByHashIdItem).get('canEdit');
  const stepsData = useSelector(selectors.feeds.isItemByHashIdItem).get('stepsData');
  const productFeedCategoryStatuses = stepsData[2].productFeedCategoryStatuses;
  const hasAccessProductFeedAutoConnect = useAccessList(accessList.productFeedAutoConnect);
  const getCurrentDesc = statusId => productFeedCategoryStatuses.filter(item => item.value === statusId)[0]?.desc;
  const isTranslationNeeded = column.name === 'category' && row?.language !== 'ru';
  const categoriesList = useSelector(selectors.categories.categoriesListContext);
  const pickableCategoriesList = categoriesList.toJS().filter(category => !category.hasChildren);

  const translatedCategoriesList = useMemo(() => pickableCategoriesList.map(category => ({
    ...category,
    label: `${category.idMagentoCategory} | ${category.nameUa ?? category.name}`,
  })), [categoriesList]);

  /**  add dummy option for Category Select to be able to show tooltips for the last element */
  const dummyOption = { id: -1, label: '' };

  const options = isTranslationNeeded ? [...translatedCategoriesList, dummyOption, dummyOption] : [...pickableCategoriesList, dummyOption, dummyOption];


  const handleChangeIgnore = (event) => {
    dispatch(asyncActions.putItemByHashIdAsync({
      feedHashId: hashId,
      hashId: row.hashId,
      ignored: Boolean(event.target.checked),
      category: currentProductFeedCategory?.[0]?.value,
    }));
    dispatch(feedActions.getFeedItemByHashIdAsync(hashId));
  };
  const handleChangeCategory = (categoryValue) => {
    dispatch(asyncActions.putItemByHashIdAsync({
      feedHashId: hashId,
      hashId: row.hashId,
      ignored: Boolean(currentIgnored),
      category: categoryValue?.[0]?.value,
      hasAccessProductFeedAutoConnect,
    }));
    dispatch(feedActions.getFeedItemByHashIdAsync(hashId));
  };

  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === 'productFeedCategory'
        && (
          <EditableContainer isEditable={false} text={value.label} fullText={false}>
            {value.label}
          </EditableContainer>
        )
        }
      {column.name === 'category' && (
        <Field
          isMulti={false}
          disabled={Boolean(!row.canEdit || !canEdit || currentIgnored || inAutoUpdateProcess)}
          handleChange={handleChangeCategory}
          onlyChild
          onlyNotPortal
          canClear={false}
          component={CategorySelect}
          variant="outlined"
          label=""
          placeholder={t('Выбрать')}
          formName={formName}
          marginBottom={false}
          name={`${row.id}_category`}
          options={options}
          optionsLanguage={row?.language}
          selectedCategory={currentProductFeedCategory?.[0]}
        />
      )}
      {column.name === 'ignored' && (
        <Field
          disabled={Boolean(!row.canIgnore || !row.canEdit || !canEdit || inAutoUpdateProcess)}
          component={CheckBoxForm}
          handleChange={handleChangeIgnore}
          label={t('Игнорировать')}
          name={`${row.id}_ignored`}
        />
      )}
      {column.name === 'categoryStatus' && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StatusIcon iconName={value.icon} />
          <Box sx={{ ml: 1 }}>{value.label}</Box>
          {getCurrentDesc(value.value) && (
            <Tooltip title={getCurrentDesc(value.value)} arrow>
              <InfoOutlinedIcon sx={{
                cursor: 'pointer',
                fill: COLOR.black.default,
                marginLeft: 'auto',
                fontSize: '18px',
              }}
              />
            </Tooltip>
          )}
        </Box>
      )}
      {column.name === 'feedProductQuantity' && <span>{value}</span> }
    </VirtualTable.Cell>
  );
}

export default memo(TableCell);
