// Core
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { change } from 'redux-form';
// Parts
import Box from '@mui/material/Box';
import Select from '../../../../../../components/_Form/Selects/Select/Select';
import PartnersNotFound from './PartnersNotFound';
// Engine
import { partnerAsyncAction } from '../../../../../../../engine/core/_partners/saga/asyncAction';
import { actions } from '../../../../../../../engine/core/messages/actions';
import { selectors } from '../../../../../../../engine/config/selectors';
// Helpers
import { useMessageReadOrEditPage } from '../../../_hooks/useMessageReadOrEditPage';
import { useRecipientsImportItems } from './_hooks/useRecipientsImportItems';
import { messageFormName } from '../../form';
import { recipientsTypes } from '../../../_helpers/recipientsTypes';

function PartnerMultipleField(props) {
  const { index } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentPartnersFound } = useRecipientsImportItems(index);
  const { isReadMessage } = useMessageReadOrEditPage();
  const partnerListContext = useSelector(selectors.partner.listContext);

  const input = useMemo(() => ({
    value: currentPartnersFound,
    onChange: (values) => {
      dispatch(change(messageFormName, `messageUsers[${index}].values`, values));

      dispatch(actions.mergeMessageUserTypesByTypeItems({
        [recipientsTypes.currentPartner]: values,
      }));
      dispatch(actions.mergeRecipientsImportItems({
        itemsFound: values,
      }));
    },
  }), [currentPartnersFound]);

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnerContextListAsync());
  }, [dispatch]);

  return (
    <Box sx={{ flexGrow: 1, width: '50%' }}>
      <Select
        isMulti
        isClearable
        hasSearch
        hasConfirmButton
        closeMenuOnSelect={false}
        options={partnerListContext}
        placeholder=""
        input={input}
        defaultValue={currentPartnersFound}
        label={t('Партнеры')}
        variant="outlined"
        disabled={isReadMessage}
      />
      <PartnersNotFound />
    </Box>
  );
}

PartnerMultipleField.propTypes = {
  index: PropTypes.number.isRequired,
};

export default PartnerMultipleField;
