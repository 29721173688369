// Core
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// Parts
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import {
  Grid, Table, TableHeaderRow, VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import TableCell from './TableCell';
import NoDataCell from '../../../../../../../components/_Table/DxTable/components/NoDataCell';
// Core
import { asyncActions } from '../../../../../../../../engine/core/products/productFeedCategoriesAttributes/saga/asyncActions';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { usePrevious } from '../../../../../../../hooks/usePrevious';

export const FEATURES_TYPES = Object.freeze({
  MULTI_SELECT: 5,
  SELECT: 6,
});

export const ToggleCellAttributesComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const { onToggle, row, expanded } = props;
  const dispatch = useDispatch();
  const ArrowIcon = expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const featureValue = row?.feature?.value;
  const prevFeatureValue = usePrevious(featureValue);

  useEffect(() => {
    if (featureValue !== prevFeatureValue && expanded) {
      onToggle();
    }
  }, [featureValue, prevFeatureValue, expanded]);

  const onClick = () => {
    if (expanded) {
      onToggle();
    } else {
      dispatch(asyncActions.getAttributesByHashId({
        hashId: row.id,
        setLoading,
        onSuccess: onToggle,
      }));
    }
  };
  return (
    <VirtualTable.Cell {...props}>
      {[FEATURES_TYPES.SELECT, FEATURES_TYPES.MULTI_SELECT].includes(row?.feature?.type) && (
        <IconButton sx={{ width: 40, height: 40 }} disabled={loading} onClick={loading ? undefined : onClick}>
          {loading ? <CircularProgress color="secondary" size={20} /> : ArrowIcon}
        </IconButton>
      )}
    </VirtualTable.Cell>
  );
};

export const RowDetailAttributes = ({ row }) => {
  const { id } = row;
  const rows = useSelector(selectors.productFeedCategoriesAttributes.attributesItems);

  return (
    <DetailItemsTable
      id={id}
      type={row.feature.type}
      rows={rows.get(id).items}
    />
  );
};
function DetailItemsTable(props) {
  const { rows, id, type } = props;
  const { t } = useTranslation();
  const columns = [
    { name: 'attributeValue', title: t('Значение характеристик в файле') },
    { name: 'featureValues', title: t('Значение характеристик Алло') },
    { name: undefined, title: undefined },
    { name: 'status', title: t('Статус') },
  ];
  const columnExtensions = [
    { columnName: 'attributeValue', width: '22%' },
    { columnName: 'featureValues', width: '24%' },
    { columnName: 'status', width: 240 },
  ];
  const PatchedCellComponent = useCallback(cellProps => <TableCell {...cellProps} id={id} type={type} />, [type]);
  let position = 0;

  return (
    <Paper sx={{ margin: '5px -5px 5px 56px' }}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={row => `${position++}_${row.id}_${row?.feature?.type}`}
      >
        <Table
          getRowId={row => `${position}_${row.id}_${row?.feature?.type}`}
          cellComponent={PatchedCellComponent}
          columnExtensions={columnExtensions}
          noDataCellComponent={NoDataCell}
        />
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
}
