// Core
import { apply, put, select } from 'redux-saga/effects';
import { isImmutable } from 'immutable';
// Engine
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { logisticsDataByCategory } from '../../selectors';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

export function* callPutItemByHashIdWorker({ payload }) {
  const { hashId, parentId, values } = payload;
  yield put(actions.setItemByHashIdPutPending(true));
  const fixedPayload = {
    hashId,
    data: {
      width: Number(values.logisticDataCategoryWidth),
      length: Number(values.logisticDataCategoryLength),
      height: Number(values.logisticDataCategoryHeight),
      weight: values.logisticDataCategoryWeight,
      ean: values.logisticDataCategoryEan,
    },
  };
  const response = yield apply(api, api.logisticsDataByCategory.putLogisticsItemByHashId, [fixedPayload]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setItemByHashIdMode(null));
        const pageSize = yield select(logisticsDataByCategory.pageSize);
        const currentPage = yield select(logisticsDataByCategory.currentPage);
        const sorting = (yield select(logisticsDataByCategory.sorting)).toJS();
        const filters = (yield select(logisticsDataByCategory.filters)).toJS();
        const treeDataRowIds = yield select(logisticsDataByCategory.treeDataRowIds);
        const getTreeDataRowIds = isImmutable(treeDataRowIds) ? treeDataRowIds.toJS() : treeDataRowIds;
        const isRoot = parentId === '';
        const treeDataRowsWithRoot = isRoot
          ? [0, ...getTreeDataRowIds]
          : getTreeDataRowIds;
        const params = {
          forceRoot: isRoot,
          limit: pageSize,
          offset: currentPage * pageSize,
          sorting,
        };
        filters.reduce((acc, { columnName, value: filterValue }) => {
          acc[columnName] = filterValue;
          return acc;
        }, params);
        yield put(asyncActions.getListAsync(params, treeDataRowsWithRoot));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle, true));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setItemByHashIdPutPending(false));
}
