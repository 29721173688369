// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';

// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../config/selectors';
import { priceCreatorActions } from '../../action';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetPriceListDownloadWorker({ payload }) {
  yield put(priceCreatorActions.setPriceListDownloadPending(true));

  const filters = yield select(selectors.contentProduct.filters);
  const sorting = yield select(selectors.contentProduct.sorting);

  const params = {
    partnerId: payload,
    sorting: sorting.toJS(),
  };

  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  const response = yield apply(api, api.products.postPriceListDownload, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case 'success': {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      case 'error': {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        getFileFromBlob('priceCreator.example.xls', response);
        break;
      }
    }
  }

  yield put(priceCreatorActions.setPriceListDownloadPending(false));
}
