// Core
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import * as PropTypes from 'prop-types';
// UI
import Grid from '@mui/material/Grid';

function ReactPDF(props) {
  const {
    classes, display, documentPageWidth, file, pages, tabContractRef,
  } = props;

  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      container
      spacing={3}
      className={classes.tabsContent}
      sx={{ minHeight: '320px' }}
      style={{ display }}
    >
      <div className={classes.documentWrapperForRef} ref={tabContractRef}>
        <Document
          file={file}
        >
          {/* Create array [1 to N] for render pages */}
          {[...Array(pages + 1).keys()].slice(1).map((page, index) => (
            <Page
              key={`pdf_page_${index}`}
              pageNumber={index + 1}
              width={documentPageWidth}
            />
          ))}
        </Document>
      </div>
    </Grid>
  );
}

ReactPDF.propTypes = {
  classes: PropTypes.object,
  display: PropTypes.string,
  documentPageWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  file: PropTypes.string,
  pages: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  tabContractRef: PropTypes.object,
};

export default ReactPDF;
