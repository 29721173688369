// Core
import React, { memo } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import * as PropTypes from 'prop-types';
// Parts
import TableHeaderRowLabel from '../TableHeaderRowLabel';
import HeaderCellComponent from './components/HeaderCellComponent';
import HeaderCellOrderComponent from './components/HeaderCellOrderComponent';

function TableHeaderRowPatched(props) {
  const {
    settingStatus, customHeaderCellComponent, ...restProps
  } = props;

  const OriginHeaderCellComponent = settingStatus
    ? HeaderCellOrderComponent
    : HeaderCellComponent;

  return (
    <TableHeaderRow
      {...restProps}
      sortLabelComponent={TableHeaderRowLabel}
      cellComponent={customHeaderCellComponent || OriginHeaderCellComponent}
    />
  );
}

TableHeaderRowPatched.propTypes = {
  settingStatus: PropTypes.bool,
  classes: PropTypes.object,
  customHeaderCellComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default memo(TableHeaderRowPatched);
