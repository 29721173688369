// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_TRANSACTIONS_PAYMENT_LIST_ASYNC, params);
  },
  getTransactionPaymentByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_TRANSACTION_PAYMENT_INFO_ASYNC, hashId);
  },
  getTransactionsPaymentStatusesAsync() {
    return getActionCreator(asyncTypes.GET_TRANSACTIONS_PAYMENT_STATUSES_ASYNC);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_TRANSACTIONS_PAYMENT_SETTINGS_ASYNC, param);
  },
  putTransactionPaymentAsync(data) {
    return getActionCreator(asyncTypes.PUT_TRANSACTION_PAYMENT_ASYNC, data);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_TRANSACTIONS_PAYMENT_INITIAL_STATE_ASYNC, path);
  },
});
