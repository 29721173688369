// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import confirmButtonsStyles from '../../../../../../components/Buttons/ConfirmButtons/ConfirmButtonsStyles';
import { actions } from '../../../../../../../engine/core/categoriesFeatureManagement/categoriesFeatureLogs/actions';
import { accessList } from '../../../../../../../engine/config/access';
import { useAccessList } from '../../../../../../hooks/useAccessList';

const useStyles = makeStyles(confirmButtonsStyles);


function CategoriesFeatureBtnLogs(props) {
  const {
    hashId,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const hasCategoriesFeatureManagementHistory = useAccessList(accessList.categoriesFeatureManagementHistory);

  const handleLog = () => {
    dispatch(actions.getCategoriesFeatureLogsRefresh({ showModal: true, hashId }));
  };

  if (!hasCategoriesFeatureManagementHistory) {
    return null;
  }

  return (
    <Button
      sx={{ p: '0px' }}
      className={classes.selectWidthConfirmClose}
      onClick={handleLog}
    >
      <SmsOutlined />
    </Button>
  );
}

CategoriesFeatureBtnLogs.propTypes = {
  hashId: PropTypes.string,
};

export default CategoriesFeatureBtnLogs;
