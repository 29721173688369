// Core
import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// Parts
import Grid from '@mui/material/Grid';
import FilesUploader from '../../../components/_Form/FilesUploader/FilesUploader';
import Loading from '../../../components/Loading/Loading';
import ReactPDF from './ReactPDF';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { accessList } from '../../../../engine/config/access';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';

import TabsPanel from '../../../containers/Tabs/TabsPanel';

function PartnerEditContract(props) {
  const {
    classes, display, isLoading, index, value,
  } = props;
  const { t } = useTranslation();
  const tabContractRef = useRef();
  const [documentPageWidth, setDocumentPageWidth] = useState(null);

  const setWidth = useCallback(() => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      const width = tabContractRef && tabContractRef.current && tabContractRef.current.getBoundingClientRect().width;
      if (display !== 'none') {
        setDocumentPageWidth(width);
      }
    }, 100);
  }, [display, tabContractRef]);

  useEffect(() => {
    if (display !== 'none') {
      setWidth();
    }
  }, [display, setWidth]);

  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return function cleanup() {
      window.removeEventListener('resize', setWidth);
    };
  }, [setWidth]);

  if (isLoading) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  const {
    handleUploadContract, hashId, mode,
    partnerContractLink, progressLoadingContract, partnerContractPage, partnerStatus, hasAccessPartnerUploadContract,
  } = props;
  const partnerStatusValue = get(partnerStatus, 'value', null);

  const isShowMode = mode === 'show';

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item lg={1} />
        <Grid item xs={12} lg={10}>
          {hasAccessPartnerUploadContract && (
          <Field
            allowFileTypeValidation
            acceptedFileTypes={['application/pdf']}
            disabled={isShowMode || ((partnerStatusValue !== partnerStatuses.contractSigning) && (partnerStatusValue !== partnerStatuses.active))} // 5 => Подписание договора;  6 => Активный;
            component={FilesUploader}
            name="contract"
            textBefore2={t('Форматом договора PDF')}
            uploadPartnerContract={handleUploadContract}
            hashID={hashId}
            isLoading={progressLoadingContract}
          />
          )}
          {typeof partnerContractLink === 'string' && typeof partnerContractPage === 'number' && (
          <ReactPDF
            file={partnerContractLink}
            classes={classes}
            documentPageWidth={documentPageWidth}
            pages={partnerContractPage}
            setDocumentPageWidth={setDocumentPageWidth}
            tabContractRef={tabContractRef}
          />
          )}
        </Grid>
        <Grid item lg={1} />
      </Grid>
    </TabsPanel>
  );
}

PartnerEditContract.propTypes = {
  classes: PropTypes.object.isRequired,
  hashId: PropTypes.string,
  progressLoadingContract: PropTypes.bool,
  partnerContractLink: PropTypes.string,
  partnerContractPage: PropTypes.number,
  handleUploadContract: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  display: PropTypes.oneOf(['flex', 'none']),
  partnerStatus: PropTypes.object,
  hasAccessPartnerUploadContract: PropTypes.bool,
  mode: PropTypes.oneOf(['edit', 'show', 'create']),
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditContract.defaultProps = {
  mode: 'edit',
};

const mapStateToProps = state => ({
  partnerStatus: selectors.partner.userData(state).partnerStatus,
  isLoading: selectors.partner.partnerIsLoading(state),
  partnerContractPage: selectors.partner.partnerContractPage(state),
  partnerContractLink: selectors.partner.partnerContractLink(state),
  progressLoadingContract: selectors.ui.progressLoadingContract(state),
  hasAccessPartnerUploadContract: selectors.user.accessList(state).includes(accessList.partnerUploadContract),
});
export default connect(mapStateToProps)(PartnerEditContract);
