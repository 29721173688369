// Core
import React, { useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// UI
import Tooltip from '@mui/material/Tooltip/Tooltip';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { IconButtonStyles } from '../components/_Table/TableCell/Styles';

function CopyToClipboardComponent(props) {
  const {
    link, className, disabled, iconComponent, tooltipText, isTableEdit,
    arrowTooltip,
  } = props;
  const { t } = useTranslation();
  const [linkText, setLinkText] = useState(tooltipText);
  const timerToClearSomewhere = useRef(null);

  const onCopy = () => {
    setLinkText(t('Готово'));
    timerToClearSomewhere.current = setTimeout(() => setLinkText(tooltipText), 5000);
  };

  return (
    <Tooltip title={link ? t(linkText) : ''} aria-label={linkText} arrow={arrowTooltip}>
      <Box className={className}>
        <CopyToClipboard text={link} onCopy={onCopy} disabled={disabled}>
          {isTableEdit ? (
            <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
              {iconComponent}
            </IconButtonStyles>
          ) : (
            <IconButton
              sx={{
                borderRadius: '3px',
                padding: 0,
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
              }}
              color="inherit"
            >
              {iconComponent}
            </IconButton>
          )}
        </CopyToClipboard>
      </Box>
    </Tooltip>
  );
}

CopyToClipboardComponent.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconComponent: PropTypes.node,
  arrowTooltip: PropTypes.bool,
  isTableEdit: PropTypes.bool,
};

CopyToClipboardComponent.defaultProps = {
  disabled: false,
  iconComponent: <LinkOutlinedIcon sx={{ p: '2px' }} />,
  className: '',
  link: '',
  arrowTooltip: false,
};

export default memo(CopyToClipboardComponent);
