import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../../hooks/useAccessList';
import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';


const TableEditCell = (props) => {
  const { tableRow } = props;
  const hasAccessEdit = useAccessList(accessList.partnerEdit);
  const hasAccessShow = useAccessList(accessList.partnerShow);
  const style = { display: 'flex', justifyContent: 'center', gap: '8px' };

  return (
    <div style={style}>
      {hasAccessEdit ? (
        <Link to={pageLinks.partner.edit(tableRow.row.hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
            <ModeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </Link>
      ) : hasAccessShow && (
        <Link to={pageLinks.partner.show(tableRow.row.hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
            <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </Link>
      )}
    </div>
  );
};

export default TableEditCell;
