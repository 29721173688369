import MaskedInput from 'react-text-mask';

const IbanMaskInput = (props) => {
  const mask = ['U', 'A', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  return (
    <MaskedInput
      {...props}
      mask={mask}
      placeholderChar="_"
    />
  );
};

export default IbanMaskInput;
