// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
// Icons
import Icon from '@mui/material/Icon';
// Parts
import RemoveProductFeatureButton from './components/RemoveProductFeatureButton';
import HeaderComponentToolTip from './components/HeaderTooltip';
// Engine
import { accessList } from '../../../../engine/config/access';
// Helpers
import HeaderWithTooltipStyles from './HeaderWithTooltipStyles';
import { useAccessList } from '../../../hooks/useAccessList';

const useStyles = makeStyles(HeaderWithTooltipStyles);

function HeaderWithTooltip(props) {
  const { categoryId } = useParams();
  const {
    column, children, isHiddenTooltip, ...restProps
  } = props;
  const classes = useStyles();
  const hasProductSettingEditAccess = useAccessList(accessList.productSettingEdit);

  switch (true) {
    case !column.active && categoryId: {
      return (
        <TableHeaderRow.Cell
          {...restProps}
          draggingEnabled={false}
          resizingEnabled={false}
        >
          <div className={classes.tableHeader}>
            <div className={cx({ [classes.tableHeaderLabel]: hasProductSettingEditAccess })}>
              {column.isRequiredLk && <Icon color="primary" className={classes.requiredIcon}>*</Icon>}
              {column.isRequiredMg && <Icon color="secondary" className={classes.recommendedIcon}>*</Icon>}
              {column.recommended && <Icon color="secondary" className={classes.recommendedIcon}>*</Icon>}
              <HeaderComponentToolTip isHiddenTooltip={isHiddenTooltip} column={column}>
                {children}
              </HeaderComponentToolTip>
            </div>
            {categoryId && (
              <RemoveProductFeatureButton
                categoryId={categoryId}
                featureId={column.name}
              />
            )}
          </div>
        </TableHeaderRow.Cell>
      );
    }
    default: {
      return (
        <TableHeaderRow.Cell
          column={column}
          {...restProps}
          draggingEnabled={false}
          resizingEnabled={false}
        >
          {column.isRequiredLk && <Icon color="primary" className={classes.requiredIcon}>*</Icon>}
          {column.isRequiredMg && <Icon color="secondary" className={classes.recommendedIcon}>*</Icon>}
          {column.recommended && <Icon color="secondary" className={classes.recommendedIcon}>*</Icon>}
          <HeaderComponentToolTip isHiddenTooltip={isHiddenTooltip} column={column}>
            {children}
          </HeaderComponentToolTip>
        </TableHeaderRow.Cell>
      );
    }
  }
}
HeaderWithTooltip.propTypes = {
  column: PropTypes.object,
  children: PropTypes.node,
  isHiddenTooltip: PropTypes.bool,
};
HeaderWithTooltip.defaultProps = {
  isHiddenTooltip: false,
};
export default memo(HeaderWithTooltip);
