import React, {
  useState, memo, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
// Icon
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FlipRoundedIcon from '@mui/icons-material/FlipRounded';
import CheckIcon from '@mui/icons-material/Check';
// Parts
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import TextField from '../TextField/TextField/TextField';
// Helpers
import FormTextAreaStyle from './FormTextAreaStyle';
import { useStyles } from '../../../hooks/useStyles';

function FormTextArea(props) {
  const {
    fullWidth, smallMarginTop, margin, input,
    valueOld, onChange, label, disabled, meta,
    approve, onApproveValue, approvedValue, onPaste, isDisplayBlock,
    color, required, descriptionComponent, isRecommended, validation,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(FormTextAreaStyle);
  const [compare, setCompare] = useState(false);
  const isApprove = useCallback(value => approvedValue === value, [approvedValue]);
  const wrapperClassNames = cx({
    [classes.wrapper]: !isDisplayBlock,
    [classes.smallMarginTop]: smallMarginTop,
  });

  const handlePaste = (event) => {
    if (input.onPaste) {
      input.onPaste(event);
    }
    if (onPaste) {
      onPaste(event);
    }
  };

  const handleCompare = () => {
    setCompare(!compare);
  };

  const handleApproveValue = approveValue => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value: approveValue });
    }
  };

  const inputProps = compare ? {
    value: valueOld || '',
    onChange,
  } : input;

  return (
    <FormControl
      className={wrapperClassNames}
      fullWidth={fullWidth}
    >
      <TextField
        label={label}
        fullWidth={fullWidth}
        multiline
        disabled={compare ? true : disabled}
        rows={2}
        color={color}
        input={inputProps}
        margin={margin}
        meta={meta}
        validation={validation}
        onPaste={handlePaste}
        required={required}
        isRecommended={isRecommended}
        descriptionComponent={descriptionComponent}
      />
      {(approve && valueOld !== undefined) && (
        <div className={classes.wrapperButton}>
          <Tooltip title={t('Показать прошлые данные')} placement="top">
            <IconButton
              className={cx(classes.button, classes.compareButton, {
                [classes.compareButtonActive]: compare,
              })}
              onClick={handleCompare}
              aria-label={t('Показать прошлые данные')}
              size="large"
            >
              <FlipRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Подтвердить')} placement="top">
            <IconButton
              className={cx(classes.button, classes.checkButton, {
                [classes.checkButtonActive]: isApprove('current'),
              })}
              onClick={handleApproveValue('current')}
              aria-label="Подтвердить"
              size="large"
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Отклонить')} placement="top">
            <IconButton
              className={cx(classes.button, classes.cancelButton, {
                [classes.cancelButtonActive]: isApprove('old'),
              })}
              onClick={handleApproveValue('old')}
              aria-label="Отклонить"
              size="large"
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

FormTextArea.propTypes = {
  approvedValue: PropTypes.any,
  fullWidth: PropTypes.bool,
  isDisplayBlock: PropTypes.bool,
  required: PropTypes.bool,
  smallMarginTop: PropTypes.bool,
  margin: PropTypes.string,
  color: PropTypes.oneOf(['secondary', 'dark']),
  valueOld: PropTypes.string,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  descriptionComponent: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  isRecommended: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onPaste: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.element,
  }),
  validation: PropTypes.bool,
  // Approve
  approve: PropTypes.bool,
  onApproveValue: PropTypes.func,
};

FormTextArea.defaultProps = {
  meta: {},
};

export default memo(FormTextArea);
