// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_CURRENT_PAGE, currentPage);
  },
  setReload(currentPage) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_RELOAD, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_PENDING, settingPending);
  },
  setLogisticsDataByCategoryItem(categoryItem) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM, categoryItem);
  },
  setLogisticsDataByCategoryTreeDataRowIds(ids) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_TREE_DATA_ROW_IDS, ids);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_SELECTION, selection);
  },
  mergeLogisticsDataByCategoryImport(data) {
    return getActionCreator(types.MERGE_LOGISTICS_DATA_BY_CATEGORY_IMPORT, data);
  },
  mergeLogisticsDataByCategoryExport(data) {
    return getActionCreator(types.MERGE_LOGISTICS_DATA_BY_CATEGORY_EXPORT, data);
  },
  setItemByHashIdData(data) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_DATA, data);
  },
  setItemByHashIdMode(mode) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_MODE, mode);
  },
  setItemByHashIdPendingId(hashId) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_PENDING_ID, hashId);
  },
  setItemByHashIdPutPending(data) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_PUT_PENDING, data);
  },
  setLogs(data) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_LOGS, data);
  },
  setLogsRefresh(data) {
    return getActionCreator(types.SET_LOGISTICS_DATA_BY_CATEGORY_LOGS_DATA, data);
  },
});
