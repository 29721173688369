// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC, params);
  },
  getItemAsync(hashID) {
    return getActionCreator(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_BY_HASH_ASYNC, hashID);
  },
  deleteAsync(data) {
    return getActionCreator(asyncTypes.DELETE_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC, data);
  },
  putItemAsync(data) {
    return getActionCreator(asyncTypes.PUT_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC, data);
  },
  postItemAsync(data) {
    return getActionCreator(asyncTypes.POST_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC, data);
  },
  getDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_DOWNLOAD_ASYNC, params);
  },
  postUploadAsync(files) {
    return getActionCreator(asyncTypes.POST_STOP_LIST_BRANDS_CATEGORIES_UPLOAD_ASYNC, files);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_STOP_LIST_BRANDS_CATEGORIES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE_ASYNC, path);
  },
});
