// Core
import { put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../config/localData';
import { partnerActions } from '../../action';
import { pageLinks } from '../../../../config/routes';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import i18n from '../../../../init/i18n';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';

export function* callGetPartnersInitialStateWorker({ payload: url }) {
  const partners = yield localData.getItem(url);
  const initialPageState = getInitialPageState(partners || {});

  const partnersAllPage = pageLinks.partner.all;
  const partnersNewPage = pageLinks.partner.new;
  const partnersRejectedPage = pageLinks.partner.rejected;
  const partnersWaitingPage = pageLinks.partner.waiting;

  const newPartnerPageRequiredFilters = [
    { value: partnerStatuses.questionnaireFill, label: i18n.t('Заполнение анкеты') },
  ];

  const rejectedPageRequiredFilters = [
    { value: partnerStatuses.frozenSigning, label: i18n.t('Подписание заморожено') },
    { value: partnerStatuses.cancelStatus, label: i18n.t('Отклонен') },
  ];

  const waitingRequiredFilters = [
    { value: partnerStatuses.contractSigning, label: i18n.t('Подписание договора') },
  ];

  let partnerStatusFiltersIndex = initialPageState.filters.findIndex(({ columnName }) => columnName === 'partnerStatus');

  if (partnerStatusFiltersIndex === -1) {
    const partnerStatusFilters = {
      columnName: 'partnerStatus',
      value: [],
    };
    initialPageState.filters.push(partnerStatusFilters);
    partnerStatusFiltersIndex = initialPageState.filters.length - 1;
  }

  const partnerStatusFilters = initialPageState.filters[partnerStatusFiltersIndex];

  switch (url) {
    case partnersAllPage: {
      break;
    }
    case partnersNewPage: {
      if (!partnerStatusFilters.value.find(({ value }) => value === partnerStatuses.questionnaireFill)) {
        partnerStatusFilters.value.push(newPartnerPageRequiredFilters[0]);
      }
      break;
    }
    case partnersRejectedPage: {
      if (!partnerStatusFilters.value.find(({ value }) => value === partnerStatuses.frozenSigning)) {
        partnerStatusFilters.value.push(rejectedPageRequiredFilters[0]);
      }
      if (!partnerStatusFilters.value.find(({ value }) => value === partnerStatuses.disabled)) {
        partnerStatusFilters.value.push(rejectedPageRequiredFilters[1]);
      }
      break;
    }
    case partnersWaitingPage: {
      if (!partnerStatusFilters.value.find(({ value }) => value === partnerStatuses.contractSigning)) {
        partnerStatusFilters.value.push(waitingRequiredFilters[0]);
      }
      break;
    }
    default: {
      break;
    }
  }

  yield put(partnerActions.setPartnerInitialState(initialPageState));
}
