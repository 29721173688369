import { COLOR } from '../../../containers/App/AppStyles';

const TypographyFieldViewStyles = () => ({
  fieldHolder: {
    position: 'relative',
    color: COLOR.grey['38'],
    border: `1px solid ${COLOR.grey['12']}`,
    marginBottom: 8,
    marginTop: 16,
  },
  field: {
    minHeight: '38px',
    padding: '5px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  label: {
    position: 'absolute',
    left: 0,
    top: 0,
    background: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    margin: 0,
    fontSize: '14px',
    lineHeight: '20px',
    transform: 'translate(10px, 9px)',
  },
  text: {
    alignItems: 'center',
    display: 'flex',
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
  },
  textarea: {
    padding: '11px 15px',
    position: 'relative',
    height: 'auto',
    minHeight: '68px',
    borderRadius: '8px',
  },
  textareaLabel: {
    transform: 'translate(10px, 11px)',
  },
  textareaText: {
    borderRadius: 4,
    margin: 0,
    lineHeight: '22px',
    fontSize: '14px',
  },
  shrinkLabel: {
    transform: 'translate(10px, -10px)',
    fontSize: '12px',
  },
});

export default TypographyFieldViewStyles;
