import { COLOR, Z_INDEX } from '../../containers/App/AppStyles';

const HeaderStyles = theme => ({
  appBar: {
    position: 'relative',
    zIndex: Z_INDEX.header,
    background: COLOR.darkStyle.main,
    color: COLOR.grey.main,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
    },
  },
  toolBar: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 5,
    minHeight: '56px',
  },
  buttonInfoBar: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonNotifications: {
    display: 'inline-flex',
  },
  infoBar: {
    display: 'none',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  infoBarChip: {
    margin: '0 10px 0 0',
    border: 'none',
    padding: '4px 8px',
    background: 'none',
    color: COLOR.darkStyle.fontColorMain,
    '& .MuiChip-label': {
      padding: 0,
    },
    '&:hover, &:focus, &:active': {
      background: 'none',
    },
  },
  infoWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  AuthButton: {
    marginRight: 8,
    flexDirection: 'column',
    color: COLOR.darkStyle.fontColorMain,
    borderRadius: '5px',
    padding: '5px',
    minWidth: '86px',
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
    },
    '& .MuiTypography-root': {
      fontSize: '10px',
      lineHeight: 1,
      fontWeight: 700,
    },
  },
  notificationButton: {
    marginRight: 8,
    flexDirection: 'column',
    color: COLOR.darkStyle.fontColorMain,
    borderRadius: '5px',
    padding: '3px 13px 5px',
    minWidth: '86px',
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
    },
    '& .MuiBadge-root': {
      '& .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
    },
    '& .MuiBadge-badge': {
      fontSize: '13px',
      lineHeight: '13px',
      fontWeight: '400',
      color: '#ffffff',
      background: COLOR.brand.main,
      padding: '0 3px',
      height: '13px',
      left: '14px',
      top: '-1px',
      right: 'auto',
      borderRadius: '5px',
      transform: 'none',
      '&.MuiBadge-invisible': {
        transform: 'scale(0) translate(50%, -50%)',
      },
    },
    '& .MuiTypography-root': {
      fontSize: '10px',
      lineHeight: 1,
      marginTop: '4px',
      fontWeight: 700,
    },
  },
  errorIcon: {
    marginLeft: -5,
    marginRight: 3,
  },
  branding: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '0',
    paddingLeft: '3px',
    flexShrink: 0,
    background: COLOR.darkStyle.secondary,
    alignSelf: 'stretch',
    height: '56px',
    [theme.breakpoints.up('md')]: {
      width: '268px',
    },
  },
  menuButton: {
    padding: '12px',
    '& svg': {
      fill: COLOR.darkStyle.fontColorMain,
    },
  },
  alignStub: {
    marginLeft: 8,
  },
  brandingSimpleHeader: {
    background: 'none',
    paddingLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'unset',
    },
  },
  logo: {
    width: '73px',
  },
  logoDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoMobile: {
    display: 'none',
    maxHeight: '100%',
    maxWidth: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  marketplaceTitle: {
    margin: '0',
    paddingLeft: '11px',
    fontSize: '18px',
    lineHeight: 1,
    color: COLOR.darkStyle.fontColorMain,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideSideBar: {
    width: 'auto',
    paddingLeft: 0,
    '& $logoLink, & $marketplaceTitle': {
      display: 'none',
    },
  },
  logoLink: {
    display: 'flex',
    width: '73px',
    height: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '56px',
    },
  },
  logoLinkDesktop: {
    [theme.breakpoints.down('sm')]: {
      height: '56px',
      width: '56px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  logoLinkMarginLeft: {
    marginLeft: 5,
  },
  serviceSupportBtn: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  userMenuButton: {
    margin: 0,
    padding: '0 9px 0 0',
    border: 0,
    width: '251px',
    background: COLOR.darkStyle.white['8'],
    flexShrink: 0,
    justifyContent: 'start',
    fontSize: '14px',
    lineHeight: '18px',
    color: COLOR.darkStyle.fontColorMain,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 4,
    },
    '& span': {
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    '& > svg': {
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    '& .MuiSvgIcon-root': {
      width: '36px',
      height: '32px',
      color: COLOR.darkStyle.fontColorMain,
      '&$userMenuArrow': {
        width: '16px',
        height: '16px',
        color: COLOR.darkStyle.fontColorMain,
        marginLeft: 'auto',
        [theme.breakpoints.down('md')]: {
          marginLeft: 0,
          width: 15,
          height: 15,
        },
      },
    },
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
      '& .MuiSvgIcon-root': {
        color: COLOR.darkStyle.fontColorMain,
      },
    },
  },
  userMenu: {
    zIndex: 301,
    '& .MuiPaper-root': {
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
      minWidth: '251px',
      left: 'auto !important',
      right: '4px',
      background: COLOR.darkStyle.main,
      zIndex: 1300,
    },
    '& .MuiList-root': {
      padding: '4px 5px',
      background: COLOR.darkStyle.main,
    },
    '& .MuiMenuItem-root': {
      minHeight: '40px',
      borderRadius: '5px',
      padding: '5px 13px',
      '&:hover': {
        background: COLOR.darkStyle.white['8'],
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '5px',
      },
      '& .MuiTypography-root': {
        fontSize: '14px',
        lineHeight: 1,
        color: COLOR.darkStyle.fontColorMain,
      },
      '& .MuiSvgIcon-root': {
        color: COLOR.darkStyle.fontColorMain,
      },
    },
  },
  userMenuArrow: {
    width: '16px',
    height: '16px',
    marginLeft: 'auto',
  },
  userMenuArrowActive: {
    transform: 'rotate(180deg)',
  },
  holderAvatarIcon: {
    background: COLOR.darkStyle.white['24'],
    borderRadius: '5px',
    width: '46px',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    flexShrink: 0,
  },
  link: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  avatar: {
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
    },
  },
  avatarIcon: {
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
    },
  },
});

export default HeaderStyles;
