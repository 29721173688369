import { COLOR } from '../../ui/containers/App/AppStyles';

export const serviceRequestsStatuses = Object.freeze({
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  SIGNING_THE_CONTRACT: 'SIGNING_THE_CONTRACT',
  AWAITING_PAYMENT: 'AWAITING PAYMENT',
  CONNECTED: 'CONNECTED',
  DISABLED: 'DISABLED',
  CANCELED: 'CANCELED',
});

export const cancellationReasons = Object.freeze({
  CANCEL_REASON_CONDITION: 'CANCEL_REASON_CONDITION',
  CANCEL_REASON_LOCATION: 'CANCEL_REASON_LOCATION',
  CANCEL_REASON_OTHER: 'CANCEL_REASON_OTHER',
});
export function isPartnerCanCancelRequest(status) {
  return status === serviceRequestsStatuses.AWAITING_APPROVAL || status === serviceRequestsStatuses.SIGNING_THE_CONTRACT;
}
export function getStatusColor(status) {
  switch (status) {
    case serviceRequestsStatuses.AWAITING_APPROVAL:
      return COLOR.serviceRequestStatusColor.awaitingApprove;
    case serviceRequestsStatuses.CANCELED:
      return COLOR.serviceRequestStatusColor.canceled;
    case serviceRequestsStatuses.CONNECTED:
      return COLOR.serviceRequestStatusColor.connected;
    case serviceRequestsStatuses.SIGNING_THE_CONTRACT:
      return COLOR.serviceRequestStatusColor.signingTheContract;
    case serviceRequestsStatuses.AWAITING_PAYMENT:
      return COLOR.serviceRequestStatusColor.awaitingPayment;
    case serviceRequestsStatuses.DISABLED:
      return COLOR.serviceRequestStatusColor.disabled;
    default:
      return COLOR.grey.main;
  }
}
