import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { memo } from 'react';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AsyncSelect from '../../../../../components/_Form/Selects/AsyncSelect/AsyncSelect';
import { COLOR } from '../../../../../containers/App/AppStyles';
import ButtonWithShadowContainer from '../../../../../components/Buttons/ButtonWithShadowContainer/ButtonWithShadowContainer';
import { selectors } from '../../../../../../engine/config/selectors';
import { formName } from '../../../form';


const MAX_ADDITIONAL_WAREHOUSES = 9;


function RenderAddWarehouses({
  fields, isPartner, fetchOptions, disabledField,
}) {
  const { t } = useTranslation();
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const mainWarehouse = formValues.toJS().mainWarehouse;

  return (
    <>
      <Grid item xs={12}>
        {fields.length > 0 && (
          <>
            <Divider sx={{ marginTop: '8px' }} />
            <Box sx={{
              marginTop: '40px',
              display: 'flex',
              height: '30px',
              alignItems: 'center',
            }}
            >
              <Typography component="span" sx={{ fontWeight: 700, fontSize: '14px' }}> {t('Дополнительные регионы расположения склада')}</Typography>
            </Box>
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        {fields.map((field, index) => (
          <Box
            sx={{
              marginBottom: '20px',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
            }}
            key={uuidv4()}
          >
            <Box sx={{ flex: 1 }}>
              <Field
                component={AsyncSelect}
                isPortal={false}
                isFloatingLabel
                placeholderTop={<Box>{t('Город')}<Box sx={{ color: COLOR.error }} component="span">*</Box></Box>}
                name={field}
                label={t('Статус')}
                errorText={t('Пожалуйста, проверьте правильность ввода города')}
                emptyText={t('Выберите город расположения склада товаров')}
                placeholder={<Box>{t('введите город')}<Box sx={{ color: COLOR.error }} component="span">*</Box></Box>}
                fullWidth
                required
                color="secondary"
                variant="outlined"
                size="normal"
                pattern={/^[А-яЁёҐґЄєІіЇї]+$/}
                loadOptions={fetchOptions}
                isValidationNeeded={isPartner}
                disabled={disabledField}
              />
            </Box>
            {!disabledField && (
            <ButtonWithShadowContainer
              icon={<DeleteOutlineIcon style={{ color: COLOR.error }} />}
              handleClick={() => fields.remove(index)}
              buttonText={t('Удалить')}
              conteinerStyles={{ alignSelf: 'start', marginTop: '4px' }}
              disabled={disabledField}
            />
            )}
          </Box>
        ))}
        {fields.length < MAX_ADDITIONAL_WAREHOUSES && !disabledField && (
          <Tooltip title={!mainWarehouse ? t('Сначала выберите основной город расположения склада') : ''}>
            <Box sx={{ maxWidth: '200px' }}>
              <Button
                sx={{
                  display: 'flex',
                  marginTop: fields.length > 0 ? '24px' : '0px',
                  alignItems: 'center',
                  border: `1px solid ${COLOR.grey[12]}`,
                  padding: '5px 12px',
                  borderRadius: '5px',
                  maxWidth: '200px',
                  '&:hover': {
                    backgroundColor: 'inherit',
                  },
                }}
                disabled={!mainWarehouse || disabledField}
                onClick={() => fields.push()}
                disableRipple
              ><AddCircleIcon color="secondary" fontSize="small" /><Typography sx={{ marginLeft: '8px', fontSize: '14px', color: COLOR.blue.main }}>{t('Добавить еще город')}</Typography>
              </Button>
            </Box>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={6} />
    </>
  );
}


export default memo(RenderAddWarehouses);
