export const types = Object.freeze({
  SET_PRODUCT_GROUPS_LIST: 'SET_PRODUCT_GROUPS_LIST',
  SET_PRODUCT_GROUPS_CURRENT_PAGE: 'SET_PRODUCT_GROUPS_CURRENT_PAGE',
  SET_PRODUCT_GROUPS_FILTERS: 'SET_PRODUCT_GROUPS_FILTERS',
  SET_PRODUCT_GROUPS_PAGE_SIZE: 'SET_PRODUCT_GROUPS_PAGE_SIZE',
  SET_PRODUCT_GROUPS_SORTING: 'SET_PRODUCT_GROUPS_SORTING',
  SET_PRODUCT_GROUPS_INITIAL_STATE: 'SET_PRODUCT_GROUPS_INITIAL_STATE',
  SET_PRODUCT_GROUPS_SETTING_STATUS: 'SET_PRODUCT_GROUPS_SETTING_STATUS',
  SET_PRODUCT_GROUPS_SETTING_PENDING: 'SET_PRODUCT_GROUPS_SETTING_PENDING',
  SET_PRODUCT_GROUPS_SELECTION: 'SET_PRODUCT_GROUPS_SELECTION',
});
