// Core
import {
  apply, put, select,
} from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { userActions } from '../../../../user/action';
import { selectors } from '../../../../../config/selectors';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutUpdateCategoriesWorker({ payload }) {
  yield put(actions.mergeUpdateCategories({
    pending: true,
  }));

  const response = yield apply(api, api.priceCreatorCategories.putUpdateCategories, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status, statusTitle } = data;

    switch (status) {
      case 'success': {
        const { message, xmlFileLink } = data;
        const isOpenPartnersModal = yield select(selectors.priceCreatorCategories.isOpenPartnersModal);

        if (isOpenPartnersModal) {
          yield put(actions.mergePartnersModal({
            open: false,
          }));
        }

        if (xmlFileLink) {
          yield put(userActions.mergeInUserInfo({
            xmlFileLink,
          }));
        }

        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setReload(true));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeUpdateCategories({
    pending: false,
  }));
}
