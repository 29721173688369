// Instruments
import { types } from './types';

export const statisticsActions = Object.freeze({
  setStatistics: statisticsList => ({
    type: types.SET_STATISTICS_LISTS,
    payload: statisticsList,
  }),
  setStatisticsCounterNotificationNotRead: counter => ({
    type: types.SET_STATISTICS_COUNTER_NOTIFICATION_NOT_READ,
    payload: counter,
  }),
});
