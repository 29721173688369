// Core
import React from 'react';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// Parts
import PaymentTypesDocsModalFields from './PaymentTypesDocsModalFields';
import FilesUploaderWithValidation from '../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
// Engine
import { mimeTypeExtensions } from '../../../../../engine/config/mimeTypeExtensions';
import { validators } from '../../../../../_helpers/validationsForm';


const PaymentTypesDocsModalContent = (props) => {
  const {
    filesUploaded, label, view, mode,
  } = props;
  const isShowMode = mode === 'show';
  const isAddMode = mode === 'add';
  const { t } = useTranslation();
  return (
    <>
      {!isShowMode && (
        <>
          <Typography>
            {isAddMode ? t('Для подключения способа') : t('Для изменения способа')}
            &nbsp;<b>&quot;{label}&quot;</b> {t('Вам необходимо')} {!filesUploaded && <Icon color="primary">*</Icon>}:
          </Typography>
          <ol>
            <li>
              {t('Загрузить копии следующих документов')}:<br />
              <ul type="disc">
                <li>{t('Паспорт')};</li>
                <li>{t('ИНН')};</li>
                <li>{t('Вытяг или Выписка из ЕДР')};</li>
                <li>{t('Лицензия по продуктам - сигареты, алкоголь (если вы продаете такие категории товаров)')}.</li>
              </ul>
            </li>
            <li>{t('Заполнить анкету')};</li>
            <li>{t('Загрузить')}
              <Link href="/docs/Заява_Ліцензіата_ФОП_щодо_Вейфорпей.docx" rel="noreferrer" target="_blank">&nbsp;{t('Заявление лицензиата')}</Link>&nbsp;
              {t('и отправить ее через сервис')}&nbsp;
              <Link href="https://vchasno.ua/" target="_blank">Вчасно</Link>&nbsp;
              {t('предварительно подписав ЕЦП')};<br />
              {t('Реквизиты Вчасно Allo.ua, - едрпоу: 30012848, эл. адрес')}:&nbsp;
              <Link href="mailto:Edo_doc@allo.ua">Edo_doc@allo.ua</Link>
            </li>
            <li>{t('Подписать договор с WAYFORPAY')}<br /><strong>{t('Важно')}!</strong> {t('Анкета заполняется на украинском языке')}</li>
          </ol>
          <Field
            name="mediaFiles"
            allowFileTypeValidation
            acceptedFileTypes={[
              mimeTypeExtensions.jpeg,
            ]}
            labelFileTypeNotAllowed={t('Тип файла не валидный')}
            component={FilesUploaderWithValidation}
            validate={filesUploaded ? [] : [validators.required]}
            validation={!filesUploaded}
            allowMultiple
            maxFiles={99999}
            fullWidth
            margin="normal"
            textBefore="jpg"
          />
          <br />
        </>
      )}
      <PaymentTypesDocsModalFields view={view} disabled={isShowMode} />
    </>
  );
};


export default PaymentTypesDocsModalContent;
