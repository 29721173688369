// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, treeDataRowIds) {
    return getActionCreator(asyncTypes.GET_BANK_INSTALLMENTS_DATA_LIST_ASYNC, {
      params, treeDataRowIds,
    });
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_BANK_INSTALLMENTS_DATA_INITIAL_STATE_ASYNC, path);
  },
  postBankInstallmentsAsync(data) {
    return getActionCreator(asyncTypes.POST_BANK_INSTALLMENTS_ASYNC, data);
  },
  getLogsAsync(payload) {
    return getActionCreator(asyncTypes.GET_BANK_INSTALLMENTS_DATA_LOGS_ASYNC, payload);
  },
});
