// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
// UI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

function ButtonContainedPrimary(props) {
  const {
    classes, isLoading, onClick, size, text, fullWidth, sx,
  } = props;

  return (
    <Button
      color="primary"
      disabled={isLoading}
      onClick={onClick}
      size={size}
      variant="contained"
      fullWidth={fullWidth}
      sx={sx}
    >
      {isLoading && (
        <CircularProgress
          color="inherit"
          className={classes.circularProgress}
          size={20}
        />
      )}
      {text || '\u00A0'}
    </Button>
  );
}

ButtonContainedPrimary.propTypes = {
  classes: PropTypes.object,
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ButtonContainedPrimary.defaultProps = {
  classes: {},
  isLoading: false,
  fullWidth: false,
  onClick: () => {},
  size: 'medium',
  text: '',
};

const styles = theme => ({
  circularProgress: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(ButtonContainedPrimary);
