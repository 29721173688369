// Core
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Field, FieldArray,
} from 'redux-form/immutable';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
// Parts
import Grid from '@mui/material/Grid';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import FormSelect from '../../../../components/_Form/Selects/FormSelect/FormSelect';
import RenderFopOthersPropertyAccess from './components/RenderFopOthersPropertyAccess';
import RenderFopOthersAbroadEnterprises from './components/RenderFopOthersAbroadEnterprises';
// Styles
import PaymentTypesDocsModalStyles from './PaymentTypesDocsModalStyles';
// Helpers
import { validators } from '../../../../../_helpers/validationsForm';
import fieldsName from './fields';

const useStyles = makeStyles(PaymentTypesDocsModalStyles);

const PaymentTypesDocsModalFields = ({ disabled, view }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gridMdView = useMemo(() => view === 'columns' ? 6 : 12, [view]);

  const FirstPartItems = () => (
    <>
      <Field
        helperTextBefore={t('Дата та номер запису в Єдиному державному реєстрі юридичних осіб та фізичних осіб - підприємців про проведення державної реєстрації')}
        fullWidth
        variant="outlined"
        name={fieldsName.fopEdrDateNumberRegister}
        component={TextField}
        multiline
        margin="normal"
        disabled={disabled}
        validate={[validators.required]}
        required
        validation
      />
      <Field
        helperTextBefore={t('Номер (та за наявності - серію) паспорта громадянина України (або іншого документа, що посвідчує особу та відповідно до законодавства України може бути використаним на території України для укладення правочинів), дату видачі та орган, що його видав')}
        fullWidth
        variant="outlined"
        name={fieldsName.fopPassportData}
        margin="normal"
        component={TextField}
        multiline
        disabled={disabled}
        validate={[validators.required]}
        required
        validation
      />
      <FieldArray disabled={disabled} name="fopOthersPropertyAccess" component={RenderFopOthersPropertyAccess} />
      <Field
        helperTextBefore={t('Характеристика суті діяльності (наприклад: продаж косметичних засобів через мережу «Інтернет», страхова діяльність, благодійна діяльність,  тощо)')}
        fullWidth
        margin="normal"
        variant="outlined"
        name={fieldsName.fopBusinessType}
        component={TextField}
        multiline
        disabled={disabled}
        validate={[validators.required]}
        required
        validation
      />
      <FieldArray disabled={disabled} name={fieldsName.fopOthersAbroadEnterprises} component={RenderFopOthersAbroadEnterprises} />
    </>
  );
  const SecondPartItems = () => (
    <>
      <br />
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{t('Показники, що характеризують фінансовий стан (грн)')}:</legend>
        <Field
          helperTextBefore={t('Прибуток/збиток за останній календарний рік (для новостворених юридичних осіб вказується запланована сума)')}
          component={FormSelect}
          options={[
            { value: 'Більше 50 000', label: t('Більше 50 000') },
            { value: '10 000 – 50 000', label: '10 000 – 50 000' },
            { value: 'Менше 10 000', label: t('Менше 10 000') },
            { value: '0/збиток', label: t('0/збиток') },
          ]}
          name={fieldsName.fopYearProfit}
          fullWidth
          required
          validation
          variant="outlined"
          margin="normal"
          disabled={disabled}
          validate={[validators.required]}
        />
        <Field
          helperTextBefore={t('Розмір доходу (виручки) від реалізації продукції, товарів, робіт, послуг за рік (Підприємства, які здійснюють свою діяльність менше року та новостворені клієнти Підприємства зазначають обсяг доходу (виручки) від реалізації продукції, товарів, робіт, послуг, який планують отримати протягом найближчих 12 місяців')}
          component={FormSelect}
          options={[
            { value: 'Більше  1 000 000', label: t('Більше  1 000 000') },
            { value: 'Від 250 000 – 1 000 000', label: t('Від 250 000 – 1 000 000') },
            { value: 'Менше 250 000', label: t('Менше 250 000') },
            { value: 0, label: '0' },
          ]}
          name={fieldsName.fopProductsYearProfit}
          fullWidth
          required
          validation
          variant="outlined"
          margin="normal"
          disabled={disabled}
          validate={[validators.required]}
        />
        <Field
          helperTextBefore={t('Кількість штатних працівників')}
          component={FormSelect}
          options={[
            { value: 'Більше 10', label: t('Більше 10') },
            { value: 'Менше 10', label: t('Менше 10') },
          ]}
          name={fieldsName.fopEmployeeCount}
          fullWidth
          required
          validation
          variant="outlined"
          margin="normal"
          disabled={disabled}
          validate={[validators.required]}
        />
      </fieldset>
      <Field
        helperTextBefore={t('Ліцензії (дозволи) на право здійснення певних операцій (діяльності) (найменування, серії, номери, ким видані, термін дії)')}
        fullWidth
        margin="normal"
        variant="outlined"
        name={fieldsName.fopLicenses}
        disabled={disabled}
        component={TextField}
        multiline
      />
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{t('После согласования Wayforpay, вам будет отправлен договор по Новой почте. Укажите данные для отправки')}:</legend>
        <Field
          variant="outlined"
          helperTextBefore={t('ФИО получателя')}
          fullWidth
          margin="normal"
          name={fieldsName.fopMailFullName}
          component={TextField}
          multiline
          disabled={disabled}
          validate={[validators.required]}
          validation
          required
        />
        <Field
          variant="outlined"
          helperTextBefore={t('Телефон получателя')}
          fullWidth
          margin="normal"
          name={fieldsName.fopMailPhone}
          component={TextField}
          multiline
          disabled={disabled}
          validate={[validators.required]}
          validation
          required
        />
        <Field
          variant="outlined"
          helperTextBefore={t('Область')}
          fullWidth
          margin="normal"
          name={fieldsName.fopMailRegion}
          component={TextField}
          multiline
          disabled={disabled}
          validate={[validators.required]}
          validation
          required
        />
        <Field
          variant="outlined"
          helperTextBefore={t('Населенный пункт')}
          fullWidth
          margin="normal"
          name={fieldsName.fopMailLocation}
          component={TextField}
          multiline
          disabled={disabled}
          validate={[validators.required]}
          validation
          required
        />
        <Field
          variant="outlined"
          helperTextBefore={t('№ Отделения Новой почты')}
          fullWidth
          margin="normal"
          name={fieldsName.fopMailNovaposhtaDepartment}
          component={TextField}
          multiline
          disabled={disabled}
          validate={[validators.required]}
          validation
          required
        />
      </fieldset>
    </>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={gridMdView}>
          <FirstPartItems />
        </Grid>
        <Grid item xs={12} md={gridMdView}>
          <SecondPartItems />
        </Grid>
      </Grid>
    </>
  );
};

PaymentTypesDocsModalFields.propTypes = {
  disabled: PropTypes.bool,
  view: PropTypes.string,
};


export default memo(PaymentTypesDocsModalFields);
