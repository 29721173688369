// Core
import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
// Parts
import DatetimeFormatter from '../../../../../../components/_Table/TableCell/components/DatetimeFormatter';

function DateTimeTypeProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={DatetimeFormatter}
      {...props}
    />
  );
}

export default DateTimeTypeProvider;
