// Core
import cx from 'classnames';
// Parts
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import HighlightedText from '../../../../../containers/HighlightedText';
// Helpers
import { COLOR } from '../../../../../containers/App/AppStyles';

const Option = (props) => {
  const {
    innerRef, isSelected, children, isFocused, data,
    innerProps: { onClick }, selectProps, isDisabled, isMulti,
  } = props;
  const currentFocusOn = selectProps.focusOn;
  const isOnValueFocus = currentFocusOn
    ? data.value === currentFocusOn
    : isFocused;
  return (
    <MenuItem
      title={children}
      ref={innerRef}
      selected={isSelected}
      component="div"
      className={cx({
        'is-focused': isOnValueFocus,
      })}
      classes={{
        root: selectProps.classes.menuItemRoot,
      }}
      sx={{
        fontWeight: isSelected ? 500 : 400,
        color: isDisabled
          ? COLOR.grey['38']
          : 'initial',
      }}
      onClick={onClick}
    >
      {isMulti && (
        <Checkbox
          checked={isSelected}
          classes={{
            root: selectProps.classes.checkBoxRoot,
          }}
          color="primary"
        />
      )}
      <Box
        component="span"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <HighlightedText
          label={children}
          search={selectProps?.searchString || selectProps?.inputValue}
        />
      </Box>
    </MenuItem>
  );
};

export default Option;
