// core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
// Helpers
import { useRecipientsImportItems } from './_hooks/useRecipientsImportItems';

function PartnersNotFound() {
  const { currentPartnersNotFound } = useRecipientsImportItems();
  const { t } = useTranslation();

  return !isEmpty(currentPartnersNotFound) && currentPartnersNotFound.length <= 10 ? (
    <Box mt={1}>
      <Typography variant="caption" component="div">
        {t('Не найденные партнеры')}
      </Typography>
      <Grid container>
        {map(currentPartnersNotFound, partner => (
          <Chip
            key={partner.label}
            label={partner.label}
            variant="outlined"
            size="small"
            sx={{ ml: 0, mt: 0 }}
          />
        ))}
      </Grid>
    </Box>
  ) : null;
}

export default memo(PartnersNotFound);
