// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function OrderStatisticCommisionIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.72266 14.0479H15.5811V12.8994H10.9463C11.0602 12.8584 11.1833 12.8197 11.3154 12.7832C12.4821 12.4733 13.2409 12.2204 13.5918 12.0244C13.8288 11.8923 14.0339 11.7282 14.207 11.5322H15.5811V10.3838H14.7607C14.7972 10.1969 14.8154 10.001 14.8154 9.7959C14.8154 8.9209 14.5055 8.21224 13.8857 7.66992C13.3753 7.22331 12.6097 7 11.5889 7C10.7731 7 10.1146 7.20964 9.61328 7.62891C9.07552 8.07552 8.75195 8.68848 8.64258 9.46777L9.91406 9.65918C9.98698 9.17611 10.1396 8.80469 10.3721 8.54492C10.6637 8.2168 11.1081 8.05273 11.7051 8.05273C12.3613 8.05273 12.8512 8.23503 13.1748 8.59961C13.4482 8.90495 13.585 9.27181 13.585 9.7002C13.585 9.95996 13.5417 10.1878 13.4551 10.3838H8V11.5322H11.4932C10.6683 11.7601 10.1989 11.8991 10.085 11.9492C9.55176 12.1816 9.14616 12.4984 8.86816 12.8994H8V14.0479H8.43066C8.40788 14.2074 8.39648 14.36 8.39648 14.5059C8.39648 15.3581 8.66764 16.069 9.20996 16.6387C9.47884 16.9212 9.84342 17.1468 10.3037 17.3154C10.764 17.484 11.2288 17.5684 11.6982 17.5684C12.6462 17.5684 13.4186 17.3337 14.0156 16.8643C14.6172 16.3903 14.9544 15.7409 15.0273 14.916L13.79 14.6836C13.7126 15.1986 13.5189 15.6156 13.209 15.9346C12.8353 16.3311 12.3271 16.5293 11.6846 16.5293C11.0146 16.5293 10.4974 16.3219 10.1328 15.9072C9.81836 15.5472 9.66113 15.1074 9.66113 14.5879C9.66113 14.3828 9.68164 14.2028 9.72266 14.0479Z" fill="#006580" />
    </SvgIcon>
  );
}

export default OrderStatisticCommisionIcon;
