// Core
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import Box from '@mui/material/Box';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ComponentWithTooltip from '../../../../../../../containers/ComponentWithTooltip';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
import { locale } from '../../../../../../../../engine/init/i18n';
// Helpers
import { tableDataTypes } from '../../../../../../../../_helpers/data/tableDataTypes';
import { COLOR } from '../../../../../../../containers/App/AppStyles';

function HeaderComponent(props) {
  const {
    column, children, ...restProps
  } = props;
  let counter;
  const { i18n } = useTranslation();
  const feedIsItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const { stepsData } = feedIsItemByHashIdItem.toJS();
  const feedIsItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const currentLocale = locale[i18n.language];
  const descCellColumn = column?.desc?.[currentLocale] && <b>{column?.desc?.[currentLocale]}</b>;

  switch (column.name) {
    case tableDataTypes.productFeedCategory.name: {
      counter = stepsData?.[3].totalFeedCategories;
      break;
    }
    case tableDataTypes.feedAttributesQuantity.name: {
      // counter = `${stepsData?.[3].totalFeedAttributesConnected} / ${stepsData?.[3].totalFeedAttributes}`;
      break;
    }
    default: {
      break;
    }
  }
  return (
    <TableHeaderRow.Cell className="tableCellWithCounter" column={column} {...restProps}>
      <ComponentWithTooltip description={descCellColumn}>
        <Box sx={{
          flexGrow: 1,
          width: 'calc(100% - 20px)',
          display: 'inline-flex',
          maxWidth: '100%',
          minWidth: 0,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
          {children}
        </Box>
      </ComponentWithTooltip>
      <Box sx={{ display: 'block', width: '100%', height: '14px' }}>
        {!feedIsItemByHashIdLoading && counter && (
          <Box sx={{ color: COLOR.inactive }}>
            {
              counter
                ? `(${counter})`
                : undefined
            }
          </Box>
        )}
      </Box>
    </TableHeaderRow.Cell>
  );
}
HeaderComponent.propTypes = {
  column: PropTypes.object,
  children: PropTypes.node,
};
export default memo(HeaderComponent);
