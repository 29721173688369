// Core
import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
// UI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// Icons
import CreditCard from '@mui/icons-material/CreditCard';

const styles = {
  iconButton: {
    height: '20px',
  },
  icon: {
    width: '16px',
    height: '16px',
    fontSize: '16px',
  },
};
const useStyles = makeStyles(styles);
function OrderPaymentTooltip(props) {
  const { row } = props;
  const classes = useStyles();
  return (
    <Tooltip title={row.tooltip} placement="right">
      <IconButton className={classes.iconButton} color="primary" aria-label="info" size="small">
        {(() => {
          switch (row.icon) {
            case 'CreditCard':
              return <CreditCard className={classes.icon} />;
            default:
              return null;
          }
        })()}
      </IconButton>
    </Tooltip>
  );
}


export default withStyles(styles)(OrderPaymentTooltip);
