// Core
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form/immutable';
// Parts
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '../../../components/_Form/Checkbox/Checkbox';

const MailSettingsItem = (field) => {
  const {
    id: fieldId, disabled, subject,
  } = field;
  const { t } = useTranslation();
  const preFormattingSubject = `${t('Получать почтовое сообщение')} "${subject}"`;
  if (disabled) {
    return (
      <Tooltip
        placement="bottom-start"
        title={t('Это уведомление обязательное для получения, Вы не можете его отключить')}
      >
        <div>
          <Field
            key={fieldId}
            name={fieldId.toString()}
            disabled={disabled}
            noTrans
            component={Checkbox}
            checkboxLabel={preFormattingSubject}
            fullWidth
            margin="none"
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <Field
      key={fieldId}
      name={fieldId.toString()}
      disabled={disabled}
      noTrans
      component={Checkbox}
      checkboxLabel={preFormattingSubject}
      fullWidth
      margin="none"
    />
  );
};

export default MailSettingsItem;
