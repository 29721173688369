// Core
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatPrice = forwardRef(function NumberFormatCustom(props, ref) {
  const {
    onChange,
    allowNegative,
    value,
    ...other
  } = props;

  const isAllowed = (values) => {
    const { formattedValue, floatValue } = values;
    return formattedValue === '' || floatValue <= 2147483647;
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      value={typeof value === 'object' ? value?.floatValue : value}
      onValueChange={onChange}
      thousandSeparator=" "
      fixedDecimalScale
      isNumericString
      allowNegative={allowNegative}
      decimalScale={0}
      // decimalSeparator="," // https://github.com/s-yadav/react-number-format/issues/362
      isAllowed={isAllowed}
    />
  );
});

export default NumberFormatPrice;
