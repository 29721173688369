// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetDownloadWorker,
  callGetPaymentTypes,
  callPutPartnersProcessWorker,
  callPutPartnersHashIdConfirmFormWorker,
  callGetListByStatusWorker,
  callPostPartnersImportTopWorker,
  callGetPartnersTopExportWorker,
  callGetListWithMidWorker,
  callPutPartnersOrderProcessingScheduleWorker,
  callPutPartnersDeliveryDelayPeriodWorker,
  callDeleteDeliveryDelayPeriodWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PARTNERS_LIST_ASYNC, callGetListWorker);
}
function* watchGetListByStatus() {
  yield takeEvery(asyncTypes.GET_PARTNERS_LIST_BY_STATUS_ASYNC, callGetListByStatusWorker);
}
function* watchGetListWithMid() {
  yield takeEvery(asyncTypes.GET_PARTNERS_LIST_WITH_MID_ASYNC, callGetListWithMidWorker);
}
function* watchPostPartnersImportTop() {
  yield takeEvery(asyncTypes.POST_PARTNERS_IMPORT_TOP_ASYNC, callPostPartnersImportTopWorker);
}
function* watchGetPartnersTopExport() {
  yield takeEvery(asyncTypes.GET_PARTNERS_EXPORT_TOP_ASYNC, callGetPartnersTopExportWorker);
}
function* watchGetDownload() {
  yield takeEvery(asyncTypes.GET_PARTNERS_DOWNLOAD_ASYNC, callGetDownloadWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PARTNERS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutPartnersProcess() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_PROCESS_ASYNC, callPutPartnersProcessWorker);
}
function* watchPutPartnersOrderProcessingSchedule() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_ORDER_PROCESSING_SCHEDULE_ASYNC, callPutPartnersOrderProcessingScheduleWorker);
}
function* watchPutPartnersDeliveryDelayPeriod() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC, callPutPartnersDeliveryDelayPeriodWorker);
}
function* watchDeletePartnersDeliveryDelayPeriod() {
  yield takeEvery(asyncTypes.DELETE_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC, callDeleteDeliveryDelayPeriodWorker);
}
function* watchGetPaymentTypes() {
  yield takeEvery(asyncTypes.GET_PARTNERS_PAYMENT_TYPES_ASYNC, callGetPaymentTypes);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PARTNERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PARTNERS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PARTNERS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PARTNERS_SORTING, callSetToLocalDataWorker);
}
function* watchPutPartnersHashIdConfirmForm() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_HASH_ID_CONFIRM_FORM_ASYNC, callPutPartnersHashIdConfirmFormWorker);
}

export function* watchersPartners() {
  yield all([
    watchGetList(),
    watchGetListByStatus(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetDownload(),
    watchGetPaymentTypes(),
    watchPutPartnersProcess(),
    watchPutPartnersHashIdConfirmForm(),
    watchPostPartnersImportTop(),
    watchGetPartnersTopExport(),
    watchGetListWithMid(),
    watchPutPartnersOrderProcessingSchedule(),
    watchPutPartnersDeliveryDelayPeriod(),
    watchDeletePartnersDeliveryDelayPeriod(),
  ]);
}
