// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// UI
import IconButton from '@mui/material/IconButton';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { COLOR } from '../../../../containers/App/AppStyles';

// config
import { selectors } from '../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';

// actions
import { actions } from '../../../../../engine/core/stopList/stopWords/actions';
import { asyncActions } from '../../../../../engine/core/stopList/stopWords/saga/asyncActions';

// parts
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import TableFilterRowComponent from './TableFilterRowComponent';

// Hooks
import { useWordsList } from '../_hooks/useWordsList';
import { useAccessList } from '../../../../hooks/useAccessList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';

function EditComponent(editProps) {
  const { row: { hashId } } = editProps;

  return (
    <Link to={pageLinks.stopWords.edit(hashId)}>
      <IconButton
        sx={{
          borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)', color: COLOR.controlsIcons.main,
        }}
        color="inherit"
        size="large"
      >
        <ModeOutlinedIcon sx={{ p: '2px' }} />
      </IconButton>
    </Link>
  );
}

// Redux
function WordsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.stopWords.isLoading);
  const hasAccessDelete = useAccessList(accessList.stopWordsDelete);
  const hasAccessEdit = useAccessList(accessList.stopWordsEdit);
  const editWidth = useEditCellWidth([(hasAccessEdit)], 0, 40, 40);
  // Rows
  const rows = useSelector(selectors.stopWords.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.stopWords.currentPage);
  const pageSize = useSelector(selectors.stopWords.pageSize);
  const totalCount = useSelector(selectors.stopWords.totalCount);
  const selections = useSelector(selectors.stopWords.selection);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.stopWords.sorting);
  // Settings
  const settingStatus = useSelector(selectors.stopWords.settingStatus);
  const settingPending = useSelector(selectors.stopWords.settingPending);
  // Filtering
  const filters = useSelector(selectors.stopWords.filters);

  const onSelection = (selected) => {
    dispatch(actions.setSelection(selected));
  };

  return (
    <DxTable
      name="stopWords"
      fixedColumn
      showShortText
      rowFilterComponent={hasAccessDelete ? TableFilterRowComponent : null}
      isLoading={isLoading}
      rows={rows}
      updateHooks={useWordsList}
      // Editing
      editComponent={hasAccessEdit ? EditComponent : null}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.stopWords.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
      // Selection
      selection={selections}
      onSelection={hasAccessDelete ? onSelection : null}
    />
  );
}

export default memo(WordsTable);
