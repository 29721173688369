// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_LIST, data);
  },
  mergeList(data) {
    return getActionCreator(types.MERGE_PARTNERS_MANAGERS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_SETTING_PENDING, settingPending);
  },
  mergePartnerManager(data) {
    return getActionCreator(types.MERGE_PARTNER_MANAGER, data);
  },
  setPartnerManagerDownloadPending(payload) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_DOWNLOAD_PENDING, payload);
  },
  setActivePartnerManager(payload) {
    return getActionCreator(types.SET_ACTIVE_PARTNERS_MANAGERS, payload);
  },
  setPartnerManagerModalIsOpen(isOpen) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_IMPORT_MODAL_IS_OPEN, isOpen);
  },
  mergePartnerManagerUpload(data) {
    return getActionCreator(types.MERGE_PARTNERS_MANAGERS_UPLOAD_STOP, data);
  },
  setPartnerListReload(data) {
    return getActionCreator(types.SET_PARTNERS_MANAGERS_LIST_RELOAD, data);
  },
});
