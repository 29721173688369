// Core
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { NavLink, useLocation } from 'react-router-dom';
// Components
import cx from 'classnames';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { uiActions } from '../../../../../engine/core/ui/action';
import { pageLinks, routersLink } from '../../../../../engine/config/routes';
import { userRoles } from '../../../../../engine/config/userRoles';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { useStyles } from '../../../../hooks/useStyles';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
// Icons
import InfoOutlinedIcon from '../../../../icons/InfoOutlined';
import ArrowDownRoundedIcon from '../../../../icons/ArrowDownRounded';

import { GUIDE_TYPES } from '../../../../containers/App/components/GuideTour/types';
import HelpSectionStyles from './styles';
import MoreMenuItem from './components/MoreMenuItem';
import { useMDView } from '../../../../hooks/useDesktopView';

function HelpMenu() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const configurations = useSelector(selectors.user.configurations);
  const instructionUrl = configurations.get('PRODUCT_IMPORT_INSTRUCTION');

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const classes = useStyles(HelpSectionStyles);
  const hasFaqQuestionList = useAccessList(accessList.infoCenterLinkShow);
  const infoCenterLink = configurations.get('INFO_CENTER_LINK');

  const hasImportGuideRolesAccess = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.admin,
    userRoles.manager,
    userRoles.contentManager,
  ]);
  const contentManagersRole = useUserSomeRole([userRoles.contentManager]);
  const currentPathname = useSelector(selectors.router.pathname);
  const productPagePath = pageLinks.content.products.simpleView;
  const importPagePath = pageLinks.productsAndPrices.importPage;
  const hasAccessToProductGuide = useAccessList(accessList.productCreate);
  const hasAccessToProductImportGuide = useAccessList(accessList.productImport);
  const hasAccessProductSendToModeration = useAccessList(accessList.productSendToModeration);
  const isShowGuideProductPage = hasAccessToProductGuide && productPagePath === currentPathname;
  const isShowGuideImportPage = hasAccessToProductImportGuide && hasImportGuideRolesAccess && importPagePath === currentPathname;
  const isMDView = useMDView();
  const isActiveRoute = location.pathname === routersLink.instruction.controller;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const initGuideTour = ({ guideType, timeout = 300 }) => {
    handleClose();
    setTimeout(() => {
      dispatch(uiActions.setJoyrideSteps(guideType));
      dispatch(uiActions.setJoyrideRun(true));
    }, timeout);
  };

  const initGuideProductPage = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.PRODUCTS_CONTENT,
    });
  };

  const initGuideSendToModeration = () => {
    dispatch(uiActions.setSidebarOpen(true));
    initGuideTour({
      guideType: GUIDE_TYPES.SEND_TO_MODERATION,
    });
  };

  const initGuideEditProduct = () => {
    dispatch(uiActions.setSidebarOpen(true));
    initGuideTour({
      guideType: GUIDE_TYPES.PRODUCTS_EDIT,
    });
  };

  const initGuideUploadPriceInfo = () => {
    dispatch(uiActions.setSidebarOpen(true));
    initGuideTour({
      guideType: GUIDE_TYPES.PRODUCTS_UPLOAD_PRICE,
    });
  };

  const initGuideExportPriceInfo = () => {
    dispatch(uiActions.setSidebarOpen(true));
    initGuideTour({
      guideType: GUIDE_TYPES.PRODUCTS_EXPORT_PRICE,
    });
  };

  const initGuideMultipleEdit = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.PRODUCTS_MULTIPLE_EDIT,
    });
  };

  const initGuideImportPageLinkXML = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.IMPORT_LINK_XML,
    });
  };

  const initGuideImportFileXML = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.IMPORT_FILE_XML,
    });
  };
  const initGuideImportPageAddProductManual = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.IMPORT_ADD_PRODUCT_MANUAL,
      timeout: 300,
    });
  };
  const initGuideImportPageXlsx = () => {
    initGuideTour({
      guideType: GUIDE_TYPES.IMPORT_XLSX,
      timeout: 300,
    });
  };

  const hideHelpMenu = !isShowGuideProductPage && !isShowGuideImportPage
  && isEmpty(instructionUrl) && (!isEmpty(infoCenterLink) && hasFaqQuestionList);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>

      {hideHelpMenu ? (
        <Link color="inherit" href={infoCenterLink} target="_blank" underline="none">
          <Button
            className={cx(classes.helpMenuButton, { [classes.helpMenuButtonActive]: isOpen })}
            id="help-menu-button"
            aria-controls={isOpen ? 'help-menu' : null}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : null}
            onClick={handleClick}
            disableRipple
          >
            <Box className={classes.helpMenuBox}>
              <InfoOutlinedIcon className={classes.helpMenuIconInfo} />
              <Typography className={classes.helpMenuTitle}>
                {t('Помощь')}
              </Typography>
            </Box>
          </Button>
        </Link>
      ) : (
        <Button
          className={cx(classes.helpMenuButton, { [classes.helpMenuButtonActive]: isOpen })}
          id="help-menu-button"
          aria-controls={isOpen ? 'help-menu' : null}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : null}
          onClick={handleClick}
          disableRipple
        >
          <Box className={classes.helpMenuBox}>
            <InfoOutlinedIcon className={classes.helpMenuIconInfo} />
            <Typography className={classes.helpMenuTitle}>{t('Помощь')}</Typography>
          </Box>
          <ArrowDownRoundedIcon
            className={cx(classes.helpMenuDropIcon, { [classes.helpMenuDropIconActive]: isOpen })}
          />
          <Menu
            id="help-menu"
            anchorEl={anchorEl}
            open={isOpen}
            className={classes.helpMenu}
          >
            {!isMDView ? (
              <>
                {isShowGuideProductPage && (
                <MoreMenuItem label={t('Подсказки по разделу')}>
                  <MenuItem onClick={initGuideProductPage}>{t('Как добавить товар вручную')}</MenuItem>
                  {hasAccessProductSendToModeration && <MenuItem onClick={initGuideSendToModeration}>{t('Как отправить товары на модерацию')}</MenuItem>}
                  <MenuItem onClick={initGuideEditProduct}>{t('Как редактировать товары')}</MenuItem>
                  {!contentManagersRole && (
                    <>
                      <MenuItem onClick={initGuideUploadPriceInfo}>{t('Как загрузить информацию о цене и наличии товаров')}</MenuItem>
                      <MenuItem onClick={initGuideExportPriceInfo}>{t('Как выгрузить информацию о цене и наличии товаров')}</MenuItem>
                    </>
                  )}
                  <MenuItem onClick={initGuideMultipleEdit}>{t('Как редактировать несколько товаров в одной категории')}</MenuItem>
                </MoreMenuItem>
                )}
                {isShowGuideImportPage && (
                <MoreMenuItem label={t('Подсказки по разделу')}>
                  <MenuItem onClick={initGuideImportFileXML}>{t('Как загрузить товары через XML-файл')}</MenuItem>
                  <MenuItem onClick={initGuideImportPageLinkXML}>{t('Как загрузить товары по ссылке на XML-файл')}</MenuItem>
                  <MenuItem onClick={initGuideImportPageXlsx}>{t('Как загрузить товары через XLSX-шаблон')}</MenuItem>
                  <MenuItem onClick={initGuideImportPageAddProductManual}>{t('Как загрузить товары вручную')}</MenuItem>
                </MoreMenuItem>
                )}
              </>
            ) : null}
            <Box
              component={NavLink}
              to={routersLink.instruction.controller}
              sx={{
                pointerEvents: isActiveRoute ? 'none' : 'auto',
                color: isActiveRoute ? 'gray' : 'inherit',
                opacity: isActiveRoute ? '0.38' : '1',
                underline: 'none',
              }}
            >
              <MenuItem onClick={handleClose}>{t('Инструкция')}</MenuItem>
            </Box>
            {(!isEmpty(infoCenterLink) && hasFaqQuestionList) && (
            <Link color="inherit" href={infoCenterLink} target="_blank" underline="none">
              <MenuItem onClick={handleClose}>FAQ</MenuItem>
            </Link>
            )}
          </Menu>
        </Button>
      )}

    </ClickAwayListener>
  );
}

export default HelpMenu;
