// Core
import React, { useState, useRef, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import { makeStyles } from '@mui/styles';
import ListContext from './SpeedMenuListTree/context';
// Parts
import Row from './SpeedMenuListTree/Row';
import InnerElementType from './SpeedMenuListTree/InnerElementType';
// Helpers
import getMenuListHeight from '../../Select/_helpers/getMenuListHeight';
import sizeActions from './SpeedMenuListTree/_helpers/sizeActions';
// Hooks
import { useFlattenedData } from './SpeedMenuListTree/hooks/useFlattenedData';
import { useItemData } from './SpeedMenuListTree/hooks/useItemData';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& > div': {
      width: '100% !important',
    },
  },
  list: {
    width: '100% !important',
  },
}));

const SpeedMenuListTree = (props) => {
  const innerClasses = useStyles();
  const { selectProps, innerRef, options } = props;
  const {
    classes, defaultExpandedIds, hasSearch, searchString,
  } = selectProps;
  const [openedNodeIds, setOpenedNodeIds] = useState([]);
  const listRef = useRef(null);
  const [getSize, setSize] = sizeActions(listRef);
  // used for dynamic opening when searching
  const key = defaultExpandedIds.join('-') || 'treeView';

  let flattenedData = useFlattenedData(options, openedNodeIds, setOpenedNodeIds, selectProps, innerRef);
  let itemData = useItemData(flattenedData, setSize, openedNodeIds, setOpenedNodeIds, getSize);
  const flattenedDataLength = flattenedData.length;

  useEffect(() => () => {
    flattenedData = null;
    itemData = null;
  }, []);

  return itemData ? (
    <div className={innerClasses.wrapper}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <ListContext.Provider
            value={{
              key, classes, openedNodeIds, hasSearch, searchString,
            }}
          >
            <List
              className={innerClasses.list}
              itemCount={flattenedDataLength}
              itemSize={getSize}
              width={width}
              height={getMenuListHeight(selectProps)}
              itemData={itemData}
              innerElementType={InnerElementType}
              ref={listRef}
              rowCount={flattenedDataLength}
              rowHeight={getSize}
            >
              {Row}
            </List>
          </ListContext.Provider>
        )}
      </AutoSizer>
    </div>
  ) : null;
};

export default SpeedMenuListTree;
