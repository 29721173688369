// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_LIST_ASYNC, params);
  },
  getListByStatusAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_LIST_BY_STATUS_ASYNC, params);
  },
  getListWithMidAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_LIST_WITH_MID_ASYNC, params);
  },
  getDownloadAsync() {
    return getActionCreator(asyncTypes.GET_PARTNERS_DOWNLOAD_ASYNC);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PARTNERS_INITIAL_STATE_ASYNC, path);
  },
  getPaymentTypeAsync(path) {
    return getActionCreator(asyncTypes.GET_PARTNERS_PAYMENT_TYPES_ASYNC, path);
  },
  putPartnersProcessAsync(data) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_PROCESS_ASYNC, data);
  },
  deletePartnersDeliveryDelayPeriodAsync(hashId) {
    return getActionCreator(asyncTypes.DELETE_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC, hashId);
  },
  putPartnersOrderProcessingScheduleAsync(data) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_ORDER_PROCESSING_SCHEDULE_ASYNC, data);
  },
  putPartnersDeliveryDelayPeriodAsync(data) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC, data);
  },
  putPartnersHashIdConfirmForm(partnerData) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_HASH_ID_CONFIRM_FORM_ASYNC, partnerData);
  },
  postPartnersImportTop(partnerData) {
    return getActionCreator(asyncTypes.POST_PARTNERS_IMPORT_TOP_ASYNC, partnerData);
  },
  getPartnersExportTop() {
    return getActionCreator(asyncTypes.GET_PARTNERS_EXPORT_TOP_ASYNC);
  },
});
