// Core
import React, {
  memo, useCallback, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Icon
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// UI
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditTableCellWrapper from '../../EditTableCellWrapper/EditTableCellWrapper';
import confirmButtonsStyles from '../../../../../Buttons/ConfirmButtons/ConfirmButtonsStyles';
import ProductsFeedLogs from '../../../../../../page/Products/PriceLists/components/Table/ProductsFeedLogs';
import ButtonShowStatusesLog from '../../../../../../page/Products/Content/ContentProducts/components/ButtonShowStatusesLog';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';
import { historyType } from '../../../../../../../_helpers/data/pages/settings/products/tableStatusesLogsScheme';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../../engine/config/access';
import CategoriesFeatureBtnLogs
  from '../../../../../../page/Products/CategoriesFeaturesManaging/components/CategoriesFeatureLogs/components/CategoriesFeatureBtnLogs';

const useStyles = makeStyles(confirmButtonsStyles);

const ButtonWrapper = memo((props) => {
  const { children, link } = props;
  const classes = useStyles();
  return link
    ? (
      <div>
        <Button sx={{ p: '0px' }} className={classes.selectWidthConfirmClose} href={link} target="_blank">
          <OpenInNewIcon sx={{ p: '2px' }} />
        </Button>
        &nbsp;
        {children}
      </div>
    )
    : children;
});

const ComponentWithShowButton = memo((props) => {
  const {
    normalizeColor, toggleShowFull, fullText,
    text, chipText, secondChipText, secondText, isNotEllipsis, newDesignStyle,
  } = props;
  const style = { color: normalizeColor };
  const classes = useStyles();
  if (!text) {
    return null;
  }
  return (
    <Box onClick={toggleShowFull} sx={style}>
      <EditTableCellWrapper
        visibilityIcon
        off={fullText}
        isNotEllipsis={isNotEllipsis}
        newDesignStyle={newDesignStyle}
      >
        {chipText && (
          <Chip
            label={chipText}
            color="secondary"
            classes={{
              root: classes.chipSelf,
              label: classes.chipLabelSelf,
            }}
          />
        )}
        {secondChipText && (
          <Chip
            label={secondChipText}
            color="primary"
            classes={{
              root: classes.chip,
              label: classes.chipLabel,
            }}
          />
        )}
        {text}
        {secondText && <span className={classes.fs12}> <br /> { secondText }</span>}
      </EditTableCellWrapper>
    </Box>
  );
});

function EditableContainerFullText(props) {
  const {
    text, fullText, color, secondText, chipText,
    secondChipText, name, hashId, onClickFilter, isNotEllipsis, newDesignStyle,
  } = props;
  const classes = useStyles();
  const normalizeColor = color || 'inherit';
  const [showFull, setShowFull] = useState(false);
  const currentValuesPlainToString = `${text}`;
  const hasSourceTypeLogStatusesAccess = useAccessList(accessList.sourceTypeLogsList);
  const hasEnabledInMagentoStatusesAccess = useAccessList(accessList.enabledInMagentoLogsList);
  const hasProductLogsStatusesAccess = useAccessList(accessList.productList);
  const hasPriceProductLogStatusesAccess = useAccessList(accessList.priceProductLogsList);


  const toggleShowFull = useCallback(() => {
    if (typeof text !== 'string' || fullText) {
      return;
    }
    setShowFull(!showFull);
  }, [fullText, showFull, text]);

  const ButtonProductLogs = () => {
    switch (name) {
      case tableDataTypes.enabledInMagentoLog.name: {
        if (hasEnabledInMagentoStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.enabledInMagento} />
          );
        }
        break;
      }
      case tableDataTypes.statusChangedAt.name: {
        if (hasProductLogsStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.status} />
          );
        }
        break;
      }
      case tableDataTypes.sourceType.name: {
        if (hasSourceTypeLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.sourceType} />
          );
        }
        break;
      }
      case tableDataTypes.priceLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.price} />
          );
        }
        break;
      }
      case tableDataTypes.priceAutoUpdateLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.priceAutoUpdate} />
          );
        }
        break;
      }
      case tableDataTypes.salePriceLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.salePrice} />
          );
        }
        break;
      }
      case tableDataTypes.feedPriceLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.feedPrice} />
          );
        }
        break;
      }
      case tableDataTypes.quantityLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.quantity} />
          );
        }
        break;
      }
      case tableDataTypes.feedQuantityLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.feedQuantity} />
          );
        }
        break;
      }
      case tableDataTypes.featureAutoUpdateLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.featureAutoUpdate} />
          );
        }
        break;
      }
      case tableDataTypes.feedSalePriceLog.name: {
        if (hasPriceProductLogStatusesAccess) {
          return (
            <ButtonShowStatusesLog hashId={hashId} historyType={historyType.feedSalePrice} />
          );
        }
        break;
      }
      default: {
        return null;
      }
    }
  };

  return (showFull ? (
    <div className={classes.selectWidthConfirm}>
      {chipText && (
      <Chip
        sx={{ alignSelf: 'flex-start', margin: '0px' }}
        label={chipText}
        color="secondary"
        classes={{
          root: classes.chipSelf,
          label: classes.chipLabelSelf,
        }}
      />
      )}
      {secondChipText && (
      <Chip
        sx={{ alignSelf: 'flex-start', margin: '0px 0px 6px 0px !important', paddingLeft: '0px' }}
        label={secondChipText}
        color="primary"
        classes={{
          root: classes.chip,
          label: classes.chipLabel,
        }}
      />
      )}
      <textarea
        className={classes.onlyShowTextArea}
        disabled
        style={{ color: normalizeColor }}
        value={secondText ? `${currentValuesPlainToString}\n${secondText}` : currentValuesPlainToString}
      />
      <ButtonGroup className={classes.selectWidthConfirmWrap} size="small" variant="contained">
        <ButtonWrapper link={name === 'referrer' ? currentValuesPlainToString : undefined}>
          <Button sx={{ p: '0px' }} className={classes.selectWidthConfirmShow} onClick={toggleShowFull}>
            <VisibilityOffOutlinedIcon />
          </Button>
        </ButtonWrapper>
        {name === 'feedLog' && (
          <ProductsFeedLogs hashId={hashId} />
        )}
        {name === 'updatedAtRequiredLk' && (
          <CategoriesFeatureBtnLogs hashId={hashId} />
        )}
        <ButtonProductLogs />

        {onClickFilter && (
          <Button sx={{ padding: '0px' }} className={classes.selectWidthConfirmCheck} onClick={onClickFilter}>
            <FilterAltOutlinedIcon />
          </Button>
        )}
      </ButtonGroup>
    </div>
  ) : (
    <ComponentWithShowButton
      normalizeColor={normalizeColor}
      toggleShowFull={toggleShowFull}
      fullText={fullText}
      text={text}
      chipText={chipText}
      secondChipText={secondChipText}
      secondText={secondText}
      isNotEllipsis={isNotEllipsis}
      newDesignStyle={newDesignStyle}
    />
  )
  );
}

EditableContainerFullText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullText: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.string,
  onClickFilter: PropTypes.func,
  secondChipText: PropTypes.string,
  chipText: PropTypes.string,
  hashId: PropTypes.string,
  isNotEllipsis: PropTypes.bool,
  newDesignStyle: PropTypes.bool,
};
EditableContainerFullText.defaultProps = {
  color: null,
};

export default memo(EditableContainerFullText);
