// Core
import React, {
  useState, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
// Parts
import BrandsSelect from '../../../../../../../../components/_Form/Selects/BrandsSelect';
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';

function BrandsSelectWithConfirm(props) {
  const {
    feature, onConfirm, value, withConfirm,
    productId, valuesPlain, required, type,
  } = props;
  const normalizeValue = value?.toJS ? value.toJS() : value;
  const defaultValue = { value: null, label: null };
  const initValue = normalizeValue || defaultValue;
  const [selectedValue, setSelectedValue] = useState(initValue);

  useEffect(() => {
    setSelectedValue(normalizeValue || defaultValue);
  }, [value]);

  const onChangeValueConfirm = (payload) => {
    onConfirm({ brand: payload });
  };
  const inputProps = {
    value: selectedValue,
    onChange: setSelectedValue,
  };

  return (
    <ConfirmButtons
      key={productId + get(value, 'value', 'brand-confirm-buttons-key')}
      error={feature.error}
      disabled={feature.pending}
      required={required}
      notEmpty={selectedValue?.value !== null}
      onConfirm={onChangeValueConfirm}
      onReject={setSelectedValue}
      success={feature.success}
      value={selectedValue}
      valuesPlain={valuesPlain}
      withConfirm={withConfirm}
      type={type}
      initValue={initValue}
    >
      <BrandsSelect
        label=""
        disabled={feature.pending}
        input={inputProps}
      />
    </ConfirmButtons>
  );
}

BrandsSelectWithConfirm.propTypes = {
  productId: PropTypes.number.isRequired,
  feature: PropTypes.shape({
    error: PropTypes.bool,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }),
  onConfirm: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ]),
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withConfirm: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
};

BrandsSelectWithConfirm.defaultProps = {
  onConfirm: () => {},
  withConfirm: true,
  valuesPlain: '',
};

export default BrandsSelectWithConfirm;
