// Parts
import { useTranslation } from 'react-i18next';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
// Icons
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
// Parts
import ComponentWithTooltip from '../../../../../../../containers/ComponentWithTooltip';
// Engine
import { locale } from '../../../../../../../../engine/init/i18n';
// Helpers
import { useStyles } from '../../../../../../../hooks/useStyles';
import Styles from './styles';

const HeaderCellOrderComponent = (cellOrderProps) => {
  const classes = useStyles(Styles);
  const { i18n } = useTranslation();
  const currentLocale = locale[i18n.language];
  const {
    children: childrenCellOrderColumn, column: cellOrderColumn, draggingEnabled, resizingEnabled,
  } = cellOrderProps;
  const isFeature = cellOrderColumn.isFeature;
  const descCellOrderColumn = cellOrderColumn?.desc?.[currentLocale] && <b>{cellOrderColumn?.desc?.[currentLocale]}</b>;

  return (
    <TableHeaderRow.Cell
      {...cellOrderProps}
      draggingEnabled={isFeature ? false : draggingEnabled}
      resizingEnabled={isFeature ? false : resizingEnabled}
    >
      <div className={classes.cellWithIcon}>
        {isFeature ? null : <><SwapHorizIcon /><br /></>}
        <ComponentWithTooltip description={descCellOrderColumn}>
          <div className={classes.descriptionLabel}>{childrenCellOrderColumn}</div>
        </ComponentWithTooltip>
      </div>
    </TableHeaderRow.Cell>
  );
};

export default HeaderCellOrderComponent;
