// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function PersonIcon(props) {
  return (
    <SvgIcon className="person-icon" fontSize="inherit" viewBox="0 0 36 32" {...props}>
      <path xmlns="http://www.w3.org/2000/svg" d="M15.8081 0C15.8081 0 16.648 0.72126 18.5078 0.60105C20.3676 0.48084 22.9473 1.44252 23.6672 2.64462C23.6672 2.64462 23.8172 2.9752 23.8172 3.27572C23.8172 3.27572 23.9372 3.45604 24.1172 3.45604C24.2971 3.45604 25.377 3.93688 25.137 7.24266L24.7771 10.6085C24.7771 10.6085 25.377 10.4282 25.377 11.2096C25.377 11.9527 24.8887 14.7072 24.0668 15.1303C23.9795 15.1752 23.919 15.2584 23.8963 15.3539C23.7531 15.9543 23.2447 17.9237 22.5315 18.8487C22.4713 18.9267 22.4388 19.0222 22.4411 19.1208L22.4974 21.4875C22.4974 21.4875 22.6074 21.9832 23.6166 22.2365C23.6951 22.2562 23.7487 22.3293 23.7418 22.4101C23.6968 22.932 23.6521 24.7436 25.137 25.154L31.0163 27.9188L33.656 29.181C33.656 29.181 36.1157 29.8421 35.9957 31.8857H0V31.1645C0 31.1645 0.0599929 29.9022 2.27973 29.181L11.3387 24.9736C11.3387 24.9736 12.5244 24.0685 12.157 22.4229C12.1384 22.3395 12.1892 22.2564 12.2724 22.2373C12.5773 22.1673 13.2409 21.9753 13.3784 21.6077C13.5341 21.1918 13.5102 19.4722 13.5009 19.015C13.4995 18.9432 13.4786 18.874 13.4407 18.8131C13.2254 18.467 12.465 17.1483 12.1418 15.3139C12.1295 15.2444 12.0708 15.1951 12.0004 15.1906C11.7811 15.1765 11.3087 14.9511 11.0387 13.3734C11.0387 13.3734 10.0788 10.7287 11.0987 10.6085L10.7987 6.04055C10.7987 6.04055 10.4988 4.1773 11.9386 3.81667C11.9386 3.81667 12.5385 3.75656 12.6585 2.67467C12.7785 1.59278 13.9783 0.991733 15.5982 0.931628C15.5982 0.931628 15.9581 0.811418 15.1182 0.510893C15.1182 0.510893 16.2581 0.871523 16.3781 0.811418C16.4981 0.751313 15.8081 0 15.8081 0V0Z" fillOpacity="0.24" />
    </SvgIcon>
  );
}

export default PersonIcon;
