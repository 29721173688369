// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../engine/core/orders/saga/asyncActions';
import { actions } from '../../../../engine/core/orders/actions';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
import { usePrevious } from '../../../hooks/usePrevious';
// Data
import { requestDelay } from '../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../_helpers/convertDataToUrlParams';

export const orderStatuses = {
  new: 1,
  done: 5,
  cancel: 6,
};

export function useOrdersList(id, refresh) {
  const currentPage = useSelector(selectors.orders.currentPage);
  const limit = useSelector(selectors.orders.pageSize);
  const filters = useSelector(selectors.orders.filters);
  const sorting = useSelector(selectors.orders.sorting);
  const reload = useSelector(selectors.orders.reload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const ordersNewCounter = useSelector(selectors.statistics.ordersNewCounter);
  const ordersOverdueCounter = useSelector(selectors.statistics.ordersOverdueCounter);
  const prevOrdersNewCounter = usePrevious(ordersNewCounter);
  const prevOrdersOverdueCounter = usePrevious(ordersOverdueCounter);
  const dispatch = useDispatch();
  const hasAccessList = useAccessList(accessList.orderList);
  const offset = limit * currentPage;

  const isNewOrders = useMemo(() => {
    const urls = pageLinks.orders.done !== path;
    const newOrders = prevOrdersNewCounter && prevOrdersNewCounter !== ordersNewCounter;
    const newOverdueOrders = prevOrdersOverdueCounter && prevOrdersOverdueCounter !== ordersOverdueCounter;

    return urls && (newOrders || newOverdueOrders);
  }, [
    ordersNewCounter, prevOrdersNewCounter, path,
    ordersOverdueCounter, prevOrdersOverdueCounter,
  ]);

  const paramsByFilter = useMemo(() => {
    const params = {};
    switch (path) {
      case pageLinks.orders.new: {
        params.orderStatus = [{ value: orderStatuses.new }];
        break;
      }
      case pageLinks.orders.overdue:
        params.overdue = 1;
        break;
      case pageLinks.orders.done: {
        params.orderStatus = [
          { value: orderStatuses.done },
          { value: orderStatuses.cancel },
        ];
        break;
      }
      default: {
        break;
      }
    }
    return filters.toJS().reduce((acc, { columnName, value }) => {
      if (!Object.prototype.hasOwnProperty.call(params, columnName)) {
        acc[columnName] = value;
      }
      return acc;
    }, params);
  }, [filters, path]);

  const getListAsync = useCallback(() => {
    if (hasAccessList) {
      const params = {
        ...paramsByFilter,
        orderBy: convertSortingObjectToUrlParams(sorting.toJS()),
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [
    hasAccessList, paramsByFilter,
    offset, sorting, limit, dispatch,
  ]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    if (isNewOrders) {
      dispatch(actions.setReload(true));
    }
  }, [dispatch, isNewOrders]);

  useEffect(() => {
    getInitialState.current = true;
  }, [routeKey]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      dispatch(asyncActions.getInitialStateAsync(path));
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [
    reload, path, refresh, timeout, getListAsync,
  ]);
}
