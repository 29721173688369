// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_CATEGORIES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRICE_CREATOR_CATEGORIES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_CATEGORIES_INITIAL_STATE_ASYNC, path);
  },
  putStatusCategoryAsync(data) {
    return getActionCreator(asyncTypes.PUT_PRICE_CREATOR_STATUS_CATEGORY_ASYNC, data);
  },
  putUpdateCategories(data) {
    return getActionCreator(asyncTypes.PUT_PRICE_CREATOR_CATEGORIES_UPDATE_ASYNC, data);
  },
});
