// Core
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';

// lodash
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

// UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Parts
import TextField from '../../../../components/_Form/TextField/TextField/TextField';

// Config
import { selectors } from '../../../../../engine/config/selectors';

// helpers
import { formFields, formName } from '../helpers/form';

// hooks
import { useStyles } from '../../../../hooks/useStyles';

// Styles
import TabsStyles from '../../../../containers/Tabs/TabsStyles';

function TransactionPaymentMainTab() {
  const { t } = useTranslation();
  const classes = useStyles(TabsStyles);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const transactionPayment = useSelector(selectors.transactionsPayment.transactionPaymentData);
  const { paymentAccepted } = transactionPayment.toJS();
  const { userConfirmed, confirmedDate } = getFormValues.toJS();

  const getValueFieldPaymentAccepted = useMemo(() => {
    if (isBoolean(paymentAccepted)) {
      return paymentAccepted ? 'Да' : 'Нет';
    }

    return 'Никому не зачислены';
  }, [paymentAccepted]);

  return (
    <Grid
      container
      spacing={3}
      className={classes.tabsContent}
    >
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box mb={2} mt={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.partner}
            label={t('Партнер')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.order}
            label={t('Заказ')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.orderStatus}
            label={t('Статус заказа')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.createdAt}
            label={t('Добавлено')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.updatedAt}
            label={t('Обновлено')}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box mb={2} mt={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.partnerPaymentType}
            label={t('Метод оплаты')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.status}
            label={t('Статус транзакции')}
          />
        </Box>

        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.paymentAccepted}
            label={t('Средства зачислены')}
            input={{
              value: t(getValueFieldPaymentAccepted),
            }}
          />
        </Box>

        {!isEmpty(userConfirmed) && (
        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.userConfirmed}
            label={t('Пользователь который подтвердил')}
          />
        </Box>
        )}

        {!isEmpty(confirmedDate) && (
        <Box mb={2}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.confirmedDate}
            label={t('Дата подтверждения')}
          />
        </Box>
        )}
      </Grid>
    </Grid>
  );
}

TransactionPaymentMainTab.displayName = 'TransactionPaymentMainTab';

export default memo(TransactionPaymentMainTab);
