const HeaderDescriptionAndTooltipStyles = () => ({
  description: {
    fontSize: 18,
    marginLeft: '10px',
  },
  buttonRequired: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
});

export default HeaderDescriptionAndTooltipStyles;
