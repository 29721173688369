// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { billingActions } from '../../action';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';
import i18n from '../../../../init/i18n';

export function* callPostBillingPaymentWorker({ payload }) {
  yield put(billingActions.setBillingStatus({
    downloadPaymentOrder: {
      pending: true,
    },
  }));
  const response = yield apply(api, api.billing.postBillingPayment, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = response.data;
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { invoiceUrl } = responseData;
        if (invoiceUrl) {
          window.open(invoiceUrl, '_blank');
          yield put(billingActions.mergeBillingInvoiceModal({ modalIsOpen: false }));
        } else {
          const { message } = responseData;
          yield put(setSuccessMessage(message, statusTitle));
        }
        break;
      }

      default: {
        getFileFromBlob('payment.pdf', response);
        yield put(billingActions.setBillingStatus({
          downloadPaymentOrder: {
            status: 'success',
          },
        }));
        yield put(billingActions.mergeBillingInvoiceModal({ modalIsOpen: false }));
        yield put(setSuccessMessage(i18n.t('Счет создан и загружен на ваше устройство.'), i18n.t('Счет создан')));
        break;
      }
    }
  }

  yield put(billingActions.setBillingStatus({
    downloadPaymentOrder: {
      pending: false,
    },
  }));
}
