import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as categoriesFeatureLogsKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const categoriesFeatureLogs = Object.freeze({
  currentPage: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.currentPage], 0),
  filters: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.filters], List()),
  isLoading: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.pending], false),
  list: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.items], List()),
  pageSize: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.reload], false),
  totalCount: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.totalCount], 0),
  sorting: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.sorting], List()),
  settingStatus: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.settingStatus], false),
  settingPending: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.categoriesFeatureLogsList, categoriesFeatureLogsKeys.settingPending], false),
  categoriesFeatureLogsShowModal: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.log, categoriesFeatureLogsKeys.showModal], false),
  categoriesFeatureLogsHashId: state => getIn(state, [categoriesFeatureLogsKeys.controller, categoriesFeatureLogsKeys.log, categoriesFeatureLogsKeys.hashId], false),
});
