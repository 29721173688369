// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';


// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetTypesWorker() {
  yield put(actions.mergeTypes({ pending: true }));
  const response = yield apply(api, api.configurations.getConfigurationsTypes);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeTypes({ items: List(response.data) }));
  }
  yield put(actions.mergeTypes({ pending: false }));
}
