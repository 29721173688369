import React from 'react';
import { Trans } from 'react-i18next';

export const tableDataTypes = Object.freeze({
  feedPriority: {
    name: 'feedPriority',
    type: 'feedPriority',
    description: 'feedPriority type description',
  },
  feedsProductsStatisticsCategoryLink: Object.freeze({
    name: 'category',
    type: 'feedsProductsCategoryLink',
    title: <Trans>Категория</Trans>,
  }),
  feedType: Object.freeze({
    name: 'feedType',
    type: 'feedType',
    title: <Trans>Тип загрузки</Trans>,
  }),
  feedLink: Object.freeze({
    name: 'link',
    type: 'text',
    title: <Trans>Тип загрузки</Trans>,
  }),
  feedsProductsStatisticsProductsCount: Object.freeze({
    name: 'productsCount',
    title: <Trans>Количество товаров</Trans>,
  }),
  categoryFilterFeedProducts: {
    name: 'category',
    type: 'categoryFilterFeedProducts',
    description: 'categoryFilterFeedProducts type description',
  },
  feedStatus: {
    name: 'feedStatus',
    type: 'feedStatus',
    description: 'feedStatus type description',
  },
  feedLog: {
    name: 'feedLog',
    type: 'feedLog',
    description: 'feedLog type description',
  },
  recipientVerificationStatus: {
    name: 'recipientVerificationStatus',
    type: 'recipientVerificationStatus',
    description: 'recipientVerificationStatus type description',
  },
  feedCategoryStatus: {
    name: 'feedCategoryStatus',
    type: 'feedCategoryStatus',
    description: 'feedCategoryStatus type description',
  },
  feedProductsStatus: {
    name: 'feedProductsStatus',
    type: 'feedProductsStatus',
    description: 'feedProductsStatus type description',
  },
  productsQuantity: {
    name: 'productsQuantity',
    type: 'boolean',
    title: <Trans>Количество товаров</Trans>,
    description: 'productsQuantity name description',
  },
  quantity: {
    name: 'quantity',
    type: 'boolean',
    title: <Trans>Количество</Trans>,
    description: 'quantity name description',
  },
  feedQuantity: {
    name: 'feedQuantity',
    type: 'boolean',
    description: 'feedQuantity name description',
  },
  feedSalePrice: {
    name: 'feedSalePrice',
    type: 'price',
    description: 'feedSalePrice name description',
  },
  feedPrice: {
    name: 'feedPrice',
    type: 'price',
    description: 'feedPrice name description',
  },
  brand: {
    name: 'brand',
    type: 'brand',
    title: <Trans>Бренд</Trans>,
    description: 'brand type description',
  },
  idMagentoProduct: {
    name: 'idMagentoProduct',
    type: 'text',
    title: <Trans>M-ID товара</Trans>,
    description: 'idMagentoProduct type description',
  },
  sourceType: {
    name: 'sourceType',
    type: 'sourceType',
    description: 'sourceType type description',
  },
  enabled: {
    name: 'enabled',
    type: 'enabled',
    title: <Trans>Статус пользователя</Trans>,
    description: 'enabled type description',
  },
  faqServiceDescStatus: {
    name: 'faqServiceDescStatus',
    type: 'faqServiceDescStatus',
    description: 'faqServiceDescStatus type description',
  },
  paymentType: {
    name: 'paymentType',
    type: 'paymentType',
    description: 'paymentType type description',
  },
  contentManager: {
    name: 'manager',
    type: 'contentManager',
    description: 'contentManager type description',
  },
  adminManager: {
    name: 'adminManager',
    type: 'adminManager',
    description: 'adminManager type description',
  },
  orderStatus: {
    name: 'orderStatus',
    type: 'orderStatus',
    description: 'orderStatus type description',
  },
  status: {
    name: 'status',
    type: 'status',
    description: 'status type description',
  },
  author: {
    name: 'author',
    type: 'author',
    description: 'author type description',
  },
  assignedTo: {
    name: 'assignedTo',
    type: 'assignedTo',
    description: 'assignedTo type description',
  },
  userConfirmed: {
    name: 'userConfirmed',
    type: 'userConfirmed',
    description: 'userConfirmed type description',
  },
  available: Object.freeze({
    name: 'available',
    type: 'available',
    description: 'available type description',
  }),
  partnerEditEnabled: Object.freeze({
    name: 'partnerEditEnabled',
    type: 'partnerEditEnabled',
    description: 'partnerEditEnabled type description',
  }),
  paymentWaitingAt: {
    name: 'paymentWaitingAt',
    type: 'paymentWaitingAt',
    description: 'paymentWaitingAt type description',
  },
  confirmedDate: {
    name: 'confirmedDate',
    type: 'confirmedDate',
    description: 'confirmedDate type description',
  },
  feedCategory: {
    name: 'feedCategory',
    type: 'feedCategory',
    description: 'feedCategory type description',
  },
  mailTemplateEventName: {
    name: 'mailTemplateEventName',
    description: 'mailTemplateEventName type description',
  },
  shippingTrackingNumber: {
    name: 'shippingTrackingNumber',
    type: 'shippingTrackingNumber',
  },
  ordersPaymentType: {
    name: 'ordersPaymentType',
    type: 'ordersPaymentType',
  },
  productFeedCategoryStatus: {
    name: 'status',
    type: 'productFeedCategoryStatus',
  },
  productFeedCategory: {
    name: 'productFeedCategory',
    type: 'text',
  },
  feedProductQuantity: {
    name: 'feedProductQuantity',
    type: 'feedProductQuantity',
  },
  feedAttributesQuantity: {
    name: 'feedAttributesQuantity',
    type: 'feedAttributesQuantity',
  },
  productFeedAttributeStatus: {
    name: 'attributeStatus',
    type: 'productFeedAttributeStatus',
  },
  ignored: {
    name: 'ignored',
    type: 'ignored',
  },
  attributeIgnored: {
    name: 'attributeIgnored',
    type: 'attributeIgnored',
  },
  partnerPaymentTypes: {
    name: 'partnerPaymentTypes',
    type: 'partnerPaymentTypes',
    title: <Trans>Способы оплаты</Trans>,
    description: 'partnerPaymentTypes type description',
  },
  partnerRoles: {
    name: 'partnerRoles',
    type: 'partnerRoles',
    frontTranslate: true,
    title: <Trans>Роль партнера</Trans>,
    description: 'partnerRoles type description',
  },
  partnerPaymentTypeTransactionStatuses: {
    name: 'partnerPaymentTypeTransactionStatuses',
    type: 'partnerPaymentTypeTransactionStatuses',
    frontTranslate: true,
    title: <Trans>Статус</Trans>,
    description: 'partnerPaymentTypeTransactionStatuses type description',
  },
  mailTemplateGroup: Object.freeze({
    name: 'mailTemplateGroup',
    type: 'mailTemplateGroup',
    title: <Trans>Группа</Trans>,
    description: 'mailTemplateGroup type description',
  }),
  notificationGroup: Object.freeze({
    name: 'notificationGroup',
    type: 'notificationGroup',
    title: <Trans>Группа сообщений</Trans>,
    description: 'notificationGroup type description',
  }),
  notificationType: Object.freeze({
    name: 'notificationType',
    type: 'notificationType',
    title: <Trans>Тип сообщений</Trans>,
    description: 'notificationType type description',
  }),
  mailTemplateRoles: Object.freeze({
    name: 'mailTemplateRoles',
    type: 'mailTemplateRoles',
    title: <Trans>Получатели</Trans>,
    description: 'mailTemplateRoles type description',
  }),
  name: Object.freeze({
    name: 'name',
    title: <Trans>Имя</Trans>,
    width: '188px',
  }),
  requestMethod: Object.freeze({
    name: 'requestMethod',
    title: <Trans>Метод запроса</Trans>,
  }),
  magentoMethod: Object.freeze({
    name: 'magentoMethod',
    title: <Trans>Мадженто метод запроса</Trans>,
  }),
  runTime: Object.freeze({
    name: 'runTime',
    title: <Trans>Время выполнения</Trans>,
  }),
  magentoPath: Object.freeze({
    name: 'magentoPath',
    title: <Trans>Мадженто путь запроса</Trans>,
  }),
  responseCode: Object.freeze({
    name: 'responseCode',
    title: <Trans>Код ответа</Trans>,
  }),
  createdAt: Object.freeze({
    name: 'createdAt',
    title: <Trans>Дата создания</Trans>,
  }),
  updatedAt: Object.freeze({
    name: 'updatedAt',
    title: <Trans>Дата редактирования</Trans>,
  }),
  phone: Object.freeze({
    name: 'phone',
    type: 'phone',
    title: <Trans>Телефон</Trans>,
  }),
  email: Object.freeze({
    name: 'email',
    title: <Trans>Email</Trans>,
  }),
  additionalEmailsList: Object.freeze({
    name: 'additionalEmailsList',
    title: <Trans>Дополнительный Email</Trans>,
  }),
  roleName: Object.freeze({
    name: 'roleName',
    title: <Trans>Роль</Trans>,
  }),
  partnerText: Object.freeze({
    name: 'partner',
    type: 'text',
    description: 'partner type description',
    title: <Trans>Партнер</Trans>,
  }),
  partner: Object.freeze({
    name: 'partner',
    type: 'partner',
    description: 'partner type description',
    title: <Trans>Партнер</Trans>,
  }),
  brandPartner: Object.freeze({
    name: 'brandPartner',
    description: 'brandPartner type description',
  }),
  idMagentoPartner: Object.freeze({
    name: 'idMagentoPartner',
    type: 'partner',
    description: 'idMagentoPartner type description',
    title: <Trans>M-ID Партнера</Trans>,
  }),
  idMagentoPartnerText: Object.freeze({
    name: 'idMagentoPartner',
    type: 'text',
    description: 'idMagentoPartner type description',
    title: <Trans>M-ID Партнера</Trans>,
  }),
  id: Object.freeze({
    name: 'id',
    title: <Trans>ID</Trans>,
    description: 'id type description',
  }),
  title: Object.freeze({
    name: 'title',
    title: <Trans>Название</Trans>,
  }),
  value: Object.freeze({
    name: 'value',
    title: <Trans>Значение</Trans>,
  }),
  configurationsType: Object.freeze({
    name: 'type',
    type: 'type',
    title: <Trans>Тип</Trans>,
  }),
  editable: Object.freeze({
    name: 'editable',
    type: 'boolean',
    title: <Trans>Редактируемое</Trans>,
  }),
  featureAutoUpdate: {
    name: 'featureAutoUpdate',
    type: 'boolean',
    description: 'featureAutoUpdate type description',
  },
  priceAutoUpdate: {
    name: 'priceAutoUpdate',
    type: 'boolean',
    description: 'priceAutoUpdate type description',
  },
  faqStatus: {
    name: 'faqStatus',
    type: 'faqStatus',
    description: 'faqStatus type description',
  },
  categories: {
    name: 'categories',
    type: 'categories',
    description: 'categories type description',
  },
  attributeSets: {
    name: 'categoryAttributeSet',
    type: 'attributeSet',
    description: 'categoryAttributeSet type description',
  },
  filedPercent: {
    name: 'filedPercent',
    description: 'filedPercent type description',
  },
  boolean: {
    name: 'boolean',
    type: 'boolean',
    description: 'boolean type description',
  },
  number: {
    name: 'number',
    type: 'number',
    description: 'number type description',
  },
  datetimeNumber: {
    name: 'datetimeNumber',
    type: 'datetimeNumber',
    description: 'datetimeNumber type description',
  },
  dateTimeStringFromObject: {
    name: 'dateTimeStringFromObject',
    type: 'dateTimeStringFromObject',
    description: 'dateTimeStringFromObject type description',
  },
  dateMonth: {
    name: 'dateMonth',
    type: 'dateMonth',
    description: 'dateMonth type description',
  },
  dateSingle: {
    name: 'dateSingle',
    type: 'dateSingle',
    description: 'dateSingle type description',
  },
  dateString: {
    name: 'dateString',
    type: 'dateString',
    description: 'date type description',
  },
  date: {
    name: 'date',
    type: 'date',
    description: 'date type description',
  },
  category: {
    name: 'category',
    title: <Trans>Категория</Trans>,
    description: 'category type description',
  },
  excludePartners: {
    name: 'excludePartners',
    title: <Trans>Партнеры-исключения</Trans>,
    description: 'excludePartners type description',
  },
  decimal: {
    name: 'decimal',
    type: 'decimal',
    description: 'decimal type description',
  },
  media: {
    name: 'media',
    type: 'media',
    description: 'media type description',
  },
  datetime: {
    name: 'datetime',
    type: 'datetime',
    description: 'datetime type description',
  },
  productMedias: {
    name: 'productMedias',
    type: 'productMedias',
    description: 'productMedias type description',
  },
  productCancelStatus: {
    name: 'cancelStatus',
    type: 'productCancelStatus',
    description: 'productCancelStatus type description',
  },
  cancelStatus: {
    name: 'cancelStatus',
    type: 'cancelStatus',
    description: 'productCancelStatus type description',
  },
  productStatus: {
    name: 'productStatus',
    type: 'productStatus',
    description: 'productStatus type description',
  },
  note: {
    name: 'note',
    description: 'note type description',
  },
  select: {
    name: 'select',
    type: 'select',
    description: 'select type description',
  },
  sku: {
    name: 'sku',
    type: 'sku',
    description: 'sku type description',
  },
  text: {
    name: 'text',
    type: 'text',
    setDescByName: ['filedPercent', 'id', 'brandPartner', 'idMagentoProduct'],
    description: 'text type description',
  },
  textArea: {
    name: 'textArea',
    type: 'textArea',
    description: 'textArea type description',
  },
  multipleSelect: {
    name: 'multipleSelect',
    type: 'multipleSelect',
    description: 'multipleSelect type description',
  },
  length: {
    name: 'length',
  },
  width: {
    name: 'width',
  },
  height: {
    name: 'height',
  },
  weight: {
    name: 'weight',
  },
  ean: {
    name: 'ean',
  },
  price: {
    name: 'price',
    type: 'price',
    description: 'price type description',
  },
  feed: {
    name: 'feed',
    type: 'feed',
    description: 'feed type description',
  },
  feedLocale: {
    name: 'feedLocale',
    type: 'feedLocale',
    description: 'feedLocale type description',
  },
  creditLimitStatus: {
    name: 'creditLimitStatus',
    type: 'boolean',
  },
  messageUsers: {
    name: 'messageUsers',
    title: <Trans>Группа получателей</Trans>,
  },
  messageStatus: {
    name: 'messageStatus',
    type: 'messageStatus',
    title: <Trans>Статус отправки</Trans>,
  },
  sentAt: {
    name: 'sentAt',
    title: <Trans>Дата отправки</Trans>,
  },
  salePrice: {
    name: 'salePrice',
    type: 'price',
    description: 'salePrice type description',
  },
  xmlFileStatuses: Object.freeze({
    name: 'status',
    type: 'xmlFileStatuses',
  }),
  subject: Object.freeze({
    name: 'subject',
    title: <Trans>Тема письма ру.</Trans>,
  }),
  subjectUa: Object.freeze({
    name: 'subjectUa',
    title: <Trans>Тема письма укр.</Trans>,
  }),
  eventName: Object.freeze({
    name: 'eventName',
    type: 'mailTemplateEventName',
    title: <Trans>Событие</Trans>,
  }),
  partnerStatus: Object.freeze({
    name: 'partnerStatus',
    type: 'partnerStatus',
    title: <Trans>Статус партнера</Trans>,
  }),
  partnerCancelStatus: Object.freeze({
    name: 'partnerCancelStatus',
    type: 'partnerCancelStatus',
    title: <Trans>Причина отклонения</Trans>,
  }),
  commissionType: {
    name: 'commissionType',
    type: 'commissionType',
  },
  enabledInIPrice: {
    name: 'enabledInIPrice',
    type: 'enabledInIPrice',
  },
  cancelComment: {
    name: 'cancelComment',
    title: <Trans>Комментарий</Trans>,
  },
  createdAtInHistoryTable: {
    name: 'createdAt',
    title: <Trans>Дата изменения</Trans>,
  },
  partnerCancelStatusInHistoryTable: {
    name: 'partnerCancelStatus',
    type: 'partnerCancelStatus',
    title: <Trans>Причина отмены</Trans>,
  },
  statusFrom: {
    name: 'statusFrom',
    type: 'partnerStatus',
    title: <Trans>Статус до изменения</Trans>,
  },
  userChanged: {
    name: 'userChanged',
    title: <Trans>Пользователь который менял статус</Trans>,
  },
  isTop: {
    name: 'isTop',
    type: 'boolean',
    title: <Trans>ТОП партнер</Trans>,
  },
  statusTo: {
    name: 'statusTo',
    type: 'partnerStatus',
    title: <Trans>Статус после изменения</Trans>,
  },
  statusChangedAt: {
    name: 'statusChangedAt',
    type: 'productStatusLog',
    title: <Trans>Дата изменения статуса</Trans>,
  },
  enabledInMagentoLog: {
    name: 'enabledInMagentoLog',
    type: 'enabledInMagentoLog',
    title: <Trans>Дата изменения статуса товара на сайте</Trans>,
  },
  productFeedParameterAllo: {
    name: 'parameter',
    type: 'parameter',
  },
  priceLog: {
    name: 'priceLog',
    type: 'priceLog',
    title: <Trans>Дата изменения Цены</Trans>,
  },
  priceAutoUpdateLog: {
    name: 'priceAutoUpdateLog',
    type: 'priceAutoUpdateLog',
    title: <Trans>Дата изменения Цены в XML</Trans>,
  },
  salePriceLog: {
    name: 'salePriceLog',
    type: 'salePriceLog',
    title: <Trans>Дата изменения Акционной цены</Trans>,
  },
  feedPriceLog: {
    name: 'feedPriceLog',
    type: 'feedPriceLog',
    title: <Trans>Дата изменения Акционной цены в XML</Trans>,
  },
  quantityLog: {
    name: 'quantityLog',
    type: 'quantityLog',
    title: <Trans>Дата изменения Наличия</Trans>,
  },
  feedQuantityLog: {
    name: 'feedQuantityLog',
    type: 'feedQuantityLog',
    title: <Trans>Дата изменения Наличия в XML</Trans>,
  },
  featureAutoUpdateLog: {
    name: 'featureAutoUpdateLog',
    type: 'featureAutoUpdateLog',
    title: <Trans>Дата изменения Обновления характеристик</Trans>,
  },
  feedSalePriceLog: {
    name: 'feedSalePriceLog',
    type: 'feedSalePriceLog',
    title: <Trans>Дата изменения Акционной цены в XML</Trans>,
  },
  userWhoEdit: {
    name: 'userWhoEdit',
    type: 'userWhoEdit',
    title: 'ФИО лица, внесшего изменения',
  },
  dataFrom: {
    name: 'dataFrom',
    type: 'dataFrom',
    title: 'Значение % заполненности было',
  },
  dataTo: {
    name: 'dataTo',
    type: 'dataTo',
    title: 'Значение % заполненности стало',
  },
  createdAtCategoryHistory: {
    name: 'createdAt',
    title: 'Дата',
  },
  categoryNote: {
    name: 'note',
    description: 'note type description',
    title: 'Комментарий',
  },
  categoryFeatures: {
    name: 'categoryFeatures',
    type: 'categoryFeatures',
  },
  featureName: {
    name: 'name',
    type: 'feature',
    title: 'Название характеристики',
  },
  updatedAtRequiredLk: {
    name: 'updatedAtRequiredLk',
    type: 'datetime',
    title: 'Дата изменения статуса характеристики',
  },
  featureRequiredMg: {
    name: 'requiredMg',
    type: 'requiredMg',
    title: 'Обязательная характеристика в Magento',
  },
  featureRequiredLk: {
    name: 'requiredLk',
    type: 'requiredLk',
    title: 'Обязательная характеристика в ЛК',
  },
  featureRequiredLkValue: {
    name: 'requiredLkValue',
    type: 'requiredLkValue',
    title: 'Изменение статуса характеристики в ЛК',
    desc: {
      ru: 'Активируйте переключатель возле характеристик, которые хотите сделать обязательными для заполнения',
      uk: 'Активуйте перемикач біля тих характеристик, які хочете зробити обов\'язковими для заповнення',
    },
  },
});
