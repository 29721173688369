// Core
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/logisticsDataByCategory/actions';
import { asyncActions } from '../../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
import { userRoles } from '../../../../../../engine/config/userRoles';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
import { useLogisticsDataList } from '../../_hooks/useLogisticsDataList';
import { useUserRole } from '../../../../../hooks/useUserRole';
// Parts
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
import TableFilterRowComponent from './components/TableFilterRowComponent';
import EditComponent from './components/EditComponent';
// Helpers
import { tableColumns } from './tableColumns';

function LogisticsDataTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.logisticsDataByCategory.isLoading);
  const selections = useSelector(selectors.logisticsDataByCategory.selection);
  const isAdminRole = useUserRole(userRoles.admin);

  const onSelection = (selected) => {
    dispatch(actions.setSelection(selected));
  };
    // Rows
  const rows = useSelector(selectors.logisticsDataByCategory.list);
  // Edit
  const hasCategoryEdit = useAccessList(accessList.categoryLogisticsEdit);
  const hasCategoryShow = useAccessList(accessList.categoryLogisticsShow);
  const isShowEditComponent = hasCategoryEdit || hasCategoryShow;
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.logisticsDataByCategory.currentPage);
  const pageSize = useSelector(selectors.logisticsDataByCategory.pageSize);
  const totalCount = useSelector(selectors.logisticsDataByCategory.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = (page) => {
    dispatch(actions.setLogisticsDataByCategoryTreeDataRowIds([]));
    dispatch(actions.setCurrentPage(page));
  };
    // Sorting
  const onSortingChange = (currentSorting) => {
    dispatch(actions.setLogisticsDataByCategoryTreeDataRowIds([]));
    dispatch(actions.setSorting(currentSorting));
  };
  const sorting = useSelector(selectors.logisticsDataByCategory.sorting);
  // Settings
  const settingStatus = useSelector(selectors.logisticsDataByCategory.settingStatus);
  const settingPending = useSelector(selectors.logisticsDataByCategory.settingPending);
  // Filtering
  const filters = useSelector(selectors.logisticsDataByCategory.filters);
  const onFiltersChange = (newFilters) => {
    dispatch(actions.setLogisticsDataByCategoryTreeDataRowIds([]));
    dispatch(actions.setFilters(newFilters));
  };

  // Tree Data
  const treeDataRowIds = useSelector(selectors.logisticsDataByCategory.treeDataRowIds);
  const onTreeDataRowIdsChange = (parentIds) => {
    dispatch(actions.setLogisticsDataByCategoryTreeDataRowIds(parentIds));
  };

  const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r[[tableColumns.idMagentoCategoryParent.name]] === (row ? row[tableColumns.idMagentoCategory.name] : ''));

    if (childRows.length) {
      return childRows;
    }

    return row && row.hasChildren ? [] : null;
  };

  const logisticsDataByCategoryList = useMemo(() => !isEmpty(filters.toJS())
    ? rows.toJS().map(i => ({ ...i, editable: true }))
    : (
      map(rows.toJS(), item => ({
        ...item,
        editable: true,
        [tableColumns.idMagentoCategoryParent.name]: item[tableColumns.idMagentoCategoryParent.name] || '',
      }))
    ),
  [filters, rows]);

  const filteringColumnExtension = [
    { columnName: tableColumns.categoryLogisticsLog.name, filteringEnabled: false },
  ];
  const sortingStateColumnExtensions = [
    { columnName: tableColumns.categoryLogisticsLog.name, sortingEnabled: false },
  ];

  const checkboxProps = {
    rowFilterComponent: TableFilterRowComponent,
    selection: selections,
    selectionOffFlag: 'canSelect',
    onSelection,
  };

  return (
    <DxTable
      name="categoryLogistics"
      fixedColumn
            // showShortText
      isLoading={isLoading}
      rows={logisticsDataByCategoryList}
      updateHooks={useLogisticsDataList}
            // edit
      editComponent={isShowEditComponent ? EditComponent : null}
            // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
            // Tree Data
      treeDataRowIds={filters.size === 0 ? treeDataRowIds : null}
      onTreeDataRowIdsChange={onTreeDataRowIdsChange}
      getChildRows={getChildRows}
      tableTreeColumnFor={tableColumns.idMagentoCategory.name}
            // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions}
            // Filters
      filters={filters}
      onFiltersChange={onFiltersChange}
      filteringExtensions={filteringColumnExtension}
            // Settings
      gridSettings={gridSettings.categoryLogistics.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
            // checkbox
      {...(isAdminRole ? checkboxProps : {})}
    />

  );
}

export default LogisticsDataTable;
