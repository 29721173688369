// Core
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/categoriesFeatureManagement/actions';
import { asyncActions } from '../../../../../../engine/core/categoriesFeatureManagement/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
import i18n from '../../../../../../engine/init/i18n';
// Hooks
import { useCharacteristicsList } from '../../_hooks/useCharacteristicsList';
import { useAccessList } from '../../../../../hooks/useAccessList';
// Parts
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
import EditComponent from './components/EditComponent';
import RowComponent from './components/RowComponent';
// Helpers
import { tableColumns } from '../../tableColumns';

function CategoriesFeatureTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = i18n.language;
  const isLoading = useSelector(selectors.categoriesFeatureManagement.isLoading);

  const hasCharacteristicEdit = useAccessList(accessList.categoriesFeatureManagementEdit);
  const hasCharacteristicShow = useAccessList(accessList.categoriesFeatureManagementShow);

  const isShowEditComponent = hasCharacteristicEdit || hasCharacteristicShow;
  const [selectedRow, setSelectedRow] = useState([]);
  // Rows
  const rows = useSelector(selectors.categoriesFeatureManagement.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.categoriesFeatureManagement.currentPage);
  const pageSize = useSelector(selectors.categoriesFeatureManagement.pageSize);
  const totalCount = useSelector(selectors.categoriesFeatureManagement.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = (page) => {
    dispatch(actions.setCategoriesFeatureTreeDataRowIds([]));
    dispatch(actions.setCurrentPage(page));
  };
  // Sorting
  const onSortingChange = (currentSorting) => {
    dispatch(actions.setCategoriesFeatureTreeDataRowIds([]));
    dispatch(actions.setSorting(currentSorting));
  };
  const sorting = useSelector(selectors.categoriesFeatureManagement.sorting);
  // Settings
  const settingStatus = useSelector(selectors.categoriesFeatureManagement.settingStatus);
  const settingPending = useSelector(selectors.categoriesFeatureManagement.settingPending);
  // Filtering
  const filters = useSelector(selectors.categoriesFeatureManagement.filters);
  const onFiltersChange = (newFilters) => {
    dispatch(actions.setCategoriesFeatureTreeDataRowIds([]));
    dispatch(actions.setFilters(newFilters));
  };

  // Tree Data
  const treeDataRowIds = useSelector(selectors.categoriesFeatureManagement.treeDataRowIds);
  const onTreeDataRowIdsChange = (parentIds) => {
    dispatch(actions.setCategoriesFeatureTreeDataRowIds(parentIds));
  };

  const rowComponent = rowProps => <RowComponent {...rowProps} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />;

  const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r[[tableColumns.idMagentoCategoryParent.name]] === (row ? row[tableColumns.idMagentoCategory.name] : ''));

    if (childRows.length) {
      return childRows;
    }

    return row && row.hasChildren ? [] : null;
  };

  const characteristicList = useMemo(() => (
    !isEmpty(filters.toJS())
      ? rows.toJS()
      : (
        map(rows.toJS(), item => ({
          ...item,
          [tableColumns.idMagentoCategoryParent.name]: item[tableColumns.idMagentoCategoryParent.name] || '',
        }))
      )
  ), [filters, rows]);

  const columns = useMemo(() => {
    const valuesColumns = values(tableColumns);
    return filter(valuesColumns, column => column.name !== 'id')
      .map(i => ({
        ...i,
        title: t(`${i.title}`),
      }));
  }, [language]);

  return (
    <DxTable
      name="category"
      // fixedColumn
      // showShortText
      isLoading={isLoading}
      rows={characteristicList}
      columns={columns}
      updateHooks={useCharacteristicsList}
      rowComponent={rowComponent}
      // edit
      editComponent={isShowEditComponent ? EditComponent : null}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Tree Data
      treeDataRowIds={filters.size === 0 ? treeDataRowIds : null}
      onTreeDataRowIdsChange={onTreeDataRowIdsChange}
      getChildRows={getChildRows}
      tableTreeColumnFor={tableColumns.idMagentoCategory.name}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      onFiltersChange={onFiltersChange}
      // Settings
      gridSettings={gridSettings.category.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default CategoriesFeatureTable;
