// Core
import { put, apply, delay } from 'redux-saga/effects';
import forEach from 'lodash/forEach';
// Engine
import { push } from 'redux-first-history';
import { api, requestStatus } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { actions as feedActions, actions } from '../../actions';
import { pageLinks } from '../../../../../config/routes';

export function* callPutFeedsItemByHashId({ payload }) {
  const {
    parameters, hashId, step,
    onSuccess, onError, setPending,
    partnerEditEnabled, enabledInIPrice, needForceAction, link, forceAction,
  } = payload;
  if (setPending) {
    setPending(true);
  }

  yield put(actions.mergeFeedsItemByHashId({ putPending: true }));
  const normalizeParameters = {};

  if (parameters) {
    forEach(parameters, (value, key) => {
      const [id, name] = key.split('_');
      normalizeParameters[id] = {
        ...normalizeParameters[id],
        [name]: +value,
      };
    });
  }


  const response = yield apply(api, api.feeds.putFeedHashId, [{
    parameters: parameters ? Object.values(normalizeParameters) : undefined,
    partnerEditEnabled,
    enabledInIPrice,
    hashId,
    step,
    needForceAction,
    link,
    forceAction,
  }]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, productFeedCategoryStatuses } = responseData;

    if (productFeedCategoryStatuses) {
      yield put(feedActions.mergeFeedsItemByHashIdStepDataSecond({ productFeedCategoryStatuses }));
    }

    switch (status) {
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        if (onError) {
          onError();
        }
        if (needForceAction) {
          yield put(feedActions.setChangePriceUrlForceAction({
            link,
            hashId,
            forceAction: needForceAction ? 1 : 0,
            errorMessage: errors[0]?.message,
            onSuccess,
            setPending,
          }));
        } else {
          yield put(setErrorMessage(errors, statusTitle));
        }
        break;
      }
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        if (onSuccess) {
          onSuccess();
        }
        if (forceAction) {
          yield put(actions.setChangePriceUrlForceAction({}));
          yield delay(0);
          yield put(push(pageLinks.productsAndPrices.prices));
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeFeedsItemByHashId({ putPending: false }));
  if (setPending) {
    setPending(false);
  }
}
