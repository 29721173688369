export const asyncTypes = Object.freeze({
  GET_FEEDS_LIST_ASYNC: 'GET_FEEDS_LIST_ASYNC',
  GET_FEEDS_INITIAL_STATE_ASYNC: 'GET_FEEDS_INITIAL_STATE_ASYNC',
  GET_FEEDS_STATUSES_LIST_ASYNC: 'GET_FEEDS_STATUSES_LIST_ASYNC',
  PUT_FEEDS_SETTINGS_ASYNC: 'PUT_FEEDS_SETTINGS_ASYNC',
  PUT_FEED_ITEMS_BY_HASH_ID_ASYNC: 'PUT_FEED_ITEMS_BY_HASH_ID_ASYNC',
  GET_FEED_BY_HASH_ID_ASYNC: 'GET_FEED_BY_HASH_ID_ASYNC',
  GET_FEEDS_PRIORITIES_LIST_ASYNC: 'GET_FEEDS_PRIORITIES_LIST_ASYNC',
  GET_FEEDS_TYPES_LIST_ASYNC: 'GET_FEEDS_TYPES_LIST_ASYNC',
  POST_FEEDS_REFRESH_ASYNC: 'POST_FEEDS_REFRESH_ASYNC',
  GET_FEEDS_IMPORT_ASYNC: 'GET_FEEDS_IMPORT_ASYNC',
  GET_FEEDS_EXPORT_ASYNC: 'GET_FEEDS_EXPORT_ASYNC',
  DELETE_FEEDS_BY_HASH_ID_ASYNC: 'DELETE_FEEDS_BY_HASH_ID_ASYNC',
  POST_FEEDS_REFRESH_PRICE_LIST_ASYNC: 'POST_FEEDS_REFRESH_PRICE_LIST_ASYNC',
  GET_FEED_COMPLETION_IMPORT_LIST_ASYNC: 'GET_FEED_COMPLETION_IMPORT_LIST_ASYNC',
  PUT_FEED_COMPLETION_IMPORT_LIST_ASYNC: 'PUT_FEED_COMPLETION_IMPORT_LIST_ASYNC',
  PUT_FEED_COMPLETION_IMPORT_LIST_ITEM_ASYNC: 'PUT_FEED_COMPLETION_IMPORT_LIST_ITEM_ASYNC',
});
