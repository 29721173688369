// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetPaymentTransactionsStatusesListWorker() {
  yield put(actions.mergePaymentTransactionsStatusesList({ pending: true }));

  const response = yield apply(api, api.partners.getPaymentTransactionsStatusesList);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;

    yield put(actions.mergePaymentTransactionsStatusesList({
      items: responseData,
    }));
  }

  yield put(actions.mergePaymentTransactionsStatusesList({ pending: false }));
}
