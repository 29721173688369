// Core
import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form/immutable';
// Parts
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Loading from '../../../components/Loading/Loading';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
// Engine
import { getDate } from '../../../../_helpers/getDate';
import { locale } from '../../../../engine/init/i18n';
import { asyncActions } from '../../../../engine/core/magentoRequests/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { usePrevious } from '../../../hooks/usePrevious';
import { pageLinks } from '../../../../engine/config/routes';
import ShowCodeModal from './components/ShowCodeModal';
import { COLOR } from '../../../containers/App/AppStyles';

export const formName = 'logsMagentoRequestsItem';
function MagentoRequestsPageItem() {
  const { pending } = useSelector(selectors.magentoRequests.item).toJS();
  const { t, i18n } = useTranslation();
  const prevLanguage = usePrevious(i18n.language);
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const lang = i18n.currentLanguage;

  useEffect(() => {
    if ((i18n.language !== prevLanguage) && (prevLanguage !== undefined && i18n.language !== undefined)) {
      dispatch(asyncActions.getItemByHashIdAsync(hashId));
    }
  }, [dispatch, hashId, i18n.language, prevLanguage]);

  useEffect(() => {
    dispatch(asyncActions.getItemByHashIdAsync(hashId));
  }, [dispatch, hashId]);

  const renderTextField = useCallback((fieldRenderProps) => {
    const { input, dateField, ...rest } = fieldRenderProps;
    if (dateField) {
      if (input.value) {
        input.value = getDate(input.value, undefined, locale[lang]);
      }
    }
    return (
      <TextField
        {...rest}
        {...input}
        fullWidth
        disabled
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          maxWidth: '320px', height: '40px', marginTop: '24px', marginBottom: '6px',
        }}
      />
    );
  }, [lang]);

  const renderTextFieldJSON = useCallback((fieldRenderProps) => {
    const { input, label, ...rest } = fieldRenderProps;
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const InputProps = {
      value: '',
      startAdornment:
  <InputAdornment position="start">
    <ArrowRightIcon sx={{ color: 'rgb(255, 89, 250)' }} />
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <span style={{ color: '#303030' }}>"{label}" : &#123; <span style={{ color: '#E00027' }}>...</span> &#125;
    </span>
  </InputAdornment>,
      endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="Show code"
      onClick={() => {
        setOpen(true);
      }}
      component="span"
      size="small"
    >
      <FullscreenOutlinedIcon />
    </IconButton>
  </InputAdornment>,
    };
    try {
      const json = JSON.parse(input.value);
      if (json === null) {
        return null;
      }
      return (
        <>
          <TextField
            {...rest}
            value=""
            label={label}
            disabled
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={InputProps}
            sx={{
              maxWidth: '320px', height: '40px', marginTop: '24px', marginBottom: '6px',
            }}
          />
          <ShowCodeModal
            modalOpen={open}
            fullScreen
            source={json}
            name={label}
            handleModalToggle={handleClose}
            handleModalCancel={handleClose}
          />
        </>
      );
    } catch {
      return renderTextField(fieldRenderProps);
    }
  }, [renderTextField]);

  return (
    <Grid container justifyContent="left">
      <PageHeader title={t('Просмотр ошибки МГ')}>
        <ButtonGoBack defaultPage={pageLinks.settings.logMagentoRequests.all} />
      </PageHeader>
      <Grid item xs={1} />
      {pending
        ? (
          <Grid
            item
            xs={10}
            style={{ height: 320 }}
          >
            <Loading isLoading />
          </Grid>
        )
        : (
          <Box
            sx={{
              marginLeft: '8px',
              padding: '30px',
              boxShadow: COLOR.shadow.block.main,
              borderRadius: '5px',
              backgroundColor: '#fff',
              width: '100%',
            }}
          >
            <Grid item>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ color: COLOR.grey.main, fontWeight: '700', fontSize: '18px' }}>
                  {t('Общие данные')}
                </Box>
                <Field
                  component={renderTextField}
                  label={t('Мадженто путь запроса')}
                  name="magentoPath"
                />
                <Field
                  component={renderTextField}
                  label={t('Мадженто метод запроса')}
                  name="magentoMethod"
                />
                <Field
                  dateField
                  component={renderTextField}
                  label={t('Дата создания лога')}
                  name="createdAt"
                />
                <Field
                  component={renderTextField}
                  label={t('Время выполнения запроса')}
                  name="runTime"
                />
                <Field
                  component={renderTextField}
                  label={t('Метод запроса')}
                  name="requestMethod"
                />
                <Field
                  component={renderTextFieldJSON}
                  label={t('Тело запроса')}
                  name="requestBody"
                />
                <Field
                  component={renderTextField}
                  label={t('Код ответа')}
                  name="responseCode"
                />
                <Field
                  component={renderTextFieldJSON}
                  label={t('Тело ответа')}
                  name="responseBody"
                />
              </Box>
            </Grid>
          </Box>
        )
      }
      <Grid item xs={1} />
    </Grid>
  );
}

export default reduxForm({
  form: formName,
})(MagentoRequestsPageItem);
