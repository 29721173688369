// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Icons
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
// Parts
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Button from '@mui/material/Button';
// Engine
import { actions } from '../../../../../../../../../engine/core/messages/actions';
// Helpers
import { useMessageReadOrEditPage } from '../../../../../_hooks/useMessageReadOrEditPage';
import { recipientsTypes } from '../../../../../_helpers/recipientsTypes';

function ControlButtonsAddMulti(props) {
  const {
    type,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isReadMessage } = useMessageReadOrEditPage();
  const { currentPartner } = recipientsTypes;

  const handleAddMultiplePartners = () => {
    dispatch(actions.mergeRecipientsImport({
      isOpenModal: true,
    }));
  };

  return type === currentPartner ? (
    <Tooltip
      title={t('Добавить несколько партнеров')}
      arrow
      placement="top"
    >
      <Button
        onClick={handleAddMultiplePartners}
        disabled={isReadMessage}
        color="secondary"
      >
        <GroupAddOutlinedIcon />
      </Button>
    </Tooltip>
  ) : null;
}

ControlButtonsAddMulti.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ControlButtonsAddMulti;
