// Core
import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Loading from '../../../components/Loading/Loading';
import PartnerStatusesHistoryTable from './StatusesHistoryTable/PartnerStatusesHistoryTable';
// Hooks
import { useCurrentMode } from '../hooks/useCurrentMode';
import { selectors } from '../../../../engine/config/selectors';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import { actions } from '../../../../engine/core/partners/statusesHistory/actions';
import { initialState, stateKeys } from '../../../../engine/core/partners/statusesHistory/reducer';

import TabsPanel from '../../../containers/Tabs/TabsPanel';

function PartnerEditStatusesHistory(props) {
  const {
    classes, display, mode, index, value,
  } = props;
  const dispatch = useDispatch();
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const isLoading = useSelector(selectors.partner.partnerIsLoading);
  const isLoadingHistory = useSelector(selectors.partnerStatusesHistory.isLoading);
  const filters = useSelector(selectors.partnerStatusesHistory.filters);
  const sorting = useSelector(selectors.partnerStatusesHistory.sorting);
  const isDisabledResetButton = isLoadingHistory || !(filters.size || sorting.size);

  const { isEditMode, isShowMode } = useCurrentMode(mode);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnerStatusesHistoryList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnerStatusesHistoryList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnerStatusesHistoryList).get(stateKeys.currentPage)));
  }, [dispatch]);


  if ((isLoading || postUsersHashIdPending) && (isEditMode || isShowMode)) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  return display === 'none' ? null : (
    <TabsPanel
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
        sx={{
          flexDirection: 'column',
        }}
      >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Box sx={{ textAlign: 'right', marginBottom: '8px' }}>
            <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
          </Box>
          <PartnerStatusesHistoryTable />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

PartnerEditStatusesHistory.propTypes = {
  classes: PropTypes.object,
  display: PropTypes.oneOf(['flex', 'none']),
  mode: PropTypes.oneOf(['create', 'edit', 'show']),
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditStatusesHistory.defaultProps = {
  mode: 'edit',
};

export default PartnerEditStatusesHistory;
