// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  partner: Object.freeze({
    name: 'createdAt',
    title: <Trans>Дата создания</Trans>,
  }),
  balance: Object.freeze({
    name: 'paymentType',
    title: <Trans>Тип платежа</Trans>,
  }),
  holdBalance: Object.freeze({
    name: 'idMagentoOrder',
    title: <Trans>ID заказа</Trans>,
  }),
  summaryBalance: Object.freeze({
    name: 'partner',
    title: <Trans>М-ID Партнера</Trans>,
  }),
  question: Object.freeze({
    name: 'category',
    title: <Trans>Категории</Trans>,
  }),
  status: Object.freeze({
    name: 'status',
    title: <Trans>Статус</Trans>,
  }),
  orderAmount: Object.freeze({
    name: 'orderAmount',
    title: <Trans>Сумма заказа</Trans>,
  }),
  transaction: Object.freeze({
    name: 'transaction',
    title: <Trans>Списание / Начисление</Trans>,
  }),
});
