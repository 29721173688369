import { getIn, List, Map } from 'immutable';
import { stateKeys as magentoRequestsKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const magentoRequests = Object.freeze({
  currentPage: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.currentPage], 0),
  filters: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.filters], List()),
  isLoading: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.pending], false),
  list: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.items], List()),
  pageSize: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.reload], false),
  totalCount: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.totalCount], 0),
  sorting: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.sorting], List()),
  settingStatus: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.settingStatus], false),
  settingPending: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.magentoRequestsList, magentoRequestsKeys.settingPending], false),
  downloadPending: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.download, magentoRequestsKeys.pending], false),
  item: state => getIn(state, [magentoRequestsKeys.controller, magentoRequestsKeys.itemByHashId], Map()),
});
