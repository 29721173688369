// Core
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
// Parts
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import DxTable from '../../../../../../../components/_Table/DxTable/DxTable';
import HeaderComponent from '../../../../../../../components/_Table/TableHeaderWithTooltip/HeaderWithTooltip';
import ContentProductEditLink from '../../../../ContentProductEdit/ContentProductEditLink';
import TableFilterRowComponent from '../TableFilterRowComponent';
import ContentProductStatusProvider from '../ContentProductStatusProvider/ContentProductStatusProvider';
import ContentProductTableCell from '../ContentProductTableCell/ContentProductTableCell';
import ProductDeleteModal from '../../ContentProductsSidebar/ProductDelete/ProductDeleteModal';
import ContentProductEditResultModal from '../../../../ContentProductEdit/ContentProductEditResultModal';
import FeedProductStatisticButton from '../../FeedProductStatistic/FeedProductStatisticButton';
import FeedProductStatisticModal from '../../FeedProductStatistic/FeedProductStatisticModal';
import RowComponent from '../RowComponent';
import CategoryErrorModal from '../ContentProductTableCell/Components/CategoryWithConfirmButtons/CategoryErrorModal';
// Hooks
import { useStyles } from '../../../../../../../hooks/useStyles';
import { useCallbackAfterPostUser } from '../../../../../../../hooks/useCallbackAfterPostUser';
import { useAccessList } from '../../../../../../../hooks/useAccessList';
import { tableFiltersTypes } from '../../../../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { useUserRole } from '../../../../../../../hooks/useUserRole';
// Engine
import { pageSizesProducts } from '../../../../../../../../engine/config/globalConfig';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../../engine/config/access';
import { userRoles } from '../../../../../../../../engine/config/userRoles';
import { actions } from '../../../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../../../engine/core/contentProduct/saga/asyncActions';
import { asyncActions as contentAsyncActions } from '../../../../../../../../engine/core/content/saga/asyncActions';
import ContentProductTableStyles from './ContentProductTableStyles';
import { tableDataTypes } from '../../../../../../../../_helpers/data/tableDataTypes';
import ConfirmChangeModal from './components/ConfimChangeModal';

const InfoCell = () => {
  const { t } = useTranslation();
  const classes = useStyles(ContentProductTableStyles);

  return (
    <div className={classes.styleWrapper}>
      <p>
        {t('Для получения или обновления данных, необходимо нажать кнопку')} <br /> <b>{t('обновления данных таблицы')}</b>
        <span className={classes.styleButton} /> {t('в левой верхней части таблицы')}.
        <br />
        <br />
        {t('Для обновления данных в автоматическом режиме,')}<br />{t('измените состояние переключателя возле кнопки обновления данных')}
        <span className={classes.styleSwitch} />
      </p>
    </div>
  );
};

const NoDataCellComponent = memo(props => (
  <VirtualTable.NoDataCell
    {...props}
    getMessage={InfoCell}
  />
));

const BottomComponent = () => {
  const productsStatistic = useSelector(selectors.feedsProducts.productsStatistic);
  const { modalOpened } = productsStatistic.toJS();
  const hasAccessFeedProductCategoriesStatistic = useAccessList(accessList.feedProductCategoriesStatisticList);
  return (
    <>
      {hasAccessFeedProductCategoriesStatistic && <FeedProductStatisticButton />}
      {hasAccessFeedProductCategoriesStatistic && modalOpened && <FeedProductStatisticModal />}
    </>
  );
};

function ContentProductTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.contentProduct.isLoading);
  const selections = useSelector(selectors.contentProduct.selection);
  const sendQuery = useSelector(selectors.contentProduct.sendQuery);
  const isCallCenterRole = useUserRole(userRoles.callCenter);
  const { categoryId } = useParams();
  // Rows
  const rows = useSelector(selectors.contentProduct.list);
  // Paging
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const pageSize = useSelector(selectors.contentProduct.pageSize);
  const totalCount = useSelector(selectors.contentProduct.totalCount);
  const hasAccessProductEdit = useAccessList(accessList.productEdit);
  const hasAccessProductShow = useAccessList(accessList.productShow);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.contentProduct.sorting);
  // Settings
  const settingStatus = useSelector(selectors.contentProduct.settingStatus);
  const settingPending = useSelector(selectors.contentProduct.settingPending);
  const gridSettings = useSelector(selectors.allProductsProductGrid);
  // Filtering
  const filters = useSelector(selectors.contentProduct.filters);

  const onSelection = (selected) => {
    dispatch(actions.setSelection(selected));
  };
  useCallbackAfterPostUser(() => {
    dispatch(contentAsyncActions.getProductsStatusesAsync({ forceUpdate: true }));
  }, []);

  useEffect(() => {
    dispatch(contentAsyncActions.getProductsStatusesAsync());
  }, [dispatch]);

  const dataTypeProviderProps = useMemo(() => {
    const contentProductStatusCells = gridSettings.toJS()
      .filter(item => (
        item.type === tableFiltersTypes.productCancelStatus
        || item.type === tableFiltersTypes.productStatus
        || item.type === tableFiltersTypes.productNote
      ))
      .map(item => item.name);

    return {
      formatterComponent: ContentProductStatusProvider,
      for: contentProductStatusCells,
    };
  }, [gridSettings, categoryId]);

  const filteringColumnExtension = [
    { columnName: tableDataTypes.statusChangedAt.name, filteringEnabled: false },
    { columnName: tableDataTypes.enabledInMagentoLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.priceLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.priceAutoUpdateLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.salePriceLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.feedPriceLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.quantityLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.feedQuantityLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.featureAutoUpdateLog.name, filteringEnabled: false },
    { columnName: tableDataTypes.feedSalePriceLog.name, filteringEnabled: false },
  ];

  const sortingColumnExtensions = [
    { columnName: 'productGroupFeatures', sortingEnabled: false },
  ];

  return (
    <>
      <DxTable
        name="product"
        fixedColumn
        rowFilterComponent={TableFilterRowComponent}
        isLoading={isLoading}
        rows={rows}
        rowComponent={RowComponent}
        dataTypeProviderProps={dataTypeProviderProps}
        noDataCellComponent={sendQuery === undefined && NoDataCellComponent}
        // edit/show
        editComponent={(hasAccessProductEdit || hasAccessProductShow) ? ContentProductEditLink : undefined}
        customCellComponent={ContentProductTableCell}
        // Paging
        customPageSizes={pageSizesProducts}
        pageSize={pageSize}
        totalCount={totalCount}
        onPageSize={onPageSize}
        currentPage={currentPage}
        onCurrentPage={onCurrentPage}
        // Sorting
        onSortingChange={onSortingChange}
        sorting={sorting}
        sortingStateColumnExtensions={sortingColumnExtensions}
        // Header
        customHeaderCellComponent={settingStatus ? undefined : HeaderComponent}
        // Filters
        filters={filters}
        filtersAction={actions.setFilters}
        filteringExtensions={filteringColumnExtension}
        // Settings
        gridSettings={gridSettings}
        settingStatus={settingStatus}
        settingPending={settingPending}
        settingAction={asyncActions.putSettingsAsync}
        // Selection
        selection={selections}
        selectionOffFlag="editable"
        onSelection={isCallCenterRole ? undefined : onSelection} // TODO You need to check the access list for each control button
        bottomComponent={<BottomComponent />}
      />
      <ContentProductEditResultModal />
      <ProductDeleteModal />
      <CategoryErrorModal />
      <ConfirmChangeModal />
    </>
  );
}

export default memo(ContentProductTable);
