import { getIn, List, Map } from 'immutable';
// Store keys
import { stateKeys as stopWordsKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const stopWords = Object.freeze({
  currentPage: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.currentPage], 0),
  filters: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.filters], List()),
  isLoading: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.pending], false),
  list: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.items], List()),
  pageSize: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.reload], false),
  totalCount: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.totalCount], 0),
  getStopWordHashId: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.getStopWordHashId], Map()),
  sorting: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.sorting], List()),
  settingStatus: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.settingStatus], false),
  settingPending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.settingPending], false),
  selection: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.stopWordsList, stopWordsKeys.selection], List()),
  deletePending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.deleteStopWords, stopWordsKeys.pending], false),
  postUploadStopWordPending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.uploadStopWord, stopWordsKeys.pending], false),
  isModalOpenUploadStopWord: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.uploadStopWord, stopWordsKeys.isModalOpen], false),
  postStopWordPending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.postStopWord, stopWordsKeys.pending], false),
  getStopWordHashIdPending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.getStopWord, stopWordsKeys.pending], false),
  putStopWordPending: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.putStopWord, stopWordsKeys.pending], false),
  getExportStopWords: state => getIn(state, [stopWordsKeys.controller, stopWordsKeys.getExportStopWords, stopWordsKeys.pending], false),
});
