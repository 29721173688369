import { getIn, List } from 'immutable';
import { stateKeys as attributeSetKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const attributeSet = Object.freeze({
  currentPage: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.currentPage], 0),
  filters: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.filters], List()),
  isLoading: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.pending], false),
  list: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.items], List()),
  pageSize: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.reload], false),
  totalCount: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.totalCount], 0),
  sorting: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.sorting], List()),
  settingStatus: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.settingStatus], false),
  settingPending: state => getIn(state, [attributeSetKeys.controller, attributeSetKeys.list, attributeSetKeys.settingPending], false),
});
