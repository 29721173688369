import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Parts
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { notificationsActionAsync } from '../../../../engine/core/notifications/saga/asyncAction';
import { notificationsActions } from '../../../../engine/core/notifications/action';

function ReadNotificationsButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Export
  const selection = useSelector(selectors.notification.selection);
  const notificationList = useSelector(selectors.notification.list);
  const isLoading = useSelector(selectors.notification.isLoading);
  const normalizeSelection = selection.toJS();
  const hasUnreadNotification = notificationList.toJS().some(notification => !notification.read);
  const onClick = () => {
    dispatch(notificationsActionAsync.putNotificationsAsync(normalizeSelection));
    dispatch(notificationsActions.setSelection([]));
  };
  return (
    <ButtonWithIconAndCircularProgress
      text={!normalizeSelection.length ? t('Прочитать все уведомления') : t('Прочитать выбранное')}
      isLoading={isLoading}
      onClick={onClick}
      className="target4"
      sx={{ height: '32px' }}
      disabled={!hasUnreadNotification || isLoading}
      withoutIcon
    />
  );
}

export default ReadNotificationsButton;
