// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setFeedsList(data) {
    return getActionCreator(types.SET_FEEDS_LIST, data);
  },
  setFeedsCurrentPage(currentPage) {
    return getActionCreator(types.SET_FEEDS_CURRENT_PAGE, currentPage);
  },
  setFeedsFilters(filters) {
    return getActionCreator(types.SET_FEEDS_FILTERS, filters);
  },
  setFeedsPageSize(pageSize) {
    return getActionCreator(types.SET_FEEDS_PAGE_SIZE, pageSize);
  },
  setFeedsSorting(sorting) {
    return getActionCreator(types.SET_FEEDS_SORTING, sorting);
  },
  setFeedsInitialState(initialState) {
    return getActionCreator(types.SET_FEEDS_INITIAL_STATE, initialState);
  },
  setFeedsSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_FEEDS_SETTING_STATUS, settingsStatus);
  },
  setFeedsSettingsPending(settingPending) {
    return getActionCreator(types.SET_FEEDS_SETTING_PENDING, settingPending);
  },
  setFeedsDeletePending(pending) {
    return getActionCreator(types.SET_FEEDS_DELETE_PENDING, pending);
  },
  setFeedsDelete(data) {
    return getActionCreator(types.SET_FEEDS_DELETE_MODAL_SHOW, data);
  },
  mergeFeedsStatusesList(statusesList) {
    return getActionCreator(types.MERGE_FEEDS_STATUSES_LIST, statusesList);
  },
  mergeFeedsItemByHashId(data) {
    return getActionCreator(types.MERGE_FEEDS_ITEM_BY_HASH_ID, data);
  },
  mergeFeedsItemByHashIdStepDataSecond(data) {
    return getActionCreator(types.MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA_SECOND, data);
  },
  setFeedsItemByHashIdStepData(data) {
    return getActionCreator(types.MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA, data);
  },
  setFeedsItemByHashIdCanParse(data) {
    return getActionCreator(types.SET_FEEDS_ITEM_BY_HASH_CAN_PARSE, data);
  },
  setDefaultFeedsItemByHashId() {
    return getActionCreator(types.SET_DEFAULT_FEEDS_ITEM_BY_HASH_ID);
  },
  mergeFeedsPrioritiesList(data) {
    return getActionCreator(types.MERGE_FEEDS_PRIORITIES_LIST, data);
  },
  setImportError(data) {
    return getActionCreator(types.SET_FEEDS_IMPORT_ERROR, data);
  },
  setImportPending(data) {
    return getActionCreator(types.SET_FEEDS_IMPORT_PENDING, data);
  },
  mergeExport(hashId) {
    return getActionCreator(types.MERGE_FEEDS_EXPORT, hashId);
  },
  mergeImport(data) {
    return getActionCreator(types.MERGE_FEEDS_IMPORT, data);
  },
  setExportForceAction(data) {
    return getActionCreator(types.SET_FEEDS_EXPORT_FORCE_ACTION, data);
  },
  setChangePriceUrlForceAction(data) {
    return getActionCreator(types.SET_FEEDS_CHANGE_URL_FORCE_ACTION, data);
  },
  mergeFeedsRefresh(data) {
    return getActionCreator(types.MERGE_FEEDS_REFRESH, data);
  },
  mergeFeedsTypesList(data) {
    return getActionCreator(types.MERGE_FEEDS_TYPES_LIST, data);
  },
  setFeedsRefreshResult(data) {
    return getActionCreator(types.SET_FEEDS_REFRESH_RESULT, data);
  },
  setCompletionImportList(data) {
    return getActionCreator(types.SET_COMPLETION_IMPORT_LIST, data);
  },
  mergeCompletionImportListItem(data) {
    return getActionCreator(types.MERGE_COMPLETION_IMPORT_LIST_ITEM, data);
  },
});
