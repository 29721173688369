// Core
import React from 'react';

function FileUnknownIcon(props) {
  return (
    <svg width="29" height="38" viewBox="0 0 29 38" fill="none" fontSize="inherit" {...props}>
      <g clipPath="url(#clip0_1905_330)">
        <g filter="url(#filter0_d_1905_330)">
          <path d="M29.0006 8.20201V8.10319L21.254 0H21.1547V8.20201H29.0006Z" fill="#D9D7CA" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.1541 8.20201H29V26.3848V36.5849C29 37.3656 28.3644 38 27.5778 38H1.42219C0.637603 38 0 37.3675 0 36.5849V26.3848V1.41509C0 0.63442 0.637603 0 1.42219 0H21.1541V8.20201Z" fill="#EBE8E1" />
        <g clipPath="url(#clip1_1905_330)">
          <path d="M20.1926 27.6667H8.52598V16H16.026V14.3334H8.52598C7.60931 14.3334 6.85931 15.0834 6.85931 16V27.6667C6.85931 28.5834 7.60931 29.3334 8.52598 29.3334H20.1926C21.1093 29.3334 21.8593 28.5834 21.8593 27.6667V20.1667H20.1926V27.6667ZM13.701 25.025L12.0676 23.0584L9.77598 26H18.9426L15.9926 22.075L13.701 25.025ZM21.8593 14.3334V11.8334H20.1926V14.3334H17.6926C17.701 14.3417 17.6926 16 17.6926 16H20.1926V18.4917C20.201 18.5 21.8593 18.4917 21.8593 18.4917V16H24.3593V14.3334H21.8593Z" fill="#303030" fillOpacity="0.72" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_1905_330" x="17.1547" y="-4" width="23.8459" height="24.202" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1905_330" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1905_330" result="shape" />
        </filter>
        <clipPath id="clip0_1905_330">
          <rect width="29" height="38" rx="3" fill="white" />
        </clipPath>
        <clipPath id="clip1_1905_330">
          <rect width="20" height="20" fill="white" transform="translate(5.19266 11)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FileUnknownIcon;
