// Core
import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';
// Parts
import Select from '../../../_Form/Selects/Select/Select';

const TableHeadFilterSelectMultiple = (props) => {
  const {
    values, disabled, filters, onSelectChange, hasRejectButton,
    placeholder, wrapperClassName, isLoading, closeMenuOnScroll, searchErrorLabel,
  } = props;
  const [value, onChange] = useState(filters.value);

  const onConfirm = (hardValue) => {
    if (hardValue) {
      onSelectChange(hardValue);
    } else {
      onSelectChange(value);
    }
  };
  const showSearch = values?.length > 3 || values?.size > 3;
  const onReject = () => {
    onSelectChange('');
    onChange(null);
  };

  const onMenuClose = () => {
    onChange(filters.value ? filters.value : null);
  };

  const input = useMemo(() => ({
    value: disabled ? filters?.value : value,
    onChange,
  }), [disabled, filters?.value, value]);

  return (
    <Select
      options={values}
      disabled={disabled}
      placeholder={placeholder}
      sendOnRemove
      input={input}
      onConfirm={onConfirm}
      onReject={hasRejectButton ? onReject : null}
      hasConfirmButton
      hasSearch={showSearch}
      isLoading={isLoading}
      hideSelectedOptions={false}
      onMenuClose={onMenuClose}
      isMulti
      closeMenuOnScroll={closeMenuOnScroll}
      wrapperClassName={wrapperClassName}
      searchErrorLabel={searchErrorLabel}
    />
  );
};

TableHeadFilterSelectMultiple.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  hasRejectButton: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
  searchErrorLabel: PropTypes.string,
};

TableHeadFilterSelectMultiple.defaultProps = {
  values: [],
};

export default TableHeadFilterSelectMultiple;
