// Core
import { apply, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
// Parts
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
// Actions
import { actions } from '../../actions';
// Config
import { selectors } from '../../../../../config/selectors';
import { api, requestStatus } from '../../../../../config/api';

export function* callGetListWorker({ payload: params }) {
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.feedsProducts.getList, [params]);
  const selection = yield select(selectors.feedsProducts.selection);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;
    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default:
        data = Object.assign(data, response.data);
        break;
    }
  }

  if (!isEmpty(selection.toJS())) {
    yield put(actions.setSelection([]));
  }
  yield put(actions.setList(data));
}
