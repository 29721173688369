// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { form } from '../../../../../ui/page/Products/CategoriesFeaturesManaging/components/CategoryFeatureImportModal';

export function* callPostCategoriesFeatureImportWorker({ payload }) {
  yield put(actions.mergeCategoriesFeatureImport({ pending: true }));

  const response = yield apply(api, api.categoriesFeatureManagement.postCategoriesFeatureImport, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, statusTitle } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = responseData;
        yield put(reset(form.name));
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergeCategoriesFeatureImport({ isOpen: false }));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeCategoriesFeatureImport({ pending: false }));
}
