import { getIn, List, Map } from 'immutable';
import { stateKeys as contentTemplatesKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const contentTemplatesImport = Object.freeze({
  currentPage: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.currentPage], 0),
  filters: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.filters], List()),
  isLoading: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.pending], false),
  list: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.items], List()),
  pageSize: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.reload], false),
  totalCount: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.totalCount], 0),
  sorting: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.sorting], List()),
  settingStatus: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.settingStatus], false),
  settingPending: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesList, contentTemplatesKeys.settingPending], false),
  importModal: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesImportModal], Map()),
  productsFeatureValuesListDownload: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.productsFeatureValuesListDownload], Map()),
  productsImportTemplatesDownload: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.productsImportTemplatesDownload], Map()),
  updateFromMagentoPending: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentUpdateFromMagento, contentTemplatesKeys.pending], false),
  updateValuesFromMagentoPending: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentUpdateValuesFromMagento, contentTemplatesKeys.pending], false),
  updateValuesFromMagentoFeatureId: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentUpdateValuesFromMagento, contentTemplatesKeys.featureId], null),
  updateFromMagentoCategoryId: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentUpdateFromMagento, contentTemplatesKeys.categoryId], null),
  hideSubmitButton: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.contentTemplatesImportModal, contentTemplatesKeys.hideSubmitButton], false),
  productsImportPending: state => getIn(state, [contentTemplatesKeys.controller, contentTemplatesKeys.productsImportPending], false),
});
