import React, {
  useRef, useState, useCallback, useId,
} from 'react';
import {
  MenuItem, Menu,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { COLOR } from '../../../../../containers/App/AppStyles';


const MoreMenuItem = ({
  label, children, id, MenuProps, ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemRef = useRef(null);
  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const handleItemKeyDown = (ev) => {
    if ((ev.key !== 'ArrowRight' && ev.key !== 'Enter') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown = (ev) => {
    ev.stopPropagation();
    if ((ev.key !== 'ArrowLeft' && ev.key !== 'Escape') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) {
      return;
    }
    ev.preventDefault();
    setIsOpen(false);
  };
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <MenuItem
      {...other}
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      sx={[{ justifyContent: 'space-between' }, isOpen && { backgroundColor: 'action.hover' }]}
      onMouseEnter={open}
      onMouseLeave={close}
      onClick={handleClick}
      id={normMenuItemId}
    >
      <span>{label}</span>
      <NavigateNextIcon sx={{ fontSize: '18px' }} />
      <Menu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }} // If you need a submenu more than one level deep, it’s probably better to use context to check if it’s a top level component to focus only once
        disableRestoreFocus
        onKeyDown={handleMenuKeyDown}
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
            background: COLOR.darkStyle.main,
            zIndex: 1300,
          },
          '& .MuiList-root': {
            padding: '4px 2px',
            pointerEvents: 'auto',
          },
          '& .MuiMenuItem-root': {
            minHeight: '40px',
            borderRadius: '5px',
            padding: '5px 13px',
            fontSize: '14px',
            lineHeight: 1,
            color: COLOR.darkStyle.fontColorMain,
            '&:hover': {
              background: COLOR.darkStyle.white['8'],
            },
            '& .MuiListItemIcon-root': {
              minWidth: 'auto',
              marginRight: '5px',
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
              lineHeight: 1,
              color: COLOR.darkStyle.fontColorMain,
            },
            '& .MuiSvgIcon-root': {
              color: COLOR.darkStyle.fontColorMain,
            },
          },
        }}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId,
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={MenuProps?.anchorOrigin ?? { vertical: 'top', horizontal: 'right' }}
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: 'top',
            horizontal: 'left',
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  );
};

export default MoreMenuItem;
