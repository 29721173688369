export const asyncTypes = Object.freeze({
  GET_NOTIFICATIONS_ASYNC: 'GET_NOTIFICATIONS_ASYNC',
  PUT_NOTIFICATIONS_ASYNC: 'PUT_NOTIFICATIONS_ASYNC',
  GET_NOTIFICATIONS_LIST_ASYNC: 'GET_NOTIFICATIONS_LIST_ASYNC',
  PUT_NOTIFICATIONS_SETTINGS_ASYNC: 'PUT_NOTIFICATIONS_SETTINGS_ASYNC',
  GET_NOTIFICATIONS_INITIAL_STATE_ASYNC: 'GET_NOTIFICATIONS_INITIAL_STATE_ASYNC',
  GET_NOTIFICATIONS_BY_HASH_ID_ASYNC: 'GET_NOTIFICATIONS_BY_HASH_ID_ASYNC',
  GET_NOTIFICATIONS_GROUPS_ASYNC: 'GET_NOTIFICATIONS_GROUPS_ASYNC',
  GET_NOTIFICATIONS_TYPES_ASYNC: 'GET_NOTIFICATIONS_TYPES_ASYNC',
});
