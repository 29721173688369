// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_FILES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRICE_CREATOR_FILES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_FILES_INITIAL_STATE_ASYNC, path);
  },
  downloadDataXmlAsync(Id) {
    return getActionCreator(asyncTypes.GET_PRICE_CREATOR_DATA_XML_DOWNLOAD_ASYNC, Id);
  },
});
