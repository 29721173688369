import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { COLOR } from '../../../containers/App/AppStyles';

export const TableCellStyle = () => ({
  text: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  relative: {
    position: 'relative',
  },
  starWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  margin: { margin: 4 },
  star: {
    fontSize: 'inherit',
    marginLeft: 2,
  },
  ttnWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  ttnInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const IconButtonStyles = styled(IconButton)`
  color: ${COLOR.controlsIcons.main};
  border-radius: 5px;
  padding: 0;
  background-color: rgba(48, 48, 48, 0.04);
  width: 24px;
  height: 24px;
  svg {
    color: ${COLOR.controlsIcons.main};
    padding: 2px;
  }
`;
