// Core
import {
  apply, put, select,
} from 'redux-saga/effects';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../../config/selectors';

export function* callPostPartnersPaymentAccessesWorker({ payload }) {
  yield put(actions.setPartnersPaymentAccessesPending(true));
  const currentPage = yield select(selectors.partnersAccess.currentPage);
  const filters = yield select(selectors.partnersAccess.filters);
  const pageSize = yield select(selectors.partnersAccess.pageSize);
  const sorting = yield select(selectors.partnersAccess.sorting);

  const response = yield apply(api, api.partners.postPaymentAccesses, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        const params = {
          limit: pageSize,
          offset: pageSize * currentPage,
          sorting: sorting.toJS(),
          filterType: data.filterType,
        };

        filters.toJS().reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);

        yield put(asyncActions.getListAsync(params));

        yield put(actions.mergePartnerPaymentTypeModal({
          isOpen: false,
          mode: undefined,
        }));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setPartnersPaymentAccessesPending(false));
}
