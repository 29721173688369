import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../../engine/core/categoriesFeatureManagement/categoriesFeatureLogs/actions';
import { selectors } from '../../../../../../engine/config/selectors';
import CategoriesFeatureLogsTable from './components/CategoriesFeatureLogsTable';

function CategoriesFeatureLogsModal() {
  const { t } = useTranslation();
  const categoriesFeatureLogsShowModal = useSelector(selectors.categoriesFeatureLogs.categoriesFeatureLogsShowModal);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(actions.getCategoriesFeatureLogsRefresh({ showModal: false, hashId: null }));
  };

  if (!categoriesFeatureLogsShowModal) {
    return null;
  }

  return (
    <Modal
      title={t('Дата изменения статуса характеристики')}
      modalOpen={categoriesFeatureLogsShowModal}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      modalMaxWidth="md"
      hideButtons
    >
      <CategoriesFeatureLogsTable />
    </Modal>
  );
}


export default CategoriesFeatureLogsModal;
