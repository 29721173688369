import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import ContentWrapper from '../../../containers/ContentWrapper/ContentWrapper';
import ConnectStepper from './stepper/ConnectStepper';
import ContactsForm from './contacts/ContactsForm';
import TrusteesForm from './contacts/TrusteesForm';
import { api } from '../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { AlertModalDialog } from './AlertModalDialog';
import { formatIban, formatPhoneNumber } from '../_helpers/formHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConnectAlloExpressDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    margin: 0,
    overflow: 'scroll',
    height: '100%',
  },
}));
const stepStates = { FIRST_STEP: 0, SECOND_STEP: 1 };
export default function ConnectModal({
  partner, onClose, open, serviceName,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [firstStepValid, setFirstStepValid] = useState(false);
  const [secondStepValid, setSecondStepValid] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { infoFieldValues } = partner;
  const trusteesFormInitialState = {
    trustees: [],
    doNotCreateTrustees: false,
    powerOfAttorneyDate: null,
    isFormValid: false,
  };
  const [trusteesFormData, setTrusteesFormData] = useState(trusteesFormInitialState);

  const steps = [
    'Банківські реквізити',
    'Дані довірених осіб',
  ];

  const emptyForm = {
    partnerHash: partner.hashId,
    isUseCurrentAccount: false,
    phone: '',
    iban: '',
    edrpou: '',
    bankMfo: '',
    bankName: '',
    certificate: null,
  };

  const getPartnerFields = () => {
    if (infoFieldValues
      && (infoFieldValues.directorPhone
        && infoFieldValues.iban
        && infoFieldValues.bankClassifierCode
        && infoFieldValues.interBranchTurnover
        && infoFieldValues.bankName)
    ) {
      return {
        isUseCurrentAccount: true,
        phone: formatPhoneNumber(infoFieldValues.directorPhone.current),
        iban: formatIban(infoFieldValues.iban.current),
        edrpou: infoFieldValues.bankClassifierCode.current,
        bankMfo: infoFieldValues.interBranchTurnover.current,
        bankName: infoFieldValues.bankName.current,
        certificate: '',
      };
    }
    return emptyForm;
  };

  const partnerFields = getPartnerFields();
  const [formData, setFormData] = useState(emptyForm);

  useEffect(() => {
    setFormData(getPartnerFields());
  }, [partner]);

  useEffect(() => {
    setSecondStepValid(trusteesFormData.isFormValid);
  }, [trusteesFormData.isFormValid]);

  const handleStep = (step) => {
    if (step === stepStates.FIRST_STEP) {
      setActiveStep(step);
      return;
    }
    if (step === stepStates.SECOND_STEP && firstStepValid) {
      setActiveStep(step);
    }
  };

  const handleOnCloseConfirmOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmCancel = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmSuccess = () => {
    setConfirmDialogOpen(false);
    onClose();
    setActiveStep(0);
    setFirstStepValid(false);
    setFormData(emptyForm);
    setTrusteesFormData(trusteesFormInitialState);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSend = () => {
    if (!secondStepValid) {
      return;
    }
    const requestFormData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== 'certificate') {
        requestFormData.append(key, formData[key]);
      } else {
        requestFormData.append('certificate', formData.certificate.fileItem.file);
      }
    });
    requestFormData.append('serviceName', serviceName);
    if (!trusteesFormData.doNotCreateTrustees) {
      requestFormData.append('powerOfAttorneyDate', trusteesFormData.powerOfAttorneyDate.format('YYYY-MM-DD'));
      trusteesFormData.trustees.forEach((trustee, index) => {
        Object.keys(trustee).forEach((key) => {
          requestFormData.append(`trustees[${index}][${key}]`, trustee[key]);
        });
      });
    }
    setIsLoading(true);
    api.partnerServiceRequests.createServiceIntegrationRequest(partner.hashId, requestFormData)
      .then((response) => {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
        handleConfirmSuccess();
      })
      .catch((error) => {
        dispatch(setErrorMessage(error, t('Ошибка')));
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ConnectAlloExpressDialog
        fullScreen
        margin={0}
        open={open}
        scroll={'body'}
        onClose={handleOnCloseConfirmOpen}
        TransitionComponent={Transition}
      >
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
          padding={2}
          height={52}
        >
          <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>Підключення послуги ALLO Express</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleOnCloseConfirmOpen}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          height={'50px'}
          boxShadow={'0px 2px 8px 0px #00000014'}
        >
          <ConnectStepper
            steps={steps}
            activeStep={activeStep}
            handleStep={handleStep}
          />
        </Box>
        <ContentWrapper sx={{ marginBottom: '10px' }}>
          <Container maxWidth="md" sx={{ width: '802px' }}>
            <Box
              component="div"
              sx={{
                padding: '15px 20px',
                marginTop: '5px',
                minHeight: '100%',
              }}
            >
              <form onSubmit={handleSend}>
                {activeStep === 0 && (
                  <>
                    <ContactsForm
                      setFormValid={setFirstStepValid}
                      partnerFields={partnerFields}
                      formData={formData}
                      editMode={false}
                      setFormData={setFormData}
                      onClose={onClose}
                    />
                    <Box
                      marginTop={'15px'}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="right"
                      spacing={1}
                    >
                      <Button onClick={handleOnCloseConfirmOpen} sx={{ marginRight: '10px' }} variant="outlined">Скасувати</Button>
                      <Button
                        disabled={!firstStepValid}
                        onClick={() => {
                          handleStep(stepStates.SECOND_STEP);
                        }}
                        variant="contained"
                      >Далі
                      </Button>
                    </Box>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <TrusteesForm
                      formData={trusteesFormData}
                      setTrusteesFormData={setTrusteesFormData}
                      editMode={false}
                    />
                    <Box
                      marginTop={'15px'}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="right"
                      spacing={1}
                    >
                      <Button
                        sx={{ marginRight: '10px' }}
                        onClick={() => {
                          handleStep(stepStates.FIRST_STEP);
                        }}
                        variant="outlined"
                      >Назад
                      </Button>
                      <Box sx={{ flexGrow: 1 }} />
                      <Button
                        sx={{ marginRight: '10px' }}
                        onClick={handleOnCloseConfirmOpen}
                        variant="outlined"
                      >Скасувати
                      </Button>
                      {!secondStepValid && (
                        <Tooltip
                          slotProps={{ popper: { disablePortal: true } }}
                          title={(
                            <Box>
                              <Typography fontSize={'12px'}>Форма не валідна.</Typography>
                              <Typography fontSize={'12px'}>Переконайтесь, що всі обовязкові поля заповненно вірно.</Typography>
                            </Box>
                          )}
                          placement="top"
                        >
                          <Button
                            sx={{
                              '&.Mui-disabled': {
                                pointerEvents: 'auto',
                              },
                            }}
                            disabled={!secondStepValid || isLoading}
                            onClick={() => {
                              handleSend(trusteesFormData);
                            }}
                            variant="contained"
                          >Відправити
                          </Button>
                        </Tooltip>
                      )}
                      {secondStepValid && (
                      <Button
                        disabled={!secondStepValid || isLoading}
                        onClick={() => {
                          handleSend(trusteesFormData);
                        }}
                        variant="contained"
                      >Відправити
                      </Button>
                      )}
                    </Box>
                  </>
                )}
              </form>
            </Box>
          </Container>
        </ContentWrapper>
      </ConnectAlloExpressDialog>
      <AlertModalDialog open={confirmDialogOpen} handleConfirmCancel={handleConfirmCancel} handleConfirmSuccess={handleConfirmSuccess} />
    </>
  );
}
