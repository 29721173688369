import React from 'react';
import { Link } from 'react-router-dom';

// Engine
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
// Icons


import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';

const EmailTemplatesEditCell = (props) => {
  const { tableRow: { row: { hashId } } } = props;
  // Access
  const hasAccessEdit = useAccessList(accessList.mailTemplateEdit);
  // Edit
  if (!hasAccessEdit) {
    return null;
  }
  return (
    <Link to={pageLinks.settings.emailTemplates.edit(hashId)}>
      <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
        <ModeOutlinedIcon sx={{ p: '2px' }} />
      </IconButtonStyles>
    </Link>
  );
};
export default EmailTemplatesEditCell;
