import React, { useEffect, useCallback } from 'react';
import {
  reduxForm, Form, isSubmitting,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { COLOR, BUTTON_SIZE } from '../../containers/App/AppStyles';

// Parts
import ButtonWithIconAndCircularProgress from '../../components/Buttons/ButtonWithIconAndCircularProgress';
import MailSettingsFieldArray from './components/MailSettingsFieldArray';
import PageHeader from '../../components/PageHeader/PageHeader';

// Engine
import { userActionAsync } from '../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../engine/config/selectors';
import Loading from '../../components/Loading/Loading';
// Hooks
import { useCallbackAfterPostUser } from '../../hooks/useCallbackAfterPostUser';
import { useUserSomeRole } from '../../hooks/useUserSomeRole';
import { userRoles } from '../../../engine/config/userRoles';
import ViberSettings from './ViberSettings';

export const formName = 'mailSettingsFrom';

function MailSettings(props) {
  const { handleSubmit, pristine } = props;
  const { t } = useTranslation();
  const submitting = useSelector(isSubmitting(formName));
  const dispatch = useDispatch();
  const {
    mailTemplateGroups,
    items,
    pending,
    putPending,
  } = useSelector(selectors.user.mailSettings).toJS();

  const hasAccessToViberSettings = useUserSomeRole([
    userRoles.partnerActive,
    userRoles.partnerOrderManager,
  ]);
  const currentEmail = useSelector(selectors.user.userInfoEmail);

  const sendData = useCallback((fields) => {
    const normalizeFields = fields.toJS();
    const normaliseForSend = Object.keys(
      normalizeFields,
    ).map(
      key => ({
        id: key,
        enableSend: normalizeFields[key],
      }),
    );
    if (normaliseForSend.length > 0) {
      dispatch(userActionAsync.putMailSettingsAsync(normaliseForSend));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(userActionAsync.getMailSettingsAsync());
  }, [dispatch]);

  useCallbackAfterPostUser(() => {
    dispatch(userActionAsync.getMailSettingsAsync());
  }, []);


  return (

    <Grid container justifyContent="center">
      <PageHeader title={t('Настройка уведомлений')} />
      {pending ? <Loading isLoading /> : (
        <>
          <Box sx={{ width: '100%' }}>
            {hasAccessToViberSettings && <ViberSettings />}
          </Box>
          <Form onSubmit={handleSubmit(sendData)} style={{ width: '100%' }}>
            <Box
              sx={{
                padding: '16px',
                boxShadow: COLOR.shadow.block.main,
                borderRadius: '5px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ color: COLOR.grey.main, fontWeight: '700' }}>
                  <p>{t('Email для уведомления')}: <b>{currentEmail}</b></p>
                </Box>
              </Box>
              <MailSettingsFieldArray fields={items} group={mailTemplateGroups} />
              <ButtonWithIconAndCircularProgress
                sx={{
                  p: BUTTON_SIZE.small, alignSelf: 'flex-end', maxWidth: '160px', margin: '16px',
                }}
                component="button"
                disabled={pending || submitting || putPending || mailTemplateGroups.length === 0 || pristine}
                text={t('Сохранить')}
                type="submit"
                withoutIcon
              />
            </Box>
          </Form>
        </>
      )}
    </Grid>
  );
}

MailSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
};

export default reduxForm({
  form: formName,
})(MailSettings);
