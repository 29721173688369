const getRemovedParents = (indeterminatesState, checkboxesState) => {
  const checkedParentValues = new Set(checkboxesState.map(i => i.parentValue));

  return indeterminatesState.filter((item) => {
    if (item.hasChildren && checkedParentValues.has(item.value)) {
      return false;
    }
    return true;
  }).map(i => i.value);
};

export default getRemovedParents;
