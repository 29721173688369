import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
import { pageSizes } from '../../../config/globalConfig';
import { types } from './types';

export const initialState = fromJS({
  partnersServiceRequestsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    active: undefined,
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    isOpen: false,
  }),
  partnersServiceRequestsListUpload: Map({
    pending: false,
    isModalOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  partnersServiceRequestsList: 'partnersServiceRequestsList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  data: 'data',
  pending: 'pending',
  isDownloadPending: 'isDownloadPending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  controller: 'partnersServiceRequests',
});

export const partnersServiceRequestsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNER_SERVICES_REQUESTS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.partnersServiceRequestsList, stateKeys.pending], pending)
        .setIn([stateKeys.partnersServiceRequestsList, stateKeys.items], List(items))
        .setIn([stateKeys.partnersServiceRequestsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PARTNER_SERVICES_REQUESTS_LIST_CURRENT_PAGE: {
      return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.currentPage], payload);
    }
    case types.SET_PARTNER_SERVICES_REQUESTS_LIST_PAGE_SIZE: {
      return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.pageSize], payload);
    }
    case types.SET_PARTNER_SERVICES_REQUESTS_LIST_SORTING: {
      return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.sorting], List(payload));
    }
    case types.SET_PARTNER_SERVICES_REQUESTS_LIST_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.filters], List(filters));
    }
    case types.SET_PARTNER_SERVICES_REQUESTS_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.partnersServiceRequestsList, stateKeys.isDownloadPending], payload);
    }
    default:
      return state;
  }
};
