// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callGetNotificationsWorker,
  callPutNotificationsWorker,
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetNotificationsByHashIdWorker,
  callGetNotificationsGroupsWorker,
  callGetNotificationsTypesWorker,
} from './workers';

function* watchGetNotifications() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_ASYNC, callGetNotificationsWorker);
}
function* watchPutNotifications() {
  yield takeEvery(asyncTypes.PUT_NOTIFICATIONS_ASYNC, callPutNotificationsWorker);
}
function* watchGetList() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_NOTIFICATIONS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_NOTIFICATIONS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_NOTIFICATIONS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_NOTIFICATIONS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_NOTIFICATIONS_SORTING, callSetToLocalDataWorker);
}
function* watchGetNotificationsByHashId() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_BY_HASH_ID_ASYNC, callGetNotificationsByHashIdWorker);
}
function* watchGetNotificationsGroups() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_GROUPS_ASYNC, callGetNotificationsGroupsWorker);
}
function* watchGetNotificationsTypesWorker() {
  yield takeEvery(asyncTypes.GET_NOTIFICATIONS_TYPES_ASYNC, callGetNotificationsTypesWorker);
}

export function* watchersNotifications() {
  yield all([
    watchGetNotifications(),
    watchPutNotifications(),
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetNotificationsByHashId(),
    watchGetNotificationsGroups(),
    watchGetNotificationsTypesWorker(),
  ]);
}
