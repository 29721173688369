import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';

const EditTableCellWrapperStyle = () => ({
  wrapper: {
    position: 'absolute',
    top: 2,
    left: 2,
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    padding: '5.5px 16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid transparent',
    justifyContent: 'space-between',
    borderRadius: 4,
    gap: '4px',
    columnGap: '6px',
    '@global': {
      button: {
        display: 'none',
        opacity: 0,
      },
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.26)',
      overflow: 'hidden',
      '@global': {
        button: {
          display: 'flex !important',
          opacity: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#efefef',
          borderRadius: '3px',
        },
      },
    },
  },
  presentationText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  success: {
    borderColor: `${green[500]} !important`,
  },
  required: {
    borderColor: `${red[500]} !important`,
  },
  notEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& .MuiChip-root': {
      margin: '0 0 0 0',
      // width: '100%',
    },
  },
  newDesign: {
    padding: '5.5px 6px',
  },
});

export default EditTableCellWrapperStyle;
