const MainLogoImageStyles = theme => ({
  logo: {
    maxWidth: '80%',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
});

export default MainLogoImageStyles;
