// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import Modal from '../../../containers/Modals/Modal/Modal';
// Helpers
import { useUserRole } from '../../../hooks/useUserRole';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { userRoles } from '../../../../engine/config/userRoles';


function IndebtednessModal() {
  const { t } = useTranslation();
  const statusItems = useSelector(selectors.billing.statusItems);
  const statusPending = useSelector(selectors.billing.statusPending);
  const {
    partnersTotalDebt,
    partnersLimitDebt,
  } = statusItems;
  const isAdminRole = useUserRole(userRoles.admin);
  const [paymentNotificationOpen, setPaymentNotificationOpen] = useState(false);
  const indebtednessModalTime = localStorage.getItem('indebtednessModalTime');

  const showPopUp = useCallback(() => {
    if ((Number(partnersTotalDebt) >= Number(partnersLimitDebt)) && statusPending === false) {
      setPaymentNotificationOpen(true);
      localStorage.removeItem('indebtednessModalTime');
    }
  }, [partnersLimitDebt, partnersTotalDebt, statusPending]);

  const hidePopUp = () => {
    setPaymentNotificationOpen(false);
    localStorage.setItem('indebtednessModalTime', Date.now());
  };

  useEffect(() => {
    if ((Number(partnersTotalDebt) >= Number(partnersLimitDebt)) && !indebtednessModalTime) {
      showPopUp();
    } else if ((Number(partnersTotalDebt) < Number(partnersLimitDebt)) && statusPending === false) {
      localStorage.removeItem('indebtednessModalTime');
    }
  }, [indebtednessModalTime, partnersLimitDebt, partnersTotalDebt, showPopUp, statusPending]);

  if (!isAdminRole) {
    return null;
  }

  return (
    <Modal
      title={t('Внимание!')}
      modalOpen={paymentNotificationOpen}
      handleModalCancel={hidePopUp}
      handleModalToggle={hidePopUp}
      modalMaxWidth="sm"
      hideButtons
    >
      <Box
        component="p"
        sx={{ textAlign: 'center' }}
      >
        {t('Общая сумма кредитной задолженности по партнерам превысила граничную.')}
      </Box>
    </Modal>
  );
}

export default IndebtednessModal;
