import { getIn, List } from 'immutable';
import { faqKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const faqs = Object.freeze({
  currentPage: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.currentPage], 0),
  filters: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.filters], List()),
  isLoading: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.pending], false),
  list: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.items], List()),
  pageSize: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.reload], false),
  totalCount: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.totalCount], 0),
  selection: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.selection], List()),
  sorting: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.sorting], List()),
  sortingStateColumnExtensions: state => getIn(state, [faqKeys.controller, faqKeys.faqList, faqKeys.sortingStateColumnExtensions], List()),
  statusesList: state => getIn(state, [faqKeys.controller, faqKeys.statusesList, faqKeys.items], List()),
  statusesListPending: state => getIn(state, [faqKeys.controller, faqKeys.statusesList, faqKeys.pending], false),
  isItemByHashIdLoading: state => getIn(state, [faqKeys.controller, faqKeys.faqItemByHashId, faqKeys.pending], false),
  isItemByHashIdPutPending: state => getIn(state, [faqKeys.controller, faqKeys.faqItemByHashId, faqKeys.putPending], false),
  deletePending: state => getIn(state, [faqKeys.controller, faqKeys.deletePending], false),
  settingStatus: state => getIn(state, [faqKeys.controller, faqKeys.settingStatus], false),
  settingPending: state => getIn(state, [faqKeys.controller, faqKeys.settingPending], false),
});
