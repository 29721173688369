// Core
import indigo from '@mui/material/colors/indigo';
import { COLOR } from '../../../../containers/App/AppStyles';

const RadioButtonsStyles = theme => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControlLabel-root': {
      width: 'fit-content',
    },
    '& .MuiFormControlLabel-label': {
      gap: '6px',
      fontSize: '14px',
      color: COLOR.black.initial,
    },
  },
  darkTheme: {
    '& .MuiFormControlLabel-root': {
      gap: '6px',
      '& .MuiFormControlLabel-label': {
        color: COLOR.darkStyle.fontColorMain,
        '&:hover': {
          color: COLOR.darkStyle.white['72'],
        },
        '&.Mui-checked': {
          color: COLOR.controls.dark,
          '&:hover': {
            color: COLOR.controls.dark,
          },
        },
      },
      '& .MuiRadio-root': {
        color: COLOR.darkStyle.white['38'],
        '&.Mui-checked': {
          color: COLOR.controls.dark,
          '&:hover': {
            color: COLOR.controls.dark,
          },
        },
      },
    },
  },
  row: {
    flexDirection: 'row',
  },
  wrapperWithApprove: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    paddingRight: 10,
    fontSize: '14px',
    color: COLOR.grey['54'],
    '&.Mui-focused': {
      color: COLOR.grey['54'],
    },
  },
  groupItem: {
    marginTop: -12,
  },
  wrapperRequired: {
    '@global': {
      input: {
        paddingRight: 25,
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
      },
    },
  },
  buttonRequired: {
    color: COLOR.brand.dark,
  },
  hasError: {
    color: COLOR.error,
  },
  flexOne: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row !important',
    },
    '@global': {
      'label + .MuiInput-formControl': {
        marginTop: 0,
      },
    },
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: -4,
  },
  wrapperButtonRequired: {
    paddingRight: 20,
  },
  button: {
    width: 42,
    height: 42,
    padding: 7,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  compareButton: {
    color: indigo[500],
  },
  compareButtonActive: {
    borderColor: indigo[500],
  },
  checkButton: {
    color: COLOR.success,
  },
  checkButtonActive: {
    borderColor: COLOR.success,
  },
  cancelButton: {
    color: COLOR.error,
  },
  cancelButtonActive: {
    borderColor: COLOR.error,
  },
  radioHasError: {
    '& .MuiRadio-root': {
      color: COLOR.error,
    },
  },
});

export default RadioButtonsStyles;
