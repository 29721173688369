// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetAttributeSetsContextWorker() {
  yield put(actions.setAttributeSetsListContext({ pending: true }));

  const { attributeSetsList, attributeSetLastUpdate } = localData.keysObj;
  const attributeSetsListLastUpdateFromLocalData = yield apply(localData, localData.getItem, [attributeSetLastUpdate]);
  const attributeSetsListLastUpdateFromStatistics = yield select(selectors.statistics.attributeSetLastUpdate);
  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  let response;

  switch (true) {
    case !attributeSetsListLastUpdateFromLocalData:
    case attributeSetsListLastUpdateFromLocalData !== attributeSetsListLastUpdateFromStatistics: {
      response = yield apply(api, api.attributeSets.getList, [{ context: 'select' }]);
      yield apply(localData, localData.setItem, [attributeSetLastUpdate, attributeSetsListLastUpdateFromStatistics]);
      break;
    }
    case attributeSetsListLastUpdateFromLocalData === attributeSetsListLastUpdateFromStatistics: {
      data = yield apply(localData, localData.getItem, [attributeSetsList]);
      if (!data) {
        response = yield apply(api, api.attributeSets.getList, [{ context: 'select' }]);
      }
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [attributeSetsList, { items: data.items, totalCount: data.totalCount }]);
  }

  yield put(actions.setAttributeSetsListContext({
    pending: false,
    items: List(data.items),
    totalCount: data.totalCount,
  }));
}
