// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@mui/styles';

// UI
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

// Components
import FilesUploader from './FilesUploader';
import { COLOR } from '../../../containers/App/AppStyles';

function FilesUploaderWithValidation(props) {
  const {
    classes, className, fullWidth, meta, ...rest
  } = props;

  const { error, touched } = meta;
  const hasError = touched && typeof error !== 'undefined';

  const hasErrorClassNames = cx({
    [classes.hasError]: hasError,
  });

  const wrapperClassNames = cx(classes.wrapper, classes.errorColor);

  return (
    <FormControl fullWidth={fullWidth}>
      <FilesUploader className={cx(hasErrorClassNames, { [className]: className })} {...rest} />
      {hasError && (
        <div className={wrapperClassNames}>
          <FormHelperText error={!!hasError}>
            {error}
          </FormHelperText>
        </div>
      )}
    </FormControl>
  );
}

FilesUploaderWithValidation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  meta: PropTypes.object,
};

FilesUploaderWithValidation.defaultProps = {
  classes: {},
  className: '',
  fullWidth: true,
  meta: {},
};

const styles = () => ({
  errorColor: {
    color: COLOR.error,
  },
  hasError: {
    '& *': {
      fill: COLOR.error,
      borderColor: COLOR.error,
      color: COLOR.error,
      '&:hover': {
        outlineColor: COLOR.error,
      },
    },
  },
  wrapper: {
    display: 'flex',
  },
});

export default withStyles(styles)(FilesUploaderWithValidation);
