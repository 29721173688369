import { COLOR } from '../../containers/App/AppStyles';

const TimerCountCardStyles = () => ({
  timer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: COLOR.darkStyle.fontColorMain,
    minWidth: '55px',
  },
  shutdownIcon: {
    width: '24px',
    height: '24px',
    marginBottom: '4px',
  },
  timerCountRow: {
    display: 'flex',
    fontSize: '10px',
    alignItems: 'center',
    lineHeight: 1,
    fontWeight: 700,
    color: COLOR.darkStyle.fontColorMain,
  },
  infoIcon: {
    color: COLOR.controls.dark,
    width: '16px',
    height: '16px',
    marginLeft: '5px',
  },
  timerValue: {
    color: COLOR.darkStyle.fontColorMain,
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: 1,
  },
  timerValueInModal: {
    color: COLOR.black.initial,
    fontSize: '16px',
  },
});

export default TimerCountCardStyles;
