// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// UI
import Modal from './Modal/Modal';
// Hooks
import { useMDView } from '../../hooks/useDesktopView';
// Components

function ModalConfirm(props) {
  const { t } = useTranslation();
  const isMDView = useMDView();

  const {
    title, text, children, showModal, pendingConfirm,
    cancelText, confirmText, onCancel, onConfirm, hideButtons, fullScreen, modalMaxWidth,
  } = props;

  return (
    <Modal
      hideButtons={hideButtons}
      modalOpen={showModal}
      handleModalCancel={onCancel}
      handleModalToggle={onCancel}
      modalMaxWidth={modalMaxWidth || 'xs'}
      fullWidth
      fullScreen={fullScreen && isMDView}
      title={title}
      handleModalSend={onConfirm}
      loadingSend={pendingConfirm}
      cancelText={cancelText || t('Отменить')}
      sendText={confirmText || t('Применить')}
      buttonSendIcon={null}
    >
      { text || children }
    </Modal>
  );
}

ModalConfirm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
  showModal: PropTypes.bool,
  pendingConfirm: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  hideButtons: PropTypes.bool,
  fullScreen: PropTypes.bool,
  modalMaxWidth: PropTypes.string,
  // actionButtonPosition: PropTypes.oneOf(['center', 'alignEnd', 'spaceBetween']),
};

ModalConfirm.defaultProps = {
  showModal: false,
  pendingConfirm: false,
  fullScreen: false,
};

export default memo(ModalConfirm);
