// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Grid from '@mui/material/Grid';
// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
// Parts
import PriceCreatorCategoriesTable from '../components/PriceCreatorCategoriesTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import DownloadPriceListButton from '../components/DownloadPriceListButton';
import UpdatePricesButton from '../../Products/Content/ContentProducts/components/ContentProductsSidebar/UpdatePricesButton';
import CopyToClipboardComponent from '../../../containers/CopyToClipboard';
import UpdateDataXmlButton from '../components/UpdateDataXmlButton';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { usePriceCreatorCategoriesList } from './_hooks/usePriceCreatorCategoriesList';
import { useAccessList } from '../../../hooks/useAccessList';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../engine/config/userRoles';
// Engine
import { asyncActions } from '../../../../engine/core/priceCreator/priceCreatorCategories/saga/asyncActions';
import { asyncActions as asyncActionsPriceCreatorFiles } from '../../../../engine/core/priceCreator/priceCreatorFiles/saga/asyncActions';
import { actions as actionsPriceCreatorFiles } from '../../../../engine/core/priceCreator/priceCreatorFiles/actions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/priceCreator/priceCreatorCategories/actions';
import { initialState, stateKeys } from '../../../../engine/core/priceCreator/priceCreatorCategories/reducer';
import { accessList } from '../../../../engine/config/access';
import ModalSelectPartner from '../components/ModalSelectPartner';

function PriceCreatorCategoriesPage() {
  const { t } = useTranslation();
  usePriceCreatorCategoriesList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.priceCreatorCategories.settingStatus);
  const settingPending = useSelector(selectors.priceCreatorCategories.settingPending);
  const isLoading = useSelector(selectors.priceCreatorCategories.isLoading);
  const filters = useSelector(selectors.priceCreatorCategories.filters);
  const sorting = useSelector(selectors.priceCreatorCategories.sorting);
  const updateCategoriesPending = useSelector(selectors.priceCreatorCategories.updateCategoriesPending);
  const xmlFileLink = useSelector(selectors.user.xmlFileLink);
  const hasPriceCreatorUpdateCategories = useAccessList(accessList.xmlCategoriesUpdate);
  const userPartnerId = useSelector(selectors.user.userPartnerId);
  const isAdminAndManagerRole = useUserSomeRole([userRoles.admin, userRoles.manager]);
  const modalSelectPartnerIsOpened = useSelector(selectors.priceCreatorCategories.isOpenPartnersModal);
  const modalSelectPriceCreatorIsOpened = useSelector(selectors.priceCreatorFiles.modalActive);
  const isLoadingDataXmlLoading = useSelector(selectors.priceCreatorFiles.isDownloadUpdateDataXmlLoading);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.priceCreatorCategoriesList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.priceCreatorCategoriesList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.priceCreatorCategoriesList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  const handleUpdateCategories = () => {
    if (isAdminAndManagerRole) {
      dispatch(actions.mergePartnersModal({
        open: true,
      }));
    } else {
      dispatch(asyncActions.putUpdateCategories({
        partner: userPartnerId,
      }));
    }
  };

  const handleSendUpdateCategories = (partner) => {
    dispatch(asyncActions.putUpdateCategories({
      partner,
    }));
  };

  const handleModalToggle = () => {
    dispatch(actions.mergePartnersModal({ open: !modalSelectPartnerIsOpened }));
  };

  const handleSendUpdatePartnerCategories = (partner) => {
    dispatch(asyncActionsPriceCreatorFiles.downloadDataXmlAsync(partner));
  };

  const handleModalPartnerToggle = () => {
    dispatch(actionsPriceCreatorFiles.setDataXmlDownloadModalActive(!modalSelectPriceCreatorIsOpened));
  };

  return (
    <>
      <PageHeader title={(
        <Grid container alignItems="center">
          {t('Категории для XML')}
          {xmlFileLink && (
            <CopyToClipboardComponent
              arrowTooltip
              link={xmlFileLink}
              tooltipText={t('Скопировать ссылку на XML')}
            />
          )}
        </Grid>
      )}
      >
        <UpdateDataXmlButton />
        <DownloadPriceListButton />
        <UpdatePricesButton />
        {hasPriceCreatorUpdateCategories && (
          <ButtonWithIconAndCircularProgress
            text={t('Обновить категории')}
            onClick={handleUpdateCategories}
            isLoading={updateCategoriesPending}
            disabled={updateCategoriesPending}
          >
            <RefreshIcon />
          </ButtonWithIconAndCircularProgress>
        )}
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <ResetFiltersButton disabled={isDisabledResetButton} pending={settingPending || isLoading} onReset={resetFilters} />
      </PageHeader>
      <PriceCreatorCategoriesTable />
      {isAdminAndManagerRole && (
        <ModalSelectPartner
          pending={updateCategoriesPending}
          handleSendForm={handleSendUpdateCategories}
          textSend={t('Обновить')}
          title={t('Обновить категории')}
          handleModalToggle={handleModalToggle}
          isOpened={modalSelectPartnerIsOpened}
        />
      )}
      {isAdminAndManagerRole && modalSelectPriceCreatorIsOpened && (
        <ModalSelectPartner
          pending={isLoadingDataXmlLoading}
          handleSendForm={handleSendUpdatePartnerCategories}
          textSend={t('Обновить')}
          title={t('Обновить данные в XML')}
          handleModalToggle={handleModalPartnerToggle}
          isOpened={modalSelectPriceCreatorIsOpened}
        />
      )}
    </>
  );
}

export default PriceCreatorCategoriesPage;
