// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/synonyms/actions';
// Hooks
import { useSynonymsList } from '../_hooks/useSynonymsList';
import { asyncActions } from '../../../../../engine/core/synonyms/saga/asyncActions';
import EditComponent from '../../../../components/shared/EditComponent';
import { pageLinks } from '../../../../../engine/config/routes';
import TableFilterRowWithSelectedCounter from '../../../../components/_Table/DxTable/components/TableFilterRowWithSelectedCounter/TableFilterRowWithSelectedCounter';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';


function SynonymsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.synonyms.isLoading);
  // Rows
  const rows = useSelector(selectors.synonyms.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.synonyms.currentPage);
  const pageSize = useSelector(selectors.synonyms.pageSize);
  const totalCount = useSelector(selectors.synonyms.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.synonyms.sorting);
  // Settings
  const settingStatus = useSelector(selectors.synonyms.settingStatus);
  const settingPending = useSelector(selectors.synonyms.settingPending);
  // Filtering
  const filters = useSelector(selectors.synonyms.filters);
  const selections = useSelector(selectors.synonyms.selection);
  const editLink = pageLinks.synonyms.edit;

  const onSelection = selected => dispatch(actions.setSelection(selected));

  const hasAccessToEdit = useAccessList(accessList.synonymsAdd);

  const disableSorting = [
    { columnName: 'synonyms', sortingEnabled: false },
    { columnName: 'dictionaryType', sortingEnabled: false },
  ];

  return (
    <DxTable
      name="synonyms"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows.toJS()}
      updateHooks={useSynonymsList}
      rowFilterComponent={props => <TableFilterRowWithSelectedCounter selectedCounter={selections.size} {...props} />}
      editComponent={props => <EditComponent {...props} hasAccessEdit={hasAccessToEdit} editLink={editLink} hasAccessShow={!hasAccessToEdit} showLink={editLink} />}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting.toJS()}
      // Filters
      filters={filters.toJS()}
      filtersAction={actions.setFilters}
      sortingStateColumnExtensions={disableSorting}
      // Settings
      gridSettings={gridSettings.dictionary_value.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
      selection={selections}
      onSelection={onSelection}
    />
  );
}

export default memo(SynonymsTable);
