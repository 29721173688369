export const types = Object.freeze({
  SET_BRANDS_LIST: 'SET_BRANDS_LIST',
  MERGE_BRANDS_LIST_CONTEXT: 'MERGE_BRANDS_LIST_CONTEXT',
  SET_BRANDS_FILTERS: 'SET_BRANDS_FILTERS',
  SET_BRANDS_CURRENT_PAGE: 'SET_BRANDS_CURRENT_PAGE',
  SET_BRANDS_PAGE_SIZE: 'SET_BRANDS_PAGE_SIZE',
  SET_BRANDS_SORTING: 'SET_BRANDS_SORTING',
  MERGE_BRANDS_LIST: 'MERGE_BRANDS_LIST',
  SET_BRANDS_LIST_CONTEXT: 'SET_BRANDS_LIST_CONTEXT',
  SET_BRANDS_INITIAL_STATE: 'SET_BRANDS_INITIAL_STATE',
  SET_BRANDS_SETTING_STATUS: 'SET_BRANDS_SETTING_STATUS',
  SET_BRANDS_SETTING_PENDING: 'SET_BRANDS_SETTING_PENDING',
});
