// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
// Parts
import TextField from '@mui/material/TextField';
import TableHeadFilterCell from './TableHeadFilterCell/TableHeadFilterCell';
// Helpers
import { useDefferCall } from '../../../../hooks/useDefferCall';

const TextMaskCustom = memo((props) => {
  const mask = ['+', /\d/, /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  return (
    <MaskedInput
      {...props}
      mask={mask}
      placeholderChar="_"
    />
  );
});

const TextFieldTableFilter = (props) => {
  const {
    disabledIcon, setFilterValue, filterValue,
    resetFilter, type, regularExpr, disabledPlaceholder, ...rest
  } = props;
  const { t } = useTranslation();
  const { value, setValue } = useDefferCall({
    callback: setFilterValue,
    initialValue: filterValue,
  });

  const onChange = (ev) => {
    const currentValue = ev.target.value;
    if (regularExpr) {
      setValue(currentValue.replace(regularExpr, ''));
    } else {
      setValue(currentValue);
    }
  };

  const onChangeTel = (ev) => {
    const currentValue = ev.target.value;
    setValue(currentValue.replace(/\D/g, ''));
  };

  return (
    <TableHeadFilterCell disabled={disabledIcon} resetFilter={resetFilter}>
      {type === 'tel' ? (
        <TextField
          {...rest}
          type={type}
          fullWidth
          onChange={onChangeTel}
          placeholder={disabledPlaceholder ? '' : t('Фильтр')}
          value={value}
          InputProps={{
            inputComponent: TextMaskCustom,
          }}
        />
      ) : (
        <TextField
          {...rest}
          type={type}
          fullWidth
          placeholder={disabledPlaceholder ? '' : t('Фильтр')}
          onChange={onChange}
          value={value}
        />
      )}
    </TableHeadFilterCell>
  );
};

export default memo(TextFieldTableFilter);
