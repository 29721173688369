// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import localforage from 'localforage';
// UI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
// Containers
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useChat } from '../../../hooks/useChat';

function OverlayNeedRefreshPage() {
  useChat();
  const shouldRenderRefresh = useSelector(selectors.ui.shouldRenderRefresh);
  const { t } = useTranslation();
  const { showOverlay, title, clearLocalForage } = shouldRenderRefresh.toJS();

  const refreshPage = () => {
    if (clearLocalForage) {
      localforage.clear();
    }
    window.location.replace('/');
  };

  if (!showOverlay) {
    return null;
  }

  return (
    <Grid
      alignItems="center"
      sx={{
        backgroundColor: 'rgba(97, 97, 97 ,0.5)',
        bottom: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
      }}
      container
      direction="row"
      justifyContent="center"
    >
      <Grid item xs={6}>
        <Paper sx={{
          padding: '15px',
          textAlign: 'center',
          width: '100%',
        }}
        >
          <h2>{t(title)}!</h2>
          <p>{t('Пожалуйста, перезагрузите страницу')}</p>
          <ButtonWithIcon onClick={refreshPage} text={t('Обновить')}>
            <RefreshIcon />
          </ButtonWithIcon>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OverlayNeedRefreshPage;
