// Core
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
// UI
import Button from '@mui/material/Button';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useStyles } from '../../../../hooks/useStyles';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
import { userRoles } from '../../../../../engine/config/userRoles';

const ImportButtonStyles = () => ({
  navLink: {
    cursor: 'default !important',
  },
});

function ImportButton() {
  const { t } = useTranslation();
  const hasAccess = useAccessList(accessList.feedImport);
  const isPartnerRoleDisabled = useUserRole(userRoles.partnerDisabled);
  const classes = useStyles(ImportButtonStyles);

  if (!hasAccess && !isPartnerRoleDisabled) {
    return null;
  }

  const handleClick = (event) => {
    if (isPartnerRoleDisabled) {
      event.preventDefault();
    }
  };

  return (
    <NavLink
      className={cx({ [classes.navLink]: isPartnerRoleDisabled })}
      to={pageLinks.productsAndPrices.importPage}
      onClick={handleClick}
    >
      <Button
        variant="contained"
        color="primary"
        disabled={isPartnerRoleDisabled}
        sx={{ p: BUTTON_SIZE.small }}
        fullWidth
      >
        {t('Импортировать прайс-лист')}
      </Button>
    </NavLink>
  );
}

export default ImportButton;
