// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions as contentAsyncActions } from '../../../../../engine/core/content/saga/asyncActions';
// Parts
import Select from '../../../_Form/Selects/Select/Select';

function SourceSelectFilter(props) {
  const {
    column, getValue, wrapperClassName, handleChange, placeholder,
  } = props;
  const dispatch = useDispatch();
  const productSourceList = useSelector(selectors.content.productSourceList);
  const productSourceListPending = useSelector(selectors.content.productSourceListPending);
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);

  useEffect(() => {
    if (!currentFiltersNameList.includes(column.name)) {
      dispatch(contentAsyncActions.getProductsSourceListAsync());
    }
  }, [dispatch, column.name, currentFiltersNameList]);

  return (
    <Select
      isLoading={productSourceListPending}
      handleChange={handleChange}
      disabled={productSourceListPending}
      options={productSourceList}
      value={getValue()}
      filterValueBy="value"
      placeholder={placeholder}
      wrapperClassName={wrapperClassName}
    />
  );
}


SourceSelectFilter.propTypes = {
  getValue: PropTypes.func,
  wrapperClassName: PropTypes.string,
  column: PropTypes.object,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SourceSelectFilter;
