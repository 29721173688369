// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { asyncTypes } from './asyncTypes';

export const partnerAsyncAction = Object.freeze({
  getPartnersDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_DOWNLOAD_ASYNC, params);
  },
  getPartnerDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_DOWNLOAD_ASYNC, params);
  },
  getPartnerContractCommissionDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_CONTRACT_COMMISSION_DOWNLOAD_ASYNC, params);
  },
  getPartnerStatusesList(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_STATUSES_LIST, params);
  },
  getPartnersList(requestParams) {
    return getActionCreator(asyncTypes.GET_PARTNERS_LIST_ASYNC_OLD, requestParams);
  },
  putPartnersSettingsAsync(requestParams) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_SETTINGS_ASYNC, requestParams);
  },
  getPartnerInfo(data) {
    return getActionCreator(asyncTypes.GET_PARTNER_INFO_ASYNC, data);
  },
  putPartnerInfoAsync(config) {
    return getActionCreator(asyncTypes.PUT_PARTNER_INFO_ASYNC, config);
  },
  putPutSendContractToSed(hashId) {
    return getActionCreator(asyncTypes.PUT_PARTNER_SEND_CONTRACT_TO_SED, hashId);
  },
  uploadPartnerLogo(config) {
    return getActionCreator(asyncTypes.UPLOAD_PARTNER_LOGO_ASYNC, config);
  },
  uploadPartnerPaymentDocs(data) {
    return getActionCreator(asyncTypes.UPLOAD_PARTNER_PAYMENT_DOCS_ASYNC, data);
  },
  uploadPartnerContact(contractFile) {
    return getActionCreator(asyncTypes.UPLOAD_PARTNER_CONTRACT_ASYNC, contractFile);
  },
  createNewPartner(formData) {
    return getActionCreator(asyncTypes.CREATE_NEW_PARTNER_ASYNC, formData);
  },
  getPartnersInitialStateAsync(url) {
    return getActionCreator(asyncTypes.GET_PARTNERS_INITIAL_STATE_ASYNC, url);
  },
  getPartnerContextListAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_CONTEXT_LIST_ASYNC, params);
  },
  getPartnersForServiceRequestList(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_FOR_SERVICE_REQUESTS_LIST, params);
  },
  getUsersContextListAsync(params) {
    return getActionCreator(asyncTypes.GET_USERS_CONTEXT_LIST_ASYNC, params);
  },
  getBusinessesListAsync(params) {
    return getActionCreator(asyncTypes.GET_BUSINESSES_LIST_ASYNC, params);
  },
  postPartnerBusinessAsync(params) {
    return getActionCreator(asyncTypes.SET_PARTNER_BUSINESS_ASYNC, params);
  },
});
