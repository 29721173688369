// Core
import red from '@mui/material/colors/red';

const FormHelperTextComponentStyles = () => ({
  helperText: {
    display: 'none',
  },
  error: {
    display: 'block',
    color: red[500],
  },
});

export default FormHelperTextComponentStyles;
