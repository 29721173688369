// Core
import React, { useCallback, useMemo } from 'react';
import { array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// Parts
import Paper from '@mui/material/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import TableCell from '../../../components/_Table/TableCell/TableCell';
// Helpers
import { locale } from '../../../../engine/init/i18n';

function OrdersItemsTable(props) {
  const {
    rows, columnList, columnPosition, onOrderChange, columnWidths,
  } = props;

  const { i18n } = useTranslation();
  const language = i18n.language;

  const addPositionToStart = useMemo(() => {
    const filteredColumnPosition = columnPosition.filter(e => e !== 'position');
    filteredColumnPosition.unshift('position');
    return filteredColumnPosition;
  }, [columnPosition]);

  const CellComponent = useCallback(cellComponentProps => <TableCell {...cellComponentProps} showShortText />, []);

  const currentColumnsOrderLocale = useMemo(() => {
    const lang = locale[language];
    const path = `lang.${lang}`;
    return columnList.map(item => ({
      ...item,
      title: get(item, path, item.title),
    }));
  }, [columnList, language]);

  return (
    <Paper>
      <Grid rows={rows} columns={currentColumnsOrderLocale}>
        <Table
          columnExtensions={columnWidths}
          cellComponent={CellComponent}
        />
        {onOrderChange && (
          <TableColumnReordering
            order={addPositionToStart}
            onOrderChange={onOrderChange}
          />
        )}
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
}

OrdersItemsTable.propTypes = {
  columnList: array,
  columnPosition: array,
  columnWidths: array,
  rows: array,
  onOrderChange: func,
};

OrdersItemsTable.defaultProps = {
  columnList: [],
  columnPosition: [],
  rows: [],
};

export default OrdersItemsTable;
