// Core
import { apply, delay, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api } from '../../../../config/api';
import { pageLinks } from '../../../../config/routes';
import { userActions } from '../../action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPostUsersWorker({ payload }) {
  const { redirect, ...restPayload } = payload;
  yield put(userActions.mergeUserAdd({ pending: true }));
  const response = yield apply(api, api.user.postUsers, [restPayload]);
  if (response && response.status >= 200 && response.status < 300) {
    if (response.data) {
      const { status, statusTitle } = response.data;

      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          if (redirect) {
            yield delay(0);
            yield put(push(pageLinks.settings.users.all));
          }
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle, true));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(userActions.mergeUserAdd({ pending: false }));
}
