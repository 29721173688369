// Core
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form/immutable';
// Parts
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { isImmutable } from 'immutable';
import SelectTree from '../SelectCategoryTreeView';
// Helpers
import { SelectStyle } from '../../Select/styles';

const getKey = (value) => {
  const checkIsArr = (Array.isArray(value) && value?.length > 1) ? value.join('-') : value?.[0]?.value;
  const getValue = Array.isArray(value)
    ? checkIsArr
    : value;
  return `key_${getValue}`;
};


function SelectCategoryTreeView(props) {
  const {
    formName, meta, labelClassName, disabled, onlyChild,
    isMulti, marginBottom, label, categoriesList, filters, onSelectChange, required,
    placeholder, variant, margin, isTreeViewForm, hasConfirmButton, canClear, closeMenuOnScroll,
    onlyNotPortal, isPortal, ...rest
  } = props;

  const classes = SelectStyle(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error, touched } = meta;
  const hasError = touched && typeof error !== 'undefined';
  const errorClassNames = cx(classes.wrapperCategoryTreeForm, classes.errorColor);
  const inputName = rest?.input?.name;

  const filtersToJS = useMemo(() => (filters?.value && isImmutable(filters?.value)
    ? filters?.value?.toJS()
    : filters?.value) || [],
  [filters]);

  const setFieldTouched = useCallback(() => {
    if (!disabled) {
      if (formName && !touched) {
        dispatch(touch(formName, inputName));
      }
    }
  }, [disabled, formName, inputName, touched]);

  const wrapperClassNames = cx(
    classes.formControl,
    {
      [classes.borderBottom]: variant !== 'outlined',
    },
  );

  return (
    <FormControl
      className={wrapperClassNames}
      onClick={setFieldTouched}
      error={hasError}
      disabled={disabled}
      margin={margin}
    >
      <SelectTree
        isPortal={isPortal}
        key={getKey(filtersToJS)}
        variant={variant}
        onlyChild={onlyChild}
        placeholder={placeholder}
        disabled={disabled}
        options={categoriesList}
        hasConfirmButton={hasConfirmButton}
        required={required}
        filters={filters}
        isMulti={isMulti}
        onSelectChange={onSelectChange}
        hasRejectButton
        canClear={canClear}
        searchErrorLabel={t('Неверно введена категория')}
        meta={meta}
        closeMenuOnScroll={closeMenuOnScroll}
        onlyNotPortal={onlyNotPortal}
        label={label ?? t('Категории')}
      />
      {hasError && !isTreeViewForm && (
        <div className={errorClassNames}>
          <FormHelperText>{error}</FormHelperText>
        </div>
      )}
    </FormControl>
  );
}

SelectCategoryTreeView.propTypes = {
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  onlyChild: PropTypes.bool,
  marginBottom: PropTypes.bool,
  disabled: PropTypes.bool,
  isPortal: PropTypes.bool,
  formName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  meta: PropTypes.object,
  touchField: PropTypes.func,
  t: PropTypes.func,
  categoriesList: PropTypes.array,
  filters: PropTypes.object,
  onSelectChange: PropTypes.func,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  isTreeViewForm: PropTypes.bool,
  canClear: PropTypes.bool,
  onlyNotPortal: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  hasConfirmButton: PropTypes.bool,
};

SelectCategoryTreeView.defaultProps = {
  categoriesList: [],
  disabled: false,
  isMulti: true,
  marginBottom: true,
  onlyChild: false,
  formName: '',
  labelClassName: '',
  placeholder: '',
  meta: {},
};

export default SelectCategoryTreeView;
