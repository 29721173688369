// Core
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, reduxForm } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
// Helpers
import { validators } from '../../../../../../_helpers/validationsForm';
// Components
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import FilesUploader from '../../../../../components/_Form/FilesUploader/FilesUploader';
import FormHelperTextComponent from '../../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';
import CategoriesSelect from '../../../../../components/_Form/Selects/CategoriesSelect';
// Engine
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../../../engine/core/_partners/saga/asyncAction';
import { asyncActions } from '../../../../../../engine/core/contentTemplates/saga/asyncActions';
import { userRoles } from '../../../../../../engine/config/userRoles';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
import { useUserSomeRole } from '../../../../../hooks/useUserSomeRole';
import { actions } from '../../../../../../engine/core/contentTemplates/actions';
// Style
import { TitleWithIcon } from '../../../styles';
import { CustomCard, CustomCardContent, CardNumber } from './styles';
import { COLOR, BUTTON_SIZE } from '../../../../../containers/App/AppStyles';
import { useStyles } from '../../../../../hooks/useStyles';

export const formName = 'addFormXlsx';
export const formFields = Object.freeze({
  partner: 'partner',
  file: 'productImportFile',
  category: 'category',
});

const AddFormXlsxStyles = () => ({
  categorySelectHolder: {
    paddingTop: '5px',
  },
  partnerSelectHolder: {
    paddingTop: '5px',
  },
});


function AddFormXlsx(props) {
  const {
    handleSubmit, invalid, pristine, submitting,
  } = props;
  const classes = useStyles(AddFormXlsxStyles);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessPartnerContextList = useAccessList(accessList.partnerContextList);
  const productsImportPending = useSelector(selectors.contentTemplatesImport.productsImportPending);
  const partnerContextListPending = useSelector(selectors.partner.listContextLoading);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnerContextListAsync());
    return () => {
      dispatch(actions.setContentTemplateImportModalInitialState());
    };
  }, [dispatch]);

  const handleSendFile = (values) => {
    const { partner, category, ...formValues } = values.toJS();
    dispatch(asyncActions.postProductsImportAsync({
      formName,
      partnerId: partner?.value,
      categoryId: category?.value,
      ...formValues,
    }));
  };

  return (
    <CustomCard>
      <CustomCardContent>
        <TitleWithIcon gutterBottom variant="h6" component="div" sx={{ marginBottom: '10px' }}>
          <CardNumber component="span">2</CardNumber>
          {t('Добавьте заполненный шаблон')}
        </TitleWithIcon>
        <Box sx={{ marginTop: '10px' }}>
          <Typography component="p" sx={{ color: COLOR.grey['72'], marginBottom: '25px', fontSize: '12px' }}>
            {t('Перетащите или добавьте один заполненный шаблон.')}
            <br />
            {t('Далее выберите категорию, в которую вы хотите импортировать товары.')}
          </Typography>
          <Typography component="b" sx={{ fontWeight: 'bold', fontSize: '14px', color: COLOR.black.default }}>{t('Требования для заполненного шаблона')}</Typography>
          <ul style={{
            fontSize: '14px', marginTop: '0', paddingLeft: '17px', lineHeight: '24px', color: COLOR.black.default,
          }}
          >
            <li>
              {t('Заполненные данные должны отвечать шаблону')}
            </li>
            <li>{t('Объем файла не более 50 Мб')}</li>
          </ul>
          <Form onSubmit={handleSubmit(handleSendFile)}>
            {hasAccessPartnerContextList && !isPartnerRoles && (
              <Box sx={{ maxWidth: '320px' }}>
                <Field
                  required
                  selectFirst
                  margin="normal"
                  component={PartnerSelect}
                  name={formFields.partner}
                  label={t('Выберите партнера')}
                  validate={[validators.required]}
                  validation
                  id="partner_select_import_xlsx"
                  wrapperClassName={classes.partnerSelectHolder}
                />
              </Box>
            )}
            <Box sx={{ maxWidth: '320px' }}>
              <Field
                fullWidth
                required
                component={CategoriesSelect}
                menuPlacement="top"
                name={formFields.category}
                label={t('Категория')}
                margin="normal"
                validate={[validators.required]}
                validation
                id="choose_category_select"
                wrapperClassName={classes.categorySelectHolder}
              />
            </Box>
            <Field
              allowFileTypeValidation
              acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
              component={RenderFilesUploader}
              labelFileTypeNotAllowed={t('Тип файла не валидный')}
              textBefore2={t('Файл в формате XLSX')}
              name={formFields.file}
              validate={[validators.itemsRequiredValidator]}
            />
            <LoadingButton
              type="submit"
              sx={{ marginTop: '10px', p: BUTTON_SIZE.large }}
              variant="contained"
              color="primary"
              id="import_button_send_xlsx"
              disabled={Boolean(
                invalid || pristine
                || submitting || productsImportPending
                || partnerContextListPending,
              )}
              loading={Boolean(productsImportPending)}
            >
              {t('Импортировать')}
            </LoadingButton>
          </Form>
        </Box>
      </CustomCardContent>
    </CustomCard>
  );
}

export function RenderFilesUploader(fieldRenderProps) {
  const { meta: { touched, error }, ...rest } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} loadFileFromSaga />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

export default reduxForm({
  form: formName,
})(AddFormXlsx);
