// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';
// Partial
import { actions } from '../../actions';
import { partnerAsyncAction } from '../../../_partners/saga/asyncAction';

export function* callDeleteDeliveryDelayPeriodWorker({ payload: { hashId } }) {
  yield put(actions.setPartnersDeliveryDelayPeriodDelete({ pending: true, showModal: false }));
  const response = yield apply(api, api.partner.deletePartnerDeliveryDelayPeriod, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;
    switch (status) {
      case 'success': {
        yield put(partnerAsyncAction.getPartnerInfo({ hashId, disableLoader: true, disableInitForm: true }));
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setPartnersDeliveryDelayPeriodDeletePending(false));
}
