import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as productsGroupsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const productGroups = Object.freeze({
  currentPage: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.currentPage], 0),
  filters: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.filters], List()),
  isLoading: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.pending], false),
  list: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.items], List()),
  pageSize: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.reload], false),
  totalCount: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.totalCount], 0),
  sorting: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.sorting], List()),
  settingStatus: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.settingStatus], false),
  settingPending: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.settingPending], false),
  selection: state => getIn(state, [productsGroupsKeys.controller, productsGroupsKeys.productGroupsList, productsGroupsKeys.selection], List()),
});
