// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import PriceCreatorFilesTable from './components/PriceCreatorFilesTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';

// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';

// Hooks
import { usePriceCreatorFilesList } from './_hooks/usePriceCreatorFilesList';

// Engine
import { asyncActions } from '../../../../engine/core/priceCreator/priceCreatorFiles/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/priceCreator/priceCreatorFiles/actions';
import { initialState, stateKeys } from '../../../../engine/core/priceCreator/priceCreatorFiles/reducer';
import UpdateDataXmlButton from '../components/UpdateDataXmlButton';
import ModalSelectPartner from '../components/ModalSelectPartner';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../engine/config/userRoles';

function PriceCreatorFilesPage() {
  const { t } = useTranslation();
  usePriceCreatorFilesList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.priceCreatorFiles.settingStatus);
  const settingPending = useSelector(selectors.priceCreatorFiles.settingPending);
  const isLoading = useSelector(selectors.priceCreatorFiles.isLoading);
  const filters = useSelector(selectors.priceCreatorFiles.filters);
  const sorting = useSelector(selectors.priceCreatorFiles.sorting);
  const isAdminAndManagerRole = useUserSomeRole([userRoles.admin, userRoles.manager]);
  const modalSelectPartnerIsOpened = useSelector(selectors.priceCreatorFiles.modalActive);
  const isLoadingDataXmlLoading = useSelector(selectors.priceCreatorFiles.isDownloadUpdateDataXmlLoading);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);

  const onResetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.priceCreatorFilesList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.priceCreatorFilesList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.priceCreatorFilesList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  const handleSendUpdatePartnerCategories = (partner) => {
    dispatch(asyncActions.downloadDataXmlAsync(partner));
  };

  const handleModalPartnerToggle = () => {
    dispatch(actions.setDataXmlDownloadModalActive(!modalSelectPartnerIsOpened));
  };

  return (
    <>
      <PageHeader title={t('XML файлы')}>
        <UpdateDataXmlButton />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <ResetFiltersButton disabled={isDisabledResetButton} pending={settingPending || isLoading} onReset={onResetFilters} />
      </PageHeader>
      <PriceCreatorFilesTable />
      {isAdminAndManagerRole && modalSelectPartnerIsOpened && (
        <ModalSelectPartner
          pending={isLoadingDataXmlLoading}
          handleSendForm={handleSendUpdatePartnerCategories}
          textSend={t('Обновить')}
          title={t('Обновить данные в XML')}
          handleModalToggle={handleModalPartnerToggle}
          isOpened={modalSelectPartnerIsOpened}
        />
      )}
    </>
  );
}
//
export default PriceCreatorFilesPage;
