// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  partner: Object.freeze({
    name: 'transactionsSum',
    title: <Trans>Расход/приход денежных средств</Trans>,
  }),
  balance: Object.freeze({
    name: 'ordersQuantity',
    title: <Trans>Количество полученных заказов</Trans>,
  }),
  holdBalance: Object.freeze({
    name: 'ordersDoneQuantity',
    title: <Trans>Количество реализованных заказов</Trans>,
  }),
  summaryBalance: Object.freeze({
    name: 'ordersCancelQuantity',
    title: <Trans>Количество отменненных заказов</Trans>,
  }),
});
