const Styles = () => ({
  cellWithIcon: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  descriptionLabel: {
    flexGrow: 1,
    width: 'calc(100% - 20px)',
    display: 'inline-flex',
    maxWidth: '100%',
    minWidth: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& .TableTreeExpandButton-hidden': {
      display: 'none',
    },
  },
});

export default Styles;
