import { COLOR } from '../../../../containers/App/AppStyles';

const HelpSectionStyles = () => ({
  helpMenuButton: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR.darkStyle.fontColorMain,
    padding: '3px 6px 5px 10px',
    flexShrink: 0,
    marginRight: '10px',
    minWidth: '89px',
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  helpMenuButtonActive: {
    background: COLOR.darkStyle.white['8'],
  },
  helpMenu: {
    zIndex: 302,
    '& .MuiPaper-root': {
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
      background: COLOR.darkStyle.main,
      zIndex: 1300,
      minWidth: '89px',
    },
    '& .MuiList-root': {
      padding: '4px 2px',
    },
    '& .MuiMenuItem-root': {
      minHeight: '40px',
      borderRadius: '5px',
      padding: '5px 13px',
      fontSize: '14px',
      lineHeight: 1,
      color: COLOR.darkStyle.fontColorMain,
      '&:hover': {
        background: COLOR.darkStyle.white['8'],
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '5px',
      },
      '& .MuiTypography-root': {
        fontSize: '14px',
        lineHeight: 1,
        color: COLOR.darkStyle.fontColorMain,
      },
      '& .MuiSvgIcon-root': {
        color: COLOR.darkStyle.fontColorMain,
      },
    },
  },
  helpMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '8px',
  },
  helpMenuIconInfo: {
    width: '24px',
    height: '24px',
    marginBottom: '4px',
  },
  helpMenuTitle: {
    fontSize: '10px',
    lineHeight: 1,
    fontWeight: 700,
  },
  helpMenuDropIcon: {
    width: '16px',
    height: '16px',
  },
  helpMenuDropIconActive: {
    transform: 'rotate(180deg)',
  },
});

export default HelpSectionStyles;
