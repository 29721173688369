// Core
import {
  memo, useCallback, useEffect,
  useMemo, useRef, useState,
} from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isImmutable, List } from 'immutable';
import ReactSelect, { createFilter } from 'react-select';
import { isMobile } from 'react-device-detect';
// Parts
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import LongMenuList from './components/LongMenuList';
import MultiValue from './components/MultiValue';
import ValueContainerSingle from './components/ValueContainerSingle';
import IndicatorsContainer from './components/IndicatorsContainer/IndicatorsContainer';
import Menu from './components/Menu';
import NoOptionsMessage from './components/NoOptionMessage';
import Placeholder from './components/Placeholder';
import Option from './components/Option';
import Control from './components/Control';
import MenuList from './components/MenuList';
import DropdownIndicator from './components/DropdownIndicator';
import ClearIndicator from './components/ClearIndicator';
// Helpers
import { SelectStyle, styles } from './styles';
import { useInput } from '../../../../hooks/useInput';
import getNormalizedArray from './_helpers/getNormalizedArray';

function getNextElementSiblings(element, count) {
  for (let i = 0; i < count; i++) {
    // eslint-disable-next-line no-param-reassign
    element = element?.nextElementSibling;
    if (!element) {
      break;
    }
  }
  return element;
}

function Select(props) {
  const {
    options, isMulti, input, handleChange, value, sendOnRemove, onMenuClose: handleOnMenuClose, onMenuOpen: customOnMenuOpen,
    hideSelectedOptions, hasConfirmButton, placeholder, filterValueBy, filters, searchErrorLabel,
    checkboxesState, onChangeCheckbox, indeterminateState, defaultValue,
    ValueView, MenuListView, onConfirm, onReject, minMenuWidth,
    disabled, isTreeSelect, wrapperClassName, variant, label, margin,
    hasSearch: forcedHasSearch, color, id, isLoading, meta, isClearable, closeMenuOnSelect,
    selectFirst, isRecommended, isChanged, required, fullWidth, menuPlacement, hasHelperTextBefore,
    onlyChild, canClear, onlyNotPortal, inputId, focusOn, isSelectOpen, isSearchable, isCategorySelect,
    CustomOption, optionsLanguage, customOnBlur, dataTestId, searchDataTestId, forcedNoSearch,
  } = props;
  const { error, touched } = meta;
  const { t } = useTranslation();
  const classes = SelectStyle(props);
  const selectRef = useRef();
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  // input value for isSearchable components
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (newValue, { action }) => {
    if (newValue === '' && action === 'input-change') {
      input.onChange(null);
    }
    setInputValue(newValue);
  };
  const { onChange: onChangeSearch, value: searchString } = useInput(undefined);
  const hasError = touched && typeof error !== 'undefined';
  const isClassic = variant === 'outlined';
  const isDarkStyle = color === 'dark';
  const optionsToJS = useMemo(
    () => options && isImmutable(options)
      ? options.toJS()
      : options || [],
    [options],
  );
  const isLongList = optionsToJS?.length > 50;
  const hasSearch = isSearchable ? false : (forcedHasSearch || optionsToJS.length > 50);
  const defaultExpandedIds = useMemo(
    () => {
      const filterValue = filters?.value;
      if (filterValue) {
        return filterValue.map(i => i?.value?.toString());
      }
      return [];
    },
    [filters],
  );
  const dataListMap = useMemo(
    () => {
      const catListMap = new Map();
      optionsToJS.forEach((item) => {
        catListMap.set(item.value, item);
      });
      return catListMap;
    },
    [optionsToJS],
  );
  const filteredData = useMemo(
    () => {
      const normalizeSearch = searchString?.trimStart()?.toLowerCase();
      const isGrouped = optionsToJS[0]
        ? Object.prototype.hasOwnProperty.call(optionsToJS[0], 'options')
        : false;
      if (isGrouped) {
        return optionsToJS.map(item => ({
          ...item,
          options: item.options.filter(
            ({ label: optionLabel }) => optionLabel?.toLowerCase()?.includes(normalizeSearch),
          ),
        }));
      }
      const resultFilter = optionsToJS.filter(
        ({ label: optionLabel }) => optionLabel?.toLowerCase()?.includes(normalizeSearch),
      );
      return isTreeSelect
        ? getNormalizedArray(resultFilter, dataListMap)
        : resultFilter;
    },
    [optionsToJS, searchString, dataListMap],
  );
  const currentOptions = hasSearch && filteredData ? filteredData : optionsToJS;
  const noOptionsMessage = () => t('Нет вариантов');
  const shouldRenderFilteredLoop = searchString.trim().length > 3;
  const expandedIds = useMemo(() => shouldRenderFilteredLoop && isTreeSelect
    ? filteredData.map(data => data.value.toString())
    : defaultExpandedIds,
  [shouldRenderFilteredLoop, filteredData, defaultExpandedIds, isTreeSelect]);
  const filterOption = createFilter({
    matchFrom: 'any',
    ignoreAccents: false,
    stringify: option => `${option.label}`,
  });
  const textFieldProps = {
    variant,
    label,
    isClassic,
    color,
    hasError,
    isRecommended,
    isChanged,
    required,
    hasHelperTextBefore,
    isCategorySelect,
  };
  const onChange = (newValue) => {
    if (newValue === undefined) {
      selectRef?.current?.clearValue();
    }
    if (handleChange) {
      handleChange(newValue);
    }
    if (input && input.onChange) {
      input?.onChange(newValue);
    }
    if (closeMenuOnSelect || (closeMenuOnSelect === undefined && !hasConfirmButton)) {
      setIsFocused(false);
    }
  };
  const normalizedValue = useMemo(
    () => {
      const checkImmutable = isImmutable(input?.value)
        ? input?.value?.toJS()
        : input?.value;
      const currentValue = value === undefined
        ? checkImmutable
        : value;

      if (filterValueBy && !isMulti) {
        return optionsToJS.filter(item => item[filterValueBy] === currentValue);
      }
      return currentValue;
    },
    [value, input?.value, filterValueBy, optionsToJS],
  );
  const customSelectComponents = useMemo(() => ({
    NoOptionsMessage,
    Placeholder,
    MenuList: MenuListView || (isLongList
      ? LongMenuList
      : MenuList
    ),
    Option: CustomOption ?? Option,
    Control,
    Menu,
    MultiValue,
    IndicatorsContainer,
    ClearIndicator,
    ValueContainer: ValueView ?? ValueContainerSingle,
    DropdownIndicator,
  }), [MenuListView, isLongList, ValueView]);

  const onChangeInputSearch = useCallback((e) => {
    if (isSelectOpen) {
      return;
    }
    e.stopPropagation();
    onChangeSearch(e);
    setSearchInputValue(e?.currentTarget?.value);
  }, [setSearchInputValue, isSelectOpen]);
  const onClearSearch = useCallback(() => {
    onChangeSearch();
    setSearchInputValue('');
  }, []);
  const handleOpenSelect = (e) => {
    const { state } = selectRef.current;
    const isLabel = e.target.classList.contains('MuiInputLabel-formControl');
    const isTargetId = inputId === undefined || e.target.getAttribute('id') !== inputId;
    if (isFocused && !e.target.closest('.select-tags__menu')) {
      setIsFocused(false);
      onClearSearch();
      if (hasConfirmButton) {
        onChange(initialState);
      }
    } else if (!isFocused && !disabled && isTargetId && !isLabel) {
      if (!isSelectOpen) {
        setIsFocused(true);
      }
      if (focusOn) {
        setTimeout(() => {
          const { focusedOptionRef } = selectRef.current;
          const firstSelected = state?.selectValue?.[0];
          const focusOnIndex = options.findIndex(item => item.value === focusOn);
          const next = getNextElementSiblings(focusedOptionRef, focusOnIndex);
          if (next) {
            next.scrollIntoView({ behavior: 'smooth' });
          } else if (focusedOptionRef && firstSelected) {
            state.focusedOption = firstSelected;
            focusedOptionRef.scrollIntoView({ behavior: 'auto' });
          }
        }, 100);
      }
    }
  };
  const onMenuClose = () => {
    if (handleOnMenuClose) {
      handleOnMenuClose(isFocused);
    }
    if (isSearchable) {
      selectRef?.current?.blur();
    }
  };
  const onDomClick = (e) => {
    if (containerRef && !containerRef.current?.contains(e.target)) {
      setIsFocused((prevStateIsFocused) => {
        if (prevStateIsFocused === true && hasConfirmButton) {
          setInitialState((prevInitial) => {
            onChange(prevInitial);
          });
        }
        return false;
      });
      onClearSearch();
    }
  };

  useEffect(() => {
    if (selectFirst && optionsToJS.length === 1) {
      onChange(optionsToJS[0]);
    }
  }, [selectFirst, optionsToJS]);

  useEffect(() => {
    if (isFocused) {
      setInitialState(input?.value);
    }
  }, [isFocused]);

  const handleScroll = useCallback((e) => {
    const parentWithPartialClass = e.target.closest && (e.target.closest('.select-tags__menu') || e.target.closest('.select-tags__input-container'));
    if (!parentWithPartialClass && e.target !== document) {
      setIsFocused(false);
      selectRef?.current?.blur();
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      const focusedValue = optionsToJS.filter(item => item.value === focusOn);
      onChange(focusedValue[0]);
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();
      setIsFocused(false);
    }
  };

  const onKeyDownWithoutFocus = (e) => {
    if (isSelectOpen) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (isCategorySelect && e.key === 'Escape') {
      setIsFocused(false);
      return;
    }
    if (isCategorySelect && e.key !== 'Tab') {
      setIsFocused(true);
    }
  };

  useEffect(() => {
    if (isSelectOpen) {
      selectRef?.current?.focus();
    }
  }, [isSelectOpen]);

  const onMenuOpen = () => {
    if (normalizedValue && isSearchable) {
      setInputValue(normalizedValue.label);
      const inputElement = selectRef.current.inputRef;
      if (inputElement) {
        inputElement.focus();
        setTimeout(() => {
          inputElement.setSelectionRange(normalizedValue.label.length, normalizedValue.label.length);
        }, 0);
      }
    }
    if (customOnMenuOpen) {
      customOnMenuOpen();
    }
  };


  return (
    <ClickAwayListener onClickAway={onDomClick}>
      <FormControl
        id={id}
        fullWidth={fullWidth}
        margin={margin}
        className={cx(`${classes.wrapperSelect} select-tags-wrapper`, {
          [classes.wrapperSelectClassic]: isClassic,
          [classes.wrapperSelectError]: hasError,
          [classes.changedFormControl]: isChanged,
          [classes.wrapperSelectDark]: isDarkStyle,
          [wrapperClassName]: wrapperClassName,
        })}
        ref={containerRef}
        onClick={isMobile ? undefined : handleOpenSelect}
        onTouchStart={isMobile ? handleOpenSelect : undefined}
      >
        <ReactSelect
          onKeyDown={focusOn ? onKeyDown : onKeyDownWithoutFocus}
          closeMenuOnScroll={handleScroll}
          autoFocus={false}
          inputId={inputId}
          backspaceRemovesValue={false}
          blurInputOnSelect={false}
          isTreeSelect={isTreeSelect}
          isClearable={isClearable}
          isLoading={isLoading}
          isDisabled={disabled}
          placeholder={isFocused ? '' : placeholder ?? t('Выбрать')}
          styles={styles}
          classes={classes}
          ref={selectRef}
          noOptionsMessage={noOptionsMessage}
          options={currentOptions}
          isMulti={isMulti}
          filterOption={filterOption}
          value={normalizedValue}
          defaultValue={defaultValue}
          components={customSelectComponents}
          menuPortalTarget={document.body}
          onlyChild={onlyChild}
          onChange={onChange}
          onReject={onReject}
          onBlur={customOnBlur}
          menuPlacement={menuPlacement}
          menuShouldBlockScroll={false}
          closeMenuOnSelect={closeMenuOnSelect === undefined ? !hasConfirmButton : closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          classNamePrefix="select-tags"
          sendOnRemove={sendOnRemove}
          onConfirm={onConfirm}
          checkboxesState={checkboxesState}
          onChangeCheckbox={onChangeCheckbox}
          hasConfirmButton={hasConfirmButton}
          indeterminateState={indeterminateState}
          defaultExpandedIds={expandedIds}
          onMenuClose={onMenuClose}
          onMenuOpen={onMenuOpen}
          onChangeSearch={onChangeSearch}
          searchString={searchString}
          searchErrorLabel={searchErrorLabel}
          onClearSearch={onClearSearch}
          setIsFocused={setIsFocused}
          searchInputValue={searchInputValue}
          hasSearch={hasSearch && !forcedNoSearch}
          isSearchable={isSearchable ?? false}
          minMenuWidth={minMenuWidth}
          inputValue={inputValue}
          minMenuHeight="300"
          menuIsOpen={isFocused || isSelectOpen}
          isFocused={isFocused || undefined}
          onChangeInputSearch={onChangeInputSearch}
          onInputChange={handleInputChange}
          textFieldProps={textFieldProps}
          canClear={canClear}
          onlyNotPortal={onlyNotPortal}
          focusOn={focusOn}
          optionsLanguage={optionsLanguage}
          dataTestId={dataTestId}
          searchDataTestId={searchDataTestId}
        />
        {hasError && (
          <FormHelperText
            error
            sx={{
              marginLeft: '14px',
              marginTop: '2px',
              height: '16px',
              lineHeight: '130%',
            }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </ClickAwayListener>
  );
}

Select.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.array,
  ]).isRequired,
  isClearable: PropTypes.bool,
  hasHelperTextBefore: PropTypes.bool,
  isMulti: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.object]),
  sendOnRemove: PropTypes.bool,
  onMenuClose: PropTypes.func,
  canClear: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  hasConfirmButton: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.object]),
  filterValueBy: PropTypes.string,
  filters: PropTypes.object,
  searchErrorLabel: PropTypes.string,
  checkboxesState: PropTypes.array,
  onChangeCheckbox: PropTypes.func,
  indeterminateState: PropTypes.array,
  ValueView: PropTypes.func,
  MenuListView: PropTypes.object,
  CustomOption: PropTypes.object,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  color: PropTypes.oneOf(['secondary', 'dark']),
  menuPlacement: PropTypes.oneOf(['top', 'auto']),
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isTreeSelect: PropTypes.bool,
  hasSearch: PropTypes.bool,
  onlyChild: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  id: PropTypes.string,
  minMenuWidth: PropTypes.number,
  inputId: PropTypes.string,
  isRecommended: PropTypes.bool,
  isChanged: PropTypes.bool,
  required: PropTypes.bool,
  selectFirst: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onlyNotPortal: PropTypes.bool,
  focusOn: PropTypes.string,
  isSelectOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isCategorySelect: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  optionsLanguage: PropTypes.string,
  customOnBlur: PropTypes.func,
  dataTestId: PropTypes.string,
  searchDataTestId: PropTypes.string,
  forcedNoSearch: PropTypes.bool,
};

Select.defaultProps = {
  fullWidth: true,
  hasConfirmButton: false,
  selectFirst: false,
  hasSearch: false,
  isMulti: false,
  isClearable: false,
  hideSelectedOptions: false,
  onlyNotPortal: false,
  canClear: true,
  onlyChild: false,
  color: 'secondary',
  variant: 'standard',
  margin: 'none',
  menuPlacement: 'auto',
  meta: {},
  isSelectOpen: false,
  forcedNoSearch: false,
};

export default memo(Select);
