// core
import React from 'react';
import PropTypes from 'prop-types';

// UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';

// Style
import TabsStyles from './TabsStyles';

const useStyles = makeStyles(TabsStyles);

function TabsNav(props) {
  const { children, customClasses, isCustomTemplate } = props;
  const classes = useStyles();

  const defaultTemplate = (
    <Box
      color="default"
      className={cx(classes.tabsMenu, {
        [customClasses]: customClasses,
        [classes.tabsMenuSticky]: isCustomTemplate,
      })}
    >
      {isCustomTemplate
        ? children
        : (<div className={classes.tabsMenuSticky}>{children}</div>)
      }
    </Box>
  );

  if (isCustomTemplate) {
    return defaultTemplate;
  }
  return (
    <Grid item xs={12} md={4} lg={3} sx={{ display: 'flex' }}>
      {defaultTemplate}
    </Grid>
  );
}


TabsNav.propTypes = {
  children: PropTypes.node.isRequired,
  customClasses: PropTypes.string,
  isCustomTemplate: PropTypes.bool,
};

export default TabsNav;
