// Core
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { useCurrentMode } from './useCurrentMode';

export function usePartnerData() {
  const partnerData = useSelector(selectors.partner.userData);
  const mode = useSelector(selectors.partnerEditMode);
  const { isShowMode } = useCurrentMode(mode);
  const { t } = useTranslation();

  const getApprovedValue = useCallback(key => (
    get(partnerData, `infoFieldValues.${key}.approvedValue`)
  ), [partnerData]);

  const getCurrentValue = useCallback(key => (
    get(partnerData, `infoFieldValues.${key}.current`)
  ), [partnerData]);

  const getOldValue = useCallback((key, boolToTextOnCompare) => {
    if (boolToTextOnCompare && !isShowMode) {
      const value = get(partnerData, `infoFieldValues.${key}.old`);
      if (value === true || value === false) {
        return value ? t('Да') : t('Нет');
      }
      return value;
    }
    return isShowMode ? undefined : get(partnerData, `infoFieldValues.${key}.old`);
  }, [isShowMode, partnerData, t]);

  const getFieldValue = useCallback(key => (
    get(partnerData, key)
  ), [partnerData]);

  const getInfoFieldValue = useCallback(key => (
    get(partnerData, `infoFieldValues.${key}`)
  ), [partnerData]);

  return useMemo(() => ({
    getFieldValue,
    getInfoFieldValue,
    getApprovedValue,
    getCurrentValue,
    getOldValue,
    partnerData,
  }), [getFieldValue, getApprovedValue, getCurrentValue, getOldValue, partnerData, getInfoFieldValue]);
}
