// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ShutdownIcon(props) {
  return (
    <SvgIcon className="shutdown-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M8.44625 5.4199L8.44547 5.42029C5.57879 6.82823 3.8 9.68895 3.8 12.882C3.8 17.4345 7.4855 21.1476 12.0277 21.1925L12.0352 21.2H12.118C16.6984 21.2 20.429 17.4694 20.429 12.889C20.429 9.64426 18.5203 6.67601 15.5732 5.32589L15.5731 5.32582C15.2283 5.16821 14.8115 5.31354 14.6502 5.66325C14.4808 6.01509 14.6429 6.4311 14.9888 6.58925L14.9889 6.58929C17.4464 7.71134 19.0302 10.1832 19.0302 12.882C19.0302 16.6959 15.9249 19.8012 12.111 19.8012C8.29699 19.8012 5.19173 16.6959 5.19173 12.882C5.19173 10.2238 6.67139 7.84272 9.06053 6.67211C9.40998 6.50216 9.54688 6.08388 9.37941 5.73911C9.20957 5.38942 8.79113 5.25239 8.44625 5.4199L8.44625 5.4199Z" fill="inherit" stroke="inherit" strokeWidth="0.4" />
      <path d="M11.2663 12.3577C11.2663 12.7444 11.5754 13.0536 11.9622 13.0536C12.3489 13.0536 12.658 12.7444 12.658 12.3577V3.49587C12.658 3.10914 12.3489 2.8 11.9622 2.8C11.5754 2.8 11.2663 3.10914 11.2663 3.49587V12.3577Z" fill="inherit" stroke="inherit" strokeWidth="0.4" />
    </SvgIcon>
  );
}

export default ShutdownIcon;
