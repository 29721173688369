// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetPartnerDownloadWorker({ payload: partnerID }) {
  yield put(partnerActions.setPartnerDownloadPending(true));

  const response = yield apply(api, api.partner.getPartnerDownload, [partnerID]);

  if (response && response.status >= 200 && response.status < 400) {
    getFileFromBlob('partner.xls', response);
  }

  yield put(partnerActions.setPartnerDownloadPending(false));
}
