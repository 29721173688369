// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, treeDataRowIds) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_LIST_ASYNC, {
      params, treeDataRowIds,
    });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_CATEGORIES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_INITIAL_STATE_ASYNC, path);
  },
  getCategoriesListContextAsync(config) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_LIST_CONTEXT_ASYNC, config);
  },
  getCategoryItemByHashId(hashId) {
    return getActionCreator(asyncTypes.GET_CATEGORIES_ITEM_BY_HASH_ID_ASYNC, hashId);
  },
  postCommissionUploadAsync(file) {
    return getActionCreator(asyncTypes.POST_CATEGORIES_COMMISSION_UPLOAD_ASYNC, file);
  },
  putCategoryItemByHashId(data) {
    return getActionCreator(asyncTypes.PUT_CATEGORIES_ITEM_BY_HASH_ID_ASYNC, data);
  },
});
