// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Modal from '../../../../containers/Modals/Modal/Modal';

// Engine
import { partnerStatuses } from '../../../../../engine/config/partnerStatuses';
import { selectors } from '../../../../../engine/config/selectors';
import { billingActions } from '../../../../../engine/core/billing/action';
import { accessList } from '../../../../../engine/config/access';

// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

function BalanceOverModalFirst() {
  const { t } = useTranslation();
  const hasAccessBillingStatus = useAccessList(accessList.billingStatus);
  const statusItems = useSelector(selectors.billing.statusItems);
  const statusPending = useSelector(selectors.billing.statusPending);
  const { showPopups, partnerStatus } = useSelector(selectors.user.userInfo);
  const dispatch = useDispatch();
  const {
    recommendPaymentSum, firstPayment,
    erpStatus, creditLimitModalStatus, creditLimitModalOffStatus,
  } = statusItems;
  const [paymentNotificationOpen, setPaymentNotificationOpen] = useState(false);
  const firstPaymentNotificationStatus = localStorage.getItem('firstPaymentNotificationHide');

  const showPopUp = useCallback(() => {
    if (!creditLimitModalStatus && !creditLimitModalOffStatus && statusPending === false) {
      setPaymentNotificationOpen(true);
    }
  }, [statusPending, creditLimitModalOffStatus, creditLimitModalStatus]);

  const hidePopUp = () => {
    setPaymentNotificationOpen(false);
    localStorage.setItem('firstPaymentNotificationHide', 'true');
  };

  const onSend = () => {
    dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    setTimeout(() => {
      hidePopUp();
    }, 400);
  };

  useEffect(() => {
    if (!firstPaymentNotificationStatus && firstPayment) {
      showPopUp();
    }
  }, [firstPayment, firstPaymentNotificationStatus, showPopUp]);

  if (
    !hasAccessBillingStatus
    || !recommendPaymentSum
    || erpStatus === false
    || showPopups?.showFirstPayment === true
    || partnerStatus === partnerStatuses.problematic
  ) {
    return null;
  }

  return (
    <Modal
      title={t('Уважаемый партнер!')}
      modalOpen={paymentNotificationOpen}
      handleModalCancel={() => hidePopUp()}
      handleModalToggle={() => hidePopUp()}
      sendText={t('Пополнить баланс')}
      handleModalSend={onSend}
    >
      <p>{t('Для начала сотрудничества с Маркетплейс АЛЛО Вам необходимо пополнить баланс, минимально на')} <strong>{recommendPaymentSum}</strong> грн. </p>
      <strong>{t('Успешных продаж!')}</strong>
    </Modal>
  );
}

export default BalanceOverModalFirst;
