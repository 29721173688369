// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
// Engine
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';
import { types } from '../../types';
import { partnerActions } from '../../action';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const currentFilters = yield select(selectors.partner.filters);
  const currentSoring = yield select(selectors.partner.sorting);
  const data = {
    currentPage: yield select(selectors.partner.currentPage),
    filters: currentFilters.toJS(),
    pageSize: yield select(selectors.partner.pageSize),
    totalCount: yield select(selectors.partner.totalCount),
    sorting: currentSoring.toJS(),
  };

  let currentData;

  switch (type) {
    case types.SET_PARTNERS_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_PARTNERS_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.CHANGE_PARTNERS_FILTERS: {
      const currentPage = data.currentPage;
      const filters = payload.filters.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(partnerActions.setCurrentPage(0));
      }
      currentData = { filters };
      break;
    }
    case types.CHANGE_PARTNERS_SORTING: {
      const sorting = payload.sorting;
      currentData = { sorting };
      break;
    }
    default: {
      return;
    }
  }

  yield put(partnerActions.setSelection({ selection: [] }));
  data.currentPage = yield select(selectors.partner.currentPage);
  yield put(partnerActions.setPartnersList({ reload: true }));
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
