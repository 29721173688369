// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import confirmButtonsStyles from '../../../../../components/Buttons/ConfirmButtons/ConfirmButtonsStyles';
import { historyType as historyTypeFilter } from '../../../../../../_helpers/data/pages/settings/products/tableStatusesLogsScheme';

const useStyles = makeStyles(confirmButtonsStyles);

function ButtonShowStatusesLog(props) {
  const {
    hashId, historyType,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleLog = () => {
    dispatch(actions.mergeContentProductStatusChangeLog({ showModal: true, hashId, historyType }));
  };

  return (
    <Button
      sx={{ padding: '0px' }}
      className={classes.selectWidthConfirmClose}
      onClick={handleLog}
    >
      <SmsOutlined />
    </Button>
  );
}

ButtonShowStatusesLog.propTypes = {
  hashId: PropTypes.string,
  historyType: PropTypes.oneOf(Object.values(historyTypeFilter)),
};

export default ButtonShowStatusesLog;
