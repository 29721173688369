// Core
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
// UI
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import SortIcon from '../../../../../icons/SortIcon';
import ArrowUpSorting from '../../../../../icons/ArrowUpSorting';
import ArrowDownSorting from '../../../../../icons/ArrowDownSorting';
import { COLOR } from '../../../../../containers/App/AppStyles';

const TableHeaderRowLabelStyles = () => ({
  tableHeaderLabel: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
  },
  sortIcon: {
    color: COLOR.grey['72'],
    width: '16px',
    height: '14px',
    marginLeft: '14px',
    marginRight: '14px',
  },
});

const TableHeaderRowLabel = (props) => {
  const {
    column, classes, direction, children, ...restProps
  } = props;
  const { t } = useTranslation();
  const getMessage = () => typeof column.title === 'string' || typeof column.title === 'number'
    ? `${t('Сортировать по')} "${column.title}"`
    : t('Сортировать');
  return (
    <TableHeaderRow.SortLabel
      {...restProps}
      column={column}
      getMessage={getMessage}
      className={classes.tableHeaderLabel}
    >
      {children}
      {direction === 'asc' && <ArrowUpSorting className={classes.sortIcon} /> }
      {direction === 'desc' && <ArrowDownSorting className={classes.sortIcon} /> }
      {direction === null && <SortIcon className={classes.sortIcon} /> }
    </TableHeaderRow.SortLabel>
  );
};

export default withStyles(TableHeaderRowLabelStyles)(TableHeaderRowLabel);
