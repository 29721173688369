// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import values from 'lodash/values';

// Parts
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// config
import { selectors } from '../../../../../../engine/config/selectors';

// actions
import { actions } from '../../../../../../engine/core/billing/history/actions';
// import { asyncActions } from '../../../../../../engine/core/billing/history/saga/asyncActions';

// Hooks
import { useTransactionHistoryList } from '../../_hooks/useTransactionHistoryList';

import { tableColumns, sortingColumnExtensions } from './HistoryTableColumnsSettings';

// Redux
function TransactionHistoryTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.transactionHistory.isLoading);
  // Rows
  const rows = useSelector(selectors.transactionHistory.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.transactionHistory.currentPage);
  const pageSize = useSelector(selectors.transactionHistory.pageSize);
  const totalCount = useSelector(selectors.transactionHistory.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.transactionHistory.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.transactionHistory.settingStatus);
  // const settingPending = useSelector(selectors.transactionHistory.settingPending);
  // Filtering
  const filters = useSelector(selectors.transactionHistory.filters);
  const columns = values(tableColumns);

  return (
    <DxTable
      name="transactionHistory"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      updateHooks={useTransactionHistoryList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sortingStateColumnExtensions={sortingColumnExtensions}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.transactionHistory.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default TransactionHistoryTable;
