// Core
import React, {
  useState, memo, useMemo, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
// Icon
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FlipRoundedIcon from '@mui/icons-material/FlipRounded';
import CheckIcon from '@mui/icons-material/Check';
// Parts
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '../TextField/TextField';
// Helpers
import TooltipHint from '../../../Tooltip/TooltipHint';
import FormInputStyle from './styles';
import { useStyles } from '../../../../hooks/useStyles';

function FormTextField(props) {
  const {
    flexOne, fullWidth, valueOld, margin, label, type,
    disabled, approve, required, tooltipText, validation,
    meta, approvedValue, autoFocus, focusComponent,
    onApproveValue, input, textColor, timeout,
  } = props;
  const { touched, error } = meta;
  const { t } = useTranslation();
  const classes = useStyles(FormInputStyle);
  const [compare, setCompare] = useState(false);
  const showApproveButton = useMemo(() => valueOld !== undefined && approve, [approve, valueOld]);
  const isApprove = useCallback(data => approvedValue === data, [approvedValue]);
  const wrapperClassNames = useMemo(() => cx(classes.wrapper, {
    [classes.hasError]: validation && touched && error,
    [classes.wrapperRequired]: required,
    [classes.flexOne]: flexOne,
  }), [classes, error, flexOne, required, touched, validation]);

  const handleCompare = () => {
    setCompare(!compare);
  };

  const handleApproveValue = value => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value });
    }
  };

  const inputProps = compare ? {
    ...input,
    value: isNil(valueOld) ? '' : valueOld,
  } : input;

  const inputPropsTypeNumber = {
    ...input,
    onInput: (e) => {
      e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 6);
    },
  };

  return (
    <div className={wrapperClassNames}>
      <TextField
        autoFocus={autoFocus}
        input={type === 'number' ? inputPropsTypeNumber : inputProps}
        textColor={textColor}
        meta={meta}
        focusComponent={focusComponent}
        label={label}
        fullWidth={fullWidth}
        disabled={compare ? true : disabled}
        type={type || 'text'}
        required={required}
        margin={margin}
        validation
        timeout={timeout}
      />
      {(tooltipText || showApproveButton) && (
        <div className={classes.wrapperRow}>
          <div className={classes.wrapperButton}>
            {(valueOld !== undefined && approve) && (
              <>
                <Tooltip title={t('Показать прошлые данные')} placement="top">
                  <IconButton
                    className={cx(classes.button, classes.compareButton, {
                      [classes.compareButtonActive]: compare,
                    })}
                    onClick={handleCompare}
                    aria-label={t('Показать прошлые данные')}
                    size="large"
                  >
                    <FlipRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Подтвердить')} placement="top">
                  <IconButton
                    className={cx(classes.button, classes.checkButton, {
                      [classes.checkButtonActive]: isApprove('current'),
                    })}
                    onClick={handleApproveValue('current')}
                    aria-label={t('Подтвердить')}
                    size="large"
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Отклонить')} placement="top">
                  <IconButton
                    className={cx(classes.button, classes.cancelButton, {
                      [classes.cancelButtonActive]: isApprove('old'),
                    })}
                    onClick={handleApproveValue('old')}
                    aria-label={t('Отклонить')}
                    size="large"
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
          {tooltipText && (
            <TooltipHint
              title={tooltipText}
              classNameTooltip={classes.tooltipHint}
            />
          )}
        </div>
      )}
    </div>
  );
}

FormTextField.propTypes = {
  focusComponent: PropTypes.node,
  flexOne: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  valueOld: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  approve: PropTypes.bool,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  textColor: PropTypes.string,
  validation: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.element,
  }),
  approvedValue: PropTypes.oneOf(['current', 'old']),
  onApproveValue: PropTypes.func,
  name: PropTypes.string,
  input: PropTypes.object,
  timeout: PropTypes.number,
};

FormTextField.defaultProps = {
  flexOne: false,
  validation: false,
  input: {},
  meta: {
    touched: false,
    error: '',
  },
};

export default memo(FormTextField);
