export const types = Object.freeze({
  MERGE_NOTIFICATIONS_LISTS: 'MERGE_NOTIFICATIONS_LISTS',
  SET_NOTIFICATIONS_LISTS_PENDING: 'SET_NOTIFICATIONS_LISTS_PENDING',
  ADD_NOTIFICATION_READ_TO_ARRAY: 'ADD_NOTIFICATION_READ_TO_ARRAY',
  CLEAR_NOTIFICATION_READ_TO_LISTS: 'CLEAR_NOTIFICATION_READ_TO_LISTS',
  SET_NOTIFICATIONS_LISTS_OFFSET: 'SET_NOTIFICATIONS_LISTS_OFFSET',
  SET_NOTIFICATIONS_LISTS_OFFSET_NOT_READ: 'SET_NOTIFICATIONS_LISTS_OFFSET_NOT_READ',
  SET_NOTIFICATIONS_CENTER_TAB: 'SET_NOTIFICATIONS_CENTER_TAB',
  SET_NOTIFICATIONS_LAST_SHOW_TOAST_TIME: 'SET_NOTIFICATIONS_LAST_SHOW_TOAST_TIME',
  SET_NOTIFICATIONS_LIST: 'SET_NOTIFICATIONS_LIST',
  SET_NOTIFICATIONS_CURRENT_PAGE: 'SET_NOTIFICATIONS_CURRENT_PAGE',
  SET_NOTIFICATIONS_FILTERS: 'SET_NOTIFICATIONS_FILTERS',
  SET_NOTIFICATIONS_PAGE_SIZE: 'SET_NOTIFICATIONS_PAGE_SIZE',
  SET_NOTIFICATIONS_SORTING: 'SET_NOTIFICATIONS_SORTING',
  SET_NOTIFICATIONS_INITIAL_STATE: 'SET_NOTIFICATIONS_INITIAL_STATE',
  SET_NOTIFICATIONS_SETTING_STATUS: 'SET_NOTIFICATIONS_SETTING_STATUS',
  SET_NOTIFICATIONS_SETTING_PENDING: 'SET_NOTIFICATIONS_SETTING_PENDING',
  SET_NOTIFICATION_BY_HASH_ID: 'SET_NOTIFICATION_BY_HASH_ID',
  SET_NOTIFICATION_SELECTION: 'SET_NOTIFICATION_SELECTION',
  MERGE_NOTIFICATION_GROUPS: 'MERGE_NOTIFICATION_GROUPS',
  MERGE_NOTIFICATION_TYPES: 'MERGE_NOTIFICATION_TYPES',
});
