// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_STOP_WORDS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_STOP_WORDS_SETTINGS_ASYNC, param);
  },
  postUploadStopWordAsync(data) {
    return getActionCreator(asyncTypes.POST_UPLOAD_STOP_WORD_ASYNC, data);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_STOP_WORDS_INITIAL_STATE_ASYNC, path);
  },
  deleteStopWordsAsync(data) {
    return getActionCreator(asyncTypes.DELETE_STOP_WORD_ASYNC, data);
  },
  postStopWordsAsync(data) {
    return getActionCreator(asyncTypes.POST_STOP_WORD_ASYNC, data);
  },
  getStopWordHashIdAsync(data) {
    return getActionCreator(asyncTypes.GET_STOP_WORD_HASH_ID_ASYNC, data);
  },
  putStopWordAsync(data) {
    return getActionCreator(asyncTypes.PUT_STOP_WORD_ASYNC, data);
  },
  getExportStopWordsAsync(data) {
    return getActionCreator(asyncTypes.GET_EXPORT_STOP_WORDS_ASYNC, data);
  },
});
