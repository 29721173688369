export const tableColumns = Object.freeze({
  brand: Object.freeze({
    name: 'brand',
  }),
  cancelStatus: Object.freeze({
    name: 'cancelStatus',
  }),
  canceledAt: Object.freeze({
    name: 'canceledAt',
  }),
  category: Object.freeze({
    name: 'category',
  }),
  paymentType: Object.freeze({
    name: 'paymentType',
  }),
  doneAt: Object.freeze({
    name: 'doneAt',
  }),
  datetime: Object.freeze({
    name: 'datetime',
  }),
  orderStatus: Object.freeze({
    name: 'orderStatus',
  }),
  orderedAt: Object.freeze({
    name: 'orderedAt',
  }),
  partner: Object.freeze({
    name: 'partner',
  }),
  shippedAt: Object.freeze({
    name: 'shippedAt',
  }),
  shippingTrackingNumber: Object.freeze({
    name: 'shippingTrackingNumber',
  }),
});
