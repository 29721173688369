// Core
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks, routersLink } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';

export default function ProtectedLayout(props) {
  const { element } = props;
  const userAccessList = useSelector(selectors.user.accessList);
  const search = useSelector(selectors.router.search);
  const userInfo = useSelector(selectors.user.userInfo);
  const emptyUserInfo = Object.keys(userInfo).length === 0;
  const isEnabled = userInfo.enabled;
  const dashboardPage = routersLink.dashboard;
  const ordersPage = pageLinks.orders.all;
  const productsPage = pageLinks.content.products.all();
  const hasAccessOrdersPage = userAccessList.includes(accessList.orderList);
  const hasAccessProductsPage = userAccessList.includes(accessList.productList);
  const redirectQueryParam = decodeURIComponent(search).replace('?redirect=', '');
  const ordersPageNoSlash = ordersPage.substr(-1) === '/'
    ? ordersPage.substr(0, ordersPage.length - 1)
    : ordersPage;
  const productsPageNoSlash = productsPage.substr(-1) === '/'
    ? productsPage.substr(0, productsPage.length - 1)
    : productsPage;

  switch (true) {
    case (isEnabled === false): {
      return <Navigate to={routersLink.users.confirm} />;
    }
    case (emptyUserInfo): {
      return element;
    }
    case (hasAccessOrdersPage && redirectQueryParam === ''): {
      return <Navigate to={pageLinks.orders.all} />;
    }
    case (hasAccessProductsPage && redirectQueryParam === ''): {
      return <Navigate to={pageLinks.content.products.simpleView} />;
    }
    case (redirectQueryParam?.includes(ordersPageNoSlash)): {
      return <Navigate to={hasAccessOrdersPage ? redirectQueryParam : dashboardPage} />;
    }
    case (redirectQueryParam?.includes(productsPageNoSlash)): {
      return <Navigate to={hasAccessProductsPage ? redirectQueryParam : dashboardPage} />;
    }
    default: {
      return <Navigate to={redirectQueryParam || dashboardPage} />;
    }
  }
}
