// Core
import {
  apply, put, select,
} from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { notificationsActions } from '../../action';
import { selectors } from '../../../../config/selectors';
import { statisticsActions } from '../../../statistics/action';

export function* callPutNotificationsWorker({ payload }) {
  yield put(notificationsActions.setNotificationsPending(true));
  const response = yield apply(api, api.notifications.putNotifications, [payload]);

  if (response && response.status === 200) {
    const newNotificationsNotReadCount = response.data.notificationsNotRead;
    yield put(statisticsActions.setStatisticsCounterNotificationNotRead(newNotificationsNotReadCount));
    yield put(notificationsActions.clearNotificationRead());
  }
  const pageSize = yield select(selectors.notification.pageSize);
  const sorting = (yield select(selectors.notification.sorting)).toJS();
  const filters = (yield select(selectors.notification.filters)).toJS();
  const params = {
    sorting,
    limit: pageSize,
  };

  filters.reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);
}
