// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callSignUpWorker,
  callSignInWorker,
  callSetUserInfoWorker,
  callPutUserInfoWorker,
  callLogOutWorker,
  callGetUserInfoWorker,
  callConfirmCodeWorker,
  callPostUsersWorker,
  callGetUsersRolesWorker,
  callGetUsersHashIdWorker,
  callPutUsersHashIdWorker,
  callGetUsersSelfWorker,
  callAuthCleanUpWorker,
  callPostUserInfoWorker,
  callPostPhoneVerifyCodeWorker,
  callGetRepeatSendPhoneCodeWorker,
  callGetMailSettingsWorker,
  callPutMailSettingsWorker,
  callGetUsersPartnerRolesWorker,
  callSetUserNotificationsWorker,
} from './workers';

function* watchSignUp() {
  yield takeEvery(asyncTypes.SIGN_UP_ASYNC, callSignUpWorker);
}
function* watchGetUsersPartnerRoles() {
  yield takeEvery(asyncTypes.GET_USERS_PARTNER_ROLES_ASYNC, callGetUsersPartnerRolesWorker);
}
function* watchSignIn() {
  yield takeEvery(asyncTypes.SIGN_IN_ASYNC, callSignInWorker);
}
function* watchUserInfo() {
  yield takeEvery(asyncTypes.SET_USER_INFO_ASYNC, callSetUserInfoWorker);
}
function* watchConfirmCode() {
  yield takeEvery(asyncTypes.CONFIRM_CODE_ASYNC, callConfirmCodeWorker);
}
function* watchLogOut() {
  yield takeEvery(asyncTypes.LOG_OUT_ASYNC, callLogOutWorker);
}
function* watchGetUserInfo() {
  yield takeEvery(asyncTypes.GET_USER_INFO_ASYNC, callGetUserInfoWorker);
}
function* watchSetUserNotifications() {
  yield takeEvery(asyncTypes.SET_USER_NOTIFICATIONS_ASYNC, callSetUserNotificationsWorker);
}
function* watchPutUserInfo() {
  yield takeEvery(asyncTypes.PUT_USER_INFO_ASYNC, callPutUserInfoWorker);
}
function* watchPostUsers() {
  yield takeEvery(asyncTypes.POST_USERS_ASYNC, callPostUsersWorker);
}
function* watchGetUsersRoles() {
  yield takeEvery(asyncTypes.GET_USERS_ROLES_ASYNC, callGetUsersRolesWorker);
}
function* watchGetUsersHashId() {
  yield takeEvery(asyncTypes.GET_USER_BY_HASH_ID_ASYNC, callGetUsersHashIdWorker);
}
function* watchPutUsersHashId() {
  yield takeEvery(asyncTypes.PUT_USER_BY_HASH_ID_ASYNC, callPutUsersHashIdWorker);
}
function* watchGetUsersSelf() {
  yield takeEvery(asyncTypes.GET_USERS_SELF_ASYNC, callGetUsersSelfWorker);
}
function* watchAuthCleanUp() {
  yield takeEvery(asyncTypes.AUTH_CLEAN_UP, callAuthCleanUpWorker);
}
function* watchPostCurrentLanguage() {
  yield takeEvery(asyncTypes.POST_USER_INFO_ASYNC, callPostUserInfoWorker);
}
function* watchPostPhoneVerifyCodeWorker() {
  yield takeEvery(asyncTypes.POST_PHONE_VERIFY_CODE, callPostPhoneVerifyCodeWorker);
}
function* watchGetRepeatSendPhoneCodeWorker() {
  yield takeEvery(asyncTypes.GET_REPEAT_SEND_PHONE_CODE, callGetRepeatSendPhoneCodeWorker);
}
function* watchGetMailSettingsWorker() {
  yield takeEvery(asyncTypes.GET_MAIL_SETTINGS_ASYNC, callGetMailSettingsWorker);
}
function* watchPutMailSettingsWorker() {
  yield takeEvery(asyncTypes.PUT_MAIL_SETTINGS_ASYNC, callPutMailSettingsWorker);
}

export function* watchersUser() {
  yield all([
    watchSignUp(),
    watchSignIn(),
    watchUserInfo(),
    watchConfirmCode(),
    watchLogOut(),
    watchGetUserInfo(),
    watchPutUserInfo(),
    watchPostUsers(),
    watchSetUserNotifications(),
    watchGetUsersRoles(),
    watchGetUsersHashId(),
    watchPutUsersHashId(),
    watchGetUsersSelf(),
    watchAuthCleanUp(),
    watchPostCurrentLanguage(),
    watchPostPhoneVerifyCodeWorker(),
    watchGetRepeatSendPhoneCodeWorker(),
    watchGetMailSettingsWorker(),
    watchPutMailSettingsWorker(),
    watchGetUsersPartnerRoles(),
  ]);
}
