// Core
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// UI
import {
  VirtualTable,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

// Style
import TableFilterRowWithSelectedCounterStyle from './TableFilterRowWithSelectedCounterStyle';

const useStyles = makeStyles(TableFilterRowWithSelectedCounterStyle);
function TableFilterRowComponent(props) {
  const { children, selectedCounter, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <VirtualTable.Row {...rest}>
      {
        children.map((item, index) => index === 0 && selectedCounter !== undefined
          ? (
            <TableHeaderRow.Cell key={index} className={classes.selectCounterCell}>
              <div className={classes.selectCounterWrapper}>
                <span className={classes.selectCounterTitle}>{t('Выбрано')}</span>
                <span className={classes.selectCounterQuantity}>{selectedCounter}</span>
              </div>
            </TableHeaderRow.Cell>
          )
          : item)
      }
    </VirtualTable.Row>
  );
}

TableFilterRowComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  selectedCounter: PropTypes.number,
};

export default memo(TableFilterRowComponent);
