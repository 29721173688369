// Core
import { styled } from '@mui/system';
// Parts
import Box from '@mui/material/Box';
import { COLOR } from '../../../../../../containers/App/AppStyles';

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ValuesCount = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  height: 28px;
  min-width: 28px;
  color: #fff;
  font-size: 14px;
  background: ${COLOR.controls.dark};
  border-radius: 5px;
  -webkit-text-fill-color: #fff;
`;
