const ChatComponentsStyles = () => ({
  tabsContainer: {
    padding: '0 0 0 15px',
    '& > .MuiCircularProgress-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
  },
  messageLoader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  infinityLoader: {
    height: 40,
    width: 40,
    overflow: 'hidden',
    margin: '20px auto 0',
  },
  chatList: {
    width: '100%',
    position: 'relative',
    paddingRight: 15,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  chatListHeightIsEdit: {
    height: 'calc(100vh - 220px)',
  },
  chatListHeightIsVisible: {
    height: 'calc(100vh - 165px)',
  },
  infinityScrollStyles: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
});

export default ChatComponentsStyles;
