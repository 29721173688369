// Core
import { put } from 'redux-saga/effects';
import { localData } from '../../../../config/localData';
import { pageLinks } from '../../../../config/routes';
import { actions } from '../../action';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';

export function* callGetMailTemplatesInitialStateWorker() {
  const mail = yield localData.getItem(pageLinks.settings.emailTemplates.all);
  const initialPageState = getInitialPageState(mail || {});

  yield put(actions.setMailTemplatesInitialState(initialPageState));
}
