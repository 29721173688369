// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { billingActions } from '../../action';

export function* callGetBillingPaymentTypesWorker() {
  yield put(billingActions.setBillingPaymentTypes({ pending: true }));
  const response = yield apply(api, api.billing.getBillingPaymentTypes);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    yield put(billingActions.setBillingPaymentTypes({
      items: responseData,
      pending: false,
    }));
  }
}
