import { getIn, List, Map } from 'immutable';
// Store keys
import { stateKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const productFeedCategoriesAttributes = Object.freeze({
  // PRODUCT_FEED_ATTRIBUTES
  attributesItems: state => getIn(state, [stateKeys.controller, stateKeys.productFeedAttributes, stateKeys.items], Map()),
  attributesExpandedRowIds: state => getIn(state, [stateKeys.controller, stateKeys.productFeedAttributes, stateKeys.expandedRowIds], List()),
  // PRODUCT_FEED_CATEGORIES
  categoriesExpandedRowIds: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategories, stateKeys.expandedRowIds], List()),
  categoriesLoadingRowIds: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategories, stateKeys.loadingRowIds], List()),
  categoriesItems: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategories, stateKeys.items], Map()),
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.productFeedCategoriesAttributesList, stateKeys.settingPending], false),
  autoConnectPending: state => getIn(state, [stateKeys.controller, stateKeys.autoConnect, stateKeys.pending], false),
});
