// Core
import { put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
import { pageLinks } from '../../../../../config/routes';

// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(lists || {});
  const feedCategoryLink = pageLinks.feed.matchingCategories;
  const currentId = url.substring(url.lastIndexOf('/') + 1);
  const isWithCategoryId = url.indexOf(`${feedCategoryLink}/${currentId}`) !== -1;
  if (isWithCategoryId) {
    initialPageState.filters.push({ columnName: 'feed', value: currentId });
  }
  yield put(actions.setInitialState(initialPageState));
}
