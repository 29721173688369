import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// Engine
import { change } from 'redux-form/immutable';
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { uiActionAsync } from '../../../../../../../engine/core/ui/saga/asyncAction';
import { store } from '../../../../../../../engine/init/store';
import { fileExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { formFields, formName } from '../../../../../../page/Products/PriceLists/Import/components/AddFormXml';

const timeout = 200;
export const importFileXmlCallback = ({ hasAdminAccess }) => (data) => {
  const {
    action, status, index, type,
  } = data;

  const getIndexStep = stepIndex => hasAdminAccess ? stepIndex + 1 : stepIndex;
  const state = store.getState();
  const midPartnerData = selectors.partner.listContext(state)?.toJS().filter(
    item => item.value !== null,
  )?.[55];

  if (index === 0 && action === ACTIONS.START) {
    store.dispatch(uiActions.setImportPageType('XML'));
    store.dispatch(uiActions.setImportPageFileType('file'));
  }

  if (index === 0 && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActionAsync.setImportLang(true));
  }

  if (hasAdminAccess && index === 2 && type === EVENTS.STEP_BEFORE) {
    store.dispatch(change(formName, formFields.partner, midPartnerData));
  }

  if (index === getIndexStep(3) && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActions.setImportPageFileType('file'));
    store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xml));
  }

  if (index === getIndexStep(3)) {
    store.dispatch(uiActions.setImportPageFileType('file'));
  }

  if (index === getIndexStep(4)) {
    store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xml));
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setImportPageFilePondDefault(null));
    store.dispatch(uiActions.setJoyrideStepIndex(0));
    store.dispatch(uiActionAsync.setImportLang(false));
    setTimeout(() => {
      store.dispatch(uiActions.setImportPageFileType('link'));
    }, timeout);
    if (hasAdminAccess) {
      store.dispatch(change(formName, formFields.partner, null));
    }
  }
};
