// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  productFeedLogsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  log: Map({
    pending: false,
    showModal: false,
    hashId: null,
    id: null,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'productFeedLogs',
  productFeedLogsList: 'productFeedLogsList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  log: 'log',
  showModal: 'showModal',
  hashId: 'hashId',
  id: 'id',
});

export const productFeedLogsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRODUCT_FEED_LOGS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.productFeedLogsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.productFeedLogsList, stateKeys.pending], pending)
        .setIn([stateKeys.productFeedLogsList, stateKeys.items], List(items))
        .setIn([stateKeys.productFeedLogsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PRODUCT_FEED_LOGS_SORTING: {
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.sorting], List(payload));
    }
    case types.SET_PRODUCT_FEED_LOGS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.filters], List(filters));
    }
    case types.SET_PRODUCT_FEED_LOGS_PAGE_SIZE: {
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.pageSize], payload);
    }
    case types.SET_PRODUCT_FEED_LOGS_CURRENT_PAGE: {
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.currentPage], payload);
    }
    case types.SET_PRODUCT_FEED_LOGS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.productFeedLogsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.productFeedLogsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.productFeedLogsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.productFeedLogsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.productFeedLogsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.productFeedLogsList, stateKeys.reload], reload)
        .setIn([stateKeys.productFeedLogsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PRODUCT_FEED_LOGS_SETTING_STATUS: {
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.settingStatus], payload);
    }
    case types.SET_PRODUCT_FEED_LOGS_SETTING_PENDING: {
      return state.setIn([stateKeys.productFeedLogsList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.GET_PRODUCT_FEED_LOGS: {
      return state.mergeIn([stateKeys.log], Map(payload));
    }
    default: {
      return state;
    }
  }
};
