// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { replace } from 'redux-first-history';
import { asyncActions } from '../asyncActions';
import { api, requestStatus } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../config/routes';
import { actions } from '../../actions';

export function* callPutFaqsItemByHashId({ payload: { hashId, ...data } }) {
  yield put(actions.setIsItemByHashIdPutPending(true));
  const response = yield apply(api, api.faq.putFaqsHashId, [{ hashId, data }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        const { approved } = data;
        if (approved || approved === false) {
          yield put(asyncActions.getFaqItemByHashId(hashId));
        }
        yield put(setSuccessMessage(message, statusTitle));
        yield put(replace(pageLinks.faq.all));
        yield put(asyncActions.getFaqListAsync());
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setIsItemByHashIdPutPending(false));
}
