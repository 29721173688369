// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
// Engine
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';
import { pageLinks } from '../../../../config/routes';
import { types } from '../../types';
import { actions } from '../../actions';
import { actions as contentActions } from '../../../content/actions';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const currentFilters = yield select(selectors.contentProduct.filters);
  const currentSorting = yield select(selectors.contentProduct.sorting);
  const currentSelection = yield select(selectors.contentProduct.selection);
  const isProductCategoryCountModalOpen = yield select(selectors.content.isProductCategoryCountModalOpen);
  const contentProductsPageSendQuery = yield localData.getItem(localData.keysObj.contentProductsPageSendQuery);
  const isProductCategoryCountModalCategoryId = yield select(selectors.content.isProductCategoryCountModalCategoryId);
  const data = {
    currentPage: yield select(selectors.contentProduct.currentPage),
    filters: currentFilters.toJS(),
    pageSize: yield select(selectors.contentProduct.pageSize),
    totalCount: yield select(selectors.contentProduct.totalCount),
    sorting: currentSorting.toJS(),
  };

  let currentData;

  if (contentProductsPageSendQuery && !isProductCategoryCountModalOpen) {
    yield put(actions.setSendQuery(true));
  }

  switch (type) {
    case types.SET_CONTENT_PRODUCT_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_CONTENT_PRODUCT_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_CONTENT_PRODUCT_FILTERS: {
      const currentPage = data.currentPage;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      if (currentSelection.size) {
        yield put(actions.setSelection([]));
      }
      currentData = { filters };
      break;
    }
    case types.SET_CONTENT_PRODUCT_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.contentProduct.currentPage);
  yield put(actions.setReload(true));
  let currentURL = pathname;
  const hashId = pathname.substring(pathname.lastIndexOf('/') + 1);
  if (isProductCategoryCountModalCategoryId) {
    currentURL = pageLinks.content.products.all(isProductCategoryCountModalCategoryId);
    yield put(contentActions.setIsProductCategoryCountModalCategory(null));
  } else if (pathname === pageLinks.attributeSets.all || pathname === pageLinks.productsAndPrices.import(hashId)) {
    currentURL = pageLinks.content.products.simpleView;
  }

  yield apply(localData, localData.setItem, [
    currentURL,
    { ...data, ...currentData },
  ]);
}
