// Core
import { put, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
// Engine
import { actions } from '../../../content/actions';
import { actions as contentProductActions } from '../../actions';
// Partial
import { pageLinks } from '../../../../config/routes';
import { selectors } from '../../../../config/selectors';

export function* callRedirectToCategoryWithStatus({ payload }) {
  const { productStatusId, categoryId } = payload;
  yield put(contentProductActions.setSendQuery(false));
  const statuses = yield select(selectors.allProductsTable.productStatuses);
  const currentStatus = statuses.toJS().filter(item => item.value === productStatusId);
  yield put(actions.setIsProductCategoryCountModalCategory(categoryId));
  yield put(contentProductActions.setFilters([
    { columnName: 'productStatus', value: currentStatus },
  ]));
  yield put(push(pageLinks.content.products.all(categoryId)));
  yield put(actions.setIsProductCategoryCountModal({ isOpen: false }));
  yield put(contentProductActions.setSendQuery(true));
}
