// Core
import { useEffect, useRef, useState } from 'react';
import { List as ImList, isImmutable } from 'immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicator from '@mui/icons-material/DragIndicator';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// Parts
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import ButtonWithIconAndCircularProgress from '../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import CopyToClipboardComponent from '../../../../../containers/CopyToClipboard';
import ListItemTextWithResponseCode from '../components/ListItemTextWithResponseCode';
import TabsPanel from '../../../../../containers/Tabs/TabsPanel';
// Engine
import { mediaActionAsync } from '../../../../../../engine/core/media/saga/asyncAction';
import { media } from '../../../../../../engine/core/media/selectors';
import { useStyles } from '../../../../../hooks/useStyles';
// Style
import { SubTitle } from '../../../styles';
import { COLOR } from '../../../../../containers/App/AppStyles';
import ContentProductEditImagesTabStyles from './ContentProductEditImagesTabStyles';
import { transformGifToStatic } from '../../../../../../_helpers/transformGifToStatic';

function checkSrcExists(arr, userInput) {
  return arr.some(item => item.src === userInput);
}
function ContentProductEditImagesTab(props) {
  const {
    display, disabled, parentClasses, input, tabIndex, tabValue,
    canCreatePhoto, canEditPhoto, canDeletePhoto,
  } = props;

  const dispatch = useDispatch();
  const fileLoading = useSelector(media.uploadingFiles);
  const { t } = useTranslation();
  const classes = useStyles(ContentProductEditImagesTabStyles);
  const [imagesArr, setImagesArr] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const disableCreatePhoto = disabled || fileLoading || !canCreatePhoto;
  const disableEditPhoto = disabled || fileLoading || !canEditPhoto;
  const disableDeletePhoto = disabled || fileLoading || !canDeletePhoto;
  const [hasError, setHasError] = useState(false);
  const { onChange } = input;
  const buttonRef = useRef();
  const images = isImmutable(input.value) ? input.value.toJS() : input.value;
  const classNameAvatar = cx(parentClasses.avatarIcon, {
    [classes.disabled]: disableEditPhoto,
  });

  useEffect(() => {
    onChange(imagesArr);
  }, [onChange, imagesArr]);

  useEffect(() => {
    if (images.length) {
      if (images.some(img => img.src.endsWith('gif'))) {
        const fetchStatic = async () => {
          // set first static image of gif to secondary, bc we can't save large data images
          const staticImages = await transformGifToStatic(images, 'secondary');
          setImagesArr(staticImages);
        };
        fetchStatic();
        return;
      }
      setImagesArr(images);
    }
  }, [input.value]);

  const submitForm = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const isImgAlreadyExist = checkSrcExists(imagesArr, inputValue);

    const regExp = /\.(jpeg|jpg|png|webp|gif)$/;
    const isMatch = inputValue.match(regExp) != null;

    if (!disabled && isMatch && !isImgAlreadyExist) {
      setHasError(false);
      setImagesArr([...imagesArr, {
        key: uuidv4(),
        primary: '',
        secondary: inputValue,
        src: inputValue,
      }]);
    } else {
      setHasError(true);
    }
    setInputValue('');
    setHasError(false);
  };

  const submitUploadMedia = (ev) => {
    const files = ev.target.files;
    ev.preventDefault();
    ev.stopPropagation();
    dispatch(mediaActionAsync.postProductMediasAsync({
      files,
      context: 'product_media',
      cb: (newImageArr) => {
        setImagesArr([...imagesArr, ...newImageArr]);
      },
    }));
  };

  const onClearField = () => {
    setInputValue('');
  };

  const onTextFieldChange = (ev) => {
    setInputValue(ev.target.value);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || disabled) {
      return;
    }

    const items = reorder(
      imagesArr,
      result.source.index,
      result.destination.index,
    );

    setImagesArr(items);
  };

  const removeImage = imageKey => (function filterImageArray() {
    if (!disabled) {
      const index = imagesArr.findIndex(item => item.key === imageKey);
      imagesArr.splice(index, 1);
      setImagesArr([...imagesArr]);
    }
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !disableCreatePhoto) {
      buttonRef.current.click();
    }
  };

  const handleOnErrorAvatarImage = (e, index) => {
    setImagesArr(prevImagesArr => prevImagesArr.map((item, i) => {
      if (i === index && e.target.src === item.newSrc) {
        return { ...item, newSrc: undefined, isErrorChecked: true };
      }
      if (i === index && !item.newSrc && !item.isErrorChecked) {
        return { ...item, newSrc: item.alloLink };
      }
      return item;
    }));
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <TabsPanel
      style={{ display }}
      index={tabIndex}
      value={tabValue}
      isCustomTemplate
    >
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <SubTitle component="h2" mt={2}>{t('Редактирование Фото')}</SubTitle>
          <Typography sx={{ fontSize: '14px', mt: '10px' }}>{t('Первая фотография будет на обложке категории')}. {t('Перетащите, чтобы изменить порядок фотографий')}.</Typography>
          <Typography sx={{ fontSize: '13px', mt: '15px', color: COLOR.black['72'] }}>
            {t('Добавьте на карту товара настоящие фото, без водяных знаков')}.
            <br />
            {t('Поддерживаются файлы до 50 МБ в формате jpg, jpeg, png, webp, gif')}.
          </Typography>
          <div className={classes.wrapRoot}>
            <Box className={classes.root}>
              <TextField
                disabled={disableCreatePhoto}
                fullWidth
                onKeyUp={handleKeyPress}
                endAdornment={(
                  <>
                    {inputValue.length && !disabled ? (
                      <InputAdornment position="end" className={classes.inputAdornment}>
                        <IconButton
                          type="button"
                          disabled={disableCreatePhoto}
                          onClick={onClearField}
                          className={classes.clearButton}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null}
                  </>
                )}
                input={{
                  onChange: onTextFieldChange,
                  value: inputValue,
                }}
                variant="outlined"
                label={t('Добавить фото по ссылке')}
                color="secondary"
              />
            </Box>
            <Box className={classes.addedPhotoBtnControl}>
              <IconButton
                aria-label="directions"
                disabled={disableCreatePhoto}
                className={cx(classes.button, classes.iconAddButton, {
                  [classes.buttonActive]: inputValue.length && !hasError,
                })}
                type="button"
                onClick={submitForm}
                size="large"
                ref={buttonRef}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
              <Tooltip title={t('Загрузить несколько фото')} placement="bottom" arrow>
                <Box>
                  <ButtonWithIconAndCircularProgress
                    disabled={disableCreatePhoto}
                    isLoading={fileLoading}
                    className={classes.iconButtonAttach}
                    variant="outlined"
                    color="secondary"
                    component="label"
                  >
                    <AttachFileIcon />
                    <input hidden accept="image/jpg, image/jpeg, image/png, image/webp, image/gif" multiple type="file" onChange={submitUploadMedia} />
                  </ButtonWithIconAndCircularProgress>
                </Box>
              </Tooltip>
            </Box>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedDroppable, snapshotDroppable) => (
                <Paper ref={providedDroppable.innerRef} className={classes.dragListHolder}>
                  <List className={parentClasses.list}>
                    {Array.isArray(images) && images?.map((item, idx) => (
                      <Draggable key={item.key} draggableId={`${item.key}`} index={idx} isDragDisabled={disableEditPhoto}>
                        {(providedDraggable, snapshotDraggable) => (
                          <ListItem
                            divider
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            className={classes.dragListItem}
                            style={getItemStyle(
                              snapshotDraggable.isDragging,
                              providedDraggable.draggableProps.style,
                            )}
                          >
                            <Box className={classes.dragItemContent}>
                              <DragIndicator className={disableEditPhoto ? classes.disabled : undefined} />
                              <ListItemAvatar className={classes.avatarHolderIcon}>
                                <Avatar
                                  alt={`Avatar n°${idx}`}
                                  className={classNameAvatar}
                                  src={item.src.endsWith('gif') ? item.secondary : (item.newSrc ?? item.src)}
                                  onError={e => handleOnErrorAvatarImage(e, idx)}
                                />
                              </ListItemAvatar>
                              <ListItemTextWithResponseCode disableEditPhoto={disableEditPhoto} item={item} classes={classes} />
                            </Box>
                            <Box className={classes.dragBtnGroup}>
                              <CopyToClipboardComponent
                                link={item.newSrc ?? item.src}
                                className={cx(classes.button, classes.copy)}
                                tooltipText={t('Копировать ссылку')}
                                iconComponent={<ContentCopyOutlinedIcon />}
                              />
                              <IconButton
                                disabled={disableDeletePhoto}
                                aria-disabled={disableDeletePhoto}
                                onClick={removeImage(item.key)}
                                sx={getListStyle(snapshotDroppable.isDraggingOver)}
                                className={cx(classes.button, classes.remove)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                  </List>
                </Paper>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </TabsPanel>
  );
}

ContentProductEditImagesTab.propTypes = {
  display: PropTypes.oneOf(['flex', 'none']),
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.instanceOf(ImList),
    ]),
  }).isRequired,
  canCreatePhoto: PropTypes.bool,
  canEditPhoto: PropTypes.bool,
  canDeletePhoto: PropTypes.bool,
  parentClasses: PropTypes.object,
  tabIndex: PropTypes.string,
  tabValue: PropTypes.string,
};

ContentProductEditImagesTab.defaultProps = {
  display: 'none',
  disabled: false,
  parentClasses: {},
};

export default ContentProductEditImagesTab;

function getItemStyle(isDragging, draggableStyle) {
  return {
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && {
      boxShadow: COLOR.shadow.block.main,
      background: '#ffffff',
    }),
  };
}

function getListStyle(isDraggingOver) {
  // background: isDraggingOver ? 'lightblue' : 'lightgrey'
  return isDraggingOver ? { display: 'none' } : {};
}

// a little function to help us with reordering the result
function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
