import { COLOR } from '../../../../containers/App/AppStyles';

const errorColor = COLOR.error;
const changeColor = COLOR.changeColor;

const ContentProductEditStyles = theme => ({
  tabsContainer: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  tabsContent: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  contentHr: { borderColor: 'rgba(0, 0, 0, 0.12)', margin: '16px 0' },
  price: {
    fontSize: '18px',
    color: COLOR.brand.dark,
    fontWeight: 500,
  },
  oldPrice: {
    textDecoration: 'line-through',
    paddingLeft: '20px',
    fontSize: '18px',
    color: '#999',
    fontWeight: 500,
  },
  contentInfoName: { color: COLOR.black['38'], fontSize: '12px' },
  contentInfoValue: {
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: 1.1,
    wordBreak: 'break-word',
  },
  technicalInfo: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  descriptionLabel: {
    flexGrow: 1,
    width: 'auto',
    display: 'flex',
    maxWidth: '100%',
    minWidth: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  positionRelative: {
    position: 'relative',
    flexGrow: 1,
    minHeight: 325,
    '@global': {
      td: {
        verticalAlign: 'middle',
        position: 'relative',
      },
    },
  },
  recommendedIcon: {
    width: '6px',
    height: '11px',
    lineHeight: '14px',
    fontSize: '15px',
    marginRight: '2px',
  },
  requiredIcon: {
    width: '6px',
    height: '11px',
    lineHeight: '14px',
    fontSize: '15px',
    marginRight: '2px',
  },
  tooltipIcon: {
    height: 12,
    display: 'inline-block',
  },
  fs1em: { fontSize: '1em' },
  fs12: { fontSize: 12 },
  iconWarning: {
    fill: 'orange',
    width: '1em',
    height: '1em',
    marginRight: 4,
  },
  allEdit: {
    display: 'flex',
    alignItems: 'center',
  },
  emptyTD: {
    opacity: 0.7,
    backgroundColor: errorColor,
    textAlign: 'center',
  },
  tableHeaderLabel: {
    color: theme.palette.primary.main,
    '& span:hover': {
      color: theme.palette.primary.dark,
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  relativeWrapper: {
    position: 'relative',
  },
  formControl: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    flexDirection: 'row',
    '& label': {
      color: COLOR.grey['54'],
    },
  },
  formControlInfoText: {
    marginLeft: 0,
    marginTop: '10px',
  },
  formControlHelperText: {
    display: 'none',
    marginTop: 2,
    marginBottom: 0,
  },
  formControlCheckChanged: {
    color: changeColor,
  },
  formControlHelperTextChange: {
    display: 'block',
    color: changeColor,
    '& label': {
      color: changeColor,
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottom: `2px solid ${changeColor}`,
    },
  },
  errorTextColor: {
    display: 'block',
    color: errorColor,
  },
  formControlRecommended: {
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    '& label': {
      color: COLOR.grey['54'],
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
  changedFormControl: {
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottom: `2px solid ${changeColor}`,
    },
    '& label': {
      color: changeColor,
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${changeColor}`,
    },
    '& fieldset, fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: changeColor,
    },
  },
  errorFormControl: {
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottom: `2px solid ${errorColor}`,
    },
    '& label': {
      color: errorColor,
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${errorColor}`,
    },
    '& fieldset, fieldset.MuiOutlinedInput-notchedOutline': {
      padding: '10px',
      borderColor: errorColor,
    },
  },
  formControlCheck: {
    float: 'right',
    width: '32px',
    height: '32px',
  },
  formControlCheckText: {
    marginTop: 4,
  },
  formControlCheckSelect: {
    marginTop: 4,
  },
  formControlCheckBrand: {
    marginTop: 4,
  },
  formControlCheckTextArea: {
    marginTop: 4,
  },
  formControlCheckFieldSet: {
    marginTop: 4,
  },
  formControlFieldSet: {
    border: '1px solid #949494',
    borderRadius: 3,
    '& .formControlCheck': {
      marginTop: 4,
    },
  },
  formControlCheckFieldSetChange: {
    borderColor: changeColor,
  },
  formControlFieldLegend: {
    fontSize: 12,
    width: 'auto',
    marginBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
  },
  formControlFieldLegendChange: {
    color: '#ff8f00 !important',
  },
  buttonWrapper: {
    '@global': {
      button: {
        marginTop: 4,
        marginBottom: 4,
        marginRight: 12,
      },
    },
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    padding: 0,
  },
  fullHeight: {
    height: '100%',
  },
  tablePaper: {
    height: 300,
  },
  brandLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    width: '100%',
  },
  skuPrefix: {
    color: COLOR.grey['38'],
    fontSize: '14px',
  },
  dateFormControl: {
    '& label': {
      backgroundColor: 'white',
      paddingRight: 4,
    },
  },
  tabsNavContentProductEdit: {
    borderRight: 'none',
  },
  defaultValueHelpText: {
    fontSize: '12px',
    lineHeight: '12px',
    margin: '10px 0 0',
    color: `${COLOR.grey['54']}`,
    display: 'flex',
  },
  defaultValueHelp: {
    marginLeft: '10px',
    color: `${COLOR.grey.main}`,
  },
});

export default ContentProductEditStyles;
