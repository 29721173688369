// core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// UI
import AddCircleIcon from '@mui/icons-material/AddCircle';

// parts
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';

// hooks
import { useAccessList } from '../../../../hooks/useAccessList';

// config
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';

function AddStopWordButton() {
  const { t } = useTranslation();
  const hasAccessCreate = useAccessList(accessList.stopWordsCreate);

  if (!hasAccessCreate) {
    return null;
  }

  return (
    <Link to={pageLinks.stopWords.add}>
      <ButtonWithIcon text={t('Добавить')} sx={{ p: BUTTON_SIZE.withIconSmall, width: '100%' }}>
        <AddCircleIcon />
      </ButtonWithIcon>
    </Link>
  );
}

export default memo(AddStopWordButton);
