
export const tableColumns = Object.freeze({
  id: Object.freeze({
    name: 'id',
    title: 'ID категории',
  }),
  idMagentoCategory: Object.freeze({
    name: 'idMagentoCategory',
    title: 'M-ID категории',
  }),
  idMagentoCategoryParent: Object.freeze({
    name: 'idMagentoCategoryParent',
    title: 'M-ID родительской категории',
  }),
  name: Object.freeze({
    name: 'name',
    title: 'Название категории',
  }),
  parentId: Object.freeze({
    name: 'parentId',
    title: 'ID родительской категории',
  }),
  parentName: Object.freeze({
    name: 'parentName',
    title: 'Название родительской категории',
  }),
  fillingPercent: Object.freeze({
    name: 'fillingPercent',
    title: 'Процент заполненности категории',
  }),
});
