import { getIn, List } from 'immutable';
// Store keys
import { stateKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const productFeedParameters = Object.freeze({
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.productFeedParametersList, stateKeys.settingPending], false),
});
