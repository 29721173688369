// Parts
import { asyncTypes } from './asyncTypes';
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const billingAsyncAction = Object.freeze({
  getBillingStatusAsync() {
    return getActionCreator(asyncTypes.GET_BILLING_STATUS_ASYNC);
  },
  getBillingReportDownloadAsync(parameters) {
    return getActionCreator(asyncTypes.GET_BILLING_REPORT_DOWNLOAD_ASYNC, parameters);
  },
  getBillingInvoiceTypesAsync(context) {
    return getActionCreator(asyncTypes.GET_BILLING_INVOICE_TYPES_ASYNC, context);
  },
  postBillingPaymentAsync(payment) {
    return getActionCreator(asyncTypes.POST_BILLING_PAYMENT_ASYNC, payment);
  },
  getBillingStatusesListAsync() {
    return getActionCreator(asyncTypes.GET_BILLING_STATUSES_LIST_ASYNC);
  },
  getBillingPaymentTypesAsync() {
    return getActionCreator(asyncTypes.GET_BILLING_PAYMENT_TYPES_ASYNC);
  },
  getBillingOrdersDashboardDataAsync(parameters) {
    return getActionCreator(asyncTypes.GET_BILLING_ORDERS_DASHBOARD_DATA_ASYNC, parameters);
  },
  getBillingOnTimeOrdersDashboardDataAsync(parameters) {
    return getActionCreator(asyncTypes.GET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA_ASYNC, parameters);
  },
  getBillingNewOrdersDashboardDataAsync(parameters) {
    return getActionCreator(asyncTypes.GET_BILLING_NEW_ORDERS_DASHBOARD_DATA_ASYNC, parameters);
  },
  getBillingDashboardLastTransactionsAsync() {
    return getActionCreator(asyncTypes.GET_BILLING_DASHBOARD_LAST_TRANSACTIONS_ASYNC);
  },
  getBillingSuccessDeliveryDashboardAsync(parameters) {
    return getActionCreator(asyncTypes.GET_BILLING_SUCCESS_DELIVERY_DASHBOARD_ASYNC, parameters);
  },
  getDashboardRatingAsync() {
    return getActionCreator(asyncTypes.GET_DASHBOARD_RATING_ASYNC);
  },
  getBillingPartnersDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_BILLING_PARTNERS_DOWNLOAD_ASYNC, params);
  },
});
