// Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Map } from 'immutable';
import { push } from 'redux-first-history';
// Containers
import Modal from '../../../../../containers/Modals/Modal/Modal';
import Select from '../../../../../components/_Form/Selects/Select/Select';
// Engine
import { pageLinks } from '../../../../../../engine/config/routes';
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../engine/core/contentProduct/saga/asyncActions';
import { asyncActions as contentAsyncActions } from '../../../../../../engine/core/content/saga/asyncActions';
import { actions as contentProductActions } from '../../../../../../engine/core/contentProduct/actions';
import { accessList } from '../../../../../../engine/config/access';
import { userRoles } from '../../../../../../engine/config/userRoles';
// Hooks
import { useUserRole } from '../../../../../hooks/useUserRole';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { GUIDE_TYPES } from '../../../../../containers/App/components/GuideTour/types';

const ModalWithCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessToCreate = useAccessList(accessList.productCreate);
  const isPartnerRoleDisabled = useUserRole(userRoles.partnerDisabled);
  const productGridLoading = useSelector(selectors.content.productGridLoading);
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList).filter(
    item => item.value !== null,
  );
  const isModalOpen = useSelector(selectors.contentProduct.isOpenModalWidthCategory);
  const isLoading = useSelector(selectors.contentProduct.isCategoriesListLoading);
  const selected = useSelector(selectors.contentProduct.modalWidthCategoryValue);
  const joyrideSteps = useSelector(selectors.ui.joyrideSteps);
  const setValue = (data) => {
    dispatch(contentProductActions.setCategoryValueModalWidthCategory(data));
  };

  const onSend = () => {
    dispatch(contentAsyncActions.getProductsSettingsAsync({
      categoryId: selected.value,
      onSuccess: () => {
        dispatch(push(pageLinks.productsAndPrices.content.addByCategoryId(selected.value)));
        dispatch(contentProductActions.setActiveProductCategory(selected));
      },
    }));
  };
  const handleModalCancel = () => {
    dispatch(contentProductActions.setChangeModalWidthCategoryOpen(false));
  };
  const handleModalToggle = () => {
    dispatch(contentProductActions.setChangeModalWidthCategoryOpen(!isModalOpen));
  };
  const categoryInput = {
    value: selected,
    onChange: setValue,
  };

  useEffect(() => {
    dispatch(asyncActions.getContentCategoriesListAsync({ context: 'select' }));
  }, []);

  useEffect(() => () => {
    handleModalCancel();
    setValue(Map());
  }, []);

  if (!hasAccessToCreate && !isPartnerRoleDisabled) {
    return null;
  }

  return (
    <Modal
      title={t('Выберите категорию')}
      modalOpen={isModalOpen}
      handleModalSend={onSend}
      sendText={t('Применить')}
      disabledSend={!selected.value}
      loadingSend={productGridLoading}
      handleModalCancel={handleModalCancel}
      handleModalToggle={handleModalToggle}
      modalMaxWidth={joyrideSteps === GUIDE_TYPES.PRODUCTS_CONTENT ? 'xs' : 'sm'}
      fullScreen={false}
      idModalContent="product_category_modal"
    >
      <Select
        input={categoryInput}
        options={categoriesList}
        isLoading={isLoading}
        label={t('Выберите категорию')}
        searchErrorLabel={t('Неверно введена категория')}
        variant="outlined"
        placeholder=""
        hasSearch
      />
    </Modal>
  );
};

export default ModalWithCategories;
