// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ServiceDeskTable from './components/ServiceDeskTable';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import { selectors } from '../../../engine/config/selectors';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';

// Engine
import { asyncActions } from '../../../engine/core/serviceDesk/saga/asyncActions';
import { actions } from '../../../engine/core/serviceDesk/actions';
import { initialState, stateKeys } from '../../../engine/core/serviceDesk/reducers';
// Hooks
import { useServiceDeskList } from './_hooks/useServiceDeskList';
import { accessList } from '../../../engine/config/access';
import { useAccessList } from '../../hooks/useAccessList';
import { uiActions } from '../../../engine/core/ui/action';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';

function ServiceDeskPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.serviceDesk.settingStatus);
  const settingPending = useSelector(selectors.serviceDesk.settingPending);
  const isLoading = useSelector(selectors.serviceDesk.isLoading);
  const filters = useSelector(selectors.serviceDesk.filters);
  const sorting = useSelector(selectors.serviceDesk.sorting);
  const hasSupportMessageCreate = useAccessList(accessList.supportMessageCreate);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const handleSupportPopupOpen = () => {
    dispatch(uiActions.mergeServiceSupportModal({ isOpen: true }));
  };
  // Hooks
  useServiceDeskList();

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.list).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.list).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.list).get(stateKeys.currentPage)));
  }, [dispatch]);


  return (
    <>
      <PageHeader title={t('Поддержка Алло')}>
        {hasSupportMessageCreate && (
          <ButtonWithIcon text={t('Задать вопрос')} onClick={handleSupportPopupOpen}>
            <QuestionAnswerIcon />
          </ButtonWithIcon>
        )}
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={() => dispatch(asyncActions.putSettingsAsync())}
        />
        <ResetFiltersButton pending={isLoading} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <ServiceDeskTable />
    </>
  );
}

export default ServiceDeskPage;
