// Core
import {
  useState, useCallback, memo,
} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { isImmutable, List } from 'immutable';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
// Parts
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip/Tooltip';
// Icon
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Helpers
import { useStyles } from '../../../../hooks/useStyles';
import { FormSelectStyle } from './FormSelectStyle';
import { COMPONENTS_TYPES } from '../../../../containers/App/AppStyles';
import Select from '../Select/Select';

function FormSelect(props) {
  const {
    disabled, approve, hidden, isLoading, margin,
    className, helperTextBefore, variant, label, formSelectWidth, size, hasSearch: isSearchableProps,
    value, onChange, handleChange, fullWidth, meta, valueOld, options,
    wrapperClassName, approvedValue, onApproveValue, input, selectFirst, sx, color,
    required, isRecommended, isChanged, isPortal, closeMenuOnSelect,
    closeMenuOnScroll, focusOn, isSelectOpen, customOnBlur, dataTestId,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(FormSelectStyle);
  const [compare, setCompare] = useState(false);
  const getValue = get(input, 'value', value);
  const normalizeValue = isImmutable(getValue) ? getValue.toJS() : getValue;
  const inputValue = get(normalizeValue, 'value', normalizeValue);
  const hasSearch = isSearchableProps && (options.length > 10 || options.size > 10);
  const isApprove = useCallback(data => approvedValue === data, [approvedValue]);


  const handleCompare = () => {
    setCompare(!compare);
  };

  const handleApproveValue = approveValue => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value: approveValue });
    }
  };

  const onChangeValue = (data) => {
    const dataValue = typeof data === 'object' ? data.value : data;
    if (handleChange) {
      handleChange(dataValue);
    }
    if (onChange) {
      onChange(dataValue);
    }
    if (input?.onChange) {
      input.onChange(dataValue);
    }
  };

  const currentIdSelect = typeof label === 'object' ? 'select-label' : `select-${label}`;
  const oldValueSelect = isNil(valueOld) ? '' : valueOld;

  return (
    <Box
      component="div"
      sx={sx}
      className={cx(classes.wrapper, {
        [wrapperClassName]: wrapperClassName,
        [classes.hidden]: hidden,
        [classes.wrapperFullWidth]: fullWidth,
      })}
    >
      <FormControl
        size={size}
        disabled={compare ? true : disabled}
        variant={variant}
        margin={margin}
        className={`${classes.formSelect} ${className}`}
        style={{ width: formSelectWidth }}
        fullWidth={fullWidth}
      >
        {helperTextBefore && (
          <FormHelperText className={classes.helperText}>
            {helperTextBefore}{required && <span className={classes.helperTextRequired}>*</span>}
          </FormHelperText>
        )}
        <div className={classes.formSelectContent}>
          <div className={classes.formSelectWrapper}>
            <Select
              closeMenuOnScroll={closeMenuOnScroll}
              isPortal={isPortal}
              id={currentIdSelect}
              value={compare ? oldValueSelect : inputValue}
              handleChange={onChangeValue}
              filterValueBy="value"
              options={options}
              label={label}
              isRecommended={isRecommended}
              required={required}
              isChanged={isChanged}
              hasHelperTextBefore={!!helperTextBefore}
              placeholder=""
              isLoading={isLoading}
              variant="outlined"
              disabled={disabled || compare}
              hasSearch={hasSearch}
              color={color}
              selectFirst={selectFirst}
              meta={meta}
              closeMenuOnSelect={closeMenuOnSelect}
              focusOn={focusOn}
              isSelectOpen={isSelectOpen}
              customOnBlur={customOnBlur}
              dataTestId={dataTestId}
            />
          </div>
          {(valueOld !== undefined && approve) && (
            <div className={classes.wrapperButton}>
              <Tooltip title={t('Показать прошлые данные')} placement="top">
                <IconButton
                  className={cx(classes.button, classes.compareButton, {
                    [classes.compareButtonActive]: compare,
                  })}
                  onClick={handleCompare}
                  aria-label={t('Показать прошлые данные')}
                  size="large"
                >
                  <FlipOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Подтвердить')} placement="top">
                <IconButton
                  className={cx(classes.button, classes.checkButton, {
                    [classes.checkButtonActive]: isApprove('current'),
                  })}
                  onClick={handleApproveValue('current')}
                  aria-label={t('Подтвердить')}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Отклонить')} placement="top">
                <IconButton
                  className={cx(classes.button, classes.cancelButton, {
                    [classes.cancelButtonActive]: isApprove('old'),
                  })}
                  onClick={handleApproveValue('old')}
                  aria-label={t('Отклонить')}
                  size="large"
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </FormControl>
    </Box>
  );
}

FormSelect.propTypes = {
  disabled: PropTypes.bool,
  approve: PropTypes.bool,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  helperTextBefore: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formSelectWidth: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
  handleChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  meta: PropTypes.object,
  valueOld: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.object]),
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.array,
  ]),
  wrapperClassName: PropTypes.string,
  approvedValue: PropTypes.oneOf(['current', 'old']),
  onApproveValue: PropTypes.func,
  input: PropTypes.object,
  sx: PropTypes.object,
  selectFirst: PropTypes.bool,
  color: PropTypes.oneOf([COMPONENTS_TYPES.SELECT.secondary, COMPONENTS_TYPES.SELECT.dark]),
  hasSearch: PropTypes.bool,
  required: PropTypes.bool,
  isRecommended: PropTypes.bool,
  isChanged: PropTypes.bool,
  isPortal: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  focusOn: PropTypes.string,
  isSelectOpen: PropTypes.bool,
  customOnBlur: PropTypes.func,
  dataTestId: PropTypes.string,
};

FormSelect.defaultProps = {
  options: [{}],
  input: {},
  variant: 'outlined',
  fullWidth: false,
  color: 'secondary',
  isLoading: false,
  hasSearch: false,
  size: 'small',
  isSelectOpen: false,
};

export default memo(FormSelect);
