// Core
import { apply, put } from 'redux-saga/effects';
import get from 'lodash/get';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutUpdateValuesFromMagento({ payload: featureId }) {
  const currentFeatureId = get(featureId, 'featureId');
  yield put(actions.setIsContentUpdateValuesFromMagentoPending(true));
  yield put(actions.setIsContentUpdateValuesFromMagentoFeature(currentFeatureId));

  const response = yield apply(api, api.products.putUpdateValuesFromMagento, [featureId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      default: {
        break;
      }
    }
  }

  yield put(actions.setIsContentUpdateValuesFromMagentoPending(false));
  yield put(actions.setIsContentUpdateValuesFromMagentoFeature(null));
}
