// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import cx from 'classnames';
// UI
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// Parts
import DataRangeModal from '../DateRange/DataRangeModal';
// Style
import TableFilterStyle from './TableFilter/TableFilterStyle';
// _helpers
import { getDate } from '../../../_helpers/getDate';
// Hooks
import { useDefferCall } from '../../hooks/useDefferCall';
import { useStyles } from '../../hooks/useStyles';


function DateRangeFilter(props) {
  const {
    clearButton, fullWidth, filters, disabled,
    onChange, range, type, variant, className,
    columnName,
  } = props;
  const classes = useStyles(TableFilterStyle);
  const { t } = useTranslation();
  const [showRangeModal, setShowRangeModal] = useState(false);
  const monthPickerLang = [
    t('Янв'), t('Фев'), t('Мар'),
    t('Апр'), t('Май'), t('Июн'),
    t('Июл'), t('Авг'), t('Сен'),
    t('Окт'), t('Ноя'), t('Дек'),
  ];

  const { value, setValue } = useDefferCall({
    callback: onChange,
    initialValue: filters,
  }, 1000);

  const handleDataRangeToggle = () => {
    if (disabled) {
      return;
    }
    setShowRangeModal(!showRangeModal);
  };

  const onDateRangeChange = (ranges) => {
    if (typeof setValue === 'function') {
      setValue(ranges);
    }
  };

  const computedValue = () => {
    const endDate = get(filters, 'value.endDate', new Date());
    const startDate = get(filters, 'value.startDate', new Date());

    const formattedStartDate = getDate(startDate, 'YYYY-MM-DD');
    const formattedEndDate = getDate(endDate, 'YYYY-MM-DD');

    switch (true) {
      case type === 'month': {
        const filtersValue = get(filters, 'value');
        if (filtersValue?.from?.month && filtersValue?.to?.month) {
          const from = `${monthPickerLang[filtersValue.from.month - 1]} ${filtersValue.from.year}`;
          const to = `${monthPickerLang[filtersValue.to.month - 1]} ${filtersValue.to.year}`;
          return from === to ? from : `${from} - ${to}`;
        }
        return '';
      }
      case !filters:
      case get(filters, 'value') === null: {
        return '';
      }
      case get(filters, 'value').empty === 'empty': {
        return t('Без даты');
      }
      case moment(formattedStartDate).isSame(formattedEndDate): {
        return getDate(startDate, 'DD.MM.YYYY');
      }
      default: {
        return `${getDate(startDate, 'DD.MM')} - ${getDate(endDate, 'DD.MM')}`;
      }
    }
  };

  const clearField = (e) => {
    e.stopPropagation();
    onDateRangeChange(null);
  };

  const endDate = get(value, 'value.endDate', new Date());
  const startDate = get(value, 'value.startDate', new Date());
  const inputValue = computedValue();
  const classNames = cx(classes.inputCalendarFilter, {
    [className]: className,
  });

  return (
    <>
      <TextField
        color="secondary"
        variant={variant}
        fullWidth={fullWidth}
        placeholder={t('Фильтр')}
        onClick={handleDataRangeToggle}
        disabled={disabled}
        className={classNames}
        InputProps={{
          value: inputValue,
          readOnly: true,
          startAdornment: (
            <InputAdornment
              className={
                variant === 'outlined'
                  ? classes.adornmentCalendarIconOutlined
                  : classes.adornmentCalendarIcon
              }
              position="start"
            >
              <span className={classes.calendarIcon}>
                <CalendarMonthOutlinedIcon />
              </span>
            </InputAdornment>
          ),
          endAdornment: clearButton && (inputValue !== '') && (
            <InputAdornment position="end">
              <IconButton
                className={classes.smallButton}
                aria-label="Clear filter"
                onClick={clearField}
                component="span"
                size="large"
              >
                <ClearIcon className={classes.iconSmall} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showRangeModal && (
        <DataRangeModal
          disabled={disabled}
          title={type === 'month' ? t('Фильтр по месяцам') : t('Фильтр по дате')}
          open
          range={range}
          type={type}
          handleDataRangeToggle={handleDataRangeToggle}
          onDateRangeChange={onDateRangeChange}
          filters={filters}
          startDate={startDate}
          endDate={endDate}
          columnName={columnName}
        />
      )}
    </>
  );
}

DateRangeFilter.propTypes = {
  clearButton: PropTypes.bool,
  filters: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  range: PropTypes.bool,
  type: PropTypes.oneOf(['day', 'month']),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  columnName: PropTypes.string,
};

DateRangeFilter.defaultProps = {
  clearButton: true,
  filters: {},
  fullWidth: true,
  type: 'day',
  disabled: false,
  range: true,
};

export default memo(DateRangeFilter);
