// Core
import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
// Helpers
import getParentCheckedRecursive from '../_helpers/getParentCheckedRecursive';

const ValueContainerTree = (props) => {
  const { selectProps, children } = props;
  const {
    checkboxesState, classes, placeholder, textFieldProps,
  } = selectProps;
  const { variant } = textFieldProps;
  const { t } = useTranslation();
  const input = children[children.length - 1];
  const isOutlined = variant === 'outlined';

  const selectedCategoriesArr = useMemo(() => (checkboxesState || []).map(item => getParentCheckedRecursive(item, checkboxesState)),
    [checkboxesState]);
  const uniqueSelectedCategories = uniqBy(selectedCategoriesArr, 'value');
  const itemSize = uniqueSelectedCategories.length;
  const firstItem = uniqueSelectedCategories?.[0]?.label;

  const selectedLabel = useMemo(() => {
    const valueLength = itemSize;
    if (valueLength === 0) {
      return (
        <span className="tree-placeholder">{placeholder}</span>
      );
    }
    if (valueLength === 1) {
      return firstItem;
    }
    if (valueLength > 1) {
      return t('Выбрано');
    }
    return '';
  }, [t, itemSize, firstItem]);

  const labelClassName = cx('value-tree-label', classes.valueTreeLabel, {
    [classes.valueTreeLabelOutlined]: isOutlined,
  });

  return (
    <div className={classes.valueTreeContainer}>
      <p className={labelClassName}>{selectedLabel || children}</p>
      {input}
    </div>
  );
};

export default ValueContainerTree;
