// Core
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { push } from 'redux-first-history';
import { Map } from 'immutable';
import regexifyString from 'regexify-string';
// Colors
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
// Parts
import NumberFormat from 'react-number-format';
import Grid from '@mui/material/Grid';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageHeader/PageHeader';
import StatCard from '../../../components/Widgets/StatCard/StatCard';
import CountCard from '../../../components/Widgets/CountCard/CountCard';
import ReconciliationActButton from '../Reports/components/ReconciliationAct/ReconciliationActButton';
import OrdersPie from './components/OrdersPie/OrdersPie';
import OrdersChart from './components/OrdersChart/OrdersChart';
import BillingPageHeader from './components/PageHeader/PageHeader';
import ShowAllLink from './components/ShowAllLink/ShowAllLink';
import LastChargesTable from './components/LastChargesTable/LastChargesTable';
import ButtonPaymentAdd from '../../../components/Buttons/ButtonPaymentAdd';
import NotificationText from '../../../components/Notifications/NotificationText';
import RatingStatus from './components/RatingStatus';
import OnTimeOrdersPie from './components/OnTimeOrdersPie/OnTimeOrdersPie';
import SuccessDeliveryPie from './components/SuccessDeliveryPie/SuccessDeliveryPie';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { billingAsyncAction } from '../../../../engine/core/billing/saga/asyncAction';
import { dateStringFormat } from '../../../../engine/config/globalConfig';
import { pageLinks } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';
import { billingActions as billingAction } from '../../../../engine/core/billing/action';
import { userRoles } from '../../../../engine/config/userRoles';
import { locale } from '../../../../engine/init/i18n';
import { localData } from '../../../../engine/config/localData';
// Helpers
import { getDate } from '../../../../_helpers/getDate';
import { useAccessList } from '../../../hooks/useAccessList';
import { useUserRole } from '../../../hooks/useUserRole';
import { useStyles } from '../../../hooks/useStyles';
import AccountStyle from './AccountStyle';
import { useCallbackAfterPostUser } from '../../../hooks/useCallbackAfterPostUser';
import ReconciliationActModal from '../Reports/components/ReconciliationAct/ReconciliationActModal';
// import {userActionAsync} from "../../../../engine/core/user/saga/asyncAction";

function Account(props) {
  const classes = useStyles(AccountStyle);
  const {
    statusItems: {
      balance, billingStatus, limitDateEnd,
      holdBalancesAmount, restOfBalanceSum,
      disabledPartnersQuantity, enabledPartnersQuantity,
      creditLimitPartnersQuantity,
      // positiveBalancesAmount,
      negativeBalancesAmount,
      holdBalance, summaryBalance, updatedAt,
    },
    statusInformers,
    ratingStatus,
    ratingStatusPending,
    redirectTo,
    setBillingSelectFilterPartners,
    statusPending,
    getDashboardRatingAsync,
  } = props;
  const { t, i18n } = useTranslation();
  const currentLocale = locale[i18n.language];
  const balanceInformer = statusInformers.get('balance');
  const formatLimitDateEnd = limitDateEnd ? `${t('Нужно пополнить до')}: ${getDate(limitDateEnd, dateStringFormat, currentLocale)}` : '';

  // Access
  const hasAccessDashboardLastTransactions = useAccessList(accessList.dashboardLastTransactions);
  const hasAccessDashboardOrdersStatusesStatistic = useAccessList(accessList.dashboardOrdersStatusesStatistic);
  const hasDashboardOrdersTimelyProcessed = useAccessList(accessList.dashboardOrdersTimelyProcessed);
  const hasAccessDashboardsPartnerRating = useAccessList(accessList.dashboardsPartnerRating);
  const hasAccessDashboardNewOrdersStatistic = useAccessList(accessList.dashboardNewOrdersStatistic);
  const hasAccessToBillingStatus = useAccessList(accessList.billingStatus);
  const hasAccessDashboardOrdersTimelyDelivered = useAccessList(accessList.dashboardOrdersTimelyDelivered);
  const hasAccessBillingReconciliationActDownload = useAccessList(accessList.billingReconciliationActDownload);
  const hasAccessToBillingPaymentForPartner = useAccessList(accessList.billingPaymentForPartner);
  // Role
  const isPartnerRole = useUserRole(userRoles.partner);
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);
  const isPartnerBillingRole = useUserRole(userRoles.partnerBilling);

  const dashboardGridProps = useMemo(() => {
    const gridProps = { xs: 12, sm: 6 };
    return (hasAccessDashboardLastTransactions || hasAccessDashboardNewOrdersStatistic)
      ? gridProps
      : { ...gridProps, sm: 12 };
  }, [hasAccessDashboardLastTransactions, hasAccessDashboardNewOrdersStatistic]);

  const dashboardRatingGridProps = useMemo(() => {
    const gridProps = {
      xs: 12,
      sm: 4,
      sx: {
        display: 'flex',
        flexDirection: 'column',
      },
    };
    return (hasAccessDashboardOrdersStatusesStatistic
      && hasDashboardOrdersTimelyProcessed
      && hasAccessDashboardOrdersTimelyDelivered)
      ? gridProps
      : { ...gridProps, xs: 12, sm: 6 };
  }, [hasAccessDashboardOrdersStatusesStatistic, hasDashboardOrdersTimelyProcessed, hasAccessDashboardOrdersTimelyDelivered]);

  useCallbackAfterPostUser(() => {
    if (hasAccessDashboardsPartnerRating) {
      getDashboardRatingAsync();
    }
  }, []);
  useEffect(() => {
    if (hasAccessDashboardsPartnerRating) {
      getDashboardRatingAsync();
    }
  }, [hasAccessDashboardsPartnerRating, getDashboardRatingAsync]);

  const handleLinkToPartner = (filter) => {
    localData.removeItem(pageLinks.billing.partners, () => {
      setBillingSelectFilterPartners(filter);
      redirectTo(pageLinks.billing.partners);
    });
  };
  if (!hasAccessToBillingStatus) {
    return (
      <Navigate to={pageLinks.billing.partners} />
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <PageHeader title={t('Баланс')}>
            <ReconciliationActButton />
            {!hasAccessToBillingPaymentForPartner && <ButtonPaymentAdd />}
          </PageHeader>
        </Grid>
      </Grid>
      <Grid container className={classes.row}>
        {balanceInformer && balanceInformer.map((item, index) => (
          <NotificationText key={index} title={item.title} type={item.type} width="100%">
            {regexifyString({
              pattern: /#billingPaymentModal/i,
              decorator: () => <ButtonPaymentAdd type="link" />,
              input: item.description,
            })}
          </NotificationText>
        ))}
        {statusPending
          ? (
            <Grid className={classes.statCardLoadingContainer} item>
              <Loading isLoading />
            </Grid>
          ) : (
            <Grid
              className={classes.mb2}
              container
              spacing={2}
              alignItems="stretch"
            >
              {!isUndefined(restOfBalanceSum) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={restOfBalanceSum <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  valueDesc={t('Остаток баланса')}
                  value={(
                    <NumberFormat
                      value={restOfBalanceSum}
                      thousandSeparator=" "
                      displayType="text"
                      suffix={t(' ₴')}
                    />
                    )}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                  onClick={() => handleLinkToPartner('restOfBalanceSum')}
                />
              </Grid>
              )}
              {!isUndefined(negativeBalancesAmount) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={negativeBalancesAmount <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  valueDesc={t('Дебиторка')}
                  value={(
                    <NumberFormat
                      value={negativeBalancesAmount}
                      thousandSeparator=" "
                      displayType="text"
                      suffix={t(' ₴')}
                    />
                    )}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                  onClick={() => handleLinkToPartner('negativeBalancesAmount')}
                />
              </Grid>
              )}
              {!isUndefined(holdBalancesAmount) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={holdBalancesAmount <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  valueDesc={t('Холдирование')}
                  value={(
                    <NumberFormat
                      value={holdBalancesAmount}
                      thousandSeparator=" "
                      displayType="text"
                      suffix={t(' ₴')}
                    />
                    )}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                  onClick={() => handleLinkToPartner('holdBalancesAmount')}
                />
              </Grid>
              )}  {!isUndefined(balance)
            && (
              <Grid item xs={12} sm={6} lg={4}>
                <StatCard
                  coloredValue={balance <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  title={`${t('Дата обновления')}: ${getDate(updatedAt, dateStringFormat, currentLocale)}`}
                />
              </Grid>
            )}
              {!isUndefined(balance) && !isPartnerRole && !isPartnerActiveRole && !isPartnerBillingRole
              && (
                <Grid item xs={12} sm={6} lg={4}>
                  <CountCard
                    coloredValue={balance <= 0 ? red[500] : green[500]}
                    status={billingStatus}
                    valueDesc={t('Баланс')}
                    value={(
                      <NumberFormat
                        value={balance}
                        thousandSeparator=" "
                        displayType="text"
                        suffix={t(' ₴')}
                      />
                    )}
                    title={formatLimitDateEnd && (formatLimitDateEnd)}
                  />
                </Grid>
              )}
              {!isUndefined(holdBalance) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={holdBalance <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  valueDesc={t('Холд')}
                  value={(
                    <NumberFormat
                      value={holdBalance}
                      thousandSeparator=" "
                      displayType="text"
                      suffix={t(' ₴')}
                    />
                    )}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                />
              </Grid>
              )}

              {!isUndefined(summaryBalance) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={summaryBalance <= 0 ? red[500] : green[500]}
                  status={billingStatus}
                  valueDesc={t('Остаток Баланса')}
                  value={(
                    <NumberFormat
                      value={summaryBalance}
                      thousandSeparator=" "
                      displayType="text"
                      suffix={t(' ₴')}
                    />
                    )}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                />
              </Grid>
              )}
              {!isUndefined(enabledPartnersQuantity) && (
              <Grid item xs={12} sm={6} lg={4}>
                <CountCard
                  coloredValue={green[500]}
                  status={billingStatus}
                  valueDesc={t('Активные партнеры')}
                  value={<NumberFormat value={enabledPartnersQuantity} thousandSeparator=" " displayType="text" />}
                  title={formatLimitDateEnd && (formatLimitDateEnd)}
                  onClick={() => handleLinkToPartner('enabledPartnersQuantity')}
                />
              </Grid>
              )}
              {!isUndefined(disabledPartnersQuantity) && (
                <Grid item xs={12} sm={6} lg>
                  <CountCard
                    coloredValue={red[500]}
                    status={billingStatus}
                    valueDesc={t('Заблокированные партнеры')}
                    value={<NumberFormat value={disabledPartnersQuantity} thousandSeparator=" " displayType="text" />}
                    title={formatLimitDateEnd && (formatLimitDateEnd)}
                    onClick={() => handleLinkToPartner('disabledPartnersQuantity')}
                  />
                </Grid>
              )}
              {!isUndefined(creditLimitPartnersQuantity) && (
                <Grid item xs={12} sm={6} lg={4}>
                  <CountCard
                    coloredValue={creditLimitPartnersQuantity > 0 && red[500]}
                    valueDesc={t('Партнеры в кредитном лимите')}
                    value={<NumberFormat value={creditLimitPartnersQuantity} thousandSeparator=" " displayType="text" />}
                    onClick={() => handleLinkToPartner('creditLimitPartnersQuantity')}
                  />
                </Grid>
              )}
              {/* {!isUndefined(positiveBalancesAmount) && ( */}
              {/*  <Grid item xs={12} sm={6} lg={4}> */}
              {/*    <CountCard */}
              {/*      coloredValue={positiveBalancesAmount <= 0 ? red[500] : green[500]} */}
              {/*      status={billingStatus} */}
              {/*      valueDesc={t('Баланс')} */}
              {/*      value={( */}
              {/*        <NumberFormat */}
              {/*          value={positiveBalancesAmount} */}
              {/*          thousandSeparator=" " */}
              {/*          displayType="text" */}
              {/*          suffix={t(' грн')} */}
              {/*        /> */}
              {/*      )} */}
              {/*      title={formatLimitDateEnd && (formatLimitDateEnd)} */}
              {/*      onClick={() => handleLinkToPartner('positiveBalancesAmount')} */}
              {/*    /> */}
              {/*  </Grid> */}
              {/* )} */}
            </Grid>
          )
      }
      </Grid>

      <>
        {hasAccessDashboardsPartnerRating && (
        <Grid container>
          <Grid item xs={12}>
            <PageHeader title={t('Рейтинг')} />
          </Grid>
        </Grid>
        )}
        {hasAccessDashboardsPartnerRating && (
        <Grid container className={classes.row}>
          {ratingStatusPending
            ? (
              <Grid className={classes.statCardLoadingContainer} item>
                <Loading isLoading />
              </Grid>
            ) : (
              <Grid
                container
                spacing={2}
              >
                {(hasAccessDashboardsPartnerRating && !isUndefined(ratingStatus)) && <RatingStatus />}
              </Grid>
            )
          }
        </Grid>
        )}
        {(hasAccessDashboardOrdersStatusesStatistic || hasAccessDashboardsPartnerRating || hasDashboardOrdersTimelyProcessed) && (
        <Grid container>
          {hasAccessDashboardOrdersStatusesStatistic && (
          <Grid item {...dashboardRatingGridProps}>
            <Grid alignItems="end" container className={classes.row}>
              <BillingPageHeader classNames={classes.pieTitle}>{t('Выполненные заказы')}</BillingPageHeader>
            </Grid>
            <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
              <OrdersPie dataFieldClassName={classes.dataRange} />
              <ShowAllLink to={pageLinks.orders.all} />
            </Grid>
          </Grid>
          )}
          {hasDashboardOrdersTimelyProcessed && (
          <Grid item {...dashboardRatingGridProps}>
            <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
              <BillingPageHeader classNames={classes.pieTitle}>{t('Вовремя обработанные заказы')}</BillingPageHeader>
            </Grid>
            <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
              <OnTimeOrdersPie dataFieldClassName={classes.dataRange} />
            </Grid>
          </Grid>
          )}
          {hasAccessDashboardOrdersTimelyDelivered && (
          <Grid item {...dashboardRatingGridProps}>
            <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
              <BillingPageHeader classNames={classes.pieTitle}>{t('Успешно доставленные заказы')}</BillingPageHeader>
            </Grid>
            <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
              <SuccessDeliveryPie dataFieldClassName={classes.dataRange} />
            </Grid>
          </Grid>
          )}
        </Grid>
        )}
      </>


      {(hasAccessDashboardLastTransactions || hasAccessDashboardNewOrdersStatistic) && (
      <Grid container>
        {hasAccessDashboardNewOrdersStatistic && (
        <Grid item {...dashboardGridProps}>
          <Grid alignItems="center" container className={classes.row}>
            <BillingPageHeader>{t('Динамика заказов')}</BillingPageHeader>
          </Grid>
          <Grid alignItems="center" container className={classes.row}>
            <OrdersChart dataFieldClassName={classes.dataRange} />
          </Grid>
        </Grid>
        )}

        {hasAccessDashboardLastTransactions && (
        <Grid item {...dashboardGridProps}>
          <Grid alignItems="center" container className={classes.row} justifyContent="space-between">
            <BillingPageHeader>{t('Последние начисления')}</BillingPageHeader>
            <ShowAllLink to={pageLinks.billing.history} />
          </Grid>
          <Grid alignItems="center" container className={classes.row}>
            <LastChargesTable />
          </Grid>
        </Grid>
        )}
        {hasAccessBillingReconciliationActDownload && <ReconciliationActModal />}
      </Grid>
      )}
    </>
  );
}

Account.propTypes = {
  ratingStatus: PropTypes.string.isRequired,
  ratingStatusPending: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func.isRequired,
  getDashboardRatingAsync: PropTypes.func.isRequired,
  setBillingSelectFilterPartners: PropTypes.func.isRequired,
  statusPending: PropTypes.bool.isRequired,
  statusInformers: PropTypes.instanceOf(Map),
  statusItems: PropTypes.shape({
    creditLimitPartnersQuantity: PropTypes.number,
    disabledPartnersQuantity: PropTypes.number,
    enabledPartnersQuantity: PropTypes.number,
    summaryBalance: PropTypes.number,
    holdBalancesAmount: PropTypes.string,
    negativeBalancesAmount: PropTypes.string,
    restOfBalanceSum: PropTypes.string,
    updatedAt: PropTypes.string,
    holdBalance: PropTypes.string,
    billingStatus: PropTypes.number,
    balance: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    limitDateEnd: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};

// Redux
function mapStateToProps(state) {
  return {
    statusItems: selectors.billing.statusItems(state),
    statusInformers: selectors.billing.statusInformers(state),
    ratingStatus: selectors.billing.ratingStatus(state),
    ratingStatusPending: selectors.billing.ratingStatusPending(state),
    statusPending: selectors.billing.statusPending(state),
    downloadPaymentOrderPending: selectors.billing.downloadPaymentOrderPending(state),
    downloadPaymentStatus: selectors.billing.downloadPaymentStatus(state),
    billingSelectFilterPartners: selectors.billing.billingSelectFilterPartners(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    postBillingPaymentAsync: payment => dispatch(billingAsyncAction.postBillingPaymentAsync(payment)),
    getDashboardRatingAsync: () => dispatch(billingAsyncAction.getDashboardRatingAsync()),
    setBillingSelectFilterPartners: filter => dispatch(billingAction.setBillingSelectFilterPartners(filter)),
    redirectTo: url => dispatch(push(url)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Account);
