import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { COLOR } from '../../../../../containers/App/AppStyles';

const ContentProductEditImagesTabStyles = () => ({
  wrapRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,
  },
  disabled: {
    opacity: 0.5,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  clearButton: {
    cursor: 'pointer',
    color: COLOR.grey['72'],
    padding: 4,
    marginLeft: '4px',
    marginRight: '7px',
    borderRadius: '3px',
  },
  inputAdornment: {
    marginLeft: '0',
  },
  iconAddButton: {
    color: COLOR.grey['24'],
    '& svg': {
      fontSize: '20px',
    },
    '&.Mui-disabled': {
      backgroundColor: COLOR.grey['8'],
      color: COLOR.grey['24'],
    },
  },
  iconButtonAttach: {
    marginLeft: 4,
    padding: 4,
    borderRadius: 3,
    backgroundColor: COLOR.grey['8'],
    border: 'none',
    minWidth: 'auto',
    '&:hover': {
      border: 'none',
      backgroundColor: COLOR.grey['12'],
    },
    '& svg': {
      fontSize: '20px',
    },
    '&.Mui-disabled': {
      backgroundColor: COLOR.grey['8'],
      color: COLOR.grey['24'],
      border: 0,
      '&:hover': {
        backgroundColor: COLOR.grey['8'],
      },
    },
  },
  buttonActive: {
    color: COLOR.controls.dark,
  },
  listItemText: {
    color: COLOR.grey.main,
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '400',
  },
  responseCodeSuccess: {
    color: green[500],
  },
  responseCodeError: {
    color: red[500],
  },
  addedPhotoBtnControl: {
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    borderRadius: 3,
    background: '#ffffff',
    boxShadow: COLOR.shadow.block.main,
  },
  dragListHolder: {
    boxShadow: 'none',
  },
  dragListItem: {
    padding: 0,
    marginBottom: 20,
    border: 0,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  dragItemContent: {
    padding: '4px 26px 4px 12px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 67px)',
    width: '100%',
  },
  avatarHolderIcon: {
    minWidth: '40px',
    marginLeft: 9,
    marginRight: 6,
  },
  avatarIcon: {
    margin: 0,
    borderRadius: 0,
  },
  button: {
    width: 28,
    height: 28,
    borderRadius: 3,
    background: COLOR.grey['8'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: COLOR.grey['12'],
    },
  },
  copy: {
    '& svg': {
      color: COLOR.controls.dark,
      fontSize: '17px',
    },
  },
  remove: {
    color: COLOR.error,
    marginLeft: 4,
    '& svg': {
      fontSize: '21px',
    },
  },
  dragBtnGroup: {
    marginLeft: 3,
    padding: 2,
    borderRadius: 3,
    boxShadow: COLOR.shadow.block.main,
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
  },
});

export default ContentProductEditImagesTabStyles;
