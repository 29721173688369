// Core
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { replace } from 'redux-first-history';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  setAuthTokens, getAccessToken, getRefreshToken,
  applyAuthTokenInterceptor,
} from 'axios-jwt';
// Engines
import { toast } from 'react-toastify';
import { routersLink } from './routes';
import { userActionAsync } from '../core/user/saga/asyncAction';
import { store } from '../init/store';
// Helpers
import { convertJSObjectToUrlParams, convertDatetimeFieldToUrlParams } from '../../_helpers/convertDataToUrlParams';
import { setErrorMessage } from '../core/ui/saga/asyncActionNotificationMessages';
import i18n from '../init/i18n';

export const apiTime = Object.freeze({
  statistics: Object.freeze({
    getLists: 60000, // 1 min
    products: 60000 * 10, // 10 min
  }),
});
export const baseUrl = process.env.REACT_APP_API;
export const publicUrl = process.env.PUBLIC_URL;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const apiURL = {
  usersLogin: 'users/login',
  twoFactorLogin: 'two_factor_login',
  refreshTokenUrl: 'users/token_refresh',
  usersSelf: 'users/self',
};

export const userService = Object.freeze({
  userInfoKey: 'userInfo',
  getUserInfo() {
    const userInfoFromStore = localStorage.getItem(this.userInfoKey) || '{}';
    return JSON.parse(userInfoFromStore);
  },
  setUserInfo(userInfo = {}) {
    if (isEmpty(userInfo)) {
      const language = localStorage.getItem('language');
      localStorage.clear();
      localStorage.setItem('language', language);
    }
    const userInfoToStorage = JSON.stringify(userInfo);
    localStorage.setItem(this.userInfoKey, userInfoToStorage);
  },
});

const instance = axios.create({
  baseURL: `${baseUrl}/api/${apiVersion}/`,
  headers: { 'Content-Type': 'application/json' },
});
const requestRefresh = refresh => ({
  accessToken: getAccessToken(),
  refreshToken: refresh,
});
applyAuthTokenInterceptor(instance, { requestRefresh });

const refreshAuthLogic = failedRequest => instance.post(
  apiURL.refreshTokenUrl,
  { refreshToken: getRefreshToken() },
  { skipAuthRefresh: true },
).then((tokenRefreshResponse) => {
  switch (tokenRefreshResponse?.status) {
    case 200: {
      const token = tokenRefreshResponse?.data?.token;
      const refreshToken = tokenRefreshResponse?.data?.refreshToken;
      setAuthTokens({
        accessToken: token,
        refreshToken,
      });
      /* eslint-disable-next-line  */
      failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
      return Promise.resolve();
    }
    case 401: {
      const message = tokenRefreshResponse?.data?.message;
      const code = tokenRefreshResponse?.data?.code;
      toast.dismiss();
      store.dispatch(userActionAsync.logOutAsync(false));
      store.dispatch(setErrorMessage(message, code?.toString()));
      return Promise.reject();
    }
    default: {
      const onRefreshAndReopenBrowser = getRefreshToken() === undefined && failedRequest?.config?.url === apiURL.usersSelf;
      const changePage = getRefreshToken() === undefined && getAccessToken();
      if (onRefreshAndReopenBrowser || changePage) {
        toast.dismiss();
        store.dispatch(userActionAsync.logOutAsync(false));
        store.dispatch(setErrorMessage(i18n.t('Время сессии истекло'), '401'));
      }
      return Promise.reject();
    }
  }
});

createAuthRefreshInterceptor(instance, refreshAuthLogic, { skipWhileRefreshing: false });

instance.interceptors.response.use(
  response => response,
  (error) => {
    const errorResponse = error?.response;
    if (errorResponse) {
      const { data, status, statusText } = errorResponse;
      if (status >= 400) {
        if (status === 401) {
          const currentURL = errorResponse?.config?.url;
          if (
            currentURL === apiURL.usersLogin
            || currentURL === apiURL.twoFactorLogin
          ) {
            store.dispatch(setErrorMessage(data.message, status.toString()));
          }
        } else if (status === 404) {
          store.dispatch(replace(routersLink.errors.status404));
        } else if (status === 500) {
          store.dispatch(replace(routersLink.errors.status500));
        } else if (status !== 400) {
          store.dispatch(setErrorMessage(data?.message || statusText, status.toString()));
        }
      }
    }
    return errorResponse;
  },
);

instance.interceptors.request.use(
  (config) => {
    const extendedConfig = config;
    const token = getAccessToken();
    const refreshToken = getRefreshToken();
    if (config.url === apiURL.refreshTokenUrl && !refreshToken) {
      const controller = new AbortController();
      controller.abort();
      return {
        ...extendedConfig,
        signal: controller.signal,
      };
    }
    if (token) {
      extendedConfig.headers.Authorization = `Bearer ${token}`;
    } else if (Object.keys(userService.getUserInfo()).length !== 0) {
      toast.dismiss();
      store.dispatch(userActionAsync.logOutAsync(false));
      store.dispatch(setErrorMessage(i18n.t('Время сессии истекло'), '401'));
    }
    return extendedConfig;
  },
  error => Promise.reject(error),
);

export const api = Object.freeze({
  // Fetch
  address: {
    search({ search }) {
      return instance.get('address/search', { params: { search } });
    },
  },
  analytics: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('analytics/partners', { params });
    },
    export(args) {
      const filter = args.reduce((acc, current) => {
        // eslint-disable-next-line no-param-reassign
        acc = { ...acc, [current.columnName]: current.value };
        return acc;
      }, {});
      return instance.post('analytics/partners/export', { filter }, { responseType: 'arraybuffer' });
    },
  },
  configurations: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('configurations', { params });
    },
    getConfigurationsTypes() {
      return instance.get('configurations/types');
    },
    getItemByHashId(hashId) {
      return instance.get(`configurations/${hashId}`);
    },
    putConfigurationItemByHashId({ hashId, data }) {
      return instance.put(`configurations/${hashId}`, data);
    },
  },
  user: {
    logout(refreshToken) {
      return instance.post('users/logout', refreshToken);
    },
    signUp(userData) {
      return instance.post('users/signup', userData);
    },
    signIn(loginData) {
      return instance.post(apiURL.usersLogin, loginData, { skipAuthRefresh: true });
    },
    twoFactorLogin(loginData) {
      return instance.post(apiURL.twoFactorLogin, loginData, { skipAuthRefresh: true });
    },
    confirmCode(code) {
      return instance.post('users/check_code', code);
    },
    refreshToken(refreshToken) {
      return instance.post(apiURL.refreshTokenUrl, refreshToken);
    },
    getPartnerRoles(params) {
      return instance.get('users/partner_roles', { params });
    },
    getUserInfo() {
      return instance.get('users/self');
    },
    getMailSettings() {
      return instance.get('users/mail_settings');
    },
    putMailSettings(data) {
      return instance.put('users/mail_settings', data);
    },
    putUserInfo(data) {
      return instance.put('users/self', data);
    },
    postPhoneVerifyCode(code) {
      return instance.post('users/phone_verify_code', code);
    },
    repeatSendPhoneCode() {
      return instance.get('users/send_phone_code');
    },
    putUserSettings(data) {
      if (data.reset) {
        return instance.put(`users/settings?reset=${data.reset}`, {});
      }
      return instance.put('users/settings', data);
    },
    getUsersList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('users', { params });
    },
    postUsers({ userInfo }) {
      return instance.post('users', {
        fullName: userInfo.fullName,
        email: userInfo.email,
        password: userInfo.password,
        phone: userInfo.phone,
        viber: userInfo.viber,
        role: userInfo.role,
        partner: userInfo.partner,
        partnerRoles: userInfo.partnerRoles,
        locale: userInfo.locale,
        additionalEmails: userInfo.additionalEmails,
      });
    },
    getUsersRoles(params) {
      const context = params?.context;
      return instance.get('users/roles', { params: { context } });
    },
    getUsersDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        responseType: 'arraybuffer',
        method: 'get',
        url: 'users/download',
      });
    },
    getUsersHashId(hashId) {
      return instance.get(`users/${hashId}`);
    },
    putUsersHashId({ hashId, formData }) {
      return instance.put(`users/${hashId}`, formData);
    },
    deleteUsers({ userIds }) {
      return instance({
        method: 'delete',
        url: 'users',
        data: { userIds },
      });
    },
  },
  partners: {
    getList(args = {}) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners', { params });
    },
    postParnersTopImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { partnersTopImportFile } = args;
      formData.append('partnersTopImportFile', partnersTopImportFile[0]);
      return instance({
        url: 'partners/import_tops',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
    getPartnersTopExport({ partners, ...filters }) {
      return instance({
        params: {
          partners,
          ...convertJSObjectToUrlParams(filters),
        },
        method: 'get',
        url: 'partners/export_tops',
        responseType: 'arraybuffer',
      });
    },
    getPaymentTypes() {
      return instance.get('partners/payment/types');
    },
    postPaymentAccesses(args) {
      const {
        mediaFiles, partnerPaymentType,
        partner, ...infoFieldPaymentValues
      } = args;
      const url = 'partners/payment/access';
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('partner', JSON.stringify(partner));
      data.append('partnerPaymentType', JSON.stringify(partnerPaymentType));
      data.append('infoFieldPaymentValues', JSON.stringify(infoFieldPaymentValues));
      if (mediaFiles) {
        mediaFiles.forEach(item => data.append('mediaFiles[]', item));
      }
      return instance.post(url, data, config);
    },
    getPaymentAccesses(args = {}) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners/payment/accesses', { params });
    },
    getPaymentAccessByHashId(hashId) {
      return instance.get(`partners/payment/access/${hashId}`);
    },
    postPaymentAccessDocumentsRegenerateByHashId(hashId) {
      return instance.post(`partners/payment/access/${hashId}/documents_regenerate`);
    },
    getPaymentAccessCredentials(hashId) {
      return instance.get(`partners/payment/access/${hashId}/credentials`);
    },
    putPaymentAccessCredentials({ hashId, formData }) {
      return instance.put(`partners/payment/access/${hashId}/credentials`, formData);
    },
    deletePaymentAccessCredentials(hashId) {
      return instance.delete(`partners/payment/access/${hashId}/credentials`);
    },
    deletePaymentAccess(hashId) {
      return instance.delete(`/partners/payment/accesses/delete/${hashId}`);
    },
    putAccess({ hashId, formData }) {
      return instance.put(`partners/payment/access/${hashId}`, formData);
    },
    postBankPaymentAccess(payload) {
      return instance.post('partners/payment/access', payload);
    },
    postLicense({ hashId, payload }) {
      return instance.post(`partners/payment/access/${hashId}/action`, payload);
    },
    getBankInstallmentsList(args, treeDataRowIds) {
      const {
        forceRoot, limit = 20, offset = 0, hashId,
        ...restParams
      } = args;
      const url = `partners/payment/access/${hashId}/category_bnpl`;
      const params = { ...convertJSObjectToUrlParams(restParams), limit, offset };
      if (treeDataRowIds && treeDataRowIds.length) {
        const promisesArray = treeDataRowIds.map(parentId => instance.get(url, {
          params: {
            ...params,
            offset: forceRoot && parentId !== 0 ? undefined : offset,
            limit: forceRoot && parentId !== 0 ? undefined : limit,
            'filter[loadRootCategories]': forceRoot && parentId === 0 ? true : undefined,
            'filter[parentId]': parentId,
          },
        }));

        return Promise.all(promisesArray);
      }
      return instance.get(url, { params });
    },
    postBankInstallments(hashId, payload) {
      const url = `partners/payment/access/${hashId}/category_bnpl`;
      return instance.post(url, { items: payload });
    },
    getBankInstallmentsLogsList({ hashId, categoryId }) {
      return instance.get(`partners/payment/access/${hashId}/category_bnpl/${categoryId}/history`);
    },
    getTransactionsPayment(args = {}) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/partners/payment/transactions', { params });
    },
    getTransactionPaymentByHashId(hashId) {
      return instance.get(`/partners/payment/transactions/${hashId}`);
    },
    putTransactionPayment({ hashId, formData }) {
      return instance.put(`/partners/payment/transactions/${hashId}`, formData);
    },
    getPaymentTransactionsStatusesList() {
      return instance.get('/partners/payment/transactions/statuses');
    },
    getPartnersAccessDownload({ ...filters }) {
      const params = {
        ...convertJSObjectToUrlParams(filters),
      };

      const config = {
        params,
        method: 'get',
        url: 'partners/payment/accesses/download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    getPartnersPaymentMethods(partner) {
      return instance({
        params: {
          partner,
        },
        method: 'get',
        url: 'partners/payment/methods',
      });
    },
    getBusinesses() {
      return instance.get('partners/businesses');
    },
    setBusiness({ hashId, value }) {
      return instance.post(`partners/managers/${hashId}/businesses`, { value });
    },
  },
  partner: {
    getPartnersDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        responseType: 'arraybuffer',
        method: 'get',
        url: 'partners/download',
      });
    },
    getPartnerDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        responseType: 'arraybuffer',
        method: 'get',
        url: `partners/${args}/download`,
      });
    },
    getPartnerContractCommissionDownload(args) {
      return instance({
        responseType: 'arraybuffer',
        method: 'get',
        url: `partners/${args}/offer_contract`,
      });
    },
    getStatusesList(context) {
      return instance.get('partners/statuses', { params: { context } });
    },
    getDataByHashId(hashId) {
      return instance.get(`partners/${hashId}`);
    },
    putDataByHashId({ hashId, formData }) {
      return instance.put(`partners/${hashId}`, formData);
    },
    uploadLogos({ hashId, logoSize, files }) {
      const url = `partners/${hashId}/upload_logos`;
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append(logoSize, files[0]);
      return instance.post(url, data, config);
    },
    uploadContract({ hashId, files }) {
      const url = `partners/${hashId}/upload_contract`;
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('contract', files[0]);
      return instance.post(url, data, config);
    },
    uploadPaymentDocs({
      mediaFiles, hashId, ...dataField
    }) {
      const url = `partners/payment/${hashId}/documents`;
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('infoFieldPaymentValues', JSON.stringify(dataField));
      if (mediaFiles) {
        mediaFiles.forEach(item => data.append('mediaFiles[]', item));
      }
      return instance.post(url, data, config);
    },
    getPartnersList(args = {}) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners', { params });
    },
    getPartnersManagersList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners/managers', { params });
    },
    createNewPartner(formData) {
      return instance.post('partners', formData);
    },
    putPartnersHashIdConfirmForm({ hashId, formData }) {
      return instance.put(`partners/${hashId}/confirm_form`, { ...formData });
    },
    putPartnersProcess({ hashId }) {
      return instance.put(`partners/${hashId}/process`);
    },
    putPartnersOrderProcessingSchedule({ hashId, formData }) {
      return instance.put(`partners/${hashId}/schedule`, { ...formData });
    },
    deletePartnerDeliveryDelayPeriod(hashId) {
      return instance.delete(`partners/${hashId}/delete-schedule`);
    },
    putSendContractToSed({ hashId }) {
      return instance.put(`partners/${hashId}/send_contract_to_sed`, {
        send_contract_to_sed: 1,
      });
    },
    terminateErpContract(hashId) {
      return instance.post(`partners/${hashId}/terminateErpContract`, {});
    },
    getPartnerCategories(params) {
      return instance.get('/product-groups/prepare/categories', { params });
    },
    getPartnerBrands(params) {
      return instance.get('/product-groups/prepare/brands', { params });
    },
    partnerInfo({ hashId }) {
      return instance.get(`partners/${hashId}`);
    },
  },
  partnersManagers: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners/managers', { params });
    },
    putPartnerManager({ hashId, value }) {
      return instance.put(`/partners/managers/${hashId}`, value);
    },
    getPartnerManagerExport({ ...filters }) {
      const params = {
        ...convertJSObjectToUrlParams(filters),
      };

      const config = {
        params,
        method: 'get',
        url: 'partners/managers/download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    getPartnerManagerImport({ file }) {
      const url = 'partners/managers/import';
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('partnersManagersImportFile', file[0]);
      return instance.post(url, data, config);
    },
  },
  alloExpress: {
    getAlloExpressInfo(hashId) {
      return instance.get(`/allo_express/order/${hashId}/info`);
    },
    createAlloExpressTtn(hashId, payload) {
      return instance.post(`/allo_express/order/${hashId}/ttn`, payload);
    },
    getAlloExpressTtn(hashId) {
      return instance.get(`/allo_express/order/${hashId}/ttn`);
    },
    updateAlloExpressTtn(hashId, payload) {
      return instance.put(`/allo_express/order/${hashId}/ttn`, payload);
    },
  },
  orders: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('orders', { params });
    },
    getOrdersHashId({ hashId }) {
      return instance.get(`/orders/${hashId}`);
    },
    putOrdersHashId({ hashId, data }) {
      const body = {
        shippingTrackingNumber: data.shippingTrackingNumber,
        note: data.note,
        orderStatus: data.orderStatus,
        cancelStatus: data.cancelStatus,
        isSelfDelivery: data.isSelfDelivery,
      };
      return instance.put(`/orders/${hashId}`, body);
    },
    getOrdersStatistics(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/orders/statistics', { params });
    },
    getOrdersStatusesList() {
      return instance.get('/orders/statuses');
    },
    getOrdersPaymentTypesList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/orders/payment_types', { params });
    },
    getOrdersDownload(args) {
      const params = convertJSObjectToUrlParams(args);

      const config = {
        params,
        method: 'get',
        url: '/orders/download',
      };
      if (params.context === 'fullOrders') {
        config.responseType = 'arraybuffer';
      }
      return instance(config);
    },
    postOrdersUpload({ files, format }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('ordersFile', files[0]);

      return instance({
        url: '/orders/upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        params: { format },
      });
    },
    putOrderUnbindShippingTracking({ hashId }) {
      return instance.put(`/orders/${hashId}/unbind_shipping_tracking`);
    },
    getOrdersHistory({ args, hashId }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`/orders/${hashId}/status-history`, { params });
    },
    getOrderRefundList({ hashId }) {
      return instance.get(`/orders/${hashId}/order-refund-items`);
    },
    postRefund({ hashId, body }) {
      return instance.post(`/orders/${hashId}/order-refund-items`, body);
    },
    printTtn(hashId) {
      return instance.get(`/allo_express/order/${hashId}/ttn/print`);
    },
  },
  mail: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('mail_templates', { params });
    },
    getMailTemplateList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('mail_templates', { params });
    },
    getMailTemplatesEvents() {
      return instance.get('mail_templates/events');
    },
    getMailTemplatesGroups() {
      return instance.get('mail_template_groups');
    },
    getMailTemplateByHashId(hashId) {
      return instance.get(`mail_templates/${hashId}`);
    },
    putMailTemplateByHashId({ hashId, data }) {
      return instance.put(`mail_templates/${hashId}`, {
        body: data.body,
        bodyUa: data.bodyUa,
        event: data.event,
        subject: data.subject,
        subjectUa: data.subjectUa,
      });
    },
  },
  attributeSets: {
    getList(args, treeDataRowIds) {
      const params = convertJSObjectToUrlParams(args);
      const url = 'attribute_sets';

      if (treeDataRowIds && treeDataRowIds.length) {
        const promisesArray = treeDataRowIds.map(parentId => instance.get(url, {
          params: {
            ...params,
            'filter[parentId]': parentId,
          },
        }));

        return Promise.all(promisesArray);
      }

      return instance.get(url, { params });
    },
  },
  categories: {
    getList(args, treeDataRowIds) {
      const params = convertJSObjectToUrlParams(args);
      const url = 'categories';

      if (treeDataRowIds && treeDataRowIds.length) {
        const promisesArray = treeDataRowIds.map(parentId => instance.get(url, {
          params: {
            ...params,
            'filter[parentId]': parentId,
          },
        }));

        return Promise.all(promisesArray);
      }

      return instance.get(url, { params });
    },
    getCategoryItemByHashId(hashId) {
      return instance.get(`categories/${hashId}`);
    },
    putCategoryItemByHashId(args) {
      const { hashId, data } = args;
      return instance.put(`categories/${hashId}`, data);
    },
    getCategoryStatusesHistory({ hashId, ...args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`category/${hashId}/statuses/history`, { params });
    },
    postCommissionUpload({ files }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('offerPercentsFile', files[0]);

      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        method: 'post',
        url: 'categories/import_offer_percents',
      });
    },
    getIsConfigurableFeatures(id) {
      return instance.get(`categories/${id}/configurable-features`);
    },
  },
  logisticsDataByCategory: {
    getList(args, treeDataRowIds) {
      const {
        forceRoot, limit, offset,
        ...restParams
      } = args;
      const params = convertJSObjectToUrlParams(restParams);
      const url = 'category_logistics';

      if (treeDataRowIds && treeDataRowIds.length) {
        const promisesArray = treeDataRowIds.map(parentId => instance.get(url, {
          params: {
            ...params,
            offset: forceRoot && parentId !== 0 ? undefined : offset,
            limit: forceRoot && parentId !== 0 ? undefined : limit,
            'filter[loadRootCategories]': forceRoot && parentId === 0 ? true : undefined,
            'filter[parentId]': parentId,
          },
        }));

        return Promise.all(promisesArray);
      }

      return instance.get(url, { params: { limit, offset, ...params } });
    },
    postCategoriesFeatureExport({ partners, ...filters }) {
      return instance({
        params: {
          partners,
          ...convertJSObjectToUrlParams(filters),
        },
        method: 'post',
        url: 'category_logistics/download',
        responseType: 'arraybuffer',
      });
    },
    postLogisticsDataByCategoryImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { file } = args;
      formData.append('file', file[0]);
      return instance({
        url: 'category_logistics/import',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
    getLogisticsItemByHashId(hashId) {
      return instance.get(`category_logistics/${hashId}`);
    },
    putLogisticsItemByHashId(params) {
      const { hashId, data } = params;
      return instance.put(`category_logistics/${hashId}`, data);
    },
    getLogs(hashId) {
      return instance.get(`category_logistics/${hashId}/history`);
    },
  },
  categoriesFeatureManagement: {
    getList(args, treeDataRowIds) {
      const params = convertJSObjectToUrlParams(args);
      const url = 'categories_feature_management';

      if (treeDataRowIds && treeDataRowIds.length) {
        const promisesArray = treeDataRowIds.map(parentId => instance.get(url, {
          params: {
            ...params,
            'filter[parentId]': parentId,
          },
        }));

        return Promise.all(promisesArray);
      }

      return instance.get(url, { params });
    },
    getCategoriesFeaturesItemByHashId({ hashId, ...data }) {
      const params = convertJSObjectToUrlParams(data);
      return instance.get(`categories_feature_management/${hashId}`, { params });
    },
    putCategoriesFeatureItemByHashId(args) {
      return instance.put('categories_feature_management/bulk_edit', args);
    },
    postCategoriesFeatureUpload({ files }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('offerPercentsFile', files[0]);

      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        method: 'post',
        url: 'categories_feature_management/import_offer_percents',
      });
    },
    getCategoriesFeatureExport({ partners, ...filters }) {
      return instance({
        params: {
          partners,
          ...convertJSObjectToUrlParams(filters),
        },
        method: 'get',
        url: 'categories_feature_management/download',
        responseType: 'arraybuffer',
      });
    },
    postCategoriesFeatureImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { file } = args;
      formData.append('file', file[0]);
      return instance({
        url: 'categories_feature_management/import',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
  },
  categoriesFeatureLogs: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`categories_feature_management/${hashId}/history`, { params });
    },
  },
  messages: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('messages', { params });
    },
    getMessagesUserTypes() {
      return instance.get('messages/user_types');
    },
    getMessagesStatuses() {
      return instance.get('messages/statuses');
    },
    getMessagesUserTypesByType(type) {
      return instance.get(`messages/user_types/${type}`);
    },
    postMessages({ send, body }) {
      const {
        title, text, messageUsers, mediaId,
      } = body;
      return instance({
        method: 'post',
        url: 'messages',
        data: {
          title, text, messageUsers, mediaId,
        },
        params: { send },
      });
    },
    postRecipientsImport({ files }) {
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('partnersFile', files[0]);
      return instance.post('messages/search_partners', data, config);
    },
    getMessageByHashId(hashId) {
      return instance.get(`messages/${hashId}`);
    },
    putMessages({ send, body }) {
      const {
        title, text, messageUsers, hashId, mediaId,
      } = body;
      return instance({
        method: 'put',
        url: `messages/${hashId}`,
        data: {
          title, text, messageUsers, mediaId,
        },
        params: { send },
      });
    },
    deleteMessagesHashId({ hashId }) {
      return instance.delete(`messages/${hashId}`);
    },
  },
  statistics: {
    getStatistics() {
      return instance.get('/statistics');
    },
  },
  support: {
    postSupportMessage(data) {
      return instance.post('/support/messages', data);
    },
  },
  transactionHistory: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/billing/transactions', { params });
    },
  },
  billingRatingRatio: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/billing/partners_rating_ratios', { params });
    },
  },
  billing: {
    getBillingStatus() {
      return instance.get('/billing/status');
    },
    getReconciliationDates() {
      return instance.get('/billing/reconciliation_dates');
    },
    getReconciliationActDownload(params) {
      return instance({
        params,
        method: 'get',
        url: '/billing/reconciliation_act/download',
        responseType: 'arraybuffer',
      });
    },
    getBillingInvoiceTypes(context) {
      return instance.get('/billing/invoice_types', { params: { context } });
    },
    postBillingPayment(args) {
      return instance({
        data: args,
        method: 'post',
        url: '/billing/payment',
        responseType: 'arraybuffer',
      });
    },
    getBillingStatusPartners(args) {
      const params = convertJSObjectToUrlParams(args);

      return instance.get('/billing/status_partners', { params });
    },
    getBillingReport(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/billing/report', { params });
    },
    getBillingReportDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        method: 'get',
        url: '/billing/report/download',
        responseType: 'arraybuffer',
      });
    },
    getBillingStatusesList() {
      return instance.get('/billing/transactions/statuses');
    },
    getBillingPaymentTypes() {
      return instance.get('/billing/transactions/payment_types');
    },
    getBillingPartnersDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        responseType: 'arraybuffer',
        method: 'get',
        url: 'billing/status_partners/download',
      });
    },
  },
  dashboards: {
    getDashboardsLastTransactions() {
      return instance.get('/dashboards/last_transactions');
    },
    getDashboardsRating() {
      return instance.get('/dashboards/partner_rating');
    },
    getDashboardsOrdersStatusesStatistic(args) {
      const argsArray = convertDatetimeFieldToUrlParams(args);
      const params = {
        fromDate: argsArray[0], toDate: argsArray[1],
      };
      return instance.get('/dashboards/orders_statuses_statistic', { params });
    },
    getDashboardsOnTimeOrdersStatusesStatistic(args) {
      const argsArray = convertDatetimeFieldToUrlParams(args);
      const params = {
        fromDate: argsArray[0], toDate: argsArray[1],
      };
      return instance.get('/dashboards/orders_timely_processed', { params });
    },
    getDashboardsSuccessDeliveryStatistic(args) {
      const argsArray = convertDatetimeFieldToUrlParams(args);
      const params = {
        fromDate: argsArray[0], toDate: argsArray[1],
      };
      return instance.get('/dashboards/orders_timely_delivered', { params });
    },
    getDashboardsNewOrdersStatusesStatistic(args) {
      const argsArray = convertDatetimeFieldToUrlParams(args);
      const params = {
        fromDate: argsArray[0], toDate: argsArray[1],
      };
      return instance.get('/dashboards/new_orders_statistic', { params });
    },
  },
  notifications: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('notifications', { params });
    },
    getNotifications(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/notifications', { params });
    },
    putNotifications(ids) {
      return instance.put('/notifications', ids);
    },
    getNotificationsByHashId(hashId) {
      return instance.get(`notifications/${hashId}`);
    },
    getNotificationsGroups() {
      return instance.get('/notifications/groups');
    },
    getNotificationsTypes() {
      return instance.get('/notifications/types');
    },
  },
  brands: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('brands', { params });
    },
  },
  stopLists: {
    getBrandsCategoriesList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('stop_lists/brands_categories', { params });
    },
    postBrandsCategories({ data }) {
      return instance.post('stop_lists/brands_categories', data);
    },
    getBrandsCategoriesByHashId({ hashId }) {
      return instance.get(`stop_lists/brands_categories/${hashId}`);
    },
    putBrandsCategoriesByHashId(params) {
      const { hashId, data } = params;
      return instance.put(`stop_lists/brands_categories/${hashId}`, data);
    },
    getBrandsCategoriesExport(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('stop_lists/brands_categories/download', {
        params: { ...params },
        responseType: 'arraybuffer',
      });
    },
    deleteBrandsCategories({ stopBrandsCategoriesIds }) {
      return instance({
        method: 'delete',
        url: 'stop_lists/brands_categories',
        data: { stopBrandsCategoriesIds },
      });
    },
    wordsList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('stop_lists/words', { params });
    },
    deleteWords(data) {
      return instance({
        method: 'delete',
        url: 'stop_lists/words',
        data,
      });
    },
    getExportStopWords(args) {
      const params = convertJSObjectToUrlParams(args);
      // TODO: данного апи stop_lists/words/download ещё нет
      return instance.get('stop_lists/words/download', {
        params: { ...params },
        responseType: 'arraybuffer',
      });
    },
    postStopWord(data) {
      return instance.post('stop_lists/words', data);
    },
    putStopWord(params) {
      const { hashId, data } = params;
      return instance.put(`stop_lists/words/${hashId}`, data);
    },
    getStopWordHashId({ hashId }) {
      return instance.get(`stop_lists/words/${hashId}`);
    },
    postStopWordUpload({ files }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('stopWordsFile', files[0]);

      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        method: 'post',
        url: 'stop_lists/words/import',
      });
    },
    postBrandsCategoriesUpload({ files }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('stopBrandsCategoriesFile', files[0]);

      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        method: 'post',
        url: 'stop_lists/brands_categories/upload',
      });
    },
  },
  medias: {
    postMedias({ files, context, cropData }) {
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { context },
      };
      data.append('mediaFile', files[0]);
      if (cropData) {
        data.append('cropData', JSON.stringify(cropData));
      }
      return instance.post('medias', data, config);
    },
    deleteMedias(hashId) {
      return instance.delete(`medias/${hashId}`);
    },
    batchMediasUpload({ files, context }) {
      const filesArray = Object.values(files);
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { context },
      };
      filesArray.forEach(item => data.append('mediaFiles[]', item));
      return instance.post('batch_medias_upload', data, config);
    },
  },
  password: {
    passwordChange(args) {
      const { code, password } = args;
      const url = 'password/change';
      const data = { code, password };
      return instance.post(url, data);
    },
    passwordReset(data) {
      const url = 'password/reset';
      return instance.post(url, data);
    },
  },
  contentProduct: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products', { params });
    },
  },
  contentProductStatusLogs: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);

      return instance.get(`/products/${hashId}/logs`, { params });
    },
  },
  contentProductEnabledInMagentoLogs: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);

      return instance.get(`/products/${hashId}/logs`, { params });
    },
  },
  contentTemplates: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products/categories', { params });
    },
    putUpdateFromMagento(categoryId) {
      return instance.put('products/features/update_from_magento', categoryId);
    },
    getProductsImportTemplatesDownload({ categoryId }) {
      return instance.get('products/import_templates_download', {
        params: { categoryId },
        responseType: 'arraybuffer',
      });
    },
    postProductsImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { categoryId, partnerId, productImportFile } = args;

      formData.append('productImportFile', productImportFile[0]);

      return instance({
        url: '/products/import',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        params: {
          categoryId,
          partnerId,
        },
      });
    },
  },
  products: {
    getCategoryStatistic(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products/categories_statistic', { params });
    },
    putUpdateValuesFromMagento(featureId) {
      return instance.put('products/features/update_values_from_magento', featureId);
    },
    getProductsSetting({ categoryId, attributeSetId }) {
      return instance.get('products/setting', {
        params: { categoryId, attributeSetId },
      });
    },
    getProductsFeatureValuesListDownload({ categoryId }) {
      return instance.get('products/feature_values_list_download', {
        params: { categoryId },
        responseType: 'arraybuffer',
      });
    },
    getProductsSources() {
      return instance.get('products/sources');
    },
    getProductsExport(data) {
      const config = {
        data,
        method: 'post',
        url: 'products/download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    getProductsPropertiesExport(data) {
      const config = {
        data,
        method: 'post',
        url: 'products/export',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    getProductsOnlyTextExport(data) {
      const config = {
        data,
        method: 'post',
        url: 'products/download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    getProductsExportPrices(data) {
      const config = {
        data,
        method: 'post',
        url: 'products/export_prices',
        responseType: 'arraybuffer',
      };
      return instance(config);
    },
    putProducts(data) {
      return instance.put('products', data);
    },
    putProductsBulkEdit(data) {
      const { filter, ...rest } = data;
      const params = `?${new URLSearchParams(convertJSObjectToUrlParams(filter))}`;
      return instance.put(`products/bulk_edit${filter ? params : ''}`, rest);
    },
    getProductsStatuses() {
      return instance.get('products/statuses');
    },
    getProductsHashId({ hashId }) {
      return instance.get(`products/${hashId}`);
    },
    postProduct(data) {
      return instance.post('products', data);
    },
    putProductsSettings(data) {
      return instance.put('products/setting', data);
    },
    deleteProducts(data) {
      return instance({
        method: 'delete',
        url: 'products',
        data,
      });
    },
    postPriceListDownload({ partnerId, ...filters }) {
      const params = convertJSObjectToUrlParams(filters);
      const config = {
        params,
        data: { partner: partnerId },
        method: 'post',
        url: '/products/prices_download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
    postPriceListUpload({ file, partnerId }) {
      const url = 'products/prices';
      const data = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.append('file', file[0]);
      data.append('partner', partnerId);
      return instance.post(url, data, config);
    },
    getStatusReport(args) {
      const queryString = new URLSearchParams(args).toString();
      return instance.get(`/products/status-report?${queryString}`);
    },
  },
  productsGroups: {
    getProductGroups(params) {
      return instance.get('/product-groups/products', { params });
    },
    getProductGroupByHashId(hashId) {
      return instance.get(`/product-groups/${hashId}`);
    },
    postProductGroup(data) {
      return instance.post('/product-groups', data);
    },
    addProductToGroup({ hashId, productSku }) {
      return instance.get(`/product-groups/${hashId}/products/${productSku}`);
    },
    updateProductGroup(data, hashId) {
      return instance.patch(`/product-groups/${hashId}`, data);
    },
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/product-groups', { params });
    },
    deleteGroup(hashId) {
      return instance.delete(`/product-groups/${hashId}`);
    },
    exportProductGroups(data) {
      return instance.post('/product-groups/export', data, { responseType: 'arraybuffer' });
    },
    importProductGroups(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { file } = args;
      formData.append('file', file[0]);
      return instance({
        url: '/product-groups/import',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
  },
  logs: {
    getMagentoRequestsList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('logs/magento_requests', { params });
    },
    getMagentoRequestsItem(hashId) {
      return instance.get(`logs/magento_requests/${hashId}`);
    },
    getMagentoRequestsDownload(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        method: 'get',
        url: 'logs/magento_requests/download',
        responseType: 'arraybuffer',
      });
    },
  },
  faq: {
    getFaqs(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('faqs', { params });
    },
    getFaqStatuses() {
      return instance.get('faqs/statuses');
    },
    getFaqsHashId(hashId) {
      return instance.get(`faqs/${hashId}`);
    },
    putFaqsHashId(params) {
      const { hashId, data } = params;
      return instance.put(`faqs/${hashId}`, data);
    },
    deleteFaqsHashId(hashId) {
      return instance.delete(`faqs/${hashId}`);
    },
  },
  serviceDesk: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('service_desk', { params });
    },
    getStatuses() {
      return instance.get('service_desk/statuses');
    },
    getHashId(hashId) {
      return instance.get(`service_desk/${hashId}`);
    },
    putHashId(params) {
      const { hashId, data } = params;
      return instance.put(`service_desk/${hashId}`, data);
    },
    postCloseQuestion({ hashId }) {
      return instance.post(`service_desk/${hashId}/close`);
    },
    postContinueQuestion({ hashId }) {
      return instance.post(`service_desk/${hashId}/continue`);
    },
    getMessages({ hashId, offset, limit }) {
      return instance.get(`service_desk/${hashId}?offset=${offset}&limit=${limit}`);
    },
    postMessage({ hashId, params }) {
      return instance({
        method: 'post',
        url: `service_desk/${hashId}`,
        data: {
          message: params.message,
          messageFiles: params.messageFiles,
        },
      });
    },
    postQuestion(payload) {
      return instance({
        method: 'post',
        url: 'service_desk',
        data: {
          questionType: payload.questionType,
          questionTheme: payload.questionTheme,
          message: payload.message,
          messageFiles: payload.messageFiles,
        },
      });
    },
  },
  productFeedParameters: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`products/feeds/${hashId}/parameters`, { params });
    },
  },
  productFeedCategoriesAttributes: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`products/feeds/${hashId}/category_attributes`, { params });
    },
  },
  productFeedCategories: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`products/feeds/${hashId}/categories`, { params });
    },
    putItemByHashId(params) {
      const { hashId, ...data } = params;
      return instance.put(`products/feeds/categories/${hashId}`, data);
    },
    getItemByHashId(hashId) {
      return instance.get(`products/feeds/categories/${hashId}`);
    },
  },
  feeds: {
    getFeeds(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products/feeds', { params });
    },
    putFeedHashId(params) {
      const { hashId, ...data } = params;
      return instance.put(`products/feeds/${hashId}`, data);
    },
    getFeedsHashId(hashId) {
      return instance.get(`products/feeds/${hashId}`);
    },
    deleteFeedsHashId(hashId) {
      return instance.delete(`products/feeds/${hashId}`);
    },
    postFeedsRefresh({ id, file }) {
      if (file) {
        const { FormData } = window;
        const formData = new FormData();

        formData.append('feedImportFile', file[0]);
        return instance({
          method: 'post',
          url: `feeds/refresh?feedId=${id}`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
      }
      return instance.post(`feeds/refresh?feedId=${id}`);
    },
    postFeedsParse(params) {
      const { hashId } = params;
      return instance.post(`products/feeds/${hashId}/parse`);
    },
    postAutoConnect({ hashId, type }) {
      return instance.post(`products/feeds/${hashId}/autoconnect`, { type });
    },
    getFeedsStatusesList(params) {
      return instance.get('products/feeds/statuses', { params });
    },
    getFeedsTypesList() {
      return instance.get('products/feeds/types');
    },
    getFeedsPrioritiesList() {
      return instance.get('products/feeds/priorities');
    },
    getFeedsAttributes(hashId) {
      return instance.get(`products/feeds/attributes/${hashId}`);
    },
    putFeedsAttributes(params) {
      const { hashId, ...data } = params;
      return instance.put(`products/feeds/attributes/${hashId}`, data);
    },
    putFeedsAttributesValues(params) {
      const { hashId, ...data } = params;
      return instance.put(`products/feeds/attributes/values/${hashId}`, data);
    },
    postImport(params) {
      const url = 'products/feeds/import';
      const {
        partnerId, feedType, locale, fileType,
        feedImportFileUrl, feedImportFile,
      } = params;

      if (fileType === 'link') {
        return instance({
          params: { partnerId, feedType },
          data: { feedImportFileUrl, locale },
          method: 'post',
          url,
        });
      }

      const { FormData } = window;
      const formData = new FormData();
      formData.append('feedImportFile', feedImportFile[0]);
      formData.append('locale', JSON.stringify(locale));
      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { partnerId, feedType },
        data: formData,
        method: 'post',
        url,
      });
    },
    getExport({ hashId, forceAction }) {
      return instance.get('products/feeds/export', {
        params: { feedId: hashId, forceAction },
        responseType: 'arraybuffer',
      });
    },
    postRefreshPriceList({ payload: hashId }) {
      return instance.post(`/products/feeds/${hashId}/reset`);
    },
  },
  productFeedLogs: {
    getList({ hashId, params: args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`/products/feeds/${hashId}/logs`, { params });
    },
  },
  feedsProducts: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('feeds/products', { params });
    },
    getFeedsProductStatisticsList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products/categories/statistics', { params });
    },
    putItemByHashId(params) {
      const { hashId, data } = params;
      return instance.put(`feeds/products/${hashId}`, data);
    },
    getProductsExport(args) {
      const cloneArgs = { ...args };
      delete cloneArgs.forceAction;
      const params = convertJSObjectToUrlParams(cloneArgs);
      return instance.get('feeds/products/export', {
        params: { ...params, forceAction: args.forceAction },
        responseType: 'arraybuffer',
      });
    },
    getProductMultipleEditing(data) {
      const { filter, ...rest } = data;
      const params = `?${new URLSearchParams(convertJSObjectToUrlParams(filter))}`;
      return instance.put(`feeds/products${filter ? params : ''}`, rest);
    },
    getProductsExportFull(args) {
      const cloneArgs = { ...args };
      delete cloneArgs.forceAction;
      const params = convertJSObjectToUrlParams(cloneArgs);
      return instance.get('feeds/products/export_full', {
        params: { ...params, forceAction: args.forceAction },
        responseType: 'arraybuffer',
      });
    },
    postProductsImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { productImportFile, partnerId, onlyText } = args;
      formData.append('productImportFile', productImportFile[0]);
      if (onlyText === 1) {
        return instance({
          params: { partnerId, onlyText },
          url: 'products/import',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'post',
        });
      }

      return instance({
        params: { partnerId },
        url: 'products/import',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
    postProductsPropertiesImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { productsFile } = args;
      formData.append('productsFile', productsFile[0]);
      return instance({
        url: 'products/import/properties',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
    postProductsPricesImport(args) {
      const { FormData } = window;
      const formData = new FormData();
      const { productsFile } = args;
      formData.append('productsFile', productsFile[0]);
      return instance({
        url: 'products/import/prices',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
      });
    },
    getProductsFeedStatistic(params) {
      return instance.get('products/feeds/statistic', { params });
    },
    getProductsStatistic(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('products/statistics', { params });
    },
    getExportTableFeedList({ ...filters }) {
      const params = {
        ...convertJSObjectToUrlParams(filters),
      };

      const config = {
        params,
        method: 'post',
        url: 'products/feeds/table/export',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
  },
  priceCreatorFiles: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('xml', { params });
    },
    postDataXmlDownload(data) {
      return instance.post('xml', data);
    },
  },
  systemMotivation: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners/motivations', { params });
    },
    getDownloadResults(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('partners/motivations/download', {
        params: { ...params },
        responseType: 'arraybuffer',
      });
    },
  },
  priceCreatorCategories: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('xml/categories', { params });
    },
    putUpdateCategories(body) {
      return instance.put('xml/categories', body);
    },
    putStatusCategory({ hashId, enabled }) {
      return instance.put(`xml/categories/${hashId}`, { enabled });
    },
  },
  commissions: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('commissions', { params });
    },
    getCommissionExport({ ...filters }) {
      // lorem
      const params = {
        ...convertJSObjectToUrlParams(filters),
      };

      const config = {
        params,
        method: 'get',
        url: 'commissions/download',
        responseType: 'arraybuffer',
      };

      return instance(config);
    },
  },
  synonyms: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('dictionary/values', { params });
    },
    getItem({ hashId }) {
      return instance.get(`dictionary/values/${hashId}`);
    },
    addValue(args) {
      const { value, type, synonyms } = args;
      return instance.post('dictionary/values', { value, type, synonyms });
    },
    editValue(args) {
      const {
        value, type, synonyms, hashId,
      } = args;
      return instance.put(`dictionary/values/${hashId}`, { value, type, synonyms });
    },
    export(args) {
      return instance.post('dictionary/values/export', args, { responseType: 'arraybuffer' });
    },
    import({ file }) {
      const { FormData } = window;
      const formData = new FormData();

      formData.append('file', file[0]);

      return instance({
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        method: 'post',
        url: 'dictionary/values/import',
      });
    },
    delete({ ids }) {
      return instance.delete('dictionary/values', { data: { ids } });
    },
  },
  feedsAttributeSet: {
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('feeds/attribute_set_management', { params });
    },
    putContentManager({ hashId, assignedTo, filterType }) {
      return instance.put(`feeds/attribute_set_management/${hashId}?filter[filterType]=${filterType}`, assignedTo);
    },
  },
  partnerStatusesHistory: {
    getList({ hashId, ...args }) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`partners/${hashId}/statuses/history`, { params });
    },
  },
  common: {
    getBankInfo({ glmfo }) {
      return instance.get('/banks/search', {
        params: { glmfo },
      });
    },
  },
  partnerServiceRequests: {
    createServiceIntegrationRequest(hashId, formData) {
      return instance.post(`/partners/${hashId}/connected-services`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updateServiceIntegrationRequest(hashId, serviceRequestId, formData) {
      return instance.post(`/partners/${hashId}/connected-services/${serviceRequestId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    sendContractForIntegrationRequest(hashId, serviceRequestId) {
      return instance.post(`/partners/${hashId}/connected-services/${serviceRequestId}/contract`, {});
    },
    changeServiceRequestStatus(hashId, serviceRequestId, formData) {
      return instance.put(`/partners/${hashId}/connected-services/${serviceRequestId}/status`, formData);
    },
    getRequestHistoryList(hashId, serviceRequestId, args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get(`/partners/${hashId}/connected-services/${serviceRequestId}/status/history`, { params });
    },
    getList(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance.get('/partners/connected-services', { params });
    },
    downloadFile(args) {
      const params = convertJSObjectToUrlParams(args);
      return instance({
        params,
        responseType: 'arraybuffer',
        method: 'get',
        url: '/partners/connected-services/download',
      });
    },
    getPartnerServiceRequest(hashId) {
      return instance.get(`/partners/${hashId}/connected-services`);
    },
    getPartnerServiceRequestStatus(hashId) {
      return instance.get(`/partners/${hashId}/connected-services/status`);
    },
  },
});

export const requestStatus = Object.freeze({
  error: 'error',
  success: 'success',
});
