// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import Select from '../../../_Form/Selects/Select/Select';

function TypeFilter(props) {
  const { filters, onChange } = props;
  const { t } = useTranslation();
  const input = {
    value: filters,
    onChange,
  };

  const data = [
    { label: t('Товар'), value: 1 },
    { label: t('Заказ'), value: 2 },
  ];

  return (
    <Select
      options={data}
      label=""
      input={input}
    />
  );
}

export default TypeFilter;

TypeFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
};
