// Core
import React, {
  memo, useMemo, useState, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { useTranslation } from 'react-i18next';
// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// Parts
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// Helpers
import { useStyles } from '../../hooks/useStyles';
import { useAccessList } from '../../hooks/useAccessList';
import NotificationStyle from './NotificationStyle';
// Engine
import { accessList } from '../../../engine/config/access';

function RenderLinkDownload(props) {
  const { media, children } = props;
  const hasAccessMediaDownload = useAccessList(accessList.mediaDownload);
  const mediaLink = hasAccessMediaDownload && media && media.link
    ? media.link
    : '';

  return mediaLink ? (
    <a href={mediaLink} target="_blank" rel="noopener noreferrer" download>
      {children}
    </a>
  ) : <div>{children}</div>;
}

function NotificationContent(props) {
  const {
    message = '', title, link, onContainerClick,
    group, closeToast, id, media,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(NotificationStyle);

  const [shouldTruncate, setShouldTruncate] = useState(true);

  const groupIcon = useMemo(() => {
    switch (group) {
      case 'order':
        return (
          <ShoppingCartIcon
            fontSize="small"
            color="primary"
            classes={{
              colorPrimary: classes.iconInfoColor,
            }}
          />
        );
      case 'user':
        return (
          <VerifiedUserIcon
            fontSize="small"
            color="primary"
            classes={{
              colorPrimary: classes.iconInfoColor,
            }}
          />
        );
      default:
        return (
          <InfoOutlinedIcon
            fontSize="small"
            color="primary"
            classes={{
              colorPrimary: classes.iconInfoColor,
            }}
          />
        );
    }
  }, [classes.iconInfoColor, group]);

  const handleTruncate = () => {
    setShouldTruncate(!shouldTruncate);
  };

  const handleContainerClick = () => {
    closeToast();
    onContainerClick({ isCloseBtn: false });
  };

  const renderHtml = useCallback(content => ({
    __html: content,
  }), []);

  const handleReflow = ({ clamped }) => {
    if (!clamped) {
      setShouldTruncate(undefined);
    }
  };

  return (
    <>
      <Box
        className={cx(classes.wrapper, { [classes.containerLink]: Boolean(link) })}
        onClick={handleContainerClick}
      >
        <RenderLinkDownload media={media}>
          <h4 className={classes.title}>
            <span className={classes.wrapperIcons}>
              {Boolean(id) && groupIcon}
            </span>
            {title}
          </h4>
          {shouldTruncate ? (
            <HTMLEllipsis
              unsafeHTML={message}
              maxLine="4"
              ellipsis="..."
              basedOn="letters"
              onReflow={handleReflow}
            />
          ) : <div className={classes.message} dangerouslySetInnerHTML={renderHtml(message)} />}
        </RenderLinkDownload>
      </Box>
      {shouldTruncate !== undefined && (
        <Link
          color="inherit"
          onClick={handleTruncate}
          underline="always"
          className={classes.linkTruncate}
        >
          {shouldTruncate ? t('Показать весь текст') : t('Скрыть текст')}
        </Link>
      )}
    </>
  );
}

NotificationContent.propTypes = {
  closeToast: PropTypes.func.isRequired,
  onContainerClick: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  group: PropTypes.string,
  id: PropTypes.number,
  media: PropTypes.object,
};

NotificationContent.defaultProps = {
  message: '',
};

export default memo(NotificationContent);
