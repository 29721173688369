// Core
import {
  apply, call, put, select,
} from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import { isImmutable } from 'immutable';
import { replace } from 'redux-first-history';
// Engine
import { api } from '../../../../config/api';
import { actions as contentProductActions } from '../../actions';
import { selectors } from '../../../../config/selectors';
import { pageLinks } from '../../../../config/routes';

export function* callGetCategoriesListWorker({ payload: { categoryId, context } }) {
  yield put(contentProductActions.setIsCategoriesListLoading(true));
  const categoriesList = yield select(selectors.contentProduct.productCategoriesList);
  const hasCategoriesList = categoriesList.size > 0;

  if (hasCategoriesList) {
    const productCategoryActiveItem = isNil(categoryId)
      ? yield select(selectors.contentProduct.productCategoryActiveItem)
      : yield call(getCurrentCategory, { categoryId, categoriesList });

    if (productCategoryActiveItem) {
      yield put(contentProductActions.setActiveProductCategory(productCategoryActiveItem));
    }
  } else {
    const response = yield apply(api, api.contentTemplates.getList, [{ context }]);

    if (response && response.status >= 200 && response.status < 400) {
      const items = [{ value: null }, ...response.data.items];
      const productCategoryActiveItem = isNil(categoryId)
        ? yield select(selectors.contentProduct.productCategoryActiveItem)
        : yield call(getCurrentCategory, { categoryId, categoriesList: items });

      yield put(contentProductActions.setActiveProductCategory(productCategoryActiveItem));
      yield put(contentProductActions.setProductCategoriesList(items));
    }
  }
  yield put(contentProductActions.setIsCategoriesListLoading(false));
}

function* getCurrentCategory({ categoryId, categoriesList }) {
  const isImmutableList = yield apply(null, isImmutable, [categoriesList]);
  const defaultCategory = isImmutableList ? categoriesList.get('0') : categoriesList['0'];
  if (!Number.isNaN(categoryId)) {
    const currentCategory = categoriesList.find(item => item.value === categoryId);
    if (isNil(currentCategory) && !isNil(defaultCategory)) {
      yield put(replace(pageLinks.content.products.all(defaultCategory.value)));
    }
    return currentCategory || defaultCategory;
  }
  return defaultCategory;
}
