// Core
import React, { useEffect, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Select from './Select/Select';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { asyncActions as categoriesAsyncActions } from '../../../../engine/core/categories/saga/asyncActions';
import FieldWithClearButton from '../../../containers/FieldWithClearButton/FieldWithClearButton';

function CategoriesSelect(props) {
  const {
    input, margin, menuPlacement, withClear, label, id, wrapperClassName,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = useSelector(selectors.categories.categoriesListContext);
  const isLoading = useSelector(selectors.categories.categoriesListContextPending);
  const selectProps = useMemo(() => ({
    input,
    margin,
    menuPlacement,
    options,
    isLoading,
    id,
    hasSearch: true,
    label: label ?? t('Выберите категорию'),
    searchErrorLabel: t('Неверно введена категория'),
    variant: 'outlined',
    placeholder: '',
    wrapperClassName,
  }), [
    input,
    margin,
    menuPlacement,
    options,
    isLoading,
    label,
    id,
    wrapperClassName,
  ]);

  useEffect(() => {
    dispatch(categoriesAsyncActions.getCategoriesListContextAsync({ context: 'select' }));
  }, []);

  return withClear
    ? (
      <FieldWithClearButton margin={margin} value={input.value} onChange={input.onChange} name={input.name}>
        <Select {...selectProps} />
      </FieldWithClearButton>
    )
    : <Select {...selectProps} />;
}

export default memo(CategoriesSelect);
