// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import { callPostMediasWorker, callPostProductMediasWorker } from './workers';

function* watchPostMedias() {
  yield takeEvery(asyncTypes.POST_MEDIAS_ASYNC, callPostMediasWorker);
}
function* watchPostProductMedias() {
  yield takeEvery(asyncTypes.POST_PRODUCT_MEDIAS_ASYNC, callPostProductMediasWorker);
}

export function* watchersMedias() {
  yield all([
    watchPostMedias(),
    watchPostProductMedias(),
  ]);
}
