// Core
import { put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';

const filteredByKey = (list, returnedObj, keyName) => list.filter((item) => {
  const key = get(item, keyName);
  return returnedObj[key];
});

export function* callSetFiltersWorker(settings) {
  const columns = get(settings, 'productGroups.gridSettings', []);

  const columnsObjectLike = columns.reduce((acc, item) => {
    const { name, visible } = item;
    acc[name] = visible;
    return acc;
  }, {});

  const filters = yield select(selectors.productGroups.filters);
  const sorting = yield select(selectors.productGroups.sorting);

  const filteredFilters = filteredByKey(filters.toJS(), columnsObjectLike, 'columnName');

  const filteredSorting = filteredByKey(sorting.toJS(), columnsObjectLike, 'columnName');

  yield put(actions.setFilters(filteredFilters));
  yield put(actions.setSorting(filteredSorting));
}
