// Core
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Grid from '@mui/material/Grid';
import FormInput from '../../../components/_Form/TextField/FormTextField/FormTextField';
import FormTextArea from '../../../components/_Form/TextArea/FormTextArea';
import Loading from '../../../components/Loading/Loading';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
// Engine
import { selectors } from '../../../../engine/config/selectors';
// Helpers
import { useStyles } from '../../../hooks/useStyles';
import OrderEditStyle from './OrderEditStyle';
import { formName, formFields } from './form';

function OrderEditDeliveryTab(props) {
  const { display, tabIndex, tabValue } = props;
  const { t } = useTranslation();
  const classes = useStyles(OrderEditStyle);
  const orderItemByHashId = useSelector(selectors.orders.orderItemByHashId);
  const isLoading = useMemo(() => {
    const { pending } = orderItemByHashId.toJS();

    return pending;
  }, [orderItemByHashId]);

  if (isLoading) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  return (
    <TabsPanel
      index={tabIndex}
      value={tabValue}
      style={{ display }}
    >
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('ФИО получателя')}
            margin="normal"
            name={formFields.customerName}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Телефон')}
            margin="normal"
            name={formFields.customerPhone}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Email')}
            margin="normal"
            name={formFields.customerEmail}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Город доставки')}
            margin="normal"
            name={formFields.shippingCity}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Город доставки UID')}
            margin="normal"
            name={formFields.shippingCityUid}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Адрес название улицы')}
            margin="normal"
            name={formFields.shippingStreet}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Номер дома')}
            margin="normal"
            name={formFields.shippingHouse}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Номер квартиры')}
            margin="normal"
            name={formFields.shippingApartment}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Название склада')}
            margin="normal"
            name={formFields.stockName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Стоимость доставки')}
            margin="normal"
            name={formFields.shippingPrice}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Тип доставки')}
            margin="normal"
            name={formFields.shippingType}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Дата перевода в статус Отправлен')}
            margin="normal"
            name={formFields.shippedAt}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Дата перевода в статус Выполнен')}
            margin="normal"
            name={formFields.doneAt}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Дата перевода в статус Отменен')}
            margin="normal"
            name={formFields.canceledAt}
          />
          <Field
            component={FormTextArea}
            disabled
            fullWidth
            label={t('Дополнительная информация')}
            margin="normal"
            multiline
            name={formFields.information}
            rows={5}
          />
          <Field
            component={FormInput}
            fullWidth
            disabled
            label={t('Тип оплаты')}
            margin="normal"
            name={formFields.paymentType}
          />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

OrderEditDeliveryTab.propTypes = {
  display: PropTypes.oneOf(['flex', 'none']),
  tabIndex: PropTypes.string,
  tabValue: PropTypes.string,
};

export default reduxForm({
  form: formName,
})(OrderEditDeliveryTab);
