// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  // PRODUCT_FEED_ATTRIBUTES
  mergeAttributesItems(data) {
    return getActionCreator(types.MERGE_PRODUCT_FEED_ATTRIBUTES_ITEMS, data);
  },
  setAttributesExpendedRowIds(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_ATTRIBUTES_EXPANDED_ROW_IDS, data);
  },
  setDefaultAttributes(data) {
    return getActionCreator(types.SET_DEFAULT_PRODUCT_FEED_ATTRIBUTES, data);
  },
  setAttributesItemByHashIdAndPosition(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_ATTRIBUTES_ITEM_BY_HASH_ID_AND_POSITION, data);
  },
  // PRODUCT_FEED_CATEGORIES
  setCategoriesExpendedRowIds(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_EXPANDED_ROW_IDS, data);
  },
  mergeCategoriesLoading(data) {
    return getActionCreator(types.MERGE_PRODUCT_FEED_CATEGORIES_LOADING, data);
  },
  deleteCategoriesLoading(data) {
    return getActionCreator(types.DELETE_PRODUCT_FEED_CATEGORIES_LOADING, data);
  },
  mergeCategoriesItems(data) {
    return getActionCreator(types.MERGE_PRODUCT_FEED_CATEGORIES_ITEMS, data);
  },
  setCategoriesItemByHashIdAndPosition(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ITEM_BY_HASH_ID_AND_POSITION, data);
  },
  setDefaultCategories(data) {
    return getActionCreator(types.SET_DEFAULT_PRODUCT_FEED_CATEGORIES, data);
  },
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  setList(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_PENDING, settingPending);
  },
  setAutoConnectPending(data) {
    return getActionCreator(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_PENDING, data);
  },
});
