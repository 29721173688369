// Core
import { apply, delay, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api } from '../../../../config/api';
import { routersLink } from '../../../../config/routes';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';

export function* callPasswordChangeWorker({ payload }) {
  yield put(actions.setResetPasswordSubmitting(true));

  const response = yield apply(api, api.password.passwordChange, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { statusTitle, status } = response.data;

    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(routersLink.users.signIn));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setResetPasswordSubmitting(false));
}
