// Core
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { replace } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm, Field, Form, change, untouch, submit,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
// UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// Parts
import ButtonWrapper from '../components/ButtonWrapper';
import MainLogoImage from '../components/MainLogoImage/MainLogoImage';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import RememberCheckbox from '../components/RememberCheckbox';
// Style
import AuthenticationStyle from '../AuthenticationStyle';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';
// Engine
import { routersLink } from '../../../../engine/config/routes';
import { userActionAsync } from '../../../../engine/core/user/saga/asyncAction';
import { userActions } from '../../../../engine/core/user/action';
// Helpers
import { validators } from '../../../../_helpers/validationsForm';
// DATA
import { formFields, formName } from './form';
// Hooks
import { useStyles } from '../../../hooks/useStyles';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import Header from '../../../components/Header/Header';
import Logo from '../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../assets/img/logo_short.svg';

function SignIn(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const userSubmitting = useSelector(selectors.user.userSubmitting);
  const userInfo = useSelector(selectors.user.userInfo);
  const userTwoFactorAuth = useSelector(selectors.user.twoFactorAuth).toJS();
  const twoFactorAuthToken = userTwoFactorAuth.twoFactorAuthToken;
  const twoFactorAuthMessage = userTwoFactorAuth.message;
  const twoFactorAuthTimeLive = userTwoFactorAuth.codeValidTimeLife;
  const twoFactorPending = userTwoFactorAuth.pending;
  const [twoFactorCodeLength, setTwoFactorCodeLength] = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles(AuthenticationStyle);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (userInfo.enabled === false) {
      dispatch(replace(routersLink.users.confirm));
    }
  }, [dispatch, userInfo]);

  const onTimerUpdate = ({ time: currentTime, duration: currentDuration }) => {
    setTime(currentTime);
    setDuration(currentDuration);
  };

  const onTimerFinish = () => {
    dispatch(change(formName, formFields.twoFactorAuthCode, undefined));
    dispatch(untouch(formName, formFields.twoFactorAuthCode));
    dispatch(userActions.mergeInTwoFactorAuthInfo({ twoFactorAuthToken: null }));
  };

  const onFormSubmit = async (formData) => {
    if (!twoFactorPending) {
      const data = {
        email: formData.get(formFields.email).replace(/\s/g, ''),
        password: formData.get(formFields.password).replace(/\s/g, ''),
        rememberMe: formData.get(formFields.rememberMe),
      };
      if (process.env.REACT_APP_RECAPTCHA === 'true') {
        if (!executeRecaptcha) {
          console.warn('Execute recaptcha not yet available');
        } else {
          data.gRecaptchaResponse = await executeRecaptcha('SignIn');
          dispatch(userActionAsync.signInAsync(data));
        }
      } else {
        dispatch(userActionAsync.signInAsync(data));
      }
    }
  };

  const codeHandler = (e) => {
    const codeLength = e.target.value.length;
    if (codeLength >= 7) {
      e.preventDefault();
    } else {
      setTwoFactorCodeLength(codeLength);
      if (codeLength === 6) {
        dispatch(userActions.mergeInTwoFactorAuthInfo({
          twoFactorAuthCode: e.target.value,
        }));
        dispatch(change(formName, formFields.twoFactorAuthCode, e.target.value));
        setTimeout(() => {
          dispatch(submit(formName));
        }, 0);
      }
    }
  };

  return (
    <>
      <div className={classes.session}>
        <Header
          logoAltText={t('АЛЛО - личный кабинет')}
          logo={Logo}
          LogoMobile={LogoMobile}
          onlyLogo
          signUpButton
        />
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <Card>
              <CardContent>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="text-xs-center pb-xs">
                    <MainLogoImage />
                    <Typography variant="caption" display="block">
                      {t('Войдите в систему, чтобы продолжить')}
                    </Typography>
                  </div>
                  <Field
                    disabled={twoFactorAuthToken}
                    autoComplete={formFields.email}
                    component={TextField}
                    className={classes.textField}
                    fullWidth
                    label={t('Email или телефон')}
                    margin="normal"
                    name={formFields.email}
                    required
                    validate={[validators.required]}
                    validation
                  />
                  {twoFactorAuthToken
                    ? (
                      <>
                        <Field
                          component={TextField}
                          fullWidth
                          autoFocus
                          onChange={codeHandler}
                          label={
                            twoFactorAuthTimeLive
                              ? (
                                <Timer
                                  active
                                  duration={twoFactorAuthTimeLive * 60 * 1000}
                                  onTimeUpdate={onTimerUpdate}
                                  onFinish={onTimerFinish}
                                >
                                  <span>
                                    {twoFactorAuthMessage} <Timecode time={duration - time} /> {t('мин.')}
                                  </span>
                                </Timer>
                              )
                              : twoFactorAuthMessage
                          }
                          margin="normal"
                          name={formFields.twoFactorAuthCode}
                          type="number"
                          required
                          validate={[validators.required]}
                          validation
                        />
                      </>
                    )
                    : (
                      <div>
                        <Field
                          component={TextField}
                          fullWidth
                          label={t('Пароль')}
                          margin="normal"
                          name={formFields.password}
                          type="password"
                          required
                          validate={[validators.required]}
                          validation
                        />
                        <Field
                          component={RememberCheckbox}
                          label={t('Запомнить меня')}
                          name={formFields.rememberMe}
                        />
                      </div>
                    )
                  }
                  <Button
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={(twoFactorAuthToken && twoFactorCodeLength < 6) || userSubmitting || twoFactorPending}
                    sx={{ p: BUTTON_SIZE.middle }}
                  >
                    {userSubmitting || twoFactorPending
                      ? <CircularProgress color="inherit" size={14} />
                      : t('Войти')
                    }&nbsp;
                  </Button>
                  <ButtonWrapper>
                    <Link to={routersLink.users.password_reset}>
                      <Button>{t('Восстановить пароль')}</Button>
                    </Link>
                    <Link to={routersLink.users.signUp}>
                      <Button>{t('Регистрация')}</Button>
                    </Link>
                  </ButtonWrapper>
                </Form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

SignIn.propTypes = {
  handleSubmit: PropTypes.func,
};

SignIn.defaultProps = {
  handleSubmit: () => {
  },
};

const formConfig = {
  form: formName,
  initialValues: {
    [formFields.rememberMe]: true,
  },
  enableReinitialize: true,
};

export default reduxForm(formConfig)(SignIn);
