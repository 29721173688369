// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI
import Link from '@mui/material/Link';
// Parts
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../../../engine/config/selectors';
import { billingActions } from '../../../../../../engine/core/billing/action';

function LimitBalanceOverModalOff() {
  const { t } = useTranslation();
  const statusItems = useSelector(selectors.billing.statusItems);
  const statusPending = useSelector(selectors.billing.statusPending);
  const dispatch = useDispatch();
  const {
    creditLimitModalOffStatus, isActive, partnerOffTime,
    recommendPaymentNotificationSum,
  } = statusItems;
  const localStorageName = 'limitBalanceModalOff';
  const limitBalanceModalTime = localStorage.getItem(localStorageName);
  const [open, setOpen] = useState(
    creditLimitModalOffStatus
    && statusPending !== true
    && Boolean(limitBalanceModalTime) === false,
  );
  const TIMER = (1000 * 60 * 60) * 24; // 60 min * 24h
  const hash = useSelector(selectors.router.hash);

  const showPopUp = useCallback(() => {
    if (!statusPending && (hash.toLowerCase() !== '#showinvoice')) {
      setOpen(true);
      localStorage.removeItem(localStorageName);
    }
  }, [hash, statusPending]);

  const hidePopUp = () => {
    setOpen(false);
    localStorage.setItem(localStorageName, Date.now());
  };

  useEffect(() => {
    if (hash.toLowerCase() === '#showinvoice') {
      hidePopUp();
    }
  }, [hash]);

  const onSend = () => {
    dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    setTimeout(() => {
      hidePopUp();
    }, 400);
  };

  useEffect(() => {
    if (creditLimitModalOffStatus && !limitBalanceModalTime) {
      showPopUp();
    }
  }, [creditLimitModalOffStatus, limitBalanceModalTime, showPopUp]);

  useEffect(() => {
    if (open && creditLimitModalOffStatus === false && Boolean(limitBalanceModalTime) === false) {
      setOpen(false);
    }
  }, [limitBalanceModalTime, creditLimitModalOffStatus, open]);

  useEffect(() => {
    if (limitBalanceModalTime && !open) {
      const interval = setInterval(() => {
        const timePassed = Date.now() - parseInt(localStorage.getItem(localStorageName), 10);
        if (timePassed > TIMER && !open) {
          showPopUp();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [limitBalanceModalTime, open, TIMER, showPopUp]);

  if (partnerOffTime == null) {
    return null;
  }

  return (
    <Modal
      title={t('Уважаемый партнер!')}
      modalOpen={open}
      handleModalSend={onSend}
      sendText={t('Пополнить баланс')}
      handleModalCancel={() => hidePopUp()}
      handleModalToggle={() => hidePopUp()}
    >
      <p>{t('Так как остаток вашего баланса опустился ниже нуля, ваши товары')} {isActive ? t('будут вскоре') : t('были')} {t('выключены с сайта')} <Link href="https://allo.ua/">allo.ua</Link></p>
      <p>{t('Для включения ваших товаров пополните ваш баланс на сумму')} <strong>{recommendPaymentNotificationSum}</strong> грн. </p>
    </Modal>
  );
}

export default LimitBalanceOverModalOff;
