// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  partnersAccessList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  partnerAccess: Map({
    pending: false,
    data: Map(),
  }),
  updateWFP: Map({
    pending: false,
  }),
  putPartnerAccess: Map({
    pending: false,
  }),
  partnerAccessCredentials: Map({
    pending: false,
    data: Map(),
  }),
  putPartnerAccessCredentials: Map({
    pending: false,
  }),
  deletePartnerAccessCredentials: Map({
    pending: false,
  }),
  deletePartnerAccess: Map({
    pending: false,
  }),
  postPartnersPaymentAccesses: Map({
    pending: false,
  }),
  partnerAccessModal: Map({
    isOpen: false,
  }),
  bankModal: Map({
    isOpen: null,
    hashId: undefined,
  }),
  partnersAccessDownload: Map({
    pending: false,
  }),
  partnerPaymentTypeModal: Map({
    isOpen: false,
    hashId: undefined,
    methods: Map({
      pending: false,
      items: List([]),
    }),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'partnersAccess',
  partnerAccess: 'partnerAccess',
  data: 'data',
  isOpen: 'isOpen',
  updateWFP: 'updateWFP',
  partnersAccessDownload: 'partnersAccessDownload',
  partnerPaymentTypeModal: 'partnerPaymentTypeModal',
  putPartnerAccess: 'putPartnerAccess',
  putPartnerAccessCredentials: 'putPartnerAccessCredentials',
  deletePartnerAccessCredentials: 'deletePartnerAccessCredentials',
  deletePartnerAccess: 'deletePartnerAccess',
  partnerAccessCredentials: 'partnerAccessCredentials',
  postPartnersPaymentAccesses: 'postPartnersPaymentAccesses',
  deletePending: 'deletePending',
  partnerAccessModal: 'partnerAccessModal',
  bankModal: 'bankModal',
  partnersAccessList: 'partnersAccessList',
  currentPage: 'currentPage',
  filters: 'filters',
  isNewData: 'isNewData',
  putPending: 'putPending',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
});

export const partnersAccessReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNERS_ACCESS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.partnersAccessList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.partnersAccessList, stateKeys.pending], pending)
        .setIn([stateKeys.partnersAccessList, stateKeys.items], List(items))
        .setIn([stateKeys.partnersAccessList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PARTNERS_ACCESS_SORTING: {
      return state.setIn([stateKeys.partnersAccessList, stateKeys.sorting], List(payload));
    }
    case types.SET_PARTNERS_ACCESS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.partnersAccessList, stateKeys.filters], List(filters));
    }
    case types.SET_PARTNERS_ACCESS_PAGE_SIZE: {
      return state.setIn([stateKeys.partnersAccessList, stateKeys.pageSize], payload);
    }
    case types.SET_PARTNERS_PAYMENT_ACCESSES_PENDING: {
      return state.setIn([stateKeys.postPartnersPaymentAccesses, stateKeys.pending], payload);
    }
    case types.MERGE_PARTNERS_ACCESS_DOWNLOAD: {
      return state.mergeIn([stateKeys.partnersAccessDownload], Map(payload));
    }
    case types.MERGE_PARTNERS_ACCESS_LIST: {
      return state.mergeIn([stateKeys.partnersAccessList], Map(payload));
    }
    case types.PUT_PARTNER_ACCESS: {
      return state.mergeIn([stateKeys.putPartnerAccess], Map(payload));
    }
    case types.PUT_PARTNER_ACCESS_CREDENTIALS: {
      return state.mergeIn([stateKeys.putPartnerAccessCredentials], Map(payload));
    }
    case types.DELETE_PARTNER_ACCESS_CREDENTIALS: {
      return state.mergeIn([stateKeys.deletePartnerAccessCredentials], Map(payload));
    }
    case types.DELETE_PARTNER_ACCESS: {
      return state.mergeIn([stateKeys.deletePartnerAccess], Map(payload));
    }
    case types.SET_PARTNER_ACCESS_MODAL: {
      return state.mergeIn([stateKeys.partnerAccessModal], Map(payload));
    }
    case types.MERGE_PARTNER_PAYMENT_TYPE_MODAL: {
      return state.mergeIn([stateKeys.partnerPaymentTypeModal], Map(payload));
    }
    case types.SET_PARTNER_ACCESS_INFO: {
      return state.mergeIn([stateKeys.partnerAccess], Map(payload));
    }
    case types.SET_PARTNER_ACCESS_CREDENTIALS: {
      return state.mergeIn([stateKeys.partnerAccessCredentials], Map(payload));
    }
    case types.SET_PARTNERS_ACCESS_CURRENT_PAGE: {
      return state.setIn([stateKeys.partnersAccessList, stateKeys.currentPage], payload);
    }
    case types.SET_PARTNERS_ACCESS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.partnersAccessList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.partnersAccessList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.partnersAccessList, stateKeys.filters], List(filters))
        .setIn([stateKeys.partnersAccessList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.partnersAccessList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.partnersAccessList, stateKeys.reload], reload)
        .setIn([stateKeys.partnersAccessList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PARTNERS_ACCESS_SETTING_STATUS: {
      return state.setIn([stateKeys.partnersAccessList, stateKeys.settingStatus], payload);
    }
    case types.SET_PARTNERS_ACCESS_SETTING_PENDING: {
      return state.setIn([stateKeys.partnersAccessList, stateKeys.settingPending], payload);
    }
    case types.MERGE_PARTNERS_ACCESS_UPDATE_WFP: {
      return state.mergeIn([stateKeys.updateWFP], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_BANK_MODAL: {
      return state.mergeIn([stateKeys.bankModal], Map(payload));
    }
    default: {
      return state;
    }
  }
};
