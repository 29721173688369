import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as MaterialLink } from '@mui/material';
import Modal from '../../../containers/Modals/Modal/Modal';
import ModalFooter from '../../../containers/Modals/Modal/ModalFooter/ModalFooter';

export function ConfirmDisableModal({
  onModalOpen, open, isSendingData, onDisableRequest,
}) {
  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              sx={{ mr: '10px' }}
              disabled={false}
              variant="outlined"
              color="primary"
              onClick={() => {
                onModalOpen(false);
              }}
            >
              {'Скасувати'}
            </Button>
            <Button
              disabled={isSendingData}
              variant="contained"
              color="primary"
              onClick={() => {
                onModalOpen(false);
                onDisableRequest();
              }}
            >
              {'Вимкнути послугу'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalFooter>
  );

  return (
    <>
      <Modal
        title={'Бажаєте вимкнути послугу АЛЛО Express?'}
        modalOpen={open}
        handleModalToggle={() => {
          onModalOpen(false);
        }}
        hideButtons
        fullWidth
        noPadding
        modalMaxWidth="sm"
        modalFooterComponent={modalFooterComponent}
      >
        <Grid marginBottom={'15px'} container justifyContent="flex-end" flexDirection="column">
          <Grid sx={{ p: '20px 20px' }} item sm={12}>
            <Typography>Вимкнення послуги призведе до неможливості скористатися доставкою АЛЛО Express для всіх ваших товарів на сайті <MaterialLink target={'_blank'} href={'https://allo.ua/ua/ '}>allo.ua</MaterialLink></Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
