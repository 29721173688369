export const types = Object.freeze({
  SET_FAQ_LIST: 'SET_FAQ_LIST',
  SET_FAQ_SETTING_STATUS: 'SET_FAQ_SETTING_STATUS',
  SET_FAQ_SETTING_PENDING: 'SET_FAQ_SETTING_PENDING',
  SET_FAQ_CURRENT_PAGE: 'SET_FAQ_CURRENT_PAGE',
  SET_FAQ_PAGE_SIZE: 'SET_FAQ_PAGE_SIZE',
  SET_FAQ_SELECTION: 'SET_FAQ_SELECTION',
  SET_FAQ_FILTERS: 'SET_FAQ_FILTERS',
  SET_FAQ_RELOAD: 'SET_FAQ_RELOAD',
  SET_FAQ_STATUSES_LIST: 'SET_FAQ_STATUSES_LIST',
  SET_FAQ_SORTING: 'SET_FAQ_SORTING',
  SET_FAQ_STATUSES_LIST_PENDING: 'SET_FAQ_STATUSES_LIST_PENDING',
  SET_FAQ_INITIAL_STATE: 'SET_FAQ_INITIAL_STATE',
  SET_IS_FAQ_ITEM_BY_HASH_ID_LOADING: 'SET_IS_FAQ_ITEM_BY_HASH_ID_LOADING',
  SET_IS_FAQ_ITEM_BY_HASH_PUT_PENDING: 'SET_IS_FAQ_ITEM_BY_HASH_PUT_PENDING',
  SET_IS_FAQ_DELETE_PENDING: 'SET_IS_FAQ_DELETE_PENDING',
});
