// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function NotificationIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
      <path xmlns="http://www.w3.org/2000/svg" d="M5.44926 17.8614H8.9738C9.03383 19.4907 10.3802 21 12.344 21C14.3078 21 15.6541 19.4993 15.7227 17.8614H19.2473C20.1391 17.8614 20.6879 17.3726 20.6879 16.6351C20.6879 15.7175 19.8561 14.9371 19.0758 14.191C18.4669 13.5993 18.3211 12.4159 18.2096 11.2068C18.0896 7.9738 17.1463 5.81277 14.9081 5.00667C14.5736 3.86613 13.6217 3 12.344 3C11.0662 3 10.1143 3.86613 9.78847 5.00667C7.55026 5.81277 6.60696 7.9738 6.47832 11.2068C6.36684 12.4159 6.22106 13.5993 5.6122 14.191C4.83182 14.9371 4 15.7175 4 16.6351C4 17.3726 4.54883 17.8614 5.44926 17.8614ZM6.09242 16.2663V16.1634C6.31539 15.9147 6.81277 15.4602 7.23297 14.98C7.82468 14.3025 8.09909 13.0505 8.18485 11.4212C8.28776 7.98237 9.35112 6.82468 10.7232 6.45593C10.9376 6.3959 11.0491 6.293 11.0662 6.06146C11.1177 5.19533 11.5979 4.6122 12.344 4.6122C13.0986 4.6122 13.5788 5.19533 13.6217 6.06146C13.6389 6.293 13.7589 6.3959 13.9647 6.45593C15.3454 6.82468 16.4002 7.98237 16.5117 11.4212C16.5974 13.0505 16.8718 14.3025 17.455 14.98C17.8752 15.4602 18.3554 15.9147 18.5869 16.1634V16.2663H6.09242ZM12.344 19.6193C11.3835 19.6193 10.6803 18.9247 10.6203 17.8614H14.0762C14.0248 18.9162 13.3216 19.6193 12.344 19.6193Z" />
    </SvgIcon>
  );
}

export default NotificationIcon;
