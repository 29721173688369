// Core
import React, { memo } from 'react';
import { PagingState } from '@devexpress/dx-react-grid';
import * as PropTypes from 'prop-types';

function DxPagingState(props) {
  const {
    currentPage, pageSize, onPageSize, onCurrentPage,
  } = props;

  const setPageSize = (size) => {
    onPageSize(size);
    onCurrentPage(0);
  };

  return (
    <PagingState
      currentPage={currentPage}
      onCurrentPageChange={onCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  );
}

DxPagingState.propTypes = {
  onPageSize: PropTypes.func,
  onCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

export default memo(DxPagingState);
