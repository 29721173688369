import { COLOR } from '../../containers/App/AppStyles';

const LanguageMenuStyles = () => ({
  langMenuButton: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR.darkStyle.fontColorMain,
    padding: '7px 6px 5px 10px',
    flexShrink: 0,
    marginRight: '10px',
    minWidth: '89px',
    '&:hover, &:focus, &:active': {
      background: COLOR.darkStyle.white['8'],
    },
    '&.Mui-disabled': {
      '& $langFlag': {
        opacity: 0.6,
      },
      '& $langMenuDropIcon': {
        color: COLOR.grey['24'],
      },
    },
  },
  langMenu: {
    zIndex: 301,
    '& .MuiPaper-root': {
      borderRadius: '5px',
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
      background: COLOR.darkStyle.main,
      zIndex: 1300,
    },
    '& .MuiList-root': {
      padding: '4px 2px',
    },
    '& .MuiMenuItem-root': {
      minHeight: '40px',
      borderRadius: '5px',
      padding: '5px 13px',
      fontSize: '14px',
      lineHeight: 1,
      color: COLOR.darkStyle.fontColorMain,
      '&:hover': {
        background: COLOR.darkStyle.white['8'],
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '5px',
      },
      '& .MuiTypography-root': {
        fontSize: '14px',
        lineHeight: 1,
        color: COLOR.darkStyle.fontColorMain,
      },
      '& .MuiSvgIcon-root': {
        color: COLOR.darkStyle.fontColorMain,
      },
    },
  },
  openMenu: {
    background: COLOR.darkStyle.white['8'],
    zIndex: 1301,
    '& $langMenuDropIcon': {
      transform: 'rotate(180deg)',
    },
  },
  langMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '8px',
  },
  langFlag: {
    width: '24px',
    height: '16px',
    background: '#626262',
    borderRadius: '2px',
    overflow: 'hidden',
    marginBottom: '8px',
    position: 'relative',
  },
  ukraineFlag: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: '50%',
      background: '#005BBB',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: '50%',
      background: '#FFD500',
    },
  },
  langLabel: {
    fontSize: '10px',
    lineHeight: 1,
    fontWeight: 700,
  },
  langMenuDropIcon: {
    width: '16px',
    height: '16px',
  },

});

export default LanguageMenuStyles;
