// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function PenMenuOutlinedIcon(props) {
  return (
    <SvgIcon className="pen-menu-outlined-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" opacity="1">
        <path d="M13.3925 1.5633C13.031 1.20259 12.5412 1 12.0306 1C11.5199 1 11.0301 1.20259 10.6686 1.5633L9.98661 2.24879L2.72729 9.50394L2.71203 9.51921C2.70856 9.52267 2.70856 9.52684 2.7044 9.52684C2.69676 9.53863 2.68497 9.54973 2.67734 9.56153C2.66971 9.57332 2.67317 9.56569 2.67317 9.56916C2.67317 9.57263 2.66138 9.58859 2.65375 9.59969C2.64612 9.61079 2.65028 9.60732 2.64612 9.61148C2.64195 9.61564 2.63848 9.63091 2.63432 9.64201C2.63016 9.65311 2.63085 9.64548 2.63085 9.64964L1.02052 14.4938C0.997351 14.5613 0.993719 14.634 1.01004 14.7035C1.02637 14.7729 1.06199 14.8364 1.1128 14.8865C1.18563 14.9583 1.28388 14.9985 1.38616 14.9982C1.42802 14.9975 1.46958 14.9909 1.50966 14.9788L6.34897 13.3643C6.35244 13.3643 6.35244 13.3643 6.3566 13.3608C6.36881 13.3573 6.38048 13.3522 6.39129 13.3456C6.39413 13.3452 6.3968 13.344 6.39893 13.3421C6.41072 13.3345 6.42598 13.3268 6.43708 13.3185C6.44819 13.3102 6.45998 13.2991 6.47178 13.2914C6.48357 13.2838 6.47941 13.2873 6.47941 13.2838C6.47941 13.2803 6.4912 13.2762 6.49467 13.2685L14.4367 5.32791C14.7974 4.96644 15 4.47663 15 3.96596C15 3.4553 14.7974 2.96549 14.4367 2.60402L13.3925 1.5633ZM6.22617 12.4596L3.5446 9.778L10.2565 3.06609L12.9346 5.74766L6.22617 12.4596ZM3.16717 10.4905L5.51085 12.8328L1.99324 14.004L3.16717 10.4905ZM13.8934 4.78813L13.4848 5.20025L10.8032 2.51868L11.2153 2.10656C11.4321 1.89002 11.7259 1.76838 12.0323 1.76838C12.3387 1.76838 12.6325 1.89002 12.8493 2.10656L13.8969 3.15421C14.1121 3.37185 14.2326 3.66578 14.2319 3.97188C14.2313 4.27798 14.1096 4.5714 13.8934 4.78813Z" strokeWidth="0.3" />
      </g>
    </SvgIcon>
  );
}

export default PenMenuOutlinedIcon;
