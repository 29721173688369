// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';

// Instruments
import { types } from './types';
import { tableColumns } from '../../../_helpers/data/pages/contentMenagement/contentTemplates/tableColumns';
import { pageSizes } from '../../config/globalConfig';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  brandsList: Map({
    items: List(),
    pending: false,
    totalCount: 2,
  }),
  changeStatusModal: Map({
    isOpen: false,
    statusValue: '',
  }),
  contentProductActions: Map({
    changeStatus: { label: 'Сменить статус', value: 'changeStatus' },
  }),
  changeStatusModalStatusesList: List([
    { label: 'Ожидают модерацию', value: 0 },
    { label: 'Отклонен', value: 1 },
    { label: 'Одобрен', value: 2 },
    { label: 'Отправлен на доработку', value: 3 },
  ]),
  categoriesList: Map({
    active: undefined,
    pending: true,
    items: List(),
  }),
  productGrid: Map({
    items: List(),
    pending: true,
  }),
  productSource: Map({
    items: List(),
    pending: true,
  }),
  categoriesStatistic: Map({
    pending: true,
    quantityByCategories: List(),
    quantityByStatus: List(),
  }),
  contentTemplatesTable: Map({
    columns: List([
      { name: tableColumns.label.name, title: tableColumns.label.title },
    ]),
    currentPage: 0,
    filters: List(),
    items: List(),
    pageSize: toNumber(pageSizes[0]),
    pending: true,
    reload: false,
    sorting: List(),
    tableColumnExtensions: List([
      { columnName: tableColumns.label.name, width: 250 },
    ]),
    totalCount: 0,
  }),
  productCategoryCountModal: Map({
    productStatusId: null,
    categoryId: null,
    isOpen: false,
    modalTitle: '',
  }),
  contentUpdateFromMagento: Map({
    pending: false,
    categoryId: null,
  }),
  contentUpdateValueFromMagento: Map({
    pending: false,
    featureId: null,
  }),
  productDeleteModal: Map({
    isOpen: false,
    pending: false,
  }),
  confirmModal: Map({
    isOpen: false,
    text: '',
    params: Map(),
  }),
  productsFeatureValuesListDownload: Map(),
  allProductsTable: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pageSize: toNumber(pageSizes[0]),
    pending: true,
    reload: false,
    selection: List(),
    sorting: List(),
    totalCount: 0,
  }),
  productDownloadPending: false,
  productsDownloadForceAction: Map({
    forceAction: null,
    type: null,
  }),
  productStatuses: List(),
  productCreate: Map({
    pending: false,
  }),
  productsStatusesPending: false,
  productsImportPending: false,
  cancelStatuses: List(),
  statusesFlow: Map(),
  moderationPending: null,
  productByHashId: Map({
    item: Map(),
    pending: true,
  }),
  productEdit: Map({
    pending: false,
  }),
  productsSettings: Map({
    pending: false,
  }),
  productEditResultModal: Map({
    isOpen: false,
    successCounter: 0,
    notSuccessCounter: 0,
    notSuccessIds: List(),
  }),
  importModal: Map({
    isOpen: false,
    onlyText: false,
    hideSubmitButton: false,
  }),
  importPropertiesModal: Map({
    isOpen: false,
    onlyText: false,
    hideSubmitButton: false,
  }),
  importPricesModal: Map({
    isOpen: false,
    hideSubmitButton: false,
  }),
  import: Map({
    pending: false,
    error: Map({
      statusTitle: null,
      errors: List([]),
    }),
  }),
});

export const stateKeys = Object.freeze({
  active: 'active',
  productEdit: 'productEdit',
  productStatusId: 'productStatusId',
  allProductsTable: 'allProductsTable',
  brandsList: 'brandsList',
  categoriesList: 'categoriesList',
  cancelStatuses: 'cancelStatuses',
  changedValue: 'changedValue',
  changeStatus: 'changeStatus',
  fillingPercent: 'fillingPercent',
  productsStatusesPending: 'productsStatusesPending',
  productsSettings: 'productsSettings',
  changeStatusModal: 'changeStatusModal',
  categoriesStatistic: 'categoriesStatistic',
  quantityByCategories: 'quantityByCategories',
  contentUpdateValueFromMagento: 'contentUpdateValueFromMagento',
  featureId: 'featureId',
  quantityByStatus: 'quantityByStatus',
  changeStatusModalStatusesList: 'changeStatusModalStatusesList',
  columns: 'columns',
  contentTemplatesTable: 'contentTemplatesTable',
  controller: 'content',
  contentProductActions: 'contentProductActions',
  currentPage: 'currentPage',
  contentUpdateFromMagento: 'contentUpdateFromMagento',
  hideSubmitButton: 'hideSubmitButton',
  error: 'error',
  categoryId: 'categoryId',
  filters: 'filters',
  isOpen: 'isOpen',
  item: 'item',
  items: 'items',
  pageSize: 'pageSize',
  pending: 'pending',
  productByHashId: 'productByHashId',
  productCreate: 'productCreate',
  productEditResultModal: 'productEditResultModal',
  productCategoryCountModal: 'productCategoryCountModal',
  modalTitle: 'modalTitle',
  productGrid: 'productGrid',
  productsFeatureValuesListDownload: 'productsFeatureValuesListDownload',
  productStatuses: 'productStatuses',
  reload: 'reload',
  selection: 'selection',
  sorting: 'sorting',
  statusValue: 'statusValue',
  statusesFlow: 'statusesFlow',
  success: 'success',
  tableColumnExtensions: 'tableColumnExtensions',
  totalCount: 'totalCount',
  value: 'value',
  moderationPending: 'moderationPending',
  productDeleteModal: 'productDeleteModal',
  productTranslateModal: 'productTranslateModal',
  productDownloadPending: 'productDownloadPending',
  productsDownloadForceAction: 'productsDownloadForceAction',
  productsImportPending: 'productsImportPending',
  productSource: 'productSource',
  importModal: 'importModal',
  importPropertiesModal: 'importPropertiesModal',
  importPricesModal: 'importPricesModal',
  import: 'import',
  onlyText: 'onlyText',
  confirmModal: 'confirmModal',
});

const allProductsTableTotalCountKeyPath = [stateKeys.allProductsTable, stateKeys.totalCount];
const allProductsTableCurrentPageKeyPath = [stateKeys.allProductsTable, stateKeys.currentPage];
const allProductsTableFiltersKeyPath = [stateKeys.allProductsTable, stateKeys.filters];
const allProductsTablePageSizeKeyPath = [stateKeys.allProductsTable, stateKeys.pageSize];
const allProductsTableReloadKeyPath = [stateKeys.allProductsTable, stateKeys.reload];

export const contentReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.CONTENT_PRODUCT_DISPATCH_ACTION: {
      if (initialState.getIn([stateKeys.contentProductActions, stateKeys.changeStatus]).value === payload) {
        return state.setIn([stateKeys.changeStatusModal, stateKeys.isOpen], true);
      }
      return state;
    }
    case types.SET_IS_CONTENT_PRODUCT_CHANGE_MODAL_OPEN: {
      return state.setIn([stateKeys.changeStatusModal, stateKeys.isOpen], payload);
    }
    case types.SEND_PRODUCTS_TO_MODERATION: {
      return state.setIn([stateKeys.moderationPending], payload);
    }
    case types.SET_IS_CONTENT_UPDATE_FROM_MAGENTO_PENDING: {
      return state.setIn([stateKeys.contentUpdateFromMagento, stateKeys.pending], payload);
    }
    case types.MERGE_CONTENT_UPDATE_VALUE_FROM_MAGENTO: {
      return state.setIn([stateKeys.contentUpdateValueFromMagento], payload);
    }
    case types.SET_PRODUCT_EDIT_PENDING: {
      return state.setIn([stateKeys.productEdit, stateKeys.pending], payload);
    }
    case types.SET_IS_CONTENT_UPDATE_FROM_MAGENTO_CATEGORY: {
      return state.setIn([stateKeys.contentUpdateFromMagento, stateKeys.categoryId], payload);
    }
    case types.SET_PRODUCT_CREATE: {
      return state.mergeIn([stateKeys.productCreate], payload);
    }
    case types.SET_CONTENT_PRODUCT_STATUS_VALUE: {
      return state.setIn([stateKeys.changeStatusModal, stateKeys.statusValue], payload);
    }
    case types.SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING: {
      return state.setIn([stateKeys.categoriesList, stateKeys.pending], payload);
    }
    case types.SET_PRODUCT_CATEGORIES_LIST: {
      return state.setIn([stateKeys.categoriesList, stateKeys.items], List(payload));
    }
    case types.SET_PRODUCTS_IMPORT_MODAL_IS_OPEN: {
      return state.mergeIn([stateKeys.importModal], fromJS(payload));
    }
    case types.SET_PRODUCTS_IMPORT_PROPERTIES_MODAL_IS_OPEN: {
      return state.mergeIn([stateKeys.importPropertiesModal], fromJS(payload));
    }
    case types.SET_PRODUCTS_IMPORT_PRICES_MODAL_IS_OPEN: {
      return state.mergeIn([stateKeys.importPricesModal], fromJS(payload));
    }
    case types.SET_PRODUCT_GRID: {
      return state.setIn([stateKeys.productGrid, stateKeys.items], List(payload));
    }
    case types.SET_PRODUCT_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.productDownloadPending], payload);
    }
    case types.SET_CONTENT_PRODUCTS_DOWNLOAD_FORCE_ACTION: {
      return state.setIn([stateKeys.productsDownloadForceAction], Map(payload));
    }
    case types.SET_PRODUCT_IMPORT_PENDING: {
      return state.setIn([stateKeys.productsImportPending], payload);
    }
    case types.SET_QUANTITY_BY_STATUS: {
      return state.setIn([stateKeys.categoriesStatistic, stateKeys.quantityByStatus], List(payload));
    }
    case types.SET_QUANTITY_BY_CATEGORY: {
      return state.setIn([stateKeys.categoriesStatistic, stateKeys.quantityByCategories], List(payload));
    }

    case types.SET_IS_CATEGORY_STATISTIC_LOAD: {
      return state.setIn([stateKeys.categoriesStatistic, stateKeys.pending], payload);
    }
    case types.SET_PRODUCT_GRID_IS_LOADING: {
      return state.setIn([stateKeys.productGrid, stateKeys.pending], payload);
    }
    case types.SET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_STATE: {
      const downloadState = Object.values(payload || {});
      return downloadState.includes(true)
        ? state.mergeIn([stateKeys.productsFeatureValuesListDownload], payload)
        : state.deleteIn([stateKeys.productsFeatureValuesListDownload, Object.keys(payload).join()]);
    }
    case types.SET_ALL_PRODUCTS_TOTAL_COUNT: {
      return state.setIn(allProductsTableTotalCountKeyPath, toNumber(payload));
    }
    case types.SET_IS_ALL_PRODUCTS_LIST_LOADING: {
      return state.setIn([stateKeys.allProductsTable, stateKeys.pending], payload);
    }
    case types.SET_ALL_PRODUCTS_PAGE_SIZE: {
      return state.setIn(allProductsTablePageSizeKeyPath, toNumber(payload));
    }
    case types.SET_ALL_PRODUCTS_CURRENT_PAGE: {
      return state.setIn([stateKeys.allProductsTable, stateKeys.currentPage], toNumber(payload));
    }
    case types.SET_ALL_PRODUCTS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn(allProductsTableFiltersKeyPath, List(filters));
    }
    case types.SET_ALL_PRODUCTS_SORTING: {
      return state.setIn([stateKeys.allProductsTable, stateKeys.sorting], List(payload));
    }
    case types.SET_PRODUCTS_STATUSES_PENDING: {
      return state.set(stateKeys.productsStatusesPending, payload);
    }
    case types.SET_ALL_PRODUCTS_STATUSES: {
      return state.withMutations((mutable) => {
        const { cancelStatuses, productStatuses, statusesFlow } = payload;
        mutable
          .setIn([stateKeys.productStatuses], List(productStatuses))
          .setIn([stateKeys.cancelStatuses], List(cancelStatuses))
          .setIn([stateKeys.statusesFlow], Map(statusesFlow));
      });
    }

    case types.SET_PRODUCT_BY_HASH_ID: {
      return state.setIn([stateKeys.productByHashId, stateKeys.item], Map(payload));
    }

    case types.SET_IS_PRODUCT_BY_HASH_ID_LOADING: {
      return state.setIn([stateKeys.productByHashId, stateKeys.pending], payload);
    }

    case types.SET_PRODUCT_SELECTION: {
      return state.setIn([stateKeys.allProductsTable, stateKeys.selection], List(payload));
    }

    case types.SET_IS_PRODUCT_EDIT_RESULT_MODAL_OPEN: {
      if (payload === false) {
        return state.mergeIn([stateKeys.productEditResultModal], initialState.get(stateKeys.productEditResultModal));
      }
      return state.mergeIn([stateKeys.productEditResultModal], fromJS(payload));
    }
    case types.SET_IS_PRODUCT_CATEGORY_COUNT_MODAL: {
      return state.mergeIn([stateKeys.productCategoryCountModal], fromJS(payload));
    }
    case types.SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE: {
      return state.setIn([stateKeys.importModal], initialState.get(stateKeys.importModal));
    }
    case types.SET_IS_PRODUCT_CATEGORY_COUNT_MODAL_CATEGORY: {
      return state.setIn([stateKeys.productCategoryCountModal, stateKeys.categoryId], payload);
    }
    case types.SET_PRODUCTS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, reload, sorting = [],
      } = payload;

      const currentSorting = sorting.length
        ? List(sorting)
        : initialState.getIn([stateKeys.allProductsTable, stateKeys.sorting]);

      return state.withMutations((mutable) => {
        mutable
          .setIn(allProductsTableCurrentPageKeyPath, toNumber(currentPage))
          .setIn(allProductsTableFiltersKeyPath, fromJS(filters))
          .setIn(allProductsTablePageSizeKeyPath, toNumber(pageSize))
          .setIn(allProductsTableTotalCountKeyPath, toNumber(totalCount))
          .setIn(allProductsTableReloadKeyPath, reload)
          .setIn([stateKeys.allProductsTable, stateKeys.sorting], currentSorting);
      });
    }
    case types.SET_PRODUCT_DELETE_MODAL: {
      return state.mergeIn([stateKeys.productDeleteModal], payload);
    }
    case types.SET_PRODUCT_TRANSLATE_MODAL: {
      return state.mergeIn([stateKeys.productTranslateModal], payload);
    }
    case types.SET_PRODUCT_SOURCE_LIST: {
      return state.setIn([stateKeys.productSource, stateKeys.items], List(payload));
    }
    case types.SET_PRODUCT_SOURCE_LIST_PENDING: {
      return state.setIn([stateKeys.productSource, stateKeys.pending], payload);
    }
    case types.SET_PRODUCTS_SETTINGS_PENDING: {
      return state.setIn([stateKeys.productsSettings, stateKeys.pending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_IMPORT_PENDING: {
      return state.setIn([stateKeys.import, stateKeys.pending], payload);
    }
    case types.SET_PRODUCTS_CONFIRM_MODAL: {
      return state.mergeIn([stateKeys.confirmModal], payload);
    }

    default: {
      return state;
    }
  }
};

function toNumber(str = '0', radix = 10) {
  return Number.parseInt(str, radix);
}
