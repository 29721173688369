// core
import React from 'react';
import PropTypes from 'prop-types';

// UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import cx from 'classnames';

// Style
import TabsStyles from './TabsStyles';

const useStyles = makeStyles(TabsStyles);

function TabsContainer(props) {
  const { children, className } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      className={cx(classes.tabsContainer, {
        [className]: className,
      })}
    >
      {children}
    </Grid>
  );
}

TabsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TabsContainer;
