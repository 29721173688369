// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  list: Map({
    currentPage: 0,
    filters: List([{ columnName: 'filterType', value: 1 }]),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'feedsAttribute',
  reload: 'reload',
  list: 'list',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
});

export const feedsAttributeSetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ATTRIBUTE_SET_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.list, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.list, stateKeys.pending], pending)
        .setIn([stateKeys.list, stateKeys.items], List(items))
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount);
    }
    case types.SET_ATTRIBUTE_SET_SORTING: {
      return state.setIn([stateKeys.list, stateKeys.sorting], List(payload));
    }
    case types.SET_ATTRIBUTE_RELOAD: {
      return state.setIn([stateKeys.list, stateKeys.reload], payload);
    }
    case types.SET_ATTRIBUTE_SET_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.list, stateKeys.filters], List(filters));
    }
    case types.SET_ATTRIBUTE_SET_PAGE_SIZE: {
      return state.setIn([stateKeys.list, stateKeys.pageSize], payload);
    }
    case types.SET_ATTRIBUTE_SET_CURRENT_PAGE: {
      return state.setIn([stateKeys.list, stateKeys.currentPage], payload);
    }
    case types.SET_ATTRIBUTE_SET_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.list, stateKeys.sorting]);
      return state
        .setIn([stateKeys.list, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.list, stateKeys.filters], List(filters))
        .setIn([stateKeys.list, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.list, stateKeys.reload], reload)
        .setIn([stateKeys.list, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_ATTRIBUTE_SET_SETTING_STATUS: {
      return state.setIn([stateKeys.list, stateKeys.settingStatus], payload);
    }
    case types.SET_ATTRIBUTE_SET_SETTING_PENDING: {
      return state.setIn([stateKeys.list, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.UPDATE_SET_ATTRIBUTE_SET_LIST: {
      const {
        key, hashId, error, label,
        pending, success, value,
      } = payload;
      const partnersManagersList = state.getIn([stateKeys.list, stateKeys.items]);
      const index = partnersManagersList.findIndex(product => product.hashId === hashId);
      const currentProduct = state.getIn([stateKeys.list, stateKeys.items, index, key]);

      const featureState = {
        error,
        pending,
        label,
        success,
      };
      if (!isNil(value)) {
        featureState.value = value;
      }
      const assignedTo = { ...currentProduct, ...featureState };
      return state.setIn([stateKeys.list, stateKeys.items, index, key], assignedTo);
    }

    default: {
      return state;
    }
  }
};
