// Core
import React from 'react';
import { func } from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { replace } from 'redux-first-history';
import { useTranslation, Trans } from 'react-i18next';
import { getAccessToken } from 'axios-jwt';
// UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Header from '../../../components/Header/Header';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import MainLogoImage from '../components/MainLogoImage/MainLogoImage';
import ButtonWrapper from '../components/ButtonWrapper';
import Logo from '../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../assets/img/logo_short.svg';
// Helpers
import { validators } from '../../../../_helpers/validationsForm';
import AuthenticationStyle from '../AuthenticationStyle';
import { formFields, formName } from './form';
import { useStyles } from '../../../hooks/useStyles';
// Engine
import { routersLink } from '../../../../engine/config/routes';
import { userActionAsync } from '../../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../../engine/config/selectors';

function Confirm(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectors.user.userInfo);
  const classes = useStyles(AuthenticationStyle);
  const onBack = () => {
    dispatch(userActionAsync.authCleanUp());
    dispatch(replace(routersLink.users.signIn));
  };

  function onFormSubmit(formData) {
    if (formData && formData.toJS) {
      const jsFormData = formData.toJS();
      const confirmCode = jsFormData[formFields.confirmCod];
      if (typeof confirmCode === 'string' && confirmCode.trim() !== '') {
        dispatch(userActionAsync.confirmCodeAsync(confirmCode));
      }
    }
  }

  if (!getAccessToken()) {
    return <Navigate to={routersLink.users.signIn} />;
  } if (userInfo.enabled) {
    return <Navigate to={routersLink.dashboard} />;
  }
  return (
    <div className={classes.session}>
      <Header
        logoAltText={t('АЛЛО - личный кабинет')}
        logo={Logo}
        LogoMobile={LogoMobile}
        onlyLogo
      />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="text-xs-center pb-xs">
                  <MainLogoImage />
                  <Typography variant="subtitle2" display="block">
                    <Trans>
                      Уважаемый партнер! Вам на электронную почту, которую Вы указали при регистрации, был направлен код подтверждения. Пожалуйста, введите в поле ниже.
                    </Trans>
                  </Typography>
                </div>
                <Field
                  className={classes.textField}
                  component={TextField}
                  fullWidth
                  label={t('Введите код')}
                  margin="normal"
                  name={formFields.confirmCod}
                  validation
                  validate={[validators.required]}
                />
                {' '}
                <Button
                  sx={{ marginTop: '16px' }}
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {t('Зарегистрироваться')}
                </Button>
                <ButtonWrapper>
                  <Button onClick={onBack}>{t('Назад')}</Button>
                </ButtonWrapper>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

Confirm.propTypes = {
  handleSubmit: func,
};

Confirm.defaultProps = {
  handleSubmit: () => {},
};

export default reduxForm({
  form: formName,
})(Confirm);
