export const types = Object.freeze({
  SET_LOGISTICS_DATA_BY_CATEGORY_LIST: 'SET_LOGISTICS_DATA_BY_CATEGORY_LIST',
  SET_LOGISTICS_DATA_BY_CATEGORY_CURRENT_PAGE: 'SET_LOGISTICS_DATA_BY_CATEGORY_CURRENT_PAGE',
  SET_LOGISTICS_DATA_BY_CATEGORY_FILTERS: 'SET_LOGISTICS_DATA_BY_CATEGORY_FILTERS',
  SET_LOGISTICS_DATA_BY_CATEGORY_RELOAD: 'SET_LOGISTICS_DATA_BY_CATEGORY_RELOAD',
  SET_LOGISTICS_DATA_BY_CATEGORY_PAGE_SIZE: 'SET_LOGISTICS_DATA_BY_CATEGORY_PAGE_SIZE',
  SET_LOGISTICS_DATA_BY_CATEGORY_SORTING: 'SET_LOGISTICS_DATA_BY_CATEGORY_SORTING',
  SET_LOGISTICS_DATA_BY_CATEGORY_ITEM: 'SET_LOGISTICS_DATA_BY_CATEGORY_ITEM',
  SET_LOGISTICS_DATA_BY_CATEGORY_TREE_DATA_ROW_IDS: 'SET_LOGISTICS_DATA_BY_CATEGORY_TREE_DATA_ROW_IDS',
  SET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE: 'SET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE',
  SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_STATUS: 'SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_STATUS',
  SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_PENDING: 'SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_PENDING',
  SET_LOGISTICS_DATA_BY_CATEGORY_SELECTION: 'SET_LOGISTICS_DATA_BY_CATEGORY_SELECTION',
  MERGE_LOGISTICS_DATA_BY_CATEGORY_IMPORT: 'MERGE_LOGISTICS_DATA_BY_CATEGORY_IMPORT',
  MERGE_LOGISTICS_DATA_BY_CATEGORY_EXPORT: 'MERGE_LOGISTICS_DATA_BY_CATEGORY_EXPORT',
  SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_DATA: 'SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_DATA',
  SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_MODE: 'SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_MODE',
  SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_PENDING_ID: 'SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_PENDING_ID',
  SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_PUT_PENDING: 'SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_PUT_PENDING',
  SET_LOGISTICS_DATA_BY_CATEGORY_LOGS: 'SET_LOGISTICS_DATA_BY_CATEGORY_LOGS',
  SET_LOGISTICS_DATA_BY_CATEGORY_LOGS_DATA: 'SET_LOGISTICS_DATA_BY_CATEGORY_LOGS_DATA',
});
