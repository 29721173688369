// Core
import React, { useEffect, useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
// Parts
import Select from '../../../../_Form/Selects/Select/Select';
import ConfirmButtons from '../../../../Buttons/ConfirmButtons/ConfirmButtons';
// Styles
import selectWidthConfirmStyles from './SelectWidthConfirmStyles';

const useStyles = makeStyles(selectWidthConfirmStyles);

function SelectWidthConfirm(props) {
  const {
    error, disabled, featureName, label, showDeleteButton, isStringTypeValue,
    onConfirm, options, productId, success, value, valuesPlain, required, type,
  } = props;

  const classes = useStyles();
  const defaultValue = { value: null, label: null };
  const initValue = isNil(value) ? defaultValue : value;
  const [selectedValue, setSelectedValue] = useState(initValue);
  const [currentProductId, setCurrentProductId] = useState(productId);

  const handleChange = (newValue) => {
    setSelectedValue(newValue.value);
  };

  const onSelectReject = (rejectValue) => {
    setSelectedValue(rejectValue);
  };

  const onSelectConfirm = (payload) => {
    onConfirm({ [featureName]: get(payload, 'value', payload) });
  };

  useEffect(() => {
    setCurrentProductId(productId);
  }, [productId]);

  if (currentProductId !== productId) {
    return null;
  }

  return (
    <ConfirmButtons
      key={productId + value}
      error={error}
      disabled={disabled}
      required={required}
      notEmpty={selectedValue !== null && selectedValue.value !== null}
      onConfirm={onSelectConfirm}
      onReject={onSelectReject}
      success={success}
      value={selectedValue}
      valuesPlain={valuesPlain}
      type={type}
      initValue={initValue}
      featureName={featureName}
      showDeleteButton={showDeleteButton}
      isStringTypeValue={isStringTypeValue}
    >
      <Select
        disabled={disabled}
        handleChange={handleChange}
        options={options}
        value={selectedValue}
        label={label}
        filterValueBy="value"
        variant="outlined"
        wrapperClassName={classes.select}
      />
    </ConfirmButtons>
  );
}

SelectWidthConfirm.propTypes = {
  error: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  success: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ),
  productId: PropTypes.number.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isStringTypeValue: PropTypes.bool,
};

SelectWidthConfirm.defaultProps = {
  error: false,
  required: false,
  disabled: false,
  success: false,
  showDeleteButton: true,
  label: '',
  valuesPlain: '',
  options: [],
};

export default memo(SelectWidthConfirm);
