import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';

const BaseModal = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    margin: 0,
    overflow: 'scroll',
    height: '100%',
  },
}));

export default BaseModal;
