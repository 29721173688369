// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Parts
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
// Engine
import { actions } from '../../../../../engine/core/stopList/BrandsCategories/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';


function AddButtonAndModal() {
  const { t } = useTranslation();
  const hasAccess = useAccessList(accessList.stopBrandsCategoriesCreate);
  const dispatch = useDispatch();
  const modalOpen = useSelector(selectors.stopListBrandsCategories.addModalIsOpen);

  const handleModalToggle = () => {
    dispatch(actions.mergeAdd({ isModalOpen: !modalOpen }));
  };

  if (!hasAccess) {
    return null;
  }

  return (
    <>
      <ButtonWithIcon text={t('Добавить')} onClick={handleModalToggle}>
        <AddCircleIcon />
      </ButtonWithIcon>
    </>
  );
}

export default AddButtonAndModal;
