// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import TransactionHistoryTable from './components/Table/TransactionHistoryTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../../components/_Table/GridSettings/GridSettingsButton';
import GenerateReportModal from '../Reports/components/GenerateReport/GenerateReportModal';
import GenerateReportButton from '../Reports/components/GenerateReport/GenerateReportButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useTransactionHistoryList } from './_hooks/useTransactionHistoryList';
// Engine
// import { asyncActions } from '../../../../engine/core/billing/history/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/billing/history/actions';
import { initialState, stateKeys } from '../../../../engine/core/billing/history/reducer';

function TransactionHistoryPage() {
  const { t } = useTranslation();
  useTransactionHistoryList();
  const dispatch = useDispatch();
  // const settingStatus = useSelector(selectors.transactionHistory.settingStatus);
  const settingPending = useSelector(selectors.transactionHistory.settingPending);
  const isLoading = useSelector(selectors.transactionHistory.isLoading);
  const isOpenReportModal = useSelector(selectors.reports.reportModalIsOpen);
  // const sorting = useSelector(selectors.transactionHistory.sorting);
  const filters = useSelector(selectors.transactionHistory.filters);
  const sorting = useSelector(selectors.transactionHistory.sorting);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);
  const resetFilters = useCallback(() => {
    dispatch(actions.setSorting(initialState.get(stateKeys.transactionHistoryList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setFilters(initialState.get(stateKeys.transactionHistoryList).get(stateKeys.filters).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.transactionHistoryList).get(stateKeys.currentPage)));
  }, [dispatch]);

  // function putSetting() {
  //   dispatch(asyncActions.putSettingsAsync());
  // }

  return (
    <>
      <PageHeader title={t('История транзакций')}>
        <GenerateReportButton />
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={putSetting} */}
        {/* /> */}
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <TransactionHistoryTable />
      {isOpenReportModal && <GenerateReportModal />}
    </>
  );
}

export default TransactionHistoryPage;
