// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutUserInfoWorker({ payload }) {
  const isPhoneChange = Object.keys(payload).includes('phonePending');
  yield put(userActions.mergePutUserInfo({ pending: true }));
  const fixedAddEmails = payload?.additionalEmails && payload?.additionalEmails.toJS().map(item => item.value);
  const fixedPayload = {
    ...payload,
    additionalEmails: fixedAddEmails,
  };

  const response = yield apply(api, api.user.putUserInfo, [fixedPayload]);
  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;

      switch (status) {
        case 'success': {
          const { message } = response.data;
          if (isPhoneChange) {
            yield put(userActions.mergePutUserInfo({ newPhoneValue: payload.phonePending }));
            yield put(userActions.mergePutUserInfo({ sendNewPhoneStatus: true }));
          }

          yield put(setSuccessMessage(message, statusTitle));
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle, true));
          if (Object.keys(payload).includes('phonePending')) {
            yield put(userActions.mergePutUserInfo({ sendNewPhoneStatus: false }));
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  yield put(userActions.mergePutUserInfo({ pending: false }));
}
