import { getIn, List } from 'immutable';
import { stateKeys as brandsKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const brands = Object.freeze({
  currentPage: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.currentPage], 0),
  filters: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.filters], List()),
  isLoading: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.pending], false),
  list: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.items], List()),
  pageSize: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.reload], false),
  totalCount: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.totalCount], 0),
  sorting: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.sorting], List()),
  settingStatus: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.settingStatus], false),
  settingPending: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsList, brandsKeys.settingPending], false),
  brandsListContext: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsListContext, brandsKeys.items], List()),
  brandsListContextPending: state => getIn(state, [brandsKeys.controller, brandsKeys.brandsListContext, brandsKeys.pending], true),
});
