// Core
import React, { forwardRef } from 'react';
import TreeView from '@mui/lab/TreeView';
import ListContext from './context';
// Icons
import ArrowDownSelect from '../../../../../../icons/ArrowDownSelect';
import ArrowRightSelect from '../../../../../../icons/ArrowRightSelect';

const InnerElementType = forwardRef(({ style, ...rest }, ref) => (
  <ListContext.Consumer>
    {({
      key, classes, openedNodeIds, hasSearch, searchString,
    }) => (
      <TreeView
        ref={ref}
        key={key}
        hasSearch={hasSearch}
        style={{ ...style }}
        className={classes.treeViewMui}
        defaultCollapseIcon={searchString ? <ArrowRightSelect /> : <ArrowDownSelect />}
        defaultExpandIcon={searchString ? <ArrowDownSelect /> : <ArrowRightSelect />}
        defaultExpanded={openedNodeIds}
        {...rest}
      />
    )}
  </ListContext.Consumer>
));

export default InnerElementType;
