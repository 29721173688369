// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/brands/actions';
// Hooks
import { useBrandsList } from '../_hooks/useBrandsList';
// import { asyncActions } from '../../../../../engine/core/brands/saga/asyncActions';

function BrandsTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.brands.isLoading);
  // Rows
  const rows = useSelector(selectors.brands.list);
  const [columns] = useState([
    {
      name: 'idMagentoBrand',
      title: t('M-ID бренда'),
    },
    {
      name: 'name',
      title: t('Название бренда'),
    },
  ]);

  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.brands.currentPage);
  const pageSize = useSelector(selectors.brands.pageSize);
  const totalCount = useSelector(selectors.brands.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.brands.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.brands.settingStatus);
  // const settingPending = useSelector(selectors.brands.settingPending);
  // Filtering
  const filters = useSelector(selectors.brands.filters);

  return (
    <DxTable
      name="brands"
      fixedColumn
      // showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      updateHooks={useBrandsList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.brands.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default BrandsTable;
