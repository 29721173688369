import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function FieldWithFeatureDisabled(props) {
  const { showTooltip, children, alignButton } = props;
  const { t } = useTranslation();
  return showTooltip
    ? (
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: alignButton === 'top'
            ? 'flex-start'
            : 'flex-end',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box sx={{ width: '100%', maxWidth: 'calc(100% - 38px)' }}>{children}</Box>
          <Box
            sx={{
              marginLeft: '6px',
              padding: '2px',
              borderRadius: '3px',
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.12)',
              display: 'flex',
              alignItems: 'center',
              background: '#ffffff',
              alignSelf: 'flex-start',
              marginTop: '4px',
            }}
          >
            <Tooltip title={t('Характеристика заблокирована')}>
              <IconButton
                sx={{
                  height: '28px',
                  width: '28px',
                  display: 'flex',
                  padding: '0',
                  minWidth: '28px',
                  alignSelf: 'flex-end',
                  alignItems: 'center',
                  borderRadius: '3px',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(48, 48, 48, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(48, 48, 48, 0.12)',
                  },
                }}
              >
                <LockOutlinedIcon
                  sx={{
                    color: '1px solid rgba(0, 0, 0, 0.42)',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

      </Box>
    )
    : children;
}
FieldWithFeatureDisabled.propTypes = {
  children: PropTypes.node.isRequired,
  showTooltip: PropTypes.bool,
  alignButton: PropTypes.string,
};
export default FieldWithFeatureDisabled;
