// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Engine
import { asyncActions } from '../../../../../../engine/core/partners/managers/saga/asyncActions';
import { selectors } from '../../../../../../engine/config/selectors';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';

// Parts
import ButtonWithIconAndCircularProgress from '../../../../../components/Buttons/ButtonWithIconAndCircularProgress';

function PartnersManagersExport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccess = useAccessList(accessList.partnerXlsDownload);
  const isLoading = useSelector(selectors.partnersManagers.isLoading);
  const partnersManagersDownloadPending = useSelector(selectors.partnersManagers.partnerManagerDownloadPending);

  if (!hasAccess) {
    return null;
  }

  const downloadPartnersManagers = () => {
    dispatch(asyncActions.getPartnersManagersExportAsync());
  };

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Скачать XLSX')}
      onClick={downloadPartnersManagers}
      isLoading={isLoading}
      disabled={partnersManagersDownloadPending}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default PartnersManagersExport;
