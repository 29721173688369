export const asyncTypes = Object.freeze({
  UPLOAD_PARTNER_PAYMENT_DOCS_ASYNC: 'UPLOAD_PARTNER_PAYMENT_DOCS_ASYNC',
  PUT_PARTNERS_SETTINGS_ASYNC: 'OLD_PUT_PARTNERS_SETTINGS_ASYNC',
  GET_PARTNER_STATUSES_LIST: 'OLD_GET_PARTNER_STATUSES_LIST',
  GET_PARTNERS_LIST_ASYNC_OLD: 'OLD_GET_PARTNERS_LIST_ASYNC',
  GET_PARTNER_INFO_ASYNC: 'OLD_GET_PARTNER_INFO_ASYNC',
  PUT_PARTNER_INFO_ASYNC: 'OLD_PUT_PARTNER_INFO_ASYNC',
  UPLOAD_PARTNER_LOGO_ASYNC: 'OLD_UPLOAD_PARTNER_LOGO_ASYNC',
  UPLOAD_PARTNER_CONTRACT_ASYNC: 'OLD_UPLOAD_PARTNER_CONTRACT_ASYNC',
  CREATE_NEW_PARTNER_ASYNC: 'OLD_CREATE_NEW_PARTNER_ASYNC',
  GET_PARTNERS_DOWNLOAD_ASYNC: 'OLD_GET_PARTNERS_DOWNLOAD_ASYNC',
  GET_PARTNER_DOWNLOAD_ASYNC: 'OLD_GET_PARTNER_DOWNLOAD_ASYNC',
  GET_PARTNER_CONTEXT_LIST_ASYNC: 'OLD_GET_PARTNER_CONTEXT_LIST_ASYNC',
  GET_PARTNERS_INITIAL_STATE_ASYNC: 'OLD_GET_PARTNERS_INITIAL_STATE_ASYNC',
  GET_PARTNERS_MANAGER_INITIAL_STATE_ASYNC: 'OLD_GET_PARTNERS_MANAGER_INITIAL_STATE_ASYNC',
  GET_PARTNER_CONTRACT_COMMISSION_DOWNLOAD_ASYNC: 'OLD_GET_PARTNER_CONTRACT_COMMISSION_DOWNLOAD_ASYNC',
  GET_USERS_CONTEXT_LIST_ASYNC: 'OLD_GET_USERS_CONTEXT_LIST_ASYNC',
  PUT_PARTNER_MANAGER_ASYNC: 'OLD_PUT_PARTNER_MANAGER_ASYNC',
  PUT_PARTNER_SEND_CONTRACT_TO_SED: 'OLD_PUT_PARTNER_SEND_CONTRACT_TO_SED',
  GET_BUSINESSES_LIST_ASYNC: 'GET_BUSINESSES_LIST_ASYNC',
  SET_PARTNER_BUSINESS_ASYNC: 'SET_PARTNER_BUSINESS_ASYNC',
  GET_PARTNERS_FOR_SERVICE_REQUESTS_LIST: 'GET_PARTNERS_FOR_SERVICE_REQUESTS_LIST',
});
