// Core
import React from 'react';
import { bool, object, string } from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
// Hooks
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';

import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';

function OrderEditLink(props) {
  const { t } = useTranslation();
  const hasAccessOrderEdit = useAccessList(accessList.orderEdit);
  const hasAccessOrderShow = useAccessList(accessList.orderShow);
  const {
    classes, editable, hashId,
  } = props;


  if (!hasAccessOrderEdit || !editable) {
    return null;
  }

  const orderEditUrl = pageLinks.orders.edit(hashId);
  const iconButtonClassName = cx({ [classes.noPadding]: (hasAccessOrderEdit && editable) && hasAccessOrderShow });

  return (
    <Tooltip title={t('Редактировать заказ')}>
      <NavLink to={orderEditUrl}>
        <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" className={iconButtonClassName} size="large">
          <ModeOutlinedIcon sx={{ p: '2px' }} />
        </IconButtonStyles>
      </NavLink>
    </Tooltip>
  );
}

OrderEditLink.propTypes = {
  classes: object,
  editable: bool,
  hashId: string,
};

OrderEditLink.defaultProps = {
  classes: {},
  editable: false,
  hashId: '',
};

const styles = {
  noPadding: {
    padding: 0,
  },
};

export default withStyles(styles)(OrderEditLink);
