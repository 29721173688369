// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getAttributeSetsListContextAsync(data) {
    return getActionCreator(asyncTypes.GET_ATTRIBUTE_SETS_LIST_ASYNC, data);
  },
});
