// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetProductsSourceListWorker() {
  yield put(actions.setProductsSourceListPending(true));

  const response = yield apply(api, api.products.getProductsSources);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    yield put(actions.setProductsSourceList(data));
  }
  yield put(actions.setProductsSourceListPending(false));
}
