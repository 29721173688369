// Core
import React, { memo, useEffect, useRef } from 'react';
import { areEqual } from 'react-window';
import TreeItem from '@mui/lab/TreeItem';
import Label from '../Label';
// Helpers
import { hasCheckedValue } from '../../_helpers/hasCheckedValue';

/** Category row */
const Row = memo(({ data, index, style }) => {
  const NODE_OFFSET = 17;
  const FIRST_LEVEL_OFFSET = 10;

  const rowRef = useRef(null);
  const { flattenedData, onOpen, setSize } = data;
  const node = flattenedData[index];
  const left = node.depth > 1 ? (node.depth - 1) * NODE_OFFSET : FIRST_LEVEL_OFFSET;
  const isChecked = hasCheckedValue(node.checkboxesState, node.id);
  const isIndeterminateFirstLevel = hasCheckedValue(node.indeterminateState, node.id);

  // Make parent and child nodes the same height
  useEffect(() => {
    if (rowRef.current) {
      setSize(index, rowRef.current.firstChild.clientHeight);
    }
  });

  return (
    <TreeItem
      ref={rowRef}
      key={node.id}
      nodeId={`${node.id}`}
      className={node.classes.treeItemMui}
      style={{ ...style, paddingLeft: `${left}px` }}
      onClick={() => onOpen(node)}
      label={(
        <Label
          id={node.id}
          isChecked={Boolean(isChecked)}
          label={node.label}
          value={node.id}
          currentOption={node.currentOption}
          hasActiveChildren={node.hasActiveChildren}
          hasChildren={node.hasChildren}
          isPortal={node.isPortal}
          onlyChild={node.onlyChild}
          onChangeCheckbox={node.onChangeCheckbox}
          innerRef={node.innerRef}
          classes={node.classes}
          isIndeterminate={Boolean(isIndeterminateFirstLevel)}
          search={node.search}
          onlyNotPortal={node.onlyNotPortal}
        />
      )}
    >
      {node.hasChildren ? <></> : null}
    </TreeItem>
  );
}, areEqual);

export default Row;
