// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callPasswordResetWorker,
  callPasswordChangeWorker,
} from './workers';

function* watchGetPartnerInfo() {
  yield takeEvery(asyncTypes.PASSWORD_RESET_ASYNC, callPasswordResetWorker);
}
function* watchUploadPartnerLogo() {
  yield takeEvery(asyncTypes.PASSWORD_CHANGE_ASYNC, callPasswordChangeWorker);
}

export function* watchersPassword() {
  yield all([
    watchGetPartnerInfo(),
    watchUploadPartnerLogo(),
  ]);
}
