const getMenuListHeight = (selectProps) => {
  const {
    hasSearch,
    hasConfirmButton,
  } = selectProps;
  const partOfWindowSize = window.innerHeight * 0.33;
  const baseHeight = (partOfWindowSize < 300 ? partOfWindowSize : 300);
  const maxHeightAndHasSearch = hasSearch
    ? baseHeight - 70
    : baseHeight;
  return hasConfirmButton
    ? maxHeightAndHasSearch - 50
    : maxHeightAndHasSearch;
};

export default getMenuListHeight;
