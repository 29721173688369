export const types = Object.freeze({
  SET_MAGENTO_REQUESTS_LIST: 'SET_MAGENTO_REQUESTS_LIST',
  MERGE_MAGENTO_REQUESTS_DOWNLOAD: 'MERGE_MAGENTO_REQUESTS_DOWNLOAD',
  SET_MAGENTO_REQUESTS_CURRENT_PAGE: 'SET_MAGENTO_REQUESTS_CURRENT_PAGE',
  SET_MAGENTO_REQUESTS_FILTERS: 'SET_MAGENTO_REQUESTS_FILTERS',
  SET_MAGENTO_REQUESTS_PAGE_SIZE: 'SET_MAGENTO_REQUESTS_PAGE_SIZE',
  SET_MAGENTO_REQUESTS_SORTING: 'SET_MAGENTO_REQUESTS_SORTING',
  SET_MAGENTO_REQUESTS_INITIAL_STATE: 'SET_MAGENTO_REQUESTS_INITIAL_STATE',
  SET_MAGENTO_REQUESTS_SETTING_STATUS: 'SET_MAGENTO_REQUESTS_SETTING_STATUS',
  SET_MAGENTO_REQUESTS_SETTING_PENDING: 'SET_MAGENTO_REQUESTS_SETTING_PENDING',
  MERGE_MAGENTO_REQUESTS_ITEM_BY_HASH_ID: 'MERGE_MAGENTO_REQUESTS_ITEM_BY_HASH_ID',
});
