// Core
import React, { useCallback, useEffect } from 'react';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useStyles } from '../../../../hooks/useStyles';
import { formName, formFields } from '../form';

// Hooks
import { asyncActions } from '../../../../../engine/core/serviceDesk/saga/asyncActions';
import { actions } from '../../../../../engine/core/serviceDesk/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { localData } from '../../../../../engine/config/localData';

// Components
import EnterMessageStyle from './EnterMessageStyle';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import InputFile from '../File/InputFile/InputFile';
import AttachedFileList from '../File/AttachedFileList/AttachedFileList';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

const EnterMessage = ({ handleSubmit }) => {
  const fileIsLoading = useSelector(selectors.serviceDesk.fileIsLoading);
  const files = useSelector(selectors.serviceDesk.chatFiles);
  const currentMsg = useSelector(selectors.serviceDesk.currentMessage);
  const messageIsSending = useSelector(selectors.serviceDesk.messageIsSending);
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles(EnterMessageStyle);
  let tmrId;

  const onSubmit = useCallback((
    formValues,
  ) => {
    const normalizeValues = formValues.toJS();
    const params = {
      message: normalizeValues[formFields.msg],
      messageFiles: files ? files.map(file => file.id) : [],
    };
    dispatch(asyncActions.putMessages({ hashId, params }));
  }, [dispatch, hashId, files]);

  const onChange = (event) => {
    clearTimeout(tmrId);
    tmrId = setTimeout(() => {
      dispatch(asyncActions.putMessageToLocal({
        chatId: hashId,
        currentMessage: event.target.value,
        multipleModeContext: localData.keysObj.SDAttachedFiles,
      }));
    }, 500);
  };

  useEffect(() => {
    dispatch(asyncActions.getCurrentMessagesFromLocal({
      chatId: hashId,
      multipleModeContext: localData.keysObj.SDAttachedFiles,
    }));
    return () => {
      dispatch(actions.setCurrentMsg(''));
    };
  }, [dispatch, hashId]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={classes.footer}>
      <AttachedFileList extraClasses={classes.attachedFileList} />
      <div className={classes.messageInput}>
        <Field
          value={currentMsg}
          fullWidth
          name={formFields.msg}
          component={TextField}
          variant="outlined"
          margin="none"
          validation
          onChange={onChange}
        />
      </div>
      <div className={classes.inputFile}>
        <Field
          type="select-multiple"
          name={formFields.file}
          component={InputFile}
          id="fileAttachToMessage"
        />
      </div>
      <ButtonWithIconAndCircularProgress
        text={t('Отправить')}
        type="submit"
        color="primary"
        component="button"
        disabled={messageIsSending || fileIsLoading}
        isLoading={messageIsSending || fileIsLoading}
        className={classes.btnSend}
        withoutIcon
      />
    </Form>
  );
};

const mapStateToProps = state => ({
  initialValues: {
    [formFields.msg]: selectors.serviceDesk.currentMessage(state),
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true, // this is needed!!
  }),
)(EnterMessage);
