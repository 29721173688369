import MaskedInput from 'react-text-mask/dist/reactTextMask';
import * as React from 'react';

const MaskedInputWithRef = React.forwardRef(function TextMaskCustom(props, inputRef) {
  const { mask, ...otherProps } = props;
  return (
    <MaskedInput
      {...otherProps}
      ref={(innerRef) => {
        inputRef(innerRef ? innerRef.inputElement : null);
      }}
      mask={mask}
      showMask
      placeholderChar="_"
    />
  );
});

export default MaskedInputWithRef;
