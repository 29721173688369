export const transformGifToStatic = async (gifObj, propertyParam) => {
  const promises = gifObj.map(gif => new Promise((resolve) => {
    if (!gif[propertyParam].endsWith('gif')) {
      resolve(gif);
    }
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.crossOrigin = '*';
    img.src = gif[propertyParam];

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const firstFrameDataUrl = canvas.toDataURL('image/png');
      resolve({ ...gif, [propertyParam]: firstFrameDataUrl });
    };

    img.onerror = () => {
      resolve({ ...gif, [propertyParam]: 'brokenImage' });
    };
  }));

  return Promise.all(promises);
};
