/* eslint-disable no-param-reassign */
import {
  Grid, Table, TableHeaderRow, TableFixedColumns,
  TableSelection, VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import { SelectionState } from '@devexpress/dx-react-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import TableCell, { NO_MORE_REFUND_PRODUCTS } from './TableCell';
import OrdersTableSettings from '../../OrdersTable/OrdersTableSettings';
import DxPatchedIntegratedSelection from '../../../../../components/_Table/DxTable/components/DxPatchedIntegratedSelection';
import DxTableSelection from '../../../../../components/_Table/DxTable/components/DxTableSelection/DxTableSelection';
import LoaderComponent from '../../../../../components/_Form/Selects/FormSelect/components/LoaderComponent';

const additionalColumns = [{
  name: 'quantityRefunded', title: 'Кол-во возвращенных товаров', position: 2, width: 80,
}, {
  name: 'quantityToRefund', title: 'Кол-во товаров для возврата', position: 3, width: 120,
}, {
  name: 'amountRefunded', title: 'Общая сума возвращенных товаров', position: 0, width: 160,
}];

const HeaderCellComponent = ({ children, ...headerRowProps }) => (<VirtualTable.Cell {...headerRowProps} sx={{ fontSize: '12px !important', paddingLeft: '8px !important' }}>{children}</VirtualTable.Cell>);

const RowComponent = ({ row, ...rowProps }) => (<VirtualTable.Row {...rowProps} bgcolor="#fff" sx={{ opacity: row.quantityToRefund === NO_MORE_REFUND_PRODUCTS ? 0.38 : 1 }}>{rowProps.children}</VirtualTable.Row>);


const RefundTable = ({
  rows, selection, onSelection, setSelection, setItemsToRefund, itemsToRefund, isEditable, isPartner,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => OrdersTableSettings.columnsOrderItem
    .filter(orderItem => orderItem.name !== 'partnerPrice')
    .concat(additionalColumns)
    .map((item) => {
      if (item.name === 'quantity') {
        item.title = 'Всего товаров';
        item.position = 3;
      }
      if (item.name === 'referrer') {
        item.width = 160;
      }
      if (item.name === 'amount') {
        item.title = 'Сумма по позиции';
      }
      if (item.name === 'price') {
        item.title = 'Цена за единицу';
        item.width = 100;
      }
      if (item.name === 'position') {
        item.position = 4;
        item.width = 60;
      }
      if (item.name === 'commission') {
        item.width = 80;
      }
      if (item.name === 'name') {
        item.width = 230;
      }
      return { ...item, title: t(item.title), position: item.position ?? 0 };
    })
    .sort((a, b) => Number(b.position) - Number(a.position)), []);


  const tableWidth = columns.reduce((acc, col) => acc + Number(col.width), 0);
  const tableColumnExtensions = columns.map(col => ({ columnName: col.name, width: col.width }));

  return (
    <>
      {rows.length === 0 ? <LoaderComponent /> : (
        <Paper sx={{
          maxWidth: tableWidth,
          '&.MuiTableCell-body.TableCell-cell': {
            padding: '5.5px 8px !important',
            fontSize: '12px',
          },
        }}
        >
          <Grid
            rows={rows}
            columns={columns}
            getRowId={row => row.id}
            sx={{ maxWidth: '100%' }}
          >
            <SelectionState selection={selection} onSelectionChange={onSelection} />
            <Table
              cellComponent={cellProps => (
                <TableCell
                  {...cellProps}
                  setSelection={setSelection}
                  setItemsToRefund={setItemsToRefund}
                  selection={selection}
                  itemsToRefund={itemsToRefund}
                />
              )}
              columnExtensions={tableColumnExtensions}
              rowComponent={RowComponent}
            />
            <TableHeaderRow cellComponent={HeaderCellComponent} />
            {(isPartner && isEditable) && (
            <DxPatchedIntegratedSelection
              selectionOffFlag="quantityToRefund"
            />
            )}
            {(isPartner && isEditable) && <DxTableSelection selectionOffFlag="quantityToRefund" />}
            <TableFixedColumns
              leftColumns={[TableSelection.COLUMN_TYPE]}
            />
          </Grid>
        </Paper>
      )}
    </>
  );
};


export default RefundTable;
