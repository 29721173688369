// Core
import { apply, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';

// Engine
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
import { api } from '../../../../../config/api';
import { setSuccessMessage, setErrorMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
// Const
import { formName } from '../../../../../../ui/page/StopList/BrandsCategories/components/StopCategoryWordModal/form';
import { selectors } from '../../../../../config/selectors';

export function* callPostUploadWorker({ payload: brandsCategoriesFile }) {
  yield put(actions.mergeUpload({ pending: true }));
  const currentPage = yield select(selectors.stopWords.currentPage);
  const filters = yield select(selectors.stopWords.filters);
  const pageSize = yield select(selectors.stopWords.pageSize);
  const sorting = yield select(selectors.stopWords.sorting);

  const response = yield apply(api, api.stopLists.postBrandsCategoriesUpload, [{ files: brandsCategoriesFile }]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const {
      errors, status, message, statusTitle,
    } = responseData;
    yield put(reset(formName));

    switch (status) {
      case 'error': {
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergeUpload({ isModalOpen: false }));
        const params = {
          limit: pageSize,
          offset: pageSize * currentPage,
          sorting: sorting.toJS(),
        };

        filters.toJS().reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);
        yield put(asyncActions.getListAsync(params));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeUpload({ pending: false }));
}
