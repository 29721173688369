// Core
import React from 'react';
import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { getAccessToken } from 'axios-jwt';
// init
import { store } from '../../../../init/store';

// Actions
import { notificationsActionAsync } from '../../../notifications/saga/asyncAction';

// Components
import NotificationContent from '../../../../../ui/components/Notifications/NotificationContent';
import NotificationCloseButton from '../../../../../ui/components/Notifications/NotificationCloseButton';

// helpers
import { notificationTypes } from '../asyncActionNotificationMessages';

export function* callSetNotificationMessage({ payload }) {
  const {
    type, title, duration, message, id, link,
    position, group, externalLink, media,
  } = payload;
  const theme = {
    backgroundColor: '#28a745',
    progressBg: 'rgba(0, 0, 0, 0.3)',
    color: '#fff',
  };
  if (type === notificationTypes.error.type) {
    theme.backgroundColor = '#dc3545';
  } else if (type === notificationTypes.warning.type) {
    theme.backgroundColor = '#eab000';
  } else if (position === 'top-right') {
    theme.backgroundColor = '#fff';
    theme.progressBg = '#568da7';
    theme.color = '#000';
  }

  const handleContainerClick = ({ isCloseBtn }) => {
    if (id && getAccessToken()) {
      store.dispatch(notificationsActionAsync.putNotificationsAsync([id]));
      if (!isCloseBtn && externalLink) {
        window.open(`${externalLink}`);
      } else if (!isCloseBtn && link) {
        store.dispatch(push(link));
      }
    }
  };

  const closeButton = ({ closeToast }) => (
    <NotificationCloseButton
      onClose={handleContainerClick}
      closeToast={closeToast}
    />
  );

  const toastOptions = {
    style: {
      backgroundColor: theme.backgroundColor,
      color: theme.color,
    },
    position: position || 'bottom-right',
    autoClose: duration,
    progressStyle: {
      background: theme.progressBg,
    },
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    closeButton,
  };

  yield call(toast, <NotificationContent
    id={id}
    message={message}
    title={title}
    link={link}
    group={group}
    media={media}
    onContainerClick={handleContainerClick}
  />, toastOptions);
}
