// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callPutSettingsWorker,
  callGetListWorker,
  callGetInitialStateWorker,
  callGetStatusesListWorker,
  callPutItemByHashId,
  callGetAttachedFilesWorker,
  callDeleteAttachedFileWorker,
  callGetMessagesWorker,
  callPutMessageWorker,
  callSetMessageToLocalWorker,
  callGetCurrentMsgFromLocalWorker,
  callPostCloseQuestion,
  callPostContinueQuestion,
} from './workers';

function* watchList() {
  yield takeEvery(asyncTypes.GET_SERVICE_DESK_LIST_ASYNC, callGetListWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SERVICE_DESK_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SERVICE_DESK_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SERVICE_DESK_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_SERVICE_DESK_SORTING, callSetToLocalDataWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_SERVICE_DESK_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_SERVICE_DESK_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetStatusesList() {
  yield takeEvery(asyncTypes.GET_SERVICE_DESK_STATUSES_LIST_ASYNC, callGetStatusesListWorker);
}
function* watchPutItemByHashId() {
  yield takeEvery(asyncTypes.PUT_SERVICE_DESK_ITEM_BY_HASH_ID_ASYNC, callPutItemByHashId);
}
function* watchGetAttachedFiles() {
  yield takeEvery(asyncTypes.SET_SERVICE_DESK_FILES_IN_CHAT_MESSAGE_ASYNC, callGetAttachedFilesWorker);
}
function* watchDeleteAttachedFile() {
  yield takeEvery(asyncTypes.DELETE_SERVICE_DESK_FILE_IN_CHAT_MESSAGE_ASYNC, callDeleteAttachedFileWorker);
}
function* watchGetMessages() {
  yield takeEvery(asyncTypes.GET_SERVICE_DESK_MESSAGES_ASYNC, callGetMessagesWorker);
}
function* watchPostCloseQuestion() {
  yield takeEvery(asyncTypes.POST_SERVICE_DESK_CLOSE_QUESTION_ASYNC, callPostCloseQuestion);
}
function* watchPostContinueQuestion() {
  yield takeEvery(asyncTypes.POST_SERVICE_DESK_CONTINUE_QUESTION_ASYNC, callPostContinueQuestion);
}
function* watchPutMessage() {
  yield takeEvery(asyncTypes.PUT_SERVICE_DESK_MESSAGE, callPutMessageWorker);
}
function* watchSetMessageToLocal() {
  yield takeEvery(asyncTypes.SET_SERVICE_DESK_MESSAGE_TO_LOCAL, callSetMessageToLocalWorker);
}
function* watchGetMsgFromLocal() {
  yield takeEvery(asyncTypes.GET_SERVICE_DESK_CURRENT_MSG_FROM_LOCAL, callGetCurrentMsgFromLocalWorker);
}

export function* watchersServiceDesk() {
  yield all([
    watchList(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPutSettings(),
    watchGetInitialState(),
    watchGetStatusesList(),
    watchPutItemByHashId(),
    watchGetAttachedFiles(),
    watchDeleteAttachedFile(),
    watchGetMessages(),
    watchPutMessage(),
    watchSetMessageToLocal(),
    watchGetMsgFromLocal(),
    watchPostCloseQuestion(),
    watchPostContinueQuestion(),
  ]);
}
