import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FilePond, FileStatus, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { styled } from '@mui/material/styles';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COLOR } from '../../../../containers/App/AppStyles';
import FileXmlIcon from '../../../../icons/FileXmlIcon';
import FilePdfIcon from '../../../../icons/FilePdfIcon';
import FilePngIcon from '../../../../icons/FilePngIcon';
import FileJpegIcon from '../../../../icons/FileJpegIcon';
import FileJpgIcon from '../../../../icons/FileJpgIcon';
import FileUnknownIcon from '../../../../icons/FileUnknownIcon';
import FileXlsxIcon from '../../../../icons/FileXlsxIcon';
import SvgFileDefaultIcon from '../../../../icons/FileDefaultIcon';
import DocumentFilePreviewModal from './DocumentFilePreviewModal';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

const fiveMegabytes = 5242880;

const StyledFilePond = styled(FilePond)(({ validationColor }) => ({
  '& .filepond-drop-label-wrapper': {
    fontSize: '12px',
    paddingTop: '15px !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  '& .filepond--list-scroller': {
    marginTop: '5px !important',
  },
  '& .filepond--wrapper': {
    background: '#ffffff',
    display: 'block',
  },
  '& .filepond--credits': {
    display: 'none',
  },
  '& .filepond--file-action-button': {
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'black',
  },
  '& .filepond--drop-label': {
    fontSize: 12,
    lineHeight: '16px',
    color: COLOR.grey['54'],
    height: '100px',
  },
  '& .filepond--label-action': {
    textDecorationColor: '#4565df',
  },
  '& .filepond--panel-root': {
    backgroundColor: '#fff',
    borderRadius: '0.5em',
    borderColor: validationColor,
    borderStyle: 'dashed',
    borderWidth: '2px',
    height: '50px',
    cursor: 'pointer',
  },
  '& .filepond--panel-top .filepond--panel-root': {
    borderColor: validationColor,
  },
  '& .filepond--panel-center .filepond--panel-root': {
    borderColor: validationColor,
  },
  '& .filepond--panel-bottom .filepond--panel-root': {
    borderColor: validationColor,
  },
  '& .filepond--item-panel': {
    backgroundColor: '#ffffff',
    borderRadius: '0 !important',
  },
  '& .filepond--drip-blob': {
    backgroundColor: '#ffffff',
  },
  '& .filepond--file-action-button:hover, .filepond--file-action-button:focus': {
    boxShadow: '0 0 0 0.125em rgba(255, 255, 255, 0.9)',
  },
  '& [data-filepond-item-state="processing-complete"] .filepond--item-panel': {
    backgroundColor: 'green',
  },
  '& .filepond--file': {
    borderRadius: '0 !important',
  },
  '& .filepond--file-info': {
    alignItems: 'center',
    transform: 'none !important',
  },
  '& .filepond--file-wrapper > .filepond--panel .filepond--panel-bottom': {
    borderRadius: '0 !important',
    boxShadow: '0 !important',
  },
  '& .filepond--item > .filepond--panel .filepond--panel-bottom': {
    borderRadius: '0 !important',
    boxShadow: '0 !important',
  },
  '& .filepond--file-info .filepond--file-info-sub': {
    display: 'none',
  },
  '& .filepond--list': {
    display: 'none',
    '& .filepond--item': {
      '&[data-filepond-item-state*=invalid]': {
        background: '#ffffff',
        backgroundColor: '#ffffff',
        display: 'none',
      },
      '&[data-filepond-item-state*=error]': {
        background: '#ffffff',
        backgroundColor: '#ffffff',
        display: 'none',
      },
    },
  },
}));

function getFileIcon(extension) {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return <FilePdfIcon />;
    case 'png':
      return <FilePngIcon />;
    case 'jpg':
      return <FileJpgIcon />;
    case 'xml':
      return <FileXmlIcon />;
    case 'jpeg':
      return <FileJpegIcon />;
    case 'xlsx':
      return <FileXlsxIcon />;
    default:
      return <FileUnknownIcon />;
  }
}

const FILE_NOT_SELECTED = COLOR.grey['54'];
const FILE_VALID = COLOR.success;
const FILE_INVALID = COLOR.error;

const ListItemFileValid = styled('li')`
  & {
    color: ${FILE_VALID}
  }
  &::before {
    content: '✓ ';
    color: ${FILE_VALID};
    font-weight: bold;
  }
`;

const ListItemFileInvalid = styled('li')`
  & {
    color: ${FILE_INVALID}
  }
  &::before {
    content: 'x ';
    color: ${FILE_INVALID};
    font-weight: bold;
  }
`;

const DocumentsFileUploader = ({
  handleFileChange, selectedFile, setErrors, editMode,
}) => {
  // const timestamp = new Date().getMilliseconds();
  const [sizeValidationColor, setSizeValidationColor] = useState(FILE_NOT_SELECTED);
  const [typeValidationColor, setTypeValidationColor] = useState(FILE_NOT_SELECTED);
  const [borderColor, setBorderColor] = useState(FILE_NOT_SELECTED);
  const pondRef = useRef(null);
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);
  useEffect(() => {
    if (pondRef && editMode) {
      pondRef.current.addFile(selectedFile.fileItem);
    }
  }, [pondRef]);

  const handleFileError = useCallback((details, file) => {
    if (file && file.status === FileStatus.LOAD_ERROR) {
      // setFileInvalid(true);
      const error = {};
      if (details.sub === 'invalid_size' || file.file.size > fiveMegabytes) {
        setSizeValidationColor(FILE_INVALID);
        error[details.sub] = 'invalid_size';
      }
      if (details.sub === 'invalid_type') {
        setTypeValidationColor(FILE_INVALID);
        error[details.sub] = 'invalid_type';
      }
      if (borderColor !== FILE_INVALID) {
        setBorderColor(FILE_INVALID);
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        ...error,
      }));
    }
  }, [borderColor, setErrors]);

  const handleUpdateFiles = (fileItems) => {
    const fileItem = fileItems[0];
    if (fileItem) {
      const selectedFileData = {
        size: fileItem.file.size,
        name: fileItem.file.name,
        type: fileItem.file.type,
        extension: fileItem.file.name.split('.').pop(),
        fileItem,
      };
      handleFileChange(selectedFileData);
      if (borderColor !== FILE_VALID) {
        setBorderColor(FILE_VALID);
      }
      setTypeValidationColor(FILE_VALID);
      setSizeValidationColor(FILE_VALID);
    } else {
      handleFileChange(null);
      if (borderColor !== FILE_NOT_SELECTED) {
        setBorderColor(FILE_NOT_SELECTED);
      }
      setTypeValidationColor(FILE_NOT_SELECTED);
      setSizeValidationColor(FILE_NOT_SELECTED);
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      certificate: '',
      invalid_type: '',
      invalid_size: '',
    }));
  };

  const renderSize = (sizeInBytes) => {
    const sizeInMb = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return `${sizeInMb} Mb`;
  };

  const handleRemoveFiles = () => {
    if (selectedFile) {
      pondRef.current.removeFiles();
      handleFileChange(null);
      setTypeValidationColor(FILE_NOT_SELECTED);
      setSizeValidationColor(FILE_NOT_SELECTED);
      setBorderColor(FILE_NOT_SELECTED);
    }
  };

  const renderFormatFileListItem = () => {
    switch (typeValidationColor) {
      case FILE_VALID:
        return <ListItemFileValid>Формати: pdf, jpeg, png;</ListItemFileValid>;
      case FILE_INVALID:
        return <ListItemFileInvalid>Формати: pdf, jpeg, png;</ListItemFileInvalid>;
      default:
        return <li style={{ color: typeValidationColor }}>Формати: pdf, jpeg, png;</li>;
    }
  };

  const renderSizeFileListItem = () => {
    switch (sizeValidationColor) {
      case FILE_VALID:
        return <ListItemFileValid>{"Об'єм файлу не більше 5 Мб."}</ListItemFileValid>;
      case FILE_INVALID:
        return <ListItemFileInvalid>{"Об'єм файлу не більше 5 Мб."}</ListItemFileInvalid>;
      default:
        return <li style={{ color: sizeValidationColor }}>{"Об'єм файлу не більше 5 Мб."}</li>;
    }
  };

  const ulStyle = {
    listStyle: 'none',
    paddingLeft: '28px',
  };

  const getLabelIdle = () => {
    if (!selectedFile) {
      return '<div class="filepond-drop-label-wrapper" style="display: flex">'
        + '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">'
        + '<path d="M8.33333 1C6.93421 1 5.8 2.15879 5.8 3.58824V10.3267C6.20822 10.2088 6.63168 10.1279 7.06667 10.0878V3.58824C7.06667 2.87351 7.63377 2.29412 8.33333 2.29412H13.4V6.82353C13.4 7.89561 14.2507 8.76471 15.3 8.76471H19.7333V19.1176C19.7333 19.8324 19.1662 20.4118 18.4667 20.4118H13.907C13.6754 20.8748 13.3955 21.3084 13.0743 21.7059H18.4667C19.8658 21.7059 21 20.5471 21 19.1176V8.00663C21 7.4918 20.7998 6.99805 20.4435 6.63401L15.4855 1.56856C15.1292 1.20452 14.6459 1 14.142 1H8.33333ZM19.471 7.47059H15.3C14.9502 7.47059 14.6667 7.18089 14.6667 6.82353V2.56214L19.471 7.47059ZM7.7 23C10.848 23 13.4 20.3927 13.4 17.1765C13.4 13.9602 10.848 11.3529 7.7 11.3529C4.55198 11.3529 2 13.9602 2 17.1765C2 20.3927 4.55198 23 7.7 23ZM4.71883 17.634C4.4715 17.3813 4.4715 16.9716 4.71883 16.7189C4.96616 16.4662 5.36717 16.4662 5.6145 16.7189L7.06667 18.2026L7.06667 14.5882C7.06667 14.2309 7.35022 13.9412 7.7 13.9412C8.04978 13.9412 8.33333 14.2309 8.33333 14.5882V18.2026L9.7855 16.7189C10.0328 16.4662 10.4338 16.4662 10.6812 16.7189C10.9285 16.9716 10.9285 17.3813 10.6812 17.634L8.14783 20.2222C8.08711 20.2843 8.01713 20.3311 7.94243 20.3627C7.86886 20.3938 7.78829 20.4112 7.7038 20.4118L7.7 20.4118L7.6962 20.4118C7.61171 20.4112 7.53114 20.3938 7.45757 20.3627C7.38397 20.3316 7.31495 20.2857 7.25486 20.225L7.25166 20.2217L4.71883 17.634Z" fill="#303030" fill-opacity="0.87"/>\n'
        + '</svg>'
        + '<span>Перетягніть довідку сюди або <br/><span style="color: #4565df" class="filepond--label-action">натисніть тут</span>, щоб вибрати файл</span>'
        + '</div>';
    }
    return '';
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box style={{
        position: 'absolute',
        zIndex: 12,
        top: '15px',
        paddingTop: '10px',
        padding: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: '5px',
        right: '5px',
        textAlign: 'center',
      }}
      >
        <Box style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
        >
          {(selectedFile)
            && (
              <>
                <Box
                  position={'absolute'}
                  right={'10px'}
                  top={'0px'}
                  style={{ cursor: 'pointer' }}
                  onClick={handleRemoveFiles}
                >
                  <SvgFileDefaultIcon />
                </Box>
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                  onClick={() => {
                    setIsPreviewOpened(true);
                  }}
                >
                  <Box style={{ position: 'relative', width: '29px', height: '38px' }}>{getFileIcon(selectedFile.extension)}</Box>
                  <Typography color={COLOR.controls.dark} width={'220px'} fontSize={'12px'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{selectedFile.name}</Typography>
                </Box>
                <Typography fontSize={'13px'} color={'rgba(48, 48, 48, 0.72)'}>{renderSize(selectedFile.size)}</Typography>
                <DocumentFilePreviewModal
                  open={isPreviewOpened}
                  selectedFile={selectedFile}
                  onClose={() => {
                    setIsPreviewOpened(false);
                  }}
                />
              </>
            )
          }
        </Box>
      </Box>
      <StyledFilePond
        name="files"
        key={selectedFile && selectedFile.size}
        ref={pondRef}
        validationColor={borderColor}
        acceptedFileTypes={[
          'application/pdf',
          'image/png',
          'image/jpeg',
        ]}
        allowFileSizeValidation
        labelMaxFileSize={'invalid_size'}
        fileValidateTypeLabelExpectedTypes={'invalid_type'}
        maxFileSize={'5mb'}
        files={selectedFile ? [selectedFile.fileItem] : []}
        onerror={handleFileError}
        styleButtonRemoveItemPosition={'top right'}
        styleButtonRemoveItemAlign
        iconRemove={''}
        labelIdle={getLabelIdle()}
        onupdatefiles={handleUpdateFiles}
      />
      <Box marginTop={'10px'}>
        <Typography fontSize={12} gutterBottom>
          <ul style={borderColor !== FILE_NOT_SELECTED ? ulStyle : {}}>
            {renderFormatFileListItem()}
            {renderSizeFileListItem()}
          </ul>
        </Typography>
      </Box>
    </Box>
  );
};

export default DocumentsFileUploader;
