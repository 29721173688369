import { COLOR } from '../../../../containers/App/AppStyles';

const OrderStatisticsStyle = () => ({
  iconColor: {
    // fill: '#fff',
    fontSize: '24px',
  },
  orderModalContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    paddingTop: '5px',
  },
  spacingBottom: {
    boxShadow: COLOR.shadow.block.main,
  },
});

export default OrderStatisticsStyle;
