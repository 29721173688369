import { getIn, List } from 'immutable';
import { stateKeys as feedsMatchingCategoriesKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const feedsMatchingCategories = Object.freeze({
  currentPage: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.currentPage], 0),
  filters: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.filters], List()),
  isLoading: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.pending], false),
  list: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.items], List()),
  pageSize: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.reload], false),
  totalCount: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.totalCount], 0),
  // selection: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.selection], List()),
  sorting: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.sorting], List()),
  settingStatus: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.settingStatus], false),
  settingPending: state => getIn(state, [feedsMatchingCategoriesKeys.controller, feedsMatchingCategoriesKeys.list, feedsMatchingCategoriesKeys.settingPending], false),
});
