export const formName = 'signUp';

export const formFields = Object.freeze({
  companyName: `${formName}CompanyName`,
  cPassword: `${formName}CPassword`,
  fullName: `${formName}FullName`,
  email: `${formName}Email`,
  password: `${formName}Password`,
  phone: `${formName}Phone`,
  webSite: `${formName}WebSite`,
  agreeOffer: `${formName}agreeOffer`,
  hasOnlineStore: `${formName}HasOnlineStore`,
  activityType: `${formName}ActivityType`,
  yourselfInformation: `${formName}YourselfInformation`,
  planToSell: `${formName}PlanToSell`,
  readyToYmlFormat: `${formName}ReadyToYmlFormat`,
  beenAlloSupplier: `${formName}BeenAlloSupplier`,
  hasServiceCenter: `${formName}HasServiceCenter`,
  hasContactCenter: `${formName}HasContactCenter`,
  priceLink: `${formName}PriceLink`,
});
