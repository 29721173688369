// Core
import { Map } from 'immutable';

// Instruments
import { types } from './types';

export const initialState = Map({
  cropperModalShow: false,
  uploadModalPending: false,
  uploadFilesPending: false,
  mediaRequestFiles: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'media',
  cropperModalShow: 'cropperModalShow',
  uploadModalPending: 'uploadModalPending',
  uploadFilesPending: 'uploadFilesPending',
  mediaRequestFiles: 'mediaRequestFiles',
});

export const mediaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MEDIA_UPLOAD_MODAL_PENDING: {
      return state.set(stateKeys.uploadModalPending, payload);
    }
    case types.SET_MEDIA_UPLOAD_FILES_PENDING: {
      return state.set(stateKeys.uploadFilesPending, payload);
    }
    case types.SET_MEDIA_CROPPER_MODAL_SHOW: {
      return state.set(stateKeys.cropperModalShow, payload);
    }
    case types.SET_IN_MEDIA_REQUEST_FILES: {
      const {
        formName, name, link, status, id, hashId,
      } = payload;
      return state.setIn([stateKeys.mediaRequestFiles, formName, name], Map({
        link, status, id, hashId,
      }));
    }
    default: {
      return state;
    }
  }
};
