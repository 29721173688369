// Core
import React, { useEffect, useCallback } from 'react';
import {
  Field, Form, reduxForm, isPristine,
  isSubmitting,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// Icons
import CallMissedIcon from '@mui/icons-material/CallMissed';
import SaveIcon from '@mui/icons-material/Save';
// Parts
import Grid from '@mui/material/Grid';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import Checkbox from '../../../components/_Form/Checkbox/Checkbox';
import FormTextArea from '../../../components/_Form/TextArea/FormTextArea';
import Loading from '../../../components/Loading/Loading';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import TypographyFieldView from '../../../components/_Form/TypographyFieldView/TypographyFieldView';
import SectionHolder from '../../../containers/SectionHolder/SectionHolder';
// Helpers
import { tableDataTypes } from '../../../../_helpers/data/tableDataTypes';
import { formName, formFields } from '../../../../_helpers/data/pages/settings/configurations/form';
import { usePrevious } from '../../../hooks/usePrevious';
// Engine
import { asyncActions } from '../../../../engine/core/configurations/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
import SelectUsers from './components/SelectUsers';

function ConfigurationsEditPage(props) {
  const { handleSubmit, mode } = props;

  const submitting = useSelector(isSubmitting(formName));
  const pristine = useSelector(isPristine(formName));
  const dispatch = useDispatch();
  const { hashId } = useParams();
  const itemByHashId = useSelector(selectors.configurations.itemByHashId);
  const isLoading = useSelector(selectors.configurations.itemByHashIdLoading);
  const isPutLoading = useSelector(selectors.configurations.itemByHashIdPutLoading);
  const configurationItemByHashId = itemByHashId.toJS();
  const showMode = mode === 'show';
  const putPending = false;
  const { t, i18n } = useTranslation();
  const prevLanguage = usePrevious(i18n.language);

  function onFormSubmit(formValues, redirect) {
    if (formValues && formValues.toJS) {
      const jsFormData = formValues.toJS();

      const data = {
        value: jsFormData[formFields.value],
      };

      dispatch(asyncActions.putItemByHashIdAsync({ hashId, data, redirect }));
    }
  }

  useEffect(() => {
    if ((i18n.language !== prevLanguage) && (prevLanguage !== undefined && i18n.language !== undefined)) {
      dispatch(asyncActions.getItemByHashIdAsync(hashId));
    }
  }, [dispatch, hashId, i18n.language, prevLanguage]);

  useEffect(() => {
    dispatch(asyncActions.getItemByHashIdAsync(hashId));
  }, [dispatch, hashId]);

  const titleText = configurationItemByHashId?.title;
  const itemType = configurationItemByHashId?.type?.value;
  const loadingButton = putPending || isLoading || isPutLoading;
  const disabledButton = submitting || pristine || isLoading || putPending || isPutLoading;

  const CurrentComponent = useCallback((itemProps) => {
    const types = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: <Checkbox
        {...itemProps}
        disabled={showMode}
        checkboxLabelFalse="Запрещено"
        checkboxLabelTrue="Разрешено"
        sx={{ alignItems: 'flex-start' }}
      />,
      7: false,
      8: <FormTextArea
        {...itemProps}
        disabled={showMode}
        multiline
        rows={5}
        variant="standard"
      />,
      10: <SelectUsers {...itemProps} showMode={showMode} />,
    };

    return types[itemType] || (
      <TextField
        {...itemProps}
        disabled={showMode}
      />
    );
  }, [dispatch, itemType, showMode]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={showMode ? t('Просмотр конфигурации') : t('Редактирование конфигурации')}>
          {showMode ? (
            <ButtonGoBack defaultPage={pageLinks.settings.configurations.all} />
          ) : (
            <>
              <ButtonWithIconAndCircularProgress
                text={t('Сохранить и вернуться')}
                isLoading={loadingButton}
                disabled={disabledButton}
                onClick={handleSubmit(value => onFormSubmit(value, true))}
                component="button"
                type="button"
              >
                <CallMissedIcon />
              </ButtonWithIconAndCircularProgress>
              <ButtonWithIconAndCircularProgress
                text={t('Сохранить')}
                isLoading={loadingButton}
                disabled={disabledButton}
                onClick={handleSubmit(value => onFormSubmit(value, false))}
                component="button"
                type="button"
              >
                <SaveIcon />
              </ButtonWithIconAndCircularProgress>
            </>
          )}
        </PageHeader>
        <SectionHolder>
          <Grid container>
            <Grid item xs={1} />
            {isLoading
              ? (
                <Grid item xs={10}>
                  <Loading isLoading />
                </Grid>
              )
              : (
                <Grid item xs={10}>
                  <TypographyFieldView
                    label={tableDataTypes.title.title}
                    text={titleText}
                  />
                  <Field
                    component={CurrentComponent}
                    fullWidth
                    label={tableDataTypes.value.title}
                    name={formFields.value}
                    margin="normal"
                  />
                </Grid>
              )
            }
            <Grid item xs={1} />
          </Grid>
        </SectionHolder>
      </Grid>
    </Form>
  );
}

export default reduxForm({
  form: formName,
})(ConfigurationsEditPage);
