// Core
import { put, apply, select } from 'redux-saga/effects';
import { api, requestStatus } from '../../../../../config/api';
// Engine
import { actions } from '../../actions';
import { selectors } from '../../../../../config/selectors';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutCategoriesByHashIdWorker({ payload }) {
  const {
    itemHashId, position, hashId, ...data
  } = payload;
  yield put(actions.mergeCategoriesLoading(hashId));
  const response = yield apply(api, api.feeds.putFeedsAttributes, [{ hashId, ...data }]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, item } = responseData;
    switch (status) {
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default:
        break;
    }
    if (item) {
      yield put(actions.setCategoriesItemByHashIdAndPosition({
        hashId: itemHashId,
        position,
        value: item,
      }));
      const attributesExpandedRowIds = yield select(selectors.productFeedCategoriesAttributes.attributesExpandedRowIds);
      const newExpendedRowIds = attributesExpandedRowIds.toJS().filter(expandedRowId => expandedRowId.toString() !== position.toString());
      yield put(actions.setAttributesExpendedRowIds(newExpendedRowIds));
    }
  }
  const categoriesLoadingRowIds = yield select(selectors.productFeedCategoriesAttributes.categoriesLoadingRowIds);
  yield put(actions.deleteCategoriesLoading(categoriesLoadingRowIds.indexOf(hashId)));
}
