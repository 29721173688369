// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetBrandsListContextWorker() {
  yield put(actions.mergeBrandsListContext({ pending: true }));

  const { brands, brandsLastUpdate } = localData.keysObj;
  const brandsLastUpdateFromLocalData = yield apply(localData, localData.getItem, [brandsLastUpdate]);
  const brandsLastUpdateFromStatistics = yield select(selectors.statistics.brandsLastUpdate);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  let response;

  switch (true) {
    case !brandsLastUpdateFromLocalData:
    case brandsLastUpdateFromLocalData !== brandsLastUpdateFromStatistics: {
      response = yield apply(api, api.brands.getList, [{ context: 'select' }]);
      yield apply(localData, localData.setItem, [brandsLastUpdate, brandsLastUpdateFromStatistics]);
      break;
    }
    case brandsLastUpdateFromLocalData === brandsLastUpdateFromStatistics: {
      data = yield apply(localData, localData.getItem, [brands]);
      if (!data) {
        yield apply(localData, localData.setItem, [brandsLastUpdate, brandsLastUpdateFromStatistics]);
      }
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [brands, { items: data.items, totalCount: data.totalCount }]);
  }

  yield put(actions.mergeBrandsListContext({
    pending: false,
    items: List(data?.items || []),
    totalCount: data?.totalCount || 0,
  }));
}
