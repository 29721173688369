// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isImmutable } from 'immutable';
import * as PropTypes from 'prop-types';
import {
  Field, Form, reduxForm, submit,
} from 'redux-form/immutable';
// Parts
import FilesUploader from '../../../../../../components/_Form/FilesUploader/FilesUploader';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/messages/actions';
import { asyncActions } from '../../../../../../../engine/core/messages/saga/asyncActions';
import { mimeTypeExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
// Helpers
import { validators } from '../../../../../../../_helpers/validationsForm';

export const recipientsImportFormName = 'recipientsImportFormName';
export const recipientsImportFormFields = Object.freeze({
  recipientsImport: 'recipientsImport',
});

function RecipientsImportModal(props) {
  const { handleSubmit, valid } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpenRecipientsImportModal = useSelector(selectors.messages.isOpenRecipientsImportModal);
  const recipientsImportPending = useSelector(selectors.messages.recipientsImportPending);

  const handleModalToggle = () => {
    dispatch(actions.mergeRecipientsImport({
      isOpenModal: !isOpenRecipientsImportModal,
    }));
  };

  const handleSend = () => {
    dispatch(submit(recipientsImportFormName));
  };

  const onFormSubmit = (formData) => {
    if (isImmutable(formData)) {
      const data = formData.toJS();
      dispatch(asyncActions.postRecipientsImportAsync({
        files: data[recipientsImportFormFields.recipientsImport],
      }));
    }
  };

  return (
    <Modal
      modalOpen={isOpenRecipientsImportModal}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      handleModalAdd={handleSend}
      disabledSend={recipientsImportPending || !valid}
      loadingSend={recipientsImportPending}
      title={t('Добавить несколько партнеров')}
      modalMaxWidth="xs"
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          fullWidth
          allowFileTypeValidation
          component={FilesUploader}
          acceptedFileTypes={[mimeTypeExtensions.xlsx]}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          validate={[validators.itemsRequiredValidator]}
          name={recipientsImportFormFields.recipientsImport}
          margin="normal"
          formName={recipientsImportFormName}
          context="partners"
        />
      </Form>
    </Modal>
  );
}

RecipientsImportModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({ form: recipientsImportFormName })(RecipientsImportModal);
