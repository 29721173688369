// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  priceCreatorCategoriesList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  updateCategories: Map({
    pending: false,
  }),
  partnersModal: Map({
    open: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'priceCreatorCategories',
  priceCreatorCategoriesList: 'priceCreatorCategoriesList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  partnersModal: 'partnersModal',
  open: 'open',
  updateCategories: 'updateCategories',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
});

export const priceCreatorCategoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRICE_CREATOR_CATEGORIES_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.pending], pending)
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.items], List(items))
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_SORTING: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.sorting], List(payload));
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_RELOAD: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.reload], payload);
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.filters], List(filters));
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_PAGE_SIZE: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.pageSize], payload);
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_CURRENT_PAGE: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.currentPage], payload);
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.priceCreatorCategoriesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.reload], reload)
        .setIn([stateKeys.priceCreatorCategoriesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_SETTING_STATUS: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.settingStatus], payload);
    }
    case types.SET_PRICE_CREATOR_CATEGORIES_SETTING_PENDING: {
      return state.setIn([stateKeys.priceCreatorCategoriesList, stateKeys.settingPending], payload);
    }
    case types.MERGE_PRICE_CREATOR_CATEGORIES_UPDATE: {
      return state.mergeIn([stateKeys.updateCategories], Map(payload));
    }
    case types.MERGE_PRICE_CREATOR_CATEGORIES_PARTNERS_MODAL: {
      return state.mergeIn([stateKeys.partnersModal], Map(payload));
    }
    case types.UPDATE_PRICE_CREATOR_CATEGORIES_LIST_ITEM: {
      const {
        hashId, key, value, error, success, pending,
      } = payload;
      return state.withMutations((mutable) => {
        const allItems = mutable.getIn([stateKeys.priceCreatorCategoriesList, stateKeys.items]).toJS();
        const index = allItems.findIndex(item => item.hashId === hashId);
        if (pending) {
          mutable.mergeIn([stateKeys.priceCreatorCategoriesList, stateKeys.items, index, key], { pending });
        } else {
          mutable.mergeIn([stateKeys.priceCreatorCategoriesList, stateKeys.items, index, key], {
            value, error, success, pending,
          });
        }
      });
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
