// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_CONFIGURATIONS_LIST, data);
  },
  mergeTypes(data) {
    return getActionCreator(types.MERGE_CONFIGURATIONS_TYPES, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_CONFIGURATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_CONFIGURATIONS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_CONFIGURATIONS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_CONFIGURATIONS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_CONFIGURATIONS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_CONFIGURATIONS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_CONFIGURATIONS_SETTING_PENDING, settingPending);
  },
  mergeItemByHashId(data) {
    return getActionCreator(types.MERGE_CONFIGURATIONS_ITEM_BY_HASH_ID, data);
  },
});
