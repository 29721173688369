// Core
import { Route, Routes } from 'react-router';
import { useSelector } from 'react-redux';
// Parts
import routes, { routersLink } from '../../../../engine/config/routes';
import ProfilePartner from '../../../page/Partner/PartnerProfile/ProfilePartner';
import Error404 from '../../../page/Error/Error404';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';

function Main() {
  const accessLists = useSelector(selectors.user.accessList);
  const rolesList = useSelector(selectors.user.rolesList);
  const partnerStatus = useSelector(selectors.user.partnerStatus);

  return (
    <Routes>
      <Route path="*" element={<Error404 componentView />} />
      {routes.items.filter(item => item.type === 'page')
        .map(item => item.children.map((subItem) => {
          if (
            (typeof subItem.off === 'boolean' && subItem.off)
            || (Array.isArray(subItem.off) && subItem.off.some(offRole => rolesList.includes(offRole)))
          ) {
            return null;
          }
          return accessLists.includes(subItem.accessName) && (
            <Route
              exact
              path={`${item.path}${subItem.path}`}
              element={subItem.component}
              name={subItem.name}
            />
          );
        }))
        .filter(Boolean)}
      {routes.items
        .map((item) => {
          if (
            (typeof item.off === 'boolean' && item.off)
            || (Array.isArray(item.off) && item.off.some(offRole => rolesList.includes(offRole)))
          ) {
            return null;
          }
          switch (true) {
            case item.accessName === 'free':
            case accessLists.includes(item.accessName):
            case (typeof item.accessGroup !== 'undefined') && accessLists.some(accessListsItems => item.accessGroup.includes(accessListsItems)): {
              return (
                item.type === 'submenu'
                  ? item.children.map((subItem) => {
                    if (
                      (typeof subItem.off === 'boolean' && subItem.off)
                      || (Array.isArray(subItem.off) && subItem.off.some(offRole => rolesList.includes(offRole)))
                    ) {
                      return null;
                    }
                    return accessLists.includes(subItem.accessName) && (
                      <Route
                        exact
                        path={`${item.path}${subItem.path}`}
                        element={subItem.component}
                        name={subItem.name}
                      />
                    );
                  }) : (
                    <Route
                      exact={item.exact}
                      path={item.path}
                      element={item.component}
                      name={item.name}
                      key={item.path}
                    />
                  )
              );
            }
            case (partnerStatus === partnerStatuses.questionnaireIsNotConfirmed): {
              return (
                <Route
                  exact={item.exact}
                  path={routersLink.dashboard}
                  element={ProfilePartner}
                  key={routersLink.dashboard}
                />
              );
            }
            default: {
              return undefined;
            }
          }
        })
        .filter(Boolean)}
    </Routes>
  );
}

export default Main;
