// Core
import { put, select } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../config/localData';
import { notificationsActions } from '../../action';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';
// Data
import { tableColumns } from '../../../../../_helpers/data/pages/notifications/tableColumns';
import { pageLinks } from '../../../../config/routes';
import { selectors } from '../../../../config/selectors';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(lists || {});
  const pathname = yield select(selectors.router.pathname);

  switch (pathname) {
    case pageLinks.notifications.all: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [...filteredFilters, createFilterByStatusField(0)];
      initialPageState.currentPage = 0;
      break;
    }
    default: {
      break;
    }
  }

  yield put(notificationsActions.setInitialState(initialPageState));
}
function createFilterByStatusField(filterValue) {
  return {
    columnName: tableColumns.read.name,
    value: filterValue,
  };
}

function getFilteredFiltersArr(arr) {
  if (Array.isArray(arr)) {
    return arr.filter(({ columnName }) => columnName !== tableColumns.read.name);
  }
  return [];
}
