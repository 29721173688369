// Core
import React from 'react';
import { Trans } from 'react-i18next';

export const tableColumns = Object.freeze({
  title: Object.freeze({
    name: 'title',
    type: 'text',
    title: <Trans>Название</Trans>,
  }),
  text: Object.freeze({
    name: 'text',
    type: 'text',
    title: <Trans>Описание</Trans>,
  }),
  type: Object.freeze({
    name: 'type',
    type: 'notificationType',
    title: <Trans>Тип сообщения</Trans>,
  }),
  group: Object.freeze({
    name: 'group',
    type: 'notificationGroup',
    title: <Trans>Группа сообщений</Trans>,
  }),
  createdAt: Object.freeze({
    name: 'createdAt',
    type: 'datetime',
    title: <Trans>Добавлено</Trans>,
  }),
  read: Object.freeze({
    name: 'read',
    type: 'boolean',
    title: <Trans>Прочитано</Trans>,
  }),
});
