// Core
import isNil from 'lodash/isNil';
import get from 'lodash/get';

const getParentCheckedRecursive = (node, arr) => {
  const parentValue = get(node, 'parentValue');
  if (!isNil(parentValue)) {
    const parent = arr.find(item => parentValue === get(item, 'value'));
    if (parent) {
      return getParentCheckedRecursive(parent, arr);
    }
  }
  return node;
};


export default getParentCheckedRecursive;
