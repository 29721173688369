// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPostProductsImportWorker,
  callGetProductsImportTemplatesDownloadWorker,
  callPutUpdateFromMagento,
  callPutUpdateValuesFromMagento,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_CONTENT_TEMPLATES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_CONTENT_TEMPLATES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CONTENT_TEMPLATES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPostProductsImport() {
  yield takeEvery(asyncTypes.POST_CONTENT_TEMPLATES_IMPORT_ASYNC, callPostProductsImportWorker);
}
function* watchGetProductsImportTemplatesDownload() {
  yield takeEvery(asyncTypes.GET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_ASYNC, callGetProductsImportTemplatesDownloadWorker);
}
function* watchPutUpdateFromMagento() {
  yield takeEvery(asyncTypes.PUT_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_ASYNC, callPutUpdateFromMagento);
}
function* watchPutUpdateValuesFromMagento() {
  yield takeEvery(asyncTypes.PUT_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_ASYNC, callPutUpdateValuesFromMagento);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CONTENT_TEMPLATES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CONTENT_TEMPLATES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CONTENT_TEMPLATES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CONTENT_TEMPLATES_SORTING, callSetToLocalDataWorker);
}

export function* watchersContentTemplates() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutUpdateFromMagento(),
    watchPutSettings(),
    watchPostProductsImport(),
    watchGetProductsImportTemplatesDownload(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPutUpdateValuesFromMagento(),
  ]);
}
