import cx from 'classnames';
// UI
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
// Engine
import { userRoles } from '../../../../../../../engine/config/userRoles';
// Helpers
import { useStyles } from '../../../../../../hooks/useStyles';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';

const style = () => ({
  notComplete: {
    background: 'rgba(224, 0, 39, 0.1)',
  },
});

function RowComponent(props) {
  const { row, children } = props;
  const classes = useStyles(style);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerSigningContract,
    userRoles.partnerPaymentWaiting,
  ]);
  const isProductNotComplete = row.productNotComplete;

  const className = cx({
    [classes.notComplete]: isProductNotComplete && isPartnerRoles,
  });

  return (
    <VirtualTable.Row {...props} className={className}>
      {children}
    </VirtualTable.Row>
  );
}

export default RowComponent;
