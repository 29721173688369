// Core
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// Components
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import ChatComponents from '../ChatComponents';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// helpers and hooks
import { getStringTruncateEnd } from '../../../../../_helpers/getFormatTruncateString';
import { useAccessList } from '../../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { selectors } from '../../../../../engine/config/selectors';
// actions
import { actions } from '../../../../../engine/core/serviceDesk/actions';
import { asyncActions } from '../../../../../engine/core/serviceDesk/saga/asyncActions';
// styles
import ChatStyles from './ChatStyles';

const Chat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const externalLink = useSelector(selectors.serviceDesk.externalLink);
  const closeQuestionPending = useSelector(selectors.serviceDesk.closeQuestionPending);
  const continueQuestionPending = useSelector(selectors.serviceDesk.continueQuestionPending);
  const isCanEdit = useSelector(selectors.serviceDesk.canEdit);
  const isCanClose = useSelector(selectors.serviceDesk.canClose);
  const isCanContinue = useSelector(selectors.serviceDesk.canContinue);
  const chatNameString = useSelector(selectors.serviceDesk.chatName);
  const hasAccessFaqServiceDescContinue = useAccessList(accessList.faqServiceDescContinue);
  const hasAccessFaqServiceDescClose = useAccessList(accessList.faqServiceDescClose);
  const classes = ChatStyles({ inputEnabled: isCanEdit });
  const chatName = getStringTruncateEnd(chatNameString, 60);
  const messagesFromServerAreLoading = useSelector(selectors.serviceDesk.messagesFromServerAreLoading);
  const chatKey = useSelector(selectors.serviceDesk.chatKey);
  const { hashId } = useParams();

  useEffect(() => () => {
    dispatch(actions.setExternalLink(null));
    dispatch(actions.setChatName(''));
    dispatch(actions.mergeChat({
      canEdit: false,
    }));
  }, [dispatch]);

  const clickHandler = () => {
    dispatch(actions.setChatKey(new Date().getTime()));
  };

  const handlerCloseQuestion = () => {
    dispatch(asyncActions.postCloseQuestionAsync({ hashId }));
  };

  const handlerContinue = () => {
    dispatch(asyncActions.postContinueQuestionAsync({ hashId }));
  };

  return (
    <>
      <PageHeader
        title={(
          <div className={classes.header}>
            <span
              title={chatName.wasTruncated ? chatNameString : null}
              className={classes.title}
            >{chatName.str}
            </span>
            <Tooltip title={t('Обновить данные')} arrow>
              <span>
                <ButtonWithIcon
                  className={classes.btnRefresh}
                  disabled={messagesFromServerAreLoading}
                  onClick={clickHandler}
                >
                  { messagesFromServerAreLoading ? <CircularProgress color="inherit" size={20} /> : <RefreshIcon />}
                </ButtonWithIcon>
              </span>
            </Tooltip>
            {externalLink && (
              <Tooltip title={t('Открыть в ServiceDesk')} arrow>
                <Link target="_blank" href={externalLink}>
                  <OpenInNewIcon color="action" />
                </Link>
              </Tooltip>
            )}
          </div>
        )}
      >
        {hasAccessFaqServiceDescContinue && isCanContinue && (
          <ButtonWithIcon
            text={t('Продолжить')}
            onClick={handlerContinue}
            disabled={continueQuestionPending}
          >
            <LockOpenIcon />
          </ButtonWithIcon>
        )}
        {hasAccessFaqServiceDescClose && isCanClose && (
          <ButtonWithIcon
            text={t('Завершить диалог')}
            onClick={handlerCloseQuestion}
            disabled={closeQuestionPending}
          >
            <CancelIcon />
          </ButtonWithIcon>
        )}
        <ButtonGoBack defaultPage={pageLinks.serviceDesk.support} />
      </PageHeader>
      <div className={classes.mainChatWrapper}>
        <ChatComponents key={chatKey} />
      </div>
    </>
  );
};

export default Chat;
