export const formName = 'orderProcessingSchedule';

export const formFields = Object.freeze({
  day0: 'day0',
  day1: 'day1',
  day2: 'day2',
  day3: 'day3',
  day4: 'day4',
  day5: 'day5',
  day6: 'day6',
});
