// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// Instruments
import { api } from '../../../../config/api';
import { actions } from '../../action';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetMailTemplatesGroupsWorker({ payload }) {
  yield put(actions.mergeMailTemplatesGroups({ pending: true }));
  const { mailTemplateGroups: statuses, mailTemplateGroupsLastUpdate: statusesLastUpdate } = localData.keysObj;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [`${statusesLastUpdate}_${payload}`]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.mailTemplateGroupsLastUpdate);

  let response;
  let data;

  switch (true) {
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.mail.getMailTemplatesGroups);
      yield apply(localData, localData.setItem, [`${statusesLastUpdate}_${payload}`, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      const dataFromLocal = yield apply(localData, localData.getItem, [`${statuses}_${payload}`]);
      data = dataFromLocal?.items || [];
      if (!data) {
        response = yield apply(api, api.mail.getMailTemplatesGroups);
      }
      break;
    }
    default: {
      data = [];
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    data = responseData;

    yield apply(localData, localData.setItem, [`${statuses}_${payload}`, { items: responseData }]);
  }
  yield put(actions.mergeMailTemplatesGroups({
    item: List(data),
    pending: false,
  }));
}
