const FramePageStyles = () => ({
  containerIframe: {
    height: '100%',
  },
  hideIframeHeader: {
    marginTop: -130,
    height: 'calc(100% + 130px)',
  },
});

export default FramePageStyles;
