export const types = Object.freeze({
  SET_ATTRIBUTE_SET_LIST: 'SET_ATTRIBUTE_SET_LIST',
  SET_ATTRIBUTE_SET_CURRENT_PAGE: 'SET_ATTRIBUTE_SET_CURRENT_PAGE',
  SET_ATTRIBUTE_SET_FILTERS: 'SET_ATTRIBUTE_SET_FILTERS',
  SET_ATTRIBUTE_SET_PAGE_SIZE: 'SET_ATTRIBUTE_SET_PAGE_SIZE',
  SET_ATTRIBUTE_SET_SORTING: 'SET_ATTRIBUTE_SET_SORTING',
  SET_ATTRIBUTE_RELOAD: 'SET_ATTRIBUTE_RELOAD',
  SET_ATTRIBUTE_SET_INITIAL_STATE: 'SET_ATTRIBUTE_SET_INITIAL_STATE',
  SET_ATTRIBUTE_SET_SETTING_STATUS: 'SET_ATTRIBUTE_SET_SETTING_STATUS',
  SET_ATTRIBUTE_SET_SETTING_PENDING: 'SET_ATTRIBUTE_SET_SETTING_PENDING',
  UPDATE_SET_ATTRIBUTE_SET_LIST: 'UPDATE_SET_ATTRIBUTE_SET_LIST',
});
