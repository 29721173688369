export default Object.freeze({
  fopEdrDateNumberRegister: 'fopEdrDateNumberRegister',
  fopPassportData: 'fopPassportData',
  fopOthersPropertyAccess: 'fopOthersPropertyAccess',
  fopBusinessType: 'fopBusinessType',
  fopOthersAbroadEnterprises: 'fopOthersAbroadEnterprises',
  fopLicenses: 'fopLicenses',
  fopYearProfit: 'fopYearProfit',
  fopProductsYearProfit: 'fopProductsYearProfit',
  fopEmployeeCount: 'fopEmployeeCount',
  fopMailFullName: 'fopMailFullName',
  fopMailPhone: 'fopMailPhone',
  fopMailRegion: 'fopMailRegion',
  fopMailLocation: 'fopMailLocation',
  fopMailNovaposhtaDepartment: 'fopMailNovaposhtaDepartment',
});
