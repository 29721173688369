// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function UserIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 17 18">
      <path d="M8.1 9C11.574 9 14.4009 11.826 14.4009 15.3V17.1C14.4009 17.1 14.4 18 15.3 18C16.2 18 16.2 17.1 16.2 17.1V15.3C16.2 10.8261 12.5739 7.2 8.1 7.2C3.6261 7.2 0 10.8261 0 15.3L0 17.1C0 17.1 0 18 0.9 18C1.8 18 1.8 17.1 1.8 17.1L1.8 15.3C1.8 11.826 4.626 9 8.1 9ZM8.1 1.8C9.5895 1.8 10.8 3.0114 10.8 4.5C10.8 5.9886 9.5895 7.2 8.1 7.2C6.6114 7.2 5.4 5.9886 5.4 4.5C5.4 3.0114 6.6114 1.8 8.1 1.8ZM8.1 0C5.6151 0 3.6 2.0151 3.6 4.5C3.6 6.9849 5.6151 9 8.1 9C10.5858 9 12.6009 6.9858 12.6009 4.5C12.6009 2.0151 10.5858 0 8.1 0Z" />
    </SvgIcon>
  );
}

export default UserIcon;
