// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';
import { initialize } from 'redux-form/immutable';

// lodash
import map from 'lodash/map';

// helpers
import { getDateWithCurrentLocal } from '../../../../../_helpers/getDate';
import { formFields, formName } from '../../../../../ui/page/TransactionsPayment/TransactionPaymentEdit/helpers/form';

// config
import { api } from '../../../../config/api';

// actions
import { actions } from '../../actions';

export function* callGetTransactionPaymentInfoWorker({ payload: hashId }) {
  yield put(actions.setTransactionPaymentInfo({ pending: true }));

  const response = yield apply(api, api.partners.getTransactionPaymentByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const {
      userConfirmed, status, partnerPaymentType,
      orderStatus, confirmedDate, partner, order,
      createdAt, updatedAt,
    } = data;

    const formData = Object.freeze({
      ...(userConfirmed ? {
        [formFields.userConfirmed]: userConfirmed.label,
      } : {}),
      [formFields.status]: status.label,
      [formFields.partnerPaymentType]: partnerPaymentType,
      [formFields.orderStatus]: orderStatus,
      [formFields.partner]: partner,
      [formFields.order]: order,
      [formFields.confirmedDate]: getDateWithCurrentLocal(confirmedDate),
      [formFields.createdAt]: getDateWithCurrentLocal(createdAt),
      [formFields.updatedAt]: getDateWithCurrentLocal(updatedAt),
    });
    const fields = map(formFields, value => value);

    yield put(initialize(formName, formData, fields));
    yield put(actions.setTransactionPaymentInfo({
      data: Map({
        ...data,
        confirmedDate: getDateWithCurrentLocal(data.confirmedDate),
        createdAt: getDateWithCurrentLocal(data.createdAt),
        updatedAt: getDateWithCurrentLocal(data.updatedAt),
      }),
    }));
  }

  yield put(actions.setTransactionPaymentInfo({ pending: false }));
}
