// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetCategoriesContextWorker,
  callGetCategoryItemByHashIdWorker,
  callPostCommissionUploadWorker,
  callPutCategoryItemByHashIdWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_CATEGORIES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetCategoriesListsContext() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_LIST_CONTEXT_ASYNC, callGetCategoriesContextWorker);
}
function* watchGetCategoryItemByHashId() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_ITEM_BY_HASH_ID_ASYNC, callGetCategoryItemByHashIdWorker);
}
function* watchPostCommissionUploadWorker() {
  yield takeEvery(asyncTypes.POST_CATEGORIES_COMMISSION_UPLOAD_ASYNC, callPostCommissionUploadWorker);
}
function* watchPutCategoryItemByHashId() {
  yield takeEvery(asyncTypes.PUT_CATEGORIES_ITEM_BY_HASH_ID_ASYNC, callPutCategoryItemByHashIdWorker);
}

function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CATEGORIES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CATEGORIES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CATEGORIES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CATEGORIES_SORTING, callSetToLocalDataWorker);
}

export function* watchersCategories() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchGetCategoryItemByHashId(),
    watchGetCategoriesListsContext(),
    watchSetCurrentPage(),
    watchPutCategoryItemByHashId(),
    watchPostCommissionUploadWorker(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
  ]);
}
