import React from 'react';
import { Trans } from 'react-i18next';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

const weekOption = { weekStartsOn: 1 };
const defineds = {
  startOfWeek: startOfWeek(new Date(), weekOption),
  endOfWeek: endOfWeek(new Date(), weekOption),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), weekOption),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), weekOption),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate)
      && isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function dataRangeStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = dataRangeStaticRanges([
  {
    label: <Trans>Сегодня</Trans>,
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
      empty: false,
    }),
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate)
        && isSameDay(range.endDate, definedRange.endDate) && !range.empty
      );
    },
  },
  {
    label: <Trans>Вчера</Trans>,
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: <Trans>Эта неделя</Trans>,
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: <Trans>Прошлая неделя</Trans>,
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: <Trans>Этот месяц</Trans>,
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: <Trans>Прошлый месяц</Trans>,
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);

const withoutDate = {
  label: <Trans>Без даты</Trans>,
  range: () => ({
    startDate: new Date(0),
    endDate: new Date(0),
    empty: 'empty',
  }),
  isSelected(range) {
    return !!range.empty;
  },
};

export const expandedStaticRanges = dataRangeStaticRanges([...defaultStaticRanges, withoutDate]);


export const defaultInputRanges = [
  {
    label: <Trans>Дней до сегодня</Trans>,
    range(value) {
      return {
        startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) {
        return '-';
      }
      if (!range.startDate) {
        return '∞';
      }
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
  {
    label: <Trans>Дней от сегодня</Trans>,
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) {
        return '-';
      }
      if (!range.endDate) {
        return '∞';
      }
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    },
  },
];
