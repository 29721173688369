// Core
import {
  apply, delay, put, select,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { stopSubmit } from 'redux-form';
import { Map } from 'immutable';
import forEach from 'lodash/forEach';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { uiActions } from '../../../ui/action';
import { pageLinks } from '../../../../config/routes';
import { formName } from '../../../../../ui/page/Partner/form';
import { partnerAsyncAction } from '../asyncAction';
import { partnerActions } from '../../action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../config/selectors';
import { checkPartnerApprovedData } from '../../../../../_helpers/checkPartnerApprovedData';
import { userRoles as USER_ROLES } from '../../../../config/userRoles';
import { statisticsActionAsync } from '../../../statistics/saga/asyncAction';

export function* callPutPartnerInfoWorker({
  payload: {
    hashId,
    formData,
    userRoles = [],
    isProfilePartnerPage = false,
    redirect = true,
    checkApprovedData = true,
  },
}) {
  yield put(partnerActions.mergePartnerSave({ pending: true }));
  const updateFormData = JSON.parse(JSON.stringify(formData));
  const partner = yield select(selectors.partner.userData);
  const partnerIsTopForceAction = yield select(selectors.partner.partnerIsTopForceAction);
  const paymentTypes = yield select(selectors.partners.paymentTypes);
  const normalizePaymentTypes = paymentTypes?.toJS();
  const registeredFields = (yield select(state => selectors.form.registeredFields(state, formName))).toJS();
  const isPartnerRoles = userRoles.map(item => item.includes(USER_ROLES.partner)).includes(true);
  const partnerPaymentTypes = formData?.partnerPaymentTypes;
  if (checkApprovedData) {
    const isNotApprovedData = checkPartnerApprovedData({
      partner, registeredFields, isPartnerRoles,
    });
    if (isNotApprovedData) {
      yield put(partnerActions.mergePartnerSave({ pending: false }));
      return;
    }
  }

  if (partnerPaymentTypes) {
    const activePaymentTypes = (normalizePaymentTypes || []).map(item => ({
      type: item.type,
      isActive: item.configurations?.isActive,
    })).filter(Boolean).reduce((obj, item) => {
      const values = {};
      values[item.type] = item.isActive;
      return Object.assign(obj, values);
    }, {});

    forEach(partnerPaymentTypes, (value, key) => {
      if (activePaymentTypes[key] !== true) {
        delete updateFormData.partnerPaymentTypes[key];
      }
    });
  }
  if (partnerIsTopForceAction) {
    updateFormData.forceActionTop = partnerIsTopForceAction;
  }
  const response = yield apply(api, api.partner.putDataByHashId, [{
    hashId,
    formData: updateFormData,
  }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    yield put(partnerActions.mergePartnerSave({ showPopUps: Map(response.data?.showPopups || {}) }));
    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));

        const submissionErrors = errors.reduce((acc, currentValue) => {
          acc[currentValue.property_path] = currentValue.message;
          return acc;
        }, {});

        yield put(stopSubmit(formName, submissionErrors));
        break;
      }
      case requestStatus.success: {
        const { message, isTop } = response.data;
        if (partnerIsTopForceAction) {
          yield put(partnerActions.mergePartnerIsTop({ forceAction: false, isOpen: false }));
        }
        if (isTop) {
          yield put(partnerActions.mergePartnerIsTop({ isOpen: true }));
        } else {
          yield put(setSuccessMessage(message, statusTitle));
          if (userRoles.length === 0) {
            const currentLocation = yield select(selectors.router.pathname);
            const prevLocation = yield select(selectors.location.prevLocation);

            if (Boolean(response.data?.showPopups) === false && redirect) {
              if (typeof prevLocation === 'string' && prevLocation !== currentLocation) {
                yield delay(0);
                yield put(push(prevLocation));
              } else {
                yield delay(0);
                yield put(push(pageLinks.partner.all));
              }
            }
          }

          if (!userRoles.includes('ROLE_PARTNER_MANAGE_PROFILE') && (Boolean(response.data?.showPopups) === true || userRoles.length > 0)) {
            yield put(partnerAsyncAction.getPartnerInfo({ hashId, resetShowPopUps: false }));
          } else if (userRoles.includes('ROLE_PARTNER_MANAGE_PROFILE')) {
            yield put(uiActions.inApprove(true));
          }

          if (isProfilePartnerPage) {
            yield put(partnerAsyncAction.getPartnerInfo({ hashId: partner.hashId }));
            yield put(statisticsActionAsync.getStatisticsAsync());
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(partnerActions.mergePartnerSave({ pending: false }));
}
