export const types = Object.freeze({
  SET_FEEDS_MATCHING_CATEGORIES_LIST: 'SET_FEEDS_MATCHING_CATEGORIES_LIST',
  UPDATE_FEEDS_MATCHING_CATEGORIES_LIST: 'UPDATE_FEEDS_MATCHING_CATEGORIES_LIST',
  SET_FEEDS_MATCHING_CATEGORIES_CURRENT_PAGE: 'SET_FEEDS_MATCHING_CATEGORIES_CURRENT_PAGE',
  SET_FEEDS_MATCHING_CATEGORIES_FILTERS: 'SET_FEEDS_MATCHING_CATEGORIES_FILTERS',
  SET_FEEDS_MATCHING_CATEGORIES_PAGE_SIZE: 'SET_FEEDS_MATCHING_CATEGORIES_PAGE_SIZE',
  SET_FEEDS_MATCHING_CATEGORIES_SORTING: 'SET_FEEDS_MATCHING_CATEGORIES_SORTING',
  SET_FEEDS_MATCHING_CATEGORIES_INITIAL_STATE: 'SET_FEEDS_MATCHING_CATEGORIES_INITIAL_STATE',
  SET_FEEDS_MATCHING_CATEGORIES_SETTING_STATUS: 'SET_FEEDS_MATCHING_CATEGORIES_SETTING_STATUS',
  SET_FEEDS_MATCHING_CATEGORIES_SETTING_PENDING: 'SET_FEEDS_MATCHING_CATEGORIES_SETTING_PENDING',
  SET_FEEDS_MATCHING_CATEGORIES_RELOAD: 'SET_FEEDS_MATCHING_CATEGORIES_RELOAD',
});
