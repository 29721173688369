// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function RingIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 16 17">
      <mask id="path-1-outside-1_6871_9106" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16" fill="black">
        <rect fill="white" width="16" height="16" />
        <path d="M15.3417 13.6841C14.8722 13.2656 14.4612 12.7858 14.1198 12.2577C13.747 11.5287 13.5235 10.7326 13.4626 9.91608V7.5112C13.4658 6.22873 13.0006 4.98922 12.1543 4.02556C11.3081 3.06191 10.1391 2.44043 8.86699 2.2779V1.6499C8.86699 1.47754 8.79852 1.31223 8.67664 1.19035C8.55476 1.06847 8.38945 1 8.21709 1C8.04472 1 7.87942 1.06847 7.75754 1.19035C7.63566 1.31223 7.56718 1.47754 7.56718 1.6499V2.28764C6.30645 2.46188 5.15158 3.08712 4.31647 4.04754C3.48136 5.00796 3.0226 6.23848 3.02517 7.5112V9.91608C2.96419 10.7326 2.74076 11.5287 2.36796 12.2577C2.03249 12.7846 1.62809 13.2643 1.16552 13.6841C1.11359 13.7297 1.07197 13.7858 1.04343 13.8488C1.01489 13.9117 1.00009 13.9801 1 14.0492V14.7112C1 14.8404 1.05129 14.9642 1.14259 15.0555C1.23388 15.1468 1.35771 15.1981 1.48682 15.1981H15.0204C15.1495 15.1981 15.2733 15.1468 15.3646 15.0555C15.4559 14.9642 15.5072 14.8404 15.5072 14.7112V14.0492C15.5071 13.9801 15.4923 13.9117 15.4638 13.8488C15.4352 13.7858 15.3936 13.7297 15.3417 13.6841ZM2.01258 14.2244C2.46552 13.7869 2.86432 13.2966 3.20042 12.764C3.67001 11.8835 3.944 10.9121 4.00367 9.91608V7.5112C3.98436 6.94067 4.08006 6.37208 4.28507 5.8393C4.49008 5.30652 4.80021 4.82045 5.19698 4.41002C5.59376 3.99959 6.06907 3.6732 6.59461 3.45029C7.12015 3.22738 7.68517 3.1125 8.25603 3.1125C8.82689 3.1125 9.39192 3.22738 9.91746 3.45029C10.443 3.6732 10.9183 3.99959 11.3151 4.41002C11.7119 4.82045 12.022 5.30652 12.227 5.8393C12.432 6.37208 12.5277 6.94067 12.5084 7.5112V9.91608C12.5681 10.9121 12.8421 11.8835 13.3116 12.764C13.6477 13.2966 14.0465 13.7869 14.4995 14.2244H2.01258Z" />
      </mask>
      <path d="M15.3417 13.6841C14.8722 13.2656 14.4612 12.7858 14.1198 12.2577C13.747 11.5287 13.5235 10.7326 13.4626 9.91608V7.5112C13.4658 6.22873 13.0006 4.98922 12.1543 4.02556C11.3081 3.06191 10.1391 2.44043 8.86699 2.2779V1.6499C8.86699 1.47754 8.79852 1.31223 8.67664 1.19035C8.55476 1.06847 8.38945 1 8.21709 1C8.04472 1 7.87942 1.06847 7.75754 1.19035C7.63566 1.31223 7.56718 1.47754 7.56718 1.6499V2.28764C6.30645 2.46188 5.15158 3.08712 4.31647 4.04754C3.48136 5.00796 3.0226 6.23848 3.02517 7.5112V9.91608C2.96419 10.7326 2.74076 11.5287 2.36796 12.2577C2.03249 12.7846 1.62809 13.2643 1.16552 13.6841C1.11359 13.7297 1.07197 13.7858 1.04343 13.8488C1.01489 13.9117 1.00009 13.9801 1 14.0492V14.7112C1 14.8404 1.05129 14.9642 1.14259 15.0555C1.23388 15.1468 1.35771 15.1981 1.48682 15.1981H15.0204C15.1495 15.1981 15.2733 15.1468 15.3646 15.0555C15.4559 14.9642 15.5072 14.8404 15.5072 14.7112V14.0492C15.5071 13.9801 15.4923 13.9117 15.4638 13.8488C15.4352 13.7858 15.3936 13.7297 15.3417 13.6841ZM2.01258 14.2244C2.46552 13.7869 2.86432 13.2966 3.20042 12.764C3.67001 11.8835 3.944 10.9121 4.00367 9.91608V7.5112C3.98436 6.94067 4.08006 6.37208 4.28507 5.8393C4.49008 5.30652 4.80021 4.82045 5.19698 4.41002C5.59376 3.99959 6.06907 3.6732 6.59461 3.45029C7.12015 3.22738 7.68517 3.1125 8.25603 3.1125C8.82689 3.1125 9.39192 3.22738 9.91746 3.45029C10.443 3.6732 10.9183 3.99959 11.3151 4.41002C11.7119 4.82045 12.022 5.30652 12.227 5.8393C12.432 6.37208 12.5277 6.94067 12.5084 7.5112V9.91608C12.5681 10.9121 12.8421 11.8835 13.3116 12.764C13.6477 13.2966 14.0465 13.7869 14.4995 14.2244H2.01258Z" />
      <path d="M15.3417 13.6841C14.8722 13.2656 14.4612 12.7858 14.1198 12.2577C13.747 11.5287 13.5235 10.7326 13.4626 9.91608V7.5112C13.4658 6.22873 13.0006 4.98922 12.1543 4.02556C11.3081 3.06191 10.1391 2.44043 8.86699 2.2779V1.6499C8.86699 1.47754 8.79852 1.31223 8.67664 1.19035C8.55476 1.06847 8.38945 1 8.21709 1C8.04472 1 7.87942 1.06847 7.75754 1.19035C7.63566 1.31223 7.56718 1.47754 7.56718 1.6499V2.28764C6.30645 2.46188 5.15158 3.08712 4.31647 4.04754C3.48136 5.00796 3.0226 6.23848 3.02517 7.5112V9.91608C2.96419 10.7326 2.74076 11.5287 2.36796 12.2577C2.03249 12.7846 1.62809 13.2643 1.16552 13.6841C1.11359 13.7297 1.07197 13.7858 1.04343 13.8488C1.01489 13.9117 1.00009 13.9801 1 14.0492V14.7112C1 14.8404 1.05129 14.9642 1.14259 15.0555C1.23388 15.1468 1.35771 15.1981 1.48682 15.1981H15.0204C15.1495 15.1981 15.2733 15.1468 15.3646 15.0555C15.4559 14.9642 15.5072 14.8404 15.5072 14.7112V14.0492C15.5071 13.9801 15.4923 13.9117 15.4638 13.8488C15.4352 13.7858 15.3936 13.7297 15.3417 13.6841ZM2.01258 14.2244C2.46552 13.7869 2.86432 13.2966 3.20042 12.764C3.67001 11.8835 3.944 10.9121 4.00367 9.91608V7.5112C3.98436 6.94067 4.08006 6.37208 4.28507 5.8393C4.49008 5.30652 4.80021 4.82045 5.19698 4.41002C5.59376 3.99959 6.06907 3.6732 6.59461 3.45029C7.12015 3.22738 7.68517 3.1125 8.25603 3.1125C8.82689 3.1125 9.39192 3.22738 9.91746 3.45029C10.443 3.6732 10.9183 3.99959 11.3151 4.41002C11.7119 4.82045 12.022 5.30652 12.227 5.8393C12.432 6.37208 12.5277 6.94067 12.5084 7.5112V9.91608C12.5681 10.9121 12.8421 11.8835 13.3116 12.764C13.6477 13.2966 14.0465 13.7869 14.4995 14.2244H2.01258Z" strokeWidth="0.4" mask="url(#path-1-outside-1_6871_9106)" />
      <path d="M8.25174 16.9998C8.59021 16.992 8.91501 16.8648 9.1687 16.6406C9.42239 16.4164 9.58862 16.1097 9.63798 15.7748H6.81177C6.86253 16.1188 7.03653 16.4327 7.30141 16.6581C7.56629 16.8834 7.90401 17.0049 8.25174 16.9998Z" />
    </SvgIcon>
  );
}

export default RingIcon;
