// Core
import { apply, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';

export function* callGetStatusesWorker({ payload }) {
  yield put(partnerActions.setStatusesListPending(true));

  const response = yield apply(api, api.partner.getStatusesList, [payload]);
  if (response && response.status >= 200 && response.status < 300) {
    yield put(partnerActions.setStatusesList(response.data));
  }

  yield put(partnerActions.setStatusesListPending(false));
}
