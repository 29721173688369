// Core
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import NotificationsTable from './All/NotificationsTable';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Hooks
import { useNotificationsList } from './_hooks/useNotificationsList';
// Engine
import { notificationsActionAsync } from '../../../engine/core/notifications/saga/asyncAction';
import { selectors } from '../../../engine/config/selectors';
import { notificationsActions } from '../../../engine/core/notifications/action';
import { initialState, stateKeys } from '../../../engine/core/notifications/reducers';
import ReadNotificationButton from './All/ReadNotificationButton';
import { statisticsActionAsync } from '../../../engine/core/statistics/saga/asyncAction';

function NotificationsPage() {
  const { t } = useTranslation();
  useNotificationsList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.notification.settingStatus);
  const settingPending = useSelector(selectors.notification.settingPending);
  const filters = useSelector(selectors.notification.filters);
  const sorting = useSelector(selectors.notification.sorting);
  const isDisabledResetButton = !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(notificationsActions.setFilters(initialState.get(stateKeys.notificationsList).get(stateKeys.filters).toJS()));
    dispatch(notificationsActions.setSorting(initialState.get(stateKeys.notificationsList).get(stateKeys.sorting).toJS()));
    dispatch(notificationsActions.setCurrentPage(initialState.get(stateKeys.notificationsList).get(stateKeys.currentPage)));
  }, [dispatch]);
  const putSettings = () => dispatch(notificationsActionAsync.putSettingsAsync());

  useEffect(() => {
    dispatch(statisticsActionAsync.getStatisticsAsync());
  }, [dispatch]);
  return (
    <>
      <PageHeader title={t('Уведомления')}>
        <ReadNotificationButton className="target4" />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
      </PageHeader>
      <NotificationsTable />
    </>
  );
}

export default NotificationsPage;
