import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
import { KeyboardArrowRight } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { api } from '../../../../engine/config/api';
import Loading from '../../../components/Loading/Loading';
import { getStatusColor, serviceRequestsStatuses } from '../../../../engine/config/serviceRequestsStatuses';
import { COLOR } from '../../../containers/App/AppStyles';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const color = getStatusColor(row.changeValueAfter);

  return (
    <>
      <Box sx={{
        border: 'solid 1px rgba(48, 48, 48, 0.08)',
        borderLeft: `5px solid ${color}`,
        borderRadius: '5px',
        margin: '3px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
      >
        <Grid container spacing={1} sx={{ marginTop: '0' }} alignItems="center">
          <Grid item xs={3} lg={3}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Box width={'38px'}>
                {row.changeValueAfter === serviceRequestsStatuses.CANCELED && (
                  <>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRight />}
                    </IconButton>
                  </>
                )}
              </Box>
              <Box>
                <Typography fontSize={'13px'} fontWeight={400} color={COLOR.black['54']}>{row.changeDate}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Box sx={{ color: getStatusColor(row.changeValueBefore) }}>
              <Typography fontSize={'13px'} fontWeight={400}>{row.changeLabelBefore}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Box sx={{ color: getStatusColor(row.changeValueAfter) }}>
              <Typography fontSize={'13px'} fontWeight={400}>{row.changeLabelAfter}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3} lg={3}>
            <Typography fontSize={'13px'} fontWeight={400}>{row.user}</Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ marginLeft: '20px' }}>
                <Grid marginTop={'10px'} marginBottom={'10px'} container spacing={2}>
                  <Grid item lg={4} xs={4}>
                    <Typography fontSize={'14px'} color={COLOR.black['54']}>Причина скасування: </Typography>
                  </Grid>
                  <Grid item lg={8} xs={8}>
                    <Typography fontSize={'14px'}>{row.cancelReason}</Typography>
                  </Grid>
                  {row.cancelComment && row.cancelComment !== '' && (
                    <>
                      <Grid item lg={4} xs={4}>
                        <Typography fontWeight={400} fontSize={'14px'} color={COLOR.black['54']}>Коментар: </Typography>
                      </Grid>
                      <Grid item lg={8} xs={8}>
                        <Typography fontWeight={400} fontSize={'14px'}>{row.cancelComment}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export function ChangeHistoryTable({ partnerHash, partnerServiceRequestId, fetchHistory }) {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  const params = {
    limit: 100,
    offset: 0,
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const historyResponse = await api.partnerServiceRequests.getRequestHistoryList(
        partnerHash, partnerServiceRequestId, params,
      );
      setItems(historyResponse.data.items);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchHistory]);

  if (isLoading) {
    return (
      <>
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={isLoading} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3} lg={3}>
          <Typography marginLeft={'50px'} fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>Дата зміни</Typography>
        </Grid>
        <Grid item xs={3} lg={3}>
          <Typography fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>Статус до зміни</Typography>
        </Grid>
        <Grid item xs={3} lg={3}>
          <Typography fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>Статус після зміни</Typography>
        </Grid>
        <Grid item xs={3} lg={3}>
          <Typography fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>
            Користувач, який змінював статус
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          {items.map(row => (
            <Row key={row.name} row={row} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
