// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import NumberFormat from 'react-number-format';
import cx from 'classnames';
import { string } from 'prop-types';

// UI
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Hooks
import { useStyles } from '../../../../hooks/useStyles';

// Icons
import ArrowDownRoundedIcon from '../../../../icons/ArrowDownRounded';
import BalanceIcon from '../../../../icons/Balance';
import CalendarRoundedIcon from '../../../../icons/CalendarRounded';

import BalancePartnerStyles from './styles';

function BalancePartner({
  summaryBalance, holdBalance, creditLimit, partnerOffTime,
}) {
  const { t } = useTranslation();
  const classes = useStyles(BalancePartnerStyles);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const isNegativeBalance = Number(summaryBalance) < 0;
  const isNegativeHold = Number(holdBalance) < 0;
  const isNegativeCredit = Number(creditLimit) < 0;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
      <Button
        className={cx(classes.balanceMenuButton, { [classes.helpMenuButtonActive]: isOpen })}
        id="balance-partner-menu-button"
        aria-controls={isOpen ? 'balance-partner-menu' : null}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : null}
        onClick={handleClick}
        disableRipple
      >
        <Box className={classes.balanceMenuBox}>
          <BalanceIcon className={classes.balanceIcon} />
          <Typography className={cx(classes.balanceTitle, {
            [classes.negativeValue]: isNegativeBalance,
          })}
          >
            <NumberFormat
              value={summaryBalance}
              thousandSeparator=" "
              displayType="text"
              suffix={t(' ₴')}
            />
          </Typography>
        </Box>
        <ArrowDownRoundedIcon
          className={cx(classes.balanceDropIcon, { [classes.balanceDropIconActive]: isOpen })}
        />
        <Menu
          id="balance-partner-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          className={classes.balancesMenu}
        >
          {!isUndefined(summaryBalance) && (
          <MenuItem className={classes.menuItem}>
            <Typography variant="span">{t('Остаток Баланса')}:&nbsp;</Typography>
            <Typography
              className={cx(classes.menuItemValue, {
                [classes.negativeValue]: isNegativeBalance,
              })}
              variant="span"
            >
              <NumberFormat
                value={summaryBalance}
                thousandSeparator=" "
                displayType="text"
                suffix={t(' ₴')}
              />
            </Typography>
          </MenuItem>
          )}
          {!isUndefined(holdBalance) && (
          <MenuItem className={classes.menuItem}>
            <Typography variant="span">{t('Холд')}:&nbsp;</Typography>
            <Typography
              className={cx(classes.menuItemValue, {
                [classes.negativeValue]: isNegativeHold,
              })}
              variant="span"
            >
              <NumberFormat
                value={holdBalance}
                thousandSeparator=" "
                displayType="text"
                suffix={t(' ₴')}
              />
            </Typography>
          </MenuItem>
          )}
          {!isUndefined(creditLimit) && (
          <MenuItem className={cx(classes.menuItem, classes.creditItem)}>
            <Typography variant="span">{t('Кредитный лимит')}:&nbsp;</Typography>
            <Typography
              className={cx(classes.menuItemValue, {
                [classes.negativeValue]: isNegativeCredit,
              })}
              variant="span"
            >
              <NumberFormat
                value={creditLimit}
                thousandSeparator=" "
                partnerOffTime
                displayType="text"
                suffix={t(' ₴')}
              />
            </Typography>
            {partnerOffTime && (
            <Box>
              <Typography className={classes.creditTitle}>{t('Дата отключения товаров')}:</Typography>
              <Typography className={classes.creditDate}>
                <CalendarRoundedIcon />
                {(new Date(partnerOffTime).toLocaleDateString('uk-UK'))}
              </Typography>
            </Box>
            )}
          </MenuItem>
          )}
        </Menu>
      </Button>
    </ClickAwayListener>
  );
}

BalancePartner.propTypes = {
  summaryBalance: string,
  holdBalance: string,
  creditLimit: string,
  partnerOffTime: string,
};

export default BalancePartner;
