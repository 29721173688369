// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { push } from 'redux-first-history';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
import { formName as refreshFormName } from '../../../../../../ui/page/Products/PriceLists/components/RefreshModal';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { pageLinks } from '../../../../../config/routes';

export function* callPostRefreshWorker({ payload: data }) {
  yield put(actions.mergeFeedsRefresh({ pending: true }));
  const response = yield apply(api, api.feeds.postFeedsRefresh, [{ id: data.id, file: data.file }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        const errorMessage = errors[0].message;
        yield put(actions.setFeedsRefreshResult({ showModal: true, message: errorMessage, statusTitle }));
        yield put(actions.mergeFeedsRefresh({ showModal: false }));
        break;
      }
      case 'success': {
        const { message, errors } = response.data;
        yield put(actions.setFeedsRefreshResult({ showModal: true, message, statusTitle }));
        yield put(asyncActions.getFeedItemByHashIdAsync(data.hashId));
        if (data.file) {
          yield put(reset(refreshFormName));
          yield put(actions.mergeFeedsRefresh({ showModal: false }));
        }
        if (errors) {
          yield errors.map(error => put(actions.setFeedsRefreshResult({ showModal: true, message: error.message, statusTitle })));
          yield put(actions.mergeFeedsRefresh({ showModal: false }));
        } else {
          yield put(actions.mergeImport({ categoryId: data.id, showSuccess: true }));
        }
        yield put(push(pageLinks.productsAndPrices.prices));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeFeedsRefresh({ pending: false }));
}
