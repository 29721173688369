// Core
import {
  apply, put, select, delay,
} from 'redux-saga/effects';
import { actions } from '../../actions';
import { productFeedCategoriesAttributes } from '../../selectors';
import { api, requestStatus } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { asyncActions } from '../asyncActions';
import { productFeedCategoriesAttributesConst } from '../../../const';

export function* callPostAutoConnectWorker({ payload }) {
  yield put(actions.setAutoConnectPending(true));
  const { hashId, type = 'features' } = payload;
  const response = yield apply(api, api.feeds.postAutoConnect, [{ hashId, type }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.success: {
        const { statusTitle, message, feedCategories } = responseData;
        yield put(setSuccessMessage(message, statusTitle));

        if (feedCategories) {
          const currentList = yield select(productFeedCategoriesAttributes.list);
          const currentTotalCount = yield select(productFeedCategoriesAttributes.totalCount);
          const data = {
            items: currentList.map(item => feedCategories.find(bItem => bItem.id === item.id) || item),
            totalCount: currentTotalCount,
            pending: false,
          };
          yield put(actions.setList(data));
          yield delay(1000);
        }

        const pageSize = yield select(productFeedCategoriesAttributes.pageSize);
        const sorting = (yield select(productFeedCategoriesAttributes.sorting)).toJS();
        const filters = (yield select(productFeedCategoriesAttributes.filters)).toJS();
        filters.push({
          columnName: 'categoryStatus',
          value: [{
            value: productFeedCategoriesAttributesConst.AUTO_CONNECT_STATUS,
          }],
        });

        const params = {
          sorting,
          limit: pageSize,
        };

        filters.reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);

        yield put(asyncActions.getListAsync(params, hashId, productFeedCategoriesAttributesConst.AUTO_CONNECT_MODE_BUTTON));
        break;
      }
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(actions.setAutoConnectPending(false));
        break;
      }
      default: {
        break;
      }
    }
  }
}
