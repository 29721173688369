// Core
import { useTranslation } from 'react-i18next';
import 'moment/locale/uk';
import 'moment/locale/ru';
// Parts
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { locale } from '../../../../../engine/init/i18n';
import CalendarRounded from '../../../../icons/CalendarRounded';
import { Wrapper } from './styles';

function DataRangeDrop(props) {
  const {
    label,
    margin, disablePast, fullWidth,
    minDate, maxDate, input, handleChange,
  } = props;
  const { i18n: { language } } = useTranslation();
  const { value: inputValue, ...restInput } = input;
  const value = inputValue || null;

  const onChange = (newValue) => {
    if (handleChange) {
      handleChange(newValue);
    }
    if (input && input.onChange) {
      input?.onChange(newValue);
    }
  };

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale[language]}>
        <DesktopDatePicker
          slots={{
            openPickerIcon: CalendarRounded,
          }}
          slotProps={{
            textField: {
              readOnly: true,
              variant: 'outlined',
              color: 'secondary',
              margin,
              fullWidth,
              error: false,
            },
          }}
          {...restInput}
          onChange={onChange}
          value={value}
          disablePast={disablePast}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </Wrapper>
  );
}

export default DataRangeDrop;
