// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';

// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';

export function* callGetDataXmlDownloadWorker({ payload: partnerId }) {
  yield put(actions.setDataXmlDownloadPending(true));

  const response = yield apply(api, api.priceCreatorFiles.postDataXmlDownload, [{ partner: partnerId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setDataXmlDownloadModalActive(false));
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setDataXmlDownloadPending(false));
}
