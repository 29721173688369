import { getIn, List } from 'immutable';
import { stateKeys as reportsKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const reports = Object.freeze({
  currentPage: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.currentPage], 0),
  filters: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.filters], List()),
  isLoading: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.pending], false),
  list: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.items], List()),
  pageSize: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.reload], false),
  firstLoad: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.firstLoad], false),
  totalCount: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.totalCount], 0),
  sorting: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.sorting], List()),
  settingStatus: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.settingStatus], false),
  settingPending: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.settingPending], false),
  reportGeneral: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.report], false),
  reportModalIsOpen: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.modalIsOpen], false),
  partnerId: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.partnerId], null),
  createdAt: state => getIn(state, [reportsKeys.controller, reportsKeys.reportsList, reportsKeys.createdAt], 0),
  reconciliationDatesItems: state => getIn(state, [reportsKeys.controller, reportsKeys.reconciliation, reportsKeys.dates, reportsKeys.items], List()),
  reconciliationDatesPending: state => getIn(state, [reportsKeys.controller, reportsKeys.reconciliation, reportsKeys.dates, reportsKeys.pending], true),
  reconciliationDownloadPending: state => getIn(state, [reportsKeys.controller, reportsKeys.reconciliation, reportsKeys.downloadPending], false),
  reconciliationModalIsOpen: state => getIn(state, [reportsKeys.controller, reportsKeys.reconciliation, reportsKeys.modalIsOpen], false),
});
