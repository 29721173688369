// Core
import React, { useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import { useDispatch } from 'react-redux';
// parts
import FormSelect from '../../../../../../components/_Form/Selects/FormSelect/FormSelect';
// config
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
import { userRoles } from '../../../../../../../engine/config/userRoles';
// Actions
import { partnerAsyncAction } from '../../../../../../../engine/core/_partners/saga/asyncAction';
import { partnerActions } from '../../../../../../../engine/core/_partners/action';
// helpers
import { formFields } from './ProductMultipleEditingForm';
// hooks
import { useShallowEqualSelector } from '../../../../../../hooks/useShallowEqualSelector';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';

function ProductManagerField(props) {
  const {
    disabled, validate, color, label,
  } = props;
  const dispatch = useDispatch();
  const managersListPending = (useShallowEqualSelector(selectors.partner.listUsersContextLoading));
  const managersList = (useShallowEqualSelector(selectors.partner.listUsersAdminsContext));
  const managersListContext = (useShallowEqualSelector(selectors.partner.listUsersContext));
  const hasAccessUserContextList = useAccessList(accessList.userContextList);
  const isAdminRole = useUserSomeRole([userRoles.admin]);

  useEffect(() => {
    if (hasAccessUserContextList) {
      dispatch(partnerAsyncAction.getUsersContextListAsync({
        idRole: '6',
        itemsWithAdmin: isAdminRole,
        context: isAdminRole ? 'select_with_unassigned' : 'select',
      }));
    }
  }, [dispatch, hasAccessUserContextList, isAdminRole]);

  useEffect(() => () => {
    dispatch(partnerActions.setUsersContext({ itemsWithAdmin: [] }));
  }, [dispatch]);

  return (
    <Field
      component={FormSelect}
      isPortal={false}
      hasSearch
      options={isAdminRole ? managersList : managersListContext}
      name={formFields.manager}
      label={label}
      isLoading={managersListPending}
      disabled={!isAdminRole || disabled || managersListPending}
      fullWidth
      validation
      variant="outlined"
      validate={validate}
      color={color}
    />
  );
}

export default ProductManagerField;
