// Core
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Icons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// Hooks
import ShowAllLinkStyles from './ShowAllLinkStyles';

const useStyles = makeStyles(ShowAllLinkStyles);
function ShowAllLink(props) {
  const { to } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <NavLink className={classes.showAllLink} to={to}>
      {t('Показать все')} <ArrowRightAltIcon />
    </NavLink>
  );
}

ShowAllLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default ShowAllLink;
