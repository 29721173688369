// Core
import { put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// lodash
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';

// actions
import { userActions } from '../../action';

// config
import { selectors } from '../../../../config/selectors';

export function* callSetUserNotificationsWorker() {
  const userInfo = yield select(selectors.user.userInfo);
  const userNotificationsMessage = yield select(selectors.user.userNotificationsMessage);
  const userNotificationsPopup = yield select(selectors.user.userNotificationsPopup);
  const userNotificationsMessageToJS = userNotificationsMessage.toJS();
  const userNotificationsPopupToJS = userNotificationsPopup.toJS();

  if (userInfo.notification && isArray(userInfo.notification)) {
    const filterTypeMessage = filter(userInfo.notification, { type: 'message' });
    const filterTypePopup = filter(userInfo.notification, { type: 'popup' });
    const data = {};
    if (!isEmpty(userNotificationsPopupToJS) || !isEmpty(filterTypePopup)) {
      data.popups = List(map(!isEmpty(filterTypePopup) ? filterTypePopup : userNotificationsPopupToJS, item => item));
    }

    if (!isEmpty(userNotificationsMessageToJS) || !isEmpty(filterTypeMessage)) {
      data.messages = List(!isEmpty(filterTypeMessage) ? filterTypeMessage : userNotificationsMessageToJS);
    }

    if (!isEmpty(data)) {
      yield put(userActions.setUserNotifications(data));
    }
  }
}
