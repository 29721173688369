// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callDeleteStopWordsWorker,
  callPutSettingsWorker,
  callPostStopWordWorker,
  callGetStopWordHashIdWorker,
  callPutStopWordWorker,
  callPostUploadStopWordWorker,
  callGetExportStopWords,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_STOP_WORDS_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_STOP_WORDS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchDeleteStopWords() {
  yield takeEvery(asyncTypes.DELETE_STOP_WORD_ASYNC, callDeleteStopWordsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_STOP_WORDS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPostStopWord() {
  yield takeEvery(asyncTypes.POST_STOP_WORD_ASYNC, callPostStopWordWorker);
}
function* watchGetStopWordHashId() {
  yield takeEvery(asyncTypes.GET_STOP_WORD_HASH_ID_ASYNC, callGetStopWordHashIdWorker);
}
function* watchPutStopWord() {
  yield takeEvery(asyncTypes.PUT_STOP_WORD_ASYNC, callPutStopWordWorker);
}
function* watchGetExportStopWords() {
  yield takeEvery(asyncTypes.GET_EXPORT_STOP_WORDS_ASYNC, callGetExportStopWords);
}
function* watchPostUploadStopWord() {
  yield takeEvery(asyncTypes.POST_UPLOAD_STOP_WORD_ASYNC, callPostUploadStopWordWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_STOP_WORDS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_STOP_WORDS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_STOP_WORDS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_STOP_WORDS_SORTING, callSetToLocalDataWorker);
}

export function* watchersStopWords() {
  yield all([
    watchGetList(),
    watchPostStopWord(),
    watchPutStopWord(),
    watchGetExportStopWords(),
    watchGetStopWordHashId(),
    watchGetInitialState(),
    watchDeleteStopWords(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPostUploadStopWord(),
  ]);
}
