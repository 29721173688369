import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// Engine
import { change } from 'redux-form/immutable';
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { uiActionAsync } from '../../../../../../../engine/core/ui/saga/asyncAction';
import { store } from '../../../../../../../engine/init/store';
import { selectors } from '../../../../../../../engine/config/selectors';

import { formName, formFields } from '../../../../../../page/Products/PriceLists/Import/components/AddFormXml';

const linkXML = 'https://www.google.com/search?q=%D0%A9%D0%BE+%D1%82%D0%B0%D0%BA%D0%B5+%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B+xml&oq=%D0%A9%D0%BE+%D1%82%D0%B0%D0%BA%D0%B5+%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B+xml&aqs=chrome..69i57j33i160.7825j0j7&sourceid=chrome&ie=UTF-8';

export const importLinkXmlCallback = ({ hasAdminAccess }) => (data) => {
  const {
    action, status, index, type,
  } = data;

  const state = store.getState();
  const midPartnerData = selectors.partner.listContext(state)?.toJS().filter(
    item => item.value !== null,
  )?.[55];

  if (index === 0 && action === ACTIONS.START) {
    store.dispatch(uiActions.setImportPageType('XML'));
  }
  if (index === 0 && action === ACTIONS.PREV && type === EVENTS.STEP_BEFORE) {
    store.dispatch(uiActions.setImportPageType('XML'));
  }

  if (index === 0 && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActionAsync.setImportLang(true));
  }
  if (index === 2 && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActions.setImportPageFileType('link'));
  }
  if (hasAdminAccess && index === 2 && type === EVENTS.STEP_BEFORE) {
    store.dispatch(change(formName, formFields.partner, midPartnerData));
  }

  const indexForAdmin = hasAdminAccess ? 5 : 4;
  if (index === indexForAdmin && type === EVENTS.STEP_BEFORE) {
    store.dispatch(uiActionAsync.setImportLink(linkXML));
  }

  if (action === ACTIONS.PREV && type === EVENTS.TARGET_NOT_FOUND) {
    if (index === 0) {
      store.dispatch(uiActions.setImportPageType('XML'));
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setJoyrideStepIndex(0));
    store.dispatch(uiActionAsync.setImportLink(null));
    store.dispatch(uiActionAsync.setImportLang(false));
    if (hasAdminAccess) {
      store.dispatch(change(formName, formFields.partner, null));
    }
  }
};
