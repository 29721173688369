import isNil from 'lodash/isNil';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';


const CompareComponent = (props) => {
  const {
    valueOld, input, confirmInputValue, compareInInput, groupClassNames, options,
  } = props;
  const checkOldValue = isNil(valueOld) ? '' : valueOld;
  const inputValue = confirmInputValue ? `${checkOldValue}, ${confirmInputValue}` : checkOldValue;
  return compareInInput
    ? <TextField value={inputValue} disabled />
    : (
      <RadioGroup className={groupClassNames} aria-label="gender" name="gender1" {...input} value={isNil(valueOld) ? '' : valueOld}>
        { options.map(item => <FormControlLabel value={item.value} key={item.value} control={<Radio color="primary" disabled />} label={item.label} />) }
      </RadioGroup>
    );
};

export default CompareComponent;
