import { Trans } from 'react-i18next';
import React from 'react';

export const historyType = {
  status: 'status',
  enabledInMagento: 'enabledInMagento',
  sourceType: 'sourceType',
  price: 'price',
  priceAutoUpdate: 'priceAutoUpdate',
  salePrice: 'salePrice',
  feedPrice: 'feedPrice',
  quantity: 'quantity',
  feedQuantity: 'feedQuantity',
  featureAutoUpdate: 'featureAutoUpdate',
  feedSalePrice: 'feedSalePrice',
};


export const tableStatusesLogsTitle = {
  [historyType.status]: <Trans>Детали изменения статуса</Trans>,
  [historyType.enabledInMagento]: <Trans>Детали изменения статуса товара на сайте</Trans>,
  [historyType.sourceType]: <Trans>Детали изменения источника</Trans>,
  [historyType.price]: <Trans>Детали изменения цены</Trans>,
  [historyType.feedPrice]: <Trans>Детали изменения цены в XML</Trans>,
  [historyType.salePrice]: <Trans>Детали изменения Акционной цены</Trans>,
  [historyType.feedSalePrice]: <Trans>Детали изменения Акционной цены в XML</Trans>,
  [historyType.quantity]: <Trans>Детали изменения наличия</Trans>,
  [historyType.feedQuantity]: <Trans>Детали изменения наличия в XML</Trans>,
  [historyType.featureAutoUpdate]: <Trans>Детали изменения обновления характеристик</Trans>,
  [historyType.priceAutoUpdate]: <Trans>Детали изменения обновления цен и наличия</Trans>,
};

const pricesTableGridCommon = [{
  name: 'createdAt',
  title: <Trans>Дата изменения</Trans>,
},
{
  name: 'dataFrom',
  title: <Trans>Предыдущее значение</Trans>,
},
{
  name: 'dataTo',
  title: <Trans>Новое значение</Trans>,
},
{
  name: 'userWhoEdit',
  title: <Trans>Пользователь, который внес изменения</Trans>,
},
{
  name: 'changeReason',
  title: <Trans>Причина</Trans>,
}];

export const tableStatusesLogsScheme = {
  [historyType.status]: [
    {
      name: 'createdAt',
      title: <Trans>Дата</Trans>,
    },
    {
      name: 'dataFrom',
      title: <Trans>Статус товара (c которого был переведен)</Trans>,
    },
    {
      name: 'dataTo',
      title: <Trans>Статус товара (в который был переведен)</Trans>,
    },
    {
      name: 'cancelComment',
      title: <Trans>Комментарий</Trans>,
    },
    {
      name: 'cancelStatus',
      title: <Trans>Причина отмены</Trans>,
    },
    {
      name: 'userWhoEdit',
      title: <Trans>Пользователь который менял статус</Trans>,
    },
  ],
  [historyType.enabledInMagento]: [
    {
      name: 'createdAt',
      title: <Trans>Дата</Trans>,
    },
    {
      name: 'dataFrom',
      title: <Trans>Значение до изменения</Trans>,
    },
    {
      name: 'dataTo',
      title: <Trans>Новое значение</Trans>,
    },
    {
      name: 'userWhoEdit',
      title: <Trans>Пользователь который изменил</Trans>,
    },
    {
      name: 'changeReason',
      title: <Trans>Причина</Trans>,
    },
  ],
  [historyType.sourceType]: [
    {
      name: 'createdAt',
      title: <Trans>Дата и время изменения</Trans>,
    },
    {
      name: 'dataFrom',
      title: <Trans>Предыдущий источник</Trans>,
    },
    {
      name: 'dataTo',
      title: <Trans>Новый источник</Trans>,
    },
    {
      name: 'userWhoEdit',
      title: <Trans>ФИО, внесшего изменения</Trans>,
    },
  ],
  [historyType.price]: pricesTableGridCommon,
  [historyType.priceAutoUpdate]: pricesTableGridCommon,
  [historyType.salePrice]: pricesTableGridCommon,
  [historyType.feedPrice]: pricesTableGridCommon,
  [historyType.quantity]: pricesTableGridCommon,
  [historyType.feedQuantity]: pricesTableGridCommon,
  [historyType.featureAutoUpdate]: pricesTableGridCommon,
  [historyType.feedSalePrice]: pricesTableGridCommon,
};
