// Core
import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// lodash
import each from 'lodash/each';
// UI
import {
  TableColumnResizing as TableColumnResizingUI,
} from '@devexpress/dx-react-grid-material-ui';
// Engine
import { userActions } from '../../../../../../engine/core/user/action';

const DEFAULT_COLUMN_WIDTH = 200;

function TableColumnResizing(props) {
  const {
    gridSettings, name, setColumnWidths,
    onColumnWidthsChange,
  } = props;
  const columnWidths = gridSettings?.map(column => ({ columnName: column.name, width: column.width ?? DEFAULT_COLUMN_WIDTH }));
  const dispatch = useDispatch();
  const handleColumnWidthChange = useCallback((newColumnWidths) => {
    const newColumnWidth = each(gridSettings.filter(item => item.isFeature !== true), (item) => {
      newColumnWidths.find((newWidth) => { // eslint-disable-line
        if (item.name === newWidth.columnName) {
          item.width = newWidth.width;  // eslint-disable-line
        }
      });
    });

    if (onColumnWidthsChange) {
      onColumnWidthsChange({ name, newColumnWidths });
    } else {
      dispatch(userActions.mergeUserSettings({ [name]: { gridSettings: newColumnWidth } }));
      setColumnWidths(newColumnWidths);
    }
  }, [gridSettings, onColumnWidthsChange, setColumnWidths, name]);

  return (
    <TableColumnResizingUI
      columnWidths={columnWidths}
      onColumnWidthsChange={handleColumnWidthChange}
    />
  );
}

TableColumnResizing.propTypes = {
  setColumnWidths: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  gridSettings: PropTypes.array.isRequired,
  onColumnWidthsChange: PropTypes.func,
};

export default TableColumnResizing;
