// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  mergeDelete(data) {
    return getActionCreator(types.MERGE_DELETE_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  mergeEdit(data) {
    return getActionCreator(types.MERGE_EDIT_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  mergeAdd(data) {
    return getActionCreator(types.MERGE_ADD_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  mergeDownload(data) {
    return getActionCreator(types.MERGE_DOWNLOAD_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  mergeUpload(data) {
    return getActionCreator(types.MERGE_UPLOAD_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  mergePut(data) {
    return getActionCreator(types.MERGE_PUT_STOP_LIST_BRANDS_CATEGORIES, data);
  },
  setList(data) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE, initialState);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_SELECTION, selection);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_PENDING, settingPending);
  },
});
