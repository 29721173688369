
import { all, takeEvery } from 'redux-saga/effects';
import { callGetListWorker } from './workers/getList';
import { partnerServiceRequestsDownloadActionWorker } from './workers/partnerServiceRequestsDownloadActionWorker';
import { asyncTypes } from './asyncTypes';

export function* watchGetListWorker() {
  yield takeEvery(asyncTypes.GET_PARTNER_SERVICES_REQUESTS_LIST_ASYNC, callGetListWorker);
}

export function* watchPartnerServiceRequestsDownloadActionWorker() {
  yield takeEvery(asyncTypes.GET_PARTNER_SERVICES_REQUESTS_DOWNLOAD, partnerServiceRequestsDownloadActionWorker);
}

export function* watchersConnectedServicesRequests() {
  yield all([
    watchGetListWorker(),
    watchPartnerServiceRequestsDownloadActionWorker(),
  ]);
}
