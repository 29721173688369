// Core
import { fromJS, List, Map } from 'immutable';
import forEach from 'lodash/forEach';
import assign from 'lodash/assign';
import isNil from 'lodash/isNil';

// Instruments
import { types } from './types';
import { pageSizes } from '../../config/globalConfig';
import { partnerStatuses } from '../../config/partnerStatuses';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  partnerHashId: null,
  partnerContractLink: null,
  partnerContractPage: null,
  partner: Map({
    pending: true,
    data: {},
  }),
  partnerSave: Map({
    pending: false,
    showPopUps: Map(),
  }),
  partnerIsTop: Map({
    isOpen: false,
    forceAction: false,
  }),
  partnerCreateNew: Map({
    pending: false,
  }),
  statusesPending: false,
  statuses: Map({
    partnerStatuses: List(),
    cancelStatuses: List(),
    disableStatuses: List(),
    stoppedStatuses: List(),
    problemStatuses: List(),
    statusesFlow: Map(),
  }),
  partnersList: Map({
    pending: true,
    items: [],
    totalCount: 0,
    pageSize: pageSizes[0],
    currentPage: 0,
    filters: List(),
    sorting: List(),
    reload: false,
    selection: List(),
    settingStatus: false,
    settingPending: false,
  }),
  partnersDownload: Map({
    pending: false,
  }),
  partnersContractCommissionDownload: Map({
    pending: false,
  }),
  partnerDownload: Map({
    pending: false,
  }),
  partnersListContext: Map({
    pending: false,
    items: List(),
  }),
  partnersListContextBillingPaymentForPartner: Map({
    pending: false,
    items: List(),
  }),
  partnersListContextForServiceRequests: Map({
    pending: false,
    items: List(),
  }),
  usersListContext: Map({
    pending: false,
    items: [],
    itemsWithAdmin: List(),
  }),
  sendContractToSed: Map({
    pending: false,
  }),
  businessesList: Map({
    pending: false,
    items: List(),
  }),
});

export const stateKeys = Object.freeze({
  categories: 'categories',
  controller: 'partner',
  currentPage: 'currentPage',
  data: 'data',
  filters: 'filters',
  items: 'items',
  status: 'status',
  itemsWithAdmin: 'itemsWithAdmin',
  value: 'value',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  active: 'active',
  partner: 'partner',
  partnerIsTop: 'partnerIsTop',
  isOpen: 'isOpen',
  forceAction: 'forceAction',
  statusesPending: 'statusesPending',
  sendContractToSed: 'sendContractToSed',
  partnerContractLink: 'partnerContractLink',
  partnerContractPage: 'partnerContractPage',
  partnerDownload: 'partnerDownload',
  partnersDownload: 'partnersDownload',
  partnersContractCommissionDownload: 'partnersContractCommissionDownload',
  partnersListContextBillingPaymentForPartner: 'partnersListContextBillingPaymentForPartner',
  partnersListContextForServiceRequests: 'partnersListContextForServiceRequests',
  partnersListContextForServiceRequestsPending: 'partnersListContextForServiceRequestsPending',
  partnerHashId: 'partnerHashId',
  partnersListContext: 'partnersListContext',
  usersListContext: 'usersListContext',
  partnerStatus: 'partnerStatus',
  cancelStatuses: 'cancelStatuses',
  disableStatuses: 'disableStatuses',
  stoppedStatuses: 'stoppedStatuses',
  problemStatuses: 'problemStatuses',
  partnerStatuses: 'partnerStatuses',
  partnerStatusesFlow: 'statusesFlow',
  partnersList: 'partnersList',
  partnerSave: 'partnerSave',
  showPopUps: 'showPopUps',
  partnerCreateNew: 'partnerCreateNew',
  pageSize: 'pageSize',
  pending: 'pending',
  sorting: 'sorting',
  statuses: 'statuses',
  reload: 'reload',
  modal: 'modal',
  shouldApproveValues: 'shouldApproveValues',
  totalCount: 'totalCount',
  selection: 'selection',
  partnersManagersList: 'partnersManagersList',
  showButton: 'showButton',
  businessesList: 'businessesList',
  businessesListPending: 'businessesListPending',
});

export const partnerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNER_INFO: {
      return state.set(stateKeys.partner, Map(payload));
    }
    case types.SET_PARTNERS_LIST_SETTING_STATUS: {
      return state.setIn([stateKeys.partnersList, stateKeys.settingStatus], payload);
    }
    case types.SET_PARTNERS_LIST_SETTING_PENDING: {
      return state.setIn([stateKeys.partnersList, stateKeys.settingPending], payload);
    }
    case types.SET_STATUSES_LIST: {
      return state.set(stateKeys.statuses, fromJS(payload));
    }
    case types.SET_STATUSES_LIST_PENDING: {
      return state.set(stateKeys.statusesPending, payload);
    }
    case types.SET_PARTNER_CONTRACT_LINK: {
      return state.set(stateKeys.partnerContractLink, payload);
    }
    case types.SET_PARTNER_CONTRACT_PAGE: {
      return state.set(stateKeys.partnerContractPage, payload);
    }
    case types.SET_PARTNERS_LIST: {
      return state.mergeIn([stateKeys.partnersList], payload);
    }
    case types.MERGE_PARTNER_SAVE: {
      return state.mergeIn([stateKeys.partnerSave], payload);
    }
    case types.MERGE_PARTNER_IS_TOP: {
      return state.mergeIn([stateKeys.partnerIsTop], payload);
    }
    case types.MERGE_PARTNER_CREATE_NEW: {
      return state.mergeIn([stateKeys.partnerCreateNew], payload);
    }
    case types.MERGE_PARTNER_SEND_CONTRACT_TO_SED: {
      return state.mergeIn([stateKeys.sendContractToSed], payload);
    }
    case types.SET_PARTNERS_CURRENT_PAGE: {
      return state.setIn([stateKeys.partnersList, stateKeys.currentPage], payload);
    }

    case types.SET_PARTNERS_PAGE_SIZE: {
      return state.setIn([stateKeys.partnersList, stateKeys.pageSize], payload);
    }
    case types.CHANGE_PARTNERS_SORTING: {
      const { sorting } = payload;
      return state.setIn([stateKeys.partnersList, stateKeys.sorting], List(sorting));
    }
    case types.CHANGE_PARTNERS_FILTERS: {
      const filters = payload.filters.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.partnersList, stateKeys.filters], List(filters));
    }
    case types.SET_PARTNER_HASH_ID: {
      if (payload === null) {
        const newState = {
          partnerHashId: initialState.partnerHashId,
          partner: initialState.partner,
        };
        return state.merge(newState);
      }
      return state.set(stateKeys.partnerHashId, payload);
    }
    case types.APPROVE_INFO_FIELD_VALUES: {
      const isPartnerStatusActive = state.getIn([stateKeys.partner, stateKeys.data, stateKeys.partnerStatus, stateKeys.value]) === partnerStatuses.active;
      if (isPartnerStatusActive) {
        const partnerData = state.getIn([stateKeys.partner, stateKeys.data], {});
        const { categories, infoFieldValues } = partnerData;
        const approvedValueKey = 'approvedValue';
        forEach(infoFieldValues, (value) => {
          value[approvedValueKey] = payload; // eslint-disable-line
        });
        forEach(categories, (category) => {
          if (approvedValueKey in category) {
            category[approvedValueKey] = payload; // eslint-disable-line
          }
        });
        return state.setIn([stateKeys.partner, stateKeys.data], assign({}, partnerData));
      }
      return state;
    }
    case types.APPROVE_INFO_FIELD: {
      const partnerData = state.getIn([stateKeys.partner, stateKeys.data], {});
      const { infoFieldValues } = partnerData;
      infoFieldValues[payload.key].approvedValue = payload.value;
      return state.setIn([stateKeys.partner, stateKeys.data], assign({}, partnerData));
    }
    case types.RESET_PARTNERS_FILTERS: {
      return state.setIn([stateKeys.partnersList, stateKeys.filters], List());
    }
    case types.SET_PARTNERS_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.partnersDownload, stateKeys.pending], payload);
    }
    case types.SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.partnersContractCommissionDownload, stateKeys.pending], payload);
    }
    case types.SET_PARTNER_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.partnerDownload, stateKeys.pending], payload);
    }
    case types.SET_PARTNER_CONTEXT_LIST: {
      return state.setIn([stateKeys.partnersListContext, stateKeys.items], List(payload));
    }
    case types.SET_PARTNER_CONTEXT_PENDING: {
      return state.setIn([stateKeys.partnersListContext, stateKeys.pending], payload);
    }
    case types.SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_PENDING: {
      return state.setIn([stateKeys.partnersListContextBillingPaymentForPartner, stateKeys.pending], payload);
    }
    case types.SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_LIST: {
      return state.setIn([stateKeys.partnersListContextBillingPaymentForPartner, stateKeys.items], List(payload));
    }
    case types.SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS: {
      return state.setIn([stateKeys.partnersListContextForServiceRequests, stateKeys.items], List(payload));
    }
    case types.SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS_PENDING: {
      return state.setIn([stateKeys.partnersListContextForServiceRequestsPending, stateKeys.pending], payload);
    }
    case types.SET_USERS_LIST_CONTEXT: {
      return state.mergeIn([stateKeys.usersListContext], payload);
    }
    case types.SET_USERS_LIST_CONTEXT_PENDING: {
      return state.setIn([stateKeys.usersListContext, stateKeys.pending], payload);
    }
    case types.SET_USERS_LIST_CONTEXT_AND_ROLE: {
      const { items, idRole, context } = payload;
      return state.setIn([stateKeys.usersListContext, context, idRole], List(items));
    }
    case types.SET_PARTNER_SELECTION: {
      const { selection } = payload;
      return state.setIn([stateKeys.partnersList, stateKeys.selection], List(selection));
    }
    case types.SET_PARTNERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting,
      } = payload;

      const reload = true;

      const currentSorting = sorting.length
        ? List(sorting)
        : initialState.getIn([stateKeys.partnersList, stateKeys.sorting]);

      return state
        .setIn([stateKeys.partnersList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.partnersList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.partnersList, stateKeys.filters], List(filters))
        .setIn([stateKeys.partnersList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.partnersList, stateKeys.reload], reload)
        .setIn([stateKeys.partnersList, stateKeys.sorting], currentSorting);
    }
    case types.SET_PARTNER_LIST_CONTEXT: {
      const { pending, items } = payload;
      if (pending) {
        return state.setIn([stateKeys.partnersListContext, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.partnersListContext, stateKeys.pending], pending)
        .setIn([stateKeys.partnersListContext, stateKeys.items], List(items));
    }
    case types.APPROVE_CATEGORIES_PERCENT: {
      const keyValue = payload.key.substr(payload.key.indexOf('.') + 1);
      return state.withMutations((mutable) => {
        const allCategories = mutable.getIn([stateKeys.partner, stateKeys.data, stateKeys.categories]);
        const position = allCategories.findIndex(i => i.value === parseInt(keyValue, 10));
        mutable.setIn([stateKeys.partner, stateKeys.data, stateKeys.categories, position, 'approvedValue'], payload.value);
      });
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_BUSINESSES_LIST: {
      return state.setIn([stateKeys.businessesList, stateKeys.items], List(payload));
    }
    case types.SET_BUSINESSES_LIST_PENDING: {
      return state.setIn([stateKeys.businessesList, stateKeys.pending], payload);
    }
    default: {
      return state;
    }
  }
};
