// Core
import React, {
  useCallback, useEffect, memo, useState,
} from 'react';
import { push } from 'redux-first-history';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
// Components
import Select from '../../../../../components/_Form/Selects/Select/Select';
// Engine
import { asyncActions } from '../../../../../../engine/core/attributeSets/saga/asyncActions';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { pageLinks } from '../../../../../../engine/config/routes';
import { selectors } from '../../../../../../engine/config/selectors';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { asyncActions as categoriesActions } from '../../../../../../engine/core/categories/saga/asyncActions';


function ProductsAttributesSelect() {
  const { t } = useTranslation();
  const attributeSetId = useQueryParams('attributeSetId');
  const dispatch = useDispatch();
  const attributeSets = useSelector(selectors.attributeSets.attributeSetsListContext);
  const isLoading = useSelector(selectors.attributeSets.attributeSetsListContextPending);
  const [selectedValue, setSelectedValue] = useState(null);
  const selected = useSelector(selectors.contentProduct.productCategoryActiveItem)?.toJS();
  const defaultValue = { value: null, label: t('Все атрибут-сеты') };

  useEffect(() => {
    if (attributeSetId) {
      dispatch(categoriesActions.getCategoriesListContextAsync({ attributeSetId: [attributeSetId] }));
    }
  }, [attributeSetId]);

  useEffect(() => {
    if (selected?.value) {
      setSelectedValue(defaultValue.value);
    }
  }, [selected?.value]);

  useEffect(() => {
    if (attributeSetId) {
      setSelectedValue(+attributeSetId);
    }
  }, []);


  const handleChangeAttributeSetId = useCallback(({ value }) => {
    dispatch(actions.setFilters([]));
    const activeAttributeSet = attributeSets.toJS().find(attribute => attribute.value === value);
    setSelectedValue(value);
    if (activeAttributeSet?.value) {
      dispatch(push(pageLinks.productsAndPrices.content.byAttributeSetId(value)));
    } else {
      dispatch(push(pageLinks.productsAndPrices.content.all));
    }
  }, [attributeSets, attributeSetId]);

  useEffect(() => {
    dispatch(asyncActions.getAttributeSetsListContextAsync({
      context: 'select',
    }));
  }, [attributeSetId]);

  const attributeSetsListToJS = attributeSets.toJS();
  const optionsData = [defaultValue, ...attributeSetsListToJS];

  return (
    <Box sx={{ width: '210px' }}>
      <Select
        handleChange={handleChangeAttributeSetId}
        options={optionsData}
        id="attributes_select"
        isLoading={isLoading}
        value={selectedValue}
        filterValueBy="value"
        label={t('Выберите атрибут-сет')}
        closeMenuOnSelect
        defaultValue={defaultValue}
        searchErrorLabel={t('Неверно введен атрибут-сет')}
        variant="outlined"
        placeholder=""
        hasSearch
      />
    </Box>
  );
}

export default memo(ProductsAttributesSelect);
