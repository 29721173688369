import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirm from '../../../../../../../../containers/Modals/ModalConfirm';
import { selectors } from '../../../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../../../engine/core/content/actions';
import { asyncActions } from '../../../../../../../../../engine/core/content/saga/asyncActions';

const ConfirmChangeModal = () => {
  const dispatch = useDispatch();
  const confirmModal = useSelector(selectors.content.confirmModal);
  const modalInfo = useMemo(() => confirmModal.toJS(), [confirmModal]);
  const { t } = useTranslation();
  const handleConfirm = () => {
    dispatch(asyncActions.putProductsAsync({ ...modalInfo.params, needForceAction: true }));
    dispatch(actions.setConfirmModal({ isOpen: false }));
  };

  const handleCancel = () => {
    dispatch(actions.setConfirmModal({ isOpen: false }));
  };

  return (
    <ModalConfirm
      confirmText={t('Подтвердить')}
      textAlign="center"
      pendingConfirm={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      showModal={modalInfo.isOpen}
      text={modalInfo.text}
      title={t('Внимание')}
    />
  );
};

export default ConfirmChangeModal;
