import React from 'react';
import * as PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';
// UI
import IconButton from '@mui/material/IconButton';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { COLOR } from '../../../../containers/App/AppStyles';

// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

// config
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';

const EditCell = (props) => {
  const { tableRow } = props;
  const dispatch = useDispatch();
  const hasAccessEdit = useAccessList(accessList.stopBrandsCategoriesEdit);

  if (!hasAccessEdit) {
    return null;
  }

  const handleGoToEdit = () => {
    dispatch(push(pageLinks.stopBrandsCategories.edit(tableRow?.row?.hashId)));
  };

  return (
    <IconButton
      sx={{
        borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)', color: COLOR.controlsIcons.main,
      }}
      color="inherit"
      onClick={handleGoToEdit}
      size="large"
    >
      <ModeOutlinedIcon sx={{ p: '2px' }} />
    </IconButton>
  );
};

EditCell.propTypes = {
  tableRow: PropTypes.shape({
    row: PropTypes.shape({
      hashId: PropTypes.string,
    }),
  }),
};

export default EditCell;
