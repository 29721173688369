// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  productFeedAttributes: Map({
    items: Map(),
    expandedRowIds: List(),
  }),
  productFeedCategories: Map({
    expandedRowIds: List(),
    loadingRowIds: List(),
    items: Map(),
  }),
  productFeedCategoriesAttributesList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  autoConnect: Map({
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'productFeedCategoriesAttributes',
  productFeedCategories: 'productFeedCategories',
  productFeedAttributes: 'productFeedAttributes',
  expandedRowIds: 'expandedRowIds',
  loadingRowIds: 'loadingRowIds',
  productFeedCategoriesAttributesList: 'productFeedCategoriesAttributesList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  autoConnect: 'autoConnect',
});

export const productFeedCategoriesAttributesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // PRODUCT_FEED_ATTRIBUTES
    case types.MERGE_PRODUCT_FEED_ATTRIBUTES_ITEMS: {
      return state.mergeIn([stateKeys.productFeedAttributes, stateKeys.items], payload);
    }
    case types.SET_PRODUCT_FEED_ATTRIBUTES_EXPANDED_ROW_IDS: {
      return state.setIn([stateKeys.productFeedAttributes, stateKeys.expandedRowIds], List(payload));
    }
    case types.SET_PRODUCT_FEED_ATTRIBUTES_ITEM_BY_HASH_ID_AND_POSITION: {
      const { hashId, position, value } = payload;
      return state.setIn([stateKeys.productFeedAttributes, stateKeys.items, hashId, stateKeys.items, position], value);
    }
    case types.SET_DEFAULT_PRODUCT_FEED_ATTRIBUTES: {
      return state.setIn([stateKeys.productFeedAttributes], initialState.get(stateKeys.productFeedAttributes));
    }
    // PRODUCT_FEED_CATEGORIES
    case types.SET_PRODUCT_FEED_CATEGORIES_EXPANDED_ROW_IDS: {
      return state.setIn([stateKeys.productFeedCategories, stateKeys.expandedRowIds], List(payload));
    }
    case types.MERGE_PRODUCT_FEED_CATEGORIES_LOADING: {
      return state.mergeIn([stateKeys.productFeedCategories, stateKeys.loadingRowIds], payload);
    }
    case types.DELETE_PRODUCT_FEED_CATEGORIES_LOADING: {
      return state.deleteIn([stateKeys.productFeedCategories, stateKeys.loadingRowIds, payload]);
    }
    case types.MERGE_PRODUCT_FEED_CATEGORIES_ITEMS: {
      return state.mergeIn([stateKeys.productFeedCategories, stateKeys.items], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ITEM_BY_HASH_ID_AND_POSITION: {
      const { hashId, position, value } = payload;
      return state.setIn([stateKeys.productFeedCategories, stateKeys.items, hashId, stateKeys.items, position], value);
    }
    case types.SET_DEFAULT_PRODUCT_FEED_CATEGORIES: {
      return state.setIn([stateKeys.productFeedCategories], initialState.get(stateKeys.productFeedCategories));
    }
    // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.pending], pending)
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.items], List(items))
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SORTING: {
      return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.sorting], List(payload));
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.currentPage], 0);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_PAGE_SIZE: {
      return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.pageSize], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_CURRENT_PAGE: {
      return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.currentPage], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.reload], reload)
        .setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_STATUS: {
      return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.settingStatus], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_PENDING: {
      return state.setIn([stateKeys.productFeedCategoriesAttributesList, stateKeys.settingPending], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_PENDING: {
      return state.setIn([stateKeys.autoConnect, stateKeys.pending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
