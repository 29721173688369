// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callGetFeedsWorker,
  callGetFeedsInitialStateWorker,
  callSetToLocalDataWorker,
  callPutFeedsSettingsWorker,
  callGetFeedsStatusesListWorker,
  callGetFeedItemByHashIdWorker,
  callGetFeedsPrioritiesListWorker,
  callGetFeedsTypesListWorker,
  callPutFeedsItemByHashId,
  callPostFeedsImportWorker,
  callGetFeedsExportWorker,
  callPostRefreshWorker,
  callDeleteFeedByHashIdWorker,
  callPostRefreshPriceListWorker,
  callGetCompletionImportListWorker,
  callPutCompletionImportListWorker,
  callPutCompletionImportListItemWorker,
} from './workers';

function* watchGetFeeds() {
  yield takeEvery(asyncTypes.GET_FEEDS_LIST_ASYNC, callGetFeedsWorker);
}
function* watchGetFeedsInitialState() {
  yield takeEvery(asyncTypes.GET_FEEDS_INITIAL_STATE_ASYNC, callGetFeedsInitialStateWorker);
}
function* watchSetFeedsCurrentPage() {
  yield takeEvery(types.SET_FEEDS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetFeedsPageSize() {
  yield takeEvery(types.SET_FEEDS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFeedsFilters() {
  yield takeEvery(types.SET_FEEDS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetFeedsSorting() {
  yield takeEvery(types.SET_FEEDS_SORTING, callSetToLocalDataWorker);
}
function* watchPutFeedsSettings() {
  yield takeEvery(asyncTypes.PUT_FEEDS_SETTINGS_ASYNC, callPutFeedsSettingsWorker);
}
function* watchGetFeedsStatusesList() {
  yield takeEvery(asyncTypes.GET_FEEDS_STATUSES_LIST_ASYNC, callGetFeedsStatusesListWorker);
}
function* watchGetFeedsPrioritiesList() {
  yield takeEvery(asyncTypes.GET_FEEDS_PRIORITIES_LIST_ASYNC, callGetFeedsPrioritiesListWorker);
}
function* watchGetFeedsTypesList() {
  yield takeEvery(asyncTypes.GET_FEEDS_TYPES_LIST_ASYNC, callGetFeedsTypesListWorker);
}
function* watchGetFeedByHashId() {
  yield takeEvery(asyncTypes.GET_FEED_BY_HASH_ID_ASYNC, callGetFeedItemByHashIdWorker);
}
function* watchPutFeedByHashId() {
  yield takeEvery(asyncTypes.PUT_FEED_ITEMS_BY_HASH_ID_ASYNC, callPutFeedsItemByHashId);
}
function* watchPutFeedImport() {
  yield takeEvery(asyncTypes.GET_FEEDS_IMPORT_ASYNC, callPostFeedsImportWorker);
}
function* watchPutFeedExport() {
  yield takeEvery(asyncTypes.GET_FEEDS_EXPORT_ASYNC, callGetFeedsExportWorker);
}
function* watchPostRefresh() {
  yield takeEvery(asyncTypes.POST_FEEDS_REFRESH_ASYNC, callPostRefreshWorker);
}
function* watchDeleteFeedByHashId() {
  yield takeEvery(asyncTypes.DELETE_FEEDS_BY_HASH_ID_ASYNC, callDeleteFeedByHashIdWorker);
}

function* watchPostRefreshPriceList() {
  yield takeEvery(asyncTypes.POST_FEEDS_REFRESH_PRICE_LIST_ASYNC, callPostRefreshPriceListWorker);
}

function* watchGetCompletionImportListWorker() {
  yield takeEvery(asyncTypes.GET_FEED_COMPLETION_IMPORT_LIST_ASYNC, callGetCompletionImportListWorker);
}

function* watchPutCompletionImportListWorker() {
  yield takeEvery(asyncTypes.PUT_FEED_COMPLETION_IMPORT_LIST_ASYNC, callPutCompletionImportListWorker);
}
function* watchPutCompletionImportListItemWorker() {
  yield takeEvery(asyncTypes.PUT_FEED_COMPLETION_IMPORT_LIST_ITEM_ASYNC, callPutCompletionImportListItemWorker);
}

export function* watchersFeeds() {
  yield all([
    watchGetFeeds(),
    watchGetFeedsInitialState(),
    watchSetFeedsCurrentPage(),
    watchSetFeedsPageSize(),
    watchSetFeedsFilters(),
    watchSetFeedsSorting(),
    watchPutFeedsSettings(),
    watchGetFeedsStatusesList(),
    watchGetFeedByHashId(),
    watchGetFeedsPrioritiesList(),
    watchGetFeedsTypesList(),
    watchPutFeedByHashId(),
    watchPutFeedImport(),
    watchPutFeedExport(),
    watchPostRefresh(),
    watchDeleteFeedByHashId(),
    watchPostRefreshPriceList(),
    watchGetCompletionImportListWorker(),
    watchPutCompletionImportListWorker(),
    watchPutCompletionImportListItemWorker(),
  ]);
}
