// Core
import { useEffect } from 'react';
import { List } from 'immutable';
import { change, Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { addDays } from 'date-fns';
// Parts
import FormSelect from '../../../../components/_Form/Selects/FormSelect/FormSelect';
import DataRangeModal from '../../../../components/_Form/DataRange/DataRangeModal';
import FormTextArea from '../../../../components/_Form/TextArea/FormTextArea';
// _helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { formFields } from '../../form';
import { usePartnerData } from '../../hooks/usePartnerData';
import { useRequiredPartnerStatusFields } from '../../../../hooks/useRequiredPartnerStatusFields';
import { useAccessList } from '../../../../hooks/useAccessList';
import { usePrevious } from '../../../../hooks/usePrevious';
import { useUserRole } from '../../../../hooks/useUserRole';
import { userRoles } from '../../../../../engine/config/userRoles';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import { partnerStatuses } from '../../../../../engine/config/partnerStatuses';
import { dateStringShortFormat } from '../../../../../engine/config/globalConfig';

function ReasonRejectionFields(props) {
  const {
    approve, approveInfoField, options, formName,
  } = props;
  const dispatch = useDispatch();
  const { getApprovedValue, getCurrentValue, getOldValue } = usePartnerData();
  const { t } = useTranslation();
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const isLoading = useSelector(selectors.partner.statusesListPending);
  const partnerStatus = getFormValues.get('partnerStatus');
  const prevPartnerStatus = usePrevious(partnerStatus);
  const noticeDateAt = getFormValues.get('noticeDateAt');
  const { isRequiredStatusFields } = useRequiredPartnerStatusFields(partnerStatus);
  const isRequiredFieldsComment = isRequiredStatusFields(formFields.cancelComment);
  const isRequiredFieldsPartnerCancelStatus = isRequiredStatusFields(formFields.partnerCancelStatus);
  const hasAccessPartnerTopNoticeDateEdit = useAccessList(accessList.partnerTopNoticeDateEdit);
  const hasAccessPartnerTopNoticeDateShow = useAccessList(accessList.partnerTopNoticeDateShow);
  const isSuspendedStatus = partnerStatuses.suspended === partnerStatus?.value;
  const isProblematicStatus = partnerStatuses.problematic === partnerStatus?.value;
  const showNoticeDateAt = hasAccessPartnerTopNoticeDateShow && (isSuspendedStatus || isProblematicStatus);
  const isAdminRole = useUserRole(userRoles.admin);
  const isSalesManager = useUserRole(userRoles.salesManager);
  const isManager = useUserRole(userRoles.manager);
  const isFieldDisabled = !(isAdminRole || isSalesManager || isManager);

  useEffect(() => () => {
    dispatch(change(formName, formFields.noticeDateAt, null));
    dispatch(change(formName, formFields.cancelComment, null));
    dispatch(change(formName, formFields.partnerCancelStatus, null));
  }, []);

  useEffect(() => {
    if (showNoticeDateAt && !noticeDateAt) {
      dispatch(change(formName, formFields.noticeDateAt, addDays(new Date(), +1)));
    }
  }, [showNoticeDateAt, noticeDateAt]);

  useEffect(() => {
    if ((partnerStatus?.value !== prevPartnerStatus?.value) && prevPartnerStatus?.value) {
      dispatch(change(formName, formFields.cancelComment, null));
      dispatch(change(formName, formFields.partnerCancelStatus, null));
      if (hasAccessPartnerTopNoticeDateEdit) {
        dispatch(change(formName, formFields.noticeDateAt, addDays(new Date(), +1)));
      }
    }
  }, [partnerStatus, prevPartnerStatus, hasAccessPartnerTopNoticeDateEdit]);

  return (
    <>
      <Field
        isLoading={isLoading}
        name={formFields.partnerCancelStatus}
        disabled={isLoading || isFieldDisabled}
        label={t('Причина отмены')}
        fullWidth
        validation
        validate={isRequiredFieldsPartnerCancelStatus ? [validators.required] : []}
        component={FormSelect}
        margin="normal"
        options={options?.toJS()}
      />
      <Field
        fullWidth
        name={formFields.cancelComment}
        disabled={isLoading || isFieldDisabled}
        label={t('Примечание')}
        margin="normal"
        component={FormTextArea}
        multiline
        rows={5}
        approve={approve}
        value={getCurrentValue('comment')}
        valueOld={getOldValue('comment')}
        approvedValue={getApprovedValue('comment')}
        onApproveValue={approveInfoField}
        variant="outlined"
        validate={isRequiredFieldsComment ? [validators.required] : []}
        validation
      />
      {showNoticeDateAt && (
        <Field
          label={t('Дата уведомления')}
          name={formFields.noticeDateAt}
          validate={isRequiredFieldsComment ? [validators.required] : []}
          margin="normal"
          validation
          disabled={!hasAccessPartnerTopNoticeDateEdit}
          component={DataRangeModal}
          showClear
          minDate={addDays(new Date(), +1)}
          dateFormat={dateStringShortFormat}
        />
      )}
    </>
  );
}

ReasonRejectionFields.propTypes = {
  approve: PropTypes.bool,
  approveInfoField: PropTypes.func,
  options: PropTypes.instanceOf(List),
  formName: PropTypes.string,
};

export default ReasonRejectionFields;
