// Core
import React from 'react';
import { NavLink } from 'react-router-dom';

// UI
import IconButton from '@mui/material/IconButton';
// Engine
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

import { COLOR } from '../../../../containers/App/AppStyles';

function ContentProductEditLink(props) {
  const { row: { hashId, editable } } = props;
  const hasAccessProductEdit = useAccessList(accessList.productEdit);
  const hasAccessProductShow = useAccessList(accessList.productShow);
  const isEditable = hasAccessProductEdit && editable;
  const CurrentIcon = isEditable ? ModeOutlinedIcon : RemoveRedEyeOutlinedIcon;
  const currentUrl = isEditable
    ? pageLinks.content.products.edit(hashId)
    : pageLinks.content.products.show(hashId);

  if (!hasAccessProductEdit && !hasAccessProductShow) {
    return null;
  }

  return (
    <NavLink to={currentUrl}>
      <IconButton
        color="inherit"
        sx={{
          padding: '0',
          color: COLOR.controlsIcons.main,
          background: COLOR.grey['4'],
          borderRadius: '5px',
        }}
        size="small"
      >
        <CurrentIcon sx={{ p: '2px' }} />
      </IconButton>
    </NavLink>
  );
}


export default ContentProductEditLink;
