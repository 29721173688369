// Core
import { apply, put } from 'redux-saga/effects';
import { replace } from 'redux-first-history';
// Engine
import { api } from '../../../../config/api';
import { asyncActions } from '../asyncActions';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
// Engine
import { pageLinks } from '../../../../config/routes';

export function* callPutOrderUnbindShippingTrackingWorker({ payload: { hashId } }) {
  yield put(actions.mergeOrderUnbindShippingTracking({ pending: true }));
  const response = yield apply(api, api.orders.putOrderUnbindShippingTracking, [{ hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(asyncActions.getOrdersHashIdAsync({ hashId, mode: 'edit' }));
        yield put(replace(pageLinks.orders.edit(hashId)));
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeOrderUnbindShippingTracking({ pending: false }));
}
