const HeaderWithTooltipStyles = theme => ({
  descriptionLabel: {
    flexGrow: 1,
    width: 'auto',
    display: 'flex',
    maxWidth: '100%',
    minWidth: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  recommendedIcon: {
    width: '6px',
    height: '11px',
    lineHeight: '14px',
    fontSize: '15px',
    marginRight: '2px',
  },
  requiredIcon: {
    width: '6px',
    height: '11px',
    lineHeight: '14px',
    fontSize: '15px',
    marginRight: '2px',
  },
  tooltipIcon: {
    height: 12,
    display: 'inline-block',
  },
  tableHeaderLabel: {
    color: theme.palette.primary.main,
    '& span:hover': {
      color: theme.palette.primary.dark,
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tooltipIconHelp: {
    width: '25px',
    height: '25px',
    marginRight: '-3px',
    '& svg': {
      maxWidth: '19px',
      maxHeight: '19px',
    },
  },
});

export default HeaderWithTooltipStyles;
