// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Navigate } from 'react-router-dom';
import ReportsTable from './components/All/ReportsTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ReportGeneralTable from './components/ReportGeneralTable/ReportGeneralTable';
// Hooks
import { useReportsList } from './_hooks/useReportsList';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/billing/reports/actions';
import { initialState, stateKeys } from '../../../../engine/core/billing/reports/reducer';
import { billingAsyncAction } from '../../../../engine/core/billing/saga/asyncAction';
import { pageLinks } from '../../../../engine/config/routes';

function ReportsPage() {
  const { t } = useTranslation();
  useReportsList();
  const dispatch = useDispatch();
  const partner = useSelector(selectors.reports.partnerId);
  const createdAt = useSelector(selectors.reports.createdAt);
  const isLoading = useSelector(selectors.reports.isLoading);
  const filters = useSelector(selectors.reports.filters);
  const sorting = useSelector(selectors.reports.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const reportGeneral = useSelector(selectors.reports.reportGeneral);
  const { createdAtFrom, createdAtTo } = reportGeneral;
  const reportReload = useSelector(selectors.reports.firstLoad);
  const reportDownloadParameters = useSelector(selectors.billing.reportDownloadParameters);
  const reportDownloadPending = useSelector(selectors.billing.reportDownloadPending);
  const date = createdAtFrom && createdAtTo ? `${createdAtFrom.split('-').reverse().join('.')} - ${createdAtTo.split('-').reverse().join('.')}` : '';
  const title = date ? t('История транзакций за ') + date : t('История транзакций');
  const resetFilters = () => {
    dispatch(actions.setFilters([{ columnName: 'partner', value: partner }, { columnName: 'createdAt', value: createdAt }]));
    dispatch(actions.setSorting(initialState.get(stateKeys.reportsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.reportsList).get(stateKeys.currentPage)));
  };
  const download = () => dispatch(billingAsyncAction.getBillingReportDownloadAsync(reportDownloadParameters.toJS()));

  return (
    <>
      {reportReload
        ? (
          <>
            <PageHeader title={title}>
              <ButtonWithIconAndCircularProgress
                isLoading={reportDownloadPending}
                disabled={reportDownloadPending}
                text={t('Скачать')}
                onClick={download}
              >
                <CloudDownloadIcon />
              </ButtonWithIconAndCircularProgress>

              <ResetFiltersButton disabled={isDisabledResetButton} pending={isLoading} onReset={resetFilters} />
            </PageHeader>
            <ReportGeneralTable />
            <Box m={2} />
            <ReportsTable />
          </>

        )
        : <Navigate to={pageLinks.billing.history} />
      }
    </>
  );
}

export default ReportsPage;
