// Core
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';

export const useEmotionStyles = (stylesElement) => {
  const theme = useTheme();
  const rawClasses = typeof stylesElement === 'function' ? stylesElement(theme) : stylesElement;
  const prepared = {};

  Object.entries(rawClasses).forEach(([key, value = {}]) => {
    prepared[key] = css(value);
  });

  return prepared;
};
