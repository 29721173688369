// Core
import { apply, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { pageLinks } from '../../../../config/routes';

// _helpers
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callDeleteMessageHashIdWorker({ payload }) {
  yield put(actions.mergeMessageRequestState({
    delete: true,
  }));

  const response = yield apply(api, api.messages.deleteMessagesHashId, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        yield put(push(pageLinks.messages.all));
        break;
      }
      case 'error': {
        const { statusTitle, errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeMessageRequestState({
    delete: false,
  }));
}
