import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import {
  Field, reduxForm, initialize,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { selectors } from '../../../../../../../engine/config/selectors';
import Select from '../../../../../../components/_Form/Selects/Select/Select';
import Checkbox from '../../../../../../components/_Form/Checkbox/Checkbox';
import { api } from '../../../../../../../engine/config/api';
import { ProductCreatedDateRange } from './components/ProductCreatedDateRange';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';

const formName = 'ProductsProcessingStatistics';
const formData = {
  createdAfter: 'createdAfter',
  createdBefore: 'createdBefore',
  statusFromStartingAt: 'statusFromStartingAt',
  statusFromEndingAt: 'statusFromEndingAt',
  statusToStartingAt: 'statusToStartingAt',
  statusToEndingAt: 'statusToEndingAt',
  directTransition: 'directTransition',
  statusFrom: 'statusFrom',
  statusTo: 'statusTo',
};

const initialData = {
  [formData.createdAfter]: '',
  [formData.createdBefore]: '',
  [formData.statusFromStartingAt]: '',
  [formData.statusFromEndingAt]: '',
  [formData.statusToStartingAt]: '',
  [formData.statusToEndingAt]: '',
  [formData.directTransition]: true,
};


const ProductsLogisticsSidebar = ({
  openLogisticsSidebar, setOpenLogisticsSidebar, pristine, handleSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productStatuses = useSelector(selectors.allProductsTable.productStatuses).toJS();
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const statusFrom = formValues.get(formData.statusFrom);
  const statusTo = formValues.get(formData.statusTo);
  const statusFromStartingAt = formValues.get(formData.statusFromStartingAt);
  const statusFromEndingAt = formValues.get(formData.statusFromEndingAt);
  const statusToStartingAt = formValues.get(formData.statusToStartingAt);
  const statusToEndingAt = formValues.get(formData.statusToEndingAt);
  const createdBefore = formValues.get(formData.createdBefore);
  const createdAfter = formValues.get(formData.createdAfter);
  const otherProductStatuses = productStatuses.filter(status => status.value !== statusFrom?.value);

  const [minDateCreateBefore, setMinDateCreateBefore] = useState(null);
  const [maxDateCreateAfter, setMaxDateCreateAfter] = useState(moment());
  const [maxDateStatusFromStartingAt, setMaxDateStatusFromStartingAt] = useState(moment());
  const [minDateStatusFromEndingAt, setMinDateStatusFromEndingAt] = useState(null);
  const [maxDateStatusToStartingAt, setMaxDateStatusToStartingAt] = useState(moment());
  const [minDateStatusToEndingAt, setMinDateStatusToEndingAt] = useState(null);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  useEffect(() => {
    dispatch(initialize(formName, initialData, Object.keys(initialData)));
  }, []);

  const disabledSend = () => {
    if (createdAfter && createdBefore) {
      return false;
    }
    if (statusFrom && statusFromStartingAt && statusFromEndingAt) {
      return false;
    }
    if (statusTo && statusToStartingAt && statusToEndingAt) {
      return false;
    }
    return true;
  };

  const handleClose = () => setOpenLogisticsSidebar(false);
  const handleClear = () => dispatch(initialize(formName, initialData, Object.keys(initialData)));

  const onSubmit = async (data) => {
    setSendButtonDisabled(true);
    const normalizedData = data.toJS();
    const params = {
      [formData.createdAfter]: normalizedData.createdAfter ? normalizedData.createdAfter.format('YYYY-MM-DD') : undefined,
      [formData.createdBefore]: normalizedData.createdBefore ? normalizedData.createdBefore.format('YYYY-MM-DD') : undefined,
      [formData.statusFrom]: normalizedData.statusFrom?.value,
      [formData.statusFromStartingAt]: normalizedData.statusFromStartingAt ? normalizedData.statusFromStartingAt.format('YYYY-MM-DD') : undefined,
      [formData.statusFromEndingAt]: normalizedData.statusFromEndingAt ? normalizedData.statusFromEndingAt.format('YYYY-MM-DD') : undefined,
      [formData.statusTo]: normalizedData.statusTo?.value,
      [formData.statusToStartingAt]: normalizedData.statusToStartingAt ? normalizedData.statusToStartingAt.format('YYYY-MM-DD') : undefined,
      [formData.statusToEndingAt]: normalizedData.statusToEndingAt ? normalizedData.statusToEndingAt.format('YYYY-MM-DD') : undefined,
      [formData.directTransition]: normalizedData.directTransition,
    };

    const filteredParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value !== undefined));
    const response = await api.products.getStatusReport(filteredParams);
    setOpenLogisticsSidebar(false);
    setSendButtonDisabled(false);
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(response.data?.message, t('Успех')));
      dispatch(initialize(formName, initialData, Object.keys(initialData)));
    } else {
      dispatch(setErrorMessage(response.data?.message, t('Ошибка')));
    }
  };


  const handleChangeCreatedAfter = (date) => {
    setMinDateCreateBefore(date);
  };

  const handleChangeCreatedBefore = (date) => {
    setMaxDateCreateAfter(date);
  };

  const handleChangeStatusFromStartingAt = (date) => {
    setMinDateStatusFromEndingAt(date);
  };

  const handleChangeStatusFromEndingAt = (date) => {
    setMaxDateStatusFromStartingAt(date);
  };

  const handleChangeStatusToStartingAt = (date) => {
    setMinDateStatusToEndingAt(date);
  };

  const handleChangeStatusToEndingAt = (date) => {
    setMaxDateStatusToStartingAt(date);
  };

  return (
    <Drawer
      anchor="right"
      open={openLogisticsSidebar}
      onClose={handleClose}
      containerStyle={{ height: '100%' }}
    >
      <Box sx={{
        width: '380px', flex: 1, display: 'flex', flexDirection: 'column',
      }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          ml: '30px',
          mr: '30px',
          mt: '16px',
          mb: '16px',
          alignItems: 'center',
          maxHeight: '24px',
        }}
        >
          <Typography
            component="span"
            sx={{ fontWeight: 700, fontSize: '16px', lineHeight: '22px' }}
          >
            {t('Статистика обработки товаров')}
          </Typography>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
            sx={{
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
                backgroundColor: 'inherit',
              },
              padding: 0,
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Box>
        <Divider />
        <Box sx={{
          padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1,
        }}
        >
          <Typography sx={{ alignSelf: 'start', fontSize: '14px', lineHeight: '14px' }}>{t('Период создания товара')}</Typography>
          <ProductCreatedDateRange
            firstName={formData.createdAfter}
            secondName={formData.createdBefore}
            firstHandleChange={handleChangeCreatedAfter}
            secondHandleChange={handleChangeCreatedBefore}
            secondMinDate={minDateCreateBefore}
            firstMaxdate={maxDateCreateAfter}
          />
          <Box sx={{
            width: '100%', pt: '8px', pb: '8px', mb: '8px',
          }}
          >
            <Field
              name={formData.statusFrom}
              component={Select}
              options={productStatuses}
              placeholder={'Статус'}
              closeMenuOnScroll
              variant="outlined"
            />
          </Box>
          <ProductCreatedDateRange
            firstName={formData.statusFromStartingAt}
            secondName={formData.statusFromEndingAt}
            firstHandleChange={handleChangeStatusFromStartingAt}
            secondHandleChange={handleChangeStatusFromEndingAt}
            secondMinDate={minDateStatusFromEndingAt}
            firstMaxdate={maxDateStatusFromStartingAt}
          />
          <Divider sx={{ width: '100%', mt: '10px', mb: '30px' }} />
          <Box sx={{
            width: '100%', pt: '8px', pb: '8px',
          }}
          >
            <Field
              name={formData.statusTo}
              component={Select}
              options={otherProductStatuses}
              placeholder={'Статус'}
              closeMenuOnScroll
              variant="outlined"
            />
          </Box>
          <ProductCreatedDateRange
            firstName={formData.statusToStartingAt}
            secondName={formData.statusToEndingAt}
            firstHandleChange={handleChangeStatusToStartingAt}
            secondHandleChange={handleChangeStatusToEndingAt}
            secondMinDate={minDateStatusToEndingAt}
            firstMaxdate={maxDateStatusToStartingAt}
          />
          <Field
            component={Checkbox}
            name={formData.directTransition}
            checkboxLabel={t('Прямой переход между статусами')}
            sx={{ maxHeight: '24px' }}
          />
          <Box sx={{
            alignSelf: 'end', display: 'flex', flex: 1, gap: '8px', alignItems: 'end', justifyContent: 'end', flexDirection: 'column',
          }}
          >
            {!pristine && <Button onClick={handleClear} variant="contained" sx={{ maxHeight: '36px' }}>{t('Очистить данные')}</Button>}
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                disabled={pristine}
                sx={{ maxHeight: '36px' }}
              >
                {t('Отменить')}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{ maxHeight: '36px' }}
                disabled={disabledSend() || sendButtonDisabled}
              >
                {t('Сформировать отчёт')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default reduxForm({
  form: formName,
})(ProductsLogisticsSidebar);
