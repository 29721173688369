// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// UI
import Tab from '@mui/material/Tab';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

// Style
import { useStyles } from '../../../hooks/useStyles';
import TabItemMenuStyles from './TabItemMenuStyle';

function TabItemMenu(props) {
  const {
    value, label, onChange, selected,
  } = props;
  const classes = useStyles(TabItemMenuStyles);

  return (
    <Tab
      value={value}
      label={label}
      className={classes.tabItem}
      onChange={onChange}
      selected={selected}
      icon={<KeyboardArrowRightRoundedIcon className={classes.tabItemIcon} />}
      iconPosition="end"
    />
  );
}

TabItemMenu.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};

export default TabItemMenu;
