import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Field, Form, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { submit } from 'redux-form';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { mimeTypeExtensions } from '../../../../../engine/config/mimeTypeExtensions';
import { asyncActions } from '../../../../../engine/core/feeds/all/saga/asyncActions';
import { actions } from '../../../../../engine/core/feeds/all/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { validators } from '../../../../../_helpers/validationsForm';
import FilesUploader from '../../../../components/_Form/FilesUploader/FilesUploader';
import FormHelperTextComponent from '../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';

export const formName = 'refreshModal';
export const formFields = Object.freeze({
  file: 'file',
});

function RefreshModal(props) {
  const { t } = useTranslation();
  const { handleSubmit, invalid } = props;
  const refreshPending = useSelector(selectors.feeds.refreshPending);
  const id = useSelector(selectors.feeds.refreshId);
  const hashId = useSelector(selectors.feeds.refreshHashId);
  const refreshShowModal = useSelector(selectors.feeds.refreshShowModal);
  const dispatch = useDispatch();
  function onFormSubmit(data) {
    dispatch(asyncActions.postRefreshAsync({ id, hashId, file: data.toJS().file }));
  }
  const handleModalClose = () => {
    dispatch(actions.mergeFeedsRefresh({ showModal: false, hashId: null }));
  };
  const handleModalSend = () => {
    dispatch(submit(formName));
  };

  return (
    <Modal
      title={t('Обновить фид')}
      sendText={t('Отправить')}
      modalOpen={refreshShowModal}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      handleModalSend={handleModalSend}
      disabledSend={refreshPending || invalid}
      modalMaxWidth="xs"
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xml]}
          component={RenderFilesUploader}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          textBefore2={t('Файл в формате XML')}
          name={formFields.file}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

export function RenderFilesUploader(fieldRenderProps) {
  const {
    meta: {
      touched,
      error,
    },
    ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

RefreshModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: formName,
})(memo(RefreshModal));
