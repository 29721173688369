// Core
import { Map, List } from 'immutable';
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { billingActions } from '../../action';

export function* callGetBillingInvoiceTypesWorker({ payload }) {
  yield put(billingActions.mergeBillingInvoiceTypes({ pending: true }));
  const response = yield apply(api, api.billing.getBillingInvoiceTypes, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    const { status } = response.data;
    switch (status) {
      case 'success': {
        const { items } = response.data;
        yield put(billingActions.mergeBillingInvoiceTypes(Map({ items: List(items), errors: List(), statusTitle: null })));
        break;
      }
      case 'error': {
        const { errors, statusTitle } = response.data;
        yield put(billingActions.mergeBillingInvoiceTypes(Map({ errors: List(errors), statusTitle })));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(billingActions.mergeBillingInvoiceTypes({ pending: false }));
}
