// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// Components
import ContainedPrimaryButton from '../../../../../../../components/Buttons/ButtonContainedPrimary';
// Engine
import { asyncActions } from '../../../../../../../../engine/core/contentTemplates/saga/asyncActions';
import { selectors } from '../../../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../../../../../engine/config/access';

function DownloadContentTemplatesImportProductButton(props) {
  const { categoryId, getProductsImportTemplatesDownloadAsync, isLoading } = props;
  const { t } = useTranslation();

  const onClickHandler = () => {
    getProductsImportTemplatesDownloadAsync(categoryId);
  };

  const hasAccess = useAccessList(accessList.productImportTemplatesDownload);

  if (!hasAccess) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  return (
    <ContainedPrimaryButton
      isLoading={isLoading}
      onClick={onClickHandler}
      text={t('Скачать шаблон импорта')}
    />
  );
}

DownloadContentTemplatesImportProductButton.displayName = 'DownloadContentTemplatesImportProductButton';

DownloadContentTemplatesImportProductButton.propTypes = {
  categoryId: PropTypes.number.isRequired,
  getProductsImportTemplatesDownloadAsync: PropTypes.func,
  isLoading: PropTypes.bool,
};

DownloadContentTemplatesImportProductButton.defaultProps = {
  getProductsImportTemplatesDownloadAsync: () => {},
  isLoading: false,
};

function mapStateToProps(state, ownProps) {
  const { categoryId } = ownProps;
  return {
    isLoading: !!selectors.contentTemplatesImport.productsImportTemplatesDownload(state).get(`${categoryId}`),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProductsImportTemplatesDownloadAsync: categoryId => dispatch(asyncActions.getProductsImportTemplatesDownloadAsync({ categoryId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadContentTemplatesImportProductButton);
