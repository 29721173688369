// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// lodash
import values from 'lodash/values';

// UI
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/billingRatingRatio/actions';
// Hooks
import { usePartnersRatingRatiosList } from '../../_hooks/usePartnersRatingRatiosList';
// import { asyncActions } from '../../../../../engine/core/billingRatingRatio/saga/asyncActions';
import { tableColumns } from './PartnersRatingRatiosTableColumnsSettings';

// Redux
function PartnersRatingRatiosTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.billingRatingRatio.isLoading);
  // const columns = values(tableColumns);
  const columns = values(tableColumns);
  // Rows
  const rows = useSelector(selectors.billingRatingRatio.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.billingRatingRatio.currentPage);
  const pageSize = useSelector(selectors.billingRatingRatio.pageSize);
  const totalCount = useSelector(selectors.billingRatingRatio.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.billingRatingRatio.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.billingRatingRatio.settingStatus);
  // const settingPending = useSelector(selectors.billingRatingRatio.settingPending);
  // Filtering
  const filters = useSelector(selectors.billingRatingRatio.filters);

  return (
    <DxTable
      name="billingRatingRatio"
      // fixedColumn
      // showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePartnersRatingRatiosList}
      columns={columns}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.billingRatingRatio.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default PartnersRatingRatiosTable;
