// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import EditCell from './EditCell';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/magentoRequests/actions';
// Hooks
import { useMagentoRequestsList } from '../_hooks/useMagentoRequestsList';
import { useAccessList } from '../../../../hooks/useAccessList';
// import { asyncActions } from '../../../../../engine/core/magentoRequests/saga/asyncActions';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';
import { accessList } from '../../../../../engine/config/access';

function Table() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.magentoRequests.isLoading);
  // Data
  const rows = useSelector(selectors.magentoRequests.list);
  const columns = [
    tableDataTypes.requestMethod,
    tableDataTypes.magentoMethod,
    tableDataTypes.magentoPath,
    tableDataTypes.responseCode,
    tableDataTypes.createdAt,
    tableDataTypes.runTime,
  ];
  // Paging
  const currentPage = useSelector(selectors.magentoRequests.currentPage);
  const pageSize = useSelector(selectors.magentoRequests.pageSize);
  const totalCount = useSelector(selectors.magentoRequests.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.magentoRequests.sorting);
  // Edit
  const hasAccessEdit = useAccessList(accessList.configurationEdit);
  const editWidth = useEditCellWidth([hasAccessEdit]);
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.magentoRequests.settingStatus);
  // const settingPending = useSelector(selectors.magentoRequests.settingPending);
  // Filtering
  const filters = useSelector(selectors.magentoRequests.filters);

  return (
    <DxTable
      name="magentoRequests"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      // Edit
      editWidth={editWidth}
      editComponent={EditCell}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.magentoRequests.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
      updateHooks={useMagentoRequestsList}
    />
  );
}

export default memo(Table);
