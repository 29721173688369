// Core
import { useState } from 'react';

export function useInput(defaultValue = '') {
  const [value, onChangeHandler] = useState(defaultValue);

  function onChange(ev) {
    onChangeHandler(ev?.target?.value || '');
  }

  return { value, onChange };
}
