// Core
import {
  apply, delay, put,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api } from '../../../../config/api';
import { pageLinks } from '../../../../config/routes';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { userActions } from '../../action';

export function* callPutUsersHashIdWorker({ payload }) {
  const { redirect, ...restPayload } = payload;
  yield put(userActions.mergePutUsersHashId({ pending: true }));

  const response = yield apply(api, api.user.putUsersHashId, [restPayload]);
  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;

      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          if (redirect) {
            yield delay(0);
            yield put(push(pageLinks.settings.users.all));
          }
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle, true));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(userActions.mergePutUsersHashId({ pending: false }));
}
