// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
// UI
import StatisticProductsIcon from '../../../../../../icons/StatisticProductsIcon';
// parts
import StatCard from '../../../../../../components/Widgets/StatCard/StatCard';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
// Engine
import { actions } from '../../../../../../../engine/core/feeds/products/actions';
import { selectors } from '../../../../../../../engine/config/selectors';
// hooks
import { useStyles } from '../../../../../../hooks/useStyles';
// Style
import FeedProductStatisticStyle from './FeedProductStatisticStyle';
import { useFeedsProductsStatisticsList } from './_hooks/useFeedsProductsStatisticsList';
import FeedsProductsStatisticsListTable from './FeedProductStatisticTable';

function FeedProductStatisticModal() {
  useFeedsProductsStatisticsList();
  const classes = useStyles(FeedProductStatisticStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productsStatistic = useSelector(selectors.feedsProducts.productsStatistic);
  const { items, pending, modalOpened } = productsStatistic.toJS();

  const handleClickClose = () => {
    dispatch(actions.mergeProductsStatistic({
      modalOpened: false,
    }));
  };

  return (
    <Modal
      title={t('Статистика товаров прайсов')}
      modalOpen={modalOpened && !pending}
      handleModalCancel={handleClickClose}
      handleModalToggle={handleClickClose}
      hideButtons
    >
      <div className={classes.orderModalContent}>
        {!isEmpty(items) && (
          <StatCard
            title={t('Количество товаров')}
            value={<NumberFormat value={items.quantity} thousandSeparator=" " displayType="text" />}
            icon={<StatisticProductsIcon sx={{ width: '40px', height: '40px' }} className={classes.iconColor} />}
            customClasses={classes.spacingBottom}
          />
        )}
        <FeedsProductsStatisticsListTable />
      </div>
    </Modal>
  );
}

export default memo(FeedProductStatisticModal);
