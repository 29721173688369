// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
// Components
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import PartnersImportTopModal from './components/PartnersImportTopModal';
// Parts
import PartnersTable from './components/PartnersTable/PartnersTable';
import AddButton from './components/AddButton';
import ActionsButtonGroups from './components/ActionsButtonGroups';

// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Engine
import { asyncActions } from '../../../engine/core/partners/saga/asyncActions';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/partners/actions';
import { initialState, stateKeys } from '../../../engine/core/partners/reducer';
import { pageLinks } from '../../../engine/config/routes';
import { tableDataTypes } from '../../../_helpers/data/tableDataTypes';
import { partnerStatuses } from '../../../engine/config/partnerStatuses';
// Hooks
import { usePartnersList } from './components/PartnersTable/_hooks/usePartnersList';

function PartnersPage() {
  usePartnersList();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectors.partners.filters);
  const sorting = useSelector(selectors.partners.sorting);
  const settingStatus = useSelector(selectors.partners.settingStatus);
  const settingPending = useSelector(selectors.partners.settingPending);
  const isLoading = useSelector(selectors.partners.isLoading);
  const pathname = useSelector(selectors.router.pathname);
  const statusesList = useSelector(selectors.partner.statusesList);
  const hasFilters = pathname === pageLinks.partner.all ? filters.size : filters.size > 1;
  const isDisabledResetButton = isLoading || settingPending || !(hasFilters || sorting.size);

  const resetFilters = useCallback(() => {
    let defaultFilters = [];

    switch (pathname) {
      case pageLinks.partner.new: {
        defaultFilters = [{
          columnName: tableDataTypes.partnerStatus.name,
          value: find(statusesList.toJS(), { value: partnerStatuses.questionnaireFill }),
        }];
        break;
      }
      case pageLinks.partner.waiting: {
        defaultFilters = [{
          columnName: tableDataTypes.partnerStatus.name,
          value: find(statusesList.toJS(), { value: partnerStatuses.contractSigning }),
        }];
        break;
      }
      case pageLinks.partner.rejected: {
        defaultFilters = [{
          columnName: tableDataTypes.partnerStatus.name,
          value: [
            find(statusesList.toJS(), { value: partnerStatuses.frozenSigning }),
            find(statusesList.toJS(), { value: partnerStatuses.cancelStatus }),
          ],
        }];
        break;
      }
      default: {
        break;
      }
    }
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersList).get(stateKeys.sorting).toJS()));
    if (defaultFilters.length !== filters.size) {
      dispatch(actions.setFilters(defaultFilters));
    }
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersList).get(stateKeys.currentPage)));
  }, [dispatch, filters.size, pathname, statusesList]);

  const putSettings = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeader title={t('Список партнеров')}>
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
        <ActionsButtonGroups />
        <AddButton />
      </PageHeader>
      <PartnersTable />
      <PartnersImportTopModal />
    </>
  );
}

export default PartnersPage;
