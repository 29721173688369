// Core
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
// Parts
import Modal from '../../../../../../../containers/Modals/Modal/Modal';
import { completionPriceListModalConfig } from '../ProductFeedParametersTable';
// Helpers
import { dispatchCompletionListItemData } from '../../../_helpers/dispatchCompletionListItemData';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';

function HelpModalEditPriceListOptions() {
  const { hashId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const completionImportList = useSelector(selectors.feeds.completionImportList).toJS();
  const stepKey = completionPriceListModalConfig.steps.step_1;
  const modalTypeKey = completionPriceListModalConfig.type.edit;
  const isOpen = completionImportList?.[hashId]?.[modalTypeKey]?.[stepKey]?.value;

  const closeCallback = () => {
    dispatchCompletionListItemData({
      dispatch, hashId, completionImportList, modalTypeKey, stepKey,
    });
  };

  return (
    <Modal
      title={t('Подсказка')}
      modalOpen={isOpen}
      hideButtons
      cancelText={t('Понятно')}
      handleModalCancel={closeCallback}
      handleModalToggle={closeCallback}
      modalMaxWidth="sm"
    >
      <p>{t('Для внесения изменений - необходимо сохранить изменения и завершить импорт. Для этого, после редактирования, нажмите на кнопку "Сохранить и завершить импорт".')}</p>
      <p>{t('При необходимости внести изменения в нескольких шагах - нажмите кнопку "Сохранить и продолжить импорт", внесите изменения на всех шагах и нажмите кнопку "Завершить импорт" на последнем шаге сопоставления данных.')}</p>
      <p>{t('Горячая линия')}: 0(800)200900</p>
    </Modal>
  );
}

export default HelpModalEditPriceListOptions;
