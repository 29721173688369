// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// UI
import Grid from '@mui/material/Grid';
import PaymentTypesDocsModalFields from './PaymentTypesDocsModal/PaymentTypesDocsModalFields';
// Engine
import { accessList } from '../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
import TabsPanel from '../../../containers/Tabs/TabsPanel';

function PartnerEditPaymentTypesData(props) {
  const {
    display, mode, index, value,
  } = props;
  const isShowMode = mode === 'show';
  // Access
  const hasAccessEdit = useAccessList(accessList.partnerPaymentTypeEdit);

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item xs={false} lg={1} />
        <Grid item xs={12} lg={10}>
          <PaymentTypesDocsModalFields view="columns" disabled={isShowMode || !hasAccessEdit} />
        </Grid>
        <Grid item xs={false} lg={1} />
      </Grid>
    </TabsPanel>
  );
}

PartnerEditPaymentTypesData.propTypes = {
  display: PropTypes.oneOf(['flex', 'none']),
  mode: PropTypes.oneOf(['edit', 'show', 'create']),
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditPaymentTypesData.defaultProps = {
  mode: 'edit',
};

export default PartnerEditPaymentTypesData;
