// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// Components
import ContainedPrimaryButton from '../../../../../../../components/Buttons/ButtonContainedPrimary';
// Engine
import { asyncActions } from '../../../../../../../../engine/core/contentTemplates/saga/asyncActions';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../../../hooks/useAccessList';

function DownloadContentTemplatesFeaturesListButton(props) {
  const { categoryId, getProductsFeatureValuesListDownloadAsync, isLoading } = props;
  const { t } = useTranslation();

  const onClickHandler = () => {
    getProductsFeatureValuesListDownloadAsync(categoryId);
  };

  const hasAccess = useAccessList(accessList.productFeatureValuesListDownload);

  if (!hasAccess) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  return (
    <ContainedPrimaryButton
      isLoading={isLoading}
      onClick={onClickHandler}
      text={t('Скачать список значений и характеристик')}
    />
  );
}

DownloadContentTemplatesFeaturesListButton.displayName = 'DownloadContentTemplatesFeaturesListButton';

DownloadContentTemplatesFeaturesListButton.propTypes = {
  categoryId: PropTypes.number.isRequired,
  getProductsFeatureValuesListDownloadAsync: PropTypes.func,
  isLoading: PropTypes.bool,
};

DownloadContentTemplatesFeaturesListButton.defaultProps = {
  getProductsFeatureValuesListDownloadAsync: () => {},
  isLoading: false,
};

function mapStateToProps(state, ownProps) {
  const { categoryId } = ownProps;
  return {
    isLoading: !!selectors.contentTemplatesImport.productsFeatureValuesListDownload(state).get(`${categoryId}`),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProductsFeatureValuesListDownloadAsync: categoryId => dispatch(asyncActions.getProductsFeatureValuesListDownloadAsync({ categoryId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadContentTemplatesFeaturesListButton);
