const AccountStyle = theme => ({
  row: {
    padding: theme.spacing(),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  messageTitle: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 0,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
  },
  iconWarning: {
    fill: 'orange',
    width: '1em',
    height: '1em',
    marginRight: 4,
  },
  iconDone: {
    fill: 'green',
    width: '1em',
    height: '1em',
    marginRight: 4,
  },
  listWrapper: {
    margin: 0,
  },
  marginLeft: {
    marginLeft: 10,
  },
  statCardLoadingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 105,
    minWidth: 300,
  },
  dataRange: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      paddingBottom: '0',
    },
  },
  pieTitle: {
    minHeight: '60px',
    [theme.breakpoints.only('lg')]: {
      minHeight: '59px',
    },
    [theme.breakpoints.only('md')]: {
      minHeight: '50px',
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '45px',
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: 'auto',
    },
  },
});

export default AccountStyle;
