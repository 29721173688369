export const types = Object.freeze({
  SET_COMMISSION_LIST: 'SET_COMMISSION_LIST',
  SET_COMMISSION_CURRENT_PAGE: 'SET_COMMISSION_CURRENT_PAGE',
  SET_COMMISSION_FILTERS: 'SET_COMMISSION_FILTERS',
  SET_COMMISSION_PAGE_SIZE: 'SET_COMMISSION_PAGE_SIZE',
  SET_COMMISSION_SORTING: 'SET_COMMISSION_SORTING',
  SET_COMMISSION_INITIAL_STATE: 'SET_COMMISSION_INITIAL_STATE',
  SET_COMMISSION_SETTING_STATUS: 'SET_COMMISSION_SETTING_STATUS',
  SET_COMMISSION_SETTING_PENDING: 'SET_COMMISSION_SETTING_PENDING',
  SET_COMMISSION_DOWNLOAD_PENDING: 'SET_COMMISSION_DOWNLOAD_PENDING',
});
