// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// UI
import TextField from '@mui/material/TextField';
// Containers
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';

const DISMETIONS_CODE = ['product_logistics_height', 'product_logistics_length', 'product_logistics_width'];

const useStyles = makeStyles(theme => ({
  input: {
    display: 'block',
    '@global input': {
      boxSizing: 'border-box',
      fontSize: 14,
      height: 35,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(1.25),
      paddingTop: theme.spacing(0.5),
      textOverflow: 'ellipsis',
    },
    '@global .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.25),
    },
    '@global legend': {
      display: 'none',
    },
    '@global fieldset': {
      top: 0,
      borderColor: 'inherit !important',
    },
  },
}));

function UnsignedIntFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, isEditable, onConfirm, productId,
    success, value, required, type, valuesPlain, fullText, showDeleteButton,
    code,
  } = props;

  const initValue = value || '';
  const [textFieldValue, setTextFieldValue] = useState(initValue);
  const classes = useStyles();
  const isLogisticDismentions = DISMETIONS_CODE.includes(code);

  const onChangeHandler = (event) => {
    if (isLogisticDismentions) {
      const regexValidation = /^[1-9]\d{0,3}$/;
      if (regexValidation.test(event.target.value) || event.target.value === '') {
        setTextFieldValue(event.target.value);
      }
    } else {
      const numberValue = Number(event.target.value.replace(/[^0-9]/g, ''));
      if (numberValue <= 2147483647) {
        setTextFieldValue(numberValue.toString());
      }
    }
  };

  const onConfirmHandler = (payload) => {
    onConfirm({ [featureName]: payload });
  };

  return (
    <EditableContainer isEditable={isEditable} fullText={fullText} text={textFieldValue}>
      <ConfirmButtons
        key={productId}
        error={error}
        disabled={disabled}
        required={required}
        notEmpty={textFieldValue !== ''}
        onConfirm={onConfirmHandler}
        onReject={setTextFieldValue}
        success={success}
        type={type}
        value={textFieldValue.trim()}
        valuesPlain={valuesPlain}
        initValue={initValue}
        showDeleteButton={showDeleteButton}
      >
        <TextField
          className={classes.input}
          value={textFieldValue}
          id={`formatted-unsignedInt-input__${productId}`}
          onChange={onChangeHandler}
          margin="none"
          variant="outlined"
        />
      </ConfirmButtons>
    </EditableContainer>
  );
}

UnsignedIntFieldWithConfirmButtons.propTypes = {
  showDeleteButton: PropTypes.bool,
  error: PropTypes.bool,
  fullText: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditable: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  success: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  code: PropTypes.string,
};

UnsignedIntFieldWithConfirmButtons.defaultProps = {
  showDeleteButton: true,
  error: false,
  required: false,
  disabled: false,
  isEditable: false,
  success: false,
  value: '',
};

export default memo(UnsignedIntFieldWithConfirmButtons);
