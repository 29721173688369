// Core
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { billingAsyncAction } from '../../../../../../../engine/core/billing/saga/asyncAction';
import { selectors } from '../../../../../../../engine/config/selectors';

export function useTableRows() {
  const rows = useSelector(selectors.billing.dashboardLastTransactions);
  const isLoading = useSelector(selectors.billing.dashboardLastTransactionsPending);
  const dispatch = useDispatch();

  const getRowsData = useCallback(() => {
    dispatch(billingAsyncAction.getBillingDashboardLastTransactionsAsync());
  }, [dispatch]);

  return useMemo(() => ({
    getRowsData,
    isLoading,
    rows: rows.toJS(),
  }), [getRowsData, isLoading, rows]);
}
