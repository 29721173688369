import { getIn, List } from 'immutable';
import { stateKeys as emailTemplatesKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const emailTemplates = Object.freeze({
  currentPage: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.currentPage], 0),
  filters: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.filters], List()),
  isLoading: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.pending], false),
  list: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.items], List()),
  pageSize: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.reload], false),
  totalCount: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.totalCount], 0),
  sorting: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.sorting], List()),
  settingStatus: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.settingStatus], false),
  settingPending: state => getIn(state, [emailTemplatesKeys.controller, emailTemplatesKeys.emailList, emailTemplatesKeys.settingPending], false),
});
