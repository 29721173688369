// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// Instruments
import { api } from '../../../../config/api';
import { actions } from '../../action';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetMailTemplatesRolesWorker() {
  yield put(actions.mergeMailTemplatesRoles({ pending: true }));
  const { mailTemplateRoles: statuses, rolesLastUpdate: statusesLastUpdate } = localData.keysObj;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [statusesLastUpdate]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.rolesLastUpdate);

  let response;
  let data;

  switch (true) {
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.user.getUsersRoles, [{ context: 'mailTemplateRoles' }]);
      yield apply(localData, localData.setItem, [statusesLastUpdate, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      const dataFromLocal = yield apply(localData, localData.getItem, [statuses]);
      data = dataFromLocal?.items || [];
      if (!data) {
        response = yield apply(api, api.user.getUsersRoles, [{ context: 'mailTemplateRoles' }]);
      }
      break;
    }
    default: {
      data = [];
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    data = responseData;

    yield apply(localData, localData.setItem, [statuses, { items: responseData }]);
  }
  yield put(actions.mergeMailTemplatesRoles({
    item: List(data),
    pending: false,
  }));
}
