// Core
import React, { useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import { IntegratedSelection } from '@devexpress/dx-react-grid';

function DxPatchedIntegratedSelection(props) {
  const { selectionOffFlag } = props;
  const rowsRef = useRef();
  const name = 'rows';

  const setRowsRefGetter = useCallback(
    ({ rows }) => {
      const rowSelectionEnabled = row => row[selectionOffFlag];
      rowsRef.current = rows;
      return rows.filter(rowSelectionEnabled);
    },
    [selectionOffFlag],
  );

  const computedRowsGetter = () => rowsRef.current;

  return selectionOffFlag ? (
    <Plugin>
      <Getter
        name={name}
        computed={setRowsRefGetter}
      />
      <IntegratedSelection />
      <Getter
        name={name}
        computed={computedRowsGetter}
      />
    </Plugin>
  ) : <IntegratedSelection />;
}

DxPatchedIntegratedSelection.propTypes = {
  selectionOffFlag: PropTypes.string,
};

DxPatchedIntegratedSelection.defaultProps = {
  selectionOffFlag: '',
};

export default DxPatchedIntegratedSelection;
