// Core
import React, { useEffect, useState } from 'react';
import {
  reduxForm, Field, Form, FieldArray,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Icons
import SaveIcons from '@mui/icons-material/Save';
import EmailIcon from '@mui/icons-material/Email';
// Parts
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
// import Checkbox from '../../../components/_Form/Checkbox/Checkbox';
import TextField from '../../components/_Form/TextField/TextField/TextField';
import ChangePhone from './components/ChangePhone';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import PageHeader from '../../components/PageHeader/PageHeader';
import SectionHolder from '../../containers/SectionHolder/SectionHolder';
// Engine
import { userActionAsync } from '../../../engine/core/user/saga/asyncAction';
// import { userRoles } from '../../../engine/config/userRoles';
import { pageLinks } from '../../../engine/config/routes';
import { selectors } from '../../../engine/config/selectors';
import { accessList } from '../../../engine/config/access';
// Helpers
import { phoneMask, validators } from '../../../_helpers/validationsForm';
import { useAccessList } from '../../hooks/useAccessList';
import AdditionalEmailGroupsFields from './components/AdditionalEmailGroupField/AdditionalEmailGroupsFields';
// Data
export const formName = 'profileSettings';
export const formFields = Object.freeze({
  cPassword: 'profileCPassword',
  fullName: 'fullName',
  email: 'email',
  phone: 'phone',
  oldPassword: 'profileOldPassword',
  password: 'profilePassword',
  // skype: 'skype',
  viber: 'viber',
  locale: 'locale',
  mailEnabled: 'mailEnabled',
  additionalEmails: 'additionalEmails',
});

const isPhoneConfirm = process.env.REACT_APP_PHONE_CONFIRM === 'true';

function UserSelf(props) {
  const { t, i18n } = useTranslation();
  const { handleSubmit } = props;
  const putUserInfoPending = (useSelector(selectors.user.putUserInfo)).toJS().pending;
  const hasAccessMailSettings = useAccessList(accessList.userMailSettingEdit);
  const dispatch = useDispatch();
  const [showNewPhoneForm, setShowNewPhoneForm] = useState(false);

  useEffect(() => {
    dispatch(userActionAsync.getUsersSelfAsync());
  }, []);

  const onChangePhone = () => setShowNewPhoneForm(true);

  const onFormSubmit = (formData) => {
    // const isAdmin = rolesList.includes(userRoles.admin);
    if (formData && formData.toJS) {
      const userInfo = {
        fullName: formData.get(formFields.fullName),
        email: formData.get(formFields.email),
        phone: formData.get(formFields.phone),
        oldPassword: formData.get(formFields.oldPassword),
        password: formData.get(formFields.password),
        // skype: formData.get(formFields.skype),
        // viber: formData.get(formFields.viber),
        additionalEmails: formData.get(formFields.additionalEmails),
        locale: i18n.language,
      };
      // if (isAdmin) {
      //   userInfo.mailEnabled = formData.get(formFields.mailEnabled);
      // }
      dispatch(userActionAsync.putUserInfoAsync(userInfo));
    }
  };

  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Grid container justifyContent="center">
        <PageHeader title={t('Настройка профиля')}>
          {hasAccessMailSettings && (
            <NavLink to={pageLinks.mailSettings}>
              <ButtonWithIcon text={t('Настройка уведомлений')} component="button" type="button">
                <EmailIcon />
              </ButtonWithIcon>
            </NavLink>
          )}
          <ButtonWithIcon text={t('Сохранить')} disabled={putUserInfoPending} onClick={handleSubmit(onFormSubmit)} component="button" type="button">
            <SaveIcons />
          </ButtonWithIcon>
        </PageHeader>
        <SectionHolder>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                name={formFields.fullName}
                component={TextField}
                label={t('Имя')}
                fullWidth
                validation
                required
                validate={[validators.required]}
              />
              <Field
                name={formFields.email}
                component={TextField}
                label={t('Email')}
                fullWidth
                margin="normal"
                validation
                required
                validate={[validators.required, validators.email]}
              />
              <FieldArray name={formFields.additionalEmails} component={AdditionalEmailGroupsFields} />
              <Field
                name={formFields.phone}
                component={TextField}
                label={t('Телефон')}
                fullWidth
                margin="normal"
                disabled={isPhoneConfirm}
                validation
                type="tel"
                endAdornment={isPhoneConfirm && (
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={showNewPhoneForm}
                    onClick={onChangePhone}
                  >
                    {t('Сменить')}
                  </Button>
                </InputAdornment>
                )}
                {...phoneMask}
              />
              {isPhoneConfirm && <ChangePhone showForm={showNewPhoneForm} formName={formName} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                name={formFields.oldPassword}
                component={TextField}
                label={t('Старый пароль')}
                type="password"
                fullWidth
                validation
              />
              <Field
                name={formFields.password}
                component={TextField}
                label={t('Новый пароль')}
                type="password"
                fullWidth
                margin="normal"
                validation
              />
              <Field
                name={formFields.cPassword}
                component={TextField}
                label={t('Повторить Новый пароль')}
                type="password"
                fullWidth
                margin="normal"
                validation
              />
              {/* <Field */}
              {/*  name={formFields.mailEnabled} */}
              {/*  disabled={!isAdmin} */}
              {/*  component={Checkbox} */}
              {/*  checkboxLabel={t('Получать рассылку уведомлений на email')} */}
              {/*  fullWidth */}
              {/*  margin="normal" */}
              {/* /> */}
            </Grid>
          </Grid>
        </SectionHolder>
      </Grid>
    </Form>
  );
}

UserSelf.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(UserSelf);
