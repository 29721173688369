// Core
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import forEach from 'lodash/forEach';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Parts
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import ComponentWithTooltip from '../../../../containers/ComponentWithTooltip';
// Engine
import { locale } from '../../../../../engine/init/i18n';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import { asyncActions } from '../../../../../engine/core/content/saga/asyncActions';
// Helpers
import { useAccessList } from '../../../../hooks/useAccessList';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import HeaderWithTooltipStyles from '../HeaderWithTooltipStyles';

const useStyles = makeStyles(HeaderWithTooltipStyles);

function HeaderTooltip(props) {
  const { column, children, isHiddenTooltip } = props;
  const classes = useStyles();
  const { t, i18n: { language } } = useTranslation();
  const columnDesc = column?.desc?.[locale[language]];
  const dispatch = useDispatch();
  const updateValueFromMagentoFeatureId = useSelector(selectors.content.updateValueFromMagentoFeatureId);
  const updateValueFromMagentoPending = useSelector(selectors.content.updateValueFromMagentoPending);
  const hasFeatureUpdateValuesFromMagentoAccess = useAccessList(accessList.featureUpdateValuesFromMagento);
  const descriptionFiled = useMemo(() => {
    let description;

    if (columnDesc) {
      description = columnDesc;
    } else {
      forEach(tableDataTypes, (value) => {
        if (column.type === value.type) {
          if (value.setDescByName?.includes(column.name)) {
            description = tableDataTypes[column.name].description;
            return description;
          }
          description = value.description;
          return description;
        }
        if (column.name === value.name) {
          description = value.description;
          return description;
        }
      });
    }
    return description;
  }, [column.name, column.type, language]);

  const handleRefresh = () => {
    dispatch(asyncActions.putUpdateValuesFromMagentoAsync({ featureId: column.name }));
  };

  return !isHiddenTooltip && (column.isRequiredLk || column.isRequiredMg || column.recommended || descriptionFiled)
    ? (
      <ComponentWithTooltip
        classesTooltipHelp={classes.tooltipIconHelp}
        description={(
          <>
            {descriptionFiled && <Box component="span" sx={{ fontWeight: 600, whiteSpace: 'pre-line' }}>{columnDesc ? descriptionFiled : t(descriptionFiled)}</Box>}
            {column.isRequiredLk && <><br /><Icon color="primary" className={`${classes.requiredIcon} ${classes.tooltipIcon}`}>*</Icon> - {t('обязательное поле')}</>}
            {column.isRequiredMg && <><br /><Icon color="secondary" className={`${classes.recommendedIcon} ${classes.tooltipIcon}`}>*</Icon> - {t('рекомендуемое поле')}</>}
            {column.recommended && <><br /><Icon color="secondary" className={`${classes.recommendedIcon} ${classes.tooltipIcon}`}>*</Icon> - {t('рекомендуемое поле')}</>}
          </>
        )}
        refresh={column.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
        refreshDisabled={updateValueFromMagentoPending && (column.name === updateValueFromMagentoFeatureId)}
        refreshDescription={t('Обновить значение характеристики')}
        onRefresh={handleRefresh}
      >
        <div className={classes.descriptionLabel}>{children}</div>
      </ComponentWithTooltip>
    )
    : children;
}

HeaderTooltip.propTypes = {
  column: PropTypes.object,
  children: PropTypes.node,
  isHiddenTooltip: PropTypes.bool,
};

export default memo(HeaderTooltip);
