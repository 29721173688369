import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as categoryStatusesHistoryKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const categoryStatusesHistory = Object.freeze({
  currentPage: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.currentPage], 0),
  filters: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.filters], List()),
  isLoading: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.pending], false),
  list: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.items], List()),
  pageSize: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.reload], false),
  totalCount: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.totalCount], 0),
  sorting: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.sorting], List()),
  settingStatus: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.settingStatus], false),
  settingPending: state => getIn(state, [categoryStatusesHistoryKeys.controller, categoryStatusesHistoryKeys.categoryStatusesHistoryList, categoryStatusesHistoryKeys.settingPending], false),
});
