
export const formName = 'ServiceSupportForm';

export const formFields = Object.freeze({
  subject: `${formName}_subject`,
  text: `${formName}Text`,
  title: `${formName}Title`,
  email: `${formName}Email`,
  phone: `${formName}Phone`,
  media: `${formName}Media`,
});
