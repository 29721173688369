// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_LIST, data);
  },
  setChangeCategoryErrorOpen(status) {
    return getActionCreator(types.SET_MODAL_CHANGE_CATEGORY_ERROR_OPEN, status);
  },
  setSendQuery(data) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_SEND_QUERY, data);
  },
  setItems(items) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_ITEMS, items);
  },
  setReload(data) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_RELOAD, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_SETTING_PENDING, settingPending);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_SELECTION, selection);
  },
  setAllProductsProductFeatureState(featureState) {
    return getActionCreator(types.SET_ALL_PRODUCTS_FEATURE_STATE, featureState);
  },
  setIsCategoriesListLoading(pending) {
    return getActionCreator(types.SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING, pending);
  },
  setActiveProductCategory(category) {
    return getActionCreator(types.SET_ACTIVE_PRODUCT_CATEGORY, category);
  },
  setProductCategoriesList(categoriesList) {
    return getActionCreator(types.SET_PRODUCT_CATEGORIES_LIST, categoriesList);
  },
  changeProductDataActiveEdit(activeEdit) {
    return getActionCreator(types.CHANGE_PRODUCT_DATA_ACTIVE_EDIT, activeEdit);
  },
  mergeContentProductValue(data) {
    return getActionCreator(types.MERGE_CONTENT_PRODUCT_STATUS_VALUE, data);
  },
  mergeContentProductStatusChangeLog(data) {
    return getActionCreator(types.MERGE_CONTENT_PRODUCT_LOG, data);
  },
  setChangeModalWidthCategoryOpen(status) {
    return getActionCreator(types.SET_MODAL_WIDTH_CATEGORY_OPEN, status);
  },
  setCategoryValueModalWidthCategory(data) {
    return getActionCreator(types.SET_MODAL_WIDTH_CATEGORY_VALUE, data);
  },
});
