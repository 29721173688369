// Core
import {
  useState, useEffect, useRef, useCallback,
} from 'react';
// Engine
import { filtersDelay } from '../../engine/config/globalConfig';

export function useDefferCall(args, delay = filtersDelay) {
  const { callback, initialRender = true, initialValue } = args;
  const [value, setValue] = useState(initialValue);
  const firstRender = useRef(initialRender);
  const callbackMemoized = useCallback(callback, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    let timer = null;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      callbackMemoized(value);
    }, delay);

    return () => clearTimeout(timer);
  }, [callbackMemoized, value]);

  return { value, setValue };
}
