// Core
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Parts
import ModalConfirm from '../../../../../../../../../containers/Modals/ModalConfirm';
// Engine
import { selectors } from '../../../../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../../../../engine/core/contentProduct/actions';

function CategoryErrorModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showModal = useSelector(selectors.contentProduct.modalChangeCategoryErrorOpen);
  const onCancel = () => dispatch(actions.setChangeCategoryErrorOpen(false));
  return (
    <ModalConfirm
      textAlign="center"
      cancelText="OK"
      onCancel={onCancel}
      hideButtons
      showModal={showModal}
      // actionButtonPosition="center"
      text={`${t("Изменить категорию можно только товарам, которые находятся в статусе 'Новый' / 'На доработке'. Для товаров в других статусах - изменение категории недоступно")}.`}
      title={t('Внимание')}
    />
  );
}

export default CategoryErrorModal;
