// Core
import React, { memo, useState } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
// Parts
import Box from '@mui/material/Box';
// Icons
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// Helpers
import { useStyles } from '../../../../../hooks/useStyles';
import { IconButtonStyles } from '../../Styles';
import EditTableCellWrapperStyle from './EditTableCellWrapperStyle';


const BaseComponentWithAction = memo((props) => {
  const { onClick, color, children } = props;
  return (
    onClick || color
      ? <Box component="div" onClick={onClick} sx={{ color }}>{children}</Box>
      : children
  );
});

const BaseComponentWithEdit = memo((props) => {
  const {
    onClick, color, children, visibilityIcon,
    className, required, success, isNotEllipsis, newDesignStyle,
  } = props;
  const [showIcon, setShowIcon] = useState(false);
  const classes = useStyles(EditTableCellWrapperStyle);
  const componentClasses = cx(classes.wrapper, {
    [className]: className,
    [classes.required]: required,
    [classes.success]: success,
    [classes.newDesign]: newDesignStyle,
  });
  const childrenHolderClasses = cx(classes.presentationText, {
    [classes.notEllipsis]: isNotEllipsis,
  });
  const canShowChild = (!Array.isArray(children) && Boolean(children))
    || (Array.isArray(children) && children.filter(Boolean).length);
  const onMouseEnter = () => setShowIcon(true);
  const onMouseLeave = () => setShowIcon(false);
  return (
    <Box
      component="div"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={{ color }}
      className={componentClasses}
    >
      {Boolean(canShowChild) && <div className={childrenHolderClasses}>{children}</div>}
      {showIcon && (
        <IconButtonStyles
          color="inherit"
          disabled
          sx={{
            padding: '5px',
            marginLeft: 'auto',
            width: '24px',
            height: '24px',
          }}
          size="large"
        >
          {visibilityIcon
            ? <RemoveRedEyeOutlinedIcon sx={{ p: '3px' }} />
            : <ModeOutlinedIcon sx={{ p: '3px' }} />
          }
        </IconButtonStyles>
      )}
    </Box>
  );
});

function EditTableCellWrapper(props) {
  const {
    children, className, required, off,
    success, visibilityIcon, onClick, color, isNotEllipsis, newDesignStyle,
  } = props;

  if (children === null && off) {
    return null;
  }

  return off
    ? (
      <BaseComponentWithAction
        onClick={onClick}
        color={color}
      >
        {children}
      </BaseComponentWithAction>
    )
    : (
      <BaseComponentWithEdit
        onClick={onClick}
        color={color}
        visibilityIcon={visibilityIcon}
        className={className}
        required={required}
        success={success}
        isNotEllipsis={isNotEllipsis}
        newDesignStyle={newDesignStyle}
      >
        {children}
      </BaseComponentWithEdit>
    );
}

EditTableCellWrapper.propTypes = {
  className: PropTypes.object,
  children: PropTypes.node,
  required: PropTypes.bool,
  off: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  success: PropTypes.bool,
  visibilityIcon: PropTypes.bool,
  isNotEllipsis: PropTypes.bool,
  newDesignStyle: PropTypes.bool,
};

EditTableCellWrapper.defaultProps = {
  visibilityIcon: false,
  required: false,
  off: false,
};

export default memo(EditTableCellWrapper);
