const PageHeaderStyles = theme => ({
  pageHeadingText: {
    marginRight: '15px',
    [theme.breakpoints.only('xl')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
    },
  },
});

export default PageHeaderStyles;
