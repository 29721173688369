// Core
import React, { useEffect, useMemo, useState } from 'react';
import { Form, reduxForm } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';
import { useParams } from 'react-router';
// Icons
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// UI
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import HeaderComponent from './components/HeaderComponent';
import { RowDetailCategories, ToggleCellCategoriesComponent } from './components/RowDetailCategories';
import TableCell from './components/TableCell';
import Switcher from '../../../../../../components/_Form/Switcher/Switcher.js';
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
import ResetFiltersButton from '../../../../../../components/_Table/DxTable/components/ResetFilterButton';
import HelpModalInitialLoadPriceListCharacteristics from './components/HelpModalInitialLoadPriceListСharacteristics';
import ButtonWithIconAndCircularProgress from '../../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions } from '../../../../../../../engine/core/products/productFeedCategoriesAttributes/saga/asyncActions';
import { asyncActions as asyncActionsFeeds } from '../../../../../../../engine/core/feeds/all/saga/asyncActions';
import { asyncActions as asyncActionsProductsFeeds } from '../../../../../../../engine/core/feeds/products/saga/asyncActions';
import { initialState, stateKeys } from '../../../../../../../engine/core/products/productFeedCategoriesAttributes/reducer';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/products/productFeedCategoriesAttributes/actions';
import { userRoles } from '../../../../../../../engine/config/userRoles';
import { locale } from '../../../../../../../engine/init/i18n';
import { accessList } from '../../../../../../../engine/config/access';
// Helpers
import { completionPriceListModalConfig } from '../ProductFeedParametersTable/ProductFeedParametersTable';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';
import { useProductFeedCategoriesAttributesList } from './_hooks/useProductFeedCategoriesAttributesList';
import { dispatchCompletionListItemData } from '../../_helpers/dispatchCompletionListItemData';
import TooltipCantFinish from '../TooltipCantFinish';
import CompleteImportModal from '../CompleteImportModal';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';
import { useStyles } from '../../../../../../hooks/useStyles';

import { CategoriesAttributesStyles } from './styles';

export const sortingColumnExtensions = [
  {
    columnName: tableDataTypes.feedAttributesQuantity.name,
    sortingEnabled: false,
  },
];

export const formName = 'productFeed_step2';
function ProductFeedCategoriesAttributesTable(props) {
  const isItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const {
    feedStatusData, canChangePartnerEdit,
    partnerEditEnabled, canEdit, canParse,
  } = isItemByHashIdItem.toJS();
  const canSendHooks = isItemByHashIdLoading === false && feedStatusData === undefined;
  useProductFeedCategoriesAttributesList(canSendHooks);
  const { handleSubmit, handleBackStep, disable } = props;
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles(CategoriesAttributesStyles);
  const isLoading = useSelector(selectors.productFeedCategoriesAttributes.isLoading);
  const autoConnectPending = useSelector(selectors.productFeedCategoriesAttributes.autoConnectPending);
  const inProcessAutoConnect = autoConnectPending || !canParse;
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const { i18n: { language }, t } = useTranslation();
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerPaymentWaiting,
  ]);
  const hasAccessProductFeedAutoConnect = useAccessList(accessList.productFeedAutoConnect);
  const completionImportList = useSelector(selectors.feeds.completionImportList)?.toJS();
  const stepKey = completionPriceListModalConfig.steps.step_3;
  const isEditMode = completionImportList[hashId]?.isSaved;
  const modalTypeKey = completionPriceListModalConfig.type.import;

  // Rows
  const rows = useSelector(selectors.productFeedCategoriesAttributes.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const grid = useMemo(() => {
    const lang = locale[language];
    return gridSettings.productFeedAttributes.gridSettings.map(({ width, ...item }) => {
      let newWidth;
      if (item.name === 'productFeedCategory') {
        newWidth = '47%';
      } else if (item.name === 'attributeStatus') {
        newWidth = 250;
      }
      return ({
        ...item,
        closeMenuOnScroll: true,
        width: newWidth, // TODO set from back-end
        title: item.lang?.[lang],
      });
    });
  }, [gridSettings, language]);
  const columns = values(grid);
  const currentPage = useSelector(selectors.productFeedCategoriesAttributes.currentPage);
  const pageSize = useSelector(selectors.productFeedCategoriesAttributes.pageSize);
  const totalCount = useSelector(selectors.productFeedCategoriesAttributes.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.productFeedCategoriesAttributes.sorting);
  // Settings
  const settingStatus = useSelector(selectors.productFeedCategoriesAttributes.settingStatus);
  const settingPending = useSelector(selectors.productFeedCategoriesAttributes.settingPending);
  // Filtering
  const filters = useSelector(selectors.productFeedCategoriesAttributes.filters);
  const isDisabledResetButton = !(filters.size || sorting.size);

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.productFeedCategoriesAttributesList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.productFeedCategoriesAttributesList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.productFeedCategoriesAttributesList).get(stateKeys.currentPage)));
  };
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Expanded
  const onExpandedRowIdsChange = ids => dispatch(actions.setCategoriesExpendedRowIds(ids));
  const expandedRowIds = useSelector(selectors.productFeedCategoriesAttributes.categoriesExpandedRowIds);

  const onSubmit = () => {
    dispatch(asyncActionsFeeds.putCompletionImportListItemAsync({ hashId, data: { isSaved: true } }));
    dispatch(asyncActionsProductsFeeds.postParseByHashIdAsync({ hashId }));
  };

  const handleAutoConnect = () => {
    dispatch(asyncActions.postAutoConnectAsync({ hashId }));
  };

  const onChangePartnerEditStatus = (event) => {
    dispatch(asyncActionsFeeds.putFeedItemByHashId({
      hashId,
      partnerEditEnabled: event.target.checked === true ? 0 : 1,
    }));
  };

  const showConfirmModal = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => () => {
    dispatch(actions.setCategoriesExpendedRowIds([]));
  }, []);

  useEffect(() => {
    // import mode
    if (isPartnerRoles && !isEditMode && !completionImportList?.[hashId]?.[modalTypeKey]?.[stepKey]?.isOpened) {
      dispatchCompletionListItemData({
        dispatch,
        hashId,
        completionImportList,
        modalTypeKey,
        stepKey,
        value: true,
        isOpened: false,
        isSaved: false,
      });
    }
  }, []);

  return (
    <>
      <Box sx={{ textAlign: 'right', mb: 1 }}>
        <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
        {hasAccessProductFeedAutoConnect && (
          <ButtonWithIconAndCircularProgress
            text={t('Автосопоставление характеристик')}
            disabled={inProcessAutoConnect}
            isLoading={autoConnectPending}
            onClick={handleAutoConnect}
            sx={{ marginLeft: '18px' }}
          >
            <AutoFixHighIcon />
          </ButtonWithIconAndCircularProgress>
        )}
        {canChangePartnerEdit && (
          <Tooltip title={t('Блокировка параметров')}>
            <Switcher
              defaultChecked={!partnerEditEnabled}
              onChange={onChangePartnerEditStatus}
              sx={{ marginLeft: '18px' }}
            />
          </Tooltip>
        )}
      </Box>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <DxTable
          type="static"
          name="productFeedCategoriesAttributes"
          customCellComponent={TableCell}
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          // Paging
          pageSize={pageSize}
          totalCount={totalCount}
          onPageSize={onPageSize}
          currentPage={currentPage}
          onCurrentPage={onCurrentPage}
          // Sorting
          onSortingChange={onSortingChange}
          sortingStateColumnExtensions={sortingColumnExtensions}
          sorting={sorting}
          // Filters
          filters={filters}
          filtersAction={actions.setFilters}
          // Settings
          settingStatus={settingStatus}
          settingPending={settingPending}
          settingAction={asyncActions.putSettingsAsync}
          // Header
          customHeaderCellComponent={HeaderComponent}
          // Detail
          rowDetailComponent={RowDetailCategories}
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={onExpandedRowIdsChange}
          toggleCellComponent={ToggleCellCategoriesComponent}
          screenHeightFull
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
        }}
        >
          <LoadingButton
            variant="outlined"
            onClick={handleBackStep}
            sx={{ mr: 1 }}
            disabled={disable}
            loading={disable}
          >
            {t('Назад')}
          </LoadingButton>
          <TooltipCantFinish>
            <LoadingButton
              variant="contained"
              sx={{ mr: 1 }}
              disabled={disable || !canEdit || autoConnectPending || inProcessAutoConnect}
              loading={disable}
              onClick={showConfirmModal}
            >
              {t('Завершить импорт')}
            </LoadingButton>
          </TooltipCantFinish>
        </Box>
      </Form>
      { isPartnerRoles && !isEditMode ? (<HelpModalInitialLoadPriceListCharacteristics />) : null}
      { isShowConfirmModal ? (
        <CompleteImportModal
          showModal
          setShowConfirmModal={setShowConfirmModal}
          onConfirm={handleSubmit(onSubmit)}
          disable={disable}
        />
      ) : null}
    </>
  );
}

export default reduxForm({
  form: formName,
})(ProductFeedCategoriesAttributesTable);
