// Core
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { change, Field } from 'redux-form/immutable';
// Parts
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import Divider from '@mui/material/Divider';
import { selectors } from '../../../../../../engine/config/selectors';
import { monobankOptions, privatbankOptions } from '../utils';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import { asyncActions } from '../../../../../../engine/core/bankInstallments/saga/asyncActions';
import HistoryButton from '../../../../../components/HistoryButton/HistoryButton';
import { COLOR } from '../../../../../containers/App/AppStyles';
import { usePartnerRole } from '../../../../../hooks/usePartnerRole';
import { dispatchActionsForChildren } from '../utils/dispatchActionsForChildren';
import { PRIVATBANK_INSTALLMENTS_PAYMENT_METHOD } from '../../../../PartnersAccess/constants/paymentMethods';


function TableCell(props) {
  const {
    column,
    row,
    value,
    isShowMode,
    paymentMethod,
    ...restProps
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hashId } = useParams();
  const bankInstallments = useSelector(selectors.bankInstallments.list);
  const formValues = useSelector(state => selectors.form.getFormValues(state, 'bankInstallments'));
  const bankInstallmentsLogs = useSelector(selectors.bankInstallments.logsList);
  const [showPopover, setShowPopover] = useState(false);
  const isPartner = usePartnerRole();
  const options = paymentMethod === PRIVATBANK_INSTALLMENTS_PAYMENT_METHOD ? privatbankOptions : monobankOptions;


  const handleChange = useCallback((selected) => {
    dispatch(change('bankInstallments', `${row.categoryId}_paymentCount`, selected.value));
    dispatchActionsForChildren(bankInstallments, row.categoryId, dispatch, selected);
  }, [row, bankInstallments]);
  const currentValue = formValues.toJS()[`${row.categoryId}_paymentCount`] ?? value;

  const currentLabel = useMemo(() => {
    const filteredArray = options.filter(opt => opt.value <= currentValue?.value).map(o => o.value).sort((a, b) => b - a);
    if (filteredArray.length > 1) {
      return filteredArray.filter(v => v !== 0).toString();
    }

    return filteredArray.toString();
  }, [options, currentValue]);

  const handleOnClick = () => {
    dispatch(asyncActions.getLogsAsync({ hashId, categoryId: row.categoryId }));
    setShowPopover(true);
  };
  const handleClose = () => setShowPopover(false);

  const logs = useMemo(() => bankInstallmentsLogs.toJS(), [bankInstallmentsLogs]);

  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === 'idMagentoCategory' && <div>{row.idMagentoCategory}</div>}
      {column.name === 'name' && <div>{row.name}</div> }
      {column.name === 'paymentCount' && (
      <Field
        options={options}
        handleChange={handleChange}
        variant="outlined"
        placeholder={t('Количество платежей')}
        component={Select}
        disabled={!isPartner || !isShowMode}
        props={{ value: currentValue.value ? { value: currentValue.value, label: currentLabel } : undefined }}
        name={`${row.categoryId}_paymentCount`}
      />
      )}
      {column.name === 'installmentsHistory' && (
      <HistoryButton
        hashId={row.hashId}
        hasHistory={row?.hasHistory}
        canShow
        titleTooltip={t('История изменений')}
        showPopover={showPopover}
        handleClick={handleOnClick}
        handleClose={handleClose}
        currentHashId={row.hashId}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {logs.map((log, index) => (
            <React.Fragment key={log.id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ fontSize: '14px' }}>{log.createdAt}</Box>{index === 0 && (
                <Box sx={{
                  p: '2px 5px 2px 5px', borderRadius: '3px', backgroundColor: COLOR.success, color: '#FFFFFF', fontSize: '12px',
                }}
                >{t('Актуальный')}
                </Box>
                )}
              </Box>
              <Box sx={{
                marginTop: '16px', marginBottom: '16px', lineHeight: '14px', fontSize: '14px',
              }}
              >{log.userName}
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '14px',
              }}
              ><Box sx={{ fontSize: '13px', lineHeight: '13px', color: COLOR.black[72] }}>{t('Количество платежей')}:</Box>
                <Box sx={{ fontSize: '14px' }}>{log.paymentCount}</Box>
              </Box>
              {index !== logs.length - 1 && <Divider sx={{ marginBottom: '14px' }} />}
            </React.Fragment>
          ))}

        </Box>
      </HistoryButton>
      )}
    </VirtualTable.Cell>
  );
}

export default TableCell;
