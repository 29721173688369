// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function FaqIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 18 18">
      <circle cx="9" cy="9" r="8.25" stroke="#F97988" strokeWidth="1.5" fill="transparent" />
      <path d="M6 6.716C6.06533 5.89467 6.38733 5.23667 6.966 4.742C7.54467 4.24733 8.27733 4 9.164 4C10.0693 4 10.8253 4.252 11.432 4.756C12.0387 5.25067 12.342 5.87133 12.342 6.618C12.342 7.25267 12.0573 7.85467 11.488 8.424L11.152 8.732C11.012 8.86267 10.8813 8.97933 10.76 9.082C10.34 9.446 10.074 9.73533 9.962 9.95C9.85 10.1647 9.794 10.4913 9.794 10.93V11.126H8.45V10.72C8.45 9.78667 8.912 8.928 9.836 8.144C10.5453 7.56533 10.9 7.06133 10.9 6.632C10.9 6.212 10.7367 5.85733 10.41 5.568C10.0833 5.288 9.67733 5.148 9.192 5.148C8.212 5.148 7.61 5.72667 7.386 6.884L6 6.716ZM8.422 11.938H9.948V13.464H8.422V11.938Z" fill="#F97988" />
    </SvgIcon>
  );
}

export default FaqIcon;
