import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';

export function BlockIconWrapper(props) {
  const {
    showIcon, children, valuesPlain, disabled,
  } = props;
  return showIcon
    ? (
      <>
        {disabled ? (
          <Box sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          >
            {valuesPlain}
          </Box>
        ) : children}
        <LockOutlinedIcon
          sx={{
            position: 'absolute',
            left: '2px',
            top: '2px',
            color: 'rgba(0, 0, 0, 0.42)',
            height: '12px',
            width: '12px',
            borderRadius: '50%',
          }}
        />
      </>
    )
    : children;
}
