import { getIn, List } from 'immutable';
import { stateKeys as transactionHistoryKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const transactionHistory = Object.freeze({
  currentPage: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.currentPage], 0),
  filters: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.filters], List()),
  isLoading: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.pending], false),
  list: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.items], List()),
  pageSize: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.reload], false),
  totalCount: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.totalCount], 0),
  sorting: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.sorting], List()),
  settingStatus: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.settingStatus], false),
  settingPending: state => getIn(state, [transactionHistoryKeys.controller, transactionHistoryKeys.transactionHistoryList, transactionHistoryKeys.settingPending], false),
});
