// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { billingActions } from '../../action';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callGetBillingReportDownload({ payload }) {
  yield put(billingActions.mergeBillingReportDownload({
    pending: true,
  }));

  const response = yield apply(api, api.billing.getBillingReportDownload, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, message } = responseData;
        yield put(setErrorMessage(errors || message, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      default: {
        getFileFromBlob('billing_report.xls', response);
        break;
      }
    }
  }

  yield put(billingActions.mergeBillingReportDownload({
    pending: false,
  }));
}
