// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import DeleteIcons from '@mui/icons-material/Delete';
// Parts
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions as messagesAsyncActions } from '../../../../../engine/core/messages/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import { useAccessList } from '../../../../hooks/useAccessList';

function MessageDeleteButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const messageByHashId = useSelector(selectors.messages.messageByHashId);
  const isSendDeleteRequest = useSelector(selectors.messages.messageDeleteRequestState);
  const isSendSendRequest = useSelector(selectors.messages.messageSendRequestState);
  const isSendSaveRequest = useSelector(selectors.messages.messageSaveRequestState);
  const hasAccessMessageDelete = useAccessList(accessList.messageDelete);

  const deleteMessage = () => {
    const hashId = messageByHashId.toJS().hashId;
    if (typeof hashId === 'string' && hashId !== '') {
      dispatch(messagesAsyncActions.deleteMessageByHashIdAsync({ hashId }));
    }
  };

  if (!hasAccessMessageDelete) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      disabled={isSendSendRequest || isSendSaveRequest}
      text={t('Удалить')}
      onClick={deleteMessage}
      isLoading={isSendDeleteRequest}
    >
      <DeleteIcons />
    </ButtonWithIconAndCircularProgress>
  );
}
export default MessageDeleteButton;
