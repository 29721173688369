import { COLOR } from '../../../../containers/App/AppStyles';

export const SupportButtonStyles = () => ({
  link: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    color: COLOR.darkStyle.fontColorMain,
    textDecoration: 'none',
  },
  supportButtonsMenu: {
    zIndex: 301,
    '& .MuiMenu-paper': {
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      background: COLOR.darkStyle.main,
    },
    '& .MuiMenu-list': {
      padding: '8px',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  supportMenu: {
    zIndex: 301,
  },
  supportMenuButtonActive: {
    background: COLOR.darkStyle.white['8'],
  },
  supportMenuItem: {
    padding: 0,
    borderRadius: '5px',
    '& .MuiListItemIcon-root': {
      minWidth: '16px',
      marginRight: '6px',
      color: COLOR.darkStyle.fontColorMain,
    },
    '&:hover': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  row: {
    minHeight: '40px',
    padding: '4px 8px',
  },
  supportItemInfo: {
    color: COLOR.darkStyle.white['72'],
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '30px',
    minHeight: '40px',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  supportMenuButton: {
    fontSize: '14px',
    lineHeight: '14px',
    color: COLOR.darkStyle.fontColorMain,
    borderRadius: '5px',
    padding: '8px',
    marginRight: 'auto',
    marginLeft: '12px',
    '&:hover, &:active, &:focus': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  supportMenuDropIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '6px',
  },
  supportMenuDropIconActive: {
    transform: 'rotate(180deg)',
  },
  supportMenuIcon: {
    width: '24px',
    height: '24px',
    marginRight: '4px',
  },
  supportMenuBox: {
    display: 'flex',
    alignItems: 'center',
  },
  hideMobile: {
    display: 'none',
  },
});
