// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_CATEGORIES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_CATEGORIES_CURRENT_PAGE, currentPage);
  },
  setReload(currentPage) {
    return getActionCreator(types.SET_CATEGORIES_RELOAD, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_CATEGORIES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_CATEGORIES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_CATEGORIES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_CATEGORIES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_CATEGORIES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_CATEGORIES_SETTING_PENDING, settingPending);
  },
  setCategoriesListContext(categoriesList) {
    return getActionCreator(types.SET_CATEGORIES_LIST_CONTEXT, categoriesList);
  },
  setCategoryItem(categoryItem) {
    return getActionCreator(types.SET_CATEGORIES_ITEM, categoryItem);
  },
  setCommissionsUploadIsOpen(isOpen) {
    return getActionCreator(types.SET_CATEGORIES_COMMISSIONS_UPLOAD_IS_OPEN, isOpen);
  },
  setCommissionsUploadUploading(pending) {
    return getActionCreator(types.SET_CATEGORIES_COMMISSIONS_UPLOAD_UPLOADING, pending);
  },
  setCategoriesTreeDataRowIds(ids) {
    return getActionCreator(types.SET_CATEGORIES_TREE_DATA_ROW_IDS, ids);
  },
});
