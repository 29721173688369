import { getIn, List } from 'immutable';
import { stateKeys as partnersManagersKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const partnersManagers = Object.freeze({
  currentPage: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.currentPage], 0),
  filters: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.filters], List()),
  isLoading: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.pending], false),
  list: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.items], List()),
  pageSize: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.reload], false),
  totalCount: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.totalCount], 0),
  sorting: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.sorting], List()),
  settingStatus: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.settingStatus], false),
  settingPending: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.settingPending], false),
  partnerManagerPending: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnerManager, partnersManagersKeys.pending], false),
  partnerManagerModalIsOpen: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersList, partnersManagersKeys.isOpen], false),
  partnerManagerUploadIsPending: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnersManagersUpload, partnersManagersKeys.pending], false),
  partnerManagerDownloadPending: state => getIn(state, [partnersManagersKeys.controller, partnersManagersKeys.partnerManagerDownloadPending], false),
});
