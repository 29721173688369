import { COLOR } from '../../../containers/App/AppStyles';

const FilesUploaderStyle = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  description: {
    fontSize: 12,
    lineHeight: '16px',
    color: COLOR.grey['54'],
    marginTop: 11,
    marginBottom: 11,
  },
  wrapperRelative: {
    position: 'relative',
    '@global': {
      '.filepond--file-action-button': {
        cursor: 'pointer !important',
      },
      '.filepond--file-status': {
        alignItems: 'flex-start',
      },
    },
  },
  wrapperFileUploader: {
    flexGrow: 1,
  },
  wrapperFileUploaderOffRemove: {
    '@global': {
      '.filepond--action-remove-item': {
        display: 'none',
      },
      '.filepond--file-info': {
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
  loader: {
    top: 17.5,
    left: 18,
    zIndex: 1,
    position: 'absolute',
  },
  compareImage: {
    border: `2px solid ${COLOR.controls.dark}`,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    minHeight: '80px',
    maxWidth: '320px',
    textAlign: 'center',
    padding: 16,
    '@global': {
      img: {
        display: 'block',
        maxWidth: '100%',
        margin: 'auto',
      },
    },
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    borderRadius: 3,
    padding: 2,
    boxShadow: COLOR.shadow.block.main,
    marginLeft: '16px',
    background: '#ffffff',
  },
  button: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 3,
    background: COLOR.grey['8'],
    marginBottom: 4,
    '& svg': {
      width: 21,
      height: 21,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      background: COLOR.grey['12'],
    },
  },
  compareButton: {
    color: COLOR.controls.dark,
  },
  compareButtonActive: {
    background: COLOR.controls.dark,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.controls.dark,
      color: '#ffffff',
    },
  },
  checkButton: {
    color: COLOR.success,
  },
  checkButtonActive: {
    background: COLOR.success,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.success,
      color: '#ffffff',
    },
  },
  cancelButton: {
    color: COLOR.error,
  },
  cancelButtonActive: {
    background: COLOR.error,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.error,
      color: '#ffffff',
    },
  },
  imagePreview: {
    boxSizing: 'border-box',
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 100,
    maxHeight: 78,
    padding: '.5em',
    pointerEvents: 'none',

    '& + $pondImageHolder': {
      '& .filepond--drop-label': {
        '&:before': {
          top: '-100%',
          right: '-100%',
          bottom: '-100%',
          left: '-100%',
          content: '""',
          position: 'absolute',
        },
      },
    },
  },
  imagePreviewImage: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  filePondContainer: {
    alignItems: 'center',
    borderRadius: '0.5em',
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    maxWidth: '320px',
    backgroundColor: '#ffffff',
    border: `1px dashed ${COLOR.controls.dark}`,
    overflow: 'hidden',
    '&:hover': {
      borderStyle: 'solid',
      outline: `1px solid ${COLOR.controls.dark}`,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '280px',
    },
  },
  filePondActiveLoader: {
    borderStyle: 'solid',
  },
  pondImageHolder: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    '& .filepond--credits': {
      display: 'none',
    },
    '& .filepond--root': {
      padding: '18px 5px 0',
      '& .filepond--browser': {
        cursor: 'pointer',
      },
    },
    '& .filepond--root .filepond--drop-label': {
      minHeight: '78px',
      top: '10px',
    },
    '& .filepond--panel.filepond--item-panel': {
      display: 'none',
    },
    '& .filepond--panel-top': {
      backgroundColor: 'transparent',
    },
    '& .filepond--panel-center': {
      backgroundColor: 'transparent',
    },
    '& .filepond--panel-bottom': {
      backgroundColor: 'transparent',
    },
    '& .filepond--drop-label.filepond--drop-label label': {
      padding: 0,
      color: COLOR.controls.dark,
      fontSize: '12px',
      lineHeight: '16px',
      cursor: 'pointer',
      '& .filepond--label-action': {
        textDecorationColor: 'inherit',
      },
    },
    '& .filepond--file': {
      borderRadius: '5px',
      padding: 0,
      display: 'block',
    },
    '& .filepond--file-wrapper': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    '& .filepond--file-info': {
      alignItems: 'center',
      transform: 'none !important',
      textAlign: 'center',
      '& .filepond--file-info-main': {
        color: 'rgba(12, 12, 12, 0.94)',
        height: '24px',
        lineHeight: '24px',
      },
    },
    '& .filepond--file-info .filepond--file-info-sub': {
      color: 'rgba(12, 12, 12, 0.94)',
      display: 'none',
    },
    '& .filepond--list': {
      left: '50%',
      right: 'auto',
      transform: 'translateX(calc(-50% - 17px))',
      width: '196px',
      [theme.breakpoints.down('sm')]: {
        width: '176px',
      },
      '& .filepond--item': {
        background: '#E6E6E6',
        borderRadius: '5px',
        width: '196px',
        '&[data-filepond-item-state*=invalid]': {
          background: COLOR.error,
        },
        '&[data-filepond-item-state*=error]': {
          background: COLOR.error,
        },
        [theme.breakpoints.down('sm')]: {
          width: '176px',
        },
      },
    },
    '& .filepond--root .filepond--list-scroller': {
      marginTop: '28px',
    },
    '& .filepond--file-action-button.filepond--action-remove-item': {
      left: 'calc(100% + 6px)',
      borderRadius: '5px',
      background: COLOR.error,
      color: '#ffffff',
      fill: '#ffffff',
      width: '24px',
      height: '24px',
    },
    '& .filepond--file-action-button.filepond--action-abort-item-load': {
      position: 'absolute',
      right: '4px',
      top: 'calc(50% - 13px)',
    },
    '& .filepond--file-action-button.filepond--action-retry-item-load': {
      position: 'absolute',
      right: '4px',
      top: 'calc(50% - 13px)',
    },
    '& .filepond--processing-complete-indicator': {
      display: 'none',
    },
    '& .filepond--file-status': {
      transform: 'none !important',
      left: 0,
      top: '100%',
      color: COLOR.error,
      position: 'absolute',
    },
  },
  disabled: {
    position: 'relative',
    '&::before': {
      content: '""',
      cursor: 'default',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      position: 'absolute',
      zIndex: 10,
    },
  },
  pondImage: {
    width: '24px',
    height: '24px',
    display: 'block',
    position: 'absolute',
    left: 'calc(50% - 12px)',
    top: '10px',
  },
});

export default FilesUploaderStyle;
