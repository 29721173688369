import { COLOR } from '../../../../containers/App/AppStyles';

export const FormSelectStyle = theme => ({
  formSelect: {
    flexDirection: 'column',
  },
  formSelectContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  helperText: {
    margin: 0,
  },
  helperTextRequired: {
    lineHeight: 'inherit',
    fontSize: 'inherit',
    width: 11,
    color: COLOR.error,
  },
  formSelectWrapper: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  wrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  wrapperFullWidth: {
    width: '100%',
  },
  hasError: {
    color: COLOR.error,
    marginLeft: 0,
  },
  wrapperButton: {
    display: 'flex',
    alignSelf: 'center',
    borderRadius: 3,
    padding: 2,
    boxShadow: COLOR.shadow.block.main,
    marginLeft: 8,
    background: '#ffffff',
  },
  button: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 3,
    background: COLOR.grey['8'],
    marginLeft: 4,
    '& svg': {
      width: 21,
      height: 21,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      background: COLOR.grey['12'],
    },
  },
  compareButton: {
    color: COLOR.controls.dark,
  },
  compareButtonActive: {
    background: COLOR.controls.dark,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.controls.dark,
      color: '#ffffff',
    },
  },
  checkButton: {
    color: COLOR.success,
  },
  checkButtonActive: {
    background: COLOR.success,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.success,
      color: '#ffffff',
    },
  },
  cancelButton: {
    color: COLOR.error,
  },
  cancelButtonActive: {
    background: COLOR.error,
    color: '#ffffff',
    '&:hover': {
      background: COLOR.error,
      color: '#ffffff',
    },
  },
  hidden: {
    display: 'none',
  },
  paperDarkStyle: {
    background: `${COLOR.darkStyle.main}`,
    boxShadow: '0px 0px 12px rgba(82, 133, 204, 0.12)',
    borderRadius: '8px',
    marginTop: '2px',
    '& .MuiList-root': {
      padding: '8px',
    },
  },
});
