// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Field, reduxForm,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
// parts
import FilesUploaderWithValidation from '../../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import Modal from '../../../../../containers/Modals/Modal/Modal';
// actions
import { actions } from '../../../../../../engine/core/stopList/BrandsCategories/actions';
import { asyncActions } from '../../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
// _helpers
import { validators } from '../../../../../../_helpers/validationsForm';
// config
import { selectors } from '../../../../../../engine/config/selectors';
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';

import { formName, form } from './form';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';

// Component
function StopCategoryWordModal(props) {
  const {
    handleSubmit, submitting, pending, pristine,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const brandsCategoriesUploadModalIsOpen = useSelector(selectors.stopListBrandsCategories.uploadIsModalOpen);
  const brandsCategoriesUploadPending = useSelector(selectors.stopListBrandsCategories.uploadPending);
  const hasAccessUpload = useAccessList(accessList.stopBrandsCategoriesUpload);

  const handleModalToggle = () => {
    dispatch(actions.mergeUpload({ isModalOpen: !brandsCategoriesUploadModalIsOpen }));
  };

  const handleSendForm = () => dispatch(submit(formName));

  const onFormSubmit = (formValues) => {
    const file = formValues.get(form.fields.stopBrandsCategoriesImportFile);
    dispatch(asyncActions.postUploadAsync(file));
  };

  if (!hasAccessUpload) {
    return null;
  }

  return (
    <Modal
      modalOpen={brandsCategoriesUploadModalIsOpen}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      modalMaxWidth="xs"
      handleModalAdd={handleSendForm}
      disabledSend={pristine || submitting}
      loadingSend={pending || brandsCategoriesUploadPending}
      title={t('Импорт стоп брендов-категорий')}
      subTitleAttentionText={t('Внимание!')}
      subTitle={t('Перед импортом будут удалены все текущие Бренды и Категории и добавлены из файла импорта.')}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={FilesUploaderWithValidation}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.stopBrandsCategoriesImportFile}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

StopCategoryWordModal.displayName = 'stopBrandsCategoriesUploadForm';

StopCategoryWordModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
};

StopCategoryWordModal.defaultProps = {
  submitting: false,
  pending: false,
};

export default reduxForm({
  form: formName,
})(memo(StopCategoryWordModal));
