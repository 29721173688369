// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
// Helpers
import { useValuesByType } from './useValuesByType';

export function useRecipientsImportItems(index) {
  const recipientsImportItems = useSelector(selectors.messages.recipientsImportItems);
  const { valuesByType } = useValuesByType(index);

  const currentPartnersNotFound = useMemo(() => {
    const importItems = recipientsImportItems.toJS();
    if (!isEmpty(importItems)) {
      if (importItems && !isEmpty(importItems.itemsNotFound)) {
        return importItems.itemsNotFound;
      }
    }

    return [];
  }, [recipientsImportItems]);

  const currentPartnersFound = useMemo(() => {
    const importItems = recipientsImportItems.toJS();
    if (!isEmpty(importItems) || valuesByType) {
      const itemsFound = !isEmpty(importItems.itemsFound) ? importItems.itemsFound : [];
      const values = valuesByType || [];

      return uniqBy([
        ...values,
        ...itemsFound,
      ], 'value');
    }

    return [];
  }, [recipientsImportItems, valuesByType]);

  return { currentPartnersNotFound, currentPartnersFound };
}
