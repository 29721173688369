// Core
import {
  apply, delay, put, select,
} from 'redux-saga/effects';
// Engine
import { asyncActions } from '../asyncActions';
import { api, requestStatus } from '../../../../../config/api';
import i18n from '../../../../../init/i18n';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../../config/selectors';
import { actions } from '../../actions';
import { tableDataTypes } from '../../../../../../_helpers/data/tableDataTypes';


export function* callPutCategoriesFeaturesItemByHashIdWorker({ payload }) {
  yield put(actions.setList({ pending: true }));
  const { result, hashId } = payload;

  let response;

  const currentPage = yield select(selectors.categoriesFeaturesItem.currentPage);
  const filters = yield select(selectors.categoriesFeaturesItem.filters);
  const pageSize = yield select(selectors.categoriesFeaturesItem.pageSize);
  const sorting = yield select(selectors.categoriesFeaturesItem.sorting);
  const normalizeSorting = sorting.toJS().map((item) => {
    if (item.columnName === tableDataTypes.featureRequiredLkValue.name) {
      return {
        ...item,
        columnName: tableDataTypes.featureRequiredLk.name,
      };
    }
    return item;
  });


  try {
    response = yield apply(api, api.categoriesFeatureManagement.putCategoriesFeatureItemByHashId, [result]);
  } catch (err) {
    yield put(setErrorMessage([{ message: err.message }], i18n.t('Ошибка')));
  }

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(actions.setList({ pending: false }));
        break;
      }
      case requestStatus.success: {
        const { message } = response.data;

        const params = {
          hashId,
          limit: pageSize,
          offset: pageSize * currentPage,
          sorting: normalizeSorting,
          clearList: true,
        };

        filters.toJS().reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);

        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(asyncActions.getListAsync(params));
        break;
      }
      default: {
        break;
      }
    }
  }
}
