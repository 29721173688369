const ContentTemplatesTableStyles = theme => ({
  positionRelative: {
    flexGrow: 1,
    minHeight: 325,
    position: 'relative',
  },
  buttonWrapper: {
    '@global': {
      button: {
        marginTop: 4,
        marginBottom: 4,
        marginRight: 12,
      },
    },
    '& button.MuiButtonBase-root': {
      [theme.breakpoints.down('sm')]: {
        padding: '9px 12px',
      },
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        padding: '6px 12px',
        minWidth: '200px',
        '& .MuiSvgIcon-root': {
          fontSize: '18px',
        },
      },
    },
  },
});

export default ContentTemplatesTableStyles;
