// Core
import { apply, put } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';

// Instruments
import { api } from '../../../../config/api';
import { userActionAsync } from '../asyncAction';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callConfirmCodeWorker({ payload: confirmCode }) {
  const response = yield apply(api, api.user.confirmCode, [{ code: confirmCode }]);
  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;
      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          yield put(userActionAsync.getUserInfoAsync({ rememberCheckbox: true, checkUserStatus: true }));
          TagManager.dataLayer({ dataLayer: { event: 'sign_up' } });
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
