import Box from '@mui/material/Box';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Informer from '../../../../components/Informer/Informer';
import { COLOR } from '../../../../containers/App/AppStyles';
import { selectors } from '../../../../../engine/config/selectors';

const WarningImportInformer = () => {
  const { t } = useTranslation();
  const { feeds } = useSelector(selectors.ui.popup).toJS();
  const infoLink = 'https://partner.allo.ua/products/info';

  return (
    <>
      {feeds ? (
        <Box sx={{ margin: '16px 0px' }}>
          <Informer
            sx={{
              border: `1px solid ${COLOR.error}`, borderLeftWidth: '4px', display: 'flex',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorIcon sx={{ color: COLOR.error, marginRight: '8px' }} />
              <span>{t('price lists not finished')}&nbsp;<strong>{feeds}</strong><br />
                {t('finish import for mentioned price-lists')}
              </span>
              <Link href={infoLink} target="_blank"><HelpOutline sx={{ marginLeft: '8px' }} /></Link>
            </Box>
          </Informer>
        </Box>
      ) : null}
    </>
  );
};

export default WarningImportInformer;
