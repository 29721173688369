// Core
import { memo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// config
import { selectors } from '../../../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../../../engine/config/routes';

// hooks
import { useStyles } from '../../../../../../hooks/useStyles';

// styles
import UserNotificationsMessageStyle from './UserNotificationsMessageStyle';

function UserNotificationsMessage() {
  const classes = useStyles(UserNotificationsMessageStyle);
  const userNotificationsMessage = useSelector(selectors.user.userNotificationsMessage);
  const pathname = useSelector(selectors.router.pathname);
  const userNotificationsMessageToJS = userNotificationsMessage.toJS();
  const renderHtml = content => ({ __html: content });

  return (pathname === pageLinks.dashboard.all && !isEmpty(userNotificationsMessageToJS)) && userNotificationsMessageToJS.map(item => (
    <Grid container justifyContent="center" alignItems="center" key={item.title}>
      <Grid item xs={12} className={classes.container}>
        <Typography variant="h3" align="center" className={classes.heading}>
          {item.title}
        </Typography>
        <Typography variant="body1" className={classes.description} component="div" align="center">
          <div dangerouslySetInnerHTML={renderHtml(item.description)} />
        </Typography>
      </Grid>
    </Grid>
  ));
}

export default memo(UserNotificationsMessage);
