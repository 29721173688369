// Core
import { LOCATION_CHANGE } from 'redux-first-history';
import { types as typesUI } from '../ui/types';

const initialState = [];

export function locationsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOCATION_CHANGE: {
      return [...state, payload];
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
