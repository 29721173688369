// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// Components
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import Label from '../../../../components/Label';

function FormFieldInput(formFieldRenderProps) {
  const { required, label, ...props } = formFieldRenderProps;
  return (
    <TextField
      {...props}
      fullWidth
      label={<Label label={label} required={required} />}
      margin="normal"
    />
  );
}

FormFieldInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormFieldInput.defaultProps = {
  required: false,
};

export default FormFieldInput;
