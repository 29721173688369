import { getIn, List } from 'immutable';
import { stateKeys as billingRatingRatioKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const billingRatingRatio = Object.freeze({
  currentPage: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.currentPage], 0),
  filters: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.filters], List()),
  isLoading: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.pending], false),
  list: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.items], List()),
  pageSize: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.reload], false),
  totalCount: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.totalCount], 0),
  sorting: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.sorting], List()),
  settingStatus: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.settingStatus], false),
  settingPending: state => getIn(state, [billingRatingRatioKeys.controller, billingRatingRatioKeys.billingRatingRatioList, billingRatingRatioKeys.settingPending], false),
});
