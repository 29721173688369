// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Error from './Error';

function Error500() {
  const { t } = useTranslation();
  return (
    <Error
      status={500}
      caption={t('Извините, произошла ошибка. Наши специалисты работают над решением проблемы, и мы сделаем все возможное, чтобы восстановить работу сайта как можно быстрее. Просим прощения за неудобства и рекомендуем попробовать зайти на сайт через некоторое время. Пожалуйста, оставайтесь с нами, мы заботимся о вашем комфорте и сделаем все возможное, чтобы обеспечить бесперебойную работу нашего сайта. Спасибо за терпение и понимание.')}
    />
  );
}


export default Error500;
