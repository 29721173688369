// Core
import { call, put, select } from 'redux-saga/effects';
import { reset } from 'redux-form';

// Engine
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../asyncActionNotificationMessages';
import { uiActions } from '../../action';

// Partials
import { formName } from '../../../../../ui/components/Header/components/ServiceSupport/form';
import { actions } from '../../../serviceDesk/actions';
import { asyncActions } from '../../../serviceDesk/saga/asyncActions';
import { selectors } from '../../../../config/selectors';
import { pageLinks } from '../../../../config/routes';

export function* callPostServiceSupportWorker({ payload }) {
  const { updateServiceDeskList = false, ...restPayload } = payload;
  yield put(uiActions.mergeServiceSupportModal({ payload: true }));
  yield put(actions.setNewTicketIsSending(true));
  const pathname = yield select(selectors.router.pathname);
  const response = yield call([api, api.serviceDesk.postQuestion], restPayload);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(uiActions.mergeServiceSupportModal({ isOpen: false }));
        yield put(reset(formName));
        if (updateServiceDeskList && pathname === pageLinks.serviceDesk.support) {
          const pageSize = yield select(selectors.serviceDesk.pageSize);
          const currentPage = yield select(selectors.serviceDesk.currentPage);
          const sorting = (yield select(selectors.serviceDesk.sorting)).toJS();
          const filters = (yield select(selectors.serviceDesk.filters)).toJS();

          const params = {
            sorting,
            limit: pageSize,
            offset: currentPage * pageSize,
          };

          filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, params);

          yield put(asyncActions.getListAsync(params));
        }
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setNewTicketIsSending(false));
  yield put(uiActions.mergeServiceSupportModal({ payload: false }));
}
