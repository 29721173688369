// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function InfoFilledIcon(props) {
  return (
    <SvgIcon className="info-filled-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="6" fill="inherit" />
      <path d="M8.00016 4.66669C7.63256 4.66669 7.3335 4.96595 7.3335 5.33379C7.3335 5.70129 7.63256 6.00029 8.00016 6.00029C8.36777 6.00029 8.66683 5.70129 8.66683 5.33379C8.66683 4.96595 8.36777 4.66669 8.00016 4.66669Z" fill="white" stroke="white" strokeWidth="0.3" />
      <path d="M8.00016 7.33331C7.63198 7.33331 7.3335 7.55718 7.3335 7.83331V10.8333C7.3335 11.1094 7.63198 11.3333 8.00016 11.3333C8.36834 11.3333 8.66683 11.1094 8.66683 10.8333V7.83331C8.66683 7.55718 8.36834 7.33331 8.00016 7.33331Z" fill="white" stroke="white" strokeWidth="0.3" />
    </SvgIcon>
  );
}

export default InfoFilledIcon;
