// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';
// Parts
// Helpers
import Select from '../../../../../../components/_Form/Selects/Select/Select';

function CancelStatusSelect(props) {
  const {
    meta, input, label, margin,
    options, required, disabled,
  } = props;
  const { onChange, value } = input;

  const normalizedValue = value && value.toJS ? value.toJS() : value || undefined;
  const selectInput = {
    value: normalizedValue,
    onChange,
  };

  return (
    <Select
      margin={margin}
      label={label}
      required={required}
      input={selectInput}
      options={List(options)}
      disabled={disabled}
      variant="outlined"
      hasSearch
      meta={meta}
    />
  );
}

CancelStatusSelect.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  margin: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  required: PropTypes.bool,
};

CancelStatusSelect.defaultProps = {
  disabled: false,
  input: {
    onChange: () => {},
  },
  label: '',
  meta: {},
  options: [],
  required: false,
};

export default CancelStatusSelect;
