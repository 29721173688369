// Core
import React, {
  useEffect,
} from 'react';
import * as PropTypes from 'prop-types';

// Parts
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useShallowEqualSelector } from '../../../../hooks/useShallowEqualSelector';

import {
  asyncActions as attributeSetsAsyncActions,
} from '../../../../../engine/core/attributeSets/saga/asyncActions';
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';
import { asyncActions as categoriesActions } from '../../../../../engine/core/categories/saga/asyncActions';
import { useQueryParams } from '../../../../hooks/useQueryParams';

function AttributeSetsContextTableFilter(props) {
  const {
    disabled, filters, onSelectChange,
    columnType, placeholder,
  } = props;

  const dispatch = useDispatch();
  const attributeSetId = useQueryParams('attributeSetId');
  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const filterValue = filters.value ? filters?.value.map(item => item.value) : undefined;

  useEffect(() => {
    if (!attributeSetId) {
      dispatch(categoriesActions.getCategoriesListContextAsync({ attributeSetId: filterValue }));
    }
  }, [attributeSetId]);

  useEffect(() => {
    if (!currentFiltersTypeList.includes(columnType)) {
      dispatch(attributeSetsAsyncActions.getAttributeSetsListContextAsync({ loadRootCategories: true, parentValue: 0 }));
    }
  }, [dispatch, currentFiltersTypeList, columnType]);

  const attributeSetsData = useShallowEqualSelector(selectors.attributeSets.attributeSetsListContext);
  const isLoading = useShallowEqualSelector(selectors.attributeSets.attributeSetsListContextPending);

  return (
    <TableHeadFilterSelectMultiple
      values={attributeSetsData}
      loading={isLoading}
      disabled={disabled}
      filters={filters}
      onSelectChange={onSelectChange}
      placeholder={placeholder}
    />
  );
}

AttributeSetsContextTableFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  columnType: PropTypes.string,
};

export default AttributeSetsContextTableFilter;
