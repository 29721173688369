// Core
import {
  apply, delay, put,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { pageLinks } from '../../../../config/routes';
import { userActionAsync } from '../../../user/saga/asyncAction';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutItemByHashIdWorker({ payload: { redirect, data, hashId } }) {
  yield put(actions.mergeItemByHashId({ putPending: true }));

  const response = yield apply(api, api.configurations.putConfigurationItemByHashId, [{ data, hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        if (redirect) {
          yield put(push(pageLinks.settings.configurations.all));
        }
        yield put(userActionAsync.getUserInfoAsync());
        break;
      }

      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      default: {
        break;
      }
    }
  }

  yield put(actions.mergeItemByHashId({ putPending: false }));
}
