// Core
import { apply, put } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

// Engine
import i18n from '../../../../init/i18n';
import { api } from '../../../../config/api';
import { asyncActions } from '../asyncActions';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { formFields, formName } from '../../../../../ui/page/Orders/OrderEdit/form';

export function* callPutOrdersHashIdWorker({ payload: { hashId, data } }) {
  yield put(actions.mergeOrderEdit({ pending: true }));
  const response = yield apply(api, api.orders.putOrdersHashId, [{ hashId, data }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { reload, status, statusTitle } = response.data;

    if (reload) {
      yield put(asyncActions.getOrdersHashIdAsync({ hashId, mode: 'edit' }));
    }

    switch (status) {
      case 'error': {
        const { errors, popup } = response.data;
        if (popup) {
          yield put(actions.mergeErrorModal({
            message: popup.message,
            title: popup.title,
            modalOpen: true,
          }));
          yield put(stopSubmit(formName, {
            [formFields.shippingTrackingNumber]: i18n.t('ТТН не добавлено'),
          }));
        } else {
          yield put(setErrorMessage(errors, statusTitle));
        }
        break;
      }
      case 'success': {
        const { message } = response.data;

        if (!reload) {
          yield put(asyncActions.getOrdersHashIdAsync({ hashId, loading: false }));
          yield put(setSuccessMessage(message, statusTitle));
          break;
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeOrderEdit({ pending: false }));
}
