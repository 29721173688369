// Core
import { put, apply } from 'redux-saga/effects';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';

export function* callGetAttributesByHashIdWorker({ payload }) {
  const { hashId, setLoading, onSuccess } = payload;
  setLoading(true);
  const { status, data } = yield apply(api, api.feeds.getFeedsAttributes, [hashId]);
  if (status >= 200 && status < 400) {
    yield put(actions.mergeAttributesItems({ [hashId]: data }));
    if (onSuccess) {
      onSuccess();
    }
  }
  setLoading(false);
}
