// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { actions } from '../../../actions';
import { localData } from '../../../../../config/localData';

export function* callGetAttachedFilesWorker({ payload: { hashId } }) {
  const { SDAttachedFiles: attachedFilesKey } = localData.keysObj;
  const filesData = yield apply(localData, localData.getItem, [attachedFilesKey]);
  if (filesData && filesData[hashId]) {
    yield put(actions.setFilesInChatMessage(filesData[hashId].files));
  }
}
