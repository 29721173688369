// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../../../../engine/core/products/productFeedLogs/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
// Data
import { requestDelay } from '../../../../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';

export function useProductsFeedLogsList(id, refresh) {
  const currentPage = useSelector(selectors.productFeedLogs.currentPage);
  const limit = useSelector(selectors.productFeedLogs.pageSize);
  const filters = useSelector(selectors.productFeedLogs.filters);
  const sorting = useSelector(selectors.productFeedLogs.sorting);
  const reload = useSelector(selectors.productFeedLogs.reload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const offset = limit * currentPage;
  const filtersStringify = JSON.stringify(filters.toJS());
  const sortingStringify = JSON.stringify(sorting.toJS());
  const hashId = useSelector(selectors.productFeedLogs.productFeedLogsHashId);
  const productFeedLogsShowModal = useSelector(selectors.productFeedLogs.productFeedLogsShowModal);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const getListAsync = useCallback(() => {
    if (productFeedLogsShowModal) {
      const params = {
        ...paramsByFilter,
        orderBy,
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params, hashId));
    }
  }, [productFeedLogsShowModal, paramsByFilter, offset, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync,
    getInitialStateAsync,
    reload, refresh, timeout, routeKey]);
}
