// Core
import React, { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { COLOR } from '../../containers/App/AppStyles';
import { useStyles } from '../../hooks/useStyles';

const styles = () => ({
  tooltip: {
    cursor: 'pointer',
  },
  iconButton: {
    fontSize: '18px',
    padding: '7px',
    borderRadius: '8px',
    color: COLOR.grey['72'],
    '&:hover': {
      backgroundColor: COLOR.grey['4'],
    },
  },
  icon: {
    padding: 0,
  },
});

function TooltipHint(props) {
  const classes = useStyles(styles);
  const {
    title, onTooltipClick, placement,
    classNameButton, buttonAriaLabel, classNameTooltip,
  } = props;

  return title ? (
    <Tooltip
      className={cx({
        [classes.tooltip]: onTooltipClick,
        [classNameTooltip]: classNameTooltip,
      })}
      title={title}
      aria-label={title}
      placement={placement}
    >
      <IconButton
        className={cx(classes.iconButton, {
          [classNameButton]: classNameButton,
        })}
        aria-label={buttonAriaLabel}
      >
        <InfoOutlinedIcon className={classes.icon} color="inherit" fontSize="inherit" />
      </IconButton>
    </Tooltip>
  ) : null;
}

TooltipHint.propTypes = {
  onTooltipClick: PropTypes.func,
  title: PropTypes.string,
  classNameButton: PropTypes.string,
  classNameTooltip: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'left-start',
    'right-start',
    'top-end',
    'top-start',
  ]),
};

TooltipHint.defaultProps = {
  placement: 'bottom-end',
};

export default memo(TooltipHint);
