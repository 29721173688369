// Parts
import { asyncTypes } from './asyncTypes';
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const uiActionAsync = Object.freeze({
  postServiceSupport: data => getActionCreator(asyncTypes.POST_SERVICE_SUPPORT_MODAL_ASYNC, data),
  setModalMultipleMessages: data => getActionCreator(asyncTypes.SET_MODAL_MULTIPLE_MESSAGES_ASYNC, data),
  setNotificationMessage: data => getActionCreator(asyncTypes.SET_NOTIFICATION_MESSAGE_ASYNC, data),
  setImportLang: data => getActionCreator(asyncTypes.SET_IMPORT_LANG, data),
  setImportLink: data => getActionCreator(asyncTypes.SET_IMPORT_LINK, data),
  setImportCategory: data => getActionCreator(asyncTypes.SET_IMPORT_CATEGORY, data),
  setImportPageFilePondDefault: data => getActionCreator(asyncTypes.SET_IMPORT_XLSX_FILE_POND, data),
});
