// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { string } from 'prop-types';
import cx from 'classnames';

// UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TooltipIcon from '../../../../containers/TooltipIcon';

// Hooks
import { useStyles } from '../../../../hooks/useStyles';

// Icons
import BalanceIcon from '../../../../icons/Balance';
import ErrorFilledIcon from '../../../../icons/ErrorFilled';
import InfoFilledIcon from '../../../../icons/InfoFilled';
import BalancePartnerStyles from './styles';

function BalanceAdmin({
  partnersLimitDebt, partnersTotalDebt,
}) {
  const { t } = useTranslation();
  const classes = useStyles(BalancePartnerStyles);
  const isIndebtedness = Number(partnersTotalDebt) >= Number(partnersLimitDebt);

  return (
    <Box className={cx(classes.balanceMenuBox, { [classes.indebtedness]: isIndebtedness })}>
      <BalanceIcon className={classes.balanceIcon} />
      <Box className={classes.balanceRowWidthIcons}>
        {(isIndebtedness && <ErrorFilledIcon className={classes.errorIcon} />)}
        <Typography className={classes.balanceValueRow}>
          <NumberFormat value={partnersTotalDebt} thousandSeparator=" " displayType="text" />
            &nbsp;/&nbsp;
          <NumberFormat value={partnersLimitDebt} thousandSeparator=" " displayType="text" />
        </Typography>
        <TooltipIcon headerIconMargin noMaxWidth text={`${t('Общая сумма задолженности')} / ${t('Граничная сумма задолженности')}`}>
          <Box sx={{ p: '0px', display: 'flex' }}>
            <InfoFilledIcon className={classes.infoIcon} />
          </Box>
        </TooltipIcon>
      </Box>
    </Box>
  );
}

BalanceAdmin.propTypes = {
  partnersTotalDebt: string,
  partnersLimitDebt: string,
};

export default BalanceAdmin;
