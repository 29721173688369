// Core
import { call, put } from 'redux-saga/effects';
import { List } from 'immutable';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetMessagesStatusesWorker() {
  yield put(actions.mergeMessagesStatuses({ pending: true }));

  const response = yield call(api.messages.getMessagesStatuses);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeMessagesStatuses({
      items: List(response.data),
    }));
  }

  yield put(actions.mergeMessagesStatuses({ pending: false }));
}
