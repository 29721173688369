// Core
import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import TabsPanel from '../../../../../containers/Tabs/TabsPanel';
import CategoryStatusesHistoryTable from './StatusesHistoryTable/CategoryStatusesHistoryTable';
import ResetFiltersButton from '../../../../../components/_Table/DxTable/components/ResetFilterButton';
// Engine
import { actions } from '../../../../../../engine/core/categories/statusesHistory/actions';
import { initialState, stateKeys } from '../../../../../../engine/core/categories/statusesHistory/reducer';
import { useCategoryStatusesHistoryList } from './StatusesHistoryTable/hooks/useCategoryStatusesHistoryList';
import { selectors } from '../../../../../../engine/config/selectors';

function CategoryEditStatusesHistory(props) {
  useCategoryStatusesHistoryList();
  const {
    display, index, value, classes,
  } = props;
  const dispatch = useDispatch();
  const filters = useSelector(selectors.categoryStatusesHistory.filters);
  const sorting = useSelector(selectors.categoryStatusesHistory.sorting);
  const isDisabledResetButton = !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.categoryStatusesHistoryList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.categoryStatusesHistoryList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.categoryStatusesHistoryList).get(stateKeys.currentPage)));
  }, [dispatch]);

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
        sx={{
          flexDirection: 'column',
        }}
      >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Box sx={{ textAlign: 'right', marginBottom: '8px' }}>
            <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
          </Box>
          <CategoryStatusesHistoryTable classes={classes} display={display} />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

CategoryEditStatusesHistory.propTypes = {
  display: PropTypes.oneOf(['flex', 'none']),
  index: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object,
};

export default CategoryEditStatusesHistory;
