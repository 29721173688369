// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callGetCategoriesStatisticWorker,
  callGetProductSettingsWorker,
  callGetProductsFeatureValuesDownloadWorker,
  callPutProductsWorker,
  callGetProductsStatusesWorker,
  callGetProductsHashIdWorker,
  callContentProductEditWorker,
  callPutProductsSettingsWorker,
  callSendProductsToModerationWorker,
  callGetProductsInitialStateWorker,
  callContentProductCreateWorker,
  callDeleteProductsWorker,
  callGetProductsExportWorker,
  callPutUpdateValuesFromMagento,
  callGetProductsSourceListWorker,
  callContentProductBulkEditWorker,
  callGetProductsPropertiesExportWorker,
  callGetProductsPricesExportWorker,
  callGetProductsOnlyTextExportWorker,
  callPostProductsImportWorker,
  callPostProductsPropertiesImportWorker,
  callPostProductsPricesImportWorker,
} from './workers';

function* watchGetCategoriesStatistic() {
  yield takeEvery(asyncTypes.GET_CATEGORY_STATISTIC_ASYNC, callGetCategoriesStatisticWorker);
}
function* watchGetProductSettings() {
  yield takeEvery(asyncTypes.GET_PRODUCT_SETTINGS_BY_CATEGORY_ID_ASYNC, callGetProductSettingsWorker);
}
function* watchGetProductsFeatureValuesDownload() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_ASYNC, callGetProductsFeatureValuesDownloadWorker);
}
function* watchPutProducts() {
  yield takeEvery(asyncTypes.PUT_PRODUCTS_ASYNC, callPutProductsWorker);
}
function* watchPutUpdateValuesFromMagento() {
  yield takeEvery(asyncTypes.PUT_UPDATE_VALUES_FROM_MAGENTO_ASYNC, callPutUpdateValuesFromMagento);
}
function* watchGetProductsStatuses() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_STATUSES_ASYNC, callGetProductsStatusesWorker);
}
function* watchGetProductsHashId() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_HASH_ID_ASYNC, callGetProductsHashIdWorker);
}
function* watchContentProductEdit() {
  yield takeEvery(asyncTypes.CONTENT_PRODUCT_EDIT_ASYNC, callContentProductEditWorker);
}
function* watchContentProductBulkEdit() {
  yield takeEvery(asyncTypes.CONTENT_PRODUCT_BULK_EDIT_ASYNC, callContentProductBulkEditWorker);
}
function* watchContentProductAdd() {
  yield takeEvery(asyncTypes.CONTENT_PRODUCT_ADD_ASYNC, callContentProductCreateWorker);
}
function* watchPutProductsSettings() {
  yield takeEvery(asyncTypes.PUT_PRODUCTS_SETTINGS_ASYNC, callPutProductsSettingsWorker);
}
function* watchSendProductsToModeration() {
  yield takeEvery(asyncTypes.SEND_PRODUCTS_TO_MODERATION_ASYNC, callSendProductsToModerationWorker);
}
function* watchGetProductsInitialState() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_INITIAL_STATE_ASYNC, callGetProductsInitialStateWorker);
}
function* watchDeleteProducts() {
  yield takeEvery(asyncTypes.DELETE_PRODUCTS, callDeleteProductsWorker);
}
function* watchGetProductsExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_EXPORT, callGetProductsExportWorker);
}
function* watchGetProductsPropertiesExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_PROPERTIES_EXPORT, callGetProductsPropertiesExportWorker);
}
function* watchGetProductsPricesExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_PRICES_EXPORT, callGetProductsPricesExportWorker);
}
function* watchGetProductsOnlyTextExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_ONLY_TEXT_EXPORT, callGetProductsOnlyTextExportWorker);
}
function* watchGetProductsSourceList() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_SOURCE_LIST, callGetProductsSourceListWorker);
}
function* watchPostProductsFeedImport() {
  yield takeEvery(asyncTypes.POST_PRODUCTS_IMPORT_ASYNC, callPostProductsImportWorker);
}
function* watchPostProductsPropertiesImport() {
  yield takeEvery(asyncTypes.POST_PRODUCTS_PROPERTIES_IMPORT_ASYNC, callPostProductsPropertiesImportWorker);
}
function* watchPostProductsPricesImport() {
  yield takeEvery(asyncTypes.POST_PRODUCTS_PRICES_IMPORT_ASYNC, callPostProductsPricesImportWorker);
}

export function* watchersContent() {
  yield all([
    watchGetCategoriesStatistic(),
    watchGetProductSettings(),
    watchGetProductsFeatureValuesDownload(),
    watchPutProducts(),
    watchGetProductsStatuses(),
    watchGetProductsHashId(),
    watchContentProductEdit(),
    watchContentProductBulkEdit(),
    watchContentProductAdd(),
    watchPutProductsSettings(),
    watchSendProductsToModeration(),
    watchGetProductsInitialState(),
    watchPutUpdateValuesFromMagento(),
    watchDeleteProducts(),
    watchGetProductsExport(),
    watchGetProductsPricesExport(),
    watchGetProductsSourceList(),
    watchGetProductsPropertiesExport(),
    watchGetProductsOnlyTextExport(),
    watchPostProductsFeedImport(),
    watchPostProductsPropertiesImport(),
    watchPostProductsPricesImport(),
  ]);
}
