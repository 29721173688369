// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// config
import { selectors } from '../../../../../../../../engine/config/selectors';

// hooks
import { useCurrentTypeGroup } from './useCurrentTypeGroup';

export function useValuesByType(index) {
  const messageUserTypesByTypeItems = useSelector(selectors.messages.messageUserTypesByTypeItems);
  const { currentType } = useCurrentTypeGroup(index);

  const valuesByType = useMemo(() => {
    const values = messageUserTypesByTypeItems.toJS();
    if (!isEmpty(values) && currentType) {
      if (values[currentType]) {
        return values[currentType];
      }
    }

    return undefined;
  }, [messageUserTypesByTypeItems, currentType]);
  return { valuesByType };
}
