// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset, submit,
} from 'redux-form';
import * as PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form/immutable';

// Parts
import SaveIcon from '@mui/icons-material/Save';
import FilesUploaderWithValidation from '../../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import Modal from '../../../../../containers/Modals/Modal/Modal';

// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';

// Engine
import { actions } from '../../../../../../engine/core/partners/managers/actions';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';
import { asyncActions } from '../../../../../../engine/core/partners/managers/saga/asyncActions';
import { validators } from '../../../../../../_helpers/validationsForm';


// Form Data
export const formName = 'partnersManagersImportForm';
export const formFields = Object.freeze({
  fields: Object.freeze({
    stopPartnersManagersFile: 'stopPartnersManagersFile',
  }),
});

function PartnersManagersImportModal(props) {
  const { t } = useTranslation();
  const {
    handleSubmit, pending,
  } = props;
  const dispatch = useDispatch();
  const isOpen = useSelector(selectors.partnersManagers.partnerManagerModalIsOpen);
  const hasAccess = useAccessList(accessList.partnerManagerList);
  const partnerManagerUploadIsPending = useSelector(selectors.partnersManagers.partnerManagerUploadIsPending);

  if (!hasAccess) {
    return null;
  }

  const setPartnerManagerModalIsOpen = () => {
    dispatch(reset(formName));
    dispatch(actions.setPartnerManagerModalIsOpen(!isOpen));
  };

  const handleModalAdd = () => {
    dispatch(submit(formName));
  };

  function onFormSubmit(formValues) {
    const file = formValues.get(formFields.fields.stopPartnersManagersFile);
    dispatch(asyncActions.postUploadPartnersManagersAsync({ file }));
  }

  return (
    <Modal
      title={t('Загрузить XLSX')}
      modalOpen={isOpen}
      buttonSendIcon={<SaveIcon />}
      sendText={t('Отправить')}
      handleModalSend={handleModalAdd}
      loadingSend={partnerManagerUploadIsPending}
      handleModalToggle={setPartnerManagerModalIsOpen}
      handleModalCancel={setPartnerManagerModalIsOpen}
      disabledSend={partnerManagerUploadIsPending}
      modalMaxWidth="xs"
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx]}
          component={FilesUploaderWithValidation}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={formFields.fields.stopPartnersManagersFile}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

PartnersManagersImportModal.displayName = 'partnersManagersImportModal';

PartnersManagersImportModal.propTypes = {
  handleSubmit: PropTypes.func,
  pending: PropTypes.bool,
};

PartnersManagersImportModal.defaultProps = {
  pending: false,
  handleSubmit: () => {},
};

export default reduxForm({
  form: formName,
})(PartnersManagersImportModal);
