// Core
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
// Icons
import SendIcon from '@mui/icons-material/Send';
// UI
import Button from '@mui/material/Button';
// Engine
import { accessList } from '../../../../../../../engine/config/access';
import { actions as contentProductActions } from '../../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
// Helpers
import { setWarningMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useDesktopView } from '../../../../../../hooks/useDesktopView';
import { sxButton, sxIcon } from './styles';

export const actions = Object.freeze({
  applyToAllProducts: 1,
  applyToSelected: 0,
});

function SendToModerationButtonGroup() {
  const isDesktop = useDesktopView();
  const { t } = useTranslation();
  const categoryId = useSelector(selectors.contentProduct.productCategoryActiveItem)?.get('value');
  const [selectedIndex, setSelectedIndex] = useState(actions.applyToAllProducts);
  const selections = useSelector(selectors.contentProduct.selection);
  const selectionsLength = useMemo(() => selections.toJS().length, [selections]);
  const hasAccessProductSendToModeration = useAccessList(accessList.productSendToModeration);
  const dispatch = useDispatch();
  const selectedProducts = useSelector(selectors.allProductsImmutableSelectionSku);
  const moderationPending = useSelector(selectors.content.moderationPending);
  const allProductsCount = useSelector(selectors.contentProduct.list);
  const text = selections.size
    ? t('Отправить на модерацию выбранные')
    : t('Отправить на модерацию все');

  useEffect(() => {
    if (moderationPending === false) {
      dispatch(contentProductActions.setReload(true));
    }
  }, [dispatch, moderationPending]);

  useEffect(() => {
    if (selectionsLength > 0) {
      setSelectedIndex(0);
    }
  }, [selectionsLength]);

  if (!hasAccessProductSendToModeration || categoryId === undefined) {
    return null;
  }

  const selectOptions = [
    { value: actions.applyToSelected, label: t('Отправить на модерацию выбранные') },
    { value: actions.applyToAllProducts, label: t('Отправить на модерацию все') },
  ];

  const onButtonClick = (index) => {
    const data = {};
    const currentIndex = typeof index === 'number' ? index : selectedIndex;
    switch (currentIndex) {
      case actions.applyToSelected: {
        if (selectedProducts.size) {
          data.products = selectedProducts.toJS().map(({ id }) => id);
        } else {
          dispatch(setWarningMessage(t('Ни одного продукта не выбрано'), t('Ошибка')));
        }
        break;
      }
      case actions.applyToAllProducts: {
        data.products = 'all';
        break;
      }
      default: {
        break;
      }
    }
    if (!isEmpty(data)) {
      data.categoryId = categoryId;
      data.productsValues = { toModeration: true };
      dispatch(asyncActions.sendProductsToModerationAsync(data));
    }
  };
  return (
    <>
      {isDesktop
        ? (
          <Button
            fullWidth
            sx={sxButton()}
            onClick={onButtonClick}
            disabled={moderationPending || allProductsCount?.size === 0}
            id="moderation_sidebar_cell"
          >
            <SendIcon sx={sxIcon} />
            {text}
          </Button>
        )
        : (
          selectOptions.map((option, idx) => (
            <Button
              sx={sxButton()}
              key={idx}
              onClick={() => onButtonClick(idx)}
            >
              <SendIcon sx={sxIcon} />
              {option.label}
            </Button>
          ))
        )
      }
    </>
  );
}

export default SendToModerationButtonGroup;
