// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const notificationsActions = Object.freeze({
  mergeNotifications: notificationsList => ({
    type: types.MERGE_NOTIFICATIONS_LISTS,
    payload: notificationsList,
  }),
  setNotificationsPending: status => ({
    type: types.SET_NOTIFICATIONS_LISTS_PENDING,
    payload: status,
  }),
  setNotificationsOffset: offset => ({
    type: types.SET_NOTIFICATIONS_LISTS_OFFSET,
    payload: offset,
  }),
  setNotificationsOffsetNotRead: offset => ({
    type: types.SET_NOTIFICATIONS_LISTS_OFFSET_NOT_READ,
    payload: offset,
  }),
  addNotificationRead: id => ({
    type: types.ADD_NOTIFICATION_READ_TO_ARRAY,
    payload: id,
  }),
  clearNotificationRead: () => ({
    type: types.CLEAR_NOTIFICATION_READ_TO_LISTS,
  }),
  setNotificationCenterTab: tabNumber => ({
    type: types.SET_NOTIFICATIONS_CENTER_TAB,
    payload: tabNumber,
  }),
  setList(data) {
    return getActionCreator(types.SET_NOTIFICATIONS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_NOTIFICATIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_NOTIFICATIONS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_NOTIFICATIONS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_NOTIFICATIONS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_NOTIFICATIONS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_NOTIFICATIONS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_NOTIFICATIONS_SETTING_PENDING, settingPending);
  },
  setNotificationByHashId(notification) {
    return getActionCreator(types.SET_NOTIFICATION_BY_HASH_ID, notification);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_NOTIFICATION_SELECTION, selection);
  },
  mergeNotificationGroups(notificationGroups) {
    return getActionCreator(types.MERGE_NOTIFICATION_GROUPS, notificationGroups);
  },
  mergeNotificationTypes(notificationTypes) {
    return getActionCreator(types.MERGE_NOTIFICATION_TYPES, notificationTypes);
  },
});
