// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { asyncTypes } from './asyncTypes';

export const asyncActions = Object.freeze({
  passwordResetAsync(params) {
    return getActionCreator(asyncTypes.PASSWORD_RESET_ASYNC, params);
  },
  passwordChangeAsync(params) {
    return getActionCreator(asyncTypes.PASSWORD_CHANGE_ASYNC, params);
  },
});
