import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
// Icons
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
// Engine
import { asyncActions } from '../../../../../../engine/core/feeds/all/saga/asyncActions';
import { pageLinks } from '../../../../../../engine/config/routes';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/feeds/all/actions';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';

import { IconButtonStyles } from '../../../../../components/_Table/TableCell/Styles';
import { useStyles } from '../../../../../hooks/useStyles';
import { COLOR } from '../../../../../containers/App/AppStyles';


// Styles
const ProductsTableEditCellStyles = () => ({
  editCellHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    gap: '8px',
    '&:not(first-child)': {
      marginLeft: '0px',
    },
  },
  iconButton: {
    padding: '0',
    background: COLOR.grey['4'],
    borderRadius: '5px',
  },
  icon: {
    width: '24px',
    height: '24px',
    padding: '2px',
  },
  ml: {
    marginLeft: '8px',
  },
  emptyButton: {
    width: '24px',
    height: '24px',
  },
});

export const ProductsTableEditCell = (editProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(ProductsTableEditCellStyles);

  // Access
  const hasAccessEdit = useAccessList(accessList.feedEdit);
  const hasAccessShow = useAccessList(accessList.feedShow);
  const hasAccessExport = useAccessList(accessList.feedExport);
  const hasAccessRefresh = useAccessList(accessList.feedRefresh);
  const hasAccessDelete = useAccessList(accessList.feedDelete);
  // Selectors
  const refreshPending = useSelector(selectors.feeds.refreshPending);
  const refreshHashId = useSelector(selectors.feeds.refreshHashId);
  const deletePending = useSelector(selectors.feeds.deletePending);
  // Edit
  const {
    tableRow: {
      row: {
        canEdit, id, hashId, feedDataType, canExport,
        canUpdate, canDelete, canResetFeed,
      },
    },
  } = editProps;
  // Export
  const exportItems = useSelector(selectors.feeds.exportItems);
  const normalizeExportItems = exportItems.toJS();
  const isDisabledDownload = !!normalizeExportItems[id];

  const onExport = () => {
    dispatch(asyncActions.getFeedsExportAsync({ hashId: id }));
  };
  const onRefresh = () => {
    if (feedDataType === 'file') {
      dispatch(actions.mergeFeedsRefresh({ showModal: true, hashId, id }));
    } else {
      dispatch(asyncActions.postRefreshAsync({ id, hashId }));
    }
  };
  const onRefreshPriceList = () => {
    dispatch(asyncActions.postRefreshPriceListAsync(hashId));
  };
  const onDelete = () => {
    if (typeof hashId === 'string' && hashId !== '') {
      dispatch(actions.setFeedsDelete({ showModal: true, hashId }));
    }
  };

  const leftEmptyButtons = useMemo(() => {
    const countAccess = [
      !(hasAccessDelete && canDelete),
      !(hasAccessRefresh && canUpdate),
      !(hasAccessEdit && canEdit),
    ];
    const maxLeftSize = 2;
    const normalize = countAccess.filter(Boolean);
    const sliceStart = countAccess.length - normalize.length;

    return normalize.length > maxLeftSize
      ? normalize.slice(0, maxLeftSize)
      : normalize.slice(sliceStart, maxLeftSize);
  }, [hasAccessDelete, canDelete, hasAccessRefresh, canUpdate, hasAccessEdit, canEdit]);


  return (
    <Box component="div" className={classes.editCellHolder}>
      {hasAccessDelete && canDelete && (
        <Tooltip title={t('Удалить')}>
          <IconButtonStyles
            color="inherit"
            disabled={deletePending}
            onClick={onDelete}
            className={classes.iconButton}
            size="large"
          >
            { deletePending
              ? <CircularProgress color="inherit" size={20} />
              : <DeleteOutlineOutlinedIcon className={classes.icon} />
            }
          </IconButtonStyles>
        </Tooltip>
      )}
      {hasAccessRefresh && canUpdate && (
        <Tooltip title={t('Обновить')}>
          <IconButtonStyles
            color="inherit"
            disabled={refreshPending}
            onClick={onRefresh}
            className={classes.iconButton}
            size="large"
          >
            { refreshPending && refreshHashId === hashId
              ? <CircularProgress color="inherit" size={20} />
              : <RefreshOutlinedIcon className={classes.icon} />
          }
          </IconButtonStyles>
        </Tooltip>
      )}
      {hasAccessEdit && canEdit && (
        <Tooltip title={t('Редактировать')}>
          <Link to={pageLinks.productsAndPrices.import(hashId)}>
            <IconButtonStyles color="inherit" size="large" className={classes.iconButton}>
              <ModeOutlinedIcon className={classes.icon} />
            </IconButtonStyles>
          </Link>
        </Tooltip>
      )}
      {hasAccessShow && !canEdit && (
        <>
          {leftEmptyButtons.map((i, key) => <div key={key} className={classes.emptyButton} />)}
          <Tooltip title={t('Просмотреть')}>
            <Link to={pageLinks.productsAndPrices.import(hashId)}>
              <IconButtonStyles color="inherit" size="large" className={classes.iconButton}>
                <RemoveRedEyeOutlinedIcon className={classes.icon} />
              </IconButtonStyles>
            </Link>
          </Tooltip>
          {!canResetFeed ? (<div className={classes.emptyButton} />) : null}
        </>
      )}
      {hasAccessExport && canExport && (
        <Tooltip title={t('Экспортировать прайс-лист')}>
          <IconButtonStyles
            color="inherit"
            disabled={isDisabledDownload || !isEmpty(normalizeExportItems)}
            onClick={onExport}
            className={classes.iconButton}
            size="large"
          >
            { isDisabledDownload
              ? <CircularProgress color="inherit" size={20} />
              : <CloudDownloadOutlinedIcon className={classes.icon} />
            }
          </IconButtonStyles>
        </Tooltip>
      )}
      {canResetFeed && (
        <IconButtonStyles
          color="inherit"
          onClick={onRefreshPriceList}
          size="large"
          className={classes.iconButton}
        >
          { refreshPending && refreshHashId === hashId ? <CircularProgress color="inherit" size={20} />
            : (
              <Tooltip title={t('Принудительное обновление')}>
                <RefreshOutlinedIcon className={classes.icon} />
              </Tooltip>
            )
          }
        </IconButtonStyles>
      )}
    </Box>
  );
};
