// Core
import { useSelector } from 'react-redux';
// Parts
import TableFilterRowWithSelectedCounter from '../../../components/_Table/DxTable/components/TableFilterRowWithSelectedCounter/TableFilterRowWithSelectedCounter';
// Engine
import { selectors } from '../../../../engine/config/selectors';

function TableFilterRowComponent(props) {
  const selection = useSelector(selectors.notification.selection);

  return (
    <TableFilterRowWithSelectedCounter selectedCounter={selection.size} {...props} />
  );
}

export default TableFilterRowComponent;
