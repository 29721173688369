import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// Parts
import Layout from './Layout/Layout';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { routersLink } from '../../../../engine/config/routes';

export default function ProtectedLayout() {
  const userInfo = useSelector(selectors.user.userInfo);
  const search = useSelector(selectors.router.search);
  const notEmptyUserInfo = Object.keys(userInfo).length !== 0;
  const isActive = userInfo.enabled;

  switch (true) {
    case (notEmptyUserInfo && isActive): {
      return <Layout />;
    }
    case (window.location.pathname === routersLink.users.signIn || window.location.pathname === '/'): {
      return <Navigate to={`${routersLink.users.signIn}${search}`} />;
    }
    default: {
      return <Navigate to={`${routersLink.users.signIn}?redirect=${encodeURIComponent(window.location.pathname)}`} />;
    }
  }
}
