// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// UI
import Button from '@mui/material/Button';
// Engine
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { accessList } from '../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { actions } from '../../../../../../../engine/core/content/actions';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';

function ProductsButtonGroups(props) {
  const { sx } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');
  const hasAccessExportFull = useAccessList(accessList.feedProductsExportFull);
  const hasAccessImport = useAccessList(accessList.feedProductsImport);
  const hasAccessExportOnlyText = useAccessList(accessList.productsExportOnlyText);
  const currentCategoryId = useSelector(selectors.contentProduct.productCategoryActiveItem)?.get('value');
  const selection = useSelector(selectors.contentProduct.selection);
  const filters = useSelector(selectors.contentProduct.filters);
  const isLoading = useSelector(selectors.content.productDownloadPending);
  const isOpen = useSelector(selectors.content.importModalIsOpen);
  const [exportProductLabel, setExportProductLabel] = useState('');
  const [exportProductOnlyTextLabel, setExportProductOnlyTextLabel] = useState('');
  const handleImport = () => {
    dispatch(actions.setImportModalIsOpen({ isOpen: !isOpen }));
  };
  const handleImportTextOnly = () => {
    dispatch(actions.setImportModalIsOpen({ isOpen: !isOpen, onlyText: true }));
  };
  const downloadProductsFull = () => {
    dispatch(asyncActions.getProductsExportAsync({ categoryId, attributeSetId }));
  };
  const downloadProductsOnlyText = () => {
    dispatch(asyncActions.getProductsOnlyTextExportAsync({ onlyTextFeatures: 1, categoryId, attributeSetId }));
  };

  useEffect(() => {
    if (selection.size) {
      setExportProductLabel(t('Экспорт выбранных товаров'));
      setExportProductOnlyTextLabel(t('Экспорт выбранных текстовых характеристик'));
    } else if (filters.size) {
      setExportProductLabel(t('Экспорт фильтрованных товаров'));
      setExportProductOnlyTextLabel(t('Экспорт фильтрованных текстовых характеристик'));
    } else {
      setExportProductLabel(t('Экспорт всех товаров'));
      setExportProductOnlyTextLabel(t('Экспорт всех текстовых характеристик'));
    }
  }, [filters.size, selection.size, i18n.language]);

  return (
    <>
      { hasAccessExportFull && (
        <Button
          fullWidth
          sx={sx}
          onClick={downloadProductsFull}
          disabled={isLoading}
        >
          {exportProductLabel}
        </Button>
      )}
      {hasAccessImport && (
        <Button
          onClick={handleImport}
          fullWidth
          disabled={isLoading}
          sx={sx}
        >
          {t('Импорт товаров')}
        </Button>
      )}
      {hasAccessExportOnlyText && currentCategoryId && (
        <Button
          sx={sx}
          onClick={downloadProductsOnlyText}
          fullWidth
          disabled={isLoading}
        >
          {exportProductOnlyTextLabel}
        </Button>
      )}
      {hasAccessImport && currentCategoryId && (
        <Button
          sx={sx}
          onClick={handleImportTextOnly}
          fullWidth
          disabled={isLoading}
        >
          {t('Импорт текстовых характеристик')}
        </Button>
      )}
    </>
  );
}


export default ProductsButtonGroups;
