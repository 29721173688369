// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
// UI
import { Calendar } from 'react-date-range';
import TextField from '@mui/material/TextField';
import Modal from '../../../../../../../../containers/Modals/Modal/Modal';
// Helpers
import { getDate, getDateFnsObjectByLang } from '../../../../../../../../../_helpers/getDate';
import { locale } from '../../../../../../../../../engine/init/i18n';
// Components
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import { dateOnlyYearStringFormat, dateStringFormat } from '../../../../../../../../../engine/config/globalConfig';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 30,
    transform: 'translate(-50%)',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      top: 26,
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',

  },
  modalTitleText: {
    [theme.breakpoints.only('xl')]: {
      fontSize: '36px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
    },
  },
  textField: {
    verticalAlign: 'middle',
    '@global': {
      div: {
        paddingTop: '8.5px',
        paddingBottom: '8.5px',
      },
      input: {
        fontSize: 14,
        height: 'auto',
        padding: '0 8.5px',
      },
    },
  },
}));

function DateFormatFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, isEditable, onConfirm, productId,
    success, value, required, type, valuesPlain, fullText, showDeleteButton,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const formattedDate = selectedValue ? getDate(selectedValue, dateStringFormat, locale[lang]) : selectedValue;
  const formattedValuesPlain = valuesPlain ? getDate(valuesPlain, dateStringFormat, locale[lang]) : valuesPlain;

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const onConfirmHandler = (payload) => {
    if (payload === '') {
      onConfirm({ [featureName]: '' });
    } else {
      onConfirm({ [featureName]: getDate(payload, dateOnlyYearStringFormat) });
    }
  };

  const onCalendarChange = (calendar) => {
    setSelectedValue(calendar);
    toggleIsOpen();
  };

  return (
    <EditableContainer isEditable={isEditable} fullText={fullText} text={formattedDate}>
      <ConfirmButtons
        key={productId}
        error={error}
        disabled={disabled}
        required={required}
        notEmpty={selectedValue !== ''}
        onConfirm={onConfirmHandler}
        onReject={setSelectedValue}
        success={success}
        value={selectedValue}
        valuesPlain={formattedValuesPlain}
        type={type}
        initValue={value}
        showDeleteButton={showDeleteButton}
      >
        <TextField
          fullWidth
          className={classes.textField}
          onClick={toggleIsOpen}
          variant="outlined"
          InputProps={{
            value: formattedDate,
            readOnly: true,
          }}
        />
        {isOpen && (
          <Modal
            title={t('Дата')}
            modalOpen={isOpen}
            handleModalToggle={toggleIsOpen}
            modalMaxWidth="unset"
            fullWidth={false}
            hideButtons
          >
            <Calendar
              color="#8a1a1c"
              locale={getDateFnsObjectByLang(lang)}
              onChange={onCalendarChange}
              date={null}
            />
          </Modal>
        )}
      </ConfirmButtons>
    </EditableContainer>
  );
}

DateFormatFieldWithConfirmButtons.propTypes = {
  fullText: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditable: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  success: PropTypes.bool,
  value: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
};

DateFormatFieldWithConfirmButtons.defaultProps = {
  required: false,
  error: false,
  disabled: false,
  featureName: '',
  isEditable: false,
  success: false,
  showDeleteButton: true,
  value: '',
};

export default memo(DateFormatFieldWithConfirmButtons);
