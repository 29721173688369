// Core
import { useRef } from 'react';
import { useCallback } from 'use-memo-one';

const DEFAULT_SIZE = 40;

/** List size actions */
const sizeActions = (listRef) => {
  const sizeMap = useRef({});

  const getSize = useCallback(index => sizeMap.current[index] || DEFAULT_SIZE, []);
  const setSize = useCallback((index, size) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
      sizeMap.current = { ...sizeMap.current, [index]: size };
    }
  }, []);

  return [getSize, setSize];
};

export default sizeActions;
