// Parts
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
import { FocusComponent } from '../../../../Content/ContentProductEdit/components/RenderFormFieldDimensions';
// Helpers
import { EndComponent } from '../styles';

function RenderFormFieldDimensions(props) {
  const {
    margin, input, meta,
    disabled, label, wrapperClassName,
  } = props;
  const currentError = Boolean(meta.touched) && meta.error && !meta.active
    ? meta.error
    : undefined;

  const inputProps = {
    ...input,
    onChange: (event) => {
      if (!event.target.value.startsWith('0')) {
        input.onChange(event.target.value.replace(/[^0-9]/g, '').slice(0, 4));
      }
    },
  };

  return (
    <TextField
      input={inputProps}
      fullWidth
      helperTextAfter={currentError}
      isError={currentError}
      required={meta.error}
      label={label}
      disabled={disabled}
      margin={margin}
      wrapperClassName={wrapperClassName}
      focusComponent={<FocusComponent />}
      endAdornment={<EndComponent component="span">{' см'}</EndComponent>}
    />
  );
}

export default RenderFormFieldDimensions;
