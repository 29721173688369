export const types = Object.freeze({
  SET_BILLING_RATING_RATIO_LIST: 'SET_BILLING_RATING_RATIO_LIST',
  SET_BILLING_RATING_RATIO_CURRENT_PAGE: 'SET_BILLING_RATING_RATIO_CURRENT_PAGE',
  SET_BILLING_RATING_RATIO_FILTERS: 'SET_BILLING_RATING_RATIO_FILTERS',
  SET_BILLING_RATING_RATIO_PAGE_SIZE: 'SET_BILLING_RATING_RATIO_PAGE_SIZE',
  SET_BILLING_RATING_RATIO_SORTING: 'SET_BILLING_RATING_RATIO_SORTING',
  SET_BILLING_RATING_RATIO_RELOAD: 'SET_BILLING_RATING_RATIO_RELOAD',
  SET_BILLING_RATING_RATIO_INITIAL_STATE: 'SET_BILLING_RATING_RATIO_INITIAL_STATE',
  SET_BILLING_RATING_RATIO_SETTING_STATUS: 'SET_BILLING_RATING_RATIO_SETTING_STATUS',
  SET_BILLING_RATING_RATIO_SETTING_PENDING: 'SET_BILLING_RATING_RATIO_SETTING_PENDING',
});
