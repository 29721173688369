// Core
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextFieldWithConfirmButtons from '../../../../page/Products/Content/ContentProducts/components/ContentProductTable/ContentProductTableCell/Components/TextFieldWithConfirmButtons';
import { asyncActions } from '../../../../../engine/core/feeds/all/saga/asyncActions';

function PriceUrlWithConfirmButtons(props) {
  const {
    value, column, hashId, canEditLink, id,
  } = props;
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState(value);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleConfirm = (newLink) => {
    dispatch(asyncActions.putFeedItemByHashId({
      needForceAction: true,
      hashId,
      link: newLink.link,
      onSuccess: () => {
        setSuccess(true);
        setCurrentValue(newLink.link);
      },
      onError: () => setError(true),
      setPending,
    }));
  };

  if (value === null) {
    return null;
  }
  return (
    <TextFieldWithConfirmButtons
      required={column.required}
      featureName={column.name}
      isEditable={canEditLink}
      onConfirm={handleConfirm}
      value={currentValue}
      valuesPlain={value}
      key={currentValue || column.name}
      disabled={pending}
      productId={id}
      success={success}
      error={error}
    />
  );
}

export default PriceUrlWithConfirmButtons;
