// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../../actions';
import { getDate } from '../../../../../../_helpers/getDate';
import { selectors } from '../../../../../config/selectors';
// Helpers
export function* callGetMessagesWorker({ payload }) {
  const {
    hashId, offset, limit, resetState,
  } = payload;
  yield put(actions.setMessagesFromServerAreLoading(true));
  const response = yield apply(api, api.serviceDesk.getMessages, [{ hashId, offset, limit }]);
  if (response && response.status >= 200 && response.status < 400) {
    const {
      items, linkToTicket, questionTheme, canEdit, canClose, canContinue,
    } = response.data;
    yield put(actions.mergeChat({ canEdit: Boolean(canEdit), canClose: Boolean(canClose), canContinue: Boolean(canContinue) }));

    if (linkToTicket) {
      yield put(actions.setExternalLink(linkToTicket));
    }
    if (questionTheme) {
      yield put(actions.setChatName(questionTheme));
    }
    yield put(actions.setMessagesFromServer(items.map(message => ({
      ...message,
      updatedAt: {
        date: getDate(message.updatedAt.date),
      },
    }))));
    if (resetState) {
      yield put(actions.setInfinityScrollState([]));
    }
    const messagesFromServer = yield select(selectors.serviceDesk.messagesFromServer);
    if (messagesFromServer.length > 0) {
      yield put(actions.setMessages(messagesFromServer));
    }
  }

  yield put(actions.setMessagesFromServerAreLoading(false));
}
