// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import detectBrowserLanguage from 'detect-browser-language';
import cx from 'classnames';
// Parts
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
// Hooks
import { useAccessList } from '../../hooks/useAccessList';
import { useStyles } from '../../hooks/useStyles';
// Engine
import { locale } from '../../../engine/init/i18n';
import { accessList } from '../../../engine/config/access';
import { userActionAsync } from '../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../engine/config/selectors';
import { routersLink } from '../../../engine/config/routes';
// Icon
import ArrowDownRoundedIcon from '../../icons/ArrowDownRounded';

import LanguageMenuStyles from './styles';

function LanguageMenu() {
  const classes = useStyles(LanguageMenuStyles);
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  const hasAccess = useAccessList(accessList.userEditSelf);
  const dispatch = useDispatch();
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const pathname = useSelector(selectors.router.pathname);
  const languageLabel = language === locale.uk || language === locale.ua ? t('Украинский') : t('Русский');
  const options = [
    { label: t('Украинский'), value: locale.uk },
    { label: t('Русский'), value: locale.ru },
  ];

  const classNameFlag = cx(
    classes.langFlag,
    {
      [classes.ukraineFlag]: language === locale.uk || language === locale.ua,
    },
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const browserLang = detectBrowserLanguage();
    const normalizeBrowserLang = browserLang === 'ru' || browserLang === 'ru-RU' ? locale.uk : locale.uk;
    const localStorageLang = localStorage.getItem('language');
    const currentLanguage = localStorageLang === locale.ru || localStorageLang === locale.uk
      ? localStorage.getItem('language')
      : normalizeBrowserLang;
    const pathLang = pathname.includes('/ru') ? locale.ru : locale.ua;

    if (
      (pathname.includes(routersLink.users.signIn)
        || pathname.includes(routersLink.users.signUp)
        || pathname.includes(routersLink.users.password_reset))
      && (pathname.includes('/ru') || pathname.includes('/ua'))
    ) {
      i18n.changeLanguage(pathLang);
      localStorage.setItem('language', pathLang);
    } else if (currentLanguage !== i18n.language) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [i18n, pathname, language]);

  const onClickHandler = ({ lang }) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    const langValue = lang === locale.ru ? locale.ru : locale.ua;
    if (hasAccess) {
      dispatch(userActionAsync.postUserInfoAsync({ lang: langValue }));
    }

    if (
      pathname.includes(routersLink.users.signIn)
      || pathname.includes(routersLink.users.signUp)
      || pathname.includes(routersLink.users.password_reset)
    ) {
      if (pathname.includes('/ru')) {
        window.history.replaceState(null, null, pathname.replace('/ru', ''));
      } else if (pathname.includes('/ua')) {
        window.history.replaceState(null, null, pathname.replace('/ua', ''));
      }
    }
    handleClose();
  };
  //
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
      <Button
        id="language-menu-button"
        aria-controls={isOpen ? 'language-menu' : null}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : null}
        onClick={handleClickListItem}
        disableRipple
        disabled={postUsersHashIdPending}
        className={cx(classes.langMenuButton, { [classes.openMenu]: isOpen })}
      >
        <Box className={classes.langMenuBox}>
          <div className={classNameFlag} />
          <Typography className={classes.langLabel}>{languageLabel}</Typography>
        </Box>
        <ArrowDownRoundedIcon className={classes.langMenuDropIcon} />
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          open={isOpen}
          className={classes.langMenu}
        >
          {options.map(({ value, label }) => (
            <MenuItem
              key={value}
              disabled={language === value}
              onClick={() => onClickHandler({ lang: value })}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Button>
    </ClickAwayListener>
  );
}

export default LanguageMenu;
