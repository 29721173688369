// Parts
import { asyncTypes } from './asyncTypes';

export const mediaActionAsync = Object.freeze({
  postMediasAsync: data => ({
    type: asyncTypes.POST_MEDIAS_ASYNC,
    payload: data,
  }),
  postProductMediasAsync: data => ({
    type: asyncTypes.POST_PRODUCT_MEDIAS_ASYNC,
    payload: data,
  }),
});
