import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as contentProductStatusLogsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const contentProductStatusLogs = Object.freeze({
  currentPage: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.currentPage], 0),
  filters: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.filters], List()),
  isLoading: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.pending], false),
  list: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.items], List()),
  pageSize: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.reload], false),
  totalCount: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.totalCount], 0),
  sorting: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.sorting], List()),
  settingStatus: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.settingStatus], false),
  settingPending: state => getIn(state, [contentProductStatusLogsKeys.controller, contentProductStatusLogsKeys.contentProductStatusLogsList, contentProductStatusLogsKeys.settingPending], false),
});
