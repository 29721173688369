// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_CONTENT_PRODUCT_STATUS_LOGS_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_CONTENT_PRODUCT_STATUS_LOGS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CONTENT_PRODUCT_STATUS_LOGS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_CONTENT_PRODUCT_STATUS_LOGS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_CONTENT_PRODUCT_STATUS_LOGS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_CONTENT_PRODUCT_STATUS_LOGS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_CONTENT_PRODUCT_STATUS_LOGS_SORTING, callSetToLocalDataWorker);
}

export function* watchersContentProductStatusLogs() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
  ]);
}
