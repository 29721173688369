// Core
import React from 'react';
import { Navigate } from 'react-router-dom';
// Components
import CategoryEdit from './CategoryEdit/CategoryEdit';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';

function CategoryShowPage() {
  const hasAccessToThePage = useAccessList(accessList.categoryEdit);

  if (hasAccessToThePage) {
    return <CategoryEdit mode="edit" />;
  }

  return <Navigate to={pageLinks.categories.all} />;
}

export default CategoryShowPage;
