import { useEffect, useState, useMemo } from 'react';
import { isImmutable } from 'immutable';
import useWindowSize from '../../../hooks/useWindowsResize';

export default function useInfinityScroll({
  listInStore, itemsFromServer, limit, infinityScrollState,
}) {
  const [hasMore, setHasMore] = useState(true);
  const [listToRender, setListToRender] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(limit);
  const size = useWindowSize();
  const getListInStore = useMemo(
    () => isImmutable(listInStore)
      ? listInStore.toJS()
      : listInStore,
    [listInStore],
  );

  useEffect(() => {
    if (listToRender.length > 0) {
      setLoading(false);
    }
  }, [listToRender]);

  useEffect(() => {
    setListToRender([...listToRender, ...getListInStore]);
    if (listToRender.length !== 0 && (itemsFromServer.length === 0 || itemsFromServer.length < limit)) {
      setHasMore(false);
    }
    // eslint-disable-next-line
  }, [getListInStore]);

  useEffect(() => {
    setListToRender(infinityScrollState);
    setHasMore(true);
    setOffset(limit);
    // eslint-disable-next-line
  }, [infinityScrollState]);

  return {
    hasMore,
    size,
    setHasMore,
    listToRender,
    setListToRender,
    loading,
    offset,
    setOffset,
  };
}
