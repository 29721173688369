// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Parts
import Checkbox from '@mui/material/Checkbox';
// Hooks
import { usePrevious } from '../../../../../hooks/usePrevious';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';

const getCheckboxName = name => (`${name}-checkbox`);
function RenderCheckbox(props) {
  const {
    error, isValueChanged, input,
    noMargin, inputValue, setWillBeClear,
    disabled, brandMargin,
  } = props;
  const inputName = input.name.toString();
  const [value, setValue] = useState(input.value);
  const prevValue = usePrevious(value);
  const classes = useStyles(ContentProductEditStyles);
  const classNames = cx(classes.formControlCheck, {
    [classes.formControlCheckSelect]: !noMargin,
    [classes.formControlCheckBrand]: brandMargin,
    [classes.formControlCheckChanged]: isValueChanged,
    [classes.errorTextColor]: error,
  });

  const onChange = (ev) => {
    const isCancelStatusCheckbox = getCheckboxName('cancelStatus') === inputName;
    const isRevisionNoteCheckbox = getCheckboxName('revisionNote') === inputName;
    if (isCancelStatusCheckbox || isRevisionNoteCheckbox) {
      return;
    }
    setValue(ev.target.checked);
    input.onChange(ev.target.checked);
  };

  useEffect(() => {
    setValue(input.value);
    input.onChange(input.value);
  }, [isValueChanged, input.value]);

  useEffect(() => {
    if (value && !inputValue) {
      setWillBeClear(true);
    } else if (prevValue) {
      setWillBeClear(false);
    }
  }, [isValueChanged, value, inputValue, setWillBeClear, prevValue]);

  return (
    <Checkbox
      color="primary"
      className={classNames}
      {...input}
      name={inputName}
      disabled={disabled}
      checked={!!value}
      onChange={onChange}
    />
  );
}

export default RenderCheckbox;
