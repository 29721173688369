import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../engine/core/products/productFeedLogs/actions';
import { selectors } from '../../../../../engine/config/selectors';
import ProductsFeedLogsTable from './Table/ProductsFeedLogsTable';

function ProductFeedLogsModal() {
  const { t } = useTranslation();
  const productFeedLogsShowModal = useSelector(selectors.productFeedLogs.productFeedLogsShowModal);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(actions.getProductFeedLogsRefresh({ showModal: false, hashId: null }));
  };


  return (
    <Modal
      title={t('Детали синхронизации')}
      modalOpen={productFeedLogsShowModal}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      modalMaxWidth="md"
      hideButtons
    >
      <ProductsFeedLogsTable />
    </Modal>
  );
}


export default ProductFeedLogsModal;
