// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  transactionsPaymentList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: false,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  transactionPayment: Map({
    pending: false,
    data: Map(),
  }),
  putTransactionPayment: Map({
    pending: false,
  }),
  transactionsPaymentStatuses: Map({
    pending: false,
    items: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'transactionsPayment',
  transactionsPaymentList: 'transactionsPaymentList',
  transactionPayment: 'transactionPayment',
  transactionsPaymentStatuses: 'transactionsPaymentStatuses',
  data: 'data',
  putTransactionPayment: 'putTransactionPayment',
  currentPage: 'currentPage',
  filters: 'filters',
  putPending: 'putPending',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
});

export const transactionsPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_TRANSACTIONS_PAYMENT_LIST: {
      const {
        items, totalCount,
      } = payload;
      return state
        .setIn([stateKeys.transactionsPaymentList, stateKeys.items], List(items))
        .setIn([stateKeys.transactionsPaymentList, stateKeys.totalCount], totalCount);
    }
    case types.SET_TRANSACTIONS_PAYMENT_LIST_LOADING: {
      const { pending } = payload;
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.pending], pending);
    }
    case types.SET_TRANSACTIONS_PAYMENT_SORTING: {
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.sorting], List(payload));
    }
    case types.SET_TRANSACTIONS_PAYMENT_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.filters], List(filters));
    }
    case types.SET_TRANSACTIONS_PAYMENT_PAGE_SIZE: {
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.pageSize], payload);
    }
    case types.MERGE_TRANSACTIONS_PAYMENT_LIST: {
      return state.mergeIn([stateKeys.transactionsPaymentList], payload);
    }
    case types.MERGE_TRANSACTIONS_PAYMENT_STATUSES: {
      return state.mergeIn([stateKeys.transactionsPaymentStatuses], Map(payload));
    }
    case types.SET_TRANSACTION_PAYMENT_INFO: {
      return state.mergeIn([stateKeys.transactionPayment], payload);
    }
    case types.PUT_TRANSACTION_PAYMENT_INFO: {
      return state.mergeIn([stateKeys.putTransactionPayment], Map(payload));
    }
    case types.SET_TRANSACTIONS_PAYMENT_CURRENT_PAGE: {
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.currentPage], payload);
    }
    case types.SET_TRANSACTIONS_PAYMENT_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.transactionsPaymentList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.transactionsPaymentList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.transactionsPaymentList, stateKeys.filters], List(filters))
        .setIn([stateKeys.transactionsPaymentList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.transactionsPaymentList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.transactionsPaymentList, stateKeys.reload], reload)
        .setIn([stateKeys.transactionsPaymentList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_TRANSACTIONS_PAYMENT_SETTING_STATUS: {
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.settingStatus], payload);
    }
    case types.SET_TRANSACTIONS_PAYMENT_SETTING_PENDING: {
      return state.setIn([stateKeys.transactionsPaymentList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
