// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Link from '@mui/material/Link';
// Parts
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../../../engine/config/selectors';
import { billingActions } from '../../../../../../engine/core/billing/action';
import TimerCountCard from '../../../../../components/TimerCountCard/TimerCountCard';

function LimitBalanceOverModal() {
  const { t } = useTranslation();
  const statusItems = useSelector(selectors.billing.statusItems);
  const statusPending = useSelector(selectors.billing.statusPending);
  const dispatch = useDispatch();
  const {
    partnerOffTime,
    serverTime, creditLimitModalStatus,
    recommendPaymentNotificationSum,
  } = statusItems;
  const localStorageName = 'limitBalanceModal';
  const limitBalanceModalTime = localStorage.getItem(localStorageName);
  const [open, setOpen] = useState(
    creditLimitModalStatus
    && statusPending !== true
    && Boolean(limitBalanceModalTime) === false,
  );
  const TIMER = (1000 * 60 * 60) * 24; // 60 min * 24h
  const hash = useSelector(selectors.router.hash);

  const showPopUp = useCallback(() => {
    if (!statusPending && (hash.toLowerCase() !== '#showinvoice')) {
      setOpen(true);
      localStorage.removeItem(localStorageName);
    }
  }, [hash, statusPending]);

  const hidePopUp = () => {
    setOpen(false);
    localStorage.setItem(localStorageName, Date.now());
  };

  useEffect(() => {
    if (hash.toLowerCase() === '#showinvoice') {
      hidePopUp();
    }
  }, [hash]);

  const onSend = () => {
    dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    setTimeout(() => {
      hidePopUp();
    }, 400);
  };

  useEffect(() => {
    if (creditLimitModalStatus && !limitBalanceModalTime) {
      showPopUp();
    }
  }, [creditLimitModalStatus, limitBalanceModalTime, showPopUp]);

  useEffect(() => {
    if (open && creditLimitModalStatus === false && Boolean(limitBalanceModalTime) === false) {
      setOpen(false);
    }
  }, [limitBalanceModalTime, creditLimitModalStatus, open]);

  useEffect(() => {
    if (limitBalanceModalTime && !open) {
      const interval = setInterval(() => {
        const timePassed = Date.now() - parseInt(localStorage.getItem(localStorageName), 10);
        if (timePassed > TIMER && !open) {
          showPopUp();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [limitBalanceModalTime, open, TIMER, showPopUp]);

  if (partnerOffTime == null) {
    return null;
  }

  return (
    <Modal
      title={t('Уважаемый партнер!')}
      modalOpen={open}
      handleModalSend={onSend}
      sendText={t('Пополнить баланс')}
      handleModalCancel={hidePopUp}
      handleModalToggle={hidePopUp}
    >
      <p>{t('Ваш остаток баланса опустился ниже нуля и вы перешли в кредитный лимит.')} {t('Время кредитного лимита')}&nbsp;<strong><TimerCountCard end={partnerOffTime} start={serverTime} isInModal /></strong>&nbsp;{t('часов.')}</p>
      <p> {t('По истечению этого периода ваши товары будут отключены с')} <Link href="https://allo.ua/">allo.ua</Link></p>
      <p>{t('Для выхода из кредитного лимита пополните ваш счет на')} <strong>{recommendPaymentNotificationSum}</strong> грн. </p>
    </Modal>
  );
}

export default LimitBalanceOverModal;
