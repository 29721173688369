// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';

export function* callGetOrdersStatisticsWorker() {
  yield put(actions.mergeOrdersStatistics({ pending: true }));
  const filters = yield select(selectors.orders.filters);
  const params = {};
  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);
  const response = yield apply(api, api.orders.getOrdersStatistics, [params]);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeOrdersStatistics({
      items: response.data,
      modalOpen: true,
    }));
  }

  yield put(actions.mergeOrdersStatistics({ pending: false }));
}
