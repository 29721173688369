// Core
import { useDispatch, useSelector } from 'react-redux';
// Parts
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButtonStyles } from '../../../../../../components/_Table/TableCell/Styles';
// Engine
import { accessList } from '../../../../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { asyncActions } from '../../../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';

const EditComponent = (props) => {
  const { tableRow } = props;
  const hashId = tableRow.row.hashId;
  const dispatch = useDispatch();
  const hasCategoryEdit = useAccessList(accessList.categoryLogisticsEdit);
  const hasCategoryShow = useAccessList(accessList.categoryLogisticsShow);
  const pendingId = useSelector(selectors.logisticsDataByCategory.itemByHashIdPendingId);
  const pendingHistory = useSelector(selectors.logisticsDataByCategory.logsPending);
  const isCurrentId = pendingId && pendingId === hashId;
  const onClickEdit = () => {
    dispatch(asyncActions.getItemByHashIdAsync({
      hashId,
      mode: 'edit',
    }));
  };
  const onClickShow = () => {
    dispatch(asyncActions.getItemByHashIdAsync({
      hashId,
      mode: 'show',
    }));
  };

  return hasCategoryEdit ? (
    <IconButtonStyles disabled={Boolean(pendingId) || pendingHistory} color="inherit" size="large" onClick={onClickEdit}>
      {isCurrentId ? <CircularProgress size={20} isLoading /> : <ModeOutlinedIcon />}
    </IconButtonStyles>
  ) : hasCategoryShow && (
    <IconButtonStyles disabled={Boolean(pendingId) || pendingHistory} color="inherit" size="large" onClick={onClickShow}>
      {isCurrentId ? <CircularProgress size={20} isLoading /> : <RemoveRedEyeOutlinedIcon />}
    </IconButtonStyles>
  );
};

export default EditComponent;
