import { COLOR } from '../../../containers/App/AppStyles';

const TableFilterStyle = {
  tableCellSelect: {
    padding: 0,
    width: '100%',
    fontSize: 13,
  },
  select: {
    width: '100%',
  },
  inputWrapper: {
    background: '#000',
    '@global': {
      svg: {
        cursor: 'pointer',
      },
      input: {
        cursor: 'text',
        fontSize: 14,
        minHeight: 22,
        '&:placeholder': {
          fontSize: 14,
        },
      },
    },
    '&:first-child': {
      paddingLeft: 24,
    },
    maxWidth: 1,
  },
  inputCalendarFilter: {
    '& .MuiInputBase-root': {
      paddingLeft: '15px',
      paddingRight: '25px',
      minHeight: '28px',
      paddingBottom: '5px',
      cursor: 'pointer',
      '& .MuiInputBase-input': {
        cursor: 'pointer',
        '& ::-webkit-input-placeholder': {
          /* Chrome/Opera/Safari */
          color: COLOR.grey['38'],
          fontSize: 13,
        },
        '& ::-moz-placeholder': {
          /* Firefox 19+ */
          color: COLOR.grey['38'],
          fontSize: 14,
        },
        '& :-ms-input-placeholder': {
          /* IE 10+ */
          color: COLOR.grey['38'],
          fontSize: 14,
        },
        '& :-moz-placeholder': {
          /* Firefox 18- */
          color: COLOR.grey['38'],
          fontSize: 14,
        },
      },
      '&:after': {
        borderColor: COLOR.grey['72'],
      },
      '&.Mui-focused': {
        '& $calendarIcon': {
          color: `${COLOR.controls.dark}`,
        },
        '&:after': {
          borderColor: `${COLOR.controls.dark}`,
        },
      },

    },
  },
  adornmentCalendarIcon: {
    marginRight: '4px',
  },
  adornmentCalendarIconOutlined: {
    marginTop: '-4px',
  },
  calendarIcon: {
    height: '16px',
    width: '16px',
    color: COLOR.grey['38'],
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  iconSmall: {
    fontSize: 18,
  },
  smallButton: {
    height: 20,
    padding: 4,
    width: 20,
  },
  flexGrowOne: {
    flexGrow: 1,
  },
  textFieldNumberHideArrowsChrome: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  textFieldNumberHideArrowsFirefox: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  selectTableHead: {
    '& .MuiInputBase-root.MuiInput-root': {
      color: COLOR.grey['38'],
      '&:before': {
        display: 'none',
      },
      '& .MuiSelect-select.MuiSelect-standard': {
        paddingTop: 7,
        paddingBottom: 7,
      },
    },
    '& .MuiSvgIcon-root': {
      width: '22px',
      height: '22px',
      top: 'calc(50% - 9px)',
      color: COLOR.grey['38'],
      '&:hover': {
        color: COLOR.grey['48'],
      },
    },
    '@global': {
      'label + .MuiInputBase-root.MuiInput-root': {
        marginTop: 0,
      },
    },
  },
};

export default TableFilterStyle;
