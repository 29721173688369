import { styled } from '@mui/system';

import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { COLOR } from '../../../../../../containers/App/AppStyles';
import RefreshVerticalIcon from '../../../../../../icons/RefreshVerticalIcon';

export const UpdateControlsBlock = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px 1px 3px 9px;
  border: 1px solid ${COLOR.grey['8']};
  background: ${COLOR.bgDefault};
`;

export const RefreshButton = styled(ButtonWithIcon)`
  background: none;
  color: ${COLOR.black['72']};
  min-width: 20px;
  padding: 0;
  box-shadow: none;
  transition: none;

  &.Mui-disabled {
    background-color: transparent;
    box-shadow: none;
    color: ${COLOR.black.default};
    opacity: .2;
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  svg {
    background: none;
  }
`;

export const RefreshIconStyled = styled(RefreshVerticalIcon)`
  width: 24px;
  height: 24px;
`;
