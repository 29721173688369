import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSelect from '../../../../components/_Form/Selects/FormSelect/FormSelect';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import { selectors } from '../../../../../engine/config/selectors';

const SelectUsers = (props) => {
  const usersRolesAdminAndManager = useSelector(selectors.partner.listUsersContext);
  const dispatch = useDispatch();
  const { showMode, input: { value, ...restInput } } = props;
  useEffect(() => {
    dispatch(partnerAsyncAction.getUsersContextListAsync({
      idRole: '1,2',
      context: 'select',
    }));
  }, [dispatch]);
  return (
    <FormSelect
      {...props}
      input={{
        ...restInput,
        value: +value,
      }}
      disabled={showMode}
      variant="standard"
      fullWidth
      options={usersRolesAdminAndManager}
    />
  );
};

export default SelectUsers;
