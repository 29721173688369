export const types = Object.freeze({
  CLEAR_NOTIFICATION_MESSAGES: 'CLEAR_NOTIFICATION_MESSAGES',
  PROGRESS_LOADING_CONTRACT: 'PROGRESS_LOADING_CONTRACT',
  LOADING: 'LOADING',
  IN_APPROVE: 'IN_APPROVE',
  SET_SIDEBAR_ACTIVE_MENU: 'SET_SIDEBAR_ACTIVE_MENU',
  SET_SIDEBAR_OPEN: 'SET_SIDEBAR_OPEN',
  SET_CURRENT_FILTERS_TYPE_LIST: 'SET_CURRENT_FILTERS_TYPE_LIST',
  CLEAR_CURRENT_FILTERS_TYPE_LIST: 'CLEAR_CURRENT_FILTERS_TYPE_LIST',
  SET_CURRENT_FILTERS_NAME_LIST: 'SET_CURRENT_FILTERS_NAME_LIST',
  CLEAR_CURRENT_FILTERS_NAME_LIST: 'CLEAR_CURRENT_FILTERS_NAME_LIST',
  MERGE_SHOULD_RENDER_REFRESH: 'MERGE_SHOULD_RENDER_REFRESH',
  SET_INITIAL_APPLICATION_STATE: 'SET_INITIAL_APPLICATION_STATE',
  SHOW_PRODUCT_STATUS_PANEL_IN_TABLES: 'showProductStatusPanelInTables',
  MERGE_SERVICE_SUPPORT_MODAL: 'MERGE_SERVICE_SUPPORT_MODAL',
  SET_CONTENT_PRODUCT_SIDEBAR_ACTIVE_MENU: 'SET_CONTENT_PRODUCT_SIDEBAR_ACTIVE_MENU',
  SET_JOYRIDE_RUN: 'SET_JOYRIDE_RUN',
  SET_STEP_INDEX: 'SET_STEP_INDEX',
  SET_STEPS: 'SET_STEPS',
  SET_IMPORT_PAGE_TYPE: 'SET_IMPORT_PAGE_TYPE',
  SET_IMPORT_PAGE_FILE_TYPE: 'SET_IMPORT_PAGE_FILE_TYPE',
  SET_OPEN_BACKDROP_OVERLAY: 'SET_OPEN_BACKDROP_OVERLAY',
  SET_IMPORT_XLSX_FILE_POND: 'SET_IMPORT_XLSX_FILE_POND',
  SET_SELECT_IS_OPEN: 'SET_SELECT_IS_OPEN',
  SET_POPUP: 'SET_POPUP',
});
