import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import ModalFooter from '../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import Modal from '../../../containers/Modals/Modal/Modal';
import FormTextFieldInput from './input/FormTextFieldInput';
import { validators } from '../../../../_helpers/validationsForm';
import { useStyles } from '../../../hooks/useStyles';
import { FormSelectStyle } from '../../../components/_Form/Selects/FormSelect/FormSelectStyle';
import { cancellationReasons } from '../../../../engine/config/serviceRequestsStatuses';
import { COLOR } from '../../../containers/App/AppStyles';

const selectSxStyle = {
  '& fieldset': {
    borderColor: 'rgba(111,126,140,0.24) !important',
    borderWidth: '1px !important',
  },
  '&.MuiInputBase-root': {
    fontSize: '14px !important',
  },
};
const selectSxInputStyle = {
  fontSize: '14px',
  '&.Mui-focused': {
    color: COLOR.black[38],
  },
};

export function ConfirmCancelRequestModal({
  onModalOpen, open, isSendingData, onCancelRequest,
}) {
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationComment, setCancellationComment] = useState('');

  const classes = useStyles(FormSelectStyle);
  const handleChange = (event) => {
    setCancellationReason(event.target.value);
  };
  const isButtonDisabled = () => {
    if (cancellationReason === '') {
      return true;
    }
    if (cancellationReason === cancellationReasons.CANCEL_REASON_OTHER && cancellationComment === '') {
      return true;
    }
    return false;
  };
  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              disabled={isButtonDisabled() || isSendingData}
              variant="contained"
              color="primary"
              onClick={() => {
                onCancelRequest(cancellationReason, cancellationComment);
                onModalOpen(false);
              }}
            >
              {'Скасувати заявку'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalFooter>
  );

  return (
    <>
      <Modal
        title={'Бажаєте скасувати заявку на послугу АЛЛО Express?'}
        modalOpen={open}
        handleModalToggle={() => {
          onModalOpen(false);
        }}
        hideButtons
        fullWidth
        noPadding
        modalMaxWidth="sm"
        modalFooterComponent={modalFooterComponent}
      >
        <Grid marginBottom={'15px'} container justifyContent="flex-end" flexDirection="column">
          <Grid sx={{ p: '20px 20px' }} item sm={12}>
            <Box className={classes.formSelectWrapper}>
              <FormControl
                sx={{
                  fontSize: '14px',
                }}
                className={classes.wrapperFullWidth}
              >
                <InputLabel sx={{ top: '-8px', ...selectSxInputStyle }}>Причина скасування</InputLabel>
                <Select
                  size="small"
                  sx={selectSxStyle}
                  onChange={e => handleChange(e)}
                  fullWidth
                  label={''}
                  placeholder=""
                  value={cancellationReason}
                  variant="outlined"
                >
                  <MenuItem style={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_CONDITION}>
                    Не влаштовують умови організації перевезень і видачі замовлень
                  </MenuItem>
                  <MenuItem style={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_LOCATION}>
                    Немає пунктів прийому/видачі замовлень у моєму місті
                  </MenuItem>
                  <MenuItem style={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_OTHER}>Інше</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid sx={{ p: '10px 20px 0' }} item>
            {cancellationReason === cancellationReasons.CANCEL_REASON_OTHER && (
              <>
                <FormTextFieldInput
                  variant="outlined"
                  name={'email'}
                  focused
                  onChange={(e) => {
                    setCancellationComment(e.target.value);
                  }}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  value={cancellationComment}
                  label="Коментар"
                  validate={validators.maxLength50}
                />
                <Typography variant="body2" color={COLOR.black[38]} align="right">
                  {cancellationComment.length}/50 символів
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
