// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setResetPasswordSubmitting(submitting) {
    return getActionCreator(types.SET_RESET_PASSWORD_SUBMITTING, submitting);
  },
});
