// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ArrowDownRoundedIcon(props) {
  return (
    <SvgIcon className="arrow-down-rounded-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6007 5.39935C12.9326 5.7313 12.9326 6.26949 12.6007 6.60143L8.60065 10.6014C8.2687 10.9334 7.73051 10.9334 7.39857 10.6014L3.39857 6.60143C3.06662 6.26949 3.06662 5.7313 3.39857 5.39935C3.73051 5.0674 4.2687 5.0674 4.60065 5.39935L7.99961 8.79831L11.3986 5.39935C11.7305 5.0674 12.2687 5.0674 12.6007 5.39935Z" fill="inherit" />
    </SvgIcon>
  );
}

export default ArrowDownRoundedIcon;
