// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetPaymentTypes() {
  yield put(actions.mergePaymentTypes({ pending: true }));
  const language = localStorage.getItem('language');

  const { partnerPaymentTypes: statusesKey, partnerPaymentTypesLastUpdate: statusesLastUpdateKey } = localData.keysObj;

  const statuses = `${statusesKey}_${language}`;
  const statusesLastUpdate = `${statusesLastUpdateKey}_${language}`;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [statusesLastUpdate]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.paymentTypesLastUpdate);

  let response;
  const data = {
    pending: false,
  };
  switch (true) {
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.partners.getPaymentTypes);
      yield apply(localData, localData.setItem, [statusesLastUpdate, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      const dataFromLocal = yield apply(localData, localData.getItem, [statuses]);
      if (!dataFromLocal) {
        response = yield apply(api, api.partners.getPaymentTypes);
      }
      data.items = List(dataFromLocal?.items);
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data.items = List(response.data);

    yield apply(localData, localData.setItem, [statuses, { items: response.data }]);
  }

  yield put(actions.mergePaymentTypes({
    ...data,
    pending: false,
  }));
}
