// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI
import Chip from '@mui/material/Chip';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { COLOR } from '../../../../containers/App/AppStyles';

function ChipFormatter(props) {
  const {
    value, frontTranslate, onClick, clickable,
    itemsToShow, moreLinkButton, showEditButton,
  } = props;
  const { t } = useTranslation();
  const [showCounter, setShowCounter] = useState(itemsToShow);
  const normalizedValue = Array.isArray(value) ? value : [value];
  const itemsToShowArr = normalizedValue.slice(0, showCounter);

  const setCounter = () => {
    setShowCounter(value.length);
  };

  if (moreLinkButton) {
    return (
      <>
        {normalizedValue.map((item) => {
          const chipLabel = () => {
            const labelText = frontTranslate ? (t(item.label)) : item.label;
            return (
              <>
                {(item?.isRequiredLk) && (<Box component="span" sx={{ color: COLOR.brand.dark, mr: '3px' }}>*</Box>)}
                {(item?.isRequiredMg) && (<Box component="span" sx={{ color: COLOR.controls.dark, mr: '3px' }}>*</Box>)}
                {labelText}
              </>
            );
          };
          return (
            item?.label ? (
              <NavLink to={moreLinkButton}>
                <Chip
                  sx={{ m: '4px' }}
                  variant="outlined"
                  key={`${item.label}_${item?.value}`}
                  onClick={onClick}
                  clickable={clickable}
                  label={chipLabel()}
                  required={item?.isRequiredLk}
                />
              </NavLink>
            ) : null
          );
        })}
        { showEditButton ? (
          <NavLink to={moreLinkButton}>
            <Box
              sx={{
                m: '4px', padding: '2px 6px', fontSize: '16px', letterSpacing: '.5px',
              }}
              component="span"
            >
              ...
            </Box>
          </NavLink>
        ) : null}
      </>
    );
  }

  return (
    <>
      {itemsToShowArr.map((item, idx) => item?.label ? (
        <Chip
          sx={{ m: '4px' }}
          variant="outlined"
          key={idx}
          onClick={onClick}
          clickable={clickable}
          label={frontTranslate ? t(item.label) : item.label}
        />
      ) : null)}
      {(itemsToShowArr.length === itemsToShow) && (value.length > itemsToShow) && (
        <Chip
          sx={{
            padding: 0,
            margin: '4px',
            '& .MuiChip-label': {
              margin: 0,
              padding: 0,
              lineHeight: 1,
              height: '24px',
            },
          }}
          variant="outlined"
          label={<><KeyboardArrowDownRoundedIcon /></>}
          onClick={setCounter}
        />
      )}
    </>
  );
}

ChipFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  ]),
  frontTranslate: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  itemsToShow: PropTypes.number,
  moreLinkButton: PropTypes.string,
  showEditButton: PropTypes.bool,
};

ChipFormatter.defaultProps = {
  value: [],
  frontTranslate: false,
  clickable: false,
  itemsToShow: 5,
};

export default ChipFormatter;
