// Core
import React from 'react';
import cx from 'classnames';
import { objectOf, string, node } from 'prop-types';
import { withStyles } from '@mui/styles';

function ButtonWrapper(props) {
  const { children, classes } = props;
  return (
    <div className={cx('pt-1', 'text-md-center', classes.buttonWrapper)}>
      {children}
    </div>
  );
}

ButtonWrapper.propTypes = {
  children: node.isRequired,
  classes: objectOf(string),
};

ButtonWrapper.defaultProps = {
  classes: {},
};

const styles = () => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    '@global': {
      button: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
});

export default withStyles(styles)(ButtonWrapper);
