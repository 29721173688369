// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getFaqListAsync(params) {
    return getActionCreator(asyncTypes.GET_FAQ_LIST_ASYNC, params);
  },
  putFaqSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_FAQ_SETTINGS_ASYNC, param);
  },
  getFaqStatusesList(params) {
    return getActionCreator(asyncTypes.GET_FAQ_STATUSES_LIST_ASYNC, params);
  },
  getFaqInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_FAQ_INITIAL_STATE_ASYNC, path);
  },
  getFaqItemByHashId(hashId) {
    return getActionCreator(asyncTypes.GET_FAQ_ITEM_BY_HASH_ID_ASYNC, hashId);
  },
  putFaqItemByHashId(params) {
    return getActionCreator(asyncTypes.PUT_FAQ_ITEM_BY_HASH_ID_ASYNC, params);
  },
  deleteFaqItemByHashId(params) {
    return getActionCreator(asyncTypes.DELETE_FAQ_BY_HASH_ID_ASYNC, params);
  },
});
