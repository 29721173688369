// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
// Parts
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';
import CreateCellComponent from './components/CreateCellComponent';

function DxPagingState(props) {
  const { selectionOffFlag } = props;
  return (
    <TableSelection
      cellComponent={CreateCellComponent(selectionOffFlag)}
      showSelectAll
    />
  );
}

DxPagingState.propTypes = {
  selectionOffFlag: PropTypes.string,
};

export default memo(DxPagingState);
