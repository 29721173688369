// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  list: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    selection: List(),
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    sendQuery: false,
  }),
  productMultipleEditing: Map({
    openForm: false,
  }),
  putProductMultipleEditing: Map({
    pending: false,
  }),
  productMultipleEditingConfirmModal: Map({
    open: false,
  }),
  statusesList: Map({
    pending: true,
    items: List(),
    flow: Map(),
  }),
  statusesElaborationList: Map({
    pending: true,
    items: List(),
    flow: Map(),
  }),
  itemByHashId: {
    item: Map(),
    pending: true,
    putPending: false,
  },
  refresh: Map({
    pending: false,
  }),
  parse: Map({
    pending: false,
  }),
  importModal: Map({
    isOpen: false,
    hideSubmitButton: false,
  }),
  productsDownloadPending: false,
  productsDownloadForceAction: Map({
    forceAction: null,
    type: null,
  }),
  productsFullDownloadPending: false,
  productsImportPending: false,
  productsStatistic: Map({
    items: Map(),
    pending: false,
    modalOpened: false,
  }),
  productsFeedStatistic: Map({
    statistic: Map(),
    pending: false,
  }),
  productsFeedExportPending: false,
});

export const stateKeys = Object.freeze({
  sendQuery: 'sendQuery',
  hideSubmitButton: 'hideSubmitButton',
  reload: 'reload',
  parse: 'parse',
  controller: 'feedsProducts',
  list: 'list',
  putProductMultipleEditing: 'putProductMultipleEditing',
  productMultipleEditing: 'productMultipleEditing',
  selection: 'selection',
  currentPage: 'currentPage',
  statusesList: 'statusesList',
  statusesElaborationList: 'statusesElaborationList',
  filters: 'filters',
  productMultipleEditingConfirmModal: 'productMultipleEditingConfirmModal',
  items: 'items',
  refresh: 'refresh',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  itemByHashId: 'itemByHashId',
  putPending: 'putPending',
  flow: 'flow',
  item: 'item',
  productsDownloadPending: 'productsDownloadPending',
  productsFullDownloadPending: 'productsFullDownloadPending',
  productsDownloadForceAction: 'productsDownloadForceAction',
  productsImportPending: 'productsImportPending',
  importModal: 'importModal',
  isOpen: 'isOpen',
  productsStatistic: 'productsStatistic',
  productsFeedStatistic: 'productsFeedStatistic',
  statistic: 'statistic',
  productsFeedExportPending: 'productsFeedExportPending',
});

export const feedsProductsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_FEEDS_PRODUCTS_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.productsDownloadPending], payload);
    }
    case types.SET_FEEDS_PRODUCTS_PARSE_PENDING: {
      return state.setIn([stateKeys.parse, stateKeys.pending], payload);
    }
    case types.SET_FEEDS_PRODUCTS_DOWNLOAD_FORCE_ACTION: {
      return state.setIn([stateKeys.productsDownloadForceAction], Map(payload));
    }
    case types.SET_FEEDS_PRODUCTS_FULL_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.productsFullDownloadPending], payload);
    }
    case types.SET_FEEDS_PRODUCTS_IMPORT_PENDING: {
      return state.setIn([stateKeys.productsImportPending], payload);
    }
    case types.SET_FEEDS_PRODUCTS_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.list, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.list, stateKeys.pending], pending)
        .setIn([stateKeys.list, stateKeys.items], List(items))
        .setIn([stateKeys.list, stateKeys.sendQuery], false)
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount);
    }
    case types.SET_FEEDS_PRODUCTS_RELOAD: {
      return state.setIn([stateKeys.list, stateKeys.reload], payload);
    }
    case types.SET_FEEDS_PRODUCTS_SEND_QUERY: {
      return state.setIn([stateKeys.list, stateKeys.sendQuery], payload);
    }
    case types.SET_FEEDS_PRODUCTS_IMPORT_MODAL_IS_OPEN: {
      return state.mergeIn([stateKeys.importModal], fromJS(payload));
    }
    case types.SET_FEEDS_PRODUCTS_MULTIPLE_EDITING_IS_OPEN: {
      return state.mergeIn([stateKeys.productMultipleEditing], Map(payload));
    }
    case types.SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE: {
      return state.setIn([stateKeys.importModal], initialState.get(stateKeys.importModal));
    }
    case types.SET_FEEDS_PRODUCTS_SORTING: {
      return state.setIn([stateKeys.list, stateKeys.sorting], List(payload));
    }
    case types.SET_FEEDS_PRODUCTS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.list, stateKeys.filters], List(filters));
    }
    case types.SET_FEEDS_PRODUCTS_PAGE_SIZE: {
      return state.setIn([stateKeys.list, stateKeys.pageSize], payload);
    }
    case types.SET_FEEDS_PRODUCTS_CURRENT_PAGE: {
      return state.setIn([stateKeys.list, stateKeys.currentPage], payload);
    }
    case types.SET_FEEDS_PRODUCTS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.list, stateKeys.sorting]);
      return state
        .setIn([stateKeys.list, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.list, stateKeys.filters], List(filters))
        .setIn([stateKeys.list, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.list, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.list, stateKeys.reload], reload)
        .setIn([stateKeys.list, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_FEEDS_PRODUCTS_SELECTION: {
      return state.setIn([stateKeys.list, stateKeys.selection], List(payload));
    }
    case types.SET_PUT_FEEDS_PRODUCTS_MULTIPLE_EDITING: {
      return state.mergeIn([stateKeys.putProductMultipleEditing], Map(payload));
    }
    case types.SET_FEEDS_PRODUCTS_SETTING_STATUS: {
      return state.setIn([stateKeys.list, stateKeys.settingStatus], payload);
    }
    case types.SET_FEEDS_PRODUCTS_SETTING_PENDING: {
      return state.setIn([stateKeys.list, stateKeys.settingPending], payload);
    }
    case types.MERGE_FEEDS_PRODUCTS_STATUSES_LIST: {
      return state.mergeIn([stateKeys.statusesList], Map(payload));
    }
    case types.MERGE_FEEDS_PRODUCTS_STATUSES_ELABORATION_LIST: {
      return state.mergeIn([stateKeys.statusesElaborationList], Map(payload));
    }
    case types.MERGE_FEEDS_PRODUCTS_MULTIPLE_EDITING_CONFIRM_MODEL: {
      return state.mergeIn([stateKeys.productMultipleEditingConfirmModal], Map(payload));
    }
    case types.MERGE_PRODUCT_REFRESH: {
      return state.mergeIn([stateKeys.refresh], Map(payload));
    }
    case types.MERGE_FEEDS_PRODUCTS_ITEM_BY_HASH_ID: {
      return state.mergeIn([stateKeys.itemByHashId], Map(payload));
    }
    case types.MERGE_FEEDS_PRODUCTS_STATISTIC: {
      return state.mergeIn([stateKeys.productsStatistic], Map(payload));
    }
    case types.SET_PRODUCTS_FEED_STATISTIC: {
      return state.set(stateKeys.productsFeedStatistic, payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_PRODUCTS_FEED_EXPORT_PENDING: {
      return state.setIn([stateKeys.productsFeedExportPending], payload);
    }
    default: {
      return state;
    }
  }
};
