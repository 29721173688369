// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Data
import { formName, formFields } from '../../../../../_helpers/data/pages/handbook/categories/form';

export function* callGetCategoryItemByHashIdWorker({ payload: hashId }) {
  let response;
  const data = {
    item: {},
    pending: true,
  };

  yield put(actions.setCategoryItem(data));

  try {
    response = yield apply(api, api.categories.getCategoryItemByHashId, [hashId]);
  } catch {
    data.pending = false;
  }

  if (response && response.status >= 200 && response.status < 400) {
    data.item = response.data;

    const fields = [
      formFields.active,
      formFields.hasChildren,
      formFields.hashId,
      formFields.fillingPercent,
      formFields.idMagentoCategory,
      formFields.idMagentoCategoryParent,
      formFields.name,
      formFields.parentName,
      formFields.partnerOfferPercent,
    ];

    const formData = {
      [formFields.active]: data.item.active,
      [formFields.hasChildren]: data.item.hasChildren,
      [formFields.hashId]: data.item.hashId,
      [formFields.fillingPercent]: data.item.fillingPercent,
      [formFields.idMagentoCategory]: data.item.idMagentoCategory,
      [formFields.idMagentoCategoryParent]: data.item.idMagentoCategoryParent,
      [formFields.name]: data.item.name,
      [formFields.parentName]: data.item.parentName,
      [formFields.partnerOfferPercent]: data.item.partnerOfferPercent,
    };

    yield put(initialize(formName, formData, fields));
  }

  data.pending = false;

  yield put(actions.setCategoryItem(data));
}
