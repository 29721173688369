import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { COLOR } from '../../../../../containers/App/AppStyles';


const StepIconRoot = styled('div')(({ ownerState }) => ({
  color: COLOR.grey['38'],
  display: 'flex',
  height: '24px',
  width: '24px',
  borderRadius: '50%',
  alignItems: 'center',
  border: `1px solid ${COLOR.grey['38']}`,
  justifyContent: 'center',
  fontSize: '12px',
  fontWeight: '600',
  ...(ownerState.active && {
    color: COLOR.success,
    borderColor: COLOR.success,
  }),
  ...(ownerState.completed && {
    color: '#ffffff',
    borderColor: COLOR.success,
    backgroundColor: COLOR.success,
  }),
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
}));
const StepperIcon = (props) => {
  const {
    active, completed, className, icon,
  } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? <CheckIcon /> : icon}
    </StepIconRoot>
  );
};

export default StepperIcon;
