// Core
import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isImmutable, List } from 'immutable';

// Engine
import { FilteringState } from '@devexpress/dx-react-grid';

function DxFilteringState(props) {
  const {
    filteringExtensions, filters, defaultFilters, filtersAction, onFiltersChange,
  } = props;
  const dispatch = useDispatch();
  const normalizeColumnExtensions = useMemo(() => (
    isImmutable(filteringExtensions)
      ? filteringExtensions.toJS()
      : filteringExtensions
  ), [filteringExtensions]);

  const normalizeFilters = useMemo(() => (
    isImmutable(filters)
      ? filters.toJS()
      : filters
  ), [filters]);
  const normalizeDefaultFilters = useMemo(() => (
    isImmutable(defaultFilters)
      ? defaultFilters.toJS()
      : defaultFilters
  ), [defaultFilters]);

  const setFilters = (filter) => {
    if (onFiltersChange) {
      onFiltersChange(filter);
    } else {
      dispatch(filtersAction(filter));
    }
  };

  return (
    <FilteringState
      columnExtensions={normalizeColumnExtensions}
      onFiltersChange={setFilters}
      filters={normalizeFilters}
      defaultFilters={normalizeDefaultFilters}
    />
  );
}

DxFilteringState.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]).isRequired,
  defaultFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]).isRequired,
  filteringExtensions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onFiltersChange: PropTypes.func,
  filtersAction: PropTypes.func,
};

export default memo(DxFilteringState);
