// Core
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

// UI
import { pageLinks } from '../../../../engine/config/routes';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';

// Engine
import { accessList } from '../../../../engine/config/access';
import { useAccessList } from '../../../hooks/useAccessList';

function AddButton() {
  const { t } = useTranslation();
  const hasAccessCreate = useAccessList(accessList.partnerCreate);
  return hasAccessCreate ? (
    <NavLink to={pageLinks.partner.add}>
      <ButtonWithIcon fullWidth text={t('Добавить партнера')}>
        <AddCircleIcon />
      </ButtonWithIcon>
    </NavLink>
  ) : null;
}

export default AddButton;
