// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { api } from '../../../../config/api';
import { locale } from '../../../../init/i18n';
// Data
import { formFields, formName } from '../../../../../ui/page/User/UserSelf';
import { uiActions } from '../../../ui/action';

export function* callGetUsersSelfWorker({ payload: refreshForAutoMatching }) {
  // TODO set pending true before request
  const response = yield apply(api, api.user.getUserInfo);

  if (response && response.status >= 200 && response.status < 400) {
    const userInfo = response.data;
    const fixedAdditionalEmails = userInfo.additionalEmails && userInfo.additionalEmails.map(item => (
      { value: item.email }
    ));
    const formData = {
      [formFields.fullName]: userInfo.name,
      [formFields.email]: userInfo.email,
      [formFields.phone]: userInfo.phone && parseInt(userInfo.phone, 10).toString().length === 12 ? userInfo.phone : '380',
      [formFields.viber]: userInfo.viber ?? '380',
      [formFields.mailEnabled]: userInfo.mailEnabled,
      [formFields.additionalEmails]: fixedAdditionalEmails,
      [formFields.locale]: locale[userInfo.locale] || locale.uk,
    };

    yield put(initialize(formName, formData, Object.keys(formData)));

    const settings = response?.data?.settings;

    if (settings?.autoFeedSetInfo?.id && refreshForAutoMatching) {
      yield put(uiActions.setPopup({ data: settings?.autoFeedSetInfo, feeds: settings?.autoFeedSetInfo?.feeds }));
    }
    if (settings?.autoFeedSetInfo?.length === 0) {
      yield put(uiActions.setPopup({ feeds: undefined }));
    }
  }
  // TODO set pending false before request
}
