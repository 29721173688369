// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetOrdersDownloadWorker({ payload }) {
  const isFullOrdersDownload = payload.context === 'fullOrders';
  yield put(setIsLoading(isFullOrdersDownload, true));

  const filters = yield select(selectors.orders.filters);
  const sorting = yield select(selectors.orders.sorting);

  const params = {
    ...payload,
    orderBy: convertSortingObjectToUrlParams(sorting.toJS()),
  };

  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  const response = yield apply(api, api.orders.getOrdersDownload, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { message, errors } = responseData;
        yield put(setErrorMessage(errors || message, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      default: {
        getFileFromBlob('orders.csv', response);
        break;
      }
    }
  }

  yield put(setIsLoading(isFullOrdersDownload, false));
}

function setIsLoading(ifStatement, isLoading) {
  return ifStatement
    ? actions.setIsDownloadFullOrdersLoading(isLoading)
    : actions.setOrdersDownloadCsv(isLoading);
}
