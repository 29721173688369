// Core
import React, { useEffect, useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
// UI
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
// Icons
import CheckIcon from '@mui/icons-material/Check';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CloseIcon from '@mui/icons-material/Close';
// Components
import EditTableCellWrapper from '../../_Table/TableCell/components/EditTableCellWrapper/EditTableCellWrapper';
import TooltipIcon from '../../../containers/TooltipIcon';
// Data
import { tableFiltersTypes } from '../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
// Helpers
import { getDate } from '../../../../_helpers/getDate';
// Styles
import confirmButtonsStyles from './ConfirmButtonsStyles';

const useStyles = makeStyles(confirmButtonsStyles);
function ConfirmButtons(props) {
  const {
    children, disabled, disabledOkButton, value, error, success, required,
    type, setDefaultAfterReject, notEmpty, onConfirm, onReject, withConfirm,
    valuesPlain, activeStatusAction, activeStatus, color, withId, confirmTooltip,
    initValue, showDeleteButton, onClickFilter, isStringTypeValue,
  } = props;
  const normalizeValue = get(value, withId ? 'id' : 'value', value);
  const [active, setActive] = useState(activeStatus);
  const [currentValue] = useState(normalizeValue);
  const [currentValuesPlain, setCurrentValuesPlain] = useState(valuesPlain);
  const [valueWasChanged, setValueWasChanged] = useState(false);
  const [successValue, setSuccessValue] = useState(success);
  const [errorValue, setErrorValue] = useState(error || (notEmpty && normalizeValue === null));
  const classes = useStyles();
  const dateFormat = 'YYYY-MM-DD';
  const activeHandlerReturn = status => activeStatusAction ? activeStatusAction(status) : setActive(status);

  const handleOk = (handler) => {
    switch (type) {
      case tableFiltersTypes.select: {
        if (currentValue === '' && normalizeValue === '') {
          activeHandlerReturn(false);
        } else {
          handler(normalizeValue);
        }
        break;
      }
      case tableFiltersTypes.date: {
        if (currentValue === getDate(normalizeValue, dateFormat)) {
          activeHandlerReturn(false);
        } else {
          handler(normalizeValue);
        }
        break;
      }
      default: {
        handler(value);
        break;
      }
    }
  };

  const handleClear = () => {
    onConfirm(type === 'multipleSelect' ? [] : '');
  };

  const handleClose = () => {
    setValueWasChanged(false);
    onReject(initValue || value);
    activeHandlerReturn(false);

    if (setDefaultAfterReject) {
      setErrorValue(false);
    } else if (normalizeValue === null) {
      setErrorValue(true);
    }
  };


  useEffect(() => {
    if (!isEqual(currentValue, normalizeValue)) {
      setSuccessValue(false);
      setErrorValue(false);
      setValueWasChanged(true);
    } else if (valueWasChanged && isEqual(currentValue, normalizeValue)) {
      setValueWasChanged(false);
    }
  }, [currentValue, valueWasChanged, normalizeValue]);

  useEffect(() => {
    setErrorValue(error);
  }, [error]);

  useEffect(() => {
    setCurrentValuesPlain(valuesPlain);
  }, [valuesPlain]);

  useEffect(() => {
    setSuccessValue(success);
  },
  [success]);
  useEffect(() => {
    setActive(activeStatus);
  }, [activeStatus]);

  useEffect(() => {
    if (success && isEqual(currentValue, normalizeValue)) {
      setSuccessValue(true);
      setValueWasChanged(false);
      setCurrentValuesPlain(valuesPlain);
    }
  }, [currentValue, success, normalizeValue, valuesPlain]);
  const childrenCount = Array.isArray(children) && children.length;
  const emptyValue = currentValuesPlain === '' || currentValuesPlain === null;
  const currentValueIsEmptyArray = Array.isArray(currentValue) && isEmpty(currentValue);
  // eslint-disable-next-line valid-typeof
  const currentTypeBoolIsValid = (type === tableFiltersTypes.boolean) && typeof normalizeValue !== (isStringTypeValue ? 'string' : 'number');
  const childItem = Array.isArray(currentValuesPlain) ? currentValuesPlain.map(item => <span key={uuidv4()}>{item}</span>) : currentValuesPlain;
  const onClick = () => activeHandlerReturn(true);
  const disableConfirmButton = disabledOkButton || currentTypeBoolIsValid || !valueWasChanged;
  const onOkClick = () => handleOk(onConfirm);

  const childrenContainerClassNames = cx(classes.select, {
    [classes.selectSuccess]: successValue,
    [classes.selectError]: errorValue,
  });
  const selectWidthConfirmCheckClassNames = cx(classes.selectWidthConfirmCheck, {
    [classes.selectWidthConfirmDisable]: disableConfirmButton,
  });
  return (
    <>
      {!active && withConfirm
        ? (
          <EditTableCellWrapper
            onClick={onClick}
            color={color}
            success={successValue}
            required={required && emptyValue}
          >
            {childItem}
          </EditTableCellWrapper>
        )
        : (
          <div className={classes.selectWidthConfirm}>
            <div className={childrenContainerClassNames}>
              {children}
            </div>
            {children && childrenCount !== 0 && withConfirm
            && (
              <ButtonGroup className={classes.selectWidthConfirmWrap} disabled={disabled} size="small" variant="contained">
                <Button
                  sx={{ padding: '0px' }}
                  className={selectWidthConfirmCheckClassNames}
                  disabled={disableConfirmButton}
                  onClick={onOkClick}
                >
                  <TooltipIcon text={confirmTooltip}>
                    <CheckIcon />
                  </TooltipIcon>
                </Button>
                <Button sx={{ padding: '0px' }} className={classes.selectWidthConfirmClose} onClick={handleClose}>
                  <CloseIcon />
                </Button>
                { type && (currentValue || currentValue === 0) && !currentValueIsEmptyArray && showDeleteButton
                  && (
                    <Button sx={{ padding: '0px' }} className={classes.selectWidthConfirmRemove} onClick={handleClear}>
                      <DeleteForeverOutlinedIcon />
                    </Button>
                  )
                }
                {onClickFilter && (
                  <Button sx={{ padding: '0px' }} className={classes.selectWidthConfirmCheck} onClick={onClickFilter}>
                    <FilterAltOutlinedIcon />
                  </Button>
                )}
              </ButtonGroup>
            )
            }
          </div>
        )}
    </>
  );
}

ConfirmButtons.propTypes = {
  children: PropTypes.node.isRequired,
  onClickFilter: PropTypes.func,
  disabled: PropTypes.bool,
  disabledOkButton: PropTypes.bool,
  success: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  error: PropTypes.bool,
  notEmpty: PropTypes.bool,
  setDefaultAfterReject: PropTypes.bool,
  withId: PropTypes.bool,
  onConfirm: PropTypes.func,
  activeStatus: PropTypes.bool,
  activeStatusAction: PropTypes.func,
  onReject: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  initValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  withConfirm: PropTypes.bool,
  required: PropTypes.bool,
  valuesPlain: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  type: PropTypes.string,
  color: PropTypes.string,
  confirmTooltip: PropTypes.string,
  isStringTypeValue: PropTypes.bool,
};

ConfirmButtons.defaultProps = {
  disabledOkButton: false,
  notEmpty: false,
  required: false,
  setDefaultAfterReject: false,
  onConfirm: () => {},
  onReject: () => {},
  withConfirm: true,
  showDeleteButton: true,
  valuesPlain: '',
  activeStatus: false,
  withId: false,
  color: null,
};

export default memo(ConfirmButtons);
