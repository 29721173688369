// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';

export function* callGetPartnersListWorker({ payload }) {
  const isContextMode = payload.context === 'select' || payload.context === 'select_allo_express_requests';

  if (isContextMode) {
    yield put(partnerActions.setPartnerListContext({ pending: true }));
  } else {
    yield put(partnerActions.setPartnersList({ pending: true }));
  }
  const response = yield apply(api, api.partner.getPartnersList, [payload]);

  let data = {
    items: [],
    totalCount: 0,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
  }

  if (isContextMode) {
    yield put(partnerActions.setPartnerListContext({ pending: false, items: data.items }));
    return;
  }

  yield put(partnerActions.setSelection({ selection: [] }));
  yield put(partnerActions.setPartnersList({
    pending: false,
    items: data.items,
    totalCount: data.totalCount,
    reload: false,
  }));
}
