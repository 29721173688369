// Core
import { put } from 'redux-saga/effects';
// Engine
// import { localData } from '../../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';

export function* callGetInitialStateWorker(
  // { payload: url }
) {
  // const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(
    // lists ||
    {},
  );
  yield put(actions.setInitialState(initialPageState));
}
