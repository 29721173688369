// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callGetBillingStatusWorker,
  callPostBillingPaymentWorker,
  callGetBillingStatusesListWorker,
  callGetBillingPaymentTypesWorker,
  callGetBillingOrdersDashboardWorker,
  callGetBillingNewOrdersDashboardWorker,
  callGetDashboardLastTransactionsWorker,
  callGetBillingReportDownload,
  callGetBillingOnTimeOrdersDashboardWorker,
  callGetBillingSuccessDeliveryDashboardWorker,
  callGetDashboardRatingWorker,
  callGetBillingPartnersDownloadWorker,
  callGetBillingInvoiceTypesWorker,
} from './workers';

function* watchGetBillingStatus() {
  yield takeEvery(asyncTypes.GET_BILLING_STATUS_ASYNC, callGetBillingStatusWorker);
}
function* watchGetBillingReportDownload() {
  yield takeEvery(asyncTypes.GET_BILLING_REPORT_DOWNLOAD_ASYNC, callGetBillingReportDownload);
}
function* watchGetBillingInvoiceTypes() {
  yield takeEvery(asyncTypes.GET_BILLING_INVOICE_TYPES_ASYNC, callGetBillingInvoiceTypesWorker);
}
function* watchPostBillingPayment() {
  yield takeEvery(asyncTypes.POST_BILLING_PAYMENT_ASYNC, callPostBillingPaymentWorker);
}
function* watchGetBillingStatusesList() {
  yield takeEvery(asyncTypes.GET_BILLING_STATUSES_LIST_ASYNC, callGetBillingStatusesListWorker);
}
function* watchGetBillingPaymentTypes() {
  yield takeEvery(asyncTypes.GET_BILLING_PAYMENT_TYPES_ASYNC, callGetBillingPaymentTypesWorker);
}
function* watchGetBillingOrdersDashboardData() {
  yield takeEvery(asyncTypes.GET_BILLING_ORDERS_DASHBOARD_DATA_ASYNC, callGetBillingOrdersDashboardWorker);
}
function* watchGetBillingOnTimeOrdersDashboard() {
  yield takeEvery(asyncTypes.GET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA_ASYNC, callGetBillingOnTimeOrdersDashboardWorker);
}
function* watchGetBillingNewOrdersDashboardData() {
  yield takeEvery(asyncTypes.GET_BILLING_NEW_ORDERS_DASHBOARD_DATA_ASYNC, callGetBillingNewOrdersDashboardWorker);
}
function* watchGetDashboardLastTransactions() {
  yield takeEvery(asyncTypes.GET_BILLING_DASHBOARD_LAST_TRANSACTIONS_ASYNC, callGetDashboardLastTransactionsWorker);
}
function* watchGetDashboardRating() {
  yield takeEvery(asyncTypes.GET_DASHBOARD_RATING_ASYNC, callGetDashboardRatingWorker);
}
function* watchGetBillingSuccessDeliveryDashboard() {
  yield takeEvery(asyncTypes.GET_BILLING_SUCCESS_DELIVERY_DASHBOARD_ASYNC, callGetBillingSuccessDeliveryDashboardWorker);
}
function* watchGetBillingPartnersDownload() {
  yield takeEvery(asyncTypes.GET_BILLING_PARTNERS_DOWNLOAD_ASYNC, callGetBillingPartnersDownloadWorker);
}

export function* watchersBilling() {
  yield all([
    call(watchGetBillingStatus),
    call(watchPostBillingPayment),
    call(watchGetBillingStatusesList),
    call(watchGetBillingPaymentTypes),
    call(watchGetBillingOrdersDashboardData),
    call(watchGetBillingNewOrdersDashboardData),
    call(watchGetDashboardLastTransactions),
    call(watchGetBillingReportDownload),
    call(watchGetBillingOnTimeOrdersDashboard),
    call(watchGetBillingSuccessDeliveryDashboard),
    call(watchGetDashboardRating),
    call(watchGetBillingPartnersDownload),
    call(watchGetBillingInvoiceTypes),
  ]);
}
