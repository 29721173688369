// Core
import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import {
  change, Field, formValueSelector,
} from 'redux-form/immutable';
import {
  useDispatch, useSelector,
} from 'react-redux';
import * as PropTypes from 'prop-types';


// Parts
import FilesUploaderWithValidation from '../FilesUploader/FilesUploaderWithValidation';
import ModalCropper from './components/ModalCropper';

// Engine
import { mediaAction } from '../../../../engine/core/media/action';
import { selectors } from '../../../../engine/config/selectors';

function FieldCropper(props) {
  const {
    approve, approvedValue, acceptedFileTypes, currentImageSrc, className, aspectRatioWidth,
    aspectRatioHeight, allowFileTypeValidation, desc, hashId,
    disabled, oldImageSrc, onApproveValue, compareImage, name, textBefore, textBefore2, allowImageValidateSize,
    minWidth, maxWidth, minHeight, maxHeight, labelFileTypeNotAllowed, formName, sendForm,
  } = props;
  const dispatch = useDispatch();
  const mediaRequestFiles = useSelector(selectors.media.mediaRequestFiles);
  const normalizeMediaRequestFiles = mediaRequestFiles.toJS();
  const responseLink = normalizeMediaRequestFiles?.[formName]?.[name]?.link;
  const responseHashId = normalizeMediaRequestFiles?.[formName]?.[name]?.hashId;
  const responseStatus = normalizeMediaRequestFiles?.[formName]?.[name]?.status;
  const responseId = normalizeMediaRequestFiles?.[formName]?.[name]?.id;

  const selector = formValueSelector(formName);
  const getValue = useSelector(state => selector(state, name));

  const defaultValue = React.useRef(getValue);
  const [formFilesBase, setFormFilesBase] = useState('');
  const [formFiles, setFormFiles] = useState([]);

  const uploadCallback = useCallback((value) => {
    const { files } = value;
    if (files.length) {
      setFormFiles(files);
      const reader = new FileReader(); /* eslint-disable-line  */
      reader.onload = () => {
        setFormFilesBase(reader.result);
        dispatch(mediaAction.setMediaCropperModalShow(true));
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormFiles([]);
      setFormFilesBase('');
      dispatch(mediaAction.setMediaCropperModalShow(false));
    }
  }, [dispatch]);
  const cancelCallback = useCallback(() => {
    dispatch(change(formName, name, responseHashId === hashId ? responseId : defaultValue?.current));
  }, [dispatch, formName, hashId, name, responseHashId, responseId]);
  const sendCallback = useCallback((value) => {
    sendForm({
      cropData: value,
      file: formFiles,
      name,
      formName,
      hashId,
    });
  }, [hashId, formFiles, formName, name, sendForm]);

  useEffect(() => {
    if (responseStatus === 'success') {
      dispatch(change(formName, name, responseHashId === hashId ? responseId : defaultValue?.current));
    }
  }, [dispatch, formName, hashId, name, responseHashId, responseId, responseStatus]);

  return (
    <div className={className}>
      <Field
        allowFileTypeValidation={allowFileTypeValidation}
        allowImageValidateSize={allowImageValidateSize}
        approve={approve}
        approvedValue={approvedValue}
        acceptedFileTypes={acceptedFileTypes}
        component={FilesUploaderWithValidation}
        currentImageSrc={responseHashId === hashId ? responseLink : currentImageSrc}
        disabled={disabled}
        setPrevStateOnCropperCancel
        prevStateOnCropperCancel={responseHashId === hashId ? responseId : defaultValue?.current}
        oldImageSrc={oldImageSrc}
        onApproveValue={onApproveValue}
        compareImage={compareImage}
        name={name}
        textBefore={textBefore}
        textBefore2={textBefore2}
        uploadCallback={uploadCallback}
        minWidth={minWidth}
        maxWidth={maxWidth}
        minHeight={minHeight}
        maxHeight={maxHeight}
        desc={desc}
        labelFileTypeNotAllowed={labelFileTypeNotAllowed}
      />
      <ModalCropper
        sendForm={sendCallback}
        cancelCallback={cancelCallback}
        aspectRatioWidth={aspectRatioWidth}
        aspectRatioHeight={aspectRatioHeight}
        files={formFilesBase}
      />
    </div>
  );
}

FieldCropper.propTypes = {
  allowImageValidateSize: PropTypes.bool,
  allowFileTypeValidation: PropTypes.bool,
  textBefore: PropTypes.string,
  sendForm: PropTypes.func.isRequired,
  aspectRatioWidth: PropTypes.number,
  aspectRatioHeight: PropTypes.number,
  textBefore2: PropTypes.string,
  hashId: PropTypes.string,
  labelFileTypeNotAllowed: PropTypes.string,
  acceptedFileTypes: PropTypes.array,
  className: PropTypes.string,
  desc: PropTypes.string,
  approve: PropTypes.bool,
  disabled: PropTypes.bool,
  compareImage: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  currentImageSrc: PropTypes.string,
  formName: PropTypes.string,
  oldImageSrc: PropTypes.string,
  onApproveValue: PropTypes.func,
  approvedValue: PropTypes.oneOf(['current', 'old']),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  name: PropTypes.string,
};

FieldCropper.defaultProps = {
  allowFileTypeValidation: false,
  allowImageValidateSize: false,
  hashId: 'FieldCropper',
  aspectRatioWidth: 16,
  aspectRatioHeight: 9,
};

export default memo(FieldCropper);
