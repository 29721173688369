// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPostPhoneVerifyCodeWorker({ payload }) {
  yield put(userActions.mergeInConfirmCode({ pending: true, status: null }));
  const response = yield apply(api, api.user.postPhoneVerifyCode, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;

      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          yield put(userActions.mergeInConfirmCode({ status: true }));
          yield put(userActions.mergePutUserInfo({
            sendNewPhoneStatus: null,
            newPhoneValue: null,
          }));
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle));
          yield put(userActions.mergeInConfirmCode({ status: false }));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(userActions.mergeInConfirmCode({ pending: false }));
}
