import { getIn } from 'immutable';
import { statisticsKeys } from './reducers';

export const statistics = Object.freeze({
  appReactHash: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.appReactHash], null),
  notificationsNotAllCount: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.notificationsAll], 0),
  notificationsNotReadCount: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.notificationsNotRead], 0),
  ordersOverdueCounter: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.ordersOverdue], 0),
  ordersNewCounter: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.ordersNew], 0),
  supportNewCounter: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.counters, statisticsKeys.supportMessagesNotRead], 0),
  brandsLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.brandsLastUpdate, statisticsKeys.updatedAt], ''),
  categoriesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.categoriesLastUpdate, statisticsKeys.updatedAt], ''),
  attributeSetLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.attributeSetLastUpdate, statisticsKeys.updatedAt], ''),
  notificationsLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.notificationsLastUpdate, statisticsKeys.updatedAt], ''),
  partnerBalanceLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.partnerBalanceLastUpdate, statisticsKeys.updatedAt], ''),
  statusesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.statusesLastUpdate, statisticsKeys.updatedAt], ''),
  feedStatusesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.feedStatusesLastUpdate, statisticsKeys.updatedAt], ''),
  faqServiceDescLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.faqServiceDescLastUpdate, statisticsKeys.updatedAt], ''),
  partnersLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.partnersLastUpdate, statisticsKeys.updatedAt], ''),
  paymentTypesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.partnerPaymentTypesLastUpdate, statisticsKeys.updatedAt], ''),
  mailEventNameLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.mailTemplateEventsLastUpdate, statisticsKeys.updatedAt], ''),
  mailTemplateGroupsLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.mailTemplateGroupsLastUpdate, statisticsKeys.updatedAt], ''),
  rolesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.rolesLastUpdate, statisticsKeys.updatedAt], ''),
  userRolesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.userRolesLastUpdate, statisticsKeys.updatedAt], ''),
  productsStatusesLastUpdate: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.lastUpdates, statisticsKeys.productsStatusesLastUpdate, statisticsKeys.updatedAt], ''),
  isWorkingHours: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.workingHours, statisticsKeys.isWorkingHour], false),
  weekdaysFrom: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.workingHours, statisticsKeys.weekdaysFrom], {}),
  weekdaysTo: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.workingHours, statisticsKeys.weekdaysTo], {}),
  weekendsFrom: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.workingHours, statisticsKeys.weekendsFrom], {}),
  weekendsTo: state => getIn(state, [statisticsKeys.controller, statisticsKeys.statisticsList, statisticsKeys.workingHours, statisticsKeys.weekendsTo], {}),
});
