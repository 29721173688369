// Core
import React, {
  memo, useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

// Icon
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined';
// UI
import Button from '@mui/material/Button';
// Parts
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../containers/Modals/Modal/Modal';
// Hooks
import { useStyles } from '../../../../hooks/useStyles';
import { selectors } from '../../../../../engine/config/selectors';
import { mediaAction } from '../../../../../engine/core/media/action';

import ModalCropperStyle from './ModalCropperStyle';

function ModalCropper(props) {
  const {
    sendForm, aspectRatioHeight,
    aspectRatioWidth, files, cancelCallback,
  } = props;
  const classes = useStyles(ModalCropperStyle);
  const { t } = useTranslation();
  const cropperRef = useRef(null);
  const dispatch = useDispatch();
  const showModal = useSelector(selectors.media.show);
  const pending = useSelector(selectors.media.pending);

  const toggleModal = useCallback(() => {
    dispatch(mediaAction.setMediaCropperModalShow(!showModal));
    if (cancelCallback) {
      cancelCallback();
    }
  }, [cancelCallback, dispatch, showModal]);

  const onCrop = () => {
    const imageElement = cropperRef?.current?.cropper;
    sendForm(imageElement.getData());
  };

  const zoomPlus = () => {
    const imageElement = cropperRef?.current?.cropper;
    imageElement.zoom(0.1);
  };
  const zoomMinus = () => {
    const imageElement = cropperRef?.current?.cropper;
    imageElement.zoom(-0.1);
  };
  const reset = () => {
    const imageElement = cropperRef?.current?.cropper;
    imageElement.reset();
  };
  const changePosition = (left, right) => {
    const imageElement = cropperRef?.current?.cropper;
    imageElement.move(left, right);
  };

  // func
  const changeLeft = () => changePosition(-10, 0);
  const changeRight = () => changePosition(10, 0);
  const changeDown = () => changePosition(0, 10);
  const changeUp = () => changePosition(0, -10);

  return (
    <Modal
      modalOpen={showModal}
      handleModalToggle={toggleModal}
      handleModalCancel={toggleModal}
      handleModalSend={onCrop}
      sendText={t('Применить')}
      modalMaxWidth="sm"
      disabledSend={pending}
      buttonSendIcon={null}
      title={t('Выберите область')}
    >
      <div className={classes.modalContent}>
        <div className={classes.cropperWrapper}>
          <Cropper
            src={files}
            minContainerHeight={50}
            className={classes.cropper}
            autoCropArea={100000}
            aspectRatio={aspectRatioWidth / aspectRatioHeight}
            guides={false}
            background={false}
            ref={cropperRef}
            onInitialized={this}
          />
        </div>
        <div className={classes.cropperHelpText}>
          {t('Размер логотипа')}
        </div>
        <div className={classes.buttonsHolder}>
          <div className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              onClick={reset}
              className={classes.button}
            >
              <Rotate90DegreesCwOutlinedIcon />
            </Button>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              onClick={zoomPlus}
              className={classes.button}
            >
              <ZoomInIcon />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={zoomMinus}
              className={classes.button}
            >
              <ZoomOutIcon />
            </Button>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              onClick={changeLeft}
              className={classes.button}
            >
              <KeyboardArrowLeftRoundedIcon className={classes.buttonArrowIcon} />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={changeRight}
              className={classes.button}
            >
              <KeyboardArrowRightRoundedIcon className={classes.buttonArrowIcon} />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={changeDown}
              className={classes.button}
            >
              <KeyboardArrowDownRoundedIcon className={classes.buttonArrowIcon} />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={changeUp}
              className={classes.button}
            >
              <KeyboardArrowUpRoundedIcon className={classes.buttonArrowIcon} />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalCropper.propTypes = {
  cancelCallback: PropTypes.func,
  sendForm: PropTypes.func,
  aspectRatioWidth: PropTypes.number,
  aspectRatioHeight: PropTypes.number,
  files: PropTypes.string,
};

ModalCropper.defaultProps = {
  aspectRatioWidth: 16,
  aspectRatioHeight: 9,
};

export default memo(ModalCropper);
