// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/categoriesFeatureManagement/categoriesFeatureLogs/actions';
import { useCategoriesFeatureLogs } from '../_hooks/useCategoriesFeatureLogs';

function CategoriesFeatureLogsTable() {
  useCategoriesFeatureLogs();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.categoriesFeatureLogs.isLoading);
  // Rows
  const rows = useSelector(selectors.categoriesFeatureLogs.list);
  // Paging
  const currentPage = useSelector(selectors.categoriesFeatureLogs.currentPage);
  const pageSize = useSelector(selectors.categoriesFeatureLogs.pageSize);
  const totalCount = useSelector(selectors.categoriesFeatureLogs.totalCount);
  const FilterComponent = () => null;
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));

  const columns = [
    {
      name: 'createdAt',
      title: t('Дата изменения'),
    },
    {
      name: 'name',
      title: t('Пользователь'),
    },
    {
      name: 'dataFrom',
      title: t('Предыдущий статус характеристики в ЛК'),
    },
    {
      name: 'dataTo',
      title: t('Текущий статус характеристики в ЛК'),
    },
  ];

  return (
    <DxTable
      name="categoriesFeatureLogs"
      fixedColumn
      hiddeRowsLabel
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      rowFilterComponent={FilterComponent}
      updateHooks={useCategoriesFeatureLogs}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
    />
  );
}

export default memo(CategoriesFeatureLogsTable);
