// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { replace } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import errorBgLogo from '../../../assets/img/error_page_bg.svg';
// Containers

// Styles
import ErrorStyles from './ErrorStyles';

// Engine
import { routersLink } from '../../../engine/config/routes';

function Error(props) {
  const {
    classes, status, title, caption, subCaption, componentView, titleView,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goToDashboard = () => dispatch(replace(routersLink.dashboard));

  const classNames = cx(classes.session, {
    [classes.componentView]: componentView,
  });


  useEffect(() => {
    if (status === 500) {
      const timeout = 10 * 100000000;
      const timerId = setTimeout(() => goToDashboard(), timeout);
      return function clearTimeoutId() {
        clearTimeout(timerId);
      };
    }
  }, [status, goToDashboard]);

  return (
    <div className={classNames}>
      <div className={classes.content}>
        { titleView || <Typography className={classes.title}>{status}</Typography> }
        <Typography className={classes.subtitle}>{title}</Typography>
        {caption && <Typography variant="caption" className={classes.caption}>{caption}</Typography>}
        {subCaption && <Typography variant="caption">{subCaption}</Typography>}
        {(status === 500 || status === 404) && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={goToDashboard}
          >
            {t('На главную')}
          </Button>
        )}
      </div>
      <img src={errorBgLogo} alt="error background logo" className={classes.bgLogo} />
    </div>
  );
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  subCaption: PropTypes.string,
  componentView: PropTypes.bool,
  titleView: PropTypes.node,
};

Error.defaultProps = {
  caption: '',
  subCaption: '',
  componentView: false,
};


export default withStyles(ErrorStyles)(Error);
