export const types = Object.freeze({
  MERGE_PUT_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_PUT_STOP_LIST_BRANDS_CATEGORIES',
  MERGE_EDIT_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_EDIT_STOP_LIST_BRANDS_CATEGORIES',
  MERGE_DELETE_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_DELETE_STOP_LIST_BRANDS_CATEGORIES',
  MERGE_DOWNLOAD_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_DOWNLOAD_STOP_LIST_BRANDS_CATEGORIES',
  MERGE_UPLOAD_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_UPLOAD_STOP_LIST_BRANDS_CATEGORIES',
  MERGE_ADD_STOP_LIST_BRANDS_CATEGORIES: 'MERGE_ADD_STOP_LIST_BRANDS_CATEGORIES',
  SET_STOP_LIST_BRANDS_CATEGORIES_LIST: 'SET_STOP_LIST_BRANDS_CATEGORIES_LIST',
  SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE: 'SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE',
  SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS: 'SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS',
  SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE: 'SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE',
  SET_STOP_LIST_BRANDS_CATEGORIES_SORTING: 'SET_STOP_LIST_BRANDS_CATEGORIES_SORTING',
  SET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE: 'SET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE',
  SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_STATUS: 'SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_STATUS',
  SET_STOP_LIST_BRANDS_CATEGORIES_SELECTION: 'SET_STOP_LIST_BRANDS_CATEGORIES_SELECTION',
  SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_PENDING: 'SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_PENDING',
});
