import { delay, apply } from 'redux-saga/effects';
import { localData } from '../../../config/localData';

export function* checkContentProductsPageSendQuery() {
  const contentProductsPageSendQuery = yield localData.getItem(localData.keysObj.contentProductsPageSendQuery);
  if (contentProductsPageSendQuery !== true && contentProductsPageSendQuery !== false) {
    yield apply(localData, localData.setItem, [localData.keysObj.contentProductsPageSendQuery, true]);
    yield delay(1000);
  }
}
