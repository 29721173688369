// Core
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
// UI
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Containers
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Helpers
import { useAccessList } from '../../../../../hooks/useAccessList';
// Engine
import { pageLinks } from '../../../../../../engine/config/routes';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions as contentProductActions } from '../../../../../../engine/core/contentProduct/actions';
import { userRoles } from '../../../../../../engine/config/userRoles';
// Hooks
import { useUserRole } from '../../../../../hooks/useUserRole';

function ProductAddButton() {
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const hasAccessToCreate = useAccessList(accessList.productCreate);
  const productGridLoading = useSelector(selectors.content.productGridLoading);
  const isPartnerRoleDisabled = useUserRole(userRoles.partnerDisabled);
  const isDisabled = productGridLoading || isPartnerRoleDisabled;

  const handleShowModal = () => {
    dispatch(contentProductActions.setChangeModalWidthCategoryOpen(true));
  };

  if (!hasAccessToCreate && !isPartnerRoleDisabled) {
    return null;
  }

  return categoryId ? (
    <NavLink to={pageLinks.productsAndPrices.content.addByCategoryId(categoryId)}>
      <ButtonWithIcon sx={{ minWidth: '184px' }} disabled={productGridLoading} text={t('Добавить товар')} fullWidth>
        <AddCircleIcon />
      </ButtonWithIcon>
    </NavLink>
  ) : (
    <div>
      <ButtonWithIcon
        disabled={productGridLoading || isDisabled}
        text={t('Добавить товар')}
        fullWidth
        onClick={handleShowModal}
        id="product_add_button"
        sx={{ minWidth: '184px' }}
      >
        <AddCircleIcon />
      </ButtonWithIcon>
    </div>
  );
}

export default ProductAddButton;
