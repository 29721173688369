// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// Parts
import ReactJson from 'react-json-view';
import Modal from '../../../../containers/Modals/Modal/Modal';

function ShowCodeModal(props) {
  const {
    modalOpen, handleModalToggle, source, name,
  } = props;

  return (
    <Modal
      fullScreen
      fullWidth
      modalOpen={modalOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      hideButtons
      modalMaxWidth={false}
      title={name}
    >
      <ReactJson
        enableClipboard={false}
        displayObjectSize={false}
        displayDataTypes={false}
        name={name}
        src={source}
        theme="summerfruit:inverted"
      />
    </Modal>
  );
}

ShowCodeModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func,
  source: PropTypes.func,
  name: PropTypes.string,
};

export default ShowCodeModal;
