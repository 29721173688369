import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { push } from 'redux-first-history';
// Engine
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { actions } from '../../../../../../../engine/core/content/actions';
import { actions as contentProductActions } from '../../../../../../../engine/core/contentProduct/actions';
import { asyncActions as contentAsyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { pageLinks } from '../../../../../../../engine/config/routes';
import { selectors } from '../../../../../../../engine/config/selectors';
import { store } from '../../../../../../../engine/init/store';
import { fileExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { formFields } from '../../../../../../page/Products/Content/ContentProducts/components/ContentProductsSidebar/ProductMultipleEditingForm';

const timeout = 200;

const redirectToProduct = ({ url }) => {
  store.dispatch(uiActions.setJoyrideRun(false));
  store.dispatch(push(url));
  store.dispatch(uiActions.setOpenBackdropOverlay(true));
  setTimeout(() => {
    store.dispatch(uiActions.setJoyrideRun(true));
    store.dispatch(uiActions.setOpenBackdropOverlay(false));
  }, timeout);
};

const finishedCloseTour = () => {
  const state = store.getState();
  const isOpenModalWidthCategory = selectors.contentProduct.isOpenModalWidthCategory(state);
  const productPagePath = pageLinks.content.products.simpleView;
  const currentPathname = selectors.router.pathname(state);

  store.dispatch(uiActions.setJoyrideRun(false));
  store.dispatch(uiActions.setJoyrideStepIndex(0));
  store.dispatch(uiActions.setJoyrideSteps(null));
  store.dispatch(contentProductActions.setCategoryValueModalWidthCategory({}));

  if (currentPathname !== productPagePath) {
    store.dispatch(push(productPagePath));
  }
  if (isOpenModalWidthCategory) {
    store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(false));
  }
};

const openManageSidebarTab = (type, index) => {
  if (type === EVENTS.STEP_BEFORE && index === 0) {
    store.dispatch(uiActions.setSidebarActiveMenu(1));
  }
};

const openRequiredTab = (type, index, tabIndex) => {
  if (type === EVENTS.STEP_BEFORE && index === 0) {
    store.dispatch(uiActions.setContentProductSidebarActiveMenu(tabIndex));
  }
};

export const productsContentCallback = (data) => {
  const {
    action, status, index, type,
  } = data;

  const state = store.getState();
  const productPagePath = pageLinks.content.products.simpleView;
  const categoryGuideItem = selectors.contentProduct.productCategoriesList(state)?.toJS().filter(
    item => item.value !== null,
  )?.[55];
  // const productGridLoading = selectors.content.productGridLoading(state);

  if (index === 1 && type === EVENTS.TARGET_NOT_FOUND) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(true));

    setTimeout(() => {
      store.dispatch(uiActions.setJoyrideRun(true));
      store.dispatch(contentProductActions.setCategoryValueModalWidthCategory(categoryGuideItem));
    }, timeout);
  }

  if (
    (index === 2 && type === EVENTS.TARGET_NOT_FOUND)
    || (index > 1 && action === ACTIONS.NEXT && type === EVENTS.TARGET_NOT_FOUND)
  ) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setOpenBackdropOverlay(true));

    store.dispatch(contentAsyncActions.getProductsSettingsAsync({
      categoryId: categoryGuideItem.value,
      onSuccess: () => {
        store.dispatch(push(pageLinks.productsAndPrices.content.addByCategoryId(categoryGuideItem.value)));
        store.dispatch(contentProductActions.setActiveProductCategory(categoryGuideItem));
        store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(false));

        setTimeout(() => {
          store.dispatch(uiActions.setJoyrideRun(true));
          store.dispatch(uiActions.setOpenBackdropOverlay(false));
        }, timeout * 6);
      },
    }));
  }

  if (index === 0 && action === ACTIONS.PREV && type === EVENTS.STEP_BEFORE) {
    store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(false));
  }

  if (action === ACTIONS.PREV && type === EVENTS.TARGET_NOT_FOUND) {
    if (index === 0) {
      store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(false));
      redirectToProduct({ url: productPagePath });
    }
    if (index === 1) {
      redirectToProduct({ url: productPagePath });

      setTimeout(() => {
        store.dispatch(contentProductActions.setChangeModalWidthCategoryOpen(true));
        store.dispatch(contentProductActions.setCategoryValueModalWidthCategory(categoryGuideItem));
      }, timeout);
    }
    if (index === 2) {
      redirectToProduct({ url: productPagePath });
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    finishedCloseTour();
  }
};

export const sendToModerationCallback = ({
  action, status, index, type,
}) => {
  openManageSidebarTab(type, index);
  openRequiredTab(type, index, 0);

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    finishedCloseTour();
  }
};

export const productEditCallback = guideTourRef => ({
  action, status, index, type,
}) => {
  openManageSidebarTab(type, index);
  openRequiredTab(type, index, 0);

  if (type === EVENTS.STEP_BEFORE && index === 2) {
    store.dispatch(uiActions.setSelectIsOpen({ edit: true }));
  }

  if (type === EVENTS.STEP_AFTER && index === 2 && action === ACTIONS.NEXT) {
    guideTourRef?.current?.ref?.current.handleChange(() => formFields.quantity);
  }

  if (type === EVENTS.STEP_BEFORE && index !== 2) {
    store.dispatch(uiActions.setSelectIsOpen({ edit: false }));
  }

  if (index === 3 && type === EVENTS.TARGET_NOT_FOUND) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setOpenBackdropOverlay(true));

    setTimeout(() => {
      store.dispatch(uiActions.setJoyrideRun(true));
      store.dispatch(uiActions.setOpenBackdropOverlay(false));
      guideTourRef?.current?.ref?.current.handleChange(() => formFields.quantity);
    }, 300);
  }

  if (type === EVENTS.STEP_AFTER && index === 3 && action === ACTIONS.NEXT) {
    guideTourRef.current.click();
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    store.dispatch(uiActions.setSidebarActiveMenu(0));
    finishedCloseTour();
    store.dispatch(uiActions.setSelectIsOpen({ edit: false }));
    guideTourRef?.current?.ref?.current.handleChange(() => undefined);
    setTimeout(() => {
      store.dispatch(uiActions.setSidebarActiveMenu(1));
    });
  }
};

export const productsPriceUploadCallback = ({
  action, status, index, type,
}) => {
  openManageSidebarTab(type, index);
  openRequiredTab(type, index, 1);

  if (type === EVENTS.STEP_AFTER && index === 2 && action === ACTIONS.NEXT) {
    store.dispatch(actions.setImportPricesModalIsOpen({ isOpen: true }));
  }

  if (type === EVENTS.TARGET_NOT_FOUND) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setOpenBackdropOverlay(true));
    if (index >= 3) {
      store.dispatch(actions.setImportPricesModalIsOpen({ isOpen: true }));
    }

    if (index === 4) {
      setTimeout(() => {
        store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xlsx));
      }, 200);
    }

    setTimeout(() => {
      store.dispatch(uiActions.setJoyrideRun(true));
      store.dispatch(uiActions.setOpenBackdropOverlay(false));
    }, 1200);
  }

  if (type === EVENTS.STEP_AFTER && index === 3) {
    store.dispatch(uiActions.setImportPageFilePondDefault(fileExtensions.xlsx));
  }

  if (action === ACTIONS.PREV && index < 4 && type === EVENTS.STEP_BEFORE) {
    store.dispatch(uiActions.setImportPageFilePondDefault(null));
    if (index < 3) {
      store.dispatch(actions.setImportPricesModalIsOpen({ isOpen: false }));
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    finishedCloseTour();
    store.dispatch(uiActions.setImportPageFilePondDefault(null));
    store.dispatch(actions.setImportPricesModalIsOpen({ isOpen: false }));
  }
};

export const productsPriceExportCallback = ({
  action, status, index, type,
}) => {
  openManageSidebarTab(type, index);
  openRequiredTab(type, index, 1);

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    finishedCloseTour();
  }
};

export const productsMultipleEditCallback = ({
  action, status, index, type,
}) => {
  if (index === 0 && type === EVENTS.STEP_BEFORE) {
    store.dispatch(uiActions.setSelectIsOpen({ categories: true }));
  }

  if (index === 0 && type === EVENTS.STEP_AFTER) {
    store.dispatch(uiActions.setSelectIsOpen({ categories: false }));
    store.dispatch(push(pageLinks.productsAndPrices.content.byCategoryId(71)));
  }

  if (index === 1 && type === EVENTS.TARGET_NOT_FOUND) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setOpenBackdropOverlay(true));

    setTimeout(() => {
      store.dispatch(uiActions.setJoyrideRun(true));
      store.dispatch(uiActions.setOpenBackdropOverlay(false));
    }, 1500);
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    finishedCloseTour();
    store.dispatch(uiActions.setSelectIsOpen({ categories: false }));
  }
};
