// Engine
import useMediaQuery from '@mui/material/useMediaQuery';

export function useDesktopView() {
  const matches = useMediaQuery('(max-width: 768px)');

  return !matches;
}

export function useMDView() {
  return useMediaQuery('(max-width: 960px)');
}

export function useMobileView() {
  return useMediaQuery('(max-width: 600px)');
}

export function useDownMDView() {
  return useMediaQuery('(max-width: 1279.95px)');
}

export function useDownLGView() {
  return useMediaQuery('(max-width: 1367.95px)');
}
export function useDownXLView() {
  return useMediaQuery('(max-width: 1500px)');
}
export function useDownLGHeightView() {
  return useMediaQuery('(max-height: 700px)');
}
