// Core
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// Ui
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { push } from 'redux-first-history';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import DateRange from '../../../../../components/DateRange/DateRange';

// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../../../engine/core/_partners/saga/asyncAction';
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import { asyncActions } from '../../../../../../engine/core/billing/reports/saga/asyncActions';
import { actions } from '../../../../../../engine/core/billing/reports/actions';
import { pageLinks } from '../../../../../../engine/config/routes';
import ModalFooter from '../../../../../containers/Modals/Modal/ModalFooter/ModalFooter';

// Redux
function mapStateToProps(state) {
  return {
    partnersList: selectors.partner.listContext(state).toJS(),
    reportPending: selectors.reports.isLoading(state),
    reportModalIsOpen: selectors.reports.reportModalIsOpen(state),
    currentPage: selectors.reports.currentPage(state),
    limit: selectors.reports.pageSize(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPartnersList: context => dispatch(partnerAsyncAction.getPartnersList(context)),
    setBillingReport: payload => dispatch(actions.setList(payload)),
    setFiltersBillingReport: payload => dispatch(actions.setFilters(payload)),
    getListAsync: payload => dispatch(asyncActions.getListAsync(payload)),
    redirectTo: url => dispatch(push(url)),
  };
}

function GenerateReportModal(props) {
  const {
    getPartnersList, partnersList, reportPending, setBillingReport, reportModalIsOpen, redirectTo,
  } = props;
  const { t } = useTranslation();
  const currentDate = new Date();
  const partnersItems = partnersList.items ? partnersList.items : partnersList; // TODO: partners list context set items to different keys in redux
  const [partner, setPartner] = useState();
  const [dateRanges, setDateRanges] = useState({
    startDate: currentDate,
    endDate: currentDate,
    key: 'selection',
  });
  const generateActModal = () => {
    setBillingReport({ modalIsOpen: !reportModalIsOpen });
  };

  const changeFormSelect = (arrayValues) => {
    setPartner(arrayValues);
  };

  const onDateRangeChange = (range) => {
    setDateRanges(range);
  };

  const generateReport = () => {
    setBillingReport({
      partnerId: partner,
      createdAt: dateRanges,
      firstLoad: true,
    });
    redirectTo(pageLinks.billing.report);
  };

  const modalFooterComponent = () => (
    <ModalFooter>
      <ButtonWithIcon text={t('Загрузить')} disabled={!partner || reportPending} onClick={generateReport}>
        {reportPending
          ? <CircularProgress color="inherit" size={20} />
          : <DescriptionIcon />}
      </ButtonWithIcon>
    </ModalFooter>
  );


  useEffect(() => {
    getPartnersList({ context: 'select' });
  }, [getPartnersList]);

  useEffect(() => {
    if (!partnersList.pending && partnersItems.length === 1) {
      setPartner(partnersItems);
    }
  }, [partnersItems, partnersList.pending]);

  return (
    <Modal
      title={t('Загрузить историю транзакций')}
      modalOpen={reportModalIsOpen}
      handleModalToggle={generateActModal}
      hideButtons
      fullWidth={false}
      noPadding
      modalMaxWidth="md"
      modalFooterComponent={modalFooterComponent}
    >
      <Grid container justifyContent="flex-end" flexDirection="column">
        <Grid sx={{ p: '10px 20px 0' }} item sm={12}>
          <PartnerSelect
            input={{ value: partner, onChange: changeFormSelect }}
            fullWidth
          />
        </Grid>
        <Box m={2} />
        <Grid item>
          <DateRange
            onChange={onDateRangeChange}
            ranges={[dateRanges]}
            startDate={currentDate}
            endDate={currentDate}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
GenerateReportModal.propTypes = {
  setBillingReport: PropTypes.func,
  redirectTo: PropTypes.func,
  reportModalIsOpen: PropTypes.bool,
  getPartnersList: PropTypes.func,
  partnersList: PropTypes.array,
  reportPending: PropTypes.bool,
};

GenerateReportModal.defaultProps = {
  getPartnersList: () => {},
  setBillingReport: () => {},
  reportPending: false,
  partnersList: [],
  reportModalIsOpen: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateReportModal);
