// Engine
import { pageSizes } from '../engine/config/globalConfig';

export function getInitialPageState(args) {
  const {
    currentPage = 0, filters = [], pageSize = pageSizes[0], totalCount = 0, sorting = [],
  } = args;

  return {
    currentPage,
    filters,
    pageSize,
    sorting,
    totalCount,
  };
}
