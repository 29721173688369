// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callGetDataXmlDownloadWorker,
  callPutSettingsWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PRICE_CREATOR_FILES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PRICE_CREATOR_FILES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRICE_CREATOR_FILES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRICE_CREATOR_FILES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PRICE_CREATOR_FILES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PRICE_CREATOR_FILES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PRICE_CREATOR_FILES_SORTING, callSetToLocalDataWorker);
}
function* watchGetDataXmlDownload() {
  yield takeEvery(asyncTypes.GET_PRICE_CREATOR_DATA_XML_DOWNLOAD_ASYNC, callGetDataXmlDownloadWorker);
}

export function* watchersPriceCreatorFiles() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetDataXmlDownload(),
  ]);
}
