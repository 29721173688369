// Core
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Field, Form, reduxForm } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
// UI
import Grid from '@mui/material/Grid';
import DeleteIcons from '@mui/icons-material/Delete';
// Containers
import PageHeader from '../../../../components/PageHeader/PageHeader';
import SectionHolder from '../../../../containers/SectionHolder/SectionHolder';
// Components
import FormFieldInput from './FormFieldInput';
import FormTextAreaField from './FormTextAreaField';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import Loading from '../../../../components/Loading/Loading';
import FaqEditSubmitFormButtons from './FaqEditSubmitFormButtons';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/faq/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { pageMode as configMode } from '../../../../../engine/config/globalConfig';
// Helpers
import { formName, formFields } from '../../../../../_helpers/data/pages/faqs/formFields';
import { useFaqItemStatus } from '../hooks/useFaqItemStatus';
import { useAccessList } from '../../../../hooks/useAccessList';
import { validators } from '../../../../../_helpers/validationsForm';
import FaqEditStyles from '../FaqEditPageStyles';


const useStyles = makeStyles(FaqEditStyles);

function FaqEditForm(props) {
  const { handleSubmit, hashId, mode: pageMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const hasAccessFaqSendToModerate = useAccessList(accessList.faqSendToModerate);
  const hasAccessFaqSendToMagento = useAccessList(accessList.faqSendToMagento);
  const hasAccessFaqEdit = useAccessList(accessList.faqQuestionEdit);
  const hasAccessFaqQuestionDelete = useAccessList(accessList.faqQuestionDelete);
  const isItemByHashIdPutPending = useSelector(selectors.faqs.isItemByHashIdPutPending);
  const isShowMode = configMode.show === pageMode;

  const getCurrentMode = useCallback(() => {
    switch (true) {
      case hasAccessFaqSendToModerate: {
        return 'moderation';
      }
      case hasAccessFaqSendToMagento: {
        return 'magento';
      }
      default: {
        return 'save';
      }
    }
  }, [hasAccessFaqSendToModerate, hasAccessFaqSendToMagento]);

  const currentMode = getCurrentMode();
  const [mode, setMode] = useState(currentMode);
  const isItemByHashIdLoading = useSelector(selectors.faqs.isItemByHashIdLoading);
  const deletePending = useSelector(selectors.faqs.deletePending);
  const { value: faqItemStatus } = useFaqItemStatus();
  const dispatch = useDispatch();
  const publishedStatusValue = 2;
  const deleteInMagento = 3;

  const onFormSubmit = useCallback((formValues) => {
    const submitMode = formValues.get('mode');

    const params = {
      hashId,
      answer: formValues.get(formFields.answer),
    };

    switch (submitMode) {
      case 'moderation': {
        params.toModeration = true;
        break;
      }
      case 'reject': {
        params.approved = false;
        break;
      }
      case 'magento': {
        params.approved = true;
        break;
      }
      case 'save': {
        params.toModeration = false;
        break;
      }
      default: {
        break;
      }
    }

    dispatch(asyncActions.putFaqItemByHashId(params));
  }, [dispatch, hashId]);

  const formatStatusLabel = useCallback((statusValue) => {
    const defaultStatusValue = '';
    if (isNil(statusValue)) {
      return defaultStatusValue;
    }
    const normalizedValue = statusValue.toJS ? statusValue.toJS() : statusValue;
    return get(normalizedValue, 'label', defaultStatusValue);
  }, []);
  const deleteByHashId = () => {
    dispatch(asyncActions.deleteFaqItemByHashId(hashId));
  };

  const pageTitle = useMemo(() => isShowMode ? t('Просмотр вопроса') : t('Редактирование вопроса'), [t, isShowMode]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={pageTitle}>
          <ButtonGoBack defaultPage={pageLinks.faq.all} />
          <FaqEditSubmitFormButtons disabled={isItemByHashIdPutPending} onButtonClick={setMode} hideButton={faqItemStatus === publishedStatusValue} />
          {(hasAccessFaqQuestionDelete && deleteInMagento === faqItemStatus) && (
            <ButtonWithIconAndCircularProgress
              disabled={deletePending}
              text={t('Удалить')}
              onClick={deleteByHashId}
              isLoading={deletePending}
            >
              <DeleteIcons />
            </ButtonWithIconAndCircularProgress>
          )}
        </PageHeader>
        <Grid container xs={12}>
          <SectionHolder>
            {isItemByHashIdLoading ? (
              <Grid
                alignItems="center"
                justifyContent="center"
                container
                spacing={3}
                className={classes.tabsContent}
                style={{ height: 320 }}
              >
                <Loading isLoading />
              </Grid>
            ) : (
              <Grid
                container
                className={classes.tabsContent}
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('M-ID вопроса')}
                    name={formFields.questionID}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('M-ID Заказа')}
                    name={formFields.orderId}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Field
                    component={FormFieldInput}
                    format={formatStatusLabel}
                    disabled
                    fullWidth
                    label={t('Статус')}
                    name={formFields.status}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('Партнер')}
                    name={formFields.partner}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('SKU')}
                    name={formFields.sku}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormTextAreaField}
                    disabled
                    fullWidth
                    label={t('Вопрос')}
                    name={formFields.question}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {(mode === 'moderation' || mode === 'magento') && (
                  <Field
                    component={FormTextAreaField}
                    disabled={(faqItemStatus === publishedStatusValue) || !hasAccessFaqEdit}
                    fullWidth
                    autoFocus
                    label={t('Ответ')}
                    name={formFields.answer}
                    validation
                    validate={validators.required}
                  />
                  )}
                  {(mode === 'save' || mode === 'reject') && (
                  <Field
                    component={FormTextAreaField}
                    disabled={(faqItemStatus === publishedStatusValue) || !hasAccessFaqEdit}
                    fullWidth
                    label={t('Ответ')}
                    autoFocus
                    name={formFields.answer}
                  />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('Дата создания')}
                    name={formFields.createdAt}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('Дата ответа')}
                    name={formFields.answeredAt}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} />
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    component={FormFieldInput}
                    disabled
                    fullWidth
                    label={t('Ответчик')}
                    name={formFields.responderName}
                  />
                </Grid>
              </Grid>
            )}
          </SectionHolder>
        </Grid>
      </Grid>
    </Form>
  );
}

FaqEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hashId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([configMode.show, configMode.edit]),
};

export default reduxForm({ form: formName })(FaqEditForm);
