import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles({
  activeColor: {
    '@global': {
      td: {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
});

export default function DxTableRow(props) {
  const {
    tableRow, children, bgColor = '#fff', row,
  } = props;
  const [selected, setSelected] = useState(false);
  const classes = useStyles();
  const className = cx({ [classes.activeColor]: selected });
  const onClick = () => {
    setSelected(!selected);
  };
  return (
    <VirtualTable.Row
      row={row}
      tableRow={tableRow}
      className={className}
      onClick={onClick}
      bgcolor={bgColor}
    >
      {children}
    </VirtualTable.Row>
  );
}

DxTableRow.propTypes = {
  row: PropTypes.object,
  tableRow: PropTypes.object,
  children: PropTypes.any,
  bgColor: PropTypes.string,
};
