import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    background: 'rgba(0,0,0,.3)',
    zIndex: 1303,
    color: '#5285CC',
  },
}));

const BackdropOverlay = ({ isOpen }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropOverlay;
