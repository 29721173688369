// Core
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import regexifyString from 'regexify-string';
// Parts
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import LinkMui from '@mui/material/Link';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneIcon from '../../../../../icons/Phone';
import EmailIcon from '../../../../../icons/Email';
// Styles
import { COLOR } from '../../../../../containers/App/AppStyles';
import { TitleWithIcon } from '../../../styles';
// Engine
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { selectors } from '../../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../../engine/config/routes';

const FEED_STATUS_TYPES = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  PRODUCT_IN_PROCESSING: 'products_in_processing',
});

const RefreshButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(selectors.feeds.isItemByHashIdPutPending);
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const onClick = () => {
    dispatch(push(pageLinks.productsAndPrices.prices));
  };
  return (
    <Box sx={{ marginTop: '20px' }}>
      <LoadingButton
        disabled={Boolean(pending || postUsersHashIdPending)}
        loading={Boolean(pending || postUsersHashIdPending)}
        onClick={onClick}
        variant="outlined"
      >
        {t('Просмотреть статус')}
      </LoadingButton>
    </Box>
  );
};
const GoToProductsButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(selectors.feeds.isItemByHashIdPutPending);
  const item = useSelector(selectors.feeds.isItemByHashIdItem);
  const price = item.toJS();
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const onClick = () => {
    dispatch(actions.setFilters([{ columnName: 'feed', value: price.id }]));
    const timer = setTimeout(() => {
      dispatch(push(pageLinks.productsAndPrices.content.all));
    }, 1000);
    return () => clearTimeout(timer);
  };
  return (
    <Box sx={{ marginTop: '20px' }}>
      <LoadingButton
        disabled={Boolean(pending || postUsersHashIdPending)}
        loading={Boolean(pending || postUsersHashIdPending)}
        onClick={onClick}
        variant="contained"
      >
        {t('Перейти к товарам')}
      </LoadingButton>
    </Box>
  );
};


const FeedStatusDataInfo = (props) => {
  const { feedStatusData } = props;
  const { t } = useTranslation();

  const message = useMemo(() => {
    switch (true) {
      case feedStatusData.description.includes('#allProducts'): {
        return regexifyString({
          pattern: /#allProducts/i,
          decorator: () => (
            <Link to={pageLinks.content.products.simpleView}>
              <LinkMui component="span">{t('Все товары')}</LinkMui>
            </Link>
          ),
          input: feedStatusData.description,
        });
      }
      case feedStatusData.description.includes('%mail_icon%'): {
        return regexifyString({
          pattern: /%mail_icon%|<br \/>/i,
          decorator: (match) => {
            switch (match) {
              case '<br />': { return <br />; }
              case '%mail_icon%': { return <EmailIcon />; }
              default: return match;
            }
          },
          input: feedStatusData.description,
        });
      }
      case feedStatusData.description.includes('%phone_icon%'): {
        return regexifyString({
          pattern: /%phone_icon%|<br \/>/i,
          decorator: (match) => {
            switch (match) {
              case '<br />': { return <br />; }
              case '%phone_icon%': { return <PhoneIcon />; }
              default: return match;
            }
          },
          input: feedStatusData.description,
        });
      }
      case feedStatusData.description.includes('%edit_icon%'): {
        return regexifyString({
          pattern: /%edit_icon%|<br \/>/gi,
          decorator: (match) => {
            switch (match) {
              case '<br />': { return <br />; }
              case '%edit_icon%': { return <ModeOutlinedIcon />; }
              default: return match;
            }
          },
          input: feedStatusData.description,
        });
      }
      default: {
        return feedStatusData.description;
      }
    }
  }, [feedStatusData.description]);

  switch (feedStatusData.type) {
    case FEED_STATUS_TYPES.PRODUCT_IN_PROCESSING: {
      return (
        <>
          <TitleWithIcon
            gutterBottom
            variant="h6"
            component="div"
            sx={{ color: COLOR.black.default }}
          >
            <ScheduleIcon sx={{ marginRight: '10px', color: COLOR.black.default }} />
            {feedStatusData.title}
          </TitleWithIcon>
          <Typography component="p" sx={{ maxWidth: 680, fontSize: 14 }}>
            {t('Процесс обработки данных может занять несколько часов.')} <br />
            {t('После завершения обработки - товары из прайс-листа отобразятся в разделе')}
            <Link to={pageLinks.content.products.simpleView}>
              &nbsp;<LinkMui component="span">&quot;{t('Товары')}&quot;</LinkMui>,&nbsp;
            </Link>
            {t('статус прайс-листа изменится на')} &quot;{t('Импортировано')}&quot;&nbsp;
            {t('(если все категории были сопоставлены с категориями ALLO), или "Ожидает сопоставление данных"')}&nbsp;
            {t('(если часть категорий осталась несопоставленной).')}&nbsp;
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <GoToProductsButton />
            <RefreshButton isRedirect={false} />
          </Box>
        </>
      );
    }
    default: {
      const color = feedStatusData.type === FEED_STATUS_TYPES.SUCCESS ? COLOR.success : COLOR.error;
      return (
        <>
          <TitleWithIcon
            gutterBottom
            variant="h6"
            component="div"
            sx={{ color }}
          >
            <CheckCircleOutlineIcon sx={{
              color,
              marginRight: '10px',
            }}
            />
            {feedStatusData.title}
          </TitleWithIcon>
          <Typography component="p">
            {message}
          </Typography>
          <RefreshButton isRedirect />
        </>
      );
    }
  }
};

export default FeedStatusDataInfo;
