// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callGetAttributeSetsContextWorker,
} from './workers';

function* watchGetAttributeSetsContext() {
  yield takeEvery(asyncTypes.GET_ATTRIBUTE_SETS_LIST_ASYNC, callGetAttributeSetsContextWorker);
}

export function* watchersGetAttributeSetsContext() {
  yield all([
    watchGetAttributeSetsContext(),
  ]);
}
