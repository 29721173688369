
import { getActionCreator } from '../../../../_helpers/getActionCreator';
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_LIST_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_LIST_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_LIST_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_LIST_SORTING, sorting);
  },
  setDownloadPending(downloadPending) {
    return getActionCreator(types.SET_PARTNER_SERVICES_REQUESTS_DOWNLOAD_PENDING, downloadPending);
  },
});
