// Core
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import Box from '@mui/material/Box';
// Parts
import NotificationNote from '../../../../../components/Notifications/NotificationNote';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';

const FeedNoteText = (props) => {
  const { noteText, activeStep } = props;
  const { t } = useTranslation();
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canEditFeatures = isItemByHashIdItem.get('canEditFeatures');
  const disabledParams = !canEditFeatures && activeStep === 2;

  if (!noteText) {
    return null;
  }
  return (
    <NotificationNote component="div" type={disabledParams ? 'warning' : 'info'}>
      {disabledParams
        ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ fontWeight: 'bold' }}>{`${t('Обратите внимание!')}`}</Box>
            <Box component="span">{noteText}</Box>
          </Box>
        )
        : noteText
      }
    </NotificationNote>
  );
};

export default FeedNoteText;
