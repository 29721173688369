// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Box from '@mui/material/Box';
// Parts
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from 'react-redux';
import { useStyles } from '../../../../../hooks/useStyles';
import Switcher from '../../../../../components/_Form/Switcher/Switcher';
import { COLOR } from '../../../../../containers/App/AppStyles';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { useCurrentMode } from '../../../hooks/useCurrentMode';
import OrderProcessingModal from './OrderProcessingModal/OrderProcessingModal';
import { usePartnerData } from '../../../hooks/usePartnerData';
import { actions } from '../../../../../../engine/core/partners/actions';
import { asyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';

const styles = () => ({
  contentHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desc: {
    fontSize: '12px',
    color: COLOR.grey['54'],
    lineHeight: '16px',
    marginTop: '11px',
    marginBottom: '11px',
  },
  dayItem: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: '15px',
  },
  dayItemLabel: {
    fontSize: '14px',
    color: COLOR.grey['54'],
  },
  dayItemTime: {
    fontSize: '14px',
    color: COLOR.grey.main,
  },
});
function OrderProcessing(props) {
  const { mode, hashId } = props;
  const { getInfoFieldValue } = usePartnerData();
  const orderProcessingScheduleValue = getInfoFieldValue('orderProcessingSchedule');
  const { isShowMode } = useCurrentMode(mode);
  const { t } = useTranslation();
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const daysValue = orderProcessingScheduleValue?.days;
  const hasSwitcherAccess = useAccessList(accessList.switchPartnerSchedule);
  const isProcessingScheduleValueExist = daysValue && daysValue.length !== 0;
  const sendText = isProcessingScheduleValueExist
    ? t('Применить изменения')
    : t('Сохранить');

  const handleChange = () => {
    dispatch(asyncActions.putPartnersOrderProcessingScheduleAsync({
      hashId,
      formData: {
        infoFieldsValues: {
          orderProcessingSchedule: {
            enabled: !orderProcessingScheduleValue?.enabled,
            days: daysValue,
          },
        },
      },
    }));
  };
  const handleOpenModal = () => {
    dispatch(actions.mergePartnerOrderProcessing({
      isModalOpen: true,
    }));
  };

  return (
    <>
      <Box>
        <Box
          component="div"
          className={classes.contentHolder}
        >
          <Typography variant="h5" className={classes.subtitle} sx={{ display: 'flex' }}>
            {`${t('График обработки заказов')}:`}<Typography color="red">&nbsp;*</Typography>
          </Typography>

          { hasSwitcherAccess && (
            <Switcher
              disableRipple
              color="secondary"
              onChange={handleChange}
              isDisabled={!isProcessingScheduleValueExist || isShowMode}
              isChecked={orderProcessingScheduleValue?.enabled}
              sx={{ marginRight: '8px', pointerEvents: (!isProcessingScheduleValueExist || isShowMode) && 'none' }}
            />
          ) }
        </Box>
        <Box className={classes.desc} component="p">{t('Выберите время, до которого необходимо оформить заказ покупателю, чтобы он был обработан вашим магазином и отправлен в день заказа.')}</Box>
        {isProcessingScheduleValueExist && (
          <Box mb={1}>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Понедельник')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[0].enabled ? daysValue[0].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Вторник')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[1].enabled ? daysValue[1].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Среда')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[2].enabled ? daysValue[2].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Четверг')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[3].enabled ? daysValue[3].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Пятница')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[4].enabled ? daysValue[4].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Суббота')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[5].enabled ? daysValue[5].time : t('Выходной')}</Box>
            </Box>
            <Box className={classes.dayItem}>
              <Box className={classes.dayItemLabel}>{t('Воскресенье')}</Box>
              <Box className={classes.dayItemTime}>{daysValue[6].enabled ? daysValue[6].time : t('Выходной')}</Box>
            </Box>
          </Box>
        )}
        <ButtonWithIcon sx={isProcessingScheduleValueExist && { padding: '9px 22px' }} disabled={isShowMode} onClick={handleOpenModal} text={isProcessingScheduleValueExist ? t('Изменить график') : t('График обработки заказов')}>
          {!isProcessingScheduleValueExist && (<AddCircleIcon />)}
        </ButtonWithIcon>
      </Box>
      <OrderProcessingModal sendText={sendText} title={isProcessingScheduleValueExist ? t('Редактирование графика обработки заказов') : t('График обработки заказов')} hashId={hashId} />
    </>
  );
}

export default OrderProcessing;
