// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { useTranslation } from 'react-i18next';
// Icons
import DescriptionIcon from '@mui/icons-material/Description';
// Parts
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { accessList } from '../../../../../../engine/config/access';
import { actions } from '../../../../../../engine/core/billing/reports/actions';
// Helpers
import { useAccessList } from '../../../../../hooks/useAccessList';

function ReconciliationActButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccess = useAccessList(accessList.billingReconciliationActDownload);

  if (!hasAccess) {
    return null;
  }

  const generateActModal = () => {
    dispatch(actions.mergeReconciliation(Map({ modalIsOpen: true })));
  };

  return (
    <>
      <ButtonWithIcon text={t('Сгенерировать акт сверки')} onClick={generateActModal}>
        <DescriptionIcon />
      </ButtonWithIcon>
    </>
  );
}

export default ReconciliationActButton;
