// Core
import { put, select } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';
import i18n from '../../../../init/i18n';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import { pageLinks } from '../../../../config/routes';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const pathname = yield select(selectors.router.pathname);
  const initialPageState = getInitialPageState(lists || {});

  switch (pathname) {
    case pageLinks.partner.new: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [
        ...filteredFilters,
        {
          columnName: tableDataTypes.partnerStatus.name,
          value: [
            {
              label: i18n.t('Заполнение анкеты'),
              value: partnerStatuses.questionnaireFill,
            },
          ],
        },
      ];
      break;
    }
    case pageLinks.partner.waiting: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [
        ...filteredFilters,
        {
          columnName: tableDataTypes.partnerStatus.name,
          value: [
            {
              label: i18n.t('Подписание договора'),
              value: partnerStatuses.contractSigning,
            },
          ],
        },
      ];
      break;
    }
    case pageLinks.partner.rejected: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [
        ...filteredFilters,
        {
          columnName: tableDataTypes.partnerStatus.name,
          value: [
            {
              label: i18n.t('Отклонен'),
              value: partnerStatuses.cancelStatus,
            },
            {
              label: i18n.t('Подписание заморожено'),
              value: partnerStatuses.frozenSigning,
            },
          ],
        },
      ];
      break;
    }
    default: {
      break;
    }
  }
  yield put(actions.setInitialState(initialPageState));
}

function getFilteredFiltersArr(arr) {
  if (Array.isArray(arr)) {
    return arr.filter(({ columnName }) => columnName !== tableDataTypes.partnerStatus.name);
  }
  return [];
}
