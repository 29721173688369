const ChatComponentsStyles = theme => ({
  footer: {
    display: 'grid',
    gridRowGap: 15,
    gridColumnGap: 15,
    marginTop: 'auto',
    width: '100%',
    paddingBottom: 15,
    paddingRight: 15,
    gridTemplateColumns: '39px auto',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      gridRowGap: 0,
      gridTemplateColumns: '39px auto 250px',
    },
  },
  messageInput: {
    gridColumn: '1 / 3',
    [theme.breakpoints.up('md')]: {
      gridColumn: '2 / 3',
      gridRow: '2 / 3',
    },
  },
  inputFile: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
  },
  btnSend: {
    gridColumn: '2 / 3',
    [theme.breakpoints.up('md')]: {
      gridColumn: '3 / 4',
      gridRow: '2 / 3',
    },
  },
  attachedFileList: {
    gridRow: '1 / 2',
    gridColumn: '1 / 3',
    padding: 0,
    margin: '0 0 -4px -2px',
    [theme.breakpoints.up('md')]: {
      margin: 0,
      padding: '4px 4px 4px 0',
      gridColumn: '1 / 4',
    },
  },
});

export default ChatComponentsStyles;
