// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import values from 'lodash/values';
// UI
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/feeds/productsStatistics/actions';
// import { asyncActions } from '../../../../engine/core/feeds/productsStatistics/saga/asyncActions';
// Hooks
import { useFeedsProductsStatisticsList } from './_hooks/useFeedsProductsStatisticsList';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';

const FilterComponent = () => null;
function FeedsProductsStatisticsListTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.feedsProductsStatistics.isLoading);
  // Rows
  const rows = useSelector(selectors.feedsProductsStatistics.list);
  // Paging
  const currentPage = useSelector(selectors.feedsProductsStatistics.currentPage);
  const pageSize = useSelector(selectors.feedsProductsStatistics.pageSize);
  const totalCount = useSelector(selectors.feedsProductsStatistics.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  // const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.feedsProductsStatistics.sorting);
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.feedsProductsStatistics.settingStatus);
  // const settingPending = useSelector(selectors.feedsProductsStatistics.settingPending);
  // Filtering
  const filters = useSelector(selectors.feedsProductsStatistics.filters);
  const columns = values({
    category: tableDataTypes.feedsProductsStatisticsCategoryLink,
    partner: tableDataTypes.feedsProductsStatisticsProductsCount,
  });

  return (
    <DxTable
      name="feedsProductsStatistics"
      rowFilterComponent={FilterComponent}
      columns={columns}
      isLoading={isLoading}
      rows={rows}
      updateHooks={useFeedsProductsStatisticsList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      // onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.feedsProductsStatisticsList.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(FeedsProductsStatisticsListTable);
