// Core
import Typography from '@mui/material/Typography';
import cx from 'classnames';

const Placeholder = (props) => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={cx('placeholder', selectProps.classes.placeholder)}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

export default Placeholder;
