// Core
import React, { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
// Engine
import CircularProgress from '@mui/material/CircularProgress';
import { asyncActions as contentAsyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { apiTime } from '../../../../../../../engine/config/api';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
import { accessList } from '../../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useCallbackAfterPostUser } from '../../../../../../hooks/useCallbackAfterPostUser';
// Style
import {
  BoxWrapperStatistic,
  ItemStatistic,
  ItemStatisticName,
  ItemStatisticQuantity,
} from './CategoryStatusCountStyles';

function CategoryStatusCount() {
  const timeoutId = useRef(null);
  const dispatch = useDispatch();
  const quantityByStatus = useSelector(selectors.content.quantityByStatus);
  const hasProductsCategoriesStatistic = useAccessList(accessList.productsCategoriesStatistic);
  const isLoading = useSelector(selectors.content.isCategoryStatisticLoading);


  const handleOpenCategoryCountModal = (id, name) => {
    dispatch(contentAsyncActions.getCategoryStatisticAsync({ productStatus: id }));
    dispatch(actions.setIsProductCategoryCountModal({ productStatusId: id, isOpen: true, modalTitle: name }));
  };

  useCallbackAfterPostUser(() => {
    dispatch(contentAsyncActions.getCategoryStatisticAsync());
  }, []);

  useEffect(() => {
    dispatch(contentAsyncActions.getCategoryStatisticAsync());
    timeoutId.current = setInterval(() => {
      dispatch(contentAsyncActions.getCategoryStatisticAsync());
    }, apiTime.statistics.products);
    return () => clearInterval(timeoutId.current);
  }, [dispatch]);


  const Counter = quantityByStatus.toJS().map((elem) => {
    const { productStatusColor, productStatusName, productsQuantity } = elem;

    const onClick = () => handleOpenCategoryCountModal(elem.productStatusId, productStatusName);
    return (
      <ItemStatistic
        key={productStatusName}
        component="a"
        onClick={onClick}
        sx={{ color: productStatusColor }}
      >
        <ItemStatisticName component="p">{productStatusName}</ItemStatisticName>
        <ItemStatisticQuantity component="div">
          <NumberFormat value={productsQuantity} thousandSeparator=" " displayType="text" />
        </ItemStatisticQuantity>
      </ItemStatistic>
    );
  });

  return hasProductsCategoriesStatistic ? (
    <BoxWrapperStatistic sx={isLoading && { justifyContent: 'center', alignItems: 'center' }} height={isLoading ? '32px' : 'unset'} width={isLoading ? '614px' : 'unset'}>
      {isLoading ? (
        <CircularProgress color="inherit" size={17} />
      ) : (
        Counter
      )}
    </BoxWrapperStatistic>
  ) : null;
}


export default memo(CategoryStatusCount);
