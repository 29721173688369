// Core
import React, { useEffect } from 'react';
// Parts
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import Typography from '@mui/material/Typography';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import NotificationStyle from './NotificationStyle';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { pageLinks } from '../../../../engine/config/routes';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import { notificationsActionAsync } from '../../../../engine/core/notifications/saga/asyncAction';
// Config
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import Loading from '../../../components/Loading/Loading';
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Helpers
import { useStyles } from '../../../hooks/useStyles';
import { getDate } from '../../../../_helpers/getDate';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';

export const formName = 'notificationForm';

function Notification() {
  const { t } = useTranslation();
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles(NotificationStyle);
  const renderHtml = content => ({ __html: content });
  const notificationData = useSelector(selectors.notification.notificationByHashId);
  const isNotificationByHashIdLoading = useSelector(selectors.notification.isNotificationByHashIdLoading);
  useEffect(() => {
    if (hashId) {
      dispatch(notificationsActionAsync.getNotificationsByHashIdAsync(hashId));
    }
  }, [dispatch, hashId]);
  const changeDataFormat = (data) => {
    if (data !== '') {
      return getDate(data, 'DD MMMM YYYY, HH:mm');
    }
    return data;
  };
  return (
    <Grid container justifyContent="left">
      <PageHeader title={t('Просмотр уведомления')}>
        <ButtonGoBack defaultPage={pageLinks.notifications.all} />
      </PageHeader>
      <Grid md={12} lg={8}>
        <TabsContainer className={classes.notificationWrap}>
          <TabsPanel value="general" index="general">
            {isNotificationByHashIdLoading ? <Loading isLoading /> : (
              <>
                <Typography component="div">
                  <h2 dangerouslySetInnerHTML={renderHtml(notificationData?.title)} />
                </Typography>
                <Typography component="div" className={classes.notificationWrapperText}>
                  <div className={classes.notificationText} dangerouslySetInnerHTML={renderHtml(notificationData?.text)} />
                </Typography>
                <div>
                  { notificationData?.media && (
                  <a href={notificationData?.media.link} target="_blank" rel="noopener noreferrer" download>
                    <ButtonWithIconAndCircularProgress
                      sx={{ p: BUTTON_SIZE.outlinedSmall }}
                      text={t('Скачать прикрепленный файл')}
                    >
                      <CloudDownloadIcon />
                    </ButtonWithIconAndCircularProgress>
                  </a>
                  )}
                  { notificationData?.externalLink && (

                  <a href={notificationData?.externalLink} target="_blank" rel="noopener noreferrer" download>
                    <ButtonWithIconAndCircularProgress
                      sx={{ p: BUTTON_SIZE.outlinedSmall }}
                      text={t('Скачать прикрепленный файл')}
                    >
                      <CloudDownloadIcon />
                    </ButtonWithIconAndCircularProgress>
                  </a>
                  )}
                  { notificationData?.link && (
                    <NavLink to={notificationData?.link}>
                      <ButtonWithIconAndCircularProgress
                        sx={{ p: BUTTON_SIZE.outlinedSmall }}
                        text={t('Перейти в раздел')}
                      >
                        <InsertLinkIcon />
                      </ButtonWithIconAndCircularProgress>
                    </NavLink>
                  )}
                </div>
                <div>
                  <span className={classes.notificationItemsCreate}>{changeDataFormat(notificationData?.createdAt)}</span>
                </div>
              </>
            )}
          </TabsPanel>
        </TabsContainer>
      </Grid>
    </Grid>
  );
}

export default reduxForm({ form: formName })(Notification);
