// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { actions as contentProductActions } from '../../../contentProduct/actions';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutProductsSettingsWorker({ payload: data }) {
  yield put(actions.setProductsSettingsPending(true));

  const response = yield apply(api, api.products.putProductsSettings, [data]);

  if (response && response.status >= 200 && response.status < 400) {
    const {
      productGrid, status, statusTitle, message, errors,
    } = response.data;

    switch (status) {
      case requestStatus.error: {
        yield put(setErrorMessage(errors || message, statusTitle));
        break;
      }
      case requestStatus.success: {
        yield put(actions.setProductGrid(productGrid));
        yield put(setSuccessMessage(message, statusTitle));
        yield put(contentProductActions.setReload(true));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setProductsSettingsPending(false));
}
