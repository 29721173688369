// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function RemoveIcon(props) {
  return (
    <SvgIcon className="person-outlined-icon" fontSize="inherit" viewBox="0 0 20 24" {...props}>
      <path d="M19 4.5H2.5L4 22.5H16L17.5 4.5H1M10 9V18M13.75 9L13 18M6.25 9L7 18M7 4.5L7.75 1.5H12.25L13 4.5" fill="transparent" stroke="#F97988" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default RemoveIcon;
