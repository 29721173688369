export const formName = 'categoryForm';

export const formFields = Object.freeze({
  active: 'active',
  fillingPercent: 'fillingPercent',
  hasChildren: 'hasChildren',
  hashId: 'hashId',
  name: 'name',
  parentName: 'parentName',
  partnerOfferPercent: 'partnerOfferPercent',
  idMagentoCategory: 'idMagentoCategory',
  idMagentoCategoryParent: 'idMagentoCategoryParent',
});
