// Core
import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Form, Field, reduxForm,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
// parts
import FilesUploaderWithValidation from '../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import Modal from '../../../../containers/Modals/Modal/Modal';
// _helpers
import { validators } from '../../../../../_helpers/validationsForm';
// config
import { mimeTypeExtensions } from '../../../../../engine/config/mimeTypeExtensions';
import { api } from '../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';

const formName = 'importSynonymsModal';
const fileName = 'synonymsFile';

function ImportModal(props) {
  const {
    handleSubmit, valid, isModalOpen, setIsModalOpen,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendForm = () => dispatch(submit(formName));

  const onFormSubmit = async (formValues) => {
    setIsLoading(true);
    const file = formValues.get(fileName);
    const response = await api.synonyms.import({ file });

    if (response && response?.status >= 200 && response?.status < 400) {
      const responseData = response.data;
      dispatch(setSuccessMessage('', responseData.status));
      setIsModalOpen(false);
    }
    if (response && response?.status >= 400) {
      if (response.data?.errors?.length > 0) {
        response.data.errors.forEach(error => dispatch(setErrorMessage(error.message, t('Ошибка'))));
      }
    }
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      modalOpen={isModalOpen}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      handleModalSend={handleSendForm}
      disabledSend={isLoading || !valid}
      sendText={t('Импортировать')}
      cancelText={t('Отменить')}
      loadingSend={isLoading}
      title={`${t('Импорт')} XLSX`}
      modalMaxWidth="xs"
      buttonSendIcon={null}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={FilesUploaderWithValidation}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={fileName}
          disabled={isLoading}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

ImportModal.displayName = 'SynonymsUpload';

ImportModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(memo(ImportModal));
