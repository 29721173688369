// Core
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectors } from '../../engine/config/selectors';
import { usePrevious } from './usePrevious';

export function useCallbackAfterPostUser(callback, deps) {
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const prevPostUsersHashIdPending = usePrevious(postUsersHashIdPending);

  useEffect(() => {
    if (postUsersHashIdPending !== prevPostUsersHashIdPending && prevPostUsersHashIdPending === true) {
      callback();
    }
  }, [...deps, postUsersHashIdPending, prevPostUsersHashIdPending]);
}
