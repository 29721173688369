// Core
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import React from 'react';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/partners/partnerServiceRequests/saga/asyncActions';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

const ExportButton = () => {
  const { t } = useTranslation();
  const hasAccessDownload = useAccessList(accessList.partnerServiceRequestsAdminList);
  const downloadPending = useSelector(selectors.partnersServiceRequests.isDownloadPending);
  const dispatch = useDispatch();
  const filters = useSelector(selectors.partnersServiceRequests.filters);
  const onDownload = () => {
    dispatch(asyncActions.partnerServiceRequestsDownloadAction());
  };

  if (!hasAccessDownload) {
    return null;
  }
  const label = (filters && filters.size > 0) ? t('Экспорт фильтрованого') : t('Экспорт данных');

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={downloadPending}
      disabled={downloadPending}
      text={label}
      onClick={onDownload}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
};

export default ExportButton;
