// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
// import { types } from '../types';
// Workers
import {
  // callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPutCategoriesFeaturesItemByHashIdWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_FEATURES_ITEM_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_CATEGORIES_FEATURES_ITEM_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_CATEGORIES_FEATURES_ITEM_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
// function* watchSetCurrentPage() {
//   yield takeEvery(types.SET_CATEGORIES_FEATURES_ITEM_CURRENT_PAGE, callSetToLocalDataWorker);
// }
// function* watchSetPageSize() {
//   yield takeEvery(types.SET_CATEGORIES_FEATURES_ITEM_PAGE_SIZE, callSetToLocalDataWorker);
// }
// function* watchSetFilters() {
//   yield takeEvery(types.SET_CATEGORIES_FEATURES_ITEM_FILTERS, callSetToLocalDataWorker);
// }
// function* watchSetSorting() {
//   yield takeEvery(types.SET_CATEGORIES_FEATURES_ITEM_SORTING, callSetToLocalDataWorker);
// }

function* watchPutCategoriesFeatureItemByHashId() {
  yield takeEvery(asyncTypes.PUT_CATEGORIES_FEATURE_MANAGEMENT_ITEM_BY_HASH_ID_ASYNC, callPutCategoriesFeaturesItemByHashIdWorker);
}

export function* watchersCategoriesFeaturesItem() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    // watchSetCurrentPage(),
    // watchSetPageSize(),
    // watchSetFilters(),
    // watchSetSorting(),
    watchPutCategoriesFeatureItemByHashId(),
  ]);
}
