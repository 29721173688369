import Box from '@mui/material/Box';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../../engine/config/selectors';

const URLS = {
  applicationForm: 'https://otp24.privatbank.ua/',
  alloUA: 'https://allo.ua/',
  form: 'https://payparts2.privatbank.ua/',
  email: 'mailto:allo-dir54@allo.ua',
  edo_email: 'mailto:Edo_doc@allo.ua',
  examplePartner: 'https://allo.ua/ua/partner_test_2/',
};

const PrivatbankInfoModal = ({ modalOpen, handleCloseModal, partnerHashId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const partnerData = useSelector(selectors.partner.userData);
  const companyType = partnerData?.infoFieldValues?.enterpriseType?.current;


  const licenseeLink = companyType === 'ФОП' ? '/docs/example_licensee_agreement_fop_privatbank_bnpl.png' : '/docs/example_licensee_agreement_tov_privatbank_bnpl.png';


  useEffect(() => {
    if (partnerHashId !== undefined) {
      dispatch(partnerAsyncAction.getPartnerInfo({ hashId: partnerHashId }));
    }
  }, [partnerHashId]);

  const handleModalSend = useCallback(() => {
    window.open(URLS.applicationForm);
    handleCloseModal();
  }, [handleCloseModal]);

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalToggle={handleCloseModal}
      handleModalCancel={handleCloseModal}
      handleModalSend={handleModalSend}
      cancelText={t('Отменить')}
      sendText={t('Заполнить форму')}
      modalMaxWidth="sm"
      buttonSendIcon={null}
      title={t('Покупка частями Приватбанк')}
    >
      <Typography fontWeight={700} fontSize={14} sx={{ marginBottom: '20px' }}>{t('Инструкция активации сервиса Оплата частями от ПриватБанка')}:</Typography>
      <Box sx={{ fontSize: '14px', lineHeight: '20px' }}>
        1.<Trans i18nKey="privat_part1" components={[<Link href={URLS.applicationForm} target="_blank" />]} /><br />
        2. <Trans i18nKey="privat_part2" /> <br />
        3. <Trans i18nKey="privat_part3" components={[<Link href={URLS.form} target="_blank" />]} /> <br />
        4. <Trans i18nKey="privat_part4" components={[<Link href={URLS.email} target="_blank" />, <Link href={URLS.examplePartner} target="_blank" />]} /><br />
        5. <Trans i18nKey="privat_part5" components={[<Link href={URLS.alloUA} target="_blank" />, <Link href={URLS.edo_email} target="_blank" />, <Link href={licenseeLink} target="_blank" />, <strong />]} /><br /> <br />
        6. <Trans i18nKey="privat_part6" />
      </Box>
    </Modal>
  );
};


export default PrivatbankInfoModal;
