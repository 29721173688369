import { COLOR } from '../../../../containers/App/AppStyles';

const ModalCropperStyle = {
  cropperWrapper: {
    position: 'relative',
    height: 334,
    minWidth: '300px',
    paddingLeft: 14,
    paddingRight: 14,
    background: 'rgba(0,0,0,.35)',
    '@global': {
      '.cropper-bg': {
        margin: 'auto',
      },
    },
  },
  cropper: {
    position: 'absolute',
    height: '100%',
    width: 'calc(100% - 28px)',
    maxWidth: '512px',
    boxSizing: 'border-box',
    '& .cropper-point': {
      width: 14,
      height: 14,
      '&.point-e, &.point-ne, &.point-se': {
        right: '-7px',
      },
      '&.point-n, &.point-ne, &.point-nw': {
        top: '-7px',
      },
      '&.point-nw, &.point-w, &.point-sw': {
        left: '-7px',
      },
      '&.point-se, &.point-s, &.point-sw': {
        bottom: '-7px',
      },
      '&.point-e, &.point-w': {
        marginTop: '-7px',
      },
      '&.point-n, &.point-s': {
        marginLeft: '-7px',
      },
    },
    '& .cropper-modal': {
      opacity: 0,
    },
  },
  cropperHelpText: {
    textAlign: 'left',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12px',
    color: COLOR.grey['54'],
    marginTop: '14px',
  },
  modalContent: {
    textAlign: 'center',
    paddingTop: 7,
    overflowY: 'auto',
  },
  buttonGroup: {
    display: 'inline-flex',
    marginLeft: 24,
    '&:first-child': {
      marginLeft: 0,
    },
    '& $button': {
      marginLeft: 8,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  buttonsHolder: {
    marginTop: 22,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  button: {
    padding: 0,
    borderRadius: 5,
    border: `1px solid ${COLOR.grey['24']}`,
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonArrowIcon: {
    fontSize: '31px',
  },
};
export default ModalCropperStyle;
