import { COLOR } from '../../containers/App/AppStyles';

export const HistoryButtonStyles = () => ({
  historyListHolder: {
    '& .MuiPopover-paper': {
      borderRadius: '5px',
      background: '#ffffff',
      boxShadow: '0 0 8px 0px rgba(0, 0, 0, 0.18)',
      width: '282px',
      maxHeight: '360px',
      overflowY: 'auto',
      padding: '16px',
    },
  },
  list: {
    '& $listItem:last-child': {
      marginBottom: 0,
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  listItem: {
    marginBottom: '14px',
    paddingBottom: '14px',
    borderBottom: `1px solid ${COLOR.grey['12']}`,
    '& $row:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  currentLabel: {
    padding: '0 5px',
    color: '#ffffff',
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '3px',
    background: `${COLOR.success}`,
  },
  historyButton: {
    color: COLOR.grey.main,
    border: `1px solid ${COLOR.grey['8']}`,
    borderRadius: '5px',
    minWidth: '24px',
    height: '24px',
    padding: '1px',
  },
  historyButtonActive: {
    background: 'rgba(249, 121, 136, 0.50)',
  },
  historyButtonIcon: {
    fontSize: '19px',
    color: `${COLOR.controlsIcons.main}`,
  },
  historyButtonIconActive: {
    color: `${COLOR.grey.main}`,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  dateRow: {
    height: '16px',
    lineHeight: '16px',
  },
  userWhoEditRow: {
    marginBottom: '19px',
  },
  mainText: {
    color: COLOR.grey.main,
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '400',
    height: '10px',
  },
  dateText: {
    lineHeight: '16px',
    height: '16px,',
  },
  userWhoEditText: {
    color: COLOR.grey.main,
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '400',
    height: '10px',
  },
  label: {
    color: COLOR.grey['72'],
    fontSize: '13px',
    lineHeight: '13px',
    fontWeight: '400',
    height: '10px',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '152px',
      padding: '4px 8px',
    },
  },
});
