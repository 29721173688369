// Core
import { Map, List } from 'immutable';
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';

export function* callGetReconciliationDatesWorker() {
  yield put(actions.mergeReconciliation(Map({
    dates: Map({
      pending: true,
    }),
  })));
  const response = yield apply(api, api.billing.getReconciliationDates);
  if (response && response.status >= 200 && response.status < 400) {
    const dates = response.data;
    const dateToObject = dates.map(value => ({ value, label: value }));
    yield put(actions.mergeReconciliation(Map({
      dates: Map({
        items: List(dateToObject),
        pending: false,
      }),
    })));
  } else {
    yield put(actions.mergeReconciliation(Map({
      dates: Map({
        pending: true,
      }),
    })));
  }
}
