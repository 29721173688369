// Core
import {
  useCallback, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

// config
import { selectors } from '../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../engine/config/routes';

export function useControlLinks() {
  const pathname = useSelector(selectors.router.pathname);

  const getLink = useMemo(() => pathname === pageLinks.motivationSystem.partner
    ? pageLinks.motivationSystem.cashBack
    : pageLinks.motivationSystem.partner, [pathname]);

  const isPage = useCallback(path => pathname === path, [pathname]);

  return {
    isPartnersCashBackPage: isPage(pageLinks.motivationSystem.cashBack),
    isSuccessfulPartnerPage: isPage(pageLinks.motivationSystem.partner),
    getLink,
  };
}
