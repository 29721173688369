// Core
import React, { useCallback } from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import Loading from '../../../Loading/Loading';

function NoDataCell(props) {
  const {
    isLoading, color, colSpan, hasList,
  } = props;
  const { t } = useTranslation();
  const getMessage = useCallback(() => isLoading && !hasList ? (
    <Loading
      color={color}
      isLoading
    />
  ) : t('Нет данных'), [isLoading, color]);

  return (
    <VirtualTable.NoDataCell
      colSpan={colSpan}
      getMessage={getMessage}
    />
  );
}

NoDataCell.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  colSpan: PropTypes.number.isRequired,
  color: PropTypes.string,
  hasList: PropTypes.bool,
};

NoDataCell.defaultProps = {
  color: 'secondary',
};

export default NoDataCell;
