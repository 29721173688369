// Core
import {
  apply, delay, put, select,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { stopSubmit } from 'redux-form';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { pageLinks } from '../../../../config/routes';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { actions as partnerActions } from '../../actions';
import { formName } from '../../../../../ui/page/Partner/form';
import { selectors } from '../../../../config/selectors';

// helpers
import { checkPartnerApprovedData } from '../../../../../_helpers/checkPartnerApprovedData';

export function* callPutPartnersHashIdConfirmFormWorker({ payload: { hashId, formData } }) {
  const partner = yield select(selectors.partner.userData);
  const registeredFields = (yield select(state => selectors.form.registeredFields(state, formName))).toJS();

  const isNotApprovedData = checkPartnerApprovedData({
    partner, registeredFields,
  });
  if (isNotApprovedData) {
    return;
  }

  yield put(partnerActions.mergePartnerHashIdConfirm({ pending: true }));
  const response = yield apply(api, api.partner.putPartnersHashIdConfirmForm, [{ hashId, formData }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));

        const submissionErrors = errors.reduce((acc, currentValue) => {
          acc[currentValue.property_path] = currentValue.message;
          return acc;
        }, {});

        yield put(stopSubmit(formName, submissionErrors));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(partnerActions.setReasonRejectionModal({ open: false }));
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(pageLinks.partner.all));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(partnerActions.mergePartnerHashIdConfirm({ pending: false }));
}
