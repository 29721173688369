// Core
import React from 'react';
import get from 'lodash/get';
// UI
import { DataTypeProvider } from '@devexpress/dx-react-grid';

function PaymentTypeFormatter(props) {
  const type = get(props, 'value.label', '');
  return (
    <span>{type}</span>
  );
}

function PaymentTypeProvider(props) {
  return (
    <DataTypeProvider {...props} formatterComponent={PaymentTypeFormatter} />
  );
}

export default PaymentTypeProvider;
