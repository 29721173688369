export const asyncTypes = Object.freeze({
  CONTENT_PRODUCT_BULK_EDIT_ASYNC: 'CONTENT_PRODUCT_BULK_EDIT_ASYNC',
  PUT_UPDATE_VALUES_FROM_MAGENTO_ASYNC: 'PUT_UPDATE_VALUES_FROM_MAGENTO_ASYNC',
  GET_CATEGORY_STATISTIC_ASYNC: 'GET_CATEGORY_STATISTIC_ASYNC',
  GET_PRODUCT_SETTINGS_BY_CATEGORY_ID_ASYNC: 'GET_PRODUCT_SETTINGS_BY_CATEGORY_ID_ASYNC',
  GET_CONTENT_TEMPLATES_CATEGORIES_LIST_ASYNC: 'GET_CONTENT_TEMPLATES_CATEGORIES_LIST_ASYNC',
  GET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_ASYNC: 'GET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_ASYNC',
  PUT_PRODUCTS_ASYNC: 'PUT_PRODUCTS_ASYNC',
  GET_PRODUCTS_STATUSES_ASYNC: 'GET_PRODUCTS_STATUSES_ASYNC',
  GET_PRODUCTS_HASH_ID_ASYNC: 'GET_PRODUCTS_HASH_ID_ASYNC',
  CONTENT_PRODUCT_EDIT_ASYNC: 'CONTENT_PRODUCT_EDIT_ASYNC',
  CONTENT_PRODUCT_ADD_ASYNC: 'CONTENT_PRODUCT_ADD_ASYNC',
  PUT_PRODUCTS_SETTINGS_ASYNC: 'PUT_PRODUCTS_SETTINGS_ASYNC',
  SEND_PRODUCTS_TO_MODERATION_ASYNC: 'SEND_PRODUCTS_TO_MODERATION_ASYNC',
  GET_PRODUCTS_INITIAL_STATE_ASYNC: 'GET_PRODUCTS_INITIAL_STATE_ASYNC',
  GET_CONTENT_TEMPLATES_INITIAL_STATE_ASYNC: 'GET_CONTENT_TEMPLATES_INITIAL_STATE_ASYNC_OLD',
  DELETE_PRODUCTS: 'DELETE_PRODUCTS',
  GET_PRODUCTS_EXPORT: 'GET_PRODUCTS_EXPORT',
  GET_PRODUCTS_PRICES_EXPORT: 'GET_PRODUCTS_PRICES_EXPORT',
  GET_PRODUCTS_PROPERTIES_EXPORT: 'GET_PRODUCTS_PROPERTIES_EXPORT',
  GET_PRODUCTS_ONLY_TEXT_EXPORT: 'GET_PRODUCTS_ONLY_TEXT_EXPORT',
  GET_PRODUCTS_SOURCE_LIST: 'GET_PRODUCTS_SOURCE_LIST',
  POST_PRODUCTS_IMPORT_ASYNC: 'POST_PRODUCTS_IMPORT_ASYNC',
  POST_PRODUCTS_PROPERTIES_IMPORT_ASYNC: 'POST_PRODUCTS_PROPERTIES_IMPORT_ASYNC',
  POST_PRODUCTS_PRICES_IMPORT_ASYNC: 'POST_PRODUCTS_PRICES_IMPORT_ASYNC',
});
