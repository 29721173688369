// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Engine
import { asyncActions } from '../../../../../engine/core/commissions/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';

// Parts
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

function CommissionExport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccess = useAccessList(accessList.partnerCommissionsDownload);
  const isLoading = useSelector(selectors.commissions.isLoading);
  const commissionDownloadPending = useSelector(selectors.commissions.commissionDownloadPending);

  if (!hasAccess) {
    return null;
  }

  const downloadCommission = () => {
    dispatch(asyncActions.getCommissionExportAsync());
  };

  return (
    <span>
      <ButtonWithIconAndCircularProgress
        text={t('Экспорт комиссий')}
        onClick={downloadCommission}
        isLoading={isLoading}
        disabled={commissionDownloadPending}
        sx={{ width: '100%' }}
      >
        <CloudDownloadIcon />
      </ButtonWithIconAndCircularProgress>
    </span>
  );
}

export default CommissionExport;
