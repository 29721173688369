// Core
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
// Parts
import Box from '@mui/material/Box';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import Button from '@mui/material/Button';
import UpdateFromMagentoButton from './UpdateFromMagentoButton';
import ProductMultipleEditingForm from './ProductMultipleEditingForm';
import UpdatePricesButton from './UpdatePricesButton';
import SendToModerationButtonGroup from './SendToModerationButtonGroup';
import ProductsButtonGroups from './ProductsButtonGroups';
import ProductsPropertiesButtonGroups from './ProductsPropertiesButtonGroups';
import ProductsPricesButtonGroups from './ProductsPricesButtonGroups';
import { COLOR } from '../../../../../../containers/App/AppStyles';
// Icons
import PenMenuSideBarOutlinedIcon from '../../../../../../icons/PenMenuSideBarOutlinedIcon';
import ExportImportIcon from '../../../../../../icons/ExportImportIcon';
import RemoveIcon from '../../../../../../icons/RemoveIcon';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { accessList } from '../../../../../../../engine/config/access';
import { actions } from '../../../../../../../engine/core/content/actions';
// Helpers
import { useAccessList } from '../../../../../../hooks/useAccessList';
import {
  buttonStyle, CustomTooltip, sxButton, sxIcon, TabsListStyled, TabStyled,
} from './styles';
import TranslateIcon from '../../../../../../icons/TranslateIcon';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../../../engine/config/userRoles';

const NAME_OR_DESCRIPTION_COLUMN_NAME = [1, 2, 6, 7];


function ContentProductsSidebar() {
  const sidebarActiveMenu = useSelector(selectors.ui.contentProductSidebarActiveMenu);
  const dispatch = useDispatch();
  const [label, setLabel] = useState('');
  const { t } = useTranslation();
  const selections = useSelector(selectors.contentProduct.selection);
  const filters = useSelector(selectors.contentProduct.filters);
  const isDeleting = useSelector(selectors.content.productDeleteModalPending);
  const userConfigurations = useSelector(selectors.user.configurations);
  const hasAccessRemove = useAccessList(accessList.productDelete);
  const hasAccessToTranslate = useUserSomeRole([
    userRoles.admin,
    userRoles.contentManager,
  ]);

  const filteredByNameOrDescription = filters.toJS()
    .filter((item => NAME_OR_DESCRIPTION_COLUMN_NAME.includes(Number(item.columnName))))
    .filter(item => ['Empty_field', 'Empty_field_one'].includes(item.value));

  const onChangeTab = () => dispatch(uiActions.setContentProductSidebarActiveMenu(sidebarActiveMenu === 0 ? 1 : 0));

  useEffect(() => {
    if (selections.size) {
      setLabel('Редактировать выбранное');
    } else if (filters.size) {
      setLabel('Редактировать фильтрованное');
    } else {
      setLabel('Редактировать все');
    }
  }, [selections.size, filters.size]);
  const handleClickRemove = () => {
    dispatch(actions.setProductDeleteModal({ isOpen: true }));
  };

  const translationEnabled = useMemo(() => {
    if (userConfigurations.get('PRODUCT_FEATURE_TRANSLATE_CONFIG')) {
      return JSON.parse(userConfigurations.get('PRODUCT_FEATURE_TRANSLATE_CONFIG')).enabled;
    }

    return false;
  }, [userConfigurations]);

  const translateButtonDisabled = () => {
    if (filteredByNameOrDescription.length > 0) {
      return false;
    }

    return selections.size < 1 || isDeleting || !translationEnabled;
  };

  const handleOpenConfirmTranslateModal = () => {
    dispatch(actions.setProductTranslateModal({ isOpen: true }));
  };

  return (
    <Tabs onChange={onChangeTab} value={sidebarActiveMenu}>
      <SendToModerationButtonGroup />
      <TabsListStyled>
        <TabStyled id="edit_sidebar_cell"><PenMenuSideBarOutlinedIcon sx={sxIcon} /><Trans>{label}</Trans></TabStyled>
        {hasAccessToTranslate && (
        <CustomTooltip title={!translationEnabled ? <>{t('Функционал автоперевода временно недоступен')}</> : ''}>
          <div style={{ width: '100%' }}>
            <Button
              sx={sxButton()}
              fullWidth
              onClick={handleOpenConfirmTranslateModal}
              disabled={translateButtonDisabled()}
            ><TranslateIcon sx={sxIcon} />{t('Перевести')}
            </Button>
          </div>
        </CustomTooltip>
        )}
        <TabStyled id="export_sidebar_cell"><ExportImportIcon sx={sxIcon} />{t('Экспорт / Импорт')}</TabStyled>
        <UpdateFromMagentoButton
          fullWidth
          sxIcon={sxIcon}
          sx={sxButton()}
        />
        {hasAccessRemove && (
        <Button
          sx={sxButton(COLOR.darkStyle.brand.main)}
          fullWidth
          onClick={handleClickRemove}
          disabled={!!(selections.size < 1 || isDeleting)}
        >
          <RemoveIcon sx={sxIcon} />
          {t('Удаление товаров')}
        </Button>
        )}
      </TabsListStyled>
      <UpdatePricesButton fullWidth sx={{ marginBottom: '10px', justifyContent: 'flex-start' }} />
      <hr style={{ border: `1px solid ${COLOR.darkStyle.white['8']}` }} />
      <TabPanel value={0}>
        <Box sx={{ padding: '10px' }}>
          <ProductMultipleEditingForm />
        </Box>
      </TabPanel>
      <TabPanel value={1}>
        <Box sx={{ padding: '10px 0' }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            color: '#fff',
            paddingLeft: '10px',
          }}
          >
            <ExportImportIcon sx={{ marginRight: '10px' }} />
            <Box sx={{ fontSize: 16, fontWeight: '700' }}>
              {t('Экспорт / Импорт')}
            </Box>
          </Box>
          <ProductsButtonGroups sx={buttonStyle} />
          <ProductsPropertiesButtonGroups sx={buttonStyle} />
          <ProductsPricesButtonGroups sx={buttonStyle} />
        </Box>
      </TabPanel>
    </Tabs>
  );
}

export default ContentProductsSidebar;
