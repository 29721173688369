// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { userService } from '../../../../config/api';
import { userActions } from '../../action';
import { statisticsActions } from '../../../statistics/action';
import { uiActions } from '../../../ui/action';
import { localData } from '../../../../config/localData';

export function* callAuthCleanUpWorker() {
  // Clear indexDB
  yield apply(localData, localData.clearStorageWithException, [['completionImportList']]);

  // Set initial application state
  yield put(uiActions.setInitialApplicationState());

  // Clear auth data
  yield apply(userService, userService.setUserInfo, [{}]);

  // Clear user info
  yield put(userActions.setUserInfo({}));

  // Clear statistics
  yield put(statisticsActions.setStatistics({}));

  sessionStorage.removeItem('rememberMe');
}
