import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  validators, phoneRegExp, requisitesPhoneMask, ibanMask, edrpouMask, mfoMask,
} from '../../../../../_helpers/validationsForm';
import DocumentsFilesUploader from '../input/DocumentsFilesUploader';
import FormTextFieldInput from '../input/FormTextFieldInput';
import MaskedInputWithRef from '../input/MaskedInputWithRef';
import { api } from '../../../../../engine/config/api';

export default function ContactsForm({
  partnerFields, formData, setFormData, setFormValid, editMode, isAdminRole,
}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    phone: '', iban: '', edrpou: '', bankName: '', bankMfo: '', certificate: '',
  });
  const handleFileChange = (file) => {
    setFormData(prevState => ({
      ...prevState,
      certificate: file,
    }));
  };

  const handleUseAccountDataChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        ...partnerFields,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        isUseCurrentAccount: !!checked,
      }));
    }
  };

  useEffect(() => {
    const result = Object.entries(formData)
      .filter(([key]) => key !== 'isUseCurrentAccount')
      .filter(([, value]) => isEmpty(value));
    const isErrorsNotExists = Object.values(errors).every(x => x === '');
    if (result && result.length === 0 && isErrorsNotExists) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData, errors]);

  useEffect(() => {
    const validationErrors = {};
    Object.entries(formData).filter(([key]) => (key !== 'isUseCurrentAccount')).forEach(([key, value]) => {
      validationErrors[key] = validators.validateBankRequisitesForm(key, value);
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      ...validationErrors,
    }));
  }, [formData]);

  const prevIban = useRef(formData.iban);

  useEffect(() => {
    if (formData.isUseCurrentAccount) {
      return;
    }
    if (prevIban.current === formData.iban) {
      return;
    }
    prevIban.current = formData.iban;
    if (errors.iban === '' && (errors.edrpou || errors.bankName || errors.bankMfo)) {
      setLoading(true);
      const mfoFromIban = formData.iban.slice(5, 11);
      api.common.getBankInfo({ glmfo: mfoFromIban }).then((response) => {
        if (response.data && response.data.length > 0) {
          const bankInfo = response.data[0];
          const state = {};
          if (formData.bankMfo === '' || errors.bankMfo) {
            state.bankMfo = mfoFromIban;
          }
          if (formData.bankName === '' || errors.bankName) {
            state.bankName = bankInfo.N_GOL;
          }
          if (formData.edrpou === '' || errors.edrpou) {
            state.edrpou = bankInfo.KOD_EDRPOU;
          }
          setFormData(prevState => ({
            ...prevState,
            ...state,
          }));
        }
        setLoading(false);
      });
    }
  }, [errors]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>
          Контактні дані
        </Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid marginBottom={'10px'} marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <Box>
              <FormTextFieldInput
                variant="outlined"
                name={'phone'}
                focused
                fullWidth
                label="Телефон"
                error={!!errors.phone}
                helperText={errors.phone}
                required
                validate={validators.required}
                InputProps={{
                  inputComponent: MaskedInputWithRef,
                  inputProps: {
                    pattern: phoneRegExp,
                    mask: requisitesPhoneMask,
                  },
                  value: formData.phone,
                  onChange: handleChange,
                }}
              />
            </Box>
          </Grid>
          {editMode && isAdminRole && (
          <Grid marginBottom={'10px'} marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <Box>
              <FormTextFieldInput
                variant="outlined"
                name={'email'}
                focused
                disabled
                fullWidth
                value={partnerFields.email}
                label="E-mail"
                validate={validators.required}
              />
            </Box>
          </Grid>
          )}
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>
          Банківські реквізити
        </Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid marginTop={'15px'} item xs={12} sm={12} lg={12} xl={12}>
            <Box>
              <FormControlLabel
                value="end"
                control={(
                  <Checkbox
                    checked={formData.isUseCurrentAccount}
                    name="isUseCurrentAccount"
                    onChange={handleUseAccountDataChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  )}
                label={<Typography fontSize={'14px'} color={'#303030'}>Використати поточний рахунок</Typography>}
                labelPlacement="end"
              />
            </Box>
          </Grid>
          <Grid marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <div className={''}>
              <FormTextFieldInput
                input={'text'}
                focused
                touched
                name="iban"
                variant="outlined"
                error={!!errors.iban}
                helperText={errors.iban}
                label={'IBAN'}
                fullWidth
                disabled={formData.isUseCurrentAccount}
                type={'text'}
                placeholder={'UA__ ______ _____ ______________'}
                required
                validation
                validate={validators.required}
                InputProps={{
                  inputComponent: MaskedInputWithRef,
                  inputProps: {
                    mask: ibanMask,
                  },
                  value: formData.iban,
                  onChange: handleChange,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    </>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <div className={''}>
              <FormTextFieldInput
                input={'text'}
                name="edrpou"
                focused
                touched
                variant="outlined"
                label={'ЄДРПОУ банку'}
                maxLength={8}
                fullWidth
                error={!!errors.edrpou}
                helperText={errors.edrpou}
                disabled={formData.isUseCurrentAccount}
                type={'text'}
                placeholder={'________'}
                required
                validation
                validate={validators.required}
                InputProps={{
                  inputComponent: MaskedInputWithRef,
                  inputProps: {
                    mask: edrpouMask,
                  },
                  value: formData.edrpou,
                  onChange: handleChange,
                }}
              />
            </div>
          </Grid>
          <Grid marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <div className={''}>
              <FormTextFieldInput
                input={'text'}
                name="bankMfo"
                variant="outlined"
                meta={''}
                focused
                error={!!errors.bankMfo}
                helperText={errors.bankMfo}
                label={'МФО банку'}
                fullWidth
                disabled={formData.isUseCurrentAccount}
                type={'text'}
                placeholder={'______'}
                required
                validation
                validate={validators.required}
                InputProps={{
                  inputComponent: MaskedInputWithRef,
                  inputProps: {
                    mask: mfoMask,
                  },
                  value: formData.bankMfo,
                  onChange: handleChange,
                }}
              />
            </div>
          </Grid>
          <Grid marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <div className={''}>
              <FormTextFieldInput
                input={'text'}
                name="bankName"
                textColor={''}
                variant="outlined"
                meta={''}
                focused
                error={!!errors.bankName}
                helperText={errors.bankName}
                value={formData.bankName}
                onChange={handleChange}
                label={'Назва банку'}
                fullWidth
                disabled={formData.isUseCurrentAccount}
                type={'text'}
                placeholder={''}
                required
                validation
                validate={validators.required}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </div>
          </Grid>
          <Grid marginTop={'15px'} item xs={12} sm={6} lg={6} xl={6}>
            <Box>
              <DocumentsFilesUploader
                fullWidth
                editMode={editMode}
                setErrors={setErrors}
                selectedFile={formData.certificate}
                handleFileChange={handleFileChange}
                styleButtonRemoveItemPosition
                input={{ name: 'partner_files', value: formData.files, onChange: handleFileChange }}
              />
            </Box>
          </Grid>
          <Grid marginTop={0} item xs={12} sm={12} lg={12} xl={12}>
            <Box style={{ borderLeft: '2px solid #DB8020' }}>
              <div style={{ paddingLeft: '10px' }}>
                <Typography fontSize={'12px'} color={'rgba(48, 48, 48, 0.87)'}>
                  Для отримання довідки про наявність рахунку зверніться до банку, з яким укладено договір, або замовте її у банківському додатку, якщо ваш банк надає таку послугу.
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
