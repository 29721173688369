// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function PlayListCheckedIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 20 13">
      <path d="M1 1H11M1 5.35484H11M11.3333 9.34677L14 12.25L19 6.80645M1 9.70968H7" fill="transparent" stroke="#fff" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default PlayListCheckedIcon;
