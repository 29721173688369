// Core
import {
  apply, put, select,
} from 'redux-saga/effects';
import { reset } from 'redux-form';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { actions as actionsContentProduct } from '../../../contentProduct/actions';
import { selectors } from '../../../../config/selectors';
import { localData } from '../../../../config/localData';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { formFields, formName } from '../../../../../ui/page/Products/Content/ContentProducts/components/ContentProductsSidebar/ProductMultipleEditingForm';

export function* callContentProductBulkEditWorker({ payload }) {
  const {
    formData, categoryId, forceAction, forceCallBack,
  } = payload;
  yield put(actions.setProductEditPending(true));
  const contentProductsPageSendQuery = yield localData.getItem(localData.keysObj.contentProductsPageSendQuery);
  const filters = yield select(selectors.contentProduct.filters);
  const selections = yield select(selectors.contentProduct.selection);

  const params = {
    property: formData.property,
  };
  const products = selections.size ? selections.toJS() : 'all';
  const filter = reduce(filters.toJS(), (acc, { columnName, value }) => {
    acc[columnName] = isArray(value) ? map(value, filterData => filterData.value).join(',') : value;
    return acc;
  }, {});

  switch (formData.property) {
    case formFields.updateProductsInElastic:
    case formFields.checkProductsInMagento:
    case formFields.updateProductsFromMagento: {
      params.value = true;
      break;
    }
    case formFields.updateProductsMediaFromMagento: {
      params.value = true;
      break;
    }
    case formFields.quantity:
    case formFields.priceAutoUpdate:
    case formFields.featureAutoUpdate:
    case formFields.enabledInMagento: {
      params.value = JSON.parse(formData[formData.property]);
      break;
    }
    case formFields.price:
    case formFields.salePrice: {
      params.value = formData[formData.property].replaceAll(' ', '');
      break;
    }
    case formFields.productStatus: {
      const cancelValue = formData[formFields.cancelStatus];
      const noteValue = formData[formFields.note];
      params.value = formData[formFields.productStatus];
      if (cancelValue) {
        params[formFields.cancelStatus] = cancelValue;
      }
      if (noteValue) {
        params[formFields.note] = noteValue;
      }
      break;
    }
    case formFields.productGroups: {
      params.property = 'addProductToGroup';
      params.value = formData.productGroups;
      break;
    }
    default: {
      params.value = formData[formData.property];
      break;
    }
  }

  const response = yield apply(api, api.products.putProductsBulkEdit, [{
    products,
    filter: selections.size ? undefined : filter,
    categoryId,
    needForceAction: forceAction,
    ...params,
  }]);

  if (formData.property === 'translateProductFeatures') {
    yield put(actions.setProductTranslateModal({ isOpen: false }));
  }
  const {
    needForceAction, errors, status, statusTitle,
  } = response.data;

  if (response && response.status >= 200 && response.status < 400) {
    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        if (forceAction) {
          forceCallBack();
        }
        if (contentProductsPageSendQuery) {
          yield put(actionsContentProduct.setReload(true));
          yield put(actionsContentProduct.setSendQuery(true));
        }
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actionsContentProduct.setReload(true));
        yield put(actionsContentProduct.setSelection([]));
        yield put(reset(formName));
        break;
      }
      case requestStatus.error: {
        if (needForceAction && forceCallBack) {
          forceCallBack(errors[0]?.message);
        } else {
          yield put(setErrorMessage(errors, statusTitle));
        }
        break;
      }
      default: {
        break;
      }
    }
  } else {
    yield put(setErrorMessage(errors, statusTitle));
  }
  yield put(actions.setProductEditPending(false));
}
