// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
// Icons
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Parts
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ModalHeader from './ModalHeader/ModalHeader';
import ModalFooter from './ModalFooter/ModalFooter';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import { BUTTON_SIZE } from '../../App/AppStyles';
// Helpers
import ModalStyle from './ModalStyle';
import { useMDView } from '../../../hooks/useDesktopView';

function Modal(props) {
  const {
    iconSendButton, idModalContent,
    classes, modalOpen, handleModalToggle, handleModalSend, children, title, disabledSend, buttonComponent, cancelText, loadingSend,
    sendText, handleModalCancel, handleModalAdd, addText, hideButtons, modalMaxWidth, fullScreen, fullWidth, subTitleAttentionText,
    subTitle, buttonSendIcon, modalFooterComponent, noPadding,
  } = props;
  const { t } = useTranslation();
  const [disabledClose, setDisabledClose] = useState(false);
  const isMDView = useMDView();
  const defaultSendText = t('Отправить');
  const defaultAddText = t('Добавить');
  const onCancel = () => {
    setDisabledClose(true);
    handleModalCancel();
  };

  useEffect(() => {
    if (modalOpen && disabledClose) {
      setDisabledClose(false);
    }
  }, [disabledClose, modalOpen]);

  const buttonSend = buttonComponent
    ? buttonComponent()
    : (
      <ButtonWithIconAndCircularProgress sx={{ p: BUTTON_SIZE.withIconLarge, height: 36 }} isLoading={loadingSend} disabled={disabledSend || loadingSend} text={sendText || defaultSendText} className={classes.modalButton} onClick={handleModalSend}>
        {buttonSendIcon}
      </ButtonWithIconAndCircularProgress>
    );

  const dialogClasses = {
    paper: classes.modal,
  };

  return (
    <Dialog
      open={!!modalOpen}
      onClose={handleModalToggle}
      aria-labelledby="form-dialog-title"
      maxWidth={modalMaxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen && isMDView}
      classes={dialogClasses}
    >
      <Box
        id={idModalContent}
        className={classes.dialogContentHolder}
      >
        <ModalHeader
          subTitleAttentionText={subTitleAttentionText}
          subTitle={subTitle}
          title={title}
          handleModalToggle={handleModalToggle}
          noMarginBottom={noPadding}
        />
        <DialogContent sx={{ overflowX: 'hidden', padding: noPadding ? '0' : '30px', paddingTop: noPadding ? '0' : '30px !important' }}>
          {children}
        </DialogContent>
        {modalFooterComponent ? (modalFooterComponent()) : (
          <ModalFooter>
            {handleModalCancel && (
            <Button
              sx={{ p: BUTTON_SIZE.outlinedMiddle, height: 36 }}
              variant={hideButtons ? 'contained' : 'outlined'}
              color="primary"
              className={classes.modalButton}
              disabled={disabledClose}
              onClick={onCancel}
            >
              {cancelText || t('Закрыть')}
            </Button>
            )}
            {!hideButtons && (
            <Box sx={{ marginLeft: '10px' }}>
              {handleModalAdd ? (
                <ButtonWithIconAndCircularProgress
                  sx={{ p: BUTTON_SIZE.withIconLarge, height: 36 }}
                  isLoading={loadingSend}
                  disabled={disabledSend}
                  text={addText || defaultAddText}
                  className={`${classes.modalButton}`}
                  onClick={handleModalAdd}
                  id="send_button_modal"
                >
                  {iconSendButton}
                </ButtonWithIconAndCircularProgress>
              ) : buttonSend}
            </Box>
            )}
          </ModalFooter>
        )}
      </Box>
    </Dialog>
  );
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  subTitleAttentionText: PropTypes.string,
  subTitle: PropTypes.string,
  sendText: PropTypes.string,
  addText: PropTypes.string,
  modalMaxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  iconSendButton: PropTypes.node,
  modalFooterComponent: PropTypes.func,
  handleModalToggle: PropTypes.func,
  handleModalAdd: PropTypes.func,
  handleModalSend: PropTypes.func,
  handleModalCancel: PropTypes.func,
  cancelText: PropTypes.string,
  hideButtons: PropTypes.bool,
  fullScreen: PropTypes.bool,
  loadingSend: PropTypes.bool,
  disabledSend: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  buttonComponent: PropTypes.func,
  buttonSendIcon: PropTypes.node,
  idModalContent: PropTypes.string,
};
Modal.defaultProps = {
  fullScreen: false,
  loadingSend: false,
  disabledSend: false,
  hideButtons: false,
  fullWidth: true,
  modalMaxWidth: 'md',
  iconSendButton: <AddCircleIcon />,
  buttonSendIcon: <SendIcon />,
};

export default withStyles(ModalStyle)(Modal);
