// Core
import localforage from 'localforage';

class LocalData {
  constructor() {
    this.localForage = localforage;
    this.keysObj = {
      brands: 'brands',
      brandsLastUpdate: 'brandsLastUpdate',
      categories: 'categories',
      categoriesLastUpdate: 'categoriesLastUpdate',
      productCategories: 'productCategories',
      productGrid: 'productGrid',
      attributeSetsList: 'attributeSetsList',
      attributeSetLastUpdate: 'attributeSetLastUpdate',
      mailTemplateEvents: 'mailTemplateEvents',
      mailTemplateEventsLastUpdate: 'mailTemplateEventsLastUpdate',
      mailTemplateRoles: 'mailTemplateRoles',
      rolesLastUpdate: 'rolesLastUpdate',
      mailTemplateGroups: 'mailTemplateGroups',
      mailTemplateGroupsLastUpdate: 'mailTemplateGroupsLastUpdate',
      statuses: 'statuses',
      statusesLastUpdate: 'statusesLastUpdate',
      productCategory: 'productCategory',
      feedStatuses: 'feedStatuses',
      partnerPaymentTypes: 'partnerPaymentTypes',
      partnerPaymentTypesLastUpdate: 'partnerPaymentTypesLastUpdate',
      feedStatusesLastUpdate: 'feedStatusesLastUpdate',
      notificationsLastShow: 'notificationsLastShow',
      feedCategoriesStatuses: 'feedCategoriesStatuses',
      feedCategoriesStatusesLastUpdate: 'feedCategoriesStatusesLastUpdate',
      feedProductsStatuses: 'feedProductsStatuses',
      feedProductsPageSendQuery: 'feedProductsPageSendQuery',
      contentProductsPageSendQuery: 'contentProductsPageSendQuery',
      feedProductsStatusesLastUpdate: 'feedProductsStatusesLastUpdate',
      faqServiceDescLastUpdate: 'faqServiceDescLastUpdate',
      partners: 'partners',
      partnersLastUpdate: 'partnersLastUpdate',
      productsStatuses: 'productsStatuses',
      productsStatusesLastUpdate: 'productsStatusesLastUpdate',
      SDAttachedFiles: 'SDAttachedFiles',
      completionImportList: 'completionImportList',
      business: 'business',
      businessInfo: 'businessInfo',
      characteristicInformer: 'characteristicInformer',
    };
  }

  getItem(key) {
    return this.localForage
      .getItem(key)
      .catch(console.warn); // eslint-disable-line no-console
  }

  setItem(key, data) {
    this.localForage
      .setItem(key, data)
      .catch(console.warn); // eslint-disable-line no-console
  }

  removeItem(key, successCallback) {
    this.localForage
      .removeItem(key, successCallback)
      .catch(console.warn); // eslint-disable-line no-console
  }

  clearStorageWithException(exceptions = []) {
    this.localForage.keys().then((keys) => {
      keys.forEach((key) => {
        if (!exceptions.includes(key)) {
          this.localForage.removeItem(key)
            .catch(console.warn); // eslint-disable-line no-console
        }
      });
    });
  }

  clearStorage() {
    this.localForage
      .clear()
      .catch(console.warn); // eslint-disable-line no-console
  }
}

export const localData = new LocalData();
