import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';

export default function SplitButton({ handleSend, isSendingData, partnerServiceRequest }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        size="small"
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{
          height: '33px',
          marginRight: '20px',
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
        >Відправити все
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            width: '8px',
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {partnerServiceRequest.contractName ? (
                    <Tooltip
                      title={(
                        <Box>
                          <Typography fontSize={'12px'}>Договір: <b>{partnerServiceRequest.contractName}</b> </Typography>
                          <Typography fontSize={'12px'}>Дата створення: <b>{partnerServiceRequest.contractCreatedDate}</b></Typography>
                          {partnerServiceRequest.contractUpdatedDate && (
                            <Typography fontSize={'12px'}>Дата оновлення з EDI: <b>{partnerServiceRequest.contractUpdatedDate}</b></Typography>
                          )}
                        </Box>
                      )}
                      placement="bottom"
                    >
                      <MenuItem sx={{ fontSize: '14px' }} disabled={isSendingData} onClick={handleSend}>
                        Відправити договір
                        <Box sx={{
                          marginLeft: '10px',
                          marginBottom: '7px',
                          backgroundColor: '#dd0404',
                          color: '#FFFFFF',
                          fontSize: '10px',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                          borderRadius: '4px',
                        }}
                        >
                          {partnerServiceRequest.sedInternalSign === 0 && partnerServiceRequest.sedExternalSign === 0 && (<>Новий</>)}
                          {partnerServiceRequest.sedInternalSign === 0 && partnerServiceRequest.sedExternalSign > 0 && (<>Підписаний партнером</>)}
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  ) : (
                    <MenuItem sx={{ fontSize: '14px' }} onClick={handleSend}>
                      Відправити договір
                    </MenuItem>
                  )}
                  <MenuItem disabled sx={{ fontSize: '14px' }}>
                    Відправити довіреність
                  </MenuItem>
                  <MenuItem disabled sx={{ fontSize: '14px' }}>
                    Відправити лист про банківські реквізити
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
