const getParents = (options, parentValue) => {
  const parents = [];
  const stack = [...options.filter(option => option.value === parentValue)];
  while (stack.length) {
    const current = stack.pop();
    parents.push(current);
    options.forEach((option) => {
      if (option.value === current.parentValue) {
        stack.push(option);
      }
    });
  }
  return parents.reverse();
};

export default getParents;
