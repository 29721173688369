const ContentWrapperStyles = theme => ({
  panel: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '56px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '56px',
    },
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  '@global .grecaptcha-badge': {
    visibility: 'hidden !important',
  },
});

export default ContentWrapperStyles;
