// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { Map } from 'immutable';

// lodash
import forEach from 'lodash/forEach';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutPartnerAccessCredentialsWorker({ payload }) {
  const { hashId, formData } = payload;
  yield put(actions.putPartnerAccessCredentials({ pending: true }));

  const changeKeys = () => {
    const result = {};
    forEach(formData, (value, key) => {
      result[key.split('-')[1]] = value;
    });

    return result;
  };
  const response = yield apply(api, api.partners.putPaymentAccessCredentials, [{
    hashId,
    formData: {
      credentials: changeKeys(),
    },
  }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.putPartnerAccessCredentials({ pending: false }));
        yield put(actions.setPartnerAccessCredentials({ data: Map() }));
        yield put(asyncActions.getParentAccessByHashIdAsync(hashId));
        yield put(actions.setPartnerAccessModal({
          isOpen: false,
        }));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(actions.putPartnerAccessCredentials({ pending: false }));
        break;
      }
      default: {
        break;
      }
    }
  }
}
