// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import FieldWithClearButtonStyle from './FieldWithClearButtonStyle';
// Engine
import { selectors } from '../../../engine/config/selectors';

// Hooks
import { useStyles } from '../../hooks/useStyles';

function FieldWithClearButton(props) {
  const {
    name, disabled, children, alignButton, value, margin,
    showButton, onRefresh, refresh, updateValueFromMagentoPending, updateValueFromMagentoFeatureId,
    handlerClear, buttonClasses, clearDisabled, clearText, canDelete, onChange, error, touched, fieldWrapperStyle,
  } = props;

  const handleChangeValue = () => {
    onChange('');
    if (typeof handlerClear === 'function') {
      handlerClear();
    }
  };
  const classes = useStyles(FieldWithClearButtonStyle);
  const deleteButtonStyle = cx(classes.deleteButton, { [classes.alignTop]: alignButton === 'top', [buttonClasses]: buttonClasses });
  const showDeleteButton = showButton && !disabled && canDelete && (value !== '' || (typeof value === 'object' && value.size) || (Array.isArray(value) && value.length));
  return (
    <div className={classes.wrapper}>
      <div
        style={fieldWrapperStyle}
        className={cx(classes.fieldWrapper, {
          [classes.fieldWrapperError]: error && touched,
        })}
      >
        {children}
      </div>
      { (refresh || showDeleteButton) && (
        <Box className={classes.buttonHolder} sx={{ marginTop: margin === 'normal' ? '20px' : '4px' }}>
          {refresh
            && (
              <Button
                className={classes.refreshButton}
                disabled={updateValueFromMagentoPending && updateValueFromMagentoFeatureId === name}
                onClick={onRefresh}
                aria-label="refresh"
              >
                <RefreshIcon />
              </Button>
            )
          }
          {
            showDeleteButton
            && (
              <Button
                disabled={clearDisabled}
                className={deleteButtonStyle}
                onClick={handleChangeValue}
              >
                <DeleteOutlineIcon /> {clearText && clearText}
              </Button>
            )
          }
        </Box>
      )}
    </div>
  );
}

FieldWithClearButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  clearText: PropTypes.string,
  name: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  clearDisabled: PropTypes.bool,
  buttonClasses: PropTypes.string,
  margin: PropTypes.string,
  onRefresh: PropTypes.func,
  handlerClear: PropTypes.func,
  refresh: PropTypes.bool,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  updateValueFromMagentoPending: PropTypes.bool,
  updateValueFromMagentoFeatureId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  canDelete: PropTypes.bool,
  alignButton: PropTypes.string,
  fieldWrapperStyle: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

FieldWithClearButton.defaultProps = {
  disabled: false,
  showButton: true,
  clearDisabled: false,
  canDelete: true,
};

function mapStateToProps(state) {
  return {
    updateValueFromMagentoPending: selectors.content.updateValueFromMagentoPending(state),
    updateValueFromMagentoFeatureId: selectors.content.updateValueFromMagentoFeatureId(state),
  };
}

export default connect(mapStateToProps, null)(FieldWithClearButton);
