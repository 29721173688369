// Core
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from 'react-redux';
// Hooks
import { useUserRole } from '../../../../hooks/useUserRole';
// Engine
import { userRoles } from '../../../../../engine/config/userRoles';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import { asyncActions } from '../../../../../engine/core/feeds/products/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';

function ExportCSVButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = useUserRole(userRoles.admin);
  const isLoading = useSelector(selectors.feedsProducts.productsFeedExportPending);

  if (!isAdmin) {
    return null;
  }

  const handleClick = () => {
    dispatch(asyncActions.getFeedsDownloadAsync());
  };

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Экспорт данных')}
      onClick={handleClick}
      isLoading={isLoading}
      disabled={isLoading}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default ExportCSVButton;
