// Core
import { useSelector } from 'react-redux';

// Selectors
import { selectors } from '../../engine/config/selectors';

export function useAccessList(accessName) {
  const accessList = useSelector(selectors.user.accessList);
  return accessList.includes(accessName);
}
