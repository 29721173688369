// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

// UI
import { Link as MaterialLink, Typography } from '@mui/material';

// Parts
import Modal from '../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../engine/config/selectors';


export default function ModalShowFirstPayment() {
  const { showPopups } = useSelector(selectors.user.userInfo);
  const hash = useSelector(selectors.router.hash);
  const [showModal, setShowModal] = useState(showPopups?.showFirstPayment && hash.toLowerCase() !== '#showinvoice');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hiddenModal = () => setShowModal(false);
  const onClickLink = () => dispatch(push('#showinvoice'));

  useEffect(() => {
    if (hash.toLowerCase() === '#showinvoice') {
      hiddenModal();
    }
  }, [hash]);

  return (
    <Modal
      modalMaxWidth="sm"
      handleModalCancel={hiddenModal}
      handleModalToggle={hiddenModal}
      modalOpen={showModal}
      title={t('Уважаемый партнер!')}
      sendText={t('Пополнить баланс')}
      handleModalSend={onClickLink}
    >
      <Typography align="center">
        {t('Для активации вашего аккаунта')}
        <br />
        {t('Пополните баланс')}
        <br />
        {t('Для пополнения нажмите')} <MaterialLink onClick={onClickLink}>{t('здесь')}</MaterialLink>.
      </Typography>
    </Modal>
  );
}
