import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../engine/core/feeds/all/actions';
import { selectors } from '../../../../../engine/config/selectors';

function RefreshModalResult() {
  const refreshShowModal = useSelector(selectors.feeds.refreshResultShowModal);
  const title = useSelector(selectors.feeds.refreshResultStatusTitleShowModal);
  const message = useSelector(selectors.feeds.refreshResultMessageShowModal);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(actions.setFeedsRefreshResult({ showModal: false, message: null, statusTitle: null }));
  };

  return (
    <Modal
      title={title}
      modalOpen={refreshShowModal}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      modalMaxWidth="sm"
      hideButtons
      cancelText="OK"
    >
      {message}
    </Modal>
  );
}
export default RefreshModalResult;
