export const types = Object.freeze({
  SET_PRODUCT_FEED_CATEGORIES_LIST: 'SET_PRODUCT_FEED_CATEGORIES_LIST',
  SET_PRODUCT_FEED_CATEGORIES_CURRENT_PAGE: 'SET_PRODUCT_FEED_CATEGORIES_CURRENT_PAGE',
  SET_PRODUCT_FEED_CATEGORIES_FILTERS: 'SET_PRODUCT_FEED_CATEGORIES_FILTERS',
  SET_PRODUCT_FEED_CATEGORIES_PAGE_SIZE: 'SET_PRODUCT_FEED_CATEGORIES_PAGE_SIZE',
  SET_PRODUCT_FEED_CATEGORIES_SORTING: 'SET_PRODUCT_FEED_CATEGORIES_SORTING',
  SET_PRODUCT_FEED_CATEGORIES_INITIAL_STATE: 'SET_PRODUCT_FEED_CATEGORIES_INITIAL_STATE',
  SET_PRODUCT_FEED_CATEGORIES_SETTING_STATUS: 'SET_PRODUCT_FEED_CATEGORIES_SETTING_STATUS',
  SET_PRODUCT_FEED_CATEGORIES_SETTING_PENDING: 'SET_PRODUCT_FEED_CATEGORIES_SETTING_PENDING',
  SET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID_PADDING: 'SET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID_PADDING',
  SET_PRODUCT_FEED_CATEGORIES_LIST_DEFAULT_STATE: 'SET_PRODUCT_FEED_CATEGORIES_LIST_DEFAULT_STATE',
});
