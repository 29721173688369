import { saveAs } from 'file-saver';

export function getFileFromBlob(defaultFileName, response) {
  const EMPTY_STRING = '';
  const { Blob } = window;
  const data = response.data;
  const contentType = response.headers['content-type'];
  const blob = new Blob([data], { type: contentType || EMPTY_STRING });
  const contentDisposition = response.headers['content-disposition'];

  let filename = defaultFileName;
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+)/);
    if (fileNameMatch && fileNameMatch.length === 2) {
      filename = fileNameMatch[1].replace(/"/g, EMPTY_STRING);
    }
  }

  saveAs(blob, filename);
}
