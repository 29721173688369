// core
import { memo } from 'react';
import { Field } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import Box from '@mui/material/Box';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import ControlButtons from '../ControlButtons/ControlButtons';
import { validators } from '../../../../../_helpers/validationsForm';

function AdditionalEmailGroupFields(props) {
  const {
    fields, index, member, disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }} mb={1} mt={2}>
      <Field
        name={`${member}.value`}
        component={TextField}
        fullWidth
        label={t('Дополнительный Email')}
        validation
        validate={[validators.additionalEmail]}
        disabled={disabled}
      />
      <ControlButtons index={index} fields={fields} disabled={disabled} />
    </Box>
  );
}

AdditionalEmailGroupFields.propTypes = {
  fields: PropTypes.object.isRequired,
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default memo(AdditionalEmailGroupFields);
