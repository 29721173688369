// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_ACCESS_LIST_ASYNC, params);
  },
  getPartnersPaymentMethodsAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_PAYMENT_METHODS_ASYNC, params);
  },
  getParentAccessByHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_PARTNER_ACCESS_INFO_ASYNC, hashId);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PARTNERS_ACCESS_SETTINGS_ASYNC, param);
  },
  putPartnerAccessAsync(data) {
    return getActionCreator(asyncTypes.PUT_PARTNER_ACCESS_ASYNC, data);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PARTNERS_ACCESS_INITIAL_STATE_ASYNC, path);
  },
  getPartnerAccessCredentialsAsync(hashId) {
    return getActionCreator(asyncTypes.GET_PARTNER_ACCESS_CREDENTIALS_ASYNC, hashId);
  },
  putPartnerAccessCredentialsAsync(data) {
    return getActionCreator(asyncTypes.PUT_PARTNER_ACCESS_CREDENTIALS_ASYNC, data);
  },
  deletePartnerAccessCredentialsAsync(payload) {
    return getActionCreator(asyncTypes.DELETE_PARTNER_ACCESS_CREDENTIALS_ASYNC, payload);
  },
  deletePartnerAccessAsync(hashId) {
    return getActionCreator(asyncTypes.DELETE_PARTNER_ACCESS_ASYNC, hashId);
  },
  postPartnerAccessUpdateWfpAsync(hashId) {
    return getActionCreator(asyncTypes.POST_PARTNERS_ACCESS_UPDATE_WFP_ASYNC, hashId);
  },
  getDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNERS_ACCESS_DOWNLOAD_ASYNC, params);
  },
  postPartnersPaymentAccessesAsync(params) {
    return getActionCreator(asyncTypes.POST_PARTNERS_PAYMENT_ACCESSES_ASYNC, params);
  },
});
