// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
// Parts
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/messages/actions';
// Helpers
import { useRecipientsImportItems } from './_hooks/useRecipientsImportItems';

function PartnersNotFoundModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpenPartnersNotFoundModal = useSelector(selectors.messages.isOpenPartnersNotFoundModal);
  const { currentPartnersNotFound } = useRecipientsImportItems();

  const handleModalToggle = () => {
    dispatch(actions.mergePartnersNotFoundModal({
      isOpen: !isOpenPartnersNotFoundModal,
    }));
  };

  return (
    <Modal
      modalOpen={isOpenPartnersNotFoundModal}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      modalMaxWidth="sm"
      hideButtons
      title={t('Не найденные партнеры')}
    >
      <Grid container>
        {map(currentPartnersNotFound, partner => (
          <Chip
            key={partner.label}
            label={partner.label}
            variant="outlined"
            size="small"
            sx={{ ml: 0, mt: 0 }}
          />
        ))}
      </Grid>
    </Modal>
  );
}

PartnersNotFoundModal.displayName = 'PartnersNotFoundModal';


export default memo(PartnersNotFoundModal);
