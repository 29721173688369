import { apply, put, select } from 'redux-saga/effects';
import { selectors } from '../../../../config/selectors';
import { partnerActions } from '../../action';
import { api } from '../../../../config/api';

export function* callGetPartnersContextForServiceListWorker() {
  const listContextLoading = yield select(selectors.partner.partnersListContextBillingPaymentForPartnerContextLoading);
  if (listContextLoading) {
    return null;
  }

  yield put(partnerActions.setPartnerContextListForServiceRequestsPending(true));

  let data = {
    items: [],
  };
  const params = {
    context: 'select_allo_express_requests',
  };

  const response = yield apply(api, api.partner.getPartnersList, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
  }
  yield put(partnerActions.setPartnerContextListForServiceRequests(data?.items));
  yield put(partnerActions.setPartnerContextListForServiceRequestsPending(false));
}
