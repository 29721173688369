// Core
import * as PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const {
    decimalScale, decimalSeparator, fixedDecimalScale,
    thousandSeparator, onChange, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      onValueChange={onChange}
      thousandSeparator={thousandSeparator}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  decimalScale: PropTypes.number,
  decimalSeparator: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  thousandSeparator: PropTypes.string,
};

NumberFormatCustom.defaultProps = {
  decimalSeparator: ',',
  fixedDecimalScale: false,
  thousandSeparator: ' ',
};

export default NumberFormatCustom;
