// Core
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
import { asyncActions as contentProductAsyncActions } from '../../../../../../../engine/core/contentProduct/saga/asyncActions';
// Parts
import TableFilter from '../../../../../../components/_Table/TableFilter/TableFilter';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import Loading from '../../../../../../components/Loading/Loading';
import NoDataCell from '../../../../../../components/_Table/DxTable/components/NoDataCell';
// Style
import ContentProductCategoryCountModalStyle from './ContentProductCategoryCountModalStyle';
import TableHeaderRowLabel from '../../../../../../components/_Table/DxTable/components/DxGridSettings/TableHeaderRowLabel';

const useStyles = makeStyles(ContentProductCategoryCountModalStyle);

const CustomTableRow = ({ tableRow, children, ...restProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productStatusId = useSelector(selectors.content.isProductCategoryCountModalId);
  const handleRedirectCategoryId = () => {
    dispatch(contentProductAsyncActions.redirectToCategoryWithStatus({
      categoryId: tableRow.row.categoryId,
      productStatusId,
    }));
  };

  return (
    <Table.Row
      {...restProps}
      tableRow={tableRow}
      className={classes.row}
      onClick={handleRedirectCategoryId}
    >
      {children}
    </Table.Row>
  );
};

const CustomFilterCellComponent = props => <TableFilter {...props} defaultFilterValue="" />;
const integratedSortingColumnExtensions = [
  { columnName: 'productsQuantity', compare: (a, b) => (+a < +b) ? -1 : 1 },
];

function ContentProductCategoryCountModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectors.content.isProductCategoryCountModalOpen);
  const modalTitle = useSelector(selectors.content.changeProductStatusModalName);
  const quantityByCategories = useSelector(selectors.content.quantityByCategories);
  const categoryStatisticPending = useSelector(selectors.content.isCategoryStatisticLoading);
  const { t } = useTranslation();

  const columns = [
    { name: 'categoryName', title: t('Категория') },
    { name: 'productsQuantity', title: t('Количество товаров'), type: 'number' },
  ];

  const tableColumnExtensions = [
    { columnName: 'categoryName', width: '80%' },
    { columnName: 'productsQuantity', width: '20%' },
  ];

  const handleClickClose = () => {
    dispatch(actions.setIsProductCategoryCountModal({ productStatusId: null, isOpen: false }));
  };

  const RenderNoDataCellComponent = useCallback(
    () => <NoDataCell colSpan={columns.length + 1} isLoading={categoryStatisticPending} />,
    [columns.length, categoryStatisticPending],
  );

  return (
    <Modal
      modalOpen={isOpen}
      hideButtons
      handleModalCancel={handleClickClose}
      handleModalToggle={handleClickClose}
      title={modalTitle}
      modalMaxWidth="sm"
    >
      {categoryStatisticPending
        ? (
          <Loading isLoading />
        )
        : (
          <Paper>
            <Grid
              rows={quantityByCategories.toJS()}
              columns={columns}
            >
              <SortingState />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <FilteringState />
              <IntegratedFiltering />
              <Table
                rowComponent={CustomTableRow}
                columnExtensions={tableColumnExtensions}
                noDataCellComponent={RenderNoDataCellComponent}
              />
              <TableHeaderRow showSortingControls sortLabelComponent={TableHeaderRowLabel} />
              <TableFilterRow cellComponent={CustomFilterCellComponent} />
            </Grid>
          </Paper>
        )
        }
    </Modal>
  );
}

ContentProductCategoryCountModal.displayName = 'ContentProductCategoryCountModal';

export default ContentProductCategoryCountModal;
