// Core
import {
  apply, delay, put, select,
} from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
import { selectors } from '../../../../config/selectors';

export function* callPostBankInstallmentsWorker({ payload }) {
  const { hashId, items } = payload;
  yield put(actions.setList({ pending: true }));
  const treeDataRowIds = yield select(selectors.bankInstallments.treeDataRowIds);
  const filters = yield select(selectors.bankInstallments.filters);


  const response = yield apply(api, api.partners.postBankInstallments, [hashId, items]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(setSuccessMessage(response.data.message, response.data.statusTitle));


    const paramsByFilter = filters.toJS().reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {});


    const params = {
      hashId,
      ...Object.keys(paramsByFilter).length > 0 ? {} : { parentId: 0 },
      ...paramsByFilter,
      forceRoot: true,
    };

    yield put(actions.setSelection([]));
    yield put(asyncActions.getListAsync(params, []));
    yield delay(100);
    yield put(asyncActions.getListAsync(params, treeDataRowIds.toJS()));
  } else {
    yield put(setErrorMessage(response.data.error));
  }
}
