// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetListWorker({ payload: params }) {
  yield put(actions.setListLoading({ pending: true }));
  const response = yield apply(api, api.partners.getTransactionsPayment, [params]);

  let data = {
    items: [],
    totalCount: 0,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  yield put(actions.setList(data));
  yield put(actions.setListLoading({ pending: false }));
}
