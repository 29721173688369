// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';

// config
import { selectors } from '../../../../../engine/config/selectors';

import { recipientsTypes } from '../_helpers/recipientsTypes';

export function useMessageReadOrEditPage(row) {
  const messageByHashId = useSelector(selectors.messages.messageByHashId);
  const { usersByRoles } = recipientsTypes;

  return useMemo(() => {
    const message = messageByHashId.toJS();
    const isSentAt = !isEmpty(message) && Boolean(message.sentAt) || row && Boolean(row.sentAt);

    const isQueueSending = !isEmpty(message)
      && !isEmpty(message.messageStatus)
      && message.messageStatus.value === usersByRoles
      || row && !isEmpty(row.messageStatus) && row.messageStatus.value === usersByRoles;

    const isEditMessage = !isEmpty(message)
      && !message.sentAt
      && !isEmpty(message) && message.messageStatus.value !== 2
      || row && !isEmpty(row.messageStatus) && row.messageStatus.value !== usersByRoles;

    return { isReadMessage: isQueueSending || isSentAt, isEditMessage };
  }, [messageByHashId, row]);
}
