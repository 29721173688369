export const types = Object.freeze({
  SET_TRANSACTIONS_PAYMENT_LIST: 'SET_TRANSACTIONS_PAYMENT_LIST',
  SET_TRANSACTIONS_PAYMENT_LIST_LOADING: 'SET_TRANSACTIONS_PAYMENT_LIST_LOADING',
  SET_TRANSACTIONS_PAYMENT_CURRENT_PAGE: 'SET_TRANSACTIONS_PAYMENT_CURRENT_PAGE',
  SET_TRANSACTIONS_PAYMENT_FILTERS: 'SET_TRANSACTIONS_PAYMENT_FILTERS',
  MERGE_TRANSACTIONS_PAYMENT_LIST: 'MERGE_TRANSACTIONS_PAYMENT_LIST',
  SET_TRANSACTION_PAYMENT_INFO: 'SET_TRANSACTION_PAYMENT_INFO',
  MERGE_TRANSACTIONS_PAYMENT_STATUSES: 'MERGE_TRANSACTIONS_PAYMENT_STATUSES',
  PUT_TRANSACTION_PAYMENT_ASYNC: 'PUT_TRANSACTION_PAYMENT_ASYNC',
  PUT_TRANSACTION_PAYMENT_INFO: 'PUT_TRANSACTION_PAYMENT_INFO',
  SET_TRANSACTIONS_PAYMENT_PAGE_SIZE: 'SET_TRANSACTIONS_PAYMENT_PAGE_SIZE',
  SET_TRANSACTIONS_PAYMENT_SORTING: 'SET_TRANSACTIONS_PAYMENT_SORTING',
  SET_TRANSACTIONS_PAYMENT_INITIAL_STATE: 'SET_TRANSACTIONS_PAYMENT_INITIAL_STATE',
  SET_TRANSACTIONS_PAYMENT_SETTING_STATUS: 'SET_TRANSACTIONS_PAYMENT_SETTING_STATUS',
  SET_TRANSACTIONS_PAYMENT_SETTING_PENDING: 'SET_TRANSACTIONS_PAYMENT_SETTING_PENDING',
});
