import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import * as React from 'react';
import AlloExpressStyle from './AlloExpressStyle';

const getOrderAddress = (order) => {
  if (order.stockName !== '') {
    return `${order.regionName} ${order.shippingCity}, ${order.stockName}`;
  }
  return `${order.shippingStreet} ${order.shippingHouse} ${order.shippingApartment}`;
};

export default function OrderInfo({ orderData }) {
  const alloExpressStyles = AlloExpressStyle;

  return (
    <>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Одержувач
        </Typography>
        <Divider />
        <Grid>
          <Box sx={{ pt: '20px' }}>
            <Box sx={alloExpressStyles.boxStyle}>
              <Typography sx={alloExpressStyles.titlesStyle}>Одержувач:</Typography>
              <Box width={'10px'} />
              <Typography sx={alloExpressStyles.fieldsStyle}><Box>{orderData.customerName}</Box></Typography>
            </Box>
            <Box sx={alloExpressStyles.boxStyle}>
              <Typography sx={alloExpressStyles.titlesStyle}>Телефон:</Typography>
              <Box width={'10px'} />
              <Typography sx={alloExpressStyles.fieldsStyle}>{orderData.customerPhone}</Typography>
            </Box>
            <Box sx={alloExpressStyles.boxStyle}>
              <Typography sx={alloExpressStyles.titlesStyle}>Адреса точки видачі:</Typography>
              <Box width={'10px'} />
              <Typography sx={alloExpressStyles.fieldsStyle}>{getOrderAddress(orderData)}</Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Про замовлення
        </Typography>
        <Divider />
        <Box sx={{ pt: '20px' }}>
          <Box sx={alloExpressStyles.boxStyle}>
            <Typography sx={alloExpressStyles.titlesStyle}>Дата замовлення:</Typography>
            <Box width={'10px'} />
            <Typography sx={alloExpressStyles.fieldsStyle}><Box>{orderData.orderDate}</Box></Typography>
          </Box>
          <Box sx={alloExpressStyles.boxStyle}>
            <Typography sx={alloExpressStyles.titlesStyle}>Замовлення №:</Typography>
            <Box width={'10px'} />
            <Typography sx={alloExpressStyles.fieldsStyle}>{orderData.orderNumber}</Typography>
          </Box>
          <Divider />
        </Box>
        <Grid>
          <Box sx={{ pt: '20px' }}>
            {orderData && orderData.orderItems.length > 0 && orderData.orderItems.map((orderItem, key) => (
              <>
                <Box key={key} sx={alloExpressStyles.boxStyle}>
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Link
                      sx={{ display: 'flex', alignItems: 'center', color: 'rgba(48, 48, 48, 0.72)' }}
                      href={orderItem.referrer}
                      target={'_blank'}
                    >
                      <OpenInNewOutlinedIcon fontSize={'4px'} />
                    </Link>
                    <Typography sx={{ ml: '4px', ...alloExpressStyles.titlesStyle }}>Товар {key + 1}:</Typography>
                  </Box>
                  <Box width={'10px'} />
                  <Typography sx={alloExpressStyles.fieldsStyle}>{orderItem.name}</Typography>
                </Box>
              </>
            ))}
            <Divider />
            <Box sx={{ mt: '10px', ...alloExpressStyles.boxStyle }}>
              <Typography sx={alloExpressStyles.titlesStyle}>Сума замовлення: </Typography>
              <Box width={'10px'} />
              <Typography fontWeight={700} sx={alloExpressStyles.fieldsStyle}>{orderData.amount} ₴</Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
