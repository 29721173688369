import TagManager from 'react-gtm-module';

export const googleTagManager = () => {
  const googleTagManagerIsActive = process.env.REACT_APP_GTAG === 'true';
  const googleTagManagerKey = process.env.REACT_APP_GTAG_KEY;
  if (googleTagManagerIsActive) {
    const tagManagerArgs = {
      gtmId: googleTagManagerKey,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
