import React from 'react';

function SvgFileDefaultIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#303030" strokeOpacity="0.08" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8608 9.19702C16.1537 8.90412 16.1537 8.42925 15.8608 8.13636C15.5679 7.84346 15.093 7.84346 14.8001 8.13636L11.9985 10.938L9.19691 8.13636C8.90402 7.84346 8.42914 7.84346 8.13625 8.13636C7.84336 8.42925 7.84336 8.90412 8.13625 9.19702L10.9379 11.9986L8.13625 14.8002C7.84336 15.0931 7.84336 15.568 8.13625 15.8609C8.42914 16.1538 8.90402 16.1538 9.19691 15.8609L11.9985 13.0593L14.8001 15.8609C15.093 16.1538 15.5679 16.1538 15.8608 15.8609C16.1537 15.568 16.1537 15.0931 15.8608 14.8002L13.0592 11.9986L15.8608 9.19702Z" fill="#303030" />
    </svg>
  );
}

export default SvgFileDefaultIcon;
