import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import declineWord from 'decline-word';
import ModalConfirm from '../../../../../containers/Modals/ModalConfirm';
import { actions } from '../../../../../../engine/core/content/actions';
import { asyncActions } from '../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../engine/config/selectors';
import { api } from '../../../../../../engine/config/api';

const MODERATING_STATUS = 2;

export const TranslateModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const closeModalTranslation = () => dispatch(actions.setProductTranslateModal({ isOpen: false }));
  const selections = useSelector(selectors.contentProduct.selection);
  const productList = useSelector(selectors.contentProduct.list).toJS();
  const filters = useSelector(selectors.contentProduct.filters).toJS();
  const moderatingProductList = productList.filter(product => product.productStatus.value === MODERATING_STATUS);
  const [disabled, setDisabled] = useState(false);
  const [filtredItemsToTranslate, setFilteredItems] = useState(0);
  const [loading, setLoading] = useState(false);


  const itemToTranslate = useRef([]);

  const isTranslateModalOpen = useSelector(selectors.content.productTranslateModalIsOpen);

  useEffect(() => {
    if (!isTranslateModalOpen) {
      setDisabled(false);
    }
  }, [isTranslateModalOpen]);

  useEffect(() => {
    (async () => {
      if (selections.size === 0 && filters.length > 0) {
        setLoading(true);
        const params = filters.reduce((acc, current) => ({ ...acc, [current.columnName]: current.value }), {});
        const response = await api.contentProduct.getList({ ...params, productStatus: MODERATING_STATUS });

        if (response.status >= 200 && response.status < 400) {
          setFilteredItems(response.data.totalCount);
          setLoading(false);
        }
      }
    })();
  }, [isTranslateModalOpen]);

  useEffect(() => {
    const mutatedSelections = selections.toJS();
    mutatedSelections.forEach((select) => {
      if (moderatingProductList.some(list => list.id === select)) {
        itemToTranslate.current.push(select);
      }
    });
    itemToTranslate.current = [...new Set(itemToTranslate.current)].filter(item => mutatedSelections.includes(item));
  }, [selections]);

  const onConfirm = () => {
    setDisabled(true);
    const value = {
      property: 'translateProductFeatures',
    };
    dispatch(asyncActions.contentProductBulkEditAsync({
      formData: value,
      forceAction: false,
    }));
  };

  const countToTranslate = selections.size === 0 ? filtredItemsToTranslate : itemToTranslate.current.length;

  return (
    <ModalConfirm
      showModal={isTranslateModalOpen}
      confirmText={t('Начать перевод')}
      textAlign="center"
      onCancel={closeModalTranslation}
      onConfirm={onConfirm}
      title={t('Желаете перевести выбранные товары?')}
      fullScreen={false}
      modalMaxWidth="sm"
      pendingConfirm={disabled}
    >
      {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box> : (
        <Box>
          <Box sx={{ height: '26px', display: 'flex' }}>
            <span
              role="img"
              aria-label="warning"
            >⚠️
            </span>
            <Box sx={{
              fontWeight: '700', fontSize: '14px', fontStyle: 'normal', alignSelf: 'end',
            }}
            > {t('Внимание')}
            </Box>
          </Box>
          <Box
            component="span"
            sx={{
              whiteSpace: 'pre-line', lineHeight: '26px', fontWeight: '400', fontSize: '14px',
            }}
          >
            <>
              {t('Для автоперевода значений полей "Name"/"Описание" выбрано')}{' '}
              {countToTranslate}{' '}
              {declineWord(countToTranslate, '', t('товар'), t('товара'), t('товаров'))}.
              <br />
              {t('Во время автоперевода, выбранные товары перейдут в статус "В обработке" и будут недоступны для редактирования.')}
            </>
          </Box>
        </Box>
      )}
    </ModalConfirm>
  );
};
