// core
import React from 'react';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Parts
import Box from '@mui/material/Box';
import MailSetting from './MailSetting';
import MailSettingsEmpty from './MailSettingsEmpty';

const ProfileMailSettings = (props) => {
  const { fields, group } = props;
  const sortArray = sortBy(group, 'position');
  const [expanded, setExpanded] = React.useState({});
  const { t } = useTranslation();

  const handleChange = panel => (event, newExpanded) => {
    const newObject = { ...expanded };
    newObject[panel] = newExpanded;
    setExpanded(newObject);
  };

  return (
    <Box component="div" sx={{ width: '100%', marginTop: '20px' }}>
      {sortArray.length > 0
        ? sortArray.map((item, iteration) => (
          <MailSetting
            key={item.hashId}
            expanded={expanded}
            iteration={iteration}
            handleChange={handleChange}
            fields={fields}
            item={item}
          />
        ))
        : <MailSettingsEmpty name={t('Нет настроек уведомлений')} />}
    </Box>
  );
};
ProfileMailSettings.propTypes = {
  fields: PropTypes.array.isRequired,
  group: PropTypes.array.isRequired,
};

export default ProfileMailSettings;
