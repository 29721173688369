// Core
import React, { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// lodash
import isEmpty from 'lodash/isEmpty';

// UI
import DeleteIcons from '@mui/icons-material/Delete';

// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

// Engine
import { asyncActions } from '../../../../../engine/core/stopList/stopWords/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

function DeleteStopWordsButton(props) {
  const { isDisplay, hashId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selection = useSelector(selectors.stopWords.selection);
  const deletePending = useSelector(selectors.stopWords.deletePending);
  const getStopWordHashId = useSelector(selectors.stopWords.getStopWordHashId);
  const isAllowedUserDelete = useAccessList(accessList.stopWordsDelete);
  const ids = selection.toJS();
  const isHashId = Boolean(hashId);

  const stopWordsIds = useMemo(() => {
    if (!isEmpty(getStopWordHashId.toJS())) {
      const { id } = getStopWordHashId.toJS();
      return [id];
    }

    return ids;
  }, [ids, getStopWordHashId]);

  const handleDelete = () => {
    dispatch(asyncActions.deleteStopWordsAsync({
      stopWordsIds, isHashId,
    }));
  };

  if (!isAllowedUserDelete || !isDisplay) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Удалить')}
      onClick={handleDelete}
      isLoading={deletePending}
      disabled={deletePending || (isDisplay && !stopWordsIds.length)}
    >
      <DeleteIcons />
    </ButtonWithIconAndCircularProgress>
  );
}

DeleteStopWordsButton.propTypes = {
  isDisplay: PropTypes.bool,
  hashId: PropTypes.string,
};

DeleteStopWordsButton.defaultProps = {
  isDisplay: true,
  hashId: '',
};

export default memo(DeleteStopWordsButton);
