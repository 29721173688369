// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// ЗФкеы
import PartnersStatusTable from './components/PartnersStatusTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import DownloadBillingPartnersXlsFileButton from './components/DownloadBillingPartnersXlsFileButton';
import ButtonPaymentAdd from '../../../components/Buttons/ButtonPaymentAdd';
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { usePartnersStatusList } from './_hooks/usePartnersStatusList';
// Engine
import { asyncActions } from '../../../../engine/core/billing/partnersStatus/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/billing/partnersStatus/actions';
import { initialState, stateKeys } from '../../../../engine/core/billing/partnersStatus/reducer';

function PartnersStatusPage() {
  const { t } = useTranslation();
  usePartnersStatusList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.billingPartnersStatus.settingStatus);
  const settingPending = useSelector(selectors.billingPartnersStatus.settingPending);
  const isLoading = useSelector(selectors.billingPartnersStatus.isLoading);
  const filters = useSelector(selectors.billingPartnersStatus.filters);
  const sorting = useSelector(selectors.billingPartnersStatus.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnersStatusList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersStatusList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersStatusList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const onPutSetting = () => dispatch(asyncActions.putSettingsAsync());

  return (
    <>
      <PageHeader title={t('Баланс Партнеров')}>
        <ButtonPaymentAdd />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={onPutSetting}
        />
        <DownloadBillingPartnersXlsFileButton />
        <ResetFiltersButton pending={isLoading} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <PartnersStatusTable />
    </>
  );
}

export default PartnersStatusPage;
