const OrdersTableStyles = () => ({
  activeColor: {
    '@global': {
      td: {
        background: 'rgba(0, 0, 0, 0.08) !important',
      },
    },
  },
  overdue: {
    background: 'rgba(224, 0, 39, 0.14)',
  },
});

export default OrdersTableStyles;
