import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const ErrorModalContent = ({ errorsArray }) => {
  const { t } = useTranslation();
  const isLabelNeeded = errorsArray.find(error => error.property_path === '[synonyms]');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {errorsArray.map(error => (
        <>
          { error?.property_path !== '[synonyms]' && (<Box component="span">{error?.message}</Box>)}
        </>
      ))}
      {isLabelNeeded && <Box sx={{ marginTop: '24px', marginBottom: '8px' }}>{t('Синоним уже используется для другого слова:')}</Box>}
      <ul>
        {errorsArray.map(error => (
          <>
            {error?.property_path === '[synonyms]'
      && <li>{error?.message}</li> }
          </>
        ))}
      </ul>
    </Box>
  );
};

export default ErrorModalContent;
