/* eslint-disable */
import { Trans } from 'react-i18next';

export const productGuide = ({ isDownXLView }) => ([
  ...(isDownXLView ? [{
    title: <Trans>Добавить товар</Trans>,
    target: '#product_add_button',
    content: <Trans>Нажмите на кнопку «Добавить товар».</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  }] : [{
    title: <Trans>Добавить товар</Trans>,
    target: '#product_add_button',
    content: <Trans>Нажмите на кнопку «Добавить товар».</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  }]),
  {
    title: <Trans>Выберите категорию</Trans>,
    target: '#product_category_modal',
    content: <Trans>Во всплывающем окне выберите категорию, в которую нужно добавить товар, и нажмите кнопку «Применить».</Trans>,
    disableBeacon: true,
    placement: 'right',
    styles: {
      spotlight: {
        opacity: 0,
      },
      overlay: {
        opacity: 0,
      },
    },
    offset: 6,
  },
  {
    title: <Trans>Заполните данные о товаре</Trans>,
    target: '#product_tabs_nav',
    content: <Trans>Заполните данные о товаре: характеристики, цену и наличие, добавьте его фото. Обязательно заполните поля, отмеченные звездочкой.</Trans>,
    disableBeacon: true,
    placement: 'right-end',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Вернуться»</Trans>,
    target: '#product_go_back_btn',
    content: <Trans>Кнопка «Вернуться» возвращает на страницу с перечнем товаров без сохранения введенных данных.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Сохранить и вернуться»</Trans>,
    target: '#product_save_go_back_btn',
    content: <Trans>Кнопка «Сохранить и вернуться» сохраняет введенные данные и возвращает на страницу с перечнем товаров.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
  {
    title: <Trans>Кнопка «Сохранить»</Trans>,
    target: '#product_save_btn',
    content: <Trans>Кнопка «Сохранить» сохраняет введенные данные, можно продолжать добавление информации о товаре.</Trans>,
    disableBeacon: true,
    placement: 'bottom',
    offset: 6,
  },
]);

const manageProducts = {
  title: <Trans>Управление товарами</Trans>,
  target: '#manageSideBar',
  content: <Trans>Этот раздел служит для массового управления вашими товарами</Trans>,
  disableBeacon: true,
  offset: 6,
};

const exportTab = {
  title: <Trans>Экспорт / Импорт</Trans>,
  target: '#export_sidebar_cell',
  content: <Trans>Тут можно изменить цену и/или наличие для группы товаров.</Trans>,
  disableBeacon: true,
  offset: 6,
  placement: 'right',
};

export const sendProductToModerationGuide = () => ([
  manageProducts,
  {
    title: <Trans>Отправка товаров на модерацию</Trans>,
    target: '#moderation_sidebar_cell',
    content: <Trans>Нажав на эту кнопку вы можете отправить все свои товары на модерацию. Перед этим вы можете отфильтровать или выбрать нужные товары для модерации.</Trans>,
    disableBeacon: true,
    offset: 6,
  },
]);

export const productEditGuide = () => ([
  manageProducts,
  {
    title: <Trans>Редактирование товаров</Trans>,
    target: '#edit_sidebar_cell',
    content: <Trans>Нажав на эту кнопку, вы можете редактировать все свои товары. Перед этим вы можете отфильтровать или выбрать нужные товары для редактирования.</Trans>,
    disableBeacon: true,
    offset: 6,
  },
  {
    title: <Trans>Выберите нужное значение для редактирования</Trans>,
    target: '#multiple_form_edit',
    content: <Trans>Выберите нужное значение из списка, чтобы отредактировать один или несколько товаров.</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
    styles: { spotlight: { paddingBottom: 300, marginTop: -10 } },
  },
  {
    title: <Trans>Укажите значение</Trans>,
    target: '#quantity_fieldset',
    content: <Trans>Укажите значение: оно будет использовано для всех выбранных товаров</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
  },
  {
    title: <Trans>Применить изменения</Trans>,
    target: '#apply_multiple_edit_form',
    content: <Trans>Нажмите кнопку "Применить", чтобы сохранить изменения.</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
  },
]);

export const productsPriceUploadGuide = () => ([
  manageProducts,
  exportTab,
  {
    title: <Trans>Импорт</Trans>,
    target: '#import_button',
    content: <Trans>Нажав на эту кнопку вы можете импортировать XLSX файл с измененной ценой и наличием у товаров. (Данный XSLX файл можно получить нажав на экспорт цен и наличия товаров, ниже этой кнопки).</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
  },
  {
    title: <Trans>Загрузка файла</Trans>,
    target: '.import_modal',
    content: <Trans>Выберите отредактированный файл для изменения цены и/или наличия у товаров .</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'left',
  },
  {
    title: <Trans>Сохранить изменения</Trans>,
    target: '#send_button_modal',
    content: <Trans>Нажмите эту кнопку, чтобы сохранить изменения. Товары ненадолго уйдут в обработку. </Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'left',
  },
]);

export const productsPriceExportGuide = () => ([
  manageProducts,
  exportTab,
  {
    title: <Trans>Экспорт</Trans>,
    target: '#export_button',
    content: <Trans>При клике на ваше устройство загрузится XLSX-файл. В нем содержатся все товары, которые вы отфильтровали или выбрали. Этот файл вы сможете отредактировать, а потом снова загрузить через кнопку "Импорт".</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
  },
]);

export const productsMultipleEditGuide = () => ([
  {
    title: <Trans>Выберите категорию</Trans>,
    target: '#categories_select',
    content: <Trans>Выберите категорию, чтобы отредактировать сразу несколько товаров из нее.</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'right',
    styles: { spotlight: { paddingBottom: 320, marginTop: -10 } },
  },
  {
    title: <Trans>Групповое редактирование</Trans>,
    target: '#edit_category_button',
    content: <Trans>Нажав на кнопку "Редактировать", вы сможете изменить всем выбранным товарам цену и наличие, фото, характеристики, основную информацию о товаре. Затем сохранить изменения.</Trans>,
    disableBeacon: true,
    offset: 6,
    placement: 'bottom',
  },
]);
