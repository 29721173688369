// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// UI
import Button from '@mui/material/Button';
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { accessList } from '../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { actions } from '../../../../../../../engine/core/content/actions';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';

function ProductsPropertiesButtonGroups(props) {
  const { sx } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');
  const hasAccessExport = useAccessList(accessList.feedProductsExport);
  const hasAccessImport = useAccessList(accessList.feedProductsImport);
  const isOpen = useSelector(selectors.content.importModalIsOpen);
  const isLoading = useSelector(selectors.content.productDownloadPending);
  const selection = useSelector(selectors.contentProduct.selection);
  const filters = useSelector(selectors.contentProduct.filters);
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (selection.size) {
      setLabel(t('Экспорт выбранных свойств товаров'));
    } else if (filters.size) {
      setLabel(t('Экспорт фильтрованных свойств товаров'));
    } else {
      setLabel(t('Экспорт всех свойств товаров'));
    }
  }, [selection.size, filters.size, i18n.language]);

  const downloadProductsProperties = () => {
    dispatch(asyncActions.getProductsPropertiesExportAsync({ categoryId, attributeSetId }));
  };
  const handleImport = () => {
    dispatch(actions.setImportPropertiesModalIsOpen({ isOpen: !isOpen }));
  };

  return (
    <>
      {hasAccessImport && (
        <Button
          fullWidth
          sx={sx}
          onClick={handleImport}
          disabled={isLoading}
        >
          {t('Импорт свойств товаров')}
        </Button>
      )}
      {hasAccessExport && (
        <Button
          fullWidth
          sx={sx}
          onClick={downloadProductsProperties}
          disabled={isLoading}
        >
          {label}
        </Button>
      )}
    </>
  );
}


export default ProductsPropertiesButtonGroups;
