const UploadCsvModalStyle = theme => ({
  messageTitle: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 0,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
  },
  iconWarning: {
    fill: 'orange',
    width: '1em',
    height: '1em',
    marginRight: 4,
  },
  iconDone: {
    fill: 'green',
    width: '1em',
    height: '1em',
    marginRight: 4,
  },
  listWrapper: {
    margin: 0,
  },
  uploadCsvContent: {
    maxWidth: '380px',
  },
});

export default UploadCsvModalStyle;
