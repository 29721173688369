// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
// UI
import DialogActions from '@mui/material/DialogActions';
import { useStyles } from '../../../../hooks/useStyles';
// Style
import ModalFooterStyle from './ModalFooterStyle';

function ModalFooter(props) {
  const {
    children, alignEnd, spaceBetween, center, footerClassNames,
  } = props;
  const classes = useStyles(ModalFooterStyle);
  const classNames = cx(classes.modalFooter, {
    [classes.alignEnd]: alignEnd,
    [classes.spaceBetween]: spaceBetween,
    [classes.alignCenter]: center,
    [footerClassNames]: footerClassNames,
  });

  return (
    <DialogActions className={classNames}>
      {children}
    </DialogActions>
  );
}

ModalFooter.propTypes = {
  alignEnd: PropTypes.bool,
  center: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  footerClassNames: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

ModalFooter.defaultProps = {
  center: false,
  alignEnd: false,
  spaceBetween: false,
};

export default ModalFooter;
