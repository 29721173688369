import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { selectors } from '../../../../../../engine/config/selectors';
import Loading from '../../../../../components/Loading/Loading';


const PartnerInfoTable = () => {
  const partnerData = useSelector(selectors.partner.userData);
  const partnerDataLoading = useSelector(selectors.partner.partnerIsLoading);
  const companyType = partnerData?.infoFieldValues?.enterpriseType?.current;
  const { t } = useTranslation();
  const sellerPageUrl = partnerData.infoFieldValues?.sellerPageUrl?.current;

  const rows = [
    { label: `${t('Название магазина')}:`, value: partnerData?.infoFieldValues?.companyName?.current },
    { label: 'ID партнера:', value: partnerData?.idMagentoPartner },
    {
      label: `${t('Товары магазина')}:`,
      value: <Link href={sellerPageUrl} target="_blank">{sellerPageUrl}</Link>,
    },
    {
      label: companyType === 'ФОП' ? `${t('Название ФЛП')}:` : `${t('ФИО контактного лица')}:`,
      value: partnerData?.infoFieldValues?.enterpriseName?.current,
    },
    {
      label: companyType === 'ФОП' ? `${t('ИНН')}:` : `${t('ЕГРПОУ')}:`,
      value: partnerData?.infoFieldValues?.enterpriseClassifierCode?.current,
    },
    {
      label: `${t('Место регистрации')}:`,
      value: `${partnerData?.infoFieldValues?.addressIndex.current} ${partnerData?.infoFieldValues?.city.current} ${partnerData?.infoFieldValues?.address.current}`,
    },
  ];

  return (
    <>
      {partnerDataLoading ? (<Loading isLoading />)
        : (
          <>
            <Box sx={{ fontWeight: 700, marginTop: '30px', marginBottom: '8px' }}>{t('Данные для заполнения формы')}:</Box>
            <TableContainer sx={{ borderWidth: '0px', height: '100%', marginBottom: '48px' }}>
              <Table aria-label="simple table">
                <TableBody sx={{ borderWidth: '0px', height: '100%' }}>
                  {rows.map((row, index) => (
                    <>
                      <TableRow key={index} sx={{ height: '10px', '& td': { padding: '0', lineHeight: '14px' } }}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderWidth: '0px',
                            color: 'rgba(48, 48, 48, 0.72)',
                            width: '50%',
                            lineHeight: '14px',
                            padding: '0',
                          }}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell align="left" sx={{ borderWidth: '0px', padding: '0' }}>{row.value}</TableCell>
                      </TableRow>
                      <TableRow sx={{ height: '12px' }} />
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box component="span">
              <span
                role="img"
                aria-label="warning"
              >⚠️
              </span>
              <Typography component="span" sx={{ fontWeight: 700 }}>{t('Важно')}!&nbsp;</Typography>
              <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>{t('После регистрации договора с “Monobank”')}</Typography>
            </Box>
          </>
        )
}
    </>
  );
};

export default PartnerInfoTable;
