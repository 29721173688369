import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form/immutable';
import { submit } from 'redux-form';
// Parts
import SaveIcons from '@mui/icons-material/Save';
import DeleteIcons from '@mui/icons-material/Delete';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// hooks
import { useAccessList } from '../../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';

import { formFields, formName } from '../formFields';

function Buttons() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessDelete = useAccessList(accessList.stopBrandsCategoriesDelete);
  const category = useSelector(state => formValueSelector(formName)(state, formFields.category));
  const brand = useSelector(state => formValueSelector(formName)(state, formFields.brand));
  const deletePending = useSelector(selectors.stopListBrandsCategories.deletePending);
  const editPending = useSelector(selectors.stopListBrandsCategories.editPending);
  const putPending = useSelector(selectors.stopListBrandsCategories.putPending);
  const editItemId = useSelector(selectors.stopListBrandsCategories.editItemId);
  const pending = deletePending || editPending || putPending;

  const handleSendForm = () => {
    dispatch(submit(formName));
  };
  const handleDeleteItemsByHashId = () => {
    dispatch(asyncActions.deleteAsync({ stopBrandsCategoriesIds: [editItemId], isEdit: true }));
  };

  return (
    <>
      <ButtonWithIconAndCircularProgress
        text={t('Сохранить')}
        component="button"
        onClick={handleSendForm}
        disabled={(!!category === false && !!brand === false) || pending}
        isLoading={pending}
      >
        <SaveIcons />
      </ButtonWithIconAndCircularProgress>
      {hasAccessDelete && (
        <ButtonWithIconAndCircularProgress
          text={t('Удалить')}
          disabled={pending}
          isLoading={pending}
          onClick={handleDeleteItemsByHashId}
        >
          <DeleteIcons />
        </ButtonWithIconAndCircularProgress>
      )}
    </>
  );
}

export default memo(Buttons);
