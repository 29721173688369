// core
import React, { memo, useMemo } from 'react';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// Parts
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { formName } from '../form';
import { useDesktopView } from '../../../../../../../hooks/useDesktopView';
import { validators } from '../../../../../../../../_helpers/validationsForm';
import FormFieldFormSwitcher from './FormFieldFormSwitcher';
import FormFieldFormSelect from './FormFieldFormSelect';

function DayField(props) {
  const {
    label, name, shortLabel,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDesktopView = useDesktopView();
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValue = formValues.toJS();
  const enabled = normalizeFormValue[name]?.enabled;
  const timeOptions = useMemo(() => {
    const options = [];

    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const timeValue = `${formattedHours}:${formattedMinutes}`;
        const timeLabel = `${formattedHours}:${formattedMinutes}`;

        options.push({ value: timeValue, label: timeLabel });
      }
    }

    return options;
  }, []);
  const onChange = (e) => {
    if (!e.target.checked) {
      dispatch(change(formName, `${name}.time`, null));
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        <Field
          component={FormFieldFormSwitcher}
          margin="normal"
          name={`${name}.enabled`}
          onChange={onChange}
        />
        <Typography variant="body1" component="div" sx={{ fontSize: '14px', color: !enabled ? COLOR.grey['54'] : COLOR.grey.main }}>
          {isDesktopView ? label : shortLabel}
        </Typography>
      </Box>
      <Field
        component={FormFieldFormSelect}
        formSelectWidth="152px"
        label={t('Оформить до')}
        margin="normal"
        options={timeOptions}
        name={`${name}.time`}
        disabled={!enabled}
        validate={enabled && [validators.requiredTime]}
      />
    </Box>
  );
}


export default memo(DayField);
