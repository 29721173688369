// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// UI
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
// Icons
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// Containers
import PageHeader from '../../../../components/PageHeader/PageHeader';
// Components
import FeedNoteText from './components/FeedNoteText';
import StatusIcon from './components/StatusIcon';
import FeedStatusDataInfo from './components/FeedStatusDataInfo';
import ProductFeedParametersTable, { completionPriceListModalConfig } from './components/ProductFeedParametersTable/ProductFeedParametersTable';
import ProductFeedCategoriesTable from './components/ProductFeedCategoriesTable/ProductFeedCategoriesTable';
import ProductFeedCategoriesAttributesTable from './components/ProductFeedCategoriesAttributesTable/ProductFeedCategoriesAttributesTable';
import Loading from '../../../../components/Loading/Loading';
import StepperIcon from './components/StepperIcon';
// Style
import { COLOR } from '../../../../containers/App/AppStyles';
// Engine
import { userRoles } from '../../../../../engine/config/userRoles';
import { asyncActions } from '../../../../../engine/core/feeds/all/saga/asyncActions';
import { actions as actionsFeeds } from '../../../../../engine/core/feeds/all/actions';
import {
  actions as actionsProductFeedCategories,
} from '../../../../../engine/core/products/productFeedCategories/actions';
import {
  actions as actionsProductFeedParameters,
} from '../../../../../engine/core/products/productFeedParameters/actions';
import {
  actions as actionsProductFeedCategoriesAttributes,
} from '../../../../../engine/core/products/productFeedCategoriesAttributes/actions';
import { selectors, ui } from '../../../../../engine/config/selectors';
// Hooks
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { useCallbackAfterPostUser } from '../../../../hooks/useCallbackAfterPostUser';
import { CustomCardBorderContent } from '../Import/components/styles';
import { StyledStepper, StepperConnector, ItemStyles } from './styles';
import { dispatchCompletionListItemData } from './_helpers/dispatchCompletionListItemData';
import { useStyles } from '../../../../hooks/useStyles';
import { usePrevious } from '../../../../hooks/usePrevious';
import { IMPORT_STEPS } from './constants';
import { uiActions } from '../../../../../engine/core/ui/action';
import { ERROR_IMPORT_STEP } from '../../../../components/Popup/PartnerWarningPopup';

function Item() {
  const isItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const parsePending = useSelector(selectors.feedsProducts.parsePending);
  const feedStatusData = isItemByHashIdItem.get('feedStatusData');
  const stepsData = isItemByHashIdItem.get('stepsData');
  const { t } = useTranslation();
  const steps = [t('Параметры'), t('Категории'), t('Характеристики и их значения')];
  const { data, isOpen } = useSelector(ui.popup).toJS();
  const step = (data.step && data.step < ERROR_IMPORT_STEP) ? (Number(data.step) - 1) : 0;
  const [activeStep, setActiveStep] = useState(step);
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles(ItemStyles({ stepsData }));
  const previousActiveStep = usePrevious(activeStep);
  const [stepDisabled, setStepDisabled] = useState({ [IMPORT_STEPS.PARAMETERS]: false, [IMPORT_STEPS.CATEGORIES]: false, [IMPORT_STEPS.CATEGORIES_ATTRIBUTES]: false });

  useEffect(() => {
    if (!isOpen) {
      if (!Number.isNaN(Number(data.step)) && data.step < ERROR_IMPORT_STEP) {
        setActiveStep(Number(data.step) - 1);
      }
      dispatch(uiActions.setPopup({ data: {} }));
    }
  }, [isOpen]);

  const disable =
    !!useSelector(selectors.feeds.isItemByHashIdPutPending)
    || isItemByHashIdLoading || parsePending;
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerSigningContract,
    userRoles.partnerPaymentWaiting,
  ]);
  const completionImportList = useSelector(selectors.feeds.completionImportList)?.toJS();
  const stepKey = completionPriceListModalConfig.steps.step_1;
  const modalTypeKey = completionPriceListModalConfig.type.edit;
  const isPartnerWithData = isPartnerRoles && stepsData && !isItemByHashIdLoading;

  const handleNextStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBackStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useCallbackAfterPostUser(() => {
    dispatch(asyncActions.getFeedItemByHashIdAsync(hashId));
  }, [hashId]);

  useEffect(() => {
    dispatch(asyncActions.getCompletionImportListAsync({ hashId }));
  }, []);

  useEffect(() => () => {
    if (isPartnerRoles && completionImportList[hashId]?.isSaved) {
      dispatchCompletionListItemData({
        dispatch, hashId, completionImportList, modalTypeKey, stepKey, isOpened: false,
      });
    }
  }, []);

  useEffect(() => {
    if (activeStep === 2 && previousActiveStep === 1) {
      dispatch(asyncActions.getFeedItemByHashIdAsync(hashId));
    }
  }, [activeStep, hashId, dispatch]);

  useEffect(() => {
    dispatch(asyncActions.getFeedItemByHashIdAsync(hashId));
    return () => {
      dispatch(actionsFeeds.setDefaultFeedsItemByHashId());
      dispatch(actionsProductFeedCategoriesAttributes.setDefaultCategories());
      dispatch(actionsProductFeedCategories.setDefaultListState());
      dispatch(actionsProductFeedParameters.setDefaultListState());
    };
  }, [dispatch, hashId]);

  return (
    <Box
      className={classes.mainHolder}
    >
      <PageHeader title={feedStatusData ? t('Импорт') : t('Сопоставление данных прайса')} />
      {isPartnerWithData && <FeedNoteText activeStep={activeStep} noteText={stepsData[activeStep + 1]?.noteText} />}
      <Card sx={{ flexGrow: 1, overflow: 'auto' }}>
        <CustomCardBorderContent sx={{ height: 'auto', minHeight: '100%' }}>
          {isPartnerWithData && stepsData[2].productFeedCategoryStatuses && activeStep === 1 && (
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12}>
                <Card>
                  <CustomCardBorderContent>
                    <Box component="b">{t('Категорий')}: {stepsData[2].totalFeedCategories}</Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      {stepsData[2].productFeedCategoryStatuses.map((item, index) => {
                        const isFirst = index === 0;
                        return (
                          <Box
                            component={isFirst ? 'p' : 'span'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: 1,
                              fontSize: '12px',
                              color: COLOR.grey['72'],
                              width: isFirst ? '100%' : 'auto',
                              mb: isFirst ? '0' : 'auto',
                            }}
                            key={item.value}
                          >
                            <StatusIcon sx={{ fontSize: '18px', mr: '8px' }} iconName={item.icon} />
                            {item.label}: {item.totalCount}
                          </Box>
                        );
                      })}
                    </Box>
                  </CustomCardBorderContent>
                </Card>
              </Grid>
              {/* <Grid item md={6}> */}
              {/*  <Card sx={{ height: '100%' }}> */}
              {/*    <CustomCardBorderContent sx={{ justifyContent: 'space-between' }}> */}
              {/*      <Box component="b" sx={{ display: 'flex', flexWrap: 'wrap' }}> */}
              {/*        {t('Оформить заявку на создание новой категории')} <HelpOutlineIcon sx={{ ml: 1 }} /> */}
              {/*      </Box> */}
              {/*      <Box component="div" sx={{ mt: 2 }}> */}
              {/*        <LoadingButton variant="outlined"> */}
              {/*          {t('Добавить категорию')} */}
              {/*        </LoadingButton> */}
              {/*      </Box> */}
              {/*    </CustomCardBorderContent> */}
              {/*  </Card> */}
              {/* </Grid> */}
            </Grid>
          )}
          {isPartnerWithData && stepsData[3].productFeedAttributeStatuses && activeStep === 2 && (
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12}>
                <Card>
                  <CustomCardBorderContent>
                    <Box component="b">{t('Характеристик')}: {stepsData[3].totalFeedAttributes}</Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      {stepsData[3].productFeedAttributeStatuses.filter(item => item.hidden !== true).map((item, index) => {
                        const isFirst = index === 0;
                        return (
                          <Box
                            component={isFirst ? 'p' : 'span'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: 1,
                              fontSize: '12px',
                              color: COLOR.grey['72'],
                              width: isFirst ? '100%' : 'auto',
                              mb: isFirst ? '0' : 'auto',
                            }}
                            key={item.value}
                          >
                            <StatusIcon sx={{ fontSize: '18px', mr: '8px' }} iconName={item.icon} />
                            {item.label}: {item.totalCount}
                          </Box>
                        );
                      })}
                    </Box>
                  </CustomCardBorderContent>
                </Card>
              </Grid>
              {/* <Grid item md={6}> */}
              {/*  <Card sx={{ height: '100%' }}> */}
              {/*    <CustomCardBorderContent sx={{ justifyContent: 'space-between' }}> */}
              {/*      <Box component="b" sx={{ display: 'flex', flexWrap: 'wrap' }}> */}
              {/*        {t('Оформить заявку на создание новой категории')} <HelpOutlineIcon sx={{ ml: 1 }} /> */}
              {/*      </Box> */}
              {/*      <Box component="div" sx={{ mt: 2 }}> */}
              {/*        <LoadingButton variant="outlined"> */}
              {/*          {t('Добавить категорию')} */}
              {/*        </LoadingButton> */}
              {/*      </Box> */}
              {/*    </CustomCardBorderContent> */}
              {/*  </Card> */}
              {/* </Grid> */}
            </Grid>
          )}
          {isItemByHashIdLoading ? (
            <Loading isLoading />
          ) : (
            <>
              {feedStatusData && <FeedStatusDataInfo feedStatusData={feedStatusData} />}
              {stepsData && (
                <>
                  <StyledStepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<StepperConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step onClick={disable || stepDisabled[index + 1] ? undefined : () => setActiveStep(index)} key={label}>
                        <StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
                      </Step>
                    ))}
                  </StyledStepper>
                  <Box
                    component="hr"
                    sx={{
                      marginBottom: '30px',
                      borderColor: 'rgb(158 158 158 / 14%)',
                      width: '100%',
                    }}
                  />
                  {activeStep === 0 && (
                    <ProductFeedParametersTable
                      disable={disable}
                      handleNextStep={handleNextStep}
                      setStepDisabled={setStepDisabled}
                    />
                  )}
                  {activeStep === 1 && (
                    <ProductFeedCategoriesTable
                      disable={disable}
                      handleNextStep={handleNextStep}
                      handleBackStep={handleBackStep}
                      setStepDisabled={setStepDisabled}
                    />
                  )}
                  {activeStep === 2 && (
                    <ProductFeedCategoriesAttributesTable
                      disable={disable}
                      handleBackStep={handleBackStep}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomCardBorderContent>
      </Card>
    </Box>
  );
}

export default Item;
