export const asyncTypes = Object.freeze({
  GET_LOGISTICS_DATA_BY_CATEGORY_LIST_ASYNC: 'GET_LOGISTICS_DATA_BY_CATEGORY_LIST_ASYNC',
  PUT_LOGISTICS_DATA_BY_CATEGORY_SETTINGS_ASYNC: 'PUT_LOGISTICS_DATA_BY_CATEGORY_SETTINGS_ASYNC',
  GET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE_ASYNC: 'GET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE_ASYNC',
  GET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC: 'GET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC',
  POST_LOGISTICS_DATA_BY_CATEGORY_IMPORT_ASYNC: 'POST_LOGISTICS_DATA_BY_CATEGORY_IMPORT_ASYNC',
  GET_LOGISTICS_DATA_BY_CATEGORY_EXPORT_ASYNC: 'GET_LOGISTICS_DATA_BY_CATEGORY_EXPORT_ASYNC',
  PUT_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC: 'PUT_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC',
  GET_LOGISTICS_DATA_BY_CATEGORY_LOGS_ASYNC: 'GET_LOGISTICS_DATA_BY_CATEGORY_LOGS_ASYNC',
});
