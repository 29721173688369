// Core
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
// UI
import Button from '@mui/material/Button';

// Styles
import ButtonWithIconStyle from './ButtonWithIconStyle';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';

// Component
function ButtonWithIcon(props) {
  const {
    classes, children, text, onClick, ButtonVariant,
    className, component, fullWidth, color, disabled, sx, id, withoutIcon,
    ...rest
  } = props;

  const ButtonText = classNames({
    [classes.ButtonWithIcon]: children && !withoutIcon,
  });

  return (
    <Button
      sx={{ p: BUTTON_SIZE.withIconSmall, ...sx }}
      disabled={disabled}
      variant={ButtonVariant}
      component={component}
      fullWidth={fullWidth}
      color={color}
      className={className}
      onClick={onClick}
      id={id}
      {...rest}
    >
      {children}
      { text && <span className={ButtonText}>{text}</span> }
    </Button>
  );
}

ButtonWithIcon.defaultProps = {
  disabled: false,
  component: 'label',
  ButtonVariant: 'contained',
  color: 'primary',
  withoutIcon: false,
};

export default withStyles(ButtonWithIconStyle)(ButtonWithIcon);
