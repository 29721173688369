// Data
import { tableColumns } from './tableColumns';

export const formName = 'faqEditForm';

export const formFields = Object.freeze({
  answer: tableColumns.answer.name,
  questionID: tableColumns.idMagentoFaq.name,
  createdAt: tableColumns.createdAt.name,
  answeredAt: tableColumns.answeredAt.name,
  responderName: tableColumns.responderName.name,
  type: tableColumns.type.name,
  partner: tableColumns.partner.name,
  question: tableColumns.question.name,
  sku: tableColumns.sku.name,
  orderId: tableColumns.idMagentoOrder.name,
  status: tableColumns.status.name,
});
