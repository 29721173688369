// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callGetPartnersManagersExportWorker,
  callPostUploadPartnerManagerWorker,
  callPutSettingsWorker,
  callPutPartnerManagerWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PARTNERS_MANAGERS_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_MANAGERS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PARTNERS_MANAGERS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutPartnerManager() {
  yield takeEvery(asyncTypes.PUT_PARTNER_MANAGER_ASYNC, callPutPartnerManagerWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PARTNERS_MANAGERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PARTNERS_MANAGERS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PARTNERS_MANAGERS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PARTNERS_MANAGERS_SORTING, callSetToLocalDataWorker);
}
function* watchGetPartnersManagersExport() {
  yield takeEvery(asyncTypes.GET_PARTNERS_MANAGERS_EXPORT, callGetPartnersManagersExportWorker);
}
function* watchGetPartnersManagersImport() {
  yield takeEvery(asyncTypes.POST_PARTNERS_MANAGERS_UPLOAD_ASYNC, callPostUploadPartnerManagerWorker);
}

export function* watchersPartnersManagers() {
  yield all([
    watchGetList(),
    watchPutPartnerManager(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetPartnersManagersExport(),
    watchGetPartnersManagersImport(),
  ]);
}
