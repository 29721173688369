// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ClearIndicator(props) {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" fontSize="inherit" {...props}>
      <path d="M11.4169 1.65533C11.7098 1.36244 11.7098 0.887563 11.4169 0.59467C11.124 0.301777 10.6491 0.301777 10.3562 0.59467L6.00554 4.94537L1.65484 0.59467C1.36195 0.301777 0.887075 0.301777 0.594182 0.59467C0.301288 0.887563 0.301288 1.36244 0.594182 1.65533L4.94488 6.00603L0.594182 10.3567C0.301288 10.6496 0.301288 11.1245 0.594182 11.4174C0.887075 11.7103 1.36195 11.7103 1.65484 11.4174L6.00554 7.06669L10.3562 11.4174C10.6491 11.7103 11.124 11.7103 11.4169 11.4174C11.7098 11.1245 11.7098 10.6496 11.4169 10.3567L7.0662 6.00603L11.4169 1.65533Z" />
    </SvgIcon>
  );
}

export default ClearIndicator;
