// Core
import React, { useEffect, useMemo } from 'react';
import { Field, change } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import find from 'lodash/find';
// Parts
import {
  Accordion as AccordionMUI,
  AccordionSummary as AccordionSummaryMUI,
  AccordionDetails as AccordionDetailsMUI,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import Checkbox from '../../../components/_Form/Checkbox/Checkbox';
import MailSettingsItem from './MailSettingsItem';
// Engine
import { selectors } from '../../../../engine/config/selectors';
// Helpers
import { formName } from '../MailSettings';
import { COLOR } from '../../../containers/App/AppStyles';

const Accordion = withStyles({
  root: {
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '10px',
    },
  },
  expanded: {},
})(AccordionMUI);

const AccordionSummary = withStyles({
  root: {
    color: COLOR.grey['87'],
    minHeight: 70,
    padding: '0 22px',
    borderRadius: 5,
    '&$expanded': {
      minHeight: 70,
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      borderBottom: '1px solid rgba(48, 48, 48, 0.08)',
      margin: '0',
      padding: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummaryMUI);

const AccordionDetails = withStyles(({
  root: {
    padding: '14px 22px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: COLOR.grey['87'],
    },
  },
}))(AccordionDetailsMUI);

function MailSetting(props) {
  const {
    expanded, iteration, handleChange,
    fields,
    item: {
      hashId, name, userMailTemplatesIds,
    },
  } = props;
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValue = formValues.toJS();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentGroup = useMemo(() => {
    const groupItems = [];
    const groupItemsTrue = [];

    find(normalizeFormValue, (value, keyName) => {
      if ((userMailTemplatesIds.includes(keyName) || userMailTemplatesIds.includes(Number.parseInt(keyName, 10)))) {
        groupItems.push({ [keyName]: value });
      }
    });

    find(normalizeFormValue, (value, keyName) => {
      if ((userMailTemplatesIds.includes(keyName) || userMailTemplatesIds.includes(Number.parseInt(keyName, 10))) && value === true) {
        groupItemsTrue.push({ [keyName]: value });
      }
    });

    return { groupItems, groupItemsTrue };
  }, [normalizeFormValue, userMailTemplatesIds]);

  const handleClickCheckboxWrapper = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (currentGroup.groupItemsTrue.length !== 0 && expanded?.[iteration] === undefined) {
      handleChange(iteration)(null, true);
    }
  }, [currentGroup.groupItemsTrue.length, expanded, handleChange, iteration]);

  const handleClickCheckbox = (e) => {
    e.stopPropagation();
    currentGroup.groupItems.forEach((item) => {
      if (currentGroup.groupItemsTrue.length === userMailTemplatesIds.length) {
        dispatch(change(formName, Object.keys(item), false));
        handleChange(iteration)(null, false);
      } else {
        dispatch(change(formName, Object.keys(item), true));
        handleChange(iteration)(null, true);
      }
    });
  };

  const nameGroup = `${t('Подписка на почтовые сообщения раздела')} "${name}"`;

  return (
    <Accordion sx={{ borderRadius: '5px', marginBottom: '10px', backgroundColor: '#FFFFFF' }} square expanded={!!expanded?.[iteration]} onChange={handleChange(iteration)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#000', fontSize: '28px' }} />}
        id="panel1a-header"
      >
        <Box display="flex" flexDirection="row" alignContent="flex-start">
          <Box onClick={handleClickCheckboxWrapper}>
            <Field
              key={hashId}
              name={`title_${hashId}`}
              indeterminate={currentGroup.groupItemsTrue.length > 0 && (currentGroup.groupItemsTrue.length !== userMailTemplatesIds.length)}
              component={Checkbox}
              noTrans
              input={{
                value: currentGroup.groupItemsTrue.length === userMailTemplatesIds.length,
                onChange: handleClickCheckbox,
              }}
              margin="none"
            />
          </Box>
          <Box flexGrow={1} onClick={handleClickCheckbox} sx={{ marginLeft: '-16px' }} alignSelf="center">{nameGroup}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {
            userMailTemplatesIds.map(
              mailItem => (
                fields
                  .map(field => mailItem === field.id
                    ? <MailSettingsItem {...field} />
                    : null)),
            )
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

MailSetting.propTypes = {
  iteration: PropTypes.number.isRequired,
  expanded: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    hashId: PropTypes.string,
    name: PropTypes.string,
    userMailTemplatesIds: PropTypes.array,
  }).isRequired,
};

export default MailSetting;
