const NotificationStyle = () => ({
  notificationWrap: {
    '& .MuiGrid-container': {
      flexDirection: 'column',
      gap: 20,
    },
  },
  notificationItemsCreate: {
    marginTop: 5,
    display: 'block',
    fontSize: 12,
    color: 'rgb(117, 117, 117)',
  },
  notificationWrapperText: {
    width: '100%',
  },
  notificationText: {
    wordBreak: 'break-word',
    '& a': {
      color: 'blue',
      textDecoration: 'underline',
    },
    '& a:hover': {
      color: 'darkblue',
    },
  },
});

export default NotificationStyle;
