// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
// UI
import CheckboxUI from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// Styles
import Tooltip from '@mui/material/Tooltip';

function Checkbox(props) {
  const {
    checkboxLabelFalse, checkboxLabelTrue, fullWidth, indeterminate, color,
    input, label, margin, checkboxLabel, disabled, defaultValue, noTrans, tooltip, sx,
  } = props;

  const checked = !!input.value;
  const currentLabel = checkboxLabel ? <Trans>{ checkboxLabel }</Trans> : <Trans>{checked ? checkboxLabelTrue : checkboxLabelFalse}</Trans>;
  const currentLabelNoTrans = checkboxLabel || (checked ? checkboxLabelTrue : checkboxLabelFalse);

  const ControlLabel = (
    <FormControlLabel
      control={
        defaultValue === null ? (
          <CheckboxUI color={color} disabled={disabled} checked={checked} onChange={input.onChange} indeterminate={indeterminate} />
        ) : (
          <CheckboxUI color={color} disabled={disabled} defaultChecked={defaultValue} onChange={input.onChange} indeterminate={indeterminate} />
        )
      }
      sx={{ width: 'fit-content' }}
      label={noTrans ? currentLabelNoTrans : currentLabel}
    />
  );

  return (
    <FormControl fullWidth={fullWidth} margin={margin} sx={sx}>
      {label && (<FormLabel sx={{ fontSize: '14px' }}>{label}</FormLabel>)}
      {tooltip ? <Tooltip arrow title={tooltip}>{ControlLabel}</Tooltip> : ControlLabel}
    </FormControl>
  );
}

Checkbox.propTypes = {
  checkboxLabelFalse: PropTypes.string,
  checkboxLabelTrue: PropTypes.string,
  checkboxLabel: PropTypes.string,
  disabled: PropTypes.bool,
  noTrans: PropTypes.bool,
  indeterminate: PropTypes.bool,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.oneOf([true, false, null]),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  sx: PropTypes.object,
};

Checkbox.defaultProps = {
  checkboxLabelFalse: '',
  checkboxLabelTrue: '',
  color: 'secondary',
  fullWidth: true,
  defaultValue: null,
  indeterminate: false,
  noTrans: false,
  label: '',
  margin: 'normal',
  disabled: false,
};

export default Checkbox;
