// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function EmailIcon(props) {
  return (
    <SvgIcon className="email-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#clip0_5779_276653)">
        <path d="M14.222 2.66666H1.77756C1.54181 2.66666 1.31572 2.76031 1.14902 2.92701C0.982322 3.0937 0.888672 3.3198 0.888672 3.55555V12.4444C0.888672 12.6802 0.982322 12.9063 1.14902 13.073C1.31572 13.2397 1.54181 13.3333 1.77756 13.3333H14.222C14.4578 13.3333 14.6838 13.2397 14.8505 13.073C15.0172 12.9063 15.1109 12.6802 15.1109 12.4444V3.55555C15.1109 3.3198 15.0172 3.0937 14.8505 2.92701C14.6838 2.76031 14.4578 2.66666 14.222 2.66666ZM13.5376 12.4444H2.51534L5.62645 9.22666L4.98645 8.60888L1.77756 11.9289V4.2311L7.30201 9.72888C7.46855 9.89443 7.69384 9.98736 7.92867 9.98736C8.1635 9.98736 8.38879 9.89443 8.55534 9.72888L14.222 4.09332V11.8711L10.9509 8.59999L10.3242 9.22666L13.5376 12.4444ZM2.35978 3.55555H13.502L7.92867 9.09777L2.35978 3.55555Z" fill="inherit" />
      </g>
      <defs>
        <clipPath id="clip0_5779_276653">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EmailIcon;
