// Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import DescriptionIcon from '@mui/icons-material/Description';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/billing/reports/actions';

function GenerateReportButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportModalIsOpen = useSelector(selectors.reports.reportModalIsOpen);

  const generateActModal = () => {
    dispatch(actions.setList({ modalIsOpen: !reportModalIsOpen }));
  };

  return (
    <ButtonWithIcon text={t('Загрузить историю транзакций')} onClick={generateActModal}>
      <DescriptionIcon />
    </ButtonWithIcon>
  );
}

export default GenerateReportButton;
