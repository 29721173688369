// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetStatusesListWorker() {
  yield put(actions.setStatusesListPending(true));

  const response = yield apply(api, api.serviceDesk.getStatuses, []);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setStatusesList(response.data));
  }

  yield put(actions.setStatusesListPending(false));
}
