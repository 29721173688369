// Core
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// UI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';

// config
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';

// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

const TableEditCell = (props) => {
  const { tableRow } = props;
  const { t } = useTranslation();
  const hasAccessShow = useAccessList(accessList.partnerPaymentTransactionShow);
  const style = { display: 'flex', justifyContent: 'center', gap: '8px' };
  const { canApprove, canCancel } = tableRow.row;

  return (
    <div style={style}>
      {hasAccessShow && !canApprove && !canCancel ? (
        <Tooltip title={t('Просмотреть транзакцию')} aria-label={t('Просмотреть транзакцию')} placement="right">
          <Link to={pageLinks.orders.paymentTransactionShow(tableRow.row.hashId)}>
            <IconButton sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
              <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
            </IconButton>
          </Link>
        </Tooltip>
      ) : Boolean(canApprove && canCancel) && (
        <Tooltip title={t('Редактировать транзакцию')} aria-label={t('Редактировать транзакцию')} placement="right">
          <Link to={pageLinks.orders.paymentTransactionEdit(tableRow.row.hashId)}>
            <IconButton sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
              <ModeOutlinedIcon sx={{ p: '2px' }} />
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

TableEditCell.propTypes = {
  tableRow: PropTypes.object.isRequired,
};

export default TableEditCell;
