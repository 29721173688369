// Core
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, Form } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import DeleteIcons from '@mui/icons-material/Delete';
import * as PropTypes from 'prop-types';
import Modal from '../../../../../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../../engine/core/content/actions';
import { accessList } from '../../../../../../../../engine/config/access';
import { asyncActions } from '../../../../../../../../engine/core/content/saga/asyncActions';
// Parts
import FormTextArea from '../../../../../../../components/_Form/TextArea/FormTextArea';
import { formName, formFields } from './form';
// Helpers
import { validators } from '../../../../../../../../_helpers/validationsForm';
// Hooks
import { useAccessList } from '../../../../../../../hooks/useAccessList';

function ProductDeleteModal(props) {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isOpen = useSelector(selectors.content.productDeleteModalIsOpen);
  const isDeleting = useSelector(selectors.content.productDeleteModalPending);
  const selections = useSelector(selectors.contentProduct.selection);
  const hasProductDeleteAccess = useAccessList(accessList.productDelete);

  if (!hasProductDeleteAccess) {
    return null;
  }

  const handleClose = () => {
    dispatch(actions.setProductDeleteModal({ isOpen: false }));
  };
  const onSubmit = (formData) => {
    if (formData && formData.toJS) {
      const data = formData.toJS();

      const dataToServer = {
        deleteNote: data[formFields.text],
        products: selections.toJS(),
      };
      dispatch(asyncActions.deleteProductsAsync(dataToServer));
    }
  };
  return (
    <Modal
      fullWidth
      modalOpen={Boolean(isOpen)}
      handleModalCancel={handleClose}
      handleModalToggle={handleClose}
      title={t('Удаление товаров')}
      iconSendButton={<DeleteIcons />}
      addText={t('Подтвердить')}
      disabledSend={isDeleting}
      loadingSend={isDeleting}
      handleModalAdd={handleSubmit}
      modalMaxWidth="sm"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FormTextArea}
          name={formFields.text}
          label={t('Причины удаления')}
          validate={[validators.required]}
          fullWidth
          multiline
          validation
          rows={10}
          variant="outlined"
          margin="normal"
        />
      </Form>
    </Modal>
  );
}

ProductDeleteModal.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(ProductDeleteModal);
