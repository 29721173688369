import { getIn, List } from 'immutable';
import { stateKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const bankInstallments = Object.freeze({
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.settingPending], false),
  treeDataRowIds: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.treeDataRowIds], List()),
  selection: state => getIn(state, [stateKeys.controller, stateKeys.bankInstallmentsList, stateKeys.selection], List()),
  logsList: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.items], List()),
  logsPending: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.pending], false),
  logsHashId: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.hashId], null),
  logsShowPopover: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.showPopover], false),
});
