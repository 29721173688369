// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_EMAIL_TEMPLATES_SETTING_PENDING, settingPending);
  },
});
