// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

// Redux
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { actions } from '../../../../../../engine/core/partners/managers/actions';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';

import { formName } from './PartnersManagersImportModal';

function PartnersManagersImport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(selectors.partnersManagers.partnerManagerModalIsOpen);
  const hasAccess = useAccessList(accessList.partnerManagerList);
  // const partnerManagerUploadIsPending = useSelector(selectors.partnersManagers.partnerManagerUploadIsPending);

  if (!hasAccess) {
    return null;
  }

  const setPartnerManagerModalIsOpen = () => {
    dispatch(reset(formName));
    dispatch(actions.setPartnerManagerModalIsOpen(!isOpen));
  };

  return (
    <>
      <ButtonWithIcon text={t('Загрузить XLSX')} onClick={setPartnerManagerModalIsOpen}>
        <CloudUploadIcon />
      </ButtonWithIcon>
    </>
  );
}

PartnersManagersImport.displayName = 'partnerManagerList';

export default PartnersManagersImport;
