// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function BalanceIcon(props) {
  return (
    <SvgIcon className="balance-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M7.19647 7.64512C7.19647 6.85763 9.74376 6.21887 12.8854 6.21887C16.0271 6.21887 18.5744 6.85763 18.5744 7.64512C18.5744 8.1732 17.427 8.63251 15.725 8.87785C15.6688 8.88594 15.6235 8.72387 15.6235 8.66713V7.64425C15.6235 7.36162 14.3972 7.13238 12.8845 7.13238C11.3719 7.13238 10.1456 7.36162 10.1456 7.64337L10.1456 8.66713C10.1456 8.72417 10.1008 8.88575 10.0444 8.87758C8.34437 8.63171 7.19647 8.17316 7.19647 7.64512ZM11.1146 15.4886C15.5955 15.4886 19.2291 14.3047 19.2291 12.8435V11.111C19.2291 12.5714 15.5964 13.7561 11.1146 13.7561C6.63274 13.7561 3 12.5723 3 11.111V12.8435C3.00088 14.3039 6.63274 15.4886 11.1146 15.4886ZM20.9921 14.6224C20.9921 16.0827 17.3594 17.2675 12.8775 17.2675C8.39571 17.2675 4.76297 16.0836 4.76297 14.6224V16.3549C4.76385 17.8161 8.39571 19 12.8775 19C17.3594 19 20.9921 17.8161 20.9921 16.3549V14.6224ZM21 7.64512C21 6.18388 17.3673 5 12.8854 5C8.40359 5 4.77085 6.18388 4.77085 7.64512L4.77085 9.37763C4.77085 10.838 8.40359 12.0227 12.8854 12.0227C17.3673 12.0227 21 10.838 21 9.37763V7.64512Z" fill="inherit" />
    </SvgIcon>
  );
}


export default BalanceIcon;
