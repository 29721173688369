// Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icon
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { asyncActions } from '../../../../../engine/core/magentoRequests/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';

function DownloadLogsMagentoRequestsButton() {
  const { t } = useTranslation();
  const logsMagentoRequestsDownloadPending = useSelector(selectors.magentoRequests.downloadPending);
  const dispatch = useDispatch();
  const onDownload = () => {
    dispatch(asyncActions.getDownloadAsync());
  };

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={logsMagentoRequestsDownloadPending}
      disabled={logsMagentoRequestsDownloadPending}
      text={t('Экспорт ошибок')}
      onClick={onDownload}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DownloadLogsMagentoRequestsButton;
