import * as React from 'react';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ContentWrapper from '../../../../containers/ContentWrapper/ContentWrapper';

const FilePreviewTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DocumentPreviewDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    margin: 0,
    overflow: 'scroll',
    height: '100%',
  },
}));

export default function DocumentFilePreviewModal({ open, selectedFile, onClose }) {
  if (!selectedFile || !selectedFile.fileItem || !selectedFile.fileItem.file) {
    return <CircularProgress />;
  }
  const fileItem = selectedFile.fileItem.source;
  const isImage = file => file && file.type.startsWith('image/');
  const isPDF = file => file && file.type === 'application/pdf';
  const [pages, setPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  return (
    <>
      <DocumentPreviewDialog
        fullScreen
        margin={0}
        open={open}
        scroll={'body'}
        onClose={onClose}
        PaperProps={{
          style: {
            width: '50%', // Make the modal cover half of the screen
            height: '100%',
            position: 'fixed',
            right: 0,
            top: 0,
            margin: 0,
            borderRadius: 0, // Remove border radius for full coverage
          },
        }}
        TransitionComponent={FilePreviewTransition}
      >
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
          padding={2}
          height={52}
        >
          <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>Перегляд документу</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <ContentWrapper style={{ margin: '20px', overflow: 'auto' }}>
          {isImage(fileItem) && <img src={URL.createObjectURL(fileItem)} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
          {isPDF(fileItem) && (
            <>
              <Document file={URL.createObjectURL(fileItem)} onLoadSuccess={onDocumentLoadSuccess}>
                {[...Array(pages + 1).keys()].slice(1).map((page, index) => (
                  <Page
                    key={`pdf_page_${index}`}
                    pageNumber={index + 1}
                    width={document.documentElement.clientWidth * 0.45}
                  />
                ))}
              </Document>
            </>
          )}
          {!isImage(fileItem) && !isPDF(fileItem) && (
          <Typography>
            Формат файлу не підтримується, перегляд не можливий. Рекомендований формат pdf, jpeg, png
          </Typography>
          )}
        </ContentWrapper>
      </DocumentPreviewDialog>
    </>
  );
}
