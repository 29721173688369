// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form/immutable';
// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// parts
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// import StopWordModal from './StopWordModal/StopWordModal';
// hooks
import { useAccessList } from '../../../../hooks/useAccessList';

// config
import { accessList } from '../../../../../engine/config/access';

// actions
import { actions } from '../../../../../engine/core/stopList/stopWords/actions';
import { formName } from './StopWordModal/form';

function UploadStopWordButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessUpload = useAccessList(accessList.stopWordsUpload);

  if (!hasAccessUpload) {
    return null;
  }

  const handleModalOpen = () => {
    dispatch(actions.mergeUploadStopWord({ isModalOpen: true }));
    dispatch(reset(formName));
  };

  return (
    <>

      <ButtonWithIcon text={t('Загрузить XLS')} onClick={handleModalOpen}>
        <CloudUploadIcon />
      </ButtonWithIcon>
    </>
  );
}

UploadStopWordButton.displayName = 'StopBrandsCategoriesUpload';

export default memo(UploadStopWordButton);
