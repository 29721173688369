import { asyncActions } from '../../../../../../engine/core/feeds/all/saga/asyncActions';

export const dispatchCompletionListItemData = ({
  dispatch, hashId, completionImportList, modalTypeKey,
  stepKey, value = false, isOpened = true, isSaved,
}) => {
  const isSavedData = isSaved !== undefined ? { isSaved } : {};
  dispatch(asyncActions.putCompletionImportListItemAsync({
    hashId,
    data: {
      ...completionImportList?.[hashId],
      ...isSavedData,
      [modalTypeKey]: {
        ...completionImportList?.[hashId]?.[modalTypeKey],
        [stepKey]: {
          value,
          isOpened,
        },
      },
    },
  }));
};
