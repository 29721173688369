// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function MonetizationIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 18 18">
      <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9 16.4732C4.87366 16.4732 1.52679 13.1263 1.52679 9C1.52679 4.87366 4.87366 1.52679 9 1.52679C13.1263 1.52679 16.4732 4.87366 16.4732 9C16.4732 13.1263 13.1263 16.4732 9 16.4732ZM9.95826 8.53393L9.44799 8.4154V5.71741C10.2114 5.82188 10.6835 6.3 10.7638 6.88661C10.7739 6.96696 10.8422 7.02522 10.9225 7.02522H11.8246C11.919 7.02522 11.9933 6.94286 11.9853 6.84844C11.8627 5.59687 10.8321 4.7933 9.45603 4.65469V3.99777C9.45603 3.90937 9.38371 3.83705 9.29531 3.83705H8.7308C8.64241 3.83705 8.57009 3.90937 8.57009 3.99777V4.66071C7.14777 4.79933 6.03482 5.58482 6.03482 7.05134C6.03482 8.40938 7.03527 9.06429 8.08594 9.3154L8.58214 9.44196V12.3087C7.6942 12.1902 7.19598 11.7161 7.09353 11.0772C7.08147 11.0009 7.01317 10.9446 6.93482 10.9446H6.0067C5.91228 10.9446 5.83795 11.025 5.84598 11.1194C5.93638 12.2243 6.77411 13.2408 8.56205 13.3714V14.0022C8.56205 14.0906 8.63438 14.1629 8.72277 14.1629H9.2933C9.3817 14.1629 9.45402 14.0906 9.45402 14.0002L9.45 13.3634C11.023 13.2248 12.148 12.383 12.148 10.8723C12.146 9.47812 11.26 8.85536 9.95826 8.53393ZM8.58013 8.20848C8.46763 8.17634 8.37321 8.1462 8.27879 8.10804C7.59978 7.86295 7.28438 7.46719 7.28438 6.95692C7.28438 6.22768 7.83683 5.81183 8.58013 5.71741V8.20848ZM9.44799 12.3147V9.62879C9.51027 9.64687 9.56652 9.66094 9.62478 9.67299C10.575 9.96228 10.8944 10.3641 10.8944 10.9808C10.8944 11.7663 10.3038 12.2384 9.44799 12.3147Z" />
    </SvgIcon>
  );
}

export default MonetizationIcon;
