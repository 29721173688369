// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form, Field, reduxForm, submit,
} from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Modal from '../../../containers/Modals/Modal/Modal';
import FilesUploader from '../../../components/_Form/FilesUploader/FilesUploader';
// Engine
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';
import { validators } from '../../../../_helpers/validationsForm';
import { mimeTypeExtensions } from '../../../../engine/config/mimeTypeExtensions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/partners/actions';
import { asyncActions } from '../../../../engine/core/partners/saga/asyncActions';
// Style
import FormHelperTextComponent from '../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';

export const form = Object.freeze({
  name: 'PartnersImportModal',
  fields: Object.freeze({
    partnersTopImportFile: 'partnersTopImportFile',
  }),
});

function PartnersImportTopModal(props) {
  const {
    handleSubmit, pending,
    pristine, valid, submitting,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessImportTop = useAccessList(accessList.partnerTopImport);
  const partnersTopImportPending = useSelector(selectors.partners.partnersTopImportPending);
  const partnersTopImportIsOpen = useSelector(selectors.partners.partnersTopImportIsOpen);

  const handleModalClose = () => {
    dispatch(actions.mergePartnersTopImport({
      isOpen: false,
    }));
  };

  const onFormSubmit = (values) => {
    dispatch(asyncActions.postPartnersImportTop(values.toJS()));
  };

  const handleModalSend = () => dispatch(submit(form.name));

  if (!hasAccessImportTop) {
    return null;
  }

  return (
    <Modal
      title={t('Импорт ТОП партнеров')}
      modalOpen={partnersTopImportIsOpen}
      handleModalToggle={handleModalClose}
      modalMaxWidth="xs"
      fullWidth
      disabledSend={pristine || submitting || partnersTopImportPending || !valid}
      loadingSend={partnersTopImportPending}
      handleModalSend={handleModalSend}
      disabledClose={partnersTopImportPending}
      handleModalCancel={handleModalClose}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={renderFilesUploader}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.partnersTopImportFile}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

function renderFilesUploader(fieldRenderProps) {
  const {
    meta: { touched, error }, disabled, ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

PartnersImportTopModal.propTypes = {
  handleSubmit: PropTypes.func,
  pending: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
};

const reduxFormConfig = {
  form: form.name,
};

export default reduxForm(reduxFormConfig)(PartnersImportTopModal);
