// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function MenuIcon(props) {
  return (
    <SvgIcon className="menu-icon" fontSize="inherit" viewBox="0 0 24 25" {...props}>
      <path d="M2 6.04297C2 5.72802 2.12511 5.42598 2.34781 5.20328C2.57051 4.98058 2.87256 4.85547 3.1875 4.85547H19.8125C20.1274 4.85547 20.4295 4.98058 20.6522 5.20328C20.8749 5.42598 21 5.72802 21 6.04297C21 6.35791 20.8749 6.65996 20.6522 6.88266C20.4295 7.10536 20.1274 7.23047 19.8125 7.23047H3.1875C2.87256 7.23047 2.57051 7.10536 2.34781 6.88266C2.12511 6.65996 2 6.35791 2 6.04297ZM2 20.293C2 19.978 2.12511 19.676 2.34781 19.4533C2.57051 19.2306 2.87256 19.1055 3.1875 19.1055H19.8125C20.1274 19.1055 20.4295 19.2306 20.6522 19.4533C20.8749 19.676 21 19.978 21 20.293C21 20.6079 20.8749 20.91 20.6522 21.1327C20.4295 21.3554 20.1274 21.4805 19.8125 21.4805H3.1875C2.87256 21.4805 2.57051 21.3554 2.34781 21.1327C2.12511 20.91 2 20.6079 2 20.293ZM3.1875 11.9805C2.87256 11.9805 2.57051 12.1056 2.34781 12.3283C2.12511 12.551 2 12.853 2 13.168C2 13.4829 2.12511 13.785 2.34781 14.0077C2.57051 14.2304 2.87256 14.3555 3.1875 14.3555H12.6875C13.0024 14.3555 13.3045 14.2304 13.5272 14.0077C13.7499 13.785 13.875 13.4829 13.875 13.168C13.875 12.853 13.7499 12.551 13.5272 12.3283C13.3045 12.1056 13.0024 11.9805 12.6875 11.9805H3.1875Z" />
    </SvgIcon>
  );
}

export default MenuIcon;
