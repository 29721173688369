// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Select from '../../../_Form/Selects/Select/Select';
// Components
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/messages/saga/asyncActions';

const MessageStatusFilter = (props) => {
  const {
    filters, disabled, onSelectChange, columnType,
  } = props;
  const dispatch = useDispatch();
  const messagesStatusesPending = useSelector(selectors.messages.messagesStatusesPending);
  const messagesStatuses = useSelector(selectors.messages.messagesStatuses);
  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const input = {
    value: filters && filters.value,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (!currentFiltersTypeList.includes(columnType)) {
      dispatch(asyncActions.getMessagesStatusesAsync());
    }
  }, [dispatch, currentFiltersTypeList, columnType]);


  return (
    <Select
      isLoading={messagesStatusesPending}
      options={messagesStatuses}
      disabled={disabled || messagesStatusesPending}
      label=""
      input={input}
    />
  );
};

export default MessageStatusFilter;

MessageStatusFilter.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  filters: PropTypes.object,
  disabled: PropTypes.bool,
  columnType: PropTypes.string,
};
