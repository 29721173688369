// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetListByStatusWorker({ payload }) {
  const { context = 'select_with_mid', statusId } = payload;
  yield put(actions.mergePartnersListByStatus({ statusId, data: { pending: true } }));
  const params = {
    context,
    partnerStatus: [{ value: statusId }],
  };
  const response = yield apply(api, api.partner.getPartnersList, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    yield put(actions.mergePartnersListByStatus({
      statusId,
      data: {
        items: List(data.items),
        totalCount: data.totalCount,
      },
    }));
  }

  yield put(actions.mergePartnersListByStatus({ statusId, data: { pending: false } }));
}
