
export const formName = 'TransactionPayment';
export const formFields = Object.freeze({
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  partnerPaymentType: 'partnerPaymentType',
  confirmedDate: 'confirmedDate',
  orderStatus: 'orderStatus',
  order: 'order',
  paymentAccepted: 'paymentAccepted',
  status: 'status',
  partner: 'partner',
  userConfirmed: 'userConfirmed',
});
