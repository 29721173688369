// Core
import { fromJS, List, Map } from 'immutable';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  synonymsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    selection: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'synonyms',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pageSize: 'pageSize',
  pending: 'pending',
  reload: 'reload',
  selection: 'selection',
  settingPending: 'settingPending',
  settingStatus: 'settingStatus',
  sorting: 'sorting',
  synonymsList: 'synonymsList',
  totalCount: 'totalCount',
});

export const synonymsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SYNONYMS_LIST: {
      const {
        data: items, total: totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.synonymsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.synonymsList, stateKeys.pending], pending)
        .setIn([stateKeys.synonymsList, stateKeys.items], List(items))
        .setIn([stateKeys.synonymsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_SYNONYMS_SORTING: {
      return state.setIn([stateKeys.synonymsList, stateKeys.sorting], List(payload));
    }
    case types.SET_SYNONYMS_FILTERS: {
      const filters = payload.filter(item => item.value !== null && item.value !== undefined);
      return state.setIn([stateKeys.synonymsList, stateKeys.filters], List(filters));
    }
    case types.SET_SYNONYMS_PAGE_SIZE: {
      return state.setIn([stateKeys.synonymsList, stateKeys.pageSize], payload);
    }
    case types.SET_SYNONYMS_SELECTION: {
      return state.setIn([stateKeys.synonymsList, stateKeys.selection], List(payload));
    }
    case types.SET_SYNONYMS_CURRENT_PAGE: {
      return state.setIn([stateKeys.synonymsList, stateKeys.currentPage], payload);
    }
    case types.SET_SYNONYMS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.synonymsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.synonymsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.synonymsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.synonymsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.synonymsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.synonymsList, stateKeys.reload], reload)
        .setIn([stateKeys.synonymsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_SYNONYMS_SETTING_STATUS: {
      return state.setIn([stateKeys.synonymsList, stateKeys.settingStatus], payload);
    }
    case types.SET_SYNONYMS_SETTING_PENDING: {
      return state.setIn([stateKeys.synonymsList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
