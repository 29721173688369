// Core
import React from 'react';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// components
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
// utils
import { validators } from '../../../../../../../_helpers/validationsForm';
import { formFields } from './ProductMultipleEditingForm';

function SkuGroupField() {
  const { t } = useTranslation();

  return (
    <Field
      component={TextField}
      isPortal={false}
      name={formFields.productGroups}
      label={t('SKU группы')}
      fullWidth
      validation
      variant="outlined"
      validate={[validators.required]}
      color="dark"
      format={value => value?.replace(/[^a-zA-Z0-9-]/g, '')}
    />
  );
}

export default SkuGroupField;
