// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../engine/core/partners/partnerServiceRequests/saga/asyncActions';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// Data
import { requestDelay } from '../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../_helpers/convertDataToUrlParams';

export function usePartnerServiceRequestList(id, refresh) {
  const currentPage = useSelector(selectors.partnersServiceRequests.currentPage);
  const limit = useSelector(selectors.partnersServiceRequests.pageSize);
  const filters = useSelector(selectors.partnersServiceRequests.filters);
  const sorting = useSelector(selectors.partnersServiceRequests.sorting);
  const reload = true;
  const routeKey = useSelector(selectors.router.key);
  const dispatch = useDispatch();
  const hasAccessList = useAccessList(accessList.partnerServiceRequestsAdminList);
  const offset = limit * currentPage;

  const filtersStringify = JSON.stringify(filters);
  const sortingStringify = JSON.stringify(sorting);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const getListAsync = useCallback(() => {
    if (hasAccessList) {
      const params = {
        ...paramsByFilter,
        orderBy,
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [hasAccessList, paramsByFilter, offset, orderBy, limit, dispatch]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);

  useEffect(() => {
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync, reload, refresh, timeout, routeKey]);
}
