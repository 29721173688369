// Core
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
// Helpers
import { COLOR } from '../../../../containers/App/AppStyles';

export const Wrapper = styled(Box)({
  '& input.MuiInputBase-input': {
    fontSize: '14px',
    lineHeight: '22px',
    padding: '10px 14px',
    '&[value^="DD/MM/YYYY"]': {
      color: COLOR.grey['87'],
    },
  },
  '& fieldset': {
    borderWidth: '1px !important',
  },
  '& svg': {
    fontSize: '22px',
    color: COLOR.black.initial,
  },
  '& .MuiFormLabel-root': {
    '&[data-shrink="false"]': {
      fontSize: '14px',
      zIndex: 'initial',
    },
  },
  '& .Mui-disabled': {
    '& input': {
      color: COLOR.grey['38'],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLOR.grey['12']} !important`,
    },
  },
});
