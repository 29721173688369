// Core
import React from 'react';
import { useParams } from 'react-router';
// Components
import FaqEditForm from './components/FaqEditForm';
// Hooks
import { useEditFaq } from './hooks/useEditFaq';

function FaqEditPage({ mode }) {
  const { hashId } = useParams();

  useEditFaq();

  return (
    <FaqEditForm hashId={hashId} mode={mode} />
  );
}

export default FaqEditPage;
