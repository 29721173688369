// Core
import { formValueSelector } from 'redux-form/immutable';

export const formName = 'orderForm';

export const formFields = Object.freeze({
  canceledAt: `${formName}CanceledAt`,
  cancelStatus: `${formName}CancelStatus`,
  cancelStatusMessage: `${formName}CancelStatusMessage`,
  customerEmail: `${formName}CustomerEmail`,
  customerName: `${formName}CustomerName`,
  customerPhone: `${formName}CustomerPhone`,
  doneAt: `${formName}DoneAt`,
  id: `${formName}Id`,
  information: `${formName}Information`,
  idMagentoOrder: `${formName}IdMagentoOrder`,
  idMagentoPartner: `${formName}IdMagentoPartner`,
  orderedAt: `${formName}OrderedAt`,
  orderStatus: `${formName}OrderStatus`,
  partner: `${formName}Partner`,
  paymentStatus: `${formName}PaymentStatus`,
  shippingApartment: `${formName}ShippingApartment`,
  shippedAt: `${formName}ShippedAt`,
  shippingCity: `${formName}ShippingCity`,
  paymentType: `${formName}PaymentType`,
  shippingCityUid: `${formName}ShippingCityUid`,
  shippingHouse: `${formName}ShippingHouse`,
  shippingPrice: `${formName}ShippingPrice`,
  shippingStreet: `${formName}ShippingStreet`,
  shippingTrackingNumber: `${formName}ShippingTrackingNumber`,
  shippingType: `${formName}ShippingType`,
  stockName: `${formName}StockName`,
  name: `${formName}Name`,
  note: `${formName}Note`,
  isSelfDelivery: `${formName}isSelfDelivery`,
});

export const formSelector = formValueSelector(formName);
