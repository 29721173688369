// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import ForEach from 'lodash/forEach';
import { api, requestStatus } from '../../../../config/api';
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';
import { convertJSObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';

export function* callGetProductsOnlyTextExportWorker({ payload }) {
  const {
    onlyTextFeatures, forceAction, categoryId, attributeSetId,
  } = payload;
  yield put(actions.setProductDownloadPending(true));

  const filters = yield select(selectors.contentProduct.filters);
  const sorting = yield select(selectors.contentProduct.sorting);
  const selection = yield select(selectors.contentProduct.selection);
  const params = {
    categoryId,
    forceAction,
    onlyTextFeatures,
    attributeSetId,
  };
  if (selection.size) {
    params.products = selection.toJS().join();
  } else {
    const data = filters.toJS().reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {});
    const convertedData = convertJSObjectToUrlParams(data);
    const filter = {};
    const fixedFilter = {};
    ForEach(convertedData, (key, value) => {
      const filterName = value.replace('filter[', '').replace(']', '');
      filter[filterName] = convertedData[value];
    });
    if (filters.size) {
      ForEach(filter, (value, key) => {
        const parts = key.split('[');
        const prop = parts[0];
        const subprop = parts[1] ? parts[1].slice(0, -1) : null;

        if (!fixedFilter[prop]) {
          fixedFilter[prop] = {};
        }

        if (subprop) {
          fixedFilter[prop][subprop] = value;
        } else {
          fixedFilter[prop] = value;
        }
      });
      params.filter = fixedFilter;
    }
  }
  if (sorting.size) {
    const sort = sorting.toJS();
    params.orderBy = `${sort[0]?.columnName} ${sort[0]?.direction}`;
  }


  const response = yield apply(api, api.products.getProductsOnlyTextExport, [params]);


  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, message, needForceAction } = responseData;
        if (needForceAction) {
          yield put(actions.setDownloadForceAction({ type: 'exportOnlyText', forceAction: needForceAction ? 1 : 0 }));
        } else {
          yield put(setErrorMessage(errors || message, statusTitle));
        }
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        if (forceAction) {
          yield put(actions.setDownloadForceAction({ type: null, forceAction: null }));
        }
        break;
      }

      default: {
        getFileFromBlob('export_products_properties.xlsx', response);
        break;
      }
    }
  }
  yield put(actions.setProductDownloadPending(false));
}
