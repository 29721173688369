
// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_SERVICES_REQUESTS_LIST_ASYNC, params);
  },
  partnerServiceRequestsDownloadAction(params) {
    return getActionCreator(asyncTypes.GET_PARTNER_SERVICES_REQUESTS_DOWNLOAD, params);
  },
});
