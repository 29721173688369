import * as React from 'react';
import Slide from '@mui/material/Slide';
import BaseModal from '../BaseModal';

const RightSideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function RightSlideModal(props) {
  const { children } = props;
  return (
    <BaseModal TransitionComponent={RightSideTransition} {...props}>{children}</BaseModal>
  );
}
