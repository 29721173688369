// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Box from '@mui/material/Box';
// Parts
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useStyles } from '../../../../../hooks/useStyles';
import Switcher from '../../../../../components/_Form/Switcher/Switcher';
import { COLOR } from '../../../../../containers/App/AppStyles';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { useCurrentMode } from '../../../hooks/useCurrentMode';
import { usePartnerData } from '../../../hooks/usePartnerData';
import { actions } from '../../../../../../engine/core/partners/actions';
import DeliveryDelayPeriodModal from './DeliveryDelayPeriodModal/DeliveryDelayPeriodModal';
import { getDateWithoutHours } from '../../../../../../_helpers/getDate';
import DeleteDeliveryDelayPeriodModal from './DeleteDeliveryDelayPeriodModal';
import { asyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../engine/config/access';

const styles = () => ({
  contentHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desc: {
    fontSize: '12px',
    color: COLOR.grey['54'],
    lineHeight: '16px',
    marginTop: '11px',
    marginBottom: '11px',
  },
  periodItem: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: '15px',
  },
  periodItemLabel: {
    fontSize: '14px',
    color: COLOR.grey['54'],
  },
  periodItemValue: {
    fontSize: '14px',
    color: COLOR.grey.main,
  },
});
function DeliveryDelayPeriod(props) {
  const { mode, hashId } = props;
  const { isShowMode } = useCurrentMode(mode);
  const { getInfoFieldValue } = usePartnerData();
  const deliveryDelayPeriodValue = getInfoFieldValue('deliveryDelayPeriod');
  const orderProcessingScheduleValue = getInfoFieldValue('orderProcessingSchedule');
  const isOrderProcessingScheduleExist = orderProcessingScheduleValue?.days && orderProcessingScheduleValue?.days.length !== 0;
  const isDelayPeriodExist = deliveryDelayPeriodValue?.delayedPeriod && deliveryDelayPeriodValue?.delayedPeriod.length !== 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(styles);
  const hasSwitcherAccess = useAccessList(accessList.switchPartnerSchedule);

  const handleChange = () => {
    dispatch(asyncActions.putPartnersOrderProcessingScheduleAsync({
      hashId,
      formData: {
        infoFieldsValues: {
          deliveryDelayPeriod: {
            enabled: !deliveryDelayPeriodValue?.enabled,
            delayedPeriod: deliveryDelayPeriodValue?.delayedPeriod,
          },
        },
      },
    }));
  };
  const handleDeleteDelayPeriod = () => {
    if (typeof hashId === 'string' && hashId !== '') {
      dispatch(actions.setPartnersDeliveryDelayPeriodDelete({ showModal: true, hashId }));
    }
  };
  const handleOpenModal = () => {
    dispatch(actions.mergePartnerDeliveryDelayPeriod({
      isModalOpen: true,
    }));
  };

  return (
    <>
      <Box>
        <Box
          component="div"
          className={classes.contentHolder}
        >
          <Typography variant="h5" className={classes.subtitle}>
            {`${t('Период задержки доставок')}:`}
          </Typography>
          { hasSwitcherAccess && (
            <Switcher
              disableRipple
              color="secondary"
              onChange={handleChange}
              isDisabled={!isDelayPeriodExist || isShowMode}
              isChecked={deliveryDelayPeriodValue?.enabled}
              sx={{ marginRight: '8px', pointerEvents: (!isDelayPeriodExist || isShowMode) && 'none' }}
            />
          ) }
        </Box>
        <Box className={classes.desc} component="p">{t('Вы можете добавить день или период, в который обработка и отгрузка заказов будет производиться дольше.')}</Box>
        {isDelayPeriodExist && (
          <Box mb={1}>
            <Box className={classes.periodItem}>
              <Box className={classes.periodItemLabel}>{t('Период')}</Box>
              <Box className={classes.periodItemValue}>{getDateWithoutHours(deliveryDelayPeriodValue?.delayedPeriod?.start)} - {getDateWithoutHours(deliveryDelayPeriodValue?.delayedPeriod?.end)}</Box>
            </Box>
            <Box className={classes.periodItem}>
              <Box className={classes.periodItemLabel}>{t('Количество дней')}</Box>
              <Box className={classes.periodItemValue}>{deliveryDelayPeriodValue?.delayedPeriod?.daysDelay}</Box>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <ButtonWithIcon
            sx={isDelayPeriodExist && { padding: '9px 22px' }}
            disabled={isShowMode || !isOrderProcessingScheduleExist}
            onClick={handleOpenModal}
            text={isDelayPeriodExist ? t('Изменить период') : t('Период задержки доставок')}
          >
            {!isDelayPeriodExist && (<AddCircleIcon />)}
          </ButtonWithIcon>
          {isDelayPeriodExist && (
            <Button onClick={handleDeleteDelayPeriod} disabled={isShowMode} color="primary" size="small" variant="contained">
              {t('Удалить период')}
            </Button>
          )}
        </Box>
      </Box>
      <DeliveryDelayPeriodModal hashId={hashId} />
      <DeleteDeliveryDelayPeriodModal hashId={hashId} />
    </>
  );
}

export default DeliveryDelayPeriod;
