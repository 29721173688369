// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Components
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';

// Hooks
import { useAccessList } from '../../../hooks/useAccessList';

// Engine
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { priceListAsyncAction } from '../../../../engine/core/priceCreator/saga/asyncAction';

function DownloadPriceListButton() {
  const hasAccess = useAccessList(accessList.productPricesDownload);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.priceList.isDownloadFullPriceListLoading);
  const partnerId = useSelector(selectors.user.userPartnerId);

  if (!hasAccess) {
    return null;
  }

  const downloadPriceList = () => {
    dispatch(priceListAsyncAction.downloadPriceListAsync(partnerId));
  };


  return (
    <ButtonWithIconAndCircularProgress
      text={t('Скачать прайс-лист')}
      onClick={downloadPriceList}
      isLoading={isLoading}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DownloadPriceListButton;
