// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setAttributeSetsListContext(data) {
    return getActionCreator(types.SET_ATTRIBUTE_SETS_LIST_CONTEXT, data);
  },
});
