import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COLOR } from '../../../../../../containers/App/AppStyles';

export const BoxWrapperStatistic = styled(Box)`
    display: flex;
    margin-left: 8px;
    border-radius: 5px;
    background: ${COLOR.grey['8']};
    padding: 1px;
    flex-wrap: wrap;
`;

export const ItemStatistic = styled(Box)`
    border-radius: 5px;
    padding: 2px;
    font-size: 10px;
    text-align: center;
    transition: 150ms;
    font-weight: 700;
    min-width: 100px;
    margin: 1px;

    &:hover  {
      background: #FFFFFF;
      box-shadow: 0px 0px 8px ${COLOR.grey['12']};
      transition: 150ms;

      & div {
        font-weight: 400;
        transition: 150ms;
        color: ${COLOR.grey.main}
      }
    }

    & div {
      color: ${COLOR.grey['72']};
      font-weight: 400;
      transition: 150ms;
      font-size: 12px;
    }
`;

export const ItemStatisticName = styled(Typography)`
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 2px;
`;

export const ItemStatisticQuantity = styled(Box)`
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  margin: 0;
  color: ${COLOR.black['72']};
`;
