// Core
import { useTranslation } from 'react-i18next';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import NotificationNote from '../../../../../components/Notifications/NotificationNote';
// Style
import { SubTitle } from '../../../styles';

const TabTitle = (props) => {
  const { title, showLogisticsMessageEdit, showLogisticsMessageMassEdit } = props;
  const showLogisticsMessage = showLogisticsMessageEdit || showLogisticsMessageMassEdit;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <SubTitle component="h2" mt={2}>{title}</SubTitle>
        {showLogisticsMessage && (
          <Box sx={{ maxWidth: 'calc(100% - 70px)' }}>
            {showLogisticsMessageEdit && (
              <NotificationNote type="info" mt={1.5}>
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: t('Вы не можете вручную изменять характеристики логистических данных для товаров с <b>автоматическим</b> обновлением характеристик.'),
                  }}
                />
                <Box>
                  {t('Для внесения изменений добавьте характеристики товара в прайс-лист или переведите товар в ручной режим обновления характеристик, чтобы внести изменения вручную.')}
                </Box>
              </NotificationNote>
            )}
            {showLogisticsMessageMassEdit && (
              <NotificationNote type="info" mt={1.5}>
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: t('Характеристики логистических данных на этой странице не могут изменяться для товаров с <b>автоматическим</b> обновлением характеристик.'),
                  }}
                />
                <Box>
                  {t('Для того чтобы внести изменения, вам нужно добавить характеристики товаров в прайс-лист или перевести эти товары в ручное обновление характеристик, чтобы изменить значение на этой странице.')}
                </Box>
              </NotificationNote>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default TabTitle;
