// Core
import { components } from 'react-select';

const MenuList = (props) => {
  const {
    children, selectProps,
  } = props;

  return (
    <components.MenuList
      {...props}
      hasSearch={selectProps?.hasSearch}
      className={`${selectProps?.classes.menuList} ${selectProps?.classes.menuItem}`}
    >
      {children}
    </components.MenuList>
  );
};
export default MenuList;
