export const asyncTypes = Object.freeze({
  GET_MESSAGES_LIST_ASYNC: 'GET_MESSAGES_LIST_ASYNC',
  PUT_MESSAGES_SETTINGS_ASYNC: 'PUT_MESSAGES_SETTINGS_ASYNC',
  GET_MESSAGES_INITIAL_STATE_ASYNC: 'GET_MESSAGES_INITIAL_STATE_ASYNC',
  GET_MESSAGES_USER_TYPES_ASYNC: 'GET_MESSAGES_USER_TYPES_ASYNC',
  POST_MESSAGE_ASYNC: 'POST_MESSAGE_ASYNC',
  GET_MESSAGE_BY_HASH_ID_ASYNC: 'GET_MESSAGE_BY_HASH_ID_ASYNC',
  DELETE_MESSAGE_HASH_ID_ASYNC: 'DELETE_MESSAGE_HASH_ID_ASYNC',
  GET_MESSAGE_USER_TYPES_BY_TYPE_ASYNC: 'GET_MESSAGE_USER_TYPES_BY_TYPE_ASYNC',
  GET_MESSAGES_STATUSES_ASYNC: 'GET_MESSAGES_STATUSES_ASYNC',
  POST_RECIPIENTS_IMPORT_ASYNC: 'POST_RECIPIENTS_IMPORT_ASYNC',
});
