// Core
import { apply, put } from 'redux-saga/effects';
import get from 'lodash/get';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutUpdateFromMagento({ payload: categoryId }) {
  yield put(actions.setIsContentUpdateFromMagentoPending(true));
  yield put(actions.setIsContentUpdateFromMagentoCategory(get(categoryId, 'categoryId')));

  const response = yield apply(api, api.contentTemplates.putUpdateFromMagento, [categoryId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      default: {
        break;
      }
    }
  }

  yield put(actions.setIsContentUpdateFromMagentoCategory(null));
  yield put(actions.setIsContentUpdateFromMagentoPending(false));
}
