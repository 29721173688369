// Core
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Engine
import { selectors } from '../../../../engine/config/selectors';
import { framePageConfigParams } from '../../../../engine/config/framePageConfigParams';
import { asyncActions } from '../../../../engine/core/systemMotivation/results/saga/asyncActions';
import { actions } from '../../../../engine/core/systemMotivation/results/actions';
import { initialState, stateKeys } from '../../../../engine/core/systemMotivation/results/reducer';
import { accessList } from '../../../../engine/config/access';

// parts
import PageHeader from '../../../components/PageHeader/PageHeader';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ResultsTable from './components/ResultsTable';
import ExportButton from './components/ExportButton';
import FramePage from '../../FramePage/FramePage';

// hooks
import { useResultsList } from './_hooks/useResultsList';
import { useControlLinks } from './_hooks/useControlLinks';
import { useAccessList } from '../../../hooks/useAccessList';

function ResultsPage() {
  const { t } = useTranslation();
  useResultsList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.systemMotivationResults.settingStatus);
  const settingPending = useSelector(selectors.systemMotivationResults.settingPending);
  const isLoading = useSelector(selectors.systemMotivationResults.isLoading);
  const filters = useSelector(selectors.systemMotivationResults.filters);
  const sorting = useSelector(selectors.systemMotivationResults.sorting);
  const hasAccessMotivationSystemList = useAccessList(accessList.motivationSystemList);
  const { isPartnersCashBackPage, getLink, isSuccessfulPartnerPage } = useControlLinks();
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.resultsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.resultsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.resultsList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const handlePutSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  const getTextTransitionButton = useMemo(() => isPartnersCashBackPage
    ? t('Результаты')
    : t('Презентация'), [isPartnersCashBackPage, t]);

  return (
    <>
      <PageHeader
        title={(
          <Grid container alignItems="center">
            <Box mr={1}>{t('Успешный партнер')}</Box>
            {hasAccessMotivationSystemList && (
              <Link to={getLink}>
                <Button variant="contained" color="primary">
                  {getTextTransitionButton}
                </Button>
              </Link>
            )}
          </Grid>
        )}
      >
        {isSuccessfulPartnerPage && (
          <>
            <ExportButton />
            <GridSettingsButton
              settingPending={settingPending}
              settingStatus={settingStatus}
              putSetting={handlePutSetting}
            />
            <ResetFiltersButton
              pending={isLoading || settingPending}
              disabled={isDisabledResetButton}
              onReset={resetFilters}
            />
          </>
        )}
      </PageHeader>
      {isPartnersCashBackPage
        ? <FramePage configParams={framePageConfigParams.htmlPartnersCallback} />
        : <ResultsTable />}
    </>
  );
}

export default memo(ResultsPage);
