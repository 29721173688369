import { Stack, styled } from '@mui/system';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { COLOR } from '../../AppStyles';

export const TooltipBody = styled(Box)`
  background-color: #ffffff;
  position: relative;
  border-radius: 5px;
  width: 450px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 3px);
`;

export const TooltipContent = styled(Box)`
  padding: ${props => (props.isDownLGHeightView ? '10px 30px' : '30px')};
  padding-bottom: 8px;
`;

export const TooltipText = styled(Box)`
  color: ${COLOR.grey.main};
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 13px;
  right: 30px;
  padding: 0;
  color: #565656;
`;

export const TooltipTitleText = styled('p')`
  color: ${COLOR.grey.main};
  margin: 0;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
`;

export const TooltipTitle = styled(Box)`
  color: ${COLOR.grey.main};
  padding: 17px 60px 17px 30px;
  line-height: 1;
  margin: 0;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipFooter = styled(Box)`
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #E6E6E6;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  * + * {
    margin-left: 0.5rem;
  }
`;

export const StepsContainer = styled(Stack)`
  background-color: transparent;
  justify-content: center;
  margin-top: ${props => (props.isDownLGHeightView ? '18px' : '24px')};
  flex-direction: row;
  align-items: center;
`;

export const StepHolder = styled('div')`
  width: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Step = styled('button')(({ active }) => ({
  height: active ? '6px' : '4px',
  width: active ? '6px' : '4px',
  borderRadius: '50%',
  backgroundColor: active ? COLOR.brand.dark : COLOR.grey['38'],
  cursor: 'pointer',
  border: 'none',
  padding: '0',
}));
