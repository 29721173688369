import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { COLOR } from '../../../containers/App/AppStyles';


const DialogHeader = ({ headerLabel, handleClose }) => (
  <Box sx={{
    p: '14px 30px 14px 20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px',
    border: 'solid',
    borderColor: COLOR.black[12],
    maxHeight: '52px',
  }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        onClick={handleClose}
        disableTouchRipple
        sx={{
          color: 'inherit',
          padding: 0,
          '&:hover': {
            backgroundColor: 'inherit',
          },
        }}
      ><KeyboardBackspaceIcon />
      </Button>
      <Typography fontSize={16} fontWeight={700}>{headerLabel}</Typography>
    </Box>
    <Button
      onClick={handleClose}
      disableTouchRipple
      sx={{
        color: 'inherit',
        '&:hover': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <CloseIcon />
    </Button>
  </Box>
);


export default DialogHeader;
