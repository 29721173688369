import React, { memo, useEffect, useState } from 'react';
import { Field } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import get from 'lodash/get';
import union from 'lodash/union';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import FormControl from '@mui/material/FormControl';
import RenderInputField from './components/RenderInputField';
import RenderRadioField from './components/RenderRadioField';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';


function FieldWithRadioAndInputWithConfirm(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    radioProps: {
      options, required, moreOptions, compareInInput, ...restRadio
    },
    inputProps: {
      inputName, type, autoFocus, ...restInput
    },
    fullWidth, name, formName, margin,
  } = props;

  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValues = formValues.toJS();
  const value = get(normalizeFormValues, name, undefined);
  const optionsNotIncludesValue = options.includes(value);
  const optionsToSelect = union(options, [moreOptions]).map(item => ({ value: item, label: t(item) }));
  const [showInput, setShowInput] = useState(optionsNotIncludesValue);
  const changeOwnership = (event) => {
    if (event.target.value === moreOptions) {
      dispatch(change(formName, inputName ? name : `${name}Radio`, moreOptions));
      setTimeout(() => {
        setShowInput(true);
        if (optionsNotIncludesValue) {
          dispatch(change(formName, inputName || name, ''));
        } else {
          dispatch(change(formName, inputName || name, value || ''));
        }
      }, 0);
    } else {
      setShowInput(false);
      dispatch(change(formName, name, event.target.value));
    }
  };

  useEffect(() => {
    if (value && !optionsNotIncludesValue) {
      setShowInput(true);
      dispatch(change(formName, inputName ? name : `${name}Radio`, moreOptions));
      dispatch(change(formName, name, value));
    } else if (value === moreOptions) {
      setShowInput(true);
      dispatch(change(formName, inputName ? name : `${name}Radio`, moreOptions));
    } else if (optionsNotIncludesValue) {
      setShowInput(false);
    }
  }, [dispatch, moreOptions, formName, name, optionsNotIncludesValue, value, inputName]);

  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <Field
        onChange={changeOwnership}
        component={RenderRadioField}
        variant="standard"
        compareInInput={compareInInput}
        confirmInputValue={restInput.getOldValue(inputName)}
        name={showInput && !inputName ? `${name}Radio` : name}
        {...restRadio}
        setShowInput={setShowInput}
        showInput={showInput}
        options={optionsToSelect}
        fullWidth={fullWidth}
        required={required}
        formSelectWidth={fullWidth ? '100%' : undefined}
      />
      {showInput
      && (
        <Field
          component={RenderInputField}
          name={inputName || name}
          type={type}
          {...restInput}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
        />
      )}
    </FormControl>
  );
}

FieldWithRadioAndInputWithConfirm.defaultProps = {
  inputProps: {
    autoFocus: true,
  },
};

FieldWithRadioAndInputWithConfirm.propTypes = {
  radioProps: PropTypes.shape({
    options: PropTypes.array,
    moreOptions: PropTypes.string,
    compareInInput: PropTypes.bool,
    required: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    inputName: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
  }),
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  formName: PropTypes.string,
  margin: PropTypes.string,
};

export default memo(FieldWithRadioAndInputWithConfirm);
