// Core
import { apply, put } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetProductSettingsWorker({ payload }) {
  yield put(actions.setProductGridLoading(true));
  const categoryId = get(payload, 'categoryId', null);
  const attributeSetId = get(payload, 'attributeSetId', null);
  const onSuccess = get(payload, 'onSuccess');
  const response = yield apply(api, api.products.getProductsSetting, [{ categoryId, attributeSetId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const productGrid = get(response, 'data.productGrid', []);

    yield put(actions.setProductGrid(productGrid.map(
      item => ({
        ...item,
        name: String(item.name), // TODO The feature name comes as a number. But for the grid settings to work correctly, it needs to be a string. https://jira.forforce.com/browse/AMP-7632
      }),
    )));
    if (onSuccess) {
      onSuccess();
    }
  }

  yield put(actions.setProductGridLoading(false));
}
