// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form/immutable';
import { change } from 'redux-form';
import * as PropTypes from 'prop-types';
// Parts
import Timer from 'react-timer-wrapper';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import ComponentWithTooltip from '../../../containers/ComponentWithTooltip';

// Helpers
import { phoneMask } from '../../../../_helpers/validationsForm';

// Engine
import { userActionAsync } from '../../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../../engine/config/selectors';

const formFields = {
  newPhoneInput: 'newPhoneInput',
  confirmCode: 'confirmCode',
};

function ChangePhone(props) {
  const {
    formName, formValue, putUserInfoAsync, putUserInfoValue, getRepeatSendPhoneCodeAsync,
    postPhoneVerifyCodeAsync, confirmCodeValue, changeFormFieldValue, showForm,
  } = props;
  const { t } = useTranslation();
  const { sendNewPhoneStatus, pending: putUserInfoValuePending, newPhoneValue } = putUserInfoValue;
  const { pending: confirmCodeValuePending, status: confirmCodeValueStatus } = confirmCodeValue;
  const { newPhoneInput, confirmCode } = formValue(formName);

  const [newPhoneLength, setNewPhoneLength] = useState(0);
  const [showConfirmCounter, setShowConfirmCounter] = useState(true);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const notValidPhoneLength = newPhoneLength !== 12;

  const sendNewPhoneHandler = () => {
    putUserInfoAsync({ phonePending: newPhoneInput });
  };

  const repeatSendPhoneCode = () => {
    setShowConfirmCounter(true);
    getRepeatSendPhoneCodeAsync();
  };

  const sendConfirmCodeHandler = () => {
    postPhoneVerifyCodeAsync({ code: confirmCode });
  };

  const onTimerUpdate = ({ time: currentTime, duration: currentDuration }) => {
    setTime(currentTime);
    setDuration(currentDuration);
  };

  useEffect(() => {
    if (typeof newPhoneInput !== 'undefined') {
      setNewPhoneLength(newPhoneInput.length);
    }
  }, [newPhoneInput]);

  useEffect(() => {
    if (showForm) {
      changeFormFieldValue(formName, formFields.newPhoneInput, newPhoneValue || '380');
    }
  }, [changeFormFieldValue, formName, showForm, newPhoneValue]);

  useEffect(() => {
    if (confirmCodeValueStatus) {
      changeFormFieldValue(formName, formFields.newPhoneInput, '');
      changeFormFieldValue(formName, formFields.confirmCode, '');
      setNewPhoneLength(0);
    }
  }, [changeFormFieldValue, formName, confirmCodeValueStatus]);

  return showForm ? (
    <div>
      <Field
        name={formFields.newPhoneInput}
        component={TextField}
        label={t('Новый телефон')}
        fullWidth
        autoFocus={!putUserInfoValuePending}
        margin="normal"
        validation
        disabled={(putUserInfoValuePending && showConfirmCounter) || sendNewPhoneStatus}
        type="tel"
        {...phoneMask}
        endAdornment={
          (!sendNewPhoneStatus && !putUserInfoValuePending)
            ? (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={notValidPhoneLength}
                  onClick={sendNewPhoneHandler}
                >
                  {t('Отправить')}
                </Button>
              </InputAdornment>
            )
            : (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={showConfirmCounter || notValidPhoneLength}
                  onClick={repeatSendPhoneCode}
                  style={{ minWidth: showConfirmCounter ? 220 : 175 }}
                >
                  {t('Отправить повторно')}
                  {showConfirmCounter
                    && (
                    <Timer active duration={9000} onTimeUpdate={onTimerUpdate} onFinish={() => setShowConfirmCounter(false)}>
                      <span>&nbsp;{t('через')} {Math.round((duration - time) / 1000)}</span>
                    </Timer>
                    )
                  }
                </Button>
              </InputAdornment>
            )
        }
      />
      {sendNewPhoneStatus && !notValidPhoneLength
      && (
        <Field
          name={formFields.confirmCode}
          component={TextField}
          label={(
            <span>{t('Код подтверждения')}
              <ComponentWithTooltip description={t('Был отправлен на указанный номер')} />
            </span>
          )}
          fullWidth
          autoFocus
          margin="normal"
          validation
          endAdornment={(
            <InputAdornment position="end">
              <Button variant="outlined" disabled={!confirmCode || confirmCodeValuePending} color="primary" onClick={sendConfirmCodeHandler}>
                {t('Отправить')}
              </Button>
            </InputAdornment>
          )}
        />
      )
      }
    </div>
  ) : null;
}

ChangePhone.propTypes = {
  showForm: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  formValue: PropTypes.func.isRequired,
  putUserInfoAsync: PropTypes.func.isRequired,
  putUserInfoValue: PropTypes.object.isRequired,
  getRepeatSendPhoneCodeAsync: PropTypes.func.isRequired,
  confirmCodeValue: PropTypes.object.isRequired,
  postPhoneVerifyCodeAsync: PropTypes.func.isRequired,
  changeFormFieldValue: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formValue: (form) => {
      const selector = formValueSelector(form);
      return selector(state, formFields.newPhoneInput, formFields.confirmCode);
    },
    putUserInfoValue: selectors.user.putUserInfo(state).toJS(),
    confirmCodeValue: selectors.user.phoneVerifyConfirmCode(state).toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeFormFieldValue: (formName, formField, fieldValue) => dispatch(change(formName, formField, fieldValue)),
    putUserInfoAsync: userInfo => dispatch(userActionAsync.putUserInfoAsync(userInfo)),
    postPhoneVerifyCodeAsync: userInfo => dispatch(userActionAsync.phoneVerifyCode(userInfo)),
    getRepeatSendPhoneCodeAsync: () => dispatch(userActionAsync.getRepeatSendPhoneCode()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePhone);
