import { Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';

const CreateCellComponent = selectionOffFlag => (props) => {
  const { row, style, ...rest } = props;
  const modifiedStyle = { ...style, padding: 0 };

  const refundSelectionOffFlag = row[selectionOffFlag] !== 0;

  return selectionOffFlag === undefined || row[selectionOffFlag] === true || refundSelectionOffFlag
    ? <TableSelection.Cell {...rest} style={modifiedStyle} />
    : <Table.StubCell {...rest} style={modifiedStyle} />;
};

export default CreateCellComponent;
