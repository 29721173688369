// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const partnerActions = {
  approveInfoFieldValues(approve) {
    return getActionCreator(types.APPROVE_INFO_FIELD_VALUES, approve);
  },
  approveInfoField(approve) {
    return getActionCreator(types.APPROVE_INFO_FIELD, approve);
  },
  mergePartnerSave(data) {
    return getActionCreator(types.MERGE_PARTNER_SAVE, data);
  },
  mergePartnerIsTop(data) {
    return getActionCreator(types.MERGE_PARTNER_IS_TOP, data);
  },
  mergePartnerCreateNew(data) {
    return getActionCreator(types.MERGE_PARTNER_CREATE_NEW, data);
  },
  approveCategoriesPercent(approve) {
    return getActionCreator(types.APPROVE_CATEGORIES_PERCENT, approve);
  },
  setPartnerInfo(partnerInfo) {
    return getActionCreator(types.SET_PARTNER_INFO, partnerInfo);
  },
  setPartnersList(partnerList) {
    return getActionCreator(types.SET_PARTNERS_LIST, partnerList);
  },
  setStatusesList(statuses) {
    return getActionCreator(types.SET_STATUSES_LIST, statuses);
  },
  setStatusesListPending(isLoading) {
    return getActionCreator(types.SET_STATUSES_LIST_PENDING, isLoading);
  },
  setPartnersListSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PARTNERS_LIST_SETTING_STATUS, settingsStatus);
  },
  setPartnersListSettingsPending(settingPending) {
    return getActionCreator(types.SET_PARTNERS_LIST_SETTING_PENDING, settingPending);
  },
  setPartnerHashId(hashId) {
    return getActionCreator(types.SET_PARTNER_HASH_ID, hashId);
  },
  setPartnerContractLink(link) {
    return getActionCreator(types.SET_PARTNER_CONTRACT_LINK, link);
  },
  setPartnerContractPage(page) {
    return getActionCreator(types.SET_PARTNER_CONTRACT_PAGE, page);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PARTNERS_CURRENT_PAGE, currentPage);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PARTNERS_PAGE_SIZE, pageSize);
  },
  changeSorting(sorting) {
    return getActionCreator(types.CHANGE_PARTNERS_SORTING, sorting);
  },
  changeFilters(filters) {
    return getActionCreator(types.CHANGE_PARTNERS_FILTERS, filters);
  },
  resetFilters() {
    return getActionCreator(types.RESET_PARTNERS_FILTERS);
  },
  setPartnersDownloadPending(isLoading) {
    return getActionCreator(types.SET_PARTNERS_DOWNLOAD_PENDING, isLoading);
  },
  setPartnerDownloadPending(isLoading) {
    return getActionCreator(types.SET_PARTNER_DOWNLOAD_PENDING, isLoading);
  },
  setPartnerContractCommissionDownloadPending(isLoading) {
    return getActionCreator(types.SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING, isLoading);
  },
  setPartnerInitialState(initialState) {
    return getActionCreator(types.SET_PARTNERS_INITIAL_STATE, initialState);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_PARTNER_SELECTION, selection);
  },
  setPartnerContextList(partnerList) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_LIST, partnerList);
  },
  setPartnerContextBillingPaymentForPartnerPending(payload) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_PENDING, payload);
  },
  setPartnerContextListForServiceRequests(payload) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS, payload);
  },
  setPartnerContextListForServiceRequestsPending(payload) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_LIST_FOR_SERVICE_REQUESTS_PENDING, payload);
  },
  setPartnerContextBillingPaymentForPartnerList(payload) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_BILLING_PAYMENT_FOR_PARTNER_LIST, payload);
  },
  setPartnerContextPending(status) {
    return getActionCreator(types.SET_PARTNER_CONTEXT_PENDING, status);
  },
  setPartnerListContext(partnerContextList) {
    return getActionCreator(types.SET_PARTNER_LIST_CONTEXT, partnerContextList);
  },
  setUsersContext(payload) {
    return getActionCreator(types.SET_USERS_LIST_CONTEXT, payload);
  },
  setUsersContextPending(payload) {
    return getActionCreator(types.SET_USERS_LIST_CONTEXT_PENDING, payload);
  },
  setUsersContextAndRole(payload) {
    return getActionCreator(types.SET_USERS_LIST_CONTEXT_AND_ROLE, payload);
  },
  mergePartnerSendContractToSed(payload) {
    return getActionCreator(types.MERGE_PARTNER_SEND_CONTRACT_TO_SED, payload);
  },
  setBusinessesList(payload) {
    return getActionCreator(types.SET_BUSINESSES_LIST, payload);
  },
  setBusinessesListPending(payload) {
    return getActionCreator(types.SET_BUSINESSES_LIST_PENDING, payload);
  },
};
