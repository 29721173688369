// Core
import { apply, put } from 'redux-saga/effects';

// actions
import { actions as partnerActions } from '../../actions';
import { partnerAsyncAction } from '../../../_partners/saga/asyncAction';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

// config
import { api, requestStatus } from '../../../../config/api';

export function* callPutPartnersProcessWorker({ payload: hashId }) {
  yield put(partnerActions.mergePartnersProcess({ pending: true }));

  const response = yield apply(api, api.partner.putPartnersProcess, [{ hashId }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(partnerAsyncAction.getPartnerInfo({ hashId }));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(partnerActions.mergePartnersProcess({ pending: false }));
}
