// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Instruments
import { types } from './types';

export const billingActions = Object.freeze({
  setBillingStatus(billingStatus) {
    return getActionCreator(types.SET_BILLING_STATUS, billingStatus);
  },
  setCreditLimitModalStatus(status) {
    return getActionCreator(types.SET_CREDIT_LIMIT_MODAL_STATUS, status);
  },
  setCreditLimitModalOffStatus(status) {
    return getActionCreator(types.SET_CREDIT_LIMIT_MODAL_OFF_STATUS, status);
  },
  mergeBillingInvoiceTypes(data) {
    return getActionCreator(types.MERGE_IN_BILLING_INVOICE_TYPES, data);
  },
  mergeBillingReportDownload(data) {
    return getActionCreator(types.MERGE_BILLING_REPORT_DOWNLOAD, data);
  },
  setBillingPartnersDownloadPending(isLoading) {
    return getActionCreator(types.SET_BILLING_PARTNERS_DOWNLOAD_PENDING, isLoading);
  },
  setBillingStatusesList(billingStatusesList) {
    return getActionCreator(types.SET_BILLING_STATUSES_LIST, billingStatusesList);
  },
  mergeBillingInvoiceModal(data) {
    return getActionCreator(types.SET_BILLING_INVOICE_MODAL, data);
  },
  setBillingPaymentTypes(billingPaymentTypes) {
    return getActionCreator(types.SET_BILLING_PAYMENT_TYPES, billingPaymentTypes);
  },
  setBillingOrdersDashboardData(data) {
    return getActionCreator(types.SET_BILLING_ORDERS_DASHBOARD_DATA, data);
  },
  setBillingOnTimeOrdersDashboardData(data) {
    return getActionCreator(types.SET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA, data);
  },
  setBillingNewOrdersDashboardData(data) {
    return getActionCreator(types.SET_BILLING_NEW_ORDERS_DASHBOARD_DATA, data);
  },
  setBillingNewOrdersDashboardDataLoading(data) {
    return getActionCreator(types.SET_BILLING_NEW_ORDERS_DASHBOARD_DATA_LOADING, data);
  },
  setBillingDashboardsLastTransactions(data) {
    return getActionCreator(types.SET_BILLING_DASHBOARD_LAST_TRANSACTIONS, data);
  },
  setIsBillingDashboardsLastTransactionsLoading(isLoading) {
    return getActionCreator(types.SET_IS_BILLING_DASHBOARD_LAST_TRANSACTIONS_LOADING, isLoading);
  },
  setBillingSelectFilterPartners(data) {
    return getActionCreator(types.SET_BILLING_SELECT_FILTER_PARTNER, data);
  },
  setBillingSuccessDeliveryDashboardData(data) {
    return getActionCreator(types.SET_BILLING_SUCCESS_DELIVERY_DASHBOARD_DATA, data);
  },
  setRatingStatus(data) {
    return getActionCreator(types.SET_RATING_STATUS, data);
  },
});
