// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';

// helpers
import { formName, formFields } from '../../../../../../ui/page/StopList/Words/components/WordPage/form';

export function* callGetStopWordHashIdWorker({ payload }) {
  yield put(actions.mergeGetStopWord({ pending: true }));

  const response = yield apply(api, api.stopLists.getStopWordHashId, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const {
      phrase, categories, excludeWords, excludePartners,
    } = data;
    const fixedPartners = excludePartners.map(item => ({ value: item.value, label: item.label }));
    const fixedExcludeWords = excludeWords && excludeWords.map(item => (
      { value: item }
    ));
    yield put(initialize(formName, {
      [formFields.word]: phrase,
      [formFields.category]: categories,
      [formFields.excludePartners]: fixedPartners,
      [formFields.excludeWords]: fixedExcludeWords,
    }, [formFields.word, formFields.category, formFields.excludeWords, formFields.excludePartners]));
    yield put(actions.setStopWordHashId(data));
  }

  yield put(actions.mergeGetStopWord({ pending: false }));
}
