// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Form, Field, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import get from 'lodash/get';
import Map from 'lodash/map';
// Components
import CategoriesSelect from '../../../../components/_Form/Selects/CategoriesSelect';
import BrandsSelect from '../../../../components/_Form/Selects/BrandsSelect';
import PartnerSelect from '../../../../components/_Form/Selects/PartnerSelect';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import Loading from '../../../../components/Loading/Loading';
// Engine
import { asyncActions } from '../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
// Helpers
import { formFields, formName } from '../formFields';

function BrandCategoryForm(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hashId } = useParams();
  const editPending = useSelector(selectors.stopListBrandsCategories.editPending);

  const onFormSubmit = (formData) => {
    if (formData && formData.toJS) {
      const normalizeFormData = formData.toJS();
      const data = {
        category: get(normalizeFormData[formFields.category], 'value'),
        excludePartners: Map(normalizeFormData[formFields.excludePartners], item => item.value),
        brand: get(normalizeFormData[formFields.brand], 'value'),
      };
      dispatch(asyncActions.putItemAsync({ data, hashId }));
    }
  };

  if (editPending) {
    return <Loading isLoading />;
  }

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Field
        withClear
        formName={formName}
        component={CategoriesSelect}
        name={formFields.category}
        margin="normal"
        label={t('Категория товара')}
      />
      <Field
        formName={formName}
        name={formFields.excludePartners}
        component={PartnerSelect}
        partnerListSelector={selectors.partners.listWithMid}
        isMulti
        validation
        margin="normal"
        label={t('Партнеры-исключения')}
      />
      <Field
        component={BrandsSelect}
        withClear
        name={formFields.brand}
        margin="normal"
        label={t('Бренд товара')}
      />
      <Field
        component={TextField}
        disabled
        fullWidth
        name={formFields.createdAt}
        margin="normal"
        label={t('Дата добавления')}
      />
      <Field
        component={TextField}
        disabled
        fullWidth
        margin="normal"
        name={formFields.updatedAt}
        label={t('Дата обновления')}
      />
    </Form>
  );
}

BrandCategoryForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(BrandCategoryForm);
