// Core
import React, {
  useCallback, useEffect, memo,
} from 'react';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Select from '../../../../../components/_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../engine/core/contentProduct/saga/asyncActions';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { pageLinks } from '../../../../../../engine/config/routes';
import { useStyles } from '../../../../../hooks/useStyles';

const CategoriesSelectStyles = () => ({
  categoriesSelect: {
    width: '210px',
  },
});

function ProductsCategoriesSelect() {
  const { t } = useTranslation();
  const classes = useStyles(CategoriesSelectStyles);
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList);
  const isLoading = useSelector(selectors.contentProduct.isCategoriesListLoading);
  const selected = useSelector(selectors.contentProduct.productCategoryActiveItem)?.toJS();
  const isCategoriesSelectOpen = useSelector(selectors.ui.isSelectOpen).toJS().categories;
  const selectValue = selected?.value;

  const handleChangeCategory = useCallback(({ value }) => {
    dispatch(actions.setFilters([]));
    const activeProductCategory = categoriesList.find(category => category.value === value);
    if (activeProductCategory?.value) {
      dispatch(push(pageLinks.productsAndPrices.content.byCategoryId(value)));
    } else {
      dispatch(push(pageLinks.productsAndPrices.content.all));
    }
  }, [categoriesList, categoryId]);

  useEffect(() => {
    dispatch(asyncActions.getContentCategoriesListAsync({
      categoryId: parseInt(categoryId, 10),
      context: 'select',
    }));
  }, [categoryId]);

  const categoriesListToJS = categoriesList.toJS();
  const optionsData = [{ ...categoriesListToJS[0], label: t('Все категории') }, ...categoriesList.toJS().slice(1)];

  return (
    <Select
      handleChange={handleChangeCategory}
      options={optionsData}
      id="categories_select"
      isLoading={isLoading}
      value={selectValue}
      filterValueBy="value"
      label={t('Выберите категорию')}
      closeMenuOnSelect
      wrapperClassName={classes.categoriesSelect}
      searchErrorLabel={t('Неверно введена категория')}
      variant="outlined"
      placeholder=""
      hasSearch
      isSelectOpen={isCategoriesSelectOpen}
    />
  );
}

export default memo(ProductsCategoriesSelect);
