// core
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import TabsStyles from './TabsStyles';
import { useStyles } from '../../hooks/useStyles';

function TabsNavMenu(props) {
  const {
    children, onChange, value, id,
  } = props;
  const classes = useStyles(TabsStyles);

  return (
    <Tabs
      id={id}
      onChange={onChange}
      value={value}
      orientation="vertical"
      className={classes.tabsNavMenu}
    >
      {children}
    </Tabs>
  );
}


TabsNavMenu.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default TabsNavMenu;
