import { getIn, List, Map } from 'immutable';
// Store keys
import { stateKeys as categoriesFeaturesItemKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const categoriesFeaturesItem = Object.freeze({
  currentPage: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.currentPage], 0),
  filters: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.filters], List()),
  isLoading: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.pending], false),
  list: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.items], List()),
  categoryName: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.categoryName], List()),
  pageSize: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.reload], false),
  totalCount: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.totalCount], 0),
  sorting: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.sorting], List()),
  settingStatus: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.settingStatus], false),
  settingPending: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.categoriesFeaturesItemList, categoriesFeaturesItemKeys.settingPending], false),
  featureRequiresLK: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.featureRequiresLK, categoriesFeaturesItemKeys.items], Map()),
  isTouched: state => getIn(state, [categoriesFeaturesItemKeys.controller, categoriesFeaturesItemKeys.featureRequiresLK, categoriesFeaturesItemKeys.isTouched], Map()),
});
