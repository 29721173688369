// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ErrorFilledIcon(props) {
  return (
    <SvgIcon className="error-filled-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="6" transform="rotate(-180 8 8)" fill="inherit" />
      <path d="M7.99984 11.3333C8.36744 11.3333 8.6665 11.0341 8.6665 10.6662C8.6665 10.2987 8.36744 9.99971 7.99984 9.99971C7.63223 9.99971 7.33317 10.2987 7.33317 10.6662C7.33317 11.0341 7.63223 11.3333 7.99984 11.3333Z" fill="white" stroke="white" strokeWidth="0.3" />
      <path d="M7.99984 8.66669C8.36802 8.66669 8.6665 8.44282 8.6665 8.16669L8.6665 5.16669C8.6665 4.89055 8.36802 4.66669 7.99984 4.66669C7.63166 4.66669 7.33317 4.89055 7.33317 5.16669L7.33317 8.16669C7.33317 8.44282 7.63166 8.66669 7.99984 8.66669Z" fill="white" stroke="white" strokeWidth="0.3" />
    </SvgIcon>
  );
}

export default ErrorFilledIcon;
