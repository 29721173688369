// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function OrderStatisticMarginIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.4974 6.09334C17.6463 6.1553 17.7813 6.24627 17.8947 6.36094C18.0082 6.47421 18.0982 6.60893 18.1592 6.75727C18.2202 6.90561 18.2511 7.0646 18.2501 7.225V10.5883C18.2495 10.8088 18.1619 11.0202 18.0062 11.1764L11.2892 17.8921C11.0594 18.1215 10.748 18.2503 10.4233 18.2503C10.0985 18.2503 9.7871 18.1215 9.55728 17.8921L6.35807 14.6929C6.12878 14.4631 6 14.1518 6 13.8272C6 13.5026 6.12878 13.1912 6.35807 12.9614L13.0778 6.24309C13.2343 6.08776 13.4457 6.00041 13.6662 6H17.0276C17.1888 5.99964 17.3485 6.03137 17.4974 6.09334ZM15.139 9.35254C15.2829 9.44868 15.4521 9.5 15.6251 9.5C15.8572 9.5 16.0798 9.40782 16.2439 9.24372C16.4079 9.07963 16.5001 8.85707 16.5001 8.625C16.5001 8.45194 16.4488 8.28277 16.3527 8.13888C16.2565 7.99499 16.1199 7.88283 15.96 7.81661C15.8001 7.75038 15.6242 7.73305 15.4544 7.76682C15.2847 7.80058 15.1288 7.88391 15.0064 8.00628C14.884 8.12866 14.8007 8.28457 14.7669 8.4543C14.7332 8.62403 14.7505 8.79997 14.8167 8.95985C14.883 9.11974 14.9951 9.25639 15.139 9.35254Z" fill="#A59731" />
    </SvgIcon>
  );
}

export default OrderStatisticMarginIcon;
