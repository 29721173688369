// Core
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { isChrome, isWindows } from 'react-device-detect';
// Parts
import GlobalStyles from '@mui/material/GlobalStyles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {
  productGuide, sendProductToModerationGuide, productEditGuide,
  productsPriceUploadGuide, productsPriceExportGuide, productsMultipleEditGuide,
} from './components/ProductsContent/guide';
import {
  importLinkXML, importProductManual,
  importXLSX, importFileXML,
} from './components/Import/guide';
import {
  productsContentCallback, sendToModerationCallback,
  productEditCallback, productsPriceUploadCallback, productsPriceExportCallback, productsMultipleEditCallback,
} from './components/ProductsContent/productsContentCallback';
import { importLinkXmlCallback } from './components/Import/importLinkXmlCallback';
import { importXlsxCallback } from './components/Import/importXlsxCallback';
import { importProductManualCallback } from './components/Import/importProductManualCallback';
import { importFileXmlCallback } from './components/Import/importFileXmlCallback';
import BackdropOverlay from '../../../../components/BackdropOverlay/BackdropOverlay';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { uiActions } from '../../../../../engine/core/ui/action';
import { history } from '../../../../../engine/init/store';
// Style
import { GUIDE_TYPES } from './types';
import {
  StepsContainer, Step, TooltipBody, TooltipTitle,
  TooltipContent, TooltipFooter, IconButtonStyled,
  TooltipTitleText, StepHolder, TooltipText,
} from './styles';
import { COLOR } from '../../AppStyles';
// Hooks
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../engine/config/userRoles';
import { useDownLGHeightView, useDownXLView, useMDView } from '../../../../hooks/useDesktopView';
import { useGuideTourRef } from '../../../Context/refContext';

function MobileStepper({
  activeStep, steps, onStepClick, isDownLGHeightView,
}) {
  return (
    <StepsContainer isDownLGHeightView={isDownLGHeightView}>
      {Array.from(Array(steps).keys()).map(step => (
        <StepHolder>
          <Step key={step} onClick={() => onStepClick(step)} active={activeStep === step} />
        </StepHolder>
      ))}
    </StepsContainer>
  );
}

function GuideTour() {
  const isRun = useSelector(selectors.ui.isJoyrideRun);
  const indexStep = useSelector(selectors.ui.joyrideStepIndex);
  const joyrideSteps = useSelector(selectors.ui.joyrideSteps);
  const isOpenBackdropOverlay = useSelector(selectors.ui.isOpenBackdropOverlay);
  const dispatch = useDispatch();
  const isMD = useMDView();
  const hasAdminAccess = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.contentManager,
  ]);
  const isDownXLView = useDownXLView();
  const isDownLGHeightView = useDownLGHeightView();
  const guideTourRef = useGuideTourRef();

  const closeGuide = () => {
    dispatch(uiActions.setJoyrideRun(false));
    dispatch(uiActions.setJoyrideStepIndex(0));
  };

  useEffect(() => {
    if (isRun && isMD) {
      closeGuide();
    }
  }, [isMD, isRun]);

  useEffect(() => {
    history.listen(({ action }) => {
      if (action === 'POP') {
        closeGuide();
      }
    });
  }, []);

  const Tooltip = (props) => {
    const {
      continuous,
      index,
      isLastStep,
      step,
      tooltipProps,
      closeProps,
      size,
    } = props;
    const { t } = useTranslation();

    return (
      <Box>
        <TooltipBody component="div" {...tooltipProps}>
          {step.title
            && (
              <TooltipTitle component="div">
                <TooltipTitleText>{step.title}</TooltipTitleText>
                <IconButtonStyled
                  {...closeProps}
                  aria-label="Close"
                  size="small"
                >
                  <CloseIcon />
                </IconButtonStyled>
              </TooltipTitle>
            )}
          {step.content
            && (
              <TooltipContent isDownLGHeightView={isDownLGHeightView}>
                <TooltipText component="p">
                  {step.content}
                </TooltipText>
                <MobileStepper
                  steps={size}
                  activeStep={index}
                  onStepClick={(currentStep) => {
                    dispatch(uiActions.setJoyrideStepIndex(currentStep));
                  }}
                  isDownLGHeightView={isDownLGHeightView}
                />
              </TooltipContent>
            )}
          <TooltipFooter>
            <Button
              disabled={index < 1}
              variant="outlined"
              id="back"
              onClick={() => dispatch(uiActions.setJoyrideStepIndex(index - 1))}
              sx={{
                mr: '8px',
                '&.Mui-disabled': {
                  backgroundColor: 'transparent',
                  color: COLOR.grey['24'],
                  borderColor: COLOR.grey['24'],
                },
              }}
            >
              {t('Назад')}
            </Button>
            <Button
              variant="contained"
              id="next"
              onClick={() => dispatch(uiActions.setJoyrideStepIndex(index + 1))}
              disabled={continuous && isLastStep}
            >
              {t('Далее')}
            </Button>
          </TooltipFooter>
        </TooltipBody>
      </Box>
    );
  };

  const currentSteps = (type) => {
    switch (type) {
      case GUIDE_TYPES.IMPORT_XML:
        return importLinkXML();
      case GUIDE_TYPES.IMPORT_XLSX:
        return importXLSX({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_LINK_XML:
        return importLinkXML({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_FILE_XML:
        return importFileXML({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_ADD_PRODUCT_MANUAL:
        return importProductManual({ isDownXLView });
      case GUIDE_TYPES.PRODUCTS_CONTENT:
        return productGuide({ isDownXLView });
      case GUIDE_TYPES.SEND_TO_MODERATION:
        return sendProductToModerationGuide();
      case GUIDE_TYPES.PRODUCTS_EDIT:
        return productEditGuide();
      case GUIDE_TYPES.PRODUCTS_UPLOAD_PRICE:
        return productsPriceUploadGuide();
      case GUIDE_TYPES.PRODUCTS_EXPORT_PRICE:
        return productsPriceExportGuide();
      case GUIDE_TYPES.PRODUCTS_MULTIPLE_EDIT:
        return productsMultipleEditGuide();
      default:
        return [];
    }
  };
  const currentCallback = (type) => {
    switch (type) {
      case GUIDE_TYPES.IMPORT_XLSX:
        return importXlsxCallback({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_LINK_XML:
        return importLinkXmlCallback({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_FILE_XML:
        return importFileXmlCallback({ hasAdminAccess });
      case GUIDE_TYPES.IMPORT_ADD_PRODUCT_MANUAL:
        return importProductManualCallback;
      case GUIDE_TYPES.PRODUCTS_CONTENT:
        return productsContentCallback;
      case GUIDE_TYPES.SEND_TO_MODERATION:
        return sendToModerationCallback;
      case GUIDE_TYPES.PRODUCTS_EDIT:
        return productEditCallback(guideTourRef);
      case GUIDE_TYPES.PRODUCTS_UPLOAD_PRICE:
        return productsPriceUploadCallback;
      case GUIDE_TYPES.PRODUCTS_EXPORT_PRICE:
        return productsPriceExportCallback;
      case GUIDE_TYPES.PRODUCTS_MULTIPLE_EDIT:
        return productsMultipleEditCallback;
      default:
        return [];
    }
  };
  const state = {
    isRun,
    steps: currentSteps(joyrideSteps),
    stepIndex: indexStep,
  };

  return (
    <Box>
      <GlobalStyles
        styles={{
          '@keyframes opacityEffect': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
          '.__floater.__floater__open': {
            filter: 'drop-shadow(rgba(0,0,0,.0144) 0 0 3px) !important',
          },
        }}
      />
      <Joyride
        continuous
        callback={currentCallback(joyrideSteps)}
        run={isRun}
        scrollToFirstStep
        steps={state.steps}
        scrollDuration={350}
        stepIndex={state.stepIndex}
        tooltipComponent={Tooltip}
        scrollOffset={100}
        disableScrollParentFix
        spotlightPadding={3}
        styles={{
          options: {
            zIndex: 1500,
          },
          overlay: {
            height: '100vh',
            backgroundColor: isChrome && isWindows ? 'transparent' : 'rgba(0, 0, 0, 0.35)',
            mixBlendMode: isChrome && isWindows ? 'unset' : 'hard-light',
          },
          spotlight: {
            borderRadius: '5px',
            backgroundColor: isChrome && isWindows ? 'transparent' : 'rgba(217, 217, 217, .40)',
            boxShadow: isChrome && isWindows ? '0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5)' : undefined,
            animation: 'opacityEffect .5s',
          },
        }}
        floaterProps={{
          styles: {
            arrow: {
              length: 8,
              spread: 16,
            },
          },
        }}
        locale={{
          close: '',
        }}
      />
      <BackdropOverlay isOpen={isOpenBackdropOverlay} />
    </Box>
  );
}

export default GuideTour;
