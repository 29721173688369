// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { pageLinks } from '../../../../config/routes';
import { userActions } from '../../action';
import { userActionAsync } from '../asyncAction';
import { selectors } from '../../../../config/selectors';
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPostUserInfoWorker({ payload: { lang } }) {
  const pathname = yield select(selectors.router.pathname);
  yield put(userActions.mergePostUserInfo({ pending: true }));
  const response = yield apply(api, api.user.putUserInfo, [{ locale: lang }]);
  if (response && response.status >= 200 && response.status < 400) {
    if (response.data) {
      const { status, statusTitle } = response.data;
      switch (status) {
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle, true));
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  if (pathname === pageLinks.dashboard.all) {
    yield put(userActionAsync.getUserInfoAsync());
  }

  yield put(userActions.mergePostUserInfo({ pending: false }));
}
