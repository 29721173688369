// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field, Form, reduxForm, submit,
} from 'redux-form/immutable';

// UI
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WarningIcon from '@mui/icons-material/Warning';
// parts
import FilesUploaderWithValidation from '../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import Modal from '../../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/orders/actions';
import { asyncActions } from '../../../../../engine/core/orders/saga/asyncActions';
// Hooks
import { useStyles } from '../../../../hooks/useStyles';

// helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { formFields, uploadModalReduxFormName } from './form';

// styles
import UploadCsvModalStyle from './UploadCsvModalStyle';

function UploadCsvModal(props) {
  const { handleSubmit } = props;
  const classes = useStyles(UploadCsvModalStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordersUploadIsUploading = useSelector(selectors.orders.ordersUploadIsUploading);
  const ordersUploadSuccess = useSelector(selectors.orders.ordersUploadSuccess);
  const ordersUploadError = useSelector(selectors.orders.ordersUploadError);
  const isModalOpenOrdersUpload = useSelector(selectors.orders.isModalOpenOrdersUpload);

  if (!isModalOpenOrdersUpload) {
    return null;
  }

  const handleModalUploadToggle = () => {
    dispatch(actions.mergeOrdersUpload({
      modalOpen: !isModalOpenOrdersUpload,
    }));
  };

  const handleModalUploadStatus = () => {
    dispatch(submit(uploadModalReduxFormName));
  };

  const onSubmit = (formData) => {
    const jsFormData = formData.toJS();
    const file = jsFormData[formFields.ordersFile];

    dispatch(asyncActions.postOrdersUpload({ files: file, format: 'csv' }));
  };

  return (
    <Modal
      handleModalToggle={handleModalUploadToggle}
      handleModalCancel={handleModalUploadToggle}
      handleModalSend={handleModalUploadStatus}
      loadingSend={ordersUploadIsUploading}
      disabledSend={ordersUploadIsUploading}
      modalOpen={isModalOpenOrdersUpload}
      title={t('Загрузка CSV')}
      sendText={t('Отправить')}
      modalMaxWidth="xs"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FilesUploaderWithValidation}
          textAfter={t('или перетащите его сюда')}
          name={formFields.ordersFile}
          allowFileTypeValidation
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
      {ordersUploadIsUploading && <LinearProgress />}
      {(ordersUploadSuccess !== '') && (
        <div className={classes.messageTitle}>
          <DoneAllIcon className={classes.iconDone} />
          <span>{ordersUploadSuccess}</span>
        </div>
      )}
      {(ordersUploadError.length > 0) && (
        <div className={classes.messageTitle}>
          <WarningIcon className={classes.iconWarning} />
          <span>{t('Ошибка импорта')}:</span>
          <ul className={classes.listWrapper}>
            {ordersUploadError.map(({ message }, idx) => (
              <li key={idx}>
                { message }
              </li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  );
}

export default reduxForm({
  form: uploadModalReduxFormName,
})(UploadCsvModal);
