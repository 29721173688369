// Core
import { put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { selectors } from '../../../../../config/selectors';
import { actions } from '../../actions';

export function* callSetFeedsFiltersWorker(settings) {
  const columnsObjectLike = {};
  const columns = get(settings, 'productFeed.gridSettings', []);

  columns.reduce((acc, item) => {
    const { name, visible } = item;
    acc[name] = visible;
    return acc;
  }, columnsObjectLike);

  const filters = yield select(selectors.feeds.filters);
  const sorting = yield select(selectors.feeds.sorting);

  const filteredFilters = filters.toJS().filter((item) => {
    const key = get(item, 'columnName');
    return columnsObjectLike[key];
  });

  const filteredSorting = sorting.toJS().filter((item) => {
    const key = get(item, 'columnName');
    return columnsObjectLike[key];
  });

  yield put(actions.setFeedsFilters(filteredFilters));
  yield put(actions.setFeedsSorting(filteredSorting));
}
