// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetPartnerAccessInfoWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPutPartnerAccessWorker,
  callPutPartnerAccessCredentialsWorker,
  callGetPartnerAccessCredentialsWorker,
  callDeletePartnerAccessCredentialsWorker,
  callPostUpdateWFPWorker,
  callDeletePartnerAccessWorker,
  callGetPartnersAccessDownloadWorker,
  callGetPartnersPaymentMethodsWorker,
  callPostPartnersPaymentAccessesWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PARTNERS_ACCESS_LIST_ASYNC, callGetListWorker);
}
function* watchSetPartnersPaymentAccessesPending() {
  yield takeEvery(asyncTypes.POST_PARTNERS_PAYMENT_ACCESSES_ASYNC, callPostPartnersPaymentAccessesWorker);
}
function* watchGetPartnersPaymentMethods() {
  yield takeEvery(asyncTypes.GET_PARTNERS_PAYMENT_METHODS_ASYNC, callGetPartnersPaymentMethodsWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_ACCESS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetPartnerAccessInfo() {
  yield takeEvery(asyncTypes.GET_PARTNER_ACCESS_INFO_ASYNC, callGetPartnerAccessInfoWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PARTNERS_ACCESS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutPartnerAccess() {
  yield takeEvery(types.PUT_PARTNER_ACCESS_ASYNC, callPutPartnerAccessWorker);
}
function* watchPutPartnerAccessCredentials() {
  yield takeEvery(asyncTypes.PUT_PARTNER_ACCESS_CREDENTIALS_ASYNC, callPutPartnerAccessCredentialsWorker);
}
function* watchGetPartnerAccessCredentials() {
  yield takeEvery(asyncTypes.GET_PARTNER_ACCESS_CREDENTIALS_ASYNC, callGetPartnerAccessCredentialsWorker);
}
function* watchDeletePartnerAccessCredentials() {
  yield takeEvery(asyncTypes.DELETE_PARTNER_ACCESS_CREDENTIALS_ASYNC, callDeletePartnerAccessCredentialsWorker);
}
function* watchDeletePartnerAccess() {
  yield takeEvery(asyncTypes.DELETE_PARTNER_ACCESS_ASYNC, callDeletePartnerAccessWorker);
}
function* watchPostPartnerAccessUpdateWfp() {
  yield takeEvery(asyncTypes.POST_PARTNERS_ACCESS_UPDATE_WFP_ASYNC, callPostUpdateWFPWorker);
}

function* watchGetPartnersAccessDownload() {
  yield takeEvery(asyncTypes.GET_PARTNERS_ACCESS_DOWNLOAD_ASYNC, callGetPartnersAccessDownloadWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PARTNERS_ACCESS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PARTNERS_ACCESS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PARTNERS_ACCESS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PARTNERS_ACCESS_SORTING, callSetToLocalDataWorker);
}

export function* watchersPartnersAccess() {
  yield all([
    watchGetList(),
    watchGetPartnerAccessInfo(),
    watchGetInitialState(),
    watchPutSettings(),
    watchGetPartnersPaymentMethods(),
    watchGetPartnersAccessDownload(),
    watchPostPartnerAccessUpdateWfp(),
    watchPutPartnerAccessCredentials(),
    watchGetPartnerAccessCredentials(),
    watchDeletePartnerAccessCredentials(),
    watchSetPartnersPaymentAccessesPending(),
    watchDeletePartnerAccess(),
    watchPutPartnerAccess(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
  ]);
}
