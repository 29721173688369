export const types = Object.freeze({
  SET_EMAIL_TEMPLATES_LIST: 'SET_EMAIL_TEMPLATES_LIST',
  SET_EMAIL_TEMPLATES_CURRENT_PAGE: 'SET_EMAIL_TEMPLATES_CURRENT_PAGE',
  SET_EMAIL_TEMPLATES_FILTERS: 'SET_EMAIL_TEMPLATES_FILTERS',
  SET_EMAIL_TEMPLATES_PAGE_SIZE: 'SET_EMAIL_TEMPLATES_PAGE_SIZE',
  SET_EMAIL_TEMPLATES_SORTING: 'SET_EMAIL_TEMPLATES_SORTING',
  SET_EMAIL_TEMPLATES_INITIAL_STATE: 'SET_EMAIL_TEMPLATES_INITIAL_STATE',
  SET_EMAIL_TEMPLATES_SETTING_STATUS: 'SET_EMAIL_TEMPLATES_SETTING_STATUS',
  SET_EMAIL_TEMPLATES_SETTING_PENDING: 'SET_EMAIL_TEMPLATES_SETTING_PENDING',
});
