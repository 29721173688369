// Core
import { apply } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { localData } from '../../../../../config/localData';
import { store } from '../../../../../init/store';

export function* callPutCompletionImportListWorker({ payload }) {
  const { hashId, data } = payload;

  const { completionImportList } = localData.keysObj;
  const completionImportListData = yield apply(localData, localData.getItem, [completionImportList]);

  yield apply(localData, localData.setItem,
    [
      completionImportList,
      { ...completionImportListData, [hashId]: data },
    ]);
  store.dispatch(actions.setCompletionImportList({ ...completionImportListData, [hashId]: data }));
}
