// Core
import React, {
  useCallback, useEffect, useMemo, useState, memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
// Parts
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Select from '../../../../../../../components/_Form/Selects/Select/Select';
import ConfirmButtons from '../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer
  from '../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
import { actions as contentActions } from '../../../../../../../../engine/core/contentProduct/actions';
import { asyncActions as contentAsyncActions } from '../../../../../../../../engine/core/content/saga/asyncActions';
import { userRoles } from '../../../../../../../../engine/config/userRoles';
// Helpers
import { useStyles } from '../../../../../../../hooks/useStyles';
import { useUserRole } from '../../../../../../../hooks/useUserRole';
import ContentProductStatusProviderStyles from './ContentProductStatusProviderStyles';

const REQUIRED_FIELDS = Object.freeze({
  cancelStatus: 'cancelStatus',
  note: 'note',
  productStatus: 'productStatus',
});

function ContentProductStatusFormatter(props) {
  const { column, row } = props;
  const classes = useStyles(ContentProductStatusProviderStyles);
  const columnName = column.name;
  const dispatch = useDispatch();
  const productId = get(row, 'id');
  const currentData = get(row, REQUIRED_FIELDS.productStatus, {});
  const productStatusValue = get(currentData, 'value');
  const productStatusColor = get(currentData, 'color');
  const valuesPlain = get(currentData, 'valuesPlain');
  const isRowEditable = get(row, 'editable', false);
  const isColumnEditable = get(column, 'editable', false);
  const isEditable = isRowEditable && isColumnEditable;
  const productStatusesFlow = useSelector(selectors.productStatusesFlow);
  const contentProductEditValue = (useSelector(selectors.contentProduct.editValue)).toJS();
  const contentProductEditValueCheckedType = contentProductEditValue?.[productId]?.type === REQUIRED_FIELDS.productStatus
    ? contentProductEditValue
    : undefined;
  const active = contentProductEditValueCheckedType?.[productId]?.active;
  const pending = contentProductEditValueCheckedType?.[productId]?.pending;
  const error = contentProductEditValueCheckedType?.[productId]?.error;
  const success = contentProductEditValueCheckedType?.[productId]?.success;
  const productEditStatus = contentProductEditValueCheckedType?.[productId]?.productStatus;
  const changedProductStatusValue = productEditStatus || productStatusValue;
  const productCategoryActiveItem = useSelector(selectors.contentProduct.productCategoryActiveItem);
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);
  const disableCancelStatusAndNote = pending || isPartnerActiveRole;
  const [productStatusOptions, setProductStatusOptions] = useState(
    !isNil(productStatusValue)
      ? productStatusesFlow.get(productStatusValue.toString(), List())
      : List(),
  );

  useEffect(() => {
    if (success) {
      dispatch(contentActions.mergeContentProductValue({
        productId,
        value: {
          note: undefined,
          productStatus: undefined,
          cancelStatus: undefined,
        },
      }));
    }
  }, [success]);

  useEffect(() => {
    setProductStatusOptions(productStatusesFlow.get(changedProductStatusValue.toString(), List()));
  }, [changedProductStatusValue, productStatusValue, productStatusesFlow]);

  const handleProductStatusChange = useCallback((ev) => {
    dispatch(contentActions.mergeContentProductValue({
      productId,
      value: {
        type: REQUIRED_FIELDS.productStatus,
        active: true,
        productStatus: ev.value,
      },
    }));
  }, [productId]);

  const handleProductActiveEditChange = useCallback((status) => {
    if (status) {
      dispatch(contentActions.mergeContentProductValue({
        productId,
        value: {
          type: REQUIRED_FIELDS.productStatus,
          active: status,
        },
      }));
    }
  }, [productId]);

  const currentItem = useMemo(() => {
    const findValue = productStatusOptions.toJS().find(item => item.value === changedProductStatusValue);
    if (isNil(findValue)) {
      return {
        additionalFields: [],
      };
    }
    return findValue;
  }, [productStatusOptions, changedProductStatusValue]);

  const additionalFields = useMemo(() => (
    get(currentItem, 'additionalFields', [])
  ), [currentItem]);

  const checkRequiredField = useCallback(field => (
    additionalFields.filter(item => item.required).map(item => item.name).includes(field)
  ), [additionalFields]);

  // Cancel status value
  const cancelStatusFeature = get(row, REQUIRED_FIELDS.cancelStatus, {});
  const cancelStatusValue = get(cancelStatusFeature, 'value');
  const newCancelStatusValue = contentProductEditValueCheckedType?.[productId]?.cancelStatus;
  const cancelStatuses = useSelector(selectors.allProductsTable.cancelStatuses);
  const changedCancelProductStatusValue = newCancelStatusValue || cancelStatusValue;
  const productCancelStatusColor = get(cancelStatusFeature, 'color');
  const handleProductCancelStatusChange = useCallback((cancelStatus) => {
    dispatch(contentActions.mergeContentProductValue({
      productId,
      value: {
        cancelStatus,
      },
    }));
  }, [productId]);

  // Note value
  const noteFeature = get(row, REQUIRED_FIELDS.note, {});
  const noteValue = noteFeature?.value || '';
  const newNote = contentProductEditValueCheckedType?.[productId]?.note;
  const handleNoteChange = useCallback((ev) => {
    dispatch(contentActions.mergeContentProductValue({
      productId,
      value: {
        note: ev.target.value,
      },
    }));
  }, [productId]);

  const onSelectConfirm = useCallback(() => {
    const cancelStatusInAdditionalField = additionalFields.filter(item => item.name === REQUIRED_FIELDS.cancelStatus).length;
    const noteInAdditionalField = additionalFields.filter(item => item.name === REQUIRED_FIELDS.note).length;
    const params = {
      categoryId: productCategoryActiveItem.get('value'),
      products: [productId],
      type: 'productStatus',
      productsValues: {
        [REQUIRED_FIELDS.productStatus]: changedProductStatusValue || productStatusValue,
      },
    };
    if (cancelStatusInAdditionalField) {
      params.productsValues[REQUIRED_FIELDS.cancelStatus] = newCancelStatusValue || cancelStatusValue;
    }
    if (noteInAdditionalField) {
      params.productsValues[REQUIRED_FIELDS.note] = newNote || noteValue;
    }

    dispatch(contentAsyncActions.putProductsAsync(params));
  }, [
    productCategoryActiveItem, productId, newCancelStatusValue, additionalFields,
    changedProductStatusValue, newNote, noteValue, cancelStatusValue,
  ]);

  const onReject = useCallback(() => {
    // If note value changed
    if (newNote !== noteValue) {
      dispatch(contentActions.mergeContentProductValue({
        productId,
        value: {
          note: undefined,
        },
      }));
    }
    // If cancel status value changed
    if (cancelStatusValue !== newCancelStatusValue) {
      dispatch(contentActions.mergeContentProductValue({
        productId,
        value: {
          cancelStatus: undefined,
        },
      }));
    }
    dispatch(contentActions.mergeContentProductValue({
      productId,
      value: {
        type: REQUIRED_FIELDS.productStatus,
        active: false,
        productStatus: undefined,
      },
    }));
  }, [newNote, noteValue, cancelStatusValue, newCancelStatusValue, productId]);

  switch (columnName) {
    case REQUIRED_FIELDS.productStatus: {
      const notEmpty = changedProductStatusValue !== null;
      return (
        <EditableContainer
          isEditable={isEditable}
          text={valuesPlain}
          color={productStatusColor}
          key={`${productId + active}productStatus`}
        >
          <ConfirmButtons
            color={productStatusColor}
            error={error}
            disabled={pending}
            notEmpty={notEmpty}
            onConfirm={onSelectConfirm}
            onReject={onReject}
            activeStatus={active}
            activeStatusAction={handleProductActiveEditChange}
            success={success}
            value={changedProductStatusValue}
            valuesPlain={valuesPlain}
          >
            <Select
              disabled={pending}
              handleChange={handleProductStatusChange}
              variant="outlined"
              defaultValue={currentItem}
              options={productStatusOptions}
            />
          </ConfirmButtons>
        </EditableContainer>
      );
    }
    case REQUIRED_FIELDS.cancelStatus: {
      const notEmpty = changedProductStatusValue !== null;
      const isCancelStatusFieldRequired = checkRequiredField(REQUIRED_FIELDS.cancelStatus);
      const cancelStatusFieldHasNoValue = isNil(newCancelStatusValue);
      const changedCancelStatusValuesPlainFromObject = get(cancelStatusFeature, 'valuesPlain', '');
      const isAdditionalField = additionalFields.filter(item => item.name === REQUIRED_FIELDS.cancelStatus).length;

      const isOKButtonDisabled = (
        isCancelStatusFieldRequired && cancelStatusFieldHasNoValue
        || cancelStatusValue === newCancelStatusValue
      );

      return (
        <EditableContainer
          isEditable={isEditable}
          text={changedCancelStatusValuesPlainFromObject}
          color={productCancelStatusColor}
          key={`${productId + active}cancelStatus`}
        >
          <ConfirmButtons
            color={productCancelStatusColor}
            error={error}
            disabled={pending}
            disabledOkButton={isOKButtonDisabled}
            notEmpty={notEmpty}
            onConfirm={onSelectConfirm}
            onReject={onReject}
            activeStatus={active}
            activeStatusAction={handleProductActiveEditChange}
            success={success}
            value={changedCancelProductStatusValue}
            valuesPlain={changedCancelStatusValuesPlainFromObject}
          >
            {isAdditionalField ? (
              <Select
                key={REQUIRED_FIELDS.cancelStatus}
                disabled={disableCancelStatusAndNote}
                handleChange={handleProductCancelStatusChange}
                variant="outlined"
                defaultValue={cancelStatusFeature}
                options={cancelStatuses}
              />
            ) : null}
          </ConfirmButtons>
        </EditableContainer>
      );
    }
    case REQUIRED_FIELDS.note: {
      const notEmpty = changedProductStatusValue !== null;
      const isAdditionalField = additionalFields.filter(item => item.name === REQUIRED_FIELDS.note).length;
      const isNoteFieldRequired = checkRequiredField(REQUIRED_FIELDS.note);
      const noteFieldHasNoValue = isNil(noteValue) || newNote?.trim() === '';
      const disableOkButton = isNoteFieldRequired ? noteFieldHasNoValue : newNote === undefined;
      const classNames = cx(classes.input, {
        [classes.disabled]: disableCancelStatusAndNote,
      });

      return (
        <EditableContainer
          isEditable={isEditable}
          text={noteValue}
          color={productCancelStatusColor}
          key={`${productId + active}note`}
        >
          <ConfirmButtons
            color={productCancelStatusColor}
            error={error}
            disabled={pending}
            disabledOkButton={disableOkButton}
            notEmpty={notEmpty}
            onConfirm={onSelectConfirm}
            onReject={onReject}
            activeStatus={active}
            activeStatusAction={handleProductActiveEditChange}
            success={success}
            value={newNote === undefined ? noteValue : newNote}
            valuesPlain={noteValue}
          >
            {isAdditionalField ? (
              <TextareaAutosize
                className={classNames}
                disabled={disableCancelStatusAndNote}
                key={REQUIRED_FIELDS.note}
                margin="none"
                onChange={handleNoteChange}
                maxRows={3}
                value={newNote === undefined ? noteValue : newNote}
                variant="outlined"
              />
            ) : null}
          </ConfirmButtons>
        </EditableContainer>
      );
    }
    default: {
      return null;
    }
  }
}

ContentProductStatusFormatter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  row: PropTypes.object.isRequired,
};

export default memo(ContentProductStatusFormatter);
