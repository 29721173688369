// Core
import isPlainObject from 'lodash/isPlainObject';
// Engine
import { Trans } from 'react-i18next';
import React from 'react';
import { uiActionAsync } from './asyncAction';

export const notificationTypes = Object.freeze({
  error: Object.freeze({
    defaultTitle: 'Error',
    type: 'danger',
  }),
  success: Object.freeze({
    defaultTitle: 'Success',
    type: 'success',
  }),
  warning: Object.freeze({
    defaultTitle: <Trans>Ошибка</Trans>,
    type: 'warning',
  }),
  info: Object.freeze({
    defaultTitle: 'Info',
    type: 'info',
  }),
});

function convertMessagesArrayToString(errors, uniqError) {
  if (uniqError) {
    const errorsArray = errors.map(obj => obj.message);
    const uniqueErrorsArraySet = new Set(errorsArray);
    return [...uniqueErrorsArraySet].reduce((acc, currentValue) => `${acc}${currentValue}<br />`, '');
  }
  if (isPlainObject(errors)) {
    return errors.message;
  }
  return errors.reduce((acc, currentValue) => `${acc}${currentValue.message}<br />`, '');
}

function setNotificationMessage(config) {
  const {
    message, title, type, duration = 5000,
  } = config;

  return uiActionAsync.setNotificationMessage({
    duration,
    message,
    title,
    type,
  });
}

export function setSuccessMessage(message, title = notificationTypes.success.defaultTitle) {
  return setNotificationMessage({
    duration: 3000,
    message,
    title,
    type: notificationTypes.success.type,
  });
}
export function setErrorMessage(errors, title = notificationTypes.error.defaultTitle, uniqError) {
  const message = typeof errors === 'object' ? convertMessagesArrayToString(errors, uniqError) : errors;
  return setNotificationMessage({
    message,
    title,
    type: notificationTypes.error.type,
  });
}

export function setWarningMessage(warnings, title = notificationTypes.warning.defaultTitle) {
  const message = Array.isArray(warnings) ? convertMessagesArrayToString(warnings) : warnings;
  return setNotificationMessage({
    message,
    title,
    type: notificationTypes.warning.type,
  });
}
