// Core
import React, {
  useEffect, useState, memo, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// lodash
import sortBy from 'lodash/sortBy';
// UI
import {
  TableColumnReordering as TableColumnReorderingUI,
} from '@devexpress/dx-react-grid-material-ui';
// Engine
import { userActions } from '../../../../../../engine/core/user/action';

function TableColumnReordering(props) {
  const { gridSettings, name, onOrderChange } = props;
  const [columnOrder, setColumnOrder] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setColumnOrder(gridSettings.map(column => column.name));
  }, [gridSettings]);


  const changeColumnPosition = useCallback((columnPosition) => {
    const newSortedColumn = sortBy(gridSettings.filter(item => item.isFeature !== true), item => columnPosition.indexOf(item.name));

    if (onOrderChange) {
      onOrderChange({ name, gridSettings: newSortedColumn });
    } else {
      dispatch(userActions.mergeUserSettings({ [name]: { gridSettings: newSortedColumn } }));
      setColumnOrder(newSortedColumn);
    }
  }, [gridSettings, name, onOrderChange]);

  return (
    <TableColumnReorderingUI
      order={columnOrder}
      onOrderChange={changeColumnPosition}
    />
  );
}

TableColumnReordering.propTypes = {
  name: PropTypes.string.isRequired,
  gridSettings: PropTypes.array.isRequired,
  onOrderChange: PropTypes.func,
};
export default memo(TableColumnReordering);
