// Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// UI
import DeleteIcons from '@mui/icons-material/Delete';

// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

// Engine
import { asyncActions } from '../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

function DeleteButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selection = useSelector(selectors.stopListBrandsCategories.selection);
  const deletePending = useSelector(selectors.stopListBrandsCategories.deletePending);
  const isAllowedUserDelete = useAccessList(accessList.stopBrandsCategoriesDelete);
  const stopBrandsCategoriesIds = selection.toJS();

  if (!isAllowedUserDelete) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  const onDelete = () => dispatch(asyncActions.deleteAsync({ stopBrandsCategoriesIds }));

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Удалить')}
      onClick={onDelete}
      isLoading={deletePending}
      disabled={deletePending || !stopBrandsCategoriesIds.length}
    >
      <DeleteIcons />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DeleteButton;
