// Core
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
// Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Helpers
import { validators } from '../../../../../../_helpers/validationsForm';
import PaymentTypesDocsModalStyles from '../PaymentTypesDocsModalStyles';

const useStyles = makeStyles(PaymentTypesDocsModalStyles);
const RenderFopOthersPropertyAccess = ({ fields, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const addHandler = () => fields.push({});

  return (
    <fieldset className={classes.fieldset}>
      <legend className={classes.legend}>{t('Ідентифікаційні дані осіб, які мають право розпоряджатися рахунками і майном(заповнюється у разі наявності законного представника ФОП на підставі нотаріальної довіреності)')}:</legend>
      <ul className={classes.fieldArrayList}>
        {fields.map((member, index) => {
          const removeHandler = () => fields.remove(index);
          return (
            <li key={index} className={classes.fieldArrayList_item}>
              <div>
                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item xs={6} md={6}>
                    <Field
                      name={`${member}.name`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      validate={[validators.required]}
                      required
                      validation
                      helperTextBefore={t('П.І.Б.')}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Field
                      name={`${member}.inn`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      validate={[validators.required]}
                      required
                      validation
                      helperTextBefore={t('Ідентифікаційний код')}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Field
                      name={`${member}.passport`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      validate={[validators.required]}
                      required
                      validation
                      helperTextBefore={t('Серія, номер паспорта, коли та ким виданий')}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Field
                      name={`${member}.addressDateOfBirth`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      validate={[validators.required]}
                      required
                      validation
                      helperTextBefore={t('Дата народження та місце проживання')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={`${member}.documentData`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      validate={[validators.required]}
                      required
                      validation
                      helperTextBefore={t('Дані про нотаріальну довіреність (дата видачі, строк дії, номер запису в нотаріальному реєстрі)')}
                    />
                  </Grid>
                </Grid>
              </div>
              {!disabled && (
                <Box className={classes.fieldArrayList_buttonHolder}>
                  <Button className={classes.fieldArrayList_item__button} onClick={removeHandler}>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              )}
            </li>
          );
        })}
      </ul>
      <Box display="flex">
        {!disabled && (
          <ButtonWithIcon text={t('Добавить')} className={classes.fieldArrayList_send_button} onClick={addHandler}>
            <AddCircleIcon />
          </ButtonWithIcon>
        )}
      </Box>
    </fieldset>
  );
};

RenderFopOthersPropertyAccess.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.object,
};

export default RenderFopOthersPropertyAccess;
