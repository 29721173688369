import { Link } from 'react-router-dom';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import React from 'react';
import Box from '@mui/material/Box';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';

const TableEditCell = (props) => {
  const { tableRow } = props;
  const hasAccessEdit = useAccessList(accessList.partnerServiceRequestsEdit);
  const style = { display: 'flex', justifyContent: 'center', gap: '8px' };
  if (!hasAccessEdit) {
    return;
  }

  return (
    <Box sx={style}>
      <Link to={pageLinks.shippingIntegration.edit(tableRow.row.partnerHash)}>
        <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
          <ModeOutlinedIcon sx={{ p: '2px' }} />
        </IconButtonStyles>
      </Link>
    </Box>
  );
};

export default TableEditCell;
