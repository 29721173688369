// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  partnersManagersList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    active: undefined,
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    isOpen: false,
  }),
  partnersManagersUpload: Map({
    pending: false,
    isModalOpen: false,
  }),
  partnerManagerDownloadPending: false,
  partnerManager: Map({
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  partnersManagersUpload: 'partnersManagersUpload',
  active: 'active',
  controller: 'partnersManagers',
  partnersManagersList: 'partnersManagersList',
  currentPage: 'currentPage',
  filters: 'filters',
  partnerManager: 'partnerManager',
  partnerManagerDownloadPending: 'partnerManagerDownloadPending',
  items: 'items',
  data: 'data',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  isOpen: 'isOpen',
});

export const partnersManagersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.MERGE_PARTNERS_MANAGERS_UPLOAD_STOP: {
      return state.mergeIn([stateKeys.partnersManagersUpload], payload);
    }
    case types.SET_PARTNERS_MANAGERS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.partnersManagersList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.partnersManagersList, stateKeys.pending], pending)
        .setIn([stateKeys.partnersManagersList, stateKeys.items], List(items))
        .setIn([stateKeys.partnersManagersList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PARTNERS_MANAGERS_SORTING: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.sorting], List(payload));
    }
    case types.MERGE_PARTNERS_MANAGERS_LIST: {
      return state.mergeIn([stateKeys.partnersManagersList], Map(payload));
    }
    case types.SET_PARTNERS_MANAGERS_DOWNLOAD_PENDING: {
      return state.setIn([stateKeys.partnerManagerDownloadPending], payload);
    }
    case types.SET_ACTIVE_PARTNERS_MANAGERS: {
      return state.withMutations((mutable) => {
        mutable
          .setIn([stateKeys.partnersManagersList, stateKeys.active], fromJS(payload));
      });
    }
    case types.SET_PARTNERS_MANAGERS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.partnersManagersList, stateKeys.filters], List(filters));
    }
    case types.SET_PARTNERS_MANAGERS_PAGE_SIZE: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.pageSize], payload);
    }
    case types.SET_PARTNERS_MANAGERS_CURRENT_PAGE: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.currentPage], payload);
    }
    case types.SET_PARTNERS_MANAGERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.partnersManagersList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.partnersManagersList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.partnersManagersList, stateKeys.filters], List(filters))
        .setIn([stateKeys.partnersManagersList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.partnersManagersList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.partnersManagersList, stateKeys.reload], reload)
        .setIn([stateKeys.partnersManagersList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PARTNERS_MANAGERS_SETTING_STATUS: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.settingStatus], payload);
    }
    case types.SET_PARTNERS_MANAGERS_SETTING_PENDING: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.settingPending], payload);
    }
    case types.MERGE_PARTNER_MANAGER: {
      return state.mergeIn([stateKeys.partnerManager], Map(payload));
    }
    case types.SET_PARTNERS_MANAGERS_IMPORT_MODAL_IS_OPEN: {
      return state.setIn([stateKeys.partnersManagersList, stateKeys.isOpen], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_PARTNERS_MANAGERS_LIST_RELOAD: {
      const { reload } = payload;
      return state.setIn([stateKeys.partnersManagersList, stateKeys.reload], reload);
    }
    default: {
      return state;
    }
  }
};
