// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Form, Field, reduxForm, formValueSelector,
} from 'redux-form/immutable';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
// Icons
import SaveIcons from '@mui/icons-material/Save';
import DeleteIcons from '@mui/icons-material/Delete';
// Parts
import Loading from '../../../../components/Loading/Loading';
import CategoriesSelect from '../../../../components/_Form/Selects/CategoriesSelect';
import BrandsSelect from '../../../../components/_Form/Selects/BrandsSelect';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import Modal from '../../../../containers/Modals/Modal/Modal';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/stopList/BrandsCategories/actions';
import { asyncActions } from '../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';

export const formName = 'stopBrandsCategoriesEditForm';

export const formFields = Object.freeze({
  brand: `${formName}Brand`,
  category: `${formName}Category`,
  excludePartners: `${formName}ExcludePartners`,
  createdAt: `${formName}CreatedAt`,
  updatedAt: `${formName}UpdatedAt`,
});


function mapStateToProps(state) {
  return {
    isLoading: selectors.stopListBrandsCategories.editPending(state),
    modalOpen: selectors.stopListBrandsCategories.editIsModalOpen(state),
    listIsLoading: selectors.stopListBrandsCategories.isLoading(state),
    putPending: selectors.stopListBrandsCategories.putPending(state),
    deletePending: selectors.stopListBrandsCategories.deletePending(state),
    hashId: selectors.stopListBrandsCategories.editItemHashId(state),
    editItemId: selectors.stopListBrandsCategories.editItemId(state),
    hasAccessDelete: selectors.user.accessList(state).includes(accessList.stopBrandsCategoriesDelete),
    hasAccessEdit: selectors.user.accessList(state).includes(accessList.stopBrandsCategoriesCreate),
    category: formValueSelector(formName)(state, formFields.category),
    brand: formValueSelector(formName)(state, formFields.brand),
    excludePartners: formValueSelector(formName)(state, formFields.excludePartners),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    putItemByHashIdAsync: params => dispatch(asyncActions.putItemAsync(params)),
    deleteItemsAsync: params => dispatch(asyncActions.deleteAsync(params)),
  };
}

function EditModal(props) {
  const {
    modalOpen, isLoading, handleSubmit, deletePending, pristine, submitting,
    hasAccessDelete, category, brand, putPending, hasAccessEdit, dispatch,
    putItemByHashIdAsync, hashId, editItemId, deleteItemsAsync, listIsLoading,
  } = props;
  const { t } = useTranslation();

  if (!hasAccessEdit) {
    return null;
  }

  const handleModalToggle = () => {
    dispatch(actions.mergeEdit({
      isModalOpen: !modalOpen,
    }));
  };

  const onFormSubmit = (formData) => {
    if (formData && formData.toJS) {
      const normalizeFormData = formData.toJS();
      const data = {
        category: get(normalizeFormData[formFields.category], 'value'),
        excludePartners: get(normalizeFormData[formFields.excludePartners], 'value'),
        brand: get(normalizeFormData[formFields.brand], 'value'),
      };
      putItemByHashIdAsync({ data, hashId });
    }
  };

  const handleSendForm = () => dispatch(submit(formName));

  const deleteItemsByHashId = () => {
    if (typeof editItemId === 'number') {
      deleteItemsAsync({ stopBrandsCategoriesIds: [editItemId], modal: true });
    }
  };

  const ButtonComponent = () => (
    <>
      <ButtonWithIconAndCircularProgress
        text={t('Сохранить')}
        component="button"
        disabled={(!!category === false && !!brand === false) || deletePending || listIsLoading || pristine || submitting}
        isLoading={isLoading || putPending}
        onClick={handleSendForm}
      >
        <SaveIcons />
      </ButtonWithIconAndCircularProgress>
      &nbsp;
      {hasAccessDelete && (
        <ButtonWithIconAndCircularProgress
          text={t('Удалить')}
          disabled={putPending || listIsLoading || submitting}
          isLoading={isLoading || deletePending}
          onClick={deleteItemsByHashId}
        >
          <DeleteIcons />
        </ButtonWithIconAndCircularProgress>
      )}
    </>
  );

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      modalMaxWidth="md"
      buttonComponent={ButtonComponent}
      title={t('Редактирование стоп бренда-категории')}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        {isLoading ? (
          <Loading isLoading />
        ) : (
          <>
            <Field
              formName={formName}
              component={CategoriesSelect}
              name={formFields.category}
              margin="normal"
              label={t('Категория товара')}
            />
            <Field
              component={BrandsSelect}
              name={formFields.brand}
              margin="normal"
              label={t('Бренд товара')}
            />
            <Field
              component={TextField}
              disabled
              fullWidth
              name={formFields.createdAt}
              margin="normal"
              label={t('Дата добавления')}
            />
            <Field
              component={TextField}
              disabled
              fullWidth
              margin="normal"
              name={formFields.updatedAt}
              label={t('Дата обновления')}
            />
          </>
        )}
      </Form>
    </Modal>
  );
}

EditModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  putPending: PropTypes.bool,
  deletePending: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hashId: PropTypes.string,
  editItemId: PropTypes.number,
  deleteItemsAsync: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  putItemByHashIdAsync: PropTypes.func,
  dispatch: PropTypes.func,
  hasAccessDelete: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  hasAccessEdit: PropTypes.bool,
  listIsLoading: PropTypes.bool,
};
export default compose(
  reduxForm({
    form: formName,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(EditModal);
