// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';

// UI
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';

// Engine
import { selectors } from '../../../../engine/config/selectors';
import { countersMaxNumber } from '../../../../engine/config/globalConfig';

function OrdersOverdueSidebarItem(props) {
  const { classes, itemName, type } = props;
  const counter = useSelector(selectors.statistics.ordersOverdueCounter);

  const badgeClassName = cx(classes.badge, {
    [classes[type]]: type !== 'default',
    [classes.badgePadding]: counter > 99,
  });

  const Counter = (counter > 0) && (
    <Typography className={badgeClassName} component="span">
      {(Boolean(countersMaxNumber) && counter > countersMaxNumber) ? `${countersMaxNumber}+` : counter}
    </Typography>
  );

  return (
    <ListItem className={classes.menuSubItem} button>
      <Typography variant="body1" className={classes.menuText}>
        {itemName}{' '}{Counter}
      </Typography>
    </ListItem>
  );
}

OrdersOverdueSidebarItem.propTypes = {
  classes: PropTypes.object,
  itemName: PropTypes.string,
  type: PropTypes.oneOf(['default', 'secondary', 'primary']),
};

OrdersOverdueSidebarItem.defaultProps = {
  classes: {},
  itemName: '',
  type: 'default',
};

export default OrdersOverdueSidebarItem;
