// Core
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
// Parts
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// Icons
import FaceIcon from '@mui/icons-material/Face';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDownRoundedIcon from '../../../../icons/ArrowDownRounded';
import PhoneIcon from '../../../../icons/Phone';
import EmailIcon from '../../../../icons/Email';
import ChatRightIcon from '../../../../icons/ChatRight';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
// Helpers
import { uiActions } from '../../../../../engine/core/ui/action';
import { SupportButtonStyles } from './styles';
import { useStyles } from '../../../../hooks/useStyles';
import { useMDView } from '../../../../hooks/useDesktopView';


function SupportButtons(props) {
  const { supportManager, hasSupportMessageCreate } = props;
  const { t } = useTranslation();
  const classes = useStyles(SupportButtonStyles);
  const [managerMenuIsOpen, setManagerMenuIsOpen] = useState(null);
  const isMDView = useMDView();
  const dispatch = useDispatch();

  const handleManagerMenu = (event) => {
    setManagerMenuIsOpen(managerMenuIsOpen ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setManagerMenuIsOpen(null);
  };

  const hasSupportManagerInfo = !isEmpty(supportManager);
  const supportManagerName = get(supportManager, 'name', false);
  const supportManagerPhone = get(supportManager, 'phone', false);
  const supportManagerEmail = get(supportManager, 'email', false);

  const handleSupportPopupOpen = () => {
    handleCloseMenu();
    dispatch(uiActions.mergeServiceSupportModal({ isOpen: true }));
  };

  return (
    <>
      {hasSupportManagerInfo || hasSupportMessageCreate ? (
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleCloseMenu}>
          <Button
            className={cx(classes.supportMenuButton, classes.supportMenu, { [classes.supportMenuButtonActive]: managerMenuIsOpen })}
            id="support-menu-button"
            aria-controls={managerMenuIsOpen ? 'support-menu' : null}
            aria-haspopup="true"
            aria-expanded={managerMenuIsOpen ? 'true' : null}
            onClick={handleManagerMenu}
            disableRipple
          >
            <Box className={classes.supportMenuBox}>
              <PhoneIcon className={classes.supportMenuIcon} />
              <Typography className={cx({ [classes.hideMobile]: isMDView })}>
                {supportManagerPhone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2}).*/, '+$1($2) $3-$4-$5')}
              </Typography>
            </Box>
            <ArrowDownRoundedIcon
              className={cx(classes.supportMenuDropIcon, { [classes.supportMenuDropIconActive]: managerMenuIsOpen })}
            />
            <Menu
              id="support-menu"
              anchorEl={managerMenuIsOpen}
              open={Boolean(managerMenuIsOpen)}
              onClose={handleCloseMenu}
              className={classes.supportButtonsMenu}
            >
              {supportManagerName && (
                <MenuItem onClick={handleCloseMenu} className={classes.supportMenuItem}>
                  <ListItemIcon className={classes.row}>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText primary={supportManagerName} />
                </MenuItem>
              )}

              {supportManagerEmail && (
                <MenuItem onClick={handleCloseMenu} className={classes.supportMenuItem}>
                  <Link color="inherit" href={`mailto:${supportManagerEmail}`} className={cx(classes.link, classes.row)}>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary={supportManagerEmail} />
                  </Link>
                </MenuItem>
              )}

              {hasSupportMessageCreate && false && ( // TODO AMP-5698
                <MenuItem onClick={handleSupportPopupOpen} className={cx(classes.supportMenuItem, classes.row)}>
                  <ListItemIcon>
                    <ChatRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Задать вопрос')} />
                </MenuItem>
              )}
              <MenuItem onClick={handleSupportPopupOpen} className={cx(classes.supportMenuItem, classes.supportItemInfo)}>
                <Typography variant="string" display="block">{t('Служба поддержки')}</Typography>
                <Typography variant="string" display="block">9:00 - 20:00</Typography>
              </MenuItem>
            </Menu>
          </Button>
        </ClickAwayListener>
      ) : null }
    </>
  );
}

SupportButtons.propTypes = {
  hasSupportMessageCreate: PropTypes.bool,
  supportManager: PropTypes.object,
};

SupportButtons.defaultProps = {
  hasSupportMessageCreate: false,
  supportManager: {},
};

// Redux
function mapStateToProps(state) {
  return {
    hasAccessToNotification: selectors.user.accessList(state).includes(accessList.notificationCenter),
    hasSupportMessageCreate: selectors.user.accessList(state).includes(accessList.supportMessageCreate),
    notificationsNotReadCount: selectors.statistics.notificationsNotReadCount(state),
    inApprove: selectors.ui.inApprove(state),
    supportManager: selectors.user.userInfo(state).supportManager,
    rolesList: selectors.user.rolesList(state),
    userName: selectors.user.userInfo(state).name,
  };
}

export default connect(mapStateToProps)(SupportButtons);
