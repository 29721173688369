export const asyncTypes = Object.freeze({
  DELETE_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC: 'DELETE_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC',
  GET_STOP_LIST_BRANDS_CATEGORIES_DOWNLOAD_ASYNC: 'GET_STOP_LIST_BRANDS_CATEGORIES_DOWNLOAD_ASYNC',
  POST_STOP_LIST_BRANDS_CATEGORIES_UPLOAD_ASYNC: 'POST_STOP_LIST_BRANDS_CATEGORIES_UPLOAD_ASYNC',
  PUT_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC: 'PUT_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC',
  POST_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC: 'POST_STOP_LIST_BRANDS_CATEGORIES_ITEM_ASYNC',
  GET_STOP_LIST_BRANDS_CATEGORIES_BY_HASH_ASYNC: 'GET_STOP_LIST_BRANDS_CATEGORIES_BY_HASH_ASYNC',
  GET_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC: 'GET_STOP_LIST_BRANDS_CATEGORIES_LIST_ASYNC',
  PUT_STOP_LIST_BRANDS_CATEGORIES_SETTINGS_ASYNC: 'PUT_STOP_LIST_BRANDS_CATEGORIES_SETTINGS_ASYNC',
  GET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE_ASYNC: 'GET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE_ASYNC',
});
