// Core
import {
  Form, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import * as PropTypes from 'prop-types';

// Icons
import SaveIcons from '@mui/icons-material/Save';

// Components
import Modal from '../../../../containers/Modals/Modal/Modal';
import ReasonRejectionFields from './ReasonRejectionFields';

// actions
import { actions as partnerActions } from '../../../../../engine/core/partners/actions';
import { asyncActions as partnerAsyncAction } from '../../../../../engine/core/partners/saga/asyncActions';

// config
import { selectors } from '../../../../../engine/config/selectors';

// helpers
import { formName } from './helpers/reasonRejectionForm';

function ReasonRejectionModal(props) {
  const {
    handleSubmit,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open } = (useSelector(selectors.partners.reasonRejectionModal)).toJS();
  const partnerHashId = useSelector(selectors.partner.partnerHashId);
  const cancelStatusesList = useSelector(selectors.partner.cancelStatusesList);
  const partnerHashIdConfirmPending = useSelector(selectors.partners.partnerHashIdConfirmPending);

  const handleModalToggle = () => {
    dispatch(partnerActions.setReasonRejectionModal({
      open: false,
    }));
  };

  const onFormSubmit = (formData) => {
    dispatch(partnerAsyncAction.putPartnersHashIdConfirmForm({
      hashId: partnerHashId,
      formData: {
        confirm: 0,
        ...formData.toJS(),
      },
    }));
  };

  const handleSendForm = () => dispatch(submit(formName));

  return (
    <Modal
      modalOpen={open}
      handleModalAdd={handleSendForm}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      modalMaxWidth="md"
      iconSendButton={<SaveIcons />}
      addText={t('Сохранить')}
      loadingSend={partnerHashIdConfirmPending}
      disabledSend={partnerHashIdConfirmPending}
      title={t('Причина отклонения')}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <ReasonRejectionFields
          formName={formName}
          options={cancelStatusesList}
        />
      </Form>
    </Modal>
  );
}

ReasonRejectionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formName,
})(ReasonRejectionModal);
