// Core
import {
  useCallback, useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../engine/core/serviceDesk/saga/asyncActions';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { usePrevious } from '../../../hooks/usePrevious';
import { useAccessList } from '../../../hooks/useAccessList';
import { useUserRole } from '../../../hooks/useUserRole';
// Data
import { requestDelay } from '../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../_helpers/convertDataToUrlParams';
import { userRoles } from '../../../../engine/config/userRoles';

export function useServiceDeskList(refresh) {
  const dispatch = useDispatch();
  const path = useSelector(selectors.router.pathname);
  const reload = useSelector(selectors.serviceDesk.reload);
  const faqServiceDescLastUpdate = useSelector(selectors.statistics.faqServiceDescLastUpdate);
  const prevFaqServiceDescLastUpdate = usePrevious(faqServiceDescLastUpdate);
  const isPartnerRole = useUserRole(userRoles.partnerActive);
  const updateFromLastUpdate =
    prevFaqServiceDescLastUpdate !== undefined && faqServiceDescLastUpdate !== undefined
    && faqServiceDescLastUpdate !== prevFaqServiceDescLastUpdate;
  const hasAccessFaqQuestionList = useAccessList(accessList.faqServiceDescQuestionList);
  const currentPage = useSelector(selectors.serviceDesk.currentPage);
  const limit = useSelector(selectors.serviceDesk.pageSize);
  const sorting = useSelector(selectors.serviceDesk.sorting);
  const filters = useSelector(selectors.serviceDesk.filters);
  const offset = limit * currentPage;

  const orderBy = useMemo(() => {
    const sort = sorting.toJS();
    return convertSortingObjectToUrlParams(sort);
  }, [sorting]);

  const paramsByFilter = useMemo(() => {
    const params = {};
    return filters.toJS().reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filters]);

  const getListAsync = useCallback(() => {
    if (hasAccessFaqQuestionList) {
      const params = {
        ...paramsByFilter,
        orderBy,
        offset,
        limit,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [dispatch, hasAccessFaqQuestionList,
    orderBy, limit, offset, paramsByFilter,
  ]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);


  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    if (updateFromLastUpdate && !isPartnerRole) {
      getListAsync();
    }
  }, [getListAsync, updateFromLastUpdate, isPartnerRole]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync, refresh,
    getInitialStateAsync,
    reload, timeout]);
}
