// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_CURRENT_PAGE, currentPage);
  },
  setReload(currentPage) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_RELOAD, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_PENDING, settingPending);
  },
  setCommissionsUploadIsOpen(isOpen) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_IS_OPEN, isOpen);
  },
  setCommissionsUploadUploading(pending) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_UPLOADING, pending);
  },
  setCategoriesFeatureTreeDataRowIds(ids) {
    return getActionCreator(types.SET_CATEGORIES_FEATURE_MANAGEMENT_TREE_DATA_ROW_IDS, ids);
  },
  mergeCategoriesFeatureImport(data) {
    return getActionCreator(types.MERGE_CATEGORIES_FEATURE_IMPORT, data);
  },
  mergeCategoriesFeatureExport(data) {
    return getActionCreator(types.MERGE_CATEGORIES_FEATURE_EXPORT, data);
  },
});
