// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetMessagesUserTypesWorker,
  callPostMessageWorker,
  callGetMessageByHashIdWorker,
  callDeleteMessageHashIdWorker,
  callGetMessagesUserTypesByTypeWorker,
  callGetMessagesStatusesWorker,
  callPostRecipientsImportWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_MESSAGES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_MESSAGES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MESSAGES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetMessagesUserTypes() {
  yield takeEvery(asyncTypes.GET_MESSAGES_USER_TYPES_ASYNC, callGetMessagesUserTypesWorker);
}
function* watchPostMessage() {
  yield takeEvery(asyncTypes.POST_MESSAGE_ASYNC, callPostMessageWorker);
}
function* watchGetMessageByHashId() {
  yield takeEvery(asyncTypes.GET_MESSAGE_BY_HASH_ID_ASYNC, callGetMessageByHashIdWorker);
}
function* watchDeleteMessageHashId() {
  yield takeEvery(asyncTypes.DELETE_MESSAGE_HASH_ID_ASYNC, callDeleteMessageHashIdWorker);
}
function* watchGetMessageStatuses() {
  yield takeEvery(asyncTypes.GET_MESSAGE_USER_TYPES_BY_TYPE_ASYNC, callGetMessagesUserTypesByTypeWorker);
}
function* watchGetMessagesStatuses() {
  yield takeEvery(asyncTypes.GET_MESSAGES_STATUSES_ASYNC, callGetMessagesStatusesWorker);
}
function* watchPostRecipientsImport() {
  yield takeEvery(asyncTypes.POST_RECIPIENTS_IMPORT_ASYNC, callPostRecipientsImportWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MESSAGES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MESSAGES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MESSAGES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MESSAGES_SORTING, callSetToLocalDataWorker);
}

export function* watchersMessages() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchGetMessagesUserTypes(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPostMessage(),
    watchPostRecipientsImport(),
    watchGetMessagesStatuses(),
    watchGetMessageStatuses(),
    watchGetMessageByHashId(),
    watchDeleteMessageHashId(),
  ]);
}
