import { apply, put } from 'redux-saga/effects';
import { select } from 'redux-saga-test-plan/matchers';
import { actions } from '../../actions';
import { api } from '../../../../../config/api';
import { selectors } from '../../../../../config/selectors';
import { getFileFromBlob } from '../../../../../../_helpers/getFileFromBlob';

export function* partnerServiceRequestsDownloadActionWorker() {
  yield put(actions.setDownloadPending(true));
  const filters = yield select(selectors.partnersServiceRequests.filters);
  const sorting = yield select(selectors.partnersServiceRequests.sorting);
  const params = {
    sorting,
  };
  filters.reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  const response = yield apply(api, api.partnerServiceRequests.downloadFile, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    getFileFromBlob('partner_services.xls', response);
  }

  yield put(actions.setDownloadPending(false));
}
