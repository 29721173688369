// UI
import FormHelperText from '@mui/material/FormHelperText';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';

function InfoText(props) {
  const { children } = props;
  const classes = useStyles(ContentProductEditStyles);

  return (
    <FormHelperText className={classes.formControlInfoText}>
      {children}
    </FormHelperText>
  );
}

export default InfoText;
