// Core
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  reduxForm, Form,
} from 'redux-form/immutable';
import { Map } from 'immutable';
import * as PropTypes from 'prop-types';
// lodash
import isEmpty from 'lodash/isEmpty';
// UI
import Grid from '@mui/material/Grid';
// helpers
import { formName } from './helpers/form';
// actions
import { asyncActions } from '../../../../engine/core/transactionsPayment/saga/asyncActions';
import { actions } from '../../../../engine/core/transactionsPayment/actions';
// parts
import SectionHolder from '../../../containers/SectionHolder/SectionHolder';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import TransactionPaymentMainTab from './components/TransactionPaymentMainTab';
import Loading from '../../../components/Loading/Loading';
// config
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';
// hooks
import { useAccessList } from '../../../hooks/useAccessList';

function TransactionPaymentEdit(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const [readyOneEntity, setReadyOneEntity] = useState(true);
  const dispatch = useDispatch();
  const hasAccessEdit = useAccessList(accessList.partnerPaymentTransactionEdit);

  const transactionPayment = useSelector(selectors.transactionsPayment.transactionPayment);
  const data = useSelector(selectors.transactionsPayment.transactionPaymentData);
  const putTransactionPayment = useSelector(selectors.transactionsPayment.putTransactionPayment);
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);

  const { pending } = transactionPayment.toJS();
  const { hashId } = useParams();

  const onFormSubmit = (resolution) => {
    dispatch(asyncActions.putTransactionPaymentAsync({
      hashId, formData: { resolution },
    }));
  };


  const getTransactionPaymentInfo = useCallback(() => {
    dispatch(asyncActions.getTransactionPaymentByHashIdAsync(hashId));
  }, [dispatch, hashId]);

  const getTitleTransactionData = useMemo(() => !isEmpty(data.toJS())
    ? `${t('Транзакция по заказу')} ${data.toJS().order}`
    : '', [data, t]);

  const isReadyData = useMemo(() => !isEmpty(data.toJS()) && !pending, [data, pending]);

  useEffect(() => {
    if (!postUsersHashIdPending) {
      setReadyOneEntity(true);
    }
  }, [postUsersHashIdPending, setReadyOneEntity]);

  useEffect(() => {
    if (readyOneEntity) {
      setReadyOneEntity(false);
      getTransactionPaymentInfo();
    }
  }, [getTransactionPaymentInfo, setReadyOneEntity, readyOneEntity]);

  useEffect(() => () => {
    dispatch(actions.setTransactionPaymentInfo({ data: Map() }));
  }, [dispatch]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={getTitleTransactionData}>
          <ButtonGoBack defaultPage={pageLinks.orders.paymentsTransactions} />
          {hasAccessEdit && (
            <>
              {data.toJS().canApprove && (
                <ButtonWithIconAndCircularProgress
                  text={t('Продолжить')}
                  disabled={putTransactionPayment.toJS().pending}
                  isLoading={putTransactionPayment.toJS().pending}
                  onClick={handleSubmit(() => onFormSubmit('approve'))}
                  component="button"
                  type="submit"
                  withoutIcon
                />
              )}
              {data.toJS().canCancel && (
                <ButtonWithIconAndCircularProgress
                  text={t('Отменить Заказ')}
                  disabled={putTransactionPayment.toJS().pending}
                  isLoading={putTransactionPayment.toJS().pending}
                  onClick={handleSubmit(() => onFormSubmit('cancel'))}
                  component="button"
                  type="submit"
                  withoutIcon
                />
              )}
            </>
          )}
        </PageHeader>
        <Grid container xs={12}>
          <SectionHolder>
            {isReadyData
              ? <TransactionPaymentMainTab />
              : <Loading isLoading />
          }
          </SectionHolder>
        </Grid>
      </Grid>
    </Form>
  );
}

TransactionPaymentEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formName,
})(TransactionPaymentEdit);
