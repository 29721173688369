// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../../partners/accesses/actions';
import { asyncActions } from '../../../partners/accesses/saga/asyncActions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callUploadPartnerPaymentDocsWorker({ payload }) {
  const { hashId, ...fields } = payload;
  yield put(actions.mergePartnerPaymentTypeModal({
    pending: true,
  }));
  const response = yield apply(api, api.partner.uploadPaymentDocs, [{ ...fields, hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergePartnerPaymentTypeModal({
          isOpen: false,
          mode: undefined,
        }));
        yield put(asyncActions.getParentAccessByHashIdAsync(hashId));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergePartnerPaymentTypeModal({
    pending: false,
  }));
}
