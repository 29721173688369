// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_FEEDS_PRODUCTS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_FEEDS_PRODUCTS_SETTINGS_ASYNC, param);
  },
  postParseByHashIdAsync(param) {
    return getActionCreator(asyncTypes.POST_FEEDS_PARSE_BY_HASH_ID_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_FEEDS_PRODUCTS_INITIAL_STATE_ASYNC, path);
  },
  getStatusesListAsync(param) {
    return getActionCreator(asyncTypes.GET_FEEDS_PRODUCTS_STATUSES_LIST_ASYNC, param);
  },
  putItemByHashId(param) {
    return getActionCreator(asyncTypes.PUT_FEEDS_PRODUCTS_ITEMS_BY_HASH_ID_ASYNC, param);
  },
  getProductsFeedExport(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_FEED_EXPORT_ASYNC, params);
  },
  getProductsFeedFullExport(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_FEED_FULL_EXPORT_ASYNC, params);
  },
  postProductsFeedImportAsync(params) {
    return getActionCreator(asyncTypes.POST_PRODUCTS_FEED_IMPORT_ASYNC, params);
  },
  putProductMultipleEditingAsync(data) {
    return getActionCreator(asyncTypes.PUT_PRODUCTS_FEED_MULTIPLE_EDITING_ASYNC, data);
  },
  getFeedProductsStatisticAsync(data) {
    return getActionCreator(asyncTypes.GET_FEEDS_PRODUCTS_STATISTIC_ASYNC, data);
  },
  getProductsFeedStatisticAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_FEED_STATISTIC_ASYNC, params);
  },
  getFeedsDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_FEED_LIST_EXPORT_ASYNC, params);
  },
});
