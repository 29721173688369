// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';

// Container
import ToggleFullText from './components/EditableContainerFullText';

function EditableContainer(props) {
  const {
    children, isEditable, text,
    secondText, fullText, color, onClickFilter,
    chipText, secondChipText, name, hashId, isNotEllipsis, newDesignStyle,
  } = props;
  return (isEditable
    ? <>{children || null}</>
    : (
      <ToggleFullText
        text={text}
        chipText={chipText}
        name={name}
        onClickFilter={onClickFilter}
        secondChipText={secondChipText}
        secondText={secondText}
        color={color}
        fullText={fullText}
        hashId={hashId}
        isNotEllipsis={isNotEllipsis}
        newDesignStyle={newDesignStyle}
      />
    ));
}

EditableContainer.propTypes = {
  children: PropTypes.node,
  fullText: PropTypes.bool,
  isEditable: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  name: PropTypes.string,
  onClickFilter: PropTypes.func,
  chipText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondChipText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hashId: PropTypes.string,
  isNotEllipsis: PropTypes.bool,
  newDesignStyle: PropTypes.bool,
};

EditableContainer.defaultProps = {
  fullText: false,
  isEditable: true,
};

export default memo(EditableContainer);
