// Core
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form/immutable';
// Parts
import Link from '@mui/material/Link';
import ModalConfirm from '../../../../../../../containers/Modals/ModalConfirm';
// Helpers
import { formFields, formName } from '../ProductMultipleEditingForm';
import { usePrevious } from '../../../../../../../hooks/usePrevious';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';

function ProductFeatureAutoModal(props) {
  const { showModal, setShowModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productEditPending = useSelector(selectors.allProductsTable.productEditPending);
  const prevProductEditPending = usePrevious(productEditPending);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const featureAutoUpdateValue = getFormValues.get(formFields.featureAutoUpdate) || false;
  const textShow = JSON.parse(featureAutoUpdateValue)
    ? t('будут отображаться значения характеристик, указанные в прайс-листах Партнера')
    : t('будут отображаться значения характеристик, указанные в Личном кабинете Партнера');
  const textIgnore = JSON.parse(featureAutoUpdateValue)
    ? t('Данные, указанные в Личном кабинете - будут проигнорированы')
    : t('Данные, указанные в прайс-листах - будут проигнорированы');
  const onConfirm = () => {
    dispatch(submit(formName));
  };
  const onCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (productEditPending === false && prevProductEditPending === true && showModal) {
      setShowModal(false);
    }
  }, [productEditPending, prevProductEditPending, showModal]);

  return (
    <ModalConfirm
      confirmText={t('Подтвердить')}
      textAlign="center"
      pendingConfirm={productEditPending}
      onCancel={onCancel}
      onConfirm={onConfirm}
      showModal={showModal}
      title={t('Внимание')}
    >
      <p>
        {t('После подтверждения изменений, на сайте')} <Link target="_blank" href="https://allo.ua/">allo.ua</Link> {textShow}.
      </p>
      <p>{textIgnore}.</p>
    </ModalConfirm>
  );
}

export default ProductFeatureAutoModal;
