// Core
import { useEffect, useRef } from 'react';
import { useMemo } from 'use-memo-one';
// Helpers
import { getCurrentOption } from '../../../_helpers/getCurrentOption';

export function useFlattenedData(options, openedNodeIds, setOpenedNodeIds, selectProps, innerRef) {
  const {
    checkboxesState, indeterminateState, onChangeCheckbox, classes, onlyChild, searchString, onlyNotPortal,
  } = selectProps;

  const getChildren = (parent, arr) => arr.filter(item => item.parentValue === parent.value);
  const buildTree = (arr, par) => {
    const childrenList = getChildren(par, arr);
    if (childrenList.length === 0) {
      return par;
    }

    // eslint-disable-next-line no-param-reassign
    par.children = childrenList.map(child => buildTree(arr, child));

    return par;
  };

  const rootOptions = useMemo(() => options.filter(item => !item.parentValue), [options, searchString]);
  const treeOptions = useMemo(
    () => rootOptions.map(rootOption => buildTree(options, rootOption)),
    [rootOptions, options, searchString],
  );

  // Exclude nodes that have no options
  const isValidForSearch = (node) => {
    const { children, hasChildren } = node;
    const hasChildrenData = children && children.length > 0;

    if (hasChildren && hasChildrenData) {
      return children.some(isValidForSearch);
    }

    return !(hasChildren && !hasChildrenData);
  };

  const isNodeContainResult = (node) => {
    const {
      isPortal, hasChildren, hasActiveChildren,
    } = node;
    const activeWithoutChild = onlyChild === true && hasChildren === true && hasActiveChildren === false;
    const showOnlyChild = onlyChild ? (!hasChildren || activeWithoutChild) : true;
    const showBox = onlyNotPortal ? showOnlyChild && isPortal === false : showOnlyChild;

    return !(!showBox && !isValidForSearch(node));
  };

  const prevSearchStringRef = useRef(searchString);
  const flattenNode = (node, depth, result) => {
    if (!isNodeContainResult(node)) {
      return;
    }

    let collapsed;
    const {
      id, label, children, isPortal, hasActiveChildren,
    } = node;
    const hasChildren = children && children.length > 0;

    if (hasChildren) {
      collapsed = searchString && (searchString !== prevSearchStringRef.searchString)
        ? false
        : !openedNodeIds.includes(id);
    }

    result.push({
      id,
      label,
      hasChildren,
      depth,
      collapsed,
      classes,
      currentOption: getCurrentOption(options, id),
      hasActiveChildren,
      isPortal,
      onlyChild,
      innerRef,
      onChangeCheckbox,
      search: searchString,
      onlyNotPortal,
      checkboxesState,
      indeterminateState,
    });

    if (!collapsed && children) {
      children.forEach((child) => {
        flattenNode(child, depth + 1, result);
      });
    }
  };

  const flattenOpened = (data) => {
    const result = [];
    data.forEach((node) => {
      flattenNode(node, 1, result);
    });

    return result;
  };

  const flattenedData = flattenOpened(treeOptions);

  useEffect(() => {
    prevSearchStringRef.searchString = searchString;

    if (searchString) {
      const searchOpenedNodeIds = flattenedData
        .filter(item => !item.collapsed && item.hasChildren)
        .map(item => item.id);

      if (searchOpenedNodeIds) {
        setOpenedNodeIds([...searchOpenedNodeIds]);
      }
    } else {
      setOpenedNodeIds([]);
    }
  }, [searchString]);

  return flattenedData;
}
