// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetStatusesListWorker,
  callPutItemByHashId,
  callGetProductsFeedExportWorker,
  callGetProductsFeedFullExportWorker,
  callPostProductsFeedImportWorker,
  callPutProductMultipleEditingWorker,
  callGetProductsStatisticWorker,
  callPostParseByHashId,
  callGetProductsFeedStatisticWorker,
  callGetExportProductsListWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_FEEDS_PRODUCTS_LIST_ASYNC, callGetListWorker);
}
function* watchPostParseByHashId() {
  yield takeEvery(asyncTypes.POST_FEEDS_PARSE_BY_HASH_ID_ASYNC, callPostParseByHashId);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_FEEDS_PRODUCTS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_FEEDS_PRODUCTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_FEEDS_PRODUCTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_FEEDS_PRODUCTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_FEEDS_PRODUCTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_FEEDS_PRODUCTS_SORTING, callSetToLocalDataWorker);
}
function* watchGetStatuses() {
  yield takeEvery(asyncTypes.GET_FEEDS_PRODUCTS_STATUSES_LIST_ASYNC, callGetStatusesListWorker);
}
function* watchPutItemByHashId() {
  yield takeEvery(asyncTypes.PUT_FEEDS_PRODUCTS_ITEMS_BY_HASH_ID_ASYNC, callPutItemByHashId);
}
function* watchGetProductsFeedExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_FEED_EXPORT_ASYNC, callGetProductsFeedExportWorker);
}
function* watchGetProductsFeedFullExport() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_FEED_FULL_EXPORT_ASYNC, callGetProductsFeedFullExportWorker);
}
function* watchPostProductsFeedImport() {
  yield takeEvery(asyncTypes.POST_PRODUCTS_FEED_IMPORT_ASYNC, callPostProductsFeedImportWorker);
}
function* watchPutProductMultipleEditing() {
  yield takeEvery(asyncTypes.PUT_PRODUCTS_FEED_MULTIPLE_EDITING_ASYNC, callPutProductMultipleEditingWorker);
}
function* watchGetProductsStatistic() {
  yield takeEvery(asyncTypes.GET_FEEDS_PRODUCTS_STATISTIC_ASYNC, callGetProductsStatisticWorker);
}
function* watchGetProductsFeedStatistic() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_FEED_STATISTIC_ASYNC, callGetProductsFeedStatisticWorker);
}

function* watchGetTableFeedsExportWorker() {
  yield takeEvery(asyncTypes.GET_PRODUCTS_FEED_LIST_EXPORT_ASYNC, callGetExportProductsListWorker);
}

export function* watchersFeedsProducts() {
  yield all([
    watchGetList(),
    watchPostParseByHashId(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetStatuses(),
    watchPutItemByHashId(),
    watchPutProductMultipleEditing(),
    watchGetProductsFeedExport(),
    watchGetProductsFeedFullExport(),
    watchPostProductsFeedImport(),
    watchGetProductsStatistic(),
    watchGetProductsFeedStatistic(),
    watchGetTableFeedsExportWorker(),
  ]);
}
