// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import { Map } from 'immutable';

// Instruments
import { api } from '../../../../config/api';
import { actions } from '../../action';
import { formName } from '../../../../../ui/page/Settings/EmailTemplates/EmailTemplatesEdit.js';

export function* callGetMailTemplateByHashIdWorker({ payload: hashId }) {
  yield put(actions.mergeMailTemplateByHashId({ pending: true }));

  const response = yield apply(api, api.mail.getMailTemplateByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;

    const formData = {
      subject: data.subject,
      subjectUa: data.subjectUa,
      event: data.event,
      body: data.body,
      bodyUa: data.bodyUa,
      group: data.mailTemplateGroup,
      roles: data.mailTemplateRoles,
    };
    yield put(actions.mergeMailTemplateByHashId({ item: Map(data) }));

    const formFields = ['subject', 'subjectUa', 'event', 'body', 'bodyUa', 'group', 'roles'];

    yield put(initialize(formName, formData, formFields));
  }
  yield put(actions.mergeMailTemplateByHashId({ pending: false }));
}
