// Core
import {
  apply, delay, put, select,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { asyncActions } from '../asyncActions';
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';

// _helpers
import { setSuccessMessage, setWarningMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callDeleteWorker({ payload: { userIds, redirectTo } }) {
  yield put(actions.mergeDelete({ pending: true }));
  const response = yield apply(api, api.user.deleteUsers, [{ userIds }]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status, statusTitle } = data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = data;
        yield put(setWarningMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        if (redirectTo) {
          yield delay(0);
          yield put(push(redirectTo));
        }
        break;
      }
      default: {
        break;
      }
    }


    if ((status === requestStatus.error || status === requestStatus.success) && !redirectTo) {
      const currentPage = yield select(selectors.users.currentPage);
      const pageSize = yield select(selectors.users.pageSize);
      const params = {
        limit: pageSize,
        offset: currentPage * pageSize,
      };

      const filters = yield select(selectors.users.filters);
      const sorting = yield select(selectors.users.sorting);

      yield put(actions.setSelection([]));

      filters.toJS().reduce((acc, { columnName, value }) => {
        acc[columnName] = value;
        return acc;
      }, params);

      sorting.toJS().reduce((acc, { columnName, direction }) => {
        acc.orderBy = `${columnName} ${direction === 'asc' ? 'ASC' : 'DESC'}`;
        return acc;
      }, params);

      yield put(asyncActions.getListAsync(params));
    }
  }
  yield put(actions.mergeDelete({ pending: false }));
}
