// Core
import React, { memo, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Select from '../../../_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { notificationsActionAsync } from '../../../../../engine/core/notifications/saga/asyncAction';

function NotificationsGroupsFilter(props) {
  const { i18n } = useTranslation();
  const { filters, changeSelectFilter } = props;
  const dispatch = useDispatch();
  const notificationsGroups = useSelector(selectors.notification.notificationsGroups);
  const input = {
    value: filters && filters.value,
    onChange: changeSelectFilter,
  };

  useEffect(() => {
    dispatch(notificationsActionAsync.getNotificationsGroups(i18n.language));
  }, [dispatch, i18n.language]);


  return (
    <Select
      options={notificationsGroups}
      label=""
      input={input}
    />
  );
}

export default memo(NotificationsGroupsFilter);


NotificationsGroupsFilter.propTypes = {
  changeSelectFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
};
