export const types = Object.freeze({
  SET_CATEGORY_STATUSES_HISTORY_LIST: 'SET_CATEGORY_STATUSES_HISTORY_LIST',
  SET_CATEGORY_STATUSES_HISTORY_CURRENT_PAGE: 'SET_CATEGORY_STATUSES_HISTORY_CURRENT_PAGE',
  SET_CATEGORY_STATUSES_HISTORY_FILTERS: 'SET_CATEGORY_STATUSES_HISTORY_FILTERS',
  SET_CATEGORY_STATUSES_HISTORY_PAGE_SIZE: 'SET_CATEGORY_STATUSES_HISTORY_PAGE_SIZE',
  SET_CATEGORY_STATUSES_HISTORY_SORTING: 'SET_CATEGORY_STATUSES_HISTORY_SORTING',
  SET_CATEGORY_STATUSES_HISTORY_INITIAL_STATE: 'SET_CATEGORY_STATUSES_HISTORY_INITIAL_STATE',
  SET_CATEGORY_STATUSES_HISTORY_SETTING_STATUS: 'SET_CATEGORY_STATUSES_HISTORY_SETTING_STATUS',
  SET_CATEGORY_STATUSES_HISTORY_SETTING_PENDING: 'SET_CATEGORY_STATUSES_HISTORY_SETTING_PENDING',
});
