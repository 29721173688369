// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/actions';
// Helpers
import { useCategoriesFeaturesItemList } from './hooks/useCategoriesFeaturesItemList';
import { tableDataTypes } from '../../../../../../_helpers/data/tableDataTypes';
import TableCell from './components/TableCell';
import { useUserRole } from '../../../../../hooks/useUserRole';
import { userRoles } from '../../../../../../engine/config/userRoles';


function CategoriesFeaturesItemTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.categoriesFeaturesItem.isLoading);
  const rows = useSelector(selectors.categoriesFeaturesItem.list);
  const isAdmin = useUserRole(userRoles.admin);

  const columns = ([
    tableDataTypes.featureName,
    tableDataTypes.updatedAtRequiredLk,
    tableDataTypes.featureRequiredMg,
    tableDataTypes.featureRequiredLk,
    isAdmin && tableDataTypes.featureRequiredLkValue,
  ]
    .filter(Boolean)
    .map(i => ({
      ...i,
      title: t(`${i?.title}`),
    })));

  // Paging
  const currentPage = useSelector(selectors.categoriesFeaturesItem.currentPage);
  const pageSize = useSelector(selectors.categoriesFeaturesItem.pageSize);
  const totalCount = useSelector(selectors.categoriesFeaturesItem.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.categoriesFeaturesItem.sorting);
  const featureRequiresLK = useSelector(selectors.categoriesFeaturesItem.featureRequiresLK).toJS();
  const featureRequiresLKId = featureRequiresLK.map(item => item.id);

  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.categoriesFeaturesItem.settingStatus);
  // const settingPending = useSelector(selectors.categoriesFeaturesItem.settingPending);
  // Filtering
  const filters = useSelector(selectors.categoriesFeaturesItem.filters);
  const filteringColumnExtension = [
    { columnName: tableDataTypes.featureRequiredMg.name, filteringEnabled: false, sortingEnabled: false },
    { columnName: tableDataTypes.featureRequiredLk.name, filteringEnabled: false, sortingEnabled: false },
    { columnName: tableDataTypes.featureRequiredLkValue.name, filteringEnabled: true, sortingEnabled: false },
  ];


  return (
    <DxTable
      name="categoryFeatures"
      customCellComponent={TableCell}
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      updateHooks={useCategoriesFeaturesItemList}
      rowComponent={props => (<VirtualTable.Row {...props} style={{ backgroundColor: featureRequiresLKId.includes(props.row.id) ? '#DB802029' : 'inherit' }} />)}
        // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
        // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
        // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      filteringExtensions={filteringColumnExtension}
    />
  );
}

export default CategoriesFeaturesItemTable;
