// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_FILTERS, filters);
  },
  mergeList(data) {
    return getActionCreator(types.MERGE_PARTNERS_ACCESS_LIST, data);
  },
  mergePartnersAccessDownload(data) {
    return getActionCreator(types.MERGE_PARTNERS_ACCESS_DOWNLOAD, data);
  },
  putPartnerAccess(data) {
    return getActionCreator(types.PUT_PARTNER_ACCESS, data);
  },
  putPartnerAccessCredentials(data) {
    return getActionCreator(types.PUT_PARTNER_ACCESS_CREDENTIALS, data);
  },
  deletePartnerAccessCredentials(data) {
    return getActionCreator(types.DELETE_PARTNER_ACCESS_CREDENTIALS, data);
  },
  deletePartnerAccess(data) {
    return getActionCreator(types.DELETE_PARTNER_ACCESS, data);
  },
  setPartnerAccessModal(data) {
    return getActionCreator(types.SET_PARTNER_ACCESS_MODAL, data);
  },
  setBankModal(data) {
    return getActionCreator(types.SET_BANK_MODAL, data);
  },
  setPartnerAccessInfo(data) {
    return getActionCreator(types.SET_PARTNER_ACCESS_INFO, data);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_PARTNERS_ACCESS_SETTING_PENDING, settingPending);
  },
  setPartnerAccessCredentials(data) {
    return getActionCreator(types.SET_PARTNER_ACCESS_CREDENTIALS, data);
  },
  mergePartnerAccessUpdateWFP(data) {
    return getActionCreator(types.MERGE_PARTNERS_ACCESS_UPDATE_WFP, data);
  },
  mergePartnerPaymentTypeModal(data) {
    return getActionCreator(types.MERGE_PARTNER_PAYMENT_TYPE_MODAL, data);
  },
  setPartnersPaymentAccessesPending(data) {
    return getActionCreator(types.SET_PARTNERS_PAYMENT_ACCESSES_PENDING, data);
  },
});
