// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function CalendarRoundedIcon(props) {
  return (
    <SvgIcon className="calendar-rounded-icon" fontSize="inherit" viewBox="0 0 15 15" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9141 0.75C10.9141 0.335786 10.5783 0 10.1641 0C9.74985 0 9.41406 0.335786 9.41406 0.75V1.33594H5.58594V0.75C5.58594 0.335786 5.25015 0 4.83594 0C4.42172 0 4.08594 0.335786 4.08594 0.75V1.33594H2.83333C1.68274 1.33594 0.75 2.26868 0.75 3.41927V6.08594V12.7526C0.75 13.9032 1.68274 14.8359 2.83333 14.8359H12.1667C13.3173 14.8359 14.25 13.9032 14.25 12.7526V6.08594V3.41927C14.25 2.26868 13.3173 1.33594 12.1667 1.33594H10.9141V0.75ZM12.75 5.33594V3.41927C12.75 3.0971 12.4888 2.83594 12.1667 2.83594H10.9141V3.41667C10.9141 3.83088 10.5783 4.16667 10.1641 4.16667C9.74985 4.16667 9.41406 3.83088 9.41406 3.41667V2.83594H5.58594V3.41667C5.58594 3.83088 5.25015 4.16667 4.83594 4.16667C4.42172 4.16667 4.08594 3.83088 4.08594 3.41667V2.83594H2.83333C2.51117 2.83594 2.25 3.0971 2.25 3.41927V5.33594H12.75ZM2.25 6.83594H12.75V12.7526C12.75 13.0748 12.4888 13.3359 12.1667 13.3359H2.83333C2.51117 13.3359 2.25 13.0748 2.25 12.7526V6.83594Z" fill="inherit" fillOpacity="0.72" />
    </SvgIcon>
  );
}


export default CalendarRoundedIcon;
