// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { COLOR } from '../../../../../containers/App/AppStyles';
import { CustomCardBorderContent } from './styles';
import { TitleWithIcon } from '../../../styles';

const ImportPartnerDataInfo = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CustomCardBorderContent>
          <TitleWithIcon
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              color: COLOR.error,
            }}
          >
            <CheckCircleOutlineIcon sx={{ color: COLOR.error, marginRight: '10px' }} />
            {t('Добавление прайс-листов заблокировано.')}
          </TitleWithIcon>
          <Typography component="p">
            {t('Для получения подробной информации  - обратитесь к вашему менеджеру.')}
          </Typography>
        </CustomCardBorderContent>
      </Card>
    </Box>
  );
};

export default ImportPartnerDataInfo;
