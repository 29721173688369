// Core
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import ContentProductEdit from './ContentProductEdit';
// Engine
import { actions } from '../../../../../engine/core/content/actions';
import { selectors } from '../../../../../engine/config/selectors';

function ContentProductEditPage() {
  const dispatch = useDispatch();
  const currentProduct = useSelector(selectors.allProductsTable.productByHashId);
  const categoryId = currentProduct.getIn(['category', 'value']);
  const pageTitle = currentProduct.getIn(['category', 'label']);

  useEffect(() => () => {
    dispatch(actions.setProductByHashId({}));
  }, [dispatch]);

  return (
    <ContentProductEdit
      categoryId={categoryId}
      pageTitle={pageTitle}
    />
  );
}

export default ContentProductEditPage;
