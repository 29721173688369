// Core
import { memo, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import SelectTree from '../../../_Form/Selects/SelectCategoryTreeView/SelectCategoryTreeView';
// Engine
import { asyncActions as categoriesAsyncActions } from '../../../../../engine/core/categories/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import { useQueryParams } from '../../../../hooks/useQueryParams';

function CategoriesTreeFilter(props) {
  const {
    filters, onSelectChange, columnType, closeMenuOnScroll,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const categoriesList = useSelector(selectors.categories.categoriesListContext);
  const attributeSetId = useQueryParams('attributeSetId');

  useEffect(() => {
    if (!currentFiltersTypeList.includes(columnType) && !attributeSetId) {
      dispatch(categoriesAsyncActions.getCategoriesListContextAsync({ loadRootCategories: true, parentValue: 0 }));
    }
  }, [dispatch, attributeSetId]);

  return (
    <SelectTree
      closeMenuOnScroll={closeMenuOnScroll}
      filters={filters}
      onSelectChange={onSelectChange}
      searchErrorLabel={t('Неверно введена категория')}
      options={categoriesList}
      hasRejectButton
    />
  );
}

CategoriesTreeFilter.propTypes = {
  closeMenuOnScroll: PropTypes.bool,
  filters: PropTypes.object,
  onSelectChange: PropTypes.func,
  columnType: PropTypes.string,
};

CategoriesTreeFilter.defaultProps = {
  filters: {},
};

export default memo(CategoriesTreeFilter);
