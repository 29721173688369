// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Engine
import { selectors } from '../../../engine/config/selectors';

// hooks
import { useStyles } from '../../hooks/useStyles';

// styles
import FramePageStyles from './FramePageStyles';


function FramePage(props) {
  const { title, configParams, hideIframeHeader } = props;
  const { t } = useTranslation();
  const classes = useStyles(FramePageStyles);
  const config = useSelector(selectors.user.configurations);

  const classNames = cx({
    [classes.hideIframeHeader]: hideIframeHeader,
    [classes.containerIframe]: !hideIframeHeader,
  });

  return (
    <>
      {title && <PageHeader title={t(title)} />}
      <div className={classNames} dangerouslySetInnerHTML={{ __html: config.get(configParams) }} />
    </>
  );
}

FramePage.propTypes = {
  title: PropTypes.string,
  configParams: PropTypes.string,
  hideIframeHeader: PropTypes.bool,
};

export default FramePage;
