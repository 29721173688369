// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import { useTranslation } from 'react-i18next';
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Parts
import EditCell from './EditCell';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/users/actions';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useUsersList } from '../_hooks/useUsersList';
import { useAccessList } from '../../../../hooks/useAccessList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';
// import { asyncActions } from '../../../../../engine/core/users/saga/asyncActions';
function Table() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.users.isLoading);
  const hasAccessEdit = useAccessList(accessList.userEdit);
  const hasAccessDelete = useAccessList(accessList.userDelete);
  const hasAccessDisable = useAccessList(accessList.userDisable);
  // Data
  const rows = useSelector(selectors.users.list);
  const tableColumns = Object.freeze({
    name: {
      name: 'name',
      title: t('Имя'),
      width: '188px',
    },
    email: {
      name: 'email',
      title: t('Email'),
    },
    additionalEmailsList: {
      name: 'additionalEmailsList',
      title: t('Дополнительный Email'),
    },
    phone: {
      name: 'phone',
      type: 'phone',
      title: t('Телефон'),
    },
    roleName: {
      name: 'roleName',
      title: t('Роль'),
    },
    partnerRoles: {
      name: 'partnerRoles',
      type: 'partnerRoles',
      frontTranslate: true,
      title: t('Роль партнера'),
    },
    partner: {
      name: 'partner',
      type: 'partner',
      title: t('Партнер'),
    },
    enabled: {
      name: 'enabled',
      type: 'enabled',
      title: t('Статус пользователя'),
    },
  });
  const columns = [
    tableColumns.name,
    tableColumns.email,
    tableColumns.additionalEmailsList,
    tableColumns.phone,
    tableColumns.roleName,
    tableColumns.partnerRoles,
    tableColumns.partner,
  ];
  if (hasAccessDisable) {
    columns.splice(3, 0, tableColumns.enabled);
  }
  // Selection
  const selection = useSelector(selectors.users.selection);
  const normalizeSelection = selection.toJS();
  const onSelection = selected => dispatch(actions.setSelection(selected));
  // Paging
  const currentPage = useSelector(selectors.users.currentPage);
  const pageSize = useSelector(selectors.users.pageSize);
  const totalCount = useSelector(selectors.users.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const sortingStateColumnExtensions = [
    { columnName: 'partnerRoles', sortingEnabled: false },
    { columnName: 'additionalEmailsList', sortingEnabled: false },
  ];
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.users.sorting);
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.users.settingStatus);
  // const settingPending = useSelector(selectors.users.settingPending);
  // Filtering
  const filters = useSelector(selectors.users.filters);
  // Edit
  const editWidth = useEditCellWidth([hasAccessEdit], 0, 40, 40);

  return (
    <DxTable
      name="users"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Edit
      editComponentWidth={editWidth}
      editComponent={EditCell}
      // Selection
      selectionOffFlag="canDelete"
      selection={normalizeSelection}
      onSelection={hasAccessDelete && onSelection}
      // Settings
      // gridSettings={gridSettings.users.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
      updateHooks={useUsersList}
      isNotEllipsis={'name'}
    />
  );
}

export default memo(Table);
