// Core
import React, {
  useState, memo, useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
// Parts
import Select from '../../../../../../components/_Form/Selects/Select/Select';
import ConfirmButtons from '../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { asyncActions as partnerAsyncAction } from '../../../../../../../engine/core/partners/managers/saga/asyncActions';
// Helpers
import { useShallowEqualSelector } from '../../../../../../hooks/useShallowEqualSelector';

function UsersProvider(props) {
  const {
    feature, hashId, value, type,
    withConfirm, valuesPlain, required, context, role,
  } = props;
  const { id, name } = value;
  const dispatch = useDispatch();
  const defaultValue = { id: null, name: null, hashId: null };
  const initValue = { value: id, label: name } || defaultValue;
  const [selectedValue, setSelectedValue] = useState(initValue);
  const options = useSelector(selectors.partner.listUsersContext);
  const partnerManagerPending = useSelector(selectors.partnersManagers.partnerManagerPending);
  const usersListContext = (useShallowEqualSelector(selectors.partner.usersListContext));
  const filteredOptions = useMemo(
    () => context && role
      ? (usersListContext.toJS())?.[context]?.[role] || []
      : options,
    [context, options, role, usersListContext],
  );

  const onChangeValueConfirm = (newValue) => {
    dispatch(partnerAsyncAction.putPartnerManagerAsync(
      {
        hashId,
        value: { [type]: newValue.value },
      },
    ));
  };

  const normalizedSelectedValue = selectedValue || defaultValue;
  const inputProps = {
    value: selectedValue,
    onChange: setSelectedValue,
  };
  const onReject = () => setSelectedValue(initValue || defaultValue);

  return (
    <ConfirmButtons
      key={get(value, 'id', 'user-confirm-buttons-key')}
      error={feature.error}
      disabled={partnerManagerPending}
      required={required}
      notEmpty={normalizedSelectedValue.value !== null}
      onConfirm={onChangeValueConfirm}
      onReject={onReject}
      success={feature.success}
      value={selectedValue}
      valuesPlain={valuesPlain}
      withConfirm={withConfirm}
      withId
      initValue={initValue}
    >
      <Select
        input={inputProps}
        closeMenuOnSelect
        isLoading={feature.pending}
        disabled={feature.pending}
        options={filteredOptions}
        variant="outlined"
        hasSearch
      />
    </ConfirmButtons>
  );
}

UsersProvider.propTypes = {
  feature: PropTypes.shape({
    error: PropTypes.bool,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }),
  value: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withConfirm: PropTypes.bool,
  required: PropTypes.bool,
  context: PropTypes.string,
  role: PropTypes.string,
  hashId: PropTypes.string,
  type: PropTypes.string,
};

UsersProvider.defaultProps = {
  withConfirm: true,
  valuesPlain: '',
};

export default memo(UsersProvider);
