// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { partnerActions } from '../../action';
import { localData } from '../../../../config/localData';

const ONE_DAY = 86400;

export function* callGetBusinessesListWorker() {
  yield put(partnerActions.setBusinessesListPending(true));

  const { business, businessInfo } = localData.keysObj;
  const info = yield apply(localData, localData.getItem, [businessInfo]);
  const { lastUpdate = 0, itemsLang = 'not set' } = info ?? {};
  const currentLanguage = localStorage.getItem('language');

  let response;
  let data = [];

  switch (true) {
    case lastUpdate:
    case currentLanguage !== itemsLang:
    case lastUpdate > Date.now() - ONE_DAY: {
      response = yield apply(api, api.partners.getBusinesses, []);
      yield apply(localData, localData.setItem, [businessInfo, { lastUpdate: Date.now(), itemsLang: currentLanguage }]);
      break;
    }
    case lastUpdate < Date.now() - ONE_DAY: {
      const dataFromLocal = yield apply(localData, localData.getItem, [business]);
      if (!dataFromLocal) {
        response = yield apply(api, api.partners.getBusinesses, []);
      }
      data = dataFromLocal;
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [business, data]);
  }

  yield put(partnerActions.setBusinessesList(data));
  yield put(partnerActions.setBusinessesListPending(false));
}
