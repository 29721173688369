// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function CloudDownloadOutlinedIcon(props) {
  const { className, ...rest } = props;
  return (
    <SvgIcon className={`cloud-download-outlined-icon ${className}`} fontSize="inherit" viewBox="0 0 24 24" {...rest}>
      <path d="M15.7611 15.016H15.5103V14.0131H15.7611C16.3596 14.037 16.943 13.8222 17.3832 13.416C17.8233 13.0097 18.084 12.4452 18.108 11.8467C18.1319 11.2482 17.9171 10.6647 17.5108 10.2246C17.1046 9.78448 16.5401 9.52377 15.9416 9.49983H15.5103L15.4602 9.08862C15.3489 8.24436 14.9346 7.46933 14.2943 6.9079C13.654 6.34647 12.8315 6.03692 11.9799 6.03692C11.1284 6.03692 10.3059 6.34647 9.66559 6.9079C9.02532 7.46933 8.61097 8.24436 8.49971 9.08862L8.48968 9.49983H8.05841C7.45992 9.52377 6.89544 9.78448 6.48917 10.2246C6.0829 10.6647 5.8681 11.2482 5.89204 11.8467C5.91598 12.4452 6.17669 13.0097 6.61682 13.416C7.05695 13.8222 7.64045 14.037 8.23894 14.0131H8.48968V15.016H8.23894C7.43478 15.011 6.66089 14.7088 6.06605 14.1676C5.47121 13.6264 5.09742 12.8845 5.01654 12.0844C4.93565 11.2843 5.15339 10.4825 5.62789 9.83324C6.10239 9.18397 6.80015 8.73302 7.58703 8.56709C7.80352 7.55738 8.35972 6.65245 9.16282 6.00329C9.96592 5.35412 10.9673 5 12 5C13.0327 5 14.0341 5.35412 14.8372 6.00329C15.6403 6.65245 16.1965 7.55738 16.413 8.56709C17.1998 8.73302 17.8976 9.18397 18.3721 9.83324C18.8466 10.4825 19.0643 11.2843 18.9835 12.0844C18.9026 12.8845 18.5288 13.6264 17.934 14.1676C17.3391 14.7088 16.5652 15.011 15.7611 15.016Z" />
      <path d="M12.4932 17.1088V11.0059H11.4903V17.1088L10.1915 15.815L9.48438 16.5221L11.9917 19.0294L14.4991 16.5221L13.792 15.815L12.4932 17.1088Z" />
    </SvgIcon>
  );
}

export default CloudDownloadOutlinedIcon;
