// Core
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, Form } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// UI
import { Link as MaterialLink } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FieldWithSelectAndInput from '../../../components/_Form/Selects/SelectAndInput/FieldWithSelectAndInput';
import FieldWithRadioAndInput from '../../../components/_Form/RadioButtons/RadioButtonsAndInput/FieldWithRadioAndInput';
import ButtonWrapper from '../components/ButtonWrapper';
import MainLogoImage from '../components/MainLogoImage/MainLogoImage';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import CheckBoxForm from '../../../components/_Form/Checkbox/CheckBoxForm';
import RadioButtons from '../../../components/_Form/RadioButtons/RadioButtons/RadioButtons';
import FormTextArea from '../../../components/_Form/TextArea/FormTextArea';
import Logo from '../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../assets/img/logo_short.svg';
import Header from '../../../components/Header/Header';
// Engine
import { routersLink } from '../../../../engine/config/routes';
import { userActionAsync } from '../../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../../engine/config/selectors';
import { locale } from '../../../../engine/init/i18n';
// Helpers
import { formName, formFields } from './form';
import { phoneMask, validators } from '../../../../_helpers/validationsForm';
import AuthenticationStyle from '../AuthenticationStyle';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';
import { useStyles } from '../../../hooks/useStyles';
// Config
const REACT_APP_AGREE_OFFER_LINK = process.env.REACT_APP_AGREE_OFFER_LINK;
const REACT_APP_AGREE_OFFER_SHOW = process.env.REACT_APP_AGREE_OFFER_SHOW;
const agreeOfferRequired = true;
const isAgreeOfferLabel = REACT_APP_AGREE_OFFER_LINK && REACT_APP_AGREE_OFFER_LINK !== 'false';

function SignUp(props) {
  const { handleSubmit } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const classes = useStyles(AuthenticationStyle);
  const dispatch = useDispatch();
  const userSubmitting = useSelector(selectors.user.userSubmitting);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryParamsObj = Array.from(params.entries()).reduce((acc, current) => {
    // eslint-disable-next-line no-param-reassign
    acc = { ...acc, [current[0]]: current[1] };
    return acc;
  }, {});

  const agreeOfferLabel = isAgreeOfferLabel
    ? <MaterialLink href={REACT_APP_AGREE_OFFER_LINK} rel="noreferrer" target="_blank">{t('публичного договора')}</MaterialLink>
    : t('публичного договора');

  const submitForm = async (formData) => {
    const agreeOffer = REACT_APP_AGREE_OFFER_SHOW === 'true'
      ? formData.get(formFields.agreeOffer)
      : true;
    if (agreeOfferRequired && agreeOffer) {
      const data = {
        companyName: formData.get(formFields.companyName),
        email: formData.get(formFields.email),
        fullName: formData.get(formFields.fullName),
        password: formData.get(formFields.password),
        phone: formData.get(formFields.phone),
        agreeOffer,
        webSite: formData.get(formFields.webSite),
        // New
        activityType: formData.get(formFields.activityType),
        hasContactCenter: formData.get(formFields.hasContactCenter),
        hasOnlineStore: formData.get(formFields.hasOnlineStore),
        hasServiceCenter: formData.get(formFields.hasServiceCenter),
        planToSell: formData.get(formFields.planToSell),
        readyToYmlFormat: formData.get(formFields.readyToYmlFormat),
        yourselfInformation: formData.get(formFields.yourselfInformation),
        locale: localStorage.getItem('language') === locale.ru ? locale.ru : locale.ua,
        analyticsTags: queryParamsObj,
      };
      if (formData.get(`${formFields.beenAlloSupplier}Radio`) === 'Так' && formData.get(formFields.beenAlloSupplier)) {
        data.beenAlloSupplier = `${formData.get(`${formFields.beenAlloSupplier}Radio`)}, ${formData.get(formFields.beenAlloSupplier)}`;
      } else if (formData.get(`${formFields.beenAlloSupplier}Radio`) === 'Так') {
        data.beenAlloSupplier = formData.get(`${formFields.beenAlloSupplier}Radio`);
      } else {
        data.beenAlloSupplier = formData.get(formFields.beenAlloSupplier);
      }
      if (formData.get(`${formFields.readyToYmlFormat}Radio`) === 'Так' && formData.get(formFields.readyToYmlFormat)) {
        data.readyToYmlFormat = `${formData.get(`${formFields.readyToYmlFormat}Radio`)}`;
        data.ymlPriceLink = `${formData.get(formFields.readyToYmlFormat)}`;
      } else {
        data.readyToYmlFormat = formData.get(formFields.readyToYmlFormat);
      }
      if (process.env.REACT_APP_RECAPTCHA === 'true') {
        if (!executeRecaptcha) {
          console.warn('Execute recaptcha not yet available');
        } else {
          data.gRecaptchaResponse = await executeRecaptcha('SignUp');
          dispatch(userActionAsync.signUpAsync(data));
        }
      } else {
        dispatch(userActionAsync.signUpAsync(data));
      }
    }
  };

  return (
    <div className={cx(classes.session, classes.background)}>
      <Header
        logoAltText={t('АЛЛО - личный кабинет')}
        logo={Logo}
        LogoMobile={LogoMobile}
        onlyLogo
        signInButton
      />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <Form onSubmit={handleSubmit(submitForm)}>
                <div className="text-xs-center pb-xs">
                  <MainLogoImage />
                  <Typography variant="caption" display="block">
                    {t('Зарегистрируйтесь, чтобы продолжить')}
                  </Typography>
                </div>
                <Field
                  fullWidth
                  name={formFields.fullName}
                  label={t('ФИО представителя компании')}
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required, validators.maxLength150]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.email}
                  label={t('Адрес электронной почты')}
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required, validators.email]}
                  validation
                  timeout={200}
                  focusComponent={(
                    <>
                      {t('Внимание! Указанный адрес электронной почты, должен соответствовать, тому по которому вы зарегистрированы в сервисе')}&nbsp;
                      <MaterialLink href="https://vchasno.ua/" target="_blank">Вчасно</MaterialLink>.
                      <br />{t('Если Вы укажете неверный адрес, то Вы не сможете получить и подписать договор.')}
                    </>
                  )}
                />
                <Field
                  fullWidth
                  name={formFields.password}
                  label={t('Пароль')}
                  type="password"
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required, validators.minLength8, validators.password]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.cPassword}
                  label={t('Подтвердите пароль')}
                  type="password"
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required, validators.confirmField(formFields.password)]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.companyName}
                  label={t('Название магазина на сайте АЛЛО')}
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required, validators.maxLength50]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.webSite}
                  label={t('Веб-сайт')}
                  margin="normal"
                  component={TextField}
                  validate={[validators.maxLength50]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.phone}
                  label={t('Телефон')}
                  margin="normal"
                  component={TextField}
                  type="tel"
                  {...phoneMask}
                  required
                  validate={[validators.required, validators.phone]}
                  validation
                />
                <Field
                  fullWidth
                  name={formFields.hasOnlineStore}
                  label={t('Есть ли у Вас интернет-магазин?')}
                  margin="normal"
                  component={RadioButtons}
                  options={[{ label: t('Так'), value: 'Так' }, { label: t('Ні'), value: 'Ні' }]}
                  required
                  validate={[validators.required]}
                  validation
                />
                <FieldWithSelectAndInput
                  selectProps={{
                    label: t('Вид Вашей деятельности'),
                    options: [
                      'Тільки інтернет-магазин',
                      'Постачальник/вендор',
                      'Виробник',
                      'Роздрібний магазин',
                      'Роздрібна мережа',
                      'Торгую на сторонніх майданчиках',
                    ],
                    elseOption: 'Інший вид діяльності',
                    validate: [validators.required],
                    validation: true,
                    required: true,
                  }}
                  inputProps={{
                    label: t('Вид деятельности'),
                    validate: [validators.required],
                    validation: true,
                  }}
                  name={formFields.activityType}
                  formName={formName}
                  fullWidth
                  margin="normal"
                />
                <Typography variant="caption" display="block" className={classes.subTitleInForm}>
                  {t('Информация о себе и своей компании')}
                </Typography>
                <Field
                  fullWidth
                  name={formFields.yourselfInformation}
                  label={t('Расскажите о себе')}
                  margin="normal"
                  component={FormTextArea}
                  required
                  multiline
                  rows={5}
                  variant="outlined"
                  validate={[validators.required]}
                  validation
                  descriptionComponent={(
                    <>
                      1. {t('Кто Вы, как давно в этом бизнесе?')}<br />
                      2. {t('Какие товарные категории и бренды хотите продавать?')}<br />
                      3. {t('Каковы Ваши сильные конкурентные стороны?')}
                    </>
                  )}
                />
                <Typography variant="caption" display="block" className={classes.subTitleInForm}>
                  {t('Информация о каталоге')}
                </Typography>
                <Field
                  fullWidth
                  name={formFields.planToSell}
                  label={t('Какое количество товаров планируете продавать?')}
                  margin="normal"
                  component={TextField}
                  required
                  validate={[validators.required]}
                  validation
                />
                <FieldWithRadioAndInput
                  selectProps={{
                    label: t('Готовы ли Вы создать/предоставить XML для выгрузки ваших товаров?'),
                    options: ['Так', 'Ні', 'Я не знаю що це таке'],
                    moreOptions: 'Так',
                    required: true,
                    validate: [validators.required],
                    validation: true,
                  }}
                  inputProps={{
                    label: t('Ссылка на прайс'),
                    validate: [validators.required],
                    validation: true,
                  }}
                  margin="normal"
                  name={formFields.readyToYmlFormat}
                  formName={formName}
                  fullWidth
                />
                <FieldWithRadioAndInput
                  selectProps={{
                    label: t('Являлись ли Вы ранее поставщиком Allo?'),
                    options: ['Так', 'Ні'],
                    moreOptions: 'Так',
                    required: true,
                    validate: [validators.required],
                    validation: true,
                  }}
                  inputProps={{
                    label: t('Название юр. лица, которое являлось поставщиком'),
                  }}
                  margin="normal"
                  name={formFields.beenAlloSupplier}
                  formName={formName}
                  fullWidth
                />
                <Field
                  component={CheckBoxForm}
                  defaultLabelStyle
                  label={t('У меня есть свой сервисный центр')}
                  name={formFields.hasServiceCenter}
                  margin="normal"
                />
                <Field
                  component={CheckBoxForm}
                  defaultLabelStyle
                  label={t('У меня есть свой контакт центр')}
                  name={formFields.hasContactCenter}
                />
                <br />
                { REACT_APP_AGREE_OFFER_SHOW === 'true'
                && (
                  <>
                    <br />
                    <Field
                      component={CheckBoxForm}
                      label={<>{t('Настоящим подтверждаю, что ознакомлен и согласен с условиями')} {agreeOfferLabel}</>}
                      name={formFields.agreeOffer}
                      required={agreeOfferRequired}
                      validate={[validators.required]}
                      validation
                    />
                  </>
                )
                }
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={userSubmitting}
                  sx={{ p: BUTTON_SIZE.middle }}
                >
                  {userSubmitting
                    ? <CircularProgress color="inherit" size={14} />
                    : t('Зарегистрироваться')
                  }
                </Button>
                <ButtonWrapper>
                  <Link to={routersLink.users.signIn}>
                    <Button>{t('Войти')}</Button>
                  </Link>
                </ButtonWrapper>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

SignUp.propTypes = {
  handleSubmit: PropTypes.func,
  signUpAsync: PropTypes.func,
};

SignUp.defaultProps = {
  handleSubmit: () => {},
  signUpAsync: () => {},
};

export default reduxForm({
  form: formName,
  initialValues: {
    [formFields.phone]: '380',
  },
})(SignUp);
