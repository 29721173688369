// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setFaqList(data) {
    return getActionCreator(types.SET_FAQ_LIST, data);
  },
  setFaqCurrentPage(currentPage) {
    return getActionCreator(types.SET_FAQ_CURRENT_PAGE, currentPage);
  },
  setFaqSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_FAQ_SETTING_STATUS, settingsStatus);
  },
  setFaqSettingsPending(settingPending) {
    return getActionCreator(types.SET_FAQ_SETTING_PENDING, settingPending);
  },
  setFaqFilters(filters) {
    return getActionCreator(types.SET_FAQ_FILTERS, filters);
  },
  setFaqPageSize(pageSize) {
    return getActionCreator(types.SET_FAQ_PAGE_SIZE, pageSize);
  },
  setFaqSelection(selection) {
    return getActionCreator(types.SET_FAQ_SELECTION, selection);
  },
  setFaqStatusesList(statusesList) {
    return getActionCreator(types.SET_FAQ_STATUSES_LIST, statusesList);
  },
  setFaqStatusesListPending(pending) {
    return getActionCreator(types.SET_FAQ_STATUSES_LIST_PENDING, pending);
  },
  setFaqSorting(sorting) {
    return getActionCreator(types.SET_FAQ_SORTING, sorting);
  },
  setFaqReload(reload) {
    return getActionCreator(types.SET_FAQ_RELOAD, reload);
  },
  setFaqInitialState(initialState) {
    return getActionCreator(types.SET_FAQ_INITIAL_STATE, initialState);
  },
  setIsItemByHashIdLoading(isLoading) {
    return getActionCreator(types.SET_IS_FAQ_ITEM_BY_HASH_ID_LOADING, isLoading);
  },
  setIsItemByHashIdPutPending(pending) {
    return getActionCreator(types.SET_IS_FAQ_ITEM_BY_HASH_PUT_PENDING, pending);
  },
  setIsDeletePending(pending) {
    return getActionCreator(types.SET_IS_FAQ_DELETE_PENDING, pending);
  },
});
