// Core
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// Parts
import RadioButtons from '../../../../../../../components/_Form/RadioButtons/RadioButtons/RadioButtons';
// Helpers
import { validators } from '../../../../../../../../_helpers/validationsForm';
import { formFields } from '../ProductMultipleEditingForm';
// Color
import { Fieldset, Legend } from '../styles';
import { COLOR } from '../../../../../../../containers/App/AppStyles';

function ProductFeatureAutoField() {
  const { t } = useTranslation();
  const options = [
    { label: t('Авто'), value: true },
    { label: t('Ручное'), value: false },
  ];
  return (
    <Fieldset sx={{ border: `1px solid ${COLOR.darkStyle.white['38']}` }} component="fieldset">
      <Legend sx={{ color: `${COLOR.darkStyle.white['54']}` }} component="legend">
        {t('Обновление характеристик')}
      </Legend>
      <Field
        fullWidth
        darkTheme
        name={formFields.featureAutoUpdate}
        component={RadioButtons}
        options={options}
        validate={[validators.required]}
        validation
        sxLabel={{ alignSelf: 'flex-start' }}
      />
    </Fieldset>
  );
}

export default ProductFeatureAutoField;
