// Core
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// UI
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Containers
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';

// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';

function AddButton() {
  const { t } = useTranslation();
  const isAllowedUserAdd = useAccessList(accessList.userCreate);

  if (!isAllowedUserAdd) {
    return null;
  }

  return (
    <NavLink to={pageLinks.settings.users.add}>
      <ButtonWithIcon text={t('Добавить пользователя')} sx={{ p: BUTTON_SIZE.withIconSmall }}>
        <AddCircleIcon />
      </ButtonWithIcon>
    </NavLink>
  );
}

AddButton.displayName = 'AddButton';

export default AddButton;
