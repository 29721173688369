// Core
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { styled } from '@mui/system';
// UI
import Box from '@mui/material/Box';
// Parts
import ContentProductsBulkEditButton from './components/ContentProductsBulkEditButton';
import TitleComponent from './components/TitleComponent/TitleComponent.js';
import ContentProductTable from './components/ContentProductTable/ContentProductTable/ContentProductTable';
import ContentProductTableFiltersButton from './components/ContentProductTableFiltersButton';
import CategoriesSelect from './components/ProductsCategoriesSelect';
import AttributesSelect from './components/ProductsAttributesSelect';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ProductAddButton from './components/ProductAddButton';
import ContentProductEditResultModal from '../ContentProductEdit/ContentProductEditResultModal';
import GridSettingsButton from '../../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ContentProductCategoryCountModal from './components/ContentProductCategoryCountModal/ContentProductCategoryCountModal';
import ProductsImportModal from './components/ProductsImportModal/ProductsImportModal';
import ProductsPropertiesImportModal from './components/ProductsPropertiesImportModal';
import ProductsPricesImportModal from './components/ProductsPricesImportModal/ProductsPricesImportModal';
import ModalConfirm from '../../../../containers/Modals/ModalConfirm';
import ContentProductLogsModal from './components/ContentProductLogModals/ContentProductLogsModal';
import ModalWithCategories from './components/ModalWithCategories';
import { TranslateModal } from './components/TranslateModal';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/content/saga/asyncActions';
import { asyncActions as contentProductsAsyncActions } from '../../../../../engine/core/contentProduct/saga/asyncActions';
import { actions } from '../../../../../engine/core/content/actions';
// Hooks
import { useContentProductList } from './_hooks/useContentProductList';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../engine/config/userRoles';
import ProductsLogisticsSidebar from './components/ProductsLogisticsSidebar/ProductsLogisticsSidebar';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ChartBarIcon from '../../../../icons/ChartBarIcon';
import { useUserRole } from '../../../../hooks/useUserRole';

const BoxWrapperTablePanel = styled(Box)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const BoxWrapperTableCategory = styled(Box)`
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
`;

function ContentProductsPage() {
  useContentProductList();
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productGridLoading = useSelector(selectors.content.productGridLoading);
  const productCategoryActiveItem = useSelector(selectors.contentProduct.productCategoryActiveItem);
  const productCategoryActiveItemValue = productCategoryActiveItem?.get('value');
  const hasProductCategoryActiveItem = !!productCategoryActiveItem;
  const fillingPercent = productCategoryActiveItem?.get('fillingPercent');
  const showFillingPercent = hasProductCategoryActiveItem && fillingPercent;
  const productsDownloadPending = useSelector(selectors.content.productDownloadPending);
  const productsDownloadForceAction = useSelector(selectors.content.productsDownloadForceAction);
  const productsDownloadType = productsDownloadForceAction?.get('type');
  const productsDownloadForceActionValue = productsDownloadForceAction?.get('forceAction');
  const isOpen = useSelector(selectors.content.importModalIsOpen);
  const isOpenProperties = useSelector(selectors.content.importPropertiesModalIsOpen);
  const isOpenPrices = useSelector(selectors.content.importPricesModalIsOpen);
  const settingStatus = useSelector(selectors.contentProduct.settingStatus);
  const settingPending = useSelector(selectors.contentProduct.settingPending);
  const attributeSetsSelectAllowed = useUserSomeRole([userRoles.admin, userRoles.contentManager]);
  const isAdmin = useUserRole(userRoles.admin);

  const [openLogisticsSidebar, setOpenLogisticsSidebar] = useState(false);

  const putSettings = () => {
    dispatch(contentProductsAsyncActions.putSettingsAsync());
  };
  const setImportModalIsOpen = () => {
    dispatch(actions.setImportModalIsOpen({ isOpen: false }));
  };
  const setImportPropertiesModalIsOpen = () => {
    dispatch(actions.setImportPropertiesModalIsOpen({ isOpen: false }));
  };
  const setImportPricesModalIsOpen = () => {
    dispatch(actions.setImportPricesModalIsOpen({ isOpen: false }));
  };
  const closeModalExport = () => {
    dispatch(actions.setDownloadForceAction({ forceAction: null }));
  };
  const confirmModalExport = useCallback(() => {
    const exportType = productsDownloadType;
    const forceAction = productsDownloadForceActionValue;
    if (exportType === 'export') {
      dispatch(asyncActions.getProductsExportAsync({
        categoryId,
        forceAction,
      }));
    }
    if (exportType === 'exportProperties') {
      dispatch(asyncActions.getProductsPropertiesExportAsync({
        categoryId,
        forceAction,
      }));
    }
    if (exportType === 'exportOnlyText') {
      dispatch(asyncActions.getProductsOnlyTextExportAsync({
        categoryId,
        onlyTextFeatures: 1,
        forceAction,
      }));
    }
    if (exportType === 'exportPrices') {
      dispatch(asyncActions.getProductsPricesExportAsync({
        categoryId,
        forceAction,
      }));
    }
  }, [dispatch, productsDownloadType, productsDownloadForceActionValue]);

  const textModalConfirm = t('Вы пытаетесь экспортировать много данных и это займет много времени');

  return (
    <>
      <PageHeader
        title={t('Товары')}
        titleDescription={showFillingPercent ? `${fillingPercent}%` : ''}
        titleDescriptionTooltip={showFillingPercent ? t('Минимальный процент заполненности категории') : ''}
        titleComponent={<TitleComponent />}
      >
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ProductAddButton />
        {productCategoryActiveItemValue && <ContentProductsBulkEditButton />}
      </PageHeader>
      <BoxWrapperTablePanel>
        <BoxWrapperTableCategory>
          <CategoriesSelect />
          {attributeSetsSelectAllowed && <AttributesSelect />}
        </BoxWrapperTableCategory>
        <Box mb="10px" sx={{ display: 'flex', gap: '8px', maxHeight: '32px' }}>
          {isAdmin && <ButtonWithIcon text={t('Статистика обработки')} onClick={() => setOpenLogisticsSidebar(true)}><ChartBarIcon sx={{ height: '24px', width: '24px' }} /></ButtonWithIcon>}
          <ContentProductTableFiltersButton />
        </Box>
      </BoxWrapperTablePanel>
      <ContentProductTable />
      {hasProductCategoryActiveItem && !productGridLoading && (
        <ContentProductEditResultModal />
      )}
      <ContentProductCategoryCountModal />
      <ProductsImportModal
        modalOpen={isOpen}
        handleModalToggle={setImportModalIsOpen}
      />
      <ProductsPropertiesImportModal
        modalOpen={isOpenProperties}
        handleModalToggle={setImportPropertiesModalIsOpen}
      />
      <ProductsPricesImportModal
        modalOpen={isOpenPrices}
        handleModalToggle={setImportPricesModalIsOpen}
      />
      <ModalConfirm
        confirmText={t('Подтвердить')}
        textAlign="center"
        pendingConfirm={productsDownloadPending}
        onCancel={closeModalExport}
        onConfirm={confirmModalExport}
        showModal={productsDownloadForceActionValue === 1}
        text={textModalConfirm}
        title={t('Внимание')}
        fullScreen={false}
      />
      <TranslateModal />
      <ContentProductLogsModal />
      <ModalWithCategories />
      <ProductsLogisticsSidebar openLogisticsSidebar={openLogisticsSidebar} setOpenLogisticsSidebar={setOpenLogisticsSidebar} />
    </>
  );
}

export default memo(ContentProductsPage);
