// Core
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { push } from 'redux-first-history';
// Components
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DxTable from '../../../components/_Table/DxTable/DxTable';
// Helpers
import { tableColumns } from '../../../../_helpers/data/pages/faqs/tableColumns';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
import { useFaqList } from '../_hooks/useFaqList';
// Engine
import { pageLinks } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/faq/actions';
import { asyncActions } from '../../../../engine/core/faq/saga/asyncActions';
// UI
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';

function FaqTable() {
  const dispatch = useDispatch();
  const userSettings = useSelector(selectors.user.userSettings);
  const rows = useSelector(selectors.faqs.list);
  const isLoading = useSelector(selectors.faqs.isLoading);
  // Paging
  const currentPage = useSelector(selectors.faqs.currentPage);
  const pageSize = useSelector(selectors.faqs.pageSize);
  const totalCount = useSelector(selectors.faqs.totalCount);
  const onPageSize = size => dispatch(actions.setFaqPageSize(size));
  const onCurrentPage = page => dispatch(actions.setFaqCurrentPage(page));
  // Sorting
  const onSortingChange = sorting => dispatch(actions.setFaqSorting(sorting));
  const sortingStateColumnExtensions = useSelector(selectors.faqs.sortingStateColumnExtensions);
  const sorting = useSelector(selectors.faqs.sorting);
  // Settings
  const settingStatus = useSelector(selectors.faqs.settingStatus);
  const settingPending = useSelector(selectors.faqs.settingPending);
  // Filtering
  const filters = useSelector(selectors.faqs.filters);
  const pathname = useSelector(selectors.router.pathname);
  const filtersAction = currentFilters => dispatch(actions.setFaqFilters(currentFilters));
  const filteringExtensions = useMemo(() => {
    switch (pathname) {
      case pageLinks.faq.published:
      case pageLinks.faq.new:
      case pageLinks.faq.review: {
        return [{ columnName: tableColumns.status.name, filteringEnabled: false }];
      }
      default: {
        return [];
      }
    }
  }, [pathname]);

  const EditComponent = (args) => {
    const { row: { hashId } } = args;
    const hasAccessEditFaq = useAccessList(accessList.faqQuestionEdit);
    const hasAccessShowFaq = useAccessList(accessList.faqQuestionShow);
    const questionStatus = get(args, `row.${tableColumns.status.name}.value`);
    const publishedStatus = 2;
    const isPublished = questionStatus === publishedStatus;

    const currentIcon = useMemo(() => (
      !isPublished && hasAccessEditFaq
        ? <ModeOutlinedIcon sx={{ p: '2px' }} />
        : <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
    ), [hasAccessEditFaq, isPublished]);

    const currentURL = useMemo(() => (
      !isPublished && hasAccessEditFaq
        ? pageLinks.faq.edit(hashId)
        : pageLinks.faq.show(hashId)
    ), [hasAccessEditFaq, hashId, isPublished]);

    const goToHandler = useCallback(() => {
      dispatch(push(currentURL));
    }, [currentURL]);

    return (hasAccessEditFaq || hasAccessShowFaq) && (
      <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" onClick={goToHandler} size="large">
        {currentIcon}
      </IconButtonStyles>
    );
  };

  return (
    <DxTable
      name="faq"
      fixedColumn
      isLoading={isLoading}
      updateHooks={useFaqList}
      rows={rows}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions.toJS()}
      // Editing
      editComponent={EditComponent}
      // Settings
      gridSettings={userSettings.faq.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putFaqSettingsAsync}
      // Filtering
      filters={filters}
      filteringExtensions={filteringExtensions}
      filtersAction={filtersAction}
    />
  );
}

export default FaqTable;
