// Core
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  change, Field, Form, reduxForm,
} from 'redux-form/immutable';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import CheckBoxForm from '../../../../../components/_Form/Checkbox/CheckBoxForm';
// Components
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import FilesUploader from '../../../../../components/_Form/FilesUploader/FilesUploader';
import FormHelperTextComponent from '../../../../../components/_Form/FormHelperTextComponent/FormHelperTextComponent';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
// Helpers
import { validators } from '../../../../../../_helpers/validationsForm';
// Engine
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';
import { accessList } from '../../../../../../engine/config/access';
import { asyncActions } from '../../../../../../engine/core/feeds/all/saga/asyncActions';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { selectors } from '../../../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../../../engine/core/_partners/saga/asyncAction';
// Style
import { TitleWithIcon } from '../../../styles';
import { CustomCard, CustomCardContent, CardNumber } from './styles';
import { BUTTON_SIZE, COLOR } from '../../../../../containers/App/AppStyles';
import { useUserSomeRole } from '../../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../../engine/config/userRoles';
import { useStyles } from '../../../../../hooks/useStyles';
import { uiActions } from '../../../../../../engine/core/ui/action';

export const formName = 'addFormXlsx';
export const formFields = Object.freeze({
  partner: 'partner',
  file: 'file',
  link: 'link',
  localeRU: 'localeRU',
  localeUK: 'localeUK',
});


const AddFormXlsxStyles = () => ({
  checkbox: {
    padding: '0',
    margin: '3px',
  },
  checkboxWrap: {
    '& .MuiFormControlLabel-root': {
      marginLeft: '-4px',
      marginRight: '32px',
    },
    '& .MuiFormControlLabel-label': {
      color: COLOR.black.default,
    },
  },
  radio: {
    padding: '0',
    margin: '2px',
  },
  controlLabel: {
    marginLeft: '-2px',
    gap: '3px',
    marginRight: '32px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: COLOR.black.default,
    },
  },
  buttonSmall: {
    position: 'absolute',
    top: '50%',
    right: '15px',
    padding: '4px',
    transform: 'translateY(-50%)',
    '&.Mui-disabled': {
      opacity: '0',
    },
  },
  iconSmall: {
    color: COLOR.grey['78'],
    fontSize: 18,
    '&:hover': {
      color: COLOR.grey['72'],
    },
  },
  linkFile: {
    paddingTop: '5px',
  },
  partnerSelectHolder: {
    paddingTop: '5px',
  },
});

function AddFormXlsx(props) {
  const {
    handleSubmit,
    invalid,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessPartnerContextList = useAccessList(accessList.partnerContextList);
  const partnerContextListPending = useSelector(selectors.partner.listContextLoading);
  const fileType = useSelector(selectors.ui.importPageFileType);
  const importPending = useSelector(selectors.feeds.importPending);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);
  const classes = useStyles(AddFormXlsxStyles);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const formFieldLink = getFormValues.get(formFields.link);

  const clearImportUrlField = () => {
    dispatch(change(formName, formFields.link, ''));
  };

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnerContextListAsync());
  }, [dispatch]);

  const handleSendFile = (formValue) => {
    const {
      partner, localeRU, localeUK, file, link,
    } = formValue.toJS();
    const locale = [
      localeRU ? 'ru' : undefined,
      localeUK ? 'uk' : undefined,
    ].filter(Boolean);

    dispatch(asyncActions.putFeedsImportAsync({
      partnerId: get(partner, 'value', null),
      fileType,
      locale,
      feedImportFile: file,
      feedImportFileUrl: link,
    }));
  };

  const handleChangeType = (event) => {
    dispatch(uiActions.setImportPageFileType(event.target.value));
  };

  return (
    <>
      <CustomCard>
        <CustomCardContent id="choose_lang_card">
          <TitleWithIcon gutterBottom variant="h6" sx={{ marginBottom: '12px' }} component="div">
            <CardNumber component="span">2</CardNumber>
            {t('Выберите язык контента прайса')}
          </TitleWithIcon>
          <Box className={classes.checkboxWrap} sx={{ marginTop: '12px', display: 'flex' }}>
            <Field
              component={CheckBoxForm}
              defaultLabelStyle
              color="secondary"
              name={formFields.localeUK}
              label={t('Украинский')}
              margin="none"
              checkboxClasses={classes.checkbox}
            />
            <Field
              component={CheckBoxForm}
              defaultLabelStyle
              color="secondary"
              label={t('Русский')}
              name={formFields.localeRU}
              margin="none"
              checkboxClasses={classes.checkbox}
            />
          </Box>
        </CustomCardContent>
      </CustomCard>
      <CustomCard>
        <CustomCardContent>
          <TitleWithIcon gutterBottom variant="h6" component="div">
            <CardNumber component="span">3</CardNumber>
            {t('Добавьте файл или ссылку')}
          </TitleWithIcon>
          <Box sx={{ marginTop: '20px' }}>
            <Form onSubmit={handleSubmit(handleSendFile)}>
              {hasAccessPartnerContextList && !isPartnerRoles && (
                <Box sx={{ maxWidth: '320px' }}>
                  <Field
                    required
                    selectFirst
                    name={formFields.partner}
                    component={PartnerSelect}
                    isPortal={false}
                    margin="normal"
                    label={t('Выберите партнера')}
                    disabled={isPartnerRoles}
                    validate={[validators.required]}
                    validation
                    id="import_mid_partner"
                    wrapperClassName={classes.partnerSelectHolder}
                  />
                </Box>
              )}
              <FormControl>
                <RadioGroup
                  row
                  defaultValue={fileType}
                  value={fileType}
                  name="file_type"
                  onChange={handleChangeType}
                >
                  <FormControlLabel id="import_link_radio" className={classes.controlLabel} value="link" control={<Radio className={classes.radio} color="secondary" />} label={t('Ссылка')} />
                  <FormControlLabel id="import_file_radio" className={classes.controlLabel} value="file" control={<Radio className={classes.radio} color="secondary" />} label={t('Файл')} />
                </RadioGroup>
              </FormControl>
              <Box sx={{ marginTop: '15px', width: 'fit-content', marginBottom: '32px' }} id="requirements_for_file">
                <Typography component="b" sx={{ fontWeight: 'bold', fontSize: '14px', color: COLOR.black.default }}>{t('Требования для файла')}</Typography>
                <ul style={{
                  fontSize: '14px', marginTop: '0', paddingLeft: '17px', lineHeight: '24px', color: COLOR.black.default,
                }}
                >
                  <li>
                    {t('Структура файла должна отвечать требованиям')}
                    &nbsp;(<Link sx={{ fontWeight: 'bold' }} color="secondary" target="_blank" href="/templates/example.xml">{t('шаблон YML')}</Link>, <Link sx={{ fontWeight: 'bold' }} color="secondary" target="_blank" href="/templates/example_hotline.xml">{t('шаблон Hotline XML')}</Link>)
                  </li>
                  <li>{t('Объем файла не более 500 Мб')}</li>
                </ul>
              </Box>
              {
                fileType === 'file'
                  ? (
                    <Field
                      margin="normal"
                      allowFileTypeValidation
                      acceptedFileTypes={[mimeTypeExtensions.xml]}
                      component={RenderFilesUploader}
                      labelFileTypeNotAllowed={t('Тип файла не валидный')}
                      textBefore2={t('Файл в формате XML')}
                      name={formFields.file}
                      validate={[validators.itemsRequiredValidator]}
                      classNameFilePond={'file_pond_xml'}
                    />
                  )
                  : (
                    <Box id="import_file_link" sx={{ maxWidth: '320px', marginBottom: '22px' }}>
                      <Field
                        required
                        name={formFields.link}
                        component={TextField}
                        wrapperClassName={classes.linkFile}
                        label={t('Адрес ссылки')}
                        fullWidth
                        // margin="normal"
                        validate={[validators.required]}
                        normalize={value => value.trim()}
                        endAdornment={formFieldLink && (
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.buttonSmall}
                              component="span"
                              disabled={false}
                              onClick={clearImportUrlField}
                              size="large"
                            >
                              <ClearIcon className={classes.iconSmall} />
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                    </Box>
                  )
              }
              <LoadingButton
                type="submit"
                sx={{ marginTop: '10px', p: BUTTON_SIZE.large }}
                variant="contained"
                color="primary"
                id="import_button_send"
                disabled={importPending || invalid || partnerContextListPending}
                loading={importPending}
              >
                {t('Импортировать')}
              </LoadingButton>
            </Form>
          </Box>
        </CustomCardContent>
      </CustomCard>
    </>
  );
}

export function RenderFilesUploader(fieldRenderProps) {
  const {
    meta: {
      touched,
      error,
    },
    ...rest
  } = fieldRenderProps;
  return (
    <>
      <FilesUploader {...rest} loadFileFromSaga />
      <FormHelperTextComponent
        error={error}
        touched={touched}
      />
    </>
  );
}

export default reduxForm({
  form: formName,
  validate: (value) => {
    const localeRU = value.get(formFields.localeRU);
    const localeUK = value.get(formFields.localeUK);
    return !localeRU && !localeUK
      ? { localeUK: 'Это обязательное поле' }
      : {};
  },
})(memo(AddFormXlsx));
