// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { actions } from '../../../actions';
import { localData } from '../../../../../config/localData';
import { api } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callDeleteAttachedFileWorker({ payload: { fileHashId, multipleModeContext, chatId } }) {
  yield put(actions.setIsChatFilePending(true));
  const response = yield apply(api, api.medias.deleteMedias, [fileHashId]);
  if (response && response.status >= 200 && response.status < 300) {
    if (response.data) {
      const { status, statusTitle } = response.data;
      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          const chatData = yield apply(localData, localData.getItem, [multipleModeContext]);
          // eslint-disable-next-line
          for (const [chatHashId, currentChatData] of Object.entries(chatData)) {
            if (chatHashId === chatId) {
              chatData[chatId].files = currentChatData.files.filter(file => file.hashId !== fileHashId);
            }
          }
          yield apply(localData, localData.setItem, [multipleModeContext, chatData]);
          yield put(actions.setFilesInChatMessage(chatData[chatId].files));
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(actions.setIsChatFilePending(false));
}
