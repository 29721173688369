// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { actions } from '../../../../../../engine/core/products/productFeedLogs/actions';
import confirmButtonsStyles from '../../../../../components/Buttons/ConfirmButtons/ConfirmButtonsStyles';

const useStyles = makeStyles(confirmButtonsStyles);

function ProductsFeedLogs(props) {
  const {
    hashId,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleLog = () => {
    dispatch(actions.getProductFeedLogsRefresh({ showModal: true, hashId }));
  };

  return (
    <Button
      sx={{ p: '0px' }}
      className={classes.selectWidthConfirmShowLog}
      onClick={handleLog}
    >
      <SmsOutlined />
    </Button>
  );
}

ProductsFeedLogs.propTypes = {
  hashId: PropTypes.string,
};

export default ProductsFeedLogs;
