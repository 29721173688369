import ReactGA from 'react-ga';
import { history } from './store';

export const googleAnalytics = () => {
  const googleAnalyticsIsActive = process.env.REACT_APP_GA === 'true';
  if (googleAnalyticsIsActive) {
    const trackingId = process.env.REACT_APP_GA_KEY;
    ReactGA.initialize(trackingId);
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }
};
