// Core
import { put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../config/localData';
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';
import { partnerActions } from '../../action';

export function* callGetPartnerManagerInitialStateWorker({ payload: url }) {
  const state = yield localData.getItem(url);
  const initialPageState = getInitialPageState(state || {});
  yield put(partnerActions.setPartnerManagerInitialState(initialPageState));
}
