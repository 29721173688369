// Core
import {
  apply, put, select, delay,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../../config/selectors';
import { pageLinks } from '../../../../../config/routes';

export function* callDeletePartnerAccessWorker({ payload }) {
  const { hashId, type } = payload;
  yield put(actions.deletePartnerAccess({ pending: true }));

  const response = yield apply(api, api.partners.deletePaymentAccess, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        if (type === 'list') {
          const pageSize = yield select(selectors.partnersAccess.pageSize);
          const currentPage = yield select(selectors.partnersAccess.currentPage);
          const sorting = (yield select(selectors.partnersAccess.sorting)).toJS();
          const filters = (yield select(selectors.partnersAccess.filters)).toJS();

          const params = {
            sorting,
            limit: pageSize,
            offset: currentPage * pageSize,
          };

          filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, params);

          yield put(asyncActions.getListAsync(params));
        } else {
          yield delay(0);
          yield put(push(pageLinks.partner.accesses));
        }
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.deletePartnerAccess({ pending: false }));
}
