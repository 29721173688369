import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import { actions } from '../../../../../engine/core/partners/partnerServiceRequests/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { usePartnerServiceRequestList } from '../../_hooks/usePartnerServiceRequestList';
import { asyncActions } from '../../../../../engine/core/partners/managers/saga/asyncActions';
import TableEditCell from './TableEditCell';
import { getStatusColor } from '../../../../../engine/config/serviceRequestsStatuses';

export function PartnerServiceRequestsTable() {
  const dispatch = useDispatch();
  const items = useSelector(selectors.partnersServiceRequests.list);
  const isLoading = useSelector(selectors.partnersServiceRequests.isLoading);
  const filters = useSelector(selectors.partnersServiceRequests.filters);
  const sorting = useSelector(selectors.partnersServiceRequests.sorting);
  const pageSize = useSelector(selectors.partnersServiceRequests.pageSize);
  const currentPage = useSelector(selectors.partnersServiceRequests.currentPage);
  const totalCount = useSelector(selectors.partnersServiceRequests.totalCount);
  const gridSettings = useSelector(selectors.user.userSettings);

  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));

  const styledItems = items.map((item) => {
    const color = getStatusColor(item.statusValue);
    const styledStatus = <Box sx={{ color }}>{item.statusLabel}</Box>;
    return { ...item, status: styledStatus };
  });

  return (
    <>
      <DxTable
        name="partnerConnectedServiceReqeusts"
        gridSettings={gridSettings.partnerServiceRequests.gridSettings}
        fixedColumn
        showShortText
        editComponent={TableEditCell}
        isLoading={isLoading}
        rows={styledItems}
        updateHooks={usePartnerServiceRequestList}
        // Paging
        pageSize={pageSize}
        totalCount={totalCount}
        onPageSize={onPageSize}
        currentPage={currentPage}
        onCurrentPage={onCurrentPage}
        // rowComponent={rowComponent}
        // Sorting
        onSortingChange={onSortingChange}
        sorting={sorting}
        // Filters
        filters={filters}
        filtersAction={actions.setFilters}
        // Settings
        settingStatus={false}
        settingPending={false}
        settingAction={asyncActions.putSettingsAsync}
      />
    </>
  );
}
