export const types = Object.freeze({
  SET_STOP_WORDS_LIST: 'SET_STOP_WORDS_LIST',
  SET_STOP_WORDS_CURRENT_PAGE: 'SET_STOP_WORDS_CURRENT_PAGE',
  SET_STOP_WORDS_FILTERS: 'SET_STOP_WORDS_FILTERS',
  SET_STOP_WORDS_PAGE_SIZE: 'SET_STOP_WORDS_PAGE_SIZE',
  SET_STOP_WORDS_SORTING: 'SET_STOP_WORDS_SORTING',
  SET_STOP_WORDS_RELOAD: 'SET_STOP_WORDS_RELOAD',
  SET_STOP_WORD_HASH_ID: 'SET_STOP_WORD_HASH_ID',
  SET_STOP_WORDS_SELECTION: 'SET_STOP_WORDS_SELECTION',
  SET_STOP_WORDS_INITIAL_STATE: 'SET_STOP_WORDS_INITIAL_STATE',
  SET_STOP_WORDS_SETTING_STATUS: 'SET_STOP_WORDS_SETTING_STATUS',
  SET_STOP_WORDS_SETTING_PENDING: 'SET_STOP_WORDS_SETTING_PENDING',
  MERGE_DELETE_STOP_WORD: 'MERGE_DELETE_STOP_WORD',
  MERGE_POST_STOP_WORD: 'MERGE_POST_STOP_WORD',
  MERGE_GET_STOP_WORD: 'MERGE_GET_STOP_WORD',
  MERGE_PUT_STOP_WORD: 'MERGE_PUT_STOP_WORD',
  MERGE_UPLOAD_STOP_WORD: 'MERGE_UPLOAD_STOP_WORD',
  MERGE_GET_EXPORT_STOP_WORDS: 'MERGE_GET_EXPORT_STOP_WORDS',
});
