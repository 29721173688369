// core
import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Parts
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ExeptionWordGroupFields from './ExeptionWordGroupFields';

function ExeptionWordGroupsFields(props) {
  const { fields } = props;
  const { t } = useTranslation();
  const [firstGroup, setFirstGroup] = useState(true);
  const groupAdd = useCallback(() => {
    fields.push({});
  }, [fields]);

  useEffect(() => {
    if (firstGroup && !fields.length) {
      setFirstGroup(false);
      groupAdd();
    }
  }, [firstGroup, setFirstGroup, groupAdd, fields]);
  return (
    <>
      <Grid container>
        <Grid item sx={{ marginTop: '15px' }} xs={12}>
          <Typography variant="body1" component="div" mb={2}>
            {t('Слова-исключения')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {fields.map((member, index) => (
            <ExeptionWordGroupFields
              key={index}
              fields={fields}
              member={member}
              index={index}
            />
          ))}
          <ButtonWithIcon
            text={t('Добавить')}
            onClick={groupAdd}
            sx={{ marginTop: '15px' }}
          >
            <AddCircleIcon />
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </>
  );
}

ExeptionWordGroupsFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default memo(ExeptionWordGroupsFields);
