import React, {
  useEffect, useState, memo,
} from 'react';
import get from 'lodash/get';
import union from 'lodash/union';
import { Field } from 'redux-form/immutable';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// UI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormInput from '../../TextField/FormTextField/FormTextField';
import FormSelect from '../FormSelect/FormSelect';
import { selectors } from '../../../../../engine/config/selectors';

function FieldWithSelectAndInput(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    selectProps: { options, elseOption, ...restSelect }, inputProps, fullWidth, name, formName, margin,
  } = props;
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValues = formValues.toJS();
  const value = get(normalizeFormValues, name, undefined);
  const optionsNotIncludesValue = options.includes(value);
  const optionsToSelect = union(options, [elseOption]).map(item => ({ value: item, label: t(item) }));
  const [showInput, setShowInput] = useState(optionsNotIncludesValue);
  const handleChange = (newValue) => {
    if (newValue === elseOption) {
      dispatch(change(formName, `${name}Select`, elseOption));
      setTimeout(() => {
        setShowInput(true);
        if (optionsNotIncludesValue) {
          dispatch(change(formName, name, ''));
        } else {
          dispatch(change(formName, name, value || ''));
        }
      }, 0);
    } else {
      setShowInput(false);
      dispatch(change(formName, name, newValue));
    }
  };
  useEffect(() => {
    if (value && !optionsNotIncludesValue) {
      setShowInput(true);
      dispatch(change(formName, `${name}Select`, elseOption));
      dispatch(change(formName, name, value));
    }
  }, [dispatch, elseOption, formName, name, optionsNotIncludesValue, value]);
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <Field
        component={FormSelect}
        variant="standard"
        name={showInput ? `${name}Select` : name}
        {...restSelect}
        options={optionsToSelect}
        fullWidth={fullWidth}
        formSelectWidth={fullWidth && '100%'}
        handleChange={handleChange}
      />
      {showInput
      && (
        <Box sx={{ mt: '24px' }}>
          <Field
            component={FormInput}
            name={name}
            {...inputProps}
            autoFocus
            fullWidth={fullWidth}
          />
        </Box>
      )}
    </FormControl>
  );
}

FieldWithSelectAndInput.propTypes = {
  selectProps: PropTypes.shape({
    options: PropTypes.array,
    elseOption: PropTypes.string,
  }),
  inputProps: PropTypes.shape({
    label: PropTypes.string,
    validation: PropTypes.bool,
  }),
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  formName: PropTypes.string,
  margin: PropTypes.string,
};

export default memo(FieldWithSelectAndInput);
