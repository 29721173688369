const DataRangeStyles = theme => ({
  dataRange: {
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& .rdrDefinedRangesWrapper': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '& .rdrMonth': {
      [theme.breakpoints.down('sm')]: {
        width: '21.667em',
      },
    },

    '& .rdrWeekDays': {
      '& .rdrWeekDay': {
        textTransform: 'capitalize',
        fontWeight: '700',
      },
    },
  },
});

export default DataRangeStyles;
