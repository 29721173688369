// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/priceCreator/priceCreatorFiles/saga/asyncActions';
import { actions } from '../../../../../engine/core/priceCreator/priceCreatorFiles/actions';
// Hooks
import { usePriceCreatorFilesList } from '../_hooks/usePriceCreatorFilesList';

function PriceCreatorFilesTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.priceCreatorFiles.isLoading);
  // Rows
  const rows = useSelector(selectors.priceCreatorFiles.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.priceCreatorFiles.currentPage);
  const pageSize = useSelector(selectors.priceCreatorFiles.pageSize);
  const totalCount = useSelector(selectors.priceCreatorFiles.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.priceCreatorFiles.sorting);
  // Settings
  const settingStatus = useSelector(selectors.priceCreatorFiles.settingStatus);
  const settingPending = useSelector(selectors.priceCreatorFiles.settingPending);
  // Filtering
  const filters = useSelector(selectors.priceCreatorFiles.filters);

  return (
    <DxTable
      name="xmlFiles"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePriceCreatorFilesList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.xmlFiles.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(PriceCreatorFilesTable);
