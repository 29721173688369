// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// lodash
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';

// config
import { selectors } from '../../../../../../../../engine/config/selectors';

// form
import { messageFormName } from '../../../form';

export function useOptionsTypesSelectControl() {
  const userGroup = useSelector(selectors.messages.userGroup);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, messageFormName));

  const optionsTypesSelect = useMemo(() => {
    const fields = getFormValues.toJS();
    return map(userGroup.toJS(), group => ({
      ...group,
      isDisabled: !isEmpty(find(fields.messageUsers, { type: group.value })),
    }));
  }, [userGroup, getFormValues]);

  const isDisabledTypesSelect = useMemo(
    () => isEmpty(filter(optionsTypesSelect, option => !option.disabled)),
    [optionsTypesSelect],
  );

  return { optionsTypesSelect, isDisabledTypesSelect };
}
