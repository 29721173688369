// Core
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import {
  reduxForm, Form, SubmissionError, formValueSelector,
} from 'redux-form/immutable';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { List } from 'immutable';
// Icons
import SaveIcon from '@mui/icons-material/Save';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Parts
import Grid from '@mui/material/Grid';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import UserEditSystem from '../components/PartnerEditSystem';
import UserEditSite from '../components/PartnerEditSite/PartnerEditSite';
import UserEditFinance from '../components/PartnerEditFinance';
import PartnerEditContract from '../components/PartnerEditContract';
import PartnerEditStatusesHistory from '../components/PartnerEditStatusesHistory';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import PartnerConfirmFormRejectButton from './PartnerConfirmFormRejectButton';
import PartnerConfirmFormApproveButton from './PartnerConfirmFormApproveButton';
import PartnerRejectAllValuesButton from './PartnerRejectAllValuesButton';
import ReasonRejectionModal from '../components/ReasonRejection/ReasonRejectionModal';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import PartnerEditPaymentTypesData from '../components/PartnerEditPaymentTypesData';
import PartnerIsTopModal from '../components/PartnerIsTopModal';
import paymentTypesDocsModalFields from '../components/PaymentTypesDocsModal/fields';
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import TabsNav from '../../../containers/Tabs/TabsNav';
import TabItemMenu from '../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsNavMenu from '../../../containers/Tabs/TabsNavMenu';
// Engine
import { partnerActions } from '../../../../engine/core/_partners/action';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import { asyncActions as partnersAsyncAction } from '../../../../engine/core/partners/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { asyncActions as categoriesAsyncActions } from '../../../../engine/core/categories/saga/asyncActions';
import { accessList } from '../../../../engine/config/access';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';
import { userRoles } from '../../../../engine/config/userRoles';
import { pageLinks } from '../../../../engine/config/routes';
// _helpers
import { formName } from '../form';
import PartnerEditStyle from './PartnerEditStyle';
import { setWarningMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import ButtonPaymentAdd from '../../../components/Buttons/ButtonPaymentAdd';
import { withRouter } from '../../../../_helpers/withRouter';
import { exepFields } from '../../../../_helpers/checkPartnerApprovedData';

function mapStateToProps(state, ownProps) {
  const hashId = get(ownProps, 'router.params.hashId', '');
  return {
    statusesList: selectors.partner.statusesList(state),
    partnerData: selectors.partner.userData(state),
    hashId: hashId || selectors.partner.partnerHashId(state),
    partnerHashIdConfirmPending: selectors.partners.partnerHashIdConfirmPending(state),
    brandsList: selectors.brands.brandsListContext(state),
    categoriesList: selectors.categories.categoriesListContext(state),
    rolesList: selectors.user.rolesList(state),
    mode: selectors.partnerEditMode(state),
    hasAccessPartnerStatusesHistoryList: selectors.user.accessList(state).includes(accessList.partnerStatusesHistoryList),
    hasAccessOnePartnerXlsDownload: selectors.user.accessList(state).includes(accessList.onePartnerXlsDownload),
    hasAccessPartnerOfferContract: selectors.user.accessList(state).includes(accessList.partnerOfferContract),
    hasAccessPartnerSendContractToSed: selectors.user.accessList(state).includes(accessList.partnerSendContractToSed),
    hasAccessBillingPaymentForPartner: selectors.user.accessList(state).includes(accessList.billingPaymentForPartner),
    partnerDownloadIsLoading: selectors.partner.partnerDownloadIsLoading(state),
    partnersProcessPending: selectors.partners.partnersProcessPending(state),
    partnerDownloadContractCommissionIsLoading: selectors.partner.partnerDownloadContractCommissionIsLoading(state),
    partnerSavePending: selectors.partner.partnerSavePending(state),
    sendContractToSedPending: selectors.partner.sendContractToSedPending(state),
    postUsersHashIdPending: selectors.user.postUsersHashIdPending(state),
    partnerContractLink: selectors.partner.partnerContractLink(state),
    selectedPartnerStatus: formValueSelector(formName)(state, 'partnerStatus'),
    formValues: selectors.form.getFormValues(state, formName),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPartnerHashId: hashId => dispatch(partnerActions.setPartnerHashId(hashId)),
    putPutSendContractToSed: hashId => dispatch(partnerAsyncAction.putPutSendContractToSed(hashId)),
    getPartnerDownloadAsync: hashId => dispatch(partnerAsyncAction.getPartnerDownloadAsync(hashId)),
    getPartnerContractCommissionDownloadAsync: hashId => dispatch(partnerAsyncAction.getPartnerContractCommissionDownloadAsync(hashId)),
    uploadPartnerLogo: config => dispatch(partnerAsyncAction.uploadPartnerLogo(config)),
    uploadPartnerContract: contractFile => dispatch(partnerAsyncAction.uploadPartnerContact(contractFile)),
    getPartnerStatusesList: () => dispatch(partnerAsyncAction.getPartnerStatusesList()),
    putPartnersProcessAsync: hashId => dispatch(partnersAsyncAction.putPartnersProcessAsync(hashId)),
    putPartnerInfoAsync: config => dispatch(partnerAsyncAction.putPartnerInfoAsync(config)),
    getPartnerInfoByHashId: hashId => dispatch(partnerAsyncAction.getPartnerInfo({ hashId })),
    getCategoriesListContextAsync: config => dispatch(categoriesAsyncActions.getCategoriesListContextAsync(config)),
  };
}

const onSubmitFail = (errors, dispatch, submitError, props) => {
  const { t } = props;
  const title = `${t('Ошибка валидации')}:`;

  const hasMatch = exepFields.some(field => Object.keys(errors).includes(field));
  const message = submitError instanceof SubmissionError
    ? t('Пожалуйста, выберите правильные значения для всех полей!')
    : t('Пожалуйста, заполните все обязательные поля!');
  if (hasMatch) {
    dispatch(setWarningMessage(t('Убедитесь в корректности введенных данных'), title));
  } else {
    dispatch(setWarningMessage(message, title));
  }
};

class PartnerEdit extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setPartnerHashId: PropTypes.func.isRequired,
    getPartnerStatusesList: PropTypes.func.isRequired,
    partnerData: PropTypes.object.isRequired,
    statusesList: PropTypes.instanceOf(List),
    selectedPartnerStatus: PropTypes.number,
    hashId: PropTypes.string,
    sendContractToSedPending: PropTypes.bool,
    putPutSendContractToSed: PropTypes.func,
    partnerSavePending: PropTypes.bool,
    postUsersHashIdPending: PropTypes.bool,
    partnersProcessPending: PropTypes.bool,
    putPartnersProcessAsync: PropTypes.func,
    hasAccessOnePartnerXlsDownload: PropTypes.bool,
    hasAccessPartnerOfferContract: PropTypes.bool,
    hasAccessPartnerStatusesHistoryList: PropTypes.bool,
    hasAccessPartnerSendContractToSed: PropTypes.bool,
    hasAccessBillingPaymentForPartner: PropTypes.bool,
    partnerDownloadIsLoading: PropTypes.bool,
    partnerDownloadContractCommissionIsLoading: PropTypes.bool,
    uploadPartnerLogo: PropTypes.func.isRequired,
    uploadPartnerContract: PropTypes.func.isRequired,
    partnerContractLink: PropTypes.string,
    getPartnerDownloadAsync: PropTypes.func.isRequired,
    partnerHashIdConfirmPending: PropTypes.bool.isRequired,
    getPartnerContractCommissionDownloadAsync: PropTypes.func.isRequired,
    putPartnerInfoAsync: PropTypes.func.isRequired,
    getPartnerInfoByHashId: PropTypes.func.isRequired,
    categoriesList: PropTypes.object,
    rolesList: PropTypes.array.isRequired,
    t: PropTypes.func,
    getCategoriesListContextAsync: PropTypes.func,
    mode: PropTypes.oneOf(['create', 'edit', 'show']),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'system',
    };

    this.inApprove = memoize(this.inApprove);
  }

  componentDidMount() {
    this.getPartnerInfo();
    this.getPartnerInfoByHashId();
    this.getCategoriesListContextAsync();
  }

  componentDidUpdate(prevProps) {
    const { hashId, postUsersHashIdPending, getPartnerStatusesList } = this.props;
    if (hashId && hashId !== prevProps.hashId) {
      this.getPartnerInfoByHashId();
    }
    const prevPostUsersHashIdPending = prevProps.postUsersHashIdPending;
    if ((postUsersHashIdPending !== prevPostUsersHashIdPending) && prevPostUsersHashIdPending === true) {
      this.getPartnerInfoByHashId();
      getPartnerStatusesList();
    }
  }

  componentWillUnmount() {
    this.setPartnerHashId(null);
  }

  getCategoriesListContextAsync = () => {
    const { getCategoriesListContextAsync } = this.props;
    getCategoriesListContextAsync({ loadRootCategories: true, parentValue: 0 });
  };

  handleChangeTab = (event, value) => {
    this.setState({ selectedTab: value });
  };

  getPartnerInfo = () => {
    const { hashId } = this.props;
    if (typeof hashId === 'string') {
      this.setPartnerHashId(hashId);
    }
  };

  setPartnerHashId = (hashId) => {
    const { setPartnerHashId } = this.props;
    setPartnerHashId(hashId);
  };

  downloadPartnerInfo = () => {
    const { hashId, getPartnerDownloadAsync } = this.props;
    if (typeof hashId === 'string') {
      getPartnerDownloadAsync(hashId);
    }
  };

  putPutSendContractToSed = () => {
    const { hashId, putPutSendContractToSed } = this.props;
    if (typeof hashId === 'string') {
      putPutSendContractToSed(hashId);
    }
  };

  downloadContractCommission = () => {
    const { hashId, getPartnerContractCommissionDownloadAsync } = this.props;
    if (typeof hashId === 'string') {
      getPartnerContractCommissionDownloadAsync(hashId);
    }
  };

  getPartnerInfoFieldsValue = (formFieldName, data) => {
    const { partnerData } = this.props;
    const approvedValue = get(partnerData, `infoFieldValues.${formFieldName}.approvedValue`);
    if (approvedValue) {
      switch (formFieldName) {
        case 'smallLogo': {
          return get(partnerData, `infoFieldValues.${formFieldName}.${approvedValue}Id`);
        }
        default: {
          return approvedValue === 'current'
            ? get(data, formFieldName)
            : get(partnerData, `infoFieldValues.${formFieldName}.${approvedValue}`);
        }
      }
    }
  };

  getPartnerInfoFieldCurrentValue = (formFieldName) => {
    const { partnerData } = this.props;
    switch (formFieldName) {
      case 'smallLogo': {
        return get(partnerData, `infoFieldValues.${formFieldName}.currentId`);
      }
      default: {
        return get(partnerData, `infoFieldValues.${formFieldName}.current`);
      }
    }
  };

  onFormSubmit = (immutableData, redirect) => {
    const {
      putPartnerInfoAsync, hashId, partnerData, t,
      statusesList, selectedPartnerStatus, rolesList,
    } = this.props;

    const ignoreStatusesList = statusesList.toJS().filter(item => item.ignoreValidate === true && selectedPartnerStatus === item.value);
    const ignoreValidateFields = (
      !!ignoreStatusesList.length);

    const inApprove = this.inApprove(rolesList, [userRoles.salesManager, userRoles.admin]);
    let data;

    if (immutableData && immutableData.toJS) {
      data = immutableData.toJS();
    }
    if (typeof hashId === 'string') {
      const { infoFieldValues } = partnerData;
      const errors = { _error: 'submit error' };

      if (!inApprove && !ignoreValidateFields) {
        forEach(infoFieldValues, (value, key) => {
          if ((value.old !== undefined) && !value.approvedValue) {
            errors[key] = t('Выберите правильное значение');
          }
        });

        if (Object.keys(errors).length > 1) {
          throw new SubmissionError(errors);
        }
      }

      let partnerStatus = get(partnerData, 'partnerStatus.value');
      const changedPartnerStatus = get(data, 'partnerStatus.value');

      if (partnerStatus && changedPartnerStatus) {
        if (partnerStatus.toString() !== changedPartnerStatus.toString()) {
          partnerStatus = parseInt(changedPartnerStatus, 10);
          if (Number.isNaN(partnerStatus)) {
            partnerStatus = undefined;
          }
        } else {
          partnerStatus = undefined;
        }
      }

      const partnerOfferPercentKey = 'partnerOfferPercent';
      const partnerOfferPercentOldKey = 'partnerOfferPercentOld';
      const partnerOfferPercentApprovedKey = 'partnerOfferPercentApproved';
      const accessBlockContentKey = 'accessBlockContent';
      const accessBillingKey = 'accessBilling';
      const accessFeedKey = 'accessFeed';
      const brandsKey = 'brands';
      const categoriesKey = 'categories';
      const accessRatingRatioKey = 'accessRatingRatio';
      const isTopKey = 'isTop';
      const noticeDateAtKey = 'noticeDateAt';
      const cancelCommentKey = 'cancelComment';
      const partnerCancelStatusKey = 'partnerCancelStatus';

      const partnerDataAccessBlockContent = get(partnerData, accessBlockContentKey);
      const partnerDataAccessBillingContent = get(partnerData, accessBillingKey);
      const partnerDataAccessFeed = get(partnerData, accessFeedKey);
      const categoriesPercent = get(data, 'categoriesPercent');
      const categoriesPercentValue = get(data, 'categoriesPercentValue');
      const partnerDataBrands = get(partnerData, brandsKey);

      const cancelComment = get(data, cancelCommentKey);
      const partnerCancelStatus = get(data, partnerCancelStatusKey);

      let partnerDataCategories = get(partnerData, categoriesKey);
      let accessBlockContent = get(data, accessBlockContentKey);
      let accessBilling = get(data, accessBillingKey);
      let accessFeed = get(data, accessFeedKey);
      let categories = get(data, categoriesKey);
      let brands = get(data, brandsKey);
      const accessRatingRatio = get(data, accessRatingRatioKey);
      const isTop = get(data, isTopKey);
      const noticeDateAt = get(data, noticeDateAtKey);

      if (categoriesPercent && categoriesPercentValue) { // Set new percentValue from input
        categoriesPercent.map((item) => {
          const newItem = item;
          Object.keys(categoriesPercentValue).forEach((itemKey) => {
            if (item.value === parseInt(itemKey, 10)) {
              newItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
            }
          });
          return newItem;
        });
      }
      if (categoriesPercent && partnerDataCategories) {
        partnerDataCategories = partnerDataCategories.map((currentPartnerDataCategories, idx) => {
          const currentCategoriesPercent = categoriesPercent[idx];
          const partnerOfferPercent = currentCategoriesPercent[partnerOfferPercentKey]; // In https://jira.forforce.com/browse/AMP-1885 change from let
          const categoryItem = {
            label: currentCategoriesPercent.label,
            partnerOfferPercent,
            value: currentCategoriesPercent.value,
          };

          if (partnerOfferPercentKey in categoryItem) {
            categoryItem[partnerOfferPercentKey] = Number.parseInt(categoryItem[partnerOfferPercentKey], 10);
            if (Number.isNaN(categoryItem[partnerOfferPercentKey])) {
              delete categoryItem[partnerOfferPercentKey];
            }
          }
          return categoryItem;
        });
      }

      const changedPartnerCategoriesPercent = partnerDataCategories && partnerDataCategories.filter(Boolean);
      const changedPartnerCategoriesList = categories.filter((item) => {
        if (partnerOfferPercentKey in item || partnerOfferPercentOldKey in item || partnerOfferPercentApprovedKey in item) {
          return;
        }
        return item;
      });

      const currentCategoryData = categories.map((item) => {
        let currentItem = item;
        if (categoriesPercentValue) {
          Object.keys(categoriesPercentValue).forEach((itemKey) => {
            if (item.value === parseInt(itemKey, 10)) {
              currentItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
            }
          });
        }

        if (changedPartnerCategoriesPercent && changedPartnerCategoriesPercent.length) {
          changedPartnerCategoriesPercent.map((percentItem) => {
            if (percentItem.value === item.value) {
              currentItem = percentItem;
            }
            return percentItem;
          });
        }
        return currentItem;
      });

      if (inApprove) {
        accessBlockContent = isEqual(accessBlockContent, partnerDataAccessBlockContent) ? undefined : accessBlockContent;
        accessBilling = isEqual(accessBilling, partnerDataAccessBillingContent) ? undefined : accessBilling;
        accessFeed = isEqual(accessFeed, partnerDataAccessFeed) ? undefined : accessFeed;
        brands = isEqual(brands, partnerDataBrands) ? undefined : brands;
        if ((changedPartnerCategoriesPercent && changedPartnerCategoriesPercent.length) || (changedPartnerCategoriesList && changedPartnerCategoriesList.length)) {
          categories = currentCategoryData;
        }
      }

      const isPartnerRoles = rolesList.some(currentRole => [
        userRoles.partner,
        userRoles.partnerManageProfile,
        userRoles.partnerActive,
        userRoles.partnerRating,
        userRoles.partnerContentManager,
        userRoles.partnerBilling,
      ].indexOf(currentRole) !== -1);

      let warehouses = data?.warehouses.map(item => item?.label ?? item) ?? [];
      const mainWarehouse = data.mainWarehouse?.label ?? data?.mainWarehouse;

      const warehousesErrors = {};
      warehousesErrors.warehouses = [];
      // eslint-disable-next-line no-underscore-dangle
      warehousesErrors._error = 'submit error';

      if (isPartnerRoles) {
        warehouses.forEach((item, index) => {
          if (item) {
            warehousesErrors.warehouses[index] = t('Выберите город расположения склада товаров');
            throw new SubmissionError(warehousesErrors);
          }
          if (item) {
            warehousesErrors.warehouses[index] = t('Пожалуйста, проверьте правильность ввода города');
            throw new SubmissionError(warehousesErrors);
          }
        });

        if (!mainWarehouse) {
          throw new SubmissionError({
            mainWarehouse: t('Выберите город расположения склада товаров'),
            _error: 'submit error',
          });
        }

        if (!mainWarehouse) {
          throw new SubmissionError({
            mainWarehouse: t('Пожалуйста, проверьте правильность ввода города'),
            _error: 'submit error',
          });
        }
      } else {
        warehouses = warehouses.filter(item => typeof item === 'string' ? !!item.trim() : !!item);
      }

      const formData = {
        brands,
        categories,
        partnerStatus,
        accessBlockContent,
        accessBilling,
        accessFeed,
        accessRatingRatio,
        isTop,
        noticeDateAt,
        infoFieldsValues: {
          warehouses: [mainWarehouse, ...warehouses].filter(Boolean),
          companyName: this.getPartnerInfoFieldsValue('companyName', data),
          site: this.getPartnerInfoFieldsValue('site', data),
          deliveryTime: this.getPartnerInfoFieldsValue('deliveryTime', data),
          deliveryTimeFrom: this.getPartnerInfoFieldsValue('deliveryTimeFrom', data),
          deliveryTimeTo: this.getPartnerInfoFieldsValue('deliveryTimeTo', data),
          detailedInformation: this.getPartnerInfoFieldsValue('detailedInformation', data),
          partnerInfo: this.getPartnerInfoFieldsValue('partnerInfo', data),
          warrantyAndReturn: this.getPartnerInfoFieldsValue('warrantyAndReturn', data),
          enterpriseName: this.getPartnerInfoFieldsValue('enterpriseName', data),
          address: this.getPartnerInfoFieldsValue('address', data),
          addressIndex: this.getPartnerInfoFieldsValue('addressIndex', data),
          city: this.getPartnerInfoFieldsValue('city', data),
          enterpriseClassifierCode: this.getPartnerInfoFieldsValue('enterpriseClassifierCode', data),
          taxIdentification: this.getPartnerInfoFieldsValue('taxIdentification', data),
          enterpriseGroupCode: this.getPartnerInfoFieldsValue('enterpriseGroupCode', data),
          automaticVoucherGeneration: this.getPartnerInfoFieldsValue('automaticVoucherGeneration', data),
          enterpriseType: this.getPartnerInfoFieldsValue('enterpriseType', data),
          formOfOwnership: this.getPartnerInfoFieldsValue('formOfOwnership', data),
          taxationType: this.getPartnerInfoFieldsValue('taxationType', data),
          iban: this.getPartnerInfoFieldsValue('iban', data),
          bankName: this.getPartnerInfoFieldsValue('bankName', data),
          interBranchTurnover: this.getPartnerInfoFieldsValue('interBranchTurnover', data),
          bankClassifierCode: this.getPartnerInfoFieldsValue('bankClassifierCode', data),
          directorName: this.getPartnerInfoFieldsValue('directorName', data),
          directorNameAdditional: this.getPartnerInfoFieldsValue('directorNameAdditional', data),
          directorPhone: this.getPartnerInfoFieldsValue('directorPhone', data),
          financialManagerName: this.getPartnerInfoFieldsValue('financialManagerName', data),
          financialManagerPhone: this.getPartnerInfoFieldsValue('financialManagerPhone', data),
          contactNameAndPosition: this.getPartnerInfoFieldsValue('contactNameAndPosition', data),
          contactPhone: this.getPartnerInfoFieldsValue('contactPhone', data),
          contactEmail: this.getPartnerInfoFieldsValue('contactEmail', data),
          contactName1: this.getPartnerInfoFieldsValue('contactName1', data),
          contactPhone1: this.getPartnerInfoFieldsValue('contactPhone1', data),
          contactName2: this.getPartnerInfoFieldsValue('contactName2', data),
          contactPhone2: this.getPartnerInfoFieldsValue('contactPhone1', data),
          smallLogo: this.getPartnerInfoFieldsValue('smallLogo', data),
          workSchedule: this.getPartnerInfoFieldsValue('workSchedule', data),
          erpCompanyName: this.getPartnerInfoFieldsValue('erpCompanyName', data),
          isErpContractTerminated: this.getPartnerInfoFieldsValue('isErpContractTerminated', data),
        },
      };

      if (data.partnerStatus && partnerStatuses.cancelStatus || data.partnerStatus === partnerStatuses.disabled) {
        formData.cancelComment = cancelComment;
        formData.partnerCancelStatus = partnerCancelStatus;
      }

      // Format formData
      const isTaxAdditionalCostNumber =
        this.getPartnerInfoFieldsValue('isTaxAdditionalCostNumber', data)
        || data.isTaxAdditionalCostNumber;
      const taxAdditionalCostNumber =
        this.getPartnerInfoFieldsValue('taxAdditionalCostNumber', data)
        || data.taxAdditionalCostNumber;

      if (isTaxAdditionalCostNumber === 'Да' || isTaxAdditionalCostNumber === 'Так') {
        formData.infoFieldsValues.isTaxAdditionalCostNumber = true;
        formData.infoFieldsValues.taxAdditionalCostNumber = taxAdditionalCostNumber;
      } else if (isTaxAdditionalCostNumber === 'Нет' || isTaxAdditionalCostNumber === 'Ні') {
        formData.infoFieldsValues.isTaxAdditionalCostNumber = false;
      }

      if (!isPartnerRoles) {
        formData.infoFieldsValues.hasOnlineStore = this.getPartnerInfoFieldsValue('hasOnlineStore', data);
        formData.infoFieldsValues.activityType = this.getPartnerInfoFieldsValue('activityType', data);
        formData.infoFieldsValues.hasContactCenter = this.getPartnerInfoFieldsValue('hasContactCenter', data);
        formData.infoFieldsValues.hasServiceCenter = this.getPartnerInfoFieldsValue('hasServiceCenter', data);
        formData.infoFieldsValues.planToSell = this.getPartnerInfoFieldsValue('planToSell', data);
        formData.infoFieldsValues.yourselfInformation = this.getPartnerInfoFieldsValue('yourselfInformation', data);
        formData.infoFieldsValues.beenAlloSupplier = this.getPartnerInfoFieldsValue('beenAlloSupplier', data);
        formData.infoFieldsValues.readyToYmlFormat = this.getPartnerInfoFieldsValue('readyToYmlFormat', data);
        const beenAlloSupplierRadioValue = data.beenAlloSupplier;
        const beenAlloSupplierValue = data.beenAlloSupplierName;
        if (this.getPartnerInfoFieldsValue('beenAlloSupplier', data) !== undefined) {
          formData.infoFieldsValues.beenAlloSupplier = this.getPartnerInfoFieldsValue('beenAlloSupplier', data);
        } else {
          if (beenAlloSupplierRadioValue === 'Так' && beenAlloSupplierValue) { /*eslint-disable-line*/
            formData.infoFieldsValues.beenAlloSupplier = `${beenAlloSupplierRadioValue}, ${beenAlloSupplierValue}`;
          } else {
            formData.infoFieldsValues.beenAlloSupplier = beenAlloSupplierRadioValue;
          }
        }

        const readyToYmlFormatRadioValue = data.readyToYmlFormat;
        if (this.getPartnerInfoFieldsValue('readyToYmlFormat', data)) {
          formData.infoFieldsValues.readyToYmlFormat = this.getPartnerInfoFieldsValue('readyToYmlFormat', data);
          formData.infoFieldsValues.ymlPriceLink = data.ymlPriceLink;
        } else {
          if (readyToYmlFormatRadioValue === 'Так') { /*eslint-disable-line*/
            formData.infoFieldsValues.readyToYmlFormat = readyToYmlFormatRadioValue;
            formData.infoFieldsValues.ymlPriceLink = data.ymlPriceLink;
          } else {
            formData.infoFieldsValues.readyToYmlFormat = readyToYmlFormatRadioValue;
          }
        }
      }

      forEach(data, (value, key) => {
        if (value) {
          const infoFieldPaymentValues = {};
          Object.keys(paymentTypesDocsModalFields).forEach((infoFieldPaymentValuesKey) => {
            infoFieldPaymentValues[infoFieldPaymentValuesKey] = formData[infoFieldPaymentValuesKey];
          });
          data.infoFieldPaymentValues = infoFieldPaymentValues;
        }
        if (key.includes('paymentTypes_')) {
          if (formData?.partnerPaymentTypes === undefined) {
            formData.partnerPaymentTypes = { [key.substring('paymentTypes_'.length)]: value };
          } else {
            formData.partnerPaymentTypes[key.substring('paymentTypes_'.length)] = value;
          }
        }
      });

      forEach(formData.infoFieldsValues, (value, key) => {
        if (value === undefined) {
          if (inApprove && isEqual(this.getPartnerInfoFieldCurrentValue(key), data[key])) {
            delete formData.infoFieldsValues[key];
          } else {
            formData.infoFieldsValues[key] = data[key];
          }
        }
      });

      forEach(formData, (value, key) => {
        if (value === undefined || key === 'infoFieldsValues' && isEmpty(value)) {
          delete formData[key];
        }
      });

      putPartnerInfoAsync({
        hashId, formData, undefined, redirect,
      });
    }
  };

  displayTab = (currentTab) => {
    const { selectedTab } = this.state;
    return (selectedTab === currentTab) ? 'flex' : 'none';
  };

  getPartnerInfoByHashId = () => {
    const { hashId, getPartnerInfoByHashId } = this.props;
    if (typeof hashId === 'string') {
      getPartnerInfoByHashId(hashId);
    }
  };

  handlePartnersProcess = () => {
    const { putPartnersProcessAsync, hashId } = this.props;
    if (hashId) {
      putPartnersProcessAsync(hashId);
    }
  };

  handleCancelCheck = () => {
    const { putPartnerInfoAsync, hashId } = this.props;
    putPartnerInfoAsync({
      hashId,
      formData: { partnerStatus: partnerStatuses.questionnaireFill },
      checkApprovedData: false,
    });
  };

  inApprove = (rolesList, currentRole) => rolesList.some(role => currentRole.some(current => role === current));

  render() {
    const {
      classes,
      handleSubmit,
      partnerData,
      statusesList,
      hashId,
      uploadPartnerLogo,
      categoriesList,
      rolesList,
      sendContractToSedPending,
      uploadPartnerContract,
      partnerDownloadIsLoading,
      partnerDownloadContractCommissionIsLoading,
      hasAccessOnePartnerXlsDownload,
      partnerSavePending,
      partnersProcessPending,
      hasAccessBillingPaymentForPartner,
      hasAccessPartnerOfferContract,
      hasAccessPartnerSendContractToSed,
      hasAccessPartnerStatusesHistoryList,
      t,
      mode: getMode,
      partnerContractLink,
      selectedPartnerStatus,
      partnerHashIdConfirmPending,
    } = this.props;

    const {
      selectedTab,
      userData,
      contractStatus,
      docsPages,
    } = this.state;
    const partnerStatus = partnerData?.partnerStatus?.value;
    const infoFieldPaymentValuesData = partnerData?.infoFieldPaymentValues;
    const isQuestionnairePendingConfirm = partnerStatus === partnerStatuses.questionnairePendingConfirm;
    const isCancelStatus = partnerStatus === partnerStatuses.cancelStatus;
    const isActiveStatus = partnerStatus === partnerStatuses.active;
    const isAwaitingPaymentStatus = partnerStatus === partnerStatuses.awaitingPayment;
    const isBlockedStatus = partnerStatus === partnerStatuses.blocked;
    const mode = isQuestionnairePendingConfirm ? 'show' : getMode;
    const inApprove = isQuestionnairePendingConfirm ? false : this.inApprove(rolesList, [userRoles.salesManager, userRoles.manager, userRoles.admin]);
    const ignoreStatusesList = statusesList.toJS().filter((item) => {
      const checkSelectedPartnerStatus = typeof selectedPartnerStatus === 'object' ? selectedPartnerStatus.value : selectedPartnerStatus;
      return item.ignoreValidate === true && checkSelectedPartnerStatus === item.value;
    });
    const ignoreValidateFields = isCancelStatus ? true : !!ignoreStatusesList.length;
    const {
      canSendContractToSed, canDownloadContract,
      canProcess, canCancel, canApprove, isSED,
      partnerStatus: partnerStatusOrigin,
    } = partnerData;

    return (
      <Form onSubmit={handleSubmit(this.onFormSubmit)}>
        <Grid container justifyContent="center">
          <PageHeader title={get(partnerData, 'infoFieldValues.companyName.current', '')}>
            {(hasAccessBillingPaymentForPartner && (
              isActiveStatus
              || isAwaitingPaymentStatus
              || isBlockedStatus
            )) && (
              <ButtonPaymentAdd />
            )}
            {canProcess && (
              <ButtonWithIconAndCircularProgress
                text={t('Обработать')}
                disabled={partnersProcessPending}
                isLoading={partnersProcessPending}
                onClick={this.handlePartnersProcess}
              >
                <ThumbUpIcon />
              </ButtonWithIconAndCircularProgress>
            )}
            {isQuestionnairePendingConfirm && (
              <ButtonWithIconAndCircularProgress
                type="button"
                text={t('Отменить проверку')}
                disabled={partnerSavePending}
                isLoading={partnerSavePending}
                onClick={this.handleCancelCheck}
              >
                <ThumbDownIcon />
              </ButtonWithIconAndCircularProgress>
            )}
            {hasAccessPartnerSendContractToSed && canSendContractToSed === true
            && (
              <ButtonWithIconAndCircularProgress
                text={t('Отправить договор')}
                disabled={sendContractToSedPending}
                isLoading={sendContractToSedPending}
                onClick={this.putPutSendContractToSed}
              >
                <CloudDownloadIcon />
              </ButtonWithIconAndCircularProgress>
            )
            }
            {hasAccessOnePartnerXlsDownload
            && (
              <ButtonWithIconAndCircularProgress
                text={t('Скачать анкету')}
                disabled={partnerDownloadIsLoading}
                isLoading={partnerDownloadIsLoading}
                onClick={this.downloadPartnerInfo}
              >
                <CloudDownloadIcon />
              </ButtonWithIconAndCircularProgress>
            )
            }
            {hasAccessPartnerOfferContract && canDownloadContract && (
              <ButtonWithIconAndCircularProgress
                text={isSED ? t('Лицензионный договор') : t('Скачать заявление на присоединение')}
                disabled={partnerDownloadContractCommissionIsLoading}
                isLoading={partnerDownloadContractCommissionIsLoading}
                onClick={this.downloadContractCommission}
              >
                <CloudDownloadIcon />
              </ButtonWithIconAndCircularProgress>
            )}
            {(mode !== 'show' || partnerStatusOrigin && partnerStatusOrigin.value !== partnerStatuses.questionnairePendingConfirm) && (
              <>
                <PartnerRejectAllValuesButton
                  partnerStatus={partnerStatus}
                  handleSubmit={handleSubmit}
                />
                <PartnerConfirmFormApproveButton
                  disabled={partnerSavePending || partnerHashIdConfirmPending}
                  isDisplay={canApprove}
                  handleSubmit={handleSubmit}
                />
                <PartnerConfirmFormRejectButton
                  disabled={partnerSavePending || partnerHashIdConfirmPending}
                  isDisplay={canCancel}
                />
                <ButtonGoBack defaultPage={pageLinks.partner.all} />
                {mode === 'edit' && (
                  <ButtonWithIconAndCircularProgress
                    text={t('Сохранить данные')}
                    disabled={partnerSavePending}
                    isLoading={partnerSavePending}
                    onClick={handleSubmit(this.onFormSubmit)}
                    component="button"
                    type="submit"
                  >
                    <SaveIcon />
                  </ButtonWithIconAndCircularProgress>
                )}
              </>
            )}
          </PageHeader>
          <TabsContainer>
            <TabsNav>
              <TabsNavMenu
                value={selectedTab}
                onChange={this.handleChangeTab}
              >
                <TabItemMenu value="system" label={t('Информация в систему')} />
                <TabItemMenu value="site" label={t('Информация на сайт')} />
                <TabItemMenu value="finance" label={t('Информация для фин. сверки')} />
                {infoFieldPaymentValuesData && <TabItemMenu value="paymentTypesData" label={t('Данные для договоров')} />}
                {partnerContractLink && <TabItemMenu value="contract" label={t('Договор')} />}
                {hasAccessPartnerStatusesHistoryList && <TabItemMenu value="statusesHistory" label={t('История изменений')} />}
              </TabsNavMenu>
            </TabsNav>
            <UserEditSystem
              approve={inApprove}
              ignoreValidateFields={ignoreValidateFields}
              display={this.displayTab('system')}
              categoryData={categoriesList}
              classes={classes}
              handleUploadLogo={uploadPartnerLogo}
              hashId={hashId}
              isPartnerRole={false}
              mode={mode}
              index="system"
              value={selectedTab}
            />
            <UserEditSite
              approve={inApprove}
              ignoreValidateFields={ignoreValidateFields}
              display={this.displayTab('site')}
              classes={classes}
              handleUploadLogo={uploadPartnerLogo}
              hashId={hashId}
              mode={mode}
              index="site"
              value={selectedTab}
            />
            <UserEditFinance
              approve={inApprove}
              ignoreValidateFields={ignoreValidateFields}
              display={this.displayTab('finance')}
              classes={classes}
              mode={mode}
              index="finance"
              value={selectedTab}
            />
            {hasAccessPartnerStatusesHistoryList && (
              <PartnerEditStatusesHistory
                display={this.displayTab('statusesHistory')}
                classes={classes}
                mode={mode}
                index="statusesHistory"
                value={selectedTab}
              />
            )}
            {infoFieldPaymentValuesData ? (
              <PartnerEditPaymentTypesData
                display={this.displayTab('paymentTypesData')}
                classes={classes}
                mode="show"
                index="paymentTypesData"
                value={selectedTab}
              />
            ) : null}
            {partnerContractLink ? (
              <PartnerEditContract
                display={this.displayTab('contract')}
                classes={classes}
                userData={userData}
                contractStatus={contractStatus}
                docsPages={docsPages}
                handleUploadContract={uploadPartnerContract}
                hashId={hashId}
                mode={mode}
                index="contract"
                value={selectedTab}
              />
            ) : null }
          </TabsContainer>
        </Grid>
        <ReasonRejectionModal />
        <PartnerIsTopModal />
      </Form>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation(),
  reduxForm({
    form: formName,
    onSubmitFail,
  }),
  withStyles(PartnerEditStyle),
)(PartnerEdit);
