// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';


const feedsItemByHashIdDefaultState = {
  item: Map(),
  pending: true,
  putPending: false,
};

export const initialState = fromJS({
  feedsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  feedDelete: Map({
    pending: false,
    showModal: false,
    hashId: null,
  }),
  statusesList: Map({
    pending: true,
    items: List(),
    flow: Map(),
  }),
  prioritiesList: Map({
    items: List(),
    pending: true,
  }),
  typesList: Map({
    items: List(),
    pending: true,
  }),
  feedsItemByHashId: Map(feedsItemByHashIdDefaultState),
  import: Map({
    pending: false,
    error: Map({
      statusTitle: null,
      errors: List([]),
    }),
  }),
  refresh: Map({
    pending: false,
    showModal: false,
    hashId: null,
    id: null,
  }),
  refreshResult: Map({
    pending: false,
    showModal: false,
    message: null,
    statusTitle: null,
  }),
  exportItems: Map(),
  exportForceAction: Map(),
  changePriceUrlForceAction: Map(),
  completionImportList: Map(),
});

export const stateKeys = Object.freeze({
  controller: 'feeds',
  reload: 'reload',
  feedsList: 'feedsList',
  currentPage: 'currentPage',
  filters: 'filters',
  item: 'item',
  items: 'items',
  import: 'import',
  exportItems: 'exportItems',
  exportForceAction: 'exportForceAction',
  changePriceUrlForceAction: 'changePriceUrlForceAction',
  isOpen: 'isOpen',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  statusesList: 'statusesList',
  flow: 'flow',
  feedsItemByHashId: 'feedsItemByHashId',
  stepsData: 'stepsData',
  putPending: 'putPending',
  prioritiesList: 'prioritiesList',
  typesList: 'typesList',
  refresh: 'refresh',
  showModal: 'showModal',
  feedDelete: 'feedDelete',
  showSuccess: 'showSuccess',
  categoryId: 'categoryId',
  success: 'success',
  error: 'error',
  hashId: 'hashId',
  id: 'id',
  statusTitle: 'statusTitle',
  refreshResult: 'refreshResult',
  message: 'message',
  canResetFeed: 'canResetFeed',
  completionImportList: 'completionImportList',
  canParse: 'canParse',
});

export const feedsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_FEEDS_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.feedsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.feedsList, stateKeys.pending], pending)
        .setIn([stateKeys.feedsList, stateKeys.items], List(items))
        .setIn([stateKeys.feedsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_FEEDS_SORTING: {
      return state.setIn([stateKeys.feedsList, stateKeys.sorting], List(payload));
    }
    case types.SET_FEEDS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.feedsList, stateKeys.filters], List(filters));
    }
    case types.SET_FEEDS_PAGE_SIZE: {
      return state.setIn([stateKeys.feedsList, stateKeys.pageSize], payload);
    }
    case types.SET_FEEDS_CURRENT_PAGE: {
      return state.setIn([stateKeys.feedsList, stateKeys.currentPage], payload);
    }
    case types.SET_FEEDS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.feedsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.feedsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.feedsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.feedsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.feedsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.feedsList, stateKeys.reload], reload)
        .setIn([stateKeys.feedsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_FEEDS_SETTING_STATUS: {
      return state.setIn([stateKeys.feedsList, stateKeys.settingStatus], payload);
    }
    case types.SET_FEEDS_DELETE_PENDING: {
      return state.setIn([stateKeys.feedDelete, stateKeys.pending], payload);
    }
    case types.SET_FEEDS_SETTING_PENDING: {
      return state.setIn([stateKeys.feedsList, stateKeys.settingPending], payload);
    }
    case types.SET_FEEDS_DELETE_MODAL_SHOW: {
      return state.setIn([stateKeys.feedDelete], Map(payload));
    }
    case types.MERGE_FEEDS_STATUSES_LIST: {
      return state.mergeIn([stateKeys.statusesList], Map(payload));
    }
    case types.MERGE_FEEDS_ITEM_BY_HASH_ID: {
      return state.mergeIn([stateKeys.feedsItemByHashId], Map(payload));
    }
    case types.MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA: {
      return state.mergeIn([stateKeys.feedsItemByHashId, stateKeys.item, stateKeys.stepsData], Map(payload));
    }
    case types.SET_FEEDS_ITEM_BY_HASH_CAN_PARSE: {
      return state.setIn([stateKeys.feedsItemByHashId, stateKeys.item, stateKeys.canParse], payload);
    }
    case types.MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA_SECOND: {
      return state.mergeIn([stateKeys.feedsItemByHashId, stateKeys.item, stateKeys.stepsData, '2'], Map(payload));
    }
    case types.SET_DEFAULT_FEEDS_ITEM_BY_HASH_ID: {
      return state.setIn([stateKeys.feedsItemByHashId], Map(feedsItemByHashIdDefaultState));
    }
    case types.MERGE_FEEDS_PRIORITIES_LIST: {
      return state.mergeIn([stateKeys.prioritiesList], Map(payload));
    }
    case types.MERGE_FEEDS_TYPES_LIST: {
      return state.mergeIn([stateKeys.typesList], Map(payload));
    }
    case types.MERGE_FEEDS_REFRESH: {
      return state.mergeIn([stateKeys.refresh], Map(payload));
    }
    case types.MERGE_FEEDS_IMPORT: {
      return state.mergeIn([stateKeys.import], Map(payload));
    }
    case types.SET_FEEDS_IMPORT_ERROR: {
      return state.setIn([stateKeys.import, stateKeys.error], Map(payload));
    }
    case types.SET_FEEDS_IMPORT_PENDING: {
      return state.setIn([stateKeys.import, stateKeys.pending], payload);
    }
    case types.SET_FEEDS_EXPORT_FORCE_ACTION: {
      return state.setIn([stateKeys.exportForceAction], Map(payload));
    }
    case types.SET_FEEDS_CHANGE_URL_FORCE_ACTION: {
      return state.setIn([stateKeys.changePriceUrlForceAction], Map(payload));
    }
    case types.MERGE_FEEDS_EXPORT: {
      const downloadState = Object.values(payload || {});
      return downloadState.includes(true)
        ? state.mergeIn([stateKeys.exportItems], payload)
        : state.deleteIn([stateKeys.exportItems, Object.keys(payload).join()]);
    }
    case types.SET_FEEDS_REFRESH_RESULT: {
      return state.mergeIn([stateKeys.refreshResult], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_COMPLETION_IMPORT_LIST: {
      return state.setIn([stateKeys.completionImportList], Map(payload));
    }
    case types.MERGE_COMPLETION_IMPORT_LIST_ITEM: {
      return state.mergeIn([stateKeys.completionImportList, payload.hashId], Map(payload.data));
    }
    default: {
      return state;
    }
  }
};
