// Core
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Parts
import { useDispatch, useSelector } from 'react-redux';
import SaveIcons from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import { CloseOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import SpeakerNotesOutlined from '@mui/icons-material/SpeakerNotesOutlined';
import PageHeader from '../../../../components/PageHeader/PageHeader';
// Engine
import { actions } from '../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/actions';
import {
  initialState, stateKeys,
} from '../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/reducer';
import { selectors } from '../../../../../engine/config/selectors';
import {
  useCategoriesFeaturesItemList,
} from '../components/CategoriesFeatureItemTable/hooks/useCategoriesFeaturesItemList';
import CategoriesFeaturesItemTable from '../components/CategoriesFeatureItemTable/CategoriesFeaturesItemTable';
import ResetFiltersButton from '../../../../components/_Table/DxTable/components/ResetFilterButton';
import {
  asyncActions,
} from '../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/saga/asyncActions';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import CategoriesFeatureLogsModal from '../components/CategoriesFeatureLogs/CategoriesFeatureLogsModal';
import { COLOR } from '../../../../containers/App/AppStyles';
import { localData } from '../../../../../engine/config/localData';
import CancelModal from '../components/CategoriesFeatureItemTable/components/CancelModal';


function CharacteristicEdit(props) {
  useCategoriesFeaturesItemList();
  const { mode } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hashId } = useParams();
  const hasAccessEditPage = useAccessList(accessList.categoriesFeatureManagementEdit);
  const isLoading = useSelector(selectors.categoriesFeaturesItem.isLoading);
  const categoryName = useSelector(selectors.categoriesFeaturesItem.categoryName);
  const hasCategoriesFeatureManagementHistory = useAccessList(accessList.categoriesFeatureManagementHistory);
  const featureRequiresLK = useSelector(selectors.categoriesFeaturesItem.featureRequiresLK).toJS();
  const [isInformerOpen, setIsInformerOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const localInformerState = await localData.getItem(localData.keysObj.characteristicInformer);
      setIsInformerOpen(!!localInformerState);
    })();
  }, []);

  const characteristicPageTitle = mode === 'edit'
    ? t('Редактирование характеристик категории')
    : t('Характеристики категории');

  const title = categoryName
    ? `${characteristicPageTitle}: "${categoryName}"`
    : characteristicPageTitle;

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.categoriesFeaturesItemList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.categoriesFeaturesItemList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.categoriesFeaturesItemList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const handleCancel = () => setCancelModalOpen(true);

  const closeCancelModal = () => setCancelModalOpen(false);

  const onSubmit = () => {
    const result = {};
    featureRequiresLK.forEach((i) => {
      result[i.id] = {
        requiredLk: i.requiredLk,
      };
    });

    dispatch(asyncActions.putCategoriesFeatureItemByHashId({
      result,
      hashId,
    }));
  };

  const handleCloseInformer = () => {
    setIsInformerOpen(false);
    localData.setItem(localData.keysObj.characteristicInformer, false);
  };

  return (
    <>
      <PageHeader title={title}>
        <Box sx={{
          display: 'flex',
        }}
        >
          <ResetFiltersButton
            sx={{ m: '8px 10px', maxHeight: '32px' }}
            onReset={resetFilters}
            pending={isLoading}
            disabled={isLoading}
          />
          { (Object.keys(featureRequiresLK).length !== 0 && hasAccessEditPage) ? (
            <>
              <ButtonWithIconAndCircularProgress
                sx={{ m: '8px 10px', ml: '0', maxHeight: '32px' }}
                disable={isLoading}
                isLoading={isLoading}
                text={t('Сохранить')}
                component="button"
                onClick={onSubmit}
              >
                <SaveIcons />
              </ButtonWithIconAndCircularProgress>
              <LoadingButton
                variant="outlined"
                sx={{ m: '8px 10px', ml: '0', maxHeight: '32px' }}
                disable={isLoading}
                loading={isLoading}
                onClick={handleCancel}
              >
                {t('Отменить')}
              </LoadingButton>
            </>
          ) : null}
        </Box>
      </PageHeader>
      {isInformerOpen && (
      <Box sx={{
        display: 'flex', margin: 2, border: '1px solid blue', backgroundColor: COLOR.blue[20], padding: 1, alignItems: 'center',
      }}
      >
        <SpeakerNotesOutlined sx={{ marginLeft: 1, marginRight: 2 }} color="secondary" />
        {t('На этой странице вы можете просматривать и настраивать обязательные для заполнения характеристики товаров в выбранной категории.')}
        <Button color="inherit" onClick={handleCloseInformer}><CloseOutlined /></Button>
      </Box>
      )}
      <CategoriesFeaturesItemTable />
      {cancelModalOpen && <CancelModal cancelModalOpen={cancelModalOpen} closeCancelModal={closeCancelModal} />}
      {hasCategoriesFeatureManagementHistory && <CategoriesFeatureLogsModal /> }
    </>
  );
}

CharacteristicEdit.propTypes = {
  mode: PropTypes.oneOf(['edit', 'show']),
};

CharacteristicEdit.defaultProps = {
};

export default CharacteristicEdit;
