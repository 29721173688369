// Core
import React, { useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
// Parts
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Calendar } from 'react-date-range';
import Modal from '../../../containers/Modals/Modal/Modal';
import TextField from '../TextField/TextField/TextField';
// Helpers
import { getDate, getDateFnsObjectByLang } from '../../../../_helpers/getDate';
import { locale } from '../../../../engine/init/i18n';
import { dateStringFormat } from '../../../../engine/config/globalConfig';

function DataRangeModal(props) {
  const {
    label, input, margin,
    showClear, meta, disabled,
    minDate, dateFormat, required, isRecommended,
  } = props;

  const { value, onChange } = input;
  const dateValue = (value ? moment(value).toDate() : value) || undefined;
  const [showRangeModal, setShowRangeModal] = useState(false);
  const { t, i18n: { language } } = useTranslation();
  const canClear = value !== '' && showClear && !disabled;
  const showModal = !disabled && showRangeModal;
  const toggleModal = () => setShowRangeModal(!showRangeModal);
  const clearField = () => onChange('');
  const onCalendarChange = (calendar) => {
    onChange(calendar);
    toggleModal();
  };
  const normalizeInput = useMemo(
    () => ({
      onClick: toggleModal,
      value: value ? getDate(value, dateFormat, locale[language]) : value,
    }),
    [value, language],
  );

  const startAdornment = (
    <InputAdornment position="start">
      <DateRangeIcon />
    </InputAdornment>
  );
  const endAdornment = (
    canClear && (
      <InputAdornment position="end">
        <IconButton
          onClick={clearField}
          component="span"
          size="small"
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    )
  );

  return (
    <>
      <TextField
        fullWidth
        label={label}
        disabled={disabled}
        meta={meta}
        margin={margin}
        input={normalizeInput}
        required={required}
        isRecommended={isRecommended}
        readOnly
        placeholder={t('Выберите дату')}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
      {showModal && (
        <Modal
          title={t('Дата')}
          modalOpen={showRangeModal}
          handleModalToggle={toggleModal}
          modalMaxWidth="unset"
          fullWidth={false}
          hideButtons
          noPadding
          modalFooterComponent={null}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Calendar
              minDate={minDate}
              color="#8a1a1c"
              locale={getDateFnsObjectByLang(language)}
              onChange={onCalendarChange}
              date={dateValue}
            />
          </Box>
        </Modal>
      )}
    </>
  );
}

DataRangeModal.propTypes = {
  showClear: PropTypes.bool,
  label: PropTypes.any,
  minDate: PropTypes.instanceOf(Date),
  value: PropTypes.string,
  margin: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  isRecommended: PropTypes.bool,
  required: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
};

DataRangeModal.defaultProps = {
  disabled: false,
  dateFormat: dateStringFormat,
};

export default DataRangeModal;
