// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  changeFilters(filters) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_FILTERS, filters);
  },
  changeSorting(sorting) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_SORTING, sorting);
  },
  setMailTemplateList(partnerInfo) {
    return getActionCreator(types.SET_MAIL_TEMPLATE_LIST, partnerInfo);
  },
  mergeMailTemplateByHashId(mailTemplate) {
    return getActionCreator(types.MERGE_IN_MAIL_TEMPLATE_BY_HASH_ID, mailTemplate);
  },
  setMailTemplatesEvents(mailTemplatesEvents) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_EVENTS, mailTemplatesEvents);
  },
  mergeMailTemplatesRoles(mailTemplatesRoles) {
    return getActionCreator(types.MERGE_MAIL_TEMPLATES_ROLES, mailTemplatesRoles);
  },
  mergeMailTemplatesGroups(mailTemplatesGroups) {
    return getActionCreator(types.MERGE_MAIL_TEMPLATES_GROUPS, mailTemplatesGroups);
  },
  setMailTemplateCurrentPage(currentPage) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_CURRENT_PAGE, currentPage);
  },
  setMailTemplatePageSize(pageSize) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_PAGE_SIZE, pageSize);
  },
  resetFilters() {
    return getActionCreator(types.RESET_MAIL_FILTERS);
  },
  setPutMailTemplateByHashIdPending(isLoading) {
    return getActionCreator(types.SET_PUT_MAIL_TEMPLATE_BY_HASH_ID_PENDING, isLoading);
  },
  setMailTemplatesInitialState(initialState) {
    return getActionCreator(types.SET_MAIL_TEMPLATES_INITIAL_STATE, initialState);
  },
});
