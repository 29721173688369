// Core
import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import * as PropTypes from 'prop-types';
// Hooks
import { useAccessList } from '../../../../../../../../hooks/useAccessList';
import { useStyles } from '../../../../../../../../hooks/useStyles';
// actions
import { accessList } from '../../../../../../../../../engine/config/access';
// helpers
import { tableColumns } from '../../../../../../../../../_helpers/data/pages/contentMenagement/contentTemplates/tableColumns';
// parts
import DownloadContentTemplatesImportProductButton from '../DownloadContentTemplatesImportProductButton';
import DownloadContentTemplatesFeaturesListButton from '../DownloadContentTemplatesFeaturesListButton';
import UpdateFromMagentoButton from '../../../../../../Content/ContentProducts/components/ContentProductsSidebar/UpdateFromMagentoButton';
// styles
import ContentTemplatesTableStyles from './ContentTemplatesTableStyles';
import { BUTTON_SIZE } from '../../../../../../../../containers/App/AppStyles';

function ContentTemplatesRow(props) {
  const { row } = props;
  const classes = useStyles(ContentTemplatesTableStyles);
  const hasAccessProductImport = useAccessList(accessList.productImport);
  const hasAccessProductImportTemplatesDownload = useAccessList(accessList.productImportTemplatesDownload);
  const hasAccessProductFeatureValuesListDownload = useAccessList(accessList.productFeatureValuesListDownload);
  const hasAccessFeaturesUpdateFromMagento = useAccessList(accessList.featuresUpdateFromMagento);

  const categoryId = row[tableColumns.value.name];
  const columnName = row[tableColumns.label.name];
  const hasAccessColumn = hasAccessProductImport
    || hasAccessProductImportTemplatesDownload
    || hasAccessProductFeatureValuesListDownload
    || hasAccessFeaturesUpdateFromMagento;
  return (
    <TableRowDetail.Row>
      <TableRowDetail.Cell colSpan={hasAccessColumn ? 1 : 2}>
        <span>{columnName}</span>
      </TableRowDetail.Cell>
      { hasAccessColumn && (
        <TableRowDetail.Cell className={classes.buttonWrapper}>
          <DownloadContentTemplatesImportProductButton categoryId={categoryId} />
          <DownloadContentTemplatesFeaturesListButton categoryId={categoryId} />
          <UpdateFromMagentoButton type="cell" sx={{ p: BUTTON_SIZE.withIconSmall }} categoryId={categoryId} />
        </TableRowDetail.Cell>
      )}
    </TableRowDetail.Row>
  );
}

ContentTemplatesRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default React.memo(ContentTemplatesRow);
