// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Field, reduxForm,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
// parts
import FilesUploaderWithValidation from '../../../../../components/_Form/FilesUploader/FilesUploaderWithValidation';
import Modal from '../../../../../containers/Modals/Modal/Modal';
// actions
import { actions } from '../../../../../../engine/core/stopList/stopWords/actions';
import { asyncActions } from '../../../../../../engine/core/stopList/stopWords/saga/asyncActions';
// _helpers
import { validators } from '../../../../../../_helpers/validationsForm';
import { formFields, formName } from './form';
// config
import { selectors } from '../../../../../../engine/config/selectors';
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';

// Component
function StopWordModal(props) {
  const { handleSubmit, valid } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpenUploadStopWord = useSelector(selectors.stopWords.isModalOpenUploadStopWord);
  const postUploadStopWordPending = useSelector(selectors.stopWords.postUploadStopWordPending);

  const handleSendForm = () => dispatch(submit(formName));

  const onFormSubmit = (formValues) => {
    dispatch(asyncActions.postUploadStopWordAsync({
      files: formValues.get(formFields.stopWordUploadFile),
    }));
  };

  const handleModalClose = () => {
    dispatch(actions.mergeUploadStopWord({ isModalOpen: false }));
  };

  return (
    <Modal
      modalOpen={isModalOpenUploadStopWord}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      handleModalAdd={handleSendForm}
      disabledSend={postUploadStopWordPending || !valid}
      loadingSend={postUploadStopWordPending}
      title={t('Импорт стоп-слов')}
      modalMaxWidth="xs"
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={FilesUploaderWithValidation}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={formFields.stopWordUploadFile}
          disabled={postUploadStopWordPending}
          validate={[validators.itemsRequiredValidator]}
        />
      </Form>
    </Modal>
  );
}

StopWordModal.displayName = 'StopBrandsCategoriesUpload';

StopWordModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: formName,
})(memo(StopWordModal));
