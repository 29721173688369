// Core
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icon
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Containers
import i18n from 'i18next';
import ButtonWithIconAndCircularProgress
  from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions } from '../../../../../engine/core/users/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';

function ExportButton() {
  const { t } = useTranslation();
  const downloadPending = useSelector(selectors.users.downloadPending);
  const dispatch = useDispatch();
  const selection = useSelector(selectors.users.selection);
  const filters = useSelector(selectors.users.filters);
  const [exportLabel, setExportLabel] = useState('');
  const onDownload = () => {
    dispatch(asyncActions.getUsersDownloadAsync());
  };
  useEffect(() => {
    if (selection.size) {
      setExportLabel(t('Экспорт выбранного'));
    } else if (filters.size) {
      setExportLabel(t('Экспорт фильтрованного'));
    } else {
      setExportLabel(t('Экспорт'));
    }
  }, [filters.size, selection.size, i18n.language]);

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={downloadPending}
      disabled={downloadPending}
      text={exportLabel}
      onClick={onDownload}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default ExportButton;
