export const types = Object.freeze({
  SET_PRODUCT_FEED_PARAMETERS_LIST: 'SET_PRODUCT_FEED_PARAMETERS_LIST',
  SET_PRODUCT_FEED_PARAMETERS_CURRENT_PAGE: 'SET_PRODUCT_FEED_PARAMETERS_CURRENT_PAGE',
  SET_PRODUCT_FEED_PARAMETERS_FILTERS: 'SET_PRODUCT_FEED_PARAMETERS_FILTERS',
  SET_PRODUCT_FEED_PARAMETERS_PAGE_SIZE: 'SET_PRODUCT_FEED_PARAMETERS_PAGE_SIZE',
  SET_PRODUCT_FEED_PARAMETERS_SORTING: 'SET_PRODUCT_FEED_PARAMETERS_SORTING',
  SET_PRODUCT_FEED_PARAMETERS_INITIAL_STATE: 'SET_PRODUCT_FEED_PARAMETERS_INITIAL_STATE',
  SET_PRODUCT_FEED_PARAMETERS_SETTING_STATUS: 'SET_PRODUCT_FEED_PARAMETERS_SETTING_STATUS',
  SET_PRODUCT_FEED_PARAMETERS_SETTING_PENDING: 'SET_PRODUCT_FEED_PARAMETERS_SETTING_PENDING',
  SET_PRODUCT_FEED_PARAMETERS_LIST_DEFAULT_STATE: 'SET_PRODUCT_FEED_PARAMETERS_LIST_DEFAULT_STATE',
});
