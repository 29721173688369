import button from '../../../../../../../../assets/img/buttons_new.jpg';

const ContentProductTableStyles = theme => ({
  styleWrapper: {
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.down(700)]: {
      maxWidth: 300,
    },
  },
  styleButton: {
    backgroundPositionX: 28,
    backgroundPositionY: -2,
    position: 'relative',
    top: 10,
    marginLeft: 8,
    marginRight: 7,
    display: 'inline-block',
    'background-image': `url(${button})`,
    width: 26,
    height: 29,
    marginTop: -2,
    zIndex: -1,
  },
  styleSwitch: {
    backgroundPositionX: -4,
    backgroundPositionY: -2,
    position: 'relative',
    top: 10,
    marginLeft: 8,
    marginRight: 7,
    display: 'inline-block',
    'background-image': `url(${button})`,
    width: 55,
    height: 29,
    marginTop: 0,
    zIndex: -1,
  },
});

export default ContentProductTableStyles;
