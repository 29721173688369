// Core
import { put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { selectors } from '../../../../config/selectors';
import { actions } from '../../actions';

export function* callSetFiltersWorker(settings) {
  const columnsObjectLike = {};
  const columns = get(settings, 'category.gridSettings', []);

  columns.reduce((acc, item) => {
    const { name, visible } = item;
    acc[name] = visible;
    return acc;
  }, columnsObjectLike);

  const filters = yield select(selectors.categoriesFeatureManagement.filters);
  const sorting = yield select(selectors.categoriesFeatureManagement.sorting);

  const filteredFilters = filters.toJS().filter((item) => {
    const key = get(item, 'columnName');
    return columnsObjectLike[key];
  });

  const filteredSorting = sorting.toJS().filter((item) => {
    const key = get(item, 'columnName');
    return columnsObjectLike[key];
  });

  yield put(actions.setFilters(filteredFilters));
  yield put(actions.setSorting(filteredSorting));
}
