// Core
import { apply, put } from 'redux-saga/effects';
import { change } from 'redux-form';

// Engine
import { api } from '../../../../config/api';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { localData } from '../../../../config/localData';
import { actions } from '../../../serviceDesk/actions';

export function* callPostMediasWorker({ payload }) {
  const {
    files, formName, inputName, context, multipleModeContext, chatId,
  } = payload;
  yield put(actions.setIsChatFilePending(true));
  const response = yield apply(api, api.medias.postMedias, [{ files, context }]);
  if (formName && inputName) {
    yield put(change(formName, inputName, []));
  }
  if (response && response.status >= 200 && response.status < 300) {
    if (response.data) {
      const { status, statusTitle } = response.data;
      switch (status) {
        case 'success': {
          const { message } = response.data;
          yield put(setSuccessMessage(message, statusTitle));
          if (multipleModeContext) {
            if (chatId) {
              let localForageData = yield apply(localData, localData.getItem, [multipleModeContext]);

              // localForage пустой
              if (localForageData === null) {
                localForageData = {
                  [chatId]: {
                    files: [response.data],
                  },
                };
                // Найден текущий чат и в нем уже есть сохраняемые данные
              } else if (localForageData[chatId] && localForageData[chatId].files) {
                localForageData = {
                  ...localForageData,
                  [chatId]: {
                    ...localForageData[chatId],
                    files: localForageData[chatId].files.concat([response.data]),
                  },
                };
                // Другой чат или тот же, но в нем лежат другие данные
              } else {
                localForageData = {
                  ...localForageData,
                  [chatId]: {
                    ...localForageData[chatId],
                    files: [response.data],
                  },
                };
              }
              yield apply(localData, localData.setItem, [multipleModeContext, localForageData]);
              yield put(actions.setFilesInChatMessage(localForageData[chatId].files));
            } else {
              // TODO для множественной загрузки
            }
          } else {
            yield put(change(formName, inputName, [response.data]));
          }
          break;
        }
        case 'error': {
          const { errors } = response.data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  yield put(actions.setIsChatFilePending(false));
}
