// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
// Components
import Table from './components/Table';
import DeleteButton from './components/DeleteButton';
import AddButton from './components/AddButtonAndModal';
import EditModal from './components/EditModal';
import ExportButton from './components/ExportButton';
import UploadButton from './components/UploadButton';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../components/_Table/GridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useBrandsCategoriesList } from './_hooks/useBrandsCategoriesList';
// Engine
// import { asyncActions } from '../../../engine/core/BrandsCategories/saga/asyncActions';
import { stopListBrandsCategories } from '../../../../engine/core/stopList/BrandsCategories/selectors';
import { actions } from '../../../../engine/core/stopList/BrandsCategories/actions';
import { initialState, stateKeys } from '../../../../engine/core/stopList/BrandsCategories/reducer';
import StopCategoryWordModal from './components/StopCategoryWordModal/StopCategoryWordModal';
import StopBrandCategoriesModal from './components/StopBrandCategoriesModal/StopBrandCategoriesModal';

function Page() {
  const { t } = useTranslation();
  useBrandsCategoriesList();
  const dispatch = useDispatch();
  // const settingStatus = useSelector(selectors.BrandsCategories.settingStatus);
  const isLoading = useSelector(stopListBrandsCategories.isLoading);
  const filters = useSelector(stopListBrandsCategories.filters);
  const sorting = useSelector(stopListBrandsCategories.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.stopListBrandsCategoriesList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.stopListBrandsCategoriesList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.stopListBrandsCategoriesList).get(stateKeys.currentPage)));
  };

  return (
    <>
      <PageHeader title={t('Стоп бренды-категории')}>
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={() => dispatch(asyncActions.putSettingsAsync())} */}
        {/* /> */}
        <ExportButton />
        <DeleteButton />
        <UploadButton />
        <AddButton />
        <ResetFiltersButton disabled={isDisabledResetButton} pending={isLoading} onReset={resetFilters} />
      </PageHeader>
      <Table />
      <EditModal />
      <StopBrandCategoriesModal />
      <StopCategoryWordModal />
    </>
  );
}

export default Page;
