// Core
import { styled } from '@mui/material/styles';
// Parts
import ButtonGroup from '@mui/material/ButtonGroup';
import { COLOR } from '../../../../../../containers/App/AppStyles';

export const ButtonGroupWrapper = styled(ButtonGroup)({
  background: '#FFFFFF',
  boxShadow: COLOR.shadow.block.main,
  borderRadius: '3px',
  padding: '2px',
  marginLeft: '8px',
  '& .MuiButton-root': {
    width: '28px',
    minWidth: 'initial',
    background: COLOR.grey['8'],
    borderRadius: '3px !important',
    border: 'none !important',
    '&.MuiButtonGroup-grouped:not(:first-of-type)': {
      marginLeft: '2px',
    },
  },
  '& .MuiButtonGroup-grouped': {
    padding: '6px 0',
    width: '28px',
    height: '28px',
  },
});
