// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
import get from 'lodash/get';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../action';
import { formName } from '../../../../../ui/page/User/UserByHashId/UserByHashId';

export function* callGetUsersHashIdWorker({ payload }) {
  yield put(userActions.mergeInUserByHashId({ pending: true }));
  const response = yield apply(api, api.user.getUsersHashId, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const fixedAdditionalEmails = data.additionalEmails && data.additionalEmails.map(item => (
      { value: item.email }
    ));

    const formData = { // TODO set full key to const
      [`${formName}FullName`]: data.name,
      [`${formName}Email`]: data.email,
      [`${formName}Phone`]: data.phone,
      [`${formName}Viber`]: data.viber,
      [`${formName}Enabled`]: data.enabled,
      [`${formName}Role`]: get(data, 'role.value'),
      [`${formName}Partner`]: data.partner,
      [`${formName}Groups`]: data.groups,
      [`${formName}Locale`]: data.locale,
      [`${formName}AdditionalEmails`]: fixedAdditionalEmails,
    };
    if (data.partnerRoles) {
      formData[`${formName}partnerRoles`] = data.partnerRoles.map(item => item.value);
    }

    const formFields = Object.keys(formData);

    yield put(initialize(formName, formData, formFields));

    yield put(userActions.mergeInUserByHashId({ pending: false, user: data }));
  }
  // TODO set pending false for else response
}
