import { BUTTON_SIZE, COLOR } from '../../containers/App/AppStyles';

const ErrorStyles = theme => ({
  session: {
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#ffffff',
  },
  content: {
    padding: theme.spacing(5, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
    textAlign: 'center',
    maxWidth: '538px',
  },
  componentView: {
    minHeight: 'auto',
    margin: 'auto',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    width: 'calc(100% - 24px)',
    height: 'calc(100% - 24px)',
  },
  title: {
    fontSize: '128px',
    lineHeight: '100px',
    fontWeight: 500,
    marginBottom: theme.spacing(8.75),
    display: 'inline-table',
    position: 'relative',
    color: COLOR.grey.main,
    userSelect: 'none',
  },
  title404: {
    width: '248px',
    height: '196px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& span': {
      lineHeight: '100px',
      display: 'inline-block',
      zIndex: 1,
      marginBottom: theme.spacing(4.75),
    },
  },
  titleImg404: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    lineHeight: '196px',
  },
  subtitle: {
    fontSize: '32px',
    fontWeight: 900,
  },
  caption: {
    fontSize: '14px',
    lineHeight: '22px',
    color: COLOR.grey.main,
    textAlign: 'center',
  },
  button: {
    marginTop: '35px',
    padding: BUTTON_SIZE.middle,
    minWidth: '160px',
    position: 'relative',
    zIndex: '1',
  },
  bgLogo: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

export default ErrorStyles;
