// Core
import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import { usePartnerRole } from '../../../../../hooks/usePartnerRole';
import CommonTableCell from '../../../../../components/_Table/TableCell/TableCell';

export const NO_MORE_REFUND_PRODUCTS = 0;


function TableCell(props) {
  const {
    column,
    row,
    value,
    setSelection,
    setItemsToRefund,
    itemsToRefund,
    selection,
    ...restProps
  } = props;

  const { t } = useTranslation();
  const isPartner = usePartnerRole();


  const renderCell = () => {
    switch (column.name) {
      case 'quantityToRefund': {
        const options = new Array(row.quantityToRefund).fill(row.quantityToRefund).map((el, index) => ({ value: (el - index), label: (el - index).toString() }));
        const handleChange = (selected) => {
          if (!selection.includes(row.id)) {
            setSelection(all => [...all, row.id]);
          }
          setItemsToRefund(items => ({ ...items, [row.id]: { ...row, quantityToRefund: selected.value } }));
        };

        const selectValue = itemsToRefund?.[row.id] ? { value: itemsToRefund?.[row.id].quantityToRefund, label: itemsToRefund?.[row.id].quantityToRefund?.toString() } : undefined;

        return (
          <VirtualTable.Cell>
            <Tooltip title={row.quantityToRefund === NO_MORE_REFUND_PRODUCTS ? t('Вы вернули деньги за данную позицию') : ''} PopperProps={{ style: { zIndex: 10000 } }}>
              <Box>
                <Select
                  variant="outlined"
                  forcedNoSearch
                  options={options}
                  handleChange={handleChange}
                  value={selectValue ?? options[0]}
                  defaultValue={options[0]}
                  disabled={row.quantityToRefund === NO_MORE_REFUND_PRODUCTS || !isPartner}
                />
              </Box>
            </Tooltip>
          </VirtualTable.Cell>
        );
      }
      case 'partnerSku':
      case 'referrer':
      case 'name': {
        return <CommonTableCell {...props} showShortText customSx={{ fontSize: '13px !important' }} newDesignStyle />;
      }
      default: {
        const Cell = () => (
          <Box sx={{
            textOverflow: 'ellipsis', maxHeight: '33px', overflow: 'hidden',
          }}
          >{row[column.name]}
          </Box>
        );
        return (
          <VirtualTable.Cell {...restProps} sx={{ '&.MuiTableCell-root.MuiTableCell-body.TableCell-cell': { padding: '5.5px 8px', fontSize: '13px !important' } }}>
            <Tooltip title={row.quantityToRefund === NO_MORE_REFUND_PRODUCTS ? t('Вы вернули деньги за данную позицию') : ''} PopperProps={{ style: { zIndex: 10000 } }}>{Cell()}</Tooltip>
          </VirtualTable.Cell>
        );
      }
    }
  };

  return (
    <>
      {renderCell()}
    </>
  );
}

export default TableCell;
