// Core
import { useMemo } from 'react';

export function useParentEditMode() {
  return useMemo(() => ({
    create: 'create',
    edit: 'edit',
    show: 'show',
  }), []);
}
