// Core
import React from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import * as PropTypes from 'prop-types';

function HighlightedText(props) {
  const { label: text, search: highlight } = props;

  if (highlight) {
    const regExp = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regExp);
    return parts.map((part, i) => {
      const styles = part.toLowerCase() === highlight.toLowerCase()
        ? { fontWeight: 'bold' }
        : {};
      return (
        <span key={i} style={styles}>
          {part}
        </span>
      );
    });
  }
  return text;
}

HighlightedText.propTypes = {
  label: PropTypes.string,
  search: PropTypes.string,
};

HighlightedText.defaultProps = {
  label: '',
  search: '',
};

export default HighlightedText;
