// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_CONFIGURATIONS_LIST_ASYNC, params);
  },
  getTypesListAsync(params) {
    return getActionCreator(asyncTypes.GET_CONFIGURATIONS_TYPES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_CONFIGURATIONS_SETTINGS_ASYNC, param);
  },
  getItemByHashIdAsync(param) {
    return getActionCreator(asyncTypes.GET_CONFIGURATIONS_ITEM_BY_HASH_ID_ASYNC, param);
  },
  putItemByHashIdAsync(param) {
    return getActionCreator(asyncTypes.PUT_CONFIGURATIONS_ITEM_BY_HASH_ID_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_CONFIGURATIONS_INITIAL_STATE_ASYNC, path);
  },
});
