// Core
import { useMemo } from 'react';

export function usePartnerTabs() {
  return useMemo(() => ({
    contract: 'contract',
    finance: 'finance',
    percent: 'percent',
    site: 'site',
    system: 'system',
    paymentTypes: 'paymentTypes',
    paymentTypesData: 'paymentTypesData',
  }), []);
}
