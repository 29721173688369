// Core
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Components
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Parts
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';
import SynonymsTable from './components/SynonymsTable';
import { pageLinks } from '../../../../engine/config/routes';
import { useSynonymsList } from './_hooks/useSynonymsList';
import { initialState, stateKeys } from '../../../../engine/core/synonyms/reducer';
import { actions } from '../../../../engine/core/synonyms/actions';
import { selectors } from '../../../../engine/config/selectors';
import { api, requestStatus } from '../../../../engine/config/api';
import { setSuccessMessage, setErrorMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../_helpers/getFileFromBlob';
import ImportModal from './components/ImportModal';
import DeleteModal from './components/DeleteModal';
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';

function SynonymsPage() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useSynonymsList();

  const [exportLabel, setExportLabel] = useState('');
  const selection = useSelector(selectors.synonyms.selection);
  const filters = useSelector(selectors.synonyms.filters);
  const sorting = useSelector(selectors.synonyms.sorting);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const hasAccessToEdit = useAccessList(accessList.synonymsAdd);

  useEffect(() => {
    if (selection.size) {
      setExportLabel(t('Экспорт выбранного'));
    } else if (filters.size) {
      setExportLabel(t('Экспорт фильтрованного'));
    } else {
      setExportLabel(t('Экспорт данных'));
    }
  }, [selection, filters, i18n.language]);

  const handleExport = async () => {
    setIsExportLoading(true);
    const response = await api.synonyms.export({ ids: selection.toJS() });
    if (response && response.status >= 200 && response.status < 400) {
      const responseType = typeof response.data;
      let responseData;

      switch (responseType) {
        case 'object': {
          if (!response.data.status) {
            try {
              const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
              responseData = JSON.parse(decodedString);
            } catch (err) {
              responseData = {};
            }
            break;
          }
          responseData = response.data;
          break;
        }
        default: {
          responseData = {};
          break;
        }
      }

      const { status } = responseData;

      switch (status) {
        case requestStatus.error: {
          const { message } = responseData;
          dispatch(setErrorMessage(message, t('Ошибка')));
          break;
        }

        case requestStatus.success: {
          const { message } = responseData;
          dispatch(setSuccessMessage(message, t('Успех')));
          break;
        }

        default: {
          getFileFromBlob('synonyms_list.xlsx', response);
          break;
        }
      }
    }
    setIsExportLoading(false);
  };

  const handleImport = () => {
    setIsImportModalOpen(true);
  };


  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.synonymsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.synonymsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.synonymsList).get(stateKeys.currentPage)));
  };

  const openConfirmModal = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <PageHeader title={t('Словарь синонимов')}>
        {selection.size > 0 && hasAccessToEdit && (
        <ButtonWithIconAndCircularProgress
          text={t('Удалить')}
          onClick={openConfirmModal}
        >
          <DeleteIcon />
        </ButtonWithIconAndCircularProgress>
        )}
        <span>
          <ButtonWithIconAndCircularProgress
            text={exportLabel}
            onClick={handleExport}
            isLoading={isExportLoading}
            disabled={isExportLoading}
            sx={{ width: '100%' }}
          >
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        </span>
        {hasAccessToEdit && (
        <ButtonWithIcon
          onClick={handleImport}
          disabled={false}
          sx={{ p: BUTTON_SIZE.withIconSmall }}
        >
          {t('Импорт')} {'XLSX'}
        </ButtonWithIcon>
        )}
        <ResetFiltersButton onReset={resetFilters} disabled={!filters.size && !sorting.size} />
        {hasAccessToEdit && (
        <Link to={pageLinks.synonyms.add}>
          <ButtonWithIcon text={t('Добавить')} sx={{ p: BUTTON_SIZE.withIconSmall, width: '100%' }}>
            <AddCircleIcon />
          </ButtonWithIcon>
        </Link>
        )}
      </PageHeader>
      <SynonymsTable />
      {isImportModalOpen && <ImportModal isModalOpen={isImportModalOpen} setIsModalOpen={setIsImportModalOpen} />}
      <DeleteModal isModalOpen={deleteModalOpen} setIsModalOpen={setDeleteModalOpen} />
    </>
  );
}

export default SynonymsPage;
