// Core
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
// Parts
import Select from '../../../../../../../../../components/_Form/Selects/Select/Select';
import ConfirmButtons from '../../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
// Helpers
import { tableFiltersTypes } from '../../../../../../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';

function MultipleSelectWidthConfirm(props) {
  const {
    error, disabled, featureName, label, onConfirm,
    options, productId, success, value, valuesPlain, required, type,
    showDeleteButton,
  } = props;

  const initValue = value || [];
  const [selectedMultipleValue, setSelectedMultipleValue] = useState(initValue);
  const [currentProductId, setCurrentProductId] = useState(productId);

  const onMultipleSelectConfirm = (newValue) => {
    onConfirm({ [featureName]: newValue }, tableFiltersTypes.multipleSelect);
  };

  useEffect(() => {
    setCurrentProductId(productId);
  }, [productId]);

  if (currentProductId !== productId) {
    return null;
  }

  return (
    <ConfirmButtons
      key={productId}
      error={error}
      required={required}
      disabled={disabled}
      notEmpty={!selectedMultipleValue.length}
      onConfirm={onMultipleSelectConfirm}
      onReject={setSelectedMultipleValue}
      success={success}
      value={selectedMultipleValue}
      valuesPlain={valuesPlain}
      type={type}
      initValue={initValue}
      showDeleteButton={showDeleteButton}
    >
      <Select
        isMulti
        closeMenuOnSelect={false}
        disabled={disabled}
        input={{
          value: selectedMultipleValue,
          onChange: setSelectedMultipleValue,
        }}
        variant="outlined"
        label={label}
        options={options}
      />
    </ConfirmButtons>
  );
}

MultipleSelectWidthConfirm.propTypes = {
  required: PropTypes.bool,
  error: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  success: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  productId: PropTypes.number.isRequired,
  type: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.arrayOf(
    PropTypes.number,
  ),
};

MultipleSelectWidthConfirm.defaultProps = {
  required: false,
  error: false,
  disabled: false,
  success: false,
  showDeleteButton: true,
  label: '',
  valuesPlain: '',
  options: [],
};

export default MultipleSelectWidthConfirm;
