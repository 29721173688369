export const types = Object.freeze({
  SET_SYSTEM_MOTIVATION_RESULTS_LIST: 'SET_SYSTEM_MOTIVATION_RESULTS_LIST',
  SET_SYSTEM_MOTIVATION_RESULTS_CURRENT_PAGE: 'SET_SYSTEM_MOTIVATION_RESULTS_CURRENT_PAGE',
  SET_SYSTEM_MOTIVATION_RESULTS_FILTERS: 'SET_SYSTEM_MOTIVATION_RESULTS_FILTERS',
  SET_SYSTEM_MOTIVATION_RESULTS_PAGE_SIZE: 'SET_SYSTEM_MOTIVATION_RESULTS_PAGE_SIZE',
  SET_SYSTEM_MOTIVATION_RESULTS_SORTING: 'SET_SYSTEM_MOTIVATION_RESULTS_SORTING',
  SET_SYSTEM_MOTIVATION_RESULTS_INITIAL_STATE: 'SET_SYSTEM_MOTIVATION_RESULTS_INITIAL_STATE',
  SET_SYSTEM_MOTIVATION_RESULTS_SETTING_STATUS: 'SET_SYSTEM_MOTIVATION_RESULTS_SETTING_STATUS',
  SET_SYSTEM_MOTIVATION_RESULTS_SETTING_PENDING: 'SET_SYSTEM_MOTIVATION_RESULTS_SETTING_PENDING',
  MERGE_DOWNLOAD_SYSTEM_MOTIVATION_RESULTS: 'MERGE_DOWNLOAD_SYSTEM_MOTIVATION_RESULTS',
  SET_SYSTEM_MOTIVATION_RESULTS_RELOAD: 'SET_SYSTEM_MOTIVATION_RESULTS_RELOAD',
});
