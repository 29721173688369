// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_MAGENTO_REQUESTS_LIST_ASYNC, params);
  },
  getDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_MAGENTO_REQUESTS_DOWNLOAD_ASYNC, params);
  },
  getItemByHashIdAsync(params) {
    return getActionCreator(asyncTypes.GET_MAGENTO_REQUESTS_ITEM_BY_HASH_ID_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_MAGENTO_REQUESTS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_MAGENTO_REQUESTS_INITIAL_STATE_ASYNC, path);
  },
});
