import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { COLOR } from '../../containers/App/AppStyles';

export const TitleWithIcon = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-weight: bold;
  color: ${COLOR.black.initial};
  font-size: 16px;
`;
export const SubTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: ${COLOR.black.initial};
`;
