// Core
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { billingAsyncAction } from '../../../../../../../engine/core/billing/saga/asyncAction';

export function useOrdersChartData() {
  const { t } = useTranslation();
  const data = useSelector(selectors.billing.newOrdersDashboard);
  const normalizeData = data.toJS();
  const isLoading = useSelector(selectors.billing.newOrdersDashboardPending);
  const dispatch = useDispatch();
  const {
    date, orderQuantity,
    amount, commission,
  } = normalizeData;

  const noData = !!(!date && !orderQuantity && !amount && !commission);

  const getDataAsync = useCallback((parameters) => {
    dispatch(billingAsyncAction.getBillingNewOrdersDashboardDataAsync(parameters));
  }, [dispatch]);

  return useMemo(() => ({
    getDataAsync,
    isLoading,
    data: {
      labels: date,
      noData,
      datasets: [
        {
          label: t('Кол-во заказов'),
          type: 'line',
          data: orderQuantity,
          fill: false,
          backgroundColor: '#4d9e57',
          borderColor: '#4d9e57',
          pointBorderColor: '#4d9e57',
          pointBackgroundColor: '#4d9e57',

          yAxisID: 'y-axis-1',
        },
        {
          type: 'bar',
          label: t('Выручка'),
          data: amount,
          fill: false,
          backgroundColor: '#dc8020',
          borderColor: '#dc8020',
          yAxisID: 'y-axis-0',
        },
        {
          type: 'bar',
          label: t('Доход'),
          data: commission,
          fill: false,
          backgroundColor: '#016580',
          borderColor: '#016580',
          yAxisID: 'y-axis-0',
        },
      ],

    },
    legend: {
      labels: {
        boxWidth: 20,
        fontSize: 10,
      },
    },
    options: {
      responsive: true,
      tooltips: {
        mode: 'label',
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-0',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-1',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
        ],
      },
    },
  }), [t, getDataAsync, isLoading, date, noData, orderQuantity, amount, commission]);
}
