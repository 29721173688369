// Core
import { put, select } from 'redux-saga/effects';
// Engine
import { pageLinks } from '../../../../config/routes';
import { selectors } from '../../../../config/selectors';
import { localData } from '../../../../config/localData';
import { actions } from '../../actions';
import i18n from '../../../../init/i18n';
// Data
import { tableColumns } from '../../../../../_helpers/data/pages/faqs/tableColumns';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';

export function* callGetFaqsInitialStateWorker({ payload: url }) {
  const faqs = yield localData.getItem(url);
  const pathname = yield select(selectors.router.pathname);
  const initialPageState = getInitialPageState(faqs || {});

  switch (pathname) {
    case pageLinks.faq.all: {
      break;
    }
    case pageLinks.faq.new: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [...filteredFilters, createFilterByStatusField('Новый', 0)];
      break;
    }
    case pageLinks.faq.review: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [...filteredFilters, createFilterByStatusField('Ожидает ревью', 1)];
      break;
    }
    case pageLinks.faq.published: {
      const filteredFilters = getFilteredFiltersArr(initialPageState.filters);
      initialPageState.filters = [...filteredFilters, createFilterByStatusField('Опубликован', 2)];
      break;
    }
    default: {
      break;
    }
  }

  yield put(actions.setFaqInitialState(initialPageState));
}

function createFilterByStatusField(filterLabel, filterValue) {
  return {
    columnName: tableColumns.status.name,
    value: {
      label: i18n.t(filterLabel),
      value: filterValue,
    },
  };
}

function getFilteredFiltersArr(arr) {
  if (Array.isArray(arr)) {
    return arr.filter(({ columnName }) => columnName !== tableColumns.status.name);
  }
  return [];
}
