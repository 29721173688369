// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
// Components
import DownloadButton from './components/DownloadButton';
import Table from './components/Table';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../../components/_Table/GridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useMagentoRequestsList } from './_hooks/useMagentoRequestsList';
// Engine
// import { asyncActions } from '../../../../engine/core/magentoRequests/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/magentoRequests/actions';
import { initialState, stateKeys } from '../../../../engine/core/magentoRequests/reducer';

function MagentoRequestsPage() {
  const { t } = useTranslation();
  useMagentoRequestsList();
  const dispatch = useDispatch();
  // const settingStatus = useSelector(selectors.magentoRequests.settingStatus);
  // const settingPending = useSelector(selectors.magentoRequests.settingPending);
  const isLoading = useSelector(selectors.magentoRequests.isLoading);
  const filters = useSelector(selectors.magentoRequests.filters);
  const sorting = useSelector(selectors.magentoRequests.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.magentoRequestsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.magentoRequestsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.magentoRequestsList).get(stateKeys.currentPage)));
  }, [dispatch]);

  return (
    <>
      <PageHeader title={t('Ошибки от МГ')}>
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={() => dispatch(asyncActions.putSettingsAsync())} */}
        {/* /> */}
        <DownloadButton />
        <ResetFiltersButton pending={isLoading} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <Table />
    </>
  );
}

export default MagentoRequestsPage;
