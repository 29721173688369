import { COLOR } from '../../../../../../../containers/App/AppStyles';

const CustomPaginPanelStyles = theme => ({
  paginContainer: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: '40%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: 10,
    height: 48,
    '& .Pagination-pagination': {
      '& .Pagination-rowsLabel': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: '400',
        color: COLOR.grey.main,
        paddingRight: '40px',
      },
      '& .MuiButtonBase-root': {
        borderRadius: '3px',
        padding: '5px',
        margin: '0 2px',
        minWidth: '30px',
        background: COLOR.grey['8'],
        fontSize: '16px',
        lineHeight: '20px',
        color: COLOR.grey.main,
        '&.Mui-disabled': {
          color: COLOR.grey['54'],
        },
        '&.Pagination-activeButton': {
          background: COLOR.controls.dark,
          color: '#ffffff',
        },
        '&.MuiIconButton-root': {
          padding: '0',
          height: '30px',
          '& .MuiSvgIcon-root': {
            width: '26px',
            height: '26px',
          },
        },
      },
    },
  },
  btn: {
    backgroundColor: COLOR.grey['8'],
    boxShadow: 'none',
    color: COLOR.grey.main,
    width: '30px',
    height: '30px',
    padding: '6px 8px',
    '& .MuiSvgIcon-root': {
      width: 16,
    },
    '&.MuiButton-root:hover': {
      background: COLOR.grey['18'],
    },
    '&.MuiButton-root:focus': {
      background: COLOR.grey['18'],
    },
    '&.Mui-disabled': {
      color: COLOR.grey['54'],
      background: COLOR.grey['8'],
    },
  },
  btnPreviousPage: {
    paddingLeft: '10px',
    paddingRight: '4px',
  },
  btnFirsPage: {
    paddingLeft: '2px',
    paddingRight: '2px',
    '& .MuiSvgIcon-root': {
      width: 26,
    },
  },
  offsetSelect: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    marginRight: 35,
  },
  selectLabel: {
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    color: COLOR.grey.main,
    overflow: 'initial',
    textOverflow: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

export default CustomPaginPanelStyles;
