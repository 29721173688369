// Core
import { apply } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { localData } from '../../../../../config/localData';
import { store } from '../../../../../init/store';

export function* callGetCompletionImportListWorker() {
  const { completionImportList } = localData.keysObj;
  const completionImportListData = yield apply(localData, localData.getItem, [completionImportList]);

  store.dispatch(actions.setCompletionImportList(completionImportListData || {}));
}
