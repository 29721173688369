// Core
import React, { memo } from 'react';
import values from 'lodash/values';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import EmailTemplatesEditCell from './EmailTemplatesEditCell';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/emailTemplates/actions';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { useEmailTemplatesList } from '../_hooks/useEmailTemplatesList';
// import { asyncActions } from '../../../../../engine/core/emailTemplates/saga/asyncActions';
// Helpers
// import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';

function EmailTemplatesTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.emailTemplates.isLoading);
  // Data
  const rows = useSelector(selectors.emailTemplates.list);
  // const columns = [
  //   tableDataTypes.subject,
  //   tableDataTypes.subjectUa,
  //   tableDataTypes.eventName,
  //   tableDataTypes.mailTemplateGroup,
  //   tableDataTypes.mailTemplateRoles,
  // ];
  // Edit
  const hasAccessEdit = useAccessList(accessList.feedEdit);
  const editWidth = useEditCellWidth([hasAccessEdit], 0, 40, 40);
  // Paging
  const currentPage = useSelector(selectors.emailTemplates.currentPage);
  const pageSize = useSelector(selectors.emailTemplates.pageSize);
  const totalCount = useSelector(selectors.emailTemplates.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.emailTemplates.sorting);
  // Filtering
  const filters = useSelector(selectors.emailTemplates.filters);
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.emailTemplates.settingStatus);
  // const settingPending = useSelector(selectors.emailTemplates.settingPending);

  const tableColumns = Object.freeze({
    subject: Object.freeze({
      type: 'subject',
      name: 'subject',
      title: t('Тема письма ру.'),
    }),
    subjectUa: Object.freeze({
      type: 'subjectUa',
      name: 'subjectUa',
      title: t('Тема письма укр.'),
    }),
    eventName: Object.freeze({
      type: 'mailTemplateEventName',
      name: 'eventName',
      title: t('Событие'),
    }),
    mailTemplateGroup: Object.freeze({
      name: 'mailTemplateGroup',
      type: 'mailTemplateGroup',
      title: t('Группа'),
    }),
    mailTemplateRoles: Object.freeze({
      name: 'mailTemplateRoles',
      type: 'mailTemplateRoles',
      title: t('Получатели'),
    }),
  });

  return (
    <DxTable
      name="emailTemplates"
      fixedColumn
      isLoading={isLoading}
      rows={rows}
      columns={values(tableColumns)}
      updateHooks={useEmailTemplatesList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Edit
      editComponent={EmailTemplatesEditCell}
      editComponentWidth={editWidth}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.emailTemplates.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(EmailTemplatesTable);
