// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function EyeIcon(props) {
  return (
    <SvgIcon className="eye-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M21.9091 11.5C21.9091 11.5 18.3636 5 12.4545 5C6.54545 5 3 11.5 3 11.5C3 11.5 6.54545 18 12.4545 18C18.3636 18 21.9091 11.5 21.9091 11.5ZM4.38627 11.5C4.9577 10.6315 5.61494 9.82263 6.34809 9.08555C7.86909 7.56218 9.94909 6.18182 12.4545 6.18182C14.96 6.18182 17.0388 7.56218 18.5622 9.08555C19.2953 9.82263 19.9526 10.6315 20.524 11.5C20.4555 11.6028 20.3798 11.7163 20.2935 11.8404C19.8976 12.4076 19.3126 13.164 18.5622 13.9145C17.0388 15.4378 14.9588 16.8182 12.4545 16.8182C9.94909 16.8182 7.87027 15.4378 6.34691 13.9145C5.61376 13.1774 4.95652 12.3685 4.38509 11.5H4.38627Z" fill="inherit" />
      <path d="M12.4547 8.54547C11.6711 8.54547 10.9196 8.85675 10.3655 9.41083C9.81146 9.96492 9.50018 10.7164 9.50018 11.5C9.50018 12.2836 9.81146 13.0351 10.3655 13.5892C10.9196 14.1433 11.6711 14.4546 12.4547 14.4546C13.2383 14.4546 13.9898 14.1433 14.5439 13.5892C15.098 13.0351 15.4093 12.2836 15.4093 11.5C15.4093 10.7164 15.098 9.96492 14.5439 9.41083C13.9898 8.85675 13.2383 8.54547 12.4547 8.54547ZM8.31836 11.5C8.31836 10.403 8.75415 9.35088 9.52987 8.57516C10.3056 7.79944 11.3577 7.36365 12.4547 7.36365C13.5518 7.36365 14.6039 7.79944 15.3796 8.57516C16.1553 9.35088 16.5911 10.403 16.5911 11.5C16.5911 12.597 16.1553 13.6491 15.3796 14.4249C14.6039 15.2006 13.5518 15.6364 12.4547 15.6364C11.3577 15.6364 10.3056 15.2006 9.52987 14.4249C8.75415 13.6491 8.31836 12.597 8.31836 11.5Z" fill="inherit" />
    </SvgIcon>
  );
}

export default EyeIcon;
