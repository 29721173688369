// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// Components
import ContentProductEdit from './ContentProductEdit';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../engine/config/routes';

function ContentProductBulkEditPage() {
  const { t } = useTranslation();
  const productCategoryActiveItem = useSelector(selectors.contentProduct.productCategoryActiveItem);

  if (!productCategoryActiveItem) {
    return <Navigate to={pageLinks.content.products.simpleView} />;
  }

  return (
    <ContentProductEdit
      categoryId={productCategoryActiveItem.get('value')}
      massEdit
      pageTitle={productCategoryActiveItem.get('label')}
      pageSubTitle={t('Массовое редактирование')}
    />
  );
}

export default ContentProductBulkEditPage;
