// Core
import { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Ui
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
// Icons
import SaveIcons from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { userActions } from '../../../../../../../engine/core/user/action';
// Parts
import ButtonWithIcon from '../../../../../Buttons/ButtonWithIcon/ButtonWithIcon';
import GridSettingsButton from '../GridSettingsButton';
import ColumnItem from './ColumnItem';
// Helpers
import { useStyles } from '../../../../../../hooks/useStyles';
import EditTableColumnStyles from './EditTableColumnStyles';
import { BUTTON_SIZE } from '../../../../../../containers/App/AppStyles';

function EditTableColumnList(props) {
  const {
    columns, handlerReset, settingStatus,
    settingPending, putSetting, name, onUserSettings,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(EditTableColumnStyles);
  const dispatch = useDispatch();
  const editColumns = useMemo(() => columns.filter(item => item.isFeature !== true), [columns]);

  const changeColumnVisible = useCallback((columnName, event) => {
    const gridSettings = [];
    find(editColumns, (item) => {
      const newItem = item;
      if (newItem.name === columnName) {
        newItem.visible = event.target.checked;
      }
      gridSettings.push(newItem);
    });

    if (onUserSettings) {
      onUserSettings({ name, gridSettings });
    } else {
      dispatch(userActions.mergeUserSettings({ [name]: { gridSettings } }));
    }
  }, [editColumns, onUserSettings, name]);

  return (
    <div className={classes.formGroup}>
      <FormGroup row>
        {editColumns.map(column => (
          <ColumnItem
            key={column.name}
            name={column.name}
            changeColumnVisible={changeColumnVisible}
            visible={column.visible}
            title={column.title}
          />
        ))}
      </FormGroup>
      <Divider className={classes.formGroupDivider} />
      <div className={classes.buttonWrapper}>
        <GridSettingsButton
          blockVariant
          color="secondary"
          icon={<SaveIcons />}
          text={t('Сохранить и закрыть')}
          settingStatus={settingStatus}
          settingPending={settingPending}
          putSetting={putSetting}
          sx={{ height: 32 }}
        />
        <ButtonWithIcon
          variant="outlined"
          color="secondary"
          text={t('Сбросить настройки')}
          onClick={handlerReset}
          className={classes.resetButton}
          sx={{ p: BUTTON_SIZE.outlinedWithIconSmall }}
        >
          <RefreshIcon />
        </ButtonWithIcon>
      </div>
    </div>
  );
}

EditTableColumnList.propTypes = {
  putSetting: PropTypes.func.isRequired,
  handlerReset: PropTypes.func.isRequired,
  settingStatus: PropTypes.bool.isRequired,
  settingPending: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      title: PropTypes.string.isRequired,
      visible: PropTypes.bool,
    }),
  ).isRequired,
  name: PropTypes.string,
  onUserSettings: PropTypes.func,
};

export default EditTableColumnList;
