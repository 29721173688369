import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as orderHistoryKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const orderHistory = Object.freeze({
  currentPage: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.currentPage], 0),
  filters: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.filters], List()),
  isLoading: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.pending], false),
  list: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.items], List()),
  pageSize: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.reload], false),
  totalCount: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.totalCount], 0),
  sorting: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.sorting], List()),
  settingStatus: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.settingStatus], false),
  settingPending: state => getIn(state, [orderHistoryKeys.controller, orderHistoryKeys.orderHistoryList, orderHistoryKeys.settingPending], false),
});
