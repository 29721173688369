// Core
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../../engine/core/categories/saga/asyncActions';
import { actions } from '../../../../../engine/core/categories/actions';
import { selectors } from '../../../../../engine/config/selectors';

export function useCategoryItem(hashId) {
  const dispatch = useDispatch();
  const categoryItem = useSelector(selectors.categories.categoryItem);
  const categoryItemLoading = useSelector(selectors.categories.categoryItemLoading);

  const getCategoryItemByHashIdAsync = useCallback(() => {
    dispatch(asyncActions.getCategoryItemByHashId(hashId));
  }, [dispatch, hashId]);

  const setCategoryItemDefaultState = useCallback(() => {
    dispatch(actions.setCategoryItem({ item: {}, pending: false }));
  }, [dispatch]);

  return useMemo(() => ({
    getCategoryItemByHashIdAsync,
    setCategoryItemDefaultState,
    category: categoryItem,
    isLoading: categoryItemLoading,
  }), [categoryItem, categoryItemLoading, getCategoryItemByHashIdAsync, setCategoryItemDefaultState]);
}
