// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetOrdersStatusesListWorker() {
  const ordersStatusesListPending = yield select(selectors.orders.ordersStatusesListPending);
  if (ordersStatusesListPending) {
    return null;
  }
  yield put(actions.setOrdersStatusesListPending(true));
  const { statuses: statusesKey, statusesLastUpdate: statusesLastUpdateKey } = localData.keysObj;
  const language = localStorage.getItem('language');
  const statuses = `${statusesKey}_${language}`;
  const statusesLastUpdate = `${statusesLastUpdateKey}_${language}`;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [statusesLastUpdate]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.statusesLastUpdate);

  let response;
  let data = {
    orderStatuses: [],
    cancelStatuses: [],
    statusesFlow: {},
  };

  switch (true) {
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.orders.getOrdersStatusesList);
      yield apply(localData, localData.setItem, [statusesLastUpdate, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      data = yield apply(localData, localData.getItem, [statuses]);
      if (!data) {
        response = yield apply(api, api.orders.getOrdersStatusesList);
      }
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [statuses, data]);
  }

  yield put(actions.setOrdersStatusesList(data));
  yield put(actions.setOrdersStatusesListPending(false));
}
