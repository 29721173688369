// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers

import {
  callGetPriceListDownloadWorker,
  callPostUploadPriceListWorker,
} from './workers';

function* watchGetPriceListDownload() {
  yield takeEvery(asyncTypes.GET_PRICE_CREATOR_PRICE_LIST_DOWNLOAD_ASYNC, callGetPriceListDownloadWorker);
}

function* watchPostPriceListUpload() {
  yield takeEvery(asyncTypes.POST_PRICE_CREATOR_PRICE_LIST_UPLOAD_ASYNC, callPostUploadPriceListWorker);
}

export function* watchersPriceCreator() {
  yield all([
    watchGetPriceListDownload(),
    watchPostPriceListUpload(),
  ]);
}
