// Core
import { apply } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { localData } from '../../../../../config/localData';
import { store } from '../../../../../init/store';

export function* callPutCompletionImportListItemWorker({ payload }) {
  const { hashId, data } = payload;

  const { completionImportList } = localData.keysObj;
  const completionImportListData = yield apply(localData, localData.getItem, [completionImportList]);


  const dataFeed = completionImportListData?.[hashId]
    ? {
      ...completionImportListData?.[hashId],
      ...data,
    }
    : data;

  yield apply(localData, localData.setItem,
    [
      completionImportList,
      {
        ...completionImportListData,
        [hashId]: dataFeed,
      },
    ]);

  store.dispatch(actions.mergeCompletionImportListItem({ hashId, data }));
}
