// Core
import { apply, put, select } from 'redux-saga/effects';
import { List, Map } from 'immutable';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../../config/localData';
import { selectors } from '../../../../../config/selectors';

export function* callGetStatusesListWorker({ payload: params }) {
  const { type, forceUpdate } = params;

  if (type === 'product') {
    yield put(actions.mergeStatusesList({ pending: true }));
  } else if (type === 'productElaboration') {
    yield put(actions.mergeStatusesElaborationList({ pending: true }));
  }

  const { feedProductsStatuses: statuses, feedProductsStatusesLastUpdate: statusesLastUpdate } = localData.keysObj;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [statusesLastUpdate]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.feedStatusesLastUpdate);

  let response;
  let data = {
    items: List(),
    flow: Map(),
  };

  switch (true) {
    case forceUpdate:
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.feeds.getFeedsStatusesList, [params]);
      yield apply(localData, localData.setItem, [statusesLastUpdate, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      const dataFromLocal = yield apply(localData, localData.getItem, [`${statuses}_${type}`]);
      if (!dataFromLocal) {
        response = yield apply(api, api.feeds.getFeedsStatusesList, [params]);
      }
      data = {
        items: List(dataFromLocal?.items),
        flow: Map(dataFromLocal?.flow),
      };
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { statuses: items, statusesFlow: flow } = responseData;
    data = {
      items: List(items),
      flow: Map(flow),
    };

    yield apply(localData, localData.setItem, [`${statuses}_${type}`, { items, flow }]);
  }

  if (type === 'product') {
    yield put(actions.mergeStatusesList({
      ...data,
      pending: false,
    }));
  } else if (type === 'productElaboration') {
    yield put(actions.mergeStatusesElaborationList({
      ...data,
      pending: false,
    }));
  }
}
