import { COLOR } from '../../../../containers/App/AppStyles';

const UserPartnerRolesFieldStyles = () => ({
  fieldSet: {
    borderRadius: 5,
    borderColor: '#949494bd',
    borderWidth: 1,
    marginTop: 5,
  },
  fieldSetError: {
    borderWidth: 2,
    borderColor: COLOR.error,
  },
  legend: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0 8px',
    fontSize: 12,
  },
  legendError: {
    color: COLOR.error,
  },
  label: {
    'align-self': 'flex-start',
  },
  disabled: {
    color: '#7f7f8f',
  },
});

export default UserPartnerRolesFieldStyles;
