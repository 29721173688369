// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callGetMailTemplateWorker,
  callGetMailTemplateByHashIdWorker,
  callGetMailTemplatesEventsWorker,
  callGetMailTemplatesInitialStateWorker,
  callPutMailTemplateByHashIdWorker,
  callGetMailTemplatesRolesWorker,
  callGetMailTemplatesGroupsWorker,
} from './workers';

function* watchGetMailTemplate() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATE_ASYNC, callGetMailTemplateWorker);
}
function* watchGetMailTemplateByHash() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATE_BY_HASH_ID_ASYNC, callGetMailTemplateByHashIdWorker);
}
function* watchGetMailTemplatesEvents() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATES_EVENTS_ASYNC, callGetMailTemplatesEventsWorker);
}
function* watchGetMailTemplatesRoles() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATES_ROLES_ASYNC, callGetMailTemplatesRolesWorker);
}
function* watchGetMailTemplatesGroups() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATES_GROUPS_ASYNC, callGetMailTemplatesGroupsWorker);
}
function* watchPutMailTemplateByHashId() {
  yield takeEvery(asyncTypes.PUT_MAIL_TEMPLATE_BY_HASH_ID_ASYNC, callPutMailTemplateByHashIdWorker);
}
function* watchGetMailTemplatesInitialState() {
  yield takeEvery(asyncTypes.GET_MAIL_TEMPLATES_INITIAL_STATE_ASYNC, callGetMailTemplatesInitialStateWorker);
}

export function* watchersAdmin() {
  yield all([
    watchGetMailTemplate(),
    watchGetMailTemplateByHash(),
    watchGetMailTemplatesEvents(),
    watchPutMailTemplateByHashId(),
    watchGetMailTemplatesInitialState(),
    watchGetMailTemplatesRoles(),
    watchGetMailTemplatesGroups(),
  ]);
}
