import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function GoogleReCaptcha(props) {
  const { children } = props;
  const isReCaptchaAvailable = process.env.REACT_APP_RECAPTCHA === 'true';
  const currentPath = window.location.pathname;
  const excludeRecaptcha = currentPath.includes('/404') || currentPath.includes('/500');

  return isReCaptchaAvailable && !excludeRecaptcha ? (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
}

export default GoogleReCaptcha;
