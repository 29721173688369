import Box from '@mui/material/Box';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { COLOR } from '../../../../containers/App/AppStyles';
import Informer from '../../../../components/Informer/Informer';
import { selectors } from '../../../../../engine/config/selectors';
import Loading from '../../../../components/Loading/Loading';

const PURCHASE_IN_INSTALLMENTS_URLS = {
  applicationForm: 'https://docs.google.com/forms/d/e/1FAIpQLSdSG6drSPzwRzLSuFL0u7MTanBfneBhka3NSec9JxdAZmi-Gw/viewform',
  conditions: 'https://chast.monobank.ua/vendors',
  alloUA: 'https://allo.ua/',
  vchasno: 'https://vchasno.com.ua/',
};

const MonoInstallmentsPaymentModal = ({ modalOpen, handleCloseModal, partnerHashId }) => {
  const dispatch = useDispatch();
  const { t, i18n: { language } } = useTranslation();
  const partnerData = useSelector(selectors.partner.userData);
  const partnerDataLoading = useSelector(selectors.partner.partnerIsLoading);
  const companyType = partnerData?.infoFieldValues?.enterpriseType?.current;

  const faqLink = `${PURCHASE_IN_INSTALLMENTS_URLS.alloUA}${language}/marketplace-faq/`;


  useEffect(() => {
    if (partnerHashId !== undefined) {
      dispatch(partnerAsyncAction.getPartnerInfo({ hashId: partnerHashId }));
    }
  }, [partnerHashId]);

  const handleModalSend = () => {
    window.open(PURCHASE_IN_INSTALLMENTS_URLS.applicationForm);
    handleCloseModal();
  };

  const licenseeLink = companyType === 'ФОП' ? '/docs/example_licensee_agreement_fop_monobank_bnpl.png' : '/docs/example_licensee_agreement_tov_monobank_bnpl.png';

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalToggle={handleCloseModal}
      handleModalCancel={handleCloseModal}
      handleModalSend={handleModalSend}
      cancelText={t('Отменить')}
      sendText={t('Заполнить форму')}
      modalMaxWidth="sm"
      buttonSendIcon={null}
      title={t('Покупка частями Monobank')}
    >
      {partnerDataLoading ? (<Loading isLoading />) : (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Informer borderColor={COLOR.error}>
            <Box sx={{ display: 'flex' }}>
              <InfoOutlinedIcon color="error" sx={{ marginRight: '8px' }} />
              <Box component="span" sx={{ fontSize: '12px' }}>{t('ОБРАТИТЕ ВНИМАНИЕ, ЧТО НАЗВАНИЕ ПРЕДПРИЯТИЯ ПРИ РЕГИСТРАЦИИ СЧЕТА ПЧ MONOBANK ДОЛЖНО СООТВЕТСТВОВАТЬ НАЗВАНИЮ ПРЕДПРИЯТИЯ В ЛИЧНОМ КАБИНЕТЕ PARTNER.ALLO.UA')}</Box>
            </Box>
          </Informer>
          <Box sx={{ marginTop: '16px', fontSize: '14px' }}>
            {t('Заполните форму для регистрации счета ПЧ monobank по')}<Link href={PURCHASE_IN_INSTALLMENTS_URLS.applicationForm} target="_blank">{t('ссылке')}. </Link><br />
            • {t('Ознакомьтесь с условиями/тарифами, предлагаемыми monobank, по')} <Link href={PURCHASE_IN_INSTALLMENTS_URLS.conditions} target="_blank">{t('ссылке')}</Link><br />
            2.  <Trans i18nKey="monobank_installments">Ознакомьтесь с инструкцией по обработке заказов с помощью сервиса «Покупка частями monobank» на площадке <Link href={PURCHASE_IN_INSTALLMENTS_URLS.alloUA} target="_blank">allo.ua</Link> по</Trans> <Link href={faqLink} target="_blank" underline="always"> {t('ссылке')} </Link> <br />
            3.&nbsp;
            <Trans i18nKey="monobank_installments_part1">В течение 1-3 дней на вашу почту и в раздел «Уведомления» Личного кабинета будет направлено письмо с текстом:
              “Уважаемый партнер, для завершения процесса подключения сервиса “Покупка частями monobank” вам необходимо загрузить Заявление лицензиата, заполнить и отправить его через сервис <Link href={PURCHASE_IN_INSTALLMENTS_URLS.vchasno} target="_blank">«Вчасно»</Link>, предварительно подписав ЭЦП.
              Реквизиты «Вчасно» <Link href={PURCHASE_IN_INSTALLMENTS_URLS.alloUA} target="_blank" underline="always">Allo.ua</Link>:
              ЕГРПОУ 30012848;
              E-mail: <Link href="mailto:Edo_doc@allo.ua">Edo_doc@allo.ua</Link>
              По <Link href={licenseeLink} target="_blank">ссылке</Link> – пример заполнения Заявления лицензиата”
            </Trans>
            <Trans components={{ bold: 'strong' }}>Важно! Если в течение 3 рабочих дней вы не получили письмо, необходимо обратиться в службу поддержки или на горячую линию</Trans><br />
            4. {t('После проверки Заявления лицензиата на вашу почту и в Личный кабинет раздел “Уведомления” будет отправлено письмо с текстом: “Уважаемый партнер, вам подключен сервис “Покупка частями monobank”.')}
          </Box>
        </Box>
      )}
    </Modal>
  );
};


export default MonoInstallmentsPaymentModal;
