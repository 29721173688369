export const partnerStatuses = Object.freeze({
  notConfirmCode: 1,
  questionnaireIsNotConfirmed: 3,
  questionnaireFill: 4,
  questionnairePendingConfirm: 5,
  questionnaireNotConfirm: 6,
  contractSigning: 11,
  active: 12,
  awaitingPayment: 13,
  cancelStatus: 14,
  frozenSigning: 15,
  questionnairePending: 16,
  suspended: 22,
  problematic: 23,
  blocked: 24,
  disabled: 25,
});
//  "1": "Не активирован кодом",
//  "3": "Анкета не подтверждена",
//  "4": "Заполнение анкеты",
//  "5": "Ожидает проверки Анкеты",
//  "6": "Не прошел подтверждение анкеты",
//  "11": "Подписание договора",
//  "12": "Активный",
//  "13": "Ожидает оплаты",
//  "14": "Отклонен",
//  "15": "Подписание заморожено"
//  "16": "Анкета на рассмотрении"
//  "22": "Приостановлен"
//  "23": "Проблемний"
//  "24": "Заблокирован"
//  "25": "Отключен"
