// Core
import { apply, select, put } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
// Engine
import { localData } from '../../../../../config/localData';
import { selectors } from '../../../../../config/selectors';
import { types } from '../../types';
import { actions } from '../../actions';

export function* callSetToLocalDataWorker({ type, payload }) {
  const pathname = yield select(selectors.router.pathname);
  const currentFilters = yield select(selectors.stopListBrandsCategories.filters);
  const currentSorting = yield select(selectors.stopListBrandsCategories.sorting);
  const currentSelection = yield select(selectors.stopListBrandsCategories.selection);
  const data = {
    currentPage: yield select(selectors.stopListBrandsCategories.currentPage),
    filters: currentFilters.toJS(),
    pageSize: yield select(selectors.stopListBrandsCategories.pageSize),
    totalCount: yield select(selectors.stopListBrandsCategories.totalCount),
    sorting: currentSorting.toJS(),
  };

  let currentData;

  switch (type) {
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE: {
      currentData = { currentPage: payload };
      break;
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE: {
      currentData = { pageSize: payload };
      break;
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS: {
      const currentPage = data.currentPage;
      const filters = payload.filter(({ value }) => !isNil(value));
      if (currentPage) {
        yield put(actions.setCurrentPage(0));
      }
      if (currentSelection.size) {
        yield put(actions.setSelection([]));
      }
      currentData = { filters };
      break;
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_SORTING: {
      currentData = { sorting: payload };
      break;
    }
    default: {
      break;
    }
  }

  data.currentPage = yield select(selectors.stopListBrandsCategories.currentPage);
  yield apply(localData, localData.setItem, [pathname, { ...data, ...currentData }]);
}
