// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import PartnersRatingRatiosTable from './components/Table/PartnersRatingRatiosTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../../components/_Table/GridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { usePartnersRatingRatiosList } from './_hooks/usePartnersRatingRatiosList';
// Engine
// import { asyncActions } from '../../../../engine/core/billingRatingRatio/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/billingRatingRatio/actions';
import { initialState, stateKeys } from '../../../../engine/core/billingRatingRatio/reducer';

function PartnersRatingRatiosPage() {
  const { t } = useTranslation();
  usePartnersRatingRatiosList();
  const dispatch = useDispatch();
  // const settingStatus = useSelector(selectors.billingRatingRatio.settingStatus);
  // const settingPending = useSelector(selectors.billingRatingRatio.settingPending);
  const isLoading = useSelector(selectors.billingRatingRatio.isLoading);
  const settingPending = useSelector(selectors.billingRatingRatio.settingPending);
  const filters = useSelector(selectors.billingRatingRatio.filters);
  const sorting = useSelector(selectors.billingRatingRatio.sorting);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);
  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.billingRatingRatioList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.billingRatingRatioList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.billingRatingRatioList).get(stateKeys.currentPage)));
  }, [dispatch]);

  return (
    <>
      <PageHeader title={t('Коэффициент рейтинга')}>
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={() => dispatch(asyncActions.putSettingsAsync())} */}
        {/* /> */}
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <PartnersRatingRatiosTable />
    </>
  );
}

export default PartnersRatingRatiosPage;
