// Core
import { apply, put, select } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import { reset, change } from 'redux-form/immutable';

// lodash
import isEmpty from 'lodash/isEmpty';

// Engine
import { api } from '../../../../config/api';

// _helpers
import { actions } from '../../actions';
import { selectors } from '../../../../config/selectors';
import {
  recipientsImportFormName,
} from '../../../../../ui/page/Settings/Messages/components/RecipientsFields/components/RecipientsImportModal';
import {
  messageFormName,
  messageFormFields,
} from '../../../../../ui/page/Settings/Messages/components/form';
import { recipientsTypes } from '../../../../../ui/page/Settings/Messages/_helpers/recipientsTypes';

export function* callPostRecipientsImportWorker({ payload }) {
  const { files } = payload;
  yield put(actions.mergeRecipientsImport({
    pending: true,
  }));
  const response = yield apply(api, api.messages.postRecipientsImport, [{ files }]);
  if (response && response.status >= 200 && response.status < 300) {
    if (response.data) {
      const {
        partners, partnersNotFound,
      } = response.data;

      const isFound = !isEmpty(partners);
      const isNotFound = !isEmpty(partnersNotFound);
      const recipientsImportItems = yield select(selectors.messages.recipientsImportItems);
      const getFormValues = yield select(state => selectors.form.getFormValues(state, messageFormName));
      const items = recipientsImportItems.toJS();
      if (isFound || isNotFound) {
        yield put(reset(recipientsImportFormName));
        const fields = getFormValues.toJS();
        const groups = fields[messageFormFields.messageUsers];
        const itemsFound = items.itemsFound || [];
        const itemsNotFound = items.itemsNotFound || [];
        const getItemsFound = (other = []) => uniqBy([
          ...itemsFound,
          ...partners,
          ...other,
        ], 'value');

        if (!isEmpty(groups)) {
          for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            if (group.type === recipientsTypes.currentPartner) {
              yield put(change(messageFormName, `messageUsers[${i}].values`, getItemsFound(group.values)));
            }
          }
        }

        yield put(actions.mergeRecipientsImportItems({
          itemsFound: getItemsFound(),
          itemsNotFound: isNotFound ? uniqBy([
            ...itemsNotFound,
            ...partnersNotFound,
          ], 'label') : itemsNotFound,
        }));
      }
      yield put(actions.mergeRecipientsImport({
        isOpenModal: false,
      }));
    }
  }
  yield put(actions.mergeRecipientsImport({
    pending: false,
  }));
}
