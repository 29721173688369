import { getIn, List, Map } from 'immutable';
import { stateKeys as configurationsKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const configurations = Object.freeze({
  currentPage: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.currentPage], 0),
  filters: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.filters], List()),
  isLoading: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.pending], false),
  list: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.items], List()),
  pageSize: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.reload], false),
  totalCount: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.totalCount], 0),
  sorting: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.sorting], List()),
  settingStatus: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.settingStatus], false),
  settingPending: state => getIn(state, [configurationsKeys.controller, configurationsKeys.configurationsList, configurationsKeys.settingPending], false),
  itemByHashId: state => getIn(state, [configurationsKeys.controller, configurationsKeys.itemById, configurationsKeys.item], Map()),
  itemByHashIdLoading: state => getIn(state, [configurationsKeys.controller, configurationsKeys.itemById, configurationsKeys.pending], true),
  itemByHashIdPutLoading: state => getIn(state, [configurationsKeys.controller, configurationsKeys.itemById, configurationsKeys.putPending], false),
  types: state => getIn(state, [configurationsKeys.controller, configurationsKeys.types, configurationsKeys.items], List()),
  typesPending: state => getIn(state, [configurationsKeys.controller, configurationsKeys.types, configurationsKeys.pending], false),
});
