import { getIn, List } from 'immutable';
import { stateKeys as usersKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const users = Object.freeze({
  selection: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.selection], List()),
  currentPage: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.currentPage], 0),
  filters: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.filters], List()),
  isLoading: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.pending], false),
  list: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.items], List()),
  pageSize: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.reload], false),
  totalCount: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.totalCount], 0),
  sorting: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.sorting], List()),
  settingStatus: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.settingStatus], false),
  settingPending: state => getIn(state, [usersKeys.controller, usersKeys.usersList, usersKeys.settingPending], false),
  deletePending: state => getIn(state, [usersKeys.controller, usersKeys.delete, usersKeys.pending], false),
  downloadPending: state => getIn(state, [usersKeys.controller, usersKeys.download, usersKeys.pending], false),
});
