// Core
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// UI
import DeleteIcons from '@mui/icons-material/Delete';
// Containers
import Modal from '../../../../containers/Modals/Modal/Modal';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions } from '../../../../../engine/core/users/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';

function DeleteButton(props) {
  const { userIds, pending, redirectTo } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deletePending = useSelector(selectors.users.deletePending);
  const [openConfirm, setOpenConfirm] = useState(false);
  const isAllowedUserDelete = useAccessList(accessList.userDelete);

  if (!isAllowedUserDelete) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  const showModal = () => {
    setOpenConfirm(true);
  };

  const hideModal = () => {
    setOpenConfirm(false);
  };

  const onDelete = () => {
    dispatch(asyncActions.deleteUsersAsync({ userIds, redirectTo }));
    setOpenConfirm(false);
  };

  const deleteButton = () => (
    <ButtonWithIconAndCircularProgress
      text={t('Удалить')}
      onClick={onDelete}
      isLoading={deletePending}
      disabled={deletePending || !userIds.length}
    >
      <DeleteIcons />
    </ButtonWithIconAndCircularProgress>
  );

  return (
    <div className="exceptionHeaderMobileDialog">
      <ButtonWithIconAndCircularProgress
        text={t('Удалить')}
        onClick={showModal}
        isLoading={deletePending || pending}
        disabled={deletePending || !userIds.length || openConfirm || pending}
        sx={{ width: '100%' }}
      >
        <DeleteIcons />
      </ButtonWithIconAndCircularProgress>
      <Modal
        modalOpen={openConfirm}
        handleModalToggle={hideModal}
        handleModalSend={onDelete}
        handleModalCancel={hideModal}
        modalMaxWidth="sm"
        cancelText={t('Отменить')}
        buttonComponent={deleteButton}
        title={t('Подтвердите удаление')}
      />
    </div>
  );
}

DeleteButton.propTypes = {
  userIds: PropTypes.array,
  pending: PropTypes.bool,
  redirectTo: PropTypes.string,
};

export default DeleteButton;
