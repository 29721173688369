import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../../../engine/core/contentProduct/actions';
import { selectors } from '../../../../../../../engine/config/selectors';
import ContentProductStatusLogsTable from '../../../ContentProductStatusLogs/ContentProductStatusLogsTable.js';
import { tableStatusesLogsTitle } from '../../../../../../../_helpers/data/pages/settings/products/tableStatusesLogsScheme';

function ContentProductLogsModal() {
  const isShowLogModal = useSelector(selectors.contentProduct.logShowModal);
  const dispatch = useDispatch();
  const historyType = useSelector(selectors.contentProduct.historyType);
  const handleModalClose = () => {
    dispatch(actions.mergeContentProductStatusChangeLog({ showModal: false, hashId: null, historyType: null }));
  };
  return (
    <Modal
      title={tableStatusesLogsTitle[historyType]}
      modalOpen={isShowLogModal}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      modalMaxWidth="md"
      hideButtons
    >
      <ContentProductStatusLogsTable />
    </Modal>
  );
}


export default ContentProductLogsModal;
