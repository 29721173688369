// Core
import React, { useEffect } from 'react';
import cx from 'classnames';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Field } from 'redux-form/immutable';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import RenderCheckbox from './RenderCheckbox';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useUserRole } from '../../../../../hooks/useUserRole';
import { userRoles } from '../../../../../../engine/config/userRoles';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName } from '../ContentProductEdit';


function RenderSkuFormField(props) {
  const {
    margin, massEdit, input, disabled, meta, refresh, onRefresh, mode,
    featureDisabled, label, type, productByHashId,
    changeFieldValue, setTouchField, isRecommended, required,
  } = props;
  const isAdminRole = useUserRole(userRoles.admin);
  const { touched } = meta;
  const error = !!(touched && meta.error);
  const isDisabled = disabled || featureDisabled?.disabled || (!isAdminRole && (mode !== 'create'));
  const { isValueChanged, showChangedMessage } = useIsValueWasChanged(input);
  const classes = useStyles(ContentProductEditStyles);
  const checkboxName = getCheckboxName(input.name.toString());

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  const classNames = cx(classes.formControl, {
    [classes.formControlHelperTextChange]: showChangedMessage,
    [classes.changedFormControl]: showChangedMessage,
    [classes.errorFormControl]: error,
  });

  const prefix = productByHashId.getIn([input.name, 'prefix'], '');
  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;

  return (
    <FormControl
      error={error}
      className={classNames}
      margin={margin}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            formName={formName}
            {...input}
            name={input.name.toString()}
            disabled={isDisabled}
            error={error}
            touched={touched}
          >
            <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
              <TextField
                input={input}
                label={label}
                type={type}
                isRecommended={isRecommended}
                required={!isDisabled ? required : undefined}
                fullWidth
                disabled={isDisabled}
                meta={meta}
                error={error}
                validation
                endAdornment={prefix && (
                  <InputAdornment position="end">
                    <span className={classes.skuPrefix}>
                      &nbsp;&ndash;&nbsp;{prefix}&nbsp;
                    </span>
                  </InputAdornment>
                )}
              />
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderSkuFormField;
