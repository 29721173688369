// Core
import { List, AutoSizer } from 'react-virtualized';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
// Parts
import Typography from '@mui/material/Typography';
// Helpers
import { COLOR } from '../../../../../containers/App/AppStyles';
import getMenuListHeight from '../_helpers/getMenuListHeight';

/** Separate version of LongList for children amount without scroll */
const ITEMS_WITHOUT_SCROLL = 7;

const LongMenuListVirtual = (props) => {
  const { selectProps, children } = props;
  const { t } = useTranslation();

  const rowRenderer = (rowProps) => {
    const { index, key, style } = rowProps;
    return (
      <div
        className={cx(selectProps.classes.longRowItem, selectProps.classes.menuItem)}
        key={key}
        style={style}
      >
        {children[index]}
      </div>
    );
  };

  const scrollToIndex = Array.isArray(children) ? children.findLastIndex(child => child.props.isFocused) : -1;

  if (!children.length) {
    return (
      <Typography
        color="textSecondary"
        className={selectProps?.classes?.noOptionsMessage}
        sx={selectProps?.textFieldProps?.color === 'dark' && { color: COLOR.darkStyle.fontColorMain }}
      >
        {t('Нет вариантов')}
      </Typography>
    );
  }

  if (children.length < ITEMS_WITHOUT_SCROLL) {
    return (
      <components.MenuList
        {...props}
        className={`${selectProps?.classes.menuList} ${selectProps?.classes.menuItem}`}
      >
        {children}
        <div style={{ height: '80px' }} />
      </components.MenuList>
    );
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          height={getMenuListHeight(selectProps)}
          overscanRowCount={10}
          rowCount={children.length || 0}
          rowHeight={40}
          rowRenderer={rowRenderer}
          width={width}
          scrollToIndex={scrollToIndex}
        />
      )}
    </AutoSizer>
  );
};
export default LongMenuListVirtual;
