// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Select from '../../../_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncAction as mailAsyncActions } from '../../../../../engine/core/mail/saga/asyncAction';

function LetterTemplateEventFilter(props) {
  const {
    changeSelectFilter, filters,
  } = props;
  const mailTemplateListPending = useSelector(selectors.mail.isLoading);
  const dispatch = useDispatch();
  const mailTemplates = useSelector(selectors.mail.mailTemplatesEvents);
  const input = {
    value: filters && filters?.value,
    onChange: changeSelectFilter,
  };

  useEffect(
    () => {
      dispatch(mailAsyncActions.getMailTemplatesEvents());
    },
    [],
  );

  return (
    <Select
      isLoading={mailTemplateListPending}
      options={mailTemplates}
      label=""
      input={input}
    />
  );
}

LetterTemplateEventFilter.propTypes = {
  changeSelectFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default LetterTemplateEventFilter;
