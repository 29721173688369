// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
// UI
import InputAdornment from '@mui/material/InputAdornment';
// Parts
import TextField from '../../../../../../../../components/_Form/TextField/TextField/TextField';
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
// Engine
import { userRoles } from '../../../../../../../../../engine/config/userRoles';
// Helpers
import { useUserRole } from '../../../../../../../../hooks/useUserRole';

function SkuFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, isEditable: propsIsEditable, fullText,
    onConfirm, productId, sku, success, userId, valuesPlain, required, type,
  } = props;
  const [value, setValue] = useState(sku);
  const isAdminRole = useUserRole(userRoles.admin);
  const isDisabled = disabled || !isAdminRole;
  const isEditable = propsIsEditable && !isDisabled;
  const textFieldValue = value.trim();
  const isValidSkuProduct = required ? textFieldValue : undefined;

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onConfirmHandler = (payload) => {
    onConfirm({ [featureName]: payload });
  };

  const inputProps = {
    value,
    onChange,
  };

  return (
    <EditableContainer
      isEditable={isEditable}
      fullText={fullText}
      text={valuesPlain || textFieldValue}
    >
      <ConfirmButtons
        key={productId}
        error={error || !isValidSkuProduct}
        disabled={disabled}
        disabledOkButton={!isValidSkuProduct}
        required={required}
        notEmpty={textFieldValue !== ''}
        onConfirm={onConfirmHandler}
        onReject={setValue}
        success={success}
        value={textFieldValue}
        valuesPlain={valuesPlain}
        type={type}
        initValue={sku}
      >
        <TextField
          input={inputProps}
          endAdornment={(
            <InputAdornment position="end">
              &nbsp;&ndash;&nbsp;{userId.padStart(4, 0)}&nbsp;&nbsp;&nbsp;
            </InputAdornment>
            )}
        />
      </ConfirmButtons>
    </EditableContainer>
  );
}

SkuFieldWithConfirmButtons.propTypes = {
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditable: PropTypes.bool,
  fullText: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  sku: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  success: PropTypes.bool,
  type: PropTypes.string,
  userId: PropTypes.string,
};

SkuFieldWithConfirmButtons.defaultProps = {
  error: false,
  required: false,
  disabled: false,
  isEditable: false,
  fullText: false,
  sku: '',
  success: false,
  userId: '',
  valuesPlain: '',
};

export default memo(SkuFieldWithConfirmButtons);
