// Core
import {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
// Engine
import { Form, reduxForm, change } from 'redux-form/immutable';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SaveIcons from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import { uniqBy } from 'lodash';
import { isImmutable } from 'immutable';
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/bankInstallments/actions';
import { asyncActions } from '../../../../../engine/core/bankInstallments/saga/asyncActions';
// Hooks
import { useBankInstallmentsDataList } from './hooks/useBankIntsallmentsDataList';
// Parts
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import TableFilterRowComponent from './components/TableFilterRowComponent';
// Helpers
import { tableColumns } from './tableColumns';
import Select from '../../../../components/_Form/Selects/Select/Select';
import { initialState, stateKeys } from '../../../../../engine/core/bankInstallments/reducer';
import ResetFiltersButton from '../../../../components/_Table/DxTable/components/ResetFilterButton';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import TableCell from './components/TableCell';
import { usePartnerRole } from '../../../../hooks/usePartnerRole';
import { dispatchActionsForChildren } from './utils/dispatchActionsForChildren';
import { formName, monobankOptions, privatbankOptions } from './utils';
import { getChildrenRecursively } from './utils/getChildren';
import { MONO_INSTALLMENTS_PAYMENT_METHOD } from '../../../PartnersAccess/constants/paymentMethods';

function BankInstallmentsTable({
  hashId, handleSubmit, setIsPristine,
}) {
  useBankInstallmentsDataList(hashId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.bankInstallments.isLoading);
  const selections = useSelector(selectors.bankInstallments.selection);
  const accessData = useSelector(selectors.partnersAccess.accessData);
  const data = useSelector(selectors.partnersAccess.accessData);
  const normalizedData = useMemo(() => isImmutable(data) ? data.toJS() : data, [data]);

  const isShowMode = useMemo(() => {
    const { trackingStatus, contractSigned, payload: { licenseeStatement = false } = {} } = accessData.toJS();

    return trackingStatus && contractSigned && licenseeStatement;
  }, [accessData]);

  const [selectValue, setSelectValue] = useState('');
  const isPartner = usePartnerRole();
  // Rows
  const rows = useSelector(selectors.bankInstallments.list);

  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));

  const currentPage = useSelector(selectors.bankInstallments.currentPage);
  const pageSize = useSelector(selectors.bankInstallments.pageSize);
  const totalCount = useSelector(selectors.bankInstallments.totalCount);
  const selection = useSelector(selectors.bankInstallments.selection);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = (page) => {
    dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
    dispatch(actions.setCurrentPage(page));
  };
    // Sorting
  const onSortingChange = (currentSorting) => {
    dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
    dispatch(actions.setSorting(currentSorting));
  };
  const sorting = useSelector(selectors.bankInstallments.sorting);
  // Filtering
  const filters = useSelector(selectors.bankInstallments.filters);
  const onFiltersChange = (newFilters) => {
    dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
    dispatch(actions.setFilters(newFilters));
  };

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.bankInstallmentsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.bankInstallmentsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.bankInstallmentsList).get(stateKeys.currentPage)));
    dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
  }, [dispatch]);

  // Tree Data
  const treeDataRowIds = useSelector(selectors.bankInstallments.treeDataRowIds);
  const onTreeDataRowIdsChange = (parentIds) => {
    dispatch(actions.setBankInstallmentsTreeDataRowIds(parentIds));
  };

  const getChildRows = (row, rootRows) => {
    if (row === null) {
      return rootRows.filter(r => r.parentId === '');
    }

    return row && row.hasChildren ? rootRows.filter(r => r.parentId === row.categoryId) : null;
  };

  const bankInstallmentsList = useMemo(() => (
    !isEmpty(filters.toJS())
      ? rows.toJS().map(i => ({
        ...i, editable: true, key: i.categoryId, id: i.categoryId,
      }))
      : (
        map(rows.toJS(), item => ({
          ...item,
          editable: true,
          id: item.categoryId,
          key: item.categoryId,
          parentId: item?.parentId || '',
        }))
      )
  ), [filters, rows]);

  const onSelection = (selected) => {
    dispatch(actions.setSelection(selected));
  };

  useEffect(() => {
    const normalizeSelection = selection.toJS();
    const allSelections = getChildrenRecursively(bankInstallmentsList, normalizeSelection);
    const newSelection = uniqBy([...normalizeSelection, ...allSelections]);
    dispatch(actions.setSelection(newSelection));
  }, [bankInstallmentsList, selection.size]);

  const changedItems = useMemo(() => {
    const normalizeFormValue = formValues.toJS();
    return bankInstallmentsList.filter((item) => {
      const formValueItem = normalizeFormValue[`${item.categoryId}_paymentCount`]?.value ?? 0;
      return item.paymentCount !== formValueItem;
    });
  }, [bankInstallmentsList, formValues]);

  const filteringColumnExtension = tableColumns.map(column => ({ columnName: column.name, filteringEnabled: column.name !== 'installmentsHistory' }));
  const sortingStateColumnExtensions = tableColumns.map(column => ({ columnName: column.name, sortingEnabled: false }));

  const checkboxProps = {
    rowFilterComponent: TableFilterRowComponent,
    selection: selections,
    onSelection,
  };

  const onSubmit = (formVal) => {
    const normalizeFormValue = formVal.toJS();
    const items = changedItems.map(item => ({ categoryId: item.categoryId, paymentCount: normalizeFormValue[`${item.categoryId}_paymentCount`].value }));
    dispatch(asyncActions.postBankInstallmentsAsync({ items, hashId }));
    setSelectValue('');
  };

  useEffect(() => {
    setIsPristine(changedItems.length === 0);
  }, [changedItems]);

  const hangeSelectValue = (e) => {
    setSelectValue(e);
    selections.toJS().forEach((item) => {
      dispatch(change('bankInstallments', `${item}_paymentCount`, e));
      dispatchActionsForChildren(bankInstallmentsList, item, dispatch, e);
    });
  };

  const saveButtonDisabled = () => {
    if (!isShowMode) {
      return true;
    }
    if (!isPartner) {
      return true;
    }

    return selectValue ? !selectValue : changedItems.length === 0;
  };

  const options = normalizedData.partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD ? monobankOptions : privatbankOptions;

  return (
    <Form style={{ display: 'flex', flex: 1, flexDirection: 'column' }} onSubmit={e => e.preventDefault}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

        <Tooltip title={selection.size === 0 && t('Сначала выберите категории, к которым будет применено указанное количество платежей.')} PopperProps={{ style: { zIndex: 10000, maxWidth: '200px' } }}>
          <Box sx={{ width: '200px' }}>
            <Select options={options} value={selectValue} disabled={selection.size === 0 || !isPartner || !isShowMode} handleChange={hangeSelectValue} variant="outlined" placeholder={t('Количество платежей')} />
          </Box>
        </Tooltip>

        <Box sx={{ gap: '8px', display: 'flex', alignItems: 'center' }}>
          <ResetFiltersButton
            onReset={resetFilters}
            disabled={!(filters.size || sorting.size)}
            sx={{
              marginTop: '12px', marginBottom: '12px', maxHeight: '32px',
            }}
          />
          <ButtonWithIcon
            text={t('Сохранить')}
            component="button"
            disabled={saveButtonDisabled()}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            sx={{ maxHeight: '32px' }}
          >
            <SaveIcons />
          </ButtonWithIcon>
        </Box>
      </Box>
      <DxTable
        name="bankInstallments"
        fixedColumn
            // showShortText
        isLoading={isLoading}
        rows={bankInstallmentsList}
        updateHooks={useBankInstallmentsDataList}
        customCellComponent={cellProps => <TableCell {...cellProps} isShowMode={isShowMode} paymentMethod={normalizedData.partnerPaymentTypeId} />}
            // Paging
        pageSize={pageSize}
        totalCount={totalCount}
        onPageSize={onPageSize}
        currentPage={currentPage}
        onCurrentPage={onCurrentPage}
            // Tree Data
        treeDataRowIds={filters.size === 0 ? treeDataRowIds : null}
        onTreeDataRowIdsChange={onTreeDataRowIdsChange}
        getChildRows={getChildRows}
        tableTreeColumnFor="idMagentoCategory"
            // Sorting
        onSortingChange={onSortingChange}
        sorting={sorting}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
            // Filters
        filters={filters}
        onFiltersChange={onFiltersChange}
        filteringExtensions={filteringColumnExtension}
            // Settings
        gridSettings={tableColumns}
        {...(isPartner || isShowMode) && { ...checkboxProps }}
      />
    </Form>
  );
}

export default reduxForm({ form: formName })(BankInstallmentsTable);
