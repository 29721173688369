// Core
import { useCallback, useMemo } from 'react';
// Hooks
import { useParentEditMode } from './useParentEditMode';

export function useCurrentMode(mode) {
  const editMode = useParentEditMode();
  const checkCurrentMode = useCallback(currentMode => currentMode === mode, [mode]);

  return useMemo(() => ({
    isCreateMode: checkCurrentMode(editMode.create),
    isEditMode: checkCurrentMode(editMode.edit),
    isShowMode: checkCurrentMode(editMode.show),
  }), [checkCurrentMode, editMode]);
}
