// Core
import * as PropTypes from 'prop-types';
import { Map } from 'immutable';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Field, Form, reduxForm, initialize, reset,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import Loading from '../../../../components/Loading/Loading';
import Modal from '../../../../containers/Modals/Modal/Modal';
import FormSelect from '../../../../components/_Form/Selects/FormSelect/FormSelect';
import PaymentTypesDocsModalContent from '../../../Partner/components/PaymentTypesDocsModal/PaymentTypesDocsModalContent';
import PartnerSelect from '../../../../components/_Form/Selects/PartnerSelect';
// Engine
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import { partnerStatuses } from '../../../../../engine/config/partnerStatuses';
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/partners/accesses/actions';
import {
  asyncActions as partnersAsyncActions,
} from '../../../../../engine/core/partners/saga/asyncActions';
import { asyncActions as accessesAsyncActions } from '../../../../../engine/core/partners/accesses/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../../hooks/useAccessList';
import { validators } from '../../../../../_helpers/validationsForm';
import { formFields, formName } from './form';
import { api } from '../../../../../engine/config/api';
import {
  MONO_PAYMENT_METHOD, MONO_INSTALLMENTS_PAYMENT_METHOD, WFP_CARD_METHOD, PRIVATBANK_INSTALLMENTS_PAYMENT_METHOD,
} from '../../constants/paymentMethods';
import { usePartnerRole } from '../../../../hooks/usePartnerRole';


function PaymentTypeModal(props) {
  const {
    handleSubmit, partner: partnerProp, setPartner,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessCreate = useAccessList(accessList.partnerAccessCreate);
  const data = useSelector(selectors.partnersAccess.accessData);
  const accessDataPending = useSelector(selectors.partnersAccess.accessDataPending);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const partnerAccessData = data.toJS();
  const userPartnerHashId = useSelector(selectors.user.userPartnerHashId);
  const userPartnerId = useSelector(selectors.user.userPartnerId);
  const isPartner = usePartnerRole();
  const partner = isPartner ? { value: userPartnerId, hashId: userPartnerHashId } : partnerProp;
  const partnerPaymentTypeModal = useSelector(selectors.partnersAccess.partnerPaymentTypeModal);
  const {
    isOpen, mode, methods, pending, hashId,
  } = partnerPaymentTypeModal.toJS();
  const isAddMode = mode === 'add';
  const isEditMode = mode === 'edit';
  const isShowMode = mode === 'show';
  const addOrEditTitle = isAddMode ? t('Добавление способа оплат') : t('Изменить данные');
  const showTitle = t('Данные для договоров');
  const { pending: pendingMethods, items: itemsMethods } = methods;
  const partnerId = partner?.value;
  const formPaymentLabel = itemsMethods.filter(item => item.value === (getFormValues.toJS())?.[formFields.paymentType])[0]?.label;
  const formPaymentValue = itemsMethods.filter(item => item.value === (getFormValues.toJS())?.[formFields.paymentType])[0]?.value;
  const partnerPaymentTypeLabel = partnerAccessData?.partnerPaymentType || formPaymentLabel;
  const noActivePaymentMethods = partner && isAddMode && pendingMethods === false && itemsMethods.length === 0;
  const partnerInput = {
    value: partner,
    onChange: setPartner,
  };

  const handleModalToggle = useCallback(() => {
    dispatch(actions.mergePartnerPaymentTypeModal({
      isOpen: !isOpen,
      mode: undefined,
    }));
  }, [dispatch, isOpen]);


  const onSubmit = useCallback(async () => {
    const modalType = (() => {
      switch (formPaymentValue) {
        case MONO_PAYMENT_METHOD: {
          return 'MONO_MODAL';
        }
        case MONO_INSTALLMENTS_PAYMENT_METHOD: {
          return 'MONO_INSTALLMENTS_MODAL';
        }
        case PRIVATBANK_INSTALLMENTS_PAYMENT_METHOD: {
          return 'PRIVATBANK_MODAL';
        }
        default: {
          return null;
        }
      }
    })();
    if (modalType) {
      await api.partners.postBankPaymentAccess({ partner: partner.value, partnerPaymentType: formPaymentValue });
      dispatch(accessesAsyncActions.getPartnersPaymentMethodsAsync());
      dispatch(actions.setBankModal({ isOpen: modalType, hashId: partner.hashId }));
      handleModalToggle();
      return;
    }

    if (getFormValues.toJS) {
      const { mediaFiles, ...fields } = getFormValues.toJS();
      if (isEditMode) {
        dispatch(partnerAsyncAction.uploadPartnerPaymentDocs({
          mediaFiles,
          hashId: partnerAccessData?.hashId,
          ...fields,
        }));
      }
      if (isAddMode) {
        dispatch(accessesAsyncActions.postPartnersPaymentAccessesAsync({
          mediaFiles,
          partnerPaymentType: formPaymentValue,
          partner: partner?.[0]?.value || partner?.value,
          ...fields,
        }));
      }
    }
  }, [formPaymentValue, isAddMode, isEditMode, getFormValues, partnerAccessData, partner]);

  useEffect(() => () => {
    dispatch(reset(formName));
    dispatch(actions.mergePartnerPaymentTypeModal({
      isOpen: false,
      mode: undefined,
    }));
    if (isShowMode) {
      dispatch(actions.setPartnerAccessInfo({ data: Map() }));
    }
  }, [dispatch, isShowMode]);

  useEffect(() => {
    if (hashId && isShowMode) {
      dispatch(accessesAsyncActions.getParentAccessByHashIdAsync(hashId));
    }
  }, [dispatch, hashId, isShowMode]);

  useEffect(() => {
    if (isOpen && hasAccessCreate && isAddMode) {
      dispatch(partnersAsyncActions.getListWithMidAsync({ statusId: partnerStatuses.active }));
    }
  }, [dispatch, isOpen, hasAccessCreate, isAddMode]);

  useEffect(() => {
    const normalizeAccessData = data.toJS();
    const infoFieldPaymentValues = normalizeAccessData?.infoFieldPaymentValues;
    if (infoFieldPaymentValues && (isShowMode || isEditMode)) {
      dispatch(initialize(formName, infoFieldPaymentValues, Object.keys(infoFieldPaymentValues)));
    }
  }, [dispatch, data, isShowMode, isEditMode]);

  useEffect(() => {
    if (isAddMode && hasAccessCreate && partnerId && !isPartner) {
      dispatch(accessesAsyncActions.getPartnersPaymentMethodsAsync(partnerId));
    } else if (isAddMode && userPartnerHashId) {
      dispatch(accessesAsyncActions.getPartnersPaymentMethodsAsync());
    }
  }, [isAddMode, dispatch, hasAccessCreate, userPartnerHashId, partnerId]);

  const partnerListSelector = selectors.partners.listWithMid;

  return (
    <Modal
      title={isShowMode ? showTitle : addOrEditTitle}
      modalOpen={isOpen}
      loadingSend={pending}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      disabledSend={pendingMethods || noActivePaymentMethods || !partnerPaymentTypeLabel}
      hideButtons={isShowMode}
      handleModalSend={handleSubmit(onSubmit)}
      modalMaxWidth="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {
            hasAccessCreate && isAddMode && !isPartner && (
              <PartnerSelect
                input={partnerInput}
                partnerListSelector={partnerListSelector}
                name="partner"
                margin="normal"
              />
            )
          }
          {partnerId && isAddMode && pendingMethods === false && itemsMethods.length !== 0
            ? (
              <Field
                fullWidth
                validation
                name={formFields.paymentType}
                component={FormSelect}
                options={itemsMethods}
                margin="normal"
                label={t('Выберите способ оплаты')}
                validate={[validators.required]}
              />
            )
            : null
          }
          {
            (partnerId && isAddMode && pendingMethods === false && itemsMethods.length === 0)
            || (userPartnerHashId && isAddMode && pendingMethods === false && itemsMethods.length === 0)
              ? (
                t('Нет доступных способов оплат')
              ) : null}
          {(pendingMethods === true || accessDataPending === true) && (<Loading isLoading />)}
          {(!isAddMode || formPaymentValue === WFP_CARD_METHOD) && (
            <PaymentTypesDocsModalContent
              mode={mode}
              view="columns"
              filesUploaded={!isAddMode}
              label={partnerPaymentTypeLabel}
            />
          )}
        </Box>
      </Form>
    </Modal>
  );
}

PaymentTypeModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  partner: PropTypes.object,
  setPartner: PropTypes.func,
};

export default reduxForm({
  form: formName,
})(PaymentTypeModal);
