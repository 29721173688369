// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  categoriesFeaturesItemList: Map({
    categoryName: undefined,
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  featureRequiresLK: Map({
    items: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'categoriesFeaturesItem',
  categoryName: 'categoryName',
  categoriesFeaturesItemList: 'categoriesFeaturesItemList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  featureRequiresLK: 'featureRequiresLK',
});

export const categoriesFeaturesItemReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CATEGORIES_FEATURES_ITEM_LIST: {
      const {
        items, totalCount, pending, categoryName,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.pending], pending)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.categoryName], categoryName)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.items], List(items))
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.totalCount], totalCount);
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_SORTING: {
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.sorting], List(payload));
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.filters], List(filters));
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_PAGE_SIZE: {
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.pageSize], payload);
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_CURRENT_PAGE: {
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.currentPage], payload);
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.categoriesFeaturesItemList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.filters], List(filters))
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.reload], reload)
        .setIn([stateKeys.categoriesFeaturesItemList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_SETTING_STATUS: {
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.settingStatus], payload);
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_SETTING_PENDING: {
      return state.setIn([stateKeys.categoriesFeaturesItemList, stateKeys.settingPending], payload);
    }
    case types.SET_CATEGORIES_FEATURES_ITEM_FEATURE_REQUIRES_LK: {
      return state.setIn([stateKeys.featureRequiresLK, stateKeys.items], List(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
