// Core
import { apply, select, put } from 'redux-saga/effects';

// lodash
import find from 'lodash/find';

// Engine
import { api } from '../../../../../config/api';
import { selectors } from '../../../../../config/selectors';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { defaultFilterTypeValue } from '../../../../../../ui/page/Products/AttributeSet/components/ProductWithoutManagerQuantitySelect';


export function* callPutContentManagerWorker({ payload }) {
  const { hashId, assignedTo } = payload;
  const currentPage = yield select(selectors.attributeSet.currentPage);
  const filters = yield select(selectors.attributeSet.filters);
  const pageSize = yield select(selectors.attributeSet.pageSize);
  const sorting = yield select(selectors.attributeSet.sorting);
  const foundFilterType = find(filters.toJS(), { columnName: 'filterType' });

  yield put(actions.updateList(
    setProductFeatureState({
      hashId,
      label: assignedTo.label,
      pending: true,
      key: assignedTo.key,
      success: false,
    }),
  ));
  const data = {
    hashId,
    filterType: foundFilterType?.value || defaultFilterTypeValue,
    assignedTo: {
      manager: assignedTo.manager,
      priority: assignedTo.priority,
    },
  };
  const response = yield apply(api, api.feedsAttributeSet.putContentManager, [data]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;

        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.updateList(
          {
            hashId,
            label: assignedTo.label,
            key: assignedTo.key,
            value: assignedTo.priority,
            pending: false,
            error: false,
            success: true,
          },
        ));
        const params = {
          limit: pageSize,
          offset: pageSize * currentPage,
          sorting: sorting.toJS(),
          filterType: data.filterType,
        };

        filters.toJS().reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);

        yield put(asyncActions.getListAsync(params));

        break;
      }
      default: {
        break;
      }
    }
  }
}

function setProductFeatureState(productFeatureState = {}) {
  const productFeatureDefaultState = {
    error: false,
    pending: false,
    success: false,
  };

  return Object.assign({}, productFeatureDefaultState, productFeatureState);
}
