// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { replace } from 'redux-first-history';
import { asyncActions } from '../asyncActions';
import { api, requestStatus } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../config/routes';
import { actions } from '../../actions';

export function* callPutItemByHashId({ payload: { hashId, ...data } }) {
  yield put(actions.setIsItemByHashIdPutPending(true));
  const response = yield apply(api, api.serviceDesk.putHashId, [{ hashId, data }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
    yield put(replace(pageLinks.faq.other));
    yield put(asyncActions.getListAsync());
  }
  yield put(actions.setIsItemByHashIdPutPending(false));
}
