// Core
import { List } from 'immutable';
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// Instruments
import { userActions } from '../../action';
import { api } from '../../../../config/api';
import { formName } from '../../../../../ui/page/MailSettings/MailSettings.js';

export function* callGetMailSettingsWorker() {
  yield put(userActions.mergeMailSettings({ pending: true }));
  const response = yield apply(api, api.user.getMailSettings);
  const formData = {};
  const fields = [];

  if (response && response.status >= 200 && response.status < 400) {
    const { mailTemplateGroups, items, totalCount } = response.data;
    yield put(userActions.mergeMailSettings({
      mailTemplateGroups: List(mailTemplateGroups), items: List(items), totalCount,
    }));
    items.forEach((field) => {
      const { enableSend, id } = field;
      formData[id] = enableSend;
      fields.push(id);
    });
  }
  yield put(initialize(formName, formData, fields));
  yield put(userActions.mergeMailSettings({ pending: false }));
}
