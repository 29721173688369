// Core
import { push } from 'redux-first-history';
import {
  apply, delay, put, select,
} from 'redux-saga/effects';
import { change } from 'redux-form';
// Instruments
import { pageLinks, routersLink } from '../../../../config/routes';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import { api } from '../../../../config/api';
import { userActionAsync } from '../asyncAction';
import i18n, { locale } from '../../../../init/i18n';
import { userActions } from '../../action';
import { selectors } from '../../../../config/selectors';
import { formName, formFields } from '../../../../../ui/page/Authentication/SignIn/form';
import { userRoles } from '../../../../config/userRoles';
import { accessList as accessListValue } from '../../../../config/access';
import { checkContentProductsPageSendQuery } from '../../../contentProduct/_helpers/checkContentProductsPageSendQuery';
import { localData } from '../../../../config/localData';

export function* callSignInWorker({ payload: signInData }) {
  const twoFactorAuth = yield select(selectors.user.twoFactorAuth);
  const twoFactorAuthToken = twoFactorAuth.toJS().twoFactorAuthToken;
  const twoFactorAuthCode = twoFactorAuth.toJS().twoFactorAuthCode;
  yield put(userActions.setUserSubmitting(true));

  const { rememberMe } = signInData;
  if (rememberMe) {
    sessionStorage.setItem('rememberMe', 'true');
  } else {
    sessionStorage.setItem('rememberMe', 'false');
  }

  if (twoFactorAuthCode) {
    yield put(userActions.mergeInTwoFactorAuthInfo({ pending: true }));

    const response = yield apply(api, api.user.twoFactorLogin, [{
      twoFactorAuthToken,
      code: twoFactorAuthCode,
    }]);
    if (response && response.status === 200) {
      const userInfo = { rememberMe, ...response.data };
      const currentLocale = locale[userInfo.locale];
      const allAccessLists = userInfo.roles?.length ? [].concat(...userInfo.roles.map(({ accessList }) => accessList)) : [];

      if (i18n.language !== currentLocale) {
        yield apply(i18n, i18n.changeLanguage, [currentLocale]);
      }
      yield put(userActionAsync.setUserInfoAsync(userInfo));
      yield checkContentProductsPageSendQuery();
      if (
        typeof userInfo.partner !== 'undefined'
        && Number.parseInt(userInfo.partner.partnerStatus, 10) === Number.parseInt(partnerStatuses.questionnaireFill, 10)
      ) {
        if (allAccessLists.includes(accessListValue.partnerShow) || allAccessLists.includes(accessListValue.partnerEdit)) {
          yield delay(0);
          yield put(push(pageLinks.profilePartner));
        }
      } else if (!userInfo.enabled) {
        yield delay(0);
        yield put(push(routersLink.users.confirm));
      }
    } else if (response && response.status === 401) {
      const { repeatLogin } = response.data;
      if (repeatLogin) {
        yield put(change(formName, formFields.password, ''));
        yield put(change(formName, formFields.twoFactorAuthCode, ''));
        yield put(userActions.mergeInTwoFactorAuthInfo({
          twoFactorAuthToken: null,
          twoFactorAuthCode: null,
          loginMethod: null,
          codeValidTimeLife: null,
          message: null,
        }));
      }
    }

    yield put(userActions.mergeInTwoFactorAuthInfo({ pending: false }));
  } else if (signInData) {
    const { ...userData } = signInData;
    const response = yield apply(api, api.user.signIn, [userData]);
    if (response && response.status === 200) {
      const userInfo = { rememberMe, ...response.data };
      const currentLocale = locale[userInfo.locale];
      const allAccessLists = userInfo.roles?.length ? [].concat(...userInfo.roles.map(({ accessList }) => accessList)) : [];

      if (i18n.language !== currentLocale) {
        yield apply(i18n, i18n.changeLanguage, [currentLocale]);
      }

      if (userInfo.twoFactorAuthToken) {
        yield put(userActions.mergeInTwoFactorAuthInfo(userInfo));
      } else {
        yield put(userActionAsync.setUserInfoAsync(userInfo));
        yield checkContentProductsPageSendQuery();
        const isCreditDepartmentManager = userInfo.roles.map(data => data.role).includes(userRoles.creditDepartmentManager);
        if (
          typeof userInfo.partner !== 'undefined'
          && Number.parseInt(userInfo.partner.partnerStatus, 10) === Number.parseInt(partnerStatuses.questionnaireFill, 10)
        ) {
          if (allAccessLists.includes(accessListValue.partnerShow) || allAccessLists.includes(accessListValue.partnerEdit)) {
            yield delay(0);
            yield put(push(pageLinks.profilePartner));
          }
        } else if (!userInfo.enabled) {
          yield delay(0);
          yield put(push(routersLink.users.confirm));
        } else if (isCreditDepartmentManager) {
          yield delay(0);
          yield put(push(pageLinks.partner.accesses));
        }
      }
      yield localData.setItem(localData.keysObj.characteristicInformer, true);
    }
  }

  yield put(userActions.setUserSubmitting(false));
}
