export const types = Object.freeze({
  SET_CATEGORIES_LIST: 'SET_CATEGORIES_LIST',
  SET_CATEGORIES_CURRENT_PAGE: 'SET_CATEGORIES_CURRENT_PAGE',
  SET_CATEGORIES_FILTERS: 'SET_CATEGORIES_FILTERS',
  SET_CATEGORIES_RELOAD: 'SET_CATEGORIES_RELOAD',
  SET_CATEGORIES_PAGE_SIZE: 'SET_CATEGORIES_PAGE_SIZE',
  SET_CATEGORIES_SORTING: 'SET_CATEGORIES_SORTING',
  SET_CATEGORIES_ITEM: 'SET_CATEGORIES_ITEM',
  SET_CATEGORIES_TREE_DATA_ROW_IDS: 'SET_CATEGORIES_TREE_DATA_ROW_IDS',
  SET_CATEGORIES_LIST_CONTEXT: 'SET_CATEGORIES_LIST_CONTEXT',
  SET_CATEGORIES_INITIAL_STATE: 'SET_CATEGORIES_INITIAL_STATE',
  SET_CATEGORIES_SETTING_STATUS: 'SET_CATEGORIES_SETTING_STATUS',
  SET_CATEGORIES_SETTING_PENDING: 'SET_CATEGORIES_SETTING_PENDING',
  SET_CATEGORIES_COMMISSIONS_UPLOAD_UPLOADING: 'SET_CATEGORIES_COMMISSIONS_UPLOAD_UPLOADING',
  SET_CATEGORIES_COMMISSIONS_UPLOAD_IS_OPEN: 'SET_CATEGORIES_COMMISSIONS_UPLOAD_IS_OPEN',
});
