import { COLOR } from '../../../../containers/App/AppStyles';

const PaymentTypesDocsModalStyles = () => ({
  fieldset: { borderRadius: '5px', border: '1px solid #c4c4c4' },
  legend: {
    backgroundColor: '#f1f0ef',
    fontSize: '14px',
    padding: '0 5px',
    borderRadius: '5px',
    '&:empty': {
      padding: 0,
    },
  },
  fieldArrayList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  fieldArrayList_item: {
    display: 'flex',
    padding: '10px 0 20px 0',
    margin: 0,
    alignItems: 'flex-end',
    '&:last-child': {
      marginBottom: 10,
    },
  },
  fieldArrayList_buttonHolder: {
    marginLeft: 6,
    padding: 2,
    borderRadius: 3,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    gap: '4px',
    alignSelf: 'end',
    marginBottom: '5px',
  },
  fieldArrayList_item__button: {
    alignSelf: 'flex-end',
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,
    borderRadius: 3,
    backgroundColor: COLOR.grey['8'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLOR.brand.dark,
    '& svg': {
      fontSize: '20px',
    },
    '&:hover': {
      backgroundColor: COLOR.grey['12'],
    },
  },
  fieldArrayList_send_button: {
    margin: 'auto',
    minHeight: 40,
  },
});

export default PaymentTypesDocsModalStyles;
