// Core
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
// Parts
import CircularProgress from '@mui/material/CircularProgress';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import StatCard from '../../../../components/Widgets/StatCard/StatCard';
import Modal from '../../../../containers/Modals/Modal/Modal';
// Engine
import { asyncActions } from '../../../../../engine/core/orders/saga/asyncActions';
import { actions } from '../../../../../engine/core/orders/actions';
import { selectors } from '../../../../../engine/config/selectors';
// Helpers
import { useStyles } from '../../../../hooks/useStyles';
import OrderStatisticsStyle from './OrderStatisticsStyle';
import ProductsIcon from '../../../../icons/ProductsIcon';
import OrderStatisticCashIcon from '../../../../icons/OrderStatisticCashIcon';
import OrderStatisticCommisionIcon from '../../../../icons/OrderStatisticCommisionIcon';
import OrderStatisticMarginIcon from '../../../../icons/OrderStatisticMarginIcon';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';

function OrderStatistics() {
  const hasOrderStatistic = useAccessList(accessList.orderStatistic);
  const classes = useStyles(OrderStatisticsStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordersStatistics = useSelector(selectors.orders.ordersStatistics);
  const { items, pending, modalOpen } = ordersStatistics.toJS();

  const handleClick = () => {
    dispatch(asyncActions.getOrdersStatisticsAsync());
  };

  const handleClickClose = () => {
    dispatch(actions.mergeOrdersStatistics({
      modalOpen: false,
    }));
  };

  const checkOrderMargin = useMemo(() => {
    const orderMargin = !isEmpty(items) && +((items.commission / items.amount) * 100).toFixed(2);
    return !Number.isNaN(orderMargin) ? orderMargin : '0';
  }, [items]);

  if (!hasOrderStatistic) {
    return null;
  }

  return (
    <>
      <ButtonWithIcon sx={{ height: 32 }} text={t('Получить статистику')} disabled={pending} onClick={handleClick}>
        {pending ? <CircularProgress color="inherit" size={24} /> : <div />}
      </ButtonWithIcon>
      <Modal
        title={t('Статистика')}
        modalOpen={modalOpen && !pending}
        handleModalCancel={handleClickClose}
        handleModalToggle={handleClickClose}
        hideButtons
        modalMaxWidth="sm"
      >
        {!isEmpty(items) && (
          <div className={classes.orderModalContent}>
            <StatCard
              title={t('Выручка')}
              value={<NumberFormat value={parseFloat(items.amount).toFixed()} thousandSeparator=" " displayType="text" suffix="₴" />}
              icon={<OrderStatisticCashIcon className={classes.iconColor} />}
              customClasses={classes.spacingBottom}
            />
            <StatCard
              title={t('Маржа')}
              value={<NumberFormat value={checkOrderMargin} thousandSeparator=" " displayType="text" suffix={t('%')} />}
              icon={<OrderStatisticMarginIcon className={classes.iconColor} />}
              customClasses={classes.spacingBottom}
            />
            <StatCard
              title={t('Количество товаров')}
              value={<NumberFormat value={items.quantity} thousandSeparator=" " displayType="text" />}
              icon={<ProductsIcon className={classes.iconColor} />}
              customClasses={classes.spacingBottom}
            />
            <StatCard
              title={t('Комиссия')}
              value={<NumberFormat value={items.commission} thousandSeparator=" " displayType="text" suffix="₴" />}
              icon={<OrderStatisticCommisionIcon className={classes.iconColor} />}
              customClasses={classes.spacingBottom}
            />
          </div>
        )
        }
      </Modal>
    </>
  );
}

export default OrderStatistics;
