// Core
import { apply, put } from 'redux-saga/effects';
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutAttributesByHashIdWorker({ payload }) {
  const {
    position, itemHashId, setLoading, ...data
  } = payload;
  if (setLoading) {
    setLoading(true);
  }
  const response = yield apply(api, api.feeds.putFeedsAttributesValues, [data]);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status, item } = responseData;
    switch (status) {
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default:
        break;
    }
    if (item) {
      yield put(actions.setAttributesItemByHashIdAndPosition({
        hashId: itemHashId,
        position,
        value: item,
      }));
    }
  }
  if (setLoading) {
    setLoading(false);
  }
}
