export const getChildrenRecursively = (data, ids) => {
  function findChildren(parentId, allChildren = []) {
    const children = data.filter(item => item.parentId === parentId);
    allChildren.push(...children);
    children.forEach(child => findChildren(child.id, allChildren));

    return allChildren;
  }
  const allChildren = ids.flatMap(id => findChildren(id));

  return allChildren.map(child => child.id);
};
