import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import * as React from 'react';
import StepperIcon from '../../../Products/PriceLists/Item/components/StepperIcon';
import { COLOR } from '../../../../containers/App/AppStyles';

const StepperConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.success,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.success,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLOR.grey['38'],
  },
}));
const StyledStepper = styled(Stepper)(() => (
  {
    margin: '0 auto',
    maxWidth: '435px',
    width: '100%',
    '& .MuiStepLabel-iconContainer.Mui-completed': {
      '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
      },
    },
    '& .MuiSvgIcon-root.Mui-active': {
      color: 'transparent',
      border: `1px solid ${COLOR.success}`,
      borderRadius: '50%',
      fontWeight: 'bold',
      '& .MuiStepIcon-text': {
        fill: COLOR.success,
      },
    },
    '& .MuiStepLabel-iconContainer': {
      cursor: 'pointer',
      position: 'relative',
      zIndex: 2,
    },
    '& .MuiStepConnector-root': {
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
      zIndex: 1,
    },
    '& .MuiStepLabel-label': {
      textAlign: 'center',
      fontSize: '12px',
      color: COLOR.grey['38'],
      marginTop: '8px',
      lineHeight: '14px',
      '&.Mui-active, &.Mui-completed': {
        color: COLOR.success,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      cursor: 'pointer',
      display: 'contents',
    },
    '& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel': {
      marginTop: '3px',
      lineHeight: '14px',
    },
  }
));

export default function ConnectStepper({ steps, activeStep, handleStep }) {
  return (
    <>
      <StyledStepper
        activeStep={activeStep}
        alternativeLabel
        connector={<StepperConnector />}
      >
        {steps.map((label, index) => (
          <Step onClick={() => handleStep(index)} key={label}>
            <StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </>
  );
}
