import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Icon
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// Components
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// Engine
import { pageLinks } from '../../../../engine/config/routes';
import { accessList } from '../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// UI
import CloudDownloadOutlinedIcon from '../../../icons/CloudDownloadOutlinedIcon';
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';


export const NotificationsTableEditCell = (props) => {
  const { t } = useTranslation();
  // Access
  const hasAccessShow = useAccessList(accessList.notificationCenterMessage);
  const hasAccessAction = useAccessList(accessList.notificationCenterAction);
  // Edit
  const {
    tableRow: {
      row: {
        hashId, link, mediaLink, externalLink,
      },
    },
  } = props;


  return (
    <Box component="div" sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
      {hasAccessShow && (
      <Tooltip title={t('Просмотреть уведомление')} aria-label={t('Просмотреть уведомление')}>
        <Link to={pageLinks.notifications.show(hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit">
            <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </Link>
      </Tooltip>
      )}
      {hasAccessAction && link && (
        <Tooltip title={t('Ссылка на раздел')} aria-label={t('Ссылка на раздел')}>
          <Link to={link}>
            <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit">
              <InsertLinkIcon sx={{ p: '2px' }} />
            </IconButtonStyles>
          </Link>
        </Tooltip>
      )}
      { mediaLink && hasAccessAction && (
        <Tooltip title={t('Скачивание файла')} disableFocusListener>
          <a href={mediaLink} target="_blank" rel="noopener noreferrer" download>
            <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit">
              <CloudDownloadOutlinedIcon sx={{ p: '2px' }} />
            </IconButtonStyles>
          </a>
        </Tooltip>
      )}
      { externalLink && (
        <Tooltip title={t('Скачивание файла')} disableFocusListener>
          <a href={externalLink} target="_blank" rel="noopener noreferrer" download>
            <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit">
              <CloudDownloadOutlinedIcon sx={{ p: '2px' }} />
            </IconButtonStyles>
          </a>
        </Tooltip>
      )}
    </Box>
  );
};
