// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../../config/localData';
import { actions } from '../../../actions';
// Helpers
export function* callGetCurrentMsgFromLocalWorker({ payload }) {
  const { chatId, multipleModeContext } = payload;
  const chatData = yield apply(localData, localData.getItem, [multipleModeContext]);
  if (chatData && chatData[chatId]) {
    const currentMsg = chatData[chatId].currentMessage;
    yield put(actions.setCurrentMsg(currentMsg));
  }
}
