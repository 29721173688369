// Core
import React from 'react';
import { Navigate } from 'react-router-dom';
// Components
import CharacteristicEdit from './CategoriesFeatureEdit/CharacteristicEdit';
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';

function CategoriesFeatureShowPage() {
  const hasAccessToThePage = useAccessList(accessList.categoriesFeatureManagementEdit);
  const hasAccessShowPage = useAccessList(accessList.categoriesFeatureManagementShow);

  if (hasAccessToThePage || hasAccessShowPage) {
    return <CharacteristicEdit mode="show" />;
  }

  return <Navigate to={pageLinks.productsAndPrices.products} />;
}

export default CategoriesFeatureShowPage;
