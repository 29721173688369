// Core
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CommissionTable from './All/CommissionTable';
import CommissionExport from './All/CommissionExport';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useCommissionList } from './_hooks/useCommissionList';
// Engine
import { asyncActions } from '../../../../engine/core/commissions/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/commissions/actions';
import { initialState, stateKeys } from '../../../../engine/core/commissions/reducer';

function CommissionPage() {
  const { t } = useTranslation();
  useCommissionList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.commissions.settingStatus);
  const settingPending = useSelector(selectors.commissions.settingPending);
  const filters = useSelector(selectors.commissions.filters);
  const sorting = useSelector(selectors.commissions.sorting);
  const isDisabledResetButton = settingPending || !(filters.size || sorting.size);

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.commissionList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.commissionList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.commissionList).get(stateKeys.currentPage)));
  };
  const putSettings = () => dispatch(asyncActions.putSettingsAsync());

  return (
    <>
      <PageHeader title={t('Справочник комиссий')}>
        <CommissionExport />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
      </PageHeader>
      <CommissionTable />
    </>
  );
}

export default CommissionPage;
