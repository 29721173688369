// Core
import React from 'react';
import { withStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
// Icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// UI
import IconButton from '@mui/material/IconButton';
import { Plugin, Template, TemplateConnector } from '@devexpress/dx-react-core';
import { VirtualTable, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';

const predicate = ({ tableRow, tableColumn }) => tableRow.type === TableHeaderRow.ROW_TYPE && tableColumn.type === TableRowDetail.COLUMN_TYPE;
function TableToggleAllRowDetail(props) {
  const { classes } = props;
  return (
    <Plugin>
      <Template
        name="tableCell"
        predicate={predicate}
      >
        {params => (
          <TemplateConnector>
            {({ rows, getRowId, expandedDetailRowIds }, { toggleDetailRowExpanded }) => (
              <VirtualTable.Cell {...params} style={{ padding: 0, textAlign: 'center' }}>
                <IconButton
                  aria-label="expanded"
                  className={classes.button}
                  disabled={expandedDetailRowIds.length === 0}
                  onClick={() => {
                    rows
                      .map(row => getRowId(row))
                      .forEach(rowId => toggleDetailRowExpanded({ rowId, state: false }));
                  }}
                  size="small"
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </VirtualTable.Cell>
            )}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );
}

const styles = {
  button: {
    padding: 8,
  },
};

TableToggleAllRowDetail.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(TableToggleAllRowDetail);
