// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { isImmutable } from 'immutable';
import RenderCheckbox from './RenderCheckbox';
import { selectors } from '../../../../../../engine/config/selectors';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import FormSelect from '../../../../../components/_Form/Selects/FormSelect/FormSelect';
import HelperText from './HelperText';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';

function RenderFormFieldBrandsSelect(props) {
  const {
    disabled, input, label,
    massEdit, meta, margin,
    refresh, onRefresh, featureDisabled,
    isRecommended, setTouchField, changeFieldValue,
    required,
  } = props;

  const name = input.name.toString();
  const { touched } = meta;
  const isDisabled = disabled || featureDisabled?.disabled;
  const error = !!(touched && meta.error);
  const {
    isValueChanged, value, normalizedValue, showChangedMessage,
  } = useIsValueWasChanged(input);
  const checkboxName = getCheckboxName(name);
  const [willBeClear, setWillBeClear] = useState(false);
  const options = useSelector(selectors.brands.brandsListContext);
  const normalizeOptions = isImmutable(options) ? options.toJS() : options;
  const classes = useStyles(ContentProductEditStyles);

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [checkboxName, isValueChanged]);

  const classNames = cx(classes.formControl, {
    [classes.errorFormControl]: error,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(name) : null;

  return (
    <FormControl
      onClick={onClick}
      className={classNames}
      margin={margin}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              brandMargin
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            formName={formName}
            {...input}
            name={input.name.toString()}
            disabled={isDisabled}
          >
            <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
              <FormSelect
                {...input}
                isPortal={false}
                options={normalizeOptions}
                disabled={isDisabled}
                label={label}
                isRecommended={isRecommended}
                required={required}
                isChanged={showChangedMessage}
                value={normalizedValue}
                hasSearch
                fullWidth
              />
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
          <HelperText
            error={meta?.error}
            isError={error}
            isValueClear={willBeClear}
            isValueChanged={showChangedMessage}
            touched={touched}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderFormFieldBrandsSelect;
