// Parts
import Box from '@mui/material/Box';
// Helpers
import { StyledTextField, Wrapper } from '../../../TextField/TextField/styles';

const Control = (props) => {
  const {
    selectProps, innerRef, children, innerProps, isDisabled, getValue,
  } = props;
  const { onMouseDown, onTouchEnd } = innerProps;
  const {
    variant, label, color, hasError,
    isRecommended, required, hasHelperTextBefore,
  } = selectProps.textFieldProps;

  const value = getValue();
  const InputProps = {
    inputComponent: Box,
    inputProps: {
      sx: {
        display: 'flex',
        padding: '6px',
        height: '28px',
        fontSize: '14px',
        justifyContent: 'space-between',
        animation: 'none',
      },
      children,
      onMouseDown,
      onTouchEnd,
      'data-testid': selectProps.dataTestId,
    },
  };

  return (
    <Wrapper color={color} ref={innerRef}>
      <StyledTextField
        error={hasError}
        fullWidth
        color={color}
        value={Boolean(value)}
        disabled={isDisabled}
        isRecommended={isRecommended}
        InputProps={InputProps}
        variant={variant}
        required={required || isRecommended}
        label={hasHelperTextBefore ? undefined : label}
      />
    </Wrapper>
  );
};

export default Control;
