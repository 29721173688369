import { useStyles } from '../../../../hooks/useStyles';

const ChatStyles = ({ inputEnabled }) => useStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    wordBreak: 'break-all',
  },
  mainChatWrapper: {
    height: '100%',
    ...(inputEnabled ? {
      maxHeight: 'calc(100vh - 275px)',
      [theme.breakpoints.up('sm')]: {
        maxHeight: 'calc(100vh - 285px)',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: 'calc(100vh - 240px)',
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc(100vh - 235px)',
      },
    } : {
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100% - 105px)',
      },
    }),
  },
  btnRefresh: {
    marginLeft: 12,
    marginRight: 10,
  },
}));

export default ChatStyles;
