// Core
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
// Engine
import { useTranslation } from 'react-i18next';
import { locale } from '../../../engine/init/i18n';
import { store } from '../../../engine/init/store';
// Parts
import Routers from '../Routers/Routers';
import Loading from '../../components/Loading/Loading';
import GoogleReCaptcha from './components/GoogleReCaptcha';
import OverlayNeedRefreshPage from './components/OverlayNeedRefreshPage';
import ModalMultipleMessages from './components/ModalMultipleMessages/ModalMultipleMessages';
// Helpers
import { theme } from './AppStyles';

function App() {
  const { i18n: { language } } = useTranslation();
  return (
    <>
      <Helmet>
        <html lang={language === locale.ru
          ? locale.ru
          : locale.ua}
        />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Sentry.ErrorBoundary fallback={<div />}>
                <Suspense fallback={<Loading isLoading />}>
                  <GoogleReCaptcha>
                    <Routers />
                    <ModalMultipleMessages />
                    <ToastContainer />
                    <OverlayNeedRefreshPage />
                  </GoogleReCaptcha>
                </Suspense>
              </Sentry.ErrorBoundary>
            </Provider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
