// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// Parts
import Grid from '@mui/material/Grid';
import CategoryForm from './CategoryForm';
import Loading from '../../../../../components/Loading/Loading';
import TabsPanel from '../../../../../containers/Tabs/TabsPanel';

function CategoryEditGeneral(props) {
  const {
    display, index, value, isLoading,
    submitting, mode, classes,
  } = props;

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid container justifyContent="space-between" spacing={3}>
        {isLoading ? (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.tabsContent}
            style={{ height: '320px' }}
          >
            <Loading isLoading={isLoading} />
          </Grid>
        ) : (
          <CategoryForm
            disabled={submitting}
            mode={mode}
          />
        )}
      </Grid>
    </TabsPanel>
  );
}

CategoryEditGeneral.propTypes = {
  classes: PropTypes.object,
  display: PropTypes.oneOf(['flex', 'none']),
  index: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  submitting: PropTypes.func,
  mode: PropTypes.oneOf(['edit', 'mode']),
};

CategoryEditGeneral.defaultProps = {
};

export default CategoryEditGeneral;
