export const messageFormName = 'messageForm';

export const messageFormFields = Object.freeze({
  messageStatus: 'messageStatus',
  messageUsers: 'messageUsers',
  title: 'title',
  text: 'text',
  media: 'media',
  sentAt: 'sentAt',
  type: 'type',
  partners: 'partners',
});
