import { getIn, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import lodashReduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import { stateKeys as partnersKeys } from './reducer';
import { stateKeys as partnerKeys } from '../_partners/reducers';
// Config
import { pageSizes } from '../../config/globalConfig';
import { allProductsTable } from '../content/selectors';
import { user } from '../user/selectors';
import { accessList } from '../../config/access';
import { contentProduct } from '../contentProduct/selectors';

export const partners = Object.freeze({
  listByStatus: statusId => state => getIn(state, [partnersKeys.controller, partnersKeys.partnersListByStatus, statusId, partnersKeys.items], List()),
  listWithMid: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersListWithMid, partnersKeys.items], List()),
  totalCountByStatus: statusId => state => getIn(state, [partnersKeys.controller, partnersKeys.partnersListByStatus, statusId, partnersKeys.totalCount], 0),
  pendingByStatus: statusId => state => getIn(state, [partnersKeys.controller, partnersKeys.partnersListByStatus, statusId, partnersKeys.pending], false),
  currentPage: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.currentPage], 0),
  filters: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.filters], List()),
  isLoading: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.pending], false),
  list: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.items], List()),
  pageSize: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.reload], false),
  totalCount: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.totalCount], 0),
  sorting: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.sorting], List()),
  selection: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.selection], List()),
  settingStatus: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.settingStatus], false),
  settingPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersList, partnersKeys.settingPending], false),
  downloadPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersDownload, partnersKeys.pending], false),
  paymentTypes: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersPaymentTypes, partnersKeys.items], List()),
  paymentTypesPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersPaymentTypes, partnersKeys.pending], List()),
  partnersProcessPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersProcess, partnersKeys.pending], false),
  partnerHashIdConfirmPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnerHashIdConfirm, partnersKeys.pending], false),
  reasonRejectionModal: state => getIn(state, [partnersKeys.controller, partnersKeys.reasonRejectionModal], Map()),
  partnerOrderProcessingIsOpen: state => getIn(state, [partnersKeys.controller, partnersKeys.partnerOrderProcessing, partnersKeys.isModalOpen], false),
  partnerOrderProcessingPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnerOrderProcessing, partnersKeys.pending], false),
  partnerDeliveryDelayPeriodIsOpen: state => getIn(state, [partnersKeys.controller, partnersKeys.partnerDeliveryDelayPeriod, partnersKeys.isModalOpen], false),
  partnerDeliveryDelayPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnerDeliveryDelayPeriod, partnersKeys.pending], false),
  partnersTopExportPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersTopExport, partnersKeys.pending], false),
  partnersTopImportPending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersTopImport, partnersKeys.pending], false),
  partnersTopImportIsOpen: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersTopImport, partnersKeys.isOpen], false),
  partnersDeliveryDelayDeletePending: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersDeliveryDelayDelete, partnersKeys.pending], false),
  partnersDeliveryDelayDeleteShowModal: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersDeliveryDelayDelete, partnersKeys.showModal], false),
  partnersDeliveryDelayDeleteHashId: state => getIn(state, [partnersKeys.controller, partnersKeys.partnersDeliveryDelayDelete, partnersKeys.hashId], null),
});

export const partner = Object.freeze({
  partnerIsTopIsOpen: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerIsTop, partnerKeys.isOpen], false),
  partnerIsTopForceAction: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerIsTop, partnerKeys.forceAction], false),
  list: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.items], []),
  listContext: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContext, partnerKeys.items], List()),
  listContextLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContext, partnerKeys.pending], false),
  partnersListContextBillingPaymentForPartnerContext: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContextBillingPaymentForPartner, partnerKeys.items], List()),
  partnersListContextBillingPaymentForPartnerContextLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContextBillingPaymentForPartner, partnerKeys.pending], false),
  partnersListContextForServiceRequests: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContextForServiceRequests, partnerKeys.items], List()),
  partnersListContextForServiceRequestsPending: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersListContextForServiceRequestsPending, partnerKeys.pending], true),
  usersListContext: state => getIn(state, [partnerKeys.controller, partnerKeys.usersListContext], Map()),
  // TODO: необходимо List(), а не []
  listUsersContext: state => getIn(state, [partnerKeys.controller, partnerKeys.usersListContext, partnerKeys.items], []),
  listUsersAdminsContext: state => getIn(state, [partnerKeys.controller, partnerKeys.usersListContext, partnerKeys.itemsWithAdmin], List()),
  listUsersContextLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.usersListContext, partnerKeys.pending], true),
  isLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.pending], false),
  pageSize: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.pageSize], pageSizes[0]),
  currentPage: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.currentPage], 0),
  totalCount: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.totalCount], 0),
  filters: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.filters], List()),
  shouldApproveValues: state => getIn(state, [partnerKeys.controller, partnerKeys.partner, partnerKeys.data, partnerKeys.shouldApproveValues], false),
  sorting: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.sorting], List()),
  statusesList: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.partnerStatuses], []),
  statusesListPending: state => getIn(state, [partnerKeys.controller, partnerKeys.statusesPending], false),
  cancelStatusesList: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.cancelStatuses], List()),
  disableStatusesList: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.disableStatuses], List()),
  stoppedStatusesList: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.stoppedStatuses], List()),
  problemStatusesList: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.problemStatuses], List()),
  statusesListFlow: state => getIn(state, [partnerKeys.controller, partnerKeys.statuses, partnerKeys.partnerStatusesFlow], Map()),
  partnerIsLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partner, partnerKeys.pending], true),
  partnerContractPage: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerContractPage], null),
  partnerContractLink: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerContractLink], null),
  partnerHashId: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerHashId], ''),
  partnerDownloadIsLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerDownload, partnerKeys.pending], false),
  partnerDownloadContractCommissionIsLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersContractCommissionDownload, partnerKeys.pending], false),
  partnersDownloadIsLoading: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersDownload, partnerKeys.pending], false),
  userData: state => getIn(state, [partnerKeys.controller, partnerKeys.partner, partnerKeys.data], {}),
  reload: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.reload], false),
  selection: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.selection], List()),
  partnerSavePending: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerSave, partnerKeys.pending], false),
  partnerCreateNewPending: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerCreateNew, partnerKeys.pending], false),
  settingStatus: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.settingStatus], false),
  settingPending: state => getIn(state, [partnerKeys.controller, partnerKeys.partnersList, partnerKeys.settingPending], false),
  sendContractToSedPending: state => getIn(state, [partnerKeys.controller, partnerKeys.sendContractToSed, partnerKeys.pending], false),
  partnerSaveShowPopUps: state => getIn(state, [partnerKeys.controller, partnerKeys.partnerSave, partnerKeys.showPopUps], Map()),
  businessesList: state => getIn(state, [partnerKeys.controller, partnerKeys.businessesList, partnerKeys.items], List()),
  businessesListPending: state => getIn(state, [partnerKeys.controller, partnerKeys.businessesList, partnerKeys.pending], false),
});

export const partnerStatus = createSelector(
  partner.userData,
  data => getIn(data, [partnerKeys.partnerStatus, partnerKeys.value], 0),
);

export const partnerStatusesFromFlow = createSelector(
  partner.statusesList,
  partner.statusesListFlow,
  (statuses, statusesFlow) => (
    statusesFlow.reduce((acc, value, key) => {
      const findProductStatus = id => statuses.find(item => item.get('value').toString() === id.toString());
      return acc.set(key, List(value.map(findProductStatus)).push(findProductStatus(key)));
    }, Map())
  ),
);

export const paymentTypes = createSelector(
  partners.paymentTypes,
  data => List(data),
);

export const allProductsImmutableSelectionSku = createSelector(
  contentProduct.selection,
  contentProduct.list,
  (selection, productsList) => (
    selection.reduce((acc, productId) => {
      const productItem = productsList.find((product => product.id === productId));
      if (productItem) {
        const sku = productItem.sku;
        const id = productItem.id;
        const hashId = productItem.hashId;
        return acc.push({ hashId, sku, id });
      }

      return acc;
    }, List())
  ),
);

export const productStatusesFlow = createSelector(
  allProductsTable.statusesFlow,
  allProductsTable.productStatuses,
  (statusesFlow, productStatuses) => (
    statusesFlow.reduce((acc, value, key) => {
      const findProductStatus = id => productStatuses.find(item => item.value.toString() === id.toString());
      return acc.set(key, List(value.map(findProductStatus)).push(findProductStatus(key)));
    }, Map())
  ),
);

export const partnerEditMode = createSelector(
  user.accessList,
  (list) => {
    switch (true) {
      case list.includes(accessList.partnerEdit): {
        return 'edit';
      }
      case list.includes(accessList.partnerShow): {
        return 'show';
      }
      case list.includes(accessList.partnerCreate): {
        return 'create';
      }
      default: {
        return '';
      }
    }
  },
);

export const partnerPaymentTypesModal = createSelector(
  partner.partnerSaveShowPopUps,
  partners.paymentTypes,
  (showPopUps, partnerPaymentTypes) => {
    const data = [];
    const normalizePaymentList = lodashReduce(partnerPaymentTypes.toJS(), (obj, param) => {
      if (param?.configurations?.isActive) {
        obj[param.type] = param.label; // eslint-disable-line
      }
      return obj;
    }, {});
    forEach(normalizePaymentList, (paymentValue, paymentKey) => {
      if (showPopUps.toJS()?.partnerPaymentTypes) {
        forEach(showPopUps.toJS()?.partnerPaymentTypes, (modalValue, modalKey) => {
          if (modalKey === paymentKey) {
            data.push({ value: modalValue, label: paymentValue, type: paymentKey });
          }
        });
      }
    });
    return List(data);
  },
);
