// core
import React from 'react';
import PropTypes from 'prop-types';

// UI
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

// Style
import TabsStyles from './TabsStyles';

const useStyles = makeStyles(TabsStyles);
function TabsPanel(props) {
  const {
    children, value, index, isCustomTemplate, style, padding,
  } = props;
  const classes = useStyles({ padding });

  const defaultTemplate = (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className={classes.tabsContentWrapper}
      style={isCustomTemplate ? style : {}}
    >
      <Grid container className={classes.tabsContent}>
        {children}
      </Grid>
    </div>
  );

  if (isCustomTemplate) {
    return defaultTemplate;
  }

  return (
    <Grid item xs={12} md={8} lg={9} sx={style}>
      {defaultTemplate}
    </Grid>
  );
}

TabsPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  isCustomTemplate: PropTypes.bool,
  style: PropTypes.object,
  padding: PropTypes.string,
};

export default TabsPanel;
