import { getIn, List } from 'immutable';
import { stateKeys as stopListBrandsCategoriesKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const stopListBrandsCategories = Object.freeze({
  currentPage: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.currentPage], 0),
  filters: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.filters], List()),
  isLoading: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.pending], false),
  list: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.items], List()),
  pageSize: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.reload], false),
  totalCount: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.totalCount], 0),
  sorting: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.sorting], List()),
  settingStatus: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.settingStatus], false),
  settingPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.settingPending], false),
  selection: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.stopListBrandsCategoriesList, stopListBrandsCategoriesKeys.selection], List()),
  deletePending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.delete, stopListBrandsCategoriesKeys.pending], false),
  addModalIsOpen: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.add, stopListBrandsCategoriesKeys.isModalOpen], false),
  addModalPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.add, stopListBrandsCategoriesKeys.pending], false),
  editIsModalOpen: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.edit, stopListBrandsCategoriesKeys.isModalOpen], false),
  editPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.edit, stopListBrandsCategoriesKeys.pending], false),
  editItemHashId: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.edit, stopListBrandsCategoriesKeys.item, stopListBrandsCategoriesKeys.hashId], null),
  editItemId: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.edit, stopListBrandsCategoriesKeys.item, stopListBrandsCategoriesKeys.id], null),
  putPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.put, stopListBrandsCategoriesKeys.pending], false),
  uploadPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.upload, stopListBrandsCategoriesKeys.pending], false),
  uploadIsModalOpen: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.upload, stopListBrandsCategoriesKeys.isModalOpen], false),
  downloadPending: state => getIn(state, [stopListBrandsCategoriesKeys.controller, stopListBrandsCategoriesKeys.download, stopListBrandsCategoriesKeys.pending], false),
});
