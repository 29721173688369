import { COLOR } from '../../../containers/App/AppStyles';

const TabItemMenuStyles = () => ({
  tabItem: {
    color: COLOR.grey.main,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    borderRadius: 5,
    opacity: 1,
    padding: '7px 4px 7px 10px',
    minHeight: '40px',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    textAlign: 'start',
    '&.Mui-selected': {
      backgroundColor: COLOR.grey['4'],
      fontWeight: 700,
    },
    '&:hover': {
      backgroundColor: COLOR.grey['4'],
    },
  },
  tabItemIcon: {
    width: 22,
    height: 22,
  },
});

export default TabItemMenuStyles;
