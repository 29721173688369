export const types = Object.freeze({
  SET_CONTENT_TEMPLATES_LIST: 'SET_CONTENT_TEMPLATES_LIST',
  SET_CONTENT_TEMPLATES_CURRENT_PAGE: 'SET_CONTENT_TEMPLATES_CURRENT_PAGE',
  SET_CONTENT_TEMPLATES_FILTERS: 'SET_CONTENT_TEMPLATES_FILTERS',
  SET_CONTENT_TEMPLATES_PAGE_SIZE: 'SET_CONTENT_TEMPLATES_PAGE_SIZE',
  SET_CONTENT_TEMPLATES_SORTING: 'SET_CONTENT_TEMPLATES_SORTING',
  SET_CONTENT_TEMPLATES_INITIAL_STATE: 'SET_CONTENT_TEMPLATES_INITIAL_STATE',
  SET_CONTENT_TEMPLATES_SETTING_STATUS: 'SET_CONTENT_TEMPLATES_SETTING_STATUS',
  SET_CONTENT_TEMPLATES_SETTING_PENDING: 'SET_CONTENT_TEMPLATES_SETTING_PENDING',
  SET_CONTENT_TEMPLATES_IMPORT_MODAL_OPEN: 'SET_CONTENT_TEMPLATES_IMPORT_MODAL_OPEN',
  SET_CONTENT_TEMPLATES_IMPORT_PENDING: 'SET_CONTENT_TEMPLATES_IMPORT_PENDING',
  MERGE_CONTENT_TEMPLATES_LIST: 'MERGE_CONTENT_TEMPLATES_LIST',
  SET_CONTENT_TEMPLATES_IMPORT_MODAL_INITIAL_STATE: 'SET_CONTENT_TEMPLATES_IMPORT_MODAL_INITIAL_STATE',
  SET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_STATE: 'SET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_STATE',
  SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_PENDING: 'SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_PENDING',
  SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_CATEGORY: 'SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_CATEGORY',
  SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_PENDING: 'SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_PENDING',
  SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_FEATURE: 'SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_FEATURE',
});
