// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_CURRENT_PAGE, currentPage);
  },
  setReload(currentPage) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_RELOAD, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_SETTING_PENDING, settingPending);
  },
  setBankInstallmentsItem(categoryItem) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_ITEM, categoryItem);
  },
  setBankInstallmentsTreeDataRowIds(ids) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_TREE_DATA_ROW_IDS, ids);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_SELECTION, selection);
  },
  mergeBankInstallmentsImport(data) {
    return getActionCreator(types.MERGE_BANK_INSTALLMENTS_DATA_IMPORT, data);
  },
  mergeBankInstallmentsExport(data) {
    return getActionCreator(types.MERGE_BANK_INSTALLMENTS_DATA_EXPORT, data);
  },
  setItemByHashIdData(data) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_ITEM_BY_HASH_DATA, data);
  },
  setItemByHashIdMode(mode) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_ITEM_BY_HASH_MODE, mode);
  },
  setItemByHashIdPendingId(hashId) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_ITEM_BY_HASH_PENDING_ID, hashId);
  },
  setItemByHashIdPutPending(data) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_ITEM_BY_HASH_ID_PUT_PENDING, data);
  },
  setLogs(data) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_LOGS, data);
  },
  setLogsRefresh(data) {
    return getActionCreator(types.SET_BANK_INSTALLMENTS_DATA_LOGS_DATA, data);
  },
});
