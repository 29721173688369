// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_LIST, data);
  },
  setParsePending(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_PARSE_PENDING, data);
  },
  setSendQuery(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_SEND_QUERY, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_SETTING_PENDING, settingPending);
  },
  mergeStatusesList(statusesList) {
    return getActionCreator(types.MERGE_FEEDS_PRODUCTS_STATUSES_LIST, statusesList);
  },
  mergeStatusesElaborationList(statusesList) {
    return getActionCreator(types.MERGE_FEEDS_PRODUCTS_STATUSES_ELABORATION_LIST, statusesList);
  },
  mergeRefresh(data) {
    return getActionCreator(types.MERGE_PRODUCT_REFRESH, data);
  },
  mergeItemByHashId(data) {
    return getActionCreator(types.MERGE_FEEDS_PRODUCTS_ITEM_BY_HASH_ID, data);
  },
  setDownloadPending(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_DOWNLOAD_PENDING, data);
  },
  setDownloadForceAction(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_DOWNLOAD_FORCE_ACTION, data);
  },
  setProductMultipleEditingConfirmModal(data) {
    return getActionCreator(types.MERGE_FEEDS_PRODUCTS_MULTIPLE_EDITING_CONFIRM_MODEL, data);
  },
  setFullDownloadPending(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_FULL_DOWNLOAD_PENDING, data);
  },
  setImportPending(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_IMPORT_PENDING, data);
  },
  setImportModalIsOpen(isOpen) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_IMPORT_MODAL_IS_OPEN, isOpen);
  },
  setProductsImportModalInitialState(payload) {
    return getActionCreator(types.SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE, payload);
  },
  setReload(reload) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_RELOAD, reload);
  },
  setSelection(selection) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_SELECTION, selection);
  },
  setProductMultipleEditing(data) {
    return getActionCreator(types.SET_FEEDS_PRODUCTS_MULTIPLE_EDITING_IS_OPEN, data);
  },
  setPutProductMultipleEditing(data) {
    return getActionCreator(types.SET_PUT_FEEDS_PRODUCTS_MULTIPLE_EDITING, data);
  },
  mergeProductsStatistic(data) {
    return getActionCreator(types.MERGE_FEEDS_PRODUCTS_STATISTIC, data);
  },
  setProductsFeedStatistic(statistic) {
    return getActionCreator(types.SET_PRODUCTS_FEED_STATISTIC, statistic);
  },
  setProductsFeedExportPending(data) {
    return getActionCreator(types.SET_PRODUCTS_FEED_EXPORT_PENDING, data);
  },
});
