import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box/Box';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../../../engine/config/selectors';

function TooltipCantFinish(props) {
  const { children, text } = props;
  const { t } = useTranslation();
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canParse = isItemByHashIdItem.get('canParse');
  const autoConnectPending = useSelector(selectors.productFeedCategoriesAttributes.autoConnectPending);

  return autoConnectPending || !canParse ? (
    <Tooltip title={(
      <>
        <b>{t('Внимание!')}</b>&nbsp;
        { text || t('Происходит процесс автоматического сопоставления характеристик и их значений. Кнопка “Завершить импорт” будет доступна после его завершения.')}
      </>
    )}
    >
      <Box component="span">
        {children}
      </Box>
    </Tooltip>
  ) : children;
}

export default TooltipCantFinish;
