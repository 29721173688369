// Core
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { selectors } from '../../../../../config/selectors';

export function* callGetProductsStatisticWorker({ payload }) {
  const { categoryId } = payload;
  yield put(actions.mergeProductsStatistic({ pending: true }));
  const filters = yield select(selectors.contentProduct.filters);
  const params = {
    categoryId,
  };
  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);
  const response = yield apply(api, api.feedsProducts.getProductsStatistic, [params]);
  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeProductsStatistic({
      items: {
        quantity: response.data.totalCount,
      },
      modalOpened: true,
    }));
  }
  yield put(actions.mergeProductsStatistic({ pending: false }));
}
