// Core
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
// Parts
import ResetFiltersButton from '../../../../../components/_Table/DxTable/components/ResetFilterButton';
// actions
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { stateKeys, initialState } from '../../../../../../engine/core/contentProduct/reducer';

// config
import { selectors } from '../../../../../../engine/config/selectors';

function ContentProductTableFiltersButton() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.contentProduct.isLoading);
  const sorting = useSelector(selectors.contentProduct.filters);
  const filters = useSelector(selectors.contentProduct.sorting);
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const sendQuery = useSelector(selectors.contentProduct.sendQuery);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    const curSorting = sorting.toJS();
    const defSorting = initialState.get(stateKeys.contentProductList).get(stateKeys.sorting).toJS();
    const curFilters = filters.toJS();
    const defFilters = initialState.get(stateKeys.contentProductList).get(stateKeys.filters).toJS();
    const defCurrentPage = initialState.get(stateKeys.contentProductList).get(stateKeys.currentPage);
    if (!isEqual(defFilters, curFilters) || !isEqual(defSorting, curSorting) || currentPage !== defCurrentPage) {
      dispatch(actions.setFilters(defFilters));
      dispatch(actions.setSorting(defSorting));
      dispatch(actions.setCurrentPage(initialState.get(stateKeys.contentProductList).get(stateKeys.currentPage)));
    }
  }, [currentPage, dispatch, filters, sorting]);

  return (
    <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} pending={isLoading && sendQuery !== undefined} />
  );
}

ContentProductTableFiltersButton.displayName = 'ContentProductTableFiltersButton';

export default React.memo(ContentProductTableFiltersButton);
