// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../../config/api';
import { pageLinks } from '../../../../../config/routes';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPostStopWordWorker({
  payload: {
    phrase, categories, excludeWords, excludePartners,
  },
}) {
  yield put(actions.mergePostStopWord({ pending: true }));
  const categoriesId = categories?.map(item => item.value);
  const excludeWordsArray = excludeWords && excludeWords?.toJS().map(item => item.value);
  const excludePartnersArray = excludePartners?.map(item => item.value);
  const fixedPayload = {
    phrase,
    categories: categoriesId,
    excludeWords: (excludeWordsArray.length === 1 && excludeWordsArray[0] === undefined) ? [] : excludeWordsArray,
    excludePartners: excludePartnersArray,
  };

  const response = yield apply(api, api.stopLists.postStopWord, [fixedPayload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(push(pageLinks.stopWords.all));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergePostStopWord({ pending: false }));
}
