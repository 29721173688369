// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const Links = () => {
  const { t } = useTranslation();
  if (process.env.REACT_APP_ENV === 'prod') {
    return null;
  }
  return (
    <Card sx={{ display: 'flex', margin: 'auto' }}>
      <CardContent>
        <Button variant="contained" color="primary" fullWidth href="/doc_api_allo_mp">{t('Описание API Symfony')}</Button>
        <hr />
        <Button variant="contained" color="primary" fullWidth href="/doc_nelmio_api_allo_mp">{t('Описание API Symfony Nelmio')}</Button>
        <hr />
        <Button variant="contained" color="primary" fullWidth href="/build/mails/index.html">{t('Шаблоны писем')}</Button>
      </CardContent>
    </Card>
  );
};

export default Links;
