import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

// UI
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';
import { useStyles } from '../../../../../hooks/useStyles';

import { mediaActionAsync } from '../../../../../../engine/core/media/saga/asyncAction';
import { selectors } from '../../../../../../engine/config/selectors';
import { localData } from '../../../../../../engine/config/localData';
import InputFileStyles from './InputFileStyles';

const InputFile = ({ input }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(InputFileStyles);
  const fileIsLoading = useSelector(selectors.serviceDesk.fileIsLoading);
  const { hashId } = useParams();
  const onChangeFile = ({ target: { files } }) => {
    if (files.length > 0) {
      dispatch(mediaActionAsync.postMediasAsync({
        files,
        chatId: hashId,
        context: 'support_message',
        multipleModeContext: localData.keysObj.SDAttachedFiles,
      }));
    }
  };
  return (
    <Tooltip title={t('Прикрепить вложение')} arrow>
      <label className={classes.inputLabel} htmlFor="fileAttachToMessage">
        <input
          className={classes.inputFile}
          type="file"
          id="fileAttachToMessage"
          onChange={onChangeFile}
        />
        <input type="hidden" {...input} />
        <Button
          disabled={fileIsLoading}
          variant="outlined"
          className={classes.btnAttach}
        >
          { fileIsLoading
            ? <CircularProgress color="inherit" size={20} />
            : <AttachFileIcon />
          }
        </Button>
      </label>
    </Tooltip>
  );
};
InputFile.displayName = 'InputFile';

export default InputFile;
