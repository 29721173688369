// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import EditCell from './EditCell';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import { actions } from '../../../../../engine/core/stopList/BrandsCategories/actions';
// Hooks
import { useBrandsCategoriesList } from '../_hooks/useBrandsCategoriesList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';
import { useAccessList } from '../../../../hooks/useAccessList';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { useUserRole } from '../../../../hooks/useUserRole';
import { userRoles } from '../../../../../engine/config/userRoles';
// import { asyncActions } from '../../../../engine/core/BrandsCategories/saga/asyncActions';

function Table() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.stopListBrandsCategories.isLoading);
  const hasAccessDelete = useAccessList(accessList.stopBrandsCategoriesDelete);
  const hasAccessEdit = useAccessList(accessList.stopBrandsCategoriesEdit);
  const isAdminRole = useUserRole(userRoles.admin);
  const isCallCenterRole = useUserRole(userRoles.callCenter);
  const isManagerRole = useUserRole(userRoles.manager);
  const isSalesManagerRole = useUserRole(userRoles.salesManager);
  const isСontentManagerRole = useUserRole(userRoles.contentManager);
  const isСreditDepartmentManagerRole = useUserRole(userRoles.creditDepartmentManager);
  // Rows
  const rows = useSelector(selectors.stopListBrandsCategories.list);
  const columns = (isAdminRole || isCallCenterRole || isManagerRole || isSalesManagerRole || isСontentManagerRole || isСreditDepartmentManagerRole) ? [
    tableDataTypes.brand,
    tableDataTypes.category,
    tableDataTypes.excludePartners,
    tableDataTypes.createdAt,
    tableDataTypes.updatedAt,
  ] : [
    tableDataTypes.brand,
    tableDataTypes.category,
    tableDataTypes.createdAt,
    tableDataTypes.updatedAt,
  ];
  // Paging
  const currentPage = useSelector(selectors.stopListBrandsCategories.currentPage);
  const pageSize = useSelector(selectors.stopListBrandsCategories.pageSize);
  const totalCount = useSelector(selectors.stopListBrandsCategories.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.stopListBrandsCategories.sorting);
  // Selection
  const selection = useSelector(selectors.stopListBrandsCategories.selection);
  const normalizeSelection = selection.toJS();
  const onSelection = selected => dispatch(actions.setSelection(selected));
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.stopListBrandsCategories.settingStatus);
  // const settingPending = useSelector(selectors.stopListBrandsCategories.settingPending);
  // Filtering
  const filters = useSelector(selectors.stopListBrandsCategories.filters);
  // Edit
  const editWidth = useEditCellWidth([hasAccessEdit], 0, 40, 40);

  return (
    <DxTable
      name="stopListBrandsCategories"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      updateHooks={useBrandsCategoriesList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Edit
      editComponentWidth={editWidth}
      editComponent={hasAccessEdit ? EditCell : undefined}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Selection
      selection={normalizeSelection}
      onSelection={hasAccessDelete ? onSelection : undefined}
      // Settings
      // gridSettings={gridSettings.stopListBrandsCategories.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(Table);
