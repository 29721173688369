// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPutContentManagerWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_ATTRIBUTE_SET_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_ATTRIBUTE_SET_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_ATTRIBUTE_SET_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchPutContentManager() {
  yield takeEvery(asyncTypes.PUT_ATTRIBUTE_SET_CONTENT_MANAGER_ASYNC, callPutContentManagerWorker);
}

function* watchSetCurrentPage() {
  yield takeEvery(types.SET_ATTRIBUTE_SET_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_ATTRIBUTE_SET_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_ATTRIBUTE_SET_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_ATTRIBUTE_SET_SORTING, callSetToLocalDataWorker);
}


export function* watchersFeedsAttributeSet() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPutContentManager(),
  ]);
}
