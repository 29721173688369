// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/action';
import { partnerActions } from '../../action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callUploadPartnerContractWorker({ payload: { hashId, files } }) {
  if (!files.length) {
    return;
  }

  yield put(uiActions.progressLoadingContract(true));

  const response = yield apply(api, api.partner.uploadContract, [{ hashId, files }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(partnerActions.setPartnerContractLink(response.data.contractLink));
        yield put(partnerActions.setPartnerContractPage(response.data.pages));
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(uiActions.progressLoadingContract(false));
}
