// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';

// parts
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';

function MessageButton(props) {
  const {
    text, isLoading, hasAccess, iconButton,
    onClick, disabled,
  } = props;

  if (!hasAccess) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      text={text}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      type="button"
      component="button"
    >
      {iconButton}
    </ButtonWithIconAndCircularProgress>
  );
}

MessageButton.displayName = 'MessageButton';

MessageButton.propTypes = {
  text: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  iconButton: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

MessageButton.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default memo(MessageButton);
