// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import ModalConfirm from '../../../../../containers/Modals/ModalConfirm';

const CompleteImportModal = ({
  showModal, setShowConfirmModal, onConfirm, disable,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <ModalConfirm
      confirmText={t('Завершить импорт')}
      cancelText={t('Отмена')}
      textAlign="center"
      onCancel={onCancel}
      onConfirm={onConfirm}
      showModal={showModal}
      title={t('Внимание')}
      pendingConfirm={disable}
    >
      <p>
        {t('После завершения импорта - прайс-лист будет недоступен на период обработки данных системой. После обработки, при необходимости, вы сможете продолжить сопоставление данных.')}
      </p>
    </ModalConfirm>
  );
};


CompleteImportModal.propTypes = {
  showModal: PropTypes.bool,
  disable: PropTypes.bool,
  setShowConfirmModal: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default CompleteImportModal;
