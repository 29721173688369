// Core
import React, {
  useMemo, useState, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { List, isImmutable } from 'immutable';
// Parts
import Select from '../Select/Select';
import SpeedMenuListTree from './components/SpeedMenuListTree';
import ValueContainerTree from './components/ValueContainerTree';
// Helpers
import getRecursiveChildrens from './_helpers/getRecursiveChildrens';
import getParents from './_helpers/getParents';
import getRemovedParents from './_helpers/getRemovedParents';

const SelectCategoryTreeView = (props) => {
  const {
    options, disabled, filters, onSelectChange, onlyChild,
    hasRejectButton, placeholder, searchErrorLabel, isMulti, required,
    variant, meta, label, hasConfirmButton, canClear, onlyNotPortal, isPortal,
    closeMenuOnScroll,
  } = props;

  const filtersToJS = useMemo(() => (filters?.value && isImmutable(filters?.value)
    ? filters?.value?.toJS()
    : filters?.value) || [],
  [filters]);

  const optionsToJS = useMemo(() => isImmutable(options) ? options.toJS() : options, [options]);
  const [value, onChange] = useState(filtersToJS);
  const [checkboxesState, setCheckboxesState] = useState([]);
  const [indeterminateState, setIndeterminateState] = useState([]);

  const initialSelected = useMemo(() => filtersToJS || [], [filtersToJS.length]);
  const initialIndeterminate = useMemo(() => {
    const result = filtersToJS.map(i => i?.parentValue ? getParents(optionsToJS, i?.parentValue) : null).flat(Infinity);
    const uniqueArr = [...new Set(result.map(obj => JSON.stringify(obj)))].map(str => JSON.parse(str));
    return uniqueArr.filter(Boolean);
  },
  [filtersToJS, optionsToJS]);
  const input = {
    value: disabled
      ? filters?.value
      : value,
    onChange,
  };

  useEffect(() => {
    setCheckboxesState(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    setIndeterminateState(initialIndeterminate);
  }, [initialIndeterminate]);


  const onConfirm = () => {
    if (checkboxesState) {
      onSelectChange(checkboxesState);
    } else {
      onSelectChange(value);
    }
  };
  const onReject = () => {
    onSelectChange('');
    onChange(null);
    setCheckboxesState([]);
    setIndeterminateState([]);
  };

  const onMenuClose = (menuStatus) => {
    if (menuStatus === false) {
      if (!filtersToJS?.length) {
        onChange(filtersToJS ? filters : null);
        setCheckboxesState([]);
        setIndeterminateState([]);
      } else {
        onChange(filtersToJS);
        setCheckboxesState(initialSelected);
        setIndeterminateState(initialIndeterminate);
      }
    }
  };

  const onChangeCheckbox = ({
    currentOption, isChecked,
  }) => {
    if (isChecked) {
      const deleteItems = [...getRecursiveChildrens(optionsToJS, currentOption?.value), currentOption];
      const newCheckboxesState = checkboxesState.filter(
        checkbox => !deleteItems?.some(deleteCheckbox => deleteCheckbox.value === checkbox.value),
      );
      const deleteParentCategories = getRemovedParents(indeterminateState, newCheckboxesState);
      const newIndeterminateState = indeterminateState.filter(item => !deleteParentCategories.includes(item.value));

      setIndeterminateState(newIndeterminateState);
      setCheckboxesState(newCheckboxesState);
    } else {
      if (currentOption?.parentValue && !currentOption.hasChildren) {
        const indeterminateValue = getParents(optionsToJS, currentOption?.parentValue);

        setIndeterminateState(indeterminateValue);
      }
      if (currentOption.hasChildren) {
        const indeterminateValue = getParents(optionsToJS, currentOption?.parentValue);
        const childrenItems = getRecursiveChildrens(optionsToJS, currentOption?.value);

        setIndeterminateState(current => ([...current, ...indeterminateValue]));
        setCheckboxesState(current => ([...current, ...childrenItems, currentOption]));
        return;
      }
      setCheckboxesState(
        isMulti
          ? [...checkboxesState, currentOption]
          : [currentOption],
      );
    }
  };

  return (
    <Select
      label={label}
      hasSearch
      options={options}
      disabled={disabled}
      filters={filters}
      value={checkboxesState}
      sendOnRemove
      onlyNotPortal={onlyNotPortal}
      input={input}
      onConfirm={onConfirm}
      onReject={hasRejectButton ? onReject : null}
      hasConfirmButton={hasConfirmButton || isMulti}
      canClear={canClear}
      closeMenuOnScroll={closeMenuOnScroll}
      closeMenuOnSelect={!isMulti}
      isMulti={isMulti}
      hideSelectedOptions={false}
      MenuListView={SpeedMenuListTree}
      onlyChild={onlyChild}
      ValueView={ValueContainerTree}
      checkboxesState={checkboxesState}
      setCheckboxesState={setCheckboxesState}
      indeterminateState={indeterminateState}
      onChangeCheckbox={onChangeCheckbox}
      onMenuClose={onMenuClose}
      minMenuWidth={240}
      searchErrorLabel={searchErrorLabel}
      placeholder={placeholder}
      isTreeSelect
      variant={variant}
      meta={meta}
      isPortal={isPortal}
      required={required}
    />
  );
};

SelectCategoryTreeView.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onlyChild: PropTypes.bool,
  meta: PropTypes.object,
  hasRejectButton: PropTypes.bool,
  isMulti: PropTypes.bool,
  hasConfirmButton: PropTypes.bool,
  canClear: PropTypes.bool,
  filters: PropTypes.object,
  onSelectChange: PropTypes.func.isRequired,
  searchErrorLabel: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  onlyNotPortal: PropTypes.bool,
  isPortal: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
};

SelectCategoryTreeView.defaultProps = {
  options: [],
  disabled: false,
  onlyChild: false,
  isMulti: true,
};

export default SelectCategoryTreeView;
