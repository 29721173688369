// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function PhoneIcon(props) {
  return (
    <SvgIcon className="phone-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M12.25 4.99805H10.45C10.2013 4.99805 10 5.19935 10 5.44805C10 5.69674 10.2013 5.89805 10.45 5.89805H12.25C12.4987 5.89805 12.7 5.69674 12.7 5.44805C12.7 5.19935 12.4987 4.99805 12.25 4.99805Z" fill="inherit" stroke="inherit" strokeWidth="0.6" />
      <path d="M15 3H7.8C6.80761 3 6 3.80684 6 4.79829V19.1846C6 20.1761 6.80761 20.9829 7.8 20.9829H15C15.9924 20.9829 16.8 20.1761 16.8 19.1846V4.79829C16.8 3.80684 15.9924 3 15 3ZM15.9 19.1846C15.9 19.6813 15.4971 20.0838 15 20.0838H7.8C7.30289 20.0838 6.9 19.6813 6.9 19.1846V4.79829C6.9 4.30165 7.30289 3.89915 7.8 3.89915H15C15.4971 3.89915 15.9 4.30165 15.9 4.79829V19.1846Z" fill="inherit" stroke="inherit" strokeWidth="0.7" />
      <path d="M11.4 18.7983C11.8971 18.7983 12.3 18.3957 12.3 17.8991C12.3 17.4026 11.8971 17 11.4 17C10.9029 17 10.5 17.4026 10.5 17.8991C10.5 18.3957 10.9029 18.7983 11.4 18.7983Z" fill="inherit" stroke="inherit" strokeWidth="0.6" />
    </SvgIcon>
  );
}

export default PhoneIcon;
