export const types = Object.freeze({
  SET_FEEDS_LIST: 'SET_FEEDS_LIST',
  SET_FEEDS_EXPORT_FORCE_ACTION: 'SET_FEEDS_EXPORT_FORCE_ACTION',
  SET_FEEDS_CURRENT_PAGE: 'SET_FEEDS_CURRENT_PAGE',
  SET_FEEDS_SETTING_STATUS: 'SET_FEEDS_SETTING_STATUS',
  SET_FEEDS_SETTING_PENDING: 'SET_FEEDS_SETTING_PENDING',
  SET_FEEDS_FILTERS: 'SET_FEEDS_FILTERS',
  SET_FEEDS_PAGE_SIZE: 'SET_FEEDS_PAGE_SIZE',
  SET_FEEDS_SORTING: 'SET_FEEDS_SORTING',
  SET_FEEDS_INITIAL_STATE: 'SET_FEEDS_INITIAL_STATE',
  MERGE_FEEDS_STATUSES_LIST: 'MERGE_FEEDS_STATUSES_LIST',
  MERGE_FEEDS_ITEM_BY_HASH_ID: 'MERGE_FEEDS_ITEM_BY_HASH_ID',
  MERGE_FEEDS_PRIORITIES_LIST: 'MERGE_FEEDS_PRIORITIES_LIST',
  SET_FEEDS_IMPORT_ERROR: 'SET_FEEDS_IMPORT_ERROR',
  SET_FEEDS_IMPORT_PENDING: 'SET_FEEDS_IMPORT_PENDING',
  MERGE_FEEDS_EXPORT: 'MERGE_FEEDS_EXPORT',
  MERGE_FEEDS_REFRESH: 'MERGE_FEEDS_REFRESH',
  MERGE_FEEDS_IMPORT: 'MERGE_FEEDS_IMPORT',
  MERGE_FEEDS_TYPES_LIST: 'MERGE_FEEDS_TYPES_LIST',
  SET_FEEDS_DELETE_PENDING: 'SET_FEEDS_DELETE_PENDING',
  SET_DEFAULT_FEEDS_ITEM_BY_HASH_ID: 'SET_DEFAULT_FEEDS_ITEM_BY_HASH_ID',
  MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA: 'MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA',
  SET_FEEDS_ITEM_BY_HASH_CAN_PARSE: 'SET_FEEDS_ITEM_BY_HASH_CAN_PARSE',
  MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA_SECOND: 'MERGE_FEEDS_ITEM_BY_HASH_ID_STEP_DATA_SECOND',
  SET_FEEDS_REFRESH_RESULT: 'SET_FEEDS_REFRESH_RESULT',
  SET_FEEDS_DELETE_MODAL_SHOW: 'SET_FEEDS_DELETE_MODAL_SHOW',
  SET_FEEDS_CHANGE_URL_MODAL_SHOW: 'SET_FEEDS_CHANGE_URL_MODAL_SHOW',
  SET_FEEDS_CHANGE_URL_PENDING: 'SET_FEEDS_CHANGE_URL_PENDING',
  SET_FEEDS_CHANGE_URL_FORCE_ACTION: 'SET_FEEDS_CHANGE_URL_FORCE_ACTION',
  SET_COMPLETION_IMPORT_LIST: 'SET_COMPLETION_IMPORT_LIST',
  MERGE_COMPLETION_IMPORT_LIST_ITEM: 'MERGE_COMPLETION_IMPORT_LIST_ITEM',
});
