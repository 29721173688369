// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import UpdateIcon from '@mui/icons-material/Update';
import RefreshIcon from '@mui/icons-material/Refresh';
// Components
import IconButton from '@mui/material/IconButton';
import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { asyncActions } from '../../../../../../../engine/core/contentTemplates/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { COLOR } from '../../../../../../containers/App/AppStyles';

function UpdateFeaturesFromMagentoButton(props) {
  const {
    featureId, small, fullWidth, sx, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fixedCurrentFeatureId = featureId.toString();
  const currentfeatureId = fixedCurrentFeatureId || useSelector(selectors.contentProduct.productCategoryActiveItem)?.get('value');
  const isLoading = useSelector(selectors.contentTemplatesImport.updateValuesFromMagentoPending);
  const currentFeatureIdUpdate = useSelector(selectors.contentTemplatesImport.updateValuesFromMagentoFeatureId);
  const disabledCurrent = disabled || Boolean(isLoading && (featureId === currentFeatureIdUpdate));
  const hasAccess = useAccessList(accessList.featureUpdateValuesFromMagento);
  const onClickHandler = () => {
    dispatch(asyncActions.putUpdateValuesFromMagentoAsync({ featureId: currentfeatureId }));
  };
  if (!hasAccess || !featureId) {
    return null;
  }

  return small
    ? (
      <IconButton
        disabled={disabledCurrent}
        onClick={onClickHandler}
        size="small"
        color="secondary"
        sx={{
          opacity: disabledCurrent ? 0.5 : 1,
          width: '24px',
          height: '24px',
          border: `1px solid ${COLOR.grey['8']}`,
          borderRadius: '5px',
        }}
      >
        <RefreshIcon sx={{ width: '15px', color: COLOR.black['72'] }} />
      </IconButton>
    )
    : (
      <ButtonWithIcon
        fullWidth={fullWidth}
        sx={sx}
        disabled={disabledCurrent}
        onClick={onClickHandler}
        text={t('Обновить характеристики')}
      >
        <UpdateIcon />
      </ButtonWithIcon>
    );
}

UpdateFeaturesFromMagentoButton.displayName = 'UpdateFeaturesFromMagentoButton';

export default memo(UpdateFeaturesFromMagentoButton);
