// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// UI
import { makeStyles } from '@mui/styles';
// Style
import ContentWrapperStyle from './ContentWrapperStyle';

const useStyles = makeStyles(ContentWrapperStyle);
// Component
function ContentWrapper(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.panel}>
      {children}
    </div>
  );
}

ContentWrapper.propTypes = {
  children: PropTypes.node,
};

export default ContentWrapper;
