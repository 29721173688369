// Core
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Parts
import TableEditCell from './TableEditCell';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/partners/actions';
import { asyncActions } from '../../../../../engine/core/partners/saga/asyncActions';
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
// Helpers
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
// Hooks
import { usePartnersList } from './_hooks/usePartnersList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';
import { useAccessList } from '../../../../hooks/useAccessList';

// Redux
function PartnersTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.partners.isLoading);
  const hasAccessEdit = useAccessList(accessList.partnerEdit);
  const hasAccessShow = useAccessList(accessList.partnerShow);
  const editWidth = useEditCellWidth([(hasAccessEdit || hasAccessShow)], 0, 40, 40);
  // Rows
  const rows = useSelector(selectors.partners.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.partners.currentPage);
  const pageSize = useSelector(selectors.partners.pageSize);
  const totalCount = useSelector(selectors.partners.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.partners.sorting);
  const sortingStateColumnExtensions = [
    { columnName: tableDataTypes.partnerPaymentTypes.name, sortingEnabled: false },
  ];
  // Settings
  const settingStatus = useSelector(selectors.partners.settingStatus);
  const settingPending = useSelector(selectors.partners.settingPending);
  // Selection
  const hasAccessExportTop = useAccessList(accessList.partnerTopExport);
  const selection = useSelector(selectors.partners.selection);
  const onSelection = selected => dispatch(actions.setSelection(selected));
  // Filtering
  const filters = useSelector(selectors.partners.filters);
  const pathname = useSelector(selectors.router.pathname);
  const filteringExtensions = useMemo(() => {
    switch (pathname) {
      case pageLinks.partner.new:
      case pageLinks.partner.waiting:
      case pageLinks.partner.rejected: {
        return [{ columnName: tableDataTypes.partnerStatus.name, filteringEnabled: false }];
      }
      default: {
        return [];
      }
    }
  }, [pathname]);

  return (
    <DxTable
      name="partner"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePartnersList}
      // Editing
      editComponent={TableEditCell}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      sortingStateColumnExtensions={sortingStateColumnExtensions}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      filteringExtensions={filteringExtensions}
      // Selection
      selection={selection}
      onSelection={hasAccessExportTop && onSelection}
      // Settings
      gridSettings={gridSettings.partner.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(PartnersTable);
