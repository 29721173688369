// Core
import { apply, put } from 'redux-saga/effects';
// lodash
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callGetListWorker({ payload: params }) {
  yield put(actions.setList({ pending: true }));
  const args = { ...params, attributeSetIdSelect: params.attributeSetId, attributeSetId: undefined };

  const response = yield apply(api, api.contentProduct.getList, [args]);

  const data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const totalCount = responseData.totalCount;
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(actions.setList({
          ...data,
        }));
        break;
      }
      default: {
        const defaultFeature = { error: false, pending: false, success: false };

        const items = responseData.items.map((productItem) => {
          const productItemCopy = Object.assign({}, productItem);
          const productItemCopyKeys = Object.keys(productItemCopy);
          productItemCopyKeys.forEach((key) => {
            const productItemValue = productItemCopy[key];
            switch (key) {
              case 'productStatus': {
                productItemCopy[key] = {
                  ...defaultFeature,
                  ...productItemValue,
                  changedValue: get(productItemValue, 'value'),
                };
                break;
              }
              default: {
                if ((isString(productItemValue) && key !== 'hashId') || isNumber(productItemValue) && key !== 'id' || Array.isArray(productItemValue)) {
                  productItemCopy[key] = {
                    ...defaultFeature,
                    value: productItemValue,
                  };
                }
                break;
              }
            }
          });
          return productItemCopy;
        });

        yield put(actions.setList({
          ...data,
          ...responseData,
          totalCount,
          items,
        }));
        break;
      }
    }
  } else {
    yield put(actions.setList(data));
  }
}
