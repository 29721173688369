/* eslint-disable no-undef */
// Core
import React, { memo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import Informer from '../../../components/Informer/Informer';
import BankInstallmentsTable from './BankInstallmentsTable/BankInstallmentsTable';
import Modal from '../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../engine/core/bankInstallments/actions';
import DialogHeader from '../../../components/Dialog/DialogHeader/DialogHeader';
import { MONO_INSTALLMENTS_PAYMENT_METHOD } from '../../PartnersAccess/constants/paymentMethods';

const MONO_INFO_LINK = 'https://chast.monobank.ua/vendors';
const PRIVATBANK_INFO_LINK = 'https://privatbank.ua/business/oplata-chastyami?utm_source=google&utm_medium=cpc&utm_campaign=ac_acquiring_new_msb_search_cpa&utm_content=gid_163804083974_g_c_9197800&utm_term=%D0%BF%D1%80%D0%B8%D0%B2%D0%B0%D1%82%20%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0%20%D1%87%D0%B0%D1%81%D1%82%D1%8F%D0%BC%D0%B8_e__aud-2106536893178:kwd-325438625488&gad_source=1&gclid=Cj0KCQjwzva1BhD3ARIsADQuPnWHcdk1hHqa-hywPGcPRus4B6VnEFWiS_Pvvyrmfj09E_g6fSPNXt4aAlSVEALw_wcB#types';


function BankInstallmentsTab({
  value, handleChangeTab, hashId, paymentMethod,
}) {
  const { t } = useTranslation();
  const [closeModal, setCloseModal] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const dispatch = useDispatch();
  const isMono = paymentMethod === MONO_INSTALLMENTS_PAYMENT_METHOD;

  const handleClose = useCallback(() => {
    if (isPristine) {
      dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
      handleChangeTab(_, 'main');
    } else {
      setCloseModal(true);
    }
  }, [isPristine]);
  const handleModalSend = useCallback(() => {
    dispatch(actions.setBankInstallmentsTreeDataRowIds([]));
    handleChangeTab(_, 'main');
    setCloseModal(false);
  }, []);

  const headerLabel = isMono ? t('Настройка сервиса Покупка частями monobank') : t('Настройка сервиса Оплата частями Приватбанк');
  const infoLink = isMono ? MONO_INFO_LINK : PRIVATBANK_INFO_LINK;

  return (
    <TabsPanel
      index="bankInstallments"
      value="bankInstallments"
    >
      <Dialog
        fullScreen
        open={value === 'bankInstallments'}
        sx={{ maxHeight: '100%', overflowY: 'hidden' }}
      >
        <DialogHeader handleClose={handleClose} headerLabel={t(headerLabel)} />
        <Box sx={{
          p: '10px 20px 0px', display: 'flex', flexDirection: 'column', flex: 1,
        }}
        >
          <Informer>
            <Box sx={{ display: 'flex', marginBottom: '8px' }}>
              <Tooltip title={t('Например, Бытовая техника – это категория наивысшего уровня. Техника для кухни – ее подкатегория.')} placement="bottom-end" PopperProps={{ style: { zIndex: 10000, whiteSpace: 'pre-line' } }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
              </Tooltip>
              <Box component="span" sx={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
                <Trans i18nKey={'bank_installments_informer'} components={{ bold: <strong /> }} tOptions={{ bankName: isMono ? 'Monobank' : 'ПриватБанк', serviceName: isMono ? t('Покупка частями') : t('Оплата частями') }} />&nbsp;
                <Link href={infoLink} target="_blank">{t('по ссылке')}</Link>
              </Box>
            </Box>
          </Informer>
          <BankInstallmentsTable hashId={hashId} setIsPristine={setIsPristine} />
        </Box>
        {closeModal && (
        <Modal
          modalOpen={closeModal}
          handleModalToggle={() => setCloseModal(false)}
          handleModalCancel={() => setCloseModal(false)}
          handleModalSend={handleModalSend}
          title={t('Хотите закрыть страницу без сохранения?')}
          modalMaxWidth="sm"
          sendText={t('Закрыть страницу')}
          cancelText={t('Отменить')}
          buttonSendIcon={null}
        >
          <Box sx={{ padding: '16px' }}>{t('Подчеркиваем, что введенные данные сохранены не будут.')} </Box>
        </Modal>
        )}
      </Dialog>
    </TabsPanel>
  );
}


export default memo(BankInstallmentsTab);
