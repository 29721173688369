const UserByHashIdStyle = theme => ({
  marginTop12: {
    marginTop: 15,
  },
  lang: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  status: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'block',
    '& .MuiFormControl-root': {
      width: 'auto',
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
  langLabel: {
    fontSize: 14,
    margin: 0,
  },
  partnerLabel: {
    fontSize: 12,
    margin: 0,
  },
  select: {
    marginTop: theme.spacing(0.5),
  },
  chipWrapper: {
    position: 'relative',
    top: 5,
    left: -15,
    [theme.breakpoints.down('lg')]: {
      left: 0,
      paddingLeft: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      '& .MuiChip-root': {
        marginLeft: '0',
      },
    },
  },
  chipRoot: {
    height: 19,
  },
  chipRootMarginLeft: {
    height: 19,
    marginLeft: 5,
  },
  chipLabel: {
    padding: 6,
  },
  pageHeaderWrap: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  pageHeaderComponentWrap: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
    },
  },
  headerWrapper: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
});

export default UserByHashIdStyle;
