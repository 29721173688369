// Core
import * as Sentry from '@sentry/react';
import React, { useState, useEffect, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Header from '../../../../components/Header/Header';
import Workspace from './components/Workspace/Workspace';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import ContentWrapper from '../../../ContentWrapper/ContentWrapper';
import GuideTour from '../../../App/components/GuideTour/GuideTour';
import { RefProvider } from '../../../Context/refContext';
// Img
import Logo from '../../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../../assets/img/logo_short.svg';
// Engine
import { userActionAsync } from '../../../../../engine/core/user/saga/asyncAction';
import { uiActions } from '../../../../../engine/core/ui/action';
import { notificationsActions } from '../../../../../engine/core/notifications/action';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
import UserNotificationsMessage from './components/UserNotificationsMessage/UserNotificationsMessage';
import { notificationsActionAsync } from '../../../../../engine/core/notifications/saga/asyncAction';
// Helpers
import { usePrevious } from '../../../../hooks/usePrevious';
import { useMDView } from '../../../../hooks/useDesktopView';

function Layout(props) {
  const { t } = useTranslation();
  const isSidebarOpen = useSelector(selectors.ui.sidebarOpened);
  const userInfo = useSelector(selectors.user.userInfo);
  const notificationsLastUpdate = useSelector(selectors.statistics.notificationsLastUpdate);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const dispatch = useDispatch();
  const isMDView = useMDView();
  const prevNotificationsLastUpdate = usePrevious(notificationsLastUpdate);

  const handleDrawerToggle = () => {
    localStorage.setItem('isSidebarOpen', !isSidebarOpen);
    dispatch(uiActions.setSidebarOpen(!isSidebarOpen));
  };
  const sendReadList = () => {
    const { notificationsReadArray, putNotificationsAsync } = props;
    const normalizeNotificationsReadArray = notificationsReadArray.toJS();

    if (normalizeNotificationsReadArray.length) {
      putNotificationsAsync(normalizeNotificationsReadArray);
    }
  };
  const handleNotificationToggle = () => {
    const { notificationsNotReadCount, setNotificationCenterTab } = props;
    setNotificationsOpen(!notificationsOpen);
    setNotificationCenterTab(notificationsNotReadCount > 0 ? 1 : 0);
  };
  const handleNotificationHeaderToggle = () => {
    sendReadList();
    handleNotificationToggle();
  };
  const userLogout = () => {
    const { logOut } = props;
    logOut(true);
  };

  useEffect(() => {
    if (userInfo?.email && process.env.REACT_APP_SENTRY === 'true' && process.env.REACT_APP_SENTRY_DSN) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: userInfo.email,
        });
      });
    }
    if (
      (prevNotificationsLastUpdate && prevNotificationsLastUpdate !== notificationsLastUpdate)
    ) {
      dispatch(notificationsActionAsync.getNotificationsAsync({
        createdAtTo: notificationsLastUpdate,
        showType: 'toast',
        read: 0,
      }));
    }
  }, [dispatch, prevNotificationsLastUpdate]);

  useEffect(() => {
    dispatch(uiActions.setSidebarOpen(!isMDView));
  }, [dispatch, isMDView]);

  return (
    <>
      <Header
        logoAltText={t('АЛЛО - личный кабинет')}
        logo={Logo}
        LogoMobile={LogoMobile}
        toggleDrawer={handleDrawerToggle}
        toggleNotifications={handleNotificationHeaderToggle}
        userLogout={userLogout}
      />
      <ContentWrapper>
        <RefProvider>
          <GuideTour />
          <Sidebar
            opened={isSidebarOpen}
            toggleDrawer={handleDrawerToggle}
          />
          <Workspace opened={isSidebarOpen}>
            <Suspense fallback={null}>
              <Outlet />
              <UserNotificationsMessage />
            </Suspense>
          </Workspace>
        </RefProvider>
      </ContentWrapper>
    </>
  );
}

Layout.propTypes = {
  notificationsNotReadCount: PropTypes.number.isRequired,
  setNotificationCenterTab: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  notificationsReadArray: PropTypes.instanceOf(List).isRequired,
  putNotificationsAsync: PropTypes.func.isRequired,
};
// Redux
function mapStateToProps(state) {
  return {
    notificationsNotReadCount: selectors.statistics.notificationsNotReadCount(state),
    hasAccessToNotification: selectors.user.accessList(state).includes(accessList.notificationCenter),
    notificationsReadArray: selectors.notification.notificationsReadArray(state),
    inApprove: selectors.ui.inApprove(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setNotificationCenterTab: () => dispatch(notificationsActions.setNotificationCenterTab()),
    logOut: () => dispatch(userActionAsync.logOutAsync()),
    putNotificationsAsync: () => dispatch(notificationsActionAsync.putNotificationsAsync()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
