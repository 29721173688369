// Core
import { apply, put } from 'redux-saga/effects';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { setAuthTokens } from 'axios-jwt';

// Instruments
import { userService } from '../../../../config/api';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import { userActions } from '../../action';
import { partnerActions } from '../../../_partners/action';
import { userActionAsync } from '../asyncAction';
import { uiActions } from '../../../ui/action';

export function* callSetUserInfoWorker({ payload: userInfo }) {
  let newUserInfo = {};
  let allAccessLists;
  const partnerStatus = get(userInfo, 'partner.partnerStatus');
  const partnerId = get(userInfo, 'partner.id');
  const partnerHashId = get(userInfo, 'partner.hashId');
  const chatAuthKey = get(userInfo, 'chatAuthKey');

  if (Object.keys(userInfo.length !== 0) && userInfo.roles) {
    // Create newUserInfo
    const allRolesList = userInfo.roles.map(({ role }) => role);

    allAccessLists = [].concat(...userInfo.roles.map(({ accessList }) => accessList));
    localStorage.setItem('language', userInfo.locale === 'uk' ? 'ua' : userInfo.locale);
    newUserInfo = {
      name: userInfo.name,
      token: userInfo.token,
      refreshToken: userInfo.refreshToken,
      enabled: userInfo.enabled,
      accessLists: allAccessLists,
      rolesList: allRolesList,
      rememberMe: userInfo.rememberMe,
      skype: userInfo.skype,
      email: userInfo.email,
      phone: userInfo.phone,
      showPopups: userInfo.showPopups,
      xmlFileLink: userInfo.xmlFileLink,
      partnerId,
      partnerStatus,
      chatAuthKey,
    };
    yield put(userActions.setUserConfiguration(userInfo.configurations));
  }

  if (Object.keys(userInfo.length !== 0) && userInfo.supportManager) {
  // Added supportManager info
    newUserInfo.supportManager = {
      name: userInfo.supportManager.name,
      email: userInfo.supportManager.email,
      skype: userInfo.supportManager.skype,
      phone: userInfo.supportManager.phone,
    };
  }

  if (!isEmpty(userInfo.notification)) {
    newUserInfo.notification = userInfo.notification;
  }
  const userInApprove = partnerStatus === partnerStatuses.questionnairePendingConfirm;
  const setUserInfoData = {
    name: newUserInfo.name,
    email: userInfo.email,
    enabled: newUserInfo.enabled,
    rememberMe: newUserInfo.rememberMe,
  };
  setAuthTokens(
    newUserInfo.rememberMe
      ? {
        accessToken: newUserInfo.token,
        refreshToken: newUserInfo.refreshToken,
      }
      : {
        accessToken: newUserInfo.token,
      },
  );

  yield apply(userService, userService.setUserInfo, [setUserInfoData]);

  if (partnerHashId) {
    yield put(userActions.setInUserPartnerHashId(partnerHashId));
  }

  yield put(userActions.mergeUserSettings(userInfo.settings));
  yield put(userActions.setUserInfo(newUserInfo));
  yield put(uiActions.inApprove(userInApprove));
  yield put(userActionAsync.setUserNotificationsAsync());

  if (userInfo.partner && userInfo.partner.hashId && allAccessLists.length !== 0) {
    yield put(partnerActions.setPartnerHashId(userInfo.partner.hashId));
  }
}
