// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Components
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';

// Hooks
import { useAccessList } from '../../../hooks/useAccessList';

// Engine
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { asyncActions } from '../../../../engine/core/orders/saga/asyncActions';

function DownloadFullOrdersButton() {
  const hasAccess = useAccessList(accessList.orderCsvDownload);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.orders.isDownloadFullOrdersLoading);

  if (!hasAccess) {
    return null;
  }

  const downloadOrders = () => {
    if (isLoading) {
      return;
    }

    const params = {
      context: 'fullOrders',
      format: 'xlsx',
    };

    dispatch(asyncActions.downloadCSVAsync(params));
  };

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Загрузить заказы')}
      onClick={downloadOrders}
      isLoading={isLoading}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DownloadFullOrdersButton;
