// Core
import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
// UI
import Select from '../../../components/_Form/Selects/Select/Select';
// Parts
import EditableContainer from '../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import ConfirmButtons from '../../../components/Buttons/ConfirmButtons/ConfirmButtons';
// Engine
import { asyncActions } from '../../../../engine/core/priceCreator/priceCreatorCategories/saga/asyncActions';
// Helpers
import { tableDataTypes } from '../../../../_helpers/data/tableDataTypes';

function FormatterComponent(props) {
  const { row } = props;
  const {
    value: rowValue, error, success, pending,
  } = row.enabled;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultStatus = rowValue ? { value: 1, label: t('Да') } : { value: 0, label: t('Нет') };
  const statuses = useMemo(() => [
    {
      value: 1,
      label: t('Да'),
    }, {
      value: 0,
      label: t('Нет'),
    },
  ], [t]);

  const [status, setStatus] = useState(defaultStatus);
  const foundStatusLabel = useMemo(() => {
    const found = find(statuses, status);
    return found;
  }, [status, statuses]);


  const handleChange = (event) => {
    setStatus(event);
  };

  const handleConfirm = (currentValue) => {
    dispatch(asyncActions.putStatusCategoryAsync({
      hashId: row.hashId,
      key: tableDataTypes.enabled.name,
      value: currentValue,
    }));
  };

  const handleReject = () => {
    setStatus(defaultStatus);
  };

  return (
    <EditableContainer isEditable text={status} fullText>
      <ConfirmButtons
        key={rowValue}
        error={error}
        disabled={pending}
        onConfirm={handleConfirm}
        onReject={handleReject}
        success={success}
        value={status.value}
        valuesPlain={foundStatusLabel?.label}
      >
        <Select
          disabled={pending}
          handleChange={handleChange}
          defaultValue={status}
          variant="outlined"
          fullWidth
          options={statuses}
        />
      </ConfirmButtons>
    </EditableContainer>
  );
}

export default FormatterComponent;
