// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import Select from '../../../_Form/Selects/Select/Select';

function BillingStatusTableFilter(props) {
  const { onChange, value, disabled } = props;
  const input = { onChange, value };
  const { t } = useTranslation();

  const options = [
    { label: t('Кредитный лимит'), value: 2 },
    { label: t('Активный'), value: 1 },
    { label: t('Заблокирован'), value: 0 },
  ];

  return (
    <Select
      options={options}
      label=""
      disabled={disabled}
      input={input}
    />
  );
}

BillingStatusTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default BillingStatusTableFilter;
