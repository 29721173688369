export const asyncTypes = Object.freeze({
  GET_PARTNERS_LIST_ASYNC: 'GET_PARTNERS_LIST_ASYNC',
  GET_PARTNERS_LIST_BY_STATUS_ASYNC: 'GET_PARTNERS_LIST_BY_STATUS_ASYNC',
  GET_PARTNERS_LIST_WITH_MID_ASYNC: 'GET_PARTNERS_LIST_WITH_MID_ASYNC',
  PUT_PARTNERS_SETTINGS_ASYNC: 'PUT_PARTNERS_SETTINGS_ASYNC',
  GET_PARTNERS_DOWNLOAD_ASYNC: 'GET_PARTNERS_DOWNLOAD_ASYNC',
  GET_PARTNERS_INITIAL_STATE_ASYNC: 'GET_PARTNERS_INITIAL_STATE_ASYNC',
  GET_PARTNERS_PAYMENT_TYPES_ASYNC: 'GET_PARTNERS_PAYMENT_TYPES_ASYNC',
  PUT_PARTNERS_PROCESS_ASYNC: 'PUT_PARTNERS_PROCESS_ASYNC',
  PUT_PARTNERS_HASH_ID_CONFIRM_FORM_ASYNC: 'PUT_PARTNERS_HASH_ID_CONFIRM_FORM_ASYNC',
  POST_PARTNERS_IMPORT_TOP_ASYNC: 'POST_PARTNERS_IMPORT_TOP_ASYNC',
  GET_PARTNERS_EXPORT_TOP_ASYNC: 'GET_PARTNERS_EXPORT_TOP_ASYNC',
  PUT_PARTNERS_ORDER_PROCESSING_SCHEDULE_ASYNC: 'PUT_PARTNERS_ORDER_PROCESSING_SCHEDULE_ASYNC',
  PUT_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC: 'PUT_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC',
  DELETE_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC: 'DELETE_PARTNERS_DELIVERY_DELAY_PERIOD_ASYNC',
});
