// Core
import cx from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
// Parts
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import HighlightedText from '../../../../../../../containers/HighlightedText';
// Helpers
import TreeView from '../../../../../../../icons/TreeView';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
import { locale } from '../../../../../../../../engine/init/i18n';

const tooltipSyle = {
  position: 'absolute',
  top: '110%',
  left: 0,
  backgroundColor: 'rgba(48, 48, 48, 0.87)',
  zIndex: 1310,
  width: '100%',
  borderRadius: '2px',
  color: '#FFFFFF',
  paddingLeft: '8px',
  paddingTop: '4px',
  paddingBottom: '4px',
  whiteSpace: 'pre-wrap',
};

const CustomOption = (props) => {
  const {
    innerRef, isSelected, isFocused, children, data,
    innerProps: { onClick }, selectProps,
  } = props;
  const currentFocusOn = selectProps.focusOn;
  const isOnValueFocus = currentFocusOn
    ? data.value === currentFocusOn
    : false;

  const [isIconHovering, setIsIconHovering] = useState(false);
  const [isTextHovering, setIsTextHovering] = useState(false);
  const [showTextTooltip, setShowTextTooltip] = useState(false);
  const textRef = useRef(null);

  const handleMouseOver = () => {
    setIsIconHovering(true);
  };

  const handleMouseOut = () => {
    setIsIconHovering(false);
  };

  const handleMouseTextOver = () => {
    setIsTextHovering(true);
  };

  const handleMouseTextOut = () => {
    setIsTextHovering(false);
  };

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current;
      if (current) {
        const isOverflowing = current.scrollWidth > current.clientWidth;
        setShowTextTooltip(isOverflowing);
      }
    };

    checkOverflow();
  }, [children]);

  return (
    <>
      <MenuItem
        ref={innerRef}
        selected={selectProps.value ? isSelected : isFocused}
        component="div"
        className={cx({
          'is-focused': isOnValueFocus,
        })}
        classes={{
          root: selectProps.classes.menuItemRoot,
        }}
        sx={{
          fontWeight: isSelected ? 500 : 400,
          '&.Mui-selected, &.is-focused, &:hover': {
            backgroundColor: children !== '' ? COLOR.grey['4'] : 'transparent !important',
            cursor: children !== '' ? 'pointer' : 'default',
          },
        }}
        onClick={onClick}
      >
        {children !== '' && (
        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onFocus={handleMouseOver} onBlur={handleMouseOut}>
          <TreeView sx={{
            '&.MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
              marginRight: '8px',
              '&:hover': {
                backgroundColor: 'rgba(48, 48, 48, 0.08)',
                borderRadius: '5px',
              },
            },
          }}
          />
          {isIconHovering && (
          <Box sx={tooltipSyle}>{selectProps.optionsLanguage === locale.ru ? data?.parentsPath : data?.parentsPathUa ?? data?.parentName}
          </Box>
          )}
        </div>
        )}
        <Box
          component="span"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onMouseOver={handleMouseTextOver}
          onMouseOut={handleMouseTextOut}
          onFocus={handleMouseTextOver}
          onBlur={handleMouseTextOut}
          ref={textRef}
        >
          <HighlightedText
            label={children}
            search={selectProps?.inputValue}
          />
          {isTextHovering && showTextTooltip && (
            <Box sx={tooltipSyle}>{children}
            </Box>
          )}
        </Box>
      </MenuItem>
    </>
  );
};


export default CustomOption;
