// Core
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { billingAsyncAction } from '../../../../../../../engine/core/billing/saga/asyncAction';

export function useSuccessDeliveryPie() {
  const { t } = useTranslation();
  const data = useSelector(selectors.billing.successDeliveryDashboard);
  const dispatch = useDispatch();
  const getDataAsync = useCallback((parameters) => {
    dispatch(billingAsyncAction.getBillingSuccessDeliveryDashboardAsync(parameters));
  }, [dispatch]);
  return useMemo(() => {
    const backgroundColor = ['#4d9e57', '#e00226', '#dc8020'];
    const hoverBackgroundColor = ['#4d9e57', '#e00226', '#dc8020'];
    const labels = [t('Успешно доставленные'), t('Неуспешно доставленные'), t('В процесе доставки')];
    const timelyDeliveredQuantity = data.get('timelyDeliveredQuantity');
    const overdueQuantity = data.get('overdueQuantity');
    const inProcessingQuantity = data.get('inProcessingQuantity');
    const datasetsData = [timelyDeliveredQuantity, overdueQuantity, inProcessingQuantity];
    const noData = !!(!timelyDeliveredQuantity && !overdueQuantity && !inProcessingQuantity);
    return {
      getDataAsync,
      data: {
        labels,
        noData,
        datasets: [
          {
            backgroundColor,
            hoverBackgroundColor,
            data: datasetsData,
          },
        ],
      },
      legend: {
        labels: {
          boxWidth: 20,
          fontSize: 10,
        },
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, _data) => {
              const dataset = _data.datasets[tooltipItem.datasetIndex];
              const total = dataset.data.reduce((previousValue, currentValue) => (
                previousValue + currentValue
              ));
              const currentValue = dataset.data[tooltipItem.index];
              const numberWithSpaces = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              const currentValueWithSpace = numberWithSpaces(currentValue);
              const percentage = Math.floor(((currentValue / total) * 100) + 0.5);
              return ` ${t('Кол-во')}: ${currentValueWithSpace}; ${percentage}%`;
            },
          },
        },
      },
    };
  }, [getDataAsync, data, t]);
}
