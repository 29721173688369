// Core
import React from 'react';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Typography from '@mui/material/Typography';
import Modal from '../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { partnerActions } from '../../../../engine/core/_partners/action';
// Style
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';
import { formName } from '../form';

function PartnerIsTopModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessPartnerTopEdit = useAccessList(accessList.partnerTopEdit);
  const partnersIsTopIsOpen = useSelector(selectors.partner.partnerIsTopIsOpen);
  const partnerSavePending = useSelector(selectors.partner.partnerSavePending);

  const handleModalClose = () => {
    dispatch(partnerActions.mergePartnerIsTop({
      isOpen: false,
    }));
  };

  const handleSubmit = () => {
    dispatch(partnerActions.mergePartnerIsTop({
      forceAction: true,
    }));
    dispatch(submit(formName));
  };

  if (!hasAccessPartnerTopEdit) {
    return null;
  }

  return (
    <Modal
      title={t('Внимание')}
      modalOpen={partnersIsTopIsOpen}
      handleModalToggle={handleModalClose}
      modalMaxWidth="sm"
      fullWidth
      handleModalSend={handleSubmit}
      disabledSend={partnerSavePending}
      loadingSend={partnerSavePending}
      sendText={t('Да')}
      disabledClose={partnerSavePending}
      handleModalCancel={handleModalClose}
      cancelText={t('Нет')}
    >
      <Typography component="p" sx={{ textAlign: 'center' }}>
        {t("Данный Партнер входит в список 'ТОП партнеров'. Вы точно хотите изменить статус Партнера?")}
      </Typography>
    </Modal>
  );
}

export default PartnerIsTopModal;
