// Core
import { all, takeEvery } from 'redux-saga/effects';
// Parts
import { asyncTypes } from './asyncTypes';
import { types } from '../types';

// Workers
import {
  callGetPartnerInfoWorker,
  callGetStatusesWorker,
  callUploadPartnerLogoWorker,
  callPutPartnerInfoWorker,
  callGetPartnersListWorker,
  callUploadPartnerContractWorker,
  callCreateNewPartnerWorker,
  callGetPartnersDownloadWorker,
  callGetPartnersInitialStateWorker,
  callGetPartnersContextWorker,
  callSetToLocalDataWorker,
  callGetPartnerDownloadWorker,
  callGetPartnerContractCommissionDownloadWorker,
  callGetUsersContextWorker,
  callGetPartnerManagerInitialStateWorker,
  callPutPartnersSettingsWorker,
  callPutSendContractToSed,
  callUploadPartnerPaymentDocsWorker,
  callGetBusinessesListWorker,
  callPostBusinessesListWorker,
  callGetPartnersContextForServiceListWorker,
} from './workers';

function* watchGetPartnerInfo() {
  yield takeEvery(asyncTypes.GET_PARTNER_INFO_ASYNC, callGetPartnerInfoWorker);
}
function* watchPutPartnerSendContractToSed() {
  yield takeEvery(asyncTypes.PUT_PARTNER_SEND_CONTRACT_TO_SED, callPutSendContractToSed);
}
function* watchGetStatusesList() {
  yield takeEvery(asyncTypes.GET_PARTNER_STATUSES_LIST, callGetStatusesWorker);
}
function* watchUploadPartnerPaymentDocs() {
  yield takeEvery(asyncTypes.UPLOAD_PARTNER_PAYMENT_DOCS_ASYNC, callUploadPartnerPaymentDocsWorker);
}
function* watchUploadPartnerLogo() {
  yield takeEvery(asyncTypes.UPLOAD_PARTNER_LOGO_ASYNC, callUploadPartnerLogoWorker);
}
function* watchUploadPartnerContract() {
  yield takeEvery(asyncTypes.UPLOAD_PARTNER_CONTRACT_ASYNC, callUploadPartnerContractWorker);
}
function* watchPutPartnerInfo() {
  yield takeEvery(asyncTypes.PUT_PARTNER_INFO_ASYNC, callPutPartnerInfoWorker);
}
function* watchGetPartnersList() {
  yield takeEvery(asyncTypes.GET_PARTNERS_LIST_ASYNC_OLD, callGetPartnersListWorker);
}
function* watchCreateNewPartner() {
  yield takeEvery(asyncTypes.CREATE_NEW_PARTNER_ASYNC, callCreateNewPartnerWorker);
}
function* watchGetPartnersDownload() {
  yield takeEvery(asyncTypes.GET_PARTNERS_DOWNLOAD_ASYNC, callGetPartnersDownloadWorker);
}
function* watchGetPartnerDownload() {
  yield takeEvery(asyncTypes.GET_PARTNER_DOWNLOAD_ASYNC, callGetPartnerDownloadWorker);
}
function* watchcGetPartnerContractCommissionDownload() {
  yield takeEvery(asyncTypes.GET_PARTNER_CONTRACT_COMMISSION_DOWNLOAD_ASYNC, callGetPartnerContractCommissionDownloadWorker);
}
function* watchGetPartnersInitialState() {
  yield takeEvery(asyncTypes.GET_PARTNERS_INITIAL_STATE_ASYNC, callGetPartnersInitialStateWorker);
}
function* watchGetPartnerContextList() {
  yield takeEvery(asyncTypes.GET_PARTNER_CONTEXT_LIST_ASYNC, callGetPartnersContextWorker);
}
function* watchGetUsersContext() {
  yield takeEvery(asyncTypes.GET_USERS_CONTEXT_LIST_ASYNC, callGetUsersContextWorker);
}
function* watchGetPartnerManagerInitialState() {
  yield takeEvery(asyncTypes.GET_PARTNERS_MANAGER_INITIAL_STATE_ASYNC, callGetPartnerManagerInitialStateWorker);
}
function* watchPutPartnersSettings() {
  yield takeEvery(asyncTypes.PUT_PARTNERS_SETTINGS_ASYNC, callPutPartnersSettingsWorker);
}
function* watchGetBusinessesList() {
  yield takeEvery(asyncTypes.GET_BUSINESSES_LIST_ASYNC, callGetBusinessesListWorker);
}
function* watchUpdateBusinessesList() {
  yield takeEvery(asyncTypes.SET_PARTNER_BUSINESS_ASYNC, callPostBusinessesListWorker);
}
function* watchCallGetPartnersContextForServiceList() {
  yield takeEvery(asyncTypes.GET_PARTNERS_FOR_SERVICE_REQUESTS_LIST, callGetPartnersContextForServiceListWorker);
}
function* watchSetPartnersCurrentPage() {
  yield takeEvery(types.SET_PARTNERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPartnersPageSize() {
  yield takeEvery(types.SET_PARTNERS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetPartnersFiltersSize() {
  yield takeEvery(types.CHANGE_PARTNERS_FILTERS, callSetToLocalDataWorker);
}
function* watchResetPartnersFilters() {
  yield takeEvery(types.RESET_PARTNERS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetPartnersSorting() {
  yield takeEvery(types.CHANGE_PARTNERS_SORTING, callSetToLocalDataWorker);
}


export function* watchersPartner() {
  yield all([
    watchGetPartnerInfo(),
    watchPutPartnerSendContractToSed(),
    watchGetStatusesList(),
    watchUploadPartnerLogo(),
    watchUploadPartnerContract(),
    watchUploadPartnerPaymentDocs(),
    watchPutPartnerInfo(),
    watchGetPartnersList(),
    watchCreateNewPartner(),
    watchGetPartnersDownload(),
    watchGetPartnerDownload(),
    watchGetPartnersInitialState(),
    watchSetPartnersCurrentPage(),
    watchSetPartnersPageSize(),
    watchSetPartnersFiltersSize(),
    watchResetPartnersFilters(),
    watchGetPartnerContextList(),
    watchSetPartnersSorting(),
    watchcGetPartnerContractCommissionDownload(),
    watchGetUsersContext(),
    watchGetPartnerManagerInitialState(),
    watchPutPartnersSettings(),
    watchGetBusinessesList(),
    watchUpdateBusinessesList(),
    watchCallGetPartnersContextForServiceList(),
  ]);
}
