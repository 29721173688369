// Core
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';
// Parts
import Paper from '@mui/material/Paper';
import {
  PagingState,
  SortingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import TableCell from '../../../../../components/_Table/TableCell/TableCell';
import NoDataCell from '../../../../../components/_Table/DxTable/components/NoDataCell';
// Data
import { tableColumns } from './ReportGeneralTableColumnsSettings';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { locale } from '../../../../../../engine/init/i18n';
import DxLoader from '../../../../../components/_Table/DxTable/components/DxLoader';

const ReportGeneralTable = () => {
  const { i18n } = useTranslation();
  const reportGeneral = useSelector(selectors.reports.reportGeneral);
  const currentLocale = locale[i18n.language];
  const columns = values(tableColumns);
  const rows = [reportGeneral];
  const isLoading = useSelector(selectors.reports.isLoading);

  return (
    <>
      <Paper sx={{ position: 'relative' }}>
        <Grid
          rows={rows}
          columns={columns}
          key={currentLocale}
        >
          <SortingState />
          <PagingState />
          <CustomPaging />
          <Table
            cellComponent={TableCell}
            noDataCellComponent={dataCell => <NoDataCell colSpan={dataCell.colSpan} isLoading={isLoading} />}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </Paper>
      <DxLoader
        isLoading={isLoading}
        hasList={rows.length > 0}
      />
    </>
  );
};

export default ReportGeneralTable;
