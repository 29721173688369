// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';

// Style
import { useStyles } from '../../hooks/useStyles';
import SectionHolderStyles from './SectionHolderStyle';

function SectionHolder(props) {
  const { children } = props;
  const classes = useStyles(SectionHolderStyles);
  return (
    <Box className={classes.section}>
      {children}
    </Box>
  );
}

SectionHolder.propTypes = {
  children: PropTypes.node,
};

export default SectionHolder;
