// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
import { partnersManagers } from '../../selectors';
// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';


export function* callPutPartnerManagerWorker({ payload }) {
  const { hashId, value } = payload;

  yield put(actions.mergePartnerManager({
    pending: true,
  }));


  const response = yield apply(api, api.partnersManagers.putPartnerManager, [{ hashId, value }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;
    switch (status) {
      case 'error': {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        const pageSize = yield select(partnersManagers.pageSize);
        const sorting = (yield select(partnersManagers.sorting)).toJS();
        const filters = (yield select(partnersManagers.filters)).toJS();

        const params = {
          sorting,
          limit: pageSize,
        };

        filters.reduce((acc, { columnName, value: filterValue }) => {
          acc[columnName] = filterValue;
          return acc;
        }, params);

        yield put(asyncActions.getListAsync(params));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergePartnerManager({
    pending: false,
  }));
}
