// Core
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// Parts
import { RowDetailState } from '@devexpress/dx-react-grid';
import Paper from '@mui/material/Paper';
import {
  Grid, Table, TableHeaderRow, VirtualTable, TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import NoDataCell from '../../../../../../../components/_Table/DxTable/components/NoDataCell';
import TableCell from './TableCell';
import { RowDetailAttributes, ToggleCellAttributesComponent } from './RowDetailAttributes';
// Core
import { asyncActions } from '../../../../../../../../engine/core/products/productFeedCategoriesAttributes/saga/asyncActions';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../../engine/core/products/productFeedCategoriesAttributes/actions';
import { productFeedCategoriesAttributesConst } from '../../../../../../../../engine/core/products/const';

export const ToggleCellCategoriesComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const { onToggle, row, expanded } = props;
  const { t } = useTranslation();
  const rowStatus = row.attributeStatus.value;
  const rowInProcessStatus = rowStatus === productFeedCategoriesAttributesConst.AUTO_CONNECT_STATUS;
  const inProcess = rowInProcessStatus || loading;
  const dispatch = useDispatch();
  const ArrowIcon = expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  const onClick = (e) => {
    if (expanded) {
      onToggle(e);
    } else {
      dispatch(asyncActions.getCategoriesByHashId({
        hashId: row.hashId,
        setLoading,
        onSuccess: () => onToggle(e),
      }));
    }
  };

  useEffect(() => {
    if (expanded && rowInProcessStatus) {
      onToggle(false);
    }
  }, [expanded, rowInProcessStatus]);

  const loadingComponent = rowInProcessStatus ? (
    <Tooltip
      title={(
        <>
          <b>{t('Внимание!')}</b>&nbsp;
          {t('Происходит процесс автоматического сопоставления характеристик и их значений. После завершения процесса – просмотр характеристик и их значений снова станет доступным.')}
        </>
      )}
    >
      <CircularProgress color="secondary" size={20} />
    </Tooltip>
  ) : (
    <CircularProgress color="secondary" size={20} />
  );

  return (
    <VirtualTable.Cell {...props} style={{ padding: 0, textAlign: 'center' }}>
      <IconButton sx={{ width: 40, height: 40 }} disabled={loading} onClick={inProcess ? undefined : onClick}>
        {inProcess ? loadingComponent : ArrowIcon}
      </IconButton>
    </VirtualTable.Cell>
  );
};

export const RowDetailCategories = ({ row }) => {
  const { hashId } = row;
  const rows = useSelector(selectors.productFeedCategoriesAttributes.categoriesItems);
  return (
    <DetailItemsTable
      hashId={hashId}
      rows={rows.get(hashId).items}
    />
  );
};
function DetailItemsTable(props) {
  const { rows, hashId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = [
    { name: 'attribute', title: t('Характеристики в файле') },
    { name: 'feature', title: t('Характеристики Алло') },
    { name: 'ignored', title: t('Игнорировать') },
    { name: 'status', title: t('Статус') },
  ];
  const columnExtensions = [
    { columnName: 'feature', width: '23%' },
    { columnName: 'attribute', width: '22%' },
    { columnName: 'status', width: 240 },
  ];
  const PatchedCellComponent = useCallback(cellProps => <TableCell {...cellProps} hashId={hashId} />, [hashId]);
  // Expanded
  const onExpandedRowIdsChange = ids => dispatch(actions.setAttributesExpendedRowIds(ids));
  const expandedRowIds = useSelector(selectors.productFeedCategoriesAttributes.attributesExpandedRowIds);
  let position = 0;

  useEffect(() => () => {
    dispatch(actions.setAttributesExpendedRowIds([]));
  }, []);

  return (
    <Paper sx={{ margin: '5px 5px 5px 60px', '& .TableCell-cell:first-child': { paddingLeft: '4px' }, '& .TableDetailCell-active .TableCell-cell:first-child': { paddingLeft: '24px' } }}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={row => `${position++}_${hashId}_${row.id}_${row?.feature?.type}`}
      >
        <RowDetailState
          style={{ backgroundColor: 'red' }}
          expandedRowIds={expandedRowIds.toJS()}
          onExpandedRowIdsChange={onExpandedRowIdsChange}
        />
        <Table
          getRowId={row => `${position}_${hashId}_${row.id}_${row?.feature?.type}`}
          cellComponent={PatchedCellComponent}
          columnExtensions={columnExtensions}
          noDataCellComponent={NoDataCell}
        />
        <TableHeaderRow />
        <TableRowDetail
          toggleCellComponent={ToggleCellAttributesComponent}
          contentComponent={RowDetailAttributes}
        />
      </Grid>
    </Paper>
  );
}
