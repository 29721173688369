// Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icon
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
// Engine
import { asyncActions } from '../../../../../engine/core/systemMotivation/results/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';

function ExportButton() {
  const { t } = useTranslation();
  const hasAccessList = useAccessList(accessList.motivationSystemList);
  const downloadPending = useSelector(selectors.systemMotivationResults.downloadPending);
  const dispatch = useDispatch();
  const onDownload = () => {
    dispatch(asyncActions.getDownloadAsync());
  };

  if (!hasAccessList) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={downloadPending}
      disabled={downloadPending}
      text={t('Экспорт данных')}
      onClick={onDownload}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default ExportButton;
