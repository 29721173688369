const ShowAllLinkStyles = () => ({
  showAllLink: {
    alignItems: 'center',
    display: 'flex',
    height: '23px',
    fontSize: '14px',
    color: '#497eaf',
  },
});

export default ShowAllLinkStyles;
