// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  // PRODUCT_FEED_FEATURE_VALUES
  getAttributesByHashId(data) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID, data);
  },
  putAttributesByHashId(data) {
    return getActionCreator(asyncTypes.PUT_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID, data);
  },
  // PRODUCT_FEED_FEATURE
  getCategoriesByHashId(data) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID, data);
  },
  putCategoriesByHashId(data) {
    return getActionCreator(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_BY_HASH_ID, data);
  },
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  getListAsync(params, hashId, mode) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST_ASYNC, { params, hashId, mode });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE_ASYNC, path);
  },
  postAutoConnectAsync(params) {
    return getActionCreator(asyncTypes.POST_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_ASYNC, params);
  },
});
