// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetDownload,
  callGetItemWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_MAGENTO_REQUESTS_LIST_ASYNC, callGetListWorker);
}
function* watchGetItem() {
  yield takeEvery(asyncTypes.GET_MAGENTO_REQUESTS_ITEM_BY_HASH_ID_ASYNC, callGetItemWorker);
}
function* watchGetDownload() {
  yield takeEvery(asyncTypes.GET_MAGENTO_REQUESTS_DOWNLOAD_ASYNC, callGetDownload);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_MAGENTO_REQUESTS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MAGENTO_REQUESTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MAGENTO_REQUESTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MAGENTO_REQUESTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MAGENTO_REQUESTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MAGENTO_REQUESTS_SORTING, callSetToLocalDataWorker);
}

export function* watchersMagentoRequests() {
  yield all([
    watchGetItem(),
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchGetDownload(),
  ]);
}
