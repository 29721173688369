// Core
import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icon
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Containers
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
// Engine
import { asyncActions } from '../../../../../engine/core/stopList/stopWords/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';

function ExportStopWordsButton() {
  const { t } = useTranslation();
  const hasAccessList = useAccessList(accessList.stopWordsDownload);
  const getExportStopWords = useSelector(selectors.stopWords.getExportStopWords);
  const dispatch = useDispatch();
  const onDownload = () => {
    dispatch(asyncActions.getExportStopWordsAsync());
  };

  if (!hasAccessList) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={getExportStopWords}
      disabled={getExportStopWords}
      text={t('Экспорт данных')}
      onClick={onDownload}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default memo(ExportStopWordsButton);
