// Core
import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
// UI
import ConfirmButtons from '../../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import Select from '../../../../../../../../../components/_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../../../../engine/core/contentProduct/actions';

function CategoryWithConfirmButtons(props) {
  const {
    error, disabled, featureName, onConfirm, productId, onClickFilter,
    success, value, valuesPlain, required, showDeleteButton, canChangeCategory,
  } = props;
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList).filter(
    item => item.value !== null,
  );
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.contentProduct.isCategoriesListLoading);
  const initValue = isNil(value) ? undefined : value;
  const [selected, setSelected] = useState(initValue);

  const handleChange = (newValue) => {
    setSelected(newValue);
  };

  const onSelectReject = (rejectValue) => {
    setSelected(rejectValue);
  };

  const onSelectConfirm = (newValue) => {
    if (canChangeCategory) {
      onConfirm({ [featureName]: newValue });
    } else {
      dispatch(actions.setChangeCategoryErrorOpen(true));
    }
  };

  const selectProps = {
    value: selected,
    onChange: handleChange,
  };

  return (
    <ConfirmButtons
      key={productId + value}
      error={error}
      disabled={disabled}
      required={required}
      notEmpty={selected !== null}
      onConfirm={onSelectConfirm}
      onReject={onSelectReject}
      success={success}
      value={selected}
      valuesPlain={valuesPlain}
      initValue={initValue}
      featureName={featureName}
      onClickFilter={onClickFilter}
      showDeleteButton={showDeleteButton}
    >
      <Select
        input={selectProps}
        options={categoriesList}
        isLoading={isLoading}
        variant="outlined"
        hasSearch
      />
    </ConfirmButtons>
  );
}

CategoryWithConfirmButtons.propTypes = {
  showDeleteButton: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  success: PropTypes.bool,
  canChangeCategory: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClickFilter: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

CategoryWithConfirmButtons.defaultProps = {
  showDeleteButton: true,
  error: false,
  required: false,
  disabled: false,
  success: false,
  valuesPlain: '',
};

export default memo(CategoryWithConfirmButtons);
