// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { billingActions } from '../../action';
import { api } from '../../../../config/api';
// Helpers
import { setErrorMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callGetBillingOnTimeOrdersDashboardWorker(parameters) {
  let response;

  try {
    response = yield apply(api, api.dashboards.getDashboardsOnTimeOrdersStatusesStatistic, [parameters.payload]);
  } catch (err) {
    yield put(setErrorMessage(err.message, 'Error'));
    return;
  }

  if (response && response.status >= 200 && response.status < 400) {
    yield put(billingActions.setBillingOnTimeOrdersDashboardData(response.data));
  }
}
