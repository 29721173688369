// Core
import { List, Map } from 'immutable';
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { billingActions } from '../../../action';

export function* callGetListWorker({ payload }) {
  yield put(actions.setList({
    pending: true,
  }));
  yield put(billingActions.mergeBillingReportDownload({
    parameters: Map(payload),
  }));
  const response = yield apply(api, api.billing.getBillingReport, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    const { items, report, totalCount } = response.data;
    yield put(actions.setList({
      items: List(items),
      report,
      totalCount,
      modalIsOpen: false,
      reload: false,
    }));
  }
  yield put(actions.setList({
    pending: false,
  }));
}
