import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { Tab, tabClasses } from '@mui/base/Tab';
import { buttonClasses } from '@mui/base/Button';
import { TabsList } from '@mui/base/TabsList';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { COLOR } from '../../../../../../containers/App/AppStyles';

export const Fieldset = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
`;
export const Legend = styled(Box)`
  color: ${COLOR.black['54']};
  font-size: 12px;
  padding: 0 4px;
`;

export const TabStyled = styled(Tab)`
  color: ${COLOR.darkStyle.fontColorMain};
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  width: 100%;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 48px;
  line-height: 1.75;
  text-align: left;
  &:hover {
    background-color: ${COLOR.darkStyle.white['8']};
    color: ${COLOR.darkStyle.fontColorMain};
  }
  &.${tabClasses.selected} {
    background-color: ${COLOR.darkStyle.white['8']};
    color: ${COLOR.darkStyle.fontColorMain};
  }
  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabsListStyled = styled(TabsList)(
  () => `
    border-radius: 5px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    flex-direction: column;
    row-gap: 0;
  `,
);

export const buttonStyle = {
  justifyContent: 'left',
  textAlign: 'left',
  color: `${COLOR.darkStyle.fontColorMain}`,
  paddingLeft: '10px',
  '&:hover': {
    backgroundColor: `${COLOR.darkStyle.white['8']}`,
  },
  '&.Mui-disabled': {
    color: `${COLOR.darkStyle.white['38']}`,
  },
};

export const sxButton = (color = COLOR.darkStyle.fontColorMain) => ({
  justifyContent: 'left',
  padding: '12px 18px',
  color,
  lineHeight: 1,
  textAlign: 'left',
  '&.MuiButton-root:hover': {
    background: `${COLOR.darkStyle.white['8']}`,
  },
  '&.MuiButton-root.Mui-disabled': {
    opacity: '0.5',
    cursor: 'not-allowed',
    color,
  },
});

export const sxIcon = { fontSize: 18, marginRight: '8px' };

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 192,
    fontSize: 12,
    boxShadow: '0px 0px 8px 2px #FFFFFF14',
  },
});
