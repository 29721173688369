// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
import { getFileFromBlob } from '../../../../../../_helpers/getFileFromBlob';
import { selectors } from '../../../../../config/selectors';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callGetProductsFeedExportWorker({ payload: { hashId, forceAction } }) {
  yield put(actions.setDownloadPending(true));
  const filters = yield select(selectors.feedsProducts.filters);
  const normalizeFilters = filters.toJS();
  const sorting = yield select(selectors.feedsProducts.sorting);
  const params = {
    sorting: sorting.toJS(),
    forceAction,
  };
  if (hashId !== undefined) {
    normalizeFilters.push({ columnName: 'feedCategory', value: hashId });
  }
  normalizeFilters.reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);

  const response = yield apply(api, api.feedsProducts.getProductsExport, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, needForceAction } = responseData;
        if (needForceAction) {
          yield put(actions.setDownloadForceAction({ type: 'export', forceAction: needForceAction ? 1 : 0 }));
        } else {
          yield put(setErrorMessage(errors, statusTitle));
        }
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        if (forceAction) {
          yield put(actions.setDownloadForceAction({ type: null, forceAction: null }));
        }
        break;
      }

      default: {
        getFileFromBlob('products_feed.xlsx', response);
        break;
      }
    }
  }

  yield put(actions.setDownloadPending(false));
}
