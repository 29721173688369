// Core
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import transform from 'lodash/transform';
// Parts
import RowComponent from './components/RowComponent';
import OrdersItemsTable from '../OrdersItemsTable';
import OrderStatistics from '../OrderStatistics/OrderStatistics';
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import CellComponent from './components/CellComponent';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/orders/actions';
import { asyncActions } from '../../../../../engine/core/orders/saga/asyncActions';
import { userActions } from '../../../../../engine/core/user/action';
import { pageLinks } from '../../../../../engine/config/routes';
// Hooks
import { useOrdersList } from '../../_hooks/useOrdersList';

function OrdersTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.orders.isLoading);
  const pathname = useSelector(selectors.router.pathname);
  // Rows
  const rows = useSelector(selectors.orders.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.orders.currentPage);
  const pageSize = useSelector(selectors.orders.pageSize);
  const totalCount = useSelector(selectors.orders.totalCount);
  const expandedRowIds = useSelector(selectors.orders.expandedRowIds);
  const userSettings = useSelector(selectors.user.userSettings);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.orders.sorting);
  // Settings
  const settingStatus = useSelector(selectors.orders.settingStatus);
  const settingPending = useSelector(selectors.orders.settingPending);
  // Filtering
  const filters = useSelector(selectors.orders.filters);

  const settingsDetailComponent = useMemo(() => {
    const settings = userSettings.order.gridSettings;
    const columnsOrderItemName = map(settings.columnsOrderItem, column => column.name);
    const columnOrderWidth = map(settings.columnsOrder, ({ name, width }) => ({ columnName: name, width }));
    const columnsOrderItemWidth = map(settings.columnsOrderItem, ({ name, width }) => ({ columnName: name, width }));

    return {
      columnsOrder: settings.columnsOrder,
      columnsOrderItem: settings.columnsOrderItem,
      columnPosition: columnsOrderItemName,
      columnWidths: uniqBy([...columnOrderWidth, ...columnsOrderItemWidth], 'columnName'),
    };
  }, [userSettings]);

  const onExpandedRowIdsChange = (ids) => {
    dispatch(actions.setOrdersExpandedRowIds(ids));
  };

  const onUserSettings = ({ name, gridSettings: newGridSettings, newColumnWidths }) => {
    let columnsOrder = newGridSettings || gridSettings.order.gridSettings.columnsOrder;
    if (newColumnWidths) {
      const widths = transform(newColumnWidths, (result, item) => {
        // eslint-disable-next-line no-param-reassign
        result[item.columnName] = item.width;
      }, {});

      columnsOrder = map(gridSettings.order.gridSettings.columnsOrder, column => ({
        ...column,
        width: widths[column.name],
      }));
    }
    dispatch(userActions.mergeUserSettings({
      [name]: {
        gridSettings: {
          columnsOrderItem: gridSettings.order.gridSettings.columnsOrderItem,
          columnsOrder,
        },
      },
    }));
  };

  const RowDetailComponent = useCallback(({ row }) => (
    <OrdersItemsTable
      rows={row.orderItems}
      columnPosition={settingsDetailComponent.columnPosition}
      columnList={settingsDetailComponent.columnsOrderItem}
      onOrderChange={onUserSettings}
      columnWidths={settingsDetailComponent.columnWidths}
    />
  ), [
    settingsDetailComponent.columnPosition,
    settingsDetailComponent.columnWidths,
    settingsDetailComponent.columnsOrderItem,
  ]);

  const filteringEnabled = !((pathname === pageLinks.orders.new || pathname === pageLinks.orders.done));
  const filteringColumnExtension = [{ columnName: 'orderStatus', filteringEnabled }];

  return (
    <DxTable
      name="order"
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useOrdersList}
      editComponent={CellComponent}
      editComponentWidth={48}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Detail
      expandedRowIds={expandedRowIds}
      onExpandedRowIdsChange={onExpandedRowIdsChange}
      rowDetailComponent={RowDetailComponent}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Row
      rowComponent={RowComponent}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      filteringExtensions={filteringColumnExtension}
      // Settings
      gridSettings={gridSettings.order.gridSettings.columnsOrder}
      settingStatus={settingStatus}
      settingPending={settingPending}
      onUserSettings={onUserSettings}
      onOrderChange={onUserSettings}
      onColumnWidthsChange={onUserSettings}
      settingAction={asyncActions.putSettingsAsync}
      bottomComponent={<OrderStatistics />}
    />
  );
}

export default OrdersTable;
