// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { partnerActions } from '../../action';

// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetPartnerContractCommissionDownloadWorker({ payload: partnerID }) {
  yield put(partnerActions.setPartnerContractCommissionDownloadPending(true));

  const response = yield apply(api, api.partner.getPartnerContractCommissionDownload, [partnerID]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;


    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      default: {
        getFileFromBlob('partner_contract_commission.pdf', response);
        break;
      }
    }
  }
  yield put(partnerActions.setPartnerContractCommissionDownloadPending(false));
}
