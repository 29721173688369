// Core
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Parts
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import MultipleSelectFilter from './MultipleSelectFilter';

function BusinessFilter(props) {
  const {
    disabled, filters, onSelectChange,
    columnName, placeholder,
  } = props;

  const { t } = useTranslation();
  const isLoading = useSelector(selectors.partner.businessesListPending);
  const businesses = useSelector(selectors.partner.businessesList);

  return (
    <MultipleSelectFilter
      values={businesses}
      loading={isLoading}
      disabled={disabled}
      filters={filters}
      onSelectChange={onSelectChange}
      columnName={columnName}
      placeholder={placeholder}
      getAction={partnerAsyncAction.getBusinessesListAsync()}
      searchErrorLabel={t('Бизнес не найден')}
    />
  );
}

BusinessFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  columnName: PropTypes.string,
};

export default BusinessFilter;
