// Core
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// UI
import Tooltip from '@mui/material/Tooltip/Tooltip';
// Engine
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { pageLinks } from '../../../../../engine/config/routes';
// hooks
import { useMessageReadOrEditPage } from '../_hooks/useMessageReadOrEditPage';

import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';


// Redux
function MessagesTableEditColumn({ tableRow }) {
  const { t } = useTranslation();
  const { isReadMessage } = useMessageReadOrEditPage(tableRow.row);
  const getTitle = isReadMessage
    ? t('Просмотреть сообщение')
    : t('Редактировать сообщение');

  return (
    <Tooltip
      title={getTitle}
      aria-label={getTitle}
    >
      <Link to={pageLinks.messages.edit(tableRow.row.hashId)}>
        <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
          {isReadMessage ? <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} /> : <ModeOutlinedIcon sx={{ p: '2px' }} />}
        </IconButtonStyles>
      </Link>
    </Tooltip>
  );
}

export default MessagesTableEditColumn;
