import { getIn, List } from 'immutable';
import { stateKeys as serviceDeskKeys } from './reducers';

// config
import { pageSizes } from '../../config/globalConfig';

export const serviceDesk = Object.freeze({
  currentPage: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.currentPage], 0),
  filters: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.filters], List()),
  isLoading: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.pending], false),
  list: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.items], List()),
  pageSize: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.reload], false),
  totalCount: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.totalCount], 0),
  sorting: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.sorting], List()),
  sortingStateColumnExtensions: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.list, serviceDeskKeys.sortingStateColumnExtensions], List()),
  statusesList: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.statusesList, serviceDeskKeys.items], List()),
  statusesListPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.statusesList, serviceDeskKeys.pending], false),
  isItemByHashIdLoading: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.itemByHashId, serviceDeskKeys.pending], false),
  isItemByHashIdPutPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.itemByHashId, serviceDeskKeys.putPending], false),
  settingStatus: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.setting, serviceDeskKeys.status], false),
  settingPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.setting, serviceDeskKeys.pending], false),
  // Files
  chatFiles: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.chatFiles], List()),
  fileIsLoading: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.pending], false),
  // Messages
  messagesFromServer: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.messagesFromServer, serviceDeskKeys.data], List()),
  messagesFromServerAreLoading: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.messagesFromServer, serviceDeskKeys.pending], false),
  messages: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.messages, serviceDeskKeys.data], List()),
  messageIsSending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.messageIsSending], List()),
  currentMessage: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.currentMessage], ''),
  // Other
  externalLink: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.externalLink], null),
  chatName: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.chatName], ''),
  chatKey: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.key], new Date().getTime()),
  infinityScrollState: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.infinityScrollState], []),
  canEdit: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.canEdit], false),
  canClose: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.canClose], false),
  canContinue: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.chat, serviceDeskKeys.canContinue], false),
  continueQuestionPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.continueQuestion, serviceDeskKeys.pending], false),
  closeQuestionPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.closeQuestion, serviceDeskKeys.pending], false),
  // Tickets
  newTicketPending: state => getIn(state, [serviceDeskKeys.controller, serviceDeskKeys.tickets, serviceDeskKeys.new, serviceDeskKeys.pending], false),
});
