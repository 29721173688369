const EditTableColumnStyles = theme => ({
  formGroup: {
    paddingTop: 6,
    paddingRight: theme.spacing(1),
    paddingBottom: 6,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resetButton: {
    margin: 2,
  },
  formGroupDivider: {
    marginTop: 4,
    marginBottom: 6,
  },
});

export default EditTableColumnStyles;
