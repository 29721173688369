// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
// Parts
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { partnerActions } from '../../../../engine/core/_partners/action';
import { selectors } from '../../../../engine/config/selectors';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';

function PartnerRejectAllValuesButton(props) {
  const { handleSubmit, partnerStatus } = props;
  const shouldApproveValues = useSelector(selectors.partner.shouldApproveValues);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onApproveBtnChickHandler = () => {
    dispatch(partnerActions.approveInfoFieldValues('old'));
  };

  if (!shouldApproveValues || partnerStatus !== partnerStatuses.active) {
    return null;
  }
  return (
    <ButtonWithIcon text={t('Отклонить все')} onClick={handleSubmit(onApproveBtnChickHandler)}>
      <CancelIcon />
    </ButtonWithIcon>
  );
}
PartnerRejectAllValuesButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  partnerStatus: PropTypes.number,
};
export default PartnerRejectAllValuesButton;
