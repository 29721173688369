// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// UI
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Parts
import Modal from '../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../engine/config/selectors';

export default function ModalSignTheContract() {
  const { showPopups } = useSelector(selectors.user.userInfo);
  const [showModal, setShowModal] = useState(showPopups?.showSendContractToSED === true || showPopups?.showSignContractAndUpload === true);
  const { t } = useTranslation();
  const hiddenModal = () => setShowModal(false);
  return (
    <Modal
      hideButtons
      handleModalCancel={hiddenModal}
      handleModalToggle={hiddenModal}
      modalOpen={showModal}
      title={t('Уважаемый партнер!')}
    >
      { showPopups?.showSendContractToSED === true && <Typography align="center">{t('Для перехода на следующий этап, подпишите и отправьте договор во')} <Link href="https://vchasno.ua/" target="_blank">Вчасно</Link></Typography>}
      { showPopups?.showSignContractAndUpload === true && <Typography align="center">{t('Для перехода на следующий этап распечатайте, подпишите и отправьте нам договор')}</Typography>}
    </Modal>
  );
}
