// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import CheckIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions as partnerAsyncAction } from '../../../../engine/core/partners/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { accessList } from '../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../hooks/useAccessList';

function PartnerConfirmFormApproveButton(props) {
  const { handleSubmit, isDisplay, disabled } = props;
  const partnerHashIdConfirmPending = useSelector(selectors.partners.partnerHashIdConfirmPending);
  const dispatch = useDispatch();
  const partnerConfirmForm = useAccessList(accessList.partnerConfirmForm);
  const { hashId } = useParams();
  const { t } = useTranslation();
  const approvePartner = () => {
    if (hashId && typeof hashId === 'string') {
      dispatch(partnerAsyncAction.putPartnersHashIdConfirmForm({
        hashId,
        formData: {
          confirm: 1,
        },
      }));
    }
  };

  if (!partnerConfirmForm || !isDisplay) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Одобрить партнера')}
      disabled={disabled}
      isLoading={partnerHashIdConfirmPending}
      onClick={handleSubmit(approvePartner)}
    >
      <CheckIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

PartnerConfirmFormApproveButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isDisplay: PropTypes.bool,
};

export default PartnerConfirmFormApproveButton;
