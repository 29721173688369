// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';
// Engine
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';
import { actions as contentProductActions } from '../../../contentProduct/actions';

// Partial
import { formName } from '../../../../../ui/page/Products/Content/ContentProducts/components/ContentProductsSidebar/ProductDelete/form';
import { localData } from '../../../../config/localData';

export function* callDeleteProductsWorker({ payload }) {
  const { deleteNote, products } = payload;
  yield put(actions.setProductDeleteModal({ pending: true }));
  const contentProductsPageSendQuery = yield localData.getItem(localData.keysObj.contentProductsPageSendQuery);

  const response = yield apply(api, api.products.deleteProducts, [{ deleteNote, products }]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;
    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setProductDeleteModal({ isOpen: false }));
        yield put(reset(formName));
        yield put(contentProductActions.setSelection([]));
        if (contentProductsPageSendQuery) {
          yield put(contentProductActions.setSendQuery(true));
          yield put(contentProductActions.setReload(true));
        }
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setProductDeleteModal({ pending: false }));
}
