// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const priceCreatorActions = Object.freeze({
  mergePriceListUpload(data) {
    return getActionCreator(types.MERGE_PRICE_CREATOR_UPLOAD_STOP, data);
  },
  setPriceListDownloadPending(isLoading) {
    return getActionCreator(types.SET_PRICE_CREATOR_DOWNLOAD_PENDING, isLoading);
  },
  setUpdatePriceModalIsOpen(isOpen) {
    return getActionCreator(types.SET_PRICE_CREATOR_UPDATE_PRICE_MODAL_IS_OPEN, isOpen);
  },
});
