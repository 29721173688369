// Core
import React from 'react';
import * as PropTypes from 'prop-types';
// Components
import FormTextArea from '../../../../components/_Form/TextArea/FormTextArea';
import Label from '../../../../components/Label';

function FormTextAreaField(renderFieldProps) {
  const { required, label, ...rest } = renderFieldProps;

  return (
    <FormTextArea
      {...rest}
      fullWidth
      label={<Label label={label} required={required} />}
      margin="normal"
      multiline
      rows={5}
    />
  );
}

FormTextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormTextAreaField.defaultProps = {
  required: false,
};

export default FormTextAreaField;
