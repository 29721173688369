// Core
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Field, Form, initialize, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router';

// UI
import Grid from '@mui/material/Grid';
// Icons
import CallMissedIcon from '@mui/icons-material/CallMissed';
// parts
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SectionHolder from '../../../../containers/SectionHolder/SectionHolder';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import Loading from '../../../../components/Loading/Loading';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { selectors } from '../../../../../engine/config/selectors';
// helpers
import { getDate } from '../../../../../_helpers/getDate';
import { validators } from '../../../../../_helpers/validationsForm';
import TypeSelect from './components/TypeSelect';
import SynonymsInput from './components/SynonymsInput';
import { api } from '../../../../../engine/config/api';
import { setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { COLOR } from '../../../../containers/App/AppStyles';
import DeleteModal from '../components/DeleteModal';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import ErrorModalContent from '../components/ErrorModalContent';

export const formName = 'AddSynonym';

const formFields = {
  value: 'value',
  type: 'type',
  synonyms: 'synonyms',
};

function SynonymPage(props) {
  const { handleSubmit, valid, pristine } = props;
  const { hashId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [id, setId] = useState(undefined);
  const [canClean, setCanClean] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorsArray, setErrorsArray] = useState([]);
  const [userInfo, setUserInfo] = useState({ lastEdit: '', userName: '' });
  const reduxFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValues = reduxFormValues.toJS();

  const hasAccessToEdit = useAccessList(accessList.synonymsAdd);

  const handleCloseErrorModal = () => setErrorModal(false);

  const handleResponse = (response, message) => {
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(t(message), t('Успех')));
      navigate(pageLinks.synonyms.all);
    } else {
      setErrorModal(true);
      setErrorsArray(response.data?.errors);
    }
  };

  const onFormSubmit = async (formValues) => {
    setErrorsArray([]);
    setSendButtonDisabled(true);
    const data = formValues.toJS();
    const { type, value, synonyms } = data;
    if (hashId) {
      const response = await api.synonyms.editValue({
        value, type: type.value, synonyms, hashId,
      });
      handleResponse(response, 'Синоним изменен успешно');
    } else {
      const response = await api.synonyms.addValue({ value, type: type.value, synonyms });
      handleResponse(response, 'Синоним создан успешно');
    }
    setSendButtonDisabled(false);
  };

  useEffect(() => {
    (async () => {
      if (hashId) {
        setLoading(true);
        const response = await api.synonyms.getItem({ hashId });
        const {
          value, type, synonyms, id: responseId, updatedAt, user: { name },
        } = response.data;
        const formData = {
          value,
          type: { label: t(type), value: type },
          synonyms,
        };
        dispatch(initialize(formName, formData));
        setLoading(false);
        setId(responseId);
        setUserInfo({ lastEdit: updatedAt, userName: name });
      }
    })();
  }, [dispatch, hashId]);

  const openConfirmModal = () => {
    setIsModalOpen(true);
  };

  const generateTitle = () => {
    if (!hashId) {
      return t('Добавление синонимов');
    }
    if (hasAccessToEdit) {
      return `${t('Редактирование синонимов для')} ${normalizeFormValues.value ?? ''}`;
    }
    return `${t('Просморт синонимов для')} ${normalizeFormValues.value ?? ''}`;
  };


  return (
    <>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container justifyContent="center">
          <PageHeader title={generateTitle()}>
            {hashId && (
            <ButtonWithIconAndCircularProgress
              text={t('Удалить')}
              onClick={openConfirmModal}
              disabled={!hasAccessToEdit}
            >
              <DeleteIcon />
            </ButtonWithIconAndCircularProgress>
            )}
            <ButtonGoBack defaultPage={pageLinks.synonyms.all} />
            <ButtonWithIconAndCircularProgress
              text={t('Сохранить и вернуться')}
              isLoading={loading}
              disabled={hashId ? (pristine || sendButtonDisabled || !hasAccessToEdit) : (!valid || sendButtonDisabled)}
              onClick={handleSubmit}
              component="button"
              type="button"
            >
              <CallMissedIcon />
            </ButtonWithIconAndCircularProgress>
          </PageHeader>
          <Grid container xs={12} height="100%">
            <SectionHolder sx={{ flex: 1, height: '100%' }}>
              {loading ? (
                <Grid container justifyContent="center" alignContent="center">
                  <Loading isLoading />
                </Grid>
              ) : (
                <Grid container xs={12}>
                  {userInfo.lastEdit && (
                  <Grid item xs={12} gap="16px" sx={{ marginBottom: '30px' }}>
                    <Box sx={{ gap: '11px', display: 'flex', marginBottom: '16px' }}>
                      <Typography color={COLOR.grey[72]} component="span" sx={{ fontSize: '13px', lineHeight: '13px' }}>{t('Пользователь')}:</Typography>
                      <Typography component="span" sx={{ fontSize: '13px', lineHeight: '13px' }}>{userInfo.userName}</Typography>
                    </Box>
                    <Box sx={{ gap: '11px', display: 'flex' }}>
                      <Typography color={COLOR.grey[72]} component="span" sx={{ fontSize: '13px', lineHeight: '13px' }}>{t('Дата изменения')}:</Typography>
                      <Typography component="span" sx={{ fontSize: '13px', lineHeight: '13px' }}>{getDate(userInfo.lastEdit)}</Typography>
                    </Box>
                  </Grid>
                  )}
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 700 }}>{t('Основные значения')}</Typography>
                    <Field
                      name={formFields.value}
                      component={TextField}
                      fullWidth
                      validation
                      margin="normal"
                      required
                      isClearable={canClean}
                      onFocus={() => setCanClean(true)}
                      onBlur={() => setCanClean(false)}
                      setIsClearable={setCanClean}
                      label={t('Слово для связывания')}
                      validate={[validators.required]}
                      disabled={!hasAccessToEdit}
                    />
                    <Field
                      name={formFields.type}
                      component={TypeSelect}
                      isPortal={false}
                      closeMenuOnSelect
                      variant="outlined"
                      margin="normal"
                      validation
                      label={t('Тип характеристики')}
                      validate={[validators.required]}
                      disabled={!hasAccessToEdit}
                    />
                    <Divider sx={{ marginTop: '20px', marginBottom: '40px' }} />
                    <Typography sx={{ fontWeight: 700, marginBottom: '16px' }}>{t('Синонимы')}</Typography>
                    <Field
                      name={formFields.synonyms}
                      component={SynonymsInput}
                      isPortal={false}
                      closeMenuOnSelect={false}
                      isMulti
                      variant="outlined"
                      margin="normal"
                      validation
                      validate={[validators.required]}
                      disabled={!hasAccessToEdit}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{ marginTop: '48px', marginLeft: '8px', color: COLOR.grey[72] }}>
                      <Tooltip
                        title={<Box sx={{ whiteSpace: 'pre-line' }}>{t('Укажите название характеристики или ее значение, которое есть в Magento, для связывания с синонимами. Например: <br> "Цвет", "Состав", "Размер".')}</Box>}
                      >
                        <InfoOutlined />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </SectionHolder>
          </Grid>
        </Grid>
      </Form>
      <DeleteModal id={id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Modal
        modalOpen={errorModal}
        handleModalToggle={handleCloseErrorModal}
        handleModalSend={handleCloseErrorModal}
        sendText={t('Редактировать')}
        title={t('Синоним не сохранен')}
        modalMaxWidth="sm"
        buttonSendIcon={null}
      >
        <ErrorModalContent errorsArray={errorsArray} />
      </Modal>
    </>
  );
}

SynonymPage.propsType = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({ form: formName })(memo(SynonymPage));
