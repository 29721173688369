// Core
import { put } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const contentProductsPageSendQuery = yield localData.getItem(localData.keysObj.contentProductsPageSendQuery);
  const initialPageState = getInitialPageState(lists || {});

  if (contentProductsPageSendQuery) {
    yield put(actions.setSendQuery(true));
  }

  yield put(actions.setInitialState(initialPageState));
}
