// Core
import * as PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form, reduxForm,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { reset } from 'redux-form';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/partners/actions';
import { formName, formFields } from './form';
import DayField from './DaysFields/DaysField';
import { asyncActions } from '../../../../../../../engine/core/partners/saga/asyncActions';
import { COLOR } from '../../../../../../containers/App/AppStyles';

function validateSchedule(schedule) {
  const fixedFormData = schedule.toJS();
  if (Object.keys(fixedFormData).length === 0) {
    return true;
  }
  return !Object.values(fixedFormData).some(day => day?.enabled === true);
}
const sx = {
  fontSize: '13px',
  color: COLOR.grey['87'],
  lineHeight: '20px',
  marginTop: '0px',
  marginBottom: '0px',
};
function OrderProcessingModal(props) {
  const {
    handleSubmit, sendText, hashId, title, dirty, valid,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectors.partners.partnerOrderProcessingIsOpen);
  const pending = useSelector(selectors.partners.partnerOrderProcessingPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const disableButton = useMemo(() => validateSchedule(formValues), [formValues]);
  const disabledSend = !valid || !dirty || disableButton || pending;
  const handleModalClose = () => {
    dispatch(reset(formName));
    dispatch(actions.mergePartnerOrderProcessing({
      isModalOpen: false,
    }));
  };

  const onSubmit = (formData) => {
    const inputObject = formData.toJS();

    const outputObject = {
      days: {
        0: { enabled: false },
        1: { enabled: false },
        2: { enabled: false },
        3: { enabled: false },
        4: { enabled: false },
        5: { enabled: false },
        6: { enabled: false },
      },
    };
    Object.keys(inputObject).forEach((key) => {
      const dayNumber = parseInt(key.replace('day', ''), 10);
      const enabled = inputObject[key]?.enabled || false;
      const time = inputObject[key]?.time;

      outputObject.days[dayNumber] = { enabled, time };
    });
    dispatch(asyncActions.putPartnersOrderProcessingScheduleAsync({
      hashId,
      formData: {
        infoFieldsValues: {
          orderProcessingSchedule: {
            enabled: true,
            days: outputObject.days,
          },
        },
      },
    }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        title={title}
        modalOpen={isModalOpen}
        handleModalCancel={handleModalClose}
        handleModalToggle={handleModalClose}
        disabledSend={disabledSend}
        handleModalSend={handleSubmit(onSubmit)}
        sendText={sendText}
        buttonSendIcon={null}
        modalMaxWidth="sm"
      >
        <Box>
          <Typography variant="h5" sx={sx}>
            {t('Выберите время, до которого необходимо оформить заказ покупателю, чтобы он был обработан вашим магазином и отправлен в день заказа.')}
          </Typography>
          <DayField name={formFields.day0} label={t('Понедельник')} shortLabel={t('Пн')} />
          <DayField name={formFields.day1} label={t('Вторник')} shortLabel={t('Вт')} />
          <DayField name={formFields.day2} label={t('Среда')} shortLabel={t('Ср')} />
          <DayField name={formFields.day3} label={t('Четверг')} shortLabel={t('Чт')} />
          <DayField name={formFields.day4} label={t('Пятница')} shortLabel={t('Пт')} />
          <DayField name={formFields.day5} label={t('Суббота')} shortLabel={t('Сб')} />
          <DayField name={formFields.day6} label={t('Воскресенье')} shortLabel={t('Вс')} />
        </Box>
      </Modal>
    </Form>
  );
}

OrderProcessingModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hashId: PropTypes.string,
  title: PropTypes.string,
  sendText: PropTypes.string,
  dirty: PropTypes.bool,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: formName,
})(OrderProcessingModal);
