// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { modifyDataItem } from '../../utils';

export function* callGetListWorker({ payload: params }) {
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.orders.getList, [params]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    /** show partner sku as sku if it's not set on backend */
    const modifiedData = response.data.items.map(item => modifyDataItem(item));
    data = Object.assign(data, { items: modifiedData, totalCount: response.data.totalCount });
  }

  yield put(actions.setList(data));
}
