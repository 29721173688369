// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ArrowDownSorting(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 8 14" {...props}>
      <path d="M3.59513 13.8321C3.6482 13.8853 3.71125 13.9276 3.78067 13.9564C3.85009 13.9852 3.9245 14 3.99966 14C4.07482 14 4.14923 13.9852 4.21865 13.9564C4.28806 13.9276 4.35112 13.8853 4.40419 13.8321L7.83244 10.4039C7.93973 10.2966 8 10.1511 8 9.99936C8 9.84763 7.93973 9.70212 7.83244 9.59483C7.72515 9.48754 7.57963 9.42726 7.4279 9.42726C7.27618 9.42726 7.13066 9.48754 7.02337 9.59483L4.57103 12.0483L4.57103 0.857373C4.57103 0.705835 4.51084 0.560505 4.40368 0.453351C4.29653 0.346197 4.1512 0.285999 3.99966 0.285999C3.84812 0.285999 3.70279 0.346197 3.59564 0.453351C3.48848 0.560505 3.42829 0.705835 3.42829 0.857373L3.42829 12.0483L0.975948 9.59483C0.868659 9.48754 0.723144 9.42726 0.571415 9.42726C0.419686 9.42726 0.274171 9.48754 0.166882 9.59483C0.0595931 9.70211 -0.00068056 9.84763 -0.000680574 9.99936C-0.000680587 10.1511 0.059593 10.2966 0.166882 10.4039L3.59513 13.8321Z" fill="#303030" fillOpacity="0.72" />
    </SvgIcon>
  );
}
export default ArrowDownSorting;
