// Core
import { apply, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { api } from '../../../../config/api';
import { statisticsActions } from '../../action';
import { selectors } from '../../../../config/selectors';
import { uiActions } from '../../../ui/action';
import { localData } from '../../../../config/localData';

export function* callGetStatisticsWorker() {
  const response = yield apply(api, api.statistics.getStatistics);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { appReactHash, serverTime } = responseData;
    const usersRoleLastUpdate = get(responseData, 'lastUpdates.userRolesLastUpdate.updatedAt');
    const appReactHashFromStorage = yield select(selectors.statistics.appReactHash);
    const userRolesLastUpdateFromStorage = yield select(selectors.statistics.userRolesLastUpdate);
    const notificationsLastShow = yield localData.getItem(localData.keysObj.notificationsLastShow);
    if (!notificationsLastShow) {
      yield apply(
        localData,
        localData.setItem,
        [localData.keysObj.notificationsLastShow, serverTime],
      );
    }

    if (appReactHashFromStorage && appReactHash !== appReactHashFromStorage) {
      yield put(uiActions.mergeShouldRenderRefresh({
        showOverlay: process.env.REACT_APP_OVERLAY_REFRESH_PAGE_FROM_REACT_HASH === 'true',
        title: 'Обновилась версия приложения',
        clearLocalForage: false,
      }));
      yield put(statisticsActions.setStatistics({ ...responseData, appReactHash: appReactHashFromStorage }));
      return;
    }
    if (userRolesLastUpdateFromStorage && usersRoleLastUpdate !== userRolesLastUpdateFromStorage) {
      yield put(uiActions.mergeShouldRenderRefresh({
        showOverlay: true,
        title: 'Изменились данные Вашего профиля',
        clearLocalForage: true,
      }));
      yield put(statisticsActions.setStatistics(responseData));
      return;
    }

    yield put(statisticsActions.setStatistics(responseData));
  }
}
