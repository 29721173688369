// Core
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/categories/actions';
import { accessList } from '../../../../../../engine/config/access';
import i18n from '../../../../../../engine/init/i18n';
// Hooks
import { useCategoriesList } from '../../_hooks/useCategoriesList';
import { useAccessList } from '../../../../../hooks/useAccessList';
// Parts
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
import EditComponent from './components/EditComponent';
import RowComponent from './components/RowComponent';
// Helpers
import { tableColumns } from '../../../../../../_helpers/data/pages/handbook/categories/tableColumns';

function CategoriesTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = i18n.language;
  const isLoading = useSelector(selectors.categories.isLoading);
  const hasCategoryEdit = useAccessList(accessList.categoryEdit);
  const hasCategoryShow = useAccessList(accessList.categoryShow);
  const isShowEditComponent = hasCategoryEdit || hasCategoryShow;
  const [selectedRow, setSelectedRow] = useState([]);
  // Rows
  const rows = useSelector(selectors.categories.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.categories.currentPage);
  const pageSize = useSelector(selectors.categories.pageSize);
  const totalCount = useSelector(selectors.categories.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = (page) => {
    dispatch(actions.setCategoriesTreeDataRowIds([]));
    dispatch(actions.setCurrentPage(page));
  };
  // Sorting
  const onSortingChange = (currentSorting) => {
    dispatch(actions.setCategoriesTreeDataRowIds([]));
    dispatch(actions.setSorting(currentSorting));
  };
  const sorting = useSelector(selectors.categories.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.categories.settingStatus);
  // const settingPending = useSelector(selectors.categories.settingPending);
  // Filtering
  const filters = useSelector(selectors.categories.filters);
  const onFiltersChange = (newFilters) => {
    dispatch(actions.setCategoriesTreeDataRowIds([]));
    dispatch(actions.setFilters(newFilters));
  };

  // Tree Data
  const treeDataRowIds = useSelector(selectors.categories.treeDataRowIds);
  const onTreeDataRowIdsChange = (parentIds) => {
    dispatch(actions.setCategoriesTreeDataRowIds(parentIds));
  };

  const rowComponent = rowProps => <RowComponent {...rowProps} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />;

  const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r[[tableColumns.idMagentoCategoryParent.name]] === (row ? row[tableColumns.idMagentoCategory.name] : ''));

    if (childRows.length) {
      return childRows;
    }

    return row && row.hasChildren ? [] : null;
  };

  const categoriesList = useMemo(() => (
    !isEmpty(filters.toJS())
      ? rows.toJS()
      : (
        map(rows.toJS(), item => ({
          ...item,
          [tableColumns.idMagentoCategoryParent.name]: item[tableColumns.idMagentoCategoryParent.name] || '',
        }))
      )
  ), [filters, rows]);

  const columns = useMemo(() => {
    const valuesColumns = values(tableColumns);
    return filter(valuesColumns, column => column.name !== 'id')
      .map(i => ({
        ...i,
        title: t(`${i.title}`),
      }));
  }, [language]);

  return (
    <DxTable
      name="categories"
      // fixedColumn
      // showShortText
      isLoading={isLoading}
      rows={categoriesList}
      columns={columns}
      updateHooks={useCategoriesList}
      rowComponent={rowComponent}
      // edit
      editComponent={isShowEditComponent ? EditComponent : null}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Tree Data
      treeDataRowIds={filters.size === 0 ? treeDataRowIds : null}
      onTreeDataRowIdsChange={onTreeDataRowIdsChange}
      getChildRows={getChildRows}
      tableTreeColumnFor={tableColumns.idMagentoCategory.name}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      onFiltersChange={onFiltersChange}
      // Settings
      // gridSettings={gridSettings.categories.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default CategoriesTable;
