// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_MESSAGES_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_MESSAGES_CURRENT_PAGE, currentPage);
  },
  setReload(reload) {
    return getActionCreator(types.SET_MESSAGES_RELOAD, reload);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_MESSAGES_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_MESSAGES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_MESSAGES_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_MESSAGES_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_MESSAGES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_MESSAGES_SETTING_PENDING, settingPending);
  },
  mergeMessagesUserTypes(data) {
    return getActionCreator(types.MERGE_MESSAGES_USER_TYPES, data);
  },
  setMessageByHashId(message) {
    return getActionCreator(types.SET_MESSAGE_BY_HASH_ID, message);
  },
  mergeMessageRequestState(messageState) {
    return getActionCreator(types.MERGE_MESSAGE_REQUEST_STATE, messageState);
  },
  mergeMessageUserTypesByType(data) {
    return getActionCreator(types.MERGE_MESSAGES_USER_TYPES_BY_TYPE, data);
  },
  mergeMessageUserTypesByTypeItems(data) {
    return getActionCreator(types.MERGE_MESSAGES_USER_TYPES_BY_TYPE_ITEMS, data);
  },
  mergeMessagesStatuses(data) {
    return getActionCreator(types.MERGE_MESSAGES_STATUSES, data);
  },
  mergeRecipientsImport(data) {
    return getActionCreator(types.MERGE_RECIPIENTS_IMPORT, data);
  },
  mergeRecipientsImportItems(data) {
    return getActionCreator(types.MERGE_RECIPIENTS_IMPORT_ITEMS, data);
  },
  mergePartnersNotFoundModal(data) {
    return getActionCreator(types.MERGE_PARTNERS_NOT_FOUND_MODAL, data);
  },
});
