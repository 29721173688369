// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
// Helpers
import { getFileFromBlob } from '../../../../../../_helpers/getFileFromBlob';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callGetFeedsExportWorker({ payload: { hashId, forceAction } }) {
  yield put(actions.mergeExport({ [hashId]: true }));

  const response = yield apply(api, api.feeds.getExport, [{ hashId, forceAction }]);

  if (response && response.status >= 200 && response.status < 400) {
    let responseData;

    switch (typeof response.data) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, message, needForceAction } = responseData;
        if (needForceAction) {
          yield put(actions.setExportForceAction({ hashId, forceAction: needForceAction ? 1 : 0 }));
        } else {
          yield put(setErrorMessage(errors || message, statusTitle));
        }
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        if (forceAction) {
          yield put(actions.setExportForceAction({}));
        }
        break;
      }

      default: {
        getFileFromBlob('feeds.export.xls', response);
        break;
      }
    }
  }

  yield put(actions.mergeExport({ [hashId]: false }));
}
