// Core
import { apply } from 'redux-saga/effects';
// Engine
import { localData } from '../../../../../config/localData';

export function* callSetMessageToLocalWorker({ payload: { chatId, currentMessage, multipleModeContext } }) {
  let filesData = yield apply(localData, localData.getItem, [multipleModeContext]);

  // localForage пустой
  if (filesData === null) {
    filesData = {
      [chatId]: {
        currentMessage,
      },
    };
    // Найден текущий чат и в нем уже есть сохраняемые данные
  } else if (filesData[chatId] && filesData[chatId].currentMessage) {
    filesData = {
      ...filesData,
      [chatId]: {
        ...filesData[chatId],
        currentMessage,
      },
    };
    // Другой чат или тот же, но в нем лежат другие данные
  } else {
    filesData = {
      ...filesData,
      [chatId]: {
        ...filesData[chatId],
        currentMessage,
      },
    };
  }
  yield apply(localData, localData.setItem, [multipleModeContext, filesData]);
}
