// Core
import React, { useEffect, memo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Select from '../../../_Form/Selects/Select/Select';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncAction as mailAsyncActions } from '../../../../../engine/core/mail/saga/asyncAction';

function LetterTemplateRolesFilter(props) {
  const mailTemplatesRoles = useSelector(selectors.mail.mailTemplatesRoles);
  const dispatch = useDispatch();
  const { filters, changeSelectFilter } = props;
  const input = {
    value: filters && filters.value,
    onChange: changeSelectFilter,
  };

  useEffect(() => {
    dispatch(mailAsyncActions.getMailTemplatesRoles());
  }, [dispatch]);

  return (
    <Select
      options={mailTemplatesRoles}
      label=""
      input={input}
    />
  );
}

LetterTemplateRolesFilter.propsType = {
  changeSelectFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default memo(LetterTemplateRolesFilter);
