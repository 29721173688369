import { getIn, List, Map } from 'immutable';
import { stateKeys as transactionsPaymentKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const transactionsPayment = Object.freeze({
  currentPage: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.currentPage], 0),
  filters: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.filters], List()),
  isLoading: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.pending], false),
  list: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.items], List()),
  transactionPayment: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionPayment], Map()),
  transactionPaymentData: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionPayment, transactionsPaymentKeys.data], Map()),
  pageSize: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.reload], false),
  totalCount: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.totalCount], 0),
  sorting: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.sorting], List()),
  settingStatus: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.settingStatus], false),
  settingPending: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentList, transactionsPaymentKeys.settingPending], false),
  putTransactionPayment: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.putTransactionPayment], Map()),
  transactionsPaymentStatuses: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentStatuses], Map()),
  transactionsPaymentStatusesPending: state => getIn(state, [transactionsPaymentKeys.controller, transactionsPaymentKeys.transactionsPaymentStatuses, transactionsPaymentKeys.pending], false),
});
