export const types = Object.freeze({
  MERGE_RECONCILIATION: 'MERGE_RECONCILIATION',
  SET_REPORTS_LIST: 'SET_REPORTS_LIST',
  SET_REPORTS_CURRENT_PAGE: 'SET_REPORTS_CURRENT_PAGE',
  SET_REPORTS_FILTERS: 'SET_REPORTS_FILTERS',
  SET_REPORTS_PAGE_SIZE: 'SET_REPORTS_PAGE_SIZE',
  SET_REPORTS_SORTING: 'SET_REPORTS_SORTING',
  SET_REPORTS_INITIAL_STATE: 'SET_REPORTS_INITIAL_STATE',
  SET_REPORTS_SETTING_STATUS: 'SET_REPORTS_SETTING_STATUS',
  SET_REPORTS_SETTING_PENDING: 'SET_REPORTS_SETTING_PENDING',
  SET_REPORTS_RELOAD: 'SET_REPORTS_RELOAD',
});
