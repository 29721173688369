// Core
import React from 'react';
import { Navigate } from 'react-router-dom';
// Components
import CategoryEdit from './CategoryEdit/CategoryEdit';
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';

function CategoryShowPage() {
  const hasAccessToThePage = useAccessList(accessList.categoryEdit);
  const hasAccessShowPage = useAccessList(accessList.categoryShow);

  if (hasAccessToThePage || hasAccessShowPage) {
    return <CategoryEdit mode="show" />;
  }

  return <Navigate to={pageLinks.categories.all} />;
}

export default CategoryShowPage;
