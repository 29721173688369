// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ChartBarIcon(props) {
  return (
    <SvgIcon className="chart-bar-icon" fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <path d="M22 21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z" fill="white" />
    </SvgIcon>
  );
}


export default ChartBarIcon;
