// Core
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { change } from 'redux-form';
import { Map } from 'immutable';
import isEmpty from 'lodash/isEmpty';
// Parts
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormSelect from '../../../../../../components/_Form/Selects/FormSelect/FormSelect';
import Select from '../../../../../../components/_Form/Selects/Select/Select';
import PartnerMultipleField from './PartnerMultipleField';
import ControlButtons from './ControlButtons/ControlButtons';
// Engine
import { asyncActions } from '../../../../../../../engine/core/messages/saga/asyncActions';
import { actions } from '../../../../../../../engine/core/messages/actions';
import { selectors } from '../../../../../../../engine/config/selectors';
// Hooks
import { useMessageReadOrEditPage } from '../../../_hooks/useMessageReadOrEditPage';
import { useOptionsTypesSelectControl } from './_hooks/useOptionsTypesControl';
import { useCurrentTypeGroup } from './_hooks/useCurrentTypeGroup';
import { useValuesByType } from './_hooks/useValuesByType';
// Helpers
import { recipientsTypes } from '../../../_helpers/recipientsTypes';
import { messageFormName } from '../../form';


function RenderTypField(props) {
  const {
    member, userGroupPending,
    isReadMessage, changeValue,
  } = props;
  const { t } = useTranslation();
  const { optionsTypesSelect, isDisabledTypesSelect } = useOptionsTypesSelectControl();

  return (
    <Field
      fullWidth
      validation
      name={`${member}.type`}
      disabled={userGroupPending || isReadMessage || isDisabledTypesSelect}
      isLoading={userGroupPending}
      component={FormSelect}
      closeMenuOnSelect
      handleChange={changeValue}
      margin="none"
      options={optionsTypesSelect}
      label={t('Тип получателей')}
    />
  );
}

RenderTypField.propTypes = {
  member: PropTypes.string.isRequired,
  userGroupPending: PropTypes.bool.isRequired,
  isReadMessage: PropTypes.bool.isRequired,
  changeValue: PropTypes.func.isRequired,
};

function RecipientsGroupFields(props) {
  const {
    member, index, fields,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isReadMessage } = useMessageReadOrEditPage();
  const { currentType } = useCurrentTypeGroup(index);
  const { valuesByType } = useValuesByType(index);
  const userGroupPending = useSelector(selectors.messages.userGroupPending);
  const messageUserTypesByTypeItems = useSelector(selectors.messages.messageUserTypesByTypeItems);
  const messageUserTypesByTypePending = useSelector(selectors.messages.messageUserTypesByTypePending);
  const [currentPending, setCurrentPending] = useState(false);
  const { currentPartner } = recipientsTypes;

  const changeValue = (value) => {
    const items = messageUserTypesByTypeItems.toJS();

    if (value !== currentPartner && (isEmpty(items) || !isEmpty(items) && !items[value])) {
      setCurrentPending(true);
      dispatch(asyncActions.getMessageUserTypesByTypeAsync(value));
    }

    if (currentType && currentType !== value) {
      dispatch(change(messageFormName, `${member}.values`, []));

      if (value === currentPartner) {
        dispatch(actions.mergeRecipientsImport({
          items: Map(),
        }));
      }
    }
  };

  useEffect(() => {
    if (!messageUserTypesByTypePending && currentPending) {
      setCurrentPending(false);
    }
  }, [
    setCurrentPending, currentPending,
    messageUserTypesByTypePending,
  ]);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} sx={{ marginBottom: '16px' }}>
          <RenderTypField
            member={member}
            userGroupPending={userGroupPending}
            isReadMessage={Boolean(isReadMessage)}
            changeValue={changeValue}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ marginBottom: '16px' }}>
          <Box display="flex" alignItems="flex-start">
            {currentType === currentPartner
              ? (
                <PartnerMultipleField index={index} />
              )
              : (
                <Box sx={{ flexGrow: 1, width: '50%' }}>
                  <Field
                    isLoading={currentPending && messageUserTypesByTypePending}
                    disabled={currentPending && messageUserTypesByTypePending || isReadMessage}
                    component={Select}
                    closeMenuOnSelect={false}
                    isMulti
                    isClearable
                    variant="outlined"
                    name={`${member}.values`}
                    options={valuesByType}
                    label={t('Группа получателей')}
                    placeholder=""
                  />
                </Box>
              )
            }
            <ControlButtons
              currentType={currentType}
              currentPartner={currentPartner}
              index={index}
              fields={fields}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

RecipientsGroupFields.propTypes = {
  fields: PropTypes.object.isRequired,
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default RecipientsGroupFields;
