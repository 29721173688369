// Core
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/feeds/attributeSet/actions';
// Hooks
import { asyncActions } from '../../../../../engine/core/feeds/attributeSet/saga/asyncActions';
import { useAttributeSetList } from '../_hooks/useAttributeSetList';
// helper
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';

// Redux
function AttributeSetTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.attributeSet.isLoading);
  // Rows
  const rows = useSelector(selectors.attributeSet.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.attributeSet.currentPage);
  const pageSize = useSelector(selectors.attributeSet.pageSize);
  const totalCount = useSelector(selectors.attributeSet.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.attributeSet.sorting);
  // Settings
  const settingStatus = useSelector(selectors.attributeSet.settingStatus);
  const settingPending = useSelector(selectors.attributeSet.settingPending);
  // Filtering
  const filters = useSelector(selectors.attributeSet.filters);

  const gridSettingsModification = useMemo(() => {
    const grid = gridSettings.feedAttributeSetManagement.gridSettings;

    return map(grid, setting => ({
      ...setting,
      ...(setting.type === tableDataTypes.contentManager.type ? {
        filter: false,
      } : {}),
    }));
  }, [gridSettings]);

  return (
    <DxTable
      name="feedAttributeSetManagement"
      fixedColumn
      showShortText
      isLoading={isLoading}
      hasEditableCell
      rows={rows}
      updateHooks={useAttributeSetList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettingsModification}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(AttributeSetTable);
