// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Helpers
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

export function* callGetProductsImportTemplatesDownloadWorker({ payload: { categoryId } }) {
  yield put(actions.setProductsImportTemplatesDownloadState({ [categoryId]: true }));

  const response = yield apply(api, api.contentTemplates.getProductsImportTemplatesDownload, [{ categoryId }]);

  if (response && response.status >= 200 && response.status < 400) {
    getFileFromBlob('product.import.example.xls', response);
  }

  yield put(actions.setProductsImportTemplatesDownloadState({ [categoryId]: false }));
}
