export const asyncTypes = Object.freeze({
  PUT_SERVICE_DESK_SETTINGS_ASYNC: 'PUT_SERVICE_DESK_SETTINGS_ASYNC',
  GET_SERVICE_DESK_LIST_ASYNC: 'GET_SERVICE_DESK_LIST_ASYNC',
  GET_SERVICE_DESK_STATUSES_LIST_ASYNC: 'GET_SERVICE_DESK_STATUSES_LIST_ASYNC',
  GET_SERVICE_DESK_INITIAL_STATE_ASYNC: 'GET_SERVICE_DESK_INITIAL_STATE_ASYNC',
  PUT_SERVICE_DESK_ITEM_BY_HASH_ID_ASYNC: 'PUT_SERVICE_DESK_ITEM_BY_HASH_ID_ASYNC',
  SET_SERVICE_DESK_FILES_IN_CHAT_MESSAGE_ASYNC: 'PUT_SERVICE_DESK_FILES_IN_CHAT_MESSAGE_ASYNC',
  DELETE_SERVICE_DESK_FILE_IN_CHAT_MESSAGE_ASYNC: 'DELETE_SERVICE_DESK_FILE_IN_CHAT_MESSAGE_ASYNC',
  GET_SERVICE_DESK_MESSAGES_ASYNC: 'GET_SERVICE_DESK_MESSAGES_ASYNC',
  PUT_SERVICE_DESK_MESSAGE: 'PUT_SERVICE_DESK_MESSAGE',
  POST_SERVICE_DESK_CLOSE_QUESTION_ASYNC: 'POST_SERVICE_DESK_CLOSE_QUESTION_ASYNC',
  POST_SERVICE_DESK_CONTINUE_QUESTION_ASYNC: 'POST_SERVICE_DESK_CONTINUE_QUESTION_ASYNC',
  SET_SERVICE_DESK_MESSAGE_TO_LOCAL: 'SET_SERVICE_DESK_MESSAGE_TO_LOCAL',
  SET_SERVICE_DESK_INIT_LOCAL: 'SET_SERVICE_DESK_INIT_LOCAL',
  GET_SERVICE_DESK_CURRENT_MSG_FROM_LOCAL: 'GET_SERVICE_DESK_CURRENT_MSG_FROM_LOCAL',
});
