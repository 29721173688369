// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';

// Engine
import { actions } from '../../actions';

import { api } from '../../../../../config/api';
import { setSuccessMessage, setErrorMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
// Const
import { formName } from '../../../../../../ui/page/Partners/components/PartnersManager/components/PartnersManagersImportModal';

export function* callPostUploadPartnerManagerWorker({ payload: { file } }) {
  yield put(actions.mergePartnerManagerUpload({ pending: true }));
  const response = yield apply(api, api.partnersManagers.getPartnerManagerImport, [{ file }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const {
      errors, status, message, statusTitle,
    } = responseData;
    yield put(reset(formName));

    switch (status) {
      case 'error': {
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case 'success': {
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.mergePartnerManagerUpload({ isModalOpen: false }));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergePartnerManagerUpload({ pending: false }));
}
