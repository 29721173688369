// Core
import {
  apply, fork, put, select,
} from 'redux-saga/effects';
import get from 'lodash/get';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../../user/action';
import { selectors } from '../../../../config/selectors';
import { partnerActions } from '../../action';
import { callSetPartnersFiltersWorker } from './setPartnersFilters';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutPartnersSettingsWorker({ payload }) {
  if (!payload) { // If change user settings
    const partnerSettingStatus = yield select(selectors.partner.settingStatus);

    if (!partnerSettingStatus) {
      yield put(partnerActions.setPartnersListSettingsStatus(true));
    } else {
      yield put(partnerActions.setPartnersListSettingsPending(true));
      const userSetting = yield select(selectors.user.userSettings);
      const gridSettings = get(userSetting, 'partner.gridSettings', []);

      const response = yield apply(api, api.user.putUserSettings, [{ partner: { gridSettings } }]);

      if (response && response.status >= 200 && response.status < 400) {
        const data = response.data;
        const { status, statusTitle } = data;

        switch (status) {
          case 'success': {
            const { message } = data;
            yield put(setSuccessMessage(message, statusTitle));
            // https://jira.forforce.com/browse/AMP-1100
            yield fork(callSetPartnersFiltersWorker, userSetting);
            yield put(partnerActions.setPartnersListSettingsStatus(false));

            break;
          }
          case 'error': {
            const { errors } = data;
            yield put(setErrorMessage(errors, statusTitle));
            break;
          }
          default: {
            break;
          }
        }
      }

      yield put(partnerActions.setPartnersListSettingsPending(false));
    }
  } else { // If have context (reset: _name_)
    yield put(partnerActions.setPartnersListSettingsPending(true));

    const response = yield apply(api, api.user.putUserSettings, [payload]);

    if (response && response.status >= 200 && response.status < 400) {
      const data = response.data;
      const { status, statusTitle } = data;

      switch (status) {
        case 'success': {
          const { message, settings } = data;
          yield put(userActions.mergeUserSettings(settings));
          yield put(setSuccessMessage(message, statusTitle));
          // https://jira.forforce.com/browse/AMP-1100
          yield fork(callSetPartnersFiltersWorker, settings);
          break;
        }
        case 'error': {
          const { errors } = data;
          yield put(setErrorMessage(errors, statusTitle));
          break;
        }
        default: {
          break;
        }
      }
    }

    yield put(partnerActions.setPartnersListSettingsPending(false));
  }
}
