const ProfilePartnerStyle = theme => ({
  // Global Style
  tabsContainer: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  tabsContent: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  tableHeaderCellInput: { width: '10%', minWidth: 170 },
  // Page Style
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
  },
  uploadLogo: {
  },
  description: {
    fontSize: 12,
    marginTop: 0,
    color: '#777',
  },
  selectWrapper: {
    width: '100%',
  },
  categoryItem: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  // Tab: Contract
  selectStatusContractWrapper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  selectStatusContractLabel: {
    transform: 'translate(13px, -4.5px) scale(0.75)',
  },
  documentWrapperForRef: {
    display: 'flex',
    justifyContent: 'center',
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: 'auto',
  },
  documentSectionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 55,
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      marginTop: 40,
    },
    [theme.breakpoints.only('md')]: {
      marginTop: 50,
    },
  },
  uploadDocumentWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  uploadDocumentName: {
    marginRight: 16,
  },
  formGroup: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '@global .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  formLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  formLabelDefault: {
    '@global .MuiFormControlLabel-label': {
      fontSize: '16px !important',
    },
  },
  inputLike: {
    paddingBottom: 7,
    paddingTop: 6,
  },
  percentHeader: {
    lineHeight: '1.1875em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  percentHeaderContainer: {
    height: '100%',
    marginTop: theme.spacing(1),
  },
  // TabsPayment
  paymentCheckBox: {
    display: 'inline',
    position: 'relative',
    top: 4,
  },
});

export default ProfilePartnerStyle;
