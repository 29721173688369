// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { billingActions } from '../../action';

export function* callGetDashboardRatingWorker() {
  yield put(billingActions.setRatingStatus({ pending: true }));
  const response = yield apply(api, api.dashboards.getDashboardsRating);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    yield put(billingActions.setRatingStatus({
      ...responseData,
      pending: false,
    }));
  }
}
