// Core
import { reset } from 'redux-form';
import { apply, put, select } from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';
import { asyncActions } from '../asyncActions';
import { actions } from '../../actions';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { formName } from '../../../../../../ui/page/StopList/BrandsCategories/components/StopBrandCategoriesModal/form';
import { stopListBrandsCategories } from '../../selectors';

export function* callPostWorker({ payload }) {
  yield put(actions.mergeAdd({ pending: true }));
  const response = yield apply(api, api.stopLists.postBrandsCategories, [payload]);
  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));
        const pageSize = yield select(stopListBrandsCategories.pageSize);
        const sorting = (yield select(stopListBrandsCategories.sorting)).toJS();
        const filters = (yield select(stopListBrandsCategories.filters)).toJS();

        const params = {
          sorting,
          limit: pageSize,
        };

        filters.reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, params);
        yield put(asyncActions.getListAsync(params));
        // formName url
        yield put(reset(formName));
        yield put(actions.mergeAdd({ isModalOpen: false }));
        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeAdd({ pending: false }));
}
