// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';

export function* callGetFeedsPrioritiesListWorker({ payload: params }) {
  yield put(actions.mergeFeedsStatusesList({ pending: true }));
  const response = yield apply(api, api.feeds.getFeedsPrioritiesList, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeFeedsPrioritiesList({
      items: List(response.data),
      pending: false,
    }));
  }
}
