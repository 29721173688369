// Core
import React from 'react';

function FileXmlIcon(props) {
  return (
    <svg width="29" height="38" viewBox="0 0 29 38" fill="none" fontSize="inherit" {...props}>
      <g clipPath="url(#clip0_1905_331)">
        <path d="M21.025 0L29 9.12V36.86C29 37.4908 28.4887 38 27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V1.14C0 0.5092 0.511316 0 1.14474 0H21.025Z" fill="#EBE8E1" />
        <path d="M21.025 0V8.74L29 9.12L21.025 0Z" fill="#D9D7CA" />
        <path d="M27.8553 38H1.14474C0.511316 38 0 37.4908 0 36.86V26.22H29V36.86C29 37.4908 28.4887 38 27.8553 38Z" fill="#4D9E57" />
        <path d="M5 35L7.23398 31.869L5.20958 29H6.75241L8.06337 30.9277L9.34757 29H10.877L8.8437 31.9141L11.0777 35H9.4858L8.03661 32.925L6.58296 35H5Z" fill="white" />
        <path d="M11.7376 35V29H13.713L14.8991 33.0928L16.0718 29H18.0516V35H16.8254V30.2769L15.5278 35H14.257L12.9639 30.2769V35H11.7376Z" fill="white" />
        <path d="M19.3983 35V29.0491H20.7181V33.9891H24V35H19.3983Z" fill="white" />
        <path d="M15.5933 11L16.9 11.2667L14.4067 23L13.1 22.7333L15.5933 11ZM20.06 17L17.6667 14.6067V12.72L21.9467 17L17.6667 21.2733V19.3867L20.06 17ZM8.05334 17L12.3333 12.72V14.6067L9.94001 17L12.3333 19.3867V21.2733L8.05334 17Z" fill="#303030" fillOpacity="0.72" />
      </g>
      <path d="M5 35L7.23398 31.869L5.20958 29H6.75241L8.06337 30.9277L9.34757 29H10.877L8.8437 31.9141L11.0777 35H9.4858L8.03661 32.925L6.58296 35H5Z" fill="white" />
      <path d="M11.7376 35V29H13.713L14.8991 33.0928L16.0718 29H18.0516V35H16.8254V30.2769L15.5278 35H14.257L12.9639 30.2769V35H11.7376Z" fill="white" />
      <path d="M19.3983 35V29.0491H20.7181V33.9891H24V35H19.3983Z" fill="white" />
      <defs>
        <clipPath id="clip0_1905_331">
          <rect width="29" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FileXmlIcon;
