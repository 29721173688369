// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
// Parts
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
// Engine
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/actions';
import EditableContainer
  from '../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import { accessList } from '../../../../../../../engine/config/access';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';
import { useAccessList } from '../../../../../../hooks/useAccessList';

function TableCell(props) {
  const {
    column,
    row,
    value,
    ...restProps
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessEditPage = useAccessList(accessList.categoriesFeatureManagementEdit);
  const hasAccessShowPage = useAccessList(accessList.categoriesFeatureManagementShow);

  const isLoading = useSelector(selectors.categoriesFeaturesItem.isLoading);
  const featureRequiresLK = useSelector(selectors.categoriesFeaturesItem.featureRequiresLK).toJS();
  const isChecked = featureRequiresLK.find(i => i.id === row.id) !== undefined
    ? featureRequiresLK.find(i => i.id === row.id)?.requiredLk
    : row?.requiredLk;

  const handleCheckbox = () => {
    const isWriting = featureRequiresLK.find(i => i.id === row.id);

    if (isWriting === undefined) {
      dispatch(actions.setFeatureRequiresLK([
        ...featureRequiresLK,
        {
          ...row,
          requiredLk: !row.requiredLk,
        },
      ]));
    } else {
      const result = featureRequiresLK.filter(i => i.id !== row.id);
      dispatch(actions.setFeatureRequiresLK(result));
    }
  };


  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === tableDataTypes.featureName.name && <EditableContainer isEditable={false} text={value}>{value}</EditableContainer> }
      {column.name === tableDataTypes.updatedAtRequiredLk.name && (
        <EditableContainer
          isEditable={false}
          text={value}
          hashId={row.hashId}
          name={column.name}
        >{value}
        </EditableContainer>
      ) }
      {column.name === tableDataTypes.featureRequiredMg.name && (
        <Chip
          label={<Trans>{value ? 'Да' : 'Нет'}</Trans>}
          variant="outlined"
        />
      ) }
      {column.name === tableDataTypes.featureRequiredLk.name && (
        <Chip
          label={<Trans>{value ? 'Да' : 'Нет'}</Trans>}
          variant="outlined"
        />
      )}
      {column.name === tableDataTypes.featureRequiredLkValue.name && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={isChecked ? t('Эта характеристика сейчас обязательна для заполнения') : t('Эта характеристика сейчас не обязательна для заполнения')}>
            <FormControlLabel
              control={(
                <Switch
                  id={row.id}
                  name={`${row.id}_switch`}
                  disabled={(hasAccessShowPage && !hasAccessEditPage) || isLoading}
                  checked={isChecked}
                  onChange={handleCheckbox}
                />
          )}
              componentsProps={{ typography: { sx: { fontSize: '14px' } } }}
            />
          </Tooltip>
        </Box>
      )}
    </VirtualTable.Cell>
  );
}

export default memo(TableCell);
