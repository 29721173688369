// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../engine/core/faq/saga/asyncActions';
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// Data
import { requestDelay } from '../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../_helpers/convertDataToUrlParams';

export const faqStatuses = {
  new: 0,
  review: 1,
  published: 2,
};

export function useFaqList(hashId, refresh) {
  const currentPage = useSelector(selectors.faqs.currentPage);
  const limit = useSelector(selectors.faqs.pageSize);
  const filters = useSelector(selectors.faqs.filters);
  const sorting = useSelector(selectors.faqs.sorting);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const reload = useSelector(selectors.faqs.reload);
  const hasAccessList = useAccessList(accessList.faqQuestionList);
  const offset = limit * currentPage;

  const paramsByFilter = useMemo(() => {
    const params = {};

    switch (path) {
      case pageLinks.faq.new: {
        params.status = [{ value: faqStatuses.new }];
        break;
      }
      case pageLinks.faq.review: {
        params.status = [{ value: faqStatuses.review }];
        break;
      }
      case pageLinks.faq.published: {
        params.status = [{ value: faqStatuses.published }];
        break;
      }
      default: {
        break;
      }
    }

    return filters.toJS().reduce((acc, { columnName, value }) => {
      if (!Object.prototype.hasOwnProperty.call(params, columnName)) {
        acc[columnName] = value;
      }
      return acc;
    }, params);
  }, [filters, path]);

  const getFaqsListAsync = useCallback(() => {
    if (hasAccessList) {
      const params = {
        ...paramsByFilter,
        orderBy: convertSortingObjectToUrlParams(sorting.toJS()),
        limit,
        offset,
      };
      dispatch(asyncActions.getFaqListAsync(params));
    }
  }, [
    hasAccessList, paramsByFilter,
    offset, sorting, limit, dispatch,
  ]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    getInitialState.current = true;
  }, [routeKey]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      dispatch(asyncActions.getFaqInitialStateAsync(path));
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getFaqsListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [
    reload, refresh, timeout, routeKey, getFaqsListAsync,
  ]);
}
