import React, { useEffect, useState } from 'react';
import {
  reduxForm, Form, Field, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// UI
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Done } from '@mui/icons-material';
import { COLOR } from '../../containers/App/AppStyles';
// Engine
import { userActionAsync } from '../../../engine/core/user/saga/asyncAction';
// Hooks
import ViberIcon from '../../icons/Viber';
import { IconButtonStyles } from '../../components/_Table/TableCell/Styles';
import InfoOutlinedIcon from '../../icons/InfoOutlined';
import { phoneMask, validators } from '../../../_helpers/validationsForm';
import PenMenuOutlinedIcon from '../../icons/PenMenuOutlined';
import TextField from '../../components/_Form/TextField/TextField/TextField';
import { formFields } from '../User/UserSelf';
import { selectors } from '../../../engine/config/selectors';
import { styles } from './styles';

export const formName = 'profileSettings';

function ViberSettings(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [showEditButton, setShowEditButton] = useState(true);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const isDisableDeleteButton = !formValues.get(formFields.viber) || formValues.get(formFields.viber) === '380';

  const handleChange = (input, event) => {
    const { value } = event.target;
    const numberLength = value.replace(/\D/g, '').length;
    if (numberLength > 2) {
      input.onChange(event);
    }
  };

  const onSubmit = (formData) => {
    const updatedViberData = { viber: formData.get(formFields.viber) };
    if (formData && updatedViberData) {
      dispatch(userActionAsync.putUserInfoAsync(updatedViberData));
    }
    setShowEditButton(true);
  };

  const handleDeleteViber = () => {
    if (!isDisableDeleteButton) {
      dispatch(userActionAsync.putUserInfoAsync({ viber: '' }));
      dispatch(change(formName, formFields.viber, '380'));
    }
  };

  useEffect(() => {
    dispatch(userActionAsync.getUsersSelfAsync());
  }, []);

  const onClearField = () => {
    dispatch(change(formName, formFields.viber, '380'));
  };


  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Box
        sx={styles.root}
      >
        <Box>
          <Box sx={styles.header}>
            <Box
              sx={styles.viberContainer}
            >
              <ViberIcon sx={{ height: 18, width: 18 }} />
            </Box>
            <Typography sx={{
              color: COLOR.grey.main, fontWeight: '700', display: 'flex', alignItems: 'center', fontSize: '14px',
            }}
            >{t('Сообщения')} Viber/SMS
            </Typography>
            <Tooltip
              title={t('Сообщения о поступлении новых заказов')}
            >
              <IconButtonStyles
                color="inherit"
                disabled={false}
                onClick={() => null}
                size="large"
                sx={styles.infoIcon}
              >
                <InfoOutlinedIcon sx={{ height: 18, width: 18 }} />
              </IconButtonStyles>
            </Tooltip>
          </Box>
          <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
          <Box sx={{
            ...styles.inputContainer,
            [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
          }}
          >
            <Box sx={{ width: '320px' }}>
              <Field
                name={formFields.viber}
                component={TextField}
                autoFocus
                customOnChange={handleChange}
                label="Viber/SMS"
                margin="normal"
                disabled={showEditButton}
                validation
                fullWidth
                type="tel"
                validate={validators.viber}
                endAdornment={!showEditButton ? (
                  <InputAdornment position="end">
                    <IconButton
                      type="button"
                      onClick={onClearField}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null}
                {...phoneMask}
              />
            </Box>
            <Box sx={{
              ...styles.iconsContainer,
              [theme.breakpoints.down('sm')]: {
                alignSelf: 'end',
                marginTop: '0px',
              },
            }}
            >
              {showEditButton ? (
                <>
                  <IconButtonStyles
                    onClick={() => setShowEditButton(false)}
                    text={t('Редактировать')}
                    sx={{
                      ...styles.button,
                      marginRight: '4px',
                    }}
                  >
                    <PenMenuOutlinedIcon />
                  </IconButtonStyles>
                  <IconButtonStyles
                    disabled={isDisableDeleteButton}
                    onClick={handleDeleteViber}
                    text={t('Удалить')}
                    sx={{
                      ...styles.button,
                      '&.Mui-disabled': {
                        backgroundColor: COLOR.grey[8],
                      },
                    }}
                  >
                    <DeleteOutlineIcon style={{ color: COLOR.error }} />
                  </IconButtonStyles>
                </>
              ) : (
                <IconButtonStyles
                  disabled={false}
                  onClick={handleSubmit(onSubmit)}
                  text={t('Сохранить')}
                  sx={styles.button}
                >
                  <Done style={{ color: COLOR.success }} />
                </IconButtonStyles>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Form>
  );
}

ViberSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formName,
})(ViberSettings);
