// Core
import { memo } from 'react';
// Parts
import ControlButtonsAddMulti from './components/ControlButtonsAddMulti';
import ControlButtonsRemove from './components/ControlButtonsRemove';
import ControlButtonsNotFoundMulti from './components/ControlButtonsNotFoundMulti';
import { ButtonGroupWrapper } from './styles';

function ControlButtons(props) {
  const {
    currentType, fields, index,
    currentPartner,
  } = props;

  if (fields.length === 1 && currentType !== currentPartner) {
    return null;
  }
  return (
    <>
      <ButtonGroupWrapper size="small">
        {currentType === currentPartner && (
          <>
            <ControlButtonsNotFoundMulti />
            <ControlButtonsAddMulti type={currentType} />
          </>
        )}
        <ControlButtonsRemove index={index} fields={fields} />
      </ButtonGroupWrapper>
    </>
  );
}

export default memo(ControlButtons);
