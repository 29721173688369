// Core
import React, { memo } from 'react';
import {
  bool, func, node, object, string,
} from 'prop-types';

// UI
import CircularProgress from '@mui/material/CircularProgress';

// Containers
import ButtonWithIcon from './ButtonWithIcon/ButtonWithIcon';
import { COLOR, BUTTON_SIZE } from '../../containers/App/AppStyles';

function ButtonWithIconAndCircularProgress(props) {
  const {
    children, darkTheme, isLoading, disabled,
    hideButton, sx, withoutIcon, ...rest
  } = props;

  if (hideButton) {
    return null;
  }

  return (
    <ButtonWithIcon
      disabled={disabled || isLoading}
      withoutIcon={withoutIcon}
      {...rest}
      sx={darkTheme ? {
        '&': {
          '&.Mui-disabled': {
            backgroundColor: COLOR.darkStyle.white['8'],
            color: COLOR.darkStyle.white['24'],
          },
        },
        ...sx,
      } : { p: BUTTON_SIZE.withIconSmall, ...sx }}
    >
      {isLoading ? (
        <CircularProgress
          color="inherit"
          size={24}
          sx={withoutIcon ? {
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          } : null}
        />
      ) : children !== undefined && children}
    </ButtonWithIcon>
  );
}

ButtonWithIconAndCircularProgress.propTypes = {
  children: node,
  isLoading: bool,
  disabled: bool,
  hideButton: bool,
  darkTheme: bool,
  withoutIcon: bool,
  sx: object,
  text: string,
  onClick: func,
};

ButtonWithIconAndCircularProgress.defaultProps = {
  isLoading: false,
  disabled: false,
  hideButton: false,
  darkTheme: false,
  withoutIcon: false,
};

export default memo(ButtonWithIconAndCircularProgress);
