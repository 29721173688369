// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';

export function* callGetProductsFeedStatisticWorker({ payload: params }) {
  const response = yield apply(api, api.feedsProducts.getProductsFeedStatistic, [params]);
  let data = {};
  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
  }
  yield put(actions.setProductsFeedStatistic({ statistic: data }));
}
