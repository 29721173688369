import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Field } from 'redux-form/immutable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { validators } from '../../../../../../_helpers/validationsForm';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import PaymentTypesDocsModalStyles from '../PaymentTypesDocsModalStyles';

const useStyles = makeStyles(PaymentTypesDocsModalStyles);
const RenderFopOthersAbroadEnterprises = ({ fields, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const addHandler = () => fields.push({});

  return (
    <fieldset className={classes.fieldset}>
      <legend className={classes.legend}>{t('Інформація про належність Підприємства та осіб, зазначених у пунктах Опитувальника до національних, іноземних публічних осіб та діячів що виконують політичні функції в міжнародних організаціях, їх  близьких осіб або пов\'язаних з ними осіб')}:</legend>
      <ul className={classes.fieldArrayList}>
        {fields.map((member, index) => {
          const removeHandler = () => fields.remove(index);
          return (
            <li key={index} className={classes.fieldArrayList_item}>
              <Grid container alignItems="flex-end" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Field
                    name={`${member}.name`}
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    component={TextField}
                    validate={[validators.required]}
                    validation
                    required
                    helperTextBefore={t('Підприємств / юридична особа /ПІБ фізичної особи (в тому числі ПІБ учасника)')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name={`${member}.position`}
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    component={TextField}
                    validate={[validators.required]}
                    validation
                    required
                    helperTextBefore={t('ПІБ публічного діяча, посада')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name={`${member}.attitude`}
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    component={TextField}
                    validate={[validators.required]}
                    validation
                    required
                    helperTextBefore={t('Відношення особи до публічного діяча')}
                  />
                </Grid>
              </Grid>
              {!disabled && (
                <Box className={classes.fieldArrayList_buttonHolder}>
                  <Button className={classes.fieldArrayList_item__button} onClick={removeHandler}>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              )}
            </li>
          );
        })}
      </ul>
      <Box display="flex">
        {!disabled && (
          <ButtonWithIcon text={t('Добавить')} className={classes.fieldArrayList_send_button} onClick={addHandler}>
            <AddCircleIcon />
          </ButtonWithIcon>
        )}
      </Box>
    </fieldset>
  );
};

RenderFopOthersAbroadEnterprises.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.object,
};

export default RenderFopOthersAbroadEnterprises;
