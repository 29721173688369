export const types = Object.freeze({
  SET_PARTNERS_LIST: 'SET_PARTNERS_LIST',
  MERGE_PARTNERS_LIST: 'MERGE_PARTNERS_LIST',
  SET_PARTNERS_CURRENT_PAGE: 'SET_PARTNERS_CURRENT_PAGE',
  SET_PARTNERS_FILTERS: 'SET_PARTNERS_FILTERS',
  SET_PARTNERS_PAGE_SIZE: 'SET_PARTNERS_PAGE_SIZE',
  MERGE_PARTNER_SAVE: 'MERGE_PARTNER_SAVE',
  MERGE_PARTNER_CREATE_NEW: 'MERGE_PARTNER_CREATE_NEW',
  CHANGE_PARTNERS_SORTING: 'CHANGE_PARTNERS_SORTING',
  CHANGE_PARTNERS_FILTERS: 'CHANGE_PARTNERS_FILTERS',
  RESET_PARTNERS_FILTERS: 'RESET_PARTNERS_FILTERS',
  SET_PARTNER_CONTEXT_LIST: 'SET_PARTNER_CONTEXT_LIST',
  SET_PARTNERS_SORTING: 'SET_PARTNERS_SORTING',
  SET_PARTNERS_SELECTION: 'SET_PARTNERS_SELECTION',
  MERGE_PARTNERS_TOP_IMPORT: 'MERGE_PARTNERS_TOP_IMPORT',
  MERGE_PARTNERS_TOP_EXPORT: 'MERGE_PARTNERS_TOP_EXPORT',
  SET_PARTNERS_INITIAL_STATE: 'SET_PARTNERS_INITIAL_STATE',
  SET_PARTNER_SELECTION: 'SET_PARTNER_SELECTION',
  SET_PARTNER_LIST_CONTEXT: 'SET_PARTNER_LIST_CONTEXT',
  MERGE_REASON_REJECTION: 'MERGE_REASON_REJECTION',
  MERGE_PARTNERS_PROCESS: 'MERGE_PARTNERS_PROCESS',
  SET_PARTNER_CONTEXT_PENDING: 'SET_PARTNER_CONTEXT_PENDING',
  SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING: 'SET_PARTNERS_CONTRACT_COMMISSION_DOWNLOAD_PENDING',
  MERGE_PARTNER_SEND_CONTRACT_TO_SED: 'MERGE_PARTNER_SEND_CONTRACT_TO_SED',
  MERGE_PARTNERS_HASH_ID_CONFIRM: 'MERGE_PARTNERS_HASH_ID_CONFIRM',
  SET_USERS_CONTEXT_PENDING: 'SET_USERS_CONTEXT_PENDING',
  SET_PARTNERS_SETTING_STATUS: 'SET_PARTNERS_SETTING_STATUS',
  SET_PARTNERS_SETTING_PENDING: 'SET_PARTNERS_SETTING_PENDING',
  MERGE_PARTNERS_DOWNLOAD: 'MERGE_PARTNERS_DOWNLOAD',
  MERGE_PARTNERS_PAYMENT_TYPES: 'MERGE_PARTNERS_PAYMENT_TYPES',
  MERGE_PARTNERS_LIST_BY_STATUS: 'MERGE_PARTNERS_LIST_BY_STATUS',
  MERGE_PARTNERS_LIST_WITH_MID: 'MERGE_PARTNERS_LIST_WITH_MID',
  MERGE_PARTNER_ORDER_PROCESSING: 'MERGE_PARTNER_ORDER_PROCESSING',
  MERGE_PARTNER_DELIVERY_DELAY_PERIOD: 'MERGE_PARTNER_DELIVERY_DELAY_PERIOD',
  SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_MODAL_SHOW: 'SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_MODAL_SHOW',
  SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_PENDING: 'SET_PARTNER_DELIVERY_DELAY_PERIOD_DELETE_PENDING',
});
