// Core
import { put } from 'redux-saga/effects';
// Engine
import { Map } from 'immutable';
import { localData } from '../../../../config/localData';
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../_helpers/getInitialPageState';

export function* callGetInitialStateWorker({ payload: url }) {
  const lists = yield localData.getItem(url);
  const initialPageState = getInitialPageState(Map(lists) || {});
  yield put(actions.setInitialState(initialPageState));
}
