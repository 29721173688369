// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_CONTENT_PRODUCT_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_CONTENT_PRODUCT_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_CONTENT_PRODUCT_INITIAL_STATE_ASYNC, path);
  },
  getContentCategoriesListAsync(config) {
    return getActionCreator(asyncTypes.GET_CONTENT_CATEGORIES_LIST_ASYNC, config);
  },
  redirectToCategoryWithStatus(params) {
    return getActionCreator(asyncTypes.REDIRECT_TO_CONTENT_PRODUCT_CATEGORY_WITH_STATUS, params);
  },
});
