import { COLOR } from '../App/AppStyles';

const FieldWithClearButtonStyle = () => ({
  wrapper: {
    display: 'flex',
  },
  fieldWrapper: {
    maxWidth: 'calc(100% - 70px)',
    flexGrow: 1,
    position: 'relative',
  },
  buttonHolder: {
    marginLeft: 6,
    padding: 2,
    borderRadius: 3,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    gap: '4px',
    alignSelf: 'flex-start',
  },
  deleteButton: {
    alignSelf: 'flex-end',
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,
    borderRadius: 3,
    backgroundColor: COLOR.grey['8'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLOR.brand.dark,
    '& svg': {
      fontSize: '20px',
    },
    '&:hover': {
      backgroundColor: COLOR.grey['12'],
    },
  },
  refreshButton: {
    alignSelf: 'flex-end',
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,
    borderRadius: 3,
    backgroundColor: COLOR.grey['8'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLOR.controls.dark,
    '& svg': {
      fontSize: '20px',
    },
    '&:hover': {
      backgroundColor: COLOR.grey['12'],
    },
  },
  alignTop: {
    alignSelf: 'flex-start',
  },
  fieldWrapperError: {
    '& fieldset, & .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.error,
    },
    '& .MuiInputLabel-root': {
      color: COLOR.error,
    },
  },
});

export default FieldWithClearButtonStyle;
