// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
// UI
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Style
import HeaderDescriptionAndTooltipStyles from './HeaderDescriptionAndTooltipStyles';

function HeaderDescriptionAndTooltip(props) {
  const { classes, description, tooltip } = props;

  if (description && tooltip) {
    return (
      <Tooltip title={tooltip}>
        <div className={classes.description}>
          {description}
          <Icon color="primary">*</Icon>
        </div>
      </Tooltip>
    );
  }

  if (description) {
    return (
      <div className={classes.description}>
        {description}
      </div>
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Icon color="primary" className={classes.buttonRequired}>*</Icon>
      </Tooltip>
    );
  }
  return null;
}

HeaderDescriptionAndTooltip.displayName = 'HeaderDescriptionAndTooltip';

HeaderDescriptionAndTooltip.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string,
  tooltip: PropTypes.string,
};

HeaderDescriptionAndTooltip.defaultProps = {
  classes: {},
};

export default withStyles(HeaderDescriptionAndTooltipStyles)(HeaderDescriptionAndTooltip);
