import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import debounce from 'lodash/debounce';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isImmutable } from 'immutable';
import styles from './styles';

const AsyncSelect = ({
  loadOptions, placeholder = 'Поиск', isFloatingLabel, placeholderTop, customSx,
  errorText, emptyText, pattern, input, meta, isValidationNeeded, disabled, dataTestId,
}) => {
  const [inputValue, setInputValue] = useState(isImmutable(input.value) ? input?.value?.toJS()?.label : input?.value ?? '');
  const [options, setOptions] = useState([]);
  const [label, setLabel] = useState(inputValue ? placeholderTop : placeholder);
  const [error, setError] = useState('');
  const textOptions = useMemo(() => options.map(item => item?.value) ?? [], [options]);

  const debouncedLoadOptions = useCallback(debounce(async (val) => {
    const loadedOptions = await loadOptions(val);
    setOptions(loadedOptions);
    if (loadedOptions.length === 0) {
      setError(errorText);
    }
  }, 500),
  []);

  useEffect(() => {
    if (inputValue?.length >= 2 && !textOptions.includes(inputValue) && !isImmutable(input.value)) {
      debouncedLoadOptions(inputValue);
    }
  }, [inputValue, debouncedLoadOptions]);


  useEffect(() => {
    if (meta.touched && !inputValue && isValidationNeeded) {
      setError(emptyText);
    }
  }, [meta.touched]);

  const sx = isFloatingLabel ? styles(((meta.error && meta.touched))) : customSx;

  const handleInputChange = (event, value) => {
    if (event?.nativeEvent?.inputType === 'deleteContentBackward') {
      setInputValue(value);
      input.onChange(value);
    }
    if (pattern && pattern.test(value[value.length - 1]) || value === '') {
      setInputValue(value);
    } else {
      event.preventDefault();
    }
  };

  const handleChange = (event, newValue) => {
    input.onChange(newValue.label ?? newValue);
    setInputValue(newValue.label ?? newValue);
  };

  const handleBlur = () => {
    if (isFloatingLabel && !inputValue) {
      setLabel(placeholder);
    }
    if (isValidationNeeded) {
      if (!inputValue) {
        setError(emptyText);
      }

      if (!textOptions.includes(inputValue) && !isImmutable(input.value)) {
        setError(errorText);
      }
    }
  };


  const handleFocus = () => {
    setError('');
    if (isFloatingLabel) {
      setLabel(placeholderTop);
    }
  };

  return (
    <Autocomplete
      freeSolo
      autoComplete
      includeInputInList
      filterOptions={x => x}
      value={isImmutable(input.value) ? input?.value?.toJS().value : input?.value}
      onChange={handleChange}
      options={options}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      disableClearable
      disablePortal
      disabled={disabled}
      data-testid={dataTestId}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onFocus={handleFocus}
          onBlur={handleBlur}
          fullWidth
          variant="outlined"
          sx={{ ...sx, flex: 1 }}
          error={!!error}
          helperText={error}
          FormHelperTextProps={{ error: true }}
        />
      )}
    />
  );
};

export default AsyncSelect;
