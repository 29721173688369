// Core
import { fromJS, List, Map } from 'immutable';

// Instruments
import { types } from './types';
import { userService } from '../../config/api';
import { types as typesUI } from '../ui/types';

export const initialState = Map({
  userInfo: userService.getUserInfo(),
  twoFactorAuth: Map({
    pending: false,
    twoFactorAuthToken: null,
    twoFactorAuthCode: null,
    loginMethod: null,
    codeValidTimeLife: null,
    message: null,
  }),
  putUserInfo: Map({
    sendNewPhoneStatus: null,
    newPhoneValue: null,
    pending: false,
  }),
  postUserInfo: Map({
    pending: false,
  }),
  putUsersHashId: Map({
    pending: false,
  }),
  phoneVerifyConfirmCode: Map({
    status: null,
    pending: false,
  }),
  userAdd: Map({
    pending: false,
  }),
  userConfigurations: Map(),
  userSettings: {},
  mailSettings: Map({
    mailTemplateGroups: List(),
    items: List(),
    totalCount: 0,
    pending: true,
    putPending: false,
  }),
  userSubmitting: false,
  usersRoles: [],
  userByHashId: Map({
    pending: false,
    user: {},
  }),
  userPartnerHashId: null,
  userPartnerRoles: Map({
    pending: false,
    items: List(),
  }),
  userNotifications: Map({
    messages: List(),
    popups: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'user',
  userByHashId: 'userByHashId',
  rolesList: 'rolesList',
  userPartnerHashId: 'userPartnerHashId',
  userAdd: 'userAdd',
  userNotifications: 'userNotifications',
  popups: 'popups',
  email: 'email',
  xmlFileLink: 'xmlFileLink',
  partnerId: 'partnerId',
  rememberMe: 'rememberMe',
  partnerStatus: 'partnerStatus',
  pending: 'pending',
  accessLists: 'accessLists',
  messages: 'messages',
  items: 'items',
  putUsersHashId: 'putUsersHashId',
  userPartnerRoles: 'userPartnerRoles',
  userInfo: 'userInfo',
  twoFactorAuth: 'twoFactorAuth',
  putUserInfo: 'putUserInfo',
  postUserInfo: 'postUserInfo',
  phoneVerifyConfirmCode: 'phoneVerifyConfirmCode',
  userSettings: 'userSettings',
  usersRoles: 'usersRoles',
  userSubmitting: 'userSubmitting',
  userConfigurations: 'userConfigurations',
  mailSettings: 'mailSettings',
  mailTemplateGroups: 'mailTemplateGroups',
});

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USER_INFO: {
      return state.set(stateKeys.userInfo, payload);
    }
    case types.MERGE_IN_USER_INFO: {
      return state.mergeIn([stateKeys.userInfo], payload);
    }
    case types.MERGE_GET_USERS_PARTNER_ROLES: {
      return state.mergeIn([stateKeys.userPartnerRoles], fromJS(payload));
    }
    case types.SET_USER_PARTNER_HASH_ID: {
      return state.setIn([stateKeys.userPartnerHashId], payload);
    }
    case types.MERGE_TWO_FACTOR_AUTH_INFO: {
      return state.mergeIn([stateKeys.twoFactorAuth], payload);
    }
    case types.MERGE_POST_IN_USER_INFO: {
      return state.mergeIn([stateKeys.postUserInfo], fromJS(payload));
    }
    case types.MERGE_IN_PUT_USER_INFO: {
      return state.mergeIn([stateKeys.putUserInfo], fromJS(payload));
    }
    case types.MERGE_IN_CONFIRM_CODE: {
      return state.mergeIn([stateKeys.phoneVerifyConfirmCode], fromJS(payload));
    }
    case types.MERGE_USER_SETTINGS: {
      return state.mergeIn([stateKeys.userSettings], payload);
    }
    case types.SET_USER_SUBMITTING: {
      return state.set(stateKeys.userSubmitting, payload);
    }
    case types.SET_USERS_ROLES: {
      return state.set(stateKeys.usersRoles, payload);
    }
    case types.MERGE_IN_USER_BY_HASH_ID: {
      return state.mergeIn([stateKeys.userByHashId], payload);
    }
    case types.MERGE_USER_ADD: {
      return state.mergeIn([stateKeys.userAdd], payload);
    }
    case types.MERGE_MAIL_SETTINGS: {
      return state.mergeIn([stateKeys.mailSettings], payload);
    }
    case types.MERGE_PUT_USERS_HASH_ID: {
      return state.mergeIn([stateKeys.putUsersHashId], payload);
    }
    case types.SET_USER_CONFIGURATIONS: {
      return state.setIn([stateKeys.userConfigurations], fromJS(payload));
    }
    case types.MERGE_USER_NOTIFICATIONS: {
      return state.mergeIn([stateKeys.userNotifications], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
