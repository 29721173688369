// Core
import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
import { useUserRole } from '../../../hooks/useUserRole';

// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';
import { userRoles } from '../../../../engine/config/userRoles';
import { selectors } from '../../../../engine/config/selectors';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';

function MainRedirects() {
  const hasAccessOrdersList = useAccessList(accessList.orderList);
  const hasAccessPartnerEdit = useAccessList(accessList.partnerEdit);
  const hasAccessPartnerShow = useAccessList(accessList.partnerShow);
  const hasAccessProductList = useAccessList(accessList.productList);
  const hasAccessBillingStatus = useAccessList(accessList.billingStatus);
  const isPartnerSigningContract = useUserRole(userRoles.partnerSigningContract);
  const isPartnerManageProfile = useUserRole(userRoles.partnerManageProfile);
  const isCreditDepartmentManager = useUserRole(userRoles.creditDepartmentManager);
  const partnerStatus = useSelector(selectors.user.partnerStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    let url = pageLinks.dashboard.all;
    if (!hasAccessOrdersList && hasAccessProductList) {
      url = pageLinks.content.products.all();
    } else if (hasAccessOrdersList) {
      url = pageLinks.orders.all;
    } else if (
      isPartnerSigningContract
      || isPartnerManageProfile
      || partnerStatus === partnerStatuses.awaitingPayment
      || partnerStatus === partnerStatuses.questionnaireFill
      || partnerStatus === partnerStatuses.contractSigning
      || partnerStatus === partnerStatuses.questionnairePendingConfirm
      || partnerStatus === partnerStatuses.questionnairePending
    ) {
      if (hasAccessPartnerEdit || hasAccessPartnerShow) {
        url = pageLinks.profilePartner;
      }
    } else if (hasAccessBillingStatus) {
      url = pageLinks.billing.account;
    } else if (isCreditDepartmentManager) {
      url = pageLinks.partner.accesses;
    }
    dispatch(push(url));
  });

  return (
    <></>
  );
}

export default MainRedirects;
