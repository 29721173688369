
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  Field,
} from 'redux-form/immutable';
import moment from 'moment';
import DataRangeDrop from '../../../../../../../components/_Form/DataRange/DataRangeDrop/DataRangeDrop';
import { COLOR } from '../../../../../../../containers/App/AppStyles';

export const ProductCreatedDateRange = ({
  firstName, firstHandleChange, firstMaxdate = moment(), secondName, secondMinDate, secondHandleChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', pt: '4px', alignItems: 'center' }}>
        <Field
          name={firstName}
          component={DataRangeDrop}
          handleChange={firstHandleChange}
          disablePast={false}
          fullWidth
          validate={[]}
          maxDate={firstMaxdate}
          label={t('Начало')}
          margin="normal"
        />
        <Box sx={{ ml: '8px', mr: '8px' }}>—</Box>
        <Field
          name={secondName}
          component={DataRangeDrop}
          handleChange={secondHandleChange}
          disablePast={false}
          fullWidth
          validate={[]}
          minDate={secondMinDate}
          maxDate={moment()}
          label={t('Конец')}
          margin="normal"
        />
      </Box>
      <Box
        component="span"
        sx={{
          color: COLOR.grey[54], fontSize: '12px', lineHeight: '12px', mb: '20px',
        }}
      >{t('Период, за который товар попал выбраный статус')}
      </Box>
    </>
  );
};
