import { COLOR } from '../../../containers/App/AppStyles';

const CountCardStyles = theme => ({
  content: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '16px',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  descriptionText: {
    color: COLOR.grey[72],
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '6px',
    display: 'block',
  },
  icon: {
    boxShadow: 'none',
    color: 'white',
  },
  iconFloat: {
    position: 'absolute',
    right: '15px',
    top: '50%',
    marginTop: '-20px',
    opacity: '0.2',
    transform: 'rotate(-5deg)',
  },
  lightText: {
    color: 'white',
  },
  counter: {
    display: 'inline-flex',
    fontSize: ' 18px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  counterWithStar: {
    marginLeft: 5,
    alignItems: 'center',
  },
  card: {
    height: '100%',
  },
  star: {
    marginLeft: 5,
    paddingBottom: '3px',
  },
  smallTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '28px',
  },
});

export default CountCardStyles;
