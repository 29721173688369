// Core
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
// Instruments
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: createBrowserHistory(),
});
const sagaMiddleware = createSagaMiddleware();
const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';
const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // eslint-disable-line
const composeEnhancers = isDev && devtools ? devtools : compose;
const store = createStore(
  rootReducer(routerReducer),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware)),
);
sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
export { store };
