// Core
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { reset } from 'redux-form';
import {
  Form, Field, reduxForm, formValueSelector,
} from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import SaveIcon from '@mui/icons-material/Save';
// Parts
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import ImportResultXlsxBlock from '../ImportResult/ImportResultXlsxBlock';
import RenderFilesUploader from './components/RenderFilesUploader';
// Engine
import { accessList } from '../../../../../../../engine/config/access';
import { mimeTypeExtensions } from '../../../../../../../engine/config/mimeTypeExtensions';
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
// Helpers
import { validators } from '../../../../../../../_helpers/validationsForm';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { usePrevious } from '../../../../../../hooks/usePrevious';
import { useStyles } from '../../../../../../hooks/useStyles';
import ModalStyle from '../../../../../../containers/Modals/Modal/ModalStyle';

export const form = Object.freeze({
  name: 'ProductsImportModal',
  fields: Object.freeze({
    productImportFile: 'productImportFile',
    partnerId: 'partnerId',
  }),
});
const selector = formValueSelector(form.name);

function ProductsImportModal(props) {
  const {
    handleModalToggle, handleSubmit, pending,
    modalOpen, pristine, valid, submitting,
  } = props;
  const classes = useStyles(ModalStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessProductImport = useAccessList(accessList.productImport);
  const importModal = useSelector(selectors.content.importModal);
  const textOnly = importModal.toJS().onlyText;
  const productsImportPending = useSelector(selectors.content.productsImportPending);
  const feedProductsFile = useSelector(state => selector(state, form.fields.productImportFile));
  const prevModal = usePrevious(modalOpen);
  const titleModal = textOnly ? t('Импорт текстовых характеристик товаров') : t('Импорт товаров');

  useEffect(() => {
    if (hasAccessProductImport) {
      if (!modalOpen) {
        dispatch(actions.setProductsImportModalInitialState());
      }
      if (prevModal === true && modalOpen === false) {
        dispatch(reset(form.name));
      }
    }
  }, [hasAccessProductImport, modalOpen, dispatch, prevModal]); //eslint-disable-line

  const onFormSubmit = (values) => {
    const { productImportFile } = values.toJS();
    dispatch(asyncActions.postProductsFeedImportAsync({
      productImportFile,
      onlyText: textOnly
        ? 1
        : undefined,
    }));
  };

  if (!hasAccessProductImport) {
    return null;
  }

  return (
    <Modal
      modalOpen={modalOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      addText={t('Сохранить')}
      iconSendButton={<SaveIcon />}
      disabledSend={pristine || submitting || productsImportPending || !valid || !feedProductsFile || !feedProductsFile.length}
      title={titleModal}
      handleModalAdd={handleSubmit}
      loadingSend={productsImportPending}
      modalMaxWidth="xs"
      fullScreen={false}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={RenderFilesUploader}
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.productImportFile}
          disabled={pending}
          validate={[validators.itemsRequiredValidator]}
        />
        <ImportResultXlsxBlock classes={classes} importModal={importModal} />
      </Form>
    </Modal>
  );
}

ProductsImportModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleModalToggle: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
};

ProductsImportModal.defaultProps = {
  pristine: false,
  submitting: false,
  pending: false,
  handleSubmit: () => {},
};

export default reduxForm({
  form: form.name,
})(ProductsImportModal);
