// Parts
import { ValuesCount, Wrapper } from './styles';

export default function IndicatorsContainer(props) {
  const {
    children, isMulti, isDisabled, getValue,
  } = props;

  const valueLength = getValue().length;
  return (
    <Wrapper isDisabled={isDisabled}>
      {valueLength > 1 && isMulti && <ValuesCount>{valueLength}</ValuesCount>}
      {children}
    </Wrapper>
  );
}
