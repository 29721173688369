// Core
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import Map from 'lodash/map';
import { submit } from 'redux-form';
import {
  Field, Form, formValueSelector, reduxForm, untouch, change,
} from 'redux-form/immutable';
import * as PropTypes from 'prop-types';
// Parts
import Modal from '../../../../../containers/Modals/Modal/Modal';
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import BrandsSelect from '../../../../../components/_Form/Selects/BrandsSelect';
import CategoriesSelect from '../../../../../components/_Form/Selects/CategoriesSelect';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
import { usePrevious } from '../../../../../hooks/usePrevious';
// Engine
import { actions } from '../../../../../../engine/core/stopList/BrandsCategories/actions';
import { asyncActions } from '../../../../../../engine/core/stopList/BrandsCategories/saga/asyncActions';
import { selectors } from '../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../engine/config/access';
import { asyncActions as partnersAsyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';

import { formName, formFields } from './form';


function StopBrandCategoriesModal(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const hasAccess = useAccessList(accessList.stopBrandsCategoriesCreate);
  const dispatch = useDispatch();
  const modalOpen = useSelector(selectors.stopListBrandsCategories.addModalIsOpen);
  const prevModalOpen = usePrevious(modalOpen);
  const pending = useSelector(selectors.stopListBrandsCategories.addModalPending);
  const selector = formValueSelector(formName);
  const categories = useSelector(state => selector(state, formFields.categories));
  const brand = useSelector(state => selector(state, formFields.brand));
  const partner = useSelector(state => selector(state, formFields.excludePartners));

  useEffect(() => {
    if (modalOpen === true && (modalOpen !== prevModalOpen)) {
      dispatch(partnersAsyncActions.getListWithMidAsync({ statusId: 12 }));
      Object.values(formFields).forEach((field) => {
        dispatch(change(formName, field, undefined));
        dispatch(untouch(formName, field));
      });
    }
  }, [dispatch, modalOpen, prevModalOpen]);

  const handleModalToggle = () => {
    dispatch(actions.mergeAdd({ isModalOpen: !modalOpen }));
  };

  const handleSendForm = () => dispatch(submit(formName));

  const onFormSubmit = (formData) => {
    if (formData && formData.toJS) {
      const data = formData.toJS();
      const dataToServer = {
        category: get(data[formFields.categories], 'value'),
        excludePartners: Map(data[formFields.excludePartners], item => item.value),
        brand: get(data[formFields.brand], 'value'),
      };
      dispatch(asyncActions.postItemAsync({ data: dataToServer }));
    }
  };

  if (!hasAccess) {
    return null;
  }

  return (
    <>
      {modalOpen && (
        <Modal
          modalOpen={modalOpen}
          handleModalToggle={handleModalToggle}
          handleModalCancel={handleModalToggle}
          modalMaxWidth="md"
          handleModalAdd={handleSendForm}
          disabledSend={(categories === undefined && brand === undefined && partner === undefined) || pending}
          title={t('Добавление стоп бренда-категории')}
        >
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Field
              component={CategoriesSelect}
              name={formFields.categories}
              margin="normal"
            />
            <Field
              formName={formName}
              name={formFields.excludePartners}
              component={PartnerSelect}
              partnerListSelector={selectors.partners.listWithMid}
              fullWidth
              isMulti
              validation
              margin="normal"
              label={t('Партнеры-исключения')}
            />
            <Field
              component={BrandsSelect}
              margin="normal"
              name={formFields.brand}
            />
          </Form>
        </Modal>
      )}
    </>
  );
}

StopBrandCategoriesModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: formName })(StopBrandCategoriesModal);
