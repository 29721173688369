import { COLOR } from '../../../containers/App/AppStyles';

const AlloExpressStyle = {
  titlesStyle: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    color: 'rgba(48, 48, 48, 0.72)',
  },
  fieldsStyle: {
    fontSize: '14px',
    color: '#303030',
    textAlign: 'end',
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    borderTopLeftRadius: '6px',
  },
  selectFormControlStyle: {
    '& .MuiInputLabel-root.Mui-focused': {
      color: COLOR.grey[72],
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: `${COLOR.error}`,
    },
    fontSize: '14px',
    width: '100%',
  },
  selectStyle: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.grey[38],
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.grey[38],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.grey[38],
      borderWidth: '1px !important',
    },
  },
};

export default AlloExpressStyle;
