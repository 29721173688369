// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import * as PropTypes from 'prop-types';
// Parts
import { Pie } from 'react-chartjs-2';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import DateRangeFilter from '../../../../../components/_Table/DateRangeFilter';
// Helpers
import { useSuccessDeliveryPie } from './hooks/useSuccessDeliveryPie';
import { COLOR } from '../../../../../containers/App/AppStyles';

function SuccessDeliveryPie(props) {
  const { dataFieldClassName } = props;
  const {
    data,
    getDataAsync,
    options,
    legend,
  } = useSuccessDeliveryPie();
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const onFilterChange = (value) => {
    setCurrentDate({ value });
    getDataAsync(value);
    setChecked(false);
  };
  const handleCheckboxChange = (event) => {
    setCurrentDate(null);
    if (event.target.checked || isNil(currentDate)) {
      getDataAsync();
    } else {
      getDataAsync(currentDate.value);
    }
    setChecked(event.target.checked);
  };

  useEffect(getDataAsync, []);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <DateRangeFilter
            variant="outlined"
            clearButton={false}
            filters={currentDate}
            onChange={onFilterChange}
            className={dataFieldClassName}
          />
        </Grid>
        <Grid item>
          <FormGroup sx={{ marginLeft: '6px' }} row>
            <FormControlLabel
              control={(
                <Checkbox
                  disabled={checked}
                  checked={checked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  sx={{ margin: '0px' }}
                />
              )}
              label={t('За весь период')}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box position="relative" sx={{ width: '100%', minHeight: '30px' }}>
        {data.noData ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              height: '100%',
              width: '100%',
              backgroundColor: COLOR.bgDefault,
            }}
          >
            {t('Нет заказов')}
          </Box>
        ) : (
          <Pie legend={legend} options={options} data={data} />
        )}
      </Box>
    </>
  );
}

SuccessDeliveryPie.propTypes = {
  dataFieldClassName: PropTypes.string,
};

export default SuccessDeliveryPie;
