// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
// import { types } from '../types';
// Workers
import {
  // PRODUCT_FEED_FEATURE_VALUES
  callGetAttributesByHashIdWorker,
  callPutAttributesByHashIdWorker,
  // PRODUCT_FEED_FEATURE
  callGetCategoriesByHashIdWorker,
  callPutCategoriesByHashIdWorker,
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  // callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callPostAutoConnectWorker,
} from './workers';

// PRODUCT_FEED_FEATURE_VALUES
function* watchGetAttributesByHashId() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID, callGetAttributesByHashIdWorker);
}
function* watchPutAttributesByHashId() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_FEED_ATTRIBUTES_BY_HASH_ID, callPutAttributesByHashIdWorker);
}
// PRODUCT_FEED_FEATURE
function* watchGetCategoriesByHashId() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID, callGetCategoriesByHashIdWorker);
}
function* watchPutCategoriesByHashId() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_BY_HASH_ID, callPutCategoriesByHashIdWorker);
}
// PRODUCT_FEED_CATEGORIES_ATTRIBUTES
function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
// function* watchSetCurrentPage() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_CURRENT_PAGE, callSetToLocalDataWorker);
// }
// function* watchSetPageSize() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_PAGE_SIZE, callSetToLocalDataWorker);
// }
// function* watchSetFilters() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_FILTERS, callSetToLocalDataWorker);
// }
// function* watchSetSorting() {
//   yield takeEvery(types.SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SORTING, callSetToLocalDataWorker);
// }
function* watchPostAutoConnectWorker() {
  yield takeEvery(asyncTypes.POST_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_ASYNC, callPostAutoConnectWorker);
}

export function* watchersProductFeedCategoriesAttributes() {
  yield all([
    // PRODUCT_FEED_FEATURE_VALUES
    watchGetAttributesByHashId(),
    watchPutAttributesByHashId(),
    // PRODUCT_FEED_FEATURE
    watchGetCategoriesByHashId(),
    watchPutCategoriesByHashId(),
    // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    // watchSetCurrentPage(),
    // watchSetPageSize(),
    // watchSetFilters(),
    // watchSetSorting(),
    watchPostAutoConnectWorker(),
  ]);
}
