// Core
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { NavLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
// Parts
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import ServiceSupportModal from './components/ServiceSupport/ServiceSupportModal';
import InvoiceModal from './components/ModalInvoice/ModalInvoice';
import LimitBalanceOverModal from '../../page/Billing/Account/components/LimitBalanceOverModal/LimitBalanceOverModal';
import LimitBalanceOverModalOff from '../../page/Billing/Account/components/LimitBalanceOverModal/LimitBalanceOverModalOff';
import BalanceOverModalFirst from '../../page/Orders/components/BalanceOverModal/BalanceOverModalFirst';
import TimerCountCard from '../TimerCountCard/TimerCountCard';
import IndebtednessModal from './components/IndebtednessModal';
import HelpMenu from './components/HelpMenu/HelpMenu';
import BalancePartner from './components/BalancePartner/BalancePartner';

import SupportButtons from './components/ServiceSupport/SupportButtons';
import ModalSignTheContract from './components/ModalSignTheContract';
import ModalShowFirstPayment from './components/ModalShowFirstPayment';
import BalanceAdmin from './components/BalanceAdmin/BalanceAdmin';
// Icons
import PersonIcon from '../../icons/Person';
import PenMenuOutlinedIcon from '../../icons/PenMenuOutlined';
import ExitIcon from '../../icons/Exit';
import GearIcon from '../../icons/Gear';
import PersonOutlinedIcon from '../../icons/PersonOutlined';
import NotificationIcon from '../../icons/Notification';
import UserIcon from '../../icons/UserIcon';
import ArrowDownRoundedIcon from '../../icons/ArrowDownRounded';
import MenuIcon from '../../icons/Menu';
// Engine
import { userRoles } from '../../../engine/config/userRoles';
import { selectors } from '../../../engine/config/selectors';
import { accessList } from '../../../engine/config/access';
import { countersMaxNumber } from '../../../engine/config/globalConfig';
import { billingAsyncAction } from '../../../engine/core/billing/saga/asyncAction';
import { routersLink, pageLinks } from '../../../engine/config/routes';
import { billingActions } from '../../../engine/core/billing/action';
// Helpers
import HeaderStyles from './HeaderStyle';
import { useStyles } from '../../hooks/useStyles';
import { useAccessList } from '../../hooks/useAccessList';
import { usePrevious } from '../../hooks/usePrevious';
import { useUserRole } from '../../hooks/useUserRole';
import { useUserSomeRole } from '../../hooks/useUserSomeRole';

function Header(props) {
  const { userLogout, toggleDrawer } = props;
  const { t } = useTranslation();
  const classes = useStyles(HeaderStyles);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(null);
  const isOpen = Boolean(userMenuIsOpen);
  const isAdminRole = useUserRole(userRoles.admin);
  const isCallCenterRole = useUserRole(userRoles.callCenter);
  const isManagerRole = useUserRole(userRoles.manager);
  const isCreditManagerRole = useUserRole(userRoles.creditDepartmentManager);
  const isSalesManagerRole = useUserRole(userRoles.salesManager);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);
  const hasAccessBillingStatus = useAccessList(accessList.billingStatus);
  const statusPending = useSelector(selectors.billing.statusPending);
  const isOpenedSideBar = useSelector(selectors.ui.sidebarOpened);
  const hasAccessMailSettingEdit = useAccessList(accessList.userMailSettingEdit);
  const hasUserEditSelf = useAccessList(accessList.userEditSelf);

  const handleUserMenu = (event) => {
    setUserMenuIsOpen(userMenuIsOpen ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setUserMenuIsOpen(null);
  };

  const {
    logo, LogoMobile, logoAltText, inApprove, userName, supportManager,
    notificationsNotReadCount, hasAccessToNotification, hasSupportMessageCreate, onlyLogo,
    partnerBalanceLastUpdate, hasAccessToPartnerEdit, isHideLangMenu,
    hasAccessToPartnerShow, signUpButton, signInButton,
    statusItems: {
      holdBalance, summaryBalance, creditLimit,
      partnerOffTime,
      isActive,
      serverTime, erpStatus,
      creditLimitModalOffStatus,
      partnersLimitDebt,
      partnersTotalDebt,
    },
  } = props;
  const prevPartnerBalanceLastUpdate = usePrevious(partnerBalanceLastUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((partnerBalanceLastUpdate !== prevPartnerBalanceLastUpdate)
      && hasAccessBillingStatus && prevPartnerBalanceLastUpdate !== '') {
      dispatch(billingAsyncAction.getBillingStatusAsync());
    }
  }, [hasAccessBillingStatus, partnerBalanceLastUpdate, prevPartnerBalanceLastUpdate]);

  useEffect(() => {
    if (hasAccessBillingStatus) {
      dispatch(billingAsyncAction.getBillingStatusAsync());
    }
  }, [hasAccessBillingStatus]);

  const hasSupportManagerInfo = !isEmpty(supportManager);

  const mainLogoLinkUrl = process.env.NODE_ENV === 'production'
    ? routersLink.dashboard
    : routersLink.pageList;

  const onLimitEnd = () => {
    dispatch(billingActions.setCreditLimitModalStatus(false));
    dispatch(billingActions.setCreditLimitModalOffStatus(true));
  };

  const onDescClickAfterEndTimer = useCallback(() => {
    if (isActive === true || isActive === false) {
      if (creditLimitModalOffStatus === true) {
        dispatch(billingActions.setCreditLimitModalOffStatus(false));
      }
      setTimeout(() => {
        localStorage.removeItem('limitBalanceModalOff');
        dispatch(billingActions.setCreditLimitModalOffStatus(true));
      }, 100);
    }
  }, [creditLimitModalOffStatus, dispatch, isActive]);

  if (onlyLogo) {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={cx(classes.branding, { [classes.alignStub]: inApprove }, { [classes.brandingSimpleHeader]: onlyLogo })}>
            <NavLink to={mainLogoLinkUrl} className={`${classes.logoLink} ${classes.logoLinkDesktop}`}>
              <img src={logo} alt={logoAltText} className={`${classes.logo} ${classes.logoDesktop}`} />
              <img src={LogoMobile} alt={logoAltText} className={classes.logoMobile} />
              <Typography variant="div" className={classes.marketplaceTitle}>{t('Маркетплейс') }</Typography>
            </NavLink>
          </div>
          <div className={classes.buttonInfoBar}>
            {signUpButton && (
              <NavLink to={routersLink.users.signUp} sx={{ display: 'inline-flex' }}>
                <IconButton
                  color="inherit"
                  className={classes.AuthButton}
                  size="large"
                >
                  <UserIcon sx={{ p: '5px' }} />
                  <Typography variant="span">{t('Зарегистрироваться')}</Typography>
                </IconButton>
              </NavLink>
            )}
            {signInButton && (
              <NavLink to={routersLink.users.signIn} sx={{ display: 'inline-flex' }}>
                <IconButton
                  color="inherit"
                  className={classes.AuthButton}
                  size="large"
                >
                  <UserIcon sx={{ p: '5px' }} />
                  <Typography variant="span">{t('Войти')}</Typography>
                </IconButton>
              </NavLink>
            )}
            {!isHideLangMenu && <LanguageMenu classes={classes} />}
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <ModalSignTheContract />
      <ModalShowFirstPayment />
      <Toolbar className={classes.toolBar}>
        <div className={
          cx(
            classes.branding,
            { [classes.hideSideBar]: !isOpenedSideBar },
          )
          }
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to={mainLogoLinkUrl} className={`${classes.logoLink} ${classes.logoLinkDesktop}`}>
            <img src={logo} alt={logoAltText} className={`${classes.logo} ${classes.logoDesktop}`} />
            <img src={LogoMobile} alt={logoAltText} className={classes.logoMobile} />
            <Typography variant="div" className={classes.marketplaceTitle}>{t('Маркетплейс') }</Typography>
          </NavLink>
        </div>

        {isPartnerRoles && hasSupportManagerInfo && (
          <SupportButtons />
        )}

        {isAdminRole && !isUndefined(partnersLimitDebt) && !isUndefined(partnersTotalDebt) && (
          <BalanceAdmin
            partnersLimitDebt={partnersLimitDebt}
            partnersTotalDebt={partnersTotalDebt}
          />
        )}

        {hasAccessBillingStatus && !statusPending
        && (
          <div className={classes.infoBar}>
            { (isActive === true || isActive === false) && erpStatus === false && partnerOffTime && (
              <Chip
                label={(
                  <TimerCountCard
                    end={isActive ? partnerOffTime : null}
                    start={serverTime}
                    onEnd={onLimitEnd}
                    desc={t('Осталось времени до отключения ваших товаров с allo.ua')}
                    onDescClickAfterEndTimer={onDescClickAfterEndTimer}
                    endText={isActive ? t('Товары выключаются') : t('Товары выключены')}
                    endDesc={isActive
                      ? t('Так как остаток вашего баланса опустился ниже нуля, ваши товары будут вскоре выключены с сайта allo.ua')
                      : t('Так как остаток вашего баланса опустился ниже нуля, ваши товары были выключены с сайта allo.ua')
                    }
                  />
                  )}
                className={classes.infoBarChip}
                size="small"
              />
            )}
            <LimitBalanceOverModal />
            <LimitBalanceOverModalOff />
            <IndebtednessModal />
            <InvoiceModal />
            <BalanceOverModalFirst />
          </div>
        )
        }
        <div className={classes.buttonInfoBar}>
          {!isHideLangMenu && <LanguageMenu classes={classes} />}

          {hasSupportMessageCreate && <ServiceSupportModal />}
          <HelpMenu />
          {hasAccessToNotification && (
            <NavLink to={routersLink.notifications.controller} className={classes.buttonNotifications}>
              <IconButton
                color="inherit"
                className={classes.notificationButton}
                size="large"
              >
                <Badge
                  badgeContent={notificationsNotReadCount}
                  color="secondary"
                  max={Boolean(countersMaxNumber) && (notificationsNotReadCount > countersMaxNumber) ? countersMaxNumber : notificationsNotReadCount}
                >
                  <NotificationIcon />
                </Badge>
                <Typography variant="span">{t('Уведомления')}</Typography>
              </IconButton>
            </NavLink>
          )}
          {isPartnerRoles && (
            !isUndefined(summaryBalance)
            || !isUndefined(holdBalance)
            || !isUndefined(creditLimit)
          ) && (
            <BalancePartner
              summaryBalance={summaryBalance}
              holdBalance={holdBalance}
              creditLimit={creditLimit}
              partnerOffTime={partnerOffTime}
            />
          )}
          <div>
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleCloseMenu}>
              <Button
                onClick={handleUserMenu}
                id="user-menu-button"
                aria-controls={isOpen ? 'user-menu' : null}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : null}
                disableRipple
                className={classes.userMenuButton}
              >
                <div className={classes.holderAvatarIcon}><PersonIcon className={classes.avatarIcon} /></div>
                {userName}
                <ArrowDownRoundedIcon
                  className={cx(classes.userMenuArrow, { [classes.userMenuArrowActive]: isOpen })}
                />
                <Menu
                  id="user-menu"
                  anchorEl={userMenuIsOpen}
                  open={isOpen}
                  onClose={handleCloseMenu}
                  className={classes.userMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {hasUserEditSelf && (
                    <NavLink to={pageLinks.profileUser}>
                      <MenuItem onClick={handleCloseMenu}>
                        <ListItemIcon>
                          <PersonOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Настройка профиля')} />
                      </MenuItem>
                    </NavLink>
                  )}
                  {(!inApprove && hasAccessMailSettingEdit) && (
                    <NavLink to={pageLinks.mailSettings}>
                      <MenuItem onClick={handleCloseMenu}>
                        <ListItemIcon>
                          <GearIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Настройка уведомлений')} />
                      </MenuItem>
                    </NavLink>
                  )}
                  {((hasAccessToPartnerEdit || hasAccessToPartnerShow)
                    && !isCallCenterRole
                    && !isManagerRole
                    && !isSalesManagerRole
                    && !isAdminRole
                    && !isCreditManagerRole
                  ) && (
                    <NavLink to={pageLinks.profilePartner}>
                      <MenuItem onClick={handleCloseMenu}>
                        <ListItemIcon>
                          <PenMenuOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Редактировать анкету')} />
                      </MenuItem>
                    </NavLink>
                  )}
                  <MenuItem onClick={userLogout}>
                    <ListItemIcon>
                      <ExitIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Выход')} />
                  </MenuItem>
                </Menu>
              </Button>
            </ClickAwayListener>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onlyLogo: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  LogoMobile: PropTypes.string,
  logoAltText: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.func,
  inApprove: PropTypes.bool,
  hasAccessToPartnerEdit: PropTypes.bool,
  hasAccessToPartnerShow: PropTypes.bool,
  hasAccessToNotification: PropTypes.bool,
  hasSupportMessageCreate: PropTypes.bool,
  supportManager: PropTypes.object,
  statusItems: PropTypes.object,
  notificationsNotReadCount: PropTypes.number,
  userLogout: PropTypes.func,
  userName: PropTypes.string,
  partnerBalanceLastUpdate: PropTypes.string,
  signInButton: PropTypes.bool,
  signUpButton: PropTypes.bool,
  isHideLangMenu: PropTypes.bool,
};

Header.defaultProps = {
  hasAccessToNotification: false,
  hasSupportMessageCreate: false,
  notificationsNotReadCount: 0,
  inApprove: true,
  supportManager: {},
  userName: '',
};

// Redux
function mapStateToProps(state) {
  return {
    hasAccessToPartnerEdit: selectors.user.accessList(state).includes(accessList.partnerEdit),
    hasAccessToPartnerShow: selectors.user.accessList(state).includes(accessList.partnerShow),
    hasAccessToNotification: selectors.user.accessList(state).includes(accessList.notificationCenter),
    hasSupportMessageCreate: selectors.user.accessList(state).includes(accessList.supportMessageCreate),
    notificationsNotReadCount: selectors.statistics.notificationsNotReadCount(state),
    inApprove: selectors.ui.inApprove(state),
    supportManager: selectors.user.userInfo(state).supportManager,
    userName: selectors.user.userInfo(state).name,
    statusItems: selectors.billing.statusItems(state),
    partnerBalanceLastUpdate: selectors.statistics.partnerBalanceLastUpdate(state),
  };
}
export default connect(mapStateToProps)(Header);
