// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
// Parts
import Paper from '@mui/material/Paper';
import { Grid, VirtualTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import NoDataCell from '../../../../../components/_Table/DxTable/components/NoDataCell';
import DateTimeTypeProvider from './components/DateTimeTypeProvider';
import PaymentTypeProvider from './components/PaymentTypeProvider';
// Helpers
import { useTableRows } from './hooks/useTableRows';
import { locale } from '../../../../../../engine/init/i18n';
import { useStyles } from '../../../../../hooks/useStyles';

const getRowId = row => row.id;

const LastChargesTableStyles = () => ({
  paper: {
    position: 'relative',
    flexGrow: '1',
    minHeight: '350px',
    '& table.MuiTable-root': {
      width: 'auto',
    },
  },
});
function LastChargesTable() {
  const { t, i18n } = useTranslation();
  const currentLocale = locale[i18n.language];
  const classes = useStyles(LastChargesTableStyles);
  const columnNames = {
    createdAt: 'createdAt',
    paymentType: 'paymentType',
    transaction: 'transaction',
  };
  const columns = [
    { name: columnNames.createdAt, title: t('Дата операции') },
    { name: columnNames.paymentType, title: t('Тип операции') },
    { name: columnNames.transaction, title: t('Начисления, грн') },
  ];
  const { getRowsData, rows, isLoading } = useTableRows();
  const [paymentColumns] = useState([columnNames.paymentType]);
  const [dateTimeColumns] = useState([columnNames.createdAt]);

  const containerClassNames = cx(classes.paper, { 'no-table-data': !rows.length });

  useEffect(() => {
    getRowsData();
  }, [getRowsData, currentLocale]);

  return (
    <Paper
      className={containerClassNames}
    >
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <PaymentTypeProvider for={paymentColumns} />
        <DateTimeTypeProvider for={dateTimeColumns} />
        <VirtualTable
          height={300}
          noDataCellComponent={dataCell => <NoDataCell colSpan={dataCell.colSpan} isLoading={isLoading} />}
        />
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
}

export default LastChargesTable;
