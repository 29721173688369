// Core
import React from 'react';
import { string, object, bool } from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
// Icons
import LayersClearIcon from '@mui/icons-material/LayersClear';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { accessList } from '../../../../engine/config/access';
import { pageLinks } from '../../../../engine/config/routes';
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';
import { usePartnerRole } from '../../../hooks/usePartnerRole';

function OrderShowLink(props) {
  const { t } = useTranslation();
  const {
    classes, hashId, canUnbindShippingTracking, isInstallmentsPayment,
  } = props;
  const hasAccessOrderShow = useAccessList(accessList.orderShow);
  const hasAccessOrderUnbindShippingTracking = useAccessList(accessList.orderUnbindShippingTracking);
  const isPartner = usePartnerRole();
  const tooltipText = (isPartner && isInstallmentsPayment) ? `${t('Посмотреть заказ')} / ${t('Вернуть деньги')}` : t('Посмотреть заказ');

  if (!hasAccessOrderShow) {
    return null;
  }

  const orderShowUrl = pageLinks.orders.show(hashId);
  const iconButtonClassName = cx(classes.noPadding);

  return (
    <Tooltip title={tooltipText} placement={isPartner ? 'right' : 'bottom'}>
      <NavLink to={orderShowUrl}>
        <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" className={iconButtonClassName} size="large">
          { canUnbindShippingTracking && hasAccessOrderUnbindShippingTracking
            ? <LayersClearIcon sx={{ p: '2px' }} />
            : <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
          }
        </IconButtonStyles>
      </NavLink>
    </Tooltip>
  );
}

OrderShowLink.propTypes = {
  classes: object,
  hashId: string,
  canUnbindShippingTracking: bool,
  isInstallmentsPayment: bool,
};

OrderShowLink.defaultProps = {
  classes: {},
  hashId: '',
};

const styles = {
  noPadding: {
    padding: 0,
  },
};

export default withStyles(styles)(OrderShowLink);
