import { getIn, List, Map } from 'immutable';
import { storeKeys as mailKeys } from './reducers';

export const mail = Object.freeze({
  isLoading: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplateList, mailKeys.pending], false),
  mailTemplatesEvents: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplatesEvents], []),
  mailTemplatesRoles: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplatesRoles, mailKeys.item], List()),
  mailTemplatesGroups: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplatesGroups, mailKeys.item], List()),
  mailTemplateByHashIdPending: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplateByHashId, mailKeys.pending], true),
  mailTemplateByHashIdItem: state => getIn(state, [mailKeys.controller, mailKeys.mailTemplateByHashId, mailKeys.item], Map()),
  putMailTemplateByHashIdPending: state => getIn(state, [mailKeys.controller, mailKeys.putMailTemplateByHashIdPending], false),
});
