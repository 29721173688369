// Core
import React, {
  useEffect, useRef, useState, memo,
} from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
// Parts
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import OrdersOverdueSidebarItem from '../OrdersOverdueSidebarItem';
import OrdersNewSidebarItem from '../OrdersNewSidebarItem';
import SupportNewSidebarItem from '../SupportNewSidebarItem';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../engine/config/routes';
import { uiActions } from '../../../../../engine/core/ui/action';
// Helpers
import { useStyles } from '../../../../hooks/useStyles';
import { useMDView } from '../../../../hooks/useDesktopView';

import SidebarItemStyles from './SidebarItemStyle';
import useCheckUserStatus from '../../../../hooks/useUserSomeStatus';
import { partnerStatuses } from '../../../../../engine/config/partnerStatuses';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../engine/config/userRoles';

function SidebarItem(props) {
  const {
    route, index, activeRoute,
    toggleMenu, isMenuOpened, setActiveRoute,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMDView = useMDView();
  const classes = useStyles(SidebarItemStyles);
  const accessLists = useSelector(selectors.user.accessList);
  const config = useSelector(selectors.user.configurations);
  const rolesList = useSelector(selectors.user.rolesList);
  const itemRef = useRef(null);
  const isCurrentRouteActive = activeRoute === index;
  const [menuPosition, setMenuPosition] = useState('top');
  const [miniListVisible, setMiniListVisible] = useState(false);
  const location = useLocation();

  const hasAdminAccess = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.contentManager,
  ]);

  const containerClassNames = cx({
    [classes.menuCollapsed]: isCurrentRouteActive,
    [classes.menuClosed]: !isCurrentRouteActive,
  });

  const classNameWrapper = cx(classes.menuItem, {
    [classes.menuActive]: location.pathname.startsWith(route.path) && !isMenuOpened,
    [classes.menuLabel]: !isMenuOpened && activeRoute === index,
  });

  const classNameList = cx({
    [classes.miniMenuList]: !isMenuOpened,
    [classes.miniMenuListBottom]: !isMenuOpened && menuPosition === 'bottom',
  });

  useEffect(() => {
    if (location.pathname.startsWith(route.path) && isMenuOpened) {
      setActiveRoute(index);
    }
  }, [location.pathname, route.path, isMenuOpened, index]);

  useEffect(() => {
    setMiniListVisible(false);
  }, [index, isMenuOpened]);

  useEffect(() => {
    const el = itemRef.current;
    if (isCurrentRouteActive && el) {
      const position = el.getBoundingClientRect();
      if (position.bottom > document.body.clientHeight) {
        setMenuPosition('bottom');
      }
    }
  }, [isCurrentRouteActive]);

  const handleOpenMiniMenu = () => {
    if (!isMenuOpened) {
      setMiniListVisible(true);
      setMenuPosition('top');
      toggleMenu(index);
    }
  };
  const handleCloseMiniMenu = () => {
    if (!isMenuOpened) {
      setMiniListVisible(false);
      toggleMenu(null);
    }
  };

  const handleListItem = () => {
    if (isMenuOpened) {
      toggleMenu(index);
    }
  };

  const handleNavClick = () => {
    if (isMobile || isMDView) {
      dispatch(uiActions.setSidebarOpen(false));
      setMiniListVisible(false);
      toggleMenu(null);
    }
  };

  const partnerHasAccess = useCheckUserStatus([
    partnerStatuses.active,
    partnerStatuses.blocked,
    partnerStatuses.suspended,
    partnerStatuses.problematic,
  ]);

  const alloExpressData = config.get('ALLO_EXPRESS_ALLOW_TO_USE');
  const hasAllowToCreateRequest = alloExpressData && alloExpressData.has('allow_to_create_request');
  const hasAccessToAlloExpress = hasAllowToCreateRequest
    ? alloExpressData.get('allow_to_create_request')
    : (partnerHasAccess || hasAdminAccess);

  if (route.featureFlag && route.featureFlag === 'ALLO_EXPRESS' && !hasAccessToAlloExpress) {
    return;
  }

  // Render submenu
  if (route.type === 'submenu') {
    // Check access
    if (!accessLists.some(items => route.accessGroup.includes(items))) {
      return false;
    }

    return (
      <div
        className={containerClassNames}
        onMouseEnter={handleOpenMiniMenu}
        onMouseLeave={handleCloseMiniMenu}
        id={(route.path === '/products' && (!isMenuOpened || containerClassNames.includes(classes.menuClosed))) && 'linkToProduct'}
      >
        <ListItem
          className={classNameWrapper}
          button
          key={index}
          onClick={handleListItem}
        >
          <ListItemIcon className={classes.menuIcon}>
            <route.icon />
          </ListItemIcon>
          {isMenuOpened && (
            <>
              <Typography variant="body1" className={classes.menuText}>{t(route.name)}</Typography>
              <ListItemIcon className={classes.caret}>
                {isCurrentRouteActive ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
              </ListItemIcon>
            </>
          )}
        </ListItem>
        <Collapse in={isCurrentRouteActive} timeout="auto" unmountOnExit>
          <List disablePadding ref={itemRef} className={classNameList}>
            <Box className={cx({ [classes.miniMenuListBox]: !isMenuOpened, [classes.miniMenuListBoxActive]: miniListVisible })}>
              {!isMenuOpened && (
              <ListItem className={cx([classes.menuSubItem, classes.menuLabel])}>
                <Typography variant="body1" className={classes.menuTextCollapsed}>{t(route.name)}</Typography>
              </ListItem>
              )}
              {route.children.map((subItem, i) => {
                if (subItem.featureFlag && subItem.featureFlag === 'ALLO_EXPRESS' && !hasAccessToAlloExpress) {
                  return null;
                }
                if (
                  (typeof subItem.off === 'boolean' && subItem.off)
                  || (Array.isArray(route.off) && route.off.some(offRole => rolesList.includes(offRole)))
                ) {
                  return null;
                }
                // users have access to link in lists && link not free && hidden
                if ((subItem.accessName !== '' && !accessLists.includes(subItem.accessName))
                  || subItem.hide
                  || !isEmpty(subItem.configParams) && isEmpty(config.get(subItem.configParams))) {
                  return false;
                }

                const linkTo = `${route.path ? route.path : ''}${subItem.path ? subItem.path : ''}`;

                let NavLinkItem = null;

                switch (linkTo) {
                  case pageLinks.orders.overdue: {
                    NavLinkItem = (
                      <OrdersOverdueSidebarItem
                        classes={classes}
                        itemName={t(subItem.name)}
                        type="primary"
                      />
                    );
                    break;
                  }
                  case pageLinks.orders.new: {
                    NavLinkItem = (
                      <OrdersNewSidebarItem
                        classes={classes}
                        itemName={t(subItem.name)}
                        type="primary"
                      />
                    );
                    break;
                  }
                  case pageLinks.serviceDesk.support: {
                    NavLinkItem = (
                      <SupportNewSidebarItem
                        classes={classes}
                        itemName={t(subItem.name)}
                        type="primary"
                      />
                    );
                    break;
                  }
                  default: {
                    NavLinkItem = (
                      <ListItem className={classes.menuSubItem} button>
                        <Typography variant="body1" className={classes.menuText}>
                          {t(subItem.name)}
                        </Typography>
                      </ListItem>
                    );
                  }
                }

                return (
                  <NavLink
                    to={linkTo}
                    id={(linkTo === '/products/content' && isMenuOpened) && 'linkToProduct'}
                    className={({ isActive }) => {
                      const isNotPageLvl3 = location.pathname.split('/').length - 1 < 4;
                      const isEditOrShowPage = location.pathname.includes('show') || location.pathname.includes('edit');
                      const isActiveChild = location.pathname.startsWith(linkTo) && isEditOrShowPage && isNotPageLvl3;
                      const activeClass = isActive || isActiveChild
                        ? classes.menuActive
                        : undefined;
                      return `${classes.menuLink} ${activeClass}`;
                    }}
                    key={i}
                    onClick={handleNavClick}
                  >
                    {NavLinkItem}
                  </NavLink>
                );
              })}
            </Box>
          </List>
        </Collapse>
      </div>
    );
  }

  // Render link
  if (!accessLists.includes(route.accessName) && route.accessName !== 'free') {
    return false;
  }
  return (
    <NavLink
      to={route.path}
      exact
      className={({ isActive }) => `${classes.menuLink} ${isActive ? classes.menuActive : undefined}`}
      key={index}
      onClick={handleNavClick}
    >
      <ListItem className={classes.menuItem} button onClick={() => toggleMenu(index)}>
        <ListItemIcon className={classes.menuIcon}>
          <route.icon />
        </ListItemIcon>
        {isMenuOpened && <Typography variant="body1" className={classes.menuText}>{t(route.name)}</Typography>}
      </ListItem>
    </NavLink>
  );
}

SidebarItem.propTypes = {
  route: PropTypes.object,
  index: PropTypes.number,
  activeRoute: PropTypes.number,
  toggleMenu: PropTypes.func,
  setActiveRoute: PropTypes.func,
  isMenuOpened: PropTypes.bool,
};

export default memo(SidebarItem);
