// Core
import React, {
  useCallback, useState, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { reduxForm, Form, SubmissionError } from 'redux-form/immutable';
// import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { isImmutable } from 'immutable';
import get from 'lodash/get';
// Icons
import SendIcon from '@mui/icons-material/Send';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Parts
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import ButtonPaymentAdd from '../../../components/Buttons/ButtonPaymentAdd';
import PartnerEditSystem from '../components/PartnerEditSystem';
import PartnerEditSite from '../components/PartnerEditSite/PartnerEditSite';
import PartnerEditFinance from '../components/PartnerEditFinance';
import PartnerEditPaymentTypesData from '../components/PartnerEditPaymentTypesData';
import PartnerEditContract from '../components/PartnerEditContract';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import TabItemMenu from '../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsNav from '../../../containers/Tabs/TabsNav';
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import TabsNavMenu from '../../../containers/Tabs/TabsNavMenu';
// Engine
import i18n from '../../../../engine/init/i18n';
import { userRoles } from '../../../../engine/config/userRoles';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import { pageLinks } from '../../../../engine/config/routes';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';
import { asyncActions as categoriesAsyncActions } from '../../../../engine/core/categories/saga/asyncActions';
// Helpers
import ProfilePartnerStyle from './ProfilePartnerStyle';
import { useAccessList } from '../../../hooks/useAccessList';
import { setWarningMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { formName } from '../form';
import { usePartnerTabs } from '../hooks/usePartnerTabs';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import paymentTypesDocsModalFields from '../components/PaymentTypesDocsModal/fields';
import { useStyles } from '../../../hooks/useStyles';
import { useCallbackAfterPostUser } from '../../../hooks/useCallbackAfterPostUser';


function ProfilePartner(props) {
  const {
    handleSubmit, pristine,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(ProfilePartnerStyle);
  const dispatch = useDispatch();

  const userConfigurations = useSelector(selectors.user.configurations);
  const partnerData = useSelector(selectors.partner.userData);
  const hashId = useSelector(selectors.partner.partnerHashId);
  const rolesList = useSelector(selectors.user.rolesList);
  const userSubmitting = useSelector(selectors.user.userSubmitting);
  const partnerDownloadContractCommissionIsLoading = useSelector(selectors.partner.partnerDownloadContractCommissionIsLoading);
  const partnerSavePending = useSelector(selectors.partner.partnerSavePending);
  const partnerContractLink = useSelector(selectors.partner.partnerContractLink);
  const hasAccessPartnerOfferContract = useAccessList(accessList.partnerOfferContract);
  const hasAccessPartnerEdit = useAccessList(accessList.partnerEdit);

  const isPartnerRoles = useUserSomeRole([userRoles.partner, userRoles.partnerManageProfile, userRoles.partnerActive, userRoles.partnerRating, userRoles.partnerContentManager, userRoles.partnerBilling]);

  const canShowProfile = rolesList.includes(userRoles.partnerPaymentWaiting) || rolesList.includes(userRoles.partnerManageProfile) || rolesList.includes(userRoles.partnerActive) || rolesList.includes(userRoles.partner) || rolesList.includes(userRoles.partnerSigningContract);
  const [selectedTab, setSelectedTab] = useState('system');
  const hasAccessContractPercentOfferDownload = useAccessList(accessList.contractPercentOfferDownload);
  const linkContractPercentOffer = userConfigurations.get('CONTRACT_PERCENT_OFFER');
  const linkContractPartner = userConfigurations.get('CONTRACT_PARTNER');
  const partnerStatus = get(partnerData.partnerStatus, 'value');
  const infoFieldPaymentValuesData = partnerData?.infoFieldPaymentValues;
  const tabs = usePartnerTabs();

  useCallbackAfterPostUser(() => {
    dispatch(partnerAsyncAction.getPartnerInfo({ hashId }));
  }, []);

  const handleChangeTab = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);

  const displayTab = useCallback(
    (currentTab) => {
      const isCurrentTabSelected = selectedTab === currentTab;
      return isCurrentTabSelected ? 'flex' : 'none';
    },
    [selectedTab],
  );

  const downloadContractCommission = () => {
    if (typeof hashId === 'string') {
      dispatch(partnerAsyncAction.getPartnerContractCommissionDownloadAsync(hashId));
    }
  };

  const onFormSubmit = useCallback(
    (formData) => {
      if (isImmutable(formData)) {
        const categoriesKey = 'categories';
        const jsFormData = formData.toJS();
        const categoriesPercent = get(jsFormData, 'categoriesPercent');
        const categoriesPercentValue = get(jsFormData, 'categoriesPercentValue');
        const categories = get(jsFormData, categoriesKey);

        if (categoriesPercent && categoriesPercentValue) {
          // Set new percentValue from input
          categoriesPercent.map((item) => {
            const newItem = item;
            Object.keys(categoriesPercentValue).forEach((itemKey) => {
              if (item.value === parseInt(itemKey, 10)) {
                newItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
              }
            });
            return newItem;
          });
        }

        const currentCategoryData = categories.map((item) => {
          let currentItem = item;
          if (categoriesPercentValue) {
            Object.keys(categoriesPercentValue).forEach((itemKey) => {
              if (item.value === parseInt(itemKey, 10)) {
                currentItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
              }
            });
          }
          if (categoriesPercent) {
            categoriesPercent.map((percentItem) => {
              if (percentItem.value === item.value) {
                currentItem = percentItem;
              }
              return percentItem;
            });
          }
          delete currentItem.partnerOfferPercentOld;
          delete currentItem.partnerOfferPercentApproved;
          return currentItem;
        });


        let warehouses = jsFormData?.warehouses.map(item => item?.label ?? item) ?? [];
        const mainWarehouse = jsFormData.mainWarehouse?.label ?? jsFormData?.mainWarehouse;

        const errors = {};
        errors.warehouses = [];
        // eslint-disable-next-line no-underscore-dangle
        errors._error = 'submit error';

        if (isPartnerRoles) {
          warehouses.forEach((item, index) => {
            if (!item) {
              errors.warehouses[index] = t('Выберите город расположения склада товаров');
              throw new SubmissionError(errors);
            }
            if (!item) {
              errors.warehouses[index] = t('Пожалуйста, проверьте правильность ввода города');
              throw new SubmissionError(errors);
            }
          });

          if (!mainWarehouse) {
            throw new SubmissionError({
              mainWarehouse: t('Выберите город расположения склада товаров'),
              _error: 'submit error',
            });
          }

          if (!mainWarehouse) {
            throw new SubmissionError({
              mainWarehouse: t('Пожалуйста, проверьте правильность ввода города'),
              _error: 'submit error',
            });
          }
        } else {
          warehouses = warehouses.filter(item => typeof item === 'string' ? !!item.trim() : !!item);
        }

        if (!jsFormData.orderProcessingSchedule.enabled) {
          throw new Error({
            _error: 'submit error',
          });
        }

        const data = {
          accessBlockContent: jsFormData.accessBlockContent,
          accessFeed: jsFormData.accessFeed,
          accessBilling: jsFormData.accessBilling,
          accessRatingRatio: jsFormData.accessRatingRatio,
          isTop: jsFormData.isTop,
          brands: jsFormData.brands,
          categories: currentCategoryData,
          infoFieldsValues: {
            companyName: jsFormData.companyName,
            site: jsFormData.site,
            // TODO: change or delete this value (add constant because of emergency http://jira.nautilus.allo.ua/browse/AMP-625)
            deliveryTime: jsFormData.deliveryTime ? jsFormData.deliveryTime : '1-3 дня',
            deliveryTimeFrom: 1,
            deliveryTimeTo: 3,
            detailedInformation: jsFormData.detailedInformation,
            partnerInfo: jsFormData.partnerInfo,
            warrantyAndReturn: jsFormData.warrantyAndReturn,
            enterpriseName: jsFormData.enterpriseName,
            address: jsFormData.address,
            addressIndex: jsFormData.addressIndex,
            city: jsFormData.city,
            enterpriseClassifierCode: jsFormData.enterpriseClassifierCode,
            taxIdentification: jsFormData.taxIdentification,
            enterpriseGroupCode: jsFormData.enterpriseGroupCode,
            automaticVoucherGeneration: jsFormData.automaticVoucherGeneration,
            enterpriseType: jsFormData.enterpriseType,
            formOfOwnership: jsFormData.formOfOwnership,
            formOfOwnershipSelect: jsFormData.formOfOwnership,
            taxationType: jsFormData.taxationType,
            iban: jsFormData.iban,
            bankName: jsFormData.bankName,
            interBranchTurnover: jsFormData.interBranchTurnover,
            bankClassifierCode: jsFormData.bankClassifierCode,
            directorName: jsFormData.directorName,
            directorNameAdditional: jsFormData.directorNameAdditional,
            directorPhone: jsFormData.directorPhone,
            financialManagerName: jsFormData.financialManagerName,
            financialManagerPhone: jsFormData.financialManagerPhone,
            contactNameAndPosition: jsFormData.contactNameAndPosition,
            contactPhone: jsFormData.contactPhone,
            contactEmail: jsFormData.contactEmail,
            smallLogo: jsFormData.smallLogo,
            workSchedule: jsFormData.workSchedule,
            contactPhone1: jsFormData.contactPhone1,
            contactPhone2: jsFormData.contactPhone2,
            contactName1: jsFormData.contactName1,
            contactName2: jsFormData.contactName2,
            warehouses: [mainWarehouse, ...warehouses].filter(Boolean),
            orderProcessingSchedule: jsFormData.orderProcessingSchedule,
          },
        };

        if (jsFormData.isTaxAdditionalCostNumber === 'Да' || jsFormData.isTaxAdditionalCostNumber === 'Так') {
          data.infoFieldsValues.isTaxAdditionalCostNumber = true;
          data.infoFieldsValues.taxAdditionalCostNumber = jsFormData.taxAdditionalCostNumber;
        } else if (jsFormData.isTaxAdditionalCostNumber === 'Нет' || jsFormData.isTaxAdditionalCostNumber === 'Ні') {
          data.infoFieldsValues.isTaxAdditionalCostNumber = false;
        }

        if (!isPartnerRoles) {
          data.infoFieldsValues.activityType = jsFormData.activityType;
          data.infoFieldsValues.hasOnlineStore = jsFormData.hasOnlineStore;
          data.infoFieldsValues.hasServiceCenter = jsFormData.hasServiceCenter;
          data.infoFieldsValues.hasContactCenter = jsFormData.hasContactCenter;
          data.infoFieldsValues.planToSell = jsFormData.planToSell;
          data.infoFieldsValues.readyToYmlFormat = jsFormData.readyToYmlFormat;
          data.infoFieldsValues.yourselfInformation = jsFormData.yourselfInformation;
          data.infoFieldsValues.ymlPriceLink = jsFormData.ymlPriceLink;
          if (jsFormData.beenAlloSupplier === 'Так' && jsFormData.beenAlloSupplierName) {
            data.infoFieldsValues.beenAlloSupplier = `${jsFormData.beenAlloSupplier}, ${jsFormData.beenAlloSupplierName}`;
          } else if (jsFormData.beenAlloSupplier === 'Так') {
            data.infoFieldsValues.beenAlloSupplier = jsFormData.beenAlloSupplier;
          } else {
            data.infoFieldsValues.beenAlloSupplier = jsFormData.beenAlloSupplier;
          }
        }

        Object.keys(jsFormData).forEach((key) => {
          if (key.includes('paymentTypes_')) {
            if (jsFormData[key]) {
              const infoFieldPaymentValues = {};
              Object.keys(paymentTypesDocsModalFields).forEach((infoFieldPaymentValuesKey) => {
                infoFieldPaymentValues[infoFieldPaymentValuesKey] = data[infoFieldPaymentValuesKey];
              });
              data.infoFieldPaymentValues = infoFieldPaymentValues;
            }
            if (formData?.partnerPaymentTypes === undefined) {
              data.partnerPaymentTypes = { [key.substring('paymentTypes_'.length)]: jsFormData[key] };
            } else {
              data.partnerPaymentTypes[key.substring('paymentTypes_'.length)] = jsFormData[key];
            }
          }
        });

        if (typeof hashId === 'string') {
          dispatch(partnerAsyncAction.putPartnerInfoAsync({
            hashId,
            formData: data,
            userRoles: rolesList,
            isProfilePartnerPage: true,
          }));
        }
      }
    },
    [hashId, isPartnerRoles, rolesList],
  );

  useEffect(() => {
    if (typeof hashId === 'string' && hashId.trim() !== '' && userSubmitting === false) {
      dispatch(partnerAsyncAction.getPartnerInfo({ hashId }));
    }
  }, [hashId, userSubmitting]);

  useEffect(() => {
    dispatch(categoriesAsyncActions.getCategoriesListContextAsync({ loadRootCategories: true, parentValue: 0 }));
    // getCategoriesListContextAsync({ loadRootCategories: true, parentValue: 0 });
  }, []);

  if (!canShowProfile) {
    return <Navigate to={pageLinks.profileUser} />;
  }
  const canDownloadContract = get(partnerData, 'canDownloadContract');
  const isActivePartner = partnerStatus === partnerStatuses.active;
  const isSED = get(partnerData, 'isSED');
  const isPartnerFinishRegistration = !(partnerStatus === partnerStatuses.notConfirmCode || partnerStatus === partnerStatuses.questionnaireFill);
  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={t('Анкета партнера')}>
          <ButtonPaymentAdd />
          {hasAccessPartnerOfferContract && canDownloadContract && (
            <ButtonWithIconAndCircularProgress
              text={isSED ? t('Лицензионный договор') : t('Скачать заявление на присоединение')}
              disabled={partnerDownloadContractCommissionIsLoading}
              isLoading={partnerDownloadContractCommissionIsLoading}
              onClick={downloadContractCommission}
            >
              <CloudDownloadIcon />
            </ButtonWithIconAndCircularProgress>
          )}
          {hasAccessContractPercentOfferDownload && linkContractPercentOffer && (
            <ButtonWithIcon
              component="a"
              href={linkContractPercentOffer}
              target="_blank"
              text={t('Скачать договор процентов оферты')}
            >
              <CloudDownloadIcon />
            </ButtonWithIcon>
          )}
          {partnerStatus
            && partnerStatus !== partnerStatuses.active
            && partnerStatus !== partnerStatuses.contractSigning
            && partnerStatus !== partnerStatuses.awaitingPayment
            && linkContractPartner && ( // TODO переделать проверку на асксс лист
              <ButtonWithIcon
                component="a"
                href={linkContractPartner}
                target="_blank"
                text={t('Скачать шаблон заявления на присоединение')}
              >
                <CloudDownloadIcon />
              </ButtonWithIcon>
          )}
          {hasAccessPartnerEdit && (
            <ButtonWithIcon
              text={isPartnerFinishRegistration ? t('Сохранить изменения') : t('Отправить анкету на проверку')}
              disabled={isPartnerFinishRegistration ? (pristine || partnerSavePending) : partnerSavePending}
              component="button"
              type="button"
              onClick={handleSubmit(onFormSubmit)}
            >
              {isPartnerFinishRegistration ? (<SaveIcon />) : (<SendIcon />)}
            </ButtonWithIcon>
          )}
        </PageHeader>
        <TabsContainer>
          <TabsNav>
            <TabsNavMenu
              value={selectedTab}
              onChange={handleChangeTab}
            >
              <TabItemMenu value={tabs.system} label={t('Информация в систему')} />
              <TabItemMenu value={tabs.site} label={t('Информация на сайт')} />
              <TabItemMenu value={tabs.finance} label={t('Информация для фин. сверки')} />
              {infoFieldPaymentValuesData && <TabItemMenu value={tabs.paymentTypesData} label={t('Данные для договоров')} />}
              {(isActivePartner || partnerStatus === partnerStatuses.awaitingPayment || partnerStatus === partnerStatuses.contractSigning) && partnerContractLink
                && <TabItemMenu value={tabs.contract} label={t('Договор')} />}
            </TabsNavMenu>
          </TabsNav>
          <PartnerEditSystem
            mode={hasAccessPartnerEdit === false ? 'show' : undefined}
            classes={classes}
            display={displayTab(tabs.system)}
            hashId={hashId}
            index={tabs.system}
            value={selectedTab}
          />
          <PartnerEditSite
            mode={hasAccessPartnerEdit === false ? 'show' : undefined}
            classes={classes}
            display={displayTab(tabs.site)}
            hashId={hashId}
            // handleUploadLogo={uploadPartnerLogo}
            userData={partnerData}
            index={tabs.site}
            value={selectedTab}
          />
          <PartnerEditFinance
            mode={hasAccessPartnerEdit === false ? 'show' : undefined}
            display={displayTab(tabs.finance)}
            userData={partnerData}
            classes={classes}
            index={tabs.finance}
            value={selectedTab}
          />
          {infoFieldPaymentValuesData
            ? (
              <PartnerEditPaymentTypesData
                display={displayTab(tabs.paymentTypesData)}
                classes={classes}
                mode="show"
                index={tabs.paymentTypesData}
                value={selectedTab}
              />
            ) : null}
          <PartnerEditContract
            display={displayTab(tabs.contract)}
            classes={classes}
            index={tabs.contract}
            value={selectedTab}
          />
        </TabsContainer>
      </Grid>
    </Form>
  );
}

ProfilePartner.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
};

export default
reduxForm({
  form: formName,
  onSubmitFail: (errors, dispatch, submitError) => {
    const warnings = submitError instanceof SubmissionError
      ? i18n.t('Пожалуйста, выберите правильные значения для всех полей!')
      : i18n.t('Пожалуйста, заполните все обязательные поля!');
    dispatch(setWarningMessage(warnings, i18n.t('Ошибка валидации')));
  },
})(ProfilePartner);
