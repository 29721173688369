// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import { tableDataTypes } from '../../../../../../_helpers/data/tableDataTypes';

export function* callGetListWorker({ payload }) {
  const { clearList, ...params } = payload;

  yield put(actions.setList({ pending: true }));
  const normalizeParams = params?.orderBy?.includes(tableDataTypes.featureRequiredLkValue.name)
    ? {
      ...params,
      orderBy: params.orderBy.replace(tableDataTypes.featureRequiredLkValue.name, tableDataTypes.featureRequiredLk.name),
    }
    : params;
  const response = yield apply(api, api.categoriesFeatureManagement.getCategoriesFeaturesItemByHashId, [normalizeParams]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }

  if (clearList) {
    yield put(actions.setFeatureRequiresLK(List()));
  }

  yield put(actions.setList(data));
}
