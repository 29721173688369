// Core
import { apply, put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { api } from '../../../../../config/api';
import { actions } from '../../actions';
import {
  formName,
} from '../../../../../../ui/page/Products/PriceLists/Item/components/ProductFeedParametersTable/ProductFeedParametersTable';
import { selectors } from '../../../../../config/selectors';

export function* callGetListWorker({ payload }) {
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.productFeedParameters.getList, [payload]);
  const isItemByHashIdItem = yield select(selectors.feeds.isItemByHashIdItem);
  const stepsData = isItemByHashIdItem.get('stepsData');

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    const fieldsValues = {};
    data = Object.assign(data, response.data);
    data.items.forEach((row) => {
      if (row.parameter?.value) {
        fieldsValues[`${row.id}_parameter`] = row.parameter?.value;
      }
      if (row.type?.value) {
        fieldsValues[`${row.id}_type`] = row.type?.value;
      } else {
        const availableTypes = row.parameter?.availableTypes;
        const isCanDisable = row.canDisable;
        const availableOptions = Object.keys(stepsData[1]?.parameters)
          .map((key) => {
            const parameterItem = stepsData[1]?.parameters?.[key];
            return isCanDisable === false && parameterItem?.values?.length === 0
              ? undefined
              : ({
                value: key,
                label: parameterItem.label,
              });
          })
          .filter((item) => {
            const checkItem = item !== undefined;
            return availableTypes === undefined
              ? checkItem
              : Boolean(checkItem && availableTypes.includes(+item.value));
          });

        fieldsValues[`${row.id}_type`] = availableOptions.length === 1
          ? availableOptions[0].value
          : undefined;
      }
      if (row.productFeedParameter?.value) {
        fieldsValues[`${row.id}_productFeedParameter`] = row.productFeedParameter?.value;
      }
    });
    if (Object.keys(fieldsValues).length) {
      yield put(initialize(formName, fieldsValues, Object.keys(fieldsValues)));
    }
  }

  yield put(actions.setList(data));
}
