import { getIn, List } from 'immutable';

// Store keys
import { stateKeys as commissionKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const commissions = Object.freeze({
  currentPage: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.currentPage], 0),
  filters: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.filters], List()),
  isLoading: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.pending], false),
  list: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.items], List()),
  pageSize: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.reload], false),
  totalCount: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.totalCount], 0),
  sorting: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.sorting], List()),
  settingStatus: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.settingStatus], false),
  settingPending: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionList, commissionKeys.settingPending], false),
  commissionDownloadPending: state => getIn(state, [commissionKeys.controller, commissionKeys.commissionDownloadPending], false),
});
