export const types = Object.freeze({
  SET_BILLING_PARTNERS_STATUS_LIST: 'SET_BILLING_PARTNERS_STATUS_LIST',
  SET_BILLING_PARTNERS_STATUS_CURRENT_PAGE: 'SET_BILLING_PARTNERS_STATUS_CURRENT_PAGE',
  SET_BILLING_PARTNERS_STATUS_FILTERS: 'SET_BILLING_PARTNERS_STATUS_FILTERS',
  SET_BILLING_PARTNERS_STATUS_PAGE_SIZE: 'SET_BILLING_PARTNERS_STATUS_PAGE_SIZE',
  SET_BILLING_PARTNERS_STATUS_SORTING: 'SET_BILLING_PARTNERS_STATUS_SORTING',
  SET_BILLING_PARTNERS_STATUS_INITIAL_STATE: 'SET_BILLING_PARTNERS_STATUS_INITIAL_STATE',
  SET_BILLING_PARTNERS_STATUS_SETTING_STATUS: 'SET_BILLING_PARTNERS_STATUS_SETTING_STATUS',
  SET_BILLING_PARTNERS_STATUS_SETTING_PENDING: 'SET_BILLING_PARTNERS_STATUS_SETTING_PENDING',
});
