import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import each from 'lodash/each';
import i18n from '../engine/init/i18n';
import { store } from '../engine/init/store';
import { setErrorMessage } from '../engine/core/ui/saga/asyncActionNotificationMessages';

export const exepFields = ['contactPhone1', 'contactPhone2', 'contactName1', 'contactName2'];

export function checkPartnerApprovedData({ partner, registeredFields, isPartnerRoles = false }) {
  const isNullApprovedValue = fields => !isEmpty(pickBy(fields, data => !isArray(data) && isObject(data) && data.approvedValue === null));
  const onlyShowFields = (fields) => {
    const showFields = {};
    each(fields, (item, key) => {
      if (!exepFields.includes(key) && Object.keys(registeredFields).includes(key)) {
        showFields[key] = item;
      }
    });

    return isNullApprovedValue(showFields);
  };


  if (onlyShowFields(partner.infoFieldValues) && !isPartnerRoles) {
    store.dispatch(setErrorMessage([{
      message: i18n.t('Сначала подтвердите все изменения в анкете'),
    }], i18n.t('Ошибка')));

    return true;
  }

  return false;
}
