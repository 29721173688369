// Core
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// Parts
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/Star';
// Helpers
import { useStyles } from '../../../hooks/useStyles';
import CountCardStyles from './CountCardStyles';

const CountCard = (props) => {
  const {
    type, title, value, color, coloredValue, subTitle, valueDesc, onClick, rating, smallTitle,
  } = props;

  const isFillType = type === 'fill';
  const classes = useStyles(CountCardStyles);
  const counterClassNames = cx(classes.counter, {
    [classes.lightText]: isFillType,
    [classes.counterWithStar]: rating,
    [classes.smallTitle]: smallTitle,
  });
  const lightTextClassNames = cx({ [classes.lightText]: isFillType });
  return (
    <Card classes={{ root: classes.card }} sx={isFillType ? { backgroundColor: color } : null} onClick={onClick}>
      <CardActionArea classes={{ root: classes.card }} component="div">
        <CardContent component="div" classes={{ root: classes.content }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item>
              <div>
                {title && (
                  <Typography
                    variant="caption"
                    className={lightTextClassNames}
                  >
                    {title}
                  </Typography>
                )}
                <Typography variant="subtitle1" sx={{ lineHeight: 1 }} className={lightTextClassNames}>
                  <span className={counterClassNames} style={{ color: coloredValue }}>
                    {value}
                    {rating && <StarIcon className={classes.star} color="primary" />}
                  </span>
                  <Typography
                    className={classes.descriptionText}
                    component="span"
                  >
                    {valueDesc}
                  </Typography>
                </Typography>
                {subTitle && (
                  <Typography
                    variant="caption"
                    className={lightTextClassNames}
                  >
                    {subTitle}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CountCard.propTypes = {
  type: PropTypes.oneOf([
    'fill',
  ]),
  title: PropTypes.string,
  valueDesc: PropTypes.string,
  subTitle: PropTypes.string,
  smallTitle: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element]),
  color: PropTypes.string,
  coloredValue: PropTypes.string,
  onClick: PropTypes.func,
  rating: PropTypes.bool,
};

CountCard.defaultProps = {
  onClick: () => {
  },
};

export default CountCard;
