// Core
import React from 'react';
// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import BlockIcon from '@mui/icons-material/Block';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// Helpers
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import { COLOR } from '../../../../../containers/App/AppStyles';

const StatusIcon = (props) => {
  const { iconName, sx } = props;
  switch (iconName) {
    case 'CheckCircleOutline': {
      return <CheckCircleOutlineIcon sx={{ ...sx, fill: COLOR.success }} />;
    }
    case 'WarningAmber': {
      return <WarningAmberIcon sx={{ ...sx, fill: COLOR.error }} />;
    }
    case 'CompareOutlined': {
      return <CompareOutlinedIcon sx={{ ...sx, fill: COLOR.warning }} />;
    }
    case 'Block': {
      return <BlockIcon sx={{ ...sx, fill: COLOR.inactive }} />;
    }
    case 'HelpOutlineOutlined': {
      return <HelpOutlineOutlinedIcon sx={{ ...sx, fill: COLOR.black.initial }} />;
    }
    case 'autoFixHigh': {
      return <AutoFixHigh sx={{ ...sx, fill: COLOR.blue.main }} />;
    }
    default: {
      return null;
    }
  }
};

export default StatusIcon;
