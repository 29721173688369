// Core
import React, { useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  change, Field, touch,
} from 'redux-form/immutable';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
// Parts
import Box from '@mui/material/Box';
// Components
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormSelect from '../../../../../../../components/_Form/Selects/FormSelect/FormSelect';
import StatusIcon from '../../StatusIcon';
// Helpers
import { formName } from '../ProductFeedParametersTable';
import { validators } from '../../../../../../../../_helpers/validationsForm';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
import { uiActions } from '../../../../../../../../engine/core/ui/action';

function ParametersTableCell(props) {
  const {
    column,
    row,
    value,
    ...restProps
  } = props;
  const isRequired = row.parameter?.required;
  const availableTypes = row.parameter?.availableTypes;
  const isCanDisable = row.canDisable;
  const isDisabled = row.disabled;
  const dispatch = useDispatch();
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const productSelect = useSelector(selectors.ui.isSelectOpen).toJS()?.productParameters;
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const currentType = getFormValues.get(`${row.id}_type`);
  const { stepsData, canEdit } = isItemByHashIdItem.toJS();
  const disabled = !canEdit || isDisabled;
  const optionsTag = useMemo(
    () => Object.keys(stepsData[1]?.parameters)
      .map((key) => {
        const item = stepsData[1]?.parameters?.[key];
        return isCanDisable === false
        && (availableTypes !== undefined && item?.values?.length === 0)
          ? undefined
          : ({
            value: +key,
            label: item.label,
          });
      })
      .filter((item) => {
        const checkItem = item !== undefined;
        return availableTypes === undefined
          ? checkItem
          : Boolean(checkItem && availableTypes.includes(+item.value));
      }),
    [stepsData],
  );

  const optionsProductFeedParameter = useMemo(
    () => stepsData[1]?.parameters?.[currentType]?.values,
    [currentType, stepsData],
  );

  const handleChangeType = () => {
    dispatch(change(formName, `${row.id}_productFeedParameter`, null));
    dispatch(uiActions.setSelectIsOpen({ productParameters: { [row.id]: true } }));
  };

  const handleChangeFeedParameter = () => {
    dispatch(uiActions.setSelectIsOpen({ productParameters: { [row.id]: false } }));
  };

  const handleBlur = () => {
    dispatch(touch(formName, `${row.id}_${column.name}`));
  };

  const handleBlurParameter = () => {
    handleBlur();
    handleChangeFeedParameter();
  };

  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === 'parameter' && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 'auto' }}>
            {value.label} {isRequired && <Box component="span" sx={{ color: COLOR.error }}>*</Box>}
          </Box>
          <Tooltip title={value.tooltipText} arrow>
            <InfoOutlinedIcon sx={{
              cursor: 'pointer',
              fill: COLOR.black['72'],
              marginLeft: '8px',
              marginRight: '2px',
              fontSize: '15px',
            }}
            />
          </Tooltip>
          <Field
            component="input"
            name={`${row.id}_parameter`}
            value={value.value}
            type="hidden"
          />
        </Box>
      )}
      {column.name === 'type' && (
        <Field
          component={FormSelect}
          options={optionsTag}
          fullWidth
          closeMenuOnScroll
          variant="outlined"
          disabled={disabled || (optionsTag.length === 1 && isRequired)}
          handleChange={handleChangeType}
          name={`${row.id}_type`}
          formSelectWidth="100%"
          validation={isRequired}
          validate={isRequired ? [validators.required] : []}
          customOnBlur={handleBlur}
        />
      )}
      {Boolean(column.name === 'productFeedParameter' && optionsProductFeedParameter?.length) && (
        typeof value === 'string'
          ? (
            <Box sx={{
              pointerEvents: 'none',
              borderRadius: '4px',
              color: 'rgba(0, 0, 0, 0.38)',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              paddingLeft: '8px',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
            >{value}
            </Box>
          )
          : (
            <Field
              component={FormSelect}
              options={optionsProductFeedParameter}
              fullWidth
              multiline
              closeMenuOnScroll
              variant="outlined"
              disabled={disabled}
              name={`${row.id}_productFeedParameter`}
              formSelectWidth="100%"
              validation={isRequired}
              validate={isRequired ? [validators.required] : []}
              customOnBlur={handleBlurParameter}
              handleChange={handleChangeFeedParameter}
              isSelectOpen={productSelect?.[row.id] ?? false}
            />
          )
      )}
      {column.name === 'status' && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StatusIcon iconName={value.icon} /> <Box sx={{ ml: 1 }}>{value.label}</Box>
        </Box>
      )}
    </VirtualTable.Cell>
  );
}

export default memo(ParametersTableCell);
