// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import values from 'lodash/values';
import { useTranslation } from 'react-i18next';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/messages/actions';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useMessagesList } from '../_hooks/useMessagesList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';
import { useAccessList } from '../../../../hooks/useAccessList';
// import { asyncActions } from '../../../../../engine/core/messages/saga/asyncActions';

// parts
import MessagesTableEditColumn from './MessagesTableEditColumn';

function MessagesTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.messages.isLoading);
  const hasAccessEdit = useAccessList(accessList.messageEdit);
  const editWidth = useEditCellWidth([hasAccessEdit], 0, 40, 40);
  // Rows
  const rows = useSelector(selectors.messages.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.messages.currentPage);
  const pageSize = useSelector(selectors.messages.pageSize);
  const totalCount = useSelector(selectors.messages.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.messages.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.messages.settingStatus);
  // const settingPending = useSelector(selectors.messages.settingPending);
  // Filtering
  const filters = useSelector(selectors.messages.filters);

  const tableColumns = Object.freeze({
    messageStatus: Object.freeze({
      name: 'messageStatus',
      type: 'messageStatus',
      title: t('Статус отправки'),
    }),
    createdAt: Object.freeze({
      name: 'createdAt',
      title: t('Дата создания'),
    }),
    sentAt: Object.freeze({
      name: 'sentAt',
      title: t('Дата отправки'),
    }),
    messageUsers: Object.freeze({
      name: 'messageUsers',
      title: t('Группа получателей'),
    }),
    title: Object.freeze({
      name: 'title',
      title: t('Заголовок сообщения'),
    }),
  });

  return (
    <DxTable
      name="Messages"
      // fixedColumn
      // showShortText
      isLoading={isLoading}
      rows={rows}
      columns={values(tableColumns)}
      updateHooks={useMessagesList}
      // Editing
      editComponent={hasAccessEdit ? MessagesTableEditColumn : null}
      editComponentWidth={hasAccessEdit ? editWidth : null}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.messages.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(MessagesTable);
