// Core
import React, { useState } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
// Parts
import List from '@mui/icons-material/List';
import ButtonWithIcon from '../../Buttons/ButtonWithIcon/ButtonWithIcon';
import Modal from '../../../containers/Modals/Modal/Modal';

// Hooks
import { useStyles } from '../../../hooks/useStyles';
import { useDesktopView } from '../../../hooks/useDesktopView';

const style = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContentMargin: {
    '& > *:not(:last-child)': {
      marginBottom: 10,
    },
    '@global': {
      '.hidden': {
        marginBottom: 0,
      },
    },
  },
};

function DesktopToMobileDialog(props) {
  const isDesktop = useDesktopView();
  const classes = useStyles(style);
  const [showModal, setShowModal] = useState(false);
  const {
    buttonText, children, modalMaxWidth, dialogContentMargin, fullScreen,
  } = props;
  const handleCloseAfterClick = (e) => {
    const targetElement = e.target;
    const classList = get(targetElement, 'classList');

    if (
      classList?.contains('MuiSelect-root')
      || classList?.contains('ActionButtonGroupIcon')
      || classList?.contains('MuiMenuItem-root')
      || classList?.contains('MuiButtonGroup-grouped')
      || classList?.contains(classes.dialogContentMargin)
      || targetElement?.value
      || targetElement?.closest('.exceptionHeaderMobileDialog')
    ) {
      return;
    }
    setShowModal(!showModal);
  };

  const modalToggle = () => setShowModal(!showModal);

  return (
    <>
      {
        isDesktop
          ? <>{ children }</>
          : (
            <div>
              <ButtonWithIcon text={buttonText} onClick={modalToggle}>
                <List />
              </ButtonWithIcon>
              <Modal
                fullWidth={false}
                fullScreen={fullScreen}
                maxWidth={modalMaxWidth}
                hideButtons
                modalOpen={showModal}
                handleModalToggle={modalToggle}
              >
                <div
                  className={cx(classes.modal, { [classes.dialogContentMargin]: dialogContentMargin })}
                  onClick={handleCloseAfterClick}
                  role="presentation"
                >
                  { children }
                </div>
              </Modal>
            </div>
          )
      }
    </>
  );
}

DesktopToMobileDialog.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  modalMaxWidth: PropTypes.string,
  dialogContentMargin: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

export default DesktopToMobileDialog;
