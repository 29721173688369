

export function getDefaultFormValues(orderData) {
  return {
    senderName: '',
    deliveryType: 'owner',
    ownerDeliveryCity: '',
    currierDeliveryCity: 'Київ',
    deliveryWarehouse: '',
    deliveryStreet: '',
    deliveryHouse: '',
    deliveryApartment: '',
    postPaid: 0,
    amount: orderData.amount || '',
    comment: '',
    whenCurrierArrive: {
      date: '',
      timeRage: '',
      originDate: '',
    },
    packageOptions: [
      {
        length: '',
        width: '',
        height: '',
        weight: '',
      },
    ],
  };
}
