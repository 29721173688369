// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';
import { initialize } from 'redux-form/immutable';

// lodash
import map from 'lodash/map';

// actions
import { actions } from '../../actions';

// helpers
import { getDateWithCurrentLocal } from '../../../../../../_helpers/getDate';
import { formFields, formName } from '../../../../../../ui/page/PartnerAccess/form';

// config
import { api } from '../../../../../config/api';

export function* callGetPartnerAccessInfoWorker({ payload: hashId }) {
  yield put(actions.setPartnerAccessInfo({ pending: true }));

  const response = yield apply(api, api.partners.getPaymentAccessByHashId, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const {
      active, partnerPaymentType, documentsLink,
      createdAt, updatedAt, trackingStatus,
      paymentDocumentLink, contractSigned, payload: { licenseeStatement, iban },
    } = data;

    const formData = Object.freeze({
      [formFields.active]: active,
      [formFields.trackingStatus]: trackingStatus,
      [formFields.partnerPaymentType]: partnerPaymentType,
      [formFields.paymentDocumentLink]: paymentDocumentLink,
      [formFields.documentsLink]: documentsLink,
      [formFields.createdAt]: getDateWithCurrentLocal(createdAt),
      [formFields.updatedAt]: getDateWithCurrentLocal(updatedAt),
      [formFields.contractSigned]: contractSigned,
      [formFields.licenseeStatement]: licenseeStatement,
      [formFields.iban]: iban,
    });
    const fields = map(formFields, value => value);

    yield put(initialize(formName, formData, fields));
    yield put(actions.setPartnerAccessInfo({
      data: Map({
        ...data,
        createdAt: getDateWithCurrentLocal(data.createdAt),
        updatedAt: getDateWithCurrentLocal(data.updatedAt),
      }),
    }));
  }

  yield put(actions.setPartnerAccessInfo({ pending: false }));
}
