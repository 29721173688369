export const types = Object.freeze({
  SET_CATEGORIES_FEATURE_LOGS_LIST: 'SET_CATEGORIES_FEATURE_LOGS_LIST',
  SET_CATEGORIES_FEATURE_LOGS_CURRENT_PAGE: 'SET_CATEGORIES_FEATURE_LOGS_CURRENT_PAGE',
  SET_CATEGORIES_FEATURE_LOGS_FILTERS: 'SET_CATEGORIES_FEATURE_LOGS_FILTERS',
  SET_CATEGORIES_FEATURE_LOGS_PAGE_SIZE: 'SET_CATEGORIES_FEATURE_LOGS_PAGE_SIZE',
  SET_CATEGORIES_FEATURE_LOGS_SORTING: 'SET_CATEGORIES_FEATURE_LOGS_SORTING',
  SET_CATEGORIES_FEATURE_LOGS_INITIAL_STATE: 'SET_CATEGORIES_FEATURE_LOGS_INITIAL_STATE',
  SET_CATEGORIES_FEATURE_LOGS_SETTING_STATUS: 'SET_CATEGORIES_FEATURE_LOGS_SETTING_STATUS',
  SET_CATEGORIES_FEATURE_LOGS_SETTING_PENDING: 'SET_CATEGORIES_FEATURE_LOGS_SETTING_PENDING',
  GET_CATEGORIES_FEATURE_LOGS: 'GET_CATEGORIES_FEATURE_LOGS',
});
