// Core
import React, {
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch, useSelector,
  // useSelector
} from 'react-redux';
// Components
import Table from './components/Table';
import AddButton from './components/AddButton';
import DeleteButton from './components/DeleteButton';
import ExportButton from './components/ExportButton';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
// import GridSettingsButton from '../../../components/_Table/GridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useUsersList } from './_hooks/useUsersList';
// Engine
// import { asyncActions } from '../../../../engine/core/users/saga/asyncActions';
// import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/users/actions';
import { initialState, stateKeys } from '../../../../engine/core/users/reducer';
import { selectors } from '../../../../engine/config/selectors';

function UsersPage() {
  const { t } = useTranslation();
  useUsersList();
  const dispatch = useDispatch();
  const selection = useSelector(selectors.users.selection);
  const userIds = selection.toJS();

  // const settingStatus = useSelector(selectors.users.settingStatus);
  // const settingPending = useSelector(selectors.users.settingPending);
  // const putSettings = useCallback(() => dispatch(asyncActions.putSettingsAsync()), [dispatch]);
  const isLoading = useSelector(selectors.users.isLoading);
  const filters = useSelector(selectors.users.filters);
  const sorting = useSelector(selectors.users.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.usersList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.usersList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.usersList).get(stateKeys.currentPage)));
  }, [dispatch]);

  return (
    <>
      <PageHeader title={t('Пользователи')}>
        {/* <GridSettingsButton */}
        {/*  settingPending={settingPending} */}
        {/*  settingStatus={settingStatus} */}
        {/*  putSetting={putSettings} */}
        {/* /> */}
        <ExportButton />
        <DeleteButton userIds={userIds} />
        <ResetFiltersButton disabled={isDisabledResetButton} pending={isLoading} onReset={resetFilters} />
        <AddButton />
      </PageHeader>
      <Table />
    </>
  );
}

export default UsersPage;
