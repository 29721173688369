// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../config/api';
import { pageLinks } from '../../../../config/routes';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutTransactionPaymentWorker({ payload }) {
  const { hashId, formData } = payload;
  yield put(actions.putTransactionPaymentInfo({ pending: true }));

  const response = yield apply(api, api.partners.putTransactionPayment, [{
    hashId, formData,
  }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(push(pageLinks.orders.paymentsTransactions));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.putTransactionPaymentInfo({ pending: false }));
}
