// Core
import { apply, put, select } from 'redux-saga/effects';
import { List } from 'immutable';

// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';
import { tableColumns } from '../../../../../ui/page/Products/CategoriesFeaturesManaging/tableColumns';

export function* callGetCategoriesContextWorker({ payload }) {
  yield put(actions.setCategoriesListContext({ pending: true }));

  let filteredTreeResponse = {};

  const { categories, categoriesLastUpdate } = localData.keysObj;
  const categoriesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [categoriesLastUpdate]);
  const categoriesLastUpdateFromStatistics = yield select(selectors.statistics.categoriesLastUpdate);
  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  let response;

  switch (true) {
    case !categoriesLastUpdateFromLocalData:
    case categoriesLastUpdateFromLocalData !== categoriesLastUpdateFromStatistics: {
      response = yield apply(api, api.categories.getList, [{ context: 'select', orderBy: `${tableColumns.idMagentoCategory.name} ASC` }]);
      yield apply(localData, localData.setItem, [categoriesLastUpdate, categoriesLastUpdateFromStatistics]);
      break;
    }
    case categoriesLastUpdateFromLocalData === categoriesLastUpdateFromStatistics: {
      data = yield apply(localData, localData.getItem, [categories]);
      if (!data) {
        response = yield apply(api, api.categories.getList, [{ context: 'select' }]);
      }
      break;
    }
    default: {
      break;
    }
  }

  let filteredResponse;

  if (payload?.attributeSetId && payload.attributeSetId.length > 0) {
    filteredResponse = yield apply(api, api.categories.getList, [{ context: 'select', ...payload }]);
  }

  /** custom logic for task http://jira.nautilus.allo.ua/browse/AMP-527 */
  if (filteredResponse) {
    const itemMap = data.items.reduce((map, item) => {
      // eslint-disable-next-line no-param-reassign
      map[item.id] = item;
      return map;
    }, {});
    let filteredItemMap = filteredResponse.data.items.reduce((map, item) => {
      // eslint-disable-next-line no-param-reassign
      map[item.id] = item;
      return map;
    }, {});
    filteredResponse.data.items.forEach((item) => {
      if (item.parentId) {
        const itemsWithParents = recursiveAddParent(item.parentId, filteredItemMap, itemMap);
        filteredItemMap = { ...filteredItemMap, ...itemsWithParents };
      }
    });
    filteredTreeResponse = Object.values(filteredItemMap);
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [categories, { items: data.items, totalCount: data.totalCount }]);
  }

  yield put(actions.setCategoriesListContext({
    pending: false,
    items: filteredTreeResponse.length > 0 ? List(filteredTreeResponse) : List(data.items),
    totalCount: data.totalCount,
  }));
}

function recursiveAddParent(itemId, resultSet, itemMap) {
  const item = itemMap[itemId];
  if (item && !resultSet[itemId]) {
    // eslint-disable-next-line no-param-reassign
    resultSet[itemId] = item;
    if (item.parentId && item.parentId !== '') {
      recursiveAddParent(item.parentId, resultSet, itemMap);
    } else {
      return resultSet;
    }
  } else {
    return resultSet;
  }
}
