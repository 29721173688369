// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import UpdateIcon from '@mui/icons-material/Update';
import RefreshIcon from '@mui/icons-material/Refresh';
// Components
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { asyncActions } from '../../../../../../../engine/core/contentTemplates/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { COLOR } from '../../../../../../containers/App/AppStyles';

function UpdateFromMagentoButton(props) {
  const {
    categoryId, fullWidth, sx, sxIcon, type, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCategoryId = categoryId || useSelector(selectors.contentProduct.productCategoryActiveItem)?.get('value');
  const isLoading = useSelector(selectors.contentTemplatesImport.updateFromMagentoPending);
  const currentCategoryIdUpdate = useSelector(selectors.contentTemplatesImport.updateFromMagentoCategoryId);
  const disabledCurrent = disabled || Boolean(isLoading && (currentCategoryId === currentCategoryIdUpdate));
  const hasAccess = useAccessList(accessList.featuresUpdateFromMagento);
  const text = t('Обновить характеристики');
  const onClickHandler = () => dispatch(asyncActions.putUpdateFromMagentoAsync({ categoryId: currentCategoryId }));

  if (!hasAccess || !currentCategoryId) {
    return null;
  }

  if (type === 'cell') {
    return (
      <ButtonWithIcon
        fullWidth={fullWidth}
        sx={sx}
        disabled={disabledCurrent}
        onClick={onClickHandler}
        text={text}
      >
        <UpdateIcon />
      </ButtonWithIcon>
    );
  }

  if (type === 'small') {
    return (
      <IconButton
        disabled={disabledCurrent}
        onClick={onClickHandler}
        size="small"
        color="secondary"
        sx={{
          opacity: disabledCurrent ? 0.5 : 1,
          width: '24px',
          height: '24px',
          border: `1px solid ${COLOR.grey['8']}`,
          borderRadius: '5px',
        }}
      >
        <RefreshIcon sx={{ width: '15px', color: COLOR.black['72'] }} />
      </IconButton>
    );
  }

  return (
    <Button
      fullWidth={fullWidth}
      sx={sx}
      disabled={disabledCurrent}
      onClick={onClickHandler}
    >
      <UpdateIcon sx={sxIcon} />
      {text}
    </Button>
  );
}

export default memo(UpdateFromMagentoButton);
