// Core
import React, { memo } from 'react';
import { Field } from 'redux-form/immutable';
// Parts
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
// Helpers
import { validators } from '../../../../../../../_helpers/validationsForm';

function ProductPriceField(props) {
  const { name, label } = props;
  return (
    <Field
      fullWidth
      name={name}
      label={label}
      isPrice
      component={TextField}
      variant="outlined"
      color="dark"
      validate={[validators.required]}
      validation
    />
  );
}
export default memo(ProductPriceField);
