// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';

// Config
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';

// Hooks
import { useTransactionsPaymentList } from '../../_hooks/useTransactionsPaymentList';
import { useAccessList } from '../../../../hooks/useAccessList';

// Actions
import { asyncActions } from '../../../../../engine/core/transactionsPayment/saga/asyncActions';
import { actions } from '../../../../../engine/core/transactionsPayment/actions';

// parts
import TableEditCell from './TableEditCell';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';

function TransactionsPaymentTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.transactionsPayment.isLoading);
  const hasAccessShow = useAccessList(accessList.partnerPaymentTransactionShow);
  const editWidth = useEditCellWidth([(hasAccessShow)], 0, 40, 40);

  // Rows
  const rows = useSelector(selectors.transactionsPayment.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.transactionsPayment.currentPage);
  const pageSize = useSelector(selectors.transactionsPayment.pageSize);
  const totalCount = useSelector(selectors.transactionsPayment.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.transactionsPayment.sorting);
  // Settings
  const settingStatus = useSelector(selectors.transactionsPayment.settingStatus);
  const settingPending = useSelector(selectors.transactionsPayment.settingPending);
  // Filtering
  const filters = useSelector(selectors.transactionsPayment.filters);

  return (
    <DxTable
      name="partnerPaymentTypeTransactions"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useTransactionsPaymentList}
      // Editing
      editComponent={TableEditCell}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.partnerPaymentTypeTransactions.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(TransactionsPaymentTable);
