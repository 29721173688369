// Core
import React, { memo, useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
// Parts
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import TextField from '../../../_Form/TextField/TextField/TextField';
import Select from '../../../_Form/Selects/Select/Select';
import PartnerSelect from '../../../_Form/Selects/PartnerSelect';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { useStyles } from '../../../../hooks/useStyles';
import { stateKeys } from '../../../../../engine/core/_partners/reducers';

const styles = theme => ({
  invoice: {
    marginTop: theme.spacing(1.5),
    display: 'none',
  },
  invoiceShow: {
    display: 'block',
  },
  invoiceBody: {
    '& p': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
});

function InvoiceModal(props) {
  const {
    changePartnerFieldValue,
    partnerFieldValue,
    changePaymentFieldValue,
    changeInvoiceTypeValue,
    isPaymentForPartner,
    invoiceTypeFieldValue,
    paymentFieldValue,
    error,
  } = props;
  const { t } = useTranslation();
  const invoiceTypes = useSelector(selectors.billing.invoiceTypes);
  const normalizeInvoiceTypes = invoiceTypes.toJS();
  const {
    items: invoiceItems,
  } = normalizeInvoiceTypes;
  const classes = useStyles(styles);
  const statusItems = useSelector(selectors.billing.statusItems);
  const downloadPaymentOrderPending = useSelector(selectors.billing.downloadPaymentOrderPending);
  const { firstPayment } = statusItems;
  const commissionValue = Number(paymentFieldValue) * (Number(invoiceTypeFieldValue?.commissionPercent) / 100);
  const payValue = Number(paymentFieldValue) + Number(commissionValue);

  const invoiceClasses = cx(classes.invoice, {
    [classes.invoiceShow]: invoiceItems.length > 1,
  });

  const inputProps = useMemo(() => ({
    value: paymentFieldValue,
    onInput: (e) => {
      e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 6);
    },
    onChange: changePaymentFieldValue,
  }), [paymentFieldValue, changePaymentFieldValue]);

  const partnerInput = useMemo(() => ({
    value: partnerFieldValue,
    onChange: (partner) => {
      changePartnerFieldValue(partner.value);
    },
  }), [partnerFieldValue, changePartnerFieldValue]);

  return error.length ? <h3 className="text-xs-center">{error[0]?.message}</h3> : (
    <>
      <Box className={classes.invoiceBody}>
        {isPaymentForPartner
          ? (
            <PartnerSelect
              reducerName={stateKeys.partnersListContextBillingPaymentForPartner}
              partnerListSelector={selectors.partner.partnersListContextBillingPaymentForPartnerContext}
              partnerPendingSelector={selectors.partner.partnersListContextBillingPaymentForPartnerContextLoading}
              input={partnerInput}
              filterValueBy="value"
              margin="none"
              fullWidth
              selectFirst
            />
          )
          : (
            <Box
              component="p"
              sx={{ margin: '0 0 4px' }}
            >
              {t('Укажите сумму, на которую хотите пополнить баланс')}
            </Box>
          )
        }
        <TextField
          autoFocus
          fullWidth
          disabled={firstPayment}
          type="number"
          input={inputProps}
          label={t('Рекомендуемая сумма')}
          margin="normal"
          variant="outlined"
          endAdornment={<Box component="span" sx={{ paddingRight: '10px', color: 'rgba(0, 0, 0, 0.6)', fontSize: 'inherit' }}>{' ₴'}</Box>}
        />
        <div className={invoiceClasses}>
          <Select
            label={t('Тип оплаты')}
            disabled={!invoiceItems.length}
            filterValueBy="value"
            variant="outlined"
            value={invoiceTypeFieldValue?.value || ''}
            handleChange={changeInvoiceTypeValue}
            options={invoiceItems}
            input={{
              value: invoiceTypeFieldValue,
              onChange: changeInvoiceTypeValue,
            }}
          />
        </div>
        {Number(invoiceTypeFieldValue?.commissionPercent) > 0 && (
          <>
            <Box component="p" sx={{ margin: '20px 0 0' }}>{t('Комиссия за проведение операции в режиме онлайн составляет')}
              <strong>&nbsp;{invoiceTypeFieldValue?.commissionPercent}</strong>%
            </Box>
            <Box component="p" sx={{ margin: '0' }}>{t('Сумма комиссии')}: <strong>{Math.floor(commissionValue * 100) / 100}</strong>{t(' грн')}</Box>
            <Box component="p" sx={{ margin: '0' }}>{t('Сумма к оплате')}: <strong>{Math.floor(payValue * 100) / 100}</strong>{t(' грн')}</Box>
          </>
        )}
      </Box>
      {downloadPaymentOrderPending && <LinearProgress sx={{ mt: 1 }} />}
    </>
  );
}

InvoiceModal.propTypes = {
  changePaymentFieldValue: PropTypes.func,
  changePartnerFieldValue: PropTypes.func,
  changeInvoiceTypeValue: PropTypes.func,
  isPaymentForPartner: PropTypes.bool,
  invoiceTypeFieldValue: PropTypes.shape({
    commissionPercent: PropTypes.number,
    default: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  paymentFieldValue: PropTypes.number,
  partnerFieldValue: PropTypes.number,
  error: PropTypes.array,
};

InvoiceModal.defaultProps = {
  paymentFieldValue: 0,
};

export default memo(InvoiceModal);
