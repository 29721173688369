// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';


// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPostCommissionUploadWorker({ payload: offerPercentsFile }) {
  yield put(actions.setCommissionsUploadUploading(true));
  const response = yield apply(api, api.categoriesFeatureManagement.postCategoriesFeatureUpload, [{ files: offerPercentsFile }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors, statusTitle } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message, statusTitle } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(asyncActions.getInitialStateAsync());
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setCommissionsUploadUploading(false));
}
