// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import PartnersManagersExport from './components/PartnersManager/components/PartnersManagersExport';
import PartnersManagersImport from './components/PartnersManager/components/PartnersManagersImport';
import PartnersManagersImportModal from './components/PartnersManager/components/PartnersManagersImportModal';
import PartnersManagersTable from './components/PartnersManager/components/PartnersManagersTable';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Hooks
import { usePartnersManagersList } from './components/PartnersManager/_hooks/usePartnersManagersList';
// Engine
import { asyncActions } from '../../../engine/core/partners/managers/saga/asyncActions';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/partners/managers/actions';
import { initialState, stateKeys } from '../../../engine/core/partners/managers/reducer';

function PartnersManegersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  usePartnersManagersList();
  const settingStatus = useSelector(selectors.partnersManagers.settingStatus);
  const settingPending = useSelector(selectors.partnersManagers.settingPending);
  const isLoading = useSelector(selectors.partnersManagers.isLoading);
  const filters = useSelector(selectors.partnersManagers.filters);
  const sorting = useSelector(selectors.partnersManagers.sorting);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);

  const onResetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnersManagersList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersManagersList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersManagersList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSettingsAsync = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeader title={t('Управление СМ')}>
        <PartnersManagersExport />
        <PartnersManagersImport />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettingsAsync}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={onResetFilters} />
      </PageHeader>
      <PartnersManagersTable />
      <PartnersManagersImportModal />
    </>
  );
}

export default PartnersManegersPage;
