// Core
import { apply, put } from 'redux-saga/effects';
import { reset } from 'redux-form';

// Engine
import { actions } from '../../actions';
import { api } from '../../../../config/api';

// helpers
import { uploadModalReduxFormName } from '../../../../../ui/page/Orders/components/UploadCsvModal/form';

export function* callPostOrdersUploadWorker({ payload: { files, format } }) {
  yield put(actions.mergeOrdersUpload({ pending: true }));

  const response = yield apply(api, api.orders.postOrdersUpload, [{ format, files }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { errors, status, message } = responseData;

    switch (status) {
      case 'error': {
        yield put(reset(uploadModalReduxFormName));
        yield put(actions.mergeOrdersUpload({ error: errors }));
        break;
      }
      case 'success': {
        yield put(reset(uploadModalReduxFormName));
        yield put(actions.mergeOrdersUpload({ success: message }));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergeOrdersUpload({ pending: false }));
}
