// Core
import { useState } from 'react';
import cx from 'classnames';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Popover } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

// Hooks
import * as PropTypes from 'prop-types';
import { useStyles } from '../../hooks/useStyles';
import { HistoryButtonStyles } from './HistoryButtonStyle';


function HistoryButton(props) {
  const {
    hashId, hasHistory, canShow, pending, pendingEdit, children, currentHashId, showPopover, handleClick: customHandleClick, handleClose: customHandleClose, titleTooltip,
  } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isShowCurrent = hashId === currentHashId && showPopover;

  const handleClick = (event) => {
    customHandleClick();
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    customHandleClose();
  };

  const id = showPopover ? hashId : undefined;
  const classes = useStyles(HistoryButtonStyles);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  if (!(canShow)) {
    return null;
  }

  return (
    <>
      <Popover
        id={id}
        open={isShowCurrent ? showPopover : null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.historyListHolder}
      >
        <Box className={classes.list}>
          {children}
        </Box>
      </Popover>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={titleTooltip}
          placement="bottom"
          sx={{ maxWidth: '152px' }}
          classes={{ popper: classes.tooltip }}
          onClose={handleTooltipClose}
          open={open}
        >
          <Button
            aria-describedby={id}
            onClick={handleClick}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            disabled={pending || pendingEdit || !hasHistory}
            className={cx(classes.historyButton, { [classes.historyButtonActive]: isShowCurrent })}
          >
            {pending && hashId === currentHashId
              ? <CircularProgress size={16} className={classes.historyButtonIcon} isLoading />
              : <HistoryIcon className={cx(classes.historyButtonIcon, { [classes.historyButtonIconActive]: isShowCurrent })} />}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
}

HistoryButton.propTypes = {
  hashId: PropTypes.string,
  hasHistory: PropTypes.bool,
  canShow: PropTypes.bool,
  pending: PropTypes.bool,
  pendingEdit: PropTypes.bool,
  children: PropTypes.node,
  currentHashId: PropTypes.string,
  showPopover: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  titleTooltip: PropTypes.string,
};


export default HistoryButton;
