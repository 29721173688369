import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../../../engine/core/partners/actions';
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';

function DeleteDeliveryDelayPeriodModal(props) {
  const { hashId } = props;
  const deleteShowModal = useSelector(selectors.partners.partnersDeliveryDelayDeleteShowModal);
  const pending = useSelector(selectors.partners.partnersDeliveryDelayDeletePending);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleModalClose = () => {
    dispatch(actions.setPartnersDeliveryDelayPeriodDelete({ showModal: false }));
  };
  const handleModalSend = () => {
    dispatch(asyncActions.deletePartnersDeliveryDelayPeriodAsync({ hashId }));
  };

  return (
    <Modal
      title={t('Удаление периода задержки доставок')}
      modalOpen={deleteShowModal}
      sendText={t('Подтвердить удаление')}
      handleModalToggle={handleModalClose}
      handleModalCancel={handleModalClose}
      handleModalSend={handleModalSend}
      modalMaxWidth="sm"
      cancelText={t('Отменить')}
      buttonSendIcon={null}
      disabledSend={pending}
    >
      {t('Вы уверены, что хотите удалить ')}<span style={{ fontWeight: 'bold' }}> {t('период задержки доставок?')}</span><br />
      {t('Это действие необратимо, но вы можете создать новый период в любое время.')}
    </Modal>
  );
}
export default DeleteDeliveryDelayPeriodModal;
