export const asyncTypes = Object.freeze({
  GET_PARTNER_ACCESS_INFO_ASYNC: 'GET_PARTNER_ACCESS_INFO_ASYNC',
  GET_PARTNERS_ACCESS_LIST_ASYNC: 'GET_PARTNERS_ACCESS_LIST_ASYNC',
  PUT_PARTNER_ACCESS_ASYNC: 'PUT_PARTNER_ACCESS_ASYNC',
  PUT_PARTNERS_ACCESS_SETTINGS_ASYNC: 'PUT_PARTNERS_ACCESS_SETTINGS_ASYNC',
  GET_PARTNERS_ACCESS_INITIAL_STATE_ASYNC: 'GET_PARTNERS_ACCESS_INITIAL_STATE_ASYNC',
  GET_PARTNER_ACCESS_CREDENTIALS_ASYNC: 'GET_PARTNER_ACCESS_CREDENTIALS_ASYNC',
  PUT_PARTNER_ACCESS_CREDENTIALS_ASYNC: 'PUT_PARTNER_ACCESS_CREDENTIALS_ASYNC',
  DELETE_PARTNER_ACCESS_CREDENTIALS_ASYNC: 'DELETE_PARTNER_ACCESS_CREDENTIALS_ASYNC',
  POST_PARTNERS_ACCESS_UPDATE_WFP_ASYNC: 'POST_PARTNERS_ACCESS_UPDATE_WFP_ASYNC',
  DELETE_PARTNER_ACCESS_ASYNC: 'DELETE_PARTNER_ACCESS_ASYNC',
  GET_PARTNERS_ACCESS_DOWNLOAD_ASYNC: 'GET_PARTNERS_ACCESS_DOWNLOAD_ASYNC',
  GET_PARTNERS_PAYMENT_METHODS_ASYNC: 'GET_PARTNERS_PAYMENT_METHODS_ASYNC',
  POST_PARTNERS_PAYMENT_ACCESSES_ASYNC: 'POST_PARTNERS_PAYMENT_ACCESSES_ASYNC',
});
