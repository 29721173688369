// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import { isNil } from 'lodash';
// UI
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Parts
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import { COLOR } from '../../../../containers/App/AppStyles';
// Engine
import { asyncActions } from '../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { actions } from '../../../../../engine/core/logisticsDataByCategory/actions';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useStyles } from '../../../../hooks/useStyles';
import { useAccessList } from '../../../../hooks/useAccessList';

const ProductsButtonGroupsStyles = () => ({
  menuList: {
    padding: '8px',
  },
  menuListItem: {
    borderRadius: '5px',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      background: COLOR.grey['4'],
    },

    '&.Mui-selected': {
      background: COLOR.grey['4'],
      '&:hover': {
        background: COLOR.grey['4'],
      },
    },
  },
});


function ActionsButtonGroups() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = i18n.language;
  const isLoadingExport = useSelector(selectors.logisticsDataByCategory.exportPending);
  const isLoadingImport = useSelector(selectors.logisticsDataByCategory.importPending);
  const selections = useSelector(selectors.logisticsDataByCategory.selection);
  const hasAccessImport = useAccessList(accessList.categoryLogisticsImport);
  const hasAccessDownload = useAccessList(accessList.categoryLogisticsDownload);
  const [exportLabel, setExportLabel] = useState('Экспорт логистических данных категорий');
  const options = [
    { label: exportLabel, value: 'Export', access: hasAccessDownload },
    { label: t('Импорт логистических данных категорий'), value: 'Import', access: hasAccessImport },
  ];
  const hasAccess = find(options, { access: true });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedValue, setSelectedValue] = React.useState(isNil(hasAccess) ? '' : hasAccess.value);
  const classes = useStyles(ProductsButtonGroupsStyles);
  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleImport = () => {
    dispatch(actions.mergeLogisticsDataByCategoryImport({ isOpen: true }));
  };
  const handleExport = () => {
    dispatch(asyncActions.getLogisticsDataByCategoryExport());
  };

  useEffect(() => {
    if (selections.size) {
      setExportLabel(t('Экспорт выбранных логистических данных категорий'));
    } else {
      setExportLabel(t('Экспорт логистических данных категорий'));
    }
  }, [selections.size, language]);

  if (!hasAccess) {
    return null;
  }

  const selectedButton = (type) => {
    switch (type) {
      case 'Export':
        return (
          <ButtonWithIconAndCircularProgress
            text={exportLabel}
            onClick={handleExport}
            isLoading={isLoadingExport}
            disabled={isLoadingExport}
            fullWidth
            sx={{ justifyContent: 'flex-start', lineHeight: '1.2' }}
          >
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        );
      case 'Import':
        return (
          <>
            <ButtonWithIcon
              text={t('Импорт логистических данных категорий')}
              onClick={handleImport}
              fullWidth
              isLoading={isLoadingImport}
              disabled={isLoadingImport}
              sx={{ justifyContent: 'flex-start', lineHeight: '1.2' }}
            >
              <CloudUploadIcon />
            </ButtonWithIcon>
          </>
        );
      default:
    }
  };

  return (
    <Box>
      <ButtonGroup
        sx={{
          display: 'flex',
          boxShadow: 'none',
        }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        {selectedButton(selectedValue)}
        <Button
          color="primary"
          size="small"
          onClick={handleToggle}
          sx={{ p: '4px 10px' }}
          className="exceptionHeaderMobileDialog"
        >
          <ArrowDropDownIcon className="ActionButtonGroupIcon" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        sx={{ zIndex: 1302 }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="exceptionHeaderMobileDialog"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={classes.menuList}>
                  {options.map((option, index) => (
                    option.access && (
                    <MenuItem
                      key={index}
                      selected={option.value === selectedValue}
                      onClick={() => handleMenuItemClick(option.value)}
                      className={classes.menuListItem}
                    >
                      {option.label}
                    </MenuItem>
                    )
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}


export default ActionsButtonGroups;
