// Core
import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError, Form, reduxForm } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { isImmutable } from 'immutable';
import get from 'lodash/get';
// Icons
import SendIcon from '@mui/icons-material/Send';
// Parts
import Grid from '@mui/material/Grid';
import UserEditSystem from './components/PartnerEditSystem';
import UserEditSite from './components/PartnerEditSite/PartnerEditSite';
import UserEditFinance from './components/PartnerEditFinance';
import TabsContainer from '../../containers/Tabs/TabsContainer';
import TabsNav from '../../containers/Tabs/TabsNav';
import TabsNavMenu from '../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../components/Tabs/TabMenuItem/TabItemMenu';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { asyncActions as categoriesAsyncActions } from '../../../engine/core/categories/saga/asyncActions';
import { partnerAsyncAction } from '../../../engine/core/_partners/saga/asyncAction';
import { userRoles } from '../../../engine/config/userRoles';
import { selectors } from '../../../engine/config/selectors';
// Helpers
import { useParentEditMode } from './hooks/useParentEditMode';
import { usePartnerTabs } from './hooks/usePartnerTabs';
import PartnerEditStyle from './PartnerEdit/PartnerEditStyle';
import { useUserSomeRole } from '../../hooks/useUserSomeRole';
import { useStyles } from '../../hooks/useStyles';
import { formName } from './form';

function PartnerAdd(props) {
  const {
    handleSubmit,
  } = props;
  const dispatch = useDispatch();
  const partnerCreateNewPending = useSelector(selectors.partner.partnerCreateNewPending);
  const classes = useStyles(PartnerEditStyle);
  const { t } = useTranslation();
  const tabs = usePartnerTabs();
  const [selectedTab, setSelectedTab] = useState(tabs.system);
  const mode = useParentEditMode();
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);
  const handleChangeTab = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);

  const displayTab = useCallback((currentTab) => {
    const isCurrentTabSelected = selectedTab === currentTab;
    return isCurrentTabSelected ? 'flex' : 'none';
  }, [selectedTab]);

  const onFormSubmit = useCallback((immutableData) => {
    if (isImmutable(immutableData)) {
      const jsFormData = immutableData.toJS();
      const categoriesPercent = get(jsFormData, 'categoriesPercent');
      const categoriesPercentValue = get(jsFormData, 'categoriesPercentValue');
      const currentCategories = get(jsFormData, 'categories');

      if (categoriesPercent && categoriesPercentValue) { // Set new percentValue from input
        categoriesPercent.map((item) => {
          const newItem = item;
          Object.keys(categoriesPercentValue).forEach((itemKey) => {
            if (item.value === parseInt(itemKey, 10)) {
              newItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
            }
          });
          return newItem;
        });
      }

      let warehouses = jsFormData?.warehouses?.map(item => item?.label) ?? [];
      const mainWarehouse = jsFormData.mainWarehouse?.label ?? jsFormData?.mainWarehouse;

      const warehousesErrors = {};
      warehousesErrors.warehouses = [];
      // eslint-disable-next-line no-underscore-dangle
      warehousesErrors._error = 'submit error';

      if (isPartnerRoles) {
        warehouses.forEach((item, index) => {
          if (!item) {
            warehousesErrors.warehouses[index] = t('Выберите город расположения склада товаров');
            throw new SubmissionError(warehousesErrors);
          }
          if (!item) {
            warehousesErrors.warehouses[index] = t('Пожалуйста, проверьте правильность ввода города');
            throw new SubmissionError(warehousesErrors);
          }
        });

        if (!mainWarehouse) {
          throw new SubmissionError({
            mainWarehouse: t('Выберите город расположения склада товаров'),
            _error: 'submit error',
          });
        }

        if (!mainWarehouse) {
          throw new SubmissionError({
            mainWarehouse: t('Пожалуйста, проверьте правильность ввода города'),
            _error: 'submit error',
          });
        }
      } else {
        warehouses = warehouses.filter(item => typeof item === 'string' ? !!item.trim() : !!item);
      }

      const currentCategoryData = currentCategories.map((item) => {
        let currentItem = item;
        if (categoriesPercentValue) {
          Object.keys(categoriesPercentValue).forEach((itemKey) => {
            if (item.value === parseInt(itemKey, 10)) {
              currentItem.partnerOfferPercent = parseInt(categoriesPercentValue[itemKey], 10);
            }
          });
        }
        if (categoriesPercent) {
          categoriesPercent.map((percentItem) => {
            if (percentItem.value === item.value) {
              currentItem = percentItem;
            }
            return percentItem;
          });
        }
        delete currentItem.partnerOfferPercentOld;
        delete currentItem.partnerOfferPercentApproved;
        return currentItem;
      });
      const formData = {
        accessBlockContent: jsFormData.accessBlockContent,
        accessFeed: jsFormData.accessFeed,
        accessBilling: jsFormData.accessBilling,
        accessRatingRatio: jsFormData.accessRatingRatio,
        isTop: jsFormData.isTop,
        brands: jsFormData.brands,
        partnerStatus: jsFormData.partnerStatus.value,
        categories: currentCategoryData,
        infoFieldsValues: {
          companyName: jsFormData.companyName,
          site: jsFormData.site,
          // temporary solution for http://jira.nautilus.allo.ua/browse/AMP-625
          deliveryTime: '1-3 дня',
          deliveryTimeFrom: 1,
          deliveryTimeTo: 3,
          detailedInformation: jsFormData.detailedInformation,
          partnerInfo: jsFormData.partnerInfo,
          warrantyAndReturn: jsFormData.warrantyAndReturn,
          enterpriseName: jsFormData.enterpriseName,
          address: jsFormData.address,
          addressIndex: jsFormData.addressIndex,
          city: jsFormData.city,
          enterpriseClassifierCode: jsFormData.enterpriseClassifierCode,
          taxIdentification: jsFormData.taxIdentification,
          enterpriseType: jsFormData.enterpriseType,
          formOfOwnership: jsFormData.formOfOwnership,
          enterpriseGroupCode: jsFormData.enterpriseGroupCode,
          automaticVoucherGeneration: jsFormData.automaticVoucherGeneration,
          taxationType: jsFormData.taxationType,
          iban: jsFormData.iban,
          bankName: jsFormData.bankName,
          interBranchTurnover: jsFormData.interBranchTurnover,
          bankClassifierCode: jsFormData.bankClassifierCode,
          directorName: jsFormData.directorName,
          directorNameAdditional: jsFormData.directorNameAdditional,
          directorPhone: jsFormData.directorPhone,
          financialManagerName: jsFormData.financialManagerName,
          financialManagerPhone: jsFormData.financialManagerPhone,
          contactNameAndPosition: jsFormData.contactNameAndPosition,
          contactPhone: jsFormData.contactPhone,
          contactEmail: jsFormData.contactEmail,
          workSchedule: jsFormData.workSchedule,
          contactName1: jsFormData.contactName1,
          contactName2: jsFormData.contactName2,
          contactPhone1: jsFormData.contactPhone1,
          contactPhone2: jsFormData.contactPhone2,
          warehouses: [mainWarehouse, ...warehouses].filter(Boolean),
        },
      };

      if (jsFormData.isTaxAdditionalCostNumber === 'Да' || jsFormData.isTaxAdditionalCostNumber === 'Так') {
        formData.infoFieldsValues.isTaxAdditionalCostNumber = true;
        formData.infoFieldsValues.taxAdditionalCostNumber = jsFormData.taxAdditionalCostNumber;
      } else if (jsFormData.isTaxAdditionalCostNumber === 'Нет' || jsFormData.isTaxAdditionalCostNumber === 'Ні') {
        formData.infoFieldsValues.isTaxAdditionalCostNumber = false;
      }

      if (!isPartnerRoles) {
        formData.infoFieldsValues.hasOnlineStore = jsFormData.hasOnlineStore;
        formData.infoFieldsValues.hasServiceCenter = jsFormData.hasServiceCenter;
        formData.infoFieldsValues.planToSell = jsFormData.planToSell;
        formData.infoFieldsValues.activityType = jsFormData.activityType;
        formData.infoFieldsValues.hasContactCenter = jsFormData.hasContactCenter;
        formData.infoFieldsValues.yourselfInformation = jsFormData.yourselfInformation;
        const beenAlloSupplierRadioValue = jsFormData.beenAlloSupplier;
        const beenAlloSupplierValue = jsFormData.beenAlloSupplierName;
        if (beenAlloSupplierRadioValue === 'Так' && beenAlloSupplierValue) {
          formData.infoFieldsValues.beenAlloSupplier = `${beenAlloSupplierRadioValue}, ${beenAlloSupplierValue}`;
        } else {
          formData.infoFieldsValues.beenAlloSupplier = beenAlloSupplierRadioValue;
        }

        const readyToYmlFormatRadioValue = jsFormData.readyToYmlFormat;
        if (readyToYmlFormatRadioValue === 'Так') {
          formData.infoFieldsValues.readyToYmlFormat = readyToYmlFormatRadioValue;
          formData.infoFieldsValues.ymlPriceLink = jsFormData.ymlPriceLink;
        } else {
          formData.infoFieldsValues.readyToYmlFormat = readyToYmlFormatRadioValue;
        }
      }
      dispatch(partnerAsyncAction.createNewPartner(formData));
    }
  }, [isPartnerRoles]);

  useEffect(() => {
    dispatch(categoriesAsyncActions.getCategoriesListContextAsync({
      loadRootCategories: true,
      parentValue: 0,
    }));
  }, []);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Grid container justifyContent="center">
        <PageHeader title={t('Добавление партнера')}>
          <ButtonWithIcon
            text={t('Отправить анкету на проверку')}
            disabled={partnerCreateNewPending}
            component="button"
            type="button"
            onClick={handleSubmit(onFormSubmit)}
          >
            <SendIcon />
          </ButtonWithIcon>
        </PageHeader>
        <TabsContainer>
          <TabsNav>
            <TabsNavMenu
              value={selectedTab}
              onChange={handleChangeTab}
            >
              <TabItemMenu value={tabs.system} label={t('Информация в систему')} />
              <TabItemMenu value={tabs.site} label={t('Информация на сайт')} />
              <TabItemMenu value={tabs.finance} label={t('Информация для фин. сверки')} />
              {/* <TabItemMenu value={tabs.percent} label={t('Комиссии по категориям')} /> */}
            </TabsNavMenu>
          </TabsNav>
          <UserEditSystem
            display={displayTab(tabs.system)}
            classes={classes}
            isPartnerRole={false}
            mode={mode.create}
            index={tabs.system}
            value={selectedTab}
          />
          <UserEditSite
            classes={classes}
            display={displayTab(tabs.site)}
            mode={mode.create}
            index={tabs.site}
            value={selectedTab}
          />
          <UserEditFinance
            classes={classes}
            display={displayTab(tabs.finance)}
            mode={mode.create}
            index={tabs.finance}
            value={selectedTab}
          />
        </TabsContainer>
      </Grid>
    </Form>
  );
}

PartnerAdd.propTypes = {
  handleSubmit: PropTypes.func,
};
export default reduxForm({
  form: formName,
  initialValues: {
    contactPhone: '380',
    directorPhone: '380',
    financialManagerPhone: '380',
  },
})(PartnerAdd);
