// Core
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import cx from 'classnames';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Popover } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

// Engine
import { actions } from '../../../../../../engine/core/logisticsDataByCategory/actions';
import { selectors } from '../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../engine/config/access';
// Hooks
import { useStyles } from '../../../../../hooks/useStyles';
import { useAccessList } from '../../../../../hooks/useAccessList';
import { asyncActions } from '../../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { HistoryButtonStyles } from './HistoryButtonStyle';


/** rewrite it with HistoryButton from src/components, do not reuse now */
function HistoryButton(props) {
  const { hashId, hasHistory } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const categoryLogisticsShow = useAccessList(accessList.categoryLogisticsShow);
  const categoryLogisticsEdit = useAccessList(accessList.categoryLogisticsEdit);
  const pending = useSelector(selectors.logisticsDataByCategory.logsPending);
  const pendingEdit = useSelector(selectors.logisticsDataByCategory.itemByHashIdPendingId);
  const list = useSelector(selectors.logisticsDataByCategory.logsList);
  const categoriesFeatureLogsHashId = useSelector(selectors.logisticsDataByCategory.logsHashId);
  const showPopover = useSelector(selectors.logisticsDataByCategory.logsShowPopover);
  const [anchorEl, setAnchorEl] = useState(null);
  const isShowCurrent = hashId === categoriesFeatureLogsHashId && showPopover;

  const handleClick = (event) => {
    dispatch(asyncActions.getLogsAsync(hashId));
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(actions.setLogsRefresh({ hashId: null, items: List(), showPopover: null }));
  };

  const id = useMemo(() => showPopover ? hashId : undefined, [showPopover, hashId]);
  const classes = useStyles(HistoryButtonStyles);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  if (!(categoryLogisticsEdit || categoryLogisticsShow) || !hasHistory) {
    return null;
  }

  return (
    <>
      <Popover
        id={id}
        open={isShowCurrent ? showPopover : null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.historyListHolder}
      >
        <Box className={classes.list}>
          <>
            {list?.toJS().map((item, index) => (
              <Box key={item.hashId} className={classes.listItem}>
                <Box className={cx(classes.row, classes.dateRow)}>
                  <span className={cx(classes.mainText, classes.dateText)}>{item.createdAt}</span>
                  {index === 0 ? <span className={classes.currentLabel}>{t('Актуальный')}</span> : null}
                </Box>
                <Box className={cx(classes.row, classes.userWhoEditRow)}>
                  <span className={classes.userWhoEditText}>{item.userWhoEdit}</span>
                </Box>
                <Box className={classes.row}>
                  <span className={classes.label}>{t('Высота в упаковке')}:</span>
                  <span className={classes.mainText}>{item.height ? `${item.height} см` : t('Отсутствует')}</span>
                </Box>
                <Box className={classes.row}>
                  <span className={classes.label}>{t('Ширина в упаковке')}:</span>
                  <span className={classes.mainText}>{item.width ? `${item.width} см` : t('Отсутствует')}</span>
                </Box>
                <Box className={classes.row}>
                  <span className={classes.label}>{t('Длина в упаковке')}:</span>
                  <span className={classes.mainText}>{item.length ? `${item.length} см` : t('Отсутствует')}</span>
                </Box>
                <Box className={classes.row}>
                  <span className={classes.label}>{t('Вес в упаковке')}:</span>
                  <span className={classes.mainText}>{item.weight ? `${item.weight} кг` : t('Отсутствует')}</span>
                </Box>
              </Box>
            ))}
          </>
        </Box>
      </Popover>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={t('История изменений, логистических данных товаров')}
          placement="bottom"
          sx={{ maxWidth: '152px' }}
          classes={{ popper: classes.tooltip }}
          onClose={handleTooltipClose}
          open={open}
        >
          <Button
            aria-describedby={id}
            onClick={handleClick}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            disabled={pending || pendingEdit}
            className={cx(classes.historyButton, { [classes.historyButtonActive]: isShowCurrent })}
          >
            {pending && hashId === categoriesFeatureLogsHashId
              ? <CircularProgress size={16} className={classes.historyButtonIcon} isLoading />
              : <HistoryIcon className={cx(classes.historyButtonIcon, { [classes.historyButtonIconActive]: isShowCurrent })} />}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
}


export default HistoryButton;
