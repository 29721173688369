// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function PenMenuSideBarOutlinedIcon(props) {
  return (
    <SvgIcon className="pen-menu-outlined-icon" fontSize="inherit" viewBox="0 0 18 18" {...props}>
      <path d="M16.7279 4.24183L13.8014 1.30042C13.608 1.10801 13.3463 1 13.0735 1C12.8007 1 12.539 1.10801 12.3457 1.30042L1.97094 11.6602L1.0237 15.7482C0.991028 15.8977 0.992148 16.0525 1.02698 16.2015C1.06182 16.3504 1.12949 16.4897 1.22505 16.6092C1.32061 16.7286 1.44165 16.8252 1.57932 16.8919C1.71699 16.9586 1.86782 16.9937 2.02079 16.9946C2.09207 17.0018 2.16389 17.0018 2.23517 16.9946L6.3681 16.0474L16.7279 5.69758C16.9203 5.5042 17.0283 5.2425 17.0283 4.9697C17.0283 4.69691 16.9203 4.43521 16.7279 4.24183ZM5.86956 15.15L1.99587 15.9626L2.87829 12.1637L10.6406 4.43128L13.6319 7.42254L5.86956 15.15ZM14.2999 6.69966L11.3087 3.70839L13.0436 1.98342L15.985 4.97469L14.2999 6.69966Z" strokeWidth="0.5" />
    </SvgIcon>
  );
}

export default PenMenuSideBarOutlinedIcon;
