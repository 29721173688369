import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Icon
import IconButton from '@mui/material/IconButton';
import CompareIcon from '@mui/icons-material/Compare';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// UI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import CompareComponent from './components/CompareComponent';
import TooltipHint from '../../../Tooltip/TooltipHint';
import { useGuideTourRef } from '../../../../containers/Context/refContext';
// Styles
import { useStyles } from '../../../../hooks/useStyles';
import RadioButtonsStyles from './RadioButtonsStyles';

function RadioButtons(props) {
  const {
    required, fullWidth, margin, validation, approve,
    approvedValue, label, valueOld, tooltipText, showInput,
    flexOne, meta, input, helperText, options, row, onApproveValue,
    compareInInput, confirmInputValue, setShowInput, disabled, darkTheme, sxLabel,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(RadioButtonsStyles);
  const guideTourRef = useGuideTourRef();
  const { touched, error } = meta;
  const hasError = validation && touched && typeof error !== 'undefined';

  const [compare, setCompare] = useState(false);
  // ClassNames
  const activeCompare = classNames(classes.button, classes.compareButton, {
    [classes.compareButtonActive]: compare,
  });
  const activeApproveTrue = classNames(classes.button, classes.checkButton, {
    [classes.checkButtonActive]: approvedValue === 'current',
  });
  const activeApproveFalse = classNames(classes.button, classes.cancelButton, {
    [classes.cancelButtonActive]: approvedValue === 'old',
  });
  const wrapperClassNames = classNames(classes.wrapper, {
    [classes.hasError]: validation && touched && error,
    [classes.wrapperWithApprove]: valueOld !== undefined && approve,
    [classes.wrapperRequired]: required,
    [classes.flexOne]: flexOne,
    [classes.darkTheme]: darkTheme,
  });
  const groupClassNames = classNames(classes.wrapper, {
    [classes.row]: row,
    [classes.radioHasError]: hasError,
  });

  const handleOnApproveValue = value => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value });
    }
  };

  return (
    <div className={wrapperClassNames}>
      <FormControl error={hasError} fullWidth={fullWidth} margin={margin} darkTheme={darkTheme}>
        <FormLabel component="legend" className={classes.label}>
          {label}
          {required && (
            <span className={classes.buttonRequired}> *</span>
          )}
        </FormLabel>
        {compare
          ? (
            <CompareComponent
              valueOld={valueOld}
              input={input}
              confirmInputValue={confirmInputValue}
              compareInInput={compareInInput}
              groupClassNames={groupClassNames}
              options={options}
            />
          )
          : (
            <RadioGroup className={groupClassNames} aria-label="gender" name="gender1" {...input}>
              { options.map((item, index) => (
                <FormControlLabel
                  className={index > 0 && !row ? classes.groupItem : ''}
                  value={item.value}
                  disabled={disabled}
                  key={item.value}
                  control={(
                    <Radio
                      color="primary"
                      ref={guideTourRef}
                    />
                  )}
                  label={item.label}
                  sx={sxLabel}
                />
              )) }
            </RadioGroup>
          )}
        {hasError && (
          <div className={wrapperClassNames}>
            <FormHelperText>{error}</FormHelperText>
          </div>
        )}
        {(helperText && !hasError) && (
          <div className={wrapperClassNames}>
            <FormHelperText>{helperText}</FormHelperText>
          </div>
        )}
      </FormControl>
      <div className={classes.wrapperButton}>
        {tooltipText && (
          <TooltipHint
            title={tooltipText}
            classNameButton={classes.button}
          />
        )}
        {(valueOld !== undefined && approve) && (
          <Fragment>
            <Tooltip title={t('Показать прошлые данные')} placement="top">
              <IconButton
                className={activeCompare}
                onClick={() => {
                  setCompare(!compare);
                  if (setShowInput) {
                    setShowInput(!showInput);
                  }
                }}
                aria-label={t('Показать прошлые данные')}
                size="large"
              >
                <CompareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Подтвердить')} placement="top">
              <IconButton
                className={activeApproveTrue}
                onClick={handleOnApproveValue('current')}
                aria-label={t('Подтвердить')}
                size="large"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Отклонить')} placement="top">
              <IconButton
                className={activeApproveFalse}
                onClick={handleOnApproveValue('old')}
                aria-label={t('Отклонить')}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </div>
    </div>
  );
}

RadioButtons.propTypes = {
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  validation: PropTypes.bool,
  approve: PropTypes.bool,
  approvedValue: PropTypes.string,
  label: PropTypes.string,
  valueOld: PropTypes.bool,
  tooltipText: PropTypes.string,
  showInput: PropTypes.bool,
  flexOne: PropTypes.bool,
  meta: PropTypes.object,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  row: PropTypes.bool,
  onApproveValue: PropTypes.func,
  compareInInput: PropTypes.bool,
  confirmInputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setShowInput: PropTypes.func,
  disabled: PropTypes.bool,
  darkTheme: PropTypes.bool,
  sxLabel: PropTypes.object,
};

export default RadioButtons;
