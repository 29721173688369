// Core
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { history } from '../../../engine/init/store';
// Global Style
import 'normalize.css/normalize.css';
import '../../../assets/global.css';
import '../../../assets/fonts/arial/stylesheet.css';
// Parts
import SingIn from '../../page/Authentication/SignIn/SignIn';
import PasswordReset from '../../page/Authentication/PasswordReset/PasswordReset';
import PasswordChange from '../../page/Authentication/PasswordChange/PasswordChange';
import SignUp from '../../page/Authentication/SignUp/SignUp';
import Confirm from '../../page/Authentication/Confirm/Confirm';
import Links from '../../page/Links';
import MainRedirects from './components/MainRedirects';
import Error404 from '../../page/Error/Error404';
import Error500 from '../../page/Error/Error500';
import EnterContract from '../../page/EnterContract';
import Main from './components/Main';
import ProtectedElement from './components/ProtectedElement';
import ProtectedLayout from './components/ProtectedLayout';
// Engine
import { statisticsActionAsync } from '../../../engine/core/statistics/saga/asyncAction';
import { routersLink } from '../../../engine/config/routes';
import { apiTime } from '../../../engine/config/api';
import { userActionAsync } from '../../../engine/core/user/saga/asyncAction';
import { selectors } from '../../../engine/config/selectors';
import { googleAnalytics } from '../../../engine/init/googleAnalytics';
import { uiActions } from '../../../engine/core/ui/action';
import PartnerWarningPopup from '../../components/Popup/PartnerWarningPopup';

class Routers extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    authCleanUp: PropTypes.func,
    getStatisticsAsync: PropTypes.func,
    getUserInfoAsync: PropTypes.func,
    isUserAuthorized: PropTypes.bool,
    logOut: PropTypes.func,
    clearCurrentFiltersTypeList: PropTypes.func,
    clearCurrentFiltersNameList: PropTypes.func,
    userRemember: PropTypes.bool,
    userInfo: PropTypes.object,
  };

  static defaultProps = {
    authCleanUp: () => {},
    getStatisticsAsync: () => {},
    getUserInfoAsync: () => {},
    clearCurrentFiltersTypeList: () => {},
    clearCurrentFiltersNameList: () => {},
    isUserAuthorized: false,
    logOut: () => {},
    userRemember: false,
  };

  constructor(props) {
    super(props);

    this.getStatisticsInterval = null;
    this.rememberMeSessionStorageKey = 'rememberMe';

    this.getUserInfoAsync = this.getUserInfoAsync.bind(this);
    this.getStatisticsAsync = this.getStatisticsAsync.bind(this);
    this.stopGettingStatistics = this.stopGettingStatistics.bind(this);
    this.authCleanUp = this.authCleanUp.bind(this);
    this.onUserAuthStateChange = this.onUserAuthStateChange.bind(this);
    this.userLogout = this.userLogout.bind(this);
  }

  componentDidMount() {
    googleAnalytics();
    this.getUserInfoAsync();
    this.getStatisticsAsync();
    this.authCleanUp();
  }

  componentDidUpdate(prevProps) {
    const {
      isUserAuthorized,
    } = this.props;
    if (isUserAuthorized !== prevProps.isUserAuthorized) {
      this.onUserAuthStateChange();
    }
  }

  componentWillUnmount() {
    this.stopGettingStatistics();
  }

  shouldComponentUpdate(nextProps) {
    const {
      isUserAuthorized, userRemember, userInfo,
      clearCurrentFiltersTypeList, clearCurrentFiltersNameList, pathname,
    } = this.props;
    if (nextProps.pathname !== pathname) {
      clearCurrentFiltersTypeList();
      clearCurrentFiltersNameList();
    }
    return (isUserAuthorized !== nextProps.isUserAuthorized)
      || (userRemember !== nextProps.userRemember)
      || (userInfo !== nextProps.userInfo);
  }

  authCleanUp() {
    const { authCleanUp, userRemember } = this.props;
    const rememberMeSession = sessionStorage.getItem(this.rememberMeSessionStorageKey);
    if (rememberMeSession === null && userRemember === false) {
      authCleanUp();
    }
  }

  onUserAuthStateChange() {
    const { isUserAuthorized } = this.props;
    if (isUserAuthorized) {
      this.getStatisticsAsync();
      return;
    }
    this.stopGettingStatistics();
  }

  getStatisticsAsync() {
    const { getStatisticsAsync, isUserAuthorized } = this.props;
    if (isUserAuthorized) {
      getStatisticsAsync();
      this.getStatisticsInterval = setInterval(getStatisticsAsync, apiTime.statistics.getLists);
    }
  }

  stopGettingStatistics() {
    clearInterval(this.getStatisticsInterval);
  }

  getUserInfoAsync() {
    const { getUserInfoAsync } = this.props;
    getUserInfoAsync();
  }

  userLogout() {
    const { logOut } = this.props;
    toast.dismiss();
    logOut(true);
  }

  render() {
    return (
      <HistoryRouter history={history}>
        <PartnerWarningPopup />
        <Routes>
          {/* OpenRoutes */}
          <Route element={<EnterContract />} path={routersLink.infoPage.enterContract} />
          <Route element={<Error404 />} path={routersLink.errors.status404} />
          <Route element={<Error500 />} path={routersLink.errors.status500} />
          {/* AuthRoutes */}
          <Route element={<ProtectedElement element={<SingIn />} />} path={routersLink.users.signIn} />
          <Route element={<ProtectedElement element={<SingIn />} />} path={`/ru${routersLink.users.signIn}`} />
          <Route element={<ProtectedElement element={<SingIn />} />} path={`/ua${routersLink.users.signIn}`} />
          <Route element={<ProtectedElement element={<SignUp />} />} path={routersLink.users.signUp} />
          <Route element={<ProtectedElement element={<SignUp />} />} path={`/ru${routersLink.users.signUp}`} />
          <Route element={<ProtectedElement element={<SignUp />} />} path={`/ua${routersLink.users.signUp}`} />
          <Route element={<ProtectedElement element={<PasswordReset />} />} path={routersLink.users.password_reset} />
          <Route element={<ProtectedElement element={<PasswordReset />} />} path={`/ru${routersLink.users.password_reset}`} />
          <Route element={<ProtectedElement element={<PasswordReset />} />} path={`/ua${routersLink.users.password_reset}`} />
          <Route element={<ProtectedElement element={<PasswordChange />} />} path={routersLink.users.password_change} />
          <Route element={<ProtectedElement element={<PasswordChange />} />} path={`/ru${routersLink.users.password_change}`} />
          <Route element={<ProtectedElement element={<PasswordChange />} />} path={`/ua${routersLink.users.password_change}`} />
          <Route element={<Confirm />} path={routersLink.users.confirm} />
          {/* CabinetRoutes */}
          <Route element={<ProtectedLayout />} path="/">
            <Route element={<MainRedirects />} path={routersLink.dashboard} />
            <Route element={<Links />} path={routersLink.pageList} />
            <Route element={<Main />} path="*" />
          </Route>
        </Routes>
      </HistoryRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserAuthorized: !isEmpty(selectors.user.userInfo(state)),
    userRemember: selectors.user.userRemember(state),
    userInfo: selectors.user.userInfo(state),
    pathname: selectors.router.pathname(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authCleanUp: bindActionCreators(userActionAsync.authCleanUp, dispatch),
    getStatisticsAsync: bindActionCreators(statisticsActionAsync.getStatisticsAsync, dispatch),
    getUserInfoAsync: bindActionCreators(userActionAsync.getUserInfoAsync, dispatch),
    logOut: bindActionCreators(userActionAsync.logOutAsync, dispatch),
    clearCurrentFiltersTypeList: bindActionCreators(uiActions.clearCurrentFiltersTypeList, dispatch),
    clearCurrentFiltersNameList: bindActionCreators(uiActions.clearCurrentFiltersNameList, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Routers);
