// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ViberIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 18 18">
      <path d="M5.97364 4.6515C5.83425 4.63121 5.69211 4.65927 5.57089 4.731H5.56039C5.27914 4.896 5.02564 5.10375 4.80964 5.34825C4.62964 5.556 4.53214 5.766 4.50664 5.9685C4.49164 6.0885 4.50214 6.21 4.53739 6.32475L4.55089 6.33225C4.75339 6.927 5.01739 7.49925 5.33989 8.03775C5.75573 8.79412 6.26745 9.49365 6.86239 10.119L6.88039 10.1445L6.90889 10.1655L6.92614 10.1858L6.94714 10.2037C7.57468 10.8005 8.27585 11.3146 9.03364 11.7338C9.89989 12.2055 10.4256 12.4282 10.7414 12.5212V12.5258C10.8336 12.5542 10.9176 12.567 11.0024 12.567C11.2713 12.5471 11.5258 12.4378 11.7254 12.2565C11.9691 12.0405 12.1754 11.7863 12.3359 11.5035V11.4983C12.4866 11.2133 12.4356 10.9448 12.2181 10.7625C11.7813 10.3808 11.3089 10.0417 10.8074 9.75C10.4714 9.56775 10.1301 9.678 9.99214 9.8625L9.69739 10.2345C9.54589 10.419 9.27139 10.3935 9.27139 10.3935L9.26389 10.398C7.21564 9.87525 6.66889 7.8015 6.66889 7.8015C6.66889 7.8015 6.64339 7.5195 6.83314 7.3755L7.20214 7.0785C7.37914 6.9345 7.50214 6.594 7.31239 6.258C7.02259 5.75607 6.68422 5.28381 6.30214 4.848C6.2188 4.74543 6.10194 4.67558 5.97214 4.65075L5.97364 4.6515ZM9.43489 3.75C9.33543 3.75 9.24005 3.78951 9.16972 3.85984C9.0994 3.93016 9.05989 4.02554 9.05989 4.125C9.05989 4.22446 9.0994 4.31984 9.16972 4.39016C9.24005 4.46049 9.33543 4.5 9.43489 4.5C10.3829 4.5 11.1704 4.80975 11.7936 5.40375C12.1139 5.7285 12.3636 6.11325 12.5271 6.53475C12.6914 6.957 12.7664 7.40775 12.7469 7.85925C12.7427 7.95871 12.7782 8.05575 12.8456 8.12903C12.913 8.20231 13.0067 8.24582 13.1061 8.25C13.2056 8.25418 13.3026 8.21867 13.3759 8.1513C13.4492 8.08393 13.4927 7.99021 13.4969 7.89075C13.5202 7.33536 13.428 6.78119 13.2261 6.26325C13.0234 5.74285 12.7161 5.26959 12.3231 4.87275L12.3156 4.86525C11.5424 4.1265 10.5644 3.75 9.43489 3.75Z" fill="#303030" />
      <path d="M9.40869 4.98317C9.30924 4.98317 9.21385 5.02268 9.14353 5.09301C9.0732 5.16333 9.03369 5.25872 9.03369 5.35817C9.03369 5.45763 9.0732 5.55301 9.14353 5.62334C9.21385 5.69366 9.30924 5.73317 9.40869 5.73317H9.42144C10.1054 5.78192 10.6034 6.00992 10.9522 6.38417C11.3099 6.76967 11.4952 7.24892 11.4809 7.84142C11.4787 7.94088 11.516 8.03717 11.5847 8.10911C11.6534 8.18106 11.7479 8.22276 11.8473 8.22505C11.9468 8.22733 12.0431 8.19002 12.115 8.12131C12.187 8.0526 12.2287 7.95813 12.2309 7.85867C12.2489 7.08092 11.9984 6.40967 11.5019 5.87417V5.87267C10.9942 5.32817 10.2974 5.04017 9.45894 4.98392L9.44619 4.98242L9.40869 4.98317Z" fill="#303030" />
      <path d="M9.39429 6.23973C9.3441 6.2353 9.29354 6.24103 9.24562 6.25658C9.1977 6.27212 9.1534 6.29717 9.11537 6.33021C9.07734 6.36326 9.04636 6.40362 9.02427 6.44891C9.00219 6.49419 8.98946 6.54346 8.98684 6.59377C8.98421 6.64408 8.99176 6.69441 9.00902 6.74174C9.02627 6.78907 9.05289 6.83244 9.08728 6.86926C9.12167 6.90607 9.16313 6.93558 9.20917 6.95603C9.25522 6.97647 9.30491 6.98742 9.35529 6.98823C9.66879 7.00473 9.86904 7.09923 9.99504 7.22598C10.1218 7.35348 10.2163 7.55823 10.2335 7.87848C10.2345 7.92881 10.2455 7.97843 10.2661 8.02439C10.2866 8.07035 10.3161 8.11171 10.353 8.146C10.3898 8.1803 10.4332 8.20682 10.4805 8.224C10.5278 8.24117 10.5781 8.24865 10.6284 8.24598C10.6786 8.24331 10.7278 8.23055 10.7731 8.20846C10.8183 8.18637 10.8586 8.1554 10.8916 8.1174C10.9246 8.0794 10.9496 8.03514 10.9652 7.98726C10.9807 7.93939 10.9864 7.88887 10.982 7.83873C10.958 7.38873 10.817 6.99123 10.5283 6.69873C10.238 6.40623 9.84279 6.26373 9.39429 6.23973Z" fill="#303030" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.67455 1.40696C8.10202 0.864345 10.6194 0.864345 13.0468 1.40696L13.3054 1.46418C14.015 1.62282 14.6668 1.97509 15.1882 2.48185C15.7097 2.98862 16.0804 3.63003 16.2592 4.33482C16.8754 6.76292 16.8754 9.30646 16.2592 11.7346C16.0804 12.4394 15.7097 13.0808 15.1882 13.5875C14.6668 14.0943 14.015 14.4466 13.3054 14.6052L13.0461 14.6624C11.5257 15.0024 9.96563 15.1305 8.41017 15.0431L6.3863 16.8541C6.31 16.9224 6.21676 16.969 6.11631 16.989C6.01587 17.009 5.9119 17.0016 5.81526 16.9677C5.71862 16.9338 5.63285 16.8746 5.5669 16.7963C5.50096 16.7179 5.45725 16.6233 5.44035 16.5223L5.10546 14.5221C4.46327 14.3222 3.88306 13.9611 3.42028 13.473C2.9575 12.985 2.62761 12.3864 2.46215 11.7346C1.84595 9.30647 1.84595 6.76292 2.46215 4.33482C2.64098 3.63003 3.01171 2.98862 3.53315 2.48185C4.0546 1.97509 4.70633 1.62282 5.41594 1.46418L5.67455 1.40696ZM12.7974 2.52303C10.5342 2.0171 8.1872 2.0171 5.92401 2.52303L5.66463 2.58101C5.1617 2.69365 4.69982 2.94348 4.3303 3.30277C3.96077 3.66206 3.69807 4.11674 3.57135 4.61632C3.00203 6.85968 3.00203 9.20971 3.57135 11.4531C3.69813 11.9527 3.96095 12.4075 4.33061 12.7668C4.70028 13.1261 5.16232 13.3759 5.6654 13.4884L5.73405 13.5036C5.84512 13.5285 5.94626 13.5859 6.02453 13.6685C6.1028 13.7511 6.15466 13.8552 6.17346 13.9675L6.39774 15.3086L7.82582 14.0308C7.88284 13.9796 7.94957 13.9404 8.02204 13.9155C8.09451 13.8906 8.17124 13.8805 8.24768 13.8858C9.77268 13.994 11.3053 13.8797 12.7974 13.5464L13.056 13.4884C13.5591 13.3759 14.0211 13.1261 14.3908 12.7668C14.7604 12.4075 15.0233 11.9527 15.15 11.4531C15.7191 9.21026 15.7191 6.85989 15.15 4.61632C15.0233 4.11664 14.7604 3.6619 14.3908 3.3026C14.0211 2.9433 13.5591 2.69352 13.056 2.58101L12.7974 2.52303Z" fill="#303030" />
    </SvgIcon>
  );
}

export default ViberIcon;
