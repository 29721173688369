// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// hooks
import { useAccessList } from '../../../hooks/useAccessList';

// parts
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';

// Engine
import { selectors } from '../../../../engine/config/selectors';
import { accessList } from '../../../../engine/config/access';
import { asyncActions } from '../../../../engine/core/orders/saga/asyncActions';

function DownloadOrdersCSVButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasOrderCsvDownload = useAccessList(accessList.orderCsvDownload);
  const isLoadingOrdersDownloadCsvButton = useSelector(selectors.orders.isLoadingOrdersDownloadCsvButton);

  const handleDownloadCSVAsync = () => {
    dispatch(asyncActions.downloadCSVAsync({ format: 'csv' }));
  };

  if (!hasOrderCsvDownload) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      text={t('Скачать CSV')}
      onClick={handleDownloadCSVAsync}
      disabled={isLoadingOrdersDownloadCsvButton}
      isLoading={isLoadingOrdersDownloadCsvButton}
    >
      <CloudDownloadIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default DownloadOrdersCSVButton;
