const ContentProductStatusProviderStyles = theme => ({
  fs12: { fontSize: 12 },
  input: {
    display: 'block',
    borderRadius: '4px',
    paddingTop: '8.5px',
    paddingBottom: '8.5px',
    paddingLeft: '14px',
    paddingRight: '14px',
    margin: '0px',
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    '@global input': {
      boxSizing: 'border-box',
      fontSize: 14,
      height: 35,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
    },
    '@global legend': {
      display: 'none',
    },
    '@global fieldset': {
      top: 0,
    },
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
    cursor: 'default',
  },
  selectMarginBoth: {
    marginBottom: 5,
  },
  select: {
    width: '100%',
    '@global': {
      div: {
        paddingTop: '8.5px',
        paddingBottom: '8.5px',
      },
    },
  },
});

export default ContentProductStatusProviderStyles;
