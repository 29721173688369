import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { COLOR } from '../../../containers/App/AppStyles';
import SplitButton from '../../Buttons/SplitButton/SplitButton';
import ButtonWithIconAndCircularProgress from '../../Buttons/ButtonWithIconAndCircularProgress';

export const InfoKeyCell = ({ children, sx }) => (
  <Box sx={{
    minHeight: '10px', marginBottom: '4px', minWidth: '90px', display: 'flex', ...sx,
  }}
  >{children}
  </Box>
);

export const InfoValueCell = ({ children, sx }) => (<InfoKeyCell sx={{ fontSize: '14px', color: COLOR.black.initial, ...sx }}>{children}</InfoKeyCell>);

export const InfoRow = ({
  infoKey, infoValue, infoValueSx, infoKeySx,
}) => (
  <Box sx={{ display: 'flex' }}>
    <InfoKeyCell sx={{ ...infoKeySx }}>{infoKey}</InfoKeyCell>
    <InfoValueCell sx={{ ...infoValueSx }}>{infoValue}</InfoValueCell>
  </Box>
);

export const ButtonComponent = ({
  options, handleEdit, handleFinishImport, buttonText, label,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    <ButtonWithIconAndCircularProgress text={buttonText} onClick={handleFinishImport} sx={{ minHeight: '36px' }} />
    <SplitButton options={options} handleClick={handleEdit} sx={{ height: '36px' }} withLabel label={label} />
  </Box>
);

export const MatchingResult = ({ value }) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoRow infoKeySx={{ minWidth: '220px' }} infoKey={t('autocompared')} infoValue={value?.matched ?? value?.count} />
      <InfoRow infoKeySx={{ minWidth: '220px' }} infoKey={t('not autocompared')} infoValue={value?.not_matched ?? 0} />
    </>
  );
};
