export const types = Object.freeze({
  SET_CATEGORIES_FEATURE_MANAGEMENT_LIST: 'SET_CATEGORIES_FEATURE_MANAGEMENT_LIST',
  SET_CATEGORIES_FEATURE_MANAGEMENT_CURRENT_PAGE: 'SET_CATEGORIES_FEATURE_MANAGEMENT_CURRENT_PAGE',
  SET_CATEGORIES_FEATURE_MANAGEMENT_FILTERS: 'SET_CATEGORIES_FEATURE_MANAGEMENT_FILTERS',
  SET_CATEGORIES_FEATURE_MANAGEMENT_RELOAD: 'SET_CATEGORIES_FEATURE_MANAGEMENT_RELOAD',
  SET_CATEGORIES_FEATURE_MANAGEMENT_PAGE_SIZE: 'SET_CATEGORIES_FEATURE_MANAGEMENT_PAGE_SIZE',
  SET_CATEGORIES_FEATURE_MANAGEMENT_SORTING: 'SET_CATEGORIES_FEATURE_MANAGEMENT_SORTING',
  SET_CATEGORIES_FEATURE_MANAGEMENT_TREE_DATA_ROW_IDS: 'SET_CATEGORIES_FEATURE_MANAGEMENT_TREE_DATA_ROW_IDS',
  SET_CATEGORIES_FEATURE_MANAGEMENT_INITIAL_STATE: 'SET_CATEGORIES_FEATURE_MANAGEMENT_INITIAL_STATE',
  SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_STATUS: 'SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_STATUS',
  SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_PENDING: 'SET_CATEGORIES_FEATURE_MANAGEMENT_SETTING_PENDING',
  SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_UPLOADING: 'SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_UPLOADING',
  SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_IS_OPEN: 'SET_CATEGORIES_FEATURE_MANAGEMENT_COMMISSIONS_UPLOAD_IS_OPEN',
  MERGE_CATEGORIES_FEATURE_IMPORT: 'MERGE_CATEGORIES_FEATURE_IMPORT',
  MERGE_CATEGORIES_FEATURE_EXPORT: 'MERGE_CATEGORIES_FEATURE_EXPORT',
});
