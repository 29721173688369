// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
// Icons
import CloseIcon from '@mui/icons-material/Close';
// Helpers
import { useStyles } from '../../hooks/useStyles';
import NotificationStyle from './NotificationStyle';

function NotificationCloseButton({ onClose, closeToast }) {
  const classes = useStyles(NotificationStyle);
  const handleClick = () => {
    closeToast();
    onClose({ isCloseBtn: true });
  };

  return (
    <button
      className={`Toastify__close-button Toastify__close-button--light ${classes.btnClose}`}
      type="button"
      onClick={handleClick}
    >
      <CloseIcon fontSize="small" />
    </button>
  );
}

NotificationCloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default memo(NotificationCloseButton);
