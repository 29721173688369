// Core
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Field, FieldArray, Form, reduxForm,
} from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router';

// UI
import Grid from '@mui/material/Grid';
// Icons
import CallMissedIcon from '@mui/icons-material/CallMissed';
// parts
import SectionHolder from '../../../../../containers/SectionHolder/SectionHolder';
import PageHeader from '../../../../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import ButtonGoBack from '../../../../../components/Buttons/ButtonGoBack';
import Loading from '../../../../../components/Loading/Loading';
import DeleteStopWordsButton from '../DeleteStopWordButton';
import ExeptionWordGroupsFields from './ExeptionWordGroupField/ExeptionWordGroupsFields';
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
import SelectCategoryTreeViewForm
  from '../../../../../components/_Form/Selects/SelectCategoryTreeView/SelectCategoryTreeViewForm';
// Engine
import { asyncActions } from '../../../../../../engine/core/stopList/stopWords/saga/asyncActions';
import { actions } from '../../../../../../engine/core/stopList/stopWords/actions';
import { pageLinks } from '../../../../../../engine/config/routes';
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions as partnersAsyncActions } from '../../../../../../engine/core/partners/saga/asyncActions';
import { partnerAsyncAction } from '../../../../../../engine/core/_partners/saga/asyncAction';
// helpers
import { validators } from '../../../../../../_helpers/validationsForm';
import { formName, formFields } from './form';


function WordPage(props) {
  const { handleSubmit, valid } = props;
  const { hashId } = useParams();
  const { t } = useTranslation();
  const postStopWordPending = useSelector(selectors.stopWords.postStopWordPending);
  const getStopWordHashIdPending = useSelector(selectors.stopWords.getStopWordHashIdPending);
  const putStopWordPending = useSelector(selectors.stopWords.putStopWordPending);
  const dispatch = useDispatch();
  const onFormSubmit = (formValues) => {
    if (hashId) {
      dispatch(asyncActions.putStopWordAsync({
        hashId,
        data: {
          phrase: formValues.get(formFields.word),
          categories: formValues.get(formFields.category),
          excludeWords: formValues.get(formFields.excludeWords),
          excludePartners: formValues.get(formFields.excludePartners),
        },
      }));
    } else {
      dispatch(asyncActions.postStopWordsAsync({
        phrase: formValues.get(formFields.word),
        categories: formValues.get(formFields.category),
        excludeWords: formValues.get(formFields.excludeWords),
        excludePartners: formValues.get(formFields.excludePartners),
      }));
    }
  };

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnerContextListAsync());
    dispatch(partnersAsyncActions.getListWithMidAsync({ statusId: 12 }));
    if (hashId) {
      dispatch(asyncActions.getStopWordHashIdAsync({
        hashId,
      }));
    }

    return () => {
      dispatch(actions.setStopWordHashId({}));
    };
  }, [dispatch, hashId]);

  return (
    <>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container justifyContent="center">
          <PageHeader title={hashId ? t('Редактирование стоп-слова') : t('Добавление стоп-слова')}>
            <ButtonGoBack defaultPage={pageLinks.stopWords.all} />
            <DeleteStopWordsButton hashId={hashId} isDisplay={Boolean(hashId)} />
            <ButtonWithIconAndCircularProgress
              text={t('Сохранить и вернуться')}
              isLoading={postStopWordPending}
              disabled={putStopWordPending || postStopWordPending || !valid}
              onClick={handleSubmit}
              component="button"
              type="button"
            >
              <CallMissedIcon />
            </ButtonWithIconAndCircularProgress>
          </PageHeader>
          <Grid container xs={12}>
            <SectionHolder>
              {hashId && getStopWordHashIdPending ? (
                <Grid container justifyContent="center" alignContent="center">
                  <Loading isLoading />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name={formFields.word}
                    component={TextField}
                    fullWidth
                    validation
                    margin="normal"
                    label={t('Введите стоп-слово')}
                    validate={[validators.required]}
                  />
                  <Field
                    name={formFields.category}
                    variant="outlined"
                    component={SelectCategoryTreeViewForm}
                    fullWidth
                    validation
                    margin="normal"
                  />
                  <Field
                    name={formFields.excludePartners}
                    component={PartnerSelect}
                    partnerListSelector={selectors.partners.listWithMid}
                    fullWidth
                    isMulti
                    validation
                    margin="normal"
                    label={t('Партнеры-исключения')}
                  />
                  <FieldArray name={formFields.excludeWords} component={ExeptionWordGroupsFields} />
                </Grid>
              )}
            </SectionHolder>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

const reduxFormConfig = {
  form: formName,
};

WordPage.propsType = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm(reduxFormConfig)(memo(WordPage));
