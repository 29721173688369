// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetReconciliationDatesWorker,
  callGetBillingReconciliationActDownload,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_REPORTS_LIST_ASYNC, callGetListWorker);
}
function* watchReconciliationDates() {
  yield takeEvery(asyncTypes.GET_RECONCILIATION_DATES, callGetReconciliationDatesWorker);
}
function* watchGetBillingReconciliationActDownload() {
  yield takeEvery(asyncTypes.GET_RECONCILIATION_ACT_DOWNLOAD, callGetBillingReconciliationActDownload);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_REPORTS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_REPORTS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_REPORTS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_REPORTS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_REPORTS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_REPORTS_SORTING, callSetToLocalDataWorker);
}

export function* watchersReports() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchReconciliationDates(),
    watchGetBillingReconciliationActDownload(),
  ]);
}
