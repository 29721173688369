// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';
import { asyncActions } from '../asyncActions';
import { SD_CHAT_MESSAGES_PER_PAGE } from '../../../../../ui/page/ServiceDesk/components/ChatComponents';

export function* callPostContinueQuestion({ payload }) {
  yield put(actions.setContinueQuestionPending(true));

  const { hashId } = payload;
  const response = yield apply(api, api.serviceDesk.postContinueQuestion, [{ hashId }]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(asyncActions.getMessages({
          hashId, offset: 0, limit: SD_CHAT_MESSAGES_PER_PAGE, resetState: true,
        }));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setContinueQuestionPending(false));
}
