// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Containers
import Modal from '../../../containers/Modals/Modal/Modal';

// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/orders/actions';

// Hooks
import { useStyles } from '../../../hooks/useStyles';

const OrderErrorModalStyle = theme => ({
  message: {
    '@global': {
      a: {
        color: theme.palette.primary.main,
      },
    },
  },
});

function OrderErrorModal() {
  const dispatch = useDispatch();
  const classes = useStyles(OrderErrorModalStyle);
  const errorModalSelector = useSelector(selectors.orders.errorModal);
  const { modalOpen, title, message } = errorModalSelector.toJS();

  const handleModalUploadToggle = () => {
    dispatch(actions.mergeErrorModal({
      title: undefined,
      message: undefined,
      modalOpen: false,
    }));
  };

  return (
    <Modal
      hideButtons
      modalOpen={modalOpen}
      handleModalToggle={handleModalUploadToggle}
      handleModalCancel={handleModalUploadToggle}
      title={title}
    >
      <div className={classes.message} dangerouslySetInnerHTML={{ __html: message }} />
    </Modal>
  );
}

export default OrderErrorModal;
