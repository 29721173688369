export const types = Object.freeze({
  SET_CONTENT_PRODUCT_STATUS_LOGS_LIST: 'SET_CONTENT_PRODUCT_STATUS_LOGS_LIST',
  SET_CONTENT_PRODUCT_STATUS_LOGS_CURRENT_PAGE: 'SET_CONTENT_PRODUCT_STATUS_LOGS_CURRENT_PAGE',
  SET_CONTENT_PRODUCT_STATUS_LOGS_FILTERS: 'SET_CONTENT_PRODUCT_STATUS_LOGS_FILTERS',
  SET_CONTENT_PRODUCT_STATUS_LOGS_PAGE_SIZE: 'SET_CONTENT_PRODUCT_STATUS_LOGS_PAGE_SIZE',
  SET_CONTENT_PRODUCT_STATUS_LOGS_SORTING: 'SET_CONTENT_PRODUCT_STATUS_LOGS_SORTING',
  SET_CONTENT_PRODUCT_STATUS_LOGS_INITIAL_STATE: 'SET_CONTENT_PRODUCT_STATUS_LOGS_INITIAL_STATE',
  SET_CONTENT_PRODUCT_STATUS_LOGS_SETTING_STATUS: 'SET_CONTENT_PRODUCT_STATUS_LOGS_SETTING_STATUS',
  SET_CONTENT_PRODUCT_STATUS_LOGS_SETTING_PENDING: 'SET_CONTENT_PRODUCT_STATUS_LOGS_SETTING_PENDING',
});
