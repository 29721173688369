// Core
import { apply, put, select } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { replace } from 'redux-first-history';

// Instruments
import i18n from '../../../../init/i18n';
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../action';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { formName } from '../../../../../ui/page/Settings/EmailTemplates/EmailTemplatesEdit';
import { selectors } from '../../../../config/selectors';
import { pageLinks } from '../../../../config/routes';

export function* callPutMailTemplateByHashIdWorker({ payload }) {
  yield put(actions.setPutMailTemplateByHashIdPending(true));

  const response = yield apply(api, api.mail.putMailTemplateByHashId, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(i18n.t('Пожалуйста, выберите правильные значения для всех полей!'), statusTitle));
        const submissionErrors = errors.reduce((acc, currentValue) => {
          acc[currentValue.property_path] = currentValue.message;
          return acc;
        }, {});

        yield put(stopSubmit(formName, submissionErrors));
        break;
      }
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        if (payload?.redirect) {
          const prevLocation = yield select(selectors.location.prevLocation);
          if (prevLocation) {
            yield put(replace(prevLocation));
          } else {
            yield put(replace(pageLinks.settings.emailTemplates.all));
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    yield put(actions.setPutMailTemplateByHashIdPending(false));
  }
}
