// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import DxTable from '../../../../components/_Table/DxTable/DxTable';

// Config
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';

// Parts
import TableEditCell from './TableEditCell';

// Hooks
import { usePartnersAccessList } from '../../_hooks/usePartnersAccessList';
import { useAccessList } from '../../../../hooks/useAccessList';
import { useEditCellWidth } from '../../../../hooks/useEditCellWidth';

// Actions
import { asyncActions } from '../../../../../engine/core/partners/accesses/saga/asyncActions';
import { actions } from '../../../../../engine/core/partners/accesses/actions';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../../engine/config/userRoles';

const RowComponent = ({
  children, row, tableRow, ...restProps
}) => (
  <VirtualTable.Row
    {...restProps}
    style={row?.archived ? { opacity: 0.4 } : {}}
  >
    {children}
  </VirtualTable.Row>
);


function PartnersAccessTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.partnersAccess.isLoading);
  const hasAccessEdit = useAccessList(accessList.partnerAccessEdit);
  const hasAccessShow = useAccessList(accessList.partnerAccessShow);
  const hasAccessDelete = useAccessList(accessList.partnerAccessDelete);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);
  const isPartnerAccess = isPartnerRoles ? [hasAccessEdit, hasAccessShow] : [hasAccessEdit || hasAccessShow];
  const editWidth = useEditCellWidth([...isPartnerAccess, hasAccessDelete], 2);
  // Rows
  const rows = useSelector(selectors.partnersAccess.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.partnersAccess.currentPage);
  const pageSize = useSelector(selectors.partnersAccess.pageSize);
  const totalCount = useSelector(selectors.partnersAccess.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.partnersAccess.sorting);
  // Settings
  const settingStatus = useSelector(selectors.partnersAccess.settingStatus);
  const settingPending = useSelector(selectors.partnersAccess.settingPending);
  // Filtering
  const filters = useSelector(selectors.partnersAccess.filters);

  return (
    <DxTable
      name="partnersPaymentAccesses"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePartnersAccessList}
      rowComponent={RowComponent}
      // Editing
      editComponent={TableEditCell}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings.partnersPaymentAccesses.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(PartnersAccessTable);
