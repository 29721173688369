// UI
import SvgIcon from '@mui/material/SvgIcon';

export default function TranslateIcon(props) {
  return (
    <SvgIcon className="person-outlined-icon" fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <path d="M6.60345 3.93299L6.6473 4.02691L11.6521 17.0789C11.7853 17.4261 11.6171 17.8177 11.2766 17.9535C10.9644 18.0779 10.6157 17.9442 10.4572 17.6536L10.4188 17.5705L9.12889 14.2066L2.74985 14.2068L2.66519 14.2014L1.27289 17.5858C1.13147 17.9296 0.743447 18.0915 0.406223 17.9473C0.0971009 17.8151 -0.0620355 17.4717 0.0225194 17.1505L0.0516921 17.0636L5.42004 4.01165C5.63657 3.4852 6.34076 3.46284 6.60345 3.93299ZM15.0694 0C15.4112 0 15.6937 0.253977 15.7384 0.583495L15.7445 0.675102L15.7443 4.94715L17.3198 4.94784C17.6616 4.94784 17.944 5.20182 17.9887 5.53134L17.9949 5.62294C17.9949 5.96472 17.7409 6.24718 17.4114 6.29188L17.3198 6.29805L15.7443 6.29735L15.7445 12.824C15.7445 13.1658 15.4906 13.4483 15.161 13.493L15.0694 13.4991C14.7277 13.4991 14.4452 13.2452 14.4005 12.9156L14.3943 12.824V0.675102C14.3943 0.302254 14.6966 0 15.0694 0ZM6.12697 6.07065L3.2806 12.8564H8.78043L6.12697 6.07065ZM7.87021 0H12.819C13.1607 0 13.4432 0.253977 13.4879 0.583495L13.4941 0.675102V3.37884C13.4941 5.48929 11.7832 7.20014 9.67276 7.20014C9.29992 7.20014 8.99766 6.89789 8.99766 6.52504C8.99766 6.15219 9.29992 5.84994 9.67276 5.84994C10.987 5.84994 12.0616 4.82402 12.1394 3.52937L12.1439 3.37884V1.3502H7.87021C7.49736 1.3502 7.19511 1.04795 7.19511 0.675102C7.19511 0.333324 7.44909 0.0508659 7.7786 0.00616293L7.87021 0H12.819H7.87021Z" fill="white" />
    </SvgIcon>
  );
}
