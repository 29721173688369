export const types = Object.freeze({
  // PRODUCT_FEED_ATTRIBUTES
  MERGE_PRODUCT_FEED_ATTRIBUTES_ITEMS: 'MERGE_PRODUCT_FEED_ATTRIBUTES_ITEMS',
  SET_PRODUCT_FEED_ATTRIBUTES_EXPANDED_ROW_IDS: 'SET_PRODUCT_FEED_ATTRIBUTES_EXPANDED_ROW_IDS',
  SET_DEFAULT_PRODUCT_FEED_ATTRIBUTES: 'SET_DEFAULT_PRODUCT_FEED_ATTRIBUTES',
  SET_PRODUCT_FEED_ATTRIBUTES_ITEM_BY_HASH_ID_AND_POSITION: 'SET_PRODUCT_FEED_ATTRIBUTES_ITEM_BY_HASH_ID_AND_POSITION',
  // PRODUCT_FEED_CATEGORIES
  SET_PRODUCT_FEED_CATEGORIES_EXPANDED_ROW_IDS: 'SET_PRODUCT_FEED_CATEGORIES_EXPANDED_ROW_IDS',
  MERGE_PRODUCT_FEED_CATEGORIES_LOADING: 'MERGE_PRODUCT_FEED_CATEGORIES_LOADING',
  DELETE_PRODUCT_FEED_CATEGORIES_LOADING: 'DELETE_PRODUCT_FEED_CATEGORIES_LOADING',
  MERGE_PRODUCT_FEED_CATEGORIES_ITEMS: 'MERGE_PRODUCT_FEED_CATEGORIES_ITEMS',
  SET_PRODUCT_FEED_CATEGORIES_ITEM_BY_HASH_ID_AND_POSITION: 'SET_PRODUCT_FEED_CATEGORIES_ITEM_BY_HASH_ID_AND_POSITION',
  SET_DEFAULT_PRODUCT_FEED_CATEGORIES: 'SET_DEFAULT_PRODUCT_FEED_CATEGORIES',
  // PRODUCT_FEED_CATEGORIES_ATTRIBUTES
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_LIST',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_CURRENT_PAGE: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_CURRENT_PAGE',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_FILTERS: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_FILTERS',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_PAGE_SIZE: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_PAGE_SIZE',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SORTING: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SORTING',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_INITIAL_STATE',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_STATUS: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_STATUS',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_PENDING: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_SETTING_PENDING',
  SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_PENDING: 'SET_PRODUCT_FEED_CATEGORIES_ATTRIBUTES_AUTO_CONNECT_PENDING',
});
