export const types = Object.freeze({
  SET_CATEGORIES_FEATURES_ITEM_LIST: 'SET_CATEGORIES_FEATURES_ITEM_LIST',
  SET_CATEGORIES_FEATURES_ITEM_CURRENT_PAGE: 'SET_CATEGORIES_FEATURES_ITEM_CURRENT_PAGE',
  SET_CATEGORIES_FEATURES_ITEM_FILTERS: 'SET_CATEGORIES_FEATURES_ITEM_FILTERS',
  SET_CATEGORIES_FEATURES_ITEM_PAGE_SIZE: 'SET_CATEGORIES_FEATURES_ITEM_PAGE_SIZE',
  SET_CATEGORIES_FEATURES_ITEM_SORTING: 'SET_CATEGORIES_FEATURES_ITEM_SORTING',
  SET_CATEGORIES_FEATURES_ITEM_INITIAL_STATE: 'SET_CATEGORIES_FEATURES_ITEM_INITIAL_STATE',
  SET_CATEGORIES_FEATURES_ITEM_SETTING_STATUS: 'SET_CATEGORIES_FEATURES_ITEM_SETTING_STATUS',
  SET_CATEGORIES_FEATURES_ITEM_SETTING_PENDING: 'SET_CATEGORIES_FEATURES_ITEM_SETTING_PENDING',
  SET_CATEGORIES_FEATURES_ITEM_FEATURE_REQUIRES_LK: 'SET_CATEGORIES_FEATURES_ITEM_FEATURE_REQUIRES_LK',
});
