// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  contentTemplatesList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  productsImportPending: false,
  productsImportTemplatesDownload: Map(),
  contentTemplatesImportModal: Map({
    isOpen: false,
    hideSubmitButton: false,
  }),
  contentUpdateFromMagento: Map({
    pending: false,
    categoryId: null,
  }),
  contentUpdateValuesFromMagento: Map({
    pending: false,
    featureId: null,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'contentTemplates',
  contentTemplatesList: 'contentTemplatesList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  categoryId: 'categoryId',
  featureId: 'featureId',
  productsFeatureValuesListDownload: 'productsFeatureValuesListDownload',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  hideSubmitButton: 'hideSubmitButton',
  contentUpdateFromMagento: 'contentUpdateFromMagento',
  productsImportTemplatesDownload: 'productsImportTemplatesDownload',
  productsImportPending: 'productsImportPending',
  contentTemplatesImportModal: 'contentTemplatesImportModal',
});

export const contentTemplatesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CONTENT_TEMPLATES_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.contentTemplatesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.contentTemplatesList, stateKeys.pending], pending)
        .setIn([stateKeys.contentTemplatesList, stateKeys.items], List(items))
        .setIn([stateKeys.contentTemplatesList, stateKeys.totalCount], totalCount);
    }
    case types.SET_CONTENT_TEMPLATES_SORTING: {
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.sorting], List(payload));
    }
    case types.SET_CONTENT_TEMPLATES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.filters], List(filters));
    }
    case types.SET_CONTENT_TEMPLATES_PAGE_SIZE: {
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.pageSize], payload);
    }
    case types.SET_CONTENT_TEMPLATES_CURRENT_PAGE: {
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.currentPage], payload);
    }
    case types.MERGE_CONTENT_TEMPLATES_LIST: {
      return state.mergeIn([stateKeys.contentTemplatesList], Map(payload));
    }
    case types.SET_CONTENT_TEMPLATES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.contentTemplatesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.contentTemplatesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.contentTemplatesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.contentTemplatesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.contentTemplatesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.contentTemplatesList, stateKeys.reload], reload)
        .setIn([stateKeys.contentTemplatesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_CONTENT_TEMPLATES_SETTING_STATUS: {
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.settingStatus], payload);
    }
    case types.SET_CONTENT_TEMPLATES_SETTING_PENDING: {
      return state.setIn([stateKeys.contentTemplatesList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_CONTENT_TEMPLATES_IMPORT_MODAL_OPEN: {
      return state.mergeIn([stateKeys.contentTemplatesImportModal], fromJS(payload));
    }
    case types.SET_CONTENT_TEMPLATES_IMPORT_MODAL_INITIAL_STATE: {
      return state.setIn([stateKeys.contentTemplatesImportModal], initialState.get(stateKeys.contentTemplatesImportModal));
    }
    case types.SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_PENDING: {
      return state.setIn([stateKeys.contentUpdateFromMagento, stateKeys.pending], payload);
    }
    case types.SET_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_CATEGORY: {
      return state.setIn([stateKeys.contentUpdateFromMagento, stateKeys.categoryId], payload);
    }
    case types.SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_PENDING: {
      return state.setIn([stateKeys.contentUpdateValuesFromMagento, stateKeys.pending], payload);
    }
    case types.SET_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_FEATURE: {
      return state.setIn([stateKeys.contentUpdateValuesFromMagento, stateKeys.featureId], payload);
    }
    case types.SET_CONTENT_TEMPLATES_IMPORT_PENDING: {
      return state.setIn([stateKeys.productsImportPending], payload);
    }
    case types.SET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_STATE: {
      const downloadState = Object.values(payload || {});
      return downloadState.includes(true)
        ? state.mergeIn([stateKeys.productsImportTemplatesDownload], payload)
        : state.deleteIn([stateKeys.productsImportTemplatesDownload, Object.keys(payload).join()]);
    }
    default: {
      return state;
    }
  }
};
