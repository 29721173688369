// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/billing/partnersStatus/actions';
// Hooks
import { asyncActions } from '../../../../../engine/core/billing/partnersStatus/saga/asyncActions';
import { usePartnersStatusList } from '../_hooks/usePartnersStatusList';

// Redux
function PartnersStatusTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.billingPartnersStatus.isLoading);
  // Rows
  const rows = useSelector(selectors.billingPartnersStatus.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.billingPartnersStatus.currentPage);
  const pageSize = useSelector(selectors.billingPartnersStatus.pageSize);
  const totalCount = useSelector(selectors.billingPartnersStatus.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.billingPartnersStatus.sorting);
  // Settings
  const settingStatus = useSelector(selectors.billingPartnersStatus.settingStatus);
  const settingPending = useSelector(selectors.billingPartnersStatus.settingPending);
  // Filtering
  const filters = useSelector(selectors.billingPartnersStatus.filters);

  return (
    <DxTable
      name="billingStatusPartners"
      fixedColumn
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePartnersStatusList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      gridSettings={gridSettings?.billingStatusPartners?.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default PartnersStatusTable;
