// Core
import { apply, put } from 'redux-saga/effects';
import { List } from 'immutable';

// Instruments
import { api } from '../../../../config/api';
import { notificationsActions } from '../../action';

export function* callGetNotificationsGroupsWorker({ payload: params }) {
  yield put(notificationsActions.mergeNotificationGroups({ pending: true }));

  const response = yield apply(api, api.notifications.getNotificationsGroups, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const arrayOfObj = Object.keys(responseData).map(key => responseData[key]);
    yield put(notificationsActions.mergeNotificationGroups({
      item: List(arrayOfObj),
      pending: false,
    }));
  }
}
