// Core
import React, { useEffect, useMemo, useState } from 'react';
import {
  Form, isPristine, reduxForm,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';
// UI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LoadingButton from '@mui/lab/LoadingButton';
import TooltipCantFinish from '../TooltipCantFinish';
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
import HeaderComponent from './components/HeaderComponent';
import TableCell from './components/TableCell';
import ResetFiltersButton from '../../../../../../components/_Table/DxTable/components/ResetFilterButton';
import HelpModalInitialLoadPriceListCategories from './components/HelpModalInitialLoadPriceListCategories';
import ButtonWithIconAndCircularProgress from '../../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { locale } from '../../../../../../../engine/init/i18n';
import { asyncActions } from '../../../../../../../engine/core/products/productFeedCategories/saga/asyncActions';
import { asyncActions as asyncActionsFeeds } from '../../../../../../../engine/core/feeds/all/saga/asyncActions';
import { asyncActions as asyncActionsProductsFeeds } from '../../../../../../../engine/core/feeds/products/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/products/productFeedCategories/actions';
import { initialState, stateKeys } from '../../../../../../../engine/core/products/productFeedCategories/reducer';
import { userRoles } from '../../../../../../../engine/config/userRoles';
// Helpers
import { completionPriceListModalConfig } from '../ProductFeedParametersTable/ProductFeedParametersTable';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';
import { dispatchCompletionListItemData } from '../../_helpers/dispatchCompletionListItemData';
// Hooks
import { useProductFeedCategoriesList } from './_hooks/useProductFeedCategoriesList';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';
import CompleteImportModal from '../CompleteImportModal';
import { IMPORT_STEPS } from '../../constants';
import { api } from '../../../../../../../engine/config/api';

export const sortingColumnExtensions = [
  {
    columnName: tableDataTypes.feedProductQuantity.name,
    sortingEnabled: false,
  },
];

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const setWitdhByColumnName = (columnName) => {
  switch (true) {
    case columnName === tableDataTypes.productFeedCategory.name || columnName === tableDataTypes.category.name:
      return '35%';
    case columnName === tableDataTypes.ignored.name:
      return '12%';
    default:
      return '18%';
  }
};

export const formName = 'productFeed_step2';
function ProductFeedCategoriesTable(props) {
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const {
    handleSubmit, handleNextStep, handleBackStep, disable, setStepDisabled,
  } = props;

  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const isItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const pristine = useSelector(isPristine(formName));
  const { feedStatusData, canParse } = isItemByHashIdItem.toJS();
  const canSendHooks = isItemByHashIdLoading === false && feedStatusData === undefined;
  const autoConnectPending = useSelector(selectors.productFeedCategoriesAttributes.autoConnectPending);
  const inProcessAutoConnect = autoConnectPending || !canParse;
  useProductFeedCategoriesList(canSendHooks);

  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerPaymentWaiting,
  ]);
  const completionImportList = useSelector(selectors.feeds.completionImportList)?.toJS();
  const stepKey = completionPriceListModalConfig.steps.step_2;
  const isEditMode = completionImportList[hashId]?.isSaved;
  const modalTypeKey = completionPriceListModalConfig.type.import;

  const { t, i18n: { language } } = useTranslation();
  const isLoading = useSelector(selectors.productFeedCategories.isLoading);
  // Rows
  const rowsDefault = useSelector(selectors.productFeedCategories.list)?.toJS();
  const rows = rowsDefault.map((item) => {
    const itemValue = item.productFeedCategory.value;
    const itemLabel = item.productFeedCategory.label;

    return ({
      ...item,
      productFeedCategory: {
        ...item.productFeedCategory,
        label: itemValue ? `${itemValue} | ${itemLabel}` : itemLabel,
      },
    });
  });
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const grid = useMemo(() => {
    const lang = locale[language];
    return gridSettings.productFeedCategories.gridSettings.map(({ width, ...item }) => ({
      ...item,
      closeMenuOnScroll: true,
      width: setWitdhByColumnName(item.name), // TODO set from back-end
      title: item.lang?.[lang],
    })).filter(item => item.name !== 'feedProductQuantity');
  }, [gridSettings, language]);
  const columns = values(grid);
  const currentPage = useSelector(selectors.productFeedCategories.currentPage);
  const pageSize = useSelector(selectors.productFeedCategories.pageSize);
  const totalCount = useSelector(selectors.productFeedCategories.totalCount);
  const feedsIsItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canProceedToNextStep = feedsIsItemByHashIdItem.toJS().stepsData[2]?.canProceedToNextStep;
  const canAutoConnectCategory = feedsIsItemByHashIdItem.toJS().stepsData[2]?.canAutoConnectCategory;
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.productFeedCategories.sorting);
  // Settings
  const settingStatus = useSelector(selectors.productFeedCategories.settingStatus);
  const settingPending = useSelector(selectors.productFeedCategories.settingPending);
  // Filtering
  const filters = useSelector(selectors.productFeedCategories.filters);
  const isDisabledResetButton = settingPending || isLoading || !(filters.size || sorting.size);

  const exitCallback = () => {
    dispatch(asyncActionsFeeds.putCompletionImportListItemAsync({
      hashId,
      data: {
        ...completionImportList?.[hashId],
        isSaved: true,
      },
    }));
  };

  const onSubmit = () => {
    dispatch(asyncActionsProductsFeeds.postParseByHashIdAsync({
      hashId,
      onSuccess: exitCallback,
    }));
  };

  const showConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const resetFilters = () => {
    dispatch(actions.setFilters(initialState.get(stateKeys.productFeedCategoriesList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.productFeedCategoriesList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.productFeedCategoriesList).get(stateKeys.currentPage)));
  };

  useEffect(() => {
    if (isPartnerRoles && !isEditMode && !completionImportList?.[hashId]?.[modalTypeKey]?.[stepKey]?.isOpened) {
      dispatchCompletionListItemData({
        dispatch,
        hashId,
        completionImportList,
        modalTypeKey,
        stepKey,
        value: true,
        isOpened: false,
        isSaved: false,
      });
    }
  }, []);

  useEffect(() => {
    if (!canProceedToNextStep) {
      setStepDisabled(prev => ({ ...prev, [IMPORT_STEPS.CATEGORIES_ATTRIBUTES]: true }));
    } else {
      setStepDisabled(prev => ({ ...prev, [IMPORT_STEPS.CATEGORIES_ATTRIBUTES]: false }));
    }
  }, [canProceedToNextStep]);

  const handleAutoConnect = async () => {
    await api.feeds.postAutoConnect({ hashId, type: 'categories' });
    const offset = currentPage * currentPage;

    const filtersToParams = filters.toJS().reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {});

    const params = {
      ...filtersToParams,
      sorting,
      limit: pageSize,
      offset,
    };

    dispatch(asyncActions.getListAsync(params, hashId));
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    }}
    >
      <Box sx={{
        textAlign: 'right',
        mb: 1,
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      >
        <ResetFiltersButton
          disabled={isDisabledResetButton}
          pending={settingPending || isLoading}
          onReset={resetFilters}
        />
        <ButtonWithIconAndCircularProgress
          text={t('Автосопоставление категорий')}
          disabled={autoConnectPending || !canAutoConnectCategory}
          isLoading={autoConnectPending}
          onClick={handleAutoConnect}
          sx={{ marginLeft: '18px' }}
        >
          <AutoFixHighIcon />
        </ButtonWithIconAndCircularProgress>
      </Box>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
      >
        <DxTable
          type="static"
          name="productFeedCategories"
          customCellComponent={TableCell}
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          // Paging
          pageSize={pageSize}
          totalCount={totalCount}
          onPageSize={onPageSize}
          currentPage={currentPage}
          onCurrentPage={onCurrentPage}
          // Sorting
          onSortingChange={onSortingChange}
          sortingStateColumnExtensions={sortingColumnExtensions}
          sorting={sorting}
          // Filters
          filters={filters}
          filtersAction={actions.setFilters}
          // Settings
          // gridSettings={gridSettings.productFeedCategories.gridSettings}
          settingStatus={settingStatus}
          settingPending={settingPending}
          settingAction={asyncActions.putSettingsAsync}
          // Header
          customHeaderCellComponent={HeaderComponent}
          screenHeightFull
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        >
          <LoadingButton
            variant="outlined"
            onClick={handleBackStep}
            sx={{ mt: '30px', mr: 1 }}
            disabled={disable}
            loading={disable}
          >
            {t('Назад')}
          </LoadingButton>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
            {!pristine && isEditMode
              ? (
                <TooltipCantFinish>
                  <LoadingButton
                    variant="contained"
                    sx={{ mt: '30px', mr: 1 }}
                    disabled={disable || inProcessAutoConnect || !canProceedToNextStep}
                    loading={disable}
                    onClick={showConfirmModal}
                  >
                    {t('Завершить импорт')}
                  </LoadingButton>
                </TooltipCantFinish>
              ) : null }
            <LoadingButton
              variant="outlined"
              onClick={handleNextStep}
              sx={{ mt: '30px' }}
              disabled={disable || !canProceedToNextStep}
              loading={disable}
            >
              {t('Продолжить')}
            </LoadingButton>
          </Box>
        </Box>
      </StyledForm>
      { isPartnerRoles && !isEditMode ? (<HelpModalInitialLoadPriceListCategories />) : null}
      { isShowConfirmModal ? (
        <CompleteImportModal
          showModal
          setShowConfirmModal={setShowConfirmModal}
          onConfirm={handleSubmit(onSubmit)}
          disable={disable}
        />
      ) : null}
    </Box>
  );
}

export default reduxForm({
  form: formName,
})(ProductFeedCategoriesTable);
