// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_CONTENT_TEMPLATES_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_CONTENT_TEMPLATES_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_CONTENT_TEMPLATES_INITIAL_STATE_ASYNC, path);
  },
  postProductsImportAsync(params) {
    return getActionCreator(asyncTypes.POST_CONTENT_TEMPLATES_IMPORT_ASYNC, params);
  },
  getProductsImportTemplatesDownloadAsync(config) {
    return getActionCreator(asyncTypes.GET_CONTENT_TEMPLATES_IMPORT_DOWNLOAD_ASYNC, config);
  },
  putUpdateFromMagentoAsync(categoryId) {
    return getActionCreator(asyncTypes.PUT_CONTENT_TEMPLATES_UPDATE_FROM_MAGENTO_ASYNC, categoryId);
  },
  putUpdateValuesFromMagentoAsync(featureId) {
    return getActionCreator(asyncTypes.PUT_CONTENT_TEMPLATES_UPDATE_VALUES_FROM_MAGENTO_ASYNC, featureId);
  },
});
