// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  messagesList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  messagesUserTypes: Map({
    items: List(),
    pending: false,
  }),
  messageByHashId: Map({
    pending: false,
    message: Map(),
  }),
  messageRequestState: Map({
    delete: false,
    send: false,
    save: false,
  }),
  messageUserTypesByType: Map({
    items: Map(),
    pending: false,
  }),
  messagesStatuses: Map({
    items: List(),
    pending: false,
  }),
  recipientsImport: Map({
    items: Map(),
    pending: false,
    isOpenModal: false,
  }),
  partnersNotFoundModal: Map({
    isOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'messages',
  messagesList: 'messagesList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  partnersNotFoundModal: 'partnersNotFoundModal',
  isOpen: 'isOpen',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  delete: 'delete',
  send: 'send',
  save: 'save',
  sentAt: 'sentAt',
  isOpenModal: 'isOpenModal',
  recipientsImport: 'recipientsImport',
  messagesStatuses: 'messagesStatuses',
  messageUserTypesByType: 'messageUserTypesByType',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  messageByHashId: 'messageByHashId',
  reload: 'reload',
  message: 'message',
  messageRequestState: 'messageRequestState',
  messagesUserTypes: 'messagesUserTypes',
});
export const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MESSAGES_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.messagesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.messagesList, stateKeys.pending], pending)
        .setIn([stateKeys.messagesList, stateKeys.items], List(items))
        .setIn([stateKeys.messagesList, stateKeys.totalCount], totalCount);
    }
    case types.MERGE_MESSAGES_USER_TYPES_BY_TYPE: {
      return state.mergeIn([stateKeys.messageUserTypesByType], Map(payload));
    }
    case types.MERGE_RECIPIENTS_IMPORT: {
      return state.mergeIn([stateKeys.recipientsImport], Map(payload));
    }
    case types.MERGE_RECIPIENTS_IMPORT_ITEMS: {
      return state.mergeIn([stateKeys.recipientsImport, stateKeys.items], Map(payload));
    }
    case types.MERGE_PARTNERS_NOT_FOUND_MODAL: {
      return state.mergeIn([stateKeys.partnersNotFoundModal], Map(payload));
    }
    case types.MERGE_MESSAGES_USER_TYPES_BY_TYPE_ITEMS: {
      return state.mergeIn([stateKeys.messageUserTypesByType, stateKeys.items], Map(payload));
    }
    case types.SET_MESSAGES_SORTING: {
      return state.setIn([stateKeys.messagesList, stateKeys.sorting], List(payload));
    }
    case types.SET_MESSAGES_RELOAD: {
      return state.setIn([stateKeys.messagesList, stateKeys.reload], payload);
    }
    case types.MERGE_MESSAGES_STATUSES: {
      return state.mergeIn([stateKeys.messagesStatuses], Map(payload));
    }
    case types.SET_MESSAGES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.messagesList, stateKeys.filters], List(filters));
    }
    case types.SET_MESSAGES_PAGE_SIZE: {
      return state.setIn([stateKeys.messagesList, stateKeys.pageSize], payload);
    }
    case types.SET_MESSAGES_CURRENT_PAGE: {
      return state.setIn([stateKeys.messagesList, stateKeys.currentPage], payload);
    }
    case types.SET_MESSAGES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.messagesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.messagesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.messagesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.messagesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.messagesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.messagesList, stateKeys.reload], reload)
        .setIn([stateKeys.messagesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_MESSAGES_SETTING_STATUS: {
      return state.setIn([stateKeys.messagesList, stateKeys.settingStatus], payload);
    }
    case types.SET_MESSAGES_SETTING_PENDING: {
      return state.setIn([stateKeys.messagesList, stateKeys.settingPending], payload);
    }
    case types.MERGE_MESSAGES_USER_TYPES: {
      return state.mergeIn([stateKeys.messagesUserTypes], Map(payload));
    }
    case types.SET_MESSAGE_BY_HASH_ID: {
      return state.set(stateKeys.messageByHashId, Map(payload));
    }
    case types.MERGE_MESSAGE_REQUEST_STATE: {
      return state.mergeIn([stateKeys.messageRequestState], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
