import React from 'react';
import Avatar from '@mui/material/Avatar';
import cx from 'classnames';
import { Chip } from '@mui/material';
import prettyByte from 'pretty-bytes';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useStyles } from '../../../../hooks/useStyles';
import ChatStyles from './ChatMessageStyles';
import { getStringTruncateCenter, STRING_LENGTH_ALLOWED } from '../../../../../_helpers/getFormatTruncateString';

const ChatMessage = ({
  title, typeAllo, text, date, avatar, partner, files,
}) => {
  const classes = useStyles(ChatStyles);

  return (
    <div className={cx(classes.message, {
      [classes.typeAllo]: typeAllo,
    })}
    >
      <Avatar
        alt={`Avatar n°${partner}`}
        src={avatar}
      />
      <div className={classes.messageBody}>
        <div>
          <div className={classes.title}>{title}</div>
          {partner && <div className={classes.id}>{partner}</div>}
        </div>
        <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
        {(files && files.length > 0) && (
        <div className={classes.files}>
          {files.map(file => (
            <Chip
              key={file.hashId}
              size="small"
              variant="outlined"
              target="_blank"
              classes={{
                iconSmall: classes.fileIcon,
              }}
              icon={<AttachFileIcon />}
              title={file.name.length > STRING_LENGTH_ALLOWED ? file.name : null}
              label={(
                <>
                  <span>{getStringTruncateCenter(file.name)}</span>
                  <span className={classes.fileSize}>({prettyByte(file.size)})</span>
                </>
              )}
              component="a"
              href={file.link}
              clickable
            />
          ))}
        </div>
        )}
        <div className={classes.date}>{date}</div>
      </div>
    </div>
  );
};

export default ChatMessage;
