export const types = Object.freeze({
  SET_SERVICE_DESK_CURRENT_PAGE: 'SET_SERVICE_DESK_CURRENT_PAGE',
  SET_SERVICE_DESK_PAGE_SIZE: 'SET_SERVICE_DESK_PAGE_SIZE',
  SET_SERVICE_DESK_STATUSES_LIST: 'SET_SERVICE_DESK_STATUSES_LIST',
  SET_SERVICE_DESK_STATUSES_LIST_PENDING: 'SET_SERVICE_DESK_STATUSES_LIST_PENDING',
  SET_SERVICE_DESK_FILTERS: 'SET_SERVICE_DESK_FILTERS',
  SET_SERVICE_DESK_SORTING: 'SET_SERVICE_DESK_SORTING',
  SET_SERVICE_DESK_LIST: 'SET_SERVICE_DESK_LIST',
  MERGE_CHAT: 'MERGE_CHAT',
  SET_SERVICE_DESK_SETTING_STATUS: 'SET_SERVICE_DESK_SETTING_STATUS',
  SET_SERVICE_DESK_SETTING_PENDING: 'SET_SERVICE_DESK_SETTING_PENDING',
  SET_SERVICE_DESK_INITIAL_STATE: 'SET_SERVICE_DESK_INITIAL_STATE',
  SET_SERVICE_DESK_IS_ITEM_BY_HASH_ID_LOADING: 'SET_SERVICE_DESK_IS_ITEM_BY_HASH_ID_LOADING',
  SET_SERVICE_DESK_IS_ITEM_BY_HASH_PUT_PENDING: 'SET_SERVICE_DESK_IS_ITEM_BY_HASH_PUT_PENDING',
  SET_FILES_IN_CHAT_MESSAGE: 'SET_FILES_IN_CHAT_MESSAGE',
  SET_CHAT_FILE_IS_PENDING: 'SET_CHAT_FILE_IS_PENDING',
  SET_CHAT_MESSAGES: 'SET_CHAT_MESSAGES',
  SET_SERVICE_DESK_KEY: 'SET_SERVICE_DESK_KEY',
  SET_SERVICE_DESK_CLOSE_QUESTION_PENDING: 'SET_SERVICE_DESK_CLOSE_QUESTION_PENDING',
  SET_SERVICE_DESK_CONTINUE_QUESTION_PENDING: 'SET_SERVICE_DESK_CONTINUE_QUESTION_PENDING',
  SET_SERVICE_DESK_MESSAGES_FROM_SERVER: 'SET_SERVICE_DESK_MESSAGES_FROM_SERVER',
  SET_SERVICE_DESK_MESSAGES_FROM_SERVER_ARE_PENDING: 'SET_SERVICE_DESK_MESSAGES_FROM_SERVER_ARE_PENDING',
  SET_CURRENT_MESSAGES: 'SET_CURRENT_MESSAGES',
  SET_SERVICE_DESK_EXTERNAL_LINK: 'SET_SERVICE_DESK_EXTERNAL_LINK',
  SET_SERVICE_DESK_CHAT_NAME: 'SET_SERVICE_DESK_CHAT_NAME',
  SET_SERVICE_DESK_INFINITY_SCROLL_STATE: 'SET_SERVICE_DESK_INFINITY_SCROLL_STATE',
  SET_SERVICE_DESK_MESSAGE_IS_SENDING: 'SET_SERVICE_DESK_MESSAGE_IS_SENDING',
  SET_SERVICE_DESK_NEW_TICKET_PENDING: 'SET_SERVICE_DESK_NEW_TICKET_PENDING',
});
