import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as PropTypes from 'prop-types';

function LoaderComponent(props) {
  const { className } = props;

  return (
    <Box
      className={className}
      sx={{
        width: '100%', display: 'flex', flex: 1, justifyContent: 'center',
      }}
    >
      <CircularProgress color="info" size={20} />
    </Box>
  );
}

LoaderComponent.propTypes = {
  className: PropTypes.string,
};

export default LoaderComponent;
