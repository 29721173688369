// Core
import { components } from 'react-select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ArrowDownSelect from '../../../../../icons/ArrowDownSelect';

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const { isClassic } = selectProps.textFieldProps;
  return (
    <components.DropdownIndicator isClassic={isClassic} {...props}>
      {
        isClassic
          ? <ArrowDownSelect />
          : <KeyboardArrowDownRoundedIcon />
      }
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
