// Core
import { apply, call, put } from 'redux-saga/effects';
import { change } from 'redux-form';

// Engine
import { api, requestStatus } from '../../../../config/api';
import { mediaAction } from '../../../media/action';

// _helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callUploadPartnerLogoWorker({ payload }) {
  const {
    formName, name, cropData, file, hashId,
  } = payload;

  yield put(mediaAction.setMediaUploadModalPending(true));
  if (file && !file.length) {
    if (formName) {
      const action = yield call(change, formName, name);
      yield put(action);
    }
    return;
  }

  const response = yield apply(api, api.medias.postMedias, [{ files: file, context: 'partner_small_logo', cropData }]);

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors, link } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(mediaAction.setInMediaRequestFiles(formName, name, link, 'error', hashId));
        break;
      }
      case requestStatus.success: {
        const { message, id, link } = response.data;
        yield put(mediaAction.setInMediaRequestFiles(formName, name, link, 'success', id, hashId));
        yield put(mediaAction.setMediaCropperModalShow(false));
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(mediaAction.setMediaUploadModalPending(false));
}
