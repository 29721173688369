// Core
import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
// UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TypographyFieldViewStyles from './TypographyFieldViewStyles';
// Helpers
import { useStyles } from '../../../hooks/useStyles';

function TypographyFieldView(props) {
  const {
    helpText, label, text, textarea,
  } = props;
  const classes = useStyles(TypographyFieldViewStyles);

  const containerClassNames = cx(classes.fieldHolder, {
    [classes.field]: !textarea,
    [classes.textarea]: textarea,
  });

  const labelClassNames = cx(classes.label, {
    [classes.textareaLabel]: textarea,
    [classes.shrinkLabel]: text?.length > 0,
  });

  const textClassNames = cx({
    [classes.text]: !textarea,
    [classes.textareaText]: textarea,
  });

  return (
    <>
      <Grid className={containerClassNames}>
        <Typography variant="caption" display="block" gutterBottom className={labelClassNames}>
          {label}
        </Typography>
        <Typography variant="body2" display="block" gutterBottom className={textClassNames}>
          {text}
        </Typography>
      </Grid>
      {helpText && (
        <Typography variant="caption" display="block" gutterBottom>
          {helpText}
        </Typography>
      )}
    </>
  );
}

TypographyFieldView.propTypes = {
  helpText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textarea: PropTypes.bool,
};

TypographyFieldView.defaultProps = {
  helpText: null,
  label: '',
  text: '',
  textarea: false,
};

export default TypographyFieldView;
