// Core
import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
// Parts
import * as PropTypes from 'prop-types';
import PartnerSelect from '../../../components/_Form/Selects/PartnerSelect';
import Modal from '../../../containers/Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../engine/config/selectors';

// Component
function ModalSelectPartner(props) {
  const {
    pending, handleSendForm, textSend, title, handleModalToggle, isOpened,
  } = props;
  const { t } = useTranslation();
  const listContextLoading = useSelector(selectors.partner.listContextLoading);
  const listContext = useSelector(selectors.partner.listContext);
  const [partner, setPartner] = useState();
  const partnerInput = {
    value: partner,
    onChange: setPartner,
  };

  const handleModalAdd = () => {
    handleSendForm(partner.value);
  };

  useEffect(() => {
    setPartner();
  }, [isOpened]);

  return (
    <Modal
      modalOpen={isOpened}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      modalMaxWidth="sm"
      handleModalAdd={handleModalAdd}
      disabledSend={pending || listContextLoading || !partner?.value}
      loadingSend={pending}
      addText={textSend}
      iconSendButton={<RefreshIcon />}
      title={title}
    >
      <PartnerSelect
        input={partnerInput}
        label={t('Партнер')}
        disabled={!listContext.size}
        selectFirst
        fullWidth
        name="partner"
      />
    </Modal>
  );
}
ModalSelectPartner.propTypes = {
  pending: PropTypes.bool.isRequired,
  handleSendForm: PropTypes.func.isRequired,
  textSend: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default memo(ModalSelectPartner);
