import React, { useState, useRef } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { isImmutable } from 'immutable';
import { COLOR } from '../../../../../containers/App/AppStyles';


const MAX_TAGS = 15;

const SynonymsInput = (props) => {
  const {
    input, disabled,
  } = props;

  const previousTags = input?.value;

  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState(isImmutable(previousTags) ? previousTags.toJS() : []);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const updateTags = (newTags) => {
    setTags(newTags);
    input.onChange(newTags);
  };

  const addUniqueValue = (value) => {
    if (value && !tags.includes(value)) {
      updateTags([...tags, value]);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === ',') {
      event.preventDefault();
      const value = inputValue.trim();
      addUniqueValue(value);
    }
  };

  const handleDelete = (tagToDelete) => {
    updateTags(tags.filter(tag => tag !== tagToDelete));
    if (tags.length === 0) {
      setError('Укажите синоним');
    }
  };

  const deleteAllTags = () => {
    setTags([]);
    input.onChange('');
    setError('Укажите синоним');
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    setFocused(false);
    if (inputRef?.current?.value) {
      addUniqueValue(inputRef?.current?.value.trim());
    }
    if (tags.length === 0 && !inputRef?.current?.value) {
      setError('Укажите синоним');
    }
  };

  return (
    <>
      <FormControl
        component="fieldset"
        sx={{
          border: 1,
          borderColor: error ? COLOR.error : COLOR.grey[38],
          borderRadius: 1,
          height: 'auto',
          minWidth: '33%',
          marginBottom: '2px',
          display: 'flex',
          flexDirection: 'row',
          padding: tags.length > 0 ? '14px' : 0,
          '&:hover': {
            borderColor: !error && COLOR.grey[72],
          },
        }}
        onClick={() => inputRef?.current.focus()}
        onFocus={() => setError('')}
        onBlur={handleInputBlur}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '8px',
            flex: 1,
            maxWidth: '85%',
          }}
        >
          <InputLabel
            shrink={focused || tags.length > 0}
            sx={{
              backgroundColor: 'white',
              padding: '0px 4px',
              margin: 0,
              top: focused || tags.length > 0 ? 0 : '-25%',
              color: COLOR.grey[54],
            }}
          >
            {t('Синонимы')}
          </InputLabel>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              disabled={disabled}
              label={tag}
              onDelete={() => handleDelete(tag)}
              size="small"
              deleteIcon={<ClearIcon />}
              sx={{
                width: 'auto',
                display: 'flex',
                margin: 0,
                height: '28px',
                padding: '4px 8px 4px 12px',
                backgroundColor: COLOR.grey[4],
                '&:hover': {
                  cursor: 'text',
                },
                '&:focus': {
                  background: COLOR.grey[4],
                  borderColor: COLOR.grey[24],
                },
              }}
            />
          ))}
          {tags.length < MAX_TAGS && (
          <TextField
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            multiline
            disabled={disabled}
            tabIndex="0"
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setFocused(true)}
            inputRef={inputRef}
            sx={{
              pl: '8px',
              flex: 1,
              flexWrap: 'wrap',
              minWidth: '30%',
              fontSize: '14px',
              '& .MuiInputBase-root': {
                margin: '0px',
              },
            }}
          />
          )}
        </Box>
        {tags.length > 0 && (
        <Button
          onClick={deleteAllTags}
          disabled={disabled}
          sx={{
            color: COLOR.grey[54],
            height: '100%',
            padding: '0px 0px 0px 8px',
            width: '24px',
            alignSelf: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:disabled': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <ClearIcon />
        </Button>
        )}
      </FormControl>
      {!error && <Typography sx={{ fontSize: '12px', lineHeight: '12px' }} color={COLOR.grey[54]}>{t('Укажите слова через запятую')}</Typography>}
      {error && <Box sx={{ color: COLOR.error, fontSize: '12px', lineHeight: '12px' }}>{t(error)}</Box>}
    </>
  );
};

export default SynonymsInput;
