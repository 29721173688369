// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
// UI
import FormHelperText from '@mui/material/FormHelperText';
// Hooks
import { useStyles } from '../../../hooks/useStyles';
// Styles
import FormHelperTextComponentStyles from './FormHelperTextComponentStyles';

function FormHelperTextComponent(props) {
  const { touched, error } = props;
  const classes = useStyles(FormHelperTextComponentStyles);
  return (
    <FormHelperText className={cx(classes.helperText, { [classes.error]: error })}>
      {touched && error && error}
    </FormHelperText>
  );
}

FormHelperTextComponent.propTypes = {
  error: PropTypes.element,
  touched: PropTypes.bool,
};

FormHelperTextComponent.defaultProps = {
  error: undefined,
  touched: false,
};

export default FormHelperTextComponent;
