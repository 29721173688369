// Core
import React, { isValidElement, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { push } from 'redux-first-history';
// UI
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
// Parts
import Box from '@mui/material/Box';
import MediaCell from './components/MediaCell/MediaCell';
import CategoryCell from './components/CategoryCell';
import EnabledInIPriceCell from './components/EnabledInIPriceCell';
import ChipFormatter from './components/ChipFormatter';
import DatetimeFormatter from './components/DatetimeFormatter';
import ContentManagerProvider from '../../../page/Products/AttributeSet/components/ContentManagerProvider';
import FeedPriorityProvider from '../../../page/Products/AttributeSet/components/FeedPriorityProvider';
import EditableContainer from './components/EditableContainer/EditableContainer';
import ComponentWithTooltip from '../../../containers/ComponentWithTooltip';
import UsersProvider from '../../../page/Partners/components/PartnersManager/components/UsersProvider/UsersProvider';
// Engine
import { dateNumberFormat, dateStringFormat, dateStringShortFormat } from '../../../../engine/config/globalConfig';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
import { actions as contentProductActions } from '../../../../engine/core/contentProduct/actions';
// Helpers
import { getDate } from '../../../../_helpers/getDate';
import { getPhoneNumberWithMask } from '../../../../_helpers/getPhoneNumberWithMask';
import { tableDataTypes } from '../../../../_helpers/data/tableDataTypes';
import { useAccessList } from '../../../hooks/useAccessList';
import { defaultFilterTypeValue } from '../../../page/Products/AttributeSet/components/ProductWithoutManagerQuantitySelect';
import { useStyles } from '../../../hooks/useStyles';
import { TableCellStyle } from './Styles';
import PriceUrlWithConfirmButtons from './components/PriceUrlWithConfirmButtons';
import Select from '../../_Form/Selects/Select/Select';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
import HistoryButton from '../../../page/Products/LogisticsData/components/HistoryButton/HistoryButton';
import { tableColumns as LogisticsDataTableColumns } from '../../../page/Products/LogisticsData/components/LogisticsDataTable/tableColumns';


function TableCell(props) {
  const {
    column, value, showShortText, row, children, isNotEllipsis, customSx, newDesignStyle,
  } = props;
  const classes = useStyles(TableCellStyle);
  const productStatuses = useSelector(selectors.allProductsTable.productStatuses);
  const orderStatuses = useSelector(selectors.orders.orderStatuses);
  const attributeSetFilters = useSelector(selectors.attributeSet.filters);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const valueTypeIsNumberOrString = (typeof value === 'string' || typeof value === 'number');
  const valuesPlain = get(value, 'valuesPlain', null);
  const shippingType = get(value, 'shippingType', undefined);
  const shippingCreatedAt = get(value, 'createdAt', undefined);
  const shippingUpdatedAt = get(value, 'updatedAt', undefined);
  const hasPartnerManagerEditAccess = useAccessList(accessList.partnerManagerEdit);
  const hasAttributeSetManagementEdit = useAccessList(accessList.attributeSetManagementEdit);
  const hasCategoriesFeatureEdit = useAccessList(accessList.categoriesFeatureManagementEdit);
  const hasCategoriesFeatureShow = useAccessList(accessList.categoriesFeatureManagementShow);

  const stopListValuePlainByType = [
    tableDataTypes.dateTimeStringFromObject.name,
    tableDataTypes.datetimeNumber.name,
    tableDataTypes.dateString.name,
    tableDataTypes.dateSingle.name,
    tableDataTypes.number.name,
    tableDataTypes.date.name,
  ];
  const stopListValuePlainByName = [
    'priority', 'status', 'rating', 'datetime', 'shippedAt', tableDataTypes.confirmedDate.name,
    'doneAt', 'orderedAt', 'canceledAt', 'answeredAt', 'createdAt', 'updatedAt',
    'statusChangedAt', 'sentAt', 'activateAt', 'contractAt', 'waitingVerificationAt',
    'filingAt', 'notPassedAt', 'registrationAt', 'extraditionAt', 'completionAt',
    'addedToTopAt', 'limitDateEnd', 'ratingRatio', 'paymentWaitingAt', tableDataTypes.assignedTo.name,
    tableDataTypes.orderStatus.name, tableDataTypes.feedLink.name, tableDataTypes.enabledInMagentoLog.name,
    tableDataTypes.priceLog.name, tableDataTypes.priceAutoUpdateLog.name, tableDataTypes.salePriceLog.name,
    tableDataTypes.feedPriceLog.name, tableDataTypes.quantityLog.name, tableDataTypes.feedQuantityLog.name,
    tableDataTypes.featureAutoUpdateLog.name, tableDataTypes.feedSalePriceLog.name, 'warehouses',
  ];
  const checkComponents = () => {
    switch (column?.type) {
      case tableDataTypes.enabledInIPrice.type: {
        return <EnabledInIPriceCell column={column} value={value} row={row} />;
      }
      case tableDataTypes.phone.name: {
        return (
          <span>{getPhoneNumberWithMask(value)}</span>
        );
      }
      case tableDataTypes.datetimeNumber.type: {
        return <DatetimeFormatter value={value} format={dateNumberFormat} />;
      }
      case tableDataTypes.datetime.type:
      case tableDataTypes.paymentWaitingAt.type:
      case tableDataTypes.dateString.type: {
        return <DatetimeFormatter value={value} />;
      }
      case tableDataTypes.dateTimeStringFromObject.type: {
        return <DatetimeFormatter value={value?.value || value} format={dateStringFormat} />;
      }
      case tableDataTypes.dateSingle.type:
      case tableDataTypes.date.type: {
        return <DatetimeFormatter value={value} format={dateStringShortFormat} />;
      }
      case tableDataTypes.media.type: {
        return <MediaCell value={value} />;
      }
      // case tableDataTypes.attributeSets.type: {
      //   return <ChipFormatter value={value} frontTranslate={column.frontTranslate} />;
      // }
      case tableDataTypes.categoryFeatures.type: {
        const moreLinkButton = hasCategoriesFeatureShow && !hasCategoriesFeatureEdit
          ? pageLinks.productsAndPrices.categoriesFeatureManagement.show(row.hashId)
          : pageLinks.productsAndPrices.categoriesFeatureManagement.edit(row.hashId);

        return (
          <ChipFormatter
            itemsToShow={3}
            moreLinkButton={moreLinkButton}
            showEditButton={row?.showEditButtonInFeatures}
            value={value}
            frontTranslate={column.frontTranslate}
          />
        );
      }
      case tableDataTypes.available.type:
      case tableDataTypes.partnerEditEnabled.type:
      case tableDataTypes.boolean.type: {
        let currentValue = value ? 'Да' : 'Нет';
        if (column.name === 'notCallMe') {
          if (value) {
            return (
              <Chip
                label={<Trans>Да</Trans>}
                className={classes.margin}
                variant="outlined"
              />
            );
          }
          return <span className={classes.margin}>—</span>;
        }
        if (column.name === 'isTop') {
          if (value) {
            return (
              <Chip
                label={<Trans>Топ</Trans>}
                className={classes.margin}
                variant="outlined"
              />
            );
          }
          return <Chip label={<Trans>Не Топ</Trans>} className={classes.margin} variant="outlined" />;
        }
        if (column.name === 'partnerIpriceIsActive') {
          if (value === true) {
            currentValue = 'Да';
          } else if (value === false) {
            currentValue = 'Нет';
          } else {
            return null;
          }
        }
        if (column.name === tableDataTypes.creditLimitStatus.name) {
          if (value === true) {
            currentValue = 'Вкл';
          } else if (value === false) {
            currentValue = 'Выкл';
          } else {
            return null;
          }
        }
        if (column.name === 'available') {
          currentValue = value ? 'Есть' : 'Нет';
        }

        return isBoolean(value) ? (
          <Chip
            label={<Trans>{currentValue}</Trans>}
            className={classes.margin}
            variant="outlined"
          />
        ) : '';
      }
      case tableDataTypes.enabled.name: {
        if (column.name === 'enabled') {
          if (value === true) {
            return (
              <Chip
                label={<Trans>Вкл</Trans>}
                className={classes.margin}
                variant="outlined"
              />
            );
          }
          return <Chip label={<Trans>Откл</Trans>} className={classes.margin} variant="outlined" />;
        }
        break;
      }

      case 'feedPriority': {
        if (column.editable) {
          const defaultFeature = { error: false, pending: false, success: false };
          const feature = row[column.name] || defaultFeature;
          const text = get(value, 'label');
          const color = get(value, 'color');
          if (hasAttributeSetManagementEdit) {
            return (
              <EditableContainer
                fullText={!column.editable}
                isEditable
                text={text}
              >
                <FeedPriorityProvider
                  color={color}
                  key={`feedPriority_${row.hashId}`}
                  feature={feature}
                  required={column.required}
                  value={value}
                  hashId={row.hashId}
                  valuesPlain={text}
                  type="feedPriority"
                />
              </EditableContainer>
            );
          }
          return (
            color
              ? <span style={{ color }}>{get(value, 'label', null)}</span>
              : get(value, 'label', null)
          );
        }
        break;
      }
      case 'balance': {
        const color = value <= 0 ? red[500] : green[500];
        return <span style={{ color }}>{value}</span>;
      }
      case 'rating': {
        if (!isNaN(Number(value))) {
          const color = value <= 59 ? red[500] : value <= 79 ? orange[500] : green[500];// eslint-disable-line
          return <span style={{ color }}>{toString(value)}%</span>;
        }
        return toString(value);
      }
      case 'billingStatus': {
        const color = value === 2 || value === 0 ? red[500] : green[500];
        return (
          <span style={{ color }}>
            {value === 0 && <Trans>Заблокирован</Trans>}
            {value === 1 && <Trans>Активный</Trans>}
            {value === 2 && <Trans>Кредитный лимит</Trans>}
          </span>
        );
      }
      // case 'comment': {
      //   const hash = row.hashId;
      //   console.log(value);
      //   return (
      //     <ProductsFeedLogs
      //       hashId={hash}
      //       value={value}
      //     />
      //   );
      // }
      case tableDataTypes.adminManager.type:
      case tableDataTypes.assignedTo.type: {
        const defaultFeature = { error: false, pending: false, success: false };
        const feature = row[column.type] || defaultFeature;
        const text = get(value, 'name');
        let context;
        let role;

        switch (column.type) {
          case tableDataTypes.adminManager.type: {
            context = 'select';
            role = '1,2';
            break;
          }
          default: {
            break;
          }
        }
        if (hasPartnerManagerEditAccess) {
          return (
            <EditableContainer fullText={!column.editable} isEditable text={text}>
              <UsersProvider
                key={`${column.type}_${row.hashId}`}
                feature={feature}
                required={column.required}
                value={value}
                context={context}
                role={role}
                hashId={row.hashId}
                valuesPlain={text}
                type={column.type}
              />
            </EditableContainer>
          );
        }
        return get(value, 'name', null);
      }
      case 'contentManager': {
        const isProductColumn = typeof column.bulkEditable === 'boolean';
        if (column.editable && column.bulkEditable !== false) { // TODO AMP-7228
          const priority = row.priority;
          const defaultFeature = {
            priority, error: false, pending: false, success: false,
          };
          const feature = row[column.name] || defaultFeature;
          const text = get(value, 'name');
          if (hasAttributeSetManagementEdit) {
            return (
              <EditableContainer fullText={!column.editable} isEditable text={text}>
                <ContentManagerProvider
                  key={`contentManager_${row.hashId}`}
                  feature={feature}
                  required={column.required}
                  value={value}
                  hashId={row.hashId}
                  valuesPlain={isProductColumn ? t('Не назначен') : null}
                  type="contentManager"
                />
              </EditableContainer>
            );
          }
          return get(value, 'label', isProductColumn ? t('Не назначен') : null);
        }
        return get(value, 'label', isProductColumn ? t('Не назначен') : null);
      }
      default: {
        switch (column?.name) { // TODO change all to type from name
          case 'transactionsSum':
          case 'transaction': {
            const color = value <= 0 ? red[500] : green[500];
            return <span style={{ color }}>{value}</span>;
          }
          case tableDataTypes.shippingTrackingNumber.name:
            if (shippingType || shippingCreatedAt || shippingUpdatedAt) {
              return (
                <div className={classes.ttnWrapper}>
                  <ComponentWithTooltip
                    description={(
                      <p className={classes.ttnInfoWrapper}>
                        {shippingCreatedAt && (
                          <span>
                            {`${t('Дата добавления ТТН')}: ${getDate(shippingCreatedAt, dateStringFormat, i18n.language)}`}
                          </span>
                        )}
                        {shippingUpdatedAt && (
                          <span>
                            {`${t('Дата изменения ТТН')}: ${getDate(shippingUpdatedAt, dateStringFormat, i18n.language)}`}
                          </span>
                        )}
                        {shippingType && (
                          <span>
                            {`${t('Название доставки')}: ${shippingType}`}
                          </span>
                        )}
                      </p>
                    )}
                  >
                    {get(value, 'shippingTrackingNumber', value)}
                  </ComponentWithTooltip>
                </div>
              );
            }
            return get(value, 'shippingTrackingNumber', value);
          case 'assignedTo': {
            const defaultFeature = { error: false, pending: false, success: false };
            const feature = row[column.name] || defaultFeature;
            const text = get(value, 'name');
            if (hasPartnerManagerEditAccess) {
              return (
                <EditableContainer fullText={!column.editable} isEditable text={text}>
                  <UsersProvider
                    key={`assignedTo_${row.hashId}`}
                    feature={feature}
                    required={column.required}
                    value={value}
                    hashId={row.hashId}
                    valuesPlain={text}
                    type="assignedTo"
                  />
                </EditableContainer>
              );
            }
            return get(value, 'name', null);
          }
          case 'author':
            return get(value, 'name', null);
          case 'recipients':
          case tableDataTypes.partnerPaymentTypes.name:
          case 'messageUsers': {
            return value && value.map((item, index) => (
              <Chip
                className={classes.margin}
                variant="outlined"
                key={index}
                label={item.label}
              />
            ));
          }
          case tableDataTypes.orderStatus.name: {
            const statusColor = !isEmpty(orderStatuses)
              ? orderStatuses.filter(item => item.label === value).map(item => item.color).join()
              : '#303030';
            return (
              <span style={{ color: statusColor }}>
                {value}
              </span>
            );
          }
          case 'groups':
          case 'partnerRoles':
          case 'categories':
          case 'historyCategories':
          case 'mailTemplateRoles':
          case 'excludePartners':
          case 'brand': {
            return <ChipFormatter value={value} frontTranslate={column.frontTranslate} />;
          }
          case 'paymentType':
          case 'category': {
            return (
              <CategoryCell
                row={row}
                column={column}
                value={value}
              />
            );
          }
          case 'partnerStatus':
          case 'status':
          case 'recipientVerificationStatus':
          case 'mailTemplateGroup':
          case 'feedStatus':
          case tableDataTypes.userConfirmed.name:
          case 'feedProductStatus':
          case 'feedCategoryStatus':
          case 'configurationsType':
          case tableDataTypes.messageStatus.name:
          case tableDataTypes.partnerCancelStatus.name:
          case 'feedProductElaborationStatus': {
            const currentValue = get(value, 'label', null);
            const currentColor = get(value, 'color', null);
            const style = { color: currentColor };
            return (
              currentColor
                ? <span style={style}>{currentValue}</span>
                : get(value, 'label', null)
            );
          }
          case 'datetime':
          case 'shippedAt':
          case 'completedAtWarehouseAt':
          case 'doneAt':
          case 'orderedAt':
          case 'canceledAt':
          case 'answeredAt':
          case 'createdAt':
          case tableDataTypes.confirmedDate.name:
          case 'updatedAt':
          case 'partnerIpriceOffTime':
          case 'statusChangedAt':
          case 'sentAt':
          case 'activateAt':
          case 'contractAt':
          case 'waitingVerificationAt':
          case 'filingAt':
          case 'notPassedAt':
          case 'registrationAt':
          case 'extraditionAt':
          case 'completionAt':
          case 'addedToTopAt':
          case 'issueAt':
          case 'limitDateEnd': {
            return <DatetimeFormatter value={value} />;
          }
          case 'manager':
            return get(value, 'label', value);
          case 'type': {
            return (
              <Chip
                className={classes.margin}
                variant="outlined"
                label={get(value, 'label', value)}
              />
            );
          }
          case 'editable': {
            return (
              <Chip
                label={<Trans>{value ? 'Да' : 'Нет'}</Trans>}
                className={classes.margin}
                variant="outlined"
              />
            );
          }
          case 'referrer': {
            return <Link target="_blank" href={value} className={classes.text}> {toString(value)} </Link>;
          }
          case 'externalRating': {
            return (
              <span className={classes.starWrapper}>
                {toString(value)}
                { typeof value === 'number' && <StarIcon className={classes.star} color="primary" /> }
              </span>
            );
          }
          case 'orderCancelPercent': {
            return typeof value === 'number' ? `${toString(value)}%` : toString(value);
          }
          case 'ratingRatio': {
            let color = red[500];

            if (value <= 1) {
              color = green[500];
            } else if (value <= 1.1) {
              color = orange[500];
            }
            return <span style={{ color }}> {toString(value)} </span>;
          }
          case 'accessBlockContent':
          case 'accessFeed':
          case 'accessRatingRatio':
          case 'hasContactCenter':
          case 'hasServiceCenter':
          case 'accessBilling': {
            return children;
          }
          case 'productWithoutManagerQuantity':
          case 'availableProductsQuantity': {
            const redirectTo = () => {
              const filterType = attributeSetFilters.filter(item => item.columnName === 'filterType').toJS();
              const currentStatusObject = productStatuses.toJS().filter(item => item.value === filterType[0].value);
              const filterParams = [
                {
                  columnName: 'attributeSet',
                  value: [row.attributeSet],
                },
                {
                  ...(filterType[0].value !== defaultFilterTypeValue
                    ? { columnName: 'productStatus', value: currentStatusObject }
                    : {}),
                },
                {
                  ...(column.name === 'availableProductsQuantity'
                    ? { columnName: 'quantity', value: 1 }
                    : {}),
                },
              ];
              dispatch(contentProductActions.setFilters(filterParams));
              setTimeout(() => {
                dispatch(push(pageLinks.content.products.simpleView));
              }, 1000);
            };
            return (
              <Link
                component="button"
                variant="body2"
                onClick={redirectTo}
              >
                {value}
              </Link>
            );
          }
          case tableDataTypes.feedLink.name: {
            return (
              <PriceUrlWithConfirmButtons
                column={column}
                value={value}
                hashId={row.hashId}
                canEditLink={row.canEditLink}
                id={row.id}
              />
            );
          }
          case 'contacts': {
            const contactsString = value.reduce((acc, current) => {
              if (current.name || current.phone) {
                // eslint-disable-next-line no-param-reassign
                acc += `${current.name}${current.phone ? '\n +' : ''}${current.phone}; \n`;
              }
              return acc;
            }, '');
            return (
              <Box sx={{ whiteSpace: 'pre-line' }}>{contactsString}</Box>
            );
          }
          case 'synonyms': {
            const checkLabel = toString(get(value, 'label', value));
            const checkValuesPlain = toString(get(value, 'valuesPlain', checkLabel));
            return <Box sx={{ height: 'auto', flexWrap: 'wrap', overflowWrap: 'break-word' }}>{checkValuesPlain}</Box>;
          }
          case 'dictionaryType': {
            return <div>{t(row?.type)}</div>;
          }
          case 'business': {
            const options = useSelector(selectors.partner.businessesList);
            const hashId = row.hashId;
            const handleChange = (selected) => {
              dispatch(partnerAsyncAction.postPartnerBusinessAsync({ value: selected.value, hashId }));
            };
            return (
              <Select options={options} value={value} hasSearch handleChange={handleChange} searchErrorLabel={t('Бизнес не найден')} />
            );
          }
          case LogisticsDataTableColumns.categoryLogisticsLog.name: {
            return (
              <>
                <HistoryButton
                  hashId={row.hashId}
                  hasHistory={row?.hasHistory}
                />
              </>
            );
          }
          case 'warehouses': {
            const parsedValue = value ? JSON.parse(value) : undefined;
            return <Box sx={{ whiteSpace: 'pre-line' }}>{parsedValue ? parsedValue.map(item => <div>{`${item}; \n`}</div>) : null}</Box>;
          }
          default: {
            const checkBool = typeof value === 'boolean'
              ? (
                <Chip
                  label={<Trans>{value ? 'Да' : 'Нет'}</Trans>}
                  className={classes.margin}
                  variant="outlined"
                />
              ) : value;
            const checkLabel = toString(get(value, 'label', value));
            const checkValuesPlain = toString(get(value, 'valuesPlain', checkLabel));

            return <>{typeof value === 'object' ? checkValuesPlain : checkBool}</>;
          }
        }
      }
    }
  };

  if (
    (
      valuesPlain
      && !stopListValuePlainByType.includes(column.type))
    || (
      valueTypeIsNumberOrString
      && showShortText
      && !stopListValuePlainByType.includes(column.type)
      && !stopListValuePlainByName.includes(column.name)
    )
  ) {
    let normalizeValue;
    switch (column.type) {
      case 'datetime': normalizeValue = getDate(value); break;
      case 'phone': normalizeValue = getPhoneNumberWithMask(value); break;
      default: normalizeValue = value;
    }
    return (
      <VirtualTable.Cell
        column={column}
        value={value}
        row={row}
        className={classes.relative}
        sx={customSx}
      >
        <EditableContainer
          isNotEllipsis={isNotEllipsis === column.name}
          color={get(value, 'color')}
          isEditable={false}
          type={column.type}
          name={column.name}
          hashId={row.hashId}
          chipText={(column.name === 'name' && row.isCurrentUser) ? t('Это вы') : null}
          secondChipText={(column.name === 'name' && row.super) ? t('Руководитель') : null}
          text={valuesPlain || `${normalizeValue}`}
          secondText={column.name === 'cancelStatus' && !isEmpty(row.note) && row.note.valuesPlain ? row.note.valuesPlain : ''}
          newDesignStyle={newDesignStyle}
        >
          {checkComponents()}
        </EditableContainer>
      </VirtualTable.Cell>
    );
  }
  return (
    <VirtualTable.Cell
      column={column}
      value={value}
      row={row}
      tableColumn={column}
      tableRow={row}
    >
      {isValidElement(children) ? children : checkComponents()}
    </VirtualTable.Cell>
  );
}
TableCell.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.any,
  showShortText: PropTypes.bool,
  row: PropTypes.object.isRequired,
  children: PropTypes.node,
  isNotEllipsis: PropTypes.string,
  customSx: PropTypes.object,
  newDesignStyle: PropTypes.bool,
};

export default memo(TableCell);
