// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { has } from 'lodash';
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Data

export function* callGetCategoriesStatisticWorker({ payload }) {
  const isProductStatusQuery = has(payload, 'productStatus');

  if (isProductStatusQuery) {
    yield put(actions.setIsCategoryStatisticLoading(true));
  }
  yield put(actions.setIsCategoryStatisticLoading(true));

  const response = yield apply(api, api.products.getCategoryStatistic, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;

    if (isProductStatusQuery) {
      yield put(actions.setQuantityByCategories(data.quantityByCategories));
    } else {
      yield put(actions.setQuantityByStatus(data.quantityByStatus));
    }
  }
  if (isProductStatusQuery) {
    yield put(actions.setIsCategoryStatisticLoading(false));
  }
  yield put(actions.setIsCategoryStatisticLoading(false));
}
