// Core
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { List } from 'immutable';
// Parts
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from '@mui/material/Grid';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import FormSelect from '../../../../../components/_Form/Selects/FormSelect/FormSelect';
import PartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../engine/core/billing/reports/saga/asyncActions';
import { actions } from '../../../../../../engine/core/billing/reports/actions';
import Loading from '../../../../../components/Loading/Loading';
// Redux
function mapStateToProps(state) {
  return {
    reconciliationDatesItems: selectors.reports.reconciliationDatesItems(state),
    reconciliationDatesPending: selectors.reports.reconciliationDatesPending(state),
    reconciliationModalIsOpen: selectors.reports.reconciliationModalIsOpen(state),
    reconciliationDownloadPending: selectors.reports.reconciliationDownloadPending(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBillingReconciliationDatesAsync: () => dispatch(asyncActions.getReconciliationDatesAsync()),
    getBillingReconciliationActDownloadAsync: params => dispatch(asyncActions.getBillingReconciliationActDownloadAsync(params)),
    mergeReconciliation: payload => dispatch(actions.mergeReconciliation(payload)),
  };
}

function ReconciliationActModal(props) {
  const {
    reconciliationDatesItems,
    reconciliationDatesPending,
    reconciliationDownloadPending,
    reconciliationModalIsOpen,
    mergeReconciliation,
    getBillingReconciliationDatesAsync,
    getBillingReconciliationActDownloadAsync,
  } = props;
  const { t } = useTranslation();
  const [partner, setPartner] = useState(null);
  const [date, setDate] = useState(undefined);
  const partnerInput = {
    value: partner,
    onChange: setPartner,
  };
  const periodInput = {
    value: date,
    onChange: setDate,
  };
  const generateActModal = () => {
    mergeReconciliation({ modalIsOpen: !reconciliationModalIsOpen });
  };

  const generateReport = () => {
    getBillingReconciliationActDownloadAsync({
      reconciliationActDate: date,
      partner: partner?.value,
    });
  };

  useEffect(() => {
    if (reconciliationModalIsOpen) {
      getBillingReconciliationDatesAsync();
    }
  }, [reconciliationModalIsOpen]);


  if (!reconciliationModalIsOpen) {
    return null;
  }

  return (
    <Modal
      title={t('Сгенерировать акт сверки')}
      modalOpen={reconciliationModalIsOpen}
      handleModalToggle={generateActModal}
      modalMaxWidth="sm"
      disabledSend={!partner || reconciliationDatesPending || reconciliationDownloadPending || !date}
      iconSendButton={<DescriptionIcon />}
      addText={t('Сгенерировать')}
      loadingSend={reconciliationDownloadPending}
      handleModalAdd={generateReport}
    >
      <Grid container justifyContent="flex-end">
        {reconciliationDatesPending
          ? <Loading isLoading />
          : (
            <>
              <Grid item xs={12}>
                <PartnerSelect
                  input={partnerInput}
                  margin="normal"
                  fullWidth
                  selectFirst
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  label={t('Период')}
                  input={periodInput}
                  options={reconciliationDatesItems.toJS()}
                  fullWidth
                />
              </Grid>
            </>
          )
        }
      </Grid>
    </Modal>
  );
}
ReconciliationActModal.propTypes = {
  getBillingReconciliationActDownloadAsync: PropTypes.func,
  mergeReconciliation: PropTypes.func,
  getBillingReconciliationDatesAsync: PropTypes.func,
  reconciliationDatesItems: PropTypes.instanceOf(List),
  reconciliationDatesPending: PropTypes.bool,
  reconciliationDownloadPending: PropTypes.bool,
  reconciliationModalIsOpen: PropTypes.bool,
};

ReconciliationActModal.defaultProps = {
  getBillingReconciliationActDownloadAsync: () => {},
  mergeReconciliation: () => {},
  reconciliationDatesItems: List(),
  reconciliationModalIsOpen: false,
  reconciliationDownloadPending: false,
  reconciliationDatesPending: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconciliationActModal);
