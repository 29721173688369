// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Link from '@mui/material/Link';
import ButtonWithIcon from './ButtonWithIcon/ButtonWithIcon';
// Engine
import { accessList } from '../../../engine/config/access';
// Hooks
import { useAccessList } from '../../hooks/useAccessList';
import { billingActions as billingAction } from '../../../engine/core/billing/action';

function ButtonPaymentAdd(props) {
  const { type } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessToBillingPaymentAdd = useAccessList(accessList.billingPaymentAdd);
  const hasAccessToBillingPaymentForPartner = useAccessList(accessList.billingPaymentForPartner);
  const onClick = () => {
    dispatch(billingAction.mergeBillingInvoiceModal({ modalIsOpen: true }));
  };

  if (!hasAccessToBillingPaymentAdd && !hasAccessToBillingPaymentForPartner) {
    return null;
  }

  return type === 'link' ? <Link sx={{ textTransform: 'lowercase' }} onClick={onClick}>{t('Пополнить баланс')}</Link> : (
    <ButtonWithIcon
      text={hasAccessToBillingPaymentAdd ? t('Пополнить баланс') : t('Создать счет')}
      onClick={onClick}
    >
      <AddCircleIcon />
    </ButtonWithIcon>
  );
}

export default ButtonPaymentAdd;
