// Core
import { components } from 'react-select';

const ValueContainerSingle = (props) => {
  const { children } = props;

  return (
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainerSingle;
