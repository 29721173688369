// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { actions } from '../../actions';
import { api } from '../../../../config/api';

export function* callGetMessagesUserTypesByTypeWorker({ payload: type }) {
  yield put(actions.mergeMessageUserTypesByType({ pending: true }));

  const response = yield apply(api, api.messages.getMessagesUserTypesByType, [type]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.mergeMessageUserTypesByTypeItems({
      [type]: response.data,
    }));
  }

  yield put(actions.mergeMessageUserTypesByType({ pending: false }));
}
