// Core
import memoizeOne from 'memoize-one';

const getItemData = memoizeOne((onOpen, flattenedData, setSize) => ({
  onOpen, flattenedData, setSize,
}));

export function useItemData(flattenedData, setSize, openedNodeIds, setOpenedNodeIds) {
  const onOpen = node => (node.collapsed
    ? setOpenedNodeIds([...openedNodeIds, node.id])
    : setOpenedNodeIds(openedNodeIds.filter(id => id !== node.id))
  );

  return getItemData(onOpen, flattenedData, setSize);
}
