// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { Map } from 'immutable';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../../config/api';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { asyncActions } from '../asyncActions';
import { pageLinks } from '../../../../../config/routes';

export function* callDeletePartnerAccessCredentialsWorker({ payload }) {
  const { hashId, wfpCardType } = payload;
  yield put(actions.deletePartnerAccessCredentials({ pending: true }));

  const response = yield apply(api, api.partners.deletePaymentAccessCredentials, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(actions.setPartnerAccessModal({
          isOpen: false,
        }));
        yield put(actions.deletePartnerAccessCredentials({ pending: false }));
        yield put(actions.setPartnerAccessCredentials({ data: Map() }));
        if (wfpCardType) {
          yield put(asyncActions.getParentAccessByHashIdAsync(hashId));
        } else {
          yield put(push(pageLinks.partner.accesses));
        }
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(actions.deletePartnerAccessCredentials({ pending: false }));
        yield put(actions.setPartnerAccessModal({
          isOpen: false,
        }));
        break;
      }
      default: {
        break;
      }
    }
  }
}
