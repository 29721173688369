// Core
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import { isNil } from 'lodash';
// UI
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Parts
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Redux
import { asyncActions } from '../../../../engine/core/partners/saga/asyncActions';
import { actions } from '../../../../engine/core/partners/actions';
import { accessList } from '../../../../engine/config/access';
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
import { useStyles } from '../../../hooks/useStyles';
import { COLOR } from '../../../containers/App/AppStyles';

const ProductsButtonGroupsStyles = () => ({
  menuList: {
    padding: '8px',
  },
  menuListItem: {
    borderRadius: '5px',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      background: COLOR.grey['4'],
    },

    '&.Mui-selected': {
      background: COLOR.grey['4'],
      '&:hover': {
        background: COLOR.grey['4'],
      },
    },
  },
});


function ProductsButtonGroups() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessExportTop = useAccessList(accessList.partnerTopExport);
  const hasAccessImportTop = useAccessList(accessList.partnerTopImport);
  const hasAccessToPartnersXls = useAccessList(accessList.partnerXlsDownload);
  const selection = useSelector(selectors.partners.selection);
  const filters = useSelector(selectors.partners.filters);
  const isLoadingTop = useSelector(selectors.partners.partnersTopExportPending);
  const isLoadingXLSX = useSelector(selectors.partners.downloadPending);
  const [exportLabel, setExportLabel] = useState('');
  const options = [
    { label: t('Скачать xls файл'), value: 'DownloadXLSX', access: hasAccessToPartnersXls },
    { label: exportLabel, value: 'ExportTop', access: hasAccessExportTop },
    { label: t('Импорт ТОП партнеров'), value: 'ImportTop', access: hasAccessImportTop },
  ];
  const hasAccess = find(options, { access: true });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedValue, setSelectedValue] = React.useState(isNil(hasAccess) ? '' : hasAccess.value);
  const classes = useStyles(ProductsButtonGroupsStyles);
  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleDownloadXLSX = () => dispatch(asyncActions.getDownloadAsync());
  const handleImportTop = () => dispatch(actions.mergePartnersTopImport({ isOpen: true }));
  const handleExportTop = () => dispatch(asyncActions.getPartnersExportTop());

  useEffect(() => {
    if (selection.size) {
      setExportLabel(t('Экспорт выбранных ТОП партнеров'));
    } else if (filters.size) {
      setExportLabel(t('Экспорт фильтрованных ТОП партнеров'));
    } else {
      setExportLabel(t('Экспорт всех ТОП партнеров'));
    }
  }, [filters.size, selection.size]);

  if (!hasAccess) {
    return null;
  }

  const selectedButton = (type) => {
    switch (type) {
      case 'DownloadXLSX':
        return (
          <ButtonWithIconAndCircularProgress
            text={t('Скачать xls файл')}
            disabled={isLoadingXLSX}
            isLoading={isLoadingXLSX}
            onClick={handleDownloadXLSX}
          >
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        );
      case 'ExportTop':
        return (
          <ButtonWithIconAndCircularProgress
            text={exportLabel}
            onClick={handleExportTop}
            isLoading={isLoadingTop}
            disabled={isLoadingTop}
            fullWidth
            sx={{ justifyContent: 'flex-start', lineHeight: '1.2' }}
          >
            <CloudDownloadIcon />
          </ButtonWithIconAndCircularProgress>
        );
      case 'ImportTop':
        return (
          <>
            <ButtonWithIcon
              text={t('Импорт ТОП партнеров')}
              onClick={handleImportTop}
              fullWidth
              sx={{ justifyContent: 'flex-start', lineHeight: '1.2' }}
            >
              <CloudUploadIcon />
            </ButtonWithIcon>
          </>
        );
      default:
    }
  };

  return (
    <Box>
      <ButtonGroup
        sx={{
          display: 'flex',
          boxShadow: 'none',
        }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        {selectedButton(selectedValue)}
        <Button
          color="primary"
          size="small"
          onClick={handleToggle}
          sx={{ p: '4px 10px' }}
          className="exceptionHeaderMobileDialog"
        >
          <ArrowDropDownIcon className="ActionButtonGroupIcon" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        sx={{ zIndex: 1302 }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="exceptionHeaderMobileDialog"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={classes.menuList}>
                  {options.map((option, index) => (
                    option.access && (
                      <MenuItem
                        key={index}
                        selected={option.value === selectedValue}
                        onClick={() => handleMenuItemClick(option.value)}
                        className={classes.menuListItem}
                      >
                        {option.label}
                      </MenuItem>
                    )
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}


export default ProductsButtonGroups;
