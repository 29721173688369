export const types = Object.freeze({
  SET_MAIL_TEMPLATE_LIST: 'SET_MAIL_TEMPLATE_LIST',
  SET_MAIL_TEMPLATES_EVENTS: 'SET_MAIL_TEMPLATES_EVENTS',
  MERGE_MAIL_TEMPLATES_ROLES: 'MERGE_MAIL_TEMPLATES_ROLES',
  MERGE_MAIL_TEMPLATES_GROUPS: 'MERGE_MAIL_TEMPLATES_GROUPS',
  MERGE_IN_MAIL_TEMPLATE_BY_HASH_ID: 'MERGE_IN_MAIL_TEMPLATE_BY_HASH_ID',
  SET_MAIL_TEMPLATES_CURRENT_PAGE: 'SET_MAIL_TEMPLATES_CURRENT_PAGE',
  SET_MAIL_TEMPLATES_PAGE_SIZE: 'SET_MAIL_TEMPLATES_PAGE_SIZE',
  SET_MAIL_TEMPLATES_SORTING: 'SET_MAIL_TEMPLATES_SORTING',
  SET_MAIL_TEMPLATES_FILTERS: 'SET_MAIL_TEMPLATES_FILTERS',
  SET_PUT_MAIL_TEMPLATE_BY_HASH_ID_PENDING: 'SET_PUT_MAIL_TEMPLATE_BY_HASH_ID_PENDING',
  RESET_MAIL_FILTERS: 'RESET_MAIL_FILTERS',
  SET_MAIL_TEMPLATES_INITIAL_STATE: 'SET_MAIL_TEMPLATES_INITIAL_STATE',
});
