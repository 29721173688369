// Core
import { fromJS, List } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  faqList: { // TODO change to Map()
    currentPage: 0,
    filters: List(),
    items: [], // TODO change to List()
    pending: true,
    selection: [], // TODO change to List()
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    sortingStateColumnExtensions: [],
    settingStatus: false,
    settingPending: false,
  },
  statusesList: {
    items: [],
    pending: false,
  },
  faqItemByHashId: {
    pending: true,
    putPending: false,
  },
  deletePending: false,
});

export const faqKeys = Object.freeze({
  controller: 'faqs',
  columnName: 'columnName',
  currentPage: 'currentPage',
  direction: 'direction',
  faqItemByHashId: 'faqItemByHashId',
  faqList: 'faqList',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  filters: 'filters',
  items: 'items',
  pageSize: 'pageSize',
  pending: 'pending',
  putPending: 'putPending',
  totalCount: 'totalCount',
  selection: 'selection',
  sorting: 'sorting',
  deletePending: 'deletePending',
  sortingStateColumnExtensions: 'sortingStateColumnExtensions',
  statusesList: 'statusesList',
  tableColumnExtensions: 'tableColumnExtensions',
  reload: 'reload',
});

export const faqsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_FAQ_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([faqKeys.faqList, faqKeys.pending], pending);
      }
      return state
        .setIn([faqKeys.faqList, faqKeys.pending], pending)
        .setIn([faqKeys.faqList, faqKeys.items], List(items))
        .setIn([faqKeys.faqList, faqKeys.totalCount], totalCount);
    }

    case types.SET_FAQ_SETTING_STATUS: {
      return state.set(faqKeys.settingStatus, payload);// TODO change to setIn and add path faqKeys.faqList
    }
    case types.SET_FAQ_SETTING_PENDING: {
      return state.set(faqKeys.settingPending, payload);// TODO change to setIn and add path faqKeys.faqList
    }

    case types.SET_FAQ_STATUSES_LIST: {
      const statusesList = List(payload);
      return state.setIn([faqKeys.statusesList, faqKeys.items], statusesList);
    }

    case types.SET_FAQ_STATUSES_LIST_PENDING: {
      return state.setIn([faqKeys.statusesList, faqKeys.pending], payload);
    }

    case types.SET_FAQ_SORTING: {
      return state.setIn([faqKeys.faqList, faqKeys.sorting], fromJS(payload)); // TODO change to List()
    }

    case types.SET_FAQ_RELOAD: {
      return state.setIn([faqKeys.faqList, faqKeys.reload], payload);
    }

    case types.SET_IS_FAQ_DELETE_PENDING: {
      return state.setIn(['deletePending'], payload); // TODO add to keys 'deletePending'
    }

    case types.SET_FAQ_PAGE_SIZE: {
      const pageSize = action.payload;
      return state.setIn([faqKeys.faqList, faqKeys.pageSize], pageSize);
    }

    case types.SET_FAQ_CURRENT_PAGE: {
      const currentPage = action.payload;
      return state.setIn([faqKeys.faqList, faqKeys.currentPage], currentPage);
    }

    case types.SET_FAQ_SELECTION: {
      const selection = List(action.payload);
      return state.setIn([faqKeys.faqList, faqKeys.selection], selection);
    }

    case types.SET_FAQ_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([faqKeys.faqList, faqKeys.filters], List(filters));
    }

    case types.SET_FAQ_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length ? sorting : initialState.getIn([faqKeys.faqList, faqKeys.sorting]).toJS();
      return state
        .setIn([faqKeys.faqList, faqKeys.currentPage], currentPage)
        .setIn([faqKeys.faqList, faqKeys.filters], List(filters))
        .setIn([faqKeys.faqList, faqKeys.pageSize], pageSize)
        .setIn([faqKeys.faqList, faqKeys.totalCount], totalCount)
        .setIn([faqKeys.faqList, faqKeys.reload], reload)
        .setIn([faqKeys.faqList, faqKeys.sorting], fromJS(currentSorting)); // TODO change to List()
    }

    case types.SET_IS_FAQ_ITEM_BY_HASH_ID_LOADING: {
      const isLoading = action.payload;
      return state.setIn([faqKeys.faqItemByHashId, faqKeys.pending], isLoading);
    }

    case types.SET_IS_FAQ_ITEM_BY_HASH_PUT_PENDING: {
      return state.setIn([faqKeys.faqItemByHashId, faqKeys.putPending], payload);
    }

    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
