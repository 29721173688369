// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  stopListBrandsCategoriesList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    selection: List(),
    reload: false,
  }),
  delete: Map({
    pending: false,
  }),
  edit: Map({
    pending: false,
    isModalOpen: false,
    item: Map({
      id: null,
      hashId: null,
      category: undefined,
      brand: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    }),
  }),
  put: Map({
    pending: false,
  }),
  add: Map({
    pending: false,
    isModalOpen: false,
  }),
  download: Map({
    pending: false,
  }),
  upload: Map({
    pending: false,
    isModalOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  upload: 'upload',
  edit: 'edit',
  add: 'add',
  put: 'put',
  delete: 'delete',
  isModalOpen: 'isModalOpen',
  id: 'id',
  hashId: 'hashId',
  item: 'item',
  controller: 'stopListBrandsCategories',
  stopListBrandsCategoriesList: 'stopListBrandsCategoriesList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  selection: 'selection',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  download: 'download',
});

export const stopListBrandsCategoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.MERGE_UPLOAD_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.upload], payload);
    }
    case types.MERGE_DOWNLOAD_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.download], Map(payload));
    }
    case types.MERGE_PUT_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.put], fromJS(payload));
    }
    case types.MERGE_DELETE_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.delete], fromJS(payload));
    }
    case types.MERGE_EDIT_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.edit], fromJS(payload));
    }
    case types.MERGE_ADD_STOP_LIST_BRANDS_CATEGORIES: {
      return state.mergeIn([stateKeys.add], payload);
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.pending], pending)
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.items], List(items))
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.totalCount], totalCount);
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_SELECTION: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.selection], List(payload));
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_SORTING: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.sorting], List(payload));
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.filters], List(filters));
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_PAGE_SIZE: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.pageSize], payload);
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_CURRENT_PAGE: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.currentPage], payload);
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.stopListBrandsCategoriesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.reload], reload)
        .setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_STATUS: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.settingStatus], payload);
    }
    case types.SET_STOP_LIST_BRANDS_CATEGORIES_SETTING_PENDING: {
      return state.setIn([stateKeys.stopListBrandsCategoriesList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
