// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import DxTable from '../../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/categories/statusesHistory/actions';
// import { asyncActions } from '../../../../engine/core/categoryStatusesHistory/saga/asyncActions';
// Helpers
import { useCategoryStatusesHistoryList } from './hooks/useCategoryStatusesHistoryList';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';

function CategoryStatusesHistoryTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.categoryStatusesHistory.isLoading);
  // Data
  const rows = useSelector(selectors.categoryStatusesHistory.list);
  const { t } = useTranslation();

  const columns = ([
    tableDataTypes.createdAtCategoryHistory,
    tableDataTypes.dataFrom,
    tableDataTypes.dataTo,
    tableDataTypes.userWhoEdit,
    tableDataTypes.categoryNote,
  ].map(i => ({
    ...i,
    title: t(`${i.title}`),
  })));

  // Paging
  const currentPage = useSelector(selectors.categoryStatusesHistory.currentPage);
  const pageSize = useSelector(selectors.categoryStatusesHistory.pageSize);
  const totalCount = useSelector(selectors.categoryStatusesHistory.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.categoryStatusesHistory.sorting);
  // Settings
  // const gridSettings = useSelector(selectors.user.userSettings);
  // const settingStatus = useSelector(selectors.categoryStatusesHistory.settingStatus);
  // const settingPending = useSelector(selectors.categoryStatusesHistory.settingPending);
  // Filtering
  const filters = useSelector(selectors.categoryStatusesHistory.filters);


  return (
    <DxTable
      name="categoryStatusesHistory"
      type="static"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      updateHooks={useCategoryStatusesHistoryList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.categoryStatusesHistory.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default CategoryStatusesHistoryTable;
