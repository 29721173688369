// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

export const initialState = fromJS({
  reportsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: false,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    firstLoad: false,
    report: {
      ordersQuantity: 0,
    },
    partnerId: null,
    modalIsOpen: false,
  }),
  reconciliation: Map({
    dates: Map({
      items: List(),
      pending: true,
    }),
    downloadPending: false,
    modalIsOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'reports',
  reportsList: 'reportsList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  report: 'report',
  modalIsOpen: 'modalIsOpen',
  partnerId: 'partnerId',
  createdAt: 'createdAt',
  firstLoad: 'firstLoad',
  reconciliation: 'reconciliation',
  dates: 'dates',
  downloadPending: 'downloadPending',
});

export const reportsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_REPORTS_LIST: {
      return state.mergeIn([stateKeys.reportsList], payload);// TODO separate to setIn with List/Map
    }
    case types.MERGE_RECONCILIATION: { // TODO change name to "MERGE_REPORTS_RECONCILIATION"
      return state.mergeIn([stateKeys.reconciliation], payload);// TODO separate to setIn with List/Map
    }
    case types.SET_REPORTS_RELOAD: {
      return state.setIn([stateKeys.reportsList, stateKeys.reload], payload);
    }
    case types.SET_REPORTS_SORTING: {
      return state.setIn([stateKeys.reportsList, stateKeys.sorting], List(payload));
    }
    case types.SET_REPORTS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.reportsList, stateKeys.filters], List(filters));
    }
    case types.SET_REPORTS_PAGE_SIZE: {
      return state.setIn([stateKeys.reportsList, stateKeys.pageSize], payload);
    }
    case types.SET_REPORTS_CURRENT_PAGE: {
      return state.setIn([stateKeys.reportsList, stateKeys.currentPage], payload);
    }
    case types.SET_REPORTS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;

      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.reportsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.reportsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.reportsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.reportsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.reportsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.reportsList, stateKeys.reload], reload)
        .setIn([stateKeys.reportsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_REPORTS_SETTING_STATUS: {
      return state.setIn([stateKeys.reportsList, stateKeys.settingStatus], payload);
    }
    case types.SET_REPORTS_SETTING_PENDING: {
      return state.setIn([stateKeys.reportsList, stateKeys.settingPending], payload);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
