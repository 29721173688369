const FaqEditStyles = theme => ({
  tabsContainer: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  tabsContent: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  statusSelect: {
    marginTop: 29,
    marginBottom: 7,
  },
  formControl: {
    margin: theme.spacing(),
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  hasError: {
    color: 'rgb(244, 67, 54)',
  },
});

export default FaqEditStyles;
