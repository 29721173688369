// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../../engine/config/selectors';
import { billingActions } from '../../../../../engine/core/billing/action';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';


function BalanceOverModal() {
  const { t } = useTranslation();
  const statusItems = useSelector(selectors.billing.statusItems);
  const statusPending = useSelector(selectors.billing.statusPending);
  const dispatch = useDispatch();
  const {
    summaryBalance, recommendPaymentNotificationSum, paymentNotification,
    erpStatus, creditLimitModalStatus, creditLimitModalOffStatus,
  } = statusItems;
  const hasAccessBillingStatus = useAccessList(accessList.billingStatus);
  const [paymentNotificationOpen, setPaymentNotificationOpen] = useState(false);
  const paymentNotificationTime = localStorage.getItem('paymentNotificationTime');
  const TIMER = (1000 * 60 * 60) * 3; // 60 min * 3h

  const showPopUp = useCallback(() => {
    if (!creditLimitModalStatus && !creditLimitModalOffStatus && statusPending === false) {
      setPaymentNotificationOpen(true);
      localStorage.removeItem('paymentNotificationTime');
    }
  }, [statusPending, creditLimitModalOffStatus, creditLimitModalStatus]);

  const hidePopUp = () => {
    setPaymentNotificationOpen(false);
    localStorage.setItem('paymentNotificationTime', Date.now());
  };

  useEffect(() => {
    if (paymentNotification && !paymentNotificationTime) {
      showPopUp();
    } else if (paymentNotification === false) {
      localStorage.removeItem('paymentNotificationTime');
    }
  }, [paymentNotificationTime, paymentNotification, showPopUp]);

  useEffect(() => {
    if (paymentNotificationOpen && paymentNotification === false) {
      setPaymentNotificationOpen(false);
    }
  }, [paymentNotification, paymentNotificationOpen]);

  useEffect(() => {
    if (paymentNotificationTime && !paymentNotificationOpen) {
      const interval = setInterval(() => {
        const timePassed = Date.now() - parseInt(localStorage.getItem('paymentNotificationTime'), 10);
        if (timePassed > TIMER && !paymentNotificationOpen) {
          showPopUp();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [paymentNotificationTime, paymentNotificationOpen, TIMER, showPopUp]);

  const onSend = () => {
    dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    setTimeout(() => {
      hidePopUp();
    }, 400);
  };

  if (!hasAccessBillingStatus || erpStatus === false) {
    return null;
  }

  return (
    <Modal
      title={t('Рекомендуем пополнить баланс')}
      modalOpen={paymentNotificationOpen}
      handleModalCancel={() => hidePopUp()}
      handleModalToggle={() => hidePopUp()}
      sendText={t('Пополнить баланс')}
      handleModalSend={onSend}
    >
      <p>{t('Ваш остаток баланса на Marketplace «Алло»')} <strong>{summaryBalance}</strong> грн. {t('Рекомендуем пополнить баланс на')} <strong>{recommendPaymentNotificationSum}</strong> грн. </p>
      <p>{t('При отрицательном остатке баланса на счету, ваши товары будут отключены на сайте. Просим заранее пополнять ваш счет.')}</p>
      <strong>{t('Успешных продаж!')}</strong>
    </Modal>
  );
}

export default BalanceOverModal;
