// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// parts
import WordsTable from './components/WordsTable';
import DeleteStopWordButton from './components/DeleteStopWordButton';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import UploadStopWordButton from './components/UploadStopWordButton';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
import AddStopWordButton from './components/AddStopWordButton';
import ExportStopWordsButton from './components/ExportStopWordsButton';
// Hooks
import { useWordsList } from './_hooks/useWordsList';
// actions
import { asyncActions } from '../../../../engine/core/stopList/stopWords/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/stopList/stopWords/actions';

// reducer
import { initialState, stateKeys } from '../../../../engine/core/stopList/stopWords/reducer';
import StopWordModal from './components/StopWordModal/StopWordModal';
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';

function WordsPage() {
  const { t } = useTranslation();
  useWordsList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.stopWords.settingStatus);
  const settingPending = useSelector(selectors.stopWords.settingPending);
  const filters = useSelector(selectors.stopWords.filters);
  const sorting = useSelector(selectors.stopWords.sorting);
  const hasAccessStopWordsUpload = useAccessList(accessList.stopWordsUpload);
  const isDisabledResetButton = !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.stopWordsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.stopWordsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.stopWordsList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const handlePutSettings = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeader title={t('Стоп-слова')}>
        <ExportStopWordsButton />
        <UploadStopWordButton />
        <AddStopWordButton />
        <DeleteStopWordButton />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={handlePutSettings}
        />
        <ResetFiltersButton onReset={resetFilters} disabled={isDisabledResetButton} />
      </PageHeader>
      {hasAccessStopWordsUpload && (<StopWordModal />)}
      <WordsTable />
    </>
  );
}

export default WordsPage;
