import React, { useEffect } from 'react';
import prettyByte from 'pretty-bytes';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';

// UI
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import cx from 'classnames';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AttachedFileListStyles from './AttachedFileListStyles';

// Hooks
import { useStyles } from '../../../../../hooks/useStyles';
import { selectors } from '../../../../../../engine/config/selectors';
import { localData } from '../../../../../../engine/config/localData';
import { asyncActions } from '../../../../../../engine/core/serviceDesk/saga/asyncActions';
import { actions } from '../../../../../../engine/core/serviceDesk/actions';
import { STRING_LENGTH_ALLOWED, getStringTruncateCenter } from '../../../../../../_helpers/getFormatTruncateString';

export default function AttachedFileList({ extraClasses }) {
  const fileIsLoading = useSelector(selectors.serviceDesk.fileIsLoading);
  const dispatch = useDispatch();
  const { hashId } = useParams();
  const classes = useStyles(AttachedFileListStyles);
  const files = useSelector(selectors.serviceDesk.chatFiles);
  const handleDelete = chipToDelete => () => {
    dispatch(asyncActions.deleteFileInChatMessage({
      fileHashId: chipToDelete.hashId,
      multipleModeContext: localData.keysObj.SDAttachedFiles,
      chatId: hashId,
    }));
  };
  const setUpLabel = data => (
    <span>
      <span className={classes.fileName}>{getStringTruncateCenter(data.name)}&nbsp;</span>
      <span className={classes.fileSize}>({prettyByte(data.size)})&nbsp;</span>
    </span>
  );

  useEffect(() => {
    dispatch(asyncActions.setFilesInChatMessage({ hashId }));
    return () => {
      dispatch(actions.setFilesInChatMessage([]));
    };
  }, [dispatch, hashId]);

  if (isEmpty(files)) {
    return null;
  }

  return (
    <div className={classes.filesWrapper}>
      <Paper
        component="ul"
        className={cx(classes.root, {
          [extraClasses]: extraClasses,
        })}
      >
        {files.map(data => (
          <li key={data.id}>
            <Chip
              avatar={<AttachFileIcon />}
              label={setUpLabel(data)}
              title={data.name.length > STRING_LENGTH_ALLOWED ? data.name : null}
              onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              deleteIcon={<CloseRoundedIcon />}
              className={classes.chip}
              disabled={fileIsLoading}
            />
          </li>
        ))}
      </Paper>
    </div>
  );
}
