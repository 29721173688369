// Core
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Parts
import { useTranslation } from 'react-i18next';
import MultipleSelectFilter from './MultipleSelectFilter';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';

function PartnerFilter(props) {
  const {
    disabled, filters, onSelectChange,
    columnName, placeholder,
  } = props;

  const { t } = useTranslation();
  const isLoading = useSelector(selectors.partner.listContextLoading);
  const partnerData = useSelector(selectors.partner.listContext);

  return (
    <MultipleSelectFilter
      values={partnerData}
      loading={isLoading}
      disabled={disabled}
      filters={filters}
      onSelectChange={onSelectChange}
      columnName={columnName}
      placeholder={placeholder}
      getAction={partnerAsyncAction.getPartnerContextListAsync()}
      searchErrorLabel={t('Партнер не найден')}
    />
  );
}

PartnerFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  columnName: PropTypes.string,
};

export default PartnerFilter;
