// Core
import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import Button from '@mui/material/Button';
import Loading from '../../../Loading/Loading';
import Modal from '../../../../containers/Modals/Modal/Modal';
import InvoiceModalBody from './ModalInvoiceBody';
import ModalFooter from '../../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';
// Engine
import { billingAsyncAction } from '../../../../../engine/core/billing/saga/asyncAction';
import { billingActions } from '../../../../../engine/core/billing/action';
import { selectors } from '../../../../../engine/config/selectors';
import { accessList } from '../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { useStyles } from '../../../../hooks/useStyles';

const Styles = () => ({
  modalFooter: {
    padding: '8px !important',
  },
});

const DEFAULT_PAYMENT_FIELD_VALUE = 1000;
const DEFAULT_INVOICE_TYPE_FIELD_VALUE = false;
const DEFAULT_PARTNER_FIELD_VALUE = null;

const INVOICE_PAYMENT_TYPE = {
  card: 1,
  privat24: 2,
  liqPay: 3,
  cash: 4,
  emailBill: 5,
  masterPass: 6,
  pdfBill: 7,
};

function ModalInvoice() {
  const { t } = useTranslation();
  const classes = useStyles(Styles);
  const invoiceTypes = useSelector(selectors.billing.invoiceTypes);
  const normalizeInvoiceTypes = invoiceTypes.toJS();
  const {
    pending: invoicePending,
    statusTitle: invoiceStatusTitle,
    errors: invoiceErrors,
    items: invoiceItems,
  } = normalizeInvoiceTypes;
  const [paymentFieldValue, setPaymentFieldValue] = useState(DEFAULT_PAYMENT_FIELD_VALUE);
  const [invoiceTypeFieldValue, setInvoiceTypeFieldValue] = useState(DEFAULT_INVOICE_TYPE_FIELD_VALUE);
  const [partnerFieldValue, setPartnerFieldValue] = useState(DEFAULT_PARTNER_FIELD_VALUE);
  const dispatch = useDispatch();
  const userData = useSelector(selectors.partner.userData);
  const hash = useSelector(selectors.router.hash);
  const pathname = useSelector(selectors.router.pathname);
  const statusItems = useSelector(selectors.billing.statusItems);
  const isModalInvoiceOpen = useSelector(selectors.billing.billingInvoiceModalIsOpen);
  const downloadPaymentStatus = useSelector(selectors.billing.downloadPaymentStatus);
  const downloadPaymentOrderPending = useSelector(selectors.billing.downloadPaymentOrderPending);
  const hasAccessToBillingPaymentAdd = useAccessList(accessList.billingPaymentAdd);
  const hasAccessToBillingPaymentForPartner = useAccessList(accessList.billingPaymentForPartner);
  const { recommendPaymentSum } = statusItems;
  const isPaymentForPartner = hasAccessToBillingPaymentForPartner;
  const defaultTile = isPaymentForPartner ? t('Создание счета') : t('Пополнение баланса');
  const defaultPartnerId = isPaymentForPartner && userData
    ? userData.id
    : undefined;

  // Send
  const sendForm = () => {
    const payload = {
      paymentSum: paymentFieldValue,
      invoiceType: invoiceTypeFieldValue.value,
    };
    if (partnerFieldValue !== null) {
      payload.partner = partnerFieldValue;
    }
    dispatch(billingAsyncAction.postBillingPaymentAsync(payload));
  };

  // Callbacks
  const generateInvoiceModal = useCallback(() => {
    dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: !isModalInvoiceOpen }));
  }, [dispatch, isModalInvoiceOpen]);
  const changePaymentFieldValue = useCallback((e) => {
    setPaymentFieldValue(e.target.value);
  }, []);
  const changeInvoiceTypeValue = useCallback((value) => {
    const currentItem = invoiceItems.find(item => value?.value === item.value);
    setInvoiceTypeFieldValue(currentItem);
  }, [invoiceItems]);

  // Effects
  useEffect(
    () => {
      if (isModalInvoiceOpen === false) {
        setPaymentFieldValue(recommendPaymentSum || DEFAULT_PAYMENT_FIELD_VALUE);
        setInvoiceTypeFieldValue(DEFAULT_INVOICE_TYPE_FIELD_VALUE);
        setPartnerFieldValue(DEFAULT_PARTNER_FIELD_VALUE);
      }
    },
    [isModalInvoiceOpen, recommendPaymentSum],
  );
  useEffect(() => {
    if (isModalInvoiceOpen) {
      dispatch(billingAsyncAction.getBillingInvoiceTypesAsync('select'));
    }
  }, [dispatch, isModalInvoiceOpen]);
  useEffect(
    () => {
      if (recommendPaymentSum) {
        setPaymentFieldValue(recommendPaymentSum);
      }
    },
    [recommendPaymentSum],
  );
  useEffect(
    () => {
      const isShowOrEdit = pathname.includes('edit') || pathname.includes('show');
      if (defaultPartnerId && partnerFieldValue === DEFAULT_PARTNER_FIELD_VALUE && isShowOrEdit && isModalInvoiceOpen) {
        setPartnerFieldValue(defaultPartnerId);
      }
    },
    [defaultPartnerId, partnerFieldValue, pathname, isModalInvoiceOpen],
  );
  useEffect(
    () => {
      if (invoiceItems.length && !invoiceTypeFieldValue) {
        const liqPayValue = invoiceItems.find(item => item?.value === INVOICE_PAYMENT_TYPE.liqPay);
        const defaultValue = invoiceItems.find(item => item?.default);
        setInvoiceTypeFieldValue(liqPayValue ?? defaultValue);
      }
    },
    [invoiceTypeFieldValue, invoiceItems],
  );
  useEffect(() => {
    if (hash.toLowerCase() === '#showinvoice') {
      localStorage.setItem('paymentNotificationTime', Date.now()); // Hide paymentNotificationModal (order page)
      dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    }
  },
  [dispatch, hash]);
  useEffect(() => {
    if (downloadPaymentStatus === 'success') {
      dispatch(billingActions.mergeBillingInvoiceModal({ modalIsOpen: true }));
    }
  }, [dispatch, downloadPaymentStatus]);

  // Check access
  if (!hasAccessToBillingPaymentAdd && !hasAccessToBillingPaymentForPartner) {
    return null;
  }

  const modalFooterComponent = () => (
    <ModalFooter footerClassNames={classes.modalFooter}>
      <Button
        sx={{ p: BUTTON_SIZE.outlinedMiddle }}
        variant="outlined"
        color="primary"
        onClick={generateInvoiceModal}
      >
        {t('Отменить')}
      </Button>
      <Button
        variant="contained"
        sx={{ p: BUTTON_SIZE.middle }}
        disabled={
          downloadPaymentOrderPending
          || !invoiceTypeFieldValue
          || !paymentFieldValue
          || (isPaymentForPartner && partnerFieldValue === null)
        }
        onClick={sendForm}
      >
        {isPaymentForPartner ? t('Создать') : t('Пополнить')}
      </Button>
    </ModalFooter>
  );

  return (
    <Modal
      title={invoiceStatusTitle || defaultTile}
      modalOpen={isModalInvoiceOpen}
      handleModalToggle={generateInvoiceModal}
      handleModalCancel={generateInvoiceModal}
      modalMaxWidth="xs"
      modalFooterComponent={modalFooterComponent}
    >
      {invoicePending ? <Loading isLoading /> : (
        <InvoiceModalBody
          isPaymentForPartner={isPaymentForPartner}
          error={invoiceErrors}
          changePartnerFieldValue={setPartnerFieldValue}
          partnerFieldValue={partnerFieldValue}
          changePaymentFieldValue={changePaymentFieldValue}
          paymentFieldValue={paymentFieldValue}
          changeInvoiceTypeValue={changeInvoiceTypeValue}
          invoiceTypeFieldValue={invoiceTypeFieldValue}
        />
      )}
    </Modal>
  );
}

export default memo(ModalInvoice);
