// Core
import { push } from 'redux-first-history';
import {
  apply, delay, put,
} from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
import { api, requestStatus } from '../../../../config/api';
import i18n from '../../../../init/i18n';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../config/routes';

export function* callPutCategoryItemByHashIdWorker({ payload }) {
  let response;

  try {
    yield put(actions.setCategoryItem({ submitting: true }));
    response = yield apply(api, api.categories.putCategoryItemByHashId, [payload]);
  } catch (err) {
    yield put(setErrorMessage([{ message: err.message }], i18n.t('Ошибка')));
  }

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;

    switch (status) {
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      case requestStatus.success: {
        const { message } = response.data;
        yield put(actions.setCategoriesTreeDataRowIds([]));
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(0);
        yield put(push(pageLinks.categories.all));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setCategoryItem({ submitting: false }));
}
