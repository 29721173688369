// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, hashId) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_LOGS_LIST_ASYNC, { params, hashId });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRODUCT_FEED_LOGS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRODUCT_FEED_LOGS_INITIAL_STATE_ASYNC, path);
  },
});
