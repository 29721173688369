export const asyncTypes = Object.freeze({
  AUTH_CLEAN_UP: 'AUTH_CLEAN_UP',
  CONFIRM_CODE_ASYNC: 'CONFIRM_CODE_ASYNC',
  SIGN_UP_ASYNC: 'SIGN_UP_ASYNC',
  SIGN_IN_ASYNC: 'SIGN_IN_ASYNC',
  SET_USER_INFO_ASYNC: 'SET_USER_INFO_ASYNC',
  GET_USER_INFO_ASYNC: 'GET_USER_INFO_ASYNC',
  LOG_OUT_ASYNC: 'LOG_OUT_ASYNC',
  PUT_USER_INFO_ASYNC: 'PUT_USER_INFO_ASYNC',
  POST_USERS_ASYNC: 'POST_USERS_ASYNC',
  SET_USER_NOTIFICATIONS_ASYNC: 'SET_USER_NOTIFICATIONS_ASYNC',
  GET_MAIL_SETTINGS_ASYNC: 'GET_MAIL_SETTINGS_ASYNC',
  PUT_MAIL_SETTINGS_ASYNC: 'PUT_MAIL_SETTINGS_ASYNC',
  GET_USERS_ROLES_ASYNC: 'GET_USERS_ROLES_ASYNC',
  GET_USER_BY_HASH_ID_ASYNC: 'GET_USER_BY_HASH_ID_ASYNC',
  PUT_USER_BY_HASH_ID_ASYNC: 'PUT_USER_BY_HASH_ID_ASYNC',
  GET_USERS_SELF_ASYNC: 'GET_USERS_SELF_ASYNC',
  POST_USER_INFO_ASYNC: 'POST_USER_INFO_ASYNC',
  POST_PHONE_VERIFY_CODE: 'POST_PHONE_VERIFY_CODE',
  GET_REPEAT_SEND_PHONE_CODE: 'GET_REPEAT_SEND_PHONE_CODE',
  GET_USERS_PARTNER_ROLES_ASYNC: 'GET_USERS_PARTNER_ROLES_ASYNC',
});
