import { getIn, List } from 'immutable';
import { stateKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const logisticsDataByCategory = Object.freeze({
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.settingPending], false),
  treeDataRowIds: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.treeDataRowIds], List()),
  selection: state => getIn(state, [stateKeys.controller, stateKeys.logisticsDataByCategoryList, stateKeys.selection], List()),
  importPending: state => getIn(state, [stateKeys.controller, stateKeys.import, stateKeys.pending], false),
  importIsOpen: state => getIn(state, [stateKeys.controller, stateKeys.import, stateKeys.isOpen], false),
  exportPending: state => getIn(state, [stateKeys.controller, stateKeys.export, stateKeys.pending], false),
  itemByHashIdData: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.data], List()),
  itemByHashIdPendingId: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.pendingId], undefined),
  itemByHashIdMode: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.mode], undefined),
  itemByHashIdPutPending: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.pending], false),
  logsList: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.items], List()),
  logsPending: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.pending], false),
  logsHashId: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.hashId], null),
  logsShowPopover: state => getIn(state, [stateKeys.controller, stateKeys.logs, stateKeys.showPopover], false),
});
