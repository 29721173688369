import React from 'react';
import { useDesktopView } from '../../../../../../../hooks/useDesktopView';
import Switcher from '../../../../../../../components/_Form/Switcher/Switcher';

function FormFieldFormSwitcher({
  input, ...rest
}) {
  const isDesktopView = useDesktopView();
  return (
    <Switcher
      {...rest}
      {...input}
      disableRipple
      color="secondary"
      sx={isDesktopView ? { marginRight: '40px' } : { marginRight: '10px' }}
      isChecked={input.value}
    />
  );
}

export default FormFieldFormSwitcher;
