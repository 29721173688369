// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/contentProductStatusLogs/actions';
// Hooks
import { useContentProductStatusLogsList } from './_hooks/useContentProductStatusLogsList';
import { tableStatusesLogsScheme } from '../../../../../_helpers/data/pages/settings/products/tableStatusesLogsScheme';
// import { asyncActions } from '../../../../engine/core/contentProductStatusLogs/saga/asyncActions';

// Redux
function ContentProductStatusLogsTable() {
  useContentProductStatusLogsList();
  const dispatch = useDispatch();
  const FilterComponent = () => null;
  const isLoading = useSelector(selectors.contentProductStatusLogs.isLoading);
  // Rows
  const rows = useSelector(selectors.contentProductStatusLogs.list);
  // Paging
  // const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.contentProductStatusLogs.currentPage);
  const pageSize = useSelector(selectors.contentProductStatusLogs.pageSize);
  const totalCount = useSelector(selectors.contentProductStatusLogs.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  // const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  // const sorting = useSelector(selectors.contentProductStatusLogs.sorting);
  // Settings
  // const settingStatus = useSelector(selectors.contentProductStatusLogs.settingStatus);
  // const settingPending = useSelector(selectors.contentProductStatusLogs.settingPending);
  // Filtering
  // const filters = useSelector(selectors.contentProductStatusLogs.filters);

  const historyType = useSelector(selectors.contentProduct.historyType);

  return (
    <DxTable
      name="contentProductStatusLogs"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useContentProductStatusLogsList}
      columns={tableStatusesLogsScheme[historyType]}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      rowFilterComponent={FilterComponent}
      // Sorting
      // onSortingChange={onSortingChange}
      // sorting={sorting}
      // Filters
      // filters={filters}
      // filtersAction={actions.setFilters}
      // Settings
      // gridSettings={gridSettings.contentProductStatusLogs.gridSettings}
      // settingStatus={settingStatus}
      // settingPending={settingPending}
      // settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(ContentProductStatusLogsTable);
