import { getIn, List, Map } from 'immutable';
import { stateKeys as feedsKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';
import { initialState } from '../../_partners/reducers';

export const feeds = Object.freeze({
  currentPage: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.currentPage], 0),
  filters: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.filters], List()),
  isLoading: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.pending], false),
  list: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.items], List()),
  pageSize: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.reload], false),
  totalCount: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.totalCount], 0),
  sorting: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.sorting], List()),
  refreshPending: state => getIn(state, [feedsKeys.controller, feedsKeys.refresh, feedsKeys.pending], false),
  deletePending: state => getIn(state, [feedsKeys.controller, feedsKeys.feedDelete, feedsKeys.pending], false),
  deleteShowModal: state => getIn(state, [feedsKeys.controller, feedsKeys.feedDelete, feedsKeys.showModal], false),
  deleteShowModalHashId: state => getIn(state, [feedsKeys.controller, feedsKeys.feedDelete, feedsKeys.hashId], null),
  refreshShowModal: state => getIn(state, [feedsKeys.controller, feedsKeys.refresh, feedsKeys.showModal], false),
  refreshResultShowModal: state => getIn(state, [feedsKeys.controller, feedsKeys.refreshResult, feedsKeys.showModal], false),
  refreshResultStatusTitleShowModal: state => getIn(state, [feedsKeys.controller, feedsKeys.refreshResult, feedsKeys.statusTitle], null),
  refreshResultMessageShowModal: state => getIn(state, [feedsKeys.controller, feedsKeys.refreshResult, feedsKeys.message], null),
  refreshHashId: state => getIn(state, [feedsKeys.controller, feedsKeys.refresh, feedsKeys.hashId], null),
  refreshId: state => getIn(state, [feedsKeys.controller, feedsKeys.refresh, feedsKeys.id], null),
  isItemByHashIdItem: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsItemByHashId, feedsKeys.item], Map()),
  isItemByHashIdLoading: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsItemByHashId, feedsKeys.pending], true),
  isItemByHashIdPutPending: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsItemByHashId, feedsKeys.putPending], false),
  settingStatus: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.settingStatus], false),
  settingPending: state => getIn(state, [feedsKeys.controller, feedsKeys.feedsList, feedsKeys.settingPending], false),
  statusesList: state => getIn(state, [feedsKeys.controller, feedsKeys.statusesList, feedsKeys.items], List()),
  statusesPending: state => getIn(state, [feedsKeys.controller, feedsKeys.statusesList, feedsKeys.pending], false),
  statusesFlow: state => getIn(state, [feedsKeys.controller, feedsKeys.statusesList, feedsKeys.flow], Map()),
  prioritiesList: state => getIn(state, [feedsKeys.controller, feedsKeys.prioritiesList, feedsKeys.items], List()),
  prioritiesListPending: state => getIn(state, [feedsKeys.controller, feedsKeys.prioritiesList, feedsKeys.pending], true),
  typesList: state => getIn(state, [feedsKeys.controller, feedsKeys.typesList, feedsKeys.items], List()),
  typesListPending: state => getIn(state, [feedsKeys.controller, feedsKeys.typesList, feedsKeys.pending], true),
  importPending: state => getIn(state, [feedsKeys.controller, feedsKeys.import, feedsKeys.pending], false),
  importError: state => getIn(state, [feedsKeys.controller, feedsKeys.import, feedsKeys.error],
    initialState.getIn([
      feedsKeys.import, feedsKeys.error,
    ])),
  exportItems: state => getIn(state, [feedsKeys.controller, feedsKeys.exportItems], Map()),
  exportForceAction: state => getIn(state, [feedsKeys.controller, feedsKeys.exportForceAction], Map()),
  changePriceUrlForceAction: state => getIn(state, [feedsKeys.controller, feedsKeys.changePriceUrlForceAction], Map()),
  completionImportList: state => getIn(state, [feedsKeys.controller, feedsKeys.completionImportList], Map()),
});
