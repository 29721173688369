// Core
import { apply, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { getRefreshToken } from 'axios-jwt';
// Engine
import { api } from '../../../../config/api';
import { routersLink } from '../../../../config/routes';

// Instruments
import { userActionAsync } from '../asyncAction';

export function* callLogOutWorker({ payload: serverLogOut }) {
  const refreshToken = getRefreshToken();
  if (refreshToken && serverLogOut) {
    yield apply(api, api.user.logout, [{ refreshToken }]);
  }
  yield put(userActionAsync.authCleanUp());
  yield put(push(routersLink.users.signIn));
}
