// core
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Parts
import FormHelperText from '@mui/material/FormHelperText';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';


function HelperText(props) {
  const {
    isError, isValueChanged, isValueClear, touched, error,
  } = props;
  const { t } = useTranslation();
  const multiMessage = (touched && (isError || isValueChanged)) && isValueClear;
  const classes = useStyles(ContentProductEditStyles);
  const errorMessage = isError && error;
  const changedMessages = isValueChanged && t('Значение было изменено');
  const formHelperTextClassNames = cx(classes.formControlHelperText, {
    [classes.formControlHelperTextChange]: (!isError && touched && isValueChanged) || isValueClear,
    [classes.errorTextColor]: isError && touched,
  });

  return (
    <FormHelperText className={formHelperTextClassNames}>
      {touched
        ? (errorMessage || changedMessages)
        : undefined
      }
      {multiMessage ? <br /> : undefined}
      {isValueClear ? t('Поле будет очищено') : undefined}
    </FormHelperText>
  );
}

HelperText.propTypes = {
  error: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.string]),
  isError: PropTypes.bool,
  isValueClear: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  isValueChanged: PropTypes.bool.isRequired,
};

export default HelperText;
