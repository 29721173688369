// Core
import { apply, put } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
// Data
import { formName, formFields } from '../../../../../_helpers/data/pages/settings/logsMagentoRequests/form';

export function* callGetItemWorker({ payload: hashId }) {
  yield put(actions.mergeItemByHashId({ pending: true }));
  const response = yield apply(api, api.logs.getMagentoRequestsItem, [hashId]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;

    const formData = Object.keys(formFields).reduce((acc, key) => {
      acc[key] = responseData[key];
      return acc;
    }, {});

    const fields = Object.keys(formData);
    yield put(actions.mergeItemByHashId({ item: responseData }));
    yield put(initialize(formName, formData, fields));
  }

  yield put(actions.mergeItemByHashId({ pending: false }));
}
