// Core
import * as PropTypes from 'prop-types';
// Parts
import FormSelect from '../../../FormSelect/FormSelect';

const RenderSelectField = (formFieldRenderProps) => {
  const {
    input, margin = 'normal', getApprovedValue, getCurrentValue,
    getOldValue, approveInfoField, isShowMode, handleChange, dataTestId, ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  return (
    <FormSelect
      isPortal={false}
      disabled={isShowMode}
      {...rest}
      fullWidth
      approvedValue={getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue(inputName)}
      valueOld={getOldValue(inputName)}
      handleChange={handleChange}
      validation
      dataTestId={dataTestId}
    />
  );
};

RenderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  margin: PropTypes.string,
  getApprovedValue: PropTypes.func,
  getCurrentValue: PropTypes.func,
  getOldValue: PropTypes.func,
  approveInfoField: PropTypes.func,
  isShowMode: PropTypes.bool,
};

export default RenderSelectField;
