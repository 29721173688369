// Core
import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
// Parts
import Typography from '@mui/material/Typography';
import Modal from '../../../Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
// Helpers
import { useStyles } from '../../../../hooks/useStyles';
import ModalMultipleMessagesStyle from './ModalMultipleMessagesStyle';

function ModalComponent({
  title, content, id, showAgain,
}) {
  const classes = useStyles(ModalMultipleMessagesStyle);
  const localStorageName = `modalComponent_${id}`;
  const limitBalanceModalTime = localStorage.getItem(localStorageName);
  const [open, setOpen] = useState(false);

  const showPopUp = () => setOpen(true);

  const hidePopUp = () => {
    setOpen(false);
    localStorage.setItem(localStorageName, Date.now());
  };
  useEffect(() => {
    function showModalOnceInADay() {
      const lastMessageDate = Number(localStorage.getItem(localStorageName));
      const futureDate = lastMessageDate + (24 * 60 * 60 * 1000);
      if (Date.now() >= futureDate) {
        setOpen(true);
      }
    }
    showModalOnceInADay();
    const intervalId = setInterval(showModalOnceInADay, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    switch (showAgain) {
      case 'login':
        break;
      default:
        localStorage.removeItem(localStorageName);
        break;
    }
  }, [showAgain, localStorageName]);

  useEffect(() => {
    if (localStorage.getItem(localStorageName) === null) {
      showPopUp();
    }
  }, [localStorageName, limitBalanceModalTime]);

  return (
    <Modal
      hideButtons
      modalMaxWidth="sm"
      handleModalCancel={hidePopUp}
      handleModalToggle={hidePopUp}
      modalOpen={open}
      title={title}
    >
      <Typography component="div">
        <div className={classes.description} dangerouslySetInnerHTML={content} />
      </Typography>
    </Modal>
  );
}

function ModalMultipleMessages() {
  const userNotificationsPopup = useSelector(selectors.user.userNotificationsPopup);
  const userNotificationsPopupToJS = userNotificationsPopup.toJS();

  return !isEmpty(userNotificationsPopupToJS) && userNotificationsPopupToJS.map(item => (
    <ModalComponent
      key={item.event}
      id={item.event}
      content={{ __html: item.description }}
      title={item.title}
      showAgain={item.showAgain}
    />
  ));
}

export default memo(ModalMultipleMessages);
