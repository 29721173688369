// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
// Icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// Parts
import ButtonWithIcon from '../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { accessList } from '../../../../../../engine/config/access';
import { pageLinks } from '../../../../../../engine/config/routes';
import { selectors } from '../../../../../../engine/config/selectors';
// Helpers
import { setWarningMessage } from '../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';

function ContentProductsBulkEditButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessProductEdit = useAccessList(accessList.productEdit);
  const selectedProducts = useSelector(selectors.allProductsImmutableSelectionSku);
  const productGridLoading = useSelector(selectors.content.productGridLoading);

  const onButtonClick = () => {
    switch (selectedProducts.size) {
      case 0: {
        dispatch(setWarningMessage(t('Ни одного продукта не выбрано'), t('Ошибка')));
        break;
      }
      case 1: {
        const hashId = selectedProducts.getIn([0, 'hashId']);
        dispatch(push(pageLinks.productsAndPrices.content.edit(hashId)));
        break;
      }
      default: {
        dispatch(push(pageLinks.productsAndPrices.content.bulkEdit));
      }
    }
  };

  return hasAccessProductEdit ? (
    <ButtonWithIcon
      id="edit_category_button"
      disabled={productGridLoading}
      onClick={onButtonClick}
      text={t('Редактировать')}
    >
      <ModeEditIcon />
    </ButtonWithIcon>
  ) : null;
}

export default ContentProductsBulkEditButton;
