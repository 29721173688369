// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_REPORTS_LIST_ASYNC, params);
  },
  getReconciliationDatesAsync() {
    return getActionCreator(asyncTypes.GET_RECONCILIATION_DATES);
  },
  getBillingReconciliationActDownloadAsync(params) {
    return getActionCreator(asyncTypes.GET_RECONCILIATION_ACT_DOWNLOAD, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_REPORTS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_REPORTS_INITIAL_STATE_ASYNC, path);
  },
});
