// Core
import { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';

export const useIsValueWasChanged = (input, fieldFormat) => {
  const name = input.name.toString();
  const inputValue = input.value;
  const productByHashId = useSelector(selectors.allProductsTable.productByHashId);
  const initValueFromServer = productByHashId.get(name);
  const normalizedValue = inputValue && inputValue.toJS ? inputValue.toJS() : inputValue;
  const value = get(normalizedValue, 'value', normalizedValue);
  const isValueChanged = useMemo(() => {
    const isIntFields = fieldFormat === 'price' || fieldFormat === 'integer';
    const initValue = initValueFromServer || initValueFromServer === 0 ? initValueFromServer : '';
    if (isIntFields) {
      const currentValueToNumber = +(value.toString().replace(/\s/g, ''));
      const initValueToNumber = +get(initValue, 'value', initValue);
      return currentValueToNumber !== initValueToNumber;
    }
    return (value !== get(initValue, 'value', initValue));
  }, [fieldFormat, value]);

  return {
    isValueChanged,
    value,
    normalizedValue,
    showChangedMessage: isValueChanged && (initValueFromServer !== null && initValueFromServer !== undefined),
  };
};
