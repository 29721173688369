// Core
import { apply, put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form/immutable';

// lodash
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

// config
import { api } from '../../../../../config/api';
import { selectors } from '../../../../../config/selectors';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { localData } from '../../../../../config/localData';
import { formName, formFields } from '../../../../../../ui/page/Products/Content/ContentProducts/components/ContentProductsSidebar/ProductMultipleEditingForm';

export function* callPutProductMultipleEditingWorker({ payload }) {
  yield put(actions.setPutProductMultipleEditing({
    pending: true,
  }));
  const { formData } = payload;
  const feedProductsPageSendQuery = yield localData.getItem(localData.keysObj.feedProductsPageSendQuery);
  const filters = yield select(selectors.feedsProducts.filters);
  const filter = reduce(filters.toJS(), (acc, { columnName, value }) => {
    acc[columnName] = isArray(value) ? map(value, data => data.value).join(',') : value;
    return acc;
  }, {});

  const response = yield apply(api, api.feedsProducts.getProductMultipleEditing, [{
    ...formData, ...(formData.products === 'all' && !isEmpty(filter) ? { filter } : {}),
  }]);
  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        if (feedProductsPageSendQuery) {
          yield put(actions.setReload(true));
          yield put(actions.setSendQuery(true));
        }
        yield put(actions.setProductMultipleEditingConfirmModal({ open: false }));

        if (formData.value) {
          const formValues = Object.freeze({
            [formFields.category]: null,
            [formFields.change]: formData.property,
          });
          const fields = map(formFields, value => value);

          yield put(initialize(formName, formValues, fields));
        }

        break;
      }
      case 'error': {
        const { errors, needForceAction } = data;
        if (needForceAction) {
          yield put(actions.setProductMultipleEditingConfirmModal({ open: true }));
        } else {
          yield put(setErrorMessage(errors, statusTitle));
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.setPutProductMultipleEditing({
    pending: false,
  }));
}
