// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
// UI
import Typography from '@mui/material/Typography';
// Hooks
import PageHeaderStyles from './PageHeaderStyles';

const useMUIStyles = makeStyles(PageHeaderStyles);

function PageHeader(props) {
  const { children, classNames } = props;
  const classes = useMUIStyles();

  return (
    <Typography
      variant="h4"
      className={cx(classes.pageHeadingText, {
        [classNames]: classNames,
      })}
    >
      {children}
    </Typography>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string,
};

export default PageHeader;
