// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/products/productFeedLogs/actions';
import { useProductsFeedLogsList } from './_hooks/useProductsFeedLogsList';
// Redux
function ProductsFeedLogsTable() {
  useProductsFeedLogsList();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectors.productFeedLogs.isLoading);
  // Rows
  const rows = useSelector(selectors.productFeedLogs.list);
  // Paging
  const currentPage = useSelector(selectors.productFeedLogs.currentPage);
  const pageSize = useSelector(selectors.productFeedLogs.pageSize);
  const totalCount = useSelector(selectors.productFeedLogs.totalCount);
  const FilterComponent = () => null;
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));

  const columns = [
    {
      name: 'createdAt',
      title: t('Дата'),
    },
    {
      name: 'label',
      title: t('Статус'),
    },
    {
      name: 'comment',
      title: t('Комментарий'),
    },
    {
      name: 'managerFullName',
      title: t('Пользователь который изменил'),
    },
  ];

  return (
    <DxTable
      name="productFeedLogs"
      fixedColumn
      hiddeRowsLabel
      showShortText
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      rowFilterComponent={FilterComponent}
      updateHooks={useProductsFeedLogsList}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
    />
  );
}

export default memo(ProductsFeedLogsTable);
