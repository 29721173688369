export const types = Object.freeze({
  SET_USER_INFO: 'SET_USER_INFO',
  MERGE_GET_USERS_PARTNER_ROLES: 'MERGE_GET_USERS_PARTNER_ROLES',
  MERGE_MAIL_SETTINGS: 'MERGE_MAIL_SETTINGS',
  MERGE_USER_ADD: 'MERGE_USER_ADD',
  MERGE_USER_NOTIFICATIONS: 'MERGE_USER_NOTIFICATIONS',
  MERGE_PUT_USERS_HASH_ID: 'MERGE_PUT_USERS_HASH_ID',
  MERGE_TWO_FACTOR_AUTH_INFO: 'MERGE_TWO_FACTOR_AUTH_INFO',
  MERGE_IN_USER_INFO: 'MERGE_IN_USER_INFO',
  MERGE_IN_PUT_USER_INFO: 'MERGE_IN_PUT_USER_INFO',
  MERGE_IN_CONFIRM_CODE: 'MERGE_IN_CONFIRM_CODE',
  MERGE_USER_SETTINGS: 'MERGE_USER_SETTINGS',
  MERGE_POST_IN_USER_INFO: 'MERGE_POST_IN_USER_INFO',
  SET_USERS_ROLES: 'SET_USERS_ROLES',
  MERGE_IN_USER_BY_HASH_ID: 'MERGE_IN_USER_BY_HASH_ID',
  SET_USER_CONFIGURATIONS: 'SET_USER_CONFIGURATIONS',
  SET_USER_SUBMITTING: 'SET_USER_SUBMITTING',
  SET_USER_NOTIFICATIONS: 'SET_USER_NOTIFICATIONS',
  SET_USER_PARTNER_HASH_ID: 'SET_USER_PARTNER_HASH_ID',
});
