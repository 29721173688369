// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  reduxForm, Field, Form,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// Components
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import MainLogoImage from '../components/MainLogoImage/MainLogoImage';
import Logo from '../../../../assets/img/logo_light.svg';
import LogoMobile from '../../../../assets/img/logo_short.svg';
import Header from '../../../components/Header/Header';
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Engine
import { asyncActions } from '../../../../engine/core/password/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { useStyles } from '../../../hooks/useStyles';
// Helpers
import { validators } from '../../../../_helpers/validationsForm';
import { formFields, formName } from './form';
import AuthenticationStyle from '../AuthenticationStyle';
import { BUTTON_SIZE } from '../../../containers/App/AppStyles';

function PasswordChange(props) {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resetPasswordSubmitting = useSelector(selectors.password.submitting);
  const classes = useStyles(AuthenticationStyle);

  function onFormSubmit(formData) {
    if (formData && formData.toJS) {
      const jsFormData = formData.toJS();
      const params = {
        code: jsFormData[formFields.code],
        password: jsFormData[formFields.newPassword],
      };
      dispatch(asyncActions.passwordChangeAsync(params));
    }
  }

  return (
    <div className={classes.session}>
      <Header
        logoAltText={t('АЛЛО - личный кабинет')}
        logo={Logo}
        LogoMobile={LogoMobile}
        onlyLogo
        signInButton
      />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="text-xs-center pb-xs">
                  <MainLogoImage />
                  <Typography variant="caption" display="block">
                    {t('Уважаемый клиент, вам на почту, которую вы указали, было отправлено сообщение, зайдите на почту и скопируйте')}
                    {' '}&apos;{t('код')}&apos;
                  </Typography>
                </div>
                <Field
                  component={TextField}
                  className={classes.textField}
                  margin="normal"
                  label={t('Введите код')}
                  fullWidth
                  name={formFields.code}
                  validation
                  validate={[validators.required]}
                />
                <Field
                  component={TextField}
                  className={classes.textField}
                  margin="normal"
                  label={t('Новый пароль')}
                  name={formFields.newPassword}
                  fullWidth
                  type="password"
                  validation
                  validate={[validators.required, validators.password]}
                />
                <Field
                  component={TextField}
                  className={classes.textField}
                  margin="normal"
                  label={t('Подтверждение нового пароля')}
                  name={formFields.confirmNewPassword}
                  fullWidth
                  type="password"
                  validation
                  validate={[validators.required, validators.confirmField(formFields.newPassword)]}
                />
                <ButtonWithIconAndCircularProgress
                  variant="contained"
                  color="primary"
                  fullWidth
                  isLoading={resetPasswordSubmitting}
                  disabled={resetPasswordSubmitting}
                  onClick={handleSubmit(onFormSubmit)}
                  sx={{ p: BUTTON_SIZE.small, marginTop: '16px' }}
                >
                  {t('Сменить пароль')}
                </ButtonWithIconAndCircularProgress>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

PasswordChange.propTypes = {
  handleSubmit: PropTypes.func,
};

PasswordChange.defaultProps = {
  handleSubmit: () => {
  },
};
export default reduxForm({
  form: formName,
})(PasswordChange);
