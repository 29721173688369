import { useState } from 'react';
import cx from 'classnames';
// UI
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';

// styles
import { useStyles } from '../../../../../hooks/useStyles';
import OrdersTableStyles from '../OrdersTableStyles';

function RowComponent(props) {
  const { row } = props;
  const classes = useStyles(OrdersTableStyles);
  const [selected, setSelected] = useState(false);
  const isOverdue = row.overdue;
  const isPaymentCard = row.meta;
  const onClick = () => {
    setSelected(!selected);
  };

  const className = cx({
    [classes.overdue]: isOverdue,
    [classes.activeColor]: selected,
  });

  return (
    <VirtualTable.Row
      {...props}
      onClick={onClick}
      className={className}
      style={isPaymentCard && { background: row.meta.color }}
    />
  );
}

export default RowComponent;
