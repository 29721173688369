const ChatMessageStyles = theme => ({
  message: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridColumnGap: 15,
    gridTemplateRows: 'min-content',
    marginTop: 40,
    width: 'fit-content',
    minWidth: 200,
    '&:last-child': {
      marginTop: 10,
    },
    '&:first-child': {
      marginBottom: 25,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
  messageBody: {
    backgroundColor: '#cfe4f1',
    padding: '5px 10px',
    borderRadius: 5,
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 20px',
    },
  },
  text: {
    fontSize: 12,
    marginTop: 10,
    wordBreak: 'break-all',
  },
  date: {
    color: '#8d8a8a',
    fontSize: 12,
    position: 'absolute',
    left: 0,
    top: '100%',
  },
  typeAllo: {
    placeSelf: 'flex-end',
    gridTemplateColumns: 'auto 40px',
    '& .MuiAvatar-root': {
      order: 1,
    },
    '& $messageBody': {
      backgroundColor: '#daf3da',
    },
    '& $date': {
      right: 0,
      left: 'unset',
    },
  },
  id: {
    fontSize: 12,
    color: '#8d8a8a',
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  files: {
    marginTop: 10,
    marginBottom: 6,
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 5,
    fontSize: 12,
    '& .MuiChip-root': {
      marginRight: 5,
    },
  },
  fileSize: {
    fontSize: 10,
    marginLeft: 2,
  },
  fileIcon: {
    marginLeft: 5,
  },
});

export default ChatMessageStyles;
