// Core
import React, {
  memo, useState, useCallback,
  useEffect,
  useRef,
} from 'react';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
// Parts
import LightGallery from 'lightgallery/react';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import PhotoItem from './components/PhotoItem';
// Style
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-thumbnail.css';
import LoaderComponent from '../../../../_Form/Selects/FormSelect/components/LoaderComponent';
import { transformGifToStatic } from '../../../../../../_helpers/transformGifToStatic';


function MediaCell({ value }) {
  const [normalizedValue, setNormalizeValue] = useState(get(value, 'value', value) || []);
  const [showGallery, setShowGallery] = useState(false);
  const lightgalleryRef = useRef(null);


  useEffect(() => {
    const fetchFirstFrames = async () => {
      try {
        if (normalizedValue.some(item => item.externalLink.endsWith('gif'))) {
          const firstFrameUrls = await transformGifToStatic(normalizedValue, 'externalLink');
          setNormalizeValue(firstFrameUrls);
        }
      } catch (error) {
        console.error('Error extracting first frames:', error);
      }
    };
    fetchFirstFrames();
  }, [value]);
  const firstItem = normalizedValue[0];
  const handleError = (index) => {
    setNormalizeValue(normalizedValue.map((item, i) => {
      if (i === index && item.alloLink) {
        return { ...item, externalLink: item.alloLink };
      }
      return item;
    }));
  };

  const items = normalizedValue.map(({ externalLink, alloLink, id }, index) => (
    <PhotoItem
      key={id}
      image={externalLink}
      alloImage={alloLink}
      index={index}
      offTooltip
      length={normalizedValue.length}
      onError={() => handleError(index)}
    />
  ));
  const onOpen = () => {
    setShowGallery(true);
    lightgalleryRef.current?.openGallery();
  };

  const onClose = () => setShowGallery(false);

  const onInit = useCallback((detail) => {
    if (detail) {
      detail.instance.openGallery();
      lightgalleryRef.current = detail.instance;
    }
  }, []);

  if (!firstItem?.externalLink) {
    return null;
  }

  if (firstItem.externalLink.endsWith('gif')) {
    return (
      <LoaderComponent />
    );
  }

  const onSlideItemLoad = () => {
    lightgalleryRef.current.refresh();
  };

  return (
    <>
      {showGallery === false
        ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span onClick={onOpen}>
            <PhotoItem
              index={0}
              image={firstItem?.externalLink}
              length={normalizedValue.length}
              alloImage={firstItem?.alloLink}
            />
          </span>
        )
        : (
          <LightGallery
            speed={500}
            onInit={onInit}
            onBeforeOpen={onOpen}
            toggleThumb
            onSlideItemLoad={onSlideItemLoad}
            onBeforeClose={onClose}
            plugins={[
              lgThumbnail,
              lgFullscreen,
            ]}
          >
            {items}
          </LightGallery>
        )
      }
    </>
  );
}
MediaCell.propTypes = {
  value: PropTypes.any,
};

export default memo(MediaCell);
