// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Icons
import ArrowBackIcons from '@mui/icons-material/ArrowBack';
// Containers
import CallMissedIcon from '@mui/icons-material/CallMissed';
import ButtonWithIcon from './ButtonWithIcon/ButtonWithIcon';
// Engine

function ButtonGoBack(props) {
  const {
    defaultPage, onSaveAndBackHandler, disabled, id,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(push(defaultPage));
  };

  if (onSaveAndBackHandler) {
    return (
      <ButtonWithIcon id={id} text={t('Сохранить и вернуться')} disabled={disabled} component="button" onClick={onSaveAndBackHandler}>
        <CallMissedIcon />
      </ButtonWithIcon>
    );
  }
  return (
    <ButtonWithIcon id={id} text={t('Вернуться')} component="button" onClick={onClickHandler}>
      <ArrowBackIcons />
    </ButtonWithIcon>
  );
}

ButtonGoBack.propTypes = {
  onSaveAndBackHandler: PropTypes.func,
  disabled: PropTypes.bool,
  defaultPage: PropTypes.string,
  id: PropTypes.string,
};

ButtonGoBack.defaultProps = {
  disabled: false,
};

export default ButtonGoBack;
