export const types = Object.freeze({
  SET_PRICE_CREATOR_FILES_LIST: 'SET_PRICE_CREATOR_FILES_LIST',
  SET_PRICE_CREATOR_FILES_CURRENT_PAGE: 'SET_PRICE_CREATOR_FILES_CURRENT_PAGE',
  SET_PRICE_CREATOR_FILES_FILTERS: 'SET_PRICE_CREATOR_FILES_FILTERS',
  SET_PRICE_CREATOR_FILES_PAGE_SIZE: 'SET_PRICE_CREATOR_FILES_PAGE_SIZE',
  SET_PRICE_CREATOR_FILES_SORTING: 'SET_PRICE_CREATOR_FILES_SORTING',
  SET_PRICE_CREATOR_FILES_RELOAD: 'SET_PRICE_CREATOR_FILES_RELOAD',
  SET_PRICE_CREATOR_FILES_INITIAL_STATE: 'SET_PRICE_CREATOR_FILES_INITIAL_STATE',
  SET_PRICE_CREATOR_FILES_SETTING_STATUS: 'SET_PRICE_CREATOR_FILES_SETTING_STATUS',
  SET_PRICE_CREATOR_FILES_SETTING_PENDING: 'SET_PRICE_CREATOR_FILES_SETTING_PENDING',
  SET_PRICE_CREATOR_DATA_XML_DOWNLOAD_PENDING: 'SET_PRICE_CREATOR_DATA_XML_DOWNLOAD_PENDING',
  SET_DATA_XML_DOWNLOAD_MODAL_ACTIVE: 'SET_DATA_XML_DOWNLOAD_MODAL_ACTIVE',
});
