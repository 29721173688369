// Core
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
// UI
import Chip from '@mui/material/Chip';
// Engine
import { styled } from '@mui/system';
import { selectors } from '../../../../../../engine/config/selectors';
import { COLOR } from '../../../../../containers/App/AppStyles';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';

// Styles
const SkuListItem = styled(Chip)`
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 5px;
  border-color: ${COLOR.black[38]};
  padding: 9px 17px 9px 13px;
  min-width: 184px;
  justify-content: start;

  .MuiChip-label {
    padding: 0;
  }
`;

const SkuListItemLabel = styled('div')`
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  color: ${COLOR.black['54']};

  & b {
    color: ${COLOR.black.initial};
  }
`;

function SelectedProductsSkuList() {
  const classes = useStyles(ContentProductEditStyles);
  const { t } = useTranslation();
  const skuList = useSelector(selectors.allProductsImmutableSelectionSku);
  return (
    <div className={classes.skuContainer}>
      {
        skuList.size > 10
          ? <p className={classes.multipleEditingTitle}>{`${t('Вы редактируете')} ${skuList.size} ${t('товаров')}`}</p>
          : (
            skuList.map((skuItem) => {
              const sku = skuItem.sku.value;
              return (
                <SkuListItem
                  className={classes.sku}
                  key={sku}
                  label={(
                    <SkuListItemLabel>
                      SKU:&nbsp;<b>{sku}</b>
                    </SkuListItemLabel>
                  )}
                  variant="outlined"
                />
              );
            })
          )
      }
    </div>
  );
}

const styles = () => ({
  skuContainer: {
    marginBottom: '3px',
  },
  multipleEditingTitle: {
    fontSize: '14px',
    lineHeight: '1',
    margin: '0 0 21px',
    color: COLOR.black.initial,
  },
  sku: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: '8px',
  },
});

export default withStyles(styles)(SelectedProductsSkuList);
