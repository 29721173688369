import { styled } from '@mui/system';
import { Tab, tabClasses } from '@mui/base/Tab';
import { buttonClasses } from '@mui/base/Button';
import { TabsList } from '@mui/base/TabsList';
import { COLOR } from '../../containers/App/AppStyles';

const SidebarStyles = theme => ({
  badge: {
    width: '20px',
    height: '20px',
    display: 'flex',
    zIndex: 1,
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    alignItems: 'center',
    borderRadius: '50%',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  fab: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 99,
    bottom: 10,
    left: 54,
  },
  fabSmall: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 99,
    left: 0,
    bottom: 0,
    borderRadius: 0,
    maxWidth: 55,
  },
  buttonLinkSmall: {
    borderRadius: 0,
  },
  drawerPaper: {
    position: 'relative',
    width: 268,
    maxWidth: 268,
    height: '100%',
    zIndex: theme.zIndex.drawer + 99,
    overflowY: 'auto',
    backgroundColor: COLOR.darkStyle.main,
    borderRight: 'none',
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 56px)',
    },
    '&::-webkit-scrollbar-thumb': {
      border: '2px solid #303030',
      background: 'rgba(255, 255, 255, 0.54)',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      borderWidth: 0,
      background: 'rgba(255, 255, 255, 0.34)',
    },
  },
  miniDrawerPaper: {
    position: 'relative',
    overflowY: 'inherit',
    width: 48,
    maxWidth: 48,
    borderRight: 'none',
    backgroundColor: COLOR.darkStyle.main,
  },
  modal: {
    [theme.breakpoints.up('xs')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '56px',
    },
  },
  backdrop: {
    [theme.breakpoints.up('xs')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '56px',
    },
  },
  fullHeight: {
    height: '75%',
  },
  buttonLink: {
    display: 'block',
    borderRadius: 0,
    lineHeight: 1,
    justifyContent: 'left',
    padding: 0,
    '& .MuiTypography-root': {
      color: `${COLOR.darkStyle.brand.main}`,
    },
  },
  activeButtonLink: {
    color: `${COLOR.darkStyle.fontColorMain}`,
    backgroundColor: `${COLOR.brand.dark}`,
    '& .MuiTypography-root': {
      color: `${COLOR.darkStyle.fontColorMain}`,
    },
    '& circle': {
      stroke: `${COLOR.darkStyle.fontColorMain}`,
    },
    '& path': {
      fill: `${COLOR.darkStyle.fontColorMain}`,
    },
    '& div': {
      color: `${COLOR.darkStyle.fontColorMain}`,
    },
  },
});

export default SidebarStyles;

export const TabTopStyled = styled(Tab)`
  color: ${COLOR.darkStyle.fontColorMain};
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${COLOR.darkStyle.white['8']};
    color: ${COLOR.darkStyle.fontColorMain};
  }
  &.${tabClasses.selected} {
    background-color: ${COLOR.brand.dark};
    color: ${COLOR.darkStyle.fontColorMain};
  }
  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabsListTopStyled = styled(TabsList)(
  () => `
    background-color: ${COLOR.darkStyle.white['24']};
    border-radius: 5px;
    padding: 2px;
    margin: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `,
);
