// Core
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { change, reset } from 'redux-form';
import moment from 'moment';
import {
  Field,
  Form, reduxForm,
} from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
// Parts
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
import DataRangeDrop from '../../../../../../components/_Form/DataRange/DataRangeDrop/DataRangeDrop';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
// Engine
import { formName, formFields } from './form';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/partners/actions';
import { asyncActions } from '../../../../../../../engine/core/partners/saga/asyncActions';
// Helpers
import { COLOR } from '../../../../../../containers/App/AppStyles';
import { getDateTimezone } from '../../../../../../../_helpers/getDate';
import { validators } from '../../../../../../../_helpers/validationsForm';
import { usePartnerData } from '../../../../hooks/usePartnerData';

const DelayDaysNumberFormat = (props) => {
  const { ...other } = props;
  return (
    <NumberFormat
      {...other}
      decimalScale={0}
      isAllowed={(values) => {
        if (!values.value) {
          return true;
        }
        if (values.value.charAt(0) === '0') {
          if (values.value.charAt(1) && values.value.charAt(1) !== '.') {
            return false;
          }
        }
        const { floatValue } = values;
        return floatValue < 15 && floatValue > 0;
      }}
    />
  );
};

function DeliveryDelayPeriodModal(props) {
  const {
    handleSubmit, valid, dirty,
    hashId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getInfoFieldValue } = usePartnerData();
  const deliveryDelayPeriodValue = getInfoFieldValue('deliveryDelayPeriod');
  const initStartDate = deliveryDelayPeriodValue?.delayedPeriod?.start;
  const isModalOpen = useSelector(selectors.partners.partnerDeliveryDelayPeriodIsOpen);
  const pending = useSelector(selectors.partners.partnerDeliveryDelayPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const endValue = formValues.get(formFields.end);
  const startValue = formValues.get(formFields.start);
  const disabledSend = !valid || !dirty || pending;
  const defaultMinDate = initStartDate && moment(initStartDate) >= moment().add(1, 'd')
    ? moment(initStartDate)
    : moment().add(1, 'd');
  const [minEnd, setMinEnd] = useState(defaultMinDate);
  const sendText = deliveryDelayPeriodValue
    ? t('Применить изменения')
    : t('Сохранить');

  const handleModalClose = () => {
    dispatch(reset(formName));
    setMinEnd(defaultMinDate);
    dispatch(actions.mergePartnerDeliveryDelayPeriod({
      isModalOpen: false,
    }));
  };

  const onSubmit = (formData) => {
    const normalizedValues = formData.toJS();
    dispatch(asyncActions.putPartnersDeliveryDelayPeriodAsync({
      hashId,
      formData: {
        infoFieldsValues: {
          deliveryDelayPeriod: {
            enabled: true,
            delayedPeriod: {
              end: getDateTimezone(normalizedValues?.end),
              start: getDateTimezone(normalizedValues?.start),
              daysDelay: Number(normalizedValues?.daysDelay),
            },
          },
        },
      },
    }));
  };

  const handleChangeStart = (newStartValue) => {
    if (endValue === undefined || newStartValue > endValue) {
      dispatch(change(formName, formFields.end, newStartValue));
    }
    setMinEnd(newStartValue);
  };
  const handleChangeEnd = (newEndValue) => {
    if (startValue === undefined) {
      dispatch(change(formName, formFields.start, newEndValue));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        title={t('Период задержки доставок')}
        modalOpen={isModalOpen}
        handleModalCancel={handleModalClose}
        handleModalToggle={handleModalClose}
        disabledSend={disabledSend}
        handleModalSend={handleSubmit(onSubmit)}
        sendText={sendText}
        buttonSendIcon={null}
        modalMaxWidth="sm"
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: '13px',
              color: COLOR.grey['87'],
              lineHeight: '20px',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            {t('Выберите день или период, в который отгрузка заказов будет выполняться дольше обычного графика, и укажите количество дней, на которое смещается график отгрузок.')}
          </Typography>
        </Box>
        <Grid
          container
          columnSpacing={3}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Field
              name={formFields.start}
              component={DataRangeDrop}
              handleChange={handleChangeStart}
              disablePast
              fullWidth
              validate={[validators.required]}
              minDate={moment().add(1, 'd')}
              label={t('Начало периода')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Field
              name={formFields.end}
              component={DataRangeDrop}
              handleChange={handleChangeEnd}
              disablePast
              fullWidth
              validate={[validators.required]}
              minDate={minEnd}
              label={t('Конец периода')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '0px' }}>
            <Field
              name={formFields.daysDelay}
              component={TextField}
              label={t('Количество дней')}
              fullWidth
              validate={[validators.required]}
              margin="normal"
              inputComponent={DelayDaysNumberFormat}
            />
          </Grid>
        </Grid>
      </Modal>
    </Form>
  );
}

DeliveryDelayPeriodModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hashId: PropTypes.string,
  valid: PropTypes.bool,
  dirty: PropTypes.bool,
};

export default reduxForm({
  form: formName,
  validate: (values) => {
    const endDate = values.get(formFields.end);
    const startDate = values.get(formFields.start);
    return (endDate <= moment() || startDate <= moment())
      ? { end: 'Неправильная дата' }
      : {};
  },
})(DeliveryDelayPeriodModal);
