// Core
import { apply, put } from 'redux-saga/effects';
import { Map } from 'immutable';

// Engine
import { api } from '../../../../config/api';
import { billingActions } from '../../action';

export function* callGetBillingStatusWorker() {
  yield put(billingActions.setBillingStatus({
    status: {
      pending: true,
    },
  }));
  const response = yield apply(api, api.billing.getBillingStatus);
  if (response && response.status >= 200 && response.status < 400) {
    const { erpStatus, isActive, informers } = response.data;
    const responseData = {
      ...response.data,
      creditLimitModalStatus: (erpStatus === null || isActive === null) ? null : (erpStatus === false && isActive === true),
      creditLimitModalOffStatus: isActive === false,
    };

    yield put(billingActions.setBillingStatus({
      status: {
        items: responseData,
        informers: Map(informers),
        pending: false,
      },
    }));
  }
}
