import { NavLink } from 'react-router-dom';
import React from 'react';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { pageLinks } from '../../../../../../../engine/config/routes';
import { IconButtonStyles } from '../../../../../../components/_Table/TableCell/Styles';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { accessList } from '../../../../../../../engine/config/access';

const EditComponent = (props) => {
  const { tableRow } = props;
  const hashId = tableRow.row.hashId;
  const hasCategoriesFeatureEdit = useAccessList(accessList.categoriesFeatureManagementEdit);
  const hasCategoriesFeatureShow = useAccessList(accessList.categoriesFeatureManagementShow);

  return (
    <>
      {hasCategoriesFeatureEdit && (
        <NavLink to={pageLinks.productsAndPrices.categoriesFeatureManagement.edit(hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
            <ModeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </NavLink>
      )}
      {hasCategoriesFeatureShow && !hasCategoriesFeatureEdit && (
        <NavLink to={pageLinks.productsAndPrices.categoriesFeatureManagement.show(hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
            <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </NavLink>
      )}
    </>
  );
};

export default EditComponent;
