// Core
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import DxTable from '../../../../../components/_Table/DxTable/DxTable';
// Parts
import { ProductsTableEditCell } from './ProductsTableEditCell';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/feeds/all/actions';
import { asyncActions } from '../../../../../../engine/core/feeds/all/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
import { useEditCellWidth } from '../../../../../hooks/useEditCellWidth';
import { useFeedsList } from '../../_hooks/useFeedsList';
import { tableDataTypes } from '../../../../../../_helpers/data/tableDataTypes';

// Parts
function ProductsTable() {
  const dispatch = useDispatch();
  // Access
  const hasAccessEdit = useAccessList(accessList.feedEdit);
  const hasAccessShow = useAccessList(accessList.feedShow);
  const hasAccessExport = useAccessList(accessList.feedExport);
  const hasAccessRefresh = useAccessList(accessList.feedRefresh);
  const hasAccessDelete = useAccessList(accessList.feedDelete);
  const editWidth = useEditCellWidth(
    [hasAccessRefresh, hasAccessDelete, (hasAccessEdit || hasAccessShow), hasAccessExport],
    0,
  );
  // Rows
  const rows = useSelector(selectors.feeds.list);
  // Loading
  const isLoading = useSelector(selectors.feeds.isLoading);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.feeds.currentPage);
  const pageSize = useSelector(selectors.feeds.pageSize);
  const totalCount = useSelector(selectors.feeds.totalCount);
  const onPageSize = size => dispatch(actions.setFeedsPageSize(size));
  const onCurrentPage = page => dispatch(actions.setFeedsCurrentPage(page));
  // Sorting
  const onSortingChange = sorting => dispatch(actions.setFeedsSorting(sorting));
  const sorting = useSelector(selectors.feeds.sorting);
  // Settings
  const settingStatus = useSelector(selectors.feeds.settingStatus);
  const settingPending = useSelector(selectors.feeds.settingPending);
  // Filtering
  const filters = useSelector(selectors.feeds.filters);
  const filteringColumnExtension = [
    { columnName: tableDataTypes.feedLog.name, filteringEnabled: false },
  ];

  return (
    <DxTable
      name="productFeed"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={useFeedsList}
      // Editing
      editComponent={ProductsTableEditCell}
      editComponentWidth={editWidth}
      // Paging
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      onCurrentPage={onCurrentPage}
      // Sorting
      onSortingChange={onSortingChange}
      sorting={sorting}
      // Filters
      filters={filters}
      filtersAction={actions.setFeedsFilters}
      filteringExtensions={filteringColumnExtension}
      // Settings
      gridSettings={gridSettings.productFeed.gridSettings}
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putFeedsSettingsAsync}
    />
  );
}

export default memo(ProductsTable);
