const PageHeaderStyles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    position: 'sticky',
    top: `-${theme.spacing(1)}`,
    zIndex: 301,
    rowGap: '8px',
    [theme.breakpoints.down('lg')]: {
      position: 'static',
    },
  },
  text: {
    margin: 0,
  },
  headerWrapper: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    '&.importPageHeader': {
      paddingBottom: '10px',
    },
  },
  childWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiButtonGroup-root': {
      marginBottom: 0,
    },
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  pageHeading: {
    display: 'flex',
    marginTop: '3px',
    // marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
    '&.importPageHeader': {
      marginBottom: '0px',
    },
  },
  pageHeadingText: {
    color: '#303030',
    fontWeight: 'bold',
    lineHeight: '1',
    marginTop: 3,
    [theme.breakpoints.only('xl')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
      marginBottom: theme.spacing(1),
    },
  },
  pageHeadingComponent: {
    alignSelf: 'center',
    paddingLeft: 25,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
  },
  pageHeadingSubText: {
    paddingRight: 10,
    [theme.breakpoints.only('xl')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
      marginBottom: theme.spacing(1),
    },
  },
});

export default PageHeaderStyles;
