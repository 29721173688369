// Core
import { apply, put } from 'redux-saga/effects';
import { replace } from 'redux-first-history';
// Engine
import { setAuthTokens } from 'axios-jwt';
import { api } from '../../../../config/api';
import { routersLink } from '../../../../config/routes';
import { userActions } from '../../action';

// _helpers
import { setWarningMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callSignUpWorker({ payload: userData }) {
  yield put(userActions.setUserSubmitting(true));

  const response = yield apply(api, api.user.signUp, [userData]);

  if (response && response.status >= 200 && response.status < 400) {
    const {
      status, statusTitle, token, refreshToken,
    } = response.data;
    switch (status) {
      case 'success': {
        if (token) {
          setAuthTokens({
            accessToken: token,
            refreshToken,
          });
          yield put(replace(routersLink.users.confirm));
        }
        break;
      }
      case 'error': {
        const { errors } = response.data;
        yield put(setWarningMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(userActions.setUserSubmitting(false));
}
