// Core
import { apply, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../../config/api';
import { pageLinks } from '../../../../../config/routes';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callDeleteStopWordsWorker({ payload }) {
  yield put(actions.mergeDeleteStopWords({ pending: true }));
  const response = yield apply(api, api.stopLists.deleteWords, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    const { status } = data;

    switch (status) {
      case 'success': {
        const { message, statusTitle } = data;
        yield put(setSuccessMessage(message, statusTitle));

        if (!payload.isHashId) {
          yield put(actions.setSelection([]));
          yield put(actions.setReload(false));
          yield put(actions.setReload(true));
        } else {
          yield put(push(pageLinks.stopWords.all));
        }

        break;
      }
      case 'error': {
        const { errors, statusTitle } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.mergeDeleteStopWords({ pending: false }));
}
