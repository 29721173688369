// Core
import React, {
  memo, useEffect, useState,
} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';
import { orderStatuses } from '../../../../page/Orders/_hooks/useOrdersList';
// helpers
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions as ordersAsyncAction } from '../../../../../engine/core/orders/saga/asyncActions';
import { billingAsyncAction } from '../../../../../engine/core/billing/saga/asyncAction';
import { pageLinks } from '../../../../../engine/config/routes';
import { usePrevious } from '../../../../hooks/usePrevious';

function StatusNameFilter(props) {
  const {
    columnName, filteringEnabled, filters,
    onSelectChange, label,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const pathname = useSelector(selectors.router.pathname);
  const ordersStatusesList = useSelector(selectors.orders.statuses);
  const ordersStatusesListPending = useSelector(selectors.orders.ordersStatusesListPending);
  const paymentTypes = useSelector(selectors.billing.paymentTypes);
  const paymentTypesPending = useSelector(selectors.billing.paymentTypesPending);
  const ordersCancelStatuses = useSelector(selectors.orders.cancelStatuses);
  const ordersCancelStatusesPending = useSelector(selectors.orders.isLoading);
  const billingStatusesListItems = useSelector(selectors.billing.statusesListItems);
  const billingStatusesListItemsPending = useSelector(selectors.billing.statusesListItemsPending);
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);
  const prevLanguage = usePrevious(i18n.language);
  const languageWasChanged = (i18n.language !== prevLanguage) && (prevLanguage !== undefined && i18n.language !== undefined);

  const [value, setValue] = useState(filters);
  const [values, setValues] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !currentFiltersNameList.includes(columnName)
      || languageWasChanged
    ) {
      switch (columnName) {
        case tableDataTypes.status.name:
          dispatch(billingAsyncAction.getBillingStatusesListAsync());
          break;
        case tableDataTypes.paymentType.name:
          dispatch(billingAsyncAction.getBillingPaymentTypesAsync());
          break;
        case tableDataTypes.cancelStatus.name:
        case tableDataTypes.orderStatus.name:
          dispatch(ordersAsyncAction.getOrdersStatusesListAsync());
          break;
        default:
          break;
      }
    }
  }, [dispatch, columnName, currentFiltersNameList, languageWasChanged]);

  useEffect(() => {
    switch (columnName) {
      case tableDataTypes.status.name:
        setValues(billingStatusesListItems);
        setLoading(billingStatusesListItemsPending);
        break;
      case tableDataTypes.paymentType.name:
        setValues(paymentTypes);
        setLoading(paymentTypesPending);
        break;
      case tableDataTypes.cancelStatus.name:
        setValues(ordersCancelStatuses);
        setLoading(ordersCancelStatusesPending);
        break;
      case tableDataTypes.feedLocale.name:
        setValues([
          { value: 'uk', label: t('Украинский') },
          { value: 'ru', label: t('Русский') },
        ]);
        setLoading(false);
        break;
      case tableDataTypes.isTop.name:
        setValues([
          { value: 1, label: t('Топ') },
          { value: 0, label: t('Не Топ') },
        ]);
        setLoading(false);
        break;
      case tableDataTypes.orderStatus.name:
        setValues(ordersStatusesList?.toJS()?.orderStatuses);
        setLoading(ordersStatusesListPending);
        break;
      default:
        break;
    }
  }, [
    columnName,
    billingStatusesListItems,
    billingStatusesListItemsPending,
    paymentTypes,
    paymentTypesPending,
    ordersCancelStatuses,
    ordersCancelStatusesPending,
    ordersStatusesList,
    ordersStatusesListPending,
  ]);

  useEffect(() => {
    if (columnName === tableDataTypes.orderStatus.name) {
      switch (pathname) {
        case pageLinks.orders.new: {
          setValue({
            value: [{
              label: t('Принят'),
              value: orderStatuses.new,
            }],
          });
          break;
        }
        case pageLinks.orders.done: {
          setValue({
            value: [
              {
                label: t('Выполнен'),
                value: orderStatuses.done,
              },
              {
                label: t('Отменён'),
                value: orderStatuses.cancel,
              },
            ],
          });
          break;
        }
        default: {
          setValue(filters);
          break;
        }
      }
    }
  }, [columnName, pathname, filters]);

  return (
    <TableHeadFilterSelectMultiple
      isLoading={isLoading}
      filters={value}
      values={values}
      disabled={!filteringEnabled}
      onSelectChange={onSelectChange}
      label={label}
    />
  );
}

StatusNameFilter.propTypes = {
  filteringEnabled: PropTypes.bool,
  filters: PropTypes.object,
  onSelectChange: PropTypes.func,
  label: PropTypes.string,
  columnName: PropTypes.string,
};

StatusNameFilter.defaultProps = {
  label: '',
};

export default memo(StatusNameFilter);
