import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as productFeedLogsKeys } from './reducer';
import { pageSizes } from '../../../config/globalConfig';

export const productFeedLogs = Object.freeze({
  currentPage: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.currentPage], 0),
  filters: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.filters], List()),
  isLoading: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.pending], false),
  list: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.items], List()),
  pageSize: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.reload], false),
  totalCount: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.totalCount], 0),
  sorting: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.sorting], List()),
  settingStatus: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.settingStatus], false),
  settingPending: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.productFeedLogsList, productFeedLogsKeys.settingPending], false),
  productFeedLogsShowModal: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.log, productFeedLogsKeys.showModal], false),
  productFeedLogsHashId: state => getIn(state, [productFeedLogsKeys.controller, productFeedLogsKeys.log, productFeedLogsKeys.hashId], false),
});
