const ModalFooterStyle = () => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    // padding: '10px 30px',
    borderTop: '1px solid #E6E6E6',
  },
  alignEnd: {
    justifyContent: 'flex-end',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
});

export default ModalFooterStyle;
