// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ProductsIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM5.69895 5.69895C5.25142 6.14648 5 6.75346 5 7.38636V9.13636H9.13636V5H7.38636C6.75346 5 6.14648 5.25142 5.69895 5.69895ZM9.13636 13.9091H5V10.0909H9.13636V13.9091ZM5 16.6136V14.8636H9.13636V19H7.38636C6.75346 19 6.14648 18.7486 5.69895 18.3011C5.25142 17.8535 5 17.2465 5 16.6136ZM13.9091 14.8636V19H10.0909V14.8636H13.9091ZM16.6136 19H14.8636V14.8636H19V16.6136C19 17.2465 18.7486 17.8535 18.3011 18.3011C17.8535 18.7486 17.2465 19 16.6136 19ZM19 10.0909V13.9091H14.8636V10.0909H19ZM19 7.38636V9.13636H14.8636V5H16.6136C17.2465 5 17.8535 5.25142 18.3011 5.69895C18.7486 6.14648 19 6.75346 19 7.38636ZM10.0909 9.13636V5H13.9091V9.13636H10.0909ZM13.9091 13.9091V10.0909H10.0909V13.9091H13.9091Z" fill="#DB8020" />
    </SvgIcon>
  );
}

export default ProductsIcon;
