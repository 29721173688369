// Core
import React, {
  useEffect, memo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form/immutable';
// Parts
import Box from '@mui/material/Box';
import { formFields, formName } from './ProductMultipleEditingForm';
import FormTextArea from '../../../../../../components/_Form/TextArea/FormTextArea';
import FormSelect from '../../../../../../components/_Form/Selects/FormSelect/FormSelect';
// Engine
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
// Helpers
import { useStyles } from '../../../../../../hooks/useStyles';
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { validators } from '../../../../../../../_helpers/validationsForm';
import { COMPONENTS_TYPES } from '../../../../../../containers/App/AppStyles';

const style = {
  margin: {
    marginTop: 6,
    marginBottom: 6,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

function ProductStatusField() {
  const classes = useStyles(style);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showNote, setShowNote] = useState(null);
  const [showCancelStatus, setShowCancelStatus] = useState(null);
  const hasAccessToProductStatusEdit = useAccessList(accessList.productStatusEdit);
  const productStatuses = useSelector(selectors.allProductsTable.productStatuses).toJS();
  const productStatusesWithoutTechStatus = productStatuses.filter(item => item.canEdit === true);
  const productsStatusesPending = useSelector(selectors.allProductsTable.productsStatusesPending);
  const cancelStatuses = useSelector(selectors.allProductsTable.cancelStatuses).toJS();
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const statusValue = formValues.get(formFields.productStatus);

  if (!hasAccessToProductStatusEdit) {
    return null;
  }

  useEffect(
    () => {
      dispatch(asyncActions.getProductsStatusesAsync());
    },
    [dispatch],
  );

  useEffect(() => {
    const currentStatusData = productStatuses.filter(item => item.value === statusValue)[0];
    const additionalFields = currentStatusData?.additionalFields;
    const cancelStatusParams = additionalFields?.filter(item => item.name === formFields.cancelStatus)[0];
    const noteParams = additionalFields?.filter(item => item.name === formFields.note)[0];

    setShowCancelStatus(cancelStatusParams || null);
    setShowNote(noteParams || null);
  }, [statusValue]);

  return (
    <>
      <Field
        component={FormSelect}
        isPortal={false}
        isLoading={productsStatusesPending}
        disabled={!productStatuses}
        name={formFields.productStatus}
        label={t('Статус товара')}
        options={productStatusesWithoutTechStatus}
        formSelectWidth="100%"
        validation
        validate={[validators.required]}
        color={COMPONENTS_TYPES.SELECT.dark}
      />
      {showCancelStatus && (
        <Box sx={{ mt: '24px' }}>
          <Field
            component={FormSelect}
            className={classes.margin}
            name={formFields.cancelStatus}
            label={t('Статус отмены')}
            options={cancelStatuses}
            formSelectWidth="100%"
            validation
            validate={showCancelStatus.required ? [validators.required] : []}
            color={COMPONENTS_TYPES.SELECT.dark}
          />
        </Box>
      )}
      {showNote && (
        <Box sx={{ mt: '24px' }}>
          <Field
            color="dark"
            component={FormTextArea}
            name={formFields.note}
            label={t('Примечание')}
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            isDisplayBlock
            validation
            validate={showNote.required ? [validators.required] : []}
          />
        </Box>
      )}
    </>
  );
}

export default memo(ProductStatusField);
