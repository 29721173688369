// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function SortIcon(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 16 14" {...props}>
      <path xmlns="http://www.w3.org/2000/svg" d="M4.40487 0.167863C4.3518 0.114652 4.28875 0.0724362 4.21933 0.0436316C4.14991 0.014827 4.0755 0 4.00034 0C3.92518 0 3.85077 0.014827 3.78135 0.0436316C3.71193 0.0724362 3.64888 0.114652 3.59581 0.167863L0.167563 3.59611C0.0602743 3.7034 0 3.84891 0 4.00064C0 4.15237 0.0602743 4.29788 0.167563 4.40517C0.274852 4.51246 0.420367 4.57274 0.572096 4.57274C0.723825 4.57274 0.86934 4.51246 0.976629 4.40517L3.42897 1.95169V13.1426C3.42897 13.2942 3.48916 13.4395 3.59632 13.5466C3.70347 13.6538 3.8488 13.714 4.00034 13.714C4.15188 13.714 4.29721 13.6538 4.40436 13.5466C4.51152 13.4395 4.57171 13.2942 4.57171 13.1426V1.95169L7.02405 4.40517C7.13134 4.51246 7.27686 4.57274 7.42858 4.57274C7.58031 4.57274 7.72583 4.51246 7.83312 4.40517C7.94041 4.29788 8.00068 4.15237 8.00068 4.00064C8.00068 3.84891 7.94041 3.7034 7.83312 3.59611L4.40487 0.167863V0.167863ZM11.6019 13.554C11.7085 13.6573 11.8511 13.7151 11.9996 13.7151C12.148 13.7151 12.2907 13.6573 12.3973 13.554L15.8255 10.24C15.9346 10.1346 15.9973 9.99008 15.9999 9.83835C16.0025 9.68662 15.9447 9.54009 15.8392 9.43098C15.7337 9.32187 15.5892 9.25913 15.4375 9.25656C15.2858 9.25399 15.1393 9.3118 15.0301 9.41727L12.571 11.7953V0.573538C12.571 0.422 12.5108 0.276669 12.4036 0.169516C12.2964 0.0623624 12.1511 0.00216409 11.9996 0.00216409C11.848 0.00216409 11.7027 0.0623624 11.5956 0.169516C11.4884 0.276669 11.4282 0.422 11.4282 0.573538V11.7953L8.96901 9.41841C8.91527 9.36551 8.8516 9.32376 8.78165 9.29559C8.71171 9.26741 8.63688 9.25336 8.56148 9.25424C8.48608 9.25512 8.4116 9.27092 8.34233 9.30072C8.27306 9.33052 8.21038 9.37374 8.1579 9.42789C8.10541 9.48203 8.06416 9.54603 8.03653 9.61619C8.0089 9.68635 7.99542 9.76128 7.99689 9.83668C7.99835 9.91207 8.01473 9.98642 8.04507 10.0555C8.0754 10.1245 8.11911 10.1868 8.17366 10.2389L11.6019 13.5529V13.554Z" fillOpacity="0.72" />
    </SvgIcon>
  );
}

export default SortIcon;
