// Core
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Engine
import { dateStringFormat } from '../../../../../engine/config/globalConfig';
import { locale } from '../../../../../engine/init/i18n';
// Helpers
import { getDate } from '../../../../../_helpers/getDate';

function DatetimeFormatter({ value, format = dateStringFormat }) {
  const { i18n } = useTranslation();
  const currentLocale = locale[i18n.language];
  return (
    <>
      {value ? getDate(value, format, currentLocale) : ''}
    </>
  );
}

DatetimeFormatter.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
};

export default DatetimeFormatter;
