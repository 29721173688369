// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// Engine
import { asyncActions } from '../../../../../../../engine/core/categoriesFeatureManagement/categoriesFeaturesItem/saga/asyncActions';
// import { accessList } from '../../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
// Hooks
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';
// Data
import { requestDelay } from '../../../../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';
import { userRoles } from '../../../../../../../engine/config/userRoles';
import { tableDataTypes } from '../../../../../../../_helpers/data/tableDataTypes';

export function useCategoriesFeaturesItemList(id, refresh) {
  const { hashId } = useParams();
  const currentPage = useSelector(selectors.categoriesFeaturesItem.currentPage);
  const limit = useSelector(selectors.categoriesFeaturesItem.pageSize);
  const filters = useSelector(selectors.categoriesFeaturesItem.filters);
  const sorting = useSelector(selectors.categoriesFeaturesItem.sorting);
  const normalizeSorting = sorting.toJS().map((item) => {
    if (item.columnName === tableDataTypes.featureRequiredLkValue.name) {
      return {
        ...item,
        columnName: tableDataTypes.featureRequiredLk.name,
      };
    }
    return item;
  });
  const reload = useSelector(selectors.categoriesFeaturesItem.reload);
  const routeKey = useSelector(selectors.router.key);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();

  const hasAccess = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.contentManager,
  ]);
  const offset = limit * currentPage;
  const filtersStringify = JSON.stringify(filters.toJS());
  const sortingStringify = JSON.stringify(normalizeSorting);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const getListAsync = useCallback(() => {
    if (hasAccess) {
      const params = {
        ...paramsByFilter,
        orderBy,
        limit,
        offset,
        hashId,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [hasAccess, paramsByFilter, offset, orderBy, limit, dispatch]);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync,
    getInitialStateAsync,
    reload, refresh, timeout, routeKey]);
}
