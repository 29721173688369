// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { actions } from '../../actions';
import { getFileFromBlob } from '../../../../../../_helpers/getFileFromBlob';
import { selectors } from '../../../../../config/selectors';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callGetDownload() {
  yield put(actions.mergeDownload({ pending: true }));

  const filters = yield select(selectors.stopListBrandsCategories.filters);
  const sorting = yield select(selectors.stopListBrandsCategories.sorting);

  const params = {
    sorting: sorting.toJS(),
  };
  filters.toJS().reduce((acc, { columnName, value }) => {
    acc[columnName] = value;
    return acc;
  }, params);
  const response = yield apply(api, api.stopLists.getBrandsCategoriesExport, [params]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    switch (responseType) {
      case 'object': {
        if (!response.data.status) {
          try {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
            responseData = JSON.parse(decodedString);
          } catch (err) {
            responseData = {};
          }
          break;
        }
        responseData = response.data;
        break;
      }
      default: {
        responseData = {};
        break;
      }
    }

    const { status, statusTitle } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        yield put(setSuccessMessage(message, statusTitle));
        break;
      }

      default: {
        getFileFromBlob('stop_list.xlsx', response);
        break;
      }
    }
  }

  yield put(actions.mergeDownload({ pending: false }));
}
