// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
// Containers
import ButtonWithIconAndCircularProgress from '../../../Buttons/ButtonWithIconAndCircularProgress';
// Engine

function ResetFiltersButton(props) {
  const { t } = useTranslation();
  const {
    onReset, disabled, pending, sx,
  } = props;

  return (
    <ButtonWithIconAndCircularProgress sx={sx} disabled={disabled} isLoading={pending} text={t('Сбросить фильтры')} onClick={onReset}>
      <RefreshIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

ResetFiltersButton.propTypes = {
  onReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(ResetFiltersButton);
