// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ArrowRightSelect() {
  return (
    <SvgIcon className="arrow-right-select" fontSize="inherit" viewBox="0 0 5 10">
      <path d="M0.178671 9.94335C0.232594 9.98543 0.293138 10.0047 0.353804 9.99904C0.414469 9.9934 0.472966 9.96309 0.523015 9.91135L4.85649 5.41112C4.90077 5.3651 4.93696 5.30367 4.96195 5.23209C4.98694 5.1605 5 5.0809 5 5.00009C5 4.91929 4.98694 4.83969 4.96195 4.7681C4.93696 4.69652 4.90077 4.63509 4.85649 4.58907L0.523017 0.0888362C0.473007 0.0369297 0.414483 0.00653429 0.353783 0.000940993C0.293082 -0.00465231 0.232519 0.0147697 0.178649 0.057104C0.124779 0.0994383 0.0796569 0.163072 0.0481672 0.241114C0.0166774 0.319156 2.0457e-05 0.408634 -8.287e-08 0.499858L-1.65656e-06 9.50033C-1.16998e-05 9.5916 0.0166332 9.68114 0.0481289 9.75924C0.0796247 9.83733 0.12477 9.901 0.178671 9.94335Z" />
    </SvgIcon>
  );
}
export default ArrowRightSelect;
