// Core
import { useEffect, useMemo, useRef } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
// Parts
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchField from './SearchField';

const Menu = (props) => {
  const {
    selectProps, children, innerProps, ...rest
  } = props;

  const {
    onMenuClose, classes, onReject, onConfirm, hasConfirmButton,
    searchInputValue, onChangeInputSearch, value,
    options, searchErrorLabel, onClearSearch, hasSearch,
    isTreeSelect, textFieldProps, canClear, setIsFocused, inputValue, filterOption, searchDataTestId: dataTestId,
  } = selectProps;

  const { t } = useTranslation();
  const confirmRef = useRef(null);

  useEffect(() => {
    if (!Array.isArray(value) && typeof value === 'object') {
      confirmRef?.current?.focus();
    }
  }, [value]);

  const filteredValues = useMemo(() => {
    if (inputValue && value) {
      const filtered = options.filter((option) => {
        const newOption = { value: option.value, label: option.label, data: { ...option } };
        return filterOption(newOption, inputValue);
      });

      return filtered;
    }
  }, [inputValue]);

  // TODO: simplify this logic and separate for isSerchable and customSearch
  const filteredDisableConfirm = inputValue ? filteredValues?.length === 0 : false;
  // eslint-disable-next-line no-nested-ternary
  const confimButtonDisabled = inputValue ? false : Array.isArray(value) ? !value?.length : !value;


  const onHandlerSuccess = (e) => {
    if (onConfirm) {
      onConfirm();
    }
    if (onMenuClose && !isTreeSelect) { // in TreeSelect use key (
      onMenuClose();
    }
    setIsFocused(false);
    onClearSearch(e);
  };
  const onHandlerReject = (e) => {
    if (onReject) {
      onReject();
    }
    if (onMenuClose && !isTreeSelect) { // in TreeSelect use key (
      onMenuClose();
    }
    onClearSearch(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      confirmRef?.current?.click();
    }
  };

  const searchMeta = useMemo(() => ({
    touched: !options?.length,
    error: (!options?.length && searchErrorLabel) ? searchErrorLabel : null,
  }), [options?.length, searchErrorLabel]);

  const isSomeChecked = useMemo(() => onReject && value?.length, [onReject, value]);

  return (
    <components.Menu {...rest} selectProps={selectProps} innerProps={innerProps}>
      <Paper
        square
        className={classes.paper}
      >
        {hasSearch && (
          <SearchField
            searchMeta={searchMeta}
            value={searchInputValue}
            onChange={onChangeInputSearch}
            onClearSearch={onClearSearch}
            color={textFieldProps.color}
            dataTestId={dataTestId}
          />
        )}
        {children}
        {hasConfirmButton ? (
          <Box className={classes.withConfirmButtonContainer}>
            {isSomeChecked && canClear ? (
              <Button
                className={classes.rejectButton}
                color="primary"
                size="small"
                onClick={onHandlerReject}
                variant="outlined"
              >
                {t('Очистить')}
              </Button>
            ) : null}
            <Button ref={confirmRef} type="submit" disabled={confimButtonDisabled || filteredDisableConfirm} color="primary" size="small" onTouchStart={onHandlerSuccess} onClick={onHandlerSuccess} onKeyDown={handleKeyDown} variant="contained">
              {t('Применить')}
            </Button>
          </Box>
        ) : null}
      </Paper>
    </components.Menu>
  );
};

export default Menu;
