// Core
import React from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { Navigate } from 'react-router-dom';
// Components
import ContentProductEdit from '../../ContentProductEdit/ContentProductEdit';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../../engine/config/routes';

function ContentProductEditPage() {
  const categoryActive = useSelector(selectors.contentProduct.productCategoryActiveItem);

  if (isNil(categoryActive)) {
    return (
      <Navigate to={pageLinks.content.products.all()} />
    );
  }

  const categoryId = categoryActive.toJS().value;
  const categoryLabel = categoryActive.toJS().label;
  return (
    <ContentProductEdit
      mode="create"
      categoryId={categoryId}
      pageTitle={categoryLabel}
    />
  );
}

export default ContentProductEditPage;
