export const types = Object.freeze({
  SET_FEEDS_PRODUCTS_LIST: 'SET_FEEDS_PRODUCTS_LIST',
  SET_FEEDS_PRODUCTS_SEND_QUERY: 'SET_FEEDS_PRODUCTS_SEND_QUERY',
  SET_FEEDS_PRODUCTS_PARSE_PENDING: 'SET_FEEDS_PRODUCTS_PARSE_PENDING',
  SET_FEEDS_PRODUCTS_DOWNLOAD_FORCE_ACTION: 'SET_FEEDS_PRODUCTS_DOWNLOAD_FORCE_ACTION',
  SET_FEEDS_PRODUCTS_CURRENT_PAGE: 'SET_FEEDS_PRODUCTS_CURRENT_PAGE',
  SET_FEEDS_PRODUCTS_FILTERS: 'SET_FEEDS_PRODUCTS_FILTERS',
  SET_FEEDS_PRODUCTS_PAGE_SIZE: 'SET_FEEDS_PRODUCTS_PAGE_SIZE',
  SET_FEEDS_PRODUCTS_SORTING: 'SET_FEEDS_PRODUCTS_SORTING',
  SET_PUT_FEEDS_PRODUCTS_MULTIPLE_EDITING: 'SET_PUT_FEEDS_PRODUCTS_MULTIPLE_EDITING',
  SET_FEEDS_PRODUCTS_MULTIPLE_EDITING_IS_OPEN: 'SET_FEEDS_PRODUCTS_MULTIPLE_EDITING_IS_OPEN',
  SET_FEEDS_PRODUCTS_SELECTION: 'SET_FEEDS_PRODUCTS_SELECTION',
  MERGE_FEEDS_PRODUCTS_MULTIPLE_EDITING_CONFIRM_MODEL: 'MERGE_FEEDS_PRODUCTS_MULTIPLE_EDITING_CONFIRM_MODEL',
  SET_FEEDS_PRODUCTS_INITIAL_STATE: 'SET_FEEDS_PRODUCTS_INITIAL_STATE',
  SET_FEEDS_PRODUCTS_SETTING_STATUS: 'SET_FEEDS_PRODUCTS_SETTING_STATUS',
  SET_FEEDS_PRODUCTS_SETTING_PENDING: 'SET_FEEDS_PRODUCTS_SETTING_PENDING',
  SET_FEEDS_PRODUCTS_DOWNLOAD_PENDING: 'SET_FEEDS_PRODUCTS_DOWNLOAD_PENDING',
  MERGE_FEEDS_PRODUCTS_STATUSES_LIST: 'MERGE_FEEDS_PRODUCTS_STATUSES_LIST',
  MERGE_FEEDS_PRODUCTS_STATUSES_ELABORATION_LIST: 'MERGE_FEEDS_PRODUCTS_STATUSES_ELABORATION_LIST',
  MERGE_FEEDS_PRODUCTS_ITEM_BY_HASH_ID: 'MERGE_FEEDS_PRODUCTS_ITEM_BY_HASH_ID',
  MERGE_PRODUCT_REFRESH: 'MERGE_PRODUCT_REFRESH',
  SET_FEEDS_PRODUCTS_IMPORT_MODAL_IS_OPEN: 'SET_FEEDS_PRODUCTS_IMPORT_MODAL_IS_OPEN',
  SET_FEEDS_PRODUCTS_IMPORT_PENDING: 'SET_FEEDS_PRODUCTS_IMPORT_PENDING',
  SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE: 'SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE',
  SET_FEEDS_PRODUCTS_RELOAD: 'SET_FEEDS_PRODUCTS_RELOAD',
  SET_FEEDS_PRODUCTS_FULL_DOWNLOAD_PENDING: 'SET_FEEDS_PRODUCTS_FULL_DOWNLOAD_PENDING',
  MERGE_FEEDS_PRODUCTS_STATISTIC: 'MERGE_FEEDS_PRODUCTS_STATISTIC',
  SET_PRODUCTS_FEED_STATISTIC: 'SET_PRODUCTS_FEED_STATISTIC',
  SET_PRODUCTS_FEED_EXPORT_PENDING: 'SET_PRODUCTS_FEED_EXPORT_PENDING',
});
