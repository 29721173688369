// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function LocationIcon(props) {
  return (
    <SvgIcon className="menu-icon" fontSize="inherit" viewBox="0 0 18 18" {...props}>
      <g clipPath="url(#clip0_1194_42280)">
        <path d="M14.1192 12.3122L14.1112 12.3042L14.1162 12.2982L14.1246 12.2882L14.1247 12.2881C14.3883 11.9757 14.6248 11.6396 14.8308 11.2837C15.4227 10.2604 15.7614 9.07239 15.7614 7.8057C15.7614 3.96417 12.6472 0.85 8.8057 0.85C4.96417 0.85 1.85 3.96417 1.85 7.8057C1.85 9.07239 2.18873 10.2604 2.78064 11.2837C2.96416 11.6009 3.0939 11.7535 3.29557 11.9908C3.32101 12.0207 3.34759 12.052 3.37558 12.0851L3.47811 12.2732L3.49046 12.2958L3.49985 12.3043L3.49216 12.3122L7.5309 16.5995C8.2223 17.3335 9.38907 17.3335 10.0805 16.5995L14.1192 12.3122ZM8.80766 5.45386C7.39822 5.45386 6.25564 6.59643 6.25564 8.00587C6.25564 9.41531 7.39822 10.5579 8.80766 10.5579C10.2171 10.5579 11.3597 9.41531 11.3597 8.00587C11.3597 6.59643 10.2171 5.45386 8.80766 5.45386ZM7.55648 8.00587C7.55648 7.31486 8.11665 6.7547 8.80766 6.7547C9.49866 6.7547 10.0588 7.31486 10.0588 8.00587C10.0588 8.69687 9.49866 9.25704 8.80766 9.25704C8.11665 9.25704 7.55648 8.69687 7.55648 8.00587ZM14.4606 7.8057C14.4606 9.20206 13.9559 10.4776 13.1177 11.4643L12.997 11.6064L9.13361 15.7076C8.95576 15.8964 8.65562 15.8964 8.47777 15.7076L4.6144 11.6064L4.49368 11.4643C3.65547 10.4776 3.15084 9.20206 3.15084 7.8057C3.15084 4.68261 5.68261 2.15084 8.8057 2.15084C11.9288 2.15084 14.4606 4.68261 14.4606 7.8057Z" fill="#303030" stroke="#303030" strokeWidth="0.3" />
      </g>
      <defs>
        <clipPath id="clip0_1194_42280">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default LocationIcon;
