// Core
import { isNaN } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';
import green from '@mui/material/colors/green';
import Grid from '@mui/material/Grid';
import CountCard from '../../../../components/Widgets/CountCard/CountCard';
// Engine
import { selectors } from '../../../../../engine/config/selectors';

const RatingStatus = () => {
  const { t } = useTranslation();
  const ratingStatus = useSelector(selectors.billing.ratingStatus);
  const { billingStatus } = useSelector(selectors.billing.statusItems);
  const externalRatingStatus = useSelector(selectors.billing.externalRatingStatus);
  let ratingValue = ratingStatus;
  let color = '';
  if (!isNaN(Number(ratingStatus))) {
    ratingValue = `${ratingStatus}% ${t('из 100%')}`;
    const niceColor = ratingStatus <= 79 ? orange[500] : green[500];
    color = ratingStatus <= 59 ? red[500] : niceColor;
  }
  return (
    <>
      <Grid item xs={12} sm={3}>
        <CountCard
          coloredValue={color}
          status={billingStatus}
          valueDesc={t('Ваш рейтинг')}
          smallTitle
          value={ratingValue}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <CountCard
          status={billingStatus}
          valueDesc={t('Ваш рейтинг на сайте')}
          value={externalRatingStatus}
          smallTitle
          rating={typeof externalRatingStatus === 'number'}
        />
      </Grid>
    </>
  );
};

export default RatingStatus;
