// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { api } from '../../../../config/api';
import { userActions } from '../../action';

export function* callGetUsersRolesWorker() {
  const response = yield apply(api, api.user.getUsersRoles);
  // TODO set pending true before request
  if (response && response.status >= 200 && response.status < 400) {
    const data = response.data;
    yield put(userActions.setUsersRoles(data));
  }
  // TODO set pending false before request
}
