// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Containers
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Helpers
import { accessList } from '../../../../../engine/config/access';
import { pageLinks } from '../../../../../engine/config/routes';
import { useAccessList } from '../../../../hooks/useAccessList';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';

function MessageAddButton() {
  const { t } = useTranslation();
  const hasAccess = useAccessList(accessList.messageCreate);

  if (!hasAccess) {
    return null;
  }

  return (
    <Link to={pageLinks.messages.add}>
      <ButtonWithIcon fullWidth text={t('Новое сообщение')} sx={{ p: BUTTON_SIZE.withIconSmall }}>
        <AddCircleIcon />
      </ButtonWithIcon>
    </Link>
  );
}

export default MessageAddButton;
