// Core
import { put } from 'redux-saga/effects';
// Engine
import { actions } from '../../actions';
// Helpers
import { getInitialPageState } from '../../../../../../_helpers/getInitialPageState';

export function* callGetInitialStateWorker() {
  const initialPageState = getInitialPageState({});
  yield put(actions.setInitialState(initialPageState));
}
