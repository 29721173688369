// Core
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
// UI
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function Loading(props) {
  const {
    classes, color, isLoading, request, className, ...rest
  } = props;

  return isLoading && (
    <Grid
      {...rest}
      className={cx({ [className]: className, [classes.loading]: request })}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress
        color={color}
      />
    </Grid>
  );
}

Loading.displayName = 'Loading';

Loading.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  request: PropTypes.bool,
};

Loading.defaultProps = {
  classes: {},
  color: 'secondary',
  isLoading: false,
  request: false,
};

const styles = {
  loading: {
    background: 'rgba(256, 256, 256, 0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export default withStyles(styles)(Loading);
