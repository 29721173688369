export const CategoriesAttributesStyles = () => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root:first-child': {
          '& .MuiTableCell-head': {
            '&:not(.TableStubCell-cell):last-child': {
              borderTopRightRadius: '5px',
            },
            '&:not(.TableStubCell-cell):nth-last-child(2)': {
              borderRadius: '0',
            },
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .TableNoDataCell-cell': {
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        },
        '& .MuiTableRow-root:last-child': {
          '& .MuiTableCell-body': {
            '&:last-child:not(.TableStubCell-cell)': {
              borderBottomRightRadius: '5px',
            },
          },
        },
      },
    },
  },
});
