import { COLOR } from '../../../../containers/App/AppStyles';

const BalancePartnerStyles = () => ({
  balanceMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: 'auto',
  },
  balanceRowWidthIcons: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& $infoIcon': {
      marginLeft: 0,
    },
  },
  balanceIcon: {
    width: '24px',
    height: '24px',
    marginBottom: '2px',
    color: COLOR.darkStyle.fontColorMain,
  },
  balanceValueRow: {
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    color: COLOR.darkStyle.fontColorMain,
  },
  errorIcon: {
    marginRight: '4px',
    color: COLOR.error,
  },
  infoIcon: {
    color: COLOR.controls.dark,
  },
  indebtedness: {
    '& $balanceValueRow': {
      color: COLOR.brand.main,
    },
  },
});

export default BalancePartnerStyles;
