// Core
import * as PropTypes from 'prop-types';
// Parts
import FormInput from '../../../../TextField/FormTextField/FormTextField';

const RenderInputField = (formFieldRenderProps) => {
  const {
    input, margin = 'normal', getApprovedValue, getCurrentValue,
    getOldValue, approveInfoField, isShowMode, autoFocus, ...rest
  } = formFieldRenderProps;

  const inputName = input.name;
  return (
    <FormInput
      disabled={isShowMode}
      {...rest}
      autoFocus={autoFocus}
      fullWidth
      approvedValue={getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue(inputName)}
      valueOld={getOldValue(inputName)}
      validation
    />
  );
};

RenderInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  margin: PropTypes.string,
  getApprovedValue: PropTypes.func,
  getCurrentValue: PropTypes.func,
  getOldValue: PropTypes.func,
  approveInfoField: PropTypes.func,
  isShowMode: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default RenderInputField;
