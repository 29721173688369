// Core
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () => {
  const sentryIsActive = process.env.REACT_APP_SENTRY === 'true' && process.env.REACT_APP_SENTRY_DSN;
  const sentryReplayIsActive = process.env.REACT_APP_SENTRY_REPLAY === 'true';
  if (sentryIsActive) {
    const integrations = [
      new BrowserTracing({ tracingOrigins: ['*'], tracePropagationTargets: ['*'] }),
    ];
    if (sentryReplayIsActive) {
      integrations.push(new Sentry.Replay());
    }
    Sentry.init({
      environment: process.env.NODE_ENV === 'production'
        ? 'prod'
        : process.env.NODE_ENV,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      replaysSessionSampleRate: sentryReplayIsActive ? 1.0 : 0,
      replaysOnErrorSampleRate: sentryReplayIsActive ? 1.0 : 0,
      tracesSampleRate: 1.0,
      integrations,
    });
  }
};
