// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// UI
import CircularProgress from '@mui/material/CircularProgress';
import EqualizerIcon from '@mui/icons-material/Equalizer';
// parts
import ButtonWithIcon from '../../../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { asyncActions } from '../../../../../../../engine/core/feeds/products/saga/asyncActions';
import { selectors } from '../../../../../../../engine/config/selectors';
// hooks
import { useDownLGView } from '../../../../../../hooks/useDesktopView';

function FeedProductStatisticButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const isDownLGView = useDownLGView();
  const productsStatistic = useSelector(selectors.feedsProducts.productsStatistic);
  const { pending } = productsStatistic.toJS();

  const handleClick = () => {
    dispatch(asyncActions.getFeedProductsStatisticAsync({ categoryId }));
  };

  return (
    <ButtonWithIcon
      text={isDownLGView ? null : t('Получить статистику')}
      disabled={pending}
      onClick={handleClick}
      sx={{ height: 32 }}
    >
      {isDownLGView && <EqualizerIcon />}
      {pending && <CircularProgress color="inherit" size={20} />}
    </ButtonWithIcon>
  );
}

export default memo(FeedProductStatisticButton);
