const getNormalizedArray = (filteredArr, dataListMap) => {
  const result = new Map();
  const getParentRecursive = (item) => {
    const {
      value: currentItemValue,
      parentValue: currentItemParentValue,
    } = item;

    const parent = dataListMap.get(currentItemParentValue);
    const resultCurrentItem = result.get(currentItemValue);

    if (!resultCurrentItem) {
      result.set(currentItemValue, { ...dataListMap.get(currentItemValue) });
    }

    if (parent) {
      getParentRecursive(parent);
    }
  };

  filteredArr.forEach(getParentRecursive);

  return Array.from(result.values());
};

export default getNormalizedArray;
