import red from '@mui/material/colors/red';

const ModalMultipleMessagesStyle = () => ({
  description: {
    '& a': {
      color: red[500],
    },
  },
});

export default ModalMultipleMessagesStyle;
