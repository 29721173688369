export const GUIDE_TYPES = {
  PRODUCTS_CONTENT: 'PRODUCTS_CONTENT',
  IMPORT_XML: 'IMPORT_XML',
  IMPORT_LINK_XML: 'IMPORT_LINK_XML',
  IMPORT_XLSX: 'IMPORT_XLSX',
  IMPORT_ADD_PRODUCT_MANUAL: 'IMPORT_ADD_PRODUCT_MANUAL',
  IMPORT_FILE_XML: 'IMPORT_FILE_XML',
  SEND_TO_MODERATION: 'SEND_TO_MODERATION',
  PRODUCTS_EDIT: 'PRODUCT_EDIT',
  PRODUCTS_UPLOAD_PRICE: 'PRODUCTS_UPLOAD_PRICE',
  PRODUCTS_EXPORT_PRICE: 'PRODUCTS_EXPORT_PRICE',
  PRODUCTS_MULTIPLE_EDIT: 'PRODUCTS_MULTIPLE_EDIT',
};
