export const types = Object.freeze({
  SET_PRICE_CREATOR_CATEGORIES_LIST: 'SET_PRICE_CREATOR_CATEGORIES_LIST',
  SET_PRICE_CREATOR_CATEGORIES_CURRENT_PAGE: 'SET_PRICE_CREATOR_CATEGORIES_CURRENT_PAGE',
  SET_PRICE_CREATOR_CATEGORIES_FILTERS: 'SET_PRICE_CREATOR_CATEGORIES_FILTERS',
  SET_PRICE_CREATOR_CATEGORIES_PAGE_SIZE: 'SET_PRICE_CREATOR_CATEGORIES_PAGE_SIZE',
  SET_PRICE_CREATOR_CATEGORIES_SORTING: 'SET_PRICE_CREATOR_CATEGORIES_SORTING',
  SET_PRICE_CREATOR_CATEGORIES_RELOAD: 'SET_PRICE_CREATOR_CATEGORIES_RELOAD',
  SET_PRICE_CREATOR_CATEGORIES_INITIAL_STATE: 'SET_PRICE_CREATOR_CATEGORIES_INITIAL_STATE',
  SET_PRICE_CREATOR_CATEGORIES_SETTING_STATUS: 'SET_PRICE_CREATOR_CATEGORIES_SETTING_STATUS',
  SET_PRICE_CREATOR_CATEGORIES_SETTING_PENDING: 'SET_PRICE_CREATOR_CATEGORIES_SETTING_PENDING',
  MERGE_PRICE_CREATOR_CATEGORIES_UPDATE: 'MERGE_PRICE_CREATOR_CATEGORIES_UPDATE',
  MERGE_PRICE_CREATOR_CATEGORIES_PARTNERS_MODAL: 'MERGE_PRICE_CREATOR_CATEGORIES_PARTNERS_MODAL',
  UPDATE_PRICE_CREATOR_CATEGORIES_LIST_ITEM: 'UPDATE_PRICE_CREATOR_CATEGORIES_LIST_ITEM',
});
