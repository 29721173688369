// Core
import { all, call, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
// Workers
import {
  callSetNotificationMessage, callPostServiceSupportWorker, callSetImportLang, callSetImportLink, callSetImportCategory, callSetImportPageFilePondDefault,
} from './workers';

function* watchSetNotificationMessageWorker() {
  yield takeEvery(asyncTypes.SET_NOTIFICATION_MESSAGE_ASYNC, callSetNotificationMessage);
}
function* watchPostServiceSupportWorker() {
  yield takeEvery(asyncTypes.POST_SERVICE_SUPPORT_MODAL_ASYNC, callPostServiceSupportWorker);
}
function* watchSetImportLangWorker() {
  yield takeEvery(asyncTypes.SET_IMPORT_LANG, callSetImportLang);
}
function* watchSetImportLinkWorker() {
  yield takeEvery(asyncTypes.SET_IMPORT_LINK, callSetImportLink);
}
function* watchSetImportCategoryWorker() {
  yield takeEvery(asyncTypes.SET_IMPORT_CATEGORY, callSetImportCategory);
}
function* watchSetImportPageFilePondDefault() {
  yield takeEvery(asyncTypes.SET_IMPORT_CATEGORY, callSetImportPageFilePondDefault);
}

export function* watchersUI() {
  yield all([
    call(watchSetNotificationMessageWorker),
    call(watchPostServiceSupportWorker),
    call(watchSetImportLangWorker),
    call(watchSetImportLinkWorker),
    call(watchSetImportCategoryWorker),
    call(watchSetImportPageFilePondDefault),
  ]);
}
