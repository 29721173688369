import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// Engine
import { push } from 'redux-first-history';
import { uiActions } from '../../../../../../../engine/core/ui/action';
import { store } from '../../../../../../../engine/init/store';
import { pageLinks } from '../../../../../../../engine/config/routes';

const timeout = 200;

export const importProductManualCallback = (data) => {
  const {
    action, status, index, type,
  } = data;

  if (
    (index === 1 && type === EVENTS.TARGET_NOT_FOUND)
    || (index > 0 && action === ACTIONS.NEXT && type === EVENTS.TARGET_NOT_FOUND)
  ) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setOpenBackdropOverlay(true));
    store.dispatch(push(pageLinks.content.products.simpleView));
    setTimeout(() => {
      store.dispatch(uiActions.setJoyrideRun(true));
      store.dispatch(uiActions.setOpenBackdropOverlay(false));
    }, timeout);
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
    store.dispatch(uiActions.setJoyrideRun(false));
    store.dispatch(uiActions.setJoyrideStepIndex(0));
    store.dispatch(push(pageLinks.productsAndPrices.importPage));
  }
};
