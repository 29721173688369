// Core
import {
  apply, put,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';

// config
import { api } from '../../../../../config/api';
import { pageLinks } from '../../../../../config/routes';

// actions
import { actions } from '../../actions';

// helpers
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';

export function* callPutStopWordWorker({ payload }) {
  yield put(actions.mergePutStopWord({ pending: true }));
  const partners = payload?.data?.excludePartners;
  const excludeWordsArray = payload?.data?.excludeWords?.toJS().map(item => item.value);
  const categoriesId = payload?.data?.categories;
  const normalizeExcludePartnersArray = partners && partners.toJS ? partners.toJS().map(item => item.value) : partners.map(item => item.value);
  const normalizeCategories = categoriesId && categoriesId.toJS ? categoriesId.toJS().map(item => item.value) : (categoriesId || []).map(item => item.value);
  const fixedPayload = {
    hashId: payload?.hashId,
    data: {
      phrase: payload?.data?.phrase,
      categories: normalizeCategories,
      excludeWords: (excludeWordsArray.length === 1 && excludeWordsArray[0] === undefined) ? [] : excludeWordsArray,
      excludePartners: normalizeExcludePartnersArray,
    },
  };

  const response = yield apply(api, api.stopLists.putStopWord, [fixedPayload]);

  if (response && response.status >= 200 && response.status < 400) {
    const { data } = response;
    const { status, statusTitle } = data;
    switch (status) {
      case 'success': {
        const { message } = data;
        yield put(setSuccessMessage(message, statusTitle));
        yield put(push(pageLinks.stopWords.all));
        break;
      }
      case 'error': {
        const { errors } = data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }

  yield put(actions.mergePutStopWord({ pending: false }));
}
