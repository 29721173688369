// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// Components
// Containers
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
// Engine
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { actions } from '../../../../../engine/core/stopList/BrandsCategories/actions';

// Component
function UploadButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessImport = useAccessList(accessList.stopBrandsCategoriesUpload);
  const brandsCategoriesUploadModalIsOpen = useSelector(selectors.stopListBrandsCategories.uploadIsModalOpen);

  if (!hasAccessImport) {
    // noinspection JSConstructorReturnsPrimitive
    return null;
  }

  const handleModalToggle = () => {
    dispatch(actions.mergeUpload({ isModalOpen: !brandsCategoriesUploadModalIsOpen }));
  };

  return (
    <>
      <ButtonWithIcon text={t('Загрузить XLS')} onClick={handleModalToggle}>
        <CloudUploadIcon />
      </ButtonWithIcon>
    </>
  );
}

export default UploadButton;
