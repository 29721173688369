// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Icons
import PersonOffIcon from '@mui/icons-material/PersonOff';
// Parts
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Button from '@mui/material/Button';
import { COLOR } from '../../../../../../../../containers/App/AppStyles';
// Engine
import { actions } from '../../../../../../../../../engine/core/messages/actions';
// Helpers
import { useRecipientsImportItems } from '../../_hooks/useRecipientsImportItems';

function ControlButtonsNotFoundMulti() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentPartnersNotFound } = useRecipientsImportItems();

  const handlePartnersNotFound = () => {
    dispatch(actions.mergePartnersNotFoundModal({
      isOpen: true,
    }));
  };

  return currentPartnersNotFound.length > 10 ? (
    <Tooltip
      title={t('Не найденные партнеры')}
      arrow
      placement="top"
    >
      <Button
        onClick={handlePartnersNotFound}
        color="primary"
      >
        <PersonOffIcon sx={{ color: COLOR.warning }} />
      </Button>
    </Tooltip>
  ) : null;
}

export default ControlButtonsNotFoundMulti;
