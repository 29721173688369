// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function Wallet(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 18 20">
      <path d="M8.931 1.05805C9.05484 0.903095 9.20818 0.774238 9.38216 0.678941C9.55613 0.583645 9.74728 0.523802 9.94454 0.502876C10.1418 0.481951 10.3412 0.500358 10.5313 0.557034C10.7214 0.613709 10.8984 0.707526 11.052 0.833054L15.945 4.82905C16.2141 5.04876 16.3986 5.35505 16.467 5.69564C16.5354 6.03623 16.4834 6.39 16.32 6.69655C15.8922 6.56581 15.4473 6.49958 15 6.50005H14.55L14.997 5.99005L12.42 3.88555L10.2945 6.50155H8.361L11.2575 2.93755L10.1025 1.99405L6.5055 6.50005H4.5855L8.931 1.05805ZM12.75 14.0001C12.5511 14.0001 12.3603 14.0791 12.2197 14.2197C12.079 14.3604 12 14.5511 12 14.7501C12 14.949 12.079 15.1397 12.2197 15.2804C12.3603 15.421 12.5511 15.5001 12.75 15.5001H14.25C14.4489 15.5001 14.6397 15.421 14.7803 15.2804C14.921 15.1397 15 14.949 15 14.7501C15 14.5511 14.921 14.3604 14.7803 14.2197C14.6397 14.0791 14.4489 14.0001 14.25 14.0001H12.75ZM1.5 7.25005C1.5 7.05114 1.57902 6.86038 1.71967 6.71972C1.86032 6.57907 2.05109 6.50005 2.25 6.50005H3.087L4.2795 5.00005H2.25C1.65326 5.00005 1.08097 5.23711 0.65901 5.65906C0.237053 6.08102 0 6.65332 0 7.25005V16.2501C0 17.2446 0.395088 18.1984 1.09835 18.9017C1.80161 19.605 2.75544 20.0001 3.75 20.0001H15C15.7956 20.0001 16.5587 19.684 17.1213 19.1214C17.6839 18.5588 18 17.7957 18 17.0001V11.0001C18 10.2044 17.6839 9.44134 17.1213 8.87873C16.5587 8.31612 15.7956 8.00005 15 8.00005H2.25C2.05109 8.00005 1.86032 7.92104 1.71967 7.78038C1.57902 7.63973 1.5 7.44897 1.5 7.25005ZM1.5 16.2501V9.37255C1.734 9.45505 1.9875 9.50005 2.25 9.50005H15C15.3978 9.50005 15.7794 9.65809 16.0607 9.93939C16.342 10.2207 16.5 10.6022 16.5 11.0001V17.0001C16.5 17.3979 16.342 17.7794 16.0607 18.0607C15.7794 18.342 15.3978 18.5001 15 18.5001H3.75C3.15326 18.5001 2.58097 18.263 2.15901 17.841C1.73705 17.4191 1.5 16.8468 1.5 16.2501Z" />
    </SvgIcon>
  );
}

export default Wallet;
