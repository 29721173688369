// Core
import { apply, put, select } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';
import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callGetProductsStatusesWorker({ payload }) {
  const forceUpdate = payload?.forceUpdate;
  yield put(actions.setProductsStatusesPending(true));

  const { productsStatuses: statuses, productsStatusesLastUpdate: statusesLastUpdate } = localData.keysObj;
  const statusesLastUpdateFromLocalData = yield apply(localData, localData.getItem, [statusesLastUpdate]);
  const statusesLastUpdateFromStatistics = yield select(selectors.statistics.productsStatusesLastUpdate);

  let response;
  let data = {};

  switch (true) {
    case forceUpdate:
    case !statusesLastUpdateFromLocalData:
    case statusesLastUpdateFromLocalData !== statusesLastUpdateFromStatistics: {
      response = yield apply(api, api.products.getProductsStatuses);
      yield apply(localData, localData.setItem, [statusesLastUpdate, statusesLastUpdateFromStatistics]);
      break;
    }
    case statusesLastUpdateFromLocalData === statusesLastUpdateFromStatistics: {
      data = yield apply(localData, localData.getItem, [statuses]);
      if (!data) {
        response = yield apply(api, api.products.getProductsStatuses);
      }
      break;
    }
    default: {
      break;
    }
  }

  if (response && response.status >= 200 && response.status < 400) {
    data = response.data;
    yield apply(localData, localData.setItem, [statuses, data]);
  }
  yield put(actions.setProductsStatuses(data));
  yield put(actions.setProductsStatusesPending(false));
}
