// Parts
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { COLOR } from '../../containers/App/AppStyles';

function NotificationText(props) {
  const {
    children, icon: iconProps, title, titleColor, type,
    backgroundColor, border, marginBottom, width, boxShadow, textColor,
  } = props;
  const [style, setStyle] = useState({});
  const [icon, setIcon] = useState(iconProps);

  useEffect(() => {
    if (type === 'warning') {
      setStyle({
        backgroundColor: '#fff',
        borderTop: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        borderLeft: '4px solid #DB8020',
      });
      setIcon(<InfoOutlinedIcon htmlColor="#DB8020" />);
    }
  }, [type]);
  return (
    <Box
      component="div"
      style={{
        backgroundColor, border, ...style, width,
      }}
      sx={{
        padding: '14px',
        marginBottom,
        borderRadius: '5px',
        alignItems: 'center',
        boxShadow,
      }}
    >
      {Boolean(icon || title) && (
        <Box sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
          {icon}
          {title && (
            <Box sx={{
              color: titleColor,
              marginLeft: '8px',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
            >
              {title}
            </Box>
          )}
        </Box>
      )}
      <Box
        component="p"
        sx={{ margin: 0, fontSize: '14px', color: textColor }}
      >
        {children}
      </Box>
    </Box>
  );
}
export default NotificationText;

NotificationText.defaultProps = {
  backgroundColor: 'rgba(82, 133, 204, 0.08)',
  border: `1px solid ${COLOR.controls.dark}`,
  marginBottom: '10px',
  type: 'default',
  width: undefined,
  boxShadow: '0px 8px 16px rgba(206, 206, 211, 0.24)',
  textColor: 'inherit',
};

NotificationText.propTypes = {
  border: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.oneOf(['warning', 'info', 'error', 'default']),
  titleColor: PropTypes.string,
  title: PropTypes.node,
  icon: PropTypes.node,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  marginBottom: PropTypes.string,
  boxShadow: PropTypes.string,
  textColor: PropTypes.string,
};
