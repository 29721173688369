// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function InstructionIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 18 18">
      <circle cx="9" cy="9" r="8.25" stroke="#F97988" strokeWidth="1.5" fill="transparent" />
      <path d="M9.33 6.814V14.318H8.098V6.814H9.33ZM8.728 4C8.91467 4 9.078 4.06533 9.218 4.196C9.36733 4.31733 9.442 4.51333 9.442 4.784C9.442 5.04533 9.36733 5.24133 9.218 5.372C9.078 5.50267 8.91467 5.568 8.728 5.568C8.52267 5.568 8.35 5.50267 8.21 5.372C8.07 5.24133 8 5.04533 8 4.784C8 4.51333 8.07 4.31733 8.21 4.196C8.35 4.06533 8.52267 4 8.728 4Z" fill="#F97988" />
    </SvgIcon>
  );
}

export default InstructionIcon;
