// Core
import { memo } from 'react';
// Parts
import ControlButtonsRemove from './components/ControlButtonsRemove';
import { ButtonGroupWrapper } from './styles';

function ControlButtons(props) {
  const {
    fields, index,
  } = props;
  return fields?.length !== 1 ? (
    <ButtonGroupWrapper
      size="small"
    >
      <ControlButtonsRemove index={index} fields={fields} />
    </ButtonGroupWrapper>
  ) : null;
}

export default memo(ControlButtons);
