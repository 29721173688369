// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params, treeDataRowIds) {
    return getActionCreator(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_LIST_ASYNC, {
      params, treeDataRowIds,
    });
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_LOGISTICS_DATA_BY_CATEGORY_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE_ASYNC, path);
  },
  getItemByHashIdAsync({ hashId, mode }) {
    return getActionCreator(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC, { hashId, mode });
  },
  postLogisticsDataByCategoryImport(data) {
    return getActionCreator(asyncTypes.POST_LOGISTICS_DATA_BY_CATEGORY_IMPORT_ASYNC, data);
  },
  getLogisticsDataByCategoryExport() {
    return getActionCreator(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_EXPORT_ASYNC);
  },
  putItemByHashIdAsync(data) {
    return getActionCreator(asyncTypes.PUT_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_ASYNC, data);
  },
  getLogsAsync(hashId) {
    return getActionCreator(asyncTypes.GET_LOGISTICS_DATA_BY_CATEGORY_LOGS_ASYNC, { hashId });
  },
});
