// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function ChatIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 19 19">
      <path d="M5.95 7.6H12.55M5.95 10.9H9.25M9.25 17.5C13.8065 17.5 17.5 13.8065 17.5 9.25C17.5 4.69353 13.8065 1 9.25 1C4.69353 1 1 4.69353 1 9.25C1 10.7523 1.40178 12.1623 2.10385 13.375L1.4125 17.0875L5.125 16.3961C6.37862 17.1214 7.80172 17.5022 9.25 17.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#fff" fill="transparent" />
    </SvgIcon>
  );
}

export default ChatIcon;
