// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { asyncTypes } from './asyncTypes';

export const asyncAction = Object.freeze({
  getMailTemplateList(params) {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATE_ASYNC, params);
  },
  getMailTemplatesEvents() {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATES_EVENTS_ASYNC);
  },
  getMailTemplatesRoles() {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATES_ROLES_ASYNC);
  },
  getMailTemplatesGroups(lang) {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATES_GROUPS_ASYNC, lang);
  },
  getMailTemplateByHashId(hashId) {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATE_BY_HASH_ID_ASYNC, hashId);
  },
  putMailTemplateByHashId(data) {
    return getActionCreator(asyncTypes.PUT_MAIL_TEMPLATE_BY_HASH_ID_ASYNC, data);
  },
  getMailTemplatesInitialStateAsync() {
    return getActionCreator(asyncTypes.GET_MAIL_TEMPLATES_INITIAL_STATE_ASYNC);
  },
});
