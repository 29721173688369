// Core
import { all, takeEvery } from 'redux-saga/effects';

// Parts
import { asyncTypes } from './asyncTypes';

// Workers
import {
  callGetStatisticsWorker,
} from './workers';

function* watchGetStatistics() {
  yield takeEvery(asyncTypes.GET_STATISTICS_ASYNC, callGetStatisticsWorker);
}

export function* watchersStatistics() {
  yield all([
    watchGetStatistics(),
  ]);
}
