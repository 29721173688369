export const asyncTypes = Object.freeze({
  GET_BILLING_STATUS_ASYNC: 'GET_BILLING_STATUS_ASYNC',
  GET_BILLING_INVOICE_TYPES_ASYNC: 'GET_BILLING_INVOICE_TYPES_ASYNC',
  POST_BILLING_PAYMENT_ASYNC: 'POST_BILLING_PAYMENT_ASYNC',
  GET_BILLING_REPORT_DOWNLOAD_ASYNC: 'GET_BILLING_REPORT_DOWNLOAD_ASYNC',
  GET_BILLING_STATUSES_LIST_ASYNC: 'GET_BILLING_STATUSES_LIST_ASYNC',
  GET_BILLING_PAYMENT_TYPES_ASYNC: 'GET_BILLING_PAYMENT_TYPES_ASYNC',
  GET_BILLING_ORDERS_DASHBOARD_DATA_ASYNC: 'GET_BILLING_ORDERS_DASHBOARD_DATA_ASYNC',
  GET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA_ASYNC: 'GET_BILLING_ON_TIME_ORDERS_DASHBOARD_DATA_ASYNC',
  GET_BILLING_NEW_ORDERS_DASHBOARD_DATA_ASYNC: 'GET_BILLING_NEW_ORDERS_DASHBOARD_DATA_ASYNC',
  GET_BILLING_DASHBOARD_LAST_TRANSACTIONS_ASYNC: 'GET_BILLING_DASHBOARD_LAST_TRANSACTIONS_ASYNC',
  GET_BILLING_SUCCESS_DELIVERY_DASHBOARD_ASYNC: 'GET_BILLING_SUCCESS_DELIVERY_DASHBOARD_ASYNC',
  GET_DASHBOARD_RATING_ASYNC: 'GET_DASHBOARD_RATING_ASYNC',
  GET_BILLING_PARTNERS_DOWNLOAD_ASYNC: 'GET_BILLING_PARTNERS_DOWNLOAD_ASYNC',
});
