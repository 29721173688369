const DataRangeModalStyles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 30,
    transform: 'translate(-50%)',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      top: 26,
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',

  },
  modalPaper: {
    width: 492,
    height: 250,
    marginBottom: 16,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'auto',
    },
  },
  modalTitleText: {
    [theme.breakpoints.only('xl')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
    },
  },
  modalTextField: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 16px 20px',
  },
});

export default DataRangeModalStyles;
