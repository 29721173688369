import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
import moment from 'moment';
// UI
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import RenderCheckbox from './RenderCheckbox';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import DataRangeModal from '../../../../../components/_Form/DataRange/DataRangeModal';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
import HelperText from './HelperText';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';

function RenderDateFormField(props) {
  const {
    margin, massEdit, input, label, meta, isRecommended, disabled,
    refresh, onRefresh, required, featureDisabled,
    productByHashId, changeFieldValue, setTouchField,
  } = props;

  const name = input.name.toString();
  const touched = meta.touched;
  const inputValue = input.value;
  const oldValue = productByHashId.get(name) || '';
  const checkboxName = getCheckboxName(name);
  const error = !!(meta.touched && meta.error);
  const isDisabled = disabled || featureDisabled?.disabled;
  const classes = useStyles(ContentProductEditStyles);

  const possibleFormats = ['YYYY-MM-DD', 'DD.MM.YYYY'];
  const wasInitData = productByHashId.get(name) !== null && productByHashId.get(name) !== undefined;
  const isValueChanged = (inputValue === oldValue)
    ? false
    : !moment(inputValue, possibleFormats).isSame(moment(oldValue, possibleFormats));

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  const [willBeClear, setWillBeClear] = useState(false);

  const classNames = cx(classes.formControl, classes.dateFormControl, {
    [classes.errorFormControl]: error,
    [classes.changedFormControl]: wasInitData,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(inputValue) && !isDisabled,
  });

  const onClick = (!meta.touched && !isDisabled) ? () => setTouchField(input.name) : null;

  return (
    <FormControl
      margin={margin}
      className={classNames}
      onClick={onClick}
      required={required}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              noMargin
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            value={inputValue}
            formName={formName}
            alignButton="top"
            refresh={refresh}
            onRefresh={onRefresh}
            {...input}
            name={input.name.toString()}
            disabled={isDisabled}
          >
            <FieldWithFeatureDisabled
              showTooltip={featureDisabled?.showTooltip}
              alignButton="top"
            >
              <DataRangeModal
                input={input}
                disabled={isDisabled}
                showClear={false}
                label={label}
                required={required}
                isRecommended={isRecommended}
              />
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
          <HelperText
            error={meta?.error}
            isError={error}
            isValueChanged={isValueChanged && wasInitData}
            isValueClear={willBeClear}
            touched={touched}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderDateFormField;
