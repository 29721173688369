// Core
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// config
import { selectors } from '../../../../../../../../engine/config/selectors';

// form
import { messageFormName } from '../../../form';

export function useCurrentTypeGroup(index) {
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, messageFormName));

  const currentType = useMemo(() => {
    const values = getFormValues.toJS();
    const messageUsers = values.messageUsers;
    const group = messageUsers ? values.messageUsers[index] : undefined;

    return group ? group.type : undefined;
  }, [getFormValues, index]);
  return { currentType };
}
