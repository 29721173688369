export const types = Object.freeze({
  SET_PARTNERS_MANAGERS_LIST: 'SET_PARTNERS_MANAGERS_LIST',
  SET_PARTNERS_MANAGERS_CURRENT_PAGE: 'SET_PARTNERS_MANAGERS_CURRENT_PAGE',
  SET_PARTNERS_MANAGERS_FILTERS: 'SET_PARTNERS_MANAGERS_FILTERS',
  SET_PARTNERS_MANAGERS_PAGE_SIZE: 'SET_PARTNERS_MANAGERS_PAGE_SIZE',
  SET_PARTNERS_MANAGERS_SORTING: 'SET_PARTNERS_MANAGERS_SORTING',
  MERGE_PARTNER_MANAGER: 'MERGE_PARTNER_MANAGER',
  SET_PARTNERS_MANAGERS_INITIAL_STATE: 'SET_PARTNERS_MANAGERS_INITIAL_STATE',
  SET_PARTNERS_MANAGERS_SETTING_STATUS: 'SET_PARTNERS_MANAGERS_SETTING_STATUS',
  SET_PARTNERS_MANAGERS_SETTING_PENDING: 'SET_PARTNERS_MANAGERS_SETTING_PENDING',
  MERGE_PARTNERS_MANAGERS_LIST: 'MERGE_PARTNERS_MANAGERS_LIST',
  SET_PARTNERS_MANAGERS_DOWNLOAD_PENDING: 'SET_PARTNERS_MANAGERS_DOWNLOAD_PENDING',
  SET_ACTIVE_PARTNERS_MANAGERS: 'SET_ACTIVE_PARTNERS_MANAGERS',
  SET_PARTNERS_MANAGERS_IMPORT_MODAL_IS_OPEN: 'SET_PARTNERS_MANAGERS_IMPORT_MODAL_IS_OPEN',
  MERGE_PARTNERS_MANAGERS_UPLOAD_STOP: 'MERGE_PARTNERS_MANAGERS_UPLOAD_STOP',
  SET_PARTNERS_MANAGERS_LIST_RELOAD: 'SET_PARTNERS_MANAGERS_LIST_RELOAD',
});
