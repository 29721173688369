import { COLOR } from '../App/AppStyles';

const SectionHolderStyles = () => ({
  section: {
    padding: '20px',
    boxShadow: COLOR.shadow.block.main,
    borderRadius: '5px',
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '100%',
  },
});

export default SectionHolderStyles;
