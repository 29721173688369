export const asyncTypes = Object.freeze({
  GET_STOP_WORDS_LIST_ASYNC: 'GET_STOP_WORDS_LIST_ASYNC',
  PUT_STOP_WORDS_SETTINGS_ASYNC: 'PUT_STOP_WORDS_SETTINGS_ASYNC',
  GET_STOP_WORDS_INITIAL_STATE_ASYNC: 'GET_STOP_WORDS_INITIAL_STATE_ASYNC',
  DELETE_STOP_WORD_ASYNC: 'DELETE_STOP_WORD_ASYNC',
  POST_STOP_WORD_ASYNC: 'POST_STOP_WORD_ASYNC',
  GET_STOP_WORD_HASH_ID_ASYNC: 'GET_STOP_WORD_HASH_ID_ASYNC',
  PUT_STOP_WORD_ASYNC: 'PUT_STOP_WORD_ASYNC',
  POST_UPLOAD_STOP_WORD_ASYNC: 'POST_UPLOAD_STOP_WORD_ASYNC',
  GET_EXPORT_STOP_WORDS_ASYNC: 'GET_EXPORT_STOP_WORDS_ASYNC',
});
