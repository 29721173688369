import { getIn, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { stateKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const feedsProducts = Object.freeze({
  parsePending: state => getIn(state, [stateKeys.controller, stateKeys.parse, stateKeys.pending], false),
  productsStatistic: state => getIn(state, [stateKeys.controller, stateKeys.productsStatistic], Map()),
  refreshPending: state => getIn(state, [stateKeys.controller, stateKeys.refresh, stateKeys.pending], false),
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.pending], false),
  sendQuery: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.sendQuery], undefined),
  list: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.settingPending], false),
  statusesList: state => getIn(state, [stateKeys.controller, stateKeys.statusesList, stateKeys.items], List()),
  statusesListPending: state => getIn(state, [stateKeys.controller, stateKeys.statusesList, stateKeys.pending], false),
  statusesFlow: state => getIn(state, [stateKeys.controller, stateKeys.statusesList, stateKeys.flow], Map()),
  statusesElaborationList: state => getIn(state, [stateKeys.controller, stateKeys.statusesElaborationList, stateKeys.items], List()),
  statusesElaborationListPending: state => getIn(state, [stateKeys.controller, stateKeys.statusesElaborationList, stateKeys.pending], false),
  statusesElaborationFlow: state => getIn(state, [stateKeys.controller, stateKeys.statusesElaborationList, stateKeys.flow], Map()),
  isItemByHashIdItem: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.item], Map()),
  isItemByHashIdLoading: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.pending], true),
  isItemByHashIdPutPending: state => getIn(state, [stateKeys.controller, stateKeys.itemByHashId, stateKeys.putPending], false),
  productsDownloadPending: state => getIn(state, [stateKeys.controller, stateKeys.productsDownloadPending], false),
  productsFullDownloadPending: state => getIn(state, [stateKeys.controller, stateKeys.productsFullDownloadPending], false),
  productsDownloadForceAction: state => getIn(state, [stateKeys.controller, stateKeys.productsDownloadForceAction], Map()),
  importModalIsOpen: state => getIn(state, [stateKeys.controller, stateKeys.importModal, stateKeys.isOpen], false),
  importModal: state => getIn(state, [stateKeys.controller, stateKeys.importModal], false),
  hideSubmitButton: state => getIn(state, [stateKeys.controller, stateKeys.importModal, stateKeys.hideSubmitButton], false),
  productsImportPending: state => getIn(state, [stateKeys.controller, stateKeys.productsImportPending], false),
  selection: state => getIn(state, [stateKeys.controller, stateKeys.list, stateKeys.selection], List()),
  productMultipleEditing: state => getIn(state, [stateKeys.controller, stateKeys.productMultipleEditing], Map()),
  putProductMultipleEditing: state => getIn(state, [stateKeys.controller, stateKeys.putProductMultipleEditing], Map()),
  productMultipleEditingConfirmModal: state => getIn(state, [stateKeys.controller, stateKeys.productMultipleEditingConfirmModal], Map()),
  productsFeedStatistic: state => getIn(state, [stateKeys.controller, stateKeys.productsFeedStatistic, stateKeys.statistic]),
  productsFeedStatisticPending: state => getIn(state, [stateKeys.controller, stateKeys.productsFeedStatistic, stateKeys.pending], false),
  productsFeedExportPending: state => getIn(state, [stateKeys.controller, stateKeys.productsFeedExportPending], false),
});

export const feedsProductsFiltersWithoutCategory = createSelector(
  feedsProducts.filters,
  data => data.filter(item => item.columnName !== 'category'),
);
