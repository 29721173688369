// Core
import { components } from 'react-select';
// Parts
import ClearIndicator from '../../../../../icons/ClearIndicator';

const DropdownIndicator = props => (
  <components.ClearIndicator {...props}>
    <ClearIndicator />
  </components.ClearIndicator>
);

export default DropdownIndicator;
