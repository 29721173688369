// Core
import { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Parts
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { useShallowEqualSelector } from '../../../../hooks/useShallowEqualSelector';
// Styles
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { tableFiltersTypes } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';

function UsersContextTableFilter(props) {
  const {
    filters, disabled, columnName, columnType,
    columnBulkEditable, onSelectChange, isAuthor, context, role,
  } = props;
  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);
  const userData = (useShallowEqualSelector(selectors.partner.listUsersContext));
  const usersListContext = (useShallowEqualSelector(selectors.partner.usersListContext));
  const userDataWithAdmin = (useSelector(selectors.partner.listUsersAdminsContext));
  const isLoading = useShallowEqualSelector(selectors.partner.listUsersContextLoading);
  const dispatch = useDispatch();

  const getUsersAsync = (params) => {
    dispatch(partnerAsyncAction.getUsersContextListAsync(params));
  };

  useEffect(() => {
    switch (columnType) {
      case tableFiltersTypes.manager: {
        if (!currentFiltersTypeList.includes(columnType)) {
          getUsersAsync({ idRole: '6' });
        }
        break;
      }
      case tableFiltersTypes.contentManager: {
        if (!currentFiltersTypeList.includes(columnType)) {
          const isProductColumn = typeof columnBulkEditable === 'boolean';
          getUsersAsync({
            idRole: '6',
            context: isProductColumn ? 'select_with_unassigned' : undefined,
          });
        }
        break;
      }
      case tableDataTypes.adminManager.type: {
        if (!currentFiltersTypeList.includes(columnType)) {
          getUsersAsync({ idRole: '1,2', storeContextAndRole: true });
        }
        break;
      }
      default: {
        switch (columnName) {
          case tableDataTypes.assignedTo.name: {
            if (!currentFiltersNameList.includes(columnName)) {
              getUsersAsync({ idRole: '9,1', items: true });
            }
            break;
          }
          case tableDataTypes.author.name: {
            if (!currentFiltersNameList.includes(columnName)) {
              getUsersAsync({ idRole: '1', itemsWithAdmin: true });
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }, [columnType, columnName, columnBulkEditable, getUsersAsync]);

  const filteredData = useMemo(() => {
    if (context && role) {
      return (usersListContext.toJS())?.[context]?.[role] || [];
    }
    if (isAuthor) {
      return userDataWithAdmin;
    }
    return userData;
  }, [isAuthor, userDataWithAdmin, userData, context, role, usersListContext]);

  return (
    <TableHeadFilterSelectMultiple
      values={filteredData}
      loading={isLoading}
      disabled={disabled}
      filters={filters}
      onSelectChange={onSelectChange}
    />
  );
}

UsersContextTableFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  isAuthor: PropTypes.bool,
  columnType: PropTypes.string,
  columnName: PropTypes.string,
  columnBulkEditable: PropTypes.string,
  context: PropTypes.string,
  role: PropTypes.string,
};

UsersContextTableFilter.defaultProps = {
  isAuthor: false,
};

export default UsersContextTableFilter;
