// Core
import React, {
  useMemo, memo, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Box from '@mui/material/Box';
import Select from '../../../../../components/_Form/Selects/Select/Select';

function TypeSelect(props) {
  const {
    input, margin, menuPlacement,
    label, isPortal,
    isMulti, closeMenuOnSelect, disabled,
  } = props;
  const { t } = useTranslation();
  const initialMeta = { touched: false, error: undefined };
  const [meta, setMeta] = useState(initialMeta);
  const options = [{ label: t('feature'), value: 'feature' }, { label: t('feature_value'), value: 'feature_value' }];
  const selectProps = useMemo(() => ({
    input,
    margin,
    menuPlacement,
    options,
    isMulti,
    isPortal,
    closeMenuOnSelect,
    hasSearch: false,
    isClearable: true,
    label,
    meta,
    variant: 'outlined',
    placeholder: '',
    shrink: true,
  }), [
    input,
    margin,
    menuPlacement,
    options,
    isMulti,
    isPortal,
    label,
    closeMenuOnSelect,
    meta,
  ]);

  const handleOnBlur = useCallback(() => {
    const isError = Array.isArray(input?.value) ? input.value.length === 0 : !input?.value;
    if (isError) {
      setMeta({ touched: true, error: t('Выберите тип слова для связывания') });
    }
  }, [input]);

  return (
    <Box tabIndex="0" onBlur={handleOnBlur} onFocus={() => setMeta(initialMeta)}>
      <Select disabled={disabled} {...selectProps} />
    </Box>
  );
}

export default memo(TypeSelect);
