// Core
import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as feedsProductsStatisticsListKeys } from './reducer';
// Config
import { pageSizes } from '../../../config/globalConfig';

export const feedsProductsStatistics = Object.freeze({
  currentPage: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.currentPage], 0),
  filters: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.filters], List()),
  isLoading: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.pending], false),
  list: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.items], List()),
  pageSize: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.reload], false),
  totalCount: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.totalCount], 0),
  sorting: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.sorting], List()),
  settingStatus: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.settingStatus], false),
  settingPending: state => getIn(state, [feedsProductsStatisticsListKeys.controller, feedsProductsStatisticsListKeys.list, feedsProductsStatisticsListKeys.settingPending], false),
});
