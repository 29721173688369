const ContentProductCategoryCountModalStyle = theme => ({
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowHead: {
    color: theme.palette.text.primary,
  },
});

export default ContentProductCategoryCountModalStyle;
