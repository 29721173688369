// Core
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const NoOptionsMessage = (props) => {
  const { selectProps, ...restProps } = props;
  const { t } = useTranslation();
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...restProps.innerProps}
    >
      {t('Нет вариантов')}
    </Typography>
  );
};

export default NoOptionsMessage;
