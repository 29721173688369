// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import FaqTable from './components/FaqTable';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import { selectors } from '../../../engine/config/selectors';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Hooks
import { useFaqList } from './_hooks/useFaqList';
// Engine
import { tableColumns } from '../../../_helpers/data/pages/faqs/tableColumns';
import { asyncActions } from '../../../engine/core/faq/saga/asyncActions';
import { pageLinks } from '../../../engine/config/routes';
import { actions } from '../../../engine/core/faq/actions';
import { initialState, faqKeys } from '../../../engine/core/faq/reducer';

function FaqPage() {
  useFaqList();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectors.faqs.filters);
  const sorting = useSelector(selectors.faqs.sorting);
  const settingStatus = useSelector(selectors.faqs.settingStatus);
  const settingPending = useSelector(selectors.faqs.settingPending);
  const pathname = useSelector(selectors.router.pathname);
  const isLoading = useSelector(selectors.faqs.isLoading);
  const hasFilters = pathname === pageLinks.faq.all ? filters.size : filters.size > 1;
  const isDisabledResetButton = isLoading || settingPending || !(hasFilters || sorting.size);

  const resetFilters = useCallback(() => {
    let defaultFilters = [];

    const createFilterByStatusField = (filterLabel, filterValue) => ({
      columnName: tableColumns.status.name,
      value: {
        label: filterLabel,
        value: filterValue,
      },
    });

    switch (pathname) {
      case pageLinks.faq.new: {
        const newFaqFilterValue = 0;
        defaultFilters = [createFilterByStatusField(t('Новый'), newFaqFilterValue)];
        break;
      }
      case pageLinks.faq.review: {
        const reviewFaqFilterValue = 1;
        defaultFilters = [createFilterByStatusField(t('Ожидает ревью'), reviewFaqFilterValue)];
        break;
      }
      case pageLinks.faq.published: {
        const publishedFaqFilterValue = 2;
        defaultFilters = [createFilterByStatusField(t('Опубликован'), publishedFaqFilterValue)];
        break;
      }
      default: {
        break;
      }
    }
    dispatch(actions.setFaqSorting(initialState.get(faqKeys.faqList).get(faqKeys.sorting).toJS()));
    if (defaultFilters.length !== filters.size) {
      dispatch(actions.setFaqFilters(defaultFilters));
    }
    dispatch(actions.setFaqCurrentPage(initialState.get(faqKeys.faqList).get(faqKeys.currentPage)));
  }, [dispatch, filters.size, pathname, t]);

  const putSettings = () => dispatch(asyncActions.putFaqSettingsAsync());

  return (
    <>
      <PageHeader title={t('Вопросы и ответы')}>
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton
          pending={isLoading || settingPending}
          disabled={isDisabledResetButton}
          onReset={resetFilters}
        />
      </PageHeader>
      <FaqTable />
    </>
  );
}

export default FaqPage;
